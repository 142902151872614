<div class="metrics-container">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <p><mat-icon>local_hospital</mat-icon><span class="metrics-title">Document health</span></p>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="button-container">
      <button mat-flat-button color="primary" (click)="loadMetrics()">Run document health check</button>
      <span *ngIf="metrics" class="time-since-checked"> Last checked: {{ metrics.createdAt | timeAgo }}</span>
    </div>
    <p *ngIf="!metrics">
      This panel displays various document health metrics, along with links to the sections where any problems occur.
    </p>
    <div *ngIf="metrics">
      <p>This document has:</p>

      <ng-container *ngFor="let item of documentMetricsConfig">
        <!-- Each list item is its own list, otherwise the nested items don't display well -->
        <mat-nav-list class="document-metrics">
          <mat-list-item (click)="toggleExpanded(item.key)">
            <mat-icon matListIcon *ngIf="!item.isIconSvg">{{ item.iconName }}</mat-icon>
            <mat-icon matListIcon *ngIf="item.isIconSvg" [svgIcon]="item.iconName"></mat-icon>
            <button mat-icon-button *ngIf="metrics.getValueByConfigType(item.key) !== 0">
              <mat-icon *ngIf="!isExpanded(item.key)">chevron_right</mat-icon>
              <mat-icon *ngIf="isExpanded(item.key)">expand_more</mat-icon>
            </button>
            <div class="healthy-icon" *ngIf="metrics.getValueByConfigType(item.key) === 0">
              <mat-icon matListIcon>check_circle</mat-icon>
            </div>
            <span>{{ metrics.getValueByConfigType(item.key) }} {{ getDescription(item) }}</span>
          </mat-list-item>
        </mat-nav-list>

        <mat-nav-list
          *ngIf="metrics.getValueByConfigType(item.key) > 0 && isExpanded(item.key)"
          class="section-metrics"
        >
          <ng-container *ngIf="!item.guidanceText; else guidanceText">
            <ng-container *ngFor="let sectionMetrics of metrics.sectionMetrics">
              <mat-list-item
                *ngIf="sectionMetrics.getValueByConfigType(item.key) > 0"
                class="section-metric"
                [routerLink]="getSectionUrl(sectionMetrics.sectionId)"
                tabindex="0"
                [attr.aria-label]="'go to section ' + getSectionTitle(sectionMetrics.sectionId)"
              >
                <mat-icon matListIcon *ngIf="!item.isIconSvg">{{ item.iconName }}</mat-icon>
                <mat-icon matListIcon *ngIf="item.isIconSvg" [svgIcon]="item.iconName"></mat-icon>
                <span matLine
                  >{{ sectionMetrics.sectionId | fragmentIndex }} {{ getSectionTitle(sectionMetrics.sectionId) }}</span
                >
                <span matLine class="section-metric-score"
                  >{{ sectionMetrics.getValueByConfigType(item.key) }} {{ getDescription(item, sectionMetrics) }}</span
                >
              </mat-list-item>
            </ng-container>
          </ng-container>
          <ng-template #guidanceText>
            <span>{{ item.guidanceText }}</span>
          </ng-template>
        </mat-nav-list>
      </ng-container>

      <div class="healthy-icon" *ngIf="metrics.isHealthy()">
        <mat-icon class="healthy-icon">check_circle</mat-icon>
      </div>
      <span class="metrics-title" *ngIf="metrics.isHealthy()">Your document is healthy!</span>
    </div>
    <p>Reviewers cannot see this health-check information.</p>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </mat-expansion-panel>
</div>
