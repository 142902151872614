import {Injectable} from '@angular/core';
import {FragmentOperationSource} from 'app/fragment/diff/fragment-operation-source';
import {
  AnchorFragment,
  ClauseFragment,
  ClauseType,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  Fragment,
  FragmentType,
} from 'app/fragment/types';
import {AnchorSource} from 'app/interfaces';
import {UUID} from 'app/utils/uuid';
import {FragmentService} from './fragment.service';

@Injectable({
  providedIn: 'root',
})
export class AnchorService {
  constructor(private _fragmentService: FragmentService) {}

  /**
   * Create the start and end anchor fragments from the given AnchorSource and return a promise containing the created anchors.
   */
  public createAnchors(anchorSource: AnchorSource): Promise<AnchorFragment[]> {
    if (anchorSource && !Object.values(anchorSource).includes(null)) {
      const created: Fragment[] = [];
      const updated: Fragment[] = [];

      const isSuggestion: boolean = 'currentValue' in anchorSource && 'suggestedValue' in anchorSource;

      const secondAnchor: AnchorFragment = this._handleAnchorFragmentCreation(
        false,
        anchorSource.endFragmentId,
        anchorSource.endOffset,
        created,
        updated,
        isSuggestion
      );
      const firstAnchor: AnchorFragment = this._handleAnchorFragmentCreation(
        true,
        anchorSource.startFragmentId,
        anchorSource.startOffset,
        created,
        updated,
        isSuggestion
      );
      secondAnchor.otherAnchorId = firstAnchor.id;
      firstAnchor.otherAnchorId = secondAnchor.id;

      return this._fragmentService
        .batchHandleFragmentOperations([
          ...FragmentOperationSource.create(created),
          ...FragmentOperationSource.update(updated),
        ])
        .then(() => [firstAnchor, secondAnchor]);
    }

    return Promise.resolve([]);
  }

  /**
   * This creates an anchor fragment in a location dependant on the initial location of the fragment and if the anchor is the
   * start/end anchor.
   * - If in a equation or a figure, then should be created before/after the figure fragment or equation fragment
   * - If in a text, SFR or SI fragment, then we just split the fragment and insert the anchor in the split
   */
  private _handleAnchorFragmentCreation(
    start: boolean,
    fragmentId: UUID,
    offset: number,
    created: Fragment[],
    updated: Fragment[],
    isSuggestion?: boolean
  ): AnchorFragment {
    const fragment: Fragment = this._fragmentService.find(fragmentId);
    const anchor: AnchorFragment = new AnchorFragment(null, false, start);
    const templatedClause: ClauseFragment = this._getTemplatedClause(fragment);
    if (templatedClause && !isSuggestion) {
      return this._handleInTemplatedClause(start, anchor, templatedClause, created);
    } else if (fragment.is(...EDITABLE_TEXT_FRAGMENT_TYPES) || isSuggestion) {
      return this._splitAndInsert(anchor, fragment, offset, created, updated);
    }

    return this._insert(start, anchor, fragment, created, updated);
  }

  /**
   * Get the clause ancestor of the given fragment if it is a template clause, i.e either:
   * - A standard format requirement
   * - A specifier instruction
   * If the clause ancestor fits neither of the above criteria, then return null.
   */
  private _getTemplatedClause(fragment: Fragment): ClauseFragment {
    const clause: ClauseFragment = fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    return clause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) || !!clause.standardFormatType ? clause : null;
  }

  /**
   * Handle inserting a anchor framgent into a templated clause, dependant on if the anchor framgent is the start or end anchor:
   * - If the anchor is the start anchor, then place the anchor right at the start of the clause
   * - If the anchor is the end anchor, then place the anchor right at the end of the clause
   */
  private _handleInTemplatedClause(
    start: boolean,
    anchor: AnchorFragment,
    templatedClause: ClauseFragment,
    created: Fragment[]
  ): AnchorFragment {
    const index: number = start ? 0 : templatedClause.children.length;
    templatedClause.children.splice(index, 0, anchor);
    created.push(anchor);
    return anchor;
  }

  /**
   * Split the given fragment and insert the anchor in between the two parts of the split.
   */
  private _splitAndInsert(
    anchor: AnchorFragment,
    fragment: Fragment,
    offset: number,
    created: Fragment[],
    updated: Fragment[]
  ): AnchorFragment {
    const split: Fragment = fragment.split(offset);
    anchor.insertAfter(fragment);
    split.insertAfter(anchor);
    created.push(split, anchor);
    updated.push(fragment);
    return anchor;
  }

  /**
   * Handle inserting the anchor before/after the given fragment if the anchor is the start/end anchor.
   * If the clause sibling before/after the given fragment exists and is text, then split the sibling and
   * insert the anchor in the split.
   */
  private _insert(
    before: boolean,
    anchor: AnchorFragment,
    fragment: Fragment,
    created: Fragment[],
    updated: Fragment[]
  ): AnchorFragment {
    const sibling: Fragment = before ? fragment.previousSibling() : fragment.nextSibling();
    if (sibling?.is(...EDITABLE_TEXT_FRAGMENT_TYPES)) {
      return this._splitAndInsert(anchor, sibling, before ? sibling.length() : 0, created, updated);
    } else {
      const parent: Fragment = fragment.parent;
      const index: number = before ? fragment.index() : fragment.index() + 1;
      parent.children.splice(index, 0, anchor);
      created.push(anchor);
      return anchor;
    }
  }
}
