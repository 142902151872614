import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DialogModule} from 'app/dialog/dialog/dialog.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {AutofocusModule} from 'app/utils/directives/autofocus.module';
import {CapacityOfCommentToDisplayNameModule} from 'app/utils/pipes/capacity-of-comment-to-display-name.module';
import {UserModule} from 'app/widgets/user/user.module';
import {DiscussionVersionHovertipModule} from './discussion-hovertip/discussion-version-hovertip.module';
import {DiscussionComponent} from './discussion.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    CarsErrorHandlingModule,
    MatFormFieldModule,
    MatInputModule,
    DiscussionVersionHovertipModule,
    MatDialogModule,
    FormsModule,
    AutofocusModule,
    DialogModule,
    UserModule,
    CapacityOfCommentToDisplayNameModule,
  ],
  declarations: [DiscussionComponent],
  exports: [DiscussionComponent],
})
export class DiscussionModule {}
