import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {RouterModule} from '@angular/router';
import {FragmentModule} from 'app/fragment/fragment.module';
import {BackgroundModule} from 'app/sidebar/background/background.module';
import {SectionBackgroundComponent} from './section-background.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    FragmentModule,
    BackgroundModule,
  ],
  declarations: [SectionBackgroundComponent],
  exports: [SectionBackgroundComponent],
})
export class SectionBackgroundModule {}
