import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DialogComponent} from './dialog.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatTooltipModule],
  declarations: [DialogComponent],
  exports: [DialogComponent],
})
export class DialogModule {}
