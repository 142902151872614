import {PadType} from 'app/element-ref.service';
import {Observable} from 'rxjs';
import {PermissionsHandler} from '../handlers/permissions-handler';
import {ASTNodeType, PermissionInput} from './permissions';

/**
 * An abstract base class from which all ASTNodes should be derived.
 *
 * @field type    {ASTNodeType}    The type of this ASTNode
 */
export abstract class PermissionASTNode {
  constructor(public readonly type: ASTNodeType) {}

  /**
   * Returns true if condition is satisfied
   *
   * @param handlers the permissions handler map from PermissionInput to PermissionsHandler (Stored in permissions.service)
   *
   * @returns    {Observable<boolean>}    Observable emitting boolean representation of condition satisfied
   */
  public abstract can(handlers: Map<PermissionInput, PermissionsHandler>, padType: PadType): Observable<boolean>;
}
