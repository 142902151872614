import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {ActionRequest} from 'app/fragment/action-request';
import {Caret} from 'app/fragment/caret';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {TableFragment} from 'app/fragment/table/table-fragment';
import {
  ClauseFragment,
  ClauseType,
  EquationFragment,
  FigureFragment,
  Fragment,
  FragmentType,
  ListFragment,
  ListItemFragment,
} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {ClauseService} from 'app/services/clause.service';
import {ImageService} from 'app/services/image.service';
import {LockService} from 'app/services/lock.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {RichTextType} from 'app/services/rich-text.service';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';
import {PadType} from '../../element-ref.service';
import {FragmentService} from '../../services/fragment.service';
import {CaptionedFragmentTargetClauseService} from './captioned-fragment-target-clause.service';

@Component({
  selector: 'cars-clause',
  templateUrl: './clause.component.html',
  styleUrls: ['./clause.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseComponent extends FragmentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public set content(value: ClauseFragment) {
    super.content = value;
  }

  public get content(): ClauseFragment {
    return super.content as ClauseFragment;
  }

  @Input() public showMarginIcons: boolean = true;
  @Input() public padType: PadType = PadType.MAIN_EDITABLE;
  @Input() public showCurrentClause: boolean = true;
  @Input() public currentView: CurrentView;

  public readonly tooltipDelay: number = 1000;
  public readonly ClauseType: typeof ClauseType = ClauseType;
  public readonly PadType: typeof PadType = PadType;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public reordering: boolean = false;
  public isItem: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    protected _cd: ChangeDetectorRef,
    private _viewService: ViewService,
    private _fragmentService: FragmentService,
    private _clauseService: ClauseService,
    private _reorderClausesService: ReorderClausesService,
    private _lockService: LockService,
    private _targetClauseService: CaptionedFragmentTargetClauseService,
    private _imageService: ImageService,
    elementRef: ElementRef
  ) {
    super(_cd, elementRef);
  }

  /**
   * Initialise this component and setup subscriptions.
   */
  public ngOnInit(): void {
    super.ngOnInit(); // IMPORTANT

    this.subscriptions.push(
      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        this.markForCheck();
        if (this.padType === PadType.MAIN_EDITABLE) {
          this.currentView = currentView;
        }
      }),
      this._reorderClausesService.onReorderingEvent().subscribe((status: boolean) => {
        this.reordering = status;
        this.markForCheck();
      }),
      this._fragmentService.onUpdate(this._setIsItem.bind(this), (frag) => frag.equals(this.content))
    );
    this._setIsItem();
  }

  /**
   * Tear down this component.
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy(); // IMPORTANT

    this.subscriptions.splice(0).forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * @override
   */
  public blur() {
    this._clauseService.requestVersion(this.content.id);
  }

  /**
   * @inheritdoc
   */
  public onRichText(type: RichTextType, start: Caret, end: Caret, ...args: any[]): ActionRequest {
    let action: ActionRequest = new ActionRequest();

    const listFragment: ListFragment = this.content.children.find(
      (child: Fragment) => child instanceof ListFragment
    ) as ListFragment;

    const targetClause: ClauseFragment = this._targetClauseService.determineTargetClause(
      this.content,
      type,
      listFragment,
      args
    );

    switch (type) {
      case RichTextType.LIST:
        {
          const ordered: boolean = args[0] === true;

          if (listFragment) {
            listFragment.ordered = ordered;
            this._fragmentService.update(listFragment);
          } else {
            const listIndex = targetClause.children.findIndex((child) => child.is(FragmentType.LIST));
            if (listIndex > -1) {
              // If the target clause already has a list, merge
              const newItem: ListItemFragment = ListItemFragment.empty();
              targetClause.children[listIndex].children.push(newItem);
              this._fragmentService.create(newItem);
              action.fragment = newItem.children[0];
            } else {
              // Else create a new list
              const newList: ListFragment = ListFragment.withSize(1, ordered);
              this._targetClauseService.addListToClause(newList, targetClause);
              this._fragmentService.create(newList);
              action.fragment = newList.children[0].children[0];
            }
          }
        }
        break;

      case RichTextType.TABLE:
        {
          const table: TableFragment = args[0];
          targetClause.children.push(table);
          this._fragmentService.create(table);
          action.fragment = table.at(0, 0);
        }
        break;

      case RichTextType.EQUATION:
        {
          const inline: boolean = args[0] === true;
          const equation: EquationFragment = EquationFragment.empty(inline);
          action.fragment = equation.source;

          if (inline) {
            const split: Fragment = end.fragment.split(end.offset);
            equation.insertAfter(end.fragment);
            split.insertAfter(equation);

            this._fragmentService.update(end.fragment);
            this._fragmentService.create([equation, split]);
          } else {
            const equationAncestor: EquationFragment = end.fragment.findAncestorWithType(
              FragmentType.EQUATION
            ) as EquationFragment;
            if (!!equationAncestor) {
              equationAncestor.component.blur();
            }
            targetClause.children.push(equation);
            this._fragmentService.create(equation);
          }
        }
        break;

      case RichTextType.FIGURE:
        {
          const newFigure: FigureFragment = args[0];
          const oldFigureIndex: number = args[1];
          const file: File = args[2];

          if (Number.isInteger(oldFigureIndex)) {
            newFigure.insertAfter(this.content.children[oldFigureIndex]);
          } else {
            targetClause.children.push(newFigure);
          }

          this._fragmentService.create(newFigure).then(() => this._imageService.uploadImage(file, newFigure.id));

          action.fragment = newFigure.caption;
          action.offset = newFigure.caption.length() - end.offset;
        }
        break;

      default:
        {
          action = null;
        }
        break;
    }

    if (!this.content.equals(targetClause) && !this.content.parent.is(FragmentType.CLAUSE_GROUP)) {
      // Validation occurs in getTargetClause, no need to validate.
      this._fragmentService.deleteValidatedFragments(this.content);
    }

    return action;
  }

  /**
   * Respond to click events on the clause by selecting it.
   *
   * Will select the clause if the following hold:
   * - the clause list is not being reordered
   * - the clause should show the CurrentClauseDirective if it is selected
   * - the view mode is either live, historical or the change log right hand pad in editing mode
   * - either:
   *    - the user does not have the permissions to lock the clause (and so will not lock the clause on selection)
   *    - the user does have the permissions to lock the clause, the clause is not readonly and the clause can be locked
   */
  public click(): void {
    if (
      !this.reordering &&
      this.showCurrentClause &&
      (this.currentView.isLive() || this.currentView.isHistorical() || this.currentView.isChangelog()) &&
      (!this.currentView.userCanLockClause() || (!this.readOnly && this._lockService.canLock(this.content)))
    ) {
      this._clauseService.setSelected(this.content);
    }
  }

  private _setIsItem() {
    this.isItem = this.content.isClauseOfType(ClauseType.ITEM);
  }
}
