<div>
  <button
    *ngIf="showRemove"
    [disabled]="carsAction | carsDisabled: document.id | async"
    color="primary"
    (click)="unassignUser()"
    matTooltip="Unassign user from this document"
    matTooltipPosition="before"
    [matTooltipShowDelay]="tooltipDelay"
    mat-icon-button
    [attr.aria-label]="'Unassign user from this document'"
  >
    <div>
      <mat-icon>remove_circle</mat-icon>
    </div>
  </button>
  <cars-user [userId]="userId" (userEvent)="handleUserEvent($event)"></cars-user>
  <mat-slide-toggle
    *ngIf="(CarsAction.CAN_TOGGLE_USER | carsEnabled: document.id | async) && showToggle && userFound"
    [checked]="checked"
    (change)="toggle($event)"
    [matTooltip]="tooltipMessage"
    matTooltipPosition="after"
    [matTooltipShowDelay]="tooltipDelay"
  ></mat-slide-toggle>
</div>
