<mat-nav-list>
  <cars-document-overview-section
    *ngIf="!deletedTab && (CarsAction.IS_CHANGELOG_AUX_VIEW_MODE | carsDisabled: document.documentId | async)"
    (mouseenter)="setHovering(null)"
    [currentView]="currentView"
    [document]="document"
  >
  </cars-document-overview-section>

  <ng-container *ngFor="let child of sectionListForDisplay" [ngSwitch]="child.type">
    <ng-container *ngSwitchCase="FragmentType.SECTION">
      <mat-list-item (mouseenter)="setHovering(child)">
        <cars-section-list-item
          [class.dragging]="child.equals(draggingSection)"
          [section]="child"
          [currentView]="currentView"
          [type]="type"
          (onDraggingEvent)="dragEvent(child)"
          [dragging]="child.equals(draggingSection)"
        ></cars-section-list-item>
      </mat-list-item>
    </ng-container>

    <ng-container *ngSwitchCase="FragmentType.SECTION_GROUP">
      <mat-list-item (mouseenter)="setHovering(child)" tabindex="0" disableRipple>
        <cars-section-group-list-item
          [class.dragging]="child.equals(draggingSection)"
          [sectionGroup]="child"
          [currentView]="currentView"
          [type]="type"
          (onDraggingEvent)="dragEvent(child)"
        ></cars-section-group-list-item>
      </mat-list-item>
    </ng-container>
  </ng-container>

  <cars-section-creator
    *ngIf="!deletedTab && (CarsAction.AUTHOR_DOCUMENT | carsEnabled: document.documentId | async)"
    (created)="created($event)"
  ></cars-section-creator>
</mat-nav-list>
