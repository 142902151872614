import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {AutofocusModule} from 'app/utils/directives/autofocus.module';
import {CapacityOfCommentToDisplayNameModule} from 'app/utils/pipes/capacity-of-comment-to-display-name.module';
import {DiscussionModule} from './discussion/discussion.module';
import {DiscussionsComponent} from './discussions.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatSelectModule,
    MatOptionModule,
    CarsErrorHandlingModule,
    DiscussionModule,
    FormsModule,
    AutofocusModule,
    AltAccessibilityModule,
    CapacityOfCommentToDisplayNameModule,
  ],
  declarations: [DiscussionsComponent],
  exports: [DiscussionsComponent],
})
export class DiscussionsModule {}
