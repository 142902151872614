import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {AssignedUserModule} from './assigned-user/assigned-user.module';
import {UserCreatorModule} from './user-creator/user-creator.module';
import {UserListAutocompleteComponent} from './user-list-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatOptionModule,
    MatTooltipModule,
    MatListModule,
    AssignedUserModule,
    UserCreatorModule,
    FormsModule,
    CarsPermissionPipesModule,
  ],
  declarations: [UserListAutocompleteComponent],
  exports: [UserListAutocompleteComponent],
})
export class UserListAutocompleteModule {}
