/* eslint-disable */
// tslint is unable to recognize 'parent' from inherited class when used in the html

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {PadType} from 'app/element-ref.service';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {CopyPasteService} from 'app/services/copy-paste/copy-paste.service';
import {FragmentService} from 'app/services/fragment.service';
import {ImageService} from 'app/services/image.service';
import {LockService} from 'app/services/lock.service';
import {RichTextService, RichTextType} from 'app/services/rich-text.service';
import {UserService} from 'app/services/user/user.service';
import {UUID} from 'app/utils/uuid';
import {environment} from '../../../environments/environment';
import {ActionRequest} from '../action-request';
import {Caret} from '../caret';
import {ClauseComponent} from '../clause/clause.component';
import {CaptionedFragmentComponent} from '../core/captioned-fragment.component';
import {FragmentComponent} from '../core/fragment.component';
import {Key} from '../key';
import {FigureFragment, Fragment, FragmentType, TextFragment, VirusScanState} from '../types';

@Component({
  selector: 'cars-figure-fragment',
  templateUrl: './figure-fragment.component.html',
  styleUrls: ['./figure-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true,
})
export class FigureFragmentComponent extends CaptionedFragmentComponent implements OnInit {
  public readonly PadType: typeof PadType = PadType;
  public showMarginIcons: boolean = false;
  public padType: PadType = PadType.MAIN_EDITABLE;
  public imageUrl: SafeUrl;
  public inline: boolean;
  public tooltipDelay: number = environment.tooltipDelay;
  public getUserColour: Function = UserService.getUserColour;
  public VirusScanState: typeof VirusScanState = VirusScanState;
  public displayWidth: string = '100%';

  public set content(value: FigureFragment) {
    super.content = value;
  }

  public get content(): FigureFragment {
    return super.content as FigureFragment;
  }

  constructor(
    private imageService: ImageService,
    private richTextService: RichTextService,
    protected cd: ChangeDetectorRef,
    protected fragmentService: FragmentService,
    protected lockService: LockService,
    protected copyPasteService: CopyPasteService,
    protected userService: UserService,
    protected selectionOperationService: SelectionOperationsService,
    protected elementRef: ElementRef
  ) {
    super(cd, fragmentService, lockService, copyPasteService, userService, selectionOperationService, elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.inline = !!this.content.findAncestorWithType(FragmentType.TABLE_CELL);

    const clauseComponent: ClauseComponent = this.clause?.component as ClauseComponent;
    if (!!clauseComponent) {
      this.padType = clauseComponent.padType;
      this.showMarginIcons = clauseComponent.showMarginIcons;
    }

    this._loadImageIntoPad(this.content.uploadId);
    this._setCorrectWidthForImage();

    this._subscriptions.push(
      this.fragmentService.onUpdate(
        (fragment: Fragment) => {
          this._loadImageIntoPad((fragment as FigureFragment).uploadId);
          this._setCorrectWidthForImage();
        },
        (fragment: Fragment) => this.content.equals(fragment)
      )
    );
  }

  /**
   * @inheritdoc
   */
  public onKeydown(key: Key, target: FragmentComponent, caret?: Caret): ActionRequest {
    return null;
  }

  /**
   * @inheritdoc
   */
  public onDelete(): void {
    this.imageService.delete(this.content);
  }

  /**
   * @inheritdoc
   */
  public onLandscape(): void {
    this.content.toggleLandscape();
    this.richTextService.triggerLandscapeEvent();
    this.fragmentService.update(this.content);
  }

  /**
   * Determine whether the caption should show given the parent clause and section types.
   *
   * @returns {boolean}   True if should show
   */
  public showIndex(): boolean {
    return super.showIndex() && !this.inline;
  }

  /**
   * Replace the image in the current fragment with a new upload
   */
  public onReplace(event: Event): void {
    const file: File = this.imageService.validateImage(event);
    if (!!file) {
      this.imageService.uploadImage(file, this.content.id);
    }
  }

  public showClickBelow(): boolean {
    return (
      !this.readOnly &&
      this.inline &&
      (this.content.isLastChild() || (this.content.nextSibling() && !this.content.nextSibling().is(FragmentType.TEXT)))
    );
  }

  public insertTextFragment(): void {
    const newText: TextFragment = TextFragment.empty();
    // Send the index of this figure and the table cell containing it as extra args
    // to the onRichText method so it knows where to insert the new text fragment
    this.richTextService.next(RichTextType.TEXT, newText, this.content.index(), this.content.parent);
  }

  /**
   * Helper method to return altText as a single TextFragment used as the old value in the
   * clause change summary.
   */
  public changeSummaryAltTextDisplay(): TextFragment {
    const altTextTextFrag: TextFragment = new TextFragment(UUID.random(), this.content?.altText);
    altTextTextFrag.parent = this.content;
    return altTextTextFrag;
  }

  /**
   * Loads the image from the given uploadId. Note that this uses a given id rather than the content value to prevent
   * issues with the image not updating in components which use a clone of the fragment as the order of triggering
   * fragmentService::onUpdate is not deterministic.
   *
   * @param uploadId The upload id of the displayed figure fragment.
   */
  private _loadImageIntoPad(uploadId: UUID): void {
    if (uploadId) {
      this.imageService
        .loadImage(uploadId)
        .then((imageUrl: SafeUrl) => {
          this.imageUrl = imageUrl;
          this.markForCheck();
        })
        .catch(() => this.markForCheck());
    }
  }

  /**
   * Sets the width for display in the pad so it is representative of real size.
   * Note we don't need to pass in the updated fragment when fragmentService::onUpdate is called, as that is specific
   * to figures shown in the alt text sidebar, and we always display them at 100% width.
   */
  private _setCorrectWidthForImage(): void {
    this.imageService.getWidthDisplay(this.content).subscribe((displayWidth: string) => {
      this.displayWidth = displayWidth;
      this.markForCheck();
    });
  }
}
