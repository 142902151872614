import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {ExportService} from 'app/services/export.service';
import {Subscription} from 'rxjs';
import {CarsNotification, ExportNotification, NotificationStatus, VersionNotification} from './cars-notification';

@Component({
  selector: 'cars-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnDestroy {
  @Input() public notification: CarsNotification;

  private _subscriptions: Subscription[] = [];

  constructor(private _router: Router, private exportService: ExportService) {}

  public ngOnDestroy() {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  public isInProgress(): boolean {
    return this.notification && this.notification.status === NotificationStatus.IN_PROGRESS;
  }

  public hasFailed(): boolean {
    return this.notification && this.notification.status === NotificationStatus.FAILED;
  }

  public hasSucceeded(): boolean {
    return this.notification && this.notification.status === NotificationStatus.COMPLETED;
  }

  public navigate(): void {
    if (this.notification instanceof VersionNotification) {
      if (this.notification.path) {
        this._router.navigate([this.notification.path]);
      }
    } else if (this.notification instanceof ExportNotification) {
      if (this.notification.status === NotificationStatus.COMPLETED) {
        this.exportService.retrieve(
          this.notification?.notificationId,
          this.notification?.data?.['exportType'],
          this.notification?.title
        );
      }
    }
  }
}
