<cars-alt-accessibility [altProperties]="altProperties['initial-button']"></cars-alt-accessibility>
<div class="left-toolbar">
  <button
    mat-button
    matTooltip="Sidebar"
    [matTooltipShowDelay]="tooltipDelay"
    matTooltipPosition="below"
    class="menu-toggle"
    aria-label="Toggle navigation bar"
    *ngIf="showNavigationToggle && !isNavigationOpen"
    (click)="onToggleNavBar()"
  >
    <mat-icon>{{ isNavigationOpen ? 'chevron_left' : 'menu' }}</mat-icon>
    <cars-alt-accessibility
      [altProperties]="altProperties['toggle-menu']"
      (onSelect)="onToggleNavBar()"
    ></cars-alt-accessibility>
  </button>
  <div class="navigation-placeholder" *ngIf="isNavigationOpen || isChangeLogActive"></div>
  <button
    (click)="onEnterAuthoring()"
    mat-button
    matTooltip="Authoring mode"
    [matTooltipShowDelay]="tooltipDelay"
    matTooltipPosition="after"
    class="authoring-button"
    [ngClass]="{active: isAuthoringActive}"
    aria-label="Authoring or reviewing mode"
    carsAnalytics="authoring-view"
  >
    <mat-icon svgIcon="authoring"></mat-icon>
    <cars-alt-accessibility
      [altProperties]="altProperties['authoring']"
      (onSelect)="onEnterAuthoring()"
    ></cars-alt-accessibility>
  </button>
  <button
    (click)="onEnterChangeLog()"
    *ngIf="canViewChangelog"
    mat-button
    matTooltip="Change log view"
    [matTooltipShowDelay]="tooltipDelay"
    matTooltipPosition="after"
    class="changelog-button"
    [ngClass]="{active: isChangeLogActive}"
    [disabled]="!canEnterChangeLog"
    aria-label="Change log view"
    carsAnalytics="changelog-view"
  >
    <mat-icon>chrome_reader_mode</mat-icon>
    <cars-alt-accessibility
      [altProperties]="altProperties['change-log']"
      (onSelect)="onEnterChangeLog()"
    ></cars-alt-accessibility>
  </button>
</div>
