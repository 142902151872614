import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ClauseType} from 'app/fragment/types';
import {TdifHeadingClause} from 'app/tdif/tdif-types';

@Component({
  selector: 'cars-tdif-heading-clause',
  templateUrl: './tdif-heading-clause.component.html',
  styleUrls: ['./tdif-heading-clause.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifHeadingClauseComponent {
  @Input() public content: TdifHeadingClause;

  constructor() {}

  public getClauseClass(clauseType: ClauseType): ClauseType {
    return ClauseType[clauseType];
  }
}
