import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {ResolvedIconModule} from '../resolved-icon/resolved-icon.module';
import {DashboardTableComponent} from './dashboard-table.component';
import {ReviewStatusTableModule} from './review-status-table/review-status-table.module';
import {ReviewStatusToggleModule} from './review-status-toggle/review-status-toggle.module';
import {SectionTableModule} from './section-table/section-table.module';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatTableModule,
    SectionTableModule,
    ReviewStatusToggleModule,
    ReviewStatusTableModule,
    ResolvedIconModule,
  ],
  declarations: [DashboardTableComponent],
  exports: [DashboardTableComponent],
})
export class DashboardTableModule {}
