import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {DocumentRepository} from 'app/offline/repository';
import {BreadcrumbService} from 'app/services/breadcrumb.service';
import {GlobalRole} from 'app/services/user/authentication-provider';
import {RoleService} from 'app/services/user/role.service';
import {UserService} from 'app/services/user/user.service';
import {User} from 'app/user/user';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {Breadcrumb} from '../interfaces';
import {FeedbackDialogComponent} from './feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'cars-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() onAccessibilityToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('userMenu')
  public userMenu: MatMenu;
  @ViewChild('offlineMenu')
  public offlineMenu: MatMenu;
  @ViewChild('userMenuTrigger')
  public userMenuTrigger: MatMenuTrigger;
  @ViewChild('offlineMenuTrigger')
  public offlineMenuTrigger: MatMenuTrigger;

  public breadcrumbs: Breadcrumb[] = [];
  public adminUser = this.userService.isAdmin();
  public user: User = null;

  public readonly tooltipDelay = environment.tooltipDelay;

  public offline: boolean = LocalConfigUtils.getConfig().offline;
  public canUseOffline: boolean;

  public accountUrl: string;
  public userManagementUrl: string;
  public logoutUrl: string;

  public unseenNotifications: number = 0;

  @Input() accessibilityToggleChecked: boolean;

  private _userMenuOpen: boolean = false;
  private _offlineMenuOpen: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private breadcrumbService: BreadcrumbService,
    private documentRepository: DocumentRepository,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.breadcrumbService.getBreadcrumbs().subscribe((breadcrumbs) => {
        this.breadcrumbs = breadcrumbs;
        this.cd.markForCheck();
      })
    );
    this.user = this.userService.getUser();
    this.canUseOffline = this.roleService.isInGlobalRoles(GlobalRole.OFFLINE_MODE);
    this.setUrls();
  }

  public ngOnDestroy(): void {
    this.subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public setUrls(): void {
    this.accountUrl = this.userService.createAccountUrl();
    this.userManagementUrl = this.userService.userManagement();
    this.logoutUrl = this.userService.logoutUrl();
    this.cd.markForCheck();
  }

  /**
   * Sends feedback to the web-service.
   */
  public feedback() {
    this.dialog.open(FeedbackDialogComponent, {
      ariaLabel: 'Feedback dialog',
      disableClose: true,
    });
  }

  public openUserMenu() {
    if (!this._userMenuOpen) {
      this.userMenuTrigger.openMenu();
    } else {
      this.userMenuTrigger.closeMenu();
    }
    this._userMenuOpen = !this._userMenuOpen;
    this.cd.markForCheck();
  }

  public openOfflineMenu() {
    if (!this._offlineMenuOpen) {
      this.offlineMenuTrigger.openMenu();
    } else {
      this.offlineMenuTrigger.closeMenu();
    }
    this._offlineMenuOpen = !this._offlineMenuOpen;
    this.cd.markForCheck();
  }

  public setOffline(offline: boolean): void {
    if (offline) {
      this.documentRepository.count().then((count: number) => {
        const ref: MatDialogRef<DialogComponent> = this.dialog.open(DialogComponent);
        const dialog = ref.componentInstance;
        dialog.title = 'Go offline';
        dialog.message =
          'Going offline will allow you to review documents while not connected to the internet.<br>' +
          'You will only be able to review versions of documents that you have previously downloaded for offline review.<br>' +
          'You currently have ' +
          count +
          ' documents available offline<br>' +
          '<br>' +
          'This will refresh your browser';
        dialog.closeActions = [
          {title: 'Cancel', tooltip: 'Cancel going offline', response: false},
          {title: 'Go offline', tooltip: 'Go offline', response: true},
        ];

        ref.afterClosed().subscribe((response) => {
          if (response) {
            this._setOffline(true);
          }
        });
      });
    } else {
      this._setOffline(offline);
    }
  }

  private _setOffline(offline: boolean): void {
    LocalConfigUtils.setOffline(offline);
    window.location.reload();
  }

  /**
   * Handles the case when the alt event for the logo, CARS Wiki or Contact Support buttons are triggered.
   *
   * @param id {string} The id of the document elements to be clicked.
   */
  public handleAltSelection(id: string): void {
    document.getElementById(id).click();
  }

  /**
   * Keycloak can fail to invalidate tokens on logout, this ensures that we do not attempt to provide them.
   */
  public forceLogout(): void {
    localStorage.removeItem('kc-token');
    localStorage.removeItem('kc-refresh-token');
  }

  public onUpdateUnseen(event: number): void {
    this.unseenNotifications = event;
  }

  public onKeyboardEnter() {
    this.accessibilityToggleChecked = !this.accessibilityToggleChecked;
    this.onAccessibilityToggle.next(this.accessibilityToggleChecked);
    localStorage.setItem('cars-show-accessibility-focus', this.accessibilityToggleChecked.toString());
  }

  public onToggle(event) {
    this.onAccessibilityToggle.next(event.checked);
    localStorage.setItem('cars-show-accessibility-focus', event.checked.toString());
  }
}
