import {Component, Input, OnInit} from '@angular/core';
import {TreeDiffer} from 'app/fragment/tree-differ';
import {ClauseFragment, ClauseType} from 'app/fragment/types';

@Component({
  selector: 'cars-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit {
  private static readonly _DIFFER: TreeDiffer = new TreeDiffer();

  @Input() public version: ClauseFragment;
  @Input() public previousVersion: ClauseFragment;
  @Input() public dateFormat: string;

  public mergedFragment: ClauseFragment;

  public readonly ClauseType: any = ClauseType;

  constructor() {}

  /**
   * Initialise this component.
   */
  public ngOnInit(): void {
    this.mergedFragment = VersionComponent._DIFFER.diffWithoutReferences(
      this.previousVersion,
      this.version
    ) as ClauseFragment;
  }
}
