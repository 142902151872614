import {Injectable} from '@angular/core';
import {ChangelogService} from 'app/changelog/changelog.service';
import {DocumentRole} from 'app/documents/document-data';
import {PadType} from 'app/element-ref.service';
import {DocumentFragment} from 'app/fragment/types';
import {DocumentService} from 'app/services/document.service';
import {RoleService} from 'app/services/user/role.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConditionASTNode} from '../types/condition-ast-node';
import {EqualityRelation, PermissionInput} from '../types/permissions';
import {FalseOnPadTypes} from './false-on-pad-types';
import {PermissionsHandler} from './permissions-handler';

@Injectable()
export class DocumentRolePermissionsHandler implements PermissionsHandler {
  constructor(
    private _documentService: DocumentService,
    private _changelogService: ChangelogService,
    private _roleService: RoleService
  ) {}

  public getInputType(): PermissionInput {
    return PermissionInput.DOCUMENT_ROLE;
  }

  @FalseOnPadTypes(PadType.NO_PAD)
  public handle(condition: ConditionASTNode, padType: PadType): Observable<boolean> {
    const role = this.getRole(condition);
    const documentStream: Observable<DocumentFragment> = this._getDocumentStream(padType);
    switch (condition.relation) {
      case EqualityRelation.EQUALS:
        return documentStream.pipe(
          map((document: DocumentFragment) => this._roleService.isInDocumentRole(document, role))
        );
      case EqualityRelation.NOT_EQUALS:
        return documentStream.pipe(
          map((document: DocumentFragment) => !this._roleService.isInDocumentRole(document, role))
        );
      default:
        throw new Error(`Unsupported equality relation ${condition.relation}`);
    }
  }

  private _getDocumentStream(padType: PadType): Observable<DocumentFragment> {
    return padType === PadType.MAIN_EDITABLE
      ? this._documentService.getDocumentStream()
      : this._changelogService.getPublishedDocumentStream();
  }

  private getRole(condition: ConditionASTNode): DocumentRole {
    const role = DocumentRole[condition.value as string];
    if (role !== undefined) {
      return role;
    } else {
      throw new Error(`Unknown Document Role: ${condition.value}`);
    }
  }
}
