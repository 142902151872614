import {UserReviewState} from 'app/services/review-state.service';
import {UUID} from 'app/utils/uuid';

export enum AggregateType {
  ALL = 'ALL',
  BY_USER = 'BY_USER',
}

export type VersionDiscussionsAggregate = VersionDiscussionsAggregateAll | VersionDiscussionsAggregateByUser;

abstract class VersionDiscussionsAggregateBase {
  public documentId: UUID;
  public documentTitle: string;
  public versionName: string;
  public versionId: UUID;
  public discussions: number;
  public resolvedDiscussions: number;
  public versionCreatedBy: string;
  public versionCreatedAt: number;

  public abstract type: AggregateType;

  public allDiscussionsResolved(): boolean {
    return this.discussions === this.resolvedDiscussions;
  }

  public isAll(): this is VersionDiscussionsAggregateAll {
    return this.type === AggregateType.ALL;
  }

  public isByUser(): this is VersionDiscussionsAggregateByUser {
    return this.type === AggregateType.BY_USER;
  }

  public isLive(): boolean {
    return !this.versionId;
  }
}

export class VersionDiscussionsAggregateAll extends VersionDiscussionsAggregateBase {
  public type: AggregateType.ALL = AggregateType.ALL;
  public userReviewStates: UserReviewState[];

  public static deserialise(json: any): VersionDiscussionsAggregateAll {
    const aggregate: VersionDiscussionsAggregateAll = new VersionDiscussionsAggregateAll();
    aggregate.documentId = UUID.orThrow(json.documentId);
    aggregate.documentTitle = json.documentTitle;
    aggregate.versionName = json.versionName;
    aggregate.versionId = UUID.orNull(json.versionId);
    aggregate.discussions = json.discussions;
    aggregate.resolvedDiscussions = json.resolvedDiscussions;
    aggregate.versionCreatedBy = json.versionCreatedBy;
    aggregate.versionCreatedAt = json.versionCreatedAt;
    aggregate.userReviewStates = null;
    return aggregate;
  }

  public setUserReviewStates(reviewStates: UserReviewState[]): void {
    this.userReviewStates = reviewStates;
  }
}

export class VersionDiscussionsAggregateByUser extends VersionDiscussionsAggregateBase {
  public discussionsCommentedOn: number;
  public resolvedDiscussionsCommentedOn: number;
  public userReviewState: UserReviewState;

  public type: AggregateType.BY_USER = AggregateType.BY_USER;

  public static deserialise(json: any): VersionDiscussionsAggregateByUser {
    const aggregate: VersionDiscussionsAggregateByUser = new VersionDiscussionsAggregateByUser();
    aggregate.documentId = UUID.orThrow(json.documentId);
    aggregate.documentTitle = json.documentTitle;
    aggregate.versionName = json.versionName;
    aggregate.versionId = UUID.orNull(json.versionId);
    aggregate.discussions = json.discussions;
    aggregate.resolvedDiscussions = json.resolvedDiscussions;
    aggregate.discussionsCommentedOn = json.discussionsCommentedOn;
    aggregate.resolvedDiscussionsCommentedOn = json.resolvedDiscussionsCommentedOn;
    aggregate.versionCreatedBy = json.versionCreatedBy;
    aggregate.versionCreatedAt = json.versionCreatedAt;
    aggregate.userReviewState = null;
    return aggregate;
  }

  public allCommentedOnDiscussionsResolved(): boolean {
    return this.discussionsCommentedOn === this.resolvedDiscussionsCommentedOn;
  }

  public setUserReviewState(reviewStates: UserReviewState): void {
    this.userReviewState = reviewStates;
  }
}
