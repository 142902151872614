import {Pipe, PipeTransform} from '@angular/core';
import {UUID} from 'app/utils/uuid';
import {Fragment} from '../types';
import {FragmentIndexService} from './fragment-index.service';

@Pipe({
  name: 'fragmentIndex',
  pure: false,
})
export class FragmentIndexPipe implements PipeTransform {
  constructor(private _fragmentIndexService: FragmentIndexService) {}

  /**
   * For a given fragment id, return the display index.
   * Includes an optional parameter for a default value if no index is found.
   *
   * @param arg {UUID|Fragment}   The fragment id or fragment data object
   * @param defaultValue {string} The default display index to display if no index is found. Defaults to the empty string.
   * @returns   {string}          The display index
   */
  public transform(arg: UUID | Fragment, defaultValue: string = ''): string {
    return this._fragmentIndexService.getIndex(arg, defaultValue);
  }
}
