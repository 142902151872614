<td *ngIf="row.changeType === ChangeType.UNCHANGED; else changedRow" colspan="4">
  <span class="unchanged">{{ row.newIndex }} {{ row.newFragment?.value }}</span>
</td>

<ng-template #changedRow>
  <td>
    {{ row.oldIndex }}
  </td>
  <td class="clause-cell" [class.created]="row.changeType === ChangeType.CREATED">
    {{ row.changeType === ChangeType.CREATED ? 'New' : row.oldFragment?.value }}
    <div class="wsr" *ngIf="wsrMap.has(NEW_IDENTIFIER) && row.changeType !== ChangeType.CREATED">
      <div *ngFor="let frag of wsrMap.get(OLD_IDENTIFIER)">
        <cars-clause
          [content]="frag"
          [readOnly]="true"
          [showCurrentClause]="false"
          [showMarginIcons]="false"
        ></cars-clause>
      </div>
    </div>
  </td>
  <td>
    {{ row.newIndex }}
  </td>
  <td class="clause-cell" [class.deleted]="row.changeType === ChangeType.DELETED">
    <div>
      <cars-clause
        [content]="diffedSectionTitle"
        [readOnly]="true"
        [showCurrentClause]="false"
        [showMarginIcons]="false"
      ></cars-clause>
    </div>
    <div class="wsr" *ngIf="wsrMap.has(NEW_IDENTIFIER)">
      <div *ngFor="let frag of wsrMap.get(NEW_IDENTIFIER)">
        <cars-clause
          [content]="frag"
          [readOnly]="true"
          [showCurrentClause]="false"
          [showMarginIcons]="false"
        ></cars-clause>
      </div>
    </div>
  </td>
</ng-template>
