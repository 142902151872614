import {Injectable} from '@angular/core';
import {ChangelogLink, ChangelogLinkType} from 'app/changelog/changelog-link';
import {ChangelogRange} from 'app/changelog/changelog-range';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {Callback} from 'app/utils/typedefs';
import {Subject, Subscription} from 'rxjs';

export enum LinkBuilderState {
  INITIAL,
  FIRST_RANGE,
  SECOND_RANGE,
}

@Injectable({
  providedIn: 'root',
})
export class LinkBuilder {
  private published: ChangelogRange;
  private authored: ChangelogRange;
  private comment: string;
  private type: ChangelogLinkType;

  private firstRangeAddedSubject: Subject<ChangelogRange> = new Subject();
  private secondRangeAddedSubject: Subject<ChangelogRange> = new Subject();

  private commentSubject: Subject<{
    comment: string;
    type: ChangelogLinkType;
  }> = new Subject();
  private buildSubject: Subject<ChangelogLink> = new Subject();
  private resetSubject: Subject<void> = new Subject();

  private _state: LinkBuilderState = LinkBuilderState.INITIAL;
  public get state(): LinkBuilderState {
    return this._state;
  }

  constructor() {}

  /**
   * Add a range to the builder.  If a range has already been added, and both
   * are of the same published state, it will be overwritten.  Otherwise, it will be
   * added as the second range.
   *
   * @returns {this} The builder.
   */
  public addRange(range: ChangelogRange): this {
    if (range.published) {
      this.published = range;
    } else {
      this.authored = range;
    }

    if ((!this.authored && this.published) || (this.authored && !this.published)) {
      this.firstRangeAddedSubject.next(range);
      this._state = LinkBuilderState.FIRST_RANGE;
    } else if (this.authored && this.published) {
      this.secondRangeAddedSubject.next(range);
      this._state = LinkBuilderState.SECOND_RANGE;
    }

    return this;
  }

  /**
   * Add a comment to the builder.
   *
   * @returns {this} The builder.
   */
  public addComment(comment: string, type: ChangelogLinkType): this {
    this.comment = comment;
    this.type = type;
    this.commentSubject.next({comment, type});
    return this;
  }

  /**
   * Build the link.  Will fail if both ranges have not been specified.
   *
   * @returns {ChangelogLink} The link created, or null if both ranges have not been added.
   */
  public build(): ChangelogLink {
    if (!this.authored || !this.published) {
      Logger.error('changelog-error', 'Tried to build a link without both ranges');
      return null;
    }
    const link: ChangelogLink = new ChangelogLink(null, this.published.id, this.authored.id, this.comment, this.type);
    this.buildSubject.next(link);
    this.reset();
    return link;
  }

  /**
   * @param range {ChangelogRange} The range to check.
   *
   * @returns {boolean} True if:
   * * Only one range has been passed to the builder with addRange()
   * * The range passed to this function is of the opposite type - i.e. published if the first range
   *   is authored.
   */
  public requiresAsSecondRange(range: ChangelogRange): boolean {
    return range.published ? !this.published && !!this.authored : !this.authored && !!this.published;
  }

  public get publishedRange(): ChangelogRange {
    return this.published;
  }

  public get authoredRange(): ChangelogRange {
    return this.authored;
  }

  /**
   * Reset the builder.
   *
   * @returns {this} The builder.
   */
  public reset(): this {
    this.published = null;
    this.authored = null;
    this.comment = null;
    this.type = null;
    this.resetSubject.next();
    this._state = LinkBuilderState.INITIAL;
    return this;
  }

  /**
   * Run the callback whenever the first range is added.
   */
  public onFirstRangeAdded(callback: Callback<ChangelogRange>): Subscription {
    return this.firstRangeAddedSubject.subscribe(callback);
  }

  /**
   * Run the callback whenever the second range is added.
   */
  public onSecondRangeAdded(callback: Callback<ChangelogRange>): Subscription {
    return this.secondRangeAddedSubject.subscribe(callback);
  }

  /**
   * Run the callback whenever the comment is added.
   */
  public onComment(callback: Callback<{comment: string; type: ChangelogLinkType}>): Subscription {
    return this.commentSubject.subscribe(callback);
  }

  /**
   * Run the callback on build.
   */
  public onBuild(callback: Callback<ChangelogLink>): Subscription {
    return this.buildSubject.subscribe(callback);
  }

  /**
   * Run the callback on build.
   */
  public onReset(callback: Callback<void>): Subscription {
    return this.resetSubject.subscribe(callback);
  }
}
