import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PdfDialogComponent} from './pdf-dialog.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatProgressBarModule],
  declarations: [PdfDialogComponent],
  exports: [PdfDialogComponent],
})
export class PdfDialogModule {}
