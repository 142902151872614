<span class="icons" *ngIf="!!fragment">
  <button
    *ngIf="showSplitToSis"
    [disabled]="!canLock()"
    mat-icon-button
    matTooltip="Split list to Method of Measurements"
    matTooltipPosition="above"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="splitToSis()"
    attr.aria-label="Split list to Method of Measurements"
    carsAnalytics="split-list-to-sis"
  >
    <mat-icon>menu_book</mat-icon>
  </button>

  <button
    *ngIf="showSplitToItemClauses"
    [disabled]="!canLock()"
    mat-icon-button
    matTooltip="Split list to Item clauses"
    matTooltipPosition="above"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="splitToItemClauses()"
    attr.aria-label="Split list to Item clauses"
    carsAnalytics="split-list-to-item-clauses"
  >
    <mat-icon>list</mat-icon>
  </button>

  <!-- Move up -->
  <button
    [disabled]="!canMove(true)"
    mat-icon-button
    matTooltip="Move list up"
    matTooltipPosition="above"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="move(true)"
    attr.aria-label="Move list up"
    carsAnalytics="move-list"
    #moveUpTooltip="matTooltip"
  >
    <mat-icon>keyboard_arrow_up</mat-icon>
  </button>

  <!-- Move down -->
  <button
    [disabled]="!canMove(false)"
    mat-icon-button
    matTooltip="Move list down"
    matTooltipPosition="above"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="move(false)"
    attr.aria-label="Move list down"
    carsAnalytics="move-list"
    #moveDownTooltip="matTooltip"
  >
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <!-- Delete -->
  <button
    [disabled]="!canLock()"
    mat-icon-button
    matTooltip="Delete list"
    matTooltipPosition="above"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="delete()"
    carsAnalytics="delete-list"
  >
    <mat-icon>delete</mat-icon>
  </button>
</span>
