<div class="clause-links-container">
  <ng-container *ngIf="isClauseLinksDisabled(); else selectLinks">
    <div *ngIf="clauseLinks.length === 0; else changedClauseTypeMessage">
      Verification and documentation requirements are not required for the selected clause type.
    </div>
    <ng-template #changedClauseTypeMessage>
      <div>
        The selected clause has been changed from a requirement to a heading, which does not require links. Would you
        like to delete the existing links? This action cannot be undone.
      </div>
      <button
        mat-raised-button
        color="primary"
        (click)="clearAllLinks()"
        attr.aria-label="Clear all existing links for clause {{ clause | fragmentIndex }}"
      >
        Delete existing links
      </button>
    </ng-template>
  </ng-container>

  <ng-template #selectLinks>
    <ng-container *ngIf="!selectingLinksOfType">
      <div class="guidance-text">
        The MDD stipulates that a link shall be established between constructor requirements and related verification
        and documentation requirements for validation purposes.
      </div>

      <div class="link-required-selectors">
        <cars-clause-link-required-selector
          *ngFor="let clauseLinkType of clauseLinkTypeOptions"
          [clause]="clause"
          [clauseLinkType]="clauseLinkType"
          (startSelectingLinks)="setSelectingLinksState(clauseLinkType)"
        ></cars-clause-link-required-selector>
      </div>
    </ng-container>

    <ng-container *ngIf="!!selectingLinksOfType">
      <div class="guidance-text">
        Listed below are all {{ selectingLinksOfType | clauseLinkTypeDisplayName | lowercase }} Standard Format
        Requirements within the current section; please select all which provide
        {{ selectingLinksOfType | clauseLinkTypeDisplayName | lowercase }} requirements for the constructor requirement
        under review.
      </div>

      <cars-clause-link-selector
        [clause]="clause"
        [clauseLinkType]="selectingLinksOfType"
        (endSelectingLinks)="setSelectingLinksState(null)"
      ></cars-clause-link-selector>
    </ng-container>
  </ng-template>
</div>
