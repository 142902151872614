import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {FragmentService} from 'app/services/fragment.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {
  DocumentFragment,
  DocumentInformationType,
  Fragment,
  FragmentType,
  SectionFragment,
  SectionType,
} from '../fragment/types';
import {CarsAction} from '../permissions/types/permissions';
import {PrintService} from '../print/print.service';
import {DocumentService} from '../services/document.service';

@Component({
  selector: 'cars-wsr-subject-topic',
  templateUrl: './wsr-subject-topic.component.html',
  styleUrls: ['./wsr-subject-topic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WsrSubjectTopicComponent implements OnInit, OnDestroy {
  private static readonly WSR_CODE_REGEX: RegExp = /\/(\d{3})$/;
  private static readonly SHW_DOCUMENT_CODE_REGEX: RegExp = /^[A-Z]{2} \d{3,4}$/;

  public readonly CarsAction: typeof CarsAction = CarsAction;
  public readonly tooltipDelay: number = environment.tooltipDelay;

  public loading: boolean = true;
  public documentChildren: Fragment[];
  public document: DocumentFragment;

  public subjects: Record<string, string> = {};
  public topics: Record<string, string> = {};
  public wsrTopicNumbers: Record<string, number> = {};
  public wsrDocumentCodes: Record<string, string> = {};
  public validWsrMapping: Record<string, boolean> = {};

  private subscriptions: Subscription[] = [];

  constructor(
    private _router: Router,
    private _printService: PrintService,
    private _documentService: DocumentService,
    private _fragmentService: FragmentService,
    private _matDialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.document = this._documentService.getSelected();
    this.documentChildren = this.document.children.filter(
      (child) =>
        (child.is(FragmentType.SECTION) &&
          !(child as SectionFragment).deleted &&
          !(child as SectionFragment).isSectionOfType(SectionType.DOCUMENT_INFORMATION)) ||
        (child.is(FragmentType.SECTION_GROUP) && !(child as SectionGroupFragment).deleted)
    );

    this.documentChildren.forEach(this.updateSubjectandTopic.bind(this));
    this._printService.triggerPrintEvent();

    this.loading = false;
    this._cdr.markForCheck();

    this.subscriptions.push(
      this._fragmentService.onUpdate(
        (fragment: Fragment) => {
          this.updateSubjectandTopic(fragment.parent.is(FragmentType.SECTION_GROUP) ? fragment.parent : fragment);
          this._cdr.markForCheck();
        },
        (fragment: Fragment) => {
          return (
            fragment.is(FragmentType.SECTION, FragmentType.SECTION_GROUP) &&
            fragment.documentId.equals(this.document.id)
          );
        }
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.splice(0).forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * Update the subject and topic maps to reflect the values on the given fragment.
   * For sections with administrations passed in through websockets,
   * this will get the parent section group to use in the subjects/topics maps.
   *
   * @param fragment {Fragment}   The sections or sectionGroup that had been updated
   */
  private updateSubjectandTopic(documentChild: Fragment): void {
    const firstSection: SectionFragment = documentChild.is(FragmentType.SECTION)
      ? (documentChild as SectionFragment)
      : (documentChild.children[0] as SectionFragment);

    this.subjects[documentChild.id.value] = firstSection.subject ? firstSection.subject : '';
    this.topics[documentChild.id.value] = firstSection.topic ? firstSection.topic : '';

    const wsrCode: string = firstSection.wsrCode;
    this.wsrDocumentCodes[documentChild.id.value] = wsrCode;

    this.wsrTopicNumbers[documentChild.id.value] = this.getWsrTopicNumber(wsrCode);

    this.validateChild(documentChild);
  }

  /**
   * Helper function to get an array of sections in a child of a document.
   * Returns back an array with the section if the child is a SectionFragment
   * or an array of the NDS children if the child is a SectionGroupFragment
   *
   * @param documentChild {Fragment}   The child of the document
   */
  public getSectionsFromDocumentChild(documentChild: Fragment): SectionFragment[] {
    if (documentChild.is(FragmentType.SECTION)) {
      return [documentChild as SectionFragment];
    } else {
      return documentChild.children.map((sec) => sec as SectionFragment);
    }
  }

  /**
   * Helper function to validate if all children of the document have valid WSR mappings specified.
   */
  private allChildrenWsrMappingsValid(): boolean {
    this.documentChildren.forEach(this.validateChild.bind(this));

    return Object.values(this.validWsrMapping).every(Boolean);
  }

  /**
   * Validates if all WSR mappings for the given document child have either been filled or left blank.
   */
  public validateChild(documentChild: Fragment): void {
    const hasSubject: boolean = this.subjects[documentChild.id.value]?.length > 0;
    const hasTopic: boolean = this.topics[documentChild.id.value]?.length > 0;
    const hasWsrDocumentCode: boolean = this.wsrDocumentCodes[documentChild.id.value]?.length > 0;

    this.validWsrMapping[documentChild.id.value] =
      (hasSubject && hasTopic && hasWsrDocumentCode) || (!hasSubject && !hasTopic && !hasWsrDocumentCode);
  }

  /**
   * Updates the subject and topics properties for each section and section group when 'Save Changes' button is clicked.
   * This does nothing if no children have been updated or if any rows are incomplete.
   */
  public saveChanges(): void {
    if (!this.hasAnyChildBeenUpdated()) {
      this._snackbar.open('No changes have been made', 'Dismiss', {duration: 5000});
      return;
    }

    if (!this.allChildrenWsrMappingsValid()) {
      this._snackbar.open('Unable to save changes as not all required information has been provided', 'Dismiss');
      return;
    }

    this._matDialog
      .open(DialogComponent, {
        ariaLabel: 'Save changes to WSR subject and topic mappings',
        data: {
          title: 'Save changes to WSR subject and topic mappings',
          message:
            // eslint-disable-next-line max-len
            '<p>Are you sure you want to update the WSR mappings for this document?</p><p>WARNING: If this document has already been published this will cause problems!</p>',
          closeActions: [
            {title: 'Save', tooltip: 'Save changes to WSR mappings', response: 'save'},
            {title: 'Cancel', tooltip: 'Cancel changes to WSR mappings', response: null},
          ],
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response === 'save') {
          this.loading = true;
          const sectionsToUpdate: SectionFragment[] = [];
          this.documentChildren.filter(this.hasDocumentChildBeenUpdated.bind(this)).forEach((child) => {
            this.getSectionsFromDocumentChild(child).forEach((section) => {
              section.subject = this.subjects[child.id.value];
              section.topic = this.topics[child.id.value];
              section.wsrCode = this.wsrDocumentCodes[child.id.value];
              sectionsToUpdate.push(section);
            });
          });

          return this._fragmentService.update(sectionsToUpdate).then(() => {
            this.loading = false;
            this._cdr.markForCheck();
          });
        }
      });
  }

  /**
   * Helper method to check if any child of the document has been updated.
   */
  public hasAnyChildBeenUpdated(): boolean {
    return this.documentChildren?.length > 0 && this.documentChildren.some(this.hasDocumentChildBeenUpdated.bind(this));
  }

  /**
   * Checks if any WSR mapping properties of the given fragment have been updated and therefore need saving.
   */
  private hasDocumentChildBeenUpdated(documentChild: Fragment): boolean {
    const firstSection: SectionFragment = documentChild.is(FragmentType.SECTION)
      ? (documentChild as SectionFragment)
      : (documentChild.children[0] as SectionFragment);
    return (
      !this.stringsEqual(firstSection.subject, this.subjects[documentChild.id.value]) ||
      !this.stringsEqual(firstSection.topic, this.topics[documentChild.id.value]) ||
      !this.stringsEqual(firstSection.wsrCode, this.wsrDocumentCodes[documentChild.id.value]) ||
      this.getWsrTopicNumber(firstSection.wsrCode) !== this.wsrTopicNumbers[documentChild.id.value]
    );
  }

  /**
   * Helper method to check if two strings are equal, treating any null or empty string as the same.
   */
  private stringsEqual(a: string, b: string): boolean {
    return (!a?.length && !b?.length) || a === b;
  }

  /**
   * Helper function to extract to topic number from the WSR document code
   *
   * @param wsrCode
   */
  private getWsrTopicNumber(wsrCode: string): number {
    return wsrCode?.length > 0 && WsrSubjectTopicComponent.WSR_CODE_REGEX.test(wsrCode)
      ? Number(WsrSubjectTopicComponent.WSR_CODE_REGEX.exec(wsrCode)[1])
      : null;
  }

  /**
   * Handles a change to the topic number for the given fragment. This triggers the WSR document code to be regenerated
   * and validation to be rerun.
   */
  public handleWsrTopicNumberChange(documentChild: Fragment): void {
    const shwDocumentCode = this.getShwDocumentCode();
    const topicNumber: number = this.wsrTopicNumbers[documentChild.id.value];
    this.wsrDocumentCodes[documentChild.id.value] =
      !!shwDocumentCode && this.isTopicNumberValid(topicNumber)
        ? `${shwDocumentCode}/WSR/${topicNumber.toString().padStart(3, '0')}`
        : '';

    this.validateChild(documentChild);
  }

  /**
   * Extracts and returns the document code number from the document information field.
   */
  private getShwDocumentCode(): string {
    const shwDocumentCode: string = this.document.getInformation(DocumentInformationType.SHW_DOCUMENT_CODE)?.value;

    return !!shwDocumentCode && WsrSubjectTopicComponent.SHW_DOCUMENT_CODE_REGEX.test(shwDocumentCode)
      ? WsrSubjectTopicComponent.SHW_DOCUMENT_CODE_REGEX.exec(shwDocumentCode)[0]
      : null;
  }

  /**
   * Helper method to check whether
   */
  private isTopicNumberValid(topicNumber: number): boolean {
    return !!topicNumber && topicNumber >= 1 && topicNumber <= 999 && Number.isInteger(topicNumber);
  }

  /**
   * Checks whether the WSR mapping is available to be specified for the given fragment. Note this does not check
   * whether sections are deleted or information sections.
   */
  public isWsrMappingApplicable(documentChild: Fragment): boolean {
    return documentChild.is(FragmentType.SECTION_GROUP) || documentChild.isSectionOfType(SectionType.NORMATIVE);
  }

  public closeRoute(): void {
    this._router.navigateByUrl(this._router.url.split('/').slice(0, -1).join('/'));
  }

  public printRoute(): void {
    this._router.navigateByUrl(this._router.url.split('/').slice(0, -1).join('/') + '/print' + '/wsr-subject-topic');
  }

  /**
   * Listens for the beforeunload event and presents a dialog if user has unsaved changes.
   *
   * @returns {boolean} True if user doesn't have any pending changes
   */
  @HostListener('window:beforeunload')
  public canDeactivate(): boolean {
    return !this.hasAnyChildBeenUpdated();
  }
}
