import {UUID} from 'app/utils/uuid';
import {Fragment, FragmentType} from '../types';
import {AssociatedDocumentType} from './associated-document-information-type';

export class AssociatedDocumentInformationFragment extends Fragment {
  public associatedDocumentType: AssociatedDocumentType;
  public title: string;
  public reference: string;
  public hasNationalVariations: boolean;

  /**
   * Static factory to create an empty Assocaited Document Information Fragment.
   *
   * @param clauseType {ClauseType}       The type of clause
   * @returns          {ClauseFragment}   The new clause
   */
  public static empty(): AssociatedDocumentInformationFragment {
    return new AssociatedDocumentInformationFragment(
      UUID.random(),
      AssociatedDocumentType.CONSTRUCTOR_PROFORMA,
      '',
      '',
      false
    );
  }

  constructor(
    id: UUID,
    associatedDocumentType: AssociatedDocumentType,
    title: string,
    reference: string,
    hasNationalVariations: boolean
  ) {
    super(id, FragmentType.ASSOCIATED_DOCUMENT_INFORMATION, []);
    this.associatedDocumentType = associatedDocumentType;
    this.title = title;
    this.reference = reference;
    this.hasNationalVariations = hasNationalVariations;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): AssociatedDocumentInformationFragment {
    return null;
  }
}
