import {Pipe, PipeTransform} from '@angular/core';
import {CapacityOfComment} from 'app/sidebar/discussions/discussions';

export const CAPACITY_OF_COMMENT_TO_DISPLAY_NAME: Readonly<Record<CapacityOfComment, string>> = {
  [CapacityOfComment.TAGG_CONTENT_SPECIALIST]: 'TAGG content specialist',
  [CapacityOfComment.TECHNICAL_CONSULTEES]: 'Technical consultees',
  [CapacityOfComment.COMMERCIAL]: 'Commercial',
  [CapacityOfComment.MAJOR_PROJECTS_CIP]: 'Major Projects CIP',
  [CapacityOfComment.MAJOR_PROJECTS_RIP]: 'Major Projects RIP',
  [CapacityOfComment.MAJOR_PROJECTS_SMP]: 'Major Projects SMP',
  [CapacityOfComment.MAJOR_PROJECTS_OTHERS]: 'Major Projects others',
  [CapacityOfComment.OPERATIONS]: 'Operations',
  [CapacityOfComment.PROCUREMENT]: 'Procurement',
  [CapacityOfComment.CUSTOMER_SATISFACTION]: 'Customer Satisfaction',
  [CapacityOfComment.HEALTH_AND_SAFETY]: 'Health and Safety',
  [CapacityOfComment.EQUALITY_DIVERSITY_AND_INCLUSION]: 'Equality, diversity and inclusion',
  [CapacityOfComment.CARBON_MANAGEMENT_SUSTAINABLE_DEVELOPMENT_AND_GOOD_DESIGN]:
    'Carbon management, sustainable development and good design',
  [CapacityOfComment.INTELLIGENT_TRANSPORT_SYSTEMS]: 'Intelligent Transport Systems',
};

@Pipe({
  name: 'capacityOfCommentToDisplayName',
})
export class CapacityOfCommentToDisplayNamePipe implements PipeTransform {
  private static readonly UNKNOWN_CAPACITY_OF_COMMENT: string = 'Unknown capacity of comment';

  /**
   * Transforms the capacity of comment enum into a friendly display name.
   * The {@link CAPACITY_OF_COMMENT_TO_DISPLAY_NAME} const is exported for consumers wanting to use this within typescript files.
   *
   * @param capacityOfComment {CapacityOfComment} The capacity of comment
   * @returns                 {string}            The display name
   */
  public transform(capacityOfComment: CapacityOfComment): string {
    return (
      CAPACITY_OF_COMMENT_TO_DISPLAY_NAME[capacityOfComment] ??
      CapacityOfCommentToDisplayNamePipe.UNKNOWN_CAPACITY_OF_COMMENT
    );
  }
}
