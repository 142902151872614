import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {UploadProperties} from 'app/fragment/figure/upload-properties';
import {ClauseFragment, FigureFragment, Fragment, VirusScanState} from 'app/fragment/types';
import {ClauseService} from 'app/services/clause.service';
import {FragmentService} from 'app/services/fragment.service';
import {RichTextService, RichTextType} from 'app/services/rich-text.service';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {BaseService} from './base.service';
import {FragmentDeletionValidationService} from './fragment-deletion-validation.service';

interface MaxUploadDimensionsConfig {
  widthMm: number;
  heightMm: number;
  widthPx: number;
}

export interface UploadConfig {
  maxUploadSize: number;
  allowedExtensions: string[];
  maxPortraitDimensions: MaxUploadDimensionsConfig;
  maxLandscapeDimensions: MaxUploadDimensionsConfig;
}

@Injectable({
  providedIn: 'root',
})
export class ImageService extends BaseService {
  private static readonly ONE_HUNDRED_PERCENT: string = '100%';

  /**
   * Properties defining the maximum width of portrait and landscape tables within the pad. Note these are measured
   * as the maximum size these images can scale to (ie tab as wide as possible), and are rounded up to avoid being
   * fractionally narrower (max size is approx 623.23 and 1001.23)
   */
  private static readonly WEBAPP_MAX_PAD_WIDTH_PX_PORTRAIT: number = 624;
  private static readonly WEBAPP_MAX_PAD_WIDTH_PX_LANDSCAPE: number = 1002;

  // map of Promises on the blob urls of images, keyed on the image id.
  private _cachedImageUrls: Record<string, Promise<SafeUrl>> = {};

  private _uploadConfig: UploadConfig;
  private _uploadConfigSubject: Subject<UploadConfig> = new BehaviorSubject(null);

  constructor(
    private _clauseService: ClauseService,
    private _richTextService: RichTextService,
    private _fragmentService: FragmentService,
    private _fragmentDeletionValidationService: FragmentDeletionValidationService,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _sanitizer: DomSanitizer,
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);

    this._http.get<UploadConfig>(`${environment.apiHost}/config`).subscribe((config: UploadConfig) => {
      config.maxPortraitDimensions.widthPx = ImageService.WEBAPP_MAX_PAD_WIDTH_PX_PORTRAIT;
      config.maxLandscapeDimensions.widthPx = ImageService.WEBAPP_MAX_PAD_WIDTH_PX_LANDSCAPE;
      this._uploadConfig = config;
      this._uploadConfigSubject.next(this._uploadConfig);
    });
  }

  /**
   * Delete the figure passed to this function, does not delete the upload.
   *
   * @param figure    {FigureFragment}    The selected figure
   */
  public async delete(figure: FigureFragment): Promise<void> {
    if (await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithSubtrees(figure)) {
      this._fragmentService.deleteValidatedFragments(figure);
    }
  }

  /**
   * Gets the file to upload from the event, and triggers the rich text event to create the figure
   * in the pad. THe upload will be uploaded after the figure has been created.
   *
   * @param event             {Event}             The change event when inserting a file
   * @param clause            {ClauseFragment}    The current clause
   * @param figureParent      {Fragment}          The parent of the figure fragment
   * @param oldFigureIndex    {number?}           The index of the figure to be replaced in its parent's child array
   * @returns                 {boolean}           True if figure creation is successful
   */
  public handleFigureCreation(
    event: Event,
    clause: ClauseFragment,
    figureParent: Fragment = null,
    oldFigureIndex: number = null
  ): boolean {
    if (!clause) {
      this._errorDialog('No clause selected', 'Attempt to upload with no clause').subscribe();
      return false;
    }

    this._clauseService.setSelected(clause);

    const file: File = this.validateImage(event);

    if (!file) {
      return false;
    }

    this._richTextService.next(
      RichTextType.FIGURE,
      new FigureFragment(UUID.random(), null),
      figureParent,
      oldFigureIndex,
      file
    );

    event.target['value'] = '';

    return true;
  }

  public validateImage(event: Event): File {
    const fileList: FileList = event.target['files'];
    if (!fileList?.length) {
      return null;
    }

    const file: File = event.target['files'][0];
    const extension: string = file.name.split('.').pop();

    if (!extension || this._uploadConfig.allowedExtensions.indexOf(extension.toLowerCase()) < 0) {
      this._errorDialog(
        'Unsupported file type',
        'Supported files types: ' + this._uploadConfig.allowedExtensions.join(', ')
      ).subscribe(() => {
        event.target['value'] = '';
      });
      return null;
    }

    if (file.size > this._uploadConfig.maxUploadSize) {
      this._errorDialog(
        'Upload too large',
        'Maximum upload size: ' + this._uploadConfig.maxUploadSize / 1024 / 1024 + ' Mb'
      ).subscribe(() => {
        event.target['value'] = '';
      });
      return null;
    }
    return file;
  }

  /**
   * Uploads the given image file to the upload endpoint where it is scanned and the figure
   * fragment with the given id is then updated to point at the correct upload.
   */
  public uploadImage(file: File, figureId: UUID): void {
    if (figureId) {
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      const params = new HttpParams().append('figureId', figureId.value);
      const headers: HttpHeaders = new HttpHeaders().append('Accept', 'application/json');

      this._http.post(`${environment.apiHost}/upload`, formData, {headers, params}).subscribe(
        (json: any) => {
          if (!json.successful) {
            this._errorDialog('Failed Upload', json.responseMessage).subscribe();
          }
        },
        (error: HttpErrorResponse) => {
          Logger.error('image-error', 'failed to upload image', error);
          this._errorDialog('Failed Upload', error.error).subscribe();
          const figure: FigureFragment = this._fragmentService.find(figureId) as FigureFragment;
          figure.virusScanState = VirusScanState.SCAN_FAILED;
          this._fragmentService.update(figure);
        }
      );
    } else {
      throw new Error('FigureId cannot be null');
    }
  }

  public reUploadImage(uploadId: UUID): Promise<UUID> {
    return this._http
      .get(`${environment.apiHost}/re-upload`, {params: {uploadId: uploadId.value}})
      .pipe(map((response: any) => UUID.orNull(response)))
      .toPromise()
      .then(
        (value: UUID) => value,
        (reason: any) => {
          Logger.error('image-error', 'failed to upload image', reason);
          this._handleError(reason, `Failed to re-upload image, ${uploadId.value}.`, 'image-error');
          return Promise.reject(reason);
        }
      );
  }

  private _errorDialog(title: string, body: string): Observable<any> {
    const ref = this._dialog.open(DialogComponent, {
      ariaLabel: 'Error dialog',
    });
    ref.componentInstance.title = title;
    ref.componentInstance.message = body;
    return ref.afterClosed();
  }

  /**
   * Load specified image and return {Promise} on the loaded image's URL.
   * Note we cache the image URL Promises keyed on image Id,
   *      and return the cached Promise if the image Id is in the cache.
   *
   * @param imageId   {UUID}      of the image to load
   * @returns         {Promise}   on the (sanitised) URL of the loaded image
   */
  public loadImage(imageId: UUID): Promise<SafeUrl> {
    let urlPromise: Promise<SafeUrl> = this._cachedImageUrls[imageId.value];
    if (!urlPromise) {
      const queryParams: {[param: string]: string} = {upload: imageId.value};
      urlPromise = this._http
        .get(`${environment.apiHost}/images`, {responseType: 'blob', params: queryParams})
        .toPromise()
        .then((response: Blob) => {
          return this._sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(response));
        })
        .catch((error: any) => {
          this._handleError(error, `Failed to fetch image, ${imageId.value}.`, 'image-error');
          return Promise.reject(error);
        });
      this._cachedImageUrls[imageId.value] = urlPromise;
    }
    return urlPromise;
  }

  /**
   * Return Promise which resolves when all images loaded.
   * Note this does not update if a new image starts loading after this call.
   *
   * @returns {Promise<boolean>} resolves as true when all images loaded.
   */
  public allImagesLoaded(): Promise<boolean> {
    return Promise.all(Object.values(this._cachedImageUrls)).then(() => true);
  }

  /**
   * Calculate the width to display the given figure within HTML exports, including the display units of px or %.
   * This uses the upload properties if present to convert the figure dimensions to a maximum scale that will fit in
   * the known max portrait mm widths, or the native scale for small images, then converts that scale to the number of
   * pixels using the known page pixel width. Note the landscape/portrait dimensions are provided from the config in
   * UploadService::getMaxDimensions.
   * If the UploadProperties aren't valid then returns 100% to fill the available space.
   */
  public getWidthDisplay(figure: FigureFragment): Observable<string> {
    if (!this._isUploadPropertiesValid(figure.uploadProperties)) {
      return of(ImageService.ONE_HUNDRED_PERCENT);
    }

    return this._uploadConfigSubject.pipe(
      first((_value: UploadConfig) => !!_value),
      map(() => {
        const maxDimensions: MaxUploadDimensionsConfig = figure.landscape
          ? this._uploadConfig.maxLandscapeDimensions
          : this._uploadConfig.maxPortraitDimensions;

        const widthScaleFactor: number = maxDimensions.widthMm / figure.uploadProperties.widthInMillimetres;
        const heightScaleFactor: number = maxDimensions.heightMm / figure.uploadProperties.heightInMillimetres;

        const scaleFactor: number = Math.min(1, widthScaleFactor, heightScaleFactor);
        const scaledWidth: number = (scaleFactor * figure.uploadProperties.widthInMillimetres) / maxDimensions.widthMm;

        return `${(scaledWidth * maxDimensions.widthPx).toFixed(1)}px`;
      })
    );
  }

  private _isUploadPropertiesValid(uploadProperties: UploadProperties): boolean {
    return (
      uploadProperties &&
      uploadProperties.heightDpi > -1 &&
      uploadProperties.widthDpi > -1 &&
      uploadProperties.heightInMillimetres > -1 &&
      uploadProperties.widthInMillimetres > -1
    );
  }
}
