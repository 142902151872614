import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {VersionTagTypeToDisplayNameModule} from 'app/utils/pipes/version-tag-type-to-display-name.module';
import {VersionInformationComponent} from './version-information.component';

@NgModule({
  imports: [CommonModule, MatIconModule, VersionTagTypeToDisplayNameModule],
  declarations: [VersionInformationComponent],
  exports: [VersionInformationComponent],
})
export class VersionInformationModule {}
