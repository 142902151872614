import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {SectionListMenuComponent} from './section-list-menu.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatMenuModule,
    MatTooltipModule,
    CarsErrorHandlingModule,
  ],
  declarations: [SectionListMenuComponent],
  exports: [SectionListMenuComponent],
})
export class SectionListMenuModule {}
