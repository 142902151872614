import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {CaptionedFragment, Fragment, FragmentType} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {DiscussionsService} from 'app/services/discussions.service';
import {FragmentService} from 'app/services/fragment.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {SidebarService} from 'app/services/sidebar.service';
import {Discussion} from 'app/sidebar/discussions/discussions';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-discussions-icon',
  templateUrl: './discussions-icon.component.html',
  styleUrls: ['./discussions-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscussionsIconComponent implements OnInit, OnDestroy {
  @Input() public fragment: Fragment;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public unresolvedDiscussions: number = 0;
  public resolvedDiscussions: number = 0;

  private _reordering: boolean;

  private _subscriptions: Subscription[] = [];
  private _discussionSubscriptions: Subscription[] = [];

  constructor(
    private _discussionsService: DiscussionsService,
    private _fragmentService: FragmentService,
    private _sidebarService: SidebarService,
    private _reorderClausesService: ReorderClausesService,
    private _viewService: ViewService,
    private _selectionOperationsService: SelectionOperationsService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._reorderClausesService.onReorderingEvent().subscribe((status: boolean) => (this._reordering = status)),
      this._viewService.onCurrentViewChange(this._updateDiscussionSubscriptions.bind(this))
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
    this._discussionSubscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public openSidebar() {
    if (this._reordering) {
      return;
    }

    if (this.fragment) {
      if (this.fragment.is(FragmentType.SECTION)) {
        this._fragmentService.setSelected(this.fragment);
      } else {
        const fragmentToSelect: Fragment = this.fragment.isCaptioned()
          ? (this.fragment as CaptionedFragment).caption
          : this.fragment.children[0];

        this._selectionOperationsService.setSelected(fragmentToSelect, 0, PadType.MAIN_EDITABLE);
      }
    }

    this._sidebarService.setSidebarStatus(SidebarStatus.DISCUSSIONS);
  }

  private _updateDiscussionSubscriptions(currentView: CurrentView): void {
    if (currentView.showDiscussionsMarginIcon()) {
      this._discussionsService.getDiscussions(this.fragment);

      this._discussionSubscriptions.push(
        this._discussionsService.onChange(
          this.fragment,
          (discussions: Discussion[]) => {
            this.unresolvedDiscussions = discussions.filter((d: Discussion) => !d.resolved).length;
            this.resolvedDiscussions = discussions.length - this.unresolvedDiscussions;
            this._cdr.markForCheck();
          },
          this.fragment.is(FragmentType.TABLE)
        )
      );
    } else {
      this._discussionSubscriptions.splice(0).forEach((s) => s.unsubscribe());
    }
  }
}
