import {HttpResponse} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {
  ClauseFragment,
  DocumentFragment,
  Fragment,
  FragmentType,
  InternalReferenceType,
  SectionFragment,
} from 'app/fragment/types';
import {ReferenceInputFragment} from 'app/fragment/types/input/reference-input-fragment';
import {RequiredReferenceCount} from 'app/fragment/types/input/required-reference-count';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentsSearchType} from 'app/search/document-selector/documents-search-types';
import {SearchableDocument} from 'app/search/search-documents.service';
import {FragmentService} from 'app/services/fragment.service';
import {InternalReferenceService} from 'app/services/internal-reference.service';
import {ReferenceSortingUtils} from 'app/services/references/reference-utils/reference-sorting-utils';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

/**
 * NOTE: The naming of methods/properties in this file differ from the user facing names for clarity when reading the
 * code. In the code selected refers to the document and associated available references, whilst 'staged' refers to
 * particular references that have been selected by the user - this is to avoid having selectedDocument and
 * selectedDocumentReferences. In contrast, a user will be prompted to select a document and select/deselect individual
 * section references.
 */
@Component({
  selector: 'cars-section-references',
  templateUrl: './section-references.component.html',
  styleUrls: ['./section-references.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionReferencesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public clause: ClauseFragment;

  public readonly DocumentsSearchType: typeof DocumentsSearchType = DocumentsSearchType;
  public readonly CarsAction: typeof CarsAction = CarsAction;
  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly searchPlaceholder: string = 'Select a document';
  public readonly displayedColumns: string[] = ['section'];

  public loading: boolean = false;
  public selectedDocumentId: UUID;
  public selectedDocument: DocumentFragment;
  public selectedDocumentInternalReferences: InternalDocumentReferenceFragment[];
  public stagedSectionReferences: InternalDocumentReferenceFragment[] = [];
  public selectedDocumentTechnicalAuthor: string;
  public currentSectionStaged: boolean = false;

  private _referenceInput: ReferenceInputFragment;
  private _existingInlineReferences: InternalInlineReferenceFragment[] = [];
  private _existingDocumentReferences: InternalDocumentReferenceFragment[] = [];
  private _currentDocumentInternalReferences: InternalDocumentReferenceFragment[];
  private _initialCheckboxStatus: boolean;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _internalReferencesService: InternalReferenceService,
    private _fragmentService: FragmentService,
    private _snackBar: MatSnackBar,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._fragmentService.onCreate(
        () => this._refreshExistingReferencesList(),
        (frag) =>
          frag.parentId.equals(this._referenceInput?.id) ||
          this._existingInlineReferences.some((inline: InternalInlineReferenceFragment) =>
            inline.internalDocumentReferenceId.equals(frag.id)
          )
      ),
      this._fragmentService.onDelete(
        () => this._refreshExistingReferencesList(),
        (frag: Fragment) => frag.parentId.equals(this._referenceInput?.id)
      )
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('clause')) {
      if (
        !changes.clause.previousValue ||
        !changes.clause.currentValue ||
        !changes.clause.previousValue.id.equals(changes.clause.currentValue.id)
      ) {
        this.selectedDocumentId = null;
        this.selectedDocument = null;

        this._referenceInput = this.clause?.children.find(
          (fragment: Fragment) =>
            fragment.is(FragmentType.REFERENCE_INPUT) &&
            this.isSectionOrWsrReference(fragment as ReferenceInputFragment)
        ) as ReferenceInputFragment;

        if (!!this._referenceInput) {
          this._refreshExistingReferencesList();
          this.resetStagedChanges();
        }
        this.currentSectionStaged = this.currentSectionAlreadyStaged();
        this._initialCheckboxStatus = this.currentSectionStaged;
        this._cdr.markForCheck();
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Refreshes the list of existing references based on the ReferenceInputFragment children. If the document reference
   * for any inline reference cannot be found, then replaces it with a dummy entry for display.
   */
  private _refreshExistingReferencesList(): void {
    if (this._referenceInput) {
      this._existingInlineReferences = this._referenceInput.children.map(
        (f: Fragment) => f as InternalInlineReferenceFragment
      );
      this._existingDocumentReferences = this._existingInlineReferences.map(
        (inline: InternalInlineReferenceFragment): InternalDocumentReferenceFragment => {
          const internalDocumentReference: InternalDocumentReferenceFragment = this._fragmentService.find(
            inline.internalDocumentReferenceId
          ) as InternalDocumentReferenceFragment;

          return !!internalDocumentReference
            ? internalDocumentReference
            : new InternalDocumentReferenceFragment(
                inline.internalDocumentReferenceId,
                null,
                null,
                null,
                null,
                UUID.random(), // Add random targetDocumentId to avoid null pointer issues elsewhere.
                UUID.random(), // Add random targetSectionId to avoid null pointer issues elsewhere.
                null,
                null,
                null,
                'Failed to load reference',
                null,
                null,
                true, // Mark as deleted in sidebar to show issue.
                null,
                null,
                null,
                null,
                null,
                null,
                null
              );
        }
      );
      this._existingDocumentReferences = ReferenceSortingUtils.getSortedReferences(this._existingDocumentReferences);
    }
  }

  /**
   * Returns true if the clause is not within a section reference SFR.
   */
  public showSectionReferenceSelection(): boolean {
    return !!this._referenceInput?.internalReferenceType;
  }

  /**
   * Determine if the current clause contains a WSR Reference input fragment
   */
  public containsWsrReference(): boolean {
    return this._referenceInput?.internalReferenceType === InternalReferenceType.WSR_REFERENCE;
  }

  private isSectionOrWsrReference(referenceInput: ReferenceInputFragment) {
    return (
      referenceInput.internalReferenceType === InternalReferenceType.WSR_REFERENCE ||
      referenceInput.internalReferenceType === InternalReferenceType.SECTION_REFERENCE
    );
  }

  /**
   * Fetches the internal document references of the selected document that are available to create.
   * @param selectedDocumentId {UUID} The UUID of the document that has been selected.
   */
  public getAvailableInternalReferencesFromSelectedDocument(selectedDocument: SearchableDocument): void {
    if (!selectedDocument?.DOCUMENT_ID || !(selectedDocument.DOCUMENT_ID instanceof UUID)) {
      this._onLoadFailure('Section references: invalid selected document.');
      return;
    }
    this.loading = true;
    this.selectedDocumentId = selectedDocument.DOCUMENT_ID;
    this.selectedDocumentTechnicalAuthor = selectedDocument.DOCUMENT_OWNER_NAME || 'Unknown User';
    this._internalReferencesService
      .getReferencesToCreate(
        this.selectedDocumentId,
        this.clause.documentId,
        this._referenceInput?.internalReferenceType
      )
      .then((references: InternalDocumentReferenceFragment[]) => {
        this.selectedDocumentInternalReferences = references;
        this.loading = false;
        this._cdr.markForCheck();
      })
      .catch((err) => this._onLoadFailure('Section references: invalid selected document.', err));
  }

  /**
   * Adds the given reference to the list of staged references.
   */
  public stageReferenceForCreation(reference: InternalDocumentReferenceFragment): void {
    this.stagedSectionReferences = ReferenceSortingUtils.getSortedReferences(
      this.stagedSectionReferences.concat(reference)
    );
  }

  /**
   * Removes the given reference from the list of staged references.
   */
  public unstageReferenceFromCreation(reference: InternalDocumentReferenceFragment): void {
    this.stagedSectionReferences = this.stagedSectionReferences.filter((ref: InternalDocumentReferenceFragment) => {
      return !ref.id.equals(reference.id);
    });
    if (this.clause.sectionId.equals(reference.targetSectionId)) {
      this.currentSectionStaged = false;
    }
  }

  /**
   * Returns true if the given reference can be added to the list of staged references.
   */
  public referenceCanBeStaged(reference: InternalDocumentReferenceFragment): boolean {
    return (
      !(this._referenceIsStaged(reference) || this.clause.sectionId.equals(reference.targetSectionId)) &&
      this.stagingAreaCanAcceptReferences()
    );
  }

  /**
   * Returns true if a reference can be added to the staging area, prevents staging multiple reference in a single
   * reference input.
   */
  public stagingAreaCanAcceptReferences(): boolean {
    return !(
      this._referenceInput?.requiredReferenceCount === RequiredReferenceCount.ONE &&
      this.stagedSectionReferences.length === 1
    );
  }

  /**
   * Returns true if the given reference has already been added to the staging area.
   */
  private _referenceIsStaged(reference: InternalDocumentReferenceFragment): boolean {
    return !!this.stagedSectionReferences.find((ref: InternalDocumentReferenceFragment) =>
      ref.targetSectionId.equals(reference.targetSectionId)
    );
  }

  /**
   * Returns a boolean whether there are any staged changes from the existing selection. Returns true when either the
   * number of references has changed, or when any of the existing reference target section ids is not found in the
   * staged list.
   */
  public hasStagedChanges(): boolean {
    return (
      this._existingDocumentReferences.length !== this.stagedSectionReferences.length ||
      this._existingDocumentReferences.some(
        (existingRef) =>
          !this.stagedSectionReferences.find((stagedRef) =>
            stagedRef.targetSectionId.equals(existingRef.targetSectionId)
          )
      )
    );
  }

  /**
   * Resets the staged selection of references to the existing references. Uses Object.assign to avoid changing the
   * stored existing references list.
   */
  public resetStagedChanges(): void {
    this.stagedSectionReferences = Object.assign([], this._existingDocumentReferences);
    this.currentSectionStaged = this._initialCheckboxStatus;
  }

  /**
   * Saves the changes that have been staged to the database. The fragments are all created within the frontend to
   * avoid having to wait for the http requests to resolve before the new references show in the pad.
   */
  public saveChanges(): void {
    this.loading = true;

    Promise.all([this._createNewReferences(), this._deleteReferences()])
      .then(() => {
        this.loading = false;
        this._cdr.markForCheck();
      })
      .catch(() => {
        this.loading = false;
        this._cdr.markForCheck();
      });
  }

  /**
   * Creates the new fragments for the selected references, creates both inline section reference and the internal
   * document reference fragments as required. Checks if the target section has already been referenced, and uses the
   * existing fragment if it has.
   */
  private _createNewReferences(): Promise<HttpResponse<any>> {
    let newDocumentReferences: InternalDocumentReferenceFragment[] = this.stagedSectionReferences.filter(
      (ref: InternalDocumentReferenceFragment) =>
        !this._existingDocumentReferences.some((stagedRef) => stagedRef.targetSectionId.equals(ref.targetSectionId))
    );

    if (newDocumentReferences.length === 0) {
      return Promise.resolve(null);
    }

    const document: DocumentFragment = this.clause?.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment;
    const normRefSectionClause: ClauseFragment = document.getNormReferenceSection().children[0] as ClauseFragment;

    // Replace any section references with the existing document reference if one exists
    newDocumentReferences = newDocumentReferences.map(
      (documentRef: InternalDocumentReferenceFragment) =>
        (normRefSectionClause.children.find(
          (docRef) =>
            docRef.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE) &&
            (docRef as InternalDocumentReferenceFragment).targetSectionId.equals(documentRef.targetSectionId) &&
            (docRef as InternalDocumentReferenceFragment).internalReferenceType === documentRef.internalReferenceType
        ) as InternalDocumentReferenceFragment) || documentRef
    );

    // Filter out existing document references from being created - only created fragments have validFrom set
    const documentReferencesToCreate: InternalDocumentReferenceFragment[] = newDocumentReferences.filter(
      (frag) => !frag.validFrom
    );

    // Set the information that is not provided by the backend response
    documentReferencesToCreate.forEach((documentRef) => {
      documentRef.documentId = normRefSectionClause.documentId;
      documentRef.sectionId = normRefSectionClause.sectionId;
      documentRef.parentId = normRefSectionClause.id;
      normRefSectionClause.children.push(documentRef);
      documentRef.inferWeight();
    });

    // Create inline references for each document reference
    const inlineReferencesToCreate: InternalInlineReferenceFragment[] = newDocumentReferences.map((documentRef) => {
      const inlineRef: InternalInlineReferenceFragment = new InternalInlineReferenceFragment(
        null,
        documentRef.id,
        false
      );
      inlineRef.documentId = this._referenceInput.documentId;
      inlineRef.sectionId = this._referenceInput.sectionId;
      inlineRef.parentId = this._referenceInput.id;
      this._referenceInput.children.push(inlineRef);
      inlineRef.inferWeight();
      return inlineRef;
    });

    const fragsToCreate: Fragment[] = [...documentReferencesToCreate, ...inlineReferencesToCreate];
    return this._fragmentService.create(fragsToCreate);
  }

  /**
   * Deletes the inline references that previously existed but have been unstaged. Only deletes the inline references
   * as internal document references will be handled by a service fragment event handler.
   */
  private _deleteReferences(): Promise<HttpResponse<any>> {
    const deletedDocumentReferences: InternalDocumentReferenceFragment[] = this._existingDocumentReferences.filter(
      (ref: InternalDocumentReferenceFragment) =>
        !this.stagedSectionReferences.some((stagedRef) => stagedRef.targetSectionId.equals(ref.targetSectionId))
    );
    const deletedInlineReferences: InternalInlineReferenceFragment[] = deletedDocumentReferences.map(
      (internalDocRef: InternalDocumentReferenceFragment) =>
        this._existingInlineReferences.find((inlineRef: InternalInlineReferenceFragment) =>
          inlineRef.internalDocumentReferenceId.equals(internalDocRef.id)
        )
    );

    if (deletedInlineReferences.length > 0) {
      // Always inline reference, no need to validate
      return this._fragmentService.delete(deletedInlineReferences);
    } else {
      return Promise.resolve(null);
    }
  }

  private _onLoadFailure(message: string, err?: any): void {
    this.loading = false;
    Logger.error('internal-reference-error', message, err);
    this._snackBar.open(message, 'Dismiss', {duration: 5000});
  }

  /**
   * Fetches the wsr mapping data for the current document
   */
  public selectWsrOfCurrentSection(): void {
    if (this.stagingAreaCanAcceptReferences()) {
      this.loading = true;
      this._internalReferencesService
        .getReferencesToCreate(
          this.clause.documentId,
          this.clause.documentId,
          this._referenceInput?.internalReferenceType
        )
        .then((references: InternalDocumentReferenceFragment[]) => {
          this._currentDocumentInternalReferences = references;
          this.loading = false;
          this._stageCurrentSection();
          this._cdr.markForCheck();
        });
    } else if (this.currentSectionStaged) {
      const section: SectionFragment = this.clause?.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
      this.stagedSectionReferences = this.stagedSectionReferences.filter((ref) => {
        return !ref.targetSectionId.equals(section.id);
      });
    }
  }

  /**
   * Finds the current section's subject topic mapping data and push
   * it to the staged sections list
   */
  private _stageCurrentSection(): void {
    const section: SectionFragment = this.clause?.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
    const reference = this._currentDocumentInternalReferences?.find((ref) => ref.targetSectionId.equals(section.id));

    if (reference) {
      this.stageReferenceForCreation(reference);
    } else {
      this._snackBar.open(
        'Failed to create WSR reference for the current section. See the help pages for guidance on this functionality.',
        'Dismiss',
        {duration: 5000}
      );
      this.currentSectionStaged = false;
    }
  }

  /**
   * determines if the current section is already staged
   */
  public currentSectionAlreadyStaged(): boolean {
    const section: SectionFragment = this.clause?.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
    return this.stagedSectionReferences.some((ref) => {
      return ref.targetSectionId.equals(section?.id);
    });
  }
}
