import {UUID} from 'app/utils/uuid';

export class DiscussionSummary {
  public documentId: UUID;
  public versionId: UUID;
  public sectionId: UUID;
  public fragmentId: UUID;
  public versionName: string;
  public sectionTitle: string;
  public fragmentIndex: string;
  public discussionText: string;
  public raisedBy: UUID;
  public raisedByName: string;
  public numberOfComments: number;
  public latestCommentContent: string;
  public latestCommentCreatedAt: number;
  public lastCommentRaisedBy: UUID;
  public lastCommentRaisedByName: string;
  public resolved: boolean;
  public createdAt: number;

  public static deserialise(json: any): DiscussionSummary {
    const summary: DiscussionSummary = new DiscussionSummary();
    summary.documentId = UUID.orNull(json.documentId);
    summary.versionId = UUID.orNull(json.versionId);
    summary.sectionId = UUID.orNull(json.sectionId);
    summary.fragmentId = UUID.orNull(json.fragmentId);
    summary.versionName = json.versionName;
    summary.sectionTitle = json.sectionTitle;
    summary.fragmentIndex = json.fragmentIndex;
    summary.discussionText = json.discussionText;
    summary.raisedBy = UUID.orNull(json.raisedBy);
    summary.raisedByName = json.raisedByName;
    summary.numberOfComments = json.numberOfComments;
    summary.latestCommentContent = json.latestCommentContent;
    summary.latestCommentCreatedAt = json.latestCommentCreatedAt;
    summary.lastCommentRaisedBy = UUID.orNull(json.lastCommentRaisedBy);
    summary.lastCommentRaisedByName = json.lastCommentRaisedByName;
    summary.resolved = json.resolved;
    summary.createdAt = json.createdAt;
    return summary;
  }
}
