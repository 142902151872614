<mat-card
  *ngIf="versionAggregate"
  (click)="select($event)"
  tabindex="0"
  (keyup.enter)="select($event)"
  [class.selected]="isSelected"
  class="version-card"
>
  <div class="document-title">
    <span>
      {{ versionAggregate.versionName }}
    </span>
    <mat-icon
      *ngIf="!versionAggregate.isLive()"
      [matTooltip]="versionTooltip"
      matTooltipPosition="right"
      matTooltipClass="tooltip-linebreak"
      [matTooltipShowDelay]="tooltipDelay"
    >
      info
    </mat-icon>
  </div>
  <div class="properties">
    <ng-container *ngIf="versionAggregate.isByUser() && !versionAggregate.isLive() && versionAggregate.userReviewState">
      Review status: <span>{{ reviewStateDisplayProperties.statusText }}</span>
      <mat-icon
        [style.color]="reviewStateDisplayProperties.iconColor"
        [matTooltip]="reviewStateDisplayProperties.statusText"
        matTooltipPosition="right"
        [matTooltipShowDelay]="tooltipDelay"
        >{{ reviewStateDisplayProperties.icon }}</mat-icon
      >
    </ng-container>
    <ng-container *ngIf="versionAggregate.isAll() && !versionAggregate.isLive() && versionAggregate.userReviewStates">
      Reviews: <span>{{ outstandingReviewString }}</span>
      <cars-resolved-icon [total]="totalReviews" [resolved]="completeReviews" [type]="'REVIEW'"></cars-resolved-icon>
    </ng-container>
    Discussions raised: <span>{{ discussionString }}</span
    ><cars-resolved-icon
      [total]="versionAggregate.discussions"
      [resolved]="versionAggregate.resolvedDiscussions"
      [type]="'DISCUSSION'"
    ></cars-resolved-icon>
    <ng-container *ngIf="versionAggregate.isByUser()">
      Comments raised: <span>{{ commentString }}</span>
      <cars-resolved-icon
        [total]="versionAggregate.discussionsCommentedOn"
        [resolved]="versionAggregate.resolvedDiscussionsCommentedOn"
        [type]="'COMMENT'"
      ></cars-resolved-icon>
    </ng-container>
  </div>
</mat-card>
