<div *ngIf="clauseDraggedOutOfPad" #followCursor class="clause-dragger">
  {{ numberOfDraggedClauses }}
</div>
<cars-navigation-sidebar-buttons
  *ngIf="!disableNavigationSidebar"
  [showNavigationToggle]="CarsAction.IS_CHANGELOG_AUX_VIEW_MODE | carsDisabled: documentId | async"
></cars-navigation-sidebar-buttons>
<mat-sidenav-container class="container" #container autosize>
  <mat-sidenav #navigation mode="side" disableClose [opened]="navigationOpen" position="start" [ngStyle]="sideNavStyle">
    <cars-navigation
      (toggleOpen)="toggleNavBar()"
      [useDocumentService]="!documentFragment"
      [document]="documentFragment"
      [type]="navigationName"
      [currentView]="currentView"
    >
    </cars-navigation>
    <div class="resize left" (mousedown)="mousedown($event, false)"></div>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <div class="main">
      <router-outlet #route></router-outlet>
      <ng-content></ng-content>
    </div>

    <div class="sidebar-container">
      <div
        [class.hidden]="
          (CarsAction.SHOW_RIGHT_SIDEBAR | carsDisabled: documentId | async) || sidebarStatus === SidebarStatus.CLOSED
        "
        class="sidebar"
        [style.width]="isClauseReference ? '35vw' : rightWidth + 'px'"
        [style.transition]="widthTransition"
      >
        <div [ngClass]="{'resize right': !isClauseReference}" (mousedown)="mousedown($event, true)"></div>
        <cars-sidebar></cars-sidebar>
      </div>

      <cars-saving-chip [currentView]="currentView" [useViewService]="useViewService"></cars-saving-chip>
    </div>

    <div [class.no-right-sidebar]="CarsAction.SHOW_RIGHT_SIDEBAR | carsDisabled: documentId | async" class="right">
      <cars-alt-accessibility
        class="initial-button"
        [altProperties]="altProperties['right-sidebar']"
        (updateAltProperties)="updateAltProperties()"
      ></cars-alt-accessibility>
      <button
        (click)="onSidebarToggle()"
        id="sidebar-button"
        mat-button
        [matTooltip]="
          reorderingMode && sidebarStatus === SidebarStatus.CLOSED
            ? 'Open clause options sidebar'
            : 'Collapse clause options sidebar'
        "
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
        [disabled]="sidebarStatus === SidebarStatus.CLOSED && !reorderingMode"
        [attr.aria-label]="
          reorderingMode && sidebarStatus === SidebarStatus.CLOSED
            ? 'Open clause options sidebar'
            : 'Collapse clause options sidebar'
        "
      >
        <mat-icon>{{
          reorderingMode && sidebarStatus === SidebarStatus.CLOSED ? 'chevron_left' : 'chevron_right'
        }}</mat-icon>
      </button>

      <mat-button-toggle-group
        vertical="true"
        #group="matButtonToggleGroup"
        (change)="setSidebarStatus($event)"
        aria-label="Clause options"
      >
        <ng-container *ngFor="let item of sidebarItems">
          <mat-button-toggle
            *ngIf="!item.displayCarsAction || (item.displayCarsAction | carsEnabled: documentId | async)"
            [value]="item.sidebarItemType"
            [attr.blurOption]="BlurOption.PREVENT_IMMEDIATE_CHILD_BLUR"
            [disabled]="sidebarOptionIsDisabled(item.sidebarItemType)"
            [style.color]="sidebarOptionIsDisabled(item.sidebarItemType) ? '' : 'black'"
            [checked]="sidebarStatus === item.sidebarItemType"
            [matTooltip]="item.sidebarItemType + (clauseFragment ? '' : ' (please select a clause)')"
            matTooltipPosition="before"
            [matTooltipShowDelay]="tooltipDelay"
            (keyup.enter)="sidebarStatus = item.sidebarItemType; setSidebarStatus({value: item.sidebarItemType})"
            [attr.aria-label]="item.sidebarItemType + (clauseFragment ? '' : ' (please select a clause)')"
          >
            <mat-icon *ngIf="!item.isIconSvg">{{ item.icon }} </mat-icon>
            <mat-icon *ngIf="item.isIconSvg" [svgIcon]="item.icon"></mat-icon>
            <div class="alt-container">
              <cars-alt-accessibility
                [altProperties]="altProperties[item.sidebarItemType]"
                (onSelect)="handleAltSelection(item.sidebarItemType)"
                (updateAltProperties)="updateAltProperties()"
              ></cars-alt-accessibility>
            </div>
          </mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<cars-chat *ngIf="CarsAction.SHOW_CARS_CHAT | carsEnabled: documentId | async"></cars-chat>
