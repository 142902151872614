<div class="table-container">
  <mat-table #table [dataSource]="unitsList">
    <ng-container matColumnDef="unit">
      <mat-cell *matCellDef="let unit">
        <div class="row-equation">
          <cars-equation [source]="unit.equationSource" [inline]="true"></cars-equation>
        </div>
        <div *ngIf="unit.title" class="row-title">{{ unit.title }}</div>
        <div *ngIf="unit.description" class="row-description">{{ unit.description }}</div>
      </mat-cell>
    </ng-container>
    <mat-row
      *matRowDef="let unit; columns: displayedColumns"
      (click)="selectUnit(unit)"
      (keyup.enter)="selectUnit(unit)"
    ></mat-row>
  </mat-table>
  <div class="no-results" *ngIf="unitsList && unitsList.length === 0">No references match your search terms</div>
  <mat-paginator [length]="totalUnitsCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>
