import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ManualTableColumnWidthModalComponent} from './manual-table-column-width-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatDialogModule, MatButtonModule, MatInputModule, MatSlideToggleModule],
  declarations: [ManualTableColumnWidthModalComponent],
  exports: [ManualTableColumnWidthModalComponent],
})
export class ManualTableColumnWidthModalModule {}
