import {Injectable} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {DocumentFragment} from 'app/fragment/types';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {DocumentService} from 'app/services/document.service';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ConditionASTNode} from '../types/condition-ast-node';
import {EqualityRelation, PermissionInput} from '../types/permissions';
import {FalseOnPadTypes} from './false-on-pad-types';
import {PermissionsHandler} from './permissions-handler';

export abstract class VersionTagBasePermissionsHandler implements PermissionsHandler {
  private readonly versionTagProperties: string[] = [
    'availableToReview',
    'availableForCommenting',
    'userDiscussionsOnly',
  ];

  constructor() {}

  public abstract getInputType(): PermissionInput;

  @FalseOnPadTypes(PadType.NO_PAD)
  public handle(condition: ConditionASTNode, padType: PadType): Observable<boolean> {
    const valueIsVersionTagType: boolean = this._validateAndGetIsVersionTagType(condition);
    const versionTagObsStream: Observable<VersionTag> = this._getVersionTagStream(padType);

    switch (condition.relation) {
      case EqualityRelation.EQUALS:
        return versionTagObsStream.pipe(
          map((versionTag: VersionTag) => this._compareVersionTags(versionTag, condition.value, valueIsVersionTagType))
        );
      case EqualityRelation.NOT_EQUALS:
        return versionTagObsStream.pipe(
          map((versionTag: VersionTag) => !this._compareVersionTags(versionTag, condition.value, valueIsVersionTagType))
        );
      default:
        throw new Error(`Unsupported equality relation ${condition.relation}`);
    }
  }

  /**
   * Validates that the condition value is either a valid VersionTagType or a valid property of a VersionTag defined
   * in versionTagProperties.
   *
   * @param condition The condition node to check
   * @returns         True if the value is a VersionTagType, false if it is a versionTagProperty
   */
  private _validateAndGetIsVersionTagType(condition: ConditionASTNode): boolean {
    const valueIsVersionTagType: boolean = !!VersionTagType[condition.value as string];
    const valueIsVersionTagProperty: boolean = this.versionTagProperties.includes(condition.value);

    if (condition.value !== null && !(valueIsVersionTagProperty || valueIsVersionTagType)) {
      throw new Error(`Unknown property on Version Tag: ${condition.value}`);
    }

    return valueIsVersionTagType;
  }

  protected abstract _getVersionTagStream(padType: PadType): Observable<VersionTag>;

  private _compareVersionTags(
    versionTag: VersionTag,
    conditionValue: string,
    conditionValueIsVersionTagType: boolean
  ): boolean {
    if (conditionValue === null) {
      return versionTag === null;
    } else if (conditionValueIsVersionTagType) {
      const type: VersionTagType = VersionTagType[conditionValue as string];

      return !!versionTag && versionTag.versionTagType === type;
    }
    return !!versionTag && versionTag[conditionValue];
  }
}

@Injectable()
export class VersionTagPermissionsHandler extends VersionTagBasePermissionsHandler {
  constructor(private _versioningService: VersioningService) {
    super();
  }

  public getInputType(): PermissionInput {
    return PermissionInput.VERSION_TAG;
  }

  /**
   * If the PadType is for the left side of the changelog, return a null VersionTag.
   */
  protected _getVersionTagStream(padType: PadType): Observable<VersionTag> {
    return padType === PadType.PUBLISHED_CHANGLOG
      ? of(null)
      : this._versioningService.getCurrentViewVersionTagObsStream();
  }
}

@Injectable()
export class LatestVersionTagPermissionsHandler extends VersionTagBasePermissionsHandler {
  constructor(private _versioningService: VersioningService, private _documentService: DocumentService) {
    super();
  }

  public getInputType(): PermissionInput {
    return PermissionInput.LATEST_VERSION_TAG;
  }

  /**
   * If the PadType is for the left side of the changelog, return a null VersionTag.
   */
  protected _getVersionTagStream(padType: PadType): Observable<VersionTag> {
    return padType === PadType.PUBLISHED_CHANGLOG
      ? of(null)
      : this._documentService
          .getDocumentStream()
          .pipe(
            switchMap((document: DocumentFragment) =>
              this._versioningService
                .getVersionTagsForFragmentId(document.id)
                .pipe(map((tags: VersionTag[]) => (tags.length > 0 ? tags[0] : null)))
            )
          );
  }
}
