import {Serialisable} from 'app/utils/serialisable';
import {UUID} from 'app/utils/uuid';

/**
 * A class representing a user.
 *
 * @param id           {UUID}      The Keycloak user ID
 * @param userName     {string}    The username
 * @param name         {string}    The user's full name
 * @param email        {string}    The user's email address
 * @param organisation {string}    The name of the user's organisation
 * @param initials     {string}    The user's initials
 * @param selected     {boolean}   True if the user is selected in a list
 */
export class User implements Serialisable {
  private static readonly empty: string = '(unknown)';

  public id: UUID;
  public userName: string;
  public name: string;
  public email: string;
  public organisation: string;
  public initials: string;
  public selected: boolean;

  /**
   * Return the enigmatic 'unknown' user.
   *
   * @returns {User}   The unknown user
   */
  public static unknown(): User {
    return new User(null, User.empty, 'Unknown User', User.empty, User.empty, User.empty, false);
  }

  /**
   * Deserialise an instance of a user from a JSON object
   *
   * @param json {any}  The JSON object to deserialsie
   * @returns    {User} The deserialised user
   */
  public static deserialise(json: any): User {
    const id: UUID = UUID.orNull(json.id);
    const userName: string = typeof json.username === 'string' ? json.username : User.empty;
    const name: string = this.deserialiseName(json);
    const email: string = typeof json.email === 'string' ? json.email : User.empty;
    const organisation: string = typeof json.organisation === 'string' ? json.organisation : User.empty;
    const initials: string = this.deserialiseInitials(json);
    const selected: boolean = typeof json.selected === 'boolean' ? json.selected : true;

    return new User(id, userName, name, email, organisation, initials, selected);
  }

  /**
   * Returns name extracted from the JSON object.
   *
   * @param json {any}    The JSON object to deserialise
   * @returns    {string} Stringified name
   */
  public static deserialiseName(json: any): string {
    if (typeof json.name === 'string') {
      return json.name;
    } else if (json.firstName && json.lastName) {
      return `${json.firstName} ${json.lastName}`;
    } else {
      return typeof json.username === 'string' ? json.username : User.empty;
    }
  }

  /**
   * Returns intials extracted from the JSON object.
   *
   * @param json {any}    The JSON object to deserialise
   * @returns    {string} Stringified initials
   */
  public static deserialiseInitials(json: any): string {
    if (typeof json.initials === 'string') {
      return json.initials;
    } else if (json.firstName && json.lastName) {
      return (json.firstName.charAt(0) + json.lastName.charAt(0)).toUpperCase();
    } else {
      return typeof json.username === 'string' ? json.username.substring(0, 2).toUpperCase() : User.empty;
    }
  }

  /**
   * Compares the users alphabetically by their names (not usernames).
   *
   * @param a {User} First user
   * @param b {User} Second user
   */
  public static compareNames(a: User, b: User): number {
    return a.name.localeCompare(b.name, 'en', {sensitivity: 'accent'});
  }

  constructor(
    id: UUID,
    userName: string,
    name: string,
    email: string,
    organisation: string,
    initials: string,
    selected: boolean
  ) {
    this.id = id;
    this.userName = userName;
    this.name = name;
    this.email = email;
    this.organisation = organisation;
    this.initials = initials;
    this.selected = selected;
  }

  /**
   * Getter for user's first name.
   *
   * @returns {string} First name of user
   */
  public get firstName(): string {
    const split: string[] = this.name.split(' ');
    return split[0];
  }

  /**
   * Getter for user's last name.
   *
   * @returns {string} Last name of user
   */
  public get lastName(): string {
    const split: string[] = this.name.split(' ');
    return split.length > 1 ? split[split.length - 1] : this.name;
  }

  /**
   * @inheritdoc
   */
  public serialise(): any {
    const json: any = Object.assign({}, this);
    json.id = this.id.serialise();

    return json;
  }
}
