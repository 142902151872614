import {DocumentFragment, Fragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {Discussion} from 'app/sidebar/discussions/discussions';

/**
 * An OfflineDocument is a map from version ID to OfflineVersion
 */
export type OfflineDocument = Record<string, OfflineVersion>;

/**
 * The fields on this object are stored as they are expected to be
 * received from the backend.
 *
 * Because any custom prototype will not survive persistence to IndexedDB,
 * only interfaces or types should be used.
 */
export interface OfflineVersion {
  fragments: Record<string, OfflineFragment[]>;
  discussions: OfflineDiscussion[];
  version: OfflineVersionTag;
  syncTime: number;
}

export class OfflineVersionBuilder {
  constructor(
    public document: DocumentFragment,
    public discussions: Discussion[],
    public version: VersionTag,
    public syncTime: number = Date.now()
  ) {}

  /**
   * Build an offline version for persistence to IndexedDB.
   *
   * fragment.serialise() deletes some fields that the frontend is not allowed
   * to modify on the backend. If fragments are going to the offline persistence service
   * rather than the backend, we keep track of these fields.
   *
   * There is no method to reverse this process, since these OfflineVersions are only used to serve
   * one or other of their fields in serialised form via HTTP interceptor.
   *
   */
  public build(): OfflineVersion {
    const fragments: Record<string, any[]> = {};
    this.document.iterateDown(null, null, (fragment: Fragment) => {
      const json: any = fragment.serialise();
      json.lastModifiedBy = fragment.lastModifiedBy ? fragment.lastModifiedBy.value : null;
      json.lastModifiedAt = fragment.lastModifiedAt;
      json.validFrom = fragment.validFrom;
      json.validTo = fragment.validTo;
      fragments[fragment.id.value] = [json];
    });

    return {
      fragments: fragments,
      discussions: this.discussions.map((d: Discussion) => d.serialise()),
      version: VersioningService.serialiseVersionTag(this.version),
      syncTime: this.syncTime,
    };
  }
}

// The following interfaces allow us to code against types while working
// with the serialised forms of these objects.  They are not expected to
// be complete definitions, only having enough fields to allow us to avoid
// the sort of bugs that arise when everything has type 'any'.

export interface OfflineFragment {
  id: string;
  parentId: string;
  validFrom: number;
  validTo: number;
}

export interface OfflineVersionTag {
  versionId: string;
  fragmentId: string;
  name: string;
  createdBy: string;
  createdAt: number;
}

export interface OfflineDiscussion {
  id: string;
  fragmentId: string;
  versionId: string;
  type: string;
  issueRaised: string;
  comments: any[];
}

export interface OfflineUser {
  id: string;
  userName: string;
  name: string;
  email: string;
}
