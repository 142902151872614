import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ExtendedTimeAgoModule} from 'app/utils/pipes/extended-time-ago.module';
import {UserModule} from 'app/widgets/user/user.module';
import {UserLogsComponent} from './user-logs.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, ExtendedTimeAgoModule, UserModule],
  declarations: [UserLogsComponent],
  exports: [UserLogsComponent],
})
export class UserLogsModule {}
