import {Pipe, PipeTransform} from '@angular/core';
import {CategoryOfChange} from 'app/fragment/types/category-of-change';

export const CATEGORY_OF_CHANGE_TO_DISPLAY_NAME: Readonly<Record<CategoryOfChange, string>> = {
  CATEGORY_A: 'Category A: Change to policy, major revision, new document development',
  CATEGORY_B: 'Category B: Incremental change to requirements',
  CATEGORY_C: 'Category C: Incremental change to advice',
  CATEGORY_D: 'Category D: Incremental change to notes and editorial updates',
};

@Pipe({
  name: 'categoryOfChangeDisplayName',
})
export class CategoryOfChangeToDisplayNamePipe implements PipeTransform {
  private static readonly UNKNOWN_TYPE: string = 'Unknown Category of Change';

  /**
   * Transforms the category of change enum into a friendly display name.
   * The {@link CATEGORY_OF_CHANGE_TO_DISPLAY_NAME} const is exported for consumers wanting to use this within typescript files.
   *
   * @param type  {CategoryOfChange}      The category of change
   * @returns     {string}                The display name
   */
  public transform(type: CategoryOfChange): string {
    return !!CATEGORY_OF_CHANGE_TO_DISPLAY_NAME[type]
      ? CATEGORY_OF_CHANGE_TO_DISPLAY_NAME[type]
      : CategoryOfChangeToDisplayNamePipe.UNKNOWN_TYPE;
  }
}
