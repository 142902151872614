import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Page} from 'app/admin/manage-references/manage-references.component';
import {Caret} from 'app/fragment/caret';
import {CarsRange} from 'app/fragment/cars-range';
import {DocumentReferenceFragment, ReferenceType} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {CanvasService} from 'app/services/canvas.service';
import {CaretService} from 'app/services/caret.service';
import {DocumentService} from 'app/services/document.service';
import {ReferenceService} from 'app/services/references/reference.service';
import {SearchableGlobalReferenceService} from 'app/services/references/searchable-global-reference.service';
import {GlobalReference} from 'app/sidebar/references/global-reference';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {Logger} from '../../error-handling/services/logger/logger.service';
import {SearchableGlobalReference} from './searchable-global-reference';

export interface ReferenceSearchParams {
  searchTerm: string;
  docId: string;
  page: number;
  size: number;
  listType: string;
}

@Component({
  selector: 'cars-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
})
export class ReferencesComponent implements OnInit, OnDestroy {
  @ViewChild('newReferenceForm', {static: true}) public newReferenceForm: NgForm;

  public readonly CarsAction: typeof CarsAction = CarsAction;

  public globalReferences: SearchableGlobalReference[];
  public documentReferences: SearchableGlobalReference[];

  public globalReferencesResultLength: number;
  public documentReferencesResultLength: number;

  public newReference: GlobalReference = GlobalReference.empty();

  public selectedType: ReferenceType = ReferenceType.NORMATIVE;
  public selectedRelease: string = '';
  public tabIndex: number = 0;

  public titleErrorMessage: string = 'Reference title is required';

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public filterString: string = '';

  public documentId: UUID = null;

  public currentRefId: UUID;

  public referenceExists: boolean = false;

  public submittingReference: boolean = false;

  public addNewReferenceloadingSpinner: boolean = false;

  private defaultSearchParams: ReferenceSearchParams = {
    searchTerm: '',
    docId: '',
    page: 0,
    size: 10,
    listType: 'LOAD_ALL',
  };

  private _addingReference: boolean = false;
  public set addingReference(b: boolean) {
    if (b) {
      this.newReference = GlobalReference.empty();
      this.newReferenceForm.reset();
      this.addNewReferenceloadingSpinner = false;

      if (this.filterString && this.filterString.length > 0) {
        this.newReference.title = this.filterString.trim();
      }

      this.filterString = null;
    }
    this._addingReference = b;
  }

  public get addingReference(): boolean {
    return this._addingReference;
  }

  public typeOptions: any[] = [
    {key: ReferenceType.NORMATIVE, text: 'Normative'},
    {key: ReferenceType.INFORMATIVE, text: 'Informative'},
  ];

  // The location at which new inline references will be inserted:
  public inputCaret: Caret;

  private subs: Subscription[] = [];

  private referenceInternalSearchSubscription: Subscription;
  private referenceGlobalSearchSubscription: Subscription;

  constructor(
    private referenceService: ReferenceService,
    private caretService: CaretService,
    private canvasService: CanvasService,
    private documentService: DocumentService,
    private searchableGlobalReferenceService: SearchableGlobalReferenceService
  ) {}

  public ngOnInit(): void {
    this.documentId = this.documentService.getSelected().documentId;
    this.defaultSearchParams.docId = this.documentId.value;
    this.updateReferenceLists(this.defaultSearchParams);
    this.subs.push(
      this.caretService.onSelectionChange(
        (caret: CarsRange) => {
          this.inputCaret = caret.start;
          this.canvasService.drawCaret(caret);
        },
        (caret: CarsRange) => caret && caret.isValidReferenceInsertion()
      ),
      this.referenceService.onReferenceChange().subscribe(() => this.updateReferenceLists(this.defaultSearchParams)),
      this.newReferenceForm.valueChanges.subscribe((v) => (this.referenceExists = false))
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    this.canvasService.clearCaretHighlight();
  }

  public updateFilterString(newFilterString: string): void {
    this.filterString = newFilterString;
  }

  public tabIndexChanged(event: any): void {
    // emit change to child component with new pagination stuff.
    this.filterString = null;
    this.defaultSearchParams.listType = 'LOAD_ALL';
    this.updateReferenceLists(this.defaultSearchParams);
  }

  /**
   * Add a new global reference, and then adds it to the document, if a reference already exists with the same title
   * the entry is rejected.
   */
  public createReference(): void {
    this.referenceService
      .createGlobalReference(this.newReference)
      .then((gloReference: GlobalReference) => {
        this.addingReference = false;
        this.selectReference(gloReference.id);
        this.newReferenceForm.resetForm();
        this.tabIndex = 0;
      })
      .catch((e) => {
        if (e === 'DuplicateReferenceException') {
          Logger.error('reference-error', 'Tried to create duplicate reference', e);
          this.referenceExists = true;
        }
      });
  }

  /**
   * Sets the type the reference will be added as to the document
   * @param submitType The type to submit to the document
   */
  public setCreateType(submitType: string): void {
    switch (submitType) {
      case 'informative':
        this.selectedType = ReferenceType.INFORMATIVE;
        break;
      case 'normative':
        this.selectedType = ReferenceType.NORMATIVE;
        break;
    }
    this.addNewReferenceloadingSpinner = true;
  }

  /**
   * Add a new reference to the document which has already been used in the document.
   *
   * @param ref the GlobalReference
   */
  public selectReferenceAlreadyInDocument(ref: UUID): void {
    this.currentRefId = ref;
    const docRef: DocumentReferenceFragment = this.referenceService.getDocumentReferenceFragment(this.currentRefId);
    if (docRef && this.inputCaret) {
      this.referenceService.addReference(this.currentRefId, docRef.referenceType, this.inputCaret, docRef.release);
    }
    this.currentRefId = null;
  }

  /**
   * Add a reference to the document, with the reference type determined
   * by this.selectedType
   * @param ref the reference to add.
   */
  public selectReference(ref: UUID): void {
    this.currentRefId = ref;
    this.referenceService.addReference(this.currentRefId, this.selectedType, this.inputCaret, this.selectedRelease);
    this.currentRefId = null;
  }

  public allowReferenceSubmission(): boolean {
    return this.newReferenceForm.form.valid && !!this.inputCaret;
  }

  /**
   * Set the current reference from the list of all references used in CARS.
   *
   * @param ref
   */
  public selectReferenceFromAll(ref: UUID): void {
    this.currentRefId = ref;
  }

  /**
   * Submit the current reference from the list of all references used in CARS.
   */
  public submitReferenceFromAll(reference): void {
    this.setCreateType(reference.type);
    this.selectedRelease = reference.release;
    this.submittingReference = true;
    this.referenceService
      .addReference(this.currentRefId, this.selectedType, this.inputCaret, this.selectedRelease)
      .then(() => {
        this.submittingReference = false;
        this.currentRefId = null;
        this.selectedRelease = null;
        this.tabIndex = 0;
      });
  }

  /**
   * Submit a new search request when search called
   * @param params
   */
  public updateReferenceLists(params: ReferenceSearchParams): void {
    if ((this.tabIndex === 0 && params.listType === 'DOCUMENT_REFERENCES') || params.listType === 'LOAD_ALL') {
      this.referenceInternalSearchSubscription?.unsubscribe();
      this.referenceInternalSearchSubscription = this.referenceService
        .fetchAndCacheSearchableGlobalReferencesForDocument(
          params.searchTerm,
          this.documentId.value,
          params.page,
          params.size
        )
        .subscribe((result: Page<SearchableGlobalReference>) => {
          this.documentReferences = result.resultsList;
          this.documentReferencesResultLength = result.total.valueOf();
        });
    }

    if ((this.tabIndex === 1 && params.listType === 'ALL_REFERENCES') || params.listType === 'LOAD_ALL') {
      this.referenceGlobalSearchSubscription?.unsubscribe();
      this.referenceGlobalSearchSubscription = this.searchableGlobalReferenceService
        .fetchSearchableGlobalReferencesNotInDocument(
          params.searchTerm,
          this.documentId.value,
          params.page,
          params.size
        )
        .subscribe((result: Page<SearchableGlobalReference>) => {
          this.globalReferences = result.resultsList;
          this.globalReferencesResultLength = result.total.valueOf();
        });
    }
  }

  /**
   * Method called whenever an expansion panel is opened.
   */
  public onOpen(): void {
    this.currentRefId = null;
    this.selectedType = ReferenceType.NORMATIVE;
    this.selectedRelease = '';
  }

  public onOpenLibrary(open: boolean): void {
    this.tabIndex = open ? 1 : 0;
  }
}
