import {
  DocumentReferenceFragment,
  Fragment,
  FragmentType,
  InternalReferenceType,
  ReferenceType,
} from 'app/fragment/types';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {TargetDocumentType} from 'app/fragment/types/reference/target-document-type';
import {SearchableGlobalReference} from 'app/sidebar/references/searchable-global-reference';
import {UUID} from 'app/utils/uuid';
import {referenceTitleCompareFunction} from './reference-compare-functions';

export interface SectionDisplayReference {
  id: UUID;
  targetId: UUID;
  formattedTitle: string;
  type: ReferenceType;
  orderByTitle: string;
  globalReferenceProperties?: SectionDisplayReferenceProperties;
  internalReferenceProperties?: SectionDisplayReferenceProperties;
}

interface SectionDisplayReferenceProperties {
  deleted: boolean;
  withdrawn: boolean;
}

export class SectionDisplayReferenceUtils {
  /**
   * Takes an array of document references (both internal and not), maps them to the SectionDisplayReference
   * objects that are used to display their row in the document reference table, and sorts them by
   * their titles.
   */
  public static mapCollapseAndSortAllDocumentReferences(references: Fragment[]): SectionDisplayReference[] {
    if (!references) {
      return [];
    }
    return Object.values(
      references.reduce((returnMap: Record<string, SectionDisplayReference>, current: Fragment) => {
        const displayReference: SectionDisplayReference =
          SectionDisplayReferenceUtils.mapToSectionDisplayReference(current);
        if (displayReference) {
          if (!returnMap[displayReference.targetId.value]) {
            returnMap[displayReference.targetId.value] = displayReference;
          } else {
            returnMap[displayReference.targetId.value] = this.updateValuesOnExistingReference(
              displayReference,
              returnMap[displayReference.targetId.value]
            );
          }
        }

        return returnMap;
      }, {})
    ).sort(SectionDisplayReferenceUtils.displayReferenceCompareFunction);
  }

  private static mapToSectionDisplayReference(fragment: Fragment): SectionDisplayReference {
    switch (fragment.type) {
      case FragmentType.DOCUMENT_REFERENCE:
        return SectionDisplayReferenceUtils.getSectionDisplayReferenceForDocumentReference(
          fragment as DocumentReferenceFragment
        );
      case FragmentType.INTERNAL_DOCUMENT_REFERENCE:
        return SectionDisplayReferenceUtils.getSectionDisplayReferenceForInternalDocumentReference(
          fragment as InternalDocumentReferenceFragment
        );
      default:
        return null;
    }
  }

  private static updateValuesOnExistingReference(
    existingReference: SectionDisplayReference,
    currentReference: SectionDisplayReference
  ): SectionDisplayReference {
    if (!!existingReference.globalReferenceProperties) {
      currentReference.globalReferenceProperties = {
        deleted:
          currentReference.globalReferenceProperties?.deleted || existingReference.globalReferenceProperties.deleted,
        withdrawn: existingReference.globalReferenceProperties.withdrawn,
      };
      return existingReference;
    } else if (!!existingReference.internalReferenceProperties) {
      currentReference.internalReferenceProperties = {
        deleted:
          currentReference.internalReferenceProperties?.deleted ||
          existingReference.internalReferenceProperties.deleted,
        withdrawn: false,
      };
    }
    return currentReference;
  }

  private static getSectionDisplayReferenceForDocumentReference(
    documentReference: DocumentReferenceFragment
  ): SectionDisplayReference {
    const searchable: SearchableGlobalReference = documentReference.searchableGlobalReference;

    return {
      id: documentReference.id,
      targetId: documentReference.searchableGlobalReference?.carsDocumentId || documentReference.globalReference,
      type: documentReference.referenceType,
      formattedTitle: searchable?.format(documentReference.release),
      orderByTitle: searchable?.title || '',
      globalReferenceProperties: {
        deleted: searchable?.deleted,
        withdrawn: searchable?.withdrawn,
      },
    };
  }

  /**
   * Note here that we don't want to display INTERNAL_DOCUMENT_REFERENCE fragments that are to the
   * same document in the document reference table and thus we return null for those so they can be
   * filtered out.
   */
  private static getSectionDisplayReferenceForInternalDocumentReference(
    internalReference: InternalDocumentReferenceFragment
  ): SectionDisplayReference {
    return internalReference.targetDocumentType === TargetDocumentType.DIFFERENT_DOCUMENT &&
      internalReference.internalReferenceType !== InternalReferenceType.WSR_REFERENCE
      ? {
          id: internalReference.id,
          targetId: internalReference.targetDocumentId,
          type: internalReference.referenceType,
          formattedTitle: internalReference.getFormattedTitle(),
          orderByTitle: internalReference.documentTitle || '',
          internalReferenceProperties: {
            deleted: internalReference.targetFragmentDeleted,
            withdrawn: false,
          },
        }
      : null;
  }

  private static displayReferenceCompareFunction(a: SectionDisplayReference, b: SectionDisplayReference): number {
    return referenceTitleCompareFunction(a.orderByTitle, b.orderByTitle);
  }

  // Private constructor to prevent instantiations
  private constructor() {}
}
