import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {UserLocationsComponent} from 'app/admin/user-locations/user-locations.component';
import {UserLogsComponent} from 'app/admin/user-logs/user-logs.component';
import {ChangelogComponent} from 'app/changelog/changelog.component';
import {DocumentOverviewComponent} from 'app/document-overview/document-overview.component';
import {DocumentVersionsComponent} from 'app/document-versions/document-versions.component';
import {AdminGuardService} from 'app/routing/guards/admin-guard.service';
import {SectionCommentsGuardService} from 'app/routing/guards/section-comments-guard.service';
import {ChangelogResolverService} from 'app/routing/resolvers/changelog-resolver.service';
import {DocumentResolverService} from 'app/routing/resolvers/document-resolver.service';
import {DocumentVersionResolverService} from 'app/routing/resolvers/document-version-resolver.service';
import {DocumentVersionsResolverService} from 'app/routing/resolvers/document-versions-resolver.service';
import {DocumentsResolverService} from 'app/routing/resolvers/documents-resolver.service';
import {SectionBackgroundResolverService} from 'app/routing/resolvers/section-background-resolver.service';
import {SectionCommentsResolverService} from 'app/routing/resolvers/section-comments-resolver.service';
import {SectionCompareResolverService} from 'app/routing/resolvers/section-compare-resolver.service';
import {SectionExportResolverService} from 'app/routing/resolvers/section-export-resolver.service';
import {SectionResolverService} from 'app/routing/resolvers/section-resolver.service';
import {SectionBackgroundComponent} from 'app/section-content/section-background/section-background.component';
import {SectionCommentsComponent} from 'app/section-content/section-comments/section-comments.component';
import {SectionCompareComponent} from 'app/section-content/section-compare/section-compare.component';
import {SectionProxyComponent} from 'app/section-content/section-proxy/section-proxy.component';
import {ManageReferencesComponent} from './admin/manage-references/manage-references.component';
import {UserStatisticsComponent} from './admin/user-statistics/user-statistics.component';
import {LinkTableComponent} from './changelog/link-table/link-table.component';
import {ClauseChangeSummaryComponent} from './clause-change-summary/clause-change-summary.component';
import {ContainerComponent} from './container/container.component';
import {MyDiscussionsComponent} from './dashboard/my-dashboards/my-discussions.component';
import {MyDocumentsComponent} from './dashboard/my-dashboards/my-documents.component';
import {DocumentsComponent} from './documents/documents.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PrintComponent} from './print/print.component';
import {WsrSubjectTopicGuardService} from './routing/guards/wsr-subject-topic-guard.service';
import {ChangeSummaryResolverService} from './routing/resolvers/change-summary-resolver.service';
import {DocumentOverviewResolverService} from './routing/resolvers/document-overview-resolver.service';
import {LinkTableResolverService} from './routing/resolvers/link-table-resolver.service';
import {WSRSubjectTopicResolverService} from './routing/resolvers/wsr-subject-topic-resolver.service';
import {WsrSubjectTopicComponent} from './wsr-subject-topic/wsr-subject-topic.component';

/**
 * TODO: Need to guard against users viewing versions of a document that they aren't
 * allowed to see. Currently we only guard against users being assigned onto the document.
 */

// Routes

const DOCUMENTS_ROUTE: Route = {
  path: 'documents',
  component: DocumentsComponent,
  data: {breadcrumbs: [{title: 'Documents', link: '/documents'}]},
  resolve: [DocumentsResolverService],
};

const MY_DISCUSSIONS_ROUTE: Route = {
  path: 'mydiscussions',
  component: MyDiscussionsComponent,
  data: {breadcrumbs: [{title: 'My Discussions', link: '/mydiscussions'}]},
};

const MY_DOCUMENTS_ROUTE: Route = {
  path: 'mydocuments',
  component: MyDocumentsComponent,
  data: {breadcrumbs: [{title: 'My Documents', link: '/mydocuments'}]},
};

const USER_LOCATIONS_ROUTE: Route = {
  path: 'locations',
  component: UserLocationsComponent,
  data: {breadcrumbs: [{title: 'User Locations', link: '/locations'}]},
  canActivate: [AdminGuardService],
};

const MANAGE_REFERENCES_ROUTE: Route = {
  path: 'references',
  component: ManageReferencesComponent,
  data: {breadcrumbs: [{title: 'Manage References', link: '/references'}]},
  canActivate: [AdminGuardService],
};

const USER_LOGS_ROUTE: Route = {
  path: 'logs',
  component: UserLogsComponent,
  data: {breadcrumbs: [{title: 'User Logs', link: '/logs'}]},
  canActivate: [AdminGuardService],
};

const USER_STATISTICS_ROUTE: Route = {
  path: 'statistics',
  component: UserStatisticsComponent,
  data: {breadcrumbs: [{title: 'User Statistics', link: '/statistics'}]},
  canActivate: [AdminGuardService],
};

const DOCUMENT_VERSIONS_ROUTE: Route = {
  path: 'documents/:document_id/versions',
  component: DocumentVersionsComponent,
  resolve: [DocumentVersionsResolverService],
};

const SECTION_EXPORT_ROUTE: Route = {
  path: 'documents/:document_id/sections/:section_id/export',
  component: SectionProxyComponent,
  resolve: [SectionExportResolverService],
};

const SECTION_BACKGROUND_EXPORT_ROUTE: Route = {
  path: 'background',
  component: SectionBackgroundComponent,
  resolve: [SectionBackgroundResolverService],
};

const SECTION_COMMENTS_EXPORT_ROUTE: Route = {
  path: 'comments',
  component: SectionCommentsComponent,
  resolve: [SectionCommentsResolverService],
  canDeactivate: [SectionCommentsGuardService],
};

const CHANGELOG_ROUTE: Route = {
  path: 'documents/:document_id/sections/:section_id/changelog',
  component: ChangelogComponent,
  resolve: [ChangelogResolverService],
};

const OLD_LINK_TABLE_ROUTE: Route = {
  path: 'documents/:document_id/sections/:section_id/changelog/linktable',
  redirectTo: 'documents/:document_id/linktable',
};

const LINK_TABLE_ROUTE: Route = {
  path: 'documents/:document_id/linktable',
  component: LinkTableComponent,
  resolve: [LinkTableResolverService],
};

const SECTION_ROUTE: Route = {
  path: 'sections/:section_id',
  component: SectionProxyComponent,
  resolve: [SectionResolverService],
};

const SECTION_BACKGROUND_ROUTE: Route = {
  path: 'sections/:section_id/background',
  component: SectionBackgroundComponent,
  resolve: [SectionBackgroundResolverService],
};

const SECTION_COMMENTS_ROUTE: Route = {
  path: 'sections/:section_id/comments',
  component: SectionCommentsComponent,
  resolve: [SectionCommentsResolverService],
  canDeactivate: [SectionCommentsGuardService],
};

const SECTION_COMPARE_ROUTE: Route = {
  path: 'sections/:section_id/compare',
  component: SectionCompareComponent,
  resolve: [SectionCompareResolverService],
};

const CLAUSE_CHANGE_SUMMARY_ROUTE: Route = {
  path: 'change-summary',
  component: ClauseChangeSummaryComponent,
  resolve: [ChangeSummaryResolverService],
};

const LIVE_PRINT_ROUTE: Route = {
  path: 'documents/:document_id/sections/:section_id/print',
  component: PrintComponent,
  children: [SECTION_BACKGROUND_EXPORT_ROUTE, SECTION_COMMENTS_EXPORT_ROUTE],
};

const LIVE_CHANGE_SUMMARY_PRINT_ROUTE: Route = {
  path: 'documents/:document_id/print',
  component: PrintComponent,
  children: [CLAUSE_CHANGE_SUMMARY_ROUTE],
};

const VERSION_PRINT_ROUTE: Route = {
  path: 'documents/:document_id/versions/:version_id/sections/:section_id/print',
  component: PrintComponent,
  children: [SECTION_BACKGROUND_EXPORT_ROUTE, SECTION_COMMENTS_EXPORT_ROUTE],
};

const VERSION_CHANGE_SUMMARY_PRINT_ROUTE: Route = {
  path: 'documents/:document_id/versions/:version_id/print',
  component: PrintComponent,
  children: [CLAUSE_CHANGE_SUMMARY_ROUTE],
};

const WSR_SUBJECT_TOPIC_ROUTE: Route = {
  path: 'wsr-subject-topic',
  component: WsrSubjectTopicComponent,
  resolve: [WSRSubjectTopicResolverService],
  canDeactivate: [WsrSubjectTopicGuardService],
};

const WSR_SUBJECT_TOPIC_PRINT_ROUTE: Route = {
  path: 'documents/:document_id/print',
  component: PrintComponent,
  children: [WSR_SUBJECT_TOPIC_ROUTE],
};

const DOCUMENT_VERSION_ROUTE: Route = {
  path: 'documents/:document_id/versions/:version_id',
  component: ContainerComponent,
  resolve: [DocumentVersionResolverService],
  children: [
    {
      path: '',
      component: DocumentOverviewComponent,
      resolve: [DocumentOverviewResolverService],
    },
    SECTION_ROUTE,
    SECTION_BACKGROUND_ROUTE,
    SECTION_COMMENTS_ROUTE,
    SECTION_COMPARE_ROUTE,
    CLAUSE_CHANGE_SUMMARY_ROUTE,
    WSR_SUBJECT_TOPIC_ROUTE,
  ],
};

const DOCUMENT_OVERVIEW_ROUTE: Route = {
  path: '',
  component: DocumentOverviewComponent,
  resolve: [DocumentOverviewResolverService],
};

const LIVE_DOCUMENT_ROUTE: Route = {
  path: 'documents/:document_id',
  component: ContainerComponent,
  resolve: [DocumentResolverService],
  children: [
    DOCUMENT_OVERVIEW_ROUTE,
    SECTION_ROUTE,
    SECTION_BACKGROUND_ROUTE,
    SECTION_COMMENTS_ROUTE,
    SECTION_COMPARE_ROUTE,
    CLAUSE_CHANGE_SUMMARY_ROUTE,
    WSR_SUBJECT_TOPIC_ROUTE,
  ],
};

const REDIRECT_ROUTE: Route = {
  path: '',
  redirectTo: '/documents',
  pathMatch: 'full',
};

const PAGE_NOT_FOUND_ROUTE: Route = {
  path: '**',
  component: PageNotFoundComponent,
};

const routes: Routes = [
  DOCUMENTS_ROUTE,
  MY_DISCUSSIONS_ROUTE,
  MY_DOCUMENTS_ROUTE,
  USER_LOCATIONS_ROUTE,
  MANAGE_REFERENCES_ROUTE,
  USER_LOGS_ROUTE,
  USER_STATISTICS_ROUTE,
  CHANGELOG_ROUTE,
  OLD_LINK_TABLE_ROUTE,
  LINK_TABLE_ROUTE,
  SECTION_EXPORT_ROUTE,
  LIVE_PRINT_ROUTE,
  LIVE_CHANGE_SUMMARY_PRINT_ROUTE,
  VERSION_PRINT_ROUTE,
  VERSION_CHANGE_SUMMARY_PRINT_ROUTE,
  WSR_SUBJECT_TOPIC_ROUTE,
  WSR_SUBJECT_TOPIC_PRINT_ROUTE,
  DOCUMENT_VERSIONS_ROUTE,
  DOCUMENT_VERSION_ROUTE,
  LIVE_DOCUMENT_ROUTE,
  REDIRECT_ROUTE,
  PAGE_NOT_FOUND_ROUTE,
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class CarsRoutingModule {}
