import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {FragmentService} from 'app/services/fragment.service';
import {DEFAULT_REFERENCE_KEY} from 'app/services/references/reference-utils/reference-index-utils';
import {ReferenceService} from 'app/services/references/reference.service';
import {Subscription} from 'rxjs';
import {ClauseType, Fragment, InternalReferenceType} from '../types';
import {InternalDocumentReferenceFragment} from '../types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from '../types/reference/internal-inline-reference-fragment';
import {TargetDocumentType} from '../types/reference/target-document-type';
import {SectionReferenceHovertipComponent} from './section-reference-hovertip/section-reference-hovertip.component';

@Component({
  selector: 'cars-internal-inline-reference-fragment',
  templateUrl: './internal-inline-reference-fragment.component.html',
  styleUrls: ['./internal-inline-reference-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalInlineReferenceFragmentComponent extends FragmentComponent implements OnInit, OnDestroy {
  private static readonly FAILED_TO_LOAD_REFERENCE_STRING: string = 'Failed to load reference';
  private static readonly DOCUMENT_CODE_NOT_SET_STRING: string = '[document code not set]';
  private static readonly SAME_DOCUMENT_STRING: string = 'this document';
  private static readonly HEADING_CLAUSE_TYPES: Readonly<ClauseType[]> = [
    ClauseType.HEADING_1,
    ClauseType.HEADING_2,
    ClauseType.HEADING_3,
  ];

  @ViewChild('referenceRef') public referenceRef: ElementRef;

  @Input() public set content(value: InternalInlineReferenceFragment) {
    super.content = value;
  }

  public get content(): InternalInlineReferenceFragment {
    return super.content as InternalInlineReferenceFragment;
  }

  public readonly InternalReferenceType: typeof InternalReferenceType = InternalReferenceType;
  public readonly defaultKey: string = DEFAULT_REFERENCE_KEY;

  public sectionReferenceString: string = InternalInlineReferenceFragmentComponent.FAILED_TO_LOAD_REFERENCE_STRING;
  public targetFragmentDeleted: boolean = false;
  public selected: boolean;
  public showDocumentReference: boolean;
  public internalReferenceType: InternalReferenceType;

  private _internalDocumentReferenceFragment: InternalDocumentReferenceFragment;
  private subscriptions: Subscription[] = [];

  private hovertipComponent: ComponentRef<SectionReferenceHovertipComponent>;
  private hoverTimeout: number = null;

  public static generateReferenceString(internalReference: InternalDocumentReferenceFragment) {
    const trimmedSectionIndex: string = internalReference.sectionIndex.endsWith('.')
      ? internalReference.sectionIndex.slice(0, -1)
      : internalReference.sectionIndex;

    const documentCode: string =
      internalReference.documentCode || InternalInlineReferenceFragmentComponent.DOCUMENT_CODE_NOT_SET_STRING;

    const documentString =
      internalReference.targetDocumentType === TargetDocumentType.SAME_DOCUMENT
        ? InternalInlineReferenceFragmentComponent.SAME_DOCUMENT_STRING
        : `${documentCode}`;

    return '"' + internalReference.sectionTitle + '"' + ' in Section ' + trimmedSectionIndex + ' of ' + documentString;
  }

  constructor(
    private _fragmentService: FragmentService,
    private _referenceService: ReferenceService,
    private _cdr: ChangeDetectorRef,
    private _viewContainerRef: ViewContainerRef,
    _elementRef: ElementRef
  ) {
    super(_cdr, _elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this._refreshProperties();

    this.subscriptions.push(
      this._fragmentService.onUpdate(
        () => this._refreshProperties(),
        (fragment: Fragment) => fragment.id.equals(this.content.internalDocumentReferenceId)
      ),
      this._referenceService.onDocumentReferencesRecalculated().subscribe(() => this._refreshProperties())
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public onFocus(): void {
    this.selected = true;
    this._cdr.markForCheck();
  }

  public blur(): void {
    this.selected = false;
    this._cdr.markForCheck();
  }

  /**
   * Refreshes the display properties of the inline reference.
   */
  private _refreshProperties(): void {
    this._getInternalDocumentReferenceFragment().then((documentReference) => {
      this._internalDocumentReferenceFragment = documentReference;
      this.internalReferenceType = this._internalDocumentReferenceFragment.internalReferenceType;

      this._setTargetFragmentDeleted();
      this._setReferenceString();
      this._cdr.markForCheck();
    });
  }

  /**
   * Gets the internal document reference for the inline reference at the currently viewed time. Attempts to get the
   * fragment from the cache, else uses the fragment service fetch if it is not found.
   * Initially tries to find the InternalDocumentReferenceFragment used for the diff, defaults to the standard InternalDocumentReferenceFragment
   */
  private _getInternalDocumentReferenceFragment(): Promise<InternalDocumentReferenceFragment> {
    if (this.content.diffedInternalDocumentReference) {
      return Promise.resolve(this.content.diffedInternalDocumentReference);
    }

    const docRef: InternalDocumentReferenceFragment = this._fragmentService.find(
      this.content.internalDocumentReferenceId
    ) as InternalDocumentReferenceFragment;

    if (!!docRef) {
      return Promise.resolve(docRef);
    }

    return this._fragmentService
      .fetchLatest(this.content.internalDocumentReferenceId, {
        depth: 0,
        validFrom: 0,
      })
      .then((fragment: Fragment) => fragment as InternalDocumentReferenceFragment);
  }

  /**
   * Sets whether the target section has been deleted, if the internal document reference is not found sets it to false
   * as this is flagged elsewhere.
   */
  private _setTargetFragmentDeleted(): void {
    this.targetFragmentDeleted = this._internalDocumentReferenceFragment?.targetFragmentDeleted ?? false;
  }

  /**
   * Gets the formatted section reference string to display for the inline reference.
   */
  private _setReferenceString(): void {
    if (!this._internalDocumentReferenceFragment) {
      this.sectionReferenceString = InternalInlineReferenceFragmentComponent.FAILED_TO_LOAD_REFERENCE_STRING;
      this.showDocumentReference = false;
    } else {
      switch (this._internalDocumentReferenceFragment.internalReferenceType) {
        case InternalReferenceType.SECTION_REFERENCE:
          this.showDocumentReference =
            this._internalDocumentReferenceFragment.targetDocumentType !== TargetDocumentType.SAME_DOCUMENT;

          this.sectionReferenceString = InternalInlineReferenceFragmentComponent.generateReferenceString(
            this._internalDocumentReferenceFragment
          );
          break;

        case InternalReferenceType.WSR_REFERENCE:
          this.showDocumentReference = false;
          this.sectionReferenceString = this._internalDocumentReferenceFragment.wsrCode;
          break;
        case InternalReferenceType.CLAUSE_REFERENCE:
          // Handling of document reference is done by the parent ReferenceInputFragment
          this.showDocumentReference = false;

          const trimmedSectionIndex: string = this._internalDocumentReferenceFragment.sectionIndex.replace(/\.$/m, '');
          this.sectionReferenceString = !!InternalInlineReferenceFragmentComponent.HEADING_CLAUSE_TYPES.includes(
            this._internalDocumentReferenceFragment.targetClauseType
          )
            ? this._getTargetFragmentValue() + ' in Section ' + trimmedSectionIndex
            : this._internalDocumentReferenceFragment.targetFragmentIndex;
          break;
      }
    }
  }

  private _getTargetFragmentValue() {
    if (
      InternalInlineReferenceFragmentComponent.HEADING_CLAUSE_TYPES.includes(
        this._internalDocumentReferenceFragment.targetClauseType
      )
    ) {
      return `"${this._internalDocumentReferenceFragment.targetFragmentValue}"`;
    }
    return this._internalDocumentReferenceFragment.targetFragmentValue;
  }

  /**
   * Creates the hovertip on mouseover. Note does not create the hovertip for clause references as this is handled
   * in the parent.
   */
  @HostListener('mouseover', ['$event'])
  public mouseover(event: MouseEvent): void {
    if (this.hovertipComponent) {
      return;
    }

    if (
      !this.hoverTimeout &&
      this._internalDocumentReferenceFragment.internalReferenceType !== InternalReferenceType.CLAUSE_REFERENCE
    ) {
      this.hoverTimeout = window.setTimeout(() => {
        this.hoverTimeout = null;
        this.createHovertip(event);
        this._cdr.markForCheck();
      }, 200);
    }
  }

  /**
   * Removes the hovertip on mouseleave.
   */
  @HostListener('mouseleave', ['$event'])
  public mouseleave(event: MouseEvent): void {
    if (this.hoverTimeout) {
      window.clearTimeout(this.hoverTimeout);
      this.hoverTimeout = null;
      this._cdr.markForCheck();
    }
  }

  private createHovertip(event: MouseEvent): void {
    this.hovertipComponent = this._viewContainerRef.createComponent(SectionReferenceHovertipComponent);
    this.hovertipComponent.instance.componentRef = this.hovertipComponent;
    this.hovertipComponent.instance.inlineReference = this.content;
    this.hovertipComponent.instance.event = event;
    this.hovertipComponent.instance.anchorElement = this.referenceRef.nativeElement;
    this.hovertipComponent.instance.internalReferenceType =
      this._internalDocumentReferenceFragment.internalReferenceType;
    this.subscriptions.push(
      this.hovertipComponent.instance.destroy.subscribe(() => {
        this.hovertipComponent = null;
        this._cdr.markForCheck();
      })
    );
  }
}
