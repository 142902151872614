export class StringUtils {
  /**
   * Javascript implementation of Java's String.hashCode().
   * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery
   *
   * @param str {string}  The string to hash
   * @returns {boolean} The hash code
   */
  public static hash(str: string): number {
    let hash: number = 0;
    if (str.length === 0) {
      return hash;
    }

    for (let i = 0; i < str.length; ++i) {
      const char: number = str.charCodeAt(i);
      /* eslint-disable no-bitwise */
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
      /* eslint-enable no-bitwise */
    }
    return hash;
  }
}
