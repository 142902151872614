import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {DocumentFragment, Fragment, SectionFragment} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {NavigationService, NavigationTypes} from 'app/services/navigation.service';
import {UUID} from 'app/utils/uuid';
import {CurrentView, ViewMode} from 'app/view/current-view';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {Logger} from '../../../../error-handling/services/logger/logger.service';
import {Caret} from '../../../../fragment/caret';
import {CarsRange, CarsRangeType} from '../../../../fragment/cars-range';
import {FragmentMapper} from '../../../../fragment/core/fragment-mapper';
import {CanvasService} from '../../../../services/canvas.service';
import {SectionService} from '../../../../services/section.service';
import {SearchEvent, SearchService} from '../search.service';

interface SearchResult {
  fragmentId: UUID;
  display: string[];
  startAndEnd: [number, number];
  rangeHash: string;
  navigatingTo: boolean;
}

interface SectionProperty {
  displayString: string;
  collapsed: boolean;
  searchResults: SearchResult[];
}

@Component({
  selector: 'cars-search-document',
  templateUrl: './search-document.component.html',
  styleUrls: ['./search-document.component.scss'],
})
export class SearchDocumentComponent implements OnInit, OnDestroy {
  @Output() closeSearch = new EventEmitter();
  @Input() public document: DocumentFragment;
  @Input() public currentView: CurrentView;

  public searchString: string = '';

  public sectionKeys: string[];
  public sectionProperties: Map<string, SectionProperty> = new Map();

  public searching: boolean = false;

  public numberOfResults: number = 0;

  public selectedResult: SearchResult = null;

  // This is for searching the published document in changelog so that we can scroll the result into view.
  public pendingPublishedresult: SearchResult = null;

  private _subscriptions: Subscription[] = [];

  public tooltipDelay: number = environment.tooltipDelay;

  private _apiUrl: string = `${environment.apiHost}/fragments/search`;

  constructor(
    private _httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private _fragmentService: FragmentService,
    private _canvasService: CanvasService,
    private _sectionService: SectionService,
    private _searchService: SearchService,
    private _navigationService: NavigationService,
    private _fragmentIndexService: FragmentIndexService
  ) {}

  ngOnInit() {
    this._subscriptions.push(
      this._searchService.onSearchDocumentEvent().subscribe((s: SearchEvent) => {
        if (this.document && this.document.id.equals(s.docId)) {
          this.searchString = s.searchString;
          this.searchDocument();
        }
      }),
      this._navigationService.onNavigationEnd(NavigationTypes.CHANGELOG, (t) => {
        if (this.pendingPublishedresult) {
          requestAnimationFrame(() => {
            this._drawResultRangesOnPad();
            this._scrollRangeIntoView(this.pendingPublishedresult);
            this.pendingPublishedresult.navigatingTo = false;
            this.pendingPublishedresult = null;
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
    this._canvasService.clearAllSearchHighlights();
  }

  public closeView(): void {
    this.closeSearch.emit(null);
    this._canvasService.clearAllSearchHighlights();
  }

  /**
   * Toggles whether the results list in the given section is collapsed or open.
   *
   * @param id  {string}  the id of the section to toggle.
   */
  public toggleSection(id: string): void {
    this.sectionProperties.get(id).collapsed = !this.sectionProperties.get(id).collapsed;
  }

  /**
   * Returns the text informing the user of the number of search results.
   */
  public numberOfResultsText(): string {
    const r: string = this.numberOfResults === 1 ? ' result found in ' : ' results found in ';
    const s: string = this.sectionProperties.size === 1 ? ' section' : ' sections';
    return this.numberOfResults + r + this.sectionProperties.size + s;
  }

  /**
   * Searches the document for the searchString and processes the returned fragments.
   */
  public searchDocument(): void {
    this.sectionProperties.clear();
    this._canvasService.clearAllSearchHighlights();
    this.searching = true;
    const validAt: string = this.currentView.isHistorical() ? this.currentView.versionTag.createdAt.toString() : '';
    this._httpClient
      .get(this._apiUrl, {
        responseType: 'json',
        params: {
          searchTerm: this.searchString,
          docId: this.document.id.value,
          validAt: validAt,
        },
      })
      .subscribe(
        (response: any) => {
          response.forEach((f: any) => {
            const fragment: Fragment = FragmentMapper.deserialise(f);
            const sectionId: string = fragment.sectionId.value;
            const section: SectionFragment = this._fragmentService.find(UUID.orNull(sectionId)) as SectionFragment;

            if (!this.sectionProperties.has(sectionId)) {
              this.sectionProperties.set(sectionId, {
                displayString: this._fragmentIndexService.getIndex(section.id) + section.title,
                collapsed: false,
                searchResults: this._setPreviewTextHighlights(fragment),
              });
            } else {
              this.sectionProperties.get(sectionId).searchResults.push(...this._setPreviewTextHighlights(fragment));
            }
          });
          this._setSectionKeys();
          this._getNumberOfResults();
          this._drawResultRangesOnPad();
          this.searching = false;
        },
        (error: any) => {
          Logger.error('untyped-error', 'Search operation failed.', error);
          this.searching = false;
        }
      );
  }

  /**
   * Navigates to the section containing the given search result and scrolls the page so that it is visible.
   *
   * @param sectionId {string}        The id of the section containing the search result.
   * @param result    {SearchResult}  The search result to navigate to.
   */
  public goToFragment(sectionId: string, result: SearchResult): void {
    result.navigatingTo = true;
    if (!this._sectionService.getSelected() || this._sectionService.getSelected().id.value !== sectionId) {
      const section: SectionFragment = this._fragmentService.find(UUID.orNull(sectionId)) as SectionFragment;
      let segs: string[] = [];

      switch (this.currentView.viewMode) {
        case ViewMode.CHANGELOG_MARKUP:
        case ViewMode.CHANGELOG:
          segs = ['/documents', section.documentId.value, 'sections', section.id.value, 'changelog'];
          break;
        case ViewMode.CHANGELOG_AUX: {
          this._navigationService.navigateToPublishedChangelogSection(section);
          this.pendingPublishedresult = result;
          break;
        }
        default: {
          segs = ['sections', section.id.value];
          break;
        }
      }

      if (segs.length > 0) {
        this.router.navigate([...segs], {relativeTo: this.route}).then(
          () => {
            this._drawResultRangesOnPad();
            requestAnimationFrame(() => {
              this._scrollRangeIntoView(result);
              result.navigatingTo = false;
            });
          },
          () => (result.navigatingTo = false)
        );
      }
    } else {
      this._scrollRangeIntoView(result);
      result.navigatingTo = false;
    }
  }

  /**
   * Checks if the given result is in the view window and if not, scrolls it into the middle of the viewing window.
   *
   * @param result {SearchResult} The selected SearchResult.
   */
  private _scrollRangeIntoView(result: SearchResult) {
    let scrolled: boolean = false;
    this._canvasService.getRange(result.rangeHash).forEachClientRect((t: DOMRect) => {
      if (t && !scrolled) {
        const pad: HTMLElement = this._getParentElementWithClass(
          this._fragmentService.find(result.fragmentId).component.element,
          'pad-container'
        );
        if (t.top + t.height > window.innerHeight) {
          pad.scrollTop += Math.ceil(t.top) - 0.5 * window.innerHeight;
        } else {
          const padBoundingRect: DOMRect = pad.getBoundingClientRect();
          if (t.top < padBoundingRect.top) {
            pad.scrollTop += Math.ceil(t.top - padBoundingRect.top - 0.5 * window.innerHeight);
          }
        }
        scrolled = true;
      }
    });
    if (this.selectedResult !== result) {
      const previousHash: string = this._canvasService.selectSearchRange(result.rangeHash);
      result.rangeHash = CarsRangeType[CarsRangeType.SELECTED_SEARCH_RESULT];
      if (this.selectedResult && previousHash) {
        this.selectedResult.rangeHash = previousHash;
      }
      this.selectedResult = result;
    }
  }

  /**
   * This splits the value of the fragment into an array of search result with one per match found in the
   * fragment value. It also splits the fragment value up so that we can apply highlight styling to the text
   * excerpt shown in the sidebar.
   *
   * @param   fragment {Fragment} The fragment split into search results.
   * @returns {SearchResult[]}    An array of all the search results in the given fragments value.
   */
  private _setPreviewTextHighlights(fragment: Fragment): SearchResult[] {
    const r: RegExp = new RegExp(this.searchString, 'gi');
    const returnResults: SearchResult[] = [];
    let match: RegExpExecArray;
    while ((match = r.exec(fragment.value))) {
      const display: string[] = [
        (fragment.value.slice(0, match.index).length < 6 ? '' : '...') + fragment.value.slice(0, match.index).slice(-5),
        fragment.value.slice(match.index, match.index + match[0].length),
        fragment.value.slice(match.index + match[0].length, fragment.value.length),
      ];
      returnResults.push({
        fragmentId: fragment.id,
        display: display,
        startAndEnd: [match.index, match.index + match[0].length],
        rangeHash: '',
        navigatingTo: false,
      });
    }
    return returnResults;
  }

  private _setSectionKeys(): void {
    this.sectionKeys = Array.from(this.sectionProperties.keys());
  }

  /**
   * Draws a search range over each search result on the pad.
   */
  private _drawResultRangesOnPad(): void {
    this.sectionProperties.forEach((value: SectionProperty, key: string) => {
      const results: SearchResult[] = value.searchResults;
      const highlights: CarsRange[] = [];
      results.forEach((result: SearchResult) => {
        const fragment: Fragment = this._fragmentService.find(result.fragmentId);
        if (fragment) {
          const range: CarsRange = new CarsRange(
            new Caret(fragment, result.startAndEnd[0]),
            new Caret(fragment, result.startAndEnd[1]),
            CarsRangeType.SEARCH_RESULT
          );
          result.rangeHash = range.hashcode();
          highlights.push(range);
        }
      });
      this._canvasService.drawRanges(highlights);
    });
  }

  /**
   * Sets the number of results found to the total of all of the search results in each section.
   */
  private _getNumberOfResults(): void {
    let n: number = 0;
    this.sectionProperties.forEach((value: SectionProperty) => {
      n += value.searchResults.length;
    });
    this.numberOfResults = n;
  }

  /**
   * Get the first element with class which is an ancestor of this component's element.
   * Otherwise, in views which contain multiple pads using document.getElementsByClassName
   * or similar would grab the wrong element.
   */
  private _getParentElementWithClass(element: HTMLElement, elementClass: string): HTMLElement {
    let el: HTMLElement = element;
    while (el && !el.classList.contains(elementClass)) {
      el = el.parentElement;
    }
    return el;
  }
}
