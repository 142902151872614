import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {
  DocumentInformationFragment,
  DocumentInformationType,
  FigureFragment,
  Fragment,
  FragmentType,
} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {ImageService} from 'app/services/image.service';
import {DocumentInformationItem} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoUploadComponent implements OnInit, OnDestroy {
  @Input() public set documentInformationItem(documentInformationItem: DocumentInformationItem) {
    this.docInfoFragment = documentInformationItem.documentInformationFragment;
  }
  @Input() public canEdit: boolean;

  public docInfoFragment: DocumentInformationFragment;
  public documentInformationType: DocumentInformationType;
  public hasFigureFragment: boolean;

  private _subscriptions: Subscription[] = [];

  constructor(
    private fragmentService: FragmentService,
    private cdr: ChangeDetectorRef,
    private imageService: ImageService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.hasFigureFragment = !!this.getFigureFragment();
    this._subscriptions.push(
      this.fragmentService.onCreate(
        () => {
          this.hasFigureFragment = true;
          this.cdr.markForCheck();
        },
        (fragment: Fragment) => fragment.parentId.equals(this.docInfoFragment.id) && fragment.is(FragmentType.FIGURE)
      ),
      this.fragmentService.onDelete(
        () => {
          this.hasFigureFragment = false;
          this.cdr.markForCheck();
        },
        (fragment: Fragment) => fragment.parentId.equals(this.docInfoFragment.id) && fragment.is(FragmentType.FIGURE)
      )
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s && s.unsubscribe());
  }

  public getFigureFragment(): FigureFragment {
    return this.docInfoFragment.children.length === 0 ? null : (this.docInfoFragment.children[0] as FigureFragment);
  }

  public handleUpload(event: Event): void {
    const file = this.imageService.validateImage(event);

    if (!!file) {
      const newFigure: FigureFragment = new FigureFragment(UUID.random(), null, '');
      this.docInfoFragment.children.push(newFigure);
      this.hasFigureFragment = true;
      this.fragmentService.create(newFigure).then(() => {
        this.imageService.uploadImage(file, newFigure.id);
      });
    }

    this.cdr.markForCheck();
  }

  public deleteLogo(): void {
    this.dialog
      .open(DialogComponent, {
        ariaLabel: 'Confirm removal of logo',
        data: {
          title: 'Confirm removal of logo',
          message: 'Are you sure you want to delete this logo?',
          closeActions: [
            {title: 'Cancel', tooltip: 'Cancel removal of logo', response: null},
            {title: 'Delete', tooltip: 'Confirm removal of logo', color: 'primary', response: 'delete'},
          ],
        },
      })
      .afterClosed()
      .subscribe((action: string) => {
        if (action === 'delete') {
          this.fragmentService.delete(this.getFigureFragment()).then(() => {
            this.hasFigureFragment = false;
            this.cdr.markForCheck();
          });
        }
      });
  }
}
