import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Caret} from 'app/fragment/caret';
import {CarsRange, CarsRangeType} from 'app/fragment/cars-range';
import {
  ClauseFragment,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  Fragment,
  FragmentType,
  SectionFragment,
} from 'app/fragment/types';
import {BaseService} from 'app/services/base.service';
import {CanvasService} from 'app/services/canvas.service';
import {FragmentService} from 'app/services/fragment.service';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {Callback} from 'app/utils/typedefs';
import {environment} from 'environments/environment';
import {Hunspell, HunspellFactory} from 'hunspell-asm';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {CharacterSetRegex} from './character-set-regex';
import {HunspellModuleWrapper} from './hunspell-load-module-wrapper';

/**
 * An enumeration of the required dictionary files used by the Hunspell checker.
 */
export enum DictionaryFile {
  AFFIX = 'affix', // The affix file
  DICTIONARY = 'dictionary', // The dictionary file
}

@Injectable({
  providedIn: 'root',
})
export class SpellCheckerService extends BaseService {
  private static MAX_WORD_LENGTH: number = 25;

  private static DICTIONARY_ENDPOINT: string = `${environment.apiHost}/dictionaries`;
  private static LANGUAGE: string = environment.language;

  private _clauseSet: Map<string, ClauseFragment> = new Map<string, ClauseFragment>(); // Set of clauses to be re-validated.

  private _initialisedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _updateSubject: Subject<ClauseFragment[]> = new Subject<ClauseFragment[]>();

  private _hunspellFactory: HunspellFactory;
  private _spellChecker: Hunspell;

  private _affFile: string = '';
  private _dictFile: string = '';

  /**
   * @returns {boolean} True if native wasm is supported in the browser.
   */
  public static isWasmEnabled(): boolean {
    const _window: any = window;
    return !!_window.WebAssembly && !!_window.WebAssembly.compile && !!_window.WebAssembly.instantiate;
  }

  constructor(
    private _http: HttpClient,
    private _canvasService: CanvasService,
    private _fragmentService: FragmentService,
    protected _snackbar: MatSnackBar
  ) {
    super(_snackbar);
    if (LocalConfigUtils.getConfig().offline) {
      return;
    }

    HunspellModuleWrapper.loadModule({}).then((factory: HunspellFactory) => {
      this._hunspellFactory = factory;
      this._initChecker();
    });

    this._fragmentService.onUpdate((f: Fragment) => {
      const clause: ClauseFragment = f.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
      if (clause) {
        this._clauseSet.set(clause.id.value, clause);
      }
      const clauses: ClauseFragment[] = Array.from(this._clauseSet.values());
      this._updateSubject.next(clauses);
    });

    this._fragmentService.onCreate(
      (clause: ClauseFragment) => {
        this._clauseSet.set(clause.id.value, clause);
        const clauses: ClauseFragment[] = Array.from(this._clauseSet.values());
        this._updateSubject.next(clauses);
      },
      (f: Fragment) => f.is(FragmentType.CLAUSE)
    );

    this._updateSubject.pipe(debounceTime(environment.spellCheckDebounce)).subscribe(this._onUpdateCallback.bind(this));
  }

  /**
   * Initialises the Hunspell checker.
   */
  private _initChecker(): void {
    const promises: Promise<void>[] = [];

    promises.push(this._fetchFile(DictionaryFile.AFFIX), this._fetchFile(DictionaryFile.DICTIONARY));

    Promise.all(promises)
      .then(() => {
        this._spellChecker = this._hunspellFactory.create(this._affFile, this._dictFile);
        this._initialisedSubject.next(true);
      })
      .catch((err: any) => {
        this._initialisedSubject.next(false);
        this._handleError(err, 'Unable to initialise spell checker', 'spelling-error');
      });
  }

  /**
   * Fetches the affix/dic files required for the Hunspell checker.
   *
   * @param type {DictionaryFile} Dictionary file type to fetch
   * @returns    {Promise<void>}  Promise from HTTP request
   */
  private _fetchFile(type: DictionaryFile): Promise<void> {
    const endPoint: string = `${SpellCheckerService.DICTIONARY_ENDPOINT}/${type}?language=${SpellCheckerService.LANGUAGE}`;

    return this._http
      .get(endPoint, {responseType: 'arraybuffer'})
      .toPromise()
      .then((stream: ArrayBuffer) => {
        const buffer: Uint8Array = new Uint8Array(stream);
        const file: string = this._hunspellFactory.mountBuffer(buffer);

        type === DictionaryFile.AFFIX ? (this._affFile = file) : (this._dictFile = file);
      });
  }

  /**
   * Re-validates clauses updated in the last set.
   *
   * @param clauses {ClauseFragment[]} Clauses to re-validate
   */
  private _onUpdateCallback(clauses: ClauseFragment[]): void {
    this._clauseSet.clear();
    clauses.forEach((c: ClauseFragment) => this.validateClause(c));
  }

  /**
   * Subscribes to when the Hunspell checker is intialised.
   *
   * @param callback {Callback<boolean>} The callback
   * @returns        {Subscription}      The subcription
   */
  public onReady(callback: Callback<boolean>): Subscription {
    return this._makeSubscription(this._initialisedSubject, callback);
  }

  /**
   * Spell-checks the clause and new errors are drawn on the pad.
   *
   * @param clause {ClauseFragment} Clause to validate
   */
  public validateClause(clause: ClauseFragment): void {
    const errors: CarsRange[] = this._validate(clause);

    this._canvasService.drawClauseSpellingErrors(clause, errors);
  }

  /**
   * Spell checks the section and new errors are drawn on the pad.
   *
   * @param section {SectionFragment} Section to validate
   */
  public validateSection(section: SectionFragment): void {
    const errorPerClause: Map<string, CarsRange[]> = new Map();
    section.getClauses().forEach((clause: ClauseFragment) => {
      errorPerClause.set(clause.id.value, this._validate(clause));
    });

    this._canvasService.drawSectionSpellingErrors(errorPerClause);
  }

  /**
   * Spell-checks this fragment and all it's children.
   *
   * @param fragment {Fragment}    Fragment to validate
   * @returns        {CarsRange[]} Ranges for which spelling errors are valid
   */
  private _validate(fragment: Fragment): CarsRange[] {
    const errors: CarsRange[] =
      fragment.hasValue() && this._isValidFragment(fragment) ? [...this._getErrorRanges(fragment)] : [];

    fragment.children.forEach((child: Fragment) => errors.push(...this._validate(child)));

    return errors;
  }

  /**
   * Extracts spelling errors for given fragment.
   *
   * @param fragment {Fragment}        The fragment to check for spelling errors
   * @returns        {CarsRange[]}     Range for spelling errors in the fragment
   */
  private _getErrorRanges(fragment: Fragment): CarsRange[] {
    const ranges: CarsRange[] = [];
    let offset: number = 0;

    if (fragment.hasValue()) {
      const value: string = fragment.value;
      for (let i: number = 0; i <= fragment.value.length; ++i) {
        if (CharacterSetRegex.shouldMatch(value, i)) {
          const word: string = value.slice(offset, i);
          if (!this.spell(word)) {
            const carets: Caret[] = [new Caret(fragment, offset), new Caret(fragment, offset + word.length)];
            const range: CarsRange = CarsRange.fromArray(carets, CarsRangeType.SPELLING_ERROR);
            ranges.push(range);
          }
          offset = i + 1;
        }
      }
    }

    return ranges;
  }

  /**
   * Spell-checks the given word.  If the word contains a number or is uppercase, ignore.
   *
   * If the spellchecker failed to initialise, also ignore.
   *
   * @param word {string}   Word to spell-check
   * @returns    {boolean}  True if correctly spelt
   */
  public spell(word: string): boolean {
    return (
      !this._spellChecker || /\d/.test(word) || word === word.toLocaleUpperCase() || this._spellChecker.spell(word)
    );
  }

  /**
   * Returns spelling suggestions for the given range.
   *
   * @param range {CarsRange} Spell check word in given range
   * @returns     {string[]}  List of suggested words
   */
  public getSpellingSuggestions(range: CarsRange): string[] {
    const word: string = range.value;
    if (!this._spellChecker || word.length > SpellCheckerService.MAX_WORD_LENGTH) {
      return [];
    }
    return this._spellChecker.suggest(word);
  }

  /**
   * Unmounts files from in-memory filesystem.
   * Disposes of spell-checker, allowing other instances to be created via `HunspellFactory::create`.
   */
  public disposeChecker(): void {
    this._hunspellFactory.unmount(this._affFile);
    this._hunspellFactory.unmount(this._dictFile);
    this._spellChecker.dispose();
    this._initialisedSubject.next(false);
  }

  /**
   * Checks if the given fragment should have it's value extracted.
   *
   * @param fragment {Fragment} The Fragment to check
   * @returns        {boolean}  True if this fragment is valid to extract
   */
  private _isValidFragment(fragment: Fragment): boolean {
    return fragment.is(...EDITABLE_TEXT_FRAGMENT_TYPES);
  }
}
