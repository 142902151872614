import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {ClauseFragment, ClauseType} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {VersionTag} from 'app/interfaces';
import {FragmentService} from 'app/services/fragment.service';
import {UUID} from 'app/utils/uuid';
import {ViewService} from 'app/view/view.service';

@Component({
  selector: 'cars-clause-group-link-preview',
  templateUrl: './clause-group-link-preview.component.html',
  styleUrls: ['./clause-group-link-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseGroupLinkPreviewComponent {
  @Input() public set clauseGroupId(clauseGroupId: UUID) {
    this._setClausePreviewsForGroupId(clauseGroupId);
  }

  public clausePreviews: string[] = [];

  constructor(
    private _fragmentService: FragmentService,
    private _fragmentIndexService: FragmentIndexService,
    private _viewService: ViewService
  ) {}

  private _setClausePreviewsForGroupId(clauseGroupId: UUID): string[] {
    const currentViewVersionTag: VersionTag = this._viewService.getCurrentView()?.versionTag;
    const versionTagValidAt: number = !!currentViewVersionTag ? currentViewVersionTag.createdAt : null;

    const clauseGroup: ClauseGroupFragment = this._fragmentService.find(
      clauseGroupId,
      versionTagValidAt
    ) as ClauseGroupFragment;

    if (!clauseGroup) {
      this.clausePreviews = [];
      return;
    }

    this.clausePreviews = clauseGroup
      .getClauses()
      .filter((clause: ClauseFragment) => clause.isClauseOfType(ClauseType.REQUIREMENT))
      .map((clause: ClauseFragment) => {
        clause.calculateClausePreview();
        const index: string = this._fragmentIndexService.getIndex(clause);

        return index + '  ' + clause.preview;
      });
  }
}
