import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ClauseGroupLinkPreviewComponent} from './clause-group-link-preview.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ClauseGroupLinkPreviewComponent],
  exports: [ClauseGroupLinkPreviewComponent],
})
export class ClauseGroupLinkPreviewModule {}
