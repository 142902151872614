import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {LinkCreationWizardComponent} from './link-creation-wizard.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatTooltipModule, CarsErrorHandlingModule, MatIconModule],
  declarations: [LinkCreationWizardComponent],
  exports: [LinkCreationWizardComponent],
})
export class LinkCreationWizardModule {}
