import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {ErrorDialogComponent} from 'app/dialog/error-dialog/error-dialog.component';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {FragmentService} from 'app/services/fragment.service';
import {AuthenticationProvider} from 'app/services/user/authentication-provider';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {VERSION} from '../environments/version';
import {Key} from './fragment/key';
import {CarsVersionCheckerService} from './services/cars-version-checker.service';
import {UserService} from './services/user/user.service';
import {Browser} from './utils/browser';

@Component({
  selector: 'cars-root',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CarsComponent implements OnInit, OnDestroy {
  public connection: boolean = false;
  public authenticated: boolean = false;

  private _subscriptions: Subscription[] = [];
  private readonly welcomeMessage: string = `<img src="assets/cars.svg" />
    <h1>Welcome to CARS</h1>
    <p>
      <strong>Please note that CARS works best with the Chrome and Firefox browsers.
      <font color="#e31b23">Microsoft Internet Explorer is not supported.</font></strong>
    </p>
    <p>
      The Collaborative Authoring and Review System (CARS) has been designed specifically to support the development and review of
      requirements documents. It enables authors to create and maintain requirements documents in line with defined rules and best
      practice; to collaborate with colleagues in real-time; and to share content with reviewers in order to facilitate the exchange and
      resolution of discussions at a clause level.
    </p>
    <p>
      The "Help Pages" link at the top right of the screen provides access to videos and other guidance material covering all aspects of
      CARS. Please use the "Contact Support" button to report any problems you find with the software.
    </p>
    <p>
     Please note that CARS uses cookies to store your authoring/reviewing preferences. You can view the cookie policy <a target="_blank" href="https://help.futuredmrb.co.uk/cookie-policy/">here</a>.
    </p>
    <p>
      Click "Get Started" to see the documents that are ready for you to collaborate on.
    </p>`;

  // Error dialog handler
  public errorDialogOpen: boolean = false;
  public dialogRef: MatDialogRef<ErrorDialogComponent>;
  public afterCloseSub: Subscription;

  public isToggleChecked: boolean = false;
  public showDefaultFocusStyle: boolean = false;

  /* eslint-disable @typescript-eslint/no-unused-vars */
  constructor(
    private webSocketService: WebSocketService,
    private fragmentService: FragmentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private authProvider: AuthenticationProvider,
    private route: ActivatedRoute,
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry,
    private zone: NgZone,
    private versionChecker: CarsVersionCheckerService
  ) {
    this.unregisterServiceWorkers();

    // add custom material icons
    matIconRegistry.addSvgIcon(
      'bottom_left',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_align_bottom_left.svg')
    );
    matIconRegistry.addSvgIcon(
      'bottom_middle',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_align_bottom_middle.svg')
    );
    matIconRegistry.addSvgIcon(
      'bottom_right',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_align_bottom_right.svg')
    );
    matIconRegistry.addSvgIcon(
      'middle_left',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_align_middle_left.svg')
    );
    matIconRegistry.addSvgIcon(
      'middle_middle',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_align_middle_middle.svg')
    );
    matIconRegistry.addSvgIcon(
      'middle_right',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_align_middle_right.svg')
    );
    matIconRegistry.addSvgIcon(
      'top_left',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_align_top_left.svg')
    );
    matIconRegistry.addSvgIcon(
      'top_middle',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_align_top_middle.svg')
    );
    matIconRegistry.addSvgIcon(
      'top_right',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_align_top_right.svg')
    );
    matIconRegistry.addSvgIcon(
      'delete_column',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_delete_column.svg')
    );
    matIconRegistry.addSvgIcon(
      'delete_row',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_delete_row.svg')
    );
    matIconRegistry.addSvgIcon(
      'insert_above',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_insert_above.svg')
    );
    matIconRegistry.addSvgIcon(
      'insert_below',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_insert_below.svg')
    );
    matIconRegistry.addSvgIcon(
      'insert_left',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_insert_left.svg')
    );
    matIconRegistry.addSvgIcon(
      'insert_right',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_insert_right.svg')
    );
    matIconRegistry.addSvgIcon(
      'merge_cells',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_merge_cells.svg')
    );
    matIconRegistry.addSvgIcon(
      'split_horizontally',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_split_horizontally.svg')
    );
    matIconRegistry.addSvgIcon(
      'split_vertically',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/table_icons/table_split_vertically.svg')
    );
    matIconRegistry.addSvgIcon('error', domSanitizer.bypassSecurityTrustResourceUrl('/assets/warn_icons/error.svg'));
    matIconRegistry.addSvgIcon(
      'warning',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/warn_icons/warning.svg')
    );
    matIconRegistry.addSvgIcon('info', domSanitizer.bypassSecurityTrustResourceUrl('/assets/warn_icons/info.svg'));
    matIconRegistry.addSvgIcon(
      'create_variable_table',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/variable_table_icons/create_variable_table.svg')
    );
    matIconRegistry.addSvgIcon(
      'delete_variable_table',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/variable_table_icons/delete_variable_table.svg')
    );
    matIconRegistry.addSvgIcon('authoring', domSanitizer.bypassSecurityTrustResourceUrl('/assets/authoring_icon.svg'));
    matIconRegistry.addSvgIcon(
      'units_icon',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/sidebar_icons/units_icon.svg')
    );
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  /**
   * Initialise this component.
   */
  public ngOnInit(): void {
    this._subscriptions.push(
      this.webSocketService.onConnection((connection: boolean) => {
        if (connection === true) {
          const user = this.authProvider.getCurrentUser();
          this.userService.getActiveSessions(user.id).subscribe((res: number) => this._handleActiveSessions(res));

          this._ensureSupported();
        }
        this.connection = connection;
      }),
      this.fragmentService.onFailure((error: HttpErrorResponse, failedIds: UUID[]) => {
        if (!this.errorDialogOpen) {
          this.openErrorDialog(error, failedIds);
        }
      })
    );

    this._printVersion();
    this._ensureAuthenticated();

    // Prevent the backspace key from navigating backwards for old browsers
    this.zone.runOutsideAngular(() => {
      document.addEventListener(
        'keydown',
        function (e) {
          if (e.key === 'Backspace') {
            if (
              !document.activeElement ||
              (document.activeElement.nodeName !== 'INPUT' &&
                document.activeElement.nodeName !== 'TEXTAREA' &&
                document.activeElement['contentEditable'] !== 'true')
            ) {
              e.stopPropagation();
              e.preventDefault();
            }
          }
        },
        true
      );
    });

    if (localStorage.getItem('cars-show-accessibility-focus') === 'true') {
      this.isToggleChecked = true;
      this.showDefaultFocusStyle = true;
    }
  }

  /**
   * Destroy the application
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Check the user has a supported browser, displaying a snackbar if not.
   */
  private _ensureSupported(): void {
    const localConfig = LocalConfigUtils.getConfig();
    if (!localConfig.shownWelcome || Browser.isInternetExplorer()) {
      this.dialog
        .open(DialogComponent, {
          ariaLabel: 'CARS welcome dialog',
          width: '600px',
          disableClose: true,
          closeOnNavigation: false,
          data: {
            title: '',
            message: this.welcomeMessage,
            closeActions: [
              {title: 'Get Started', color: 'primary', tooltip: 'Close welcome dialog', response: 'close'},
            ],
          },
        })
        .afterClosed()
        .subscribe(() => {
          LocalConfigUtils.setShownWelcome(true);
        });
    }
  }

  /**
   * Opens the error dialog if it is not already open.
   *
   * @param error     {HttpErrorResponse} The HTTP error response
   * @param failedIds {UUID[]}            The array of the UUIDs of the unsaved fragments.
   */
  public openErrorDialog(error: HttpErrorResponse, failedIds: UUID[]): void {
    this.dialogRef = this.dialog.open(ErrorDialogComponent, {
      ariaLabel: 'Error dialog',
      width: '800px',
      disableClose: true,
      data: {error, failedIds},
    });
    if (this.dialogRef) {
      this.errorDialogOpen = true;
      this.afterCloseSub = this.dialogRef.afterClosed().subscribe((result) => {
        this.errorDialogOpen = false;
        this.dialogRef = null;
        this.afterCloseSub.unsubscribe();
      });
    }
  }

  /**
   * Ensure the user is authenticated before establishing the websocket connection.
   */
  private _ensureAuthenticated(): void {
    this.authProvider.isAuthenticated().subscribe((auth) => {
      this.authenticated = auth;
      if (auth) {
        // Once authenticated, remove the fragment if it changes.
        this.route.fragment.subscribe((fragment: string) => {
          window.location.hash = '';
        });

        this.webSocketService.connect();
      }
    });
  }

  /**
   * If the user has more than 1 active sessions, warn them with a snackbar.
   * @param {number} numberOfSessionsActive Number of active sessions
   */
  private _handleActiveSessions(numberOfSessionsActive: number) {
    if (numberOfSessionsActive > 1 && !this.errorDialogOpen) {
      this.snackBar.open(`You have ${numberOfSessionsActive} CARS sessions open`, 'Dismiss', {
        duration: 4000,
      });
    }
  }

  /**
   * Print the version info.
   */
  private _printVersion(): void {
    const words: string[] = [
      `> ${VERSION.name} `,
      VERSION.version !== 'undefined' ? `v${VERSION.version} ` : ' ',
      `(`,
      VERSION.pipeline !== 'undefined' ? `pipeline ${VERSION.pipeline}, ` : '',
      `branch '${VERSION.branch}', `,
      `revision ${VERSION.sha}`,
      `).`,
    ];

    // eslint-disable-next-line no-console
    console.info(words.join(''));
  }

  /**
   * Uninstalls all service workers.
   */
  private unregisterServiceWorkers(): void {
    if (navigator.serviceWorker) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          const workers: number = registrations.length;

          Promise.all(registrations.map((r) => r.unregister()))
            .then(() => {
              if (workers > 0) {
                Logger.analytics('removed-service-workers');
                location.reload();
              }
            })
            .catch((err) => {
              Logger.error('service-worker-error', 'Failed to unregister service worker', err);
            });
        })
        .catch((err) => {
          Logger.error('service-worker-error', 'Failed to get service worker registrations', err);
        });
    }
  }

  public onAccessibilityToggle(value: boolean): void {
    this.isToggleChecked = value;
    if (!this.isToggleChecked) {
      this.showDefaultFocusStyle = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  public handleTab(event: KeyboardEvent): void {
    if (this.isToggleChecked) {
      const key: Key = event ? Key.fromEvent(event) : null;
      this.showDefaultFocusStyle = key?.equalsUnmodified(Key.TAB) || this.showDefaultFocusStyle;
    }
  }

  @HostListener('document:mousedown', ['$event'])
  public handleMouseDown(event: MouseEvent): void {
    this.showDefaultFocusStyle = false;
  }
}
