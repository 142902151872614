import {Component, ElementRef, Input, Renderer2} from '@angular/core';
import {AbstractHovertipComponent} from 'app/widgets/abstract-hovertip.component';

@Component({
  selector: 'cars-discussion-version-hovertip',
  templateUrl: './discussion-version-hovertip.component.html',
  styleUrls: ['./discussion-version-hovertip.component.scss'],
})
export class DiscussionVersionHovertipComponent extends AbstractHovertipComponent {
  @Input() public versionName: string;

  /**
   * @Override
   */
  protected readonly leftOffset: number = -10;
  protected readonly topOffset: number = 10;

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }
}
