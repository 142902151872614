import {Fragment} from 'app/fragment/types';
import {ComparisonOperator} from './comparison-operator';

export class ParsedRequiredValuePattern {
  constructor(
    private readonly operator: ComparisonOperator,
    private readonly compareValue: number,
    private readonly fieldPath: string[]
  ) {}

  /**
   * Return true if the value defined on the fragment:
   * - Exists, and
   * - Is non-empty, and
   * - Has a value that correctly compares to the compare value as defined by the operator
   */
  public fieldHasValidValue(fragment: Fragment): boolean {
    let field: any = fragment;
    for (const pathSegment of this.fieldPath) {
      field = field[pathSegment];
      if (this._fieldIsFalsyOrEmpty(field)) {
        return false;
      }
    }
    return this._isValidValue(+field);
  }

  private _fieldIsFalsyOrEmpty(field: any): boolean {
    if (!field && field !== 0) {
      return true;
    } else if (typeof field === 'string') {
      return field.replace(/\s|\u200b/g, '').length === 0;
    } else {
      return false;
    }
  }

  private _isValidValue(value: number): boolean {
    switch (this.operator) {
      case ComparisonOperator.GREATER_THAN_OR_EQUAL_TO:
        return value >= this.compareValue;
      case ComparisonOperator.LESS_THAN_OR_EQUAL_TO:
        return value <= this.compareValue;
      case ComparisonOperator.GREATER_THAN:
        return value > this.compareValue;
      case ComparisonOperator.LESS_THAN:
        return value < this.compareValue;
      case ComparisonOperator.EQUAL_TO:
        return value === this.compareValue;
      case ComparisonOperator.NOT_EQUAL_TO:
        return value !== this.compareValue;
      default:
        return false;
    }
  }
}
