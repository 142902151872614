import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FragmentIndexModule} from 'app/fragment/indexing/fragment-index.module';
import {AltTextModule} from './alt-text/alt-text.module';
import {BackgroundModule} from './background/background.module';
import {ClauseLinksModule} from './clause-links/clause-links.module';
import {ClauseReferencesModule} from './clause-references/clause-references.module';
import {DiscussionsModule} from './discussions/discussions.module';
import {HistoryModule} from './history/history.module';
import {ReferencesModule} from './references/references.module';
import {ReorderModule} from './reorder/reorder.module';
import {SectionReferencesModule} from './section-references/section-references.module';
import {SidebarComponent} from './sidebar.component';
import {UnitSelectionModule} from './units/unit-selection.module';
import {ValidationErrorsModule} from './validation-errors/validation-errors.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    BackgroundModule,
    DiscussionsModule,
    ValidationErrorsModule,
    HistoryModule,
    ReferencesModule,
    ClauseReferencesModule,
    ReorderModule,
    SectionReferencesModule,
    FragmentIndexModule,
    ClauseLinksModule,
    UnitSelectionModule,
    AltTextModule,
  ],
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
})
export class SidebarModule {}
