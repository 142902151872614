<span *ngFor="let clauseContent of content" [ngSwitch]="clauseContent.getIdentifier()">
  <cars-tdif-text
    *ngSwitchCase="'text'"
    [class.inline]="displayInline(clauseContent)"
    [value]="clauseContent.value"
    [properties]="clauseContent.properties"
    [class]="'inline'"
    [ngClass]="getTextStyling(clauseContent.properties)"
  ></cars-tdif-text>
  <cars-tdif-equation
    *ngSwitchCase="'equation'"
    [class.inline-flex]="displayInline(clauseContent)"
    [image]="clauseContent.image"
    [text]="clauseContent.text"
    [index]="clauseContent.index"
  ></cars-tdif-equation>
  <cars-tdif-figure
    *ngSwitchCase="'figure'"
    [class.inline]="displayInline(clauseContent)"
    [index]="clauseContent.index"
    [caption]="clauseContent.caption"
    [imageUrl]="clauseContent.imageUrl"
  ></cars-tdif-figure>
  <cars-tdif-table
    *ngSwitchCase="'table'"
    [class.inline]="displayInline(clauseContent)"
    [index]="clauseContent.index"
    [caption]="clauseContent.caption"
    [orientation]="clauseContent.orientation"
    [hasHeaderRow]="clauseContent.hasHeaderRow"
    [columnWidths]="clauseContent.columnWidths"
    [rows]="clauseContent.rows"
  ></cars-tdif-table>
  <cars-tdif-list
    *ngSwitchCase="'list'"
    [class.inline]="displayInline(clauseContent)"
    [ordered]="clauseContent.ordered"
    [items]="clauseContent.items"
  >
  </cars-tdif-list>
  <cars-tdif-inline-reference
    *ngSwitchCase="'reference'"
    [class.inline]="displayInline(clauseContent)"
    [value]="clauseContent.value"
  ></cars-tdif-inline-reference>
  <cars-tdif-input
    *ngSwitchCase="'input'"
    [content]="clauseContent.content"
    [placeholderValue]="clauseContent.placeholderValue"
  ></cars-tdif-input>
  <cars-tdif-unit-input
    *ngSwitchCase="'unitInput'"
    [image]="clauseContent.image"
    [equationRawText]="clauseContent.text"
    [class]="'unit-input'"
  ></cars-tdif-unit-input>
</span>
