<div class="content">
  <div class="info">
    <span class="author">
      <cars-user [userId]="transition.userId"></cars-user>
    </span>
    <span class="datestamp"> - {{ transition.date | date: dateFormat }}</span>
  </div>
  <ul>
    <li *ngFor="let string of transition.strings">{{ string }}</li>
  </ul>
</div>
