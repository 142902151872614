<ng-container *ngIf="!!document">
  <div
    class="readOnly-box"
    *ngIf="CarsAction.SHOW_PUBLISHED_INFORMATION_IN_OVERVIEW | carsEnabled : document.id | async"
  >
    <p><mat-icon>info</mat-icon><span class="readOnly-title">Information</span></p>
    <p class="readOnly-information">
      This document is read only as it has been submitted for approval. TAGG are able to reassign authoring permissions
      to specific users if required as part of the final approval checks.
    </p>
  </div>
  <div
    class="readOnly-box"
    *ngIf="CarsAction.SHOW_FOR_PUBLICATION_REDIRECT_FROM_LIVE_OVERVIEW | carsEnabled : document.id | async"
  >
    <p><mat-icon>info</mat-icon><span class="readOnly-title">Version publication</span></p>
    <p class="readOnly-information">
      This document is read only as there is a
      <a [routerLink]="latestVersionRoute" aria-label="Go to For approval/authorisation version">version</a> that has
      been marked as ‘For approval/authorisation’. TAGG are able to complete the publication or mark if more changes
      required, both of which will unlock the document for editing.
    </p>
  </div>
  <div
    class="live-document-information"
    *ngIf="CarsAction.IS_HISTORICAL_VIEW_MODE | carsDisabled : document.id | async"
  >
    The information on this page will be used to automatically generate the title page of this document, as well as for
    managing who can view/edit the document.
  </div>
  <cars-internal-reference-refresh
    *ngIf="CarsAction.SHOW_INTERNAL_REFERENCE_REFRESH_IN_OVERVIEW | carsEnabled : document.id | async"
    [document]="document"
  ></cars-internal-reference-refresh>
  <cars-version-settings
    *ngIf="CarsAction.IS_HISTORICAL_VIEW_MODE | carsEnabled : document.id | async"
  ></cars-version-settings>
  <cars-version-information
    *ngIf="CarsAction.IS_HISTORICAL_VIEW_MODE | carsEnabled : document.id | async"
  ></cars-version-information>
  <cars-version-publication-options
    *ngIf="CarsAction.SHOW_VERSION_PUBLICATION_INFORMATION_IN_OVERVIEW | carsEnabled : document.id | async"
    [documentId]="document.id"
  ></cars-version-publication-options>
  <cars-version-review-status-toggle
    *ngIf="CarsAction.SHOW_REVIEW_STATUS_TOGGLE_IN_OVERVIEW | carsEnabled : document.id | async"
  ></cars-version-review-status-toggle>
  <div
    class="imported-document-information"
    *ngIf="CarsAction.SHOW_IMPORTED_INFORMATION_IN_OVERVIEW | carsEnabled : document.id | async"
  >
    This document is marked as being <strong>imported</strong>.
  </div>
  <div *ngIf="document.documentData.administration">
    This is a <strong>National Application Annex</strong> Document.
  </div>
</ng-container>
