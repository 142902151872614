<div class="link-wizard">
  <button
    mat-icon-button
    class="close-button"
    (click)="close()"
    matTooltip="Cancel change creation"
    matTooltipPosition="before"
    [matTooltipShowDelay]="tooltipDelay"
    carsAnalytics="changelog-cancel-link-creation"
    aria-label="Cancel change creation"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <h3>Link creation in progress</h3>
  <div>Create a log entry by selecting where the text is going in the new document.</div>
  <div>To delete it, close this popup and select the bin icon while hovering over the selection instead.</div>
</div>
