<mat-form-field appearance="outline" [floatLabel]="'never'">
  <mat-icon matPrefix>search</mat-icon><input matInput placeholder="Filter documents" />
</mat-form-field>
<ng-container *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <div class="loading-message">Loading documents</div>
</ng-container>
<p *ngIf="!loading && !total && !searchParams.searchTerm">You have not raised or commented on any discussions.</p>
<p *ngIf="!loading && !total && searchParams.searchTerm">No documents match your search.</p>
<mat-list *ngIf="total && documentDiscussionsAggregates && documentDiscussionsAggregates.length > 0">
  <ng-container *ngFor="let documentDiscussionsAggregate of documentDiscussionsAggregates">
    <mat-card
      [class.selected]="selected[documentDiscussionsAggregate.documentId.value]"
      (click)="selectRow(documentDiscussionsAggregate)"
      tabindex="0"
      (keyup.enter)="selectRow(documentDiscussionsAggregate)"
    >
      <div class="card-head">
        <div
          class="truncate-text card-head-text"
          matTooltipPosition="above"
          [matTooltip]="documentDiscussionsAggregate.documentTitle"
          [matTooltipShowDelay]="tooltipDelay"
        >
          {{ documentDiscussionsAggregate.documentTitle }}
        </div>
        <mat-chip-list *ngIf="documentDiscussionsAggregate.role" [tabIndex]="-1">
          <mat-chip disabled="true">
            <mat-icon>{{ roleData[documentDiscussionsAggregate.role].icon }}</mat-icon>
            <div>{{ roleData[documentDiscussionsAggregate.role].displayName }}</div>
          </mat-chip>
        </mat-chip-list>
      </div>

      <div
        class="truncate-text card-body"
        [matTooltip]="getDocumentCode(documentDiscussionsAggregate)"
        [matTooltipShowDelay]="tooltipDelay"
      >
        {{ getDocumentCode(documentDiscussionsAggregate) }}
      </div>
      <cars-version-aggregates-list
        *ngIf="expanded[documentDiscussionsAggregate.documentId.value]"
        [documentId]="documentDiscussionsAggregate.documentId"
        [dashboardType]="dashboardType"
      ></cars-version-aggregates-list>
    </mat-card>
  </ng-container>
</mat-list>
<mat-paginator [length]="total" [pageSize]="searchParams.pageSize"></mat-paginator>
