import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {ClauseFragment, ClauseType, FigureFragment, Fragment, FragmentType, SectionFragment} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {FragmentFetchParams} from 'app/services/fragment.service';
import {SectionService} from 'app/services/section.service';
import {ClauseTypeConfiguration, ConfigurationService} from 'app/suite-config/configuration.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {UUID} from '../../utils/uuid';

export class Transition {
  constructor(public userId: UUID, public date: Date, public strings: string[]) {}
}

interface HistoryEntry {
  version: ClauseFragment;
  transition: Transition;
}

@Component({
  selector: 'cars-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryComponent implements OnChanges, OnDestroy {
  @Input() public clause: ClauseFragment = null;

  public readonly dateFormat: string = 'MMM dd yyyy, HH:mm';
  public readonly tooltipDelay: number = environment.tooltipDelay;

  public readonly historyOptions: Readonly<{name: string; params: FragmentFetchParams}[]> = [
    {name: 'The last 24 hours', params: VersioningService.LAST_24_HOURS},
    {name: 'The last 7 days', params: VersioningService.LAST_7_DAYS},
    {name: 'The last 31 days', params: VersioningService.LAST_MONTH},
    {name: 'All', params: VersioningService.DEFAULT_FETCH_PARAMS},
  ];

  public historyEntries: HistoryEntry[] = [];

  public loading: boolean = false;
  public showDetails: boolean = false;

  public currentOption: FragmentFetchParams = VersioningService.LAST_24_HOURS;

  private _clauseNames: Partial<Record<ClauseType, string>> = {};
  private _subscriptions: Subscription[] = [];

  constructor(
    private _clauseVersioningService: VersioningService<ClauseFragment>,
    private _clauseGroupVersioningService: VersioningService<ClauseGroupFragment>,
    private _sectionService: SectionService,
    private _configurationService: ConfigurationService,
    private _cdr: ChangeDetectorRef
  ) {
    this._subscriptions.push(this._sectionService.onSelection(this._getClauseTypeNames.bind(this)));
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Respond to binding changes by updating versions and transitions.
   */
  public ngOnChanges(): void {
    this.refresh();
  }

  /**
   * Refresh the version history.
   */
  public refresh(): void {
    this.loading = true;
    this._cdr.markForCheck();

    if (this.clause) {
      this._fetchClauseVersions()
        .then((versions: ClauseFragment[]) => this._setVersions(versions))
        .catch((err) => {
          Logger.error('history-error', 'Failed to get clause history', err);
          this._setVersions([]);
        });
    } else {
      this._setVersions([]);
    }
  }

  private async _fetchClauseVersions(): Promise<ClauseFragment[]> {
    const clauseVersions: ClauseFragment[] = await this._clauseVersioningService.fetch(
      this.clause.id,
      this.currentOption
    );

    const clauseGroupVersionCache: Record<string, ClauseGroupFragment[]> = {};

    for (let i = clauseVersions.length - 1; i > -1; i--) {
      let clauseVersion: ClauseFragment = clauseVersions[i];

      if (!clauseVersion.sectionId.equals(clauseVersion.parentId)) {
        const clauseGroupId: UUID = clauseVersion.parentId;

        while (clauseVersion?.parentId.equals(clauseGroupId)) {
          clauseVersions.splice(i, 1);
          i--;
          clauseVersion = clauseVersions[i];
        }

        i++;

        let clauseGroupVersions: ClauseGroupFragment[] = clauseGroupVersionCache[clauseGroupId.value];

        if (!clauseGroupVersions) {
          clauseGroupVersions = await this._clauseGroupVersioningService.fetch(clauseGroupId, this.currentOption);
          clauseGroupVersionCache[clauseGroupId.value] = clauseGroupVersions;
        }

        const newClauseVersions: ClauseFragment[] = clauseGroupVersions
          .map((clauseGroup: ClauseGroupFragment) =>
            clauseGroup.getClauses().find((clause: ClauseFragment) => clause.equals(this.clause))
          )
          .filter((version: ClauseFragment) => {
            if (!version) {
              return false;
            }

            const isBeforeNextVersion: boolean =
              !clauseVersion || (version.validTo ?? Infinity) <= clauseVersion.validFrom;

            const previousVersion: ClauseFragment = clauseVersions[i];
            const isAfterPreviousVersion: boolean =
              !previousVersion || version.validFrom >= (previousVersion.validTo ?? Infinity);

            return isBeforeNextVersion && isAfterPreviousVersion;
          });

        clauseVersions.splice(i, 0, ...newClauseVersions);
      }
    }

    return clauseVersions;
  }

  /**
   * Set the currently displayed versions and transitions.
   *
   * @param versions {ClauseFragment[]}   The current versions
   */
  private _setVersions(versions: ClauseFragment[]): void {
    this.historyEntries = versions.map((version: ClauseFragment) => {
      return {version, transition: null};
    });

    this._inferTransitions();
    this._filterOutDuplicateVersions();

    this._filterIfNotShowingDetails();

    this.loading = false;

    this._cdr.markForCheck();
  }

  /**
   * Infer transitions that have happened alongside clause versions.
   * Note that versions are given from newest to oldest.
   *
   * @param versions {ClauseFragment[]}   The current versions
   * @returns        {Transition[]}       The transitions
   */
  private _inferTransitions(): void {
    for (let i: number = 0; i < this.historyEntries.length - 1; i++) {
      const currentEntry: HistoryEntry = this.historyEntries[i];
      const previousEntry: HistoryEntry = this.historyEntries[i + 1];

      currentEntry.transition = new Transition(
        currentEntry.version.lastModifiedBy,
        new Date(currentEntry.version.lastModifiedAt),
        []
      );

      if (previousEntry.version.clauseType !== currentEntry.version.clauseType) {
        const before: string = this._clauseNames[previousEntry.version.clauseType].toLowerCase();
        const after: string = this._clauseNames[currentEntry.version.clauseType].toLowerCase();
        currentEntry.transition.strings.push(`Changed clause type from '${before}' to '${after}'.`);
      }

      if (
        previousEntry.version.weight !== currentEntry.version.weight ||
        !previousEntry.version.parentId.equals(currentEntry.version.parentId)
      ) {
        currentEntry.transition.strings.push(`Reordered the clause.`);
      }

      if (this._parentHasMoved(previousEntry.version, currentEntry.version)) {
        currentEntry.transition.strings.push(`Reordered the clause group.`);
      }

      if (previousEntry.version.background !== currentEntry.version.background) {
        currentEntry.transition.strings.push(`Updated the clause's background summary.`);
      }

      const transitionStringCount: number = currentEntry.transition.strings.length;
      currentEntry.version = transitionStringCount < 1 ? currentEntry.version : null;
      currentEntry.transition = transitionStringCount > 0 ? currentEntry.transition : null;
    }
  }

  private _parentHasMoved(version1: ClauseFragment, version2: ClauseFragment): boolean {
    return version1.parentId.equals(version2.parentId) && version1.parent?.weight !== version2.parent?.weight;
  }

  private _filterOutDuplicateVersions(): void {
    for (let i = 0; i < this.historyEntries.length - 1; i++) {
      const currentEntry: HistoryEntry = this.historyEntries[i];

      if (!currentEntry.version) {
        continue;
      }

      this._setToPortrait(currentEntry.version);

      const previousNotNullVersion: ClauseFragment = this.previousVersion(i);

      if (
        !currentEntry.transition &&
        this._altTextIsUnchanged(currentEntry.version, previousNotNullVersion) &&
        currentEntry.version.subtreeEquals(previousNotNullVersion)
      ) {
        this.historyEntries.splice(i, 1);
        i--;
      }
    }
  }

  /**
   * Checks if Alt text has changed on a figure fragment
   * @param currentEntry
   * @param previousNotNullVersion
   * @returns
   */
  private _altTextIsUnchanged(currentEntry: Fragment, previousNotNullVersion: Fragment): boolean {
    const currentFigureChildren: Fragment[] = currentEntry.children.filter(
      (fragment) => fragment.type === FragmentType.FIGURE
    );
    const previousFigureChildren: Fragment[] = previousNotNullVersion.children.filter(
      (fragment) => fragment.type === FragmentType.FIGURE
    );
    return currentFigureChildren.every((figure) => {
      const index: number = currentFigureChildren.indexOf(figure);
      return (figure as FigureFragment).altText === (previousFigureChildren[index] as FigureFragment)?.altText;
    });
  }

  /**
   * Helper function to toggle landscape fragments back to portrait for display in sidebar
   *
   * @param   version {ClauseFragment}   The clause version
   */
  private _setToPortrait(version: ClauseFragment): void {
    version.children.forEach((f: Fragment) => {
      if (f.isLandscape()) {
        f['landscape'] = false;
      }
    });
  }

  /**
   * @param index The index of the version to offset from.
   * @returns The version earlier than the index.
   */
  public previousVersion(index: number): ClauseFragment {
    for (let i = index + 1; i < this.historyEntries.length; i++) {
      const version: ClauseFragment = this.historyEntries[i].version;

      if (version) {
        return version;
      }
    }

    return null;
  }

  private _filterIfNotShowingDetails(): void {
    if (this.showDetails) {
      return;
    }

    for (let i = 1; i < this.historyEntries.length; i++) {
      const currentEntry: HistoryEntry = this.historyEntries[i];
      const nextEntry: HistoryEntry = this.historyEntries[i - 1];

      const currModifiedBy: UUID = currentEntry.version?.lastModifiedBy ?? currentEntry.transition?.userId;
      const nextModifiedBy: UUID = nextEntry.version?.lastModifiedBy ?? nextEntry.transition?.userId;

      if (currModifiedBy.equals(nextModifiedBy)) {
        this.historyEntries.splice(i, 1);
        i--;
      }
    }
  }

  /**
   * Helper function to convert clause type configuration into a display name map.
   *
   * @param section {SectionFragment}   The selected section
   */
  private _getClauseTypeNames(section: SectionFragment): void {
    if (!section) {
      this._clauseNames = {};
      return;
    }
    this._configurationService.getClauseTypesForSectionId(section.id).then((configList: ClauseTypeConfiguration[]) => {
      this._clauseNames = configList.reduce(
        (returnMap: Partial<Record<ClauseType, string>>, config: ClauseTypeConfiguration) => {
          returnMap[config.clauseType] = config.displayName;
          return returnMap;
        },
        {}
      );
    });
  }

  public focusTimeDropdown(): void {
    document.getElementById('history-time-dropdown').focus();
  }
}
