import {ActivatedRouteSnapshot} from '@angular/router';
import {UUID} from 'app/utils/uuid';

export class OfflineContext {
  private static sectionId: UUID;
  private static documentId: UUID;
  private static versionId: UUID;

  public static getSectionId(): UUID {
    return OfflineContext.sectionId;
  }

  public static getDocumentId(): UUID {
    return OfflineContext.documentId;
  }

  public static getVersionId(): UUID {
    return OfflineContext.versionId;
  }

  public static setSectionId(sectionId: UUID): void {
    OfflineContext.sectionId = sectionId;
  }

  public static setDocumentId(documentId: UUID): void {
    OfflineContext.documentId = documentId;
  }

  public static setVersionId(versionId: UUID): void {
    OfflineContext.versionId = versionId;
  }

  public static updateContext(route: ActivatedRouteSnapshot): void {
    OfflineContext.sectionId = UUID.orNull(OfflineContext.getRouteParam(route, 'section_id'));
    OfflineContext.documentId = UUID.orNull(OfflineContext.getRouteParam(route, 'document_id'));
    OfflineContext.versionId = UUID.orNull(OfflineContext.getRouteParam(route, 'version_id'));
  }

  private static getRouteParam(route: ActivatedRouteSnapshot, param: string): string {
    let value: string = null;
    while (!value && route) {
      value = route.paramMap.get(param);
      if (!value) {
        route = route.parent;
      }
    }
    return value;
  }

  constructor() {}
}
