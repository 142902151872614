import {ConditionASTNode} from './condition-ast-node';
import {OperationASTNode} from './operation-ast-node';
import {ASTNodeType, EqualityRelation, OperationType, PermissionInput} from './permissions';

/**
 * Recursively deserialise a PermissionASTNode
 *
 * @param json json of the root PermissionASTNode
 * @returns The root node of the deserialised tree
 */
export const deserialisePermissionASTNode = (json: any) => {
  switch (json.type) {
    case ASTNodeType.CONDITION:
      const input = PermissionInput[json.input as string];
      const relation = EqualityRelation[json.relation as string];
      const value = json.value;
      return new ConditionASTNode(input, relation, value);
    case ASTNodeType.OPERATION:
      const operationType = OperationType[json.operationType as string];
      const left = deserialisePermissionASTNode(json.left);
      const right = operationType === OperationType.NOT ? null : deserialisePermissionASTNode(json.right);
      return new OperationASTNode(operationType, left, right);
    default:
      throw new Error('Node type not supported: ' + json.type);
  }
};
