import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BlurOption} from 'app/blur-options';
import {ToolbarItem} from 'app/suite-config/configuration.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AltProperties} from '../../services/alt-accessibility.service';
import {ReorderClausesService} from '../../services/reorder-clauses.service';
import {SectionService} from '../../services/section.service';

@Component({
  selector: 'cars-reorder-clauses',
  templateUrl: './reorder-clauses.component.html',
  styleUrls: ['./reorder-clauses.component.scss', '../toolbar-buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReorderClausesComponent implements OnInit, OnDestroy {
  @Input() public toolbarItem: ToolbarItem;
  @Input() public altProperties: AltProperties;

  public enabled: boolean = false;

  public isReordering = false;
  public readonly tooltipDelay = environment.tooltipDelay;

  private _subscription: Subscription[] = [];

  public readonly BlurOption: typeof BlurOption = BlurOption;

  constructor(
    private reorderClausesService: ReorderClausesService,
    private sectionService: SectionService,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscription.push(
      this.reorderClausesService.onReorderingEvent().subscribe((status: boolean) => {
        this.isReordering = status;
        this.cd.markForCheck();
      }),
      this.sectionService.onSelection((section) => {
        if (section !== null && !this.enabled) {
          this.enabled = this.toolbarItem.enabled(section);
          this.altProperties.condition = this.enabled;
          this.cd.markForCheck();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscription.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  public reorderClauses(): void {
    this.isReordering = !this.isReordering;
    this.reorderClausesService.triggerReorderingEvent(this.isReordering);
    this.cd.markForCheck();
  }
}
