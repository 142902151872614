import {AfterViewInit, Component, ElementRef, Input, QueryList, ViewChildren} from '@angular/core';
import {ClauseFragment} from 'app/fragment/types';
import {
  DiscouragedSpecifierInstructionTypes,
  SpecifierInstructionType,
} from 'app/fragment/types/specifier-instruction-type';
import {EquationService} from 'app/services/equation.service';
import {SpecifierInstructionWrapper} from 'app/services/specifier-instruction.service';
import {SpecifierInstructionModalService} from '../specifier-instruction-modal.service';

@Component({
  selector: 'cars-specifier-instruction-list-options',
  templateUrl: './specifier-instruction-list-options.component.html',
  styleUrls: ['./specifier-instruction-list-options.component.scss'],
})
export class SpecifierInstructionListOptionsComponent implements AfterViewInit {
  @ViewChildren('source') private _guidanceTextRefs: QueryList<ElementRef>;

  @Input() public set specifierInstructionFragments(fragments: SpecifierInstructionWrapper[]) {
    this._specifierInstructionFragments = fragments;

    this._specifierInstructionFragments.forEach((fragWrapper) =>
      fragWrapper.getSpecifierInstructionFragment().calculateClausePreview()
    );
  }

  public get specifierInstructionFragments(): SpecifierInstructionWrapper[] {
    return this._specifierInstructionFragments;
  }

  private _specifierInstructionFragments: SpecifierInstructionWrapper[];

  private _rendered: boolean = false;

  constructor(
    private _specifierInstructionModalService: SpecifierInstructionModalService,
    private _equationService: EquationService
  ) {}

  /**
   * @inheritdoc
   * Called after Angular initialises the component. Renders the guidance text for each SI option.
   */
  public ngAfterViewInit(): void {
    setTimeout(this._renderGuidanceText.bind(this));
  }

  public selectOption(type: SpecifierInstructionType): void {
    this._specifierInstructionModalService.updateSelectedType(type);
  }

  public isDiscouragedSI(clause: ClauseFragment): boolean {
    return DiscouragedSpecifierInstructionTypes.has(clause.specifierInstructionType);
  }

  /**
   * Renders guidance text through the {@link EquationService}. Does not attempt to rerender the elements as mathjax
   * modifies the elementRef properties so multiple passes the output to display incorrectly.
   */
  private _renderGuidanceText(): void {
    if (this._guidanceTextRefs && !this._rendered) {
      this._rendered = true;
      this._guidanceTextRefs.forEach((_sourceRef: ElementRef) =>
        this._equationService
          .renderEquationsContainedInSource(_sourceRef.nativeElement, _sourceRef.nativeElement.innerText)
          .subscribe()
      );
    }
  }
}
