import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserService} from 'app/services/user/user.service';
import {User} from '../../../../user/user';

@Component({
  selector: 'cars-user-creator',
  templateUrl: './user-creator.component.html',
  styleUrls: ['./user-creator.component.scss'],
})
export class UserCreatorComponent {
  // Taken from https://github.com/angular/angular/blob/5.2.7/packages/forms/src/validators.ts#L58
  /* eslint-disable max-len */
  private static readonly EMAIL_REGEX: RegExp =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
  /* eslint-enable max-len */

  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public loading: boolean = false;

  @ViewChild('userCreate', {static: true}) public userForm: NgForm;

  /**
   * Helper method to capitalise first letter of the given word.
   *
   * @param word {string} Given word
   * @returns    {string} Word with first character capitalised
   */
  public static capitaliseFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  constructor(
    private _userService: UserService,
    private _dialog: MatDialogRef<UserCreatorComponent, User>,
    @Inject(MAT_DIALOG_DATA) public data: {input: string}
  ) {
    this._parseData(data);
  }

  /**
   * Extracts first/last name & email from the data object.
   * Order of preference is given
   *
   * @param data {any} Data passed in through parent component
   */
  private _parseData(data: any): void {
    this.email = this._parseEmail(data);
    this.firstName = this._parseFirstName(data);
    this.lastName = this._parseLastName(data);
  }

  /**
   * Parses email from data object.
   *
   * @param data {any}    JSON object
   * @returns    {string} Email
   */
  private _parseEmail(data: any): string {
    let email: string = '';
    if (data && data.input && UserCreatorComponent.EMAIL_REGEX.test(data.input)) {
      email = UserCreatorComponent.EMAIL_REGEX.exec(data.input)[0];
    }
    return email;
  }

  /**
   * Parses first name from the email or given data object.
   *
   * @param data {any}    JSON object
   * @returns    {string} First name
   */
  private _parseFirstName(data: any): string {
    let firstName: string = '';

    if (UserCreatorComponent.EMAIL_REGEX.test(this.email)) {
      const local: string = this.email.split('@')[0];
      firstName = local.split('.')[0];
    } else {
      const split: string[] = data ? (data.input ? data.input.split(' ') : ['']) : [''];
      firstName = split[0];
    }
    return UserCreatorComponent.capitaliseFirstLetter(firstName);
  }

  /**
   * Parses last name from the email or given data object.
   *
   * @param data {any}    JSON object
   * @returns    {string} Last name
   */
  private _parseLastName(data: any): string {
    let lastNames: string[] = [''];

    if (UserCreatorComponent.EMAIL_REGEX.test(this.email)) {
      const local: string = this.email.split('@')[0];
      const split: string[] = local.split('.');
      if (split.length > 1) {
        lastNames = split.slice(1);
      }
    } else {
      const split: string[] = data ? (data.input ? data.input.split(' ').slice(1) : ['']) : [''];
      lastNames = split;
    }
    return lastNames.map((str: string) => UserCreatorComponent.capitaliseFirstLetter(str)).join(' ');
  }

  /**
   * Creates the user from the values in the form.
   */
  public createUser(): void {
    this.loading = true;
    const json: any = this._toJson();

    this._userService
      .createUser(json)
      .then((user: User) => {
        this.loading = false;
        this.close(user);
      })
      .catch((error: HttpErrorResponse) => {
        this.loading = false;
        this.close();
      });
  }

  /**
   * Closes the dialog and emits the created user to the parent component.
   *
   * @param user {User?} Optional user to emit on close of dialog
   */
  public close(user?: User): void {
    this._dialog.close(user);
  }

  /**
   * Serialises the user input fields to send to the web-service.
   *
   * @returns {any} JSON to send to the web-service
   */
  private _toJson(): any {
    return {
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
      email: this.email.trim(),
    };
  }
}
