import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentRole} from 'app/documents/document-data';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {
  ClauseFragment,
  ClauseType,
  DocumentFragment,
  Fragment,
  FragmentType,
  SectionFragment,
  SectionType,
} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {Breadcrumb, VersionTag} from 'app/interfaces';
import {PrintService} from 'app/print/print.service';
import {DiscussionsService} from 'app/services/discussions.service';
import {DocumentFetchParams, DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {ImageService} from 'app/services/image.service';
import {LockService} from 'app/services/lock.service';
import {SectionFetchParams, SectionService} from 'app/services/section.service';
import {RoleService} from 'app/services/user/role.service';
import {UserService} from 'app/services/user/user.service';
import {Discussion} from 'app/sidebar/discussions/discussions';
import {Dictionary} from 'app/utils/typedefs';
import {UUID} from 'app/utils/uuid';
import {CurrentView, ViewMode} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

/**
 * An enumeration of all the possible discussion views.
 */
export enum DiscussionView {
  ALL, // Display all discussions
  UNRESOLVED, // Only display unresolved discussions
  RESOLVED, // Only display resolved discussions
}

/**
 * An interface defining a staged comment against a discussion.
 */
interface StagedComment {
  text: string; // Comment to raise
  resolve: boolean; // Whether the comment resolves/accepts the discussion
  reject: boolean; // Whether the comment resolves and rejects the suggested change on the discussion
}

@Component({
  selector: 'cars-section-comments',
  templateUrl: './section-comments.component.html',
  styleUrls: ['./section-comments.component.scss'],
})
export class SectionCommentsComponent implements OnInit, OnDestroy {
  compare: ((v1: any, v2: any) => boolean) | null = this.compareFn.bind(this);

  public readonly ClauseType: any = ClauseType;

  public readonly dateFormat: string = 'MMM dd yyyy HH:mm';
  public readonly tooltipDelay = environment.tooltipDelay;

  // eslint-disable-next-line max-len
  public readonly cannotResolveDiscussionMessage: string =
    'Discussions can only be resolved if this version of the document is available to comment on and they were raised by you or you are the technical author, lead author, or author';

  public readonly displayDiscussionOptions: ReadonlyArray<{key: DiscussionView; text: string}> = [
    {key: DiscussionView.ALL, text: 'All'},
    {key: DiscussionView.UNRESOLVED, text: 'Unresolved'},
    {key: DiscussionView.RESOLVED, text: 'Resolved'},
  ];

  private section: SectionFragment;
  public fragments: Fragment[] = [];

  public loading: boolean = true;
  public submitting: boolean = false;

  public closeRoute: string;

  private discussionView: DiscussionView = DiscussionView.ALL;

  public collapsed: Dictionary<boolean> = {}; // Dictionary from clause/discussion ID to its collapsed value
  public fragmentDiscussions: Dictionary<Discussion[]> = {}; // Dictionary from fragment ID to discussions
  public stagedCommentText: Dictionary<StagedComment> = {}; // Dictionary from discussion ID to staging comment/resolution
  private discussionDictionary: Dictionary<Discussion> = {}; // Dictionary from discussion ID to itself for lookup

  public raisedByMe: boolean = false;
  public isAReviewer: boolean;

  public currentView: CurrentView;
  private userId: UUID;
  private breadcrumbs: Breadcrumb[] = [];

  public versionOptions: VersionTag[] = [];

  private _discussionsSubscription: Dictionary<Subscription> = {}; // Dictionary from clause ID to discussions subscription
  private _subscriptions: Subscription[] = [];

  /**
   * Looks at the breadcrumb service and retrieves the breadcrumb prior to
   * the current breadcrumb which can be used to close the export.
   */
  get closeBreadcrumb(): Breadcrumb {
    if (!this.breadcrumbs || !this.breadcrumbs.length) {
      return null;
    }

    return this.breadcrumbs[0];
  }

  constructor(
    private _viewService: ViewService,
    private _discussionsService: DiscussionsService,
    private _userService: UserService,
    private _roleService: RoleService,
    private _versioningService: VersioningService,
    private _documentService: DocumentService,
    private _printService: PrintService,
    private _sectionService: SectionService,
    private _lockService: LockService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _fragmentIndexService: FragmentIndexService,
    private _fragmentService: FragmentService,
    private _imageService: ImageService
  ) {}

  /**
   * Initialises the component by setting up subscriptions
   */
  public ngOnInit(): void {
    this.isAReviewer = this._roleService.isInDocumentRole(null, DocumentRole.REVIEWER);
    this.userId = this._userService.getUser().id;

    this._subscriptions.push(
      this._route.data.subscribe((data) => {
        this._recycle();
        this.section = data[0];
        this.breadcrumbs = data.breadcrumbs;
        this.populateFragments();
        this._initaliseDiscussions();
        this._getVersions();
      }),

      this._viewService.onCurrentViewChange(this._onCurrentViewChange.bind(this)),

      this._fragmentService.onCreate(
        (f: Fragment) => {
          this.populateFragments();
          this._initaliseDiscussions();
        },
        (f: Fragment) => this.fragmentPredicate(f)
      ),

      this._fragmentService.onUpdate(
        (f: Fragment) => {
          this.populateFragments();
          this._initaliseDiscussions();
        },
        (f: Fragment) => this.fragmentPredicate(f)
      ),

      this._fragmentService.onDelete(
        (f: Fragment) => {
          this.populateFragments();
          this._initaliseDiscussions();
        },
        (f: Fragment) => this.fragmentPredicate(f)
      )
    );

    this.closeRoute = '/' + this.closeBreadcrumb.link;
  }

  private populateFragments() {
    this.fragments = [this.section];
    this.fragments.push(...this.section.getClauses());
  }

  private fragmentPredicate(f: Fragment): boolean {
    return f.sectionId.equals(this.section.id) && f.is(FragmentType.CLAUSE, FragmentType.SECTION);
  }

  /**
   * Fetches the discussions for the selected section.
   */
  private _initaliseDiscussions(): void {
    this.loading = true;

    this._discussionsService.getDiscussions(this.fragments[0]).then(() => {
      this.loading = false;
      setTimeout(() => {
        this._imageService.allImagesLoaded().then(() => this._printService.triggerPrintEvent());
      });
    });
    this.fragments.forEach((fragment: Fragment) => {
      if (!this.collapsed[fragment.id.value]) {
        this.collapsed[fragment.id.value] = false;
      }

      this._initialiseDiscussionSubscription(fragment);

      fragment.children.forEach((frag: Fragment) => {
        if (frag.isLandscape()) {
          frag['landscape'] = false;
        }
      });
    });
  }

  /**
   * Subscribes to discussion changes for the fragment and child fragments.
   *
   * @param fragment {Fragment} Fragment to subscribe to changes for
   */
  private _initialiseDiscussionSubscription(fragment: Fragment): void {
    if (!this._discussionsSubscription[fragment.id.value]) {
      this._discussionsSubscription[fragment.id.value] = this._discussionsService.onChange(
        fragment,
        (discussions: Discussion[]) => {
          this.fragmentDiscussions[fragment.id.value] = discussions;
          discussions.forEach((discussion: Discussion) => {
            const discussionId: string = discussion.id.value;

            this.discussionDictionary[discussionId] = discussion;

            if (!this.stagedCommentText[discussionId]) {
              this.stagedCommentText[discussionId] = {
                text: '',
                resolve: false,
                reject: false,
              };
            }

            if (!this.collapsed[discussionId]) {
              this.collapsed[discussionId] = false;
            }
          });
        },
        fragment.is(FragmentType.CLAUSE)
      );
    }
  }

  /**
   * Fetches live/versioned section on current view change.
   *
   * @param newView {CurrentView} The new current view object
   */
  private _onCurrentViewChange(newView: CurrentView): void {
    const previousView: CurrentView = this.currentView;
    this.currentView = newView;
    if (!this.currentView.isComments()) {
      return;
    }

    const versionId: UUID = this.currentView.getCurrentVersionId();
    const previousVersionId: UUID = previousView ? previousView.getCurrentVersionId() : null;

    const hasChangedVersion: boolean =
      (!versionId && !!previousVersionId) ||
      (!!versionId && !previousVersionId) ||
      (!!versionId && !versionId.equals(previousVersionId));

    if (hasChangedVersion) {
      this.loading = true;
      this._fetchVersion().then((section: SectionFragment) => {
        if (section) {
          this._documentService.setSelected(section.getDocument());
          this._sectionService.setSelected(section);
          this.section = section;
          this.populateFragments();
        }
        this._initaliseDiscussions();
      });
    }
  }

  /**
   * Fetches the section for the given versionId. If null fetches the latest version.
   *
   * @returns         {Promise<SectionFragment>} A promise resolving to the live/versioned section
   */
  private _fetchVersion(): Promise<SectionFragment> {
    const versionTag: VersionTag = this.currentView.versionTag;

    const documentFetchParams: DocumentFetchParams = {
      projection: 'INITIAL_DOCUMENT_LOAD',
      validAt: versionTag ? versionTag.createdAt : void 0,
    };

    return this._documentService.load(this.section.documentId, documentFetchParams).then((doc: DocumentFragment) => {
      const sectionFetchParams: SectionFetchParams =
        versionTag !== null ? {validAt: versionTag.createdAt, projection: 'FULL_TREE'} : {projection: 'FULL_TREE'};
      return this._sectionService.load(this.section.id, sectionFetchParams).then((section: SectionFragment) => {
        return section;
      });
    });
  }

  /**
   * Unsubscribes from subscriptions.
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());

    this._recycle();
  }

  /**
   * Clear the section-specific data from the component, ready for re-use with
   * another section.
   */
  private _recycle(): void {
    Object.values(this._discussionsSubscription).forEach((s: Subscription) => s.unsubscribe());

    this._discussionsSubscription = {};

    this.collapsed = {};
    this.fragmentDiscussions = {};
    this.stagedCommentText = {};
    this.discussionDictionary = {};

    this.versionOptions = [];
  }

  /**
   * Listens for the beforeunload event and presents a dialog if user has unsaved changes.
   *
   * @returns {boolean} True if user doesn't have any pending changes
   */
  @HostListener('window:beforeunload')
  public canDeactivate(): boolean {
    let empty: boolean = true;
    Object.values(this.stagedCommentText).forEach((value: StagedComment) => {
      if (value.text) {
        empty = false;
        return;
      }
    });
    return empty;
  }

  /**
   * Works out the indexes for the different fragment types.
   *
   * @param fragment {Fragment} The fragment
   * @returns        {string}   Fragment index
   */
  public getFragmentIndex(fragment: Fragment): string {
    if (fragment.is(FragmentType.CLAUSE)) {
      switch ((fragment as ClauseFragment).clauseType) {
        case ClauseType.HEADING_1:
          return this.section.sectionType === SectionType.APPENDIX
            ? this._fragmentIndexService.getIndex(fragment.id)
            : 'Heading';
        case ClauseType.HEADING_2:
          return 'Sub-heading';
      }
    } else if (fragment.is(FragmentType.SECTION)) {
      return 'Section ' + this._fragmentIndexService.getIndex(fragment.id);
    }
    return this._fragmentIndexService.getIndex(fragment.id);
  }

  /**
   * This toggles which discussions to display
   *
   * @param view {DiscussionView} The new view to set
   */
  public setDiscussionsToDisplay(view: DiscussionView): void {
    this.discussionView = view;
  }

  /**
   * This returns the discussions on a given fragment that meet the criteria of the selected 'view'.
   *
   * @param fragment {Fragment} The fragment to return the required discussions for
   * @returns      {Discussion[]}   The discussions for the given clause
   */
  public getFragmentDiscussions(fragment: Fragment): Discussion[] {
    let discussionToReturn: Discussion[] = this.fragmentDiscussions[fragment.id.value] || [];
    switch (this.discussionView) {
      case DiscussionView.UNRESOLVED:
        discussionToReturn = discussionToReturn.filter((discussion: Discussion) => !discussion.resolved);
        break;
      case DiscussionView.RESOLVED:
        discussionToReturn = discussionToReturn.filter((discussion: Discussion) => discussion.resolved);
        break;
    }

    if (this.raisedByMe) {
      discussionToReturn = discussionToReturn.filter((discussion: Discussion) => discussion.wasRaisedBy(this.userId));
    }

    return discussionToReturn;
  }

  /**
   * @returns {boolean} true if the given fragment is a section
   */
  public isSection(fragment: Fragment): boolean {
    return fragment.is(FragmentType.SECTION);
  }

  /**
   * Checks if a given fragment has any discussion on it at all for the selected version.
   *
   * @param fragment {Fragment} The fragment to check
   * @returns      {boolean}  True if the given fragment has discusisons
   */
  public hasDiscussions(fragment: Fragment): boolean {
    const discussionList: Discussion[] = this.fragmentDiscussions[fragment.id.value];

    return !!discussionList && discussionList.length > 0;
  }

  /**
   * @returns {boolean} true if all discussions should be displyed
   */
  public showAll(): boolean {
    return this.discussionView === DiscussionView.ALL;
  }

  /**
   * Checks if the button's discussionView has been selected. Returns true is so, else false.
   *
   * @param button {DiscussionView} The button to check the state of
   * @returns      {boolean}        True if the given view is selected
   */
  public isSelected(button: DiscussionView): boolean {
    return this.discussionView === button;
  }

  /**
   * Delegates to {@link Discussion} if this discussion is resolvable in the current view
   *
   * @param discussion {Discussion} Discussion to check
   * @returns          {boolean}    true if the discussion is resolvable
   */
  public isResolvable(discussion: Discussion): boolean {
    return discussion.isResolvable(this.userId, this.currentView);
  }

  /**
   * Delegates to {@link Discussion} if the discussion is commentable in the current view
   *
   * @param discussion {Discussion} Discussion to check
   * @returns          {boolean}    true if commenting against this discussion is disabled
   */
  public commentingDisabled(discussion: Discussion): boolean {
    return !discussion.isCommentable(this.currentView);
  }

  /**
   * @returns {boolean} True if the fragment the user is accepting a change for can be locked.
   */
  public canSubmit(): boolean {
    let canSubmit: boolean = true;
    Object.keys(this.stagedCommentText).forEach((discussionId: string) => {
      const discussion: Discussion = this.discussionDictionary[discussionId];
      if (discussion.suggestion && this.stagedCommentText[discussionId].resolve) {
        const fragment: Fragment = this.fragments.find((f: Fragment) => f.id.equals(discussion.fragmentId));

        const clause: ClauseFragment = fragment?.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
        canSubmit = clause ? this._lockService.canLock(clause) : true;
      }
    });

    return canSubmit;
  }

  /**
   * Submits comments to web service.
   */
  public submit(): void {
    this.loading = true;
    this._submitComments().then(() => {
      this.loading = false;
      this.submitting = false;
    });
  }

  /**
   * This runs the createComment method for each nonempty comment box.
   *
   * @returns {Promise<number[]>} Promise resolving to an array of HTTP status codes
   */
  private _submitComments(): Promise<void[]> {
    const promises: Promise<void>[] = [];
    this.submitting = true;
    for (const discussionId in this.stagedCommentText) {
      if (this.stagedCommentText[discussionId].text !== '') {
        const stagedComment: StagedComment = this.stagedCommentText[discussionId];
        const discussion: Discussion = this.discussionDictionary[discussionId];

        const resolving: boolean = stagedComment.resolve || stagedComment.reject;
        const acceptSuggestion: boolean = discussion.suggestion && stagedComment.resolve;

        promises.push(this._createComment(stagedComment.text, discussion, resolving, acceptSuggestion));
        this.stagedCommentText[discussionId] = {
          text: '',
          resolve: false,
          reject: false,
        };
      }
    }
    return Promise.all(promises);
  }

  /**
   * Creates a comment on the given discussion with the given text.
   *
   * @param commentString    {string}          The string to submit as a comment
   * @param discussion       {Discussion}      The discussion to raised the comment against
   * @param resolution       {boolean}         Whether or not the discussion resolves the comment; defaults to false
   * @param acceptSuggestion {boolean}         True if suggestion should be accepted
   * @returns                {Promise<number>} Promise resolving to the HTTP status
   */
  private _createComment(
    commentString: string,
    discussion: Discussion,
    resolution: boolean = false,
    acceptSuggestion: boolean = false
  ): Promise<void> {
    if (commentString) {
      return this._discussionsService.createComment(commentString, discussion, resolution).then(() => {
        if (resolution) {
          if (acceptSuggestion) {
            this._discussionsService.acceptSuggestion(discussion);
          }
          this._discussionsService.resolveDiscussion(discussion);
        }
      });
    }
  }

  /**
   * Event handler to close the current view and return to the previous breadcrumb.
   */
  public onClose(): void {
    const breadcrumb: Breadcrumb = this.closeBreadcrumb;
    if (breadcrumb) {
      this._router.navigate([breadcrumb.link]);
    }
  }

  /**
   * Retrieves all versions for the document. Displays only available to review versions if a reviewer.
   */
  private _getVersions(): void {
    this._versioningService
      .getVersionTagsForFragmentId(this.section.documentId, this.isAReviewer)
      .pipe(take(1))
      .toPromise()
      .then((taggedVersions: VersionTag[]) => (this.versionOptions = taggedVersions));
  }

  /**
   * This changes the document version that from which to display the discussions.
   *
   * @param event {MatSelectChange} The event thrown by the chosen display option
   */
  public changeSelectedVersion(event: MatSelectChange): void {
    const versionTag: VersionTag = event.value !== 'live' ? event.value : null;
    this._viewService.setCurrentView(ViewMode.COMMENTS, versionTag);
  }

  /**
   * Returns the message (string) to display on a collapsed fragment.
   *
   * @param fragment {Fragment} The fragment to display the message for
   * @returns        {string}   Display message
   */
  public fragmentCollapsedString(fragment: Fragment): string {
    return (
      (!(this.getFragmentDiscussions(fragment).length > 0) && this.hasDiscussions(fragment)
        ? 'You have raised '
        : 'There ' + (this.getFragmentDiscussions(fragment).length === 1 ? '' : 'are ')) +
      (this.getFragmentDiscussions(fragment).length === 1
        ? 'is 1 discussion '
        : (this.getFragmentDiscussions(fragment).length > 0 ? this.getFragmentDiscussions(fragment).length : 'no ') +
          ' discussions ') +
      'on this content.'
    );
  }

  public print(): void {
    this._router.navigateByUrl(this._router.url.split('/').slice(0, -1).join('/') + '/print' + '/comments');
  }

  /**
   * This finds the menu option to display when the view is first initialised.
   */
  public compareFn(v1: any, v2: any): boolean {
    return this.currentView.versionTag === null
      ? v1 === 'live'
      : v1 !== 'live' && v1.versionId.equals(this.currentView.getCurrentVersionId());
  }
}
