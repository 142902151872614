import {StringUtils} from './string-utils';

export class ColourUtils {
  public static hslFromString(str: string, alpha?: number): string {
    return this.hueToCss(StringUtils.hash(str) % 360, alpha === undefined ? 1 : alpha);
  }

  private static hueToCss(hue: number, alpha: number): string {
    return 'hsla(' + hue + ',100%,30%,' + alpha + ')';
  }
}
