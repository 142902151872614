import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {DocumentFragment} from 'app/fragment/types';
import {BaseService} from 'app/services/base.service';
import {DocumentService} from 'app/services/document.service';
import {UserService} from 'app/services/user/user.service';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {UUID} from 'app/utils/uuid';
import {Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ChatMessage} from '../interfaces';
import {Callback} from '../utils/typedefs';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService {
  private messageUrl = `${environment.apiHost}/chatMessages`;

  private chatMessageSubject = new Subject<any>();
  private currentDocument: DocumentFragment;

  private ownerId: UUID;
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
    private webSocketService: WebSocketService,
    private documentService: DocumentService,
    public snackBar: MatSnackBar,
    userService: UserService
  ) {
    super(snackBar);

    this.webSocketService.onConnection((state: boolean) => {
      if (!state) {
        return;
      }

      this.webSocketService.subscribe('/topic/chatMessages/create', (data) => {
        const message = this.extractChatMessage(data) as ChatMessage;
        if (this.currentDocument.id.equals(message.documentId)) {
          this.chatMessageSubject.next(message);
        }
      });
    });

    this.documentService.onSelection((document) => (this.currentDocument = document));

    this.ownerId = userService.getUser().id;
  }

  public onNewMessage(callback: Callback<any>): Subscription {
    return this._makeSubscription(this.chatMessageSubject, callback);
  }

  public getMessages(): Observable<ChatMessage[]> {
    return this.http
      .get(`${this.messageUrl}/search/byDocumentId?documentId=${this.currentDocument.id.value}`, {
        headers: this._httpHeaders,
      })
      .pipe(
        map((response) => {
          return this.extractChatMessage(response) as ChatMessage[];
        }),
        catchError((response) => {
          this._handleError(response, 'Failed to lookup chat messages.', 'chat-error');
          return throwError(null);
        })
      );
  }

  public sendMessage(message: string): void {
    this.loading = true;
    const body = {
      text: message,
      documentId: this.currentDocument.id.value,
    };
    this.http
      .post(`${this.messageUrl}`, body, {headers: this._httpHeaders})
      .pipe(
        map(this.extractChatMessage.bind(this)),
        catchError((response) => {
          this._handleError(response, 'Failed to send chat message.', 'chat-error');
          this.loading = false;
          return throwError(null);
        })
      )
      .subscribe((newMessage: ChatMessage) => {
        this.chatMessageSubject.next(newMessage);
        this.loading = false;
      });
  }

  public getLastReadChatMessageIndex(): Observable<number> {
    if (!this.currentDocument) {
      Logger.error('chat-error', 'Attempt to lookup chat position with no document selected');
      return throwError(null);
    }
    return this.http
      .get(`${environment.apiHost}/chat/getLastRead/${this.currentDocument.id.value}`, {headers: this._httpHeaders})
      .pipe(
        map((response: any) => response.position),
        catchError((response) => {
          if (response.status === 404) {
            return of(0);
          } else {
            this._handleError(response, 'Failed to find last read chat message.', 'chat-error');
            return throwError(null);
          }
        })
      );
  }

  public updateChatPosition(position: number): void {
    if (!this.currentDocument) {
      Logger.error('chat-error', 'Attempt to update chat position with no document selected');
      return;
    }
    this.http
      .post(`${environment.apiHost}/chat/markLastRead/${this.currentDocument.id.value}`, position, {
        headers: this._httpHeaders,
      })
      .pipe(
        map((response: any) => response.position),
        catchError((response) => {
          this._handleError(response, 'Failed to mark last message as read.', 'chat-error');
          return throwError(null);
        })
      )
      .subscribe();
  }

  private extractChatMessage(json: any): ChatMessage | ChatMessage[] {
    const embedded = json.hasOwnProperty('_embedded');
    const mapped = (embedded ? json['_embedded'].chatMessages : [json]).map((message) => {
      message.author = UUID.orThrow(message.createdBy);
      message.documentId = UUID.orThrow(message.documentId);
      message.ownMessage = this.ownerId.equals(message.author);
      return message;
    });
    return embedded ? mapped : mapped[0];
  }
}
