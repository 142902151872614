import {Caret} from 'app/fragment/caret';
import {CarsRange, CarsRangeType} from 'app/fragment/cars-range';
import {DocumentFragment, Fragment, FragmentType} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {Serialisable} from 'app/utils/serialisable';
import {UUID} from 'app/utils/uuid';

export class ChangelogRange extends CarsRange implements Serialisable {
  public type: CarsRangeType = CarsRangeType.CHANGELOG_RANGE;
  public id: UUID = null;
  public deletionComment: string;
  public isDrawn: boolean = false;
  private _deleted: boolean = false;

  public startFragmentId: UUID = null;
  public endFragmentId: UUID = null;

  // Although these fields can be calculated if the fragments are known about in the
  // frontend, it is always possible that a link will be fetched which refers to a range
  // which is in a document the user has not viewed this session.  In this case, we fall back
  // to using these values.
  public published: boolean = false;
  public storedValue: string;
  public documentTitle: string;
  public documentCode: string;

  /**
   * Deserialise a changelogRange from raw json.  If the fragments which the range references
   * are not in the FragmentCache because the user has never requested a subtree containing that
   * fragment, we still want to deserialise the range but cannot draw it.
   *
   * This should only happen in views like the LinkManagerComponent, where we need to pull down
   * ranges which could potentially be in any document.
   *
   * @param json {any} The json.
   * @param fragmentService {FragmentService} A reference to the fragment service.
   *
   * @returns {ChangelogRange} The deserialised range.
   */
  public static deserialise(json: any, fragmentService: FragmentService, validity: number): ChangelogRange {
    validity = !json.published ? null : validity;
    const startFragment: Fragment = fragmentService.find(UUID.orNull(json.startFragmentId), validity);
    const endFragment: Fragment = fragmentService.find(UUID.orNull(json.endFragmentId), validity);

    const range: ChangelogRange = new ChangelogRange(
      new Caret(startFragment, json.startOffset),
      new Caret(endFragment, json.endOffset)
    );
    range.id = UUID.orNull(json.id);
    range.deleted = json.deleted;
    range.startFragmentId = UUID.orNull(json.startFragmentId);
    range.endFragmentId = UUID.orNull(json.endFragmentId);
    range.storedValue = json.value;
    range.sectionId = UUID.orNull(json.sectionId);
    range.documentId = UUID.orNull(json.documentId);
    range.documentTitle = json.documentTitle;
    range.documentCode = json.documentCode;
    range.published = json.published;
    range.deletionComment = json.deletionComment;
    return range;
  }

  constructor(start: Caret, end: Caret) {
    super(start, end, CarsRangeType.CHANGELOG_RANGE);
    if (this.start && this.start.fragment) {
      const document: DocumentFragment = this.start.fragment.findAncestorWithType(
        FragmentType.DOCUMENT
      ) as DocumentFragment;
      this.published = document ? document.isLegacySuite() : null;
      this.documentTitle = document ? document.title : null;
      this.documentId = document ? document.id : null;
      this.documentCode = document ? document.documentCode : null;
    }
  }

  public get deleted(): boolean {
    return this._deleted;
  }

  public set deleted(d: boolean) {
    this.type = d ? CarsRangeType.DELETED_CHANGELOG_RANGE : CarsRangeType.CHANGELOG_RANGE;
    this.deletionComment = d ? this.deletionComment : null;
    this._deleted = d;
  }

  /**
   * Serialise the range to json.
   * @returns {any} The serialised form.
   */
  public serialise(): any {
    const startFragment: Fragment = this.start ? this.start.fragment : null;
    const endFragment: Fragment = this.end ? this.end.fragment : null;

    if (startFragment && endFragment) {
      return {
        id: this.id ? this.id.value : null,
        startFragmentId: startFragment.id.value,
        endFragmentId: endFragment.id.value,
        sectionId: this.sectionId ? this.sectionId.value : null,
        documentId: this.documentId ? this.documentId.value : null,
        startOffset: this.start.offset,
        endOffset: this.end.offset,
        value: this.value,
        documentTitle: this.documentTitle,
        documentCode: this.documentCode,
        published: this.published,
        deleted: this.deleted,
        deletionComment: this.deletionComment,
      };
    } else {
      console.error('Cannot serialise range: Missing start or end fragment.');
      // If we couldn't properly deserialise the range because the fragments it refers to
      // were not in the users' cache, we don't allow this to be sent to the backend.  This should
      // only happen within the link management dialog view, so it shouldn't ever ocurr.
      return {};
    }
  }

  /**
   * Overrides super.hashcode().  We can use the range ID uuid as the hashcode instead,
   * which allows us to handle updates to the range which would have caused super.hashcode()
   * to return a different value.
   */
  public hashcode(): string {
    return this.id.value;
  }
}
