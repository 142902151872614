import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {TableFragment} from 'app/fragment/table/table-fragment';
import {FigureFragment, SectionFragment, SectionType} from 'app/fragment/types';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {RichTextService} from 'app/services/rich-text.service';
import {UUID} from 'app/utils/uuid';
import {CurrentView} from 'app/view/current-view';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-section-pad',
  templateUrl: './section-pad.component.html',
  styleUrls: ['./section-pad.component.scss'],
})
export class SectionPadComponent implements OnInit, OnDestroy {
  @Input() public section: SectionFragment;
  @Input() public disableCanvas: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public currentView: CurrentView;
  @Input() public toolbarButtonsExt: TemplateRef<any>;
  @Input() public documentToolbar: TemplateRef<any>;
  @Input() public padType: PadType = PadType.MAIN_EDITABLE;

  public readonly PadType: typeof PadType = PadType;
  public readonly SectionType: any = SectionType;

  public padId: string = UUID.random().value.substring(0, 8);
  public reordering: boolean = false;
  public dragging: boolean = false;
  public hasLandscapeFragment: boolean = false;

  private _subscriptions: Subscription[] = [];

  constructor(private _reorderClausesService: ReorderClausesService, private _richTextService: RichTextService) {}

  /**
   * Initialise this component.
   */
  public ngOnInit(): void {
    this._subscriptions.push(
      this._reorderClausesService.onReorderingEvent().subscribe((reordering: boolean) => {
        if (!this.readonly) {
          this.reordering = reordering;
        }
      }),

      this._reorderClausesService.onDraggingEvent().subscribe((dragging: boolean) => {
        if (!this.readonly) {
          this.dragging = dragging;
        }
      }),

      this._richTextService.onLandscapeEvent(this._checkForLandscapeFragment.bind(this))
    );

    this._checkForLandscapeFragment();
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Checks if the section has any landscape fragments.
   */
  private _checkForLandscapeFragment(): void {
    this.hasLandscapeFragment = false;
    if (this.section) {
      this.section.iterateDown(null, null, (iterator: TableFragment | FigureFragment) => {
        if (iterator.isLandscape()) {
          this.hasLandscapeFragment = true;
          return true; // Stops unecessary iteration down through table cells
        }
      });
    }
  }

  /**
   * Handles the mouse moving outside the pad while dragging
   *
   * @param inPad {boolean}   True if the mouse is in the pad
   */
  public handleMouseInPad(inPad: boolean) {
    if (this.reordering) {
      this._reorderClausesService.triggerMouseOutsidePadEvent(!inPad);
    }
  }
}
