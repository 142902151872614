import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {NavigationSidebarButtonsComponent} from './navigation-sidebar-buttons.component';

@NgModule({
  imports: [
    CommonModule,
    AltAccessibilityModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    CarsErrorHandlingModule,
  ],
  declarations: [NavigationSidebarButtonsComponent],
  exports: [NavigationSidebarButtonsComponent],
})
export class NavigationSidebarButtonsModule {}
