<div
  *ngIf="content"
  class="clause"
  [class.item]="isItem"
  [carsClauseStyling]="content"
  [carsCurrentClause]="content"
  [showCurrentClause]="showCurrentClause"
  [attr.data-clausetype]="ClauseType[content?.clauseType] | lowercase"
  (click)="click()"
>
  <cars-locking-overlay
    *ngIf="!readOnly && padType === PadType.MAIN_EDITABLE"
    [clause]="content"
  ></cars-locking-overlay>

  <span class="index" [class.user-select-none]="content.isFirstChild() || reordering" contenteditable="false">
    <ng-container
      *ngIf="
        (!readOnly || (CarsAction.IS_CHANGELOG_MARKUP_VIEW_MODE | carsEnabled : content.documentId | async)) &&
        !content.getSection()?.deleted
      "
    >
      {{ content | fragmentIndex }}
    </ng-container>
  </span>

  <div class="content" [class.reordering]="reordering">
    <ng-template
      [carsFragmentHost]="content.children"
      [readOnly]="readOnly || (CarsAction.EDIT_PAD | carsDisabled : content.documentId | async)"
    ></ng-template>
  </div>

  <div
    *ngIf="showMarginIcons && padType === PadType.MAIN_EDITABLE"
    class="margin-icons-container"
    contenteditable="false"
  >
    <cars-validation-icon [clause]="content"></cars-validation-icon>
    <cars-discussions-icon [fragment]="content"></cars-discussions-icon>
    <cars-clause-link-icon [clause]="content"></cars-clause-link-icon>
  </div>
</div>
