<h1 matDialogTitle>{{ title }}</h1>
<div *ngIf="subtext" mat-dialog-content [innerHTML]="subtext" class="subtext"></div>
<div mat-dialog-content [innerHTML]="message"></div>
<div mat-dialog-actions>
  <button
    *ngFor="let action of closeActions"
    mat-button
    mat-raised-button
    [color]="action.color"
    [mat-dialog-close]="action.response"
    [matTooltipShowDelay]="tooltipDelay"
    matTooltipPosition="below"
    [matTooltip]="action.tooltip"
    [attr.aria-label]="action.title"
  >
    {{ action.title }}
  </button>
</div>
