import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {JiraIssue} from 'app/interfaces';
import {environment} from 'environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JiraService {
  public static readonly documentsUrl: string = environment.apiHost + '/jira/documents';
  public static readonly documentUrlPrefix: string = environment.apiHost + '/jira/document/';

  constructor(private http: HttpClient) {}

  public getJiraDocuments(): Observable<JiraIssue[]> {
    return this.http.get(JiraService.documentsUrl).pipe(
      map((issues: any) => this.toJiraIssues(issues)),
      catchError((e) => {
        Logger.error('jira-error', 'Failed to GET Jira documents from API.', e);
        return of([]);
      })
    );
  }

  public getJiraDocument(documentId: string): Observable<JiraIssue> {
    return this.http.get(JiraService.documentUrlPrefix + documentId).pipe(
      map((issue: any) => this.toJiraIssue(issue)),
      catchError((e) => {
        Logger.error('jira-error', 'Failed to GET Jira document from API.', e);
        return of(null);
      })
    );
  }

  private toJiraIssues(objects: any[]): JiraIssue[] {
    return objects.map(this.toJiraIssue);
  }

  private toJiraIssue(object: any): JiraIssue {
    if (object['extras']) {
      const extras: Map<string, string> = new Map(Object.entries(object['extras']));
      extras.forEach((val, key) => {
        const timestamp: number = Date.parse(val);
        if (!isNaN(timestamp)) {
          extras.set(key, new Date(timestamp).toDateString());
        }
      });
      object['extras'] = extras;
    }
    return object as JiraIssue;
  }
}
