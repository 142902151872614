import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ReviewStatusToggleComponent} from './review-status-toggle.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatTooltipModule, MatButtonModule, MatProgressSpinnerModule],
  declarations: [ReviewStatusToggleComponent],
  exports: [ReviewStatusToggleComponent],
})
export class ReviewStatusToggleModule {}
