<div class="padded">
  <h3>
    Withdrawn Reference:
    <span>{{ searchableGlobalReference.reference }}</span>
  </h3>
  <strong>Reference Title: </strong><span>{{ searchableGlobalReference.title }}</span>

  <p>If the exact superseded or withdrawn on date is not know, please select the last day of the month.</p>

  <div>
    Withdrawn date:
    <mat-form-field class="padded">
      <input
        matInput
        placeholder="Withdrawn on"
        [matDatepicker]="datePicker"
        [formControl]="date"
        (focus)="datePicker.open()"
        (click)="datePicker.open()"
        required
        readonly
        aria-label="select date of withdrawal"
      />
      <mat-error>
        {{ withdrawalErrorMessage }}
      </mat-error>
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="multi-year"></mat-datepicker>
    </mat-form-field>
  </div>

  <div>
    Superseded by:
    <cars-superseded-reference-selector
      #supersedingReferenceSelector
      [oldReference]="searchableGlobalReference"
      (onFormChangeIsValid)="setSupersedingReferencesFormIsValid($event)"
      (onSupersedingReferencesChanged)="setSupersedingReferences($event)"
    ></cars-superseded-reference-selector>
  </div>

  <span>
    <button mat-raised-button aria-label="Discard and exit" (click)="close(false)">Discard & Exit</button>
    <button
      [disabled]="!(date.valid && supersedingReferencesFormIsValid)"
      mat-raised-button
      color="primary"
      aira-label="Save and close"
      (click)="close(true)"
      class="save"
    >
      Save & Close
    </button>
  </span>
</div>
