import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatInput} from '@angular/material/input';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {DocumentRole} from 'app/documents/document-data';
import {SearchResult} from 'app/search/search-documents.service';
import {Dashboard, DashboardService} from 'app/services/dashboard.service';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {DocumentDiscussionsAggregate} from '../aggregates/document-discussions-aggregate';
import {VersionDiscussionsAggregate} from '../aggregates/version-discussions-aggregate';

interface RoleData {
  icon: string;
  displayName: string;
}

export interface DocumentAggregateSearchParams {
  searchTerm: string;
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'cars-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.scss'],
})
export class DashboardSidebarComponent implements OnInit, OnDestroy {
  private static readonly DEFAULT_SEARCH: DocumentAggregateSearchParams = {
    searchTerm: '',
    pageIndex: 0,
    pageSize: 25,
  };

  @Input() dashboardType: Dashboard;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatInput, {static: true}) filter: MatInput;

  public readonly roleData: Readonly<Record<DocumentRole, RoleData>> = {
    [DocumentRole.LEAD_AUTHOR]: {
      icon: 'create',
      displayName: 'Lead Author',
    },
    [DocumentRole.AUTHOR]: {
      icon: 'create',
      displayName: 'Author',
    },
    [DocumentRole.OWNER]: {
      icon: 'person',
      displayName: 'Technical Author',
    },
    [DocumentRole.PEER_REVIEWER]: {
      icon: 'assignment',
      displayName: 'Peer Reviewer',
    },
    [DocumentRole.REVIEWER]: {
      icon: 'assignment',
      displayName: 'Reviewer',
    },
  };

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public documentDiscussionsAggregates: DocumentDiscussionsAggregate[] = [];

  public expanded: Record<string, boolean> = {};
  public selected: Record<string, boolean> = {};
  public loading: boolean = false;

  public total: number = 0;

  public searchParams: DocumentAggregateSearchParams = DashboardSidebarComponent.DEFAULT_SEARCH;

  private _subscriptions: Subscription[] = [];

  constructor(private dashboardService: DashboardService) {}

  public ngOnInit() {
    this.search();
    this._subscriptions.push(
      this.paginator.page.subscribe((event: PageEvent) => {
        this.searchParams.pageIndex = event.pageIndex;
        this.searchParams.pageSize = event.pageSize;
        this.search();
      }),
      this.filter.stateChanges.pipe(debounceTime(200)).subscribe(() => {
        if (this.searchParams.searchTerm !== this.filter.value) {
          this.searchParams.searchTerm = this.filter.value;
          this.searchParams.pageIndex = 0;
          this.search();
        }
      }),
      this.dashboardService.onVersionAggregateSelect((versionAggregate: VersionDiscussionsAggregate) => {
        if (versionAggregate) {
          this._setAllFalseExcept(this.selected, versionAggregate.documentId);
          this.selected[versionAggregate.documentId.value] = true;
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  public search(): void {
    this.loading = true;
    this.dashboardService
      .getDocumentDiscussionsAggregates(this.dashboardType, this.searchParams)
      .then((results: SearchResult<DocumentDiscussionsAggregate>) => {
        this.documentDiscussionsAggregates = results.page;
        this.total = results.total;
        this.loading = false;
      })
      .catch((error: any) => (this.loading = false));
  }

  public selectRow(documentDiscussionsAggregate: DocumentDiscussionsAggregate) {
    this._setAllFalseExcept(this.expanded, documentDiscussionsAggregate.documentId);
    this.expanded[documentDiscussionsAggregate.documentId.value] =
      !this.expanded[documentDiscussionsAggregate.documentId.value];
  }

  public getDocumentCode(dda: DocumentDiscussionsAggregate): string {
    return dda.documentCode || 'No Document Code';
  }

  private _setAllFalseExcept(record: Record<string, boolean>, id: UUID): void {
    Object.keys(record).forEach((key: string) => {
      if (key !== id.value) {
        record[key] = false;
      }
    });
  }
}
