import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentService} from 'app/services/document.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {Unit} from '../unit';
import {UnitSearchParams} from '../unit-selection.component';

@Component({
  selector: 'cars-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitListComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() public unitsList: Unit[];
  @Input() public totalUnitsCount: number;

  @Input() public searchTerm: string;

  @Output() public selection: EventEmitter<Unit> = new EventEmitter();
  @Output() public onSearch: EventEmitter<UnitSearchParams> = new EventEmitter();

  public readonly displayedColumns: ReadonlyArray<string> = ['unit'];

  public dataSource: MatTableDataSource<Unit>;

  public pageSize: number = 10;
  public pageSizeOptions: number[] = [10, 20, 50, 100];

  private _subscriptions: Subscription[] = [];
  private _canAuthorDocument: boolean = false;

  constructor(private _documentService: DocumentService, private _permissionsService: PermissionsService) {}

  public ngOnInit(): void {
    const documentId: UUID = this._documentService.getSelected().documentId;
    this.dataSource = new MatTableDataSource<Unit>([]);
    this._subscriptions.push(
      this.paginator.page.subscribe(() => {
        this._refreshSearch();
      }),
      this._permissionsService
        .can(CarsAction.AUTHOR_DOCUMENT, documentId)
        .subscribe((canAuthor: boolean) => (this._canAuthorDocument = canAuthor))
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.searchTerm && this.unitsList) {
      this.paginator.pageIndex = 0;
      this._refreshSearch();
    }
  }

  private _refreshSearch(): void {
    const pageIndex = this.paginator && this.paginator.pageIndex ? this.paginator.pageIndex : 0;
    const size = this.paginator && this.paginator.pageSize ? this.paginator.pageSize : 10;
    const params: UnitSearchParams = {
      searchTerm: this.searchTerm ? this.searchTerm : '',
      page: pageIndex,
      pageSize: size,
    };
    this.onSearch.emit(params);
  }

  public selectUnit(unit: Unit): void {
    if (this._canAuthorDocument) {
      this.selection.emit(unit);
    }
  }
}
