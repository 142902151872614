<p>Example</p>

<div class="example">
  <div *ngIf="displayClauses.length === 0" class="guidance-text">Select an option to see an example requirement</div>

  <ng-container *ngIf="displayClauses.length !== 0">
    <div *ngFor="let clause of displayClauses; let i = index" class="clause-block">
      <div class="index">{{ formatIndex(i) }}</div>
      <span>{{ clause }}</span>
    </div>
  </ng-container>
</div>
