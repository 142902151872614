<h1 mat-dialog-title>Create new user</h1>
<div mat-dialog-content>
  <form (ngSubmit)="createUser()" #userCreate="ngForm">
    <mat-form-field>
      <input
        name="email"
        [(ngModel)]="email"
        matInput
        placeholder="Email"
        email
        aria-label="Enter a valid email address"
      />
      <mat-error>Please enter a valid email address</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        name="firstName"
        [(ngModel)]="firstName"
        matInput
        placeholder="First name"
        aria-label="Enter a first name"
      />
      <mat-hint align="end">Enter a first name</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <input name="lastName" [(ngModel)]="lastName" matInput placeholder="Last name" aria-label="Enter a last name" />
      <mat-hint align="end">Enter a last name</mat-hint>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    type="submit"
    mat-raised-button
    color="primary"
    (click)="createUser()"
    [disabled]="!userForm.valid"
    aria-label="Create user"
  >
    Create
  </button>
  <button mat-button (click)="close()" aria-label="Cancel user creation">Cancel</button>
</div>
<div *ngIf="loading" class="progress-bar-container">
  <mat-progress-bar mode="indeterminate" aria-label="'Creating user: ' + firstName + ' ' + lastName"></mat-progress-bar>
  <div class="loading-note">Creating {{ firstName }} {{ lastName }}, please wait a moment</div>
</div>
