export class Search {
  private static SIMILAR_TOLERANCE: number = 0.5;

  /**
   * Searches for sub-string occurances of the input in the given values.
   *
   * @param input   {string}   String to match
   * @param toCheck {string[]} Strings representing the values to check against
   * @returns       {boolean}  True if an occurance of the input
   */
  public static check(input: string, ...toCheck: string[]): boolean {
    if (!input) {
      return true;
    }
    input = input.toLowerCase();

    let match: boolean = false;
    for (const value of toCheck) {
      if (match) {
        break;
      }
      match = !!value && value.toLowerCase().indexOf(input) > -1;
    }
    return match;
  }

  /**
   * Returns true if the input string is similar to any of the strings in the toCheck array, within a certain tolerance.
   *
   * @param input
   * @param toCheck
   */
  public static similar(input: string, ...toCheck: string[]): boolean {
    if (!input) {
      return true;
    }
    input = input.toLowerCase();

    // This function calculates the levenshtein difference for the two given strings
    const levenshtein: Function = (str1: string, str2: string) => {
      const n: number = str1.length;
      const m: number = str2.length;

      if (n === 0 || m === 0) {
        return n === 0 ? m : n;
      }

      const matrix: number[][] = [];

      // Create the matrix with initial values
      matrix[0] = Array.apply(null, {length: m + 1}).map(Number.call, Number);
      for (let i: number = 1; i < n + 1; i++) {
        matrix[i] = new Array<number>(m + 1);
        matrix[i][0] = i;
      }

      // Calculate the matrix
      for (let i: number = 1; i < n + 1; i++) {
        for (let j: number = 1; j < m + 1; j++) {
          matrix[i][j] = Math.min(
            matrix[i - 1][j] + 1,
            matrix[i][j - 1] + 1,
            matrix[i - 1][j - 1] + (str1[i - 1] === str2[j - 1] ? 0 : 1)
          );
        }
      }

      return matrix[n][m];
    };

    let similar: boolean = false;
    for (const value of toCheck) {
      if (!similar) {
        similar =
          !!value &&
          levenshtein(input, value.toLowerCase()) / Math.max(input.length, value.length) < Search.SIMILAR_TOLERANCE;
      }
    }

    return similar;
  }

  protected constructor() {}
}
