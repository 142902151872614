import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {Router} from '@angular/router';
import {ClauseChangeSummaryService} from 'app/clause-change-summary/clause-change-summary.service';
import {DocumentFragment, Fragment} from 'app/fragment/types';
import {VersionTag} from 'app/interfaces';
import {PrintService} from 'app/print/print.service';
import {DocumentFetchParams, DocumentService} from 'app/services/document.service';
import {ImageService} from 'app/services/image.service';
import {UUID} from 'app/utils/uuid';
import {ViewService} from 'app/view/view.service';
import {take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {DocumentRole} from '../documents/document-data';
import {VersionTagType} from '../fragment/versioning/version-tag-type';
import {VersioningService} from '../fragment/versioning/versioning.service';
import {RoleService} from '../services/user/role.service';
import {VersionTagTypeToDisplayNamePipe} from '../utils/pipes/version-tag-type-to-display-name.pipe';
import {ClauseChangeSummaryDiffUtils} from './clause-change-summary.diff-utils';
import {ChangeSummaryRow, SectionChangeSummaryRow} from './types/change-summary-row';
import {ChangeSummaryRowType} from './types/change-summary-row-type';

@Component({
  selector: 'cars-clause-change-summary',
  templateUrl: './clause-change-summary.component.html',
  styleUrls: ['./clause-change-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VersionTagTypeToDisplayNamePipe],
})
export class ClauseChangeSummaryComponent implements OnInit, OnDestroy {
  public readonly printerMessage: string =
    'When printing the below summary to PDF please use landscape orientation and A3 papersize!';
  public readonly notGeneratedMessage: string =
    'Please select the versions to be compared and then click “Generate” to create the associated clause change summary.';
  public readonly noChangesMessage: string =
    'There are no clause changes to display for the versions selected for comparison.';
  public readonly noVersionsMessage: string =
    'There are no previous versions available to compare to. A clause change summary cannot be generated.';

  private documentId: UUID;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly RowType: typeof ChangeSummaryRowType = ChangeSummaryRowType;
  public changeSummaryRows: ChangeSummaryRow[];

  public loading: boolean = false;
  public hasClickedGenerate: boolean = false;
  public changes: boolean = true;
  public canGenerate: boolean = false;
  public noVersions: boolean = false;
  public showBackgroundCommentary: boolean;
  public isAReviewer: boolean;

  public versionOptions: VersionTag[] = [];
  public versionOptionsRH: VersionTag[] = [];
  public currentOptionLH: VersionTag;
  public currentOptionRH: VersionTag;

  private static readonly _LIVE_OPTION: VersionTag = {
    name: 'Live',
    versionId: null,
    fragmentId: null,
    availableToReview: false,
    availableForCommenting: false,
    userDiscussionsOnly: false,
    versionTagType: VersionTagType.NOT_KNOWN,
    versionNumber: ClauseChangeSummaryComponent._getDateString(),
  };

  private static _getDateString(): string {
    const currentDate: Date = new Date();

    const year: string = currentDate.getFullYear().toString();
    const month: string = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day: string = currentDate.getDate().toString().padStart(2, '0');

    return `LIVE_${year}_${month}_${day}`;
  }

  constructor(
    private _clauseChangeSummaryService: ClauseChangeSummaryService,
    private _documentService: DocumentService,
    private _viewService: ViewService,
    private _printService: PrintService,
    private _cdr: ChangeDetectorRef,
    private _imageService: ImageService,
    private _router: Router,
    private _roleService: RoleService,
    private _versioningService: VersioningService,
    private _versionTagTypeToDisplayNamePipe: VersionTagTypeToDisplayNamePipe
  ) {}

  public ngOnInit(): void {
    this.documentId = this._documentService.getSelected()?.id;
    this.showBackgroundCommentary = history.state?.showBackgroundCommentary ?? true;
    this.isAReviewer = this._roleService.isInDocumentRole(null, DocumentRole.REVIEWER);

    this._getVersions().then((taggedVersions: VersionTag[]) => {
      this.versionOptions = taggedVersions;

      if (taggedVersions.length > 0) {
        this._initialiseDropdownSelections();
      }

      this.adjustDropdownValues();

      if (this._router.url.includes('print')) {
        this.generate();
      }

      this._cdr.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    localStorage.removeItem('currentOptionLH');
    localStorage.removeItem('currentOptionRH');
    this.versionOptions = [];
    this.versionOptionsRH = [];
  }

  /**
   * Generate the clause change summary for the currently selected version tags
   */
  public generate(): void {
    this.hasClickedGenerate = true;
    this.changeSummaryRows = [];

    this._clauseChangeSummaryService
      .getSummary(this.documentId, this.currentOptionLH.versionId, this.currentOptionRH.versionId)
      .subscribe(
        (result: SectionChangeSummaryRow[]) => {
          Promise.all([
            this._getDocument(this.documentId, this.currentOptionLH),
            this._getDocument(this.documentId, this.currentOptionRH),
          ]).then(([previousDocument, currentDocument]: [DocumentFragment, DocumentFragment]) => {
            result.forEach((row: SectionChangeSummaryRow) => {
              ClauseChangeSummaryDiffUtils.diffChangeSummaryRow(row, previousDocument, currentDocument);
            });

            this.changeSummaryRows = this.flattenRows(result);
            this.loading = false;
            this.changes = result.length !== 0;
            this._cdr.markForCheck();

            setTimeout(() => {
              this._imageService.allImagesLoaded().then(() => this._printService.triggerPrintEvent());
            });
          });
        },
        (err) => {
          this.loading = false;
          this.changes = false;
          this._cdr.markForCheck();
        }
      );
  }

  public setLoading(): void {
    this.loading = true;
  }

  /**
   * Routes to the correct url on closing
   */
  public closeRoute(): void {
    this.setLoading();
    localStorage.removeItem('currentOptionLH');
    localStorage.removeItem('currentOptionRH');

    const splitUrl: string[] = this._router.url.split('/');
    let closeUrl: string = splitUrl.slice(0, -1).join('/');

    if (splitUrl.includes('print')) {
      splitUrl.splice(-2, 1);
      closeUrl = splitUrl.join('/');
    }

    this._router.navigateByUrl(closeUrl);
  }

  /**
   * Routes to the correct url on printing
   */
  public printRoute(): void {
    this._router.navigateByUrl(this._router.url.split('/').slice(0, -1).join('/') + '/print' + '/change-summary', {
      state: {
        showBackgroundCommentary: this.showBackgroundCommentary,
        currentOptionLH: this.currentOptionLH,
        currentOptionRH: this.currentOptionRH,
      },
    });
  }

  /**
   * Change the version selected in the left-hand dropdown.
   *
   * @param event {MatSelectChange} The event thrown by the chosen display option
   */
  public changeVersionLH(event: MatSelectChange): void {
    this.currentOptionLH = event.value;
    localStorage.setItem('currentOptionLH', this.currentOptionLH.versionNumber);
    this.adjustDropdownValues();
  }

  /**
   * Change the version selected in the right-hand dropdown.
   *
   * @param event {MatSelectChange} The event thrown by the chosen display option
   */
  public changeVersionRH(event: MatSelectChange): void {
    this.currentOptionRH = event.value;
    localStorage.setItem('currentOptionRH', this.currentOptionRH.versionNumber);
    this.canGenerate = this.currentOptionRH !== this.currentOptionLH;
  }

  /**
   * Formats the dropdown display string for a version tag
   * @param versionTag version tag for the document
   */
  public getOptionDisplayString(versionTag: VersionTag): string {
    if (versionTag === ClauseChangeSummaryComponent._LIVE_OPTION) {
      return versionTag.name;
    } else {
      return `${versionTag?.versionNumber}: ${this._versionTagTypeToDisplayNamePipe.transform(
        versionTag?.versionTagType
      )} - ${versionTag?.name}`;
    }
  }

  /**
   * Formats the header for the clause change summary when printing
   */
  public getClauseChangeSummaryPrintHeader(): string {
    return `Clause Change Summary: ${this.currentOptionLH?.versionNumber} compared to ${this.currentOptionRH?.versionNumber}`;
  }

  /**
   * Retrieves all versions for the document. Displays only available to review versions if a reviewer.
   */
  private _getVersions(): Promise<VersionTag[]> {
    return this._versioningService
      .getVersionTagsForFragmentId(this.documentId, this.isAReviewer)
      .pipe(take(1))
      .toPromise()
      .then((taggedVersions: VersionTag[]) => {
        if (taggedVersions.length === 0) {
          this.noVersions = true;
        }

        const allVersions: VersionTag[] = taggedVersions.slice();
        allVersions.sort((a: VersionTag, b: VersionTag) => b.createdAt - a.createdAt);

        if (!this.isAReviewer) {
          allVersions.unshift(ClauseChangeSummaryComponent._LIVE_OPTION);
        }

        return allVersions;
      });
  }

  /**
   * Selects dropdown versions from state history, used when printing
   * @private
   */
  private _setDropdownSelectionFromHistory(): void {
    this.currentOptionLH = this.versionOptions.find(
      (tag: VersionTag): boolean => tag.versionNumber === history.state.currentOptionLH.versionNumber
    );
    this.currentOptionRH = this.versionOptions.find(
      (tag: VersionTag): boolean => tag.versionNumber === history.state.currentOptionRH.versionNumber
    );
  }

  /**
   * Selects dropdown versions from local storage, used when refreshing
   * @private
   */
  private _setDropdownSelectionFromLocalStorage(): void {
    this.currentOptionLH = this.versionOptions.find(
      (tag: VersionTag): boolean => tag.versionNumber === localStorage.getItem('currentOptionLH')
    );
    this.currentOptionRH = this.versionOptions.find(
      (tag: VersionTag): boolean => tag.versionNumber === localStorage.getItem('currentOptionRH')
    );
  }

  /**
   * Selects dropdown versions by version, used on initial page load
   * Old Version >
   *    1. Most recent published version before current (if exists)
   *    2. Most recent version before current (if exists)
   *    3. Current version
   * New Version >
   *    1. Current Version
   */
  private _setDropdownSelectionFromVersions(): void {
    const currentVersionTag: VersionTag = this.versionOptions.find(
      (tag: VersionTag): boolean => tag.versionNumber === this._viewService.getCurrentView().versionTag?.versionNumber
    );
    this.currentOptionRH = !!currentVersionTag ? currentVersionTag : this.versionOptions[0];

    const versionTagIsOlderThanCurrent = (versionTag: VersionTag): boolean =>
      !this.currentOptionRH.createdAt || versionTag.createdAt < this.currentOptionRH.createdAt;
    const versionTagIsPublished = (versionTag: VersionTag): boolean =>
      versionTag.versionTagType === VersionTagType.FOR_PUBLICATION_PUBLISHED &&
      versionTagIsOlderThanCurrent(versionTag);
    const versionTagIsVersion = (versionTag: VersionTag): boolean =>
      versionTag.versionTagType !== VersionTagType.NOT_KNOWN && versionTagIsOlderThanCurrent(versionTag);

    const recentPublishedVersion: VersionTag = this.versionOptions.find(versionTagIsPublished);
    const recentVersion: VersionTag = !!recentPublishedVersion
      ? recentPublishedVersion
      : this.versionOptions.find(versionTagIsVersion);

    this.currentOptionLH = !!recentVersion ? recentVersion : this.currentOptionRH;

    localStorage.setItem('currentOptionLH', this.currentOptionLH.versionNumber);
    localStorage.setItem('currentOptionRH', this.currentOptionRH.versionNumber);
  }

  /**
   * Initialises selected values in dropdowns on page load
   * @private
   */
  private _initialiseDropdownSelections(): void {
    if (!!history.state.currentOptionLH) {
      this._setDropdownSelectionFromHistory();
      return;
    }

    if (!!localStorage.getItem('currentOptionLH')) {
      this._setDropdownSelectionFromLocalStorage();
      return;
    }

    this._setDropdownSelectionFromVersions();
  }

  /**
   * Adjusts selectable options for RH dropdown based on LH selection to prevent out of order selection
   * @private
   */
  private adjustDropdownValues(): void {
    const currentLHIndex: number = this.versionOptions.indexOf(this.currentOptionLH);

    if (currentLHIndex < 0) {
      return;
    }

    const currentRHIndex: number = this.versionOptions.indexOf(this.currentOptionRH);
    this.versionOptionsRH = this.versionOptions.slice(0, currentLHIndex + 1);

    if (currentRHIndex > currentLHIndex) {
      this.currentOptionRH = this.currentOptionLH;
      localStorage.setItem('currentOptionRH', this.currentOptionRH.versionNumber);
    }

    this.canGenerate = this.currentOptionRH !== this.currentOptionLH;
  }

  private _getDocument(documentId: UUID, versionTag: VersionTag): Promise<DocumentFragment> {
    const params: DocumentFetchParams =
      versionTag?.createdAt !== null
        ? {projection: 'INITIAL_DOCUMENT_LOAD', validAt: versionTag?.createdAt}
        : {projection: 'INITIAL_DOCUMENT_LOAD'};

    return this._documentService.load(documentId, params, false);
  }

  private flattenRows(
    rows: ChangeSummaryRow[],
    oldFragmentMap: Record<string, Fragment> = {},
    newFragmentMap: Record<string, Fragment> = {}
  ): ChangeSummaryRow[] {
    const flatArray: ChangeSummaryRow[] = [];

    rows.forEach((row: ChangeSummaryRow) => {
      this.addToMapAndSetParent(oldFragmentMap, row.oldFragment);
      this.addToMapAndSetParent(newFragmentMap, row.newFragment);
      flatArray.push(row);
      flatArray.push(...this.flattenRows(row.childRows, oldFragmentMap, newFragmentMap));
    });

    return flatArray;
  }

  private addToMapAndSetParent(map: Record<string, Fragment>, fragment: Fragment): void {
    if (!fragment) {
      return;
    }

    map[fragment.id.value] = fragment;

    if (fragment.parentId) {
      fragment.parent = map[fragment.parentId.value];
    }
  }
}
