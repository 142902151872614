import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatLineModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {RouterModule} from '@angular/router';
import {FragmentIndexModule} from 'app/fragment/indexing/fragment-index.module';
import {ExtendedTimeAgoModule} from 'app/utils/pipes/extended-time-ago.module';
import {DocumentMetricsComponent} from './document-metrics.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    RouterModule,
    MatLineModule,
    MatProgressBarModule,
    ExtendedTimeAgoModule,
    FragmentIndexModule,
  ],
  declarations: [DocumentMetricsComponent],
  exports: [DocumentMetricsComponent],
})
export class DocumentMetricsModule {}
