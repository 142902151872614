import {UUID} from 'app/utils/uuid';

export class Unit {
  public static deserialise(json: any): Unit {
    const id: UUID = UUID.orThrow(json.id);
    const equationSource: string = json.equationSource;
    const title: string = json.title;
    const description: string = json.description;

    return new Unit(id, equationSource, title, description);
  }

  constructor(public id: UUID, public equationSource: string, public title: string, public description: string) {}
}
