import {LocalConfig} from 'app/interfaces';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {UUID} from './uuid';

export class LocalConfigUtils {
  private static readonly CONFIG_KEY = 'cars-config';
  private static readonly DEFAULT_WIDTH = 300;
  private static readonly DEFAULT_CONFIG = {
    warnedBeta: false,
    warnedBrowsers: false,
    sidebarWidth: LocalConfigUtils.DEFAULT_WIDTH,
    navbarWidth: LocalConfigUtils.DEFAULT_WIDTH,
    documentNavbarOpen: true,
    changelogNavbarOpen: true,
    chatWindowSize: {
      width: 400,
      height: 500,
    },
    documentPageSize: 5,
    sendOnReturn: true,

    offline: false,
    shownWelcome: false,
    openedChangelogIdPairs: {},
  };

  private static _cachedConfig: LocalConfig = null;
  private static _configSubject: Subject<LocalConfig> = new ReplaySubject(1);

  public static getConfig(): LocalConfig {
    if (!this._cachedConfig) {
      const storedConfig = JSON.parse(localStorage.getItem(this.CONFIG_KEY)) as LocalConfig;
      this._cachedConfig = Object.assign({}, this.DEFAULT_CONFIG, storedConfig);
      this.saveConfig(this._cachedConfig);
    }
    return this._cachedConfig;
  }

  private static saveConfig(config: LocalConfig) {
    localStorage.setItem(this.CONFIG_KEY, JSON.stringify(config));
    this._cachedConfig = Object.assign({}, this.DEFAULT_CONFIG, config);
    this._configSubject.next(this._cachedConfig);
  }

  public static localConfigHasProperty(property: string): Observable<boolean> {
    return this._configSubject.pipe(map((localConfig: LocalConfig) => localConfig[property]));
  }

  public static setWarnedBrowsers(val: boolean) {
    const config = this.getConfig();
    config.warnedBrowsers = val;
    this.saveConfig(config);
  }

  public static setWarnedBeta(val: boolean) {
    const config = this.getConfig();
    config.warnedBeta = val;
    this.saveConfig(config);
  }

  public static setSidebarWidth(val: number) {
    const config = this.getConfig();
    config.sidebarWidth = val;
    this.saveConfig(config);
  }

  public static setNavbarWidth(val: number) {
    const config = this.getConfig();
    config.navbarWidth = val;
    this.saveConfig(config);
  }

  public static setChangelogNavbarOpen(val: boolean) {
    const config = this.getConfig();
    config.changelogNavbarOpen = val;
    this.saveConfig(config);
  }

  public static getChangelogNavbarOpen(): boolean {
    const config = this.getConfig();
    return config.changelogNavbarOpen || false;
  }

  public static setDocumentNavbarOpen(val: boolean) {
    const config = this.getConfig();
    config.documentNavbarOpen = val;
    this.saveConfig(config);
  }

  public static getDocumentNavbarOpen(): boolean {
    const config = this.getConfig();
    return config.documentNavbarOpen || false;
  }

  public static setChatWindowSize(width: number, height: number) {
    const config = this.getConfig();
    config.chatWindowSize.width = width;
    config.chatWindowSize.height = height;
    this.saveConfig(config);
  }

  public static setDocumentPageSize(val: number) {
    const config = this.getConfig();
    config.documentPageSize = val;
    this.saveConfig(config);
  }

  public static setSendOnReturn(val: boolean) {
    const config = this.getConfig();
    config.sendOnReturn = val;
    this.saveConfig(config);
  }

  public static setOffline(val: boolean) {
    const config = this.getConfig();
    config.offline = val;
    this.saveConfig(config);
  }

  public static setOpenedChangelogIdPair(authored: UUID, published: UUID): void {
    const config = this.getConfig();
    config.openedChangelogIdPairs[authored.value] = published;
    this.saveConfig(config);
  }

  public static setShownWelcome(val: boolean) {
    const config = this.getConfig();
    config.shownWelcome = val;
    this.saveConfig(config);
  }
}
