import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UserModule} from 'app/widgets/user/user.module';
import {TransitionComponent} from './transition.component';

@NgModule({
  imports: [CommonModule, UserModule],
  declarations: [TransitionComponent],
  exports: [TransitionComponent],
})
export class TransitionModule {}
