import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'cars-naa-export-selector',
  templateUrl: './naa-export-selector.component.html',
  styleUrls: ['./naa-export-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NaaExportSelectorComponent implements OnInit {
  public message: string =
    'Document has NAAs. Do you wish to export just the core document, or the core document with NAAs?';

  constructor(private _dialogRef: MatDialogRef<NaaExportSelectorComponent>) {}

  ngOnInit() {
    this._dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this._dialogRef.close(null);
      }
    });
  }

  public exportHas(naas: boolean): void {
    this._dialogRef.close(naas);
  }
}
