import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSelectChange} from '@angular/material/select';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentService} from 'app/services/document.service';
import {environment} from 'environments/environment';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, throttleTime} from 'rxjs/operators';
import {Logger} from '../../error-handling/services/logger/logger.service';
import {ClauseType, DocumentFragment} from '../../fragment/types';
import {SearchableDocument, SearchDocumentsService, SearchResult} from '../../search/search-documents.service';
import {UUID} from '../../utils/uuid';
import {ChangelogLink} from '../changelog-link';
import {ChangelogProperty} from '../changelog-property';
import {ChangelogService} from '../changelog.service';
import {ClauseRow} from './clause-row';
import {LinkRow} from './link-row';
import {LinkTableService} from './link-table.service';
import {RangeRow} from './range-row';

interface OutcomeDocument {
  id: UUID;
  title: string;
}

@Component({
  selector: 'cars-link-table',
  templateUrl: './link-table.component.html',
  styleUrls: ['./link-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkTableComponent implements OnInit, OnDestroy {
  public tooltipDelay: number = environment.tooltipDelay;

  public closeRoute: string = '';

  public publishedDocuments: SearchableDocument[];

  public jumpFormControl: UntypedFormControl;
  public jumpFormGroup: UntypedFormGroup;

  public displayOutcomeOptions: any[] = [
    {
      key: 'ALL',
      text: 'All',
      aria: 'See all outcomes',
      tooltip: 'See all outcomes',
    },
    {
      key: 'TECHNICAL',
      text: 'Technical',
      aria: 'See only technical changes',
      tooltip: 'See only technical changes',
    },
    {
      key: 'EDITORIAL',
      text: 'Editorial',
      aria: 'See only editorial changes',
      tooltip: 'See only editorial changes',
    },
    {
      key: 'TECHNICAL_AND_EDITORIAL',
      text: 'Technical and Editorial',
      aria: 'See only changes that are both Technical and Editorial',
      tooltip: 'See only changes that are both Technical and Editorial',
    },
    {
      key: 'DELETED',
      text: 'Deleted',
      aria: 'See only deleted ranges',
      tooltip: 'See only deleted ranges',
    },
    {
      key: 'NO_CHANGES',
      text: 'No changes',
      aria: 'See only clauses with no changes',
      tooltip: 'See only clauses with no changes',
    },
  ];

  public outcomeDocumentFilterOptions: OutcomeDocument[] = [];

  public selectedFilter: string = 'ALL';

  private _selectedOutcomeDocument: string = 'ALL_DOCUMENTS';

  private _clauseRowMap: Map<string, ClauseRow> = new Map();

  public clauseRows: ClauseRow[] = [];
  public displayedClauseRows: ClauseRow[] = [];

  public selectedPublishedDocument: DocumentFragment;

  public readonly ClauseType = ClauseType;

  public publishedDocumentFilter: string = '';
  public publishedDocumentFilterTermChanged: Subject<string> = new Subject<string>();

  public loading: boolean = false;
  public rendering: boolean = false;

  public allShownLinkRowsForClauseRow: Map<string, LinkRow[]> = new Map();
  public showEfficiencySchedule: boolean = true;
  public changelogProperty: ChangelogProperty;
  public savingEfficiency: boolean = false;
  private _efficiencyChange: Subject<string> = new Subject();

  private _subscriptions: Subscription[] = [];

  public rowsLoaded: number = 0;
  public progress: number = 0;
  public startClauseRow: number;
  public endClauseRow: number;
  public pageIndex: number;
  public pageSize: number = 10;
  public pageSizeOptions: number[] = [10, 20, 50, 100];
  public lastValidPage: number;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private _changelogService: ChangelogService,
    private _documentService: DocumentService,
    private _linkTableService: LinkTableService,
    private _searchDocumentsService: SearchDocumentsService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.startClauseRow = 0;
    this.pageIndex = 0;
    this.lastValidPage = 1;
    this.jumpFormControl = new UntypedFormControl({value: 1, disabled: this.loading || this.rendering}, [
      Validators.min(1),
      Validators.max(1),
    ]);
    this.jumpFormGroup = new UntypedFormGroup({jumpFormControl: this.jumpFormControl});
    this._subscriptions.push(
      this._efficiencyChange.pipe(debounceTime(500)).subscribe(() => {
        this.efficiencyChanged();
      }),
      this._documentService.onSelection((document: DocumentFragment) => {
        this.selectNewDocument(document);
      }),
      this.publishedDocumentFilterTermChanged.pipe(debounceTime(200), distinctUntilChanged()).subscribe((debounced) => {
        this.filterDocuments(debounced);
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  private efficiencyChanged() {
    this._changelogService.updateChangelogProperty(this.changelogProperty).then(() => (this.savingEfficiency = false));
  }

  private selectNewDocument(document: DocumentFragment) {
    if (document && (document.equals(this.selectedPublishedDocument) || !document.isLegacySuite())) {
      return;
    }
    this.loading = true;
    this._linkTableService.linkTableProgressService.init();
    this._linkTableService.linkTableProgressService.percentSubject
      .pipe(throttleTime(50))
      .subscribe((percent: number) => {
        this.progress = percent;
        this._cdr.markForCheck();
      });
    this._setUp(document);
    this.pageSize = 10;
    this.selectedFilter = 'ALL';
    this.goToFirstPage();
  }

  public pageLoaded(): void {
    this.rowsLoaded++;
    if (this.rowsLoaded === this.getShownClauseRowsForPage().length) {
      this.rendering = false;
      this._cdr.markForCheck();
    }
  }

  public goToFirstPage() {
    const page = new PageEvent();
    page.length = this.getShownClauseRows().length;
    page.pageIndex = 0;
    page.pageSize = this.pageSize;
    this.page(page);
  }

  public jumpToPage() {
    if (this.jumpFormControl.valid) {
      const page = new PageEvent();
      page.length = this.getShownClauseRows().length;
      page.pageIndex = this.jumpFormControl.value - 1;
      page.pageSize = this.pageSize;
      this.page(page);
    } else {
      this.validateFormControl();
    }
  }

  private validateFormControl() {
    if (this.jumpFormControl.errors.min) {
      this.jumpFormControl.setValue(1);
    } else if (this.jumpFormControl.errors.max) {
      this.jumpFormControl.setValue(this.maxPages());
    }
    if (this.lastValidPage !== this.jumpFormControl.value) {
      this.jumpToPage();
    }
  }

  public page(event: PageEvent) {
    this.rendering = event.length > 0;
    this.paginator.pageIndex = event.pageIndex;
    this.jumpFormControl.setValue(event.pageIndex + 1);
    this.lastValidPage = event.pageIndex + 1;
    this.displayedClauseRows = [];
    this._cdr.markForCheck();
    setTimeout(() => {
      this.rowsLoaded = 0;
      this.pageSize = event.pageSize;
      this.pageIndex = event.pageIndex;
      this.jumpFormControl.setValidators([Validators.min(1), Validators.max(this.maxPages())]);
      this.paginator.pageSizeOptions = [10, 20, 50, 100, event.length];
      this.startClauseRow = event.pageIndex * event.pageSize;
      this.endClauseRow = Math.min(this.startClauseRow + event.pageSize, event.length);
      this.displayedClauseRows = this.getShownClauseRowsForPage();
      this._cdr.markForCheck();
    });
  }

  public maxPages(): number {
    return Math.ceil(this.getShownClauseRows().length / this.pageSize);
  }

  private async _setUp(document: DocumentFragment): Promise<void> {
    await this.filterDocuments('');

    this.selectedPublishedDocument = document;
    if (!this.selectedPublishedDocument && this.publishedDocuments.length) {
      this._router.navigate(['/documents', this.publishedDocuments[0].DOCUMENT_ID.value, 'linktable'], {
        relativeTo: this._route,
      });
    }
    this.resetFilter();
    this.buildTable();
  }

  public onFilterNgModelChange(filterTerm: string) {
    this.publishedDocumentFilterTermChanged.next(filterTerm);
  }

  /**
   * Update the filteredDocuments array depending on the documentFilter.
   */
  public filterDocuments(filterTerm: string): Promise<SearchableDocument[]> {
    this.publishedDocumentFilter = filterTerm;
    return this._searchDocumentsService
      .changelog(this.publishedDocumentFilter)
      .then((res: SearchResult<SearchableDocument>) => {
        this.publishedDocuments = res.page;
        this._cdr.markForCheck();
        return this.publishedDocuments;
      })
      .catch((err) => {
        Logger.error('changelog-error', 'Failed to filter documents', err);
        this._cdr.markForCheck();
        return [];
      });
  }

  public onEfficiencyChange(a: any): void {
    this.savingEfficiency = true;
    this._efficiencyChange.next(a);
  }

  /**
   * Reset the document filter to the current document.
   */
  public resetFilter(): void {
    this.publishedDocumentFilter = this.selectedPublishedDocument ? this.selectedPublishedDocument.title : '';
  }

  /**
   * Empty the document filter.
   */
  public emptyFilter(): void {
    this.filterDocuments('');
  }

  /**
   * Select the published document.
   *
   * @param event    {MatOptionSelectionChange} The MatOptionSelectionChange event which caused this document to be selected.
   * @param document {DocumentFragment}         The document that was selected.
   */
  public onDropdownSelection(event: MatOptionSelectionChange, selectedDocument: SearchableDocument): void {
    // A selection from a mat-option in a mat-autocomplete will emit two events, it selects the new option and then deselects
    // the old one. This check ensures that we are not sending the document from the deselecting event.
    if (event.isUserInput) {
      this._router.navigate(['/documents', selectedDocument.DOCUMENT_ID.value, 'linktable'], {relativeTo: this._route});
    }
  }

  /**
   * Returns an array of every ClauseRow that should be shown in page.
   */
  public getShownClauseRowsForPage(): ClauseRow[] {
    return this.clauseRows.filter((c: ClauseRow) => c.show).slice(this.startClauseRow, this.endClauseRow);
  }

  /**
   * Returns an array of every ClauseRow that should be shown.
   */
  public getShownClauseRows(): ClauseRow[] {
    return this.clauseRows.filter((c: ClauseRow) => c.show);
  }

  /**
   * Refreshes cache of shown link rows
   */
  public calculateAllShownLinkRowInClauseRow(clauseRow: ClauseRow): void {
    const returnRows: LinkRow[] = [];
    clauseRow.shownChildren().forEach((row: RangeRow) => returnRows.push(...row.shownChildren()));
    this.allShownLinkRowsForClauseRow.set(clauseRow.clause.id.value, returnRows);
  }

  /**
   * Returns the number of rows the parent of the given LinkRow should span.
   */
  public getRangeRowspan(linkRow: LinkRow): number {
    return linkRow.parentRangeRow.shownChildren().length;
  }

  /**
   * Returns true if we should display the parent of the given linkRow.
   */
  public shouldShowRangeRowCell(linkRow: LinkRow): boolean {
    return linkRow.isFirstShownChild();
  }

  /**
   * Filter the shown rows by the link destination document.
   */
  public setFilteredRowsByDestination(event: MatSelectChange): void {
    this._selectedOutcomeDocument = event.value;
    this.allShownLinkRowsForClauseRow.clear();
    this.clauseRows.forEach((c: ClauseRow) => {
      c.filter(this._selectedOutcomeDocument, this.selectedFilter);
      this.calculateAllShownLinkRowInClauseRow(c);
    });
    this.goToFirstPage();
  }

  /**
   * Filters all rows depending on chosen outcome.
   *
   * @param event {string}  Which outcomes to show.
   */
  public setFilteredRowsByOutcome(event: string): void {
    this.selectedFilter = event;
    this.allShownLinkRowsForClauseRow.clear();
    this.clauseRows.forEach((c: ClauseRow) => {
      c.filter(this._selectedOutcomeDocument, this.selectedFilter);
      this.calculateAllShownLinkRowInClauseRow(c);
    });
    this.goToFirstPage();
  }

  public isSelected(s: string): boolean {
    return this.selectedFilter === s;
  }

  public isEmptyClauseRow(clauseRow: ClauseRow): boolean {
    return clauseRow.children.length === 0 || this.clauseRowChildrenAreEmpty(clauseRow);
  }

  private clauseRowChildrenAreEmpty(clauseRow: ClauseRow): boolean {
    let empty: boolean = true;
    clauseRow.children.forEach((rangeRow: RangeRow) => {
      empty = empty && rangeRow.children.length === 0;
    });
    return empty;
  }

  /**
   * Build table by building/pushing clauserows into the map
   */
  private buildTable(): void {
    this.loading = true;
    this._clauseRowMap.clear();
    this.allShownLinkRowsForClauseRow.clear();
    this.clauseRows = [];

    if (this.selectedPublishedDocument) {
      this._changelogService
        .fetchFullPublishedDocumentTree(this.selectedPublishedDocument.id)
        .then((doc: DocumentFragment) => {
          this._changelogService.setPublishedDocument(doc);
          this._linkTableService.fetchChangelogLinkTable(this.selectedPublishedDocument.id).then((response) => {
            this.updateChangelogProperty();
            response.forEach((clauseRow: ClauseRow, i: number) => {
              this.addAuthoredDocumentsToDropdown(clauseRow);
              this._clauseRowMap.set(clauseRow.clause.id.value, clauseRow);
              this.clauseRows.push(clauseRow);
              this.calculateAllShownLinkRowInClauseRow(clauseRow);
            });
            this.loading = false;
            this.goToFirstPage();
          });
        });
    }
  }

  private addAuthoredDocumentsToDropdown(clauseRow: ClauseRow) {
    clauseRow.children.forEach((rangeRow: RangeRow) => {
      rangeRow.children.forEach((linkRow: LinkRow) => {
        if (
          linkRow.locationInformation.documentId &&
          !this.outcomeDocumentFilterOptions.find((doc: OutcomeDocument) =>
            linkRow.locationInformation.documentId.equals(doc.id)
          )
        ) {
          this.outcomeDocumentFilterOptions.push({
            id: linkRow.locationInformation.documentId,
            title: linkRow.locationInformation.documentTitle,
          });
        }
      });
    });
  }

  private updateChangelogProperty(): void {
    this._changelogService.findChangelogPropertyForDocument(this.selectedPublishedDocument.id).then((property) => {
      if (!!property && this.selectedPublishedDocument.id.equals(property.publishedDocument)) {
        this.changelogProperty = property;
        this._cdr.markForCheck();
      } else {
        this._changelogService
          .createChangelogProperty(ChangelogProperty.empty(this.selectedPublishedDocument.id))
          .then((prop) => {
            this.changelogProperty = prop;
            this._cdr.markForCheck();
          });
      }
    });
  }

  public getRouterLink(linkRow: LinkRow): string {
    return (
      '/documents/' +
      linkRow.locationInformation.documentId.value +
      '/sections/' +
      linkRow.locationInformation.sectionId.value +
      '/changelog'
    );
  }

  public formatLinkType(link: ChangelogLink): string {
    switch (link.type) {
      case 'TECHNICAL':
        return 'Technical';
      case 'EDITORIAL':
        return 'Editorial';
      case 'TECHNICAL_AND_EDITORIAL':
        return 'Technical and Editorial';
      default:
        Logger.error('changelog-error', 'Unable to format changelog LinkType');
    }
  }

  public exit(): void {
    this.loading = true;
    const exitRoute: string[] = this._linkTableService.getExitRoute();
    if (exitRoute && exitRoute.length) {
      this._router.navigate(exitRoute, {relativeTo: this._route});
    } else {
      this._router.navigate(['/']);
    }
  }
}
