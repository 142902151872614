import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FragmentModule} from 'app/fragment/fragment.module';
import {ClauseChangeSummaryClauseCellModule} from './clause-change-summary-clause-cell/clause-change-summary-clause-cell.module';
import {ClauseChangeSummaryRowComponent} from './clause-change-summary-row.component';

@NgModule({
  imports: [CommonModule, FragmentModule, ClauseChangeSummaryClauseCellModule],
  declarations: [ClauseChangeSummaryRowComponent],
  exports: [ClauseChangeSummaryRowComponent],
})
export class ClauseChangeSummaryRowModule {}
