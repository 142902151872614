import {AdministrationUtils} from 'app/documents/administration-utils';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';

interface SortableReferenceParts {
  documentCode: string;
  wsrCode: string;
  sectionIndex: number;
  sectionAdministration: string;
  fragmentOrderingWeight: number;
  fragment: InternalDocumentReferenceFragment;
}

/**
 * Utils method for sorting of internal references, this works for clause, section and WSR references, but does not
 * handle sorting section references amongst clause references.
 * NOTE Any changes made to this class should also be reflected in the logic in the service.
 */
export class ReferenceSortingUtils {
  // Placeholder value to use for the ordering if not set, this pushes these values to the end
  public static readonly ORDERING_WEIGHT_NOT_SET: number = Number.MAX_SAFE_INTEGER;

  /**
   * Sorts the provided internal references, and returns a map of the sorted order from document references id to the
   * sorted index.
   */
  public static sortReferencesIntoMap(
    internalDocumentReferences: InternalDocumentReferenceFragment[]
  ): Record<string, number> {
    const sortedReferences: InternalDocumentReferenceFragment[] = this.getSortedReferences(internalDocumentReferences);
    const sortedIndicesMap: Record<string, number> = {};
    sortedReferences.forEach((indexFragment, index) => {
      sortedIndicesMap[indexFragment.id.value] = index + 1;
    });

    return sortedIndicesMap;
  }

  /**
   * Sorts the provided internal references, and returns a new sorted list.
   */
  public static getSortedReferences(
    internalDocumentReferences: InternalDocumentReferenceFragment[]
  ): InternalDocumentReferenceFragment[] {
    return internalDocumentReferences
      .map(this.buildSortableObject)
      .sort(this.compare)
      .map((sortableRef) => sortableRef.fragment);
  }

  private static buildSortableObject(
    internalDocumentReference: InternalDocumentReferenceFragment
  ): SortableReferenceParts {
    return {
      documentCode: internalDocumentReference.documentCode || 'ZZ 9999',
      wsrCode: internalDocumentReference.wsrCode || 'ZZ 9999/WSR/999',
      sectionIndex: Number(internalDocumentReference.sectionIndex?.match(/\d+/)?.[0]) || 99999,
      sectionAdministration: ReferenceSortingUtils.getAdministration(internalDocumentReference.sectionIndex) || 'ZZZ',
      fragmentOrderingWeight:
        internalDocumentReference.targetFragmentOrderingWeight || ReferenceSortingUtils.ORDERING_WEIGHT_NOT_SET,
      fragment: internalDocumentReference,
    };
  }

  private static getAdministration(sectionIndex: string): string {
    return AdministrationUtils.getAllAdministrations().find((admin) =>
      sectionIndex?.includes(AdministrationUtils.getInitials(admin))
    );
  }

  /**
   * Compares two references, this finds the result of the first property that is unmatched, note the order of
   * priorities is:
   * - Document code
   * - Fragment ordering weight (only set for clause references)
   * - WSR code (only set for WSR references)
   * - Section index (set for all internal references)
   * - Section administration (only set for targets within an NDS)
   */
  private static compare(a: SortableReferenceParts, b: SortableReferenceParts): number {
    const comparisons: number[] = [
      a.documentCode.localeCompare(b.documentCode),
      a.fragmentOrderingWeight - b.fragmentOrderingWeight,
      a.wsrCode.localeCompare(b.wsrCode),
      a.sectionIndex - b.sectionIndex,
      a.sectionAdministration.localeCompare(b.sectionAdministration),
    ].filter((val) => val !== 0);
    return comparisons.length > 0 ? comparisons[0] : 0;
  }
}
