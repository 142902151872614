import {Injectable} from '@angular/core';
import {StandardFormatType} from 'app/fragment/types/standard-format-type';
import {Callback} from 'app/utils/typedefs';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable()
export class StandardFormatGroupModalService {
  private _selectedTypeSubject: BehaviorSubject<StandardFormatType> = new BehaviorSubject(null);

  public updateSelectedType(type: StandardFormatType): void {
    this._selectedTypeSubject.next(type);
  }

  public onSelectedTypeChange(callback: Callback<StandardFormatType>): Subscription {
    return this._selectedTypeSubject.subscribe(callback);
  }
}
