import {Injectable} from '@angular/core';
import {FragmentState} from 'app/fragment/fragment-state';
import {Fragment} from 'app/fragment/types';

@Injectable()
export class ClauseChangeSummaryClauseCellIndexerService {
  private _newIdToIndex: Record<string, string> = {};
  private _oldIdToIndex: Record<string, string> = {};

  public getIndex(fragment: Fragment, defaultValue: string = ''): string {
    if (fragment === null) {
      return defaultValue;
    }

    const id: string = fragment.id.value;
    const state: FragmentState = fragment.state;

    switch (state) {
      case FragmentState.DELETED:
        return this._oldIdToIndex[id] || defaultValue;
      case FragmentState.UNMODIFIED:
      case FragmentState.MOVED:
      case FragmentState.CREATED:
      default:
        return this._newIdToIndex[id] || this._oldIdToIndex[id] || defaultValue;
    }
  }

  public setOldIndexMap(indexMap: Record<string, string>): void {
    this._oldIdToIndex = indexMap;
  }

  public setNewIndexMap(indexMap: Record<string, string>): void {
    this._newIdToIndex = indexMap;
  }
}
