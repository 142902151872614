import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {BackgroundComponent} from './background.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatChipsModule, AltAccessibilityModule],
  declarations: [BackgroundComponent],
  exports: [BackgroundComponent],
})
export class BackgroundModule {}
