import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {UserReviewState} from 'app/services/review-state.service';
import {environment} from 'environments/environment';
import {ReviewStatusTableData} from './review-status-table-data';

@Component({
  selector: 'cars-review-status-table',
  templateUrl: './review-status-table.component.html',
  styleUrls: ['./review-status-table.component.scss'],
})
export class ReviewStatusTableComponent implements AfterViewInit {
  @Input('userReviewStates') public set userReviewStates(value: UserReviewState[]) {
    this.dataSource.data = value.map((state: UserReviewState) => new ReviewStatusTableData(state));
  }

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public readonly dateFormat: string = 'dd/MM/yyyy';

  public readonly dataSource: MatTableDataSource<ReviewStatusTableData> =
    new MatTableDataSource<ReviewStatusTableData>();

  public readonly displayedColumns: string[] = ['toggleExpanded', 'name', 'reviewStatus', 'commentStatus'];

  public expanded: boolean = true;

  @ViewChild(MatSort) public sort: MatSort;

  constructor() {}

  public ngAfterViewInit(): void {
    this.dataSource.sortData = (data: ReviewStatusTableData[], sort: MatSort) =>
      data.sort((a: ReviewStatusTableData, b: ReviewStatusTableData) =>
        a.compare(b, {active: sort.active, direction: sort.direction})
      );
    this.dataSource.sort = this.sort;
  }

  public toggleExpanded(): void {
    this.expanded = !this.expanded;
  }
}
