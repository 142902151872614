import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {UserModule} from 'app/widgets/user/user.module';
import {RoleAssignmentsComponent} from './role-assignments.component';
import {UserListAutocompleteModule} from './user-list-autocomplete/user-list-autocomplete.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatDividerModule,
    UserListAutocompleteModule,
    UserModule,
    CarsPermissionPipesModule,
  ],
  declarations: [RoleAssignmentsComponent],
  exports: [RoleAssignmentsComponent],
})
export class RoleAssignmentsModule {}
