<div class="container">
  <span class="caption">{{ index }} {{ caption }}</span>
  <table>
    <thead></thead>
    <tbody>
      <tr *ngFor="let row of rows; let i = index">
        <ng-container *ngFor="let cell of row.cells; let j = index">
          <td
            [rowSpan]="cell.rowSpan"
            [colSpan]="cell.colSpan"
            [height]="'16px'"
            [class.table-bold]="cell.bold"
            [class.header-border]="cell.headerRowBorder"
            [style.width]="i === 0 ? columnWidths[j] + '%' : 'auto'"
          >
            <cars-tdif-content [content]="cell.content"></cars-tdif-content>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
