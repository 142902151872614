import {VersionTag} from 'app/interfaces';
import {GlobalRole} from 'app/services/user/authentication-provider';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {DocumentRole} from '../documents/document-data';
import {UUID} from '../utils/uuid';

/**
 * An enumeration of all possible view modes within CARS.
 */
export enum ViewMode {
  INIT, // The view is initialising
  LIVE, // Live document view
  HISTORICAL, // Historical document view
  EXPORT, // Export/print preview view
  BACKGROUND, // Background and commentary view
  COMMENTS, // Discussion and comments view
  COMPARE, // Compare with previous version view
  CHANGE_SUMMARY, // Clause change summary view
  LINK_TABLE,
  CHANGELOG, // The main changelog view
  CHANGELOG_MARKUP, // The markup changelog view
  WSR_SUBJECT_TOPIC, // WSR Subject Topic Mapping view
  CHANGELOG_AUX, // Secondary changelog view, governs permissions for left hand pad.
  // This should never be the official view received from the ViewService.
}

/**
 * A class to encapsulate information on the current view within CARS.
 */
export class CurrentView {
  /**
   * Static factory to create the initialising view.
   */
  public static createInitView(): CurrentView {
    return new CurrentView(ViewMode.INIT, null, null, null);
  }

  constructor(
    public readonly viewMode: ViewMode,
    public readonly userRole: DocumentRole,
    public readonly versionTag: VersionTag,
    public readonly canAuthor: boolean,
    public readonly otherVersionTag: VersionTag = null,
    public readonly userGlobalRoles: GlobalRole[] = []
  ) {}

  /**
   * @returns {boolean} true if the current view mode is LIVE
   */
  public isLive(): boolean {
    return this.viewMode === ViewMode.LIVE;
  }

  /**
   * @returns {boolean} true if the current view mode is HISTORICAL
   */
  public isHistorical(): boolean {
    return this.viewMode === ViewMode.HISTORICAL;
  }

  /**
   * @returns {boolean} true if the current view mode is EXPORT
   */
  public isExport(): boolean {
    return this.viewMode === ViewMode.EXPORT;
  }

  /**
   * @returns {boolean} true if the current view mode is COMMENTS
   */
  public isComments(): boolean {
    return this.viewMode === ViewMode.COMMENTS;
  }

  /**
   * @returns {boolean} true if the current view mode is BACKGROUND
   */
  public isBackground(): boolean {
    return this.viewMode === ViewMode.BACKGROUND;
  }

  /**
   * @returns {boolean} true if the current view mode is COMPARE
   */
  public isCompare(): boolean {
    return this.viewMode === ViewMode.COMPARE;
  }

  /**
   * @returns {boolean} true if the current view mode is CHANGELOG
   */
  public isChangelog(): boolean {
    return this.viewMode === ViewMode.CHANGELOG;
  }

  /**
   * @returns {boolean} true if the current view mode is CHANGELOG_AUX
   */
  public isChangelogAux(): boolean {
    return this.viewMode === ViewMode.CHANGELOG_AUX;
  }

  /**
   * @returns {boolean} true if the current view mode is CHANGELOG_MARKUP
   */
  public isChangelogMarkup(): boolean {
    return this.viewMode === ViewMode.CHANGELOG_MARKUP;
  }

  public isLinkTable(): boolean {
    return this.viewMode === ViewMode.LINK_TABLE;
  }

  /**
   * @returns {UUID} version id for the version tag
   */
  public getCurrentVersionId(): UUID {
    return !!this.versionTag ? this.versionTag.versionId : null;
  }

  /**
   * @returns {boolean} true if the current document is available to comment against
   */
  public isAvailableToCommentAgainst(): boolean {
    return (
      this.isLive() ||
      this.isChangelog() ||
      (this.isHistorical() && this.versionIsAvailableForCommenting()) ||
      (this.isComments() && this.versionIsAvailableForCommenting())
    );
  }

  /**
   * @returns {boolean} True if viewing a non-live version of the document, even if
   * the current view is not the pad, for example if viewing background or comments.
   */
  public isHistoricalDocument(): boolean {
    return this.versionTag !== null;
  }

  /**
   * @returns true if the user can suggest text change against the current document
   */
  public userCanSuggestTextChange(): boolean {
    return this.isLive();
  }

  /**
   * @returns {boolean} true if the user can lock a clause in the current document
   */
  public userCanLockClause(): boolean {
    return (this.isLive() || this.isChangelog()) && this.userIsAnAuthor();
  }

  /**
   * @returns {boolean} true if the section pad toolbar should be displayed
   */
  public showPadToolbar(): boolean {
    return (this.isLive() || this.isChangelog()) && this.userIsAnAuthor();
  }

  /**
   * @returns {boolean} true if the section pad should be maximum height
   */
  public padMaxHeight(): boolean {
    return this.isExport() || (this.isLive() && !this.userIsAnAuthor() && !this.userIsAReviewer()) || this.isCompare();
  }

  /**
   * @returns {boolean} true if the section pad header should be displayed
   */
  public showSectionPadHeader(): boolean {
    return (this.userIsAReviewer() || this.isHistorical()) && !this.isExport() && !this.isCompare();
  }

  /**
   * @returns {boolean} true if the reviewer info should be displayed
   */
  public showReviewerInfo(): boolean {
    return this.userIsAReviewer() && (this.versionIsAvailableForCommenting() || this.isLive());
  }

  /**
   * @returns {boolean} true if the current user has a role allowing them to edit the document
   */
  public userIsAnAuthor(): boolean {
    return (
      this.userRole === DocumentRole.OWNER ||
      this.userRole === DocumentRole.LEAD_AUTHOR ||
      this.userRole === DocumentRole.AUTHOR ||
      this.userIsAnAdmin()
    );
  }

  public userIsAnAdmin(): boolean {
    return this.userGlobalRoles.some(
      (role: GlobalRole) => role === GlobalRole.DOCUMENT_ADMIN || role === GlobalRole.ADMIN
    );
  }

  /**
   * @returns {boolean} true if the current user is a reviewer on the document
   */
  public userIsAReviewer(): boolean {
    return this.userRole === DocumentRole.REVIEWER || this.userRole === DocumentRole.PEER_REVIEWER;
  }

  /**
   * @returns {boolean} true if the version tag has user discussions only enabled
   */
  public userDiscussionsOnly(): boolean {
    return !!this.versionTag && this.versionTag.userDiscussionsOnly === true;
  }

  /**
   * @returns {boolean} true if the versioned document is available for commenting
   */
  private versionIsAvailableForCommenting(): boolean {
    return this.versionTag === null ? true : this.versionTag.availableForCommenting === true;
  }

  /**
   *  @returns {boolean} true if the user currently has authoring rights on the document.
   */
  public isUserAuthoringEnabled(): boolean {
    return this.canAuthor || this.userIsAnAdmin();
  }

  public showDiscussionsMarginIcon(): boolean {
    return this.isLive() || this.isHistorical() || this.isCompare() || this.isChangelog() || this.isChangelogMarkup();
  }

  public showResolvedDiscussionsMarginIcon(): boolean {
    return this.isCompare();
  }

  public showValidationMarginIcon(): boolean {
    return this.isLive() || this.isHistorical() || this.isChangelog() || this.isChangelogMarkup();
  }

  public isOffline(): boolean {
    return LocalConfigUtils.getConfig().offline;
  }

  public isAnyChangelog(): boolean {
    return this.isChangelog() || this.isChangelogAux() || this.isChangelogMarkup();
  }
}
