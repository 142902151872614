import {Sort} from '@angular/material/sort';
import {ReviewStatusProperties, UserReviewState} from 'app/services/review-state.service';

export class ReviewStatusTableData {
  public userReviewState: UserReviewState;
  public userReviewDisplayProperties: ReviewStatusProperties;
  public commentString: string;

  constructor(userReviewState: UserReviewState) {
    this.userReviewState = userReviewState;
    this.userReviewDisplayProperties = UserReviewState.DISPLAY_PROPERTIES[userReviewState.reviewState];
    this._setCommentString();
  }

  public compare(other: ReviewStatusTableData, sort: Sort): number {
    const direction: number = sort.direction === 'asc' ? 1 : -1;
    switch (sort.active) {
      case 'name':
        return this._compareUserNames(other) * direction;
      case 'reviewStatus':
        return this._compareReviewStatus(other) * direction;
      case 'commentStatus':
        return this._compareDiscussions(other) * direction;
      default:
        return 0;
    }
  }

  private _compareUserNames(other: ReviewStatusTableData): number {
    return this.userReviewState.userName.toLowerCase() < other.userReviewState.userName.toLowerCase() ? -1 : 1;
  }

  private _compareReviewStatus(other: ReviewStatusTableData): number {
    return this.userReviewState.reviewState < other.userReviewState.reviewState ? -1 : 1;
  }

  private _compareDiscussions(other: ReviewStatusTableData): number {
    const unresolved: number = this.userReviewState.discussions - this.userReviewState.resolvedDiscussions;
    const otherUnresolved: number = other.userReviewState.discussions - other.userReviewState.resolvedDiscussions;
    return unresolved < otherUnresolved ? -1 : 1;
  }

  private _setCommentString(): void {
    if (!this.userReviewState.discussions) {
      this.commentString = 'No discussions';
    } else {
      this.commentString = `${this.userReviewState.resolvedDiscussions}/${this.userReviewState.discussions} Discussions resolved`;
    }
  }
}
