import {Pipe, PipeTransform} from '@angular/core';
import {DocumentChanges} from 'app/documents/document-changes';

export const CHANGES_TO_DOCUMENT: Readonly<Record<DocumentChanges, string>> = {
  [DocumentChanges.CORE_DOCUMENT]: 'Core document',
  [DocumentChanges.ENGLAND]: 'England NAA',
  [DocumentChanges.NORTHERN_IRELAND]: 'Northern Ireland NAA',
  [DocumentChanges.SCOTLAND]: 'Scotland NAA',
  [DocumentChanges.WALES]: 'Wales NAA',
};

@Pipe({
  name: 'documentChangesToDisplayName',
})
export class DocumentChangesToDisplayNamePipe implements PipeTransform {
  transform(documentChanges: string): string {
    return CHANGES_TO_DOCUMENT[documentChanges] ?? '';
  }
}
