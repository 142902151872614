import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {ClauseLinkRequired} from 'app/fragment/fragment-link/clause-link-required';
import {ClauseLinkType} from 'app/fragment/fragment-link/clause-link-type';
import {FragmentLink} from 'app/fragment/fragment-link/fragment-link';
import {isClauseGroupOfType} from 'app/fragment/fragment-utils';
import {ClauseFragment, Fragment, SectionFragment} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {ClauseGroupType} from 'app/fragment/types/clause-group-type';
import {StandardFormatType} from 'app/fragment/types/standard-format-type';
import {ClauseLinkService} from 'app/services/clause-link.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

export interface LinkSelectorCheckboxState {
  clauseGroupId: UUID;
  checked: boolean;
}

@Component({
  selector: 'cars-clause-link-selector',
  templateUrl: './clause-link-selector.component.html',
  styleUrls: ['./clause-link-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseLinkSelectorComponent implements OnInit, OnDestroy {
  private static readonly VERIFICATION_STANDARD_FORMAT_TYPES: ReadonlyArray<StandardFormatType> = [
    StandardFormatType.VERIFICATION_WITHIN_SHW,
    StandardFormatType.VERIFICATION_WITHIN_SHW_NO_REPLACEMENT,
    StandardFormatType.VERIFICATION_WITHIN_WSR,
    StandardFormatType.VERIFICATION_WITHIN_WSR_NO_REPLACEMENT,
    StandardFormatType.VERIFICATION_REQUIRES_ACCREDITED_TESTING_LABORATORY,
  ];

  private static readonly DOCUMENTATION_STANDARD_FORMAT_TYPES: ReadonlyArray<StandardFormatType> = [
    StandardFormatType.DOCUMENTATION,
    StandardFormatType.DOCUMENTATION_GENERAL_REQUIREMENTS,
    StandardFormatType.DOCUMENTATION_SPECIFIC_TIMESCALES,
    StandardFormatType.DOCUMENTATION_TECHNICAL_APPROVAL,
    StandardFormatType.DOCUMENTATION_CONTINUOUS_RECORDS,
    StandardFormatType.DOCUMENTATION_CONTINUOUS_RECORDS_V2,
    StandardFormatType.DOCUMENTATION_WSR,
    StandardFormatType.DOCUMENTATION_V2,
    StandardFormatType.DOCUMENTATION_WSR_V2,
  ];

  @Input() public clause: ClauseFragment;
  @Input() public clauseLinkType: ClauseLinkType;

  @Output() public endSelectingLinks: EventEmitter<void> = new EventEmitter();

  public loading: boolean = false;
  public selectingLinks: boolean = false;

  public linkRequired: ClauseLinkRequired;

  private _clauseLinks: FragmentLink[];

  private _clauseGroupIdToStandardFormatTypes: Record<string, StandardFormatType>;

  public clauseGroupOptions: LinkSelectorCheckboxState[];

  private _subscriptions: Subscription[] = [];

  constructor(private _clauseLinkService: ClauseLinkService, private _cdr: ChangeDetectorRef) {}

  /**
   * Initialised the arrays used in the html template and gets the list of clause links for the clause. Note that the
   * user has the clause locked so this list only has to be fetched once as it should not change while this component
   * is open. Note this does not subscribe onChanges as clause changes will close this component, also note that links
   * can only be edited on the live document so we do not need to get the current viewMode versionTag.
   */
  public ngOnInit(): void {
    this.loading = true;

    this.clauseGroupOptions = [];
    this._clauseGroupIdToStandardFormatTypes = {};

    this._subscriptions.push(
      this._clauseLinkService
        .getAllFromClause(this.clause, null)
        .pipe(take(1))
        .subscribe((clauseLinks: FragmentLink[]) => {
          this._clauseLinks = clauseLinks.filter((link: FragmentLink) => link.clauseLinkType === this.clauseLinkType);

          this._getValidTargetClauseGroups();

          this.loading = false;
          this._cdr.markForCheck();
        })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Gets the list of valid clause groups for the given clause link type, and constructs the checkbox options
   * initialised to the current selection.
   */
  private _getValidTargetClauseGroups(): void {
    const section: SectionFragment = this.clause.getSection();
    const allSectionChildren: Fragment[] = section.children;

    allSectionChildren
      .filter(
        (frag: Fragment) =>
          isClauseGroupOfType(frag, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT) &&
          this._isValidClauseGroupType(frag as ClauseGroupFragment)
      )
      .forEach((frag) => {
        const clauseGroup: ClauseGroupFragment = frag as ClauseGroupFragment;

        this._clauseGroupIdToStandardFormatTypes[clauseGroup.id.value] = clauseGroup.standardFormatType;
        this.clauseGroupOptions.push({
          clauseGroupId: clauseGroup.id,
          checked: this._clauseLinks.some((link) => link.targetFragmentId.equals(clauseGroup.id)),
        });
      });
  }

  public getClauseGroupType(clauseGroupId: UUID): StandardFormatType {
    return this._clauseGroupIdToStandardFormatTypes[clauseGroupId.value];
  }

  private _isValidClauseGroupType(clauseGroup: ClauseGroupFragment): boolean {
    switch (this.clauseLinkType) {
      case ClauseLinkType.VERIFICATION:
        return ClauseLinkSelectorComponent.VERIFICATION_STANDARD_FORMAT_TYPES.includes(clauseGroup.standardFormatType);
      case ClauseLinkType.DOCUMENTATION:
        return ClauseLinkSelectorComponent.DOCUMENTATION_STANDARD_FORMAT_TYPES.includes(clauseGroup.standardFormatType);
      default:
        throw new Error('Unexpected clause link type: ' + this.clauseLinkType);
    }
  }

  /**
   * Sends updates to select the new links and delete the removed ones.
   */
  public saveSelection(): void {
    this.loading = true;

    const selectedTargetIds: UUID[] = this.clauseGroupOptions
      .filter((state) => state.checked)
      .map((state) => state.clauseGroupId);

    const deletedLinks: FragmentLink[] = this._clauseLinks.filter(
      (link: FragmentLink) => !selectedTargetIds.some((id: UUID) => id.equals(link.targetFragmentId))
    );
    const newIds: UUID[] = selectedTargetIds.filter(
      (id: UUID) => !this._clauseLinks.some((link: FragmentLink) => link.targetFragmentId.equals(id))
    );

    Promise.all([
      this._clauseLinkService.deleteAll(deletedLinks).then(() => null),
      this._clauseLinkService.createAll(this.clause.id, newIds, this.clauseLinkType),
    ]).then(() => {
      this.endSelectingLinks.emit();
      this.loading = false;
    });
  }

  /**
   * Closes the selection view without making any changes.
   */
  public cancelChanges(): void {
    this.endSelectingLinks.emit();
  }
}
