import {ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {ImageService} from 'app/services/image.service';
import {RichTextType} from 'app/services/rich-text.service';
import {FragmentService} from '../../services/fragment.service';
import {ActionRequest} from '../action-request';
import {Caret} from '../caret';
import {FragmentComponent} from '../core/fragment.component';
import {Key} from '../key';
import {
  EDITABLE_TEXT_FRAGMENT_TYPES,
  EquationFragment,
  FigureFragment,
  Fragment,
  FragmentType,
  ListFragment,
  TextFragment,
} from '../types';
import {TableCellFragment} from './table-fragment';

@Directive({
  selector: '[carsTableCellFragment]',
})
export class TableCellFragmentDirective extends FragmentComponent {
  // A UTF-16 carriage-return character used to indent caret on enter at the end of a cell.
  private static readonly _CARRIAGE_RETURN: string = '\u000D';

  @Output() cellBlur: EventEmitter<void> = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('carsTableCellFragment') public set content(value: TableCellFragment) {
    super.content = value;
  }

  public get content(): TableCellFragment {
    return super.content as TableCellFragment;
  }

  constructor(
    private _fragmentService: FragmentService,
    private _imageService: ImageService,
    protected _cd: ChangeDetectorRef,
    _elementRef: ElementRef
  ) {
    super(_cd, _elementRef);
  }

  /**
   * Respond to a keydown event on the cars-ce directive.
   *
   * @inheritdoc
   */
  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    if (
      key.equalsUnmodified(Key.ESCAPE) ||
      (key.equalsUnmodified(Key.TAB) && !target.content.findAncestorWithType(FragmentType.LIST))
    ) {
      const action: ActionRequest = super.onKeydown(key, target, caret) as ActionRequest;
      return action;
    } else if (key.equalsUnmodified(Key.ENTER)) {
      return this._handleEnter(key, target, caret);
    } else if (key.equalsUnmodified(Key.BACKSPACE)) {
      return this._handleBackspace(caret);
    } else if (key.equalsUnmodified(Key.DELETE)) {
      return this._handleDelete(caret);
    } else {
      return new ActionRequest();
    }
  }

  /**
   * Override FragmentComponent::onRichText to allow insertion of list items.
   *
   * @inheritdoc
   */
  public onRichText(type: RichTextType, start: Caret, end: Caret, ...args: any[]): ActionRequest {
    switch (type) {
      case RichTextType.LIST: {
        const action: ActionRequest = new ActionRequest();
        const ordered: boolean = args[0] === true;
        // By default, there's always a text fragment on the cell
        const defaultTextFragment: TextFragment = end.fragment;

        const newList: ListFragment = ListFragment.withSize(1, ordered);

        if (defaultTextFragment.length() === 0 || end.isAtStart()) {
          newList.insertBefore(end.fragment);
          // if text fragment is not empty updates text fragment,
          // otherwise deletes empty text fragment.
          if (end.isAtStart() && defaultTextFragment.length() !== 0) {
            this._fragmentService.update(end.fragment);
          } else {
            this._fragmentService.delete(end.fragment);
          }
        } else {
          newList.insertAfter(end.fragment);
          this._fragmentService.update(end.fragment);
        }

        this._fragmentService.create([newList]);

        action.fragment = newList.children[0].children[0];
        action.offset = 0;

        return action;
      }

      case RichTextType.FIGURE: {
        const figure: FigureFragment = args[0];
        const oldFigureIndex: number = args[1];
        const file: File = args[2];

        const created: Fragment[] = [];

        if (Number.isInteger(oldFigureIndex)) {
          // 0 is falsy but is a real index
          figure.insertAfter(this.content.children[oldFigureIndex]);
          created.push(figure);
        } else {
          const split: Fragment = end.fragment.split(end.offset);
          this._fragmentService.update(end.fragment);

          figure.insertAfter(end.fragment);
          split.insertAfter(figure);

          created.push(split, figure);
        }

        this._fragmentService.create(created).then(() => this._imageService.uploadImage(file, figure.id));

        // As ActionRequest adds offset onto current caret offset in contentedtiable.ts, we need to subtract
        // it in order to get back to zero.
        const action: ActionRequest = new ActionRequest(figure.caption, figure.caption.length() - end.offset);
        return action;
      }

      case RichTextType.TEXT: {
        // Adds am empty text fragment after an image in a table
        const emptyText: TextFragment = args[0];
        const index: number = args[1];
        emptyText.insertAfter(this.content.children[index]);
        this._fragmentService.create(emptyText);

        const action: ActionRequest = new ActionRequest(emptyText, 0);
        return action;
      }

      case RichTextType.EQUATION: {
        const inline: boolean = args[0] === true;
        const equation: EquationFragment = EquationFragment.empty(inline);
        const action: ActionRequest = new ActionRequest();
        action.fragment = equation.source;

        if (inline) {
          this._handleCreatingInlineEquations(end, equation);
        } else {
          this._handleCreatingDisplayEquations(end, equation);
        }

        return action;
      }

      default: {
        return null;
      }
    }
  }

  /**
   * Handles enter within a table cell.
   * Inserts a new line if the target fragment is of type: (TEXT, SUBSCRIPT, SUPERSCRIPT, MEMO)
   * And it's immediate parent is a table cell. Otherwise event is not allowed to bubble
   *
   * @param key    {Key}               The unmodified key that was pressed
   * @param target {FragmentComponent} The highlighted fragment component
   * @param caret  {Caret}             The current caret state
   * @returns      {ActionRequest}     The desired action to take
   */
  private _handleEnter(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    const action: ActionRequest = new ActionRequest();

    if (target.content.is(...EDITABLE_TEXT_FRAGMENT_TYPES) && target.content.parent.is(FragmentType.TABLE_CELL)) {
      action.fragment = target.content;
      action.add =
        caret.offset === caret.fragment.length()
          ? Fragment._LINE_BREAK.concat(TableCellFragmentDirective._CARRIAGE_RETURN)
          : Fragment._LINE_BREAK;
    } else if (target.content.parent.is(FragmentType.LIST_ITEM)) {
      // Walk up the tree until we are no longer in the list
      let parent: FragmentComponent = target.parent;
      let listFragment: Fragment = null;
      while (parent && parent.content.is(FragmentType.LIST, FragmentType.LIST_ITEM)) {
        listFragment = parent.content;
        parent = parent.parent;
      }

      if (parent) {
        // Insert the text area below the current place of the list box
        const textFragment: TextFragment = TextFragment.empty();
        textFragment.insertAfter(listFragment);
        this._fragmentService.create(textFragment);

        // Scan through the list, if the last item is blank then remove it
        if (listFragment && listFragment.is(FragmentType.LIST) && listFragment.children.length) {
          const lastChild: Fragment = listFragment.children[listFragment.children.length - 1];
          if (lastChild.length() === 0) {
            // Always list item, no need to validate
            this._fragmentService.delete(lastChild);
          }
        }

        // If the list is empty then delete it
        if (listFragment && listFragment.length() === 0) {
          // Always list, no need to validate
          this._fragmentService.delete(listFragment);
        }

        // Move cursor to new text fragment
        action.fragment = textFragment;
      }
    }

    return action;
  }

  /**
   * Handles backspace within a table cell.
   *
   * @param caret  {Caret}             The current caret state
   * @returns      {ActionRequest}     The desired action to take
   */
  private _handleBackspace(caret: Caret): ActionRequest {
    const action: ActionRequest = new ActionRequest();
    action.fragment = caret.fragment.previousSibling();
    if (caret && caret.fragment.is(...EDITABLE_TEXT_FRAGMENT_TYPES) && action.fragment.is(FragmentType.LIST)) {
      let fragmentToAdd: Fragment = caret.fragment;
      const fragmentsToMove: Fragment[] = [];
      while (fragmentToAdd) {
        if (fragmentToAdd.value.indexOf('\n') < 0 && !fragmentToAdd.is(FragmentType.FIGURE, FragmentType.LIST)) {
          fragmentsToMove.push(fragmentToAdd);
          fragmentToAdd = fragmentToAdd.nextSibling();
        } else if (fragmentToAdd.value.indexOf('\n') >= 0) {
          const next: Fragment = fragmentToAdd.split(fragmentToAdd.value.indexOf('\n'));
          next.insertAfter(fragmentToAdd);
          if (next.value.length > 0 && next.value[0] === '\n') {
            next.value = next.value.substring(1);
          }
          this._fragmentService.create(next);
          fragmentsToMove.push(fragmentToAdd);
          fragmentToAdd = null;
        } else {
          fragmentToAdd = null;
        }
      }

      if (action.fragment.children.length > 0) {
        const parent: Fragment = fragmentsToMove[0].parent;
        const childIndex: number = parent.childIndexOf(fragmentsToMove[0]);
        if (childIndex >= 0) {
          fragmentsToMove.forEach((frag: Fragment) => {
            parent.children.splice(parent.childIndexOf(frag), 1);
          });
        }

        const lastListItem: Fragment = action.fragment.children[action.fragment.children.length - 1];

        action.fragment = lastListItem;
        action.offset = lastListItem.length();

        lastListItem.children.push(...fragmentsToMove);
        if (
          lastListItem.hasChildren() &&
          lastListItem.children[lastListItem.children.length - 1].is(
            FragmentType.EQUATION,
            FragmentType.INLINE_REFERENCE
          )
        ) {
          lastListItem.children.push(new TextFragment(null, ''));
        }
        this._fragmentService.update(fragmentsToMove);
        this._fragmentService.mergeChildren(lastListItem);
      }
    } else {
      while (!action.fragment.is(FragmentType.TEXT, FragmentType.LIST, FragmentType.LIST_ITEM)) {
        action.fragment = action.fragment.previousSibling();
      }
      action.offset = action.fragment.length();
      if (
        caret.fragment.previousSibling() &&
        (caret.fragment.previousSibling().is(FragmentType.INLINE_REFERENCE) ||
          caret.fragment.previousSibling().is(FragmentType.EQUATION))
      ) {
        // Always inline reference, no need to validate
        this._fragmentService.delete(caret.fragment.previousSibling());
      } else if (caret.fragment.length() === 0) {
        // Caret fragment will be text type, no need to validate
        this._fragmentService.delete(caret.fragment);
      }
    }
    return action;
  }

  /**
   * Handles delete within a table cell.
   *
   * @param caret  {Caret}             The current caret state
   * @returns      {ActionRequest}     The desired action to take
   */
  private _handleDelete(caret: Caret): ActionRequest {
    let action: ActionRequest = new ActionRequest();
    const nextSibling: Fragment = caret ? caret.fragment.nextSibling() : null;
    if (caret && caret.fragment && !(nextSibling && nextSibling.is(FragmentType.EQUATION))) {
      if (nextSibling && nextSibling.is(FragmentType.INLINE_REFERENCE)) {
        // Always inline reference, no need to validate
        this._fragmentService.delete(nextSibling);
      } else if (caret.fragment.parent && caret.fragment.parent.is(FragmentType.LIST_ITEM)) {
        // Need to handle sublists and also text fragments being next
        const listitem: Fragment = caret.fragment.parent;
        const list: Fragment = listitem.parent;
        const next: Fragment = list.nextSibling();
        if (next && next.is(...EDITABLE_TEXT_FRAGMENT_TYPES)) {
          action = this._handleBackspace(new Caret(next, 0));
          action.offset = 0;
        }
      }
    }
    return action;
  }

  private _handleCreatingInlineEquations(end: Caret, equation: EquationFragment) {
    const split: Fragment = end.fragment.split(end.offset);
    equation.insertAfter(end.fragment);
    split.insertAfter(equation);

    this._fragmentService.update(end.fragment);
    this._fragmentService.create([equation, split]);
  }

  private _handleCreatingDisplayEquations(end: Caret, equation: EquationFragment) {
    const equationAncestor: EquationFragment = end.fragment.findAncestorWithType(
      FragmentType.EQUATION
    ) as EquationFragment;

    if (!!equationAncestor) {
      equationAncestor.component.blur();
    }
    equation.insertAfter(end.fragment.findAncestorWithType(FragmentType.TABLE));
    this._fragmentService.create(equation);
  }

  public blur(): void {
    this.cellBlur.emit();
  }
}
