import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationProvider} from 'app/services/user/authentication-provider';
import {User} from 'app/user/user';
import {ColourUtils} from 'app/utils/colour-utils';
import {HttpStatus} from 'app/utils/http-status';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseService} from './../base.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  private static readonly USERS_ENDPOINT: string = `${environment.apiHost}/users`;

  private static readonly SYSTEM_USER: User = new User(
    UUID.orNull(environment.systemUserId),
    'System User',
    'System User',
    null,
    null,
    'SU',
    false
  );

  public static getUserColour(user: User, alpha: number): string {
    return ColourUtils.hslFromString(user.id.value, alpha);
  }

  constructor(protected snackbar: MatSnackBar, private authProvider: AuthenticationProvider, private http: HttpClient) {
    super(snackbar);
  }

  public getUser(): User {
    return this.authProvider.getCurrentUser();
  }

  public createAccountUrl(): string {
    return this.authProvider.createAccountUrl();
  }

  public logoutUrl(): string {
    return this.authProvider.createLogoutUrl();
  }

  public isAdmin(): boolean {
    return this.authProvider.isAdmin();
  }

  public userManagement(): string {
    return this.authProvider.userManagement();
  }

  /**
   * Gets the user from the given id, note first checks for cases of the System User and returns that if id matches.
   */
  public getUserFromId(id: UUID): Observable<User> {
    return UserService.SYSTEM_USER?.id?.equals(id)
      ? new BehaviorSubject<User>(UserService.SYSTEM_USER).asObservable()
      : this.authProvider.getUser(id);
  }

  /**
   * Get the number of active sessions for the given user ID.
   *
   * @param userId {UUID}               User ID to query
   * @returns      {Observable<number>} Observable emitting the number of active sessions
   */
  public getActiveSessions(userId: UUID): Observable<number> {
    if (this.authProvider.offline()) {
      return of(0);
    }

    return this.http
      .get(`${UserService.USERS_ENDPOINT}/sessions/?userId=${userId.value}`, {
        responseType: 'text',
      })
      .pipe(map((asString: string) => parseInt(asString, 10)));
  }

  /**
   * Creates a new user from the given JSON and returns the deserialised User object.
   *
   * @param json    {any}           JSON object to create user from
   * @param message {string?}       Optional error message to display
   * @returns       {Promise<User>} Promise resolving to the newly created user
   */
  public createUser(json: any, message?: string): Promise<User> {
    message = message || `Sorry, could not create user with email: ${json.email}`;
    return this.http
      .post(`${UserService.USERS_ENDPOINT}/create`, json)
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        switch (error.status) {
          case HttpStatus.CONFLICT:
          case HttpStatus.FORBIDDEN: {
            message = error.error.message;
          }
        }
        this._handleError(error, message, 'user-creation-error');
        throw error;
      })
      .then((response: any) => User.deserialise(response));
  }
}
