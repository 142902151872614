import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {VersionRequest, VersionTag} from 'app/interfaces';
import {BaseService} from 'app/services/base.service';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {HttpStatus} from 'app/utils/http-status';
import {CurrentView} from 'app/view/current-view';
import {environment} from 'environments/environment';
import {Observable, of, ReplaySubject, Subject, Subscription, throwError} from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {DocumentService} from '../../services/document.service';
import {FragmentFetchParams, FragmentService} from '../../services/fragment.service';
import {UUID} from '../../utils/uuid';
import {ViewService} from '../../view/view.service';
import {CacheManager} from '../cache-manager';
import {FragmentMapper} from '../core/fragment-mapper';
import {FragmentCache} from '../diff/fragment-cache';
import {DocumentFragment, Fragment} from '../types';
import {VersionTagType} from './version-tag-type';

@Injectable({
  providedIn: 'root',
})
export class VersioningService<T extends Fragment = Fragment> extends BaseService implements OnDestroy {
  public static readonly DEFAULT_FETCH_PARAMS: FragmentFetchParams = {
    validFrom: 0,
  };

  public static readonly LAST_24_HOURS: FragmentFetchParams = {
    validFrom: new Date().setDate(new Date().getDate() - 1),
  };

  public static readonly LAST_7_DAYS: FragmentFetchParams = {
    validFrom: new Date().setDate(new Date().getDate() - 7),
  };

  public static readonly LAST_MONTH: FragmentFetchParams = {
    validFrom: new Date().setDate(new Date().getDate() - 31),
  };

  private static readonly VERSION_ENDPOINT: string = `${environment.apiHost}/versions`;

  private static readonly VERSION_TAG_ENDPOINT: string = `${VersioningService.VERSION_ENDPOINT}/version-tags`;

  private static readonly VERSION_TAG_TOPIC: string = '/topic/versionTags';

  private static readonly FRAGMENT_VERSION_UPDATE_TOPIC = '/topic/versions/fragments';

  private _websocketSubscriptions: Subscription[] = [];

  private _versionTagReplaySubjects: Map<string, ReplaySubject<VersionTag>> = new Map();

  private _documentVersionTagListReplaySubjects: Map<string, ReplaySubject<VersionTag[]>> = new Map();

  private _fragmentVersionUpdateSubject: Subject<Fragment> = new Subject();

  public static serialiseVersionTag(versionTag: VersionTag): any {
    const body: any = Object.assign({}, versionTag);
    // Handle UUIDs
    Object.keys(body).forEach((key: string) => {
      if (body[key] && typeof body[key].serialise === 'function') {
        body[key] = body[key].serialise();
      }
    });
    return body;
  }

  public static deserialiseVersionTag(json: any): VersionTag {
    return {
      versionId: UUID.orThrow(json.versionId),
      fragmentId: UUID.orThrow(json.fragmentId),
      name: json.name,
      createdBy: UUID.orNull(json.createdBy),
      createdAt: json.createdAt,
      availableToReview: json.availableToReview,
      availableForCommenting: json.availableForCommenting,
      userDiscussionsOnly: json.userDiscussionsOnly,
      versionTagType: VersionTagType[json.versionTagType as string],
      versionNumber: json.versionNumber,
      dateOfPublication: json.dateOfPublication,
    };
  }

  constructor(
    private _fragmentService: FragmentService<T>,
    private _documentService: DocumentService,
    private _viewService: ViewService,
    private _websocketService: WebSocketService,
    private _cacheManager: CacheManager,
    private _http: HttpClient,
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);

    this._subscriptions.push(
      this._websocketService.onConnection((connected: boolean) => {
        this._websocketSubscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());

        if (connected) {
          this._websocketSubscriptions.push(
            this._websocketService.subscribe(VersioningService.VERSION_TAG_TOPIC, (json: any) => {
              const versionTag: VersionTag = VersioningService.deserialiseVersionTag(json);
              this._handleWebsocketEvent(versionTag);
            }),
            this._websocketService.subscribe(VersioningService.FRAGMENT_VERSION_UPDATE_TOPIC, (json: any) => {
              const fragments: Fragment[] = json.map((j) => FragmentMapper.deserialise(j));

              fragments.forEach((frag) => {
                const caches: FragmentCache[] = this._cacheManager.getHistoricalCachesForFragmentVersion(frag);

                if (caches.length > 0) {
                  caches.forEach((cache: FragmentCache) => cache.insert(frag));

                  this._fragmentVersionUpdateSubject.next(frag);
                }
              });
            })
          );
        }
      }),

      // Version tags are only websocketed for documents being viewed, this ensures they are up to date on load
      this._documentService.onSelection((doc: DocumentFragment) => {
        if (!!doc) {
          this.getVersionTagsForFragmentId(doc.id, false, null, true);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._websocketSubscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Updates the stored version tag array on versionTag websocket events and reemits this for any subscribers. Adds the
   * versionTag to the start of the list if creating or replaces the existing value for updates. If the versionTag
   * document has no entry in the map then does nothing.
   */
  private _handleWebsocketEvent(versionTag: VersionTag): void {
    let versionTagSubject: ReplaySubject<VersionTag> = this._versionTagReplaySubjects.get(versionTag.versionId.value);
    if (!versionTagSubject) {
      versionTagSubject = new ReplaySubject(1);
      this._versionTagReplaySubjects.set(versionTag.versionId.value, versionTagSubject);
    }
    versionTagSubject.next(versionTag);

    const documentVersionTagListSubject: ReplaySubject<VersionTag[]> = this._documentVersionTagListReplaySubjects.get(
      versionTag.fragmentId.value
    );
    if (documentVersionTagListSubject) {
      documentVersionTagListSubject.pipe(take(1)).subscribe((tags: VersionTag[]) => {
        const existingTagIndex: number = tags.findIndex((tag) => tag.versionId.equals(versionTag.versionId));

        if (existingTagIndex !== -1) {
          tags.splice(existingTagIndex, 1, versionTag);
        } else {
          tags.unshift(versionTag);
        }

        documentVersionTagListSubject.next(tags);
      });
    }
  }

  /**
   * Returns an observable of the versionTag object of the current user location that is updated with currentView
   * changes and websockets.
   */
  public getCurrentViewVersionTagObsStream(): Observable<VersionTag> {
    return this._viewService
      .getCurrentViewObsStream()
      .pipe(
        switchMap((currentView: CurrentView) =>
          !!currentView.versionTag
            ? this.getVersionTagFromVersionId(currentView.versionTag.versionId, null, false)
            : of(null)
        )
      );
  }

  /**
   * Fetch a series of versions from the FragmentService and process the result.
   * The optional FragmentFetchParams defaults to maximum depth, validFrom=0 and
   * unbounded validTo.
   *
   * @param id      {UUID}                   The ID of the root fragment to fetch
   * @param params  {FragmentFetchParams?}   An optional fetch params object
   * @param message {string?}                An optional error message to display
   */
  public fetch(id: UUID, params?: FragmentFetchParams): Promise<T[]> {
    params = params || VersioningService.DEFAULT_FETCH_PARAMS;

    // Don't show a snackbar to the user
    const errorHandler = (err: any): Promise<never> => {
      Logger.error('history-error', 'Failed to get clause history for clause ' + id, err);
      return Promise.reject(err);
    };

    return this._fragmentService.fetch(id, params, null, errorHandler).then((versions: T[]) => this._reverse(versions));
  }

  /**
   * Returns an observable of fragment version updates, filtered to fragments whos validTo match the given timestamp.
   */
  public getFragmentVersionUpdateObsStream(validTo: number): Observable<Fragment> {
    return this._fragmentVersionUpdateSubject.asObservable().pipe(filter((f) => f.validTo === validTo));
  }

  /**
   * Reverse the list of versions so they are from newest to oldest.  This does
   * not modify the passed versions array, unlike Array::reverse().
   *
   * @param versions {T[]}   The versions to reverse
   * @returns        {T[]}   The reversed versions
   */
  private _reverse(versions: T[]): T[] {
    return versions.map((v: T, i: number) => versions[versions.length - 1 - i]);
  }

  /**
   * Retrieves tagged versions of a fragment. Returns an observable of the current version tags that updates with
   * websocket events. Stores the result for other subscribers to minimise requests.
   *
   * @param fragmentId            {UUID}    The ID of the fragment
   * @param onlyAvailableToReview {boolean} Whether to filter versions to only those available to reviewers
   * @param message               {string}  An optional error message to display
   * @param forceRefresh          {boolean} Whether to force making a fresh http request
   */
  public getVersionTagsForFragmentId(
    fragmentId: UUID,
    onlyAvailableToReview: boolean = false,
    message?: string,
    forceRefresh: boolean = false
  ): Observable<VersionTag[]> {
    message = message || `Failed to get tagged versions for ${fragmentId.value}`;

    let subject: ReplaySubject<VersionTag[]> = this._documentVersionTagListReplaySubjects.get(fragmentId.value);

    if (forceRefresh || !subject) {
      if (!subject) {
        subject = new ReplaySubject(1);
        this._documentVersionTagListReplaySubjects.set(fragmentId.value, subject);
      }

      this._http
        .get(`${VersioningService.VERSION_TAG_ENDPOINT}/by-fragment-id/${fragmentId.value}`)
        .pipe(
          map((json: any[]) => json.map((versionTag: any) => VersioningService.deserialiseVersionTag(versionTag))),
          catchError((error) => {
            this._handleError(error, message, 'version-tag-error');
            return throwError(null);
          })
        )
        .subscribe((versionTags: VersionTag[]) => subject.next(versionTags));
    }

    return subject
      .asObservable()
      .pipe(map((versionTags) => versionTags.filter((tag) => !onlyAvailableToReview || tag.availableToReview)));
  }

  public updateCollaborativeProperties(
    versionId: UUID,
    availableForCommenting: boolean,
    availableToReview: boolean,
    userDiscussionsOnly: boolean
  ): Promise<void> {
    const params: {[param: string]: string} = {
      availableForCommenting: availableForCommenting.toString(),
      availableToReview: availableToReview.toString(),
      userDiscussionsOnly: userDiscussionsOnly.toString(),
    };

    return this._http
      .patch(`${VersioningService.VERSION_TAG_ENDPOINT}/update/${versionId.value}/collaborative-properties`, null, {
        params,
      })
      .pipe(
        map(() => {}),
        catchError((err) => {
          this._handleError(err, `Failed to update properties for version ${versionId.value}`, 'version-tag-error');
          return throwError(err);
        })
      )
      .toPromise();
  }

  public transitionVersionTag(versionId: UUID, newVersionTagType: VersionTagType): Promise<void> {
    const params: {[param: string]: string} = {
      newVersionTagType,
    };

    return this._http
      .patch(`${VersioningService.VERSION_TAG_ENDPOINT}/update/${versionId.value}/transition`, null, {params})
      .pipe(
        map(() => {}),
        catchError((err) => {
          this._handleError(err, `Failed to transition tagged version ${versionId.value}`, 'version-tag-error');
          return throwError(err);
        })
      )
      .toPromise();
  }

  public updateDateOfPublication(versionId: UUID, dateOfPublication: number): Promise<void> {
    const params: {[param: string]: string} = {
      dateOfPublication: dateOfPublication.toString(),
    };

    return this._http
      .patch(`${VersioningService.VERSION_TAG_ENDPOINT}/update/${versionId.value}/date-of-publication`, null, {params})
      .pipe(
        map(() => {}),
        catchError((err) => {
          this._handleError(
            err,
            `Failed to update date of publication for version ${versionId.value}`,
            'version-tag-error'
          );
          return throwError(err);
        })
      )
      .toPromise();
  }

  /**
   * Given a version tag ID, get the version tag object for that ID. Returns an observable of the object that updates
   * with websocket events. Defaults to making a new http request on calls unless forceRefresh is set to false - avoids
   * issues where version tags are only websocketed for documents being viewed.
   *
   * @param versionId {UUID}   The version ID
   * @param message   {string} An optional error message to display
   */
  public getVersionTagFromVersionId(
    versionId: UUID,
    message?: string,
    forceRefresh: boolean = true
  ): Observable<VersionTag> {
    message = message || `Failed to get fragment ID for version ${versionId.value}`;

    let subject: ReplaySubject<VersionTag> = this._versionTagReplaySubjects.get(versionId.value);

    if (forceRefresh || !subject) {
      if (!subject) {
        subject = new ReplaySubject(1);
        this._versionTagReplaySubjects.set(versionId.value, subject);
      }

      this._http
        .get(`${VersioningService.VERSION_TAG_ENDPOINT}/by-version-id/${versionId.value}`)
        .pipe(
          map((json) => VersioningService.deserialiseVersionTag(json)),
          catchError((error) => {
            this._handleError(error, message, 'version-tag-error');
            return throwError(null);
          })
        )
        .subscribe((versionTag: VersionTag) => subject.next(versionTag));
    }

    return subject.asObservable();
  }

  public updateTableWidths(tableId: UUID, widths: number[]): Promise<number> {
    const version = this._viewService.getCurrentView().versionTag;
    const time = version ? version.createdAt.toString() : null;
    let params: HttpParams = new HttpParams().set('tableId', tableId.value);
    if (time !== null) {
      params = params.set('version', time);
    }

    const post: Observable<number> = this._http
      .post(`${VersioningService.VERSION_ENDPOINT}/patch-table-widths`, widths, {params, observe: 'response'})
      .pipe(map((response) => response.status));

    if (FragmentService.saving.getValue()?.saving === true) {
      return FragmentService.saving
        .pipe(
          filter((saving) => saving?.saving === false),
          take(1),
          mergeMap((saving) => {
            return post;
          })
        )
        .toPromise();
    } else {
      return post.toPromise();
    }
  }

  public isVersionCreationInProgress(documentId: UUID): Promise<boolean> {
    return this._http
      .get<boolean>(`${VersioningService.VERSION_ENDPOINT}/versioning-in-progress/${documentId.value}`, {
        headers: this._httpHeaders,
      })
      .pipe(
        catchError((error) => {
          this._handleError(
            error,
            `Failed to get check if versioning is in progress for ${documentId.value}`,
            'version-tag-error'
          );
          return throwError(null);
        })
      )
      .toPromise();
  }

  /**
   * Posts the version request to the backend to trigger the creation of a document version. This returns the notification id
   * of the 'Create Version' notification that is sent by the backend once the version creation has been triggered.
   */
  public createDocumentVersion(versionRequest: VersionRequest): Promise<UUID> {
    return this._http
      .post(`${VersioningService.VERSION_TAG_ENDPOINT}/create-new`, versionRequest, {
        responseType: 'json',
      })
      .pipe(
        map((response: string) => UUID.orNull(response)),
        catchError((err: HttpErrorResponse) => {
          this._handleError(
            err,
            err.status === HttpStatus.CONFLICT
              ? 'Version creation already in progress, please try again later'
              : 'Failed to create version'
          );

          return throwError(null);
        })
      )
      .toPromise();
  }

  /**
   * Posts the revert to version request to the backend to trigger the revertion of a document. This returns the notification id
   * of the 'Revert Version' notification that is sent by the backend once the version revertion has been triggered.
   */
  public revertDocumentToVersion(revertToVersionRequest: VersionRequest, versionId: UUID): Promise<void> {
    return this._http
      .post(`${VersioningService.VERSION_ENDPOINT}/revert`, revertToVersionRequest, {
        responseType: 'json',
        params: {versionId: versionId.value},
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this._handleError(
            err,
            err.status === HttpStatus.CONFLICT
              ? 'Version creation / revert already in progress, please try again later'
              : `Failed to revert version`
          );
          return throwError(null);
        })
      )
      .toPromise()
      .then(() => {});
  }
}
