<div class="background-container" [class.background-view]="isBackgroundView">
  <div class="top" *ngIf="!isBackgroundView">
    <div>
      Background commentary is mandatory for all clauses as part of first publication. Upon publication, the background
      commentary is cleared, but still accessible through Versions. New background commentary should be provided for any
      clause updated as part of future publications.
    </div>
  </div>

  <div class="middle" [class.background-view]="isBackgroundView">
    <ng-container>
      <ng-container *ngIf="!isBackgroundView">
        <textarea
          aria-label="Background & commentary"
          #source
          *ngIf="clause"
          placeholder="Please enter some background commentary."
          id="source"
          class="background-text"
          [class.hide]="rendered"
          [class.locked]="!canEditBackgroundCommentary()"
          [(ngModel)]="clause.background"
          (ngModelChange)="onChange($event)"
          (blur)="save(); renderCommentary()"
          tabindex="0"
        >
        </textarea>
      </ng-container>
      <ng-container *ngIf="isBackgroundView">
        <textarea
          aria-label="Background & commentary"
          #source
          *ngIf="clause"
          placeholder="Please enter some background commentary."
          id="source"
          class="background-text"
          [class.hide]="rendered"
          [class.locked]="!canEditBackgroundCommentary()"
          [(ngModel)]="clause.background"
          (ngModelChange)="onChange($event)"
          (blur)="save(); renderCommentary(); unlockClause()"
          rows="5"
          tabindex="0"
        >
        </textarea>
      </ng-container>

      <cars-alt-accessibility
        [altProperties]="{initialKey: 'r', key: 'q', condition: true}"
        (onSelect)="focusTextEditable(null)"
      ></cars-alt-accessibility>

      <div
        #render
        *ngIf="clause"
        class="background-text rendered"
        [class.firefox]="firefox"
        [class.empty]="!clause.background"
        [class.hide]="!rendered"
        [class.locked]="!canEditBackgroundCommentary()"
        (mousedown)="focusTextEditable($event)"
        (keyup.enter)="focusTextEditable($event)"
        tabindex="0"
      ></div>
      <textarea *ngIf="!clause" class="background-text" disabled>
        Please select a clause to edit/view its background commentary
      </textarea>
    </ng-container>

    <div class="bottom" *ngIf="!isBackgroundView">
      <mat-chip-list [selectable]="false" *ngIf="canEditBackgroundCommentary()">
        <mat-chip color="primary" [selected]="!saving" [class.saving]="saved || saving">{{ status }}</mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
