import {Injectable} from '@angular/core';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConditionASTNode} from '../types/condition-ast-node';
import {EqualityRelation, PermissionInput} from '../types/permissions';
import {PermissionsHandler} from './permissions-handler';

@Injectable()
export class LocalConfigPermissionsHandler implements PermissionsHandler {
  private readonly localConfigProperties: string[] = ['offline'];

  public getInputType(): PermissionInput {
    return PermissionInput.LOCAL_CONFIG;
  }

  public handle(condition: ConditionASTNode): Observable<boolean> {
    this.validateProperty(condition);
    switch (condition.relation) {
      case EqualityRelation.EQUALS:
        return LocalConfigUtils.localConfigHasProperty(condition.value);
      case EqualityRelation.NOT_EQUALS:
        return LocalConfigUtils.localConfigHasProperty(condition.value).pipe(map((bool) => !bool));
      default:
        throw new Error(`Unsupported equality relation ${condition.relation}`);
    }
  }

  private validateProperty(condition: ConditionASTNode): void {
    if (!this.localConfigProperties.find((val) => val === condition.value)) {
      throw new Error(`Unknown property on Local Config: ${condition.value}`);
    }
  }
}
