import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as DOMPurify from 'dompurify';

@Component({
  selector: 'cars-tdif-equation',
  templateUrl: './tdif-equation.component.html',
  styleUrls: ['./tdif-equation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifEquationComponent implements OnInit {
  @Input() public index: string;
  @Input() public text: string;
  @Input() public image: string;

  private _sanitizedSVGString: string;
  @ViewChild('equationCell', {static: true}) childComponent: ElementRef;

  constructor() {}

  ngOnInit(): void {
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if (node.hasAttribute('xlink:href') && !node.getAttribute('xlink:href').match(/^#/)) {
        node.remove();
      }
    });
    this._sanitizedSVGString = DOMPurify.sanitize(this.image, {ADD_TAGS: ['use']});
    const tempContainer: HTMLDivElement = document.createElement('div');
    tempContainer.innerHTML = this._sanitizedSVGString;
    const svgElement: SVGSVGElement = tempContainer.querySelector('svg');
    this.childComponent.nativeElement.appendChild(svgElement);
  }
}
