import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {ClauseFragment} from 'app/fragment/types';
import {
  DiscouragedSpecifierInstructionTypes,
  SpecifierInstructionType,
} from 'app/fragment/types/specifier-instruction-type';
import {SpecifierInstructionWrapper} from 'app/services/specifier-instruction.service';
import {Subscription} from 'rxjs';
import {SpecifierInstructionModalService} from './specifier-instruction-modal.service';

export interface SpecifierInstructionModalOutputData {
  cancelled: boolean;
  type: SpecifierInstructionType;
}

interface SpecifierInstructionModalInputData {
  specifierInstructionFragments: ClauseFragment[];
  isWithinScheduleSFR: boolean;
}

@Component({
  selector: 'cars-specifier-instruction-modal',
  templateUrl: './specifier-instruction-modal.component.html',
  styleUrls: ['./specifier-instruction-modal.component.scss'],
  providers: [SpecifierInstructionModalService],
})
export class SpecifierInstructionModalComponent implements OnInit, OnDestroy {
  public selectedType: SpecifierInstructionType;
  public specifierInstructionFragments: ClauseFragment[];
  public guidanceTexts: string[];
  public isWithinScheduleSFR: boolean;

  private _subscriptions: Subscription[] = [];

  public static open(
    dialog: MatDialog,
    specifierInstructionFragments: SpecifierInstructionWrapper[],
    isWithinScheduleSFR: boolean
  ): MatDialogRef<SpecifierInstructionModalComponent, SpecifierInstructionModalOutputData> {
    return dialog.open(SpecifierInstructionModalComponent, {
      ariaLabel: 'Add specifier instruction dialog',
      disableClose: true,
      autoFocus: false,
      width: '70vw',
      height: '70vh',
      data: {
        specifierInstructionFragments,
        isWithinScheduleSFR,
      },
    });
  }

  constructor(
    private _dialogRef: MatDialogRef<SpecifierInstructionModalComponent, SpecifierInstructionModalOutputData>,
    private _specifierInstructionModalService: SpecifierInstructionModalService,
    @Inject(MAT_DIALOG_DATA) private _data: SpecifierInstructionModalInputData,
    private _warningMatDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.specifierInstructionFragments = this._data.specifierInstructionFragments;
    this.isWithinScheduleSFR = this._data.isWithinScheduleSFR;
    this._subscriptions.push(
      this._specifierInstructionModalService.onSelectedTypeChange(
        (type: SpecifierInstructionType) => (this.selectedType = type)
      )
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Block event propagation when clicking on the modal to prevent window/selection changes and ensure the caret
   * doesn't jump on close.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  @HostListener('document:mousedown', ['$event'])
  public mouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  public cancel(): void {
    this._dialogRef.close({cancelled: true, type: null});
  }

  public confirm(): void {
    if (DiscouragedSpecifierInstructionTypes.has(this.selectedType)) {
      this._warningMatDialog
        .open(DialogComponent, {
          ariaLabel: 'Confirm discouraged SI usage',
          data: {
            title: 'Please confirm you wish to use this Specifier Instruction',
            message:
              'Usage of this very generic Specifier Instruction is discouraged. Please use one of the more specific Specifier Instructions available wherever possible. Do you want to continue?',
            closeActions: [
              {title: 'Cancel', tooltip: 'Cancel creation of discouraged SI', color: 'primary', response: null},
              {title: 'Continue', tooltip: 'Confirm creation of discouraged SI', response: 'insert'},
            ],
          },
        })
        .afterClosed()
        .subscribe((action: string) => {
          if (action === 'insert') {
            this._dialogRef.close({cancelled: false, type: this.selectedType});
          }
        });
    } else {
      this._dialogRef.close({cancelled: false, type: this.selectedType});
    }
  }
}
