import {TreeArray} from './tree-array';

/**
 * A class representing a bidirectional tree node, encapsulating common tree operations.
 *
 * @tparam          T                Generic type extending Tree<any>
 * @field parent    {T}              The parent fragment
 * @field children  {TreeArray<T>}   An array of child fragments
 */
export class Tree<T extends Tree<any>> {
  private _parent: T = null;
  public children: TreeArray<T> = new TreeArray<T>();
  public weight: number;

  /**
   * Find the common ancestor of a collection of tree nodes.  Returns null if the nodes are
   * contained in disconnected subtrees (should never happen!).
   *
   * @param nodes  {U[]}   The nodes to process
   * @returns      {U}     The common ancestor
   */
  public static commonAncestorOf<U extends Tree<any>>(...nodes: U[]): U {
    let result: U = null;

    if (nodes.length >= 2) {
      // First find all ancestors of first
      let ancestor: U = nodes[0];
      const firstAncestors: U[] = [];
      while (ancestor) {
        firstAncestors.push(ancestor);
        ancestor = ancestor.parent;
      }

      // Now search ancestors of relative until we find one in first's ancestor stack; since we
      // are traversing second's ancestor stack upwards, it must be the first common ancestor
      ancestor = nodes[1];
      while (ancestor && !result) {
        if (firstAncestors.indexOf(ancestor) >= 0) {
          // The common ancestor relationship is reductive: writing ancestor := commonAncestorOf,
          // ancestor(first, second, third) === ancestor(ancestor(first, second), third)
          result = Tree.commonAncestorOf(ancestor, ...nodes.slice(2));
        } else {
          ancestor = ancestor.parent;
        }
      }
    } else if (nodes.length === 1) {
      result = nodes[0];
    }

    return result;
  }

  public get parent(): T {
    return this._parent;
  }

  public set parent(value: T) {
    this._parent = value;
  }

  constructor(children: T[] = []) {
    this.children.parent = this;
    this.children.push(...children);
  }

  /**
   * Returns true if two tree nodes are equal.
   *
   * @param other {T}          The other node
   * @returns     {boolean}    True if equal
   */
  public equals(other: Tree<T>): boolean {
    return this === other;
  }

  /**
   * Insert this node as the sibling before the given node.  No attempt is made to ensure
   * that this doesn't create circular references.
   *
   * @param node {T}   The node to insert before
   */
  public insertBefore(node: T): void {
    if (node.parent) {
      const index: number = Math.max(node.index(), 0);
      node.parent.children.splice(index, 0, this);
    }
  }

  /**
   * Insert this node as the sibling after the given node.  No attempt is made to ensure
   * that this doesn't create circular references.
   *
   * @param node {T}   The node to insert after
   */
  public insertAfter(node: T): void {
    if (node.parent) {
      const index: number = Math.min(node.index() + 1, node.parent.children.length);
      node.parent.children.splice(index, 0, this);
    }
  }

  /**
   * Get the index of this tree node in it's parent's children array, or -1 if there
   * is no parent.
   *
   * @returns {number}   The child index
   */
  public index(): number {
    return this.parent ? this.parent.children.findIndex((child: T) => child.equals(this)) : -1;
  }

  /**
   * Find the first ancestor of this node for which the given predicate returns true.
   * A node is considered its own ancestor.  Returns null if none can be found.
   *
   * @param predicate {Tree<T> => boolean}   The predicate
   * @returns         {T}                    The ancestor
   */
  public findAncestor(predicate: (node: Tree<T>) => boolean): T {
    let result: Tree<T> = this;
    while (result && !predicate(result)) {
      result = result.parent;
    }

    return result ? (result as T) : null;
  }

  /**
   * Get the root node of the this tree.
   *
   * @returns {T}   The root node
   */
  public root(): T {
    return this.findAncestor((node: T) => !node.parent);
  }

  /**
   * Returns true if this fragment has one or more children.
   *
   * @returns {boolean}   True if this has children
   */
  public hasChildren(): boolean {
    return this.children && this.children.length > 0;
  }

  /**
   * Returns true if this fragment is the first child of its parent.
   *
   * @returns {boolean}   True if the first child
   */
  public isFirstChild(): boolean {
    return this.index() === 0;
  }

  /**
   * Returns true if this fragment is the last child of its parent.
   *
   * @returns {boolean}   True if the last child
   */
  public isLastChild(): boolean {
    const index: number = this.index();
    return index >= 0 && index === this.parent.children.length - 1;
  }

  /**
   * Find the index of target's ancestor which is a direct child of this tree node.  Returns
   * -1 if target is not in the subtree spanned by this fragment.
   *
   * @param target {T}        The target ancestor
   * @returns      {number}   The child index
   */
  public childIndexOf(target: T): number {
    const child: T = target ? target.findAncestor((node: T) => node.parent && node.parent.equals(this)) : null;
    return child ? child.index() : -1;
  }

  /**
   * Get the previous sibling of this node, or null if none exists.
   *
   * @returns {T}   The previous sibling
   */
  public previousSibling(): T {
    const index: number = this.index();
    return index >= 1 ? this.parent.children[index - 1] : null;
  }

  /**
   * Get the next sibling of this node, or null if none exists.
   *
   * @returns {T}   The next sibling
   */
  public nextSibling(): T {
    const index: number = this.index();
    return index >= 0 && index < this.parent.children.length - 1 ? this.parent.children[index + 1] : null;
  }

  /**
   * Get the leaf of the fragment tree immediately before this one, with ordering given by
   * DOM node ordering.  If there is no previous leaf, undefined is returned.
   *
   * @returns {T}   The previous leaf
   */
  public previousLeaf(): T {
    let node: Tree<T> = this;
    let index: number;

    // Go up the tree until node is not the left-most child of our parent
    do {
      index = node.index();
      node = node.parent;
    } while (node && index <= 0);

    // Go to node's next sibling on the left
    if (node) {
      node = node.children[index - 1];
    }

    // Drop down the right-hand side of node's tree until we reach a leaf
    while (node && node.children && node.hasChildren()) {
      node = node.children[node.children.length - 1];
    }

    return index !== Infinity ? (node as T) : null;
  }

  /**
   * Get the leaf of the fragment tree immediately after this one, with ordering given by
   * DOM node ordering.  If there is no next leaf, undefined is returned.
   *
   * @returns {T}   The previous leaf
   */
  public nextLeaf(): T {
    let node: Tree<T> = this;
    let index: number = -1;

    // Go up the tree until node is not the right-most child of our parent
    do {
      index = node.index();
      node = node.parent;
    } while (node && index >= node.children.length - 1);

    // Go to node's next sibling on the right
    if (node) {
      node = node.children[index + 1];
    }

    // Drop down the left-hand side of node's tree until we reach a leaf
    while (node && node.children && node.hasChildren()) {
      node = node.children[0];
    }

    return index >= 0 ? (node as T) : null;
  }

  /**
   * Recursively iterate up the subtree spanned by this tree node and bounded by nodes start
   * and end, calling callback for each.  Given a node, each child subtree is recursively
   * iterated over; if no truthy result is returned, callback is called on the root node.  An
   * array of the truthy results is returned, with order compatible with the leaf ordering and
   * with length bounded above by the number of leaves below this node.
   *
   * @tparam         {R}              The result type from callback
   * @param start    {T}              The start bounding node
   * @param end      {T}              The end node, with start <= end in the leaf ordering
   * @param callback {Tree<T> => R}   The callback
   */
  public iterateUp<R>(start: T, end: T, callback: (node: Tree<T>) => R): R[] {
    // Find the range of children we need to iterate over
    const startIndex: number = Math.max(this.childIndexOf(start), 0);
    let endIndex: number = this.childIndexOf(end);
    if (endIndex < 0) {
      endIndex = this.children.length - 1;
    }

    // Recursively iterate each child, collecting any truthy results; since Tree::iterate()
    // returns type R[], the reduce() flattens the array.  The slice is necessary to ensure
    // our iterators remain valid, despite callback potentially modifying the array under us.
    const truthyResults: R[] = this.children
      .slice(startIndex, endIndex + 1)
      .map((child: T) => child.iterateUp(start, end, callback))
      .reduce((array: R[], value: R[]) => {
        array.push(...value);
        return array;
      }, []);

    // If we didn't get any truthy results, the iteration bubbles to this node
    if (truthyResults.length === 0) {
      const result: R = callback(this);
      if (result) {
        truthyResults.push(result);
      }
    }

    return truthyResults;
  }

  /**
   * Asynchronously, recursively iterate up the subtree spanned by this tree node and bounded by nodes start
   * and end, calling callback for each.  Given a node, each child subtree is recursively
   * iterated over; if no truthy result is returned, callback is called on the root node.  An
   * array of the truthy results is returned, with order compatible with the leaf ordering and
   * with length bounded above by the number of leaves below this node.
   *
   * @tparam         {R}                           The result type from callback
   * @param start    {T}                           The start bounding node
   * @param end      {T}                           The end node, with start <= end in the leaf ordering
   * @param callback {Tree<T> => R | Promise<R>}   The callback
   */
  public async iterateUpAsync<R>(start: T, end: T, callback: (node: Tree<T>) => R | Promise<R>): Promise<R[]> {
    // Find the range of children we need to iterate over
    const startIndex: number = Math.max(this.childIndexOf(start), 0);
    let endIndex: number = this.childIndexOf(end);
    if (endIndex < 0) {
      endIndex = this.children.length - 1;
    }
    // Recursively iterate each child, collecting any truthy results; we need to await each call
    // in order to ensure the order of nodes pushed into the results is preserved and matches
    // synchronous version
    const truthyResults: R[] = [];
    for (const child of this.children.slice(startIndex, endIndex + 1)) {
      const childResult: R[] = await child.iterateUpAsync(start, end, callback);
      truthyResults.push(...childResult);
    }
    // If we didn't get any truthy results, the iteration bubbles to this node
    if (truthyResults.length === 0) {
      const result: R = await callback(this);
      if (result) {
        truthyResults.push(result);
      }
    }
    return truthyResults;
  }

  /**
   * Recursively iterate down the subtree spanned by this tree node and bounded by nodes start
   * and end, calling callback for each.  Given a node, each child subtree is recursively
   * iterated over; if no truthy result is returned, callback is called on the root node.  An
   * array of the truthy results is returned, with order compatible with the leaf ordering and
   * with length bounded above by the number of leaves below this node.
   *
   * @tparam         {R}              The result type from callback
   * @param start    {T}              The start bounding node
   * @param end      {T}              The end node, with start <= end in the leaf ordering
   * @param callback {Tree<T> => R}   The callback
   */
  public iterateDown<R>(start: T, end: T, callback: (node: Tree<T>) => R): R[] {
    const startIndex: number = Math.max(this.childIndexOf(start), 0);
    let endIndex: number = this.childIndexOf(end);
    if (endIndex < 0) {
      endIndex = this.children.length - 1;
    }

    const thisResult: R = callback(this);
    const truthyResults: R[] = thisResult ? [thisResult] : [];

    // If we didn't get a result from this node, process each child
    if (truthyResults.length === 0) {
      const childResults: R[] = this.children
        .slice(startIndex, endIndex + 1)
        .map((child: T) => child.iterateDown(start, end, callback))
        .reduce((array: R[], value: R[]) => {
          array.push(...value);
          return array;
        }, []);

      truthyResults.push(...childResults);
    }

    return truthyResults;
  }

  /**
   * Remove this node from the tree.  If there is no parent, this is a no-op.
   */
  public remove(): void {
    const index: number = this.index();
    if (index >= 0) {
      this.parent.children.splice(index, 1);
    }
  }
}
