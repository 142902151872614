import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'cars-pdf-preview-dialog',
  templateUrl: './pdf-preview-dialog.component.html',
  styleUrls: ['./pdf-preview-dialog.component.scss'],
})
export class PdfPreviewDialogComponent {
  pdf: Uint8Array = null;

  // receive a byte array into the preview dialog component and call method to display it on init using the src as pdf array src above.
  constructor(
    public dialogRef: MatDialogRef<PdfPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Uint8Array
  ) {
    this.pdf = data;
  }
}
