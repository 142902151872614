import {ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BlurOption} from 'app/blur-options';
import {Caret} from 'app/fragment/caret';
import {ClauseFragment} from 'app/fragment/types';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {AltProperties} from 'app/services/alt-accessibility.service';
import {CaretService} from 'app/services/caret.service';
import {ClauseGroupService, StandardFormatGroupConfig} from 'app/services/clause-group.service';
import {ClauseService} from 'app/services/clause.service';
import {RichTextService, RichTextType} from 'app/services/rich-text.service';
import {ToolbarItem} from 'app/suite-config/configuration.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {
  StandardFormatGroupModalComponent,
  StandardFormatGroupModalOutputData,
} from './standard-format-group-modal/standard-format-group-modal.component';

@Component({
  selector: 'cars-standard-format-group-creator',
  templateUrl: './standard-format-group-creator.component.html',
  styleUrls: ['./standard-format-group-creator.component.scss', '../toolbar-buttons.scss'],
})
export class StandardFormatGroupCreatorComponent implements OnInit, OnDestroy {
  @Input() public toolbarItem: ToolbarItem;
  @Input() public altProperties: AltProperties;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly BlurOption: typeof BlurOption = BlurOption;

  public enabled: boolean = false;
  private _lastSelectedClause: ClauseFragment;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _clauseService: ClauseService,
    private _clauseGroupService: ClauseGroupService,
    private _richTextService: RichTextService,
    private _selectionOperationsService: SelectionOperationsService,
    private _caretService: CaretService,
    private _cdr: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._clauseService.onSelection((clause: ClauseFragment) => {
        this.enabled = this.toolbarItem.enabled(clause);
        this.altProperties.condition = this.enabled;
        this._lastSelectedClause = clause || this._lastSelectedClause;
        this._cdr.markForCheck();
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Block event propagation when clicking on the RTE to prevent blurring.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  @HostListener('mousedown', ['$event'])
  public mouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  /**
   * Opens the dialog to add a standard format requirement and create the returned type.
   */
  public openModal(): void {
    const caretOnCancel: Caret = this._caretService.getCaretPositionFromSelectedFragment();
    const standardFormatGroupConfig: StandardFormatGroupConfig[] =
      this._clauseGroupService.getAllStandardFormatGroupFragments();
    const dialog = StandardFormatGroupModalComponent.open(this._dialog, standardFormatGroupConfig);
    dialog
      .afterClosed()
      .subscribe((modalData: StandardFormatGroupModalOutputData) => this._processModalData(modalData, caretOnCancel));
  }

  private _processModalData(modalData: StandardFormatGroupModalOutputData, caretOnCancel: Caret): void {
    if (!modalData.cancelled) {
      this._richTextService.next(RichTextType.STANDARD_FORMAT_GROUP, modalData.type, this._lastSelectedClause);
    } else {
      this._selectionOperationsService.setSelected(
        caretOnCancel.fragment,
        caretOnCancel.offset,
        this._lastSelectedClause.component.padType
      );
    }
  }
}
