import {Pipe, PipeTransform} from '@angular/core';
import {TimeAgoPipe} from 'time-ago-pipe';

@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class ExtendedTimeAgoPipe extends TimeAgoPipe implements PipeTransform {
  /**
   * We override this method to allow us to pass in the millisecond value of the time as well as the date string.
   * This works since in the implementation of transform in TimeAgoPipe, the author immediately calls new Date(date),
   * which works in both cases.
   *
   * @param date the date string or the millisecond value for the given date.
   */
  public transform(date: string | number): string {
    return super.transform(date as any);
  }
}
