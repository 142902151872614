import {Injectable} from '@angular/core';
import {CarsRange} from '../../fragment/cars-range';
import {FragmentMapper} from '../../fragment/core/fragment-mapper';
import {TableCellFragment, TableFragment, TableRowFragment} from '../../fragment/table/table-fragment';
import {TreeArray} from '../../fragment/tree/tree-array';
import {Fragment, FragmentType, ListFragment, ListItemFragment} from '../../fragment/types';
import {DummyFragment} from './dummy-fragment';

@Injectable({
  providedIn: 'root',
})
export class ExcerptService {
  constructor() {}

  /**
   * Gets the subtree under the given range for display
   *
   * @param   range {CarsRange} The range to find the subtree for
   * @returns {Fragment[]}      The fragment subtree under the range
   */
  public getExcerpt(range: CarsRange): Fragment[] {
    const tree: Fragment[] = [];
    const flatTree: Fragment[] = [];
    range.forEachFragment((fragment: Fragment) => {
      if (!fragment.is(FragmentType.SECTION, FragmentType.ANCHOR)) {
        const newFragment: Fragment = FragmentMapper.clone(fragment);
        newFragment.children = <TreeArray<Fragment>>[];

        if (newFragment.isCaptioned()) {
          (newFragment as any).landscape = false;
        }

        // Trim the part of the end fragment we don't need
        if (newFragment.id.equals(range.end.fragment.id)) {
          newFragment.value = newFragment.value.slice(0, range.end.offset);
        }

        // Trim the part of the start fragment we don't need
        if (newFragment.id.equals(range.start.fragment.id)) {
          newFragment.value = newFragment.value.slice(range.start.offset, newFragment.value.length);
        }

        // Check if the fragment should be added as a child and act as appropriate
        const parent: Fragment = flatTree.find((f: Fragment) => f.id.equals(newFragment.parentId));
        if (parent) {
          newFragment.parent = parent;
          parent.children.push(newFragment);
          this._handleNotBeingTheFirstChild(fragment, newFragment, parent);
        } else {
          this._handleNoParent(tree, newFragment, fragment);
        }
        flatTree.push(newFragment);
      }
    });

    return tree;
  }

  /**
   * This handles the case where the position of the fragment amongst the other children is important.
   * e.g for list items, their position in the list changes their number.
   *
   * @param fragment    {Fragment}  The actual fragment in the pad
   * @param newFragment {Fragment}  The current fragment to be added to the tree
   * @param parent      {Fragment}  The fragment parent in the excerpt tree
   */
  private _handleNotBeingTheFirstChild(fragment: Fragment, newFragment: Fragment, parent: Fragment): void {
    switch (newFragment.type) {
      case FragmentType.LIST_ITEM:
        // Handle list items not necessarily being the first in the list
        const indented: boolean = (newFragment as ListItemFragment).indented;
        const itemIndex: number = fragment.parent.children
          .slice(0, fragment.index())
          .filter((item: ListItemFragment) => item.indented === indented).length;
        while (parent.children.length < itemIndex + 1) {
          const newDummyFragment: DummyFragment = new DummyFragment(null);
          newDummyFragment.sectionId = newFragment.sectionId;
          newDummyFragment.documentId = newFragment.documentId;
          newDummyFragment.indented = indented;
          newDummyFragment.parent = parent;
          parent.children.unshift(newDummyFragment);
        }
        break;
      case FragmentType.TABLE_CELL:
        // Handle table cells not necessarily being the first in the row
        const cellIndex: number = fragment.index();
        while (parent.children.length < cellIndex + 1) {
          const newDummyFragment: DummyFragment = new DummyFragment(null);
          newDummyFragment.sectionId = newFragment.sectionId;
          newDummyFragment.documentId = newFragment.documentId;
          newDummyFragment.parent = parent;
          parent.children.unshift(newDummyFragment);
        }
        break;
      default:
      // Do nothing
    }
  }

  /**
   * Handles the case where the newFragment doesn't have a parent. Some fragments don't have their own components and rely on
   * having a parent (e.g list items) so this handles that case.
   *
   * @param tree        {Fragment[]}  The current extract tree
   * @param newFragment {Fragment}    The current fragment to be added
   */
  private _handleNoParent(tree: Fragment[], newFragment: Fragment, originalFragment: Fragment): void {
    let fragmentToAdd: Fragment = newFragment;
    switch (newFragment.type) {
      case FragmentType.LIST_ITEM: {
        // Give the list item a list parent
        const list: ListFragment = new ListFragment(null, [newFragment as ListItemFragment], true);
        list.documentId = newFragment.documentId;
        list.sectionId = newFragment.sectionId;
        newFragment.parent = list;
        fragmentToAdd = list;
        break;
      }
      case FragmentType.TABLE_ROW: {
        // Give the row a table parent
        const table: TableFragment = new TableFragment(null, [newFragment as TableRowFragment]);
        table.documentId = newFragment.documentId;
        table.sectionId = newFragment.sectionId;
        table.caption = FragmentMapper.clone(
          (originalFragment.findAncestorWithType(FragmentType.TABLE) as TableFragment).caption
        );
        newFragment.parent = table;
        fragmentToAdd = table;
        break;
      }
      case FragmentType.TABLE_CELL: {
        // Give the cell the proper ancestors (a table row and a table)
        const tableRow: TableRowFragment = new TableRowFragment(null, [newFragment as TableCellFragment]);
        tableRow.documentId = newFragment.documentId;
        tableRow.sectionId = newFragment.sectionId;
        const table: TableFragment = new TableFragment(null, [tableRow]);
        table.documentId = tableRow.documentId;
        table.sectionId = tableRow.sectionId;
        table.caption = FragmentMapper.clone(
          (originalFragment.findAncestorWithType(FragmentType.TABLE) as TableFragment).caption
        );
        newFragment.parent = tableRow;
        tableRow.parent = table;
        fragmentToAdd = table;
        break;
      }
      default:
        // Do nothing
        break;
    }

    tree.push(fragmentToAdd);
  }
}
