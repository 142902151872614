import {Fragment, FragmentType, TextFragment} from 'app/fragment/types';
import {UUID} from 'app/utils/uuid';
import {AbstractInputFragment} from './abstract-input-fragment';

/**
 * A fragment that allows for a placeholder value.
 */
export class InputFragment extends AbstractInputFragment {
  private static readonly START_DELIMITER: string = '<';
  private static readonly END_DELIMITER: string = '>';

  /**
   * Static factory to create empty InputFragments.
   *
   * @returns {InputFragment}   The new input fragment
   */
  public static empty(placeholderValue: string = ''): InputFragment {
    return new InputFragment(null, placeholderValue, [TextFragment.empty()]);
  }

  constructor(id: UUID, placeholderValue: string = '', children: Fragment[]) {
    super(
      id,
      FragmentType.INPUT,
      InputFragment.START_DELIMITER,
      InputFragment.END_DELIMITER,
      placeholderValue,
      children
    );
  }

  public getPreview(): string {
    return this.length()
      ? this.children.reduce((returnValue: string, child: Fragment) => returnValue + child.value, '')
      : this.displayPlaceholder;
  }
}
