import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {ListIndexingType} from 'app/fragment/types';
import {TdifListItem} from '../tdif-types';

@Component({
  selector: 'cars-tdif-list',
  templateUrl: './tdif-list.component.html',
  styleUrls: ['./tdif-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifListComponent {
  @Input() public ordered: boolean;
  @Input() public items: TdifListItem[];
  public primaryListIndexingType: ListIndexingType = ListIndexingType.NUMERICAL; // add to export of frag
  public listStartIndex;

  constructor(protected _cd: ChangeDetectorRef) {}

  /**
   * Returns true is the given indented item is the first in a block in indented items, else false.
   *
   * @param item  {ListItemFragment}  The target list item.
   */
  public isFirstIndentedItem(item: TdifListItem): boolean {
    const index: number = this.items.indexOf(item);
    return !(index > 0 && this.items[index - 1].indented);
  }

  public startOffset(): number {
    return this.listStartIndex === null ? 0 : this.listStartIndex - 1;
  }

  /**
   * Returns the given indented list item and all following consecutive indented list items.
   *
   * @param listItem  {ListItemFragment}  The target list item.
   */
  public iterateOverIndented(listItem: TdifListItem): TdifListItem[] {
    const returnList: TdifListItem[] = [];
    let index: number = this.items.indexOf(listItem);
    let currentItem: TdifListItem = listItem;

    while (currentItem && currentItem.indented) {
      returnList.push(currentItem);
      ++index;
      currentItem = index <= this.items.length ? this.items[index] : null;
    }
    return returnList;
  }
}
