<ng-container *ngIf="shouldDisplay">
  <div
    *ngIf="!richText"
    [ngClass]="{
      'alt-big': !!!altProperties.key,
      'alt-small': !!altProperties.key
    }"
  >
    {{ displayKey }}
  </div>
  <div
    *ngIf="richText"
    [ngClass]="{
      'alt-big': !!!altProperties.key,
      'alt-small': !!altProperties.key
    }"
    [ngStyle]="{left: left + 'px', top: top + 'px', position: 'fixed'}"
  >
    {{ displayKey }}
  </div>
</ng-container>
