import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {AssignedUserModule} from '../../role-assignments/user-list-autocomplete/assigned-user/assigned-user.module';
import {JiraInformationComponent} from './jira-information.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatFormFieldModule, MatInputModule, MatButtonModule, AssignedUserModule],
  declarations: [JiraInformationComponent],
  exports: [JiraInformationComponent],
})
export class JiraInformationModule {}
