import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DiscussionSummary} from 'app/dashboard/aggregates/discussion-summary';
import {DocumentDiscussionsAggregate} from 'app/dashboard/aggregates/document-discussions-aggregate';
import {SectionDiscussionsAggregate} from 'app/dashboard/aggregates/section-discussions-aggregate';
import {
  VersionDiscussionsAggregate,
  VersionDiscussionsAggregateAll,
  VersionDiscussionsAggregateByUser,
} from 'app/dashboard/aggregates/version-discussions-aggregate';
import {DocumentAggregateSearchParams} from 'app/dashboard/dashboard-sidebar/dashboard-sidebar.component';
import {SearchResult} from 'app/search/search-documents.service';
import {Callback} from 'app/utils/typedefs';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {BehaviorSubject, Subscription, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from './base.service';
import {ReviewStateService, UserReviewState} from './review-state.service';

export enum Dashboard {
  MY_DISCUSSIONS = 'MY_DISCUSSIONS',
  MY_DOCUMENTS = 'MY_DOCUMENTS',
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseService {
  private static readonly URL_MAP: Readonly<Partial<Record<Dashboard, string>>> = {
    [Dashboard.MY_DISCUSSIONS]: `${environment.apiHost}/dashboards/my-discussions`,
    [Dashboard.MY_DOCUMENTS]: `${environment.apiHost}/dashboards/my-documents`,
  };

  private _versionAggregateSelectionSubject: BehaviorSubject<VersionDiscussionsAggregate> = new BehaviorSubject(null);

  constructor(
    protected _snackbar: MatSnackBar,
    private _http: HttpClient,
    private _reviewStateService: ReviewStateService
  ) {
    super(_snackbar);
  }

  public onVersionAggregateSelect(callback: Callback<VersionDiscussionsAggregate>): Subscription {
    return this._versionAggregateSelectionSubject.subscribe(callback);
  }

  public selectVersionAggregate(versionAggregate: VersionDiscussionsAggregate): void {
    this._versionAggregateSelectionSubject.next(versionAggregate);
  }

  public getDocumentDiscussionsAggregates(
    dashboardType: Dashboard,
    searchParams: DocumentAggregateSearchParams
  ): Promise<SearchResult<DocumentDiscussionsAggregate>> {
    const params = {
      searchTerm: searchParams.searchTerm,
      pageIndex: searchParams.pageIndex.toString(),
      pageSize: searchParams.pageSize.toString(),
    };
    return this._http
      .get<any>(DashboardService.URL_MAP[dashboardType], {params})
      .toPromise()
      .then((result) => {
        return {page: result.page.map(DocumentDiscussionsAggregate.deserialise), total: result.total};
      })
      .catch((error: any) => {
        this._handleError(error, 'Failed to fetch document discussion aggregates.', 'dashboards-error');
        return Promise.reject(error);
      });
  }

  public getVersionDiscussionsAggregates(
    documentId: UUID,
    dashboardType: Dashboard
  ): Promise<VersionDiscussionsAggregate[]> {
    return dashboardType === Dashboard.MY_DISCUSSIONS
      ? this._getVersionDiscussionsAggregatesByUser(documentId)
      : this._getVersionDiscussionsAggregatesAll(documentId);
  }

  private _getVersionDiscussionsAggregatesByUser(documentId: UUID): Promise<VersionDiscussionsAggregateByUser[]> {
    const reviewStatePromise: Promise<Record<string, UserReviewState>> =
      this._reviewStateService.getCurrentUserStateForAllVersions(documentId);
    const versionDiscussionAggregatesPromise: Promise<VersionDiscussionsAggregateByUser[]> = this._http
      .get<any[]>(`${DashboardService.URL_MAP[Dashboard.MY_DISCUSSIONS]}/documents/${documentId.value}`)
      .pipe(
        catchError((err: any) => {
          this._handleError(err, 'Failed to fetch version discussions aggregates.', 'dashboards-error');
          return throwError(err);
        }),
        map((response: any[]) => response.map(VersionDiscussionsAggregateByUser.deserialise))
      )
      .toPromise();
    return Promise.all([reviewStatePromise, versionDiscussionAggregatesPromise]).then(
      ([reviewStates, versionAggregates]: [Record<string, UserReviewState>, VersionDiscussionsAggregateByUser[]]) => {
        return versionAggregates.map((aggregate: VersionDiscussionsAggregateByUser) => {
          if (aggregate.versionId) {
            aggregate.setUserReviewState(reviewStates[aggregate.versionId.value]);
          }
          return aggregate;
        });
      }
    );
  }

  private _getVersionDiscussionsAggregatesAll(documentId: UUID): Promise<VersionDiscussionsAggregateAll[]> {
    const reviewStatePromise: Promise<Record<string, UserReviewState[]>> =
      this._reviewStateService.getReviewerStatesForAllVersions(documentId);
    const aggregatesPromise: Promise<VersionDiscussionsAggregateAll[]> = this._http
      .get<any[]>(`${DashboardService.URL_MAP[Dashboard.MY_DOCUMENTS]}/documents/${documentId.value}`)
      .pipe(
        catchError((err: any) => {
          this._handleError(err, 'Failed to fetch version discussions aggregates.', 'dashboards-error');
          return throwError(err);
        }),
        map((response: any[]) => response.map(VersionDiscussionsAggregateAll.deserialise))
      )
      .toPromise();
    return Promise.all([reviewStatePromise, aggregatesPromise]).then(
      ([reviewStates, versionAggregates]: [Record<string, UserReviewState[]>, VersionDiscussionsAggregateAll[]]) => {
        return versionAggregates.map((aggregate: VersionDiscussionsAggregateAll) => {
          if (aggregate.versionId) {
            aggregate.setUserReviewStates(reviewStates[aggregate.versionId.value]);
          }
          return aggregate;
        });
      }
    );
  }

  public getLiveSectionDiscussionsAggregates(
    documentId: UUID,
    dashboardType: Dashboard
  ): Promise<SectionDiscussionsAggregate[]> {
    return this._http
      .get<any[]>(`${DashboardService.URL_MAP[dashboardType]}/documents/${documentId.value}/live-version`)
      .toPromise()
      .then((array) => {
        return array.map(SectionDiscussionsAggregate.deserialise);
      })
      .catch((error: any) => {
        this._handleError(error, 'Failed to fetch live section discussion aggregates.', 'dashboards-error');
        return Promise.reject(error);
      });
  }

  public getVersionSectionDiscussionsAggregates(
    documentId: UUID,
    versionId: UUID,
    dashboardType: Dashboard
  ): Promise<SectionDiscussionsAggregate[]> {
    return this._http
      .get<any[]>(
        `${DashboardService.URL_MAP[dashboardType]}/documents/${documentId.value}/versions/${versionId.value}`
      )
      .toPromise()
      .then((array) => {
        return array.map(SectionDiscussionsAggregate.deserialise);
      })
      .catch((error: any) => {
        this._handleError(error, 'Failed to fetch versioned section discussion aggregates.', 'dashboards-error');
        return Promise.reject(error);
      });
  }

  public getLiveDiscussionsSummariesForSection(
    documentId: UUID,
    sectionId: UUID,
    dashboardType: Dashboard
  ): Promise<DiscussionSummary[]> {
    return this._http
      .get<any[]>(
        `${DashboardService.URL_MAP[dashboardType]}/documents/${documentId.value}/live-version/sections/${sectionId.value}`
      )
      .toPromise()
      .then((array) => {
        return array.map(DiscussionSummary.deserialise);
      })
      .catch((error: any) => {
        this._handleError(error, 'Failed to fetch live discussion summaries for section.', 'dashboards-error');
        return Promise.reject(error);
      });
  }

  public getVersionDiscussionsSummariesForSection(
    documentId: UUID,
    versionId: UUID,
    sectionId: UUID,
    dashboardType: Dashboard
  ): Promise<DiscussionSummary[]> {
    return this._http
      .get<any[]>(
        `${DashboardService.URL_MAP[dashboardType]}/documents/${documentId.value}/versions/${versionId.value}/sections/${sectionId.value}`
      )
      .toPromise()
      .then((array) => {
        return array.map(DiscussionSummary.deserialise);
      })
      .catch((error: any) => {
        this._handleError(error, 'Failed to fetch versioned discussion summaries for section.', 'dashboards-error');
        return Promise.reject(error);
      });
  }
}
