<mat-nav-list *ngIf="notification">
  <mat-list-item (click)="navigate()">
    <mat-list>
      <mat-list-item>
        <mat-icon matListIcon [ngClass]="{failed: hasFailed(), succeeded: hasSucceeded()}">{{
          notification.getIconName()
        }}</mat-icon>
        <h3 matLine>{{ notification.title }} ({{ notification.createdAt | timeAgo }})</h3>
        <span matLine [ngClass]="{failed: hasFailed()}">{{ notification.content }}</span>
      </mat-list-item>
      <mat-list-item *ngFor="let child of notification.childNotifications">
        <mat-icon matListIcon [ngClass]="{warning: child.isWarning()}">{{ child.getIconName() }}</mat-icon>
        <h3 matLine>{{ child.title }} ({{ child.createdAt | timeAgo }})</h3>
        <span matLine>{{ child.content }}</span>
      </mat-list-item>
    </mat-list>
  </mat-list-item>
</mat-nav-list>
<mat-progress-bar *ngIf="isInProgress()" mode="indeterminate"></mat-progress-bar>
