import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  ClauseFragment,
  DocumentFragment,
  DocumentInformationFragment,
  DocumentInformationType,
  Fragment,
  FragmentType,
} from 'app/fragment/types';
import {AssociatedDocumentInformationFragment} from 'app/fragment/types/associated-document-information-fragment';
import {AssociatedDocumentType} from 'app/fragment/types/associated-document-information-type';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {UserService} from 'app/services/user/user.service';
import {DocumentInformationItem} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';
import {ViewMode} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {BaseFormComponent} from '../base-form.component';
import {
  AssociatedDocumentInformationModalComponent,
  AssociatedDocumentInformationModalOutputData,
} from './associated-document-information-modal/associated-document-information-modal.component';

@Component({
  selector: 'cars-associated-document-information',
  templateUrl: './associated-document-information.component.html',
  styleUrls: ['./associated-document-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociatedDocumentInformationComponent extends BaseFormComponent implements OnInit {
  @Input() public set documentInformationItem(documentInformationItem: DocumentInformationItem) {
    this._clause = documentInformationItem.documentInformationFragment.parent as ClauseFragment;

    this._documentInformationFragment = documentInformationItem.documentInformationFragment;

    this._documentInformationFragment.children
      .sort((a: AssociatedDocumentInformationFragment, b: AssociatedDocumentInformationFragment) => a.weight - b.weight)
      .forEach((child: AssociatedDocumentInformationFragment) => {
        this.associatedDocumentInformationFragments.push(child);
      });

    this.hasChildren = this.associatedDocumentInformationFragments.length > 0;
  }
  @Input() document: DocumentFragment;
  @Input() isReadonly: boolean;

  private readonly ASSOCIATED_DOCUMENT_INFO_DELTA: number = 1000;

  private _documentInformationFragment: DocumentInformationFragment;
  public associatedDocumentInformationFragments: AssociatedDocumentInformationFragment[] = new Array();
  public hasChildren: boolean;
  public currentView: ViewMode;
  public readonly tooltipDelay: number = environment.tooltipDelay;
  private subscriptions: Subscription[] = [];

  constructor(
    protected fragmentService: FragmentService,
    protected lockService: LockService,
    protected userService: UserService,
    protected versioningService: VersioningService,
    protected viewService: ViewService,
    protected cdr: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {
    super(fragmentService, lockService, userService, versioningService, cdr);
  }

  public ngOnInit(): void {
    this.currentView = this.viewService.getCurrentView().viewMode;
    this.subscriptions.push(
      this.fragmentService.onUpdate(
        (fragment: Fragment) => {
          const index = this.associatedDocumentInformationFragments.findIndex((frag) => frag.id.equals(fragment.id));
          this.associatedDocumentInformationFragments[index] = fragment as AssociatedDocumentInformationFragment;
          this.cdr.markForCheck();
        },
        (fragment: Fragment) => {
          return (
            fragment.is(FragmentType.ASSOCIATED_DOCUMENT_INFORMATION) && fragment.documentId.equals(this.document.id)
          );
        }
      )
    );
  }

  public canUpdate(): boolean {
    return (
      this.lockService.canLock(this._documentInformationFragment.parent as ClauseFragment) &&
      this.currentView === ViewMode.LIVE &&
      !this.isReadonly
    );
  }

  public getDocumentInformationFragment(): DocumentInformationFragment {
    return this._documentInformationFragment;
  }

  public openModal(existingFragment?: AssociatedDocumentInformationFragment, remove?: boolean): void {
    this.lockService.lock(this._clause);

    const dialog = AssociatedDocumentInformationModalComponent.open(this._dialog, existingFragment, remove);
    dialog.afterClosed().subscribe((modalData: AssociatedDocumentInformationModalOutputData) => {
      this.lockService.unlock(this._clause);

      // User has cancelled the modal
      if (modalData.cancelled) {
        return;
      }

      // User has submitted the remove modal
      if (modalData.remove) {
        this._handleRemove(existingFragment);
        return;
      }

      // User has submitted the modal with an existing fragment
      if (!!existingFragment) {
        this._handleEdit(existingFragment, modalData);
        return;
      }

      // User has submitted the modal with no existing fragment
      this._handleAdd(modalData);
    });
  }

  public getHasNationalVariations(existingFragment: AssociatedDocumentInformationFragment): string {
    return existingFragment.hasNationalVariations ? 'Yes' : 'No';
  }

  private _handleRemove(existingFragment: AssociatedDocumentInformationFragment) {
    const index = existingFragment.index();
    const type = existingFragment.associatedDocumentType;
    const fragmentsToUpdate: AssociatedDocumentInformationFragment[] = [];
    this.associatedDocumentInformationFragments
      .filter(
        (child: AssociatedDocumentInformationFragment) => child.associatedDocumentType === type && child.index() > index
      )
      .forEach((child: AssociatedDocumentInformationFragment) => {
        child.reference = this._generateReference(child.id, type, child.index());
        this.associatedDocumentInformationFragments[child.index()] = child;
        fragmentsToUpdate.push(child);
      });

    if (fragmentsToUpdate.length > 0) {
      this.fragmentService.update(fragmentsToUpdate);
    }

    this.associatedDocumentInformationFragments.splice(existingFragment.index(), 1);
    this.fragmentService.delete(existingFragment);

    if (this.associatedDocumentInformationFragments.length === 0) {
      this.hasChildren = false;
    }
    this.cdr.markForCheck();
  }

  private _handleEdit(
    existingFragment: AssociatedDocumentInformationFragment,
    modalData: AssociatedDocumentInformationModalOutputData
  ) {
    existingFragment.title = modalData.title;
    existingFragment.hasNationalVariations = modalData.hasNationalVariations;
    this.associatedDocumentInformationFragments[existingFragment.index()] = existingFragment;
    this.fragmentService.update(existingFragment);
    this.cdr.markForCheck();
  }

  private _handleAdd(modalData: AssociatedDocumentInformationModalOutputData) {
    const id: UUID = UUID.random();
    const associatedDocumentInformationFragmentToCreate: AssociatedDocumentInformationFragment =
      new AssociatedDocumentInformationFragment(
        id,
        modalData.associatedDocumentType,
        modalData.title,
        this._generateReference(id, modalData.associatedDocumentType),
        modalData.hasNationalVariations
      );

    associatedDocumentInformationFragmentToCreate.documentId = this._documentInformationFragment.documentId;
    associatedDocumentInformationFragmentToCreate.sectionId = this._documentInformationFragment.sectionId;
    associatedDocumentInformationFragmentToCreate.parent = this._documentInformationFragment;
    associatedDocumentInformationFragmentToCreate.parentId = this._documentInformationFragment.id;

    if (this.associatedDocumentInformationFragments.length > 0) {
      const lastChild: AssociatedDocumentInformationFragment =
        this.associatedDocumentInformationFragments[this.associatedDocumentInformationFragments.length - 1];
      associatedDocumentInformationFragmentToCreate.weight = lastChild.weight + this.ASSOCIATED_DOCUMENT_INFO_DELTA;
    }

    this.fragmentService.create(associatedDocumentInformationFragmentToCreate);

    this.associatedDocumentInformationFragments.push(associatedDocumentInformationFragmentToCreate);
    this.hasChildren = true;
    this.cdr.markForCheck();
  }

  private _generateReference(id: UUID, type: AssociatedDocumentType, index?: number): string {
    const documentCode: string =
      this.document.getInformation(DocumentInformationType.SHW_DOCUMENT_CODE).value || '[No Document Code]';
    const typeCode: string = this._getCodeForType(type);
    return documentCode + '/' + typeCode + '/' + this._calculateCode(id, type, index);
  }

  private _calculateCode(id: UUID, type: AssociatedDocumentType, index?: number): string {
    const filteredList = this.associatedDocumentInformationFragments.filter(
      (a: AssociatedDocumentInformationFragment) => a.associatedDocumentType === type
    );

    if (filteredList.find((f: Fragment) => f.id.value === id.value) === undefined) {
      const matchingTypeChildrenCount: number = filteredList.length;

      return this._convertNumberToStringAndPad(matchingTypeChildrenCount + 1);
    } else {
      const predecessorsCount: number = filteredList.filter((a) => a.index() < index).length;

      return this._convertNumberToStringAndPad(predecessorsCount);
    }
  }

  private _convertNumberToStringAndPad(number: number): string {
    return String(number).padStart(3, '0');
  }

  private _getCodeForType(type: AssociatedDocumentType): string {
    switch (type) {
      case AssociatedDocumentType.CONSTRUCTOR_PROFORMA: {
        return 'PRO';
      }
      default: {
        return '???';
      }
    }
  }
}
