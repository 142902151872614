import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {ClauseLinkRequired} from 'app/fragment/fragment-link/clause-link-required';
import {ClauseLinkType} from 'app/fragment/fragment-link/clause-link-type';
import {FragmentLink} from 'app/fragment/fragment-link/fragment-link';
import {ClauseFragment, Fragment} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {VersionTag} from 'app/interfaces';
import {CarsAction} from 'app/permissions/types/permissions';
import {ClauseLinkService} from 'app/services/clause-link.service';
import {ClauseService} from 'app/services/clause.service';
import {FragmentService} from 'app/services/fragment.service';
import {CLAUSE_LINK_TYPE_TO_DISPLAY_NAME} from 'app/utils/pipes/clause-link-type-to-display-name.pipe';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-clause-link-required-selector',
  templateUrl: './clause-link-required-selector.component.html',
  styleUrls: ['./clause-link-required-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseLinkRequiredSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public clause: ClauseFragment;
  @Input() public clauseLinkType: ClauseLinkType;

  @Output() public startSelectingLinks: EventEmitter<boolean> = new EventEmitter();

  public readonly ClauseLinkRequired: typeof ClauseLinkRequired = ClauseLinkRequired;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public readonly clauseLinkRequiredOptions: ReadonlyArray<ClauseLinkRequired> = [
    ClauseLinkRequired.NOT_SPECIFIED,
    ClauseLinkRequired.YES,
    ClauseLinkRequired.NO,
  ];

  public loading: boolean = true;

  public linkRequired: ClauseLinkRequired;

  public clauseLinks: FragmentLink[];
  public brokenLinks: FragmentLink[];

  private _versionTagValidAt: number;

  private _subscriptions: Subscription[] = [];
  private _clauseSubscriptions: Subscription[] = [];

  constructor(
    private _clauseService: ClauseService,
    private _fragmentService: FragmentService,
    private _clauseLinkService: ClauseLinkService,
    private _viewService: ViewService,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._fragmentService.onUpdate(
        () => {
          this.linkRequired = this._getLinkRequired() || ClauseLinkRequired.NOT_SPECIFIED;
          this._cdr.markForCheck();
        },
        (frag: Fragment) => frag.id.equals(this.clause.id)
      )
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('clause')) {
      this.linkRequired = this._getLinkRequired() || ClauseLinkRequired.NOT_SPECIFIED;

      this._clauseSubscriptions.splice(0).forEach((s) => s.unsubscribe());

      if (this.clause) {
        this.loading = true;
        this._setClauseLinks([]);

        const currentViewVersionTag: VersionTag = this._viewService.getCurrentView()?.versionTag;
        this._versionTagValidAt = !!currentViewVersionTag ? currentViewVersionTag.createdAt : null;

        this._clauseSubscriptions.push(
          this._clauseLinkService
            .getAllFromClause(this.clause, this._versionTagValidAt)
            .subscribe((clauseLinks: FragmentLink[]) => {
              this._setClauseLinks(clauseLinks);

              this.loading = false;
              this._cdr.markForCheck();
            }),
          this._clauseLinkService.getHasValidLinksStream(this.clause, this._versionTagValidAt).subscribe(() => {
            this._recalculateValidLinks();
            this._cdr.markForCheck();
          })
        );
      } else {
        this._setClauseLinks([]);
        this._cdr.markForCheck();
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
    this._clauseSubscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Recalculates the list of valid and broken links, triggered when another user deletes or undoes the deletion of a
   * linked clause group.
   */
  private _recalculateValidLinks(): void {
    const allLinks: FragmentLink[] = [...this.clauseLinks, ...this.brokenLinks];
    this._setClauseLinks(allLinks);
  }

  private _setClauseLinks(links: FragmentLink[]): void {
    this.clauseLinks = [];
    this.brokenLinks = [];

    links
      .filter((link: FragmentLink) => link.clauseLinkType === this.clauseLinkType)
      .forEach((link: FragmentLink) => {
        const clauseGroup: ClauseGroupFragment = this._getClauseGroupForLink(link);

        if (clauseGroup && clauseGroup.sectionId.equals(this.clause.sectionId)) {
          this.clauseLinks.push(link);
        } else {
          this.brokenLinks.push(link);
        }
      });

    this.clauseLinks.sort((linkA, linkB) => {
      const clauseGroupA: ClauseGroupFragment = this._getClauseGroupForLink(linkA);
      const clauseGroupB: ClauseGroupFragment = this._getClauseGroupForLink(linkB);

      return clauseGroupA.weight - clauseGroupB.weight;
    });
  }

  private _getClauseGroupForLink(link: FragmentLink): ClauseGroupFragment {
    return this._fragmentService.find(link.targetFragmentId, this._versionTagValidAt) as ClauseGroupFragment;
  }

  /**
   * Updates the clause property of whether a clause is required, if this changes then clear out the current list of
   * linked clauses.
   */
  public onLinkRequiredChange(): void {
    this._setLinkRequired(this.linkRequired);

    this._clauseService.update(this.clause);
    this._clearSelectedLinks();
  }

  /**
   * Emits an event that the user has clicked to start selecting requirements.
   */
  public selectRequirements(): void {
    this.startSelectingLinks.emit(true);
  }

  /**
   * Opens a modal to confirm deleting links for clause.
   */
  public clearSelection(): void {
    const clauseLinkTypeStringName: string = CLAUSE_LINK_TYPE_TO_DISPLAY_NAME[this.clauseLinkType].toLowerCase();
    const ref = this._dialog.open(DialogComponent, {
      ariaLabel: `Clear all ${clauseLinkTypeStringName} links for clause dialog`,
      data: {
        title: `Clear all ${clauseLinkTypeStringName} links for clause`,
        message: 'Are you sure you want to continue? This can not be undone.',
        closeActions: [
          {
            title: 'Clear',
            tooltip: `Clear all ${clauseLinkTypeStringName} links for clause`,
            response: 'delete',
            color: 'warn',
          },
          {
            title: 'Cancel',
            tooltip: `Cancel clearing all ${clauseLinkTypeStringName} links for clause`,
            response: null,
          },
        ],
      },
    });
    this._subscriptions.push(
      ref.afterClosed().subscribe((action) => {
        if (!!action) {
          this._clearSelectedLinks();
        }
      })
    );
  }

  /**
   * Marks any existing links from the current clause as deleted.
   */
  private _clearSelectedLinks(): void {
    this._clauseLinkService.deleteAll(this.clauseLinks);
    this.deleteBrokenLinks();
    this._cdr.markForCheck();
  }

  public deleteBrokenLinks(): void {
    this._clauseLinkService.deleteAll(this.brokenLinks);
    this._cdr.markForCheck();
  }

  /**
   * Helper method to get the linkRequired property from the clause for the component link type.
   */
  private _getLinkRequired(): ClauseLinkRequired {
    switch (this.clauseLinkType) {
      case ClauseLinkType.VERIFICATION:
        return this.clause.verificationLinkRequired;
      case ClauseLinkType.DOCUMENTATION:
        return this.clause.documentationLinkRequired;
      default:
        throw new Error('Unexpected clause link type: ' + this.clauseLinkType);
    }
  }

  /**
   * Helper method to set the linkRequired property on the clause to the provided link type.
   */
  private _setLinkRequired(clauseLinkRequired: ClauseLinkRequired): void {
    switch (this.clauseLinkType) {
      case ClauseLinkType.VERIFICATION:
        this.clause.verificationLinkRequired = clauseLinkRequired;
        break;
      case ClauseLinkType.DOCUMENTATION:
        this.clause.documentationLinkRequired = clauseLinkRequired;
        break;
      default:
        throw new Error('Unexpected clause link type: ' + this.clauseLinkType);
    }
  }
}
