import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {ClauseAndSectionOptionsModule} from 'app/suite-config/clause-and-section-options.module';
import {AltAccessibilityModule} from '../../accessibility/alt-accessibility/alt-accessibility.module';
import {ClauseTypeSelectorComponent} from './clause-type-selector.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    CarsErrorHandlingModule,
    MatOptionModule,
    FormsModule,
    ClauseAndSectionOptionsModule,
    AltAccessibilityModule,
  ],
  declarations: [ClauseTypeSelectorComponent],
  exports: [ClauseTypeSelectorComponent],
})
export class ClauseTypeSelectorModule {}
