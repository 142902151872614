<div class="cars-sidebar">
  <div class="sidebar-header">
    <p class="title">{{ status }}</p>
    <button
      *ngIf="refreshable"
      (click)="refresh()"
      mat-button
      matTooltip="Refresh sidebar"
      matTooltipPosition="below"
      [matTooltipShowDelay]="tooltipDelay"
      aria-label="Refresh sidebar"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="showClausePreviewInSidebar()">
    <div class="index" *ngIf="isIndexed(); then indexed; else nonIndexed"></div>
    <ng-template #indexed>
      <div class="index">{{ clause | fragmentIndex }} {{ clause?.preview }}</div>
    </ng-template>
    <ng-template #nonIndexed>
      <div class="index">{{ clause?.preview }}</div>
    </ng-template>
  </ng-container>

  <div class="sidebar-content" [ngSwitch]="status">
    <cars-background #content [clause]="clause" *ngSwitchCase="SidebarStatus.BACKGROUND_COMMENTARY"></cars-background>
    <cars-discussions
      #content
      [selectedFragment]="selectedFragment"
      *ngSwitchCase="SidebarStatus.DISCUSSIONS"
    ></cars-discussions>
    <cars-validation-errors
      #content
      [clause]="clause"
      *ngSwitchCase="SidebarStatus.VALIDATION"
    ></cars-validation-errors>
    <cars-history #content [clause]="clause" *ngSwitchCase="SidebarStatus.HISTORY"></cars-history>
    <cars-references #content *ngSwitchCase="SidebarStatus.REFERENCES"></cars-references>
    <cars-clause-references
      #content
      [clause]="clause"
      *ngSwitchCase="SidebarStatus.CLAUSE_REFERENCES"
    ></cars-clause-references>
    <cars-reorder #content *ngSwitchCase="SidebarStatus.REORDERING"></cars-reorder>
    <cars-clause-links #content [clause]="clause" *ngSwitchCase="SidebarStatus.CLAUSE_LINKS"></cars-clause-links>
    <cars-section-references
      #content
      [clause]="clause"
      *ngSwitchCase="SidebarStatus.SECTION_REFERENCES"
    ></cars-section-references>
    <cars-unit-selection #content [clause]="clause" *ngSwitchCase="SidebarStatus.UNIT_SELECTION"></cars-unit-selection>
    <cars-alt-text #content [clause]="clause" *ngSwitchCase="SidebarStatus.ALT_TEXT"></cars-alt-text>
  </div>
</div>
