import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DocumentSelectorModule} from 'app/search/document-selector/document-selector.module';
import {RequiredNonWhitespaceModule} from 'app/utils/directives/required-non-whitespace.module';
import {ReferenceDialogComponent} from './reference-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    RequiredNonWhitespaceModule,
    DocumentSelectorModule,
    MatProgressBarModule,
  ],
  declarations: [ReferenceDialogComponent],
  exports: [ReferenceDialogComponent],
})
export class ReferenceDialogModule {}
