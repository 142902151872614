import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ValidationErrorComponent} from './validation-error.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [ValidationErrorComponent],
  exports: [ValidationErrorComponent],
})
export class ValidationErrorModule {}
