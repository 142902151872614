import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TdifTextProperties} from '../tdif-types';

@Component({
  selector: 'cars-tdif-text',
  templateUrl: './tdif-text.component.html',
  styleUrls: ['./tdif-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifTextComponent {
  @Input() public value: String;
  @Input() public properties: TdifTextProperties;

  constructor() {}

  public isMemo(): boolean {
    return this.properties?.memo;
  }
}
