import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {VersionTag} from 'app/interfaces';
import {OfflinePersistenceService} from 'app/offline/offline-persistence.service';
import {environment} from 'environments/environment';

export enum LoadingState {
  NOT_AVAILABLE = -2,
  UNKNOWN = -1,
  NOT_DOWNLOADED = 0,
  DOWNLOADING = 1,
  DOWNLOADED = 2,
}

@Component({
  selector: 'cars-offline-version-status',
  templateUrl: './offline-version-status.component.html',
  styleUrls: ['./offline-version-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineVersionStatusComponent implements OnInit {
  public readonly dateFormat: string = 'MMM dd yyyy HH:mm';

  @Input() version: VersionTag;

  public tooltipDelay: number = environment.tooltipDelay;
  public LoadingState = LoadingState;
  public state: LoadingState = LoadingState.UNKNOWN;
  public lastSyncTime: number;

  constructor(private offlinePersistenceService: OfflinePersistenceService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.offlinePersistenceService.isAvailable()) {
      this.offlinePersistenceService
        .getLastSyncTime(this.version.fragmentId, this.version.versionId)
        .then((time: number) => {
          this.lastSyncTime = time;
          this.state = time ? LoadingState.DOWNLOADED : LoadingState.NOT_DOWNLOADED;
          this.cdr.markForCheck();
        });
    } else {
      this.state = LoadingState.NOT_AVAILABLE;
    }
  }

  public download(): void {
    const previousState: LoadingState = this.state;
    this.offlinePersistenceService
      .downloadAndPersist(this.version)
      .then((time: number) => {
        this.state = LoadingState.DOWNLOADED;
        this.lastSyncTime = time;
      })
      .catch((err) => {
        this.state = previousState;
        Logger.error('offline-error', `Failed to download version ${this.version.versionId} for offline viewing`, err);
      });
    this.state = LoadingState.DOWNLOADING;
  }
}
