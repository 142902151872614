import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {SpecifierInstructionCreatorComponent} from './specifier-instruction-creator.component';
import {SpecifierInstructionModalModule} from './specifier-instruction-modal/specifier-instruction-modal.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    AltAccessibilityModule,
    CarsErrorHandlingModule,
    SpecifierInstructionModalModule,
  ],
  declarations: [SpecifierInstructionCreatorComponent],
  exports: [SpecifierInstructionCreatorComponent],
})
export class SpecifierInstructionCreatorModule {}
