import {UUID} from 'app/utils/uuid';
import {Fragment, FragmentType} from '../types';

export class ReadonlyFragment extends Fragment {
  /**
   * Create an empty ReadonlyFragment.
   *
   * @returns   {ReadonlyFragment}  The new readonly fragment
   */
  public static empty(): ReadonlyFragment {
    return new ReadonlyFragment(null, '');
  }

  constructor(id: UUID, value: string = '') {
    super(id, FragmentType.READONLY, [], value);
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): ReadonlyFragment {
    return null;
  }
}
