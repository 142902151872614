import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OfflineUser} from 'app/offline/offline-document';
import {UserRepository} from 'app/offline/repository';
import {AuthenticationProvider, GlobalRole} from 'app/services/user/authentication-provider';
import {User} from 'app/user/user';
import {UUID} from 'app/utils/uuid';
import jwt_decode from 'jwt-decode';
import {BehaviorSubject, from, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfflineAuthenticationProvider extends AuthenticationProvider {
  private _currentUser: BehaviorSubject<User> = new BehaviorSubject(
    AuthenticationProvider.userFromkeycloakUser(jwt_decode(localStorage.getItem('kc-token')))
  );

  constructor(private userRepository: UserRepository) {
    super();

    console.log('Initialising OfflineAuthenticationProvider');
  }

  public isAuthenticated(): Observable<boolean> {
    return of(!!localStorage.getItem('kc-token'));
  }

  public augmentRequest(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return of(request);
  }

  public getCurrentUserObs(): Observable<User> {
    return this._currentUser.asObservable();
  }

  public getCurrentUser(): User {
    return this._currentUser.value;
  }

  public getCurrentGlobalRoles(): Observable<GlobalRole[]> {
    return of([]);
  }

  public accountManagement() {}

  public createAccountUrl(): string {
    return '';
  }

  public createLogoutUrl(): string {
    return '';
  }

  public isAdmin(): boolean {
    return false;
  }

  public userManagement(): string {
    return '';
  }

  /**
   * Determine if the current user has any of the specified roles.
   *
   * @param roles The roles to check.
   */
  public userInRoles(...roles: GlobalRole[]): boolean {
    // Needed? Check cache
    if (roles.includes(GlobalRole.OFFLINE_MODE)) {
      return true;
    }
    return false;
  }

  public getUser(id: UUID): Observable<User> {
    return from(this.userRepository.get(id.value).then((offlineUser: OfflineUser) => User.deserialise(offlineUser)));
  }

  public offline(): boolean {
    return true;
  }

  public getBaseUrl(): string {
    return null;
  }
}
