<h4>Document logo for export</h4>
<p>
  For guidance on creating or designing logos for the front cover, please
  <a href="https://help.futuredmrb.co.uk/manging-front-and-back-pages/" target="_blank">click here</a>.
</p>
<div class="logo-upload">
  <div *ngIf="!hasFigureFragment">
    <mat-hint>No logo uploaded.</mat-hint>
    <input hidden #uploadLogo type="file" accept="image/*" (change)="handleUpload($event)" />
    <button *ngIf="canEdit" mat-raised-button (click)="uploadLogo.click()">Click here to upload a logo</button>
  </div>
  <div *ngIf="hasFigureFragment">
    <cars-alt-image [imageFragment]="getFigureFragment()"></cars-alt-image>
    <button *ngIf="canEdit" mat-raised-button (click)="deleteLogo()">Delete Logo</button>
  </div>
</div>
