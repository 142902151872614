import {OverlayKeyboardDispatcher, OverlayRef} from '@angular/cdk/overlay';
import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, OnDestroy} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CarsOverlayKeyboardDispatcher extends OverlayKeyboardDispatcher implements OnDestroy {
  private isAttached: boolean;

  constructor(@Inject(DOCUMENT) _document: any) {
    super(_document);
  }

  ngOnDestroy() {
    this.detach();
  }

  /** Add a new overlay to the list of attached overlay refs. */
  add(overlayRef: OverlayRef): void {
    // Lazily start dispatcher once first overlay is added
    if (!this.isAttached) {
      document.body.addEventListener('keydown', this.keydownListener, true);
      this.isAttached = true;
    }

    this._attachedOverlays.push(overlayRef);
  }

  /** Remove an overlay from the list of attached overlay refs. */
  remove(overlayRef: OverlayRef): void {
    const index = this._attachedOverlays.indexOf(overlayRef);

    if (index > -1) {
      this._attachedOverlays.splice(index, 1);
    }

    // Remove the global listener once there are no more overlays.
    if (this._attachedOverlays.length === 0) {
      this.detach();
    }
  }

  /** Detaches the global keyboard event listener. */
  public detach() {
    if (this.isAttached) {
      document.body.removeEventListener('keydown', this.keydownListener, true);
      this.isAttached = false;
    }
  }

  /** Keyboard event listener that will be attached to the body. */
  private keydownListener = (event: KeyboardEvent) => {
    if (this._attachedOverlays.length) {
      // Dispatch keydown event to the correct overlay.
      this.selectOverlayFromEvent(event)._keydownEvents.next(event);
    }
  };

  /** Select the appropriate overlay from a keydown event. */
  private selectOverlayFromEvent(event: KeyboardEvent): OverlayRef {
    // Check if any overlays contain the event
    const targetedOverlay = this._attachedOverlays.find((overlay) => {
      return overlay.overlayElement === event.target || overlay.overlayElement.contains(event.target as HTMLElement);
    });

    // Use the overlay if it exists, otherwise choose the most recently attached one
    return (targetedOverlay || this._attachedOverlays[this._attachedOverlays.length - 1]) as OverlayRef;
  }
}
