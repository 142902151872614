import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {ClauseFragment} from 'app/fragment/types';
import {ValidationError, ValidationSeverity} from 'app/validation/validation-rule';
import {ValidationService} from 'app/validation/validation.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
})
export class ValidationErrorsComponent implements OnDestroy, OnChanges {
  @Input() public clause: ClauseFragment;

  public validationErrors: ValidationError[] = [];
  public errors: ValidationError[] = [];
  public warnings: ValidationError[] = [];
  public infos: ValidationError[] = [];

  private _clauseSubscription: Subscription = null;

  constructor(private validationService: ValidationService) {}

  /**
   * @inheritdoc
   */
  ngOnDestroy(): void {
    if (this._clauseSubscription) {
      this._clauseSubscription.unsubscribe();
      this._clauseSubscription = null;
    }
  }

  /**
   * @inheritdoc
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clause) {
      // Clear current subscriptions
      if (this._clauseSubscription) {
        this._clauseSubscription.unsubscribe();
        this._clauseSubscription = null;
      }

      // Subscribe to new clause
      const clause: ClauseFragment = changes.clause.currentValue as ClauseFragment;
      if (clause) {
        // Clear previous information before subscription, as the new information can be delayed
        this.onClauseValidated([]);

        this._clauseSubscription = this.validationService.onValidation(clause.id, (errors) =>
          this.onClauseValidated(errors)
        );
      } else {
        this.onClauseValidated([]); // No clause so clear validations
      }
    }
  }

  /**
   * Tracking method used to ensure that children 'validation-error' components are not
   * needlessly recreated, and to ensure that if description information is open within
   * the 'validation-error' that this is not closed as the user types.
   * @param index The index of the item being tracked
   * @param error The validation error
   */
  public trackValidation(index: number, error: ValidationError): string {
    return [error.severity, error.message, error.reference, error.title].join();
  }

  /**
   * Event handler to recieve validation errors
   * @param errors Validation errors
   */
  private onClauseValidated(errors: ValidationError[]): void {
    this.validationErrors = errors;
    this.splitBySeverity();
  }

  /**
   * Helper method to split the errors
   */
  private splitBySeverity() {
    this.errors = [];
    this.warnings = [];
    this.infos = [];
    this.validationErrors.forEach((error) => {
      switch (error.severity) {
        case ValidationSeverity.ERROR: {
          this.errors.push(error);
          break;
        }
        case ValidationSeverity.WARNING: {
          this.warnings.push(error);
          break;
        }
        case ValidationSeverity.INFO: {
          this.infos.push(error);
          break;
        }
      }
    });
  }
}
