<h1 mat-dialog-title>Manually manage column widths</h1>
<p class="guidance-text">
  You can use this feature to manually manage the widths of columns within your table. Please note that the tables below
  are for illustrative purposes only. Please use the document preview functionality to ensure documents appear as you
  expect them to.
  <a href="https://help.futuredmrb.co.uk/managing-table-column-widths/" target="_blank"
    >Click here for guidance on this feature.</a
  >
</p>

<div class="content">
  <div class="table-title">
    <span class="bold">Table: {{ title }}</span>
  </div>
  <span class="center h3 bold">Current column % widths (readonly):</span>

  <table>
    <tbody>
      <tr>
        <ng-container *ngFor="let currentWidth of currentWidths">
          <td [style.width]="currentWidth * 100 + '%'" [height]="'auto'" [class.normal-borders]="true">
            {{ formatWidthToPercentage(currentWidth) }}
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
  <br />
  <span class="center h3 bold">New column % widths (editable):</span>

  <div class="new-table-widths">
    <table>
      <tbody>
        <tr>
          <ng-container *ngFor="let newWidth of newWidths; let i = index">
            <td
              [style.width]="generateTableWidths(newWidth, i)"
              [style.min-width]="'30px'"
              [height]="'auto'"
              [class.normal-borders]="true"
              (mouseup)="$event.stopPropagation()"
              (mousedown)="$event.stopPropagation()"
            >
              <input
                matInput
                type="number"
                min="1"
                max="100"
                [placeholder]="formatWidthToPercentage(newWidth.value, 1)"
                arial-label="column width"
                [value]="newWidth.placeholder ? '' : newWidth.value"
                (input)="onColumnWidthInputChange($event.target.value, i)"
                [style.width]="'80%'"
                [style.max-width]="'100px'"
              />
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <span class="center">Total Width: {{ totalWidth }}%</span>
  </div>

  <div class="auto-fill">
    <mat-slide-toggle [checked]="autoFillRemainingWidth" (change)="onAutoFillChange($event.checked)">
      Auto-calculate unspecified column % widths
    </mat-slide-toggle>
    <p>
      With this feature on, the system will evenly distribute the remaining % width between all columns without a
      specified value, down to a minimum of 2% per column.<br />
      When this feature is off, you must specify a column % width for every column, and this must total 100% (total is
      provided below the table).
    </p>
  </div>

  <div class="mat-error" *ngIf="!canSaveWidths()">
    <ul>
      <li *ngIf="!checkAllColumnsHaveAtLeastMinimumWidth()">
        <mat-error>Not all column widths are greater than or equal to 2%</mat-error>
      </li>
      <li *ngIf="!checkSumWidthsEquals100()">
        <mat-error
          >Columns do not sum to 100%
          {{ autoFillRemainingWidth ? '(including the auto-filled column widths)' : '' }}</mat-error
        >
      </li>
      <li *ngIf="!checkAllColumnsSpecifiedIfRequired()">
        <mat-error>Not all column % widths have been specified</mat-error>
      </li>
    </ul>
  </div>
</div>

<div mat-dialog-actions>
  <div class="flex-grow"></div>
  <button mat-button color="primary" (click)="cancel()" aria-label="Cancel updating column widths">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!canSaveWidths()"
    (click)="confirm()"
    aria-label="Update column widths"
  >
    Update column widths
  </button>
</div>
