import {UUID} from 'app/utils/uuid';
import {AggregateType} from './version-discussions-aggregate';

export type SectionDiscussionsAggregate = SectionDiscussionsAggregateAll | SectionDiscussionsAggregateByUser;
export const SectionDiscussionsAggregate = {
  deserialise(json: any): SectionDiscussionsAggregate {
    return AggregateType[json.aggregateType] === AggregateType.ALL
      ? SectionDiscussionsAggregateAll.deserialise(json)
      : SectionDiscussionsAggregateByUser.deserialise(json);
  },
};

abstract class SectionDiscussionsAggregateBase {
  public documentId: UUID;
  public sectionId: UUID;
  public sectionTitle: string;
  public sectionIndex: string;
  public discussions: number;
  public resolvedDiscussions: number;

  public abstract type: AggregateType;

  public allDiscussionsResolved(): boolean {
    return this.discussions === this.resolvedDiscussions;
  }

  public isAll(): this is SectionDiscussionsAggregateAll {
    return this.type === AggregateType.ALL;
  }

  public isByUser(): this is SectionDiscussionsAggregateByUser {
    return this.type === AggregateType.BY_USER;
  }
}

export class SectionDiscussionsAggregateAll extends SectionDiscussionsAggregateBase {
  public type: AggregateType.ALL = AggregateType.ALL;

  public static deserialise(json: any): SectionDiscussionsAggregateAll {
    const aggregate: SectionDiscussionsAggregateAll = new SectionDiscussionsAggregateAll();
    aggregate.documentId = UUID.orNull(json.documentId);
    aggregate.sectionId = UUID.orNull(json.sectionId);
    aggregate.sectionTitle = json.sectionTitle;
    aggregate.sectionIndex = json.sectionIndex;
    aggregate.discussions = json.discussions;
    aggregate.resolvedDiscussions = json.resolvedDiscussions;
    return aggregate;
  }
}

export class SectionDiscussionsAggregateByUser extends SectionDiscussionsAggregateBase {
  public discussionsCommentedOn: number;
  public resolvedDiscussionsCommentedOn: number;
  public type: AggregateType.BY_USER = AggregateType.BY_USER;

  public static deserialise(json: any): SectionDiscussionsAggregateByUser {
    const aggregate: SectionDiscussionsAggregateByUser = new SectionDiscussionsAggregateByUser();
    aggregate.documentId = UUID.orNull(json.documentId);
    aggregate.sectionId = UUID.orNull(json.sectionId);
    aggregate.sectionTitle = json.sectionTitle;
    aggregate.sectionIndex = json.sectionIndex;
    aggregate.discussions = json.discussions;
    aggregate.resolvedDiscussions = json.resolvedDiscussions;
    aggregate.discussionsCommentedOn = json.discussionsCommentedOn;
    aggregate.resolvedDiscussionsCommentedOn = json.resolvedDiscussionsCommentedOn;
    return aggregate;
  }

  public allCommentedOnDiscussionsResolved(): boolean {
    return this.discussionsCommentedOn === this.resolvedDiscussionsCommentedOn;
  }
}
