import {UUID} from 'app/utils/uuid';

export enum NotificationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum NotificationType {
  EXPORT = 'EXPORT',
  VERSION = 'VERSION',
  EXPORT_WARNING = 'EXPORT_WARNING',
  VIRUS_SCAN = 'VIRUS_SCAN',
  NAA = 'NAA',
}

export abstract class CarsNotification {
  public static readonly CHILD_TYPES: NotificationType[] = [NotificationType.EXPORT_WARNING];
  public abstract readonly type: NotificationType;
  public childNotifications: CarsNotification[] = [];

  public static deserialise(json: any): CarsNotification {
    if (!json) {
      return null;
    }
    const notificationId: UUID = UUID.orNull(json.notificationId);
    const type: NotificationType = NotificationType[json.type] as NotificationType;
    const status: NotificationStatus = NotificationStatus[json.status] as NotificationStatus;
    const title: string = json.title;
    const content: string = json.content;
    const createdAt: number = new Date(json.createdAt).getTime();

    switch (type) {
      case NotificationType.EXPORT: {
        const exception: string = json.exception;
        return new ExportNotification(
          notificationId,
          title,
          content,
          status,
          createdAt,
          exception,
          JSON.parse(json.data)
        );
      }
      case NotificationType.VERSION: {
        const path: string = json.path;
        return new VersionNotification(notificationId, title, content, status, createdAt, path);
      }
      case NotificationType.EXPORT_WARNING: {
        return new WarningNotification(notificationId, title, content, status, createdAt);
      }
      case NotificationType.VIRUS_SCAN: {
        return new VirusScanNotification(notificationId, title, content, status, createdAt);
      }
      case NotificationType.NAA: {
        return new NAANotification(notificationId, title, content, status, createdAt);
      }
    }
  }

  constructor(
    public notificationId: UUID,
    public title: string,
    public content: string,
    public status: NotificationStatus,
    public createdAt: number = new Date().valueOf(),
    public seen = false
  ) {
    this.notificationId = !!notificationId ? notificationId : UUID.random();
  }

  public isWarning(): boolean {
    return this.type === NotificationType.EXPORT_WARNING;
  }

  public getIconName(): string {
    switch (this.status) {
      case NotificationStatus.IN_PROGRESS: {
        return 'history';
      }
      case NotificationStatus.COMPLETED: {
        if (this.type === NotificationType.EXPORT_WARNING) {
          return 'warning';
        }
        return 'check_circle';
      }
      case NotificationStatus.FAILED: {
        return 'error';
      }
    }
  }
}

export class VersionNotification extends CarsNotification {
  public readonly type: NotificationType = NotificationType.VERSION;

  constructor(
    notificationId: UUID,
    title: string,
    content: string,
    status: NotificationStatus,
    createdAt: number = new Date().valueOf(),
    public path: string = null
  ) {
    super(notificationId, title, content, status, createdAt);
  }
}

export class ExportNotification extends CarsNotification {
  public readonly type: NotificationType = NotificationType.EXPORT;

  constructor(
    notificationId: UUID,
    title: string,
    content: string,
    status: NotificationStatus,
    createdAt: number = new Date().valueOf(),
    public exception: string = void 0,
    public data: JSON = JSON.parse('{}')
  ) {
    super(notificationId, title, content, status, createdAt);
  }
}

export class WarningNotification extends CarsNotification {
  public readonly type: NotificationType = NotificationType.EXPORT_WARNING;
}

export class VirusScanNotification extends CarsNotification {
  public readonly type: NotificationType = NotificationType.VIRUS_SCAN;
}

export class NAANotification extends CarsNotification {
  public readonly type: NotificationType = NotificationType.NAA;
}
