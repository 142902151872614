<ol
  *ngIf="content.ordered"
  class="list-unindented"
  [class.numerical]="content.primaryListIndexingType === 'NUMERICAL'"
  [class.alpha]="content.primaryListIndexingType === 'LOWER_ALPHA'"
  [style.counter-reset]="'root ' + startOffset()"
>
  <ng-container
    *ngIf="showMenuButtons && !readOnly && (CarsAction.AUTHOR_DOCUMENT | carsEnabled : content.documentId | async)"
  >
    <button
      mat-icon-button
      class="open-list-options-button"
      [matMenuTriggerFor]="menu"
      contenteditable="false"
      [carsShowOnFocus]="content"
      [attr.blurOption]="BlurOption.PREVENT_BLUR"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu" class="list-hovertip-menu" overlapTrigger="true" xPosition="before">
      <cars-list-hovertip [fragment]="content"></cars-list-hovertip>
    </mat-menu>
  </ng-container>

  <ng-container *ngFor="let item of content?.children">
    <ng-container *ngIf="!item.indented">
      <li [carsListItemFragment]="item" [style.visibility]="item.isDummy ? 'hidden' : 'visible'">
        <ng-template [carsFragmentHost]="item.children" [readOnly]="readOnly"></ng-template>
      </li>
    </ng-container>
    <ng-container *ngIf="item.indented && isFirstIndentedItem(item)">
      <ol class="list-indented" [class.alpha]="content.secondaryListIndexingType === 'LOWER_ALPHA'">
        <ng-container *ngFor="let indentedItem of iterateOverIndented(item)">
          <li [carsListItemFragment]="indentedItem" [style.visibility]="item.isDummy ? 'hidden' : 'visible'">
            <ng-template [carsFragmentHost]="indentedItem.children" [readOnly]="readOnly"></ng-template>
          </li>
        </ng-container>
      </ol>
    </ng-container>
  </ng-container>
</ol>

<ul *ngIf="!content.ordered">
  <ng-container *ngFor="let item of content?.children">
    <ng-container *ngIf="!item.indented">
      <li [carsListItemFragment]="item">
        <ng-template [carsFragmentHost]="item.children" [readOnly]="readOnly"></ng-template>
      </li>
    </ng-container>
    <ng-container *ngIf="item.indented && iterateOverIndented(item)">
      <ul>
        <li *ngFor="let indentedItem of iterateOverIndented(item)" [carsListItemFragment]="indentedItem">
          <ng-template [carsFragmentHost]="indentedItem.children" [readOnly]="readOnly"></ng-template>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</ul>
