import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Suite} from 'app/fragment/suite';
import {environment} from 'environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BaseService} from './base.service';

export interface Template {
  versionId: string;
  createdBy: string;
  suite: Suite;
  name: string;
  forNaa: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TemplateService extends BaseService {
  private readonly _templateEndpoint = `${environment.apiHost}/template/`;

  constructor(private _httpClient: HttpClient, protected _snarBack: MatSnackBar) {
    super(_snarBack);
  }

  /**
   * Fetches a list of the available document templates.
   *
   * @returns {Observable<Template[]>}   An observable stream, resolving to an array of document templates
   */
  public getTemplates(): Observable<Template[]> {
    const endpoint: string = this._templateEndpoint + 'all';

    return this._httpClient.get<Template[]>(endpoint).pipe(
      catchError((error: HttpErrorResponse) => {
        this._handleError(error, 'Failed to get templates', 'document-templates-error');
        return throwError(error);
      })
    );
  }
}
