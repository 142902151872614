import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {ToolbarModule} from 'app/editor-toolbar/toolbar/toolbar.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {ClauseTypeSelectorModule} from './clause-type-selector/clause-type-selector.module';
import {EditorToolbarComponent} from './editor-toolbar.component';
import {ImageUploadModule} from './image-upload/image-upload.module';
import {ReorderClausesModule} from './reorder-clauses/reorder-clauses.module';
import {RichTextModule} from './rich-text/rich-text.module';
import {SpecifierInstructionCreatorModule} from './specifier-instruction-creator/specifier-instruction-creator.module';
import {StandardFormatGroupCreatorModule} from './standard-format-group-creator/standard-format-group-creator.module';
import {SuggestChangesModule} from './suggest-changes/suggest-changes.module';
import {TableCreatorModule} from './table-creator/table-creator.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    AltAccessibilityModule,
    ToolbarModule,
    RichTextModule,
    TableCreatorModule,
    ImageUploadModule,
    SuggestChangesModule,
    ReorderClausesModule,
    ClauseTypeSelectorModule,
    CarsPermissionPipesModule,
    StandardFormatGroupCreatorModule,
    SpecifierInstructionCreatorModule,
  ],
  declarations: [EditorToolbarComponent],
  exports: [EditorToolbarComponent],
})
export class EditorToolbarModule {}
