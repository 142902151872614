import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FragmentService, SavingEvent} from 'app/services/fragment.service';
import {CurrentView} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-saving-chip',
  templateUrl: './saving-chip.component.html',
  styleUrls: ['./saving-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavingChipComponent implements OnInit, OnDestroy {
  // Don't display on document overview page:
  public static readonly ALLOWED_LOCATIONS: CurrentLocation[] = [
    CurrentLocation.PAD,
    CurrentLocation.BACKGROUND,
    CurrentLocation.CHANGELOG,
    CurrentLocation.DOCUMENT_OVERVIEW,
  ];

  public static readonly SAVING_CHIP_MESSAGE: string = 'Saving...';
  public static readonly DELAYED_SAVING_CHIP_MESSAGE: string =
    'Saving is taking longer than expected, please wait before closing CARS...';

  public static readonly DELAY_MS: number = 30000;
  public static readonly TIMEOUT_MS: number = 500;

  @Input() public useViewService: boolean = true;
  @Input() public currentView: CurrentView = null;

  public inAllowedLocation: boolean = true;

  public saving: boolean;
  public errors: boolean;
  public delayed: boolean = false;

  private savingTimeout: number = null;
  private messageTimeout: number = null;

  private _subscriptions: Subscription[] = [];

  constructor(
    private fragmentService: FragmentService,
    private viewService: ViewService,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this.fragmentService.onSaving((savingEvent: SavingEvent) => {
        this.setSaving(savingEvent);
        this.cd.markForCheck();
      }),
      this.viewService.onCurrentViewChange((currentView: CurrentView) => {
        if (this.useViewService) {
          this.currentView = currentView;
          this.cd.markForCheck();
        }
      }),
      this.viewService.onLocationChange((location: CurrentLocation) => {
        this.inAllowedLocation = SavingChipComponent.ALLOWED_LOCATIONS.includes(location);
        this.cd.markForCheck();
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Sets this.saving to false if the fragment service hasn't been saving for 500ms.
   */
  private setSaving(savingEvent: SavingEvent): void {
    this.errors = savingEvent.pendingErrors;
    if (savingEvent.saving) {
      clearTimeout(this.savingTimeout);
      clearTimeout(this.messageTimeout);
      this.messageTimeout = window.setTimeout(() => {
        this.delayed = true;
        this.cd.markForCheck();
      }, SavingChipComponent.DELAY_MS);
      this.saving = true;
    } else {
      this.savingTimeout = window.setTimeout(() => {
        this.saving = false;
        clearTimeout(this.messageTimeout);
        this.delayed = false;
        this.cd.markForCheck();
      }, SavingChipComponent.TIMEOUT_MS);
    }
  }

  public getSavingMessage(): string {
    if (this.delayed && !this.errors) {
      return SavingChipComponent.DELAYED_SAVING_CHIP_MESSAGE;
    } else {
      return SavingChipComponent.SAVING_CHIP_MESSAGE;
    }
  }

  public shouldDisplay(): boolean {
    return this.saving && this.inAllowedLocation && !this.currentView.isChangelogAux();
  }
}
