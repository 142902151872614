import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {EquationModule} from 'app/widgets/equation/equation.module';
import {UnitListComponent} from './unit-list.component';

@NgModule({
  imports: [CommonModule, MatTableModule, MatPaginatorModule, EquationModule],
  declarations: [UnitListComponent],
  exports: [UnitListComponent],
})
export class UnitListModule {}
