import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../services/notification.service';
import {CarsNotification, NotificationStatus} from './notification/cars-notification';

@Component({
  selector: 'cars-notification-area',
  templateUrl: './notification-area.component.html',
  styleUrls: ['./notification-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationAreaComponent implements OnInit, OnDestroy {
  @Output() numberUnseenNotifications: EventEmitter<number> = new EventEmitter<number>();

  public notificationList: CarsNotification[] = [];

  public hasItemInProgress: boolean = false;

  private _subscriptions: Subscription[] = [];

  constructor(private _notificationService: NotificationService, private _cdr: ChangeDetectorRef) {}

  public ngOnInit() {
    this._subscriptions.push(
      this._notificationService.onNotificationList().subscribe(this._handleNotificationList.bind(this))
    );
  }

  public ngOnDestroy() {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  public markAllAsSeen(): void {
    this._notificationService.markAllNotificationsAsSeen();
  }

  public clearAll(): void {
    this._notificationService.clearNotificationList();
  }

  private _handleNotificationList(list: CarsNotification[]): void {
    this.notificationList = list;
    this.hasItemInProgress = this.notificationList.some((n) => n.status === NotificationStatus.IN_PROGRESS);
    this.numberUnseenNotifications.emit(this.notificationList.filter((n: CarsNotification) => !n.seen).length);
    this._cdr.markForCheck();
  }
}
