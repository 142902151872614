import {Administration} from 'app/documents/administrations';
import {UUID} from 'app/utils/uuid';
import {ClauseFragment, Fragment, FragmentType} from '../types';
import {ClauseGroupType} from './clause-group-type';
import {StandardFormatType} from './standard-format-type';

export class ClauseGroupFragment extends Fragment {
  public static readonly DEFAULT_NATIONALLY_DETERMINED_REQUIREMENT_TEXT: string =
    'No nationally determined requirement is provided.';

  public clauseGroupType: ClauseGroupType;
  public standardFormatType: StandardFormatType;
  public administration: Administration;

  /**
   * Create an empty ClauseGroupFragment with the given type, and no children.
   *
   * @param clauseGroupType     {ClauseGroupType}       The clause group type
   * @param standardFormatType  {StandardFormatType}    The standard format group type
   * @returns                   {ClauseGroupFragment}   The new clause group fragment
   */
  public static empty(clauseGroupType: ClauseGroupType, standardFormatType: StandardFormatType): ClauseGroupFragment {
    return new ClauseGroupFragment(null, [], clauseGroupType, standardFormatType);
  }

  public get parent(): Fragment {
    return super.parent as Fragment; // specialise the parent type
  }

  public set parent(value: Fragment) {
    super.parent = value;
  }

  constructor(
    id: UUID,
    children: Fragment[],
    clauseGroupType: ClauseGroupType,
    standardFormatType: StandardFormatType = null,
    administration: Administration = null
  ) {
    super(id, FragmentType.CLAUSE_GROUP, children);

    this.clauseGroupType = clauseGroupType;
    this.standardFormatType = standardFormatType;
    this.administration = administration;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): ClauseGroupFragment {
    return null;
  }

  /**
   * @override
   */
  public length(): number {
    return this.children.reduce((sum: number, child: Fragment) => (sum += child.length()), 0);
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();
    json.standardFormatType = StandardFormatType[json.standardFormatType];
    return json;
  }

  /**
   * Recursively get the clauses in this clause group and any descendant clause groups.
   */
  public getClauses(): ClauseFragment[] {
    return this.children.reduce((clauses: ClauseFragment[], child: Fragment) => {
      if (child.is(FragmentType.CLAUSE)) {
        clauses.push(child as ClauseFragment);
      } else if (child.is(FragmentType.CLAUSE_GROUP)) {
        clauses.push(...(child as ClauseGroupFragment).getClauses());
      }
      return clauses;
    }, []);
  }
}
