import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TdifContent, TdifEquation, TdifTextProperties, TdifVpos} from '../tdif-types';

@Component({
  selector: 'cars-tdif-content',
  templateUrl: './tdif-content.component.html',
  styleUrls: ['./tdif-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifContentComponent {
  @Input() content: TdifContent[];
  constructor() {}

  inlineElements: string[] = ['text', 'reference', 'equation'];

  public displayInline(tdifContent: TdifContent): boolean {
    return this.inlineElements.includes(tdifContent.getIdentifier()) && this._inlineEquation(tdifContent);
  }

  private _inlineEquation(tdifContent: TdifContent): boolean {
    return !(tdifContent.getIdentifier() === 'equation' && (tdifContent as TdifEquation).index);
  }

  public getTextStyling(textProperty: TdifTextProperties): string {
    switch (textProperty?.vpos) {
      case TdifVpos.SUBSCRIPT:
        return 'subscript';
      case TdifVpos.SUPERSCRIPT:
        return 'superscript';
      default:
        return 'normal';
    }
  }
}
