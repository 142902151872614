import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {Fragment} from 'app/fragment/types';
import {StringUtils} from 'app/utils/string-utils';
import {UUID} from 'app/utils/uuid';
import {VERSION} from 'environments/version';

export interface ReferenceProperties {
  globalReferenceId: string;
  referenceType: string;
  yearOfIssue?: string;
}

export class ClipboardJson {
  private static LOCAL_STORAGE_KEY: string = 'clipboard-json';

  private _plainHash: number;
  private _gitHash: string;
  private _subtree: object[];
  private _referenceRecord: Record<string, ReferenceProperties>;

  public static createWithHashes(plainTextOrHash: number | string, gitHash: string = VERSION.sha): ClipboardJson {
    const clipboardJson: ClipboardJson = new ClipboardJson();
    clipboardJson._setPlainHash(plainTextOrHash);
    clipboardJson._gitHash = gitHash;
    clipboardJson._subtree = [];
    clipboardJson._referenceRecord = {};
    return clipboardJson;
  }

  public static getCurrent(): ClipboardJson {
    const json: object = JSON.parse(localStorage.getItem(ClipboardJson.LOCAL_STORAGE_KEY));
    if (!json) {
      return null;
    }

    const clipboardJson: ClipboardJson = ClipboardJson.createWithHashes(json['plainHash'], json['gitHash']);
    clipboardJson._subtree = json['fragments'];
    clipboardJson._referenceRecord = json['referenceRecord'];

    return clipboardJson;
  }

  private constructor() {}

  public getFragments(): Fragment[] {
    this._subtree.forEach((obj: object) => this._generateNewIds(obj));
    return this._subtree.map(FragmentMapper.deserialise);
  }

  public getReferenceRecord(): Record<string, ReferenceProperties> {
    return this._referenceRecord;
  }

  public setSubtreeAndReferenceRecord(
    flatTree: object[],
    jsonTree: object[],
    referenceRecord: Record<string, ReferenceProperties>
  ): void {
    flatTree.forEach(this._clearIds);
    jsonTree.forEach((frag: object) => (frag['weight'] = null));
    this._subtree = jsonTree;
    this._referenceRecord = referenceRecord;
  }

  public save(): void {
    localStorage.setItem(ClipboardJson.LOCAL_STORAGE_KEY, this._toString());
  }

  public compareHashes(plainText: string): boolean {
    return this._gitHash === VERSION.sha && this._plainHash === StringUtils.hash(this._cleanPlainText(plainText));
  }

  private _setPlainHash(plainTextOrHash: string | number): void {
    if (typeof plainTextOrHash === 'string') {
      this._plainHash = StringUtils.hash(this._cleanPlainText(plainTextOrHash));
    } else {
      this._plainHash = plainTextOrHash;
    }
  }

  private _toString(): string {
    return JSON.stringify({
      gitHash: this._gitHash,
      plainHash: this._plainHash,
      fragments: this._subtree,
      referenceRecord: this._referenceRecord,
    });
  }

  private _generateNewIds(obj: object): void {
    if (obj['children'] && obj['children'].length > 0) {
      obj['children'].forEach((o: object) => this._generateNewIds(o));
    }
    obj['id'] = UUID.random().value;
  }

  private _clearIds(serialised: object): void {
    serialised['id'] = null;
    serialised['parentId'] = null;
    serialised['documentId'] = null;
    serialised['sectionId'] = null;
  }

  private _cleanPlainText(plainText: string): string {
    return plainText.replace(/\r|\n/g, '');
  }
}
