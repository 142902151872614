import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[carsAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.focus();
    this.cdr.detectChanges();
  }
}
