import {Component, Input} from '@angular/core';
import {StandardFormatType} from 'app/fragment/types/standard-format-type';
import {StandardFormatGroupConfig} from 'app/services/clause-group.service';
import {StandardFormatGroupModalService} from '../standard-format-group-modal.service';

@Component({
  selector: 'cars-standard-format-group-list-options',
  templateUrl: './standard-format-group-list-options.component.html',
  styleUrls: ['./standard-format-group-list-options.component.scss'],
})
export class StandardFormatGroupListOptionsComponent {
  @Input() public set standardFormatGroupConfig(configOptions: StandardFormatGroupConfig[]) {
    this.standardFormatTypes = configOptions.map((config) => config.fragmentToCreate.standardFormatType);
  }

  public standardFormatTypes: string[];

  constructor(private _modalService: StandardFormatGroupModalService) {}

  public selectOption(type: string): void {
    const standardFormatType: StandardFormatType = StandardFormatType[type];
    this._modalService.updateSelectedType(standardFormatType);
  }
}
