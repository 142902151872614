<div class="table-container" [ngClass]="{insertButtons: enableInsertButtons}">
  <mat-table #table [dataSource]="referenceList">
    <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

    <!--Reference column -->
    <ng-container matColumnDef="all">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" [class.withdrawn]="row.withdrawn && !row.deleted">
        <mat-icon
          *ngIf="row.withdrawn && !row.deleted"
          matTooltip="This is a withdrawn reference."
          matTooltipPosition="left"
        >
          error_outline</mat-icon
        >
        <div [class.deleted]="row.deleted" class="reference-box">
          <div *ngIf="row.reference || getReleaseDate(row)" class="row-reference">
            <span> {{ row.reference }}{{ getReleaseDate(row) }} </span>
          </div>
          <div *ngIf="row.title" class="row-title">
            <span>'{{ row.title }}</span
            >'
          </div>
          <div *ngIf="row.author" class="row-margin">{{ row.author }}</div>
          <div *ngIf="row.publisher" class="row-margin">
            {{ row.publisher }}
          </div>
          <div *ngIf="row.searchSuperseding">
            <span>Superseded by: </span>
            <ul>
              <li *ngFor="let supersedingDocument of row.supersededBy">
                {{ supersedingDocument.title }}
              </li>
            </ul>
          </div>
          <div *ngIf="row.deleted" class="warning">
            <mat-icon>error_outline</mat-icon>
            <strong
              >This reference has been deleted from the reference library by TAGG, please replace with the correct
              reference.</strong
            >
          </div>

          <div *ngIf="CarsAction.AUTHOR_DOCUMENT | carsEnabled: documentId | async">
            <div *ngIf="!enableInsertButtons" class="toggle-buttons">
              <button
                mat-stroked-button
                (click)="toggleReferenceType(row, $event)"
                (keyup.enter)="toggleReferenceType(row, $event)"
                color="primary"
                class="adding-buttons"
                matTooltip="Make {{ getToggleText(row) }}"
                [matTooltipShowDelay]="tooltipDelay"
                attr.aria-label="Make {{ getToggleText(row) }}"
              >
                Make {{ getToggleText(row) }}
              </button>
            </div>
          </div>

          <div *ngIf="currentRefId?.equals(row.globalReferenceId) && enableInsertButtons">
            <mat-form-field>
              <input
                carsAutofocus
                matInput
                class="text-area"
                [(ngModel)]="yearOrEdition"
                placeholder="Year/Edition"
                matTooltip="Year/edition (e.g. 2011 or 4th Edition)"
                matTooltipShowDelay="tooltipDelay"
              />
            </mat-form-field>
            <button
              mat-stroked-button
              (click)="onInsertClicked('normative', $event)"
              (keyup.enter)="onInsertClicked('normative', $event)"
              color="primary"
              class="adding-buttons"
              matTooltip="Add as normative reference"
              [matTooltipShowDelay]="tooltipDelay"
              aria-label="Add as normative reference"
            >
              Add as Normative
            </button>
            <button
              mat-stroked-button
              (click)="onInsertClicked('informative', $event)"
              (keyup.enter)="onInsertClicked('informative', $event)"
              color="primary"
              class="adding-buttons"
              matTooltip="Add as informative reference"
              [matTooltipShowDelay]="tooltipDelay"
              aria-label="Add as informative reference"
            >
              Add as Informative
            </button>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.selected-row]="row === row.globalReferenceId"
      (click)="selectReference(row)"
      (keyup.enter)="selectReference(row)"
      tabindex="0"
    ></mat-row>
  </mat-table>
  <div class="no-results" *ngIf="!referenceList || !resultsLength">
    <p *ngIf="!enableInsertButtons">Try adding one from the 'Reference Library'.</p>
  </div>
  <div class="no-results" *ngIf="referenceList && referenceList.length === 0 && filter && filter.length > 0">
    No references match your search terms
  </div>
  <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>
