import {Injectable} from '@angular/core';
import {DocumentRole} from 'app/documents/document-data';
import {DocumentFragment} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {GlobalRole} from 'app/services/user/authentication-provider';
import {RoleService} from 'app/services/user/role.service';
import {UUID} from 'app/utils/uuid';

@Injectable({
  providedIn: 'root',
})
export class DocumentLinkService {
  private static readonly ALLOWED_DOCUMENT_ROLES: Readonly<DocumentRole[]> = [
    DocumentRole.AUTHOR,
    DocumentRole.LEAD_AUTHOR,
    DocumentRole.OWNER,
    DocumentRole.PEER_REVIEWER,
    DocumentRole.REVIEWER,
  ];

  private static readonly GLOBAL_ADMIN_ROLES: Readonly<GlobalRole[]> = [GlobalRole.ADMIN, GlobalRole.DOCUMENT_ADMIN];

  // TODO: we probably need the documentService here. Not the FragmentService...?
  constructor(private fragmentService: FragmentService, private roleService: RoleService) {}

  public canUserViewDocumentLink(documentId: UUID): Promise<boolean> {
    if (this.roleService.isInGlobalRoles(...DocumentLinkService.GLOBAL_ADMIN_ROLES)) {
      return Promise.resolve(true);
    }

    return this.fragmentService
      .fetchLatest(documentId, {depth: 0})
      .then((document: DocumentFragment) =>
        this.roleService.isInDocumentRole(document, ...DocumentLinkService.ALLOWED_DOCUMENT_ROLES)
      );
  }
}
