import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {DocumentService} from 'app/services/document.service';
import {CurrentLocation, ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsResolverService implements Resolve<void> {
  constructor(private documentService: DocumentService, private viewService: ViewService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    this.viewService.setLocation(CurrentLocation.DOCUMENTS_TABLE);
    return this.documentService.setLocation(null);
  }
}
