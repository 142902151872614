/**
 * A class defining a ring buffer data structure.
 *
 * XXX: Read and write cursors will overflow after writing 9,007,199,254,740,991 times
 *      without rollback; this is ECMAScript's Number.MAX_SAFE_INTEGER).  I didn't think
 *      this was a big problem, and it made the implementation considerably easier.
 *
 * @tparam {T}   The type of elements in this buffer
 */
export class RingBuffer<T> {
  private _buffer: T[]; // Sized by constructor
  private _read: number; // Not wrapped
  private _write: number; // Not wrapped

  constructor(capacity: number) {
    this.resize(capacity);
  }

  /**
   * Returns the maximum capacity of this buffer without overwrites.
   *
   * @returns {number}   The max capacity
   */
  public get capacity(): number {
    return this._buffer.length;
  }

  /**
   * Returns the number of elements currently in the buffer.
   *
   * @returns {number}   The current element total
   */
  public get length(): number {
    return this.isEmpty() ? 0 : Math.min(this._write + 1, this.capacity);
  }

  /**
   * Returns true if this buffer is empty.
   *
   * @returns {boolean}   True if empty
   */
  public isEmpty(): boolean {
    return this._read === -1 && this._write === 0 && this._buffer[0] === null;
  }

  /**
   * Remove all items from the buffer and reset read and write pointers.
   */
  public reset(): void {
    this._initCursors();
  }

  /**
   * Return the item under the read pointer without popping it from the buffer.
   *
   * @param steps {number?}   The number of steps to peek behind the read cursor
   * @returns     {T}         The read item
   */
  public peek(steps: number = 0): T {
    steps %= this.capacity;
    const read: number = isNaN(steps) ? this._read : this._read - steps;
    const index: number = read % this.capacity;

    return index >= 0 ? this._buffer[index] : null;
  }

  /**
   * Push an item onto the buffer, possibly overwriting old items if we overflow.
   *
   * @param item {T}        The item to push
   * @returns    {number}   The new buffer length
   */
  public push(item: T): number {
    this._write = this._read + 1;
    this._read += 1;
    this._buffer[this._write % this.capacity] = item;

    return this.length;
  }

  /**
   * Returns true if this buffer can be reverted the queried number of steps.  Pass
   * negative steps to query roll-forwards.
   *
   * @param steps {number}    The number of steps to query
   * @returns     {boolean}   True if can revert
   */
  public isRevertible(steps: number): boolean {
    const delta: number = this._write - this._read;

    return steps >= 0 ? this._read >= steps - 1 && delta + steps < this.capacity : delta >= -steps && !this.isEmpty();
  }

  /**
   * Revert the buffer by the given number of steps, or throw if not possible.  Returns
   * the item under the new read pointer.  Pass negative steps to indicate roll-forwards.
   *
   * @param steps {number}       The number of steps to rollback
   * @returns     {T}            The popped item
   * @throws      {RangeError}   If cannot revert that many steps
   */
  public revert(steps: number): T {
    steps = Math.floor(steps);

    if (this.isRevertible(steps)) {
      this._read = Math.max(this._read - steps, -1);
      return this.peek();
    } else {
      const delta: number = this._write - this._read;
      const max: number = steps > 0 ? Math.min(this._read + 1, this.capacity - delta) : delta;
      const verb: string = steps > 0 ? 'rollback' : 'rollforward';

      throw new RangeError(`Cannot ${verb} by ${steps} steps, a maximum of ${max} is possible.`);
    }
  }

  /**
   * Resize the buffer to a different capacity.  Shrinking the buffer will destroy the
   * trailing elements.
   *
   * @param capacity {number}   The new capacity
   * @throws         {Error}    If capacity is not a positive integer
   */
  public resize(capacity: number): void {
    if (!this._buffer) {
      this._buffer = [];
    }

    const old: number = this.capacity;
    if (capacity > old) {
      this._buffer.push(...this._fill(capacity - old));
    } else if (capacity < old) {
      this._buffer.splice(capacity, old - capacity);
    }

    this.reset();
  }

  /**
   * Calls the callback on each non-null element of the buffer.
   *
   * @param callback  {Function}  The callback to call on each element
   */
  public forEach(callback: Function): void {
    this._buffer.forEach((t: T) => {
      if (t) {
        callback(t);
      }
    });
  }

  /**
   * Initialise the read and write cursors to the start of the buffer.
   */
  private _initCursors(): void {
    this._read = -1;
    this._write = 0;
    this._buffer[0] = null;
  }

  /**
   * Helper function to create an array of the given capacity filled with nulls.
   *
   * @param capacity {number}   The number of elements to create
   * @returns        {T[]}      The array of nulls
   */
  private _fill(capacity: number): T[] {
    return Array.apply(null, Array(capacity)).map(() => null);
  }
}
