import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StandardFormatGroupExampleComponent} from './standard-format-group-example.component';

@NgModule({
  imports: [CommonModule],
  declarations: [StandardFormatGroupExampleComponent],
  exports: [StandardFormatGroupExampleComponent],
})
export class StandardFormatGroupExampleModule {}
