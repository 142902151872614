import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {JsonClauseRow, JsonLinkRow, JsonRangeRow} from './link-table.service';

@Injectable({
  providedIn: 'root',
})
export class LinkTableProgressService {
  public percentSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  public init() {
    this.percentSubject = new BehaviorSubject(0);
  }

  public add(percent: number) {
    this.percentSubject.next(this.percentSubject.value + percent);
  }

  public set(percent: number) {
    this.percentSubject.next(percent);
  }

  public getLinkRowPercent(clauseRows: JsonClauseRow[]): number {
    let numberOfLinkRows: number = 0;
    clauseRows.forEach((clauseRow: JsonClauseRow) => {
      clauseRow.children.forEach((rangeRow: JsonRangeRow) => {
        rangeRow.children.forEach((linkRow: JsonLinkRow) => {
          if (linkRow.authoredRange && linkRow.authoredRange.documentId && linkRow.authoredRange.sectionId) {
            numberOfLinkRows++;
          }
        });
      });
    });
    const percentPerLinkRow: number = 80 / numberOfLinkRows;
    return percentPerLinkRow;
  }
}
