<ol
  *ngIf="ordered"
  class="list-unindented"
  [class.numerical]="primaryListIndexingType === 'NUMERICAL'"
  [class.alpha]="primaryListIndexingType === 'LOWER_ALPHA'"
  [style.counter-reset]="'root ' + startOffset()"
>
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="!item.indented">
      <li>
        <cars-tdif-content [content]="item.content"></cars-tdif-content>
      </li>
    </ng-container>
    <ng-container *ngIf="item.indented && isFirstIndentedItem(item)">
      <ol class="list-indented" [class.alpha]="true">
        <ng-container *ngFor="let indentedItem of iterateOverIndented(item)">
          <li>
            <cars-tdif-content [content]="indentedItem.content"></cars-tdif-content>
          </li>
        </ng-container>
      </ol>
    </ng-container>
  </ng-container>
</ol>

<ul *ngIf="!ordered">
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="!item.indented">
      <li>
        <cars-tdif-content [content]="item.content"></cars-tdif-content>
      </li>
    </ng-container>
    <ng-container *ngIf="item.indented && iterateOverIndented(item)">
      <ul>
        <li *ngFor="let indentedItem of iterateOverIndented(item)">
          <cars-tdif-content [content]="indentedItem.content"></cars-tdif-content>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</ul>
