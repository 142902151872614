/**
 * A class representing the table menu.
 *
 * @field merge {boolean}   True if the merge menu is open
 * @field row   {number}    The row the menu is placed at
 * @field col   {number}    The column the menu is placed at
 * @field left  {number}    The left pixel position of the menu
 * @field top   {number}    The top pixel position of the menu
 * @field width {number}    The pixel width of the menu
 */
export class TableMenu {
  public row: number;
  public col: number;
  public left: number;
  public top: number;
  public width: number;
  public selection: boolean[][] = [];
  public mergeMode: boolean = false;

  constructor(width: number) {
    this.width = width;
  }

  /**
   * Open the menu at a given row and column.
   *
   * @param row {number}   The row to open at
   * @param col {number}   The column to open at
   */
  public open(row: number, col: number): void {
    this.row = row;
    this.col = col;
  }

  /**
   * Close the menu.
   */
  public close(): void {
    this.row = this.col = void 0;
    this.selection = [];
    this.mergeMode = false;
  }

  /**
   * Query whether the menu is open.
   *
   * @returns {boolean}   True if open
   */
  public isOpen(): boolean {
    return typeof this.row === 'number' && typeof this.col === 'number';
  }
}

/**
 * A class representing a row/col point index pair within a table.
 *
 * @field row {number}   The row index
 * @field col {number}   The column index
 */
export class Point {
  /**
   * Calculate the rectangular area contained within two Points.
   *
   * @param first  {Point}   The first point
   * @param second {Point}   The second
   * @returns      {number}      The spanned area
   */
  public static areaBetween(first: Point, second: Point): number {
    const top: number = Math.min(first.row, second.row);
    const bottom: number = Math.max(first.row, second.row);
    const left: number = Math.min(first.col, second.col);
    const right: number = Math.max(first.col, second.col);

    return (right - left + 1) * (bottom - top + 1);
  }

  constructor(public row: number, public col: number) {
    this.row = row;
    this.col = col;
  }

  /**
   * Returns true if (row & col) of this point are equal to the given point.
   *
   * @param point {Point} Point to compare to
   */
  public equals(point: Point): boolean {
    return this.row === point.row && this.col === point.col;
  }

  /**
   * Returns true if a point is contained within the area defined by two other points.
   *
   * @param first  {Point}   The first region-defining point
   * @param second {Point}   The second region-defining point
   * @returns      {boolean} True if contained within the region
   */
  public containedWithin(first: Point, second: Point): boolean {
    const top: number = Math.min(first.row, second.row);
    const bottom: number = Math.max(first.row, second.row);
    const left: number = Math.min(first.col, second.col);
    const right: number = Math.max(first.col, second.col);

    return top <= this.row && this.row <= bottom && left <= this.col && this.col <= right;
  }
}
