import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from 'environments/environment';

interface CloseAction {
  title: string;
  response: any;
  tooltip: string;
  color?: string;
}

interface DialogInputData {
  title: string;
  message: string;
  subtext?: string;
  closeActions: CloseAction[];
}

@Component({
  selector: 'cars-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  public readonly tooltipDelay: number = environment.tooltipDelay;

  public title = 'An error has occurred';
  public message = 'An error has occurred';
  public subtext = '';
  public closeActions: CloseAction[] = [{title: 'Close', tooltip: 'Close', response: null}];

  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogInputData) {}

  public ngOnInit(): void {
    this.title = this.data.title;
    this.message = this.data.message;
    this.subtext = this.data.subtext;
    this.closeActions = this.data.closeActions;
  }
}
