<ng-container *ngIf="!dataSource?.data?.length; else userReviewStatesTable">
  <span class="empty-table">There are no reviewers assigned to this document.</span>
</ng-container>
<ng-template #userReviewStatesTable>
  <div class="table-wrapper">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      [matSortDisableClear]="true"
    >
      <ng-container matColumnDef="toggleExpanded">
        <th mat-header-cell *matHeaderCellDef>
          <button
            mat-icon-button
            (click)="toggleExpanded()"
            [matTooltip]="expanded ? 'Collapse user review status table' : 'Expand user review status table'"
            matTooltipPosition="left"
            [matTooltipShowDelay]="tooltipDelay"
            [attr.aria-label]="expanded ? 'Collapse user review status table' : 'Expand user review status table'"
          >
            <mat-icon>{{ expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row"></td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
        <td mat-cell *matCellDef="let row">
          {{ row.userReviewState.userName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reviewStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Review Status</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <mat-icon
              [style.color]="row.userReviewDisplayProperties.iconColor"
              [matTooltip]="row.userReviewDisplayProperties.statusText"
              matTooltipPosition="left"
              [matTooltipShowDelay]="tooltipDelay"
              >{{ row.userReviewDisplayProperties.icon }}</mat-icon
            >
            {{ row.userReviewDisplayProperties.statusText }}
            <span *ngIf="row.userReviewState.reviewState !== 'NOT_STARTED'"
              >({{ row.userReviewState.createdAt | date: dateFormat }})</span
            >
          </div>
        </td></ng-container
      >
      <ng-container matColumnDef="commentStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment Status</th>
        <td mat-cell *matCellDef="let row">
          <div class="cell-content">
            <cars-resolved-icon
              *ngIf="row.userReviewState.discussions"
              [total]="row.userReviewState.discussions"
              [resolved]="row.userReviewState.resolvedDiscussions"
              [type]="'DISCUSSION'"
              [tooltipPosition]="'left'"
            ></cars-resolved-icon>
            {{ row.commentString }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.hidden]="!expanded"></tr>
    </table>
  </div>
</ng-template>
