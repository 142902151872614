import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {CacheManager} from 'app/fragment/cache-manager';
import {Suite} from 'app/fragment/suite';
import {ClauseFragment, ClauseType, Fragment, FragmentType, SectionFragment, SectionType} from 'app/fragment/types';
import {LockService} from 'app/services/lock.service';
import {SectionService} from 'app/services/section.service';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {ClauseTypeConfiguration, ConfigurationService} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {Callback, Dictionary} from '../utils/typedefs';
import {CurrentView} from '../view/current-view';
import {ViewService} from '../view/view.service';
import {FragmentService} from './fragment.service';

/**
 * A map from SectionType to a map from old ClauseType to the new ClauseType that the clause would
 * have in the section, once moved there.
 * ClauseTypes without entries in the section mean that they retain their ClauseType in the section
 * when moved.
 */
const SECTION_CLAUSE_MAPPINGS: Partial<
  Readonly<Record<SectionType, Partial<Readonly<Record<ClauseType, ClauseType>>>>>
> = {
  [SectionType.INTRODUCTORY]: {
    [ClauseType.REQUIREMENT]: ClauseType.NORMAL,
    [ClauseType.ADVICE]: ClauseType.NORMAL,
    [ClauseType.NOTE]: ClauseType.NORMAL,
    [ClauseType.HEADING_3]: ClauseType.HEADING_2,
  },
  [SectionType.NORMATIVE]: {
    [ClauseType.NORMAL]: ClauseType.REQUIREMENT,
    [ClauseType.HEADING_3]: ClauseType.HEADING_2,
  },
  [SectionType.APPENDIX]: {
    [ClauseType.REQUIREMENT]: ClauseType.NORMAL,
    [ClauseType.ADVICE]: ClauseType.NORMAL,
    [ClauseType.NOTE]: ClauseType.NORMAL,
  },
};

@Injectable({
  providedIn: 'root',
})
export class ClauseService extends FragmentService<ClauseFragment> {
  private static readonly VERSION_REQUEST_DEBOUNCE: number = 20000;

  private currentView: CurrentView;

  // if a clause is in this dictionary, a version request has been sent out in the last
  // VERSION_REQUEST_DEBOUNCE milliseconds.
  private pendingVersionRequests: Dictionary<boolean> = {};

  // if a clause is in this dictionary, it or one of its descendants has been updated
  // since the last version request for that clause was sent.
  private updatedClauses: Dictionary<boolean> = {};
  // if a clause group is in this directory, it has been updated since the last version request for
  // that clause group was sent.
  private updatedClauseGroups: Dictionary<boolean> = {};

  /**
   * Gets the new clause type from the old clause type for a section of the given type.
   *
   * @param newSectionType  {SectionType}  The SectionType of the Section the clause is being moved to
   * @param oldClauseType   {ClauseType}   The existing type of the clause
   * @param suite           {Suite}        The suite of the document the clause is being moved to
   * @returns               {clauseType}   The new type of the clause
   */
  public static getClauseTypeForNewSection(
    newSectionType: SectionType,
    oldClauseType: ClauseType,
    suite: Suite
  ): ClauseType {
    const clauseMappings: Partial<Readonly<Record<ClauseType, ClauseType>>> = SECTION_CLAUSE_MAPPINGS[newSectionType];

    if (suite === Suite.MOMHW && newSectionType === SectionType.NORMATIVE && oldClauseType === ClauseType.HEADING_3) {
      return ClauseType.HEADING_3;
    }

    if (clauseMappings) {
      return clauseMappings[oldClauseType] ?? oldClauseType;
    }
    return oldClauseType;
  }

  constructor(
    protected _snackbar: MatSnackBar,
    protected _http: HttpClient,
    protected _websocketService: WebSocketService,
    protected _cacheManager: CacheManager,
    private _sectionService: SectionService,
    private _lockService: LockService,
    private _viewService: ViewService,
    private _configurationService: ConfigurationService
  ) {
    super(_websocketService, _cacheManager, _http, _snackbar);

    this._subscriptions.push(
      this.onCreate((clause: ClauseFragment) => {
        this._updatePreview(clause.getSection());
      }),
      this.onDelete((clause: ClauseFragment) => {
        this._updatePreview(this._sectionService.find(clause.sectionId));
      }),
      this.onUpdate((clause: ClauseFragment) => {
        this._updatePreview(clause.getSection());
        this.updatedClauses[clause.id.value] = true;
      }),

      // Maintain clause preview through updates to sub-clause fragments and clause group fragments
      super.onCreate((fragment: Fragment) => {
        this._onDescendantChange(fragment);
        this._onClauseGroupUpdate(fragment);
      }),
      super.onUpdate((fragment: Fragment) => {
        this._onDescendantChange(fragment);
        this._onClauseGroupUpdate(fragment);
      }),
      super.onDelete((fragment: Fragment) => {
        this._onClauseGroupUpdate(fragment);
      }),

      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        this.currentView = currentView;
      })
    );
  }

  /**
   * Request a version of a clause to be created.  If a version request has been sent out in
   * the last VERSION_REQUEST_DEBOUNCE milliseconds or the clause has not been changed since
   * the last request was sent, do nothing.
   *
   * @param id the id of the clause.  If not included, attempts to use the id of the
   * currently selected clause.  If this is null, it exits.
   */
  public requestVersion(id?: UUID): void {
    const clause = !id ? this.getSelected() : this.find(id);
    if (!clause) {
      return;
    }

    const parent: Fragment = clause.parent;

    const shouldVersionClause: boolean = this.updatedClauses[clause.id.value];
    const shouldVersionParent: boolean =
      parent?.is(FragmentType.CLAUSE_GROUP) && this.updatedClauseGroups[parent.id.value];

    const toVersion: Fragment = parent?.is(FragmentType.CLAUSE_GROUP) ? parent : clause;

    if ((shouldVersionClause || shouldVersionParent) && !this.pendingVersionRequests[toVersion.id.value]) {
      this.pendingVersionRequests[toVersion.id.value] = true;
      this.version(toVersion, {
        fragmentId: toVersion.id.value,
        name: null,
        shouldCreateTag: false,
      });

      setTimeout(() => {
        delete this.pendingVersionRequests[toVersion.id.value];
        delete this.updatedClauses[clause.id.value];
        delete this.updatedClauseGroups[parent.id.value];
      }, ClauseService.VERSION_REQUEST_DEBOUNCE);
    }
  }

  /**
   * @override
   */
  public create(clauses: ClauseFragment | ClauseFragment[], message?: string): Promise<HttpResponse<any>> {
    clauses = this._toArray(clauses);
    for (const clause of clauses) {
      this._lockService.lock(clause, false);
      if (clause.index() < 0) {
        this._sectionService.getSelected().children.push(clause);
      }
    }

    message =
      message || 'Sorry, something went wrong and we were unable to create a clause. Please try again in a moment.';
    return super.create(clauses, message);
  }

  /**
   * @override
   */
  public update(clauses: ClauseFragment | ClauseFragment[], message?: string): Promise<HttpResponse<any>> {
    message =
      message || 'Sorry, something went wrong and we were unable to update the clause. Please try again in a moment.';
    return super.update(clauses, message);
  }

  /**
   * @override
   */
  public delete(clauses: ClauseFragment | ClauseFragment[], message?: string): Promise<HttpResponse<any>> {
    message =
      message || 'Sorry, something went wrong and we were unable to delete the clause. Please try again in a moment.';
    return super.delete(clauses, message);
  }

  /**
   * @override
   */
  public onCreate(callback: Callback<ClauseFragment>): Subscription {
    return super.onCreate(callback, (f: ClauseFragment) => f.is(FragmentType.CLAUSE));
  }

  /**
   * @override
   */
  public onUpdate(callback: Callback<ClauseFragment>): Subscription {
    return super.onUpdate(callback, (f: ClauseFragment) => f.is(FragmentType.CLAUSE));
  }

  /**
   * @override
   */
  public onDelete(callback: Callback<ClauseFragment>): Subscription {
    return super.onDelete(callback, (f: ClauseFragment) => f.is(FragmentType.CLAUSE));
  }

  /**
   * @override
   */
  public onSelection(callback: Callback<ClauseFragment>): Subscription {
    return super.onSelection(callback, (f: ClauseFragment) => f.is(FragmentType.CLAUSE));
  }

  /**
   * Piggyback on setSelected by also updating clause locks if necessary.
   *
   * @override
   */
  public setSelected(clause: ClauseFragment): void {
    if (this.currentView.userCanLockClause()) {
      const prev: ClauseFragment = this.getSelected();
      if (clause && !clause.equals(prev)) {
        this._lockService.unlock(prev);
        this._lockService.lock(clause);
      } else if (!clause) {
        this._lockService.unlock(prev);
      }
    }

    super.setSelected(clause);
  }

  /**
   * Cyclically increment the type of a clause.  Ordering is reversed if the optional
   * reverse flag is set to true.
   *
   * @param clause  {ClauseFragment}   The clause to update
   * @param reverse {boolean}          True to reverse ordering
   */
  public incrementType(clause: ClauseFragment, reverse: boolean = false): Promise<HttpResponse<any>> {
    return this._configurationService
      .getClauseTypesForSectionId(clause.sectionId)
      .then((configList: ClauseTypeConfiguration[]) => {
        const oldIndex: number = configList.findIndex(
          (config: ClauseTypeConfiguration) => config.clauseType === clause.clauseType
        );
        const newIndex: number = reverse ? oldIndex - 1 : oldIndex + 1;

        // Take modulus to give circular increment
        clause.clauseType = configList[(newIndex + configList.length) % configList.length].clauseType;
        Logger.analytics('select-clause-type', 'keyboard');

        return this.update(clause);
      });
  }

  /**
   * Helper function to set the clause preview for all clauses in a section.
   *
   * @param section {SectionFragment}   The parent section
   */
  private _updatePreview(section: SectionFragment): void {
    if (!section || !section.children) {
      return;
    }

    for (const clause of section.getClauses()) {
      clause.calculateClausePreview();
      clause.markForCheck();
      clause.markChildrenForCheck((f: Fragment) => f.isCaptioned());
    }
  }

  /**
   * Helper function to update the clause preview for the clause above a fragment.
   *
   * @param fragment {Fragment}   The leaf fragment
   */
  private _onDescendantChange(fragment: Fragment): void {
    const clause: ClauseFragment = fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    if (clause) {
      clause.calculateClausePreview();
      this.updatedClauses[clause.id.value] = true;
    }
  }

  /**
   * Helper function to update the clause preview for all clauses in a section if a clause group is updated.
   *
   * @param fragment {Fragment}   The leaf fragment
   */
  private _onClauseGroupUpdate(fragment: Fragment): void {
    if (fragment.is(FragmentType.CLAUSE_GROUP)) {
      this.updatedClauseGroups[fragment.id.value] = true;
      const section: SectionFragment = this._sectionService.find(fragment.sectionId);
      this._updatePreview(section);
    }
  }
}
