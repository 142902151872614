import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {TreeStructureValidator} from 'app/fragment/tree-structure-validator';
import {PadType} from './element-ref.service';
import {ActionRequest} from './fragment/action-request';
import {Caret} from './fragment/caret';
import {ClauseListComponent} from './fragment/clause-list/clause-list.component';
import {isClauseGroupOfType} from './fragment/fragment-utils';
import {
  AnchorFragment,
  CaptionedFragment,
  ClauseFragment,
  ClauseType,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  EquationFragment,
  FigureFragment,
  Fragment,
  FragmentType,
  InternalReferenceType,
  TextFragment,
} from './fragment/types';
import {ClauseGroupFragment} from './fragment/types/clause-group-fragment';
import {ClauseGroupType} from './fragment/types/clause-group-type';
import {ReferenceInputFragment} from './fragment/types/input/reference-input-fragment';
import {SelectionOperationsService} from './selection-operations.service';
import {CopyPasteService} from './services/copy-paste/copy-paste.service';
import {DomService} from './services/dom.service';
import {FragmentDeletionValidationService} from './services/fragment-deletion-validation.service';
import {FragmentService} from './services/fragment.service';
import {LockService} from './services/lock.service';

type FragmentChanges = Record<'created' | 'updated', Fragment[]>;

@Injectable({
  providedIn: 'root',
})
export class PadOperationsService {
  private static DELETE_WITHIN_NDR: string =
    // eslint-disable-next-line max-len
    'Nested content within a Nationally Determined Requirement cannot be deleted in this way. Please use the delete functionality provided.';
  private static INVALID_RANGE: string =
    'The selected range is invalid. Either the start or end is in a locked clause.';
  private static UNABLE_TO_FORMAT: string =
    'CARS was unable to format your input into MDD-compliant text.  Please contact support.';
  private _caret: Caret = new Caret(null, 0);

  constructor(
    private _domService: DomService,
    private _lockService: LockService,
    private _snackbar: MatSnackBar,
    private _selectionOperationsService: SelectionOperationsService,
    private _fragmentService: FragmentService,
    private _fragmentDeletionValidationService: FragmentDeletionValidationService,
    private _copyPasteService: CopyPasteService
  ) {}

  /**
   * Checks whether the selection delete operation should go ahead based on the results of the
   * {@link FragmentDeletionValidationService._shouldDeleteFragments} method. The logic here mostly duplicates that of
   * {@link deleteSelection} and also includes logic from the {@link ClauseListComponent.mergeClauses} method to avoid
   * the user potentially being shown multiple prompts.
   *
   * @param start {Caret} The start of the browser caret selection
   * @param end {Caret} The end of the browser caret selection
   * @param containedInInput {boolean} True if the selection is entirely contained within a single InputFragment
   * @param containedInNDRClause {boolean} True if the selection is entirely contained within a single NDR Clause
   * @param mergeClauses {boolean} True if deletion should attempt to merge the clauses (if selection spans multiple)
   * @returns {Promise<boolean} A promise resolving to whether the deletion should go ahead or be cancelled
   */
  private _shouldDeleteFragments(
    start: Caret,
    end: Caret,
    containedInInput: boolean,
    containedInNDRClause: boolean,
    mergeClauses: boolean,
    containedInList: boolean
  ): Promise<boolean> {
    let fragsToDelete: Fragment[] = [];

    fragsToDelete.push(...this._includeEndSpecifierInstructionsForDeletion(start, end));
    fragsToDelete.push(...this._includeEndClauseGroupsForDeletion(start, end));

    const callback = (fragment, startCaret, endCaret) => {
      if (
        this._lockService.canLock(fragment.findAncestorWithType(FragmentType.CLAUSE)) &&
        this._lockService.canLockGroup(fragment.findAncestorWithType(FragmentType.CLAUSE_GROUP))
      ) {
        if (
          fragment.equals(startCaret.fragment) ||
          fragment.equals(endCaret.fragment) ||
          fragment.is(FragmentType.SECTION)
        ) {
          return true;
        } else {
          fragsToDelete.push(fragment);
          return false;
        }
      }
    };

    if (start.fragment.equals(end.fragment)) {
      callback(start.fragment, start, end);
    } else {
      start.fragment.root().iterateUp(start.fragment, end.fragment, (fragment: Fragment) => {
        return callback(fragment, start, end);
      });
    }

    fragsToDelete = fragsToDelete.filter((frag: Fragment) => {
      return (
        this._filterSingleAnchorFragmentsFromDeletion(frag, fragsToDelete) &&
        this._filterClauseGroupFragmentsFromDeletion(frag, containedInInput, containedInNDRClause, containedInList) &&
        this._filterSpecifierInstructionFragmentsFromDeletion(frag, containedInInput)
      );
    });

    const fragmentsToCheck: Fragment[] = [];
    fragsToDelete.forEach((frag: Fragment) =>
      frag.iterateDown(null, null, (f: Fragment) => {
        fragmentsToCheck.push(f);
      })
    );

    if (this._shouldMergeClauses(start, end, mergeClauses)) {
      // This logic is taken from the ClauseListComponent::mergeClauses
      fragmentsToCheck.push(start.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment);
    }

    return this._fragmentDeletionValidationService.shouldDeleteFragmentsWithoutSubtrees(fragmentsToCheck);
  }

  /**
   * Delete the fragment subtree spanned by the browser selection. Does nothing if the user cannot lock the fragments
   * or the {@link FragmentDeletionValidationService} prevents deletion.
   *
   * @param mergeClauses {boolean} True if deletion should attempt to merge the clauses (if selection spans multiple)
   * @param padType {PadType} The PadType of the current selection, used when selecting fragments
   * @param start {Caret} The start of the browser caret selection
   * @param end {Caret} The end of the browser caret selection
   * @param focus {boolean} focus the start of the deleted selection if true.
   * @returns {Promise<[boolean, Caret]} A promise resolving to whether the deletion occured and the new caret position
   */
  public async deleteSelection(
    mergeClauses: boolean,
    padType: PadType,
    [start, end]: Caret[] = this._domService.getCaretsFromSelection(),
    focus: boolean = true
  ): Promise<[boolean, Caret]> {
    if (!this._canLockCaretFragments(start, end, padType)) {
      return [false, null];
    }

    const commonAncestor: Fragment = Fragment.commonAncestorOf(start.fragment, end.fragment);

    const containedInInput: boolean = !!commonAncestor && commonAncestor.is(FragmentType.INPUT);

    const containedInList: boolean = !!commonAncestor && commonAncestor.is(FragmentType.LIST);

    const containedInNDRClause: boolean =
      !!commonAncestor &&
      !!commonAncestor.findAncestorWithType(FragmentType.CLAUSE) &&
      !!commonAncestor.findAncestor((frag: Fragment) =>
        isClauseGroupOfType(frag, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)
      );

    const startClauseGroup = this._findParentClauseGroupForDeletion(start);
    const endClauseGroup = this._findParentClauseGroupForDeletion(end);

    const shouldBlockDeletion: boolean = this._handleBlockingDeletionOfClauses(
      start,
      end,
      commonAncestor,
      startClauseGroup,
      endClauseGroup
    );
    if (
      shouldBlockDeletion ||
      !(await this._shouldDeleteFragments(
        start,
        end,
        containedInInput,
        containedInNDRClause,
        mergeClauses,
        containedInList
      ))
    ) {
      this._snackbar.open(PadOperationsService.DELETE_WITHIN_NDR, 'Dismiss', {duration: 3000});
      return [true, start];
    }

    this._adjustStartAndEndCaretsBeforeDeletion(start, end, containedInInput, containedInNDRClause, containedInList);

    const updated: Fragment[] = [];
    let deleted: Fragment[] = [];

    deleted.push(...this._includeEndSpecifierInstructionsForDeletion(start, end));
    deleted.push(...this._includeEndClauseGroupsForDeletion(start, end));

    const callback = (fragment, startCaret, endCaret) => {
      if (
        this._lockService.canLock(fragment.findAncestorWithType(FragmentType.CLAUSE)) &&
        this._lockService.canLockGroup(fragment.findAncestorWithType(FragmentType.CLAUSE_GROUP))
      ) {
        if (fragment.equals(startCaret.fragment) || fragment.equals(endCaret.fragment)) {
          updated.push(fragment);
          return true;
        } else if (fragment.is(FragmentType.SECTION)) {
          return true;
        } else {
          deleted.push(fragment);
          return false;
        }
      }
    };

    if (start.fragment.equals(end.fragment)) {
      callback(start.fragment, start, end);
    } else {
      start.fragment.root().iterateUp(start.fragment, end.fragment, (fragment: Fragment) => {
        return callback(fragment, start, end);
      });
    }
    if (
      !!startClauseGroup &&
      startClauseGroup.isFirstChild() &&
      isClauseGroupOfType(startClauseGroup, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT) &&
      isClauseGroupOfType(startClauseGroup.parent, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)
    ) {
      deleted.push(startClauseGroup.parent);
    } else if (
      !!endClauseGroup &&
      endClauseGroup.isLastChild() &&
      isClauseGroupOfType(endClauseGroup, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT) &&
      isClauseGroupOfType(endClauseGroup.parent, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)
    ) {
      deleted.push(endClauseGroup.parent);
    }

    deleted = deleted.filter((frag: Fragment) => {
      return (
        this._filterSingleAnchorFragmentsFromDeletion(frag, deleted) &&
        this._filterClauseGroupFragmentsFromDeletion(frag, containedInInput, containedInNDRClause, containedInList) &&
        this._filterSpecifierInstructionFragmentsFromDeletion(frag, containedInInput)
      );
    });

    this._fragmentService.update(updated.map((fragment) => this._accountForCaptions(fragment)));
    this._fragmentService.deleteValidatedFragments(deleted);

    if (this._shouldMergeClauses(start, end, mergeClauses)) {
      this._mergeClauses(start, end);
    }

    if (focus) {
      this._selectionOperationsService.setSelected(start.fragment, start.offset, padType);
    }

    return [true, start];
  }

  /**
   * Returns true if the clauses containing the start and end carets can be locked.
   */
  private _canLockCaretFragments(start: Caret, end: Caret, padType: PadType): boolean {
    const startClause: ClauseFragment = start.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const endClause: ClauseFragment = end.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;

    if (!this._lockService.canLock(startClause) || !this._lockService.canLock(endClause)) {
      this._snackbar.open(PadOperationsService.INVALID_RANGE, 'Dismiss', {
        duration: 3000,
      });
      this._selectionOperationsService.setSelected(start.fragment, start.offset, padType);
      return false;
    }

    return true;
  }

  /**
   * Adjusts the carets if either both carets lie in the same fragment or if they are not in disallowed reagions of
   */
  private _adjustStartAndEndCaretsBeforeDeletion(
    start: Caret,
    end: Caret,
    containedInInput: boolean,
    containedInNDRClause: boolean,
    containedInList: boolean
  ): void {
    if (start.fragment.equals(end.fragment)) {
      const length: number = start.fragment.length();
      const endValue: string = start.fragment.split(end.offset).value;
      const startValue: string = start.fragment.split(start.offset).value;
      start.fragment.value += endValue;
      end.offset -= length - startValue.length - endValue.length;
    } else {
      const startClause: ClauseFragment = start.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
      const startClauseGroup: ClauseGroupFragment = startClause?.findAncestorWithType(
        FragmentType.CLAUSE_GROUP
      ) as ClauseGroupFragment;
      const endClause: ClauseFragment = end.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
      const endClauseGroup: ClauseGroupFragment = endClause?.findAncestorWithType(
        FragmentType.CLAUSE_GROUP
      ) as ClauseGroupFragment;

      if (
        containedInInput ||
        containedInNDRClause ||
        containedInList ||
        (!startClauseGroup && startClause.clauseType !== ClauseType.SPECIFIER_INSTRUCTION)
      ) {
        start.fragment.split(start.offset);
      }
      if (
        containedInInput ||
        containedInNDRClause ||
        containedInList ||
        (!endClauseGroup && endClause.clauseType !== ClauseType.SPECIFIER_INSTRUCTION)
      ) {
        const endFragment: Fragment = end.fragment.split(end.offset);
        if (endFragment) {
          end.fragment.value = endFragment.value;
        }
      }
    }
  }

  /**
   * Returns the clause fragments containing the start and end caret if they are specifier instructions and the caret
   * selection contains the entire clause. Otherwise these specifier instruction clauses don't get added to the
   * deletion.
   */
  private _includeEndSpecifierInstructionsForDeletion(start: Caret, end: Caret): Fragment[] {
    const startClause: ClauseFragment = start.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const startCaretAtStartOfClause: boolean = startClause.correctOffset(start.fragment, start.offset) === 0;

    const endClause: ClauseFragment = end.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const endCaretAtEndOfClause: boolean = endClause.correctOffset(end.fragment, end.offset) === endClause.length();

    const clausesToDelete: Fragment[] = [];

    if (startClause.equals(endClause) && startClause.clauseType === ClauseType.SPECIFIER_INSTRUCTION) {
      if (startCaretAtStartOfClause && endCaretAtEndOfClause) {
        clausesToDelete.push(startClause);
      }
    } else if (startCaretAtStartOfClause && startClause.clauseType === ClauseType.SPECIFIER_INSTRUCTION) {
      clausesToDelete.push(startClause);
    } else if (endCaretAtEndOfClause && endClause.clauseType === ClauseType.SPECIFIER_INSTRUCTION) {
      clausesToDelete.push(endClause);
    }

    return clausesToDelete;
  }

  /**
   * Returns the clause group fragments containing the start and end caret if the caret selection contains the entire
   * clause group. Otherwise, these clause groups don't get added to the deletion.
   */
  private _includeEndClauseGroupsForDeletion(start: Caret, end: Caret): Fragment[] {
    const startClause: ClauseFragment = start.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const startCaretAtStartOfClause: boolean = startClause.correctOffset(start.fragment, start.offset) === 0;
    const startClauseGroup: ClauseGroupFragment = startClause.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    const startCaretAtStartOfClauseGroup: boolean =
      startClauseGroup && startClause.isFirstChild() && startCaretAtStartOfClause;

    const endClause: ClauseFragment = end.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const endCaretAtEndOfClause: boolean =
      end.fragment.is(FragmentType.READONLY) && !startClause.equals(endClause)
        ? true
        : endClause.correctOffset(end.fragment, end.offset) === endClause.length();
    const endClauseGroup: ClauseGroupFragment = endClause.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    const endCaretAtEndOfClauseGroup: boolean = endClauseGroup && endClause.isLastChild() && endCaretAtEndOfClause;

    const clauseGroupsToDelete: Fragment[] = [];

    if (!!startClauseGroup && !!endClauseGroup && startClauseGroup.equals(endClauseGroup)) {
      if (startCaretAtStartOfClauseGroup && endCaretAtEndOfClauseGroup) {
        clauseGroupsToDelete.push(startClauseGroup);
      }
    } else if (!!startClauseGroup && startCaretAtStartOfClauseGroup) {
      clauseGroupsToDelete.push(startClauseGroup);
    } else if (!!endClauseGroup && endCaretAtEndOfClauseGroup) {
      clauseGroupsToDelete.push(endClauseGroup);
    } else {
      this._handleSnackbarWhenBlockingDeletionOfNDRs(startClauseGroup, endClauseGroup);
    }

    return clauseGroupsToDelete;
  }

  /**
   * Prevents deletion of a single anchor fragment of a pair. Returns false if the passed fragment is an ANCHOR
   * fragment without its matching anchor fragment contained within the deleted array.
   *
   * @param fragment {Fragment}   The fragment to test
   * @param deleted  {Fragment[]} The list of fragments to be deleted
   */
  private _filterSingleAnchorFragmentsFromDeletion(fragment: Fragment, deleted: Fragment[]): boolean {
    return (
      !fragment.is(FragmentType.ANCHOR) ||
      deleted.findIndex(
        (f: Fragment) => f.is(FragmentType.ANCHOR) && fragment.id.equals((f as AnchorFragment).otherAnchorId)
      ) > -1
    );
  }

  /**
   * Prevents deletion of parts of a clause group from the pad, unless the selection is entirely contained in an INPUT
   * fragment or the whole clause group is selected.
   *
   *
   * @param fragment                {Fragment} The fragment to test
   * @param isContainedInInput      {boolean}  Whether the selection is entirely contained in an INPUT fragment
   * @param isContainedInNDRClause  {boolean}  Whether the selection is contained in a single clause of a nationally determined requirement
   *
   * @returns true if the fragment is to be deleted. False if the fragment is not to be deleted.
   */
  private _filterClauseGroupFragmentsFromDeletion(
    fragment: Fragment,
    isContainedInInput: boolean,
    isContainedInNDRClause: boolean,
    isContainedInList: boolean
  ): boolean {
    const fragmentClauseGroup: Fragment = fragment.findAncestorWithType(FragmentType.CLAUSE_GROUP);
    const sfrIsFirstOrLastChild: boolean = fragmentClauseGroup
      ? fragmentClauseGroup.isFirstChild() || fragmentClauseGroup.isLastChild()
      : undefined;

    return (
      !fragment.findAncestorWithType(FragmentType.CLAUSE_GROUP) ||
      (fragment.is(FragmentType.CLAUSE_GROUP) &&
        !isClauseGroupOfType(fragmentClauseGroup.parent, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)) ||
      (isClauseGroupOfType(fragmentClauseGroup, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT) &&
        isClauseGroupOfType(fragmentClauseGroup.parent, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT) &&
        sfrIsFirstOrLastChild) ||
      isContainedInInput ||
      isContainedInNDRClause ||
      isContainedInList
    );
  }

  /**
   * Prevents deletion of parts of a specifier instruction from the pad, unless the selection is entirely contained in
   * an INPUT fragment or the whole specifier instruction is selected.
   *
   * @param fragment            {Fragment} The fragment to test
   * @param isContainedInInput  {boolean}  Whether the selection is entirely contained in an INPUT fragment
   */
  private _filterSpecifierInstructionFragmentsFromDeletion(fragment: Fragment, isContainedInInput: boolean): boolean {
    return (
      !fragment.findAncestor((frag: Fragment) => frag.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) ||
      fragment.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) ||
      isContainedInInput
    );
  }

  /**
   * If the selected range contains more than one clause, this merges the remaining parts of the
   * clauses together after deleting the selection.
   */
  private _shouldMergeClauses(start: Caret, end: Caret, mergeClauses: boolean): boolean {
    const source: ClauseFragment = end.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const target: ClauseFragment = start.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    if (mergeClauses && source && target) {
      if (
        !source.equals(target) &&
        !source.findAncestorWithType(FragmentType.CLAUSE_GROUP) &&
        !target.findAncestorWithType(FragmentType.CLAUSE_GROUP) &&
        !source.findAncestor((frag: Fragment) => frag.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) &&
        !target.findAncestor((frag: Fragment) => frag.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION))
      ) {
        return true;
      }
    }

    return false;
  }

  private _mergeClauses(start: Caret, end: Caret): void {
    const source: ClauseFragment = end.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const target: ClauseFragment = start.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;

    const clauseList: ClauseListComponent = source.parent.component as ClauseListComponent;
    clauseList.handleMerge(source, target, true);
  }

  public strictInsertFragments(caret: Caret, fragments: Fragment[], padType: PadType): void {
    const changed: FragmentChanges = {
      created: [],
      updated: [],
    };

    if (this._specialCase(caret, fragments, changed, padType)) {
      this._save(changed);
      return;
    }

    let parent: Fragment = caret.fragment;
    let previousParent: Fragment;

    while (
      !parent.is(FragmentType.DOCUMENT) &&
      !fragments.every((fragment: Fragment) => TreeStructureValidator.isValidRelationship(parent, fragment))
    ) {
      previousParent = parent;
      parent = parent.parent;
    }

    if (parent.is(FragmentType.DOCUMENT)) {
      Logger.error('paste-error', 'Cannot paste these fragments here.');
      return;
    }

    let splitChildren: Fragment[] = [];

    switch (parent.type) {
      case FragmentType.SECTION:
        {
          if (
            caret.fragment.findAncestorWithType(FragmentType.TABLE) ||
            caret.fragment.findAncestorWithType(FragmentType.EQUATION) ||
            caret.fragment.findAncestorWithType(FragmentType.FIGURE)
          ) {
            parent.children.splice(previousParent.index() + 1, 0, ...fragments);
            changed.created.push(...fragments);
          } else {
            splitChildren = Fragment.splitChildren(
              previousParent,
              previousParent.correctOffset(caret.fragment, caret.offset)
            );
            const newClauses: Fragment[] = [...fragments];
            let split: ClauseFragment = null;

            if (splitChildren.length) {
              split = new ClauseFragment(null, (previousParent as ClauseFragment).clauseType, splitChildren, '');
              newClauses.push(split);
            }
            parent.children.splice(previousParent.index() + 1, 0, ...newClauses);

            changed.created.push(...fragments);
            changed.updated.push(caret.fragment);

            this._legitifyClause(previousParent, changed);

            if (split) {
              changed.created.push(split, ...split.children);
              this._legitifyClause(split, changed);
            }
          }
        }
        break;
      case FragmentType.CLAUSE:
        {
          if (
            caret.fragment.findAncestorWithType(FragmentType.TABLE) ||
            caret.fragment.findAncestorWithType(FragmentType.EQUATION) ||
            caret.fragment.findAncestorWithType(FragmentType.FIGURE)
          ) {
            const section: Fragment = parent.parent;
            const clause: ClauseFragment = new ClauseFragment(
              null,
              (parent as ClauseFragment).clauseType,
              fragments,
              ''
            );
            section.children.splice(parent.index() + 1, 0, clause);
            this._legitifyClause(clause, changed);
            changed.created.push(clause);
          } else {
            splitChildren = Fragment.splitChildren(parent, parent.correctOffset(caret.fragment, caret.offset));
            this._addTextFragmentIfNeeded(fragments, splitChildren);
            parent.children.push(...fragments, ...splitChildren);

            changed.updated.push(caret.fragment);
            changed.created.push(...fragments, ...splitChildren);

            this._legitifyClause(parent, changed);
          }
        }
        break;
      default:
        {
          splitChildren = Fragment.splitChildren(parent, parent.correctOffset(caret.fragment, caret.offset));
          this._addTextFragmentIfNeeded(fragments, splitChildren);
          parent.children.push(...fragments, ...splitChildren);

          const caretIsInCaptionOrEquationSource: boolean =
            caret.fragment.parent.is(FragmentType.TABLE, FragmentType.FIGURE, FragmentType.EQUATION) &&
            caret.fragment.parent.children.indexOf(caret.fragment) < 0;
          if (!caretIsInCaptionOrEquationSource) {
            // don't try to update the equation source or any captions as those fragments don't actually exist
            changed.updated.push(caret.fragment);
          }

          changed.created.push(...fragments, ...splitChildren);
        }
        break;
    }
    fragments.forEach((c) => c.inferWeight());
    splitChildren.forEach((c) => c.inferWeight());
    this._setSelectedFromPastedFragments(fragments, padType);
    this._save(changed);
  }

  private _specialCase(caret: Caret, fragments: Fragment[], changed: FragmentChanges, padType: PadType): boolean {
    if (this._isCaption(caret.fragment) && this._everyFragmentIsEditableText(fragments)) {
      const pastedValue: string = this._buildValue(fragments).trim();
      const splitValue: string = Fragment.splitValue(caret.fragment, caret.offset);
      caret.fragment.value = caret.fragment.value + pastedValue + splitValue;
      changed.updated.push(caret.fragment.parent);
      this._selectionOperationsService.setSelected(caret.fragment, caret.offset + pastedValue.length, padType);
      return true;
    }

    if (caret.fragment.parent.is(FragmentType.EQUATION) && this._everyFragmentIsEditableText(fragments)) {
      const equation: EquationFragment = caret.fragment.parent as EquationFragment;

      // Split the source of an equation fragment and insert new text here.
      const sourceString: string = equation.source.value;
      const firstSplit: string = sourceString.substr(0, caret.offset);
      const secondSplit: string = sourceString.substr(caret.offset, sourceString.length);
      const pastedValue: string = this._buildValue(fragments);
      equation.source.value = firstSplit + pastedValue + secondSplit;
      this._selectionOperationsService.setSelected(caret.fragment, caret.offset + pastedValue.length, padType);
      changed.updated.push(equation);
      return true;
    }

    if (
      caret.fragment.findAncestorWithType(FragmentType.LIST_ITEM) &&
      fragments.every((frag: Fragment) => frag.is(FragmentType.LIST))
    ) {
      const list: Fragment = caret.fragment.findAncestorWithType(FragmentType.LIST);
      const splitChildren: Fragment[] = list.split(list.correctOffset(caret.fragment, caret.offset)).children.splice(0);
      list.children.push(
        ...fragments.reduce((children: Fragment[], current: Fragment) => children.concat(current.children), []),
        ...splitChildren
      );
      Fragment.inferWeights(list.children);
      changed.updated.push(caret.fragment);
      changed.created.push(...list.children);

      if (splitChildren.length) {
        changed.created.push(...splitChildren[0].children);
      }
      this._setSelectedFromPastedFragments(fragments, padType);
      return true;
    }

    return false;
  }

  private _everyFragmentIsEditableText(fragments: Fragment[]): boolean {
    return fragments.every((fragment: Fragment) => fragment.is(...EDITABLE_TEXT_FRAGMENT_TYPES));
  }

  private _addTextFragmentIfNeeded(fragments: Fragment[], splitChildren: Fragment[]): void {
    const noTextFragmentAfterInlineFragmentBeingPastedIn: boolean =
      fragments[fragments.length - 1].isInline() && (!splitChildren.length || !splitChildren[0].is(FragmentType.TEXT));
    if (noTextFragmentAfterInlineFragmentBeingPastedIn) {
      splitChildren.unshift(TextFragment.empty());
    }
  }

  private _legitifyClause(clause: Fragment, changed: FragmentChanges): void {
    if (!clause.is(FragmentType.CLAUSE)) {
      return;
    }

    const children: Fragment[] = clause.children;

    if (
      children.length &&
      !children[0].is(FragmentType.TEXT) &&
      !clause.findAncestorWithType(FragmentType.CLAUSE_GROUP)
    ) {
      children.unshift(new TextFragment(null));
    }

    for (let i = 0; i < children.length; i++) {
      const child: Fragment = children[i];
      const next: Fragment = i + 1 < children.length ? children[i + 1] : null;
      if (next) {
        if ((child.isCaptioned() || child.is(FragmentType.LIST)) && !next.isCaptioned()) {
          const splicedChildren: Fragment[] = clause.children.splice(child.index() + 1);
          const newClause: Fragment = new ClauseFragment(
            null,
            (clause as ClauseFragment).clauseType,
            splicedChildren,
            ''
          );
          splicedChildren.forEach((c) => c.inferWeight());
          clause.parent.children.splice(clause.index() + 1, 0, newClause);
          changed.created.push(newClause, ...splicedChildren);
          this._legitifyClause(newClause, changed);
        }
      }
    }
  }

  private _save(changed: FragmentChanges): void {
    this._copyPasteService.setCursorStyle(false);
    this._fragmentService.update(changed.updated);
    this._fragmentService.create(changed.created).then(() => {
      for (const createdFragment of changed.created) {
        if (createdFragment.is(FragmentType.CLAUSE)) {
          this._lockService.unlock(createdFragment as ClauseFragment);
        }
      }
    });
    this._fragmentService.update(changed.created);
  }

  private _setSelectedFromPastedFragments(fragments: Fragment[], padType: PadType): void {
    let fragmentToSelect: Fragment = fragments.slice(-1)[0];
    if (fragmentToSelect.is(FragmentType.EQUATION)) {
      fragmentToSelect = (fragmentToSelect as EquationFragment).source;
    } else if (fragmentToSelect.is(FragmentType.FIGURE)) {
      fragmentToSelect = (fragmentToSelect as FigureFragment).caption;
    }
    this._selectionOperationsService.setSelected(fragmentToSelect, fragmentToSelect.length(), padType);
  }

  /**
   * Insert fragments at the current caret position.
   * @param caret {Caret} The caret position to insert into
   * @param fragments {Fragment[]} The fragments to insert.
   * @param focus {boolean} focus the end of the inserted selection if true.
   */
  public insertFragments(caret: Caret, fragments: Fragment[], padType: PadType, focus: boolean = true): void {
    const created: Fragment[] = [];

    if (!TreeStructureValidator.areValidTrees(fragments)) {
      this._snackbar.open(PadOperationsService.UNABLE_TO_FORMAT, 'Dismiss', {duration: 5000});
      return;
    }

    const insertingIntoTable: Fragment =
      caret.fragment && caret.fragment.findAncestorWithType(FragmentType.TABLE_CELL, FragmentType.TABLE);
    if (insertingIntoTable) {
      fragments = this._upliftClauseContents(fragments);
    }

    // Insert the fragments
    if (this._isCaption(caret.fragment)) {
      const pastedSections: Fragment[] = [];
      for (const fragment of fragments) {
        pastedSections.push(fragment);
      }

      // Combine the pasted fragments
      const pastedValue = this._buildValue(pastedSections, true).trim();
      const captionSections: Fragment[] = [];

      if (caret.offset > 0 && caret.offset < caret.fragment.length()) {
        const split: Fragment = caret.fragment.split(caret.offset);
        (caret.fragment.parent as CaptionedFragment).caption = caret.fragment;
        this._fragmentService.update(caret.fragment.parent);
        captionSections.push(split);
      }
      (caret.fragment.parent as CaptionedFragment).caption.value += pastedValue;
      (caret.fragment.parent as CaptionedFragment).caption = this._combineFragments(caret.fragment, captionSections);

      caret.offset += pastedValue.length;
      this._fragmentService.update(caret.fragment.parent);

      if (focus) {
        this._selectionOperationsService.setSelected(caret.fragment, caret.offset, padType);
      }
    } else {
      // Check whether the fragment under initial caret needs splitting. Won't split in equation fragment as this is handled
      // separately later
      if (
        caret.fragment.parent.type !== FragmentType.EQUATION &&
        caret.offset >= 0 &&
        caret.offset < caret.fragment.length()
      ) {
        const split: Fragment = caret.fragment.split(caret.offset);
        split.insertAfter(caret.fragment);

        this._fragmentService.update(caret.fragment);
        created.push(split);
      }

      for (const fragment of fragments) {
        let ancestorTypes: FragmentType[] = [fragment.type, FragmentType.CLAUSE];

        // If we are pasting into a table look up for the first table ancestor and paste there, unless
        // the fragment is a table fragment. Otherwise, the fragment will be pasted into the clause.
        if (insertingIntoTable && !fragment.is(FragmentType.TABLE, FragmentType.TABLE_ROW, FragmentType.TABLE_CELL)) {
          ancestorTypes = [fragment.type, FragmentType.TABLE_CELL, FragmentType.CLAUSE];
        }

        // Allow text to be pasted into list items or inputs.
        if (fragment.is(FragmentType.TEXT)) {
          ancestorTypes.push(FragmentType.LIST_ITEM, FragmentType.INPUT);
        }

        const target: Fragment = caret.fragment.findAncestorWithType(...ancestorTypes);

        // If target is of the same type as the fragment we're trying to insert, it can be inserted
        // immediately following target.  Otherwise, it must be inserted following the relevant child
        // of target.  (E.g., insert a clause following a clause, but a list as a child of a clause.)
        if (target.parent.is(FragmentType.EQUATION)) {
          const equation = target.parent as EquationFragment;

          // Split the source of an equation fragment and insert new text here.
          const sourceString: string = equation.source.value;
          const firstSplit: string = sourceString.substr(0, caret.offset);
          const secondSplit: string = sourceString.substr(caret.offset, sourceString.length);

          equation.source.value = firstSplit + fragment.value + secondSplit;
          caret = Caret.endOf(target);

          this._fragmentService.update(equation);
        } else {
          if (fragment.is(target.type)) {
            fragment.insertAfter(target);
          } else {
            const index: number = target.childIndexOf(caret.fragment);
            fragment.insertAfter(target.children[index]);
          }

          caret = Caret.endOf(fragment);
          created.push(fragment);
        }
      }
      this._fragmentService.create(created).then(() => {
        for (const createdFragment of created) {
          if (createdFragment.is(FragmentType.CLAUSE)) {
            this._lockService.unlock(createdFragment as ClauseFragment);
          }
        }
      });

      this._fragmentService.mergeChildren(caret.fragment.parent, caret);

      if (focus) {
        this._selectionOperationsService.setSelected(caret.fragment, caret.offset, padType);
      }
    }
  }

  private _isCaption(fragment: Fragment): boolean {
    return (
      (fragment.parent.is(FragmentType.TABLE, FragmentType.FIGURE) && fragment.parent.children.indexOf(fragment) < 0) ||
      (fragment.parent.is(FragmentType.EQUATION) && (fragment.parent as EquationFragment).source !== fragment)
    );
  }

  private _combineFragments(originalFragment: Fragment, fragments: Fragment[]): Fragment {
    originalFragment.value += this._buildValue(fragments);
    return originalFragment;
  }

  private _buildValue(fragments: Fragment[], includeSpace: boolean = false): string {
    let value: string = '';
    for (const fragment of fragments) {
      if (fragment.is(FragmentType.CLAUSE)) {
        value += this._buildValue(fragment.children, true);
      } else {
        value += fragment.value + (includeSpace ? ' ' : '');
      }
    }

    return value;
  }

  /**
   * Removes any ClauseFragments from an array and inserts their children
   * in their place.
   *
   * @param fragments {Fragment[]} The fragment array.
   * @return          {Fragment[]} The Fragments with clauses removed and clause children
   * in their place.
   */
  private _upliftClauseContents(fragments: Fragment[]): Fragment[] {
    return fragments.reduce((reducer: Fragment[], fragment: Fragment) => {
      if (fragment.is(FragmentType.CLAUSE)) {
        const children = fragment.children.splice(0);

        // If the first child is text, and the previous is text then add a new line to the previous Text fragment
        if (
          children.length > 0 &&
          children[children.length - 1].is(...EDITABLE_TEXT_FRAGMENT_TYPES) &&
          reducer.length > 0 &&
          reducer[reducer.length - 1].is(...EDITABLE_TEXT_FRAGMENT_TYPES)
        ) {
          const child = reducer[reducer.length - 1];
          child.value = child.value.concat('\n', '\r');
        }
        return [...reducer, ...children];
      } else {
        return [...reducer, fragment];
      }
    }, []);
  }

  /**
   * Handle an ActionRequest returned from a fragment's keydown or rich text handler.
   *
   * @param action {ActionRequest}   The action request
   */
  public handleActionRequest(caret: Caret, action: ActionRequest, padType: PadType): Caret {
    if (!action) {
      return caret;
    }
    this._caret = caret;

    action.fragment = action.fragment || this._caret.fragment;
    this._handleDeletionAhead(action);
    this._handleTextInsertion(action);
    this._handleDeletionBehind(action);

    if (action.hasEffect()) {
      this._fragmentService.update(this._accountForCaptions(action.fragment));
      window.getSelection().collapseToEnd();
    }

    this._caret.offset += action.toDelta();
    this._caret.fragment = action.fragment;
    this._fragmentService.mergeChildren(action.fragment.parent, this._caret);
    this._selectionOperationsService.setSelected(this._caret.fragment, this._caret.offset, padType);

    return this._caret;
  }

  /**
   * Handle the text insertion portion of an ActionRequest by inserting into the current fragment.
   *
   * @param action {ActionRequest}   The ActionRequest
   */
  private _handleTextInsertion(action: ActionRequest): void {
    if (typeof action.add === 'string') {
      const text: string = this._caret.fragment.value;
      this._caret.fragment.value =
        text.substring(0, this._caret.offset) + action.add + text.substring(this._caret.offset);
    }
  }

  /**
   * Handle the negative delete portion of an ActionRequest by deleting behind the cursor.
   *
   * @param action {ActionRequest}   The ActionRequest
   */
  private _handleDeletionBehind(action: ActionRequest): void {
    let fragment: Fragment = this._caret.fragment;
    let offset: number = this._caret.offset;
    let remaining: number = -action.remove;

    while (fragment && remaining > 0) {
      if (offset === 0) {
        // This is to handle backspacing at the start of a fragment. Without it, the caret would jump back by an extra character.
        action.offset = 1;
      }

      const remove: number = Math.min(remaining, offset);
      const text: string = fragment.value;
      fragment.value = text.substring(0, offset - remove) + text.substring(offset);
      remaining -= remove;
      offset -= remove;

      if (offset <= 0 && remaining > 0 && (fragment = fragment.previousLeaf())) {
        offset = fragment.length();
      }
      this._handleInlineReferences(fragment);
      this._handleInlineEquations(fragment);
      this._handleClauseReferences(fragment);
    }
  }

  /**
   * Handle the positive delete portion of an ActionRequest by deleting content ahead of the cursor.
   *
   * @param action {ActionRequest}   The ActionRequest
   */
  private _handleDeletionAhead(action: ActionRequest): void {
    let fragment: Fragment = this._caret.fragment;
    let offset: number = this._caret.offset;
    let remaining: number = action.remove;

    while (fragment && remaining > 0) {
      const remove: number = Math.min(remaining, fragment.length() - offset);
      const text: string = fragment.value;
      fragment.value = text.substring(0, offset) + text.substring(offset + remove);
      remaining -= remove;
      // No change to offset

      if (offset >= fragment.length() && remaining > 0 && (fragment = fragment.nextLeaf())) {
        offset = 0;
      }
      this._handleInlineReferences(fragment);
      this._handleInlineEquations(fragment);
      this._handleClauseReferences(fragment);
    }
  }

  private _handleInlineReferences(fragment: Fragment): boolean {
    if (fragment && fragment.type === FragmentType.INLINE_REFERENCE) {
      // Always inline reference, no need to validate
      this._fragmentService.delete(fragment);
      return true;
    }
    return false;
  }

  private _handleInlineEquations(fragment: Fragment): boolean {
    if (fragment && fragment.type === FragmentType.EQUATION) {
      if ((fragment as EquationFragment).inline) {
        this._fragmentService.delete(fragment);
        return true;
      }
    }
    return false;
  }

  private _handleClauseReferences(fragment: Fragment): boolean {
    if (
      fragment?.is(FragmentType.INTERNAL_INLINE_REFERENCE) &&
      (fragment.parent as ReferenceInputFragment).internalReferenceType === InternalReferenceType.CLAUSE_REFERENCE
    ) {
      this._fragmentService.delete(fragment.parent);
      return true;
    }
    return false;
  }

  // TODO: This is a temporary hack to enable caption updates, which are handled as fake
  // TextFragments by the frontend but are really just strings.  Hopefully this can be
  // made for systemic later, since there are a number of outstanding issues with captions.
  private _accountForCaptions(fragment: Fragment): Fragment {
    if (this._fragmentService.find(fragment.id)) {
      return fragment;
    } else if (fragment.parent && this._fragmentService.find(fragment.parent.id)) {
      return fragment.parent;
    } else {
      Logger.error(
        'fragment-error',
        'Attempted to update fragment ' +
          fragment.id.value +
          ' and neither it nor its parent is not known to the fragment service'
      );
      return null;
    }
  }

  private _findParentClauseGroupForDeletion(caret: Caret) {
    let caretParentClauseGroup: Fragment = caret.fragment.findAncestorWithType(FragmentType.CLAUSE_GROUP);
    if (
      !!caretParentClauseGroup &&
      !!caretParentClauseGroup.parent &&
      isClauseGroupOfType(caretParentClauseGroup, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT) &&
      isClauseGroupOfType(caretParentClauseGroup.parent, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)
    ) {
      caretParentClauseGroup = caretParentClauseGroup.parent;
    }

    return caretParentClauseGroup;
  }

  private _handleBlockingDeletionOfClauses(
    start: Caret,
    end: Caret,
    commonAncestor: Fragment,
    startClauseGroup: Fragment,
    endClauseGroup: Fragment
  ): boolean {
    return (
      (((commonAncestor.is(FragmentType.CLAUSE) && !start.fragment.parent.equals(end.fragment.parent)) ||
        isClauseGroupOfType(commonAncestor, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT) ||
        commonAncestor.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) &&
        !!commonAncestor.findAncestor((frag: Fragment) =>
          isClauseGroupOfType(frag, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)
        )) ||
      isClauseGroupOfType(commonAncestor, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT) ||
      (!!startClauseGroup &&
        isClauseGroupOfType(startClauseGroup.parent, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT) &&
        !startClauseGroup.isFirstChild()) ||
      (!!endClauseGroup &&
        isClauseGroupOfType(endClauseGroup.parent, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT) &&
        !endClauseGroup.isLastChild())
    );
  }

  private _handleSnackbarWhenBlockingDeletionOfNDRs(startClauseGroup: Fragment, endClauseGroup: Fragment) {
    if ((!!startClauseGroup && !endClauseGroup) || (!startClauseGroup && !!endClauseGroup)) {
      this._snackbar.open(PadOperationsService.DELETE_WITHIN_NDR, 'Dismiss', {duration: 3000});
    }
  }
}
