import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatInput} from '@angular/material/input';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {Suite} from 'app/fragment/suite';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentService} from 'app/services/document.service';
import {GlobalRole} from 'app/services/user/authentication-provider';
import {RoleService} from 'app/services/user/role.service';
import {UserService} from 'app/services/user/user.service';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';
import {
  PaginationAndSortData,
  SearchableDocument,
  SearchableDocumentColumn,
  SearchDocumentsService,
  SearchResult,
} from '../../search/search-documents.service';

const roleData = [
  {key: SearchableDocumentColumn.REVIEWERS_IDS, display: 'Reviewer', many: true, icon: 'assignment'},
  {
    key: SearchableDocumentColumn.PEER_REVIEWERS_IDS,
    display: 'Peer Reviewer',
    many: true,
    icon: 'assignment',
  },
  {key: SearchableDocumentColumn.AUTHORS_IDS, display: 'Author', many: true, icon: 'create'},
  {key: SearchableDocumentColumn.LEAD_AUTHOR_ID, display: 'Lead Author', many: false, icon: 'create'},
  {key: SearchableDocumentColumn.DOCUMENT_OWNER_ID, display: 'Owner', many: false, icon: 'person'},
];

@Component({
  selector: 'cars-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss'],
})
export class DocumentsTableComponent implements OnInit, OnDestroy {
  public static readonly DEFAULT_PAGINATION_AND_SORT: Readonly<PaginationAndSortData> = {
    pageIndex: 0,
    pageSize: 25,
    sort: 'TITLE',
    direction: 'ASC',
  };

  @Input() publishedTable: boolean = false;

  public readonly Suite: typeof Suite = Suite;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public dataSource: MatTableDataSource<any>;

  public loading: boolean = true;
  public color: string = 'primary';

  public checkboxSettings: Record<string, boolean> = {
    excludeDeleted: true,
    userHasRoleOnly: true,
  };

  private _displayedColumns: string[] = [
    'SUITE',
    'DOCUMENT_CODE',
    'TITLE',
    'DOCUMENT_OWNER_NAME',
    'WORKFLOW_STATUS',
    'DOCUMENT_CONTROLS',
  ];
  private _publishedColumns: string[] = [
    'SUITE',
    'LEGACY_REFERENCE',
    'TITLE',
    'DOCUMENT_OWNER_NAME',
    'WORKFLOW_STATUS',
    'DOCUMENT_CONTROLS',
  ];
  public get displayedColumns(): string[] {
    return this.publishedTable ? this._publishedColumns : this._displayedColumns;
  }

  public roleData: any[] = roleData;
  public paginationOptions = [25, 50, 100, 1000];

  public tooltipDelay: number = environment.tooltipDelay;
  public creating: boolean = false;
  public destination: SearchableDocument;

  private currentFilterText: string;

  @ViewChild(MatInput, {static: true}) filter: MatInput;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  private dialogOpen = false;

  public pageSize: number;
  public columnSelected: string;
  public direction: string;
  public total: number;

  private _subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private userService: UserService,
    private roleService: RoleService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private _dialog: MatDialog,
    private searchDocumentsService: SearchDocumentsService
  ) {}

  /**
   * Initialises the documents table.
   */
  public ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.usePaginationAndSortDefaults();

    const savedPageSize = LocalConfigUtils.getConfig().documentPageSize;
    if (this.paginationOptions.indexOf(savedPageSize) === -1) {
      this.pageSize = this.paginationOptions[0];
    } else {
      this.pageSize = savedPageSize;
    }

    // Subscribe to the initial update of query params.
    this.route.queryParamMap.pipe(take(1)).subscribe((params: ParamMap) => {
      this.filter.value = params.get('filter');
      this.checkboxSettings.userHasRoleOnly = !this.publishedTable && params.get('assignedOnly') !== 'false';
      this.checkboxSettings.excludeDeleted = params.get('excludeDeleted') !== 'false';

      this.updateSearchFilter();
    });

    this.currentFilterText = this.filter.value;

    this._subscriptions.push(
      this.filter.stateChanges.pipe(debounceTime(200)).subscribe(() => {
        this.filterStateChanged();
      }),
      this.paginator.page.subscribe((page: PageEvent) => this.onPaginationChange(page)),
      this.searchDocumentsService
        .onSearchChanged()
        .pipe(debounceTime(1000))
        .subscribe(() => this.search()),
      this.router.events.subscribe(() => {
        this.creating = false;
      })
    );
  }

  public ngOnDestroy() {
    this.currentFilterText = '';
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public search(): void {
    this.color = 'primary';
    this.loading = true;
    const userId: UUID = this.checkboxSettings.userHasRoleOnly ? this.userService.getUser().id : null;

    this.searchDocumentsService
      .homepage(
        this.currentFilterText,
        userId,
        !this.checkboxSettings.excludeDeleted,
        this.publishedTable,
        this.getPaginationAndSortData()
      )
      .then((results: SearchResult<SearchableDocument>) => {
        results.page?.forEach((page) => {
          if (page.IFS_DOCUMENT_CODE) {
            page.DOCUMENT_CODE = page.IFS_DOCUMENT_CODE;
          }
        });
        this.dataSource.data = results.page;
        this.total = results.total;
        this.loading = false;
      })
      .catch((err) => {
        this.color = 'warn';
        setTimeout(() => this.search(), 3000);
      });
  }

  public onPaginationChange(event: PageEvent) {
    if (this.pageSize !== event.pageSize) {
      this.resetPageIndex();
    }
    this.pageSize = event.pageSize;
    LocalConfigUtils.setDocumentPageSize(event.pageSize);
    this.resetScrollBar();
    this.search();
  }

  public onSortChange(event: Sort) {
    this.direction = event.direction ? event.direction.toUpperCase() : null;
    this.columnSelected = this.direction ? event.active : null;
    this.resetPageIndex();
    this.search();
  }

  private getPaginationAndSortData(): PaginationAndSortData {
    return {
      pageIndex: this.paginator.pageIndex,
      pageSize: this.pageSize,
      sort: this.columnSelected,
      direction: this.direction,
    };
  }

  public resetPageIndex(): void {
    this.paginator.pageIndex = 0;
    this.resetScrollBar();
  }

  private usePaginationAndSortDefaults(): void {
    this.paginator.pageIndex = DocumentsTableComponent.DEFAULT_PAGINATION_AND_SORT.pageIndex;
    this.pageSize = DocumentsTableComponent.DEFAULT_PAGINATION_AND_SORT.pageSize;
    this.columnSelected = DocumentsTableComponent.DEFAULT_PAGINATION_AND_SORT.sort;
    this.direction = DocumentsTableComponent.DEFAULT_PAGINATION_AND_SORT.direction;
  }

  private resetScrollBar() {
    const table = document.querySelector('.mat-table');
    table.scrollTop = 0;
  }

  private filterStateChanged() {
    if (this.okToFilter()) {
      this.updateSearchFilter();
    }
  }

  private okToFilter(): boolean {
    return !this.filter.stateChanges.isStopped && this.currentFilterText !== this.filter.value;
  }

  /**
   * Called by the template, updates the internal filter of the DataSource.
   *
   * @param filterValue The term to filter by.
   */
  public updateSearchFilter(): void {
    this.currentFilterText = this.filter.value;
    this.resetPageIndex();
    this.search();
    this.updateQueryParams();
  }

  private updateQueryParams(): void {
    const queryParams = {
      filter: this.filter.value ? this.filter.value : null,
      assignedOnly: this.checkboxSettings.userHasRoleOnly,
      excludeDelete: this.checkboxSettings.excludeDeleted,
      creating: this.creating,
    };

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }

  public optionChange(): void {
    this.search();
    this.updateQueryParams();
  }

  /**
   * Routes to the selected document from the table.
   *
   * @param document {DocumentFragment} Document selected
   */
  public rowClick(document: SearchableDocument): void {
    if (!document.IS_DELETED && !this.dialogOpen) {
      this.destination = document;
      const path: string[] = ['documents', document.DOCUMENT_ID.value];
      if (this.inRole(document.REVIEWERS_IDS)) {
        path.push('versions');
      }
      this.router
        .navigate(path)
        .then((success: boolean) => {
          if (!success) {
            this.destination = null;
          }
        })
        .catch(() => (this.destination = null));
    }
  }

  /**
   * Prevents event propagation going up the tree. Used to prevent opening document after pressing enter on cancel in
   * delete document modal.
   */
  public preventEventPropagation(event: Event): void {
    event.stopPropagation();
  }

  /**
   * Delete the selected document.
   */
  public async deleteDocument(row: SearchableDocument): Promise<void> {
    this.dialogOpen = true;
    this._dialog
      .open(DialogComponent, {
        data: {
          title: 'Delete Document',
          message: 'Are you sure you want to delete ' + row.TITLE + '?',
          closeActions: [
            {title: 'Delete', tooltip: `Delete ${row.TITLE}`, response: 'delete'},
            {title: 'Cancel', tooltip: `Cancel deletion of ${row.TITLE}`, response: null},
          ],
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.documentService.delete(await this.documentService.fetchLatest(row.DOCUMENT_ID, {depth: 0}));
        }
        this.dialogOpen = false;
      });
  }

  /**
   * Routes to the newly created document.
   *
   * @param documentId {UUID} ID of document created
   */
  public documentCreated(documentId: UUID): void {
    this.router.navigate(['documents', documentId.value]).then((success: boolean) => {
      if (!success) {
        this.creating = false;
        this.destination = null;
      }
    });
  }

  /**
   * Converts arguments to a list and checks whether the current user is in that list.
   *
   * @param ids {UUID|UUID[]} A UUID or an array of UUIDs
   * @returns   {boolean}     True if current user is in the list
   */
  public inRole(ids: UUID | UUID[]): boolean {
    ids = ids instanceof Array ? ids : [ids];
    return ids.filter((id: UUID) => !!id).findIndex((id) => id.equals(this.userService.getUser().id)) > -1;
  }

  public canDeleteDocuments(): boolean {
    return this.roleService.isInGlobalRoles(GlobalRole.DELETE_DOCUMENTS);
  }
}
