<div
  *ngIf="content"
  class="main-content-editable"
  [class.reordering]="reordering"
  [class.dragging]="dragging"
  (window:mouseup)="onDrop($event)"
  (mousedown)="contentEditableClick($event)"
  #ceDirective="carsContentEditable"
  spellcheck="false"
  [carsContentEditable]="!readOnly && !reordering && (CarsAction.EDIT_PAD | carsEnabled: content.documentId | async)"
  [padType]="padType"
  disableDefaultFocus
>
  <ng-container *ngIf="!readOnly">
    <cars-alt-accessibility
      [altProperties]="{initialKey: 'w', key: null, condition: true}"
      (onSelect)="handleAltEvent()"
    ></cars-alt-accessibility>
  </ng-container>

  <!-- Top dragzone -->
  <div *ngIf="dragging" class="dragzone top" (mouseenter)="scroll(false)" (mouseleave)="stopScroll()"></div>

  <!-- Top dropzone -->
  <div *ngIf="dragging" class="dropzone" (mouseenter)="setHovering(null)"></div>

  <ng-container *ngFor="let child of listForDisplay">
    <span class="drag-helper" *ngIf="dragging && collapseSelection && isSelected(child)">
      {{ getSelectionLength() }}
    </span>
    <div
      [class.selected]="isSelected(child)"
      [class.dragging]="dragging && isSelected(child)"
      (click)="click(child, $event)"
      (mousedown)="mouseDown(child, $event)"
      (mouseenter)="setHovering(child)"
    >
      <ng-template
        [carsFragmentHost]="[child]"
        [readOnly]="readOnly"
        [currentView]="currentView"
        [padType]="padType"
      ></ng-template>
    </div>
  </ng-container>

  <!-- Bottom dropzone -->
  <div *ngIf="dragging" class="dragzone bottom" (mouseenter)="scroll(true)" (mouseleave)="stopScroll()"></div>
</div>
