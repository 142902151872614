<mat-progress-bar *ngIf="loading" mode="indeterminate" aria-label="Loading section background"></mat-progress-bar>
<a
  mat-raised-button
  color="primary"
  class="close-background-button"
  [routerLink]="closeRoute"
  (click)="setLoading()"
  aria-label="Close background view"
>
  <mat-icon class="mat-24">close</mat-icon>
  Close
</a>

<button
  mat-raised-button
  color="primary"
  class="print"
  (click)="print()"
  aria-label="Print background text"
  enableDefaultFocus
>
  <mat-icon class="mat-24">print</mat-icon>
  Print
</button>

<div *ngIf="!isLiveView()">
  <span class="version-tag">Version: {{ currentView.versionTag?.name }}</span>
</div>

<div class="body">
  <table>
    <tr>
      <th>Index</th>
      <th>Clause</th>
      <th>Background & commentary</th>
    </tr>
    <ng-container *ngFor="let clause of clauses">
      <tr>
        <td class="index-cell">
          <span [class]="ClauseType[clause.clauseType] | lowercase">{{ clauseDisplayIndex(clause) }}</span>
        </td>
        <td class="content-cell">
          <cars-clause
            [content]="clause"
            [readOnly]="true"
            [showCurrentClause]="false"
            [showMarginIcons]="false"
          ></cars-clause>
        </td>
        <td class="background-cell">
          <cars-background class="background" (savingEmitter)="saving = $event" [clause]="clause"></cars-background>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
