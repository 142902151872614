<button
  mat-icon-button
  *ngIf="!!currentView && !currentView.isChangelogAux()"
  (click)="$event.stopPropagation()"
  (mousedown)="$event.stopPropagation()"
  [disabled]="reorderingClauses || menuEntries.length === 0"
  [matMenuTriggerFor]="sectionMenu"
  matTooltipPosition="after"
  [matTooltip]="tooltip"
  [matTooltipShowDelay]="tooltipDelay"
>
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #sectionMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngFor="let menuEntry of menuEntries">
      <button
        mat-menu-item
        *ngIf="!menuEntry.routerLink"
        (click)="menuEntry.onClick()"
        [carsAnalytics]="menuEntry.carsAnalytics"
        [attr.aria-label]="menuEntry.ariaLabel()"
      >
        <mat-icon>{{ menuEntry.icon }}</mat-icon> {{ menuEntry.name }}
      </button>

      <a
        mat-menu-item
        *ngIf="menuEntry.routerLink"
        (click)="menuEntry.onClick()"
        [routerLink]="menuEntry.routerLink | async"
        [carsAnalytics]="menuEntry.carsAnalytics"
        [attr.aria-label]="menuEntry.ariaLabel()"
      >
        <mat-icon>{{ menuEntry.icon }}</mat-icon> {{ menuEntry.name }}
      </a>
    </ng-container>
  </ng-template>
</mat-menu>
