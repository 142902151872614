import {Component, Input, OnInit} from '@angular/core';
import {VersionDiscussionsAggregate} from 'app/dashboard/aggregates/version-discussions-aggregate';
import {Dashboard, DashboardService} from 'app/services/dashboard.service';
import {UUID} from 'app/utils/uuid';

export interface VersionAggregateSelectionIds {
  documentId: UUID;
  versionId: UUID;
  documentTitle: string;
  documentReference: string;
}

@Component({
  selector: 'cars-version-aggregates-list',
  templateUrl: './version-aggregates-list.component.html',
  styleUrls: ['./version-aggregates-list.component.scss'],
})
export class VersionAggregatesListComponent implements OnInit {
  @Input() documentId: UUID;
  @Input() dashboardType: Dashboard;

  public loading: boolean = false;
  public versionDiscussionsAggregates: VersionDiscussionsAggregate[] = [];

  constructor(private dashboardService: DashboardService) {}

  public ngOnInit() {
    this.loading = true;
    this.dashboardService
      .getVersionDiscussionsAggregates(this.documentId, this.dashboardType)
      .then((versionDiscussionsAggregates: VersionDiscussionsAggregate[]) => {
        this.versionDiscussionsAggregates = versionDiscussionsAggregates;
        this.loading = false;
      })
      .catch((error: any) => (this.loading = false));
  }
}
