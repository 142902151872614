import {UUID} from 'app/utils/uuid';

export class GlobalReference {
  public static deserialise(json: any): GlobalReference {
    const id: UUID = UUID.orThrow(json.id);
    const title: string = json.title;
    const reference: string = json.reference;
    const author: string = json.author;
    const publisher: string = json.publisher;
    const deleted: boolean = json.deleted;
    const withdrawn: boolean = json.withdrawn;
    const withdrawnOn: number = json.withdrawnOn;
    const carsDocumentId: UUID = UUID.orNull(json.carsDocumentId);

    return new GlobalReference(
      id,
      title,
      reference,
      author,
      publisher,
      withdrawn,
      withdrawnOn,
      deleted,
      carsDocumentId
    );
  }

  public static empty(): GlobalReference {
    return new GlobalReference(null, null);
  }

  constructor(
    public id: UUID,
    public title: string,
    public reference?: string,
    public author?: string,
    public publisher?: string,
    public withdrawn: boolean = false,
    public withdrawnOn?: number,
    public deleted: boolean = false,
    public carsDocumentId?: UUID
  ) {}

  public serialise(): any {
    const json: any = {
      id: this.id ? this.id.value : null,
      title: this.title,
      reference: this.reference,
      author: this.author,
      publisher: this.publisher,
      withdrawn: this.withdrawn,
      withdrawnOn: this.withdrawnOn,
      deleted: this.deleted,
      carsDocumentId: this.carsDocumentId ? this.carsDocumentId.value : null,
    };

    if (!json.id) {
      delete json.id;
    }

    return json;
  }

  /**
   * Ignores the id field, and compares the values of all other fields on the
   * object.
   *
   * @param other the other reference.
   */
  public equals(other: GlobalReference): boolean {
    const process: Function = (value: string): string => {
      return value ? value.toLowerCase().trim() : null;
    };

    for (const field of Object.keys(this)) {
      if (field !== 'id' && process(this[field]) !== process(other[field])) {
        return false;
      }
    }
    return true;
  }

  /**
   * Format this reference in the style required for display in the bibliography.
   * @param normative if true, format as a normative reference.  Otherwise, format as an informative reference.
   * @param release optional string which indicates the year of release.
   * @returns a formatted string.
   */
  public format(release?: string): string {
    return `${this.publisher ? this.publisher + '. ' : ''}
            ${this.author ? this.author + '. ' : ''}
            ${this.reference ? this.reference + ', ' : ''}
            '${this.title}'
            ${release ? ', ' + release : ''}`;
  }
}
