import {Injectable} from '@angular/core';
import {Caret} from 'app/fragment/caret';
import {ClipboardFormat} from 'app/fragment/core/parser/fragment-parser';
import {
  ClauseFragment,
  ClauseType,
  DocumentReferenceFragment,
  FigureFragment,
  Fragment,
  FragmentType,
  InlineReferenceFragment,
  ReferenceType,
} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {ClauseGroupType} from 'app/fragment/types/clause-group-type';
import {ImageService} from 'app/services/image.service';
import {UUID} from 'app/utils/uuid';
import {DomService} from '../dom.service';
import {FragmentService} from '../fragment.service';
import {ReferenceService} from '../references/reference.service';
import {ClipboardJson, ReferenceProperties} from './clipboard-json';

@Injectable({
  providedIn: 'root',
})
export class CopyPasteService {
  constructor(
    private _domService: DomService,
    private _fragmentService: FragmentService,
    private _imageService: ImageService,
    private _referenceService: ReferenceService
  ) {}

  public copy(event: ClipboardEvent, toCopy: Selection | Fragment, plainText: string, htmlText: string): void {
    event.preventDefault();
    event.stopPropagation();

    if (toCopy instanceof Selection && toCopy.isCollapsed) {
      return;
    }

    event['clipboardData'].setData(ClipboardFormat.TEXT_PLAIN, plainText);
    event['clipboardData'].setData(ClipboardFormat.TEXT_HTML, htmlText);

    this._addToLocalStorage(toCopy, plainText);
  }

  /**
   * Returns true if the user can copy the given selection.
   * Users can copy outside of a clause group, or normal text fragments from within an input fragment in a clause group.
   * These conditions are:
   *  entirely within an input area OR
   *  (not within a clause group AND
   *  the selection does not contain a clause group)
   */
  public canCopy(selection: Selection): boolean {
    const [start, end]: Caret[] = this._domService.getCaretsFromSelection(selection);
    if (!start.fragment || !end.fragment) {
      return false;
    }

    const ancestor: Fragment = Fragment.commonAncestorOf(start.fragment, end.fragment);
    const clause: ClauseFragment = ancestor.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const clauseGroup: ClauseGroupFragment = clause?.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    const isContainedInInput: boolean = !!ancestor.findAncestorWithType(FragmentType.INPUT);
    const isContainedInSingleFreeTextNDRClause: boolean =
      !!clause &&
      !clause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) &&
      !clause.isUnmodifiableClause &&
      clauseGroup?.clauseGroupType === ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT;
    const isContainedInOrContainsRestrictedContent: boolean =
      clause?.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) ||
      clause?.isUnmodifiableClause ||
      (!!clauseGroup && !ancestor.findAncestorWithType(FragmentType.TABLE, FragmentType.LIST)) ||
      this._containsRestrictedContent(ancestor, start.fragment, end.fragment);

    return isContainedInInput || isContainedInSingleFreeTextNDRClause || !isContainedInOrContainsRestrictedContent;
  }

  /**
   * Returns true if a clause group or specifier instruction clause exists between the start and end fragments on the ancestor
   */
  private _containsRestrictedContent(ancestor: Fragment, start: Fragment, end: Fragment): boolean {
    let selectionContainsRestrictedContent: boolean = false;

    ancestor.iterateDown(start, end, (fragment: Fragment) => {
      if (
        fragment.is(FragmentType.CLAUSE_GROUP, FragmentType.READONLY) ||
        fragment.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) ||
        (fragment.is(FragmentType.CLAUSE) && (fragment as ClauseFragment).isUnmodifiableClause)
      ) {
        selectionContainsRestrictedContent = true;
      }
    });

    return selectionContainsRestrictedContent;
  }

  private _addToLocalStorage(toCopy: Selection | Fragment, plainText: string): void {
    const json: ClipboardJson = ClipboardJson.createWithHashes(plainText);

    if (toCopy instanceof Selection) {
      const [start, end]: Caret[] = this._domService.getCaretsFromSelection(toCopy);
      this._addSubtreeFromCarets(start, end, json);
    } else {
      this._addSubtreeFromFragment(toCopy, json);
    }

    json.save();
  }

  private _addSubtreeFromCarets(start: Caret, end: Caret, json: ClipboardJson): void {
    const jsonTree: object[] = [];
    const flatTree: object[] = [];
    const referenceRecord: Record<string, ReferenceProperties> = {};

    if (!start.fragment || !end.fragment) {
      json.setSubtreeAndReferenceRecord([], [], referenceRecord);
      return;
    }

    if (start.fragment.equals(end.fragment)) {
      const serialised: object = start.fragment.serialise();
      this._trimStartAndEndFragments(start, end, serialised);
      json.setSubtreeAndReferenceRecord([serialised], [serialised], referenceRecord);
      return;
    }

    const ancestor: Fragment = Fragment.commonAncestorOf(start.fragment, end.fragment);

    if (!ancestor) {
      json.setSubtreeAndReferenceRecord(flatTree, jsonTree, referenceRecord);
      return;
    }

    ancestor.iterateDown(start.fragment, end.fragment, (fragment: Fragment) => {
      if ((!fragment.equals(ancestor) || fragment.is(FragmentType.LIST)) && !fragment.is(FragmentType.ANCHOR)) {
        const serialised: object = fragment.serialise();

        if (fragment.is(FragmentType.CLAUSE)) {
          serialised['value'] = '';
        } else {
          this._trimStartAndEndFragments(start, end, serialised);
        }

        this._addToTree(serialised, jsonTree, flatTree, referenceRecord);
      }
    });

    json.setSubtreeAndReferenceRecord(flatTree, jsonTree, referenceRecord);
  }

  private _trimStartAndEndFragments(start: Caret, end: Caret, serialised: object): void {
    if (serialised['id'] === end.fragment.id.value) {
      serialised['value'] = serialised['value'].slice(0, end.offset);
    }

    if (serialised['id'] === start.fragment.id.value) {
      serialised['value'] = serialised['value'].slice(start.offset, serialised['value'].length);
    }
  }

  private _addSubtreeFromFragment(fragment: Fragment, json: ClipboardJson): void {
    const jsonTree: object[] = [];
    const flatTree: object[] = [];
    const referenceRecord: Record<string, ReferenceProperties> = {};

    fragment.iterateDown(null, null, (frag: Fragment) =>
      this._addToTree(frag.serialise(), jsonTree, flatTree, referenceRecord)
    );

    json.setSubtreeAndReferenceRecord(flatTree, jsonTree, referenceRecord);
  }

  private _addToTree(
    serialised: object,
    jsonTree: object[],
    flatTree: object[],
    referenceRecord: Record<string, ReferenceProperties>
  ): void {
    serialised['children'] = [];
    const parent: object = flatTree.find((f) => f['id'] === serialised['parentId']);
    if (parent) {
      parent['children'].push(serialised);
    } else {
      jsonTree.push(serialised);
    }

    if (serialised['type'] === 'INLINE_REFERENCE') {
      const documentReferenceFragment: DocumentReferenceFragment = this._fragmentService.find(
        UUID.orNull(serialised['documentReference'])
      ) as DocumentReferenceFragment;
      referenceRecord[documentReferenceFragment.id.value] = {
        globalReferenceId: documentReferenceFragment.globalReference.value,
        referenceType: documentReferenceFragment.referenceType,
        yearOfIssue: documentReferenceFragment.release,
      };
      serialised['globalReferenceWithdrawnWithYearOfIssue'] = false;
      serialised['globalReferenceWithdrawnWithoutYearOfIssue'] = false;
    }

    flatTree.push(serialised);
  }

  public getFragmentsToPaste(): Promise<Fragment[]> {
    this.setCursorStyle(true);

    const json: ClipboardJson = ClipboardJson.getCurrent();
    const fragments: Fragment[] = json.getFragments();

    const {oldDocRefToNewDocRef, referencesToCreate} = this._handleReferencesOnPaste(json);

    const reuploadPromises: Promise<void>[] = [];

    fragments.forEach((fragment: Fragment) => {
      fragment.iterateDown(null, null, (f: Fragment) => {
        if (f.is(FragmentType.FIGURE)) {
          const figure: FigureFragment = f as FigureFragment;
          reuploadPromises.push(
            this._imageService.reUploadImage(figure.uploadId).then((newId: UUID) => {
              figure.uploadId = newId;
            })
          );
        } else if (f.is(FragmentType.INLINE_REFERENCE)) {
          const inlineReference: InlineReferenceFragment = f as InlineReferenceFragment;
          inlineReference.documentReference = oldDocRefToNewDocRef[inlineReference.documentReference.value];
        }
      });
    });

    return Promise.all([...referencesToCreate, ...reuploadPromises]).then(() => fragments);
  }

  private _handleReferencesOnPaste(json: ClipboardJson): {
    oldDocRefToNewDocRef: Record<string, UUID>;
    referencesToCreate: Promise<void>[];
  } {
    const oldDocRefToNewDocRef: Record<string, UUID> = {};
    const referencesToCreate: Promise<void>[] = [];

    Object.entries(json.getReferenceRecord()).forEach(([key, entry]: [string, ReferenceProperties]) => {
      const globalReferenceId: UUID = UUID.orThrow(entry.globalReferenceId);
      const existingDocumentReference: DocumentReferenceFragment =
        this._referenceService.getDocumentReferenceFragment(globalReferenceId);
      if (existingDocumentReference) {
        oldDocRefToNewDocRef[key] = existingDocumentReference.id;
      } else {
        const newDocumentReference: DocumentReferenceFragment = new DocumentReferenceFragment(
          null,
          ReferenceType[entry.referenceType],
          globalReferenceId,
          entry.yearOfIssue ? entry.yearOfIssue : null
        );
        referencesToCreate.push(this._referenceService.createDocumentReference(newDocumentReference));
        oldDocRefToNewDocRef[key] = newDocumentReference.id;
      }
    });

    return {oldDocRefToNewDocRef, referencesToCreate};
  }

  public setCursorStyle(loading: boolean): void {
    document.body.style.cursor = loading ? 'wait' : 'auto';
  }
}
