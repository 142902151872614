import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DocumentData, DocumentRole} from 'app/documents/document-data';
import {DocumentFragment} from 'app/fragment/types';
import {BaseService} from 'app/services/base.service';
import {DocumentService} from 'app/services/document.service';
import {AuthenticationProvider, GlobalRole} from 'app/services/user/authentication-provider';
import {User} from 'app/user/user';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService {
  private static readonly USERS_ENDPOINT: string = `${environment.apiHost}/users`;

  constructor(
    protected _snackBar: MatSnackBar,
    private _http: HttpClient,
    private _authenticationProvider: AuthenticationProvider,
    private _documentService: DocumentService
  ) {
    super(_snackBar);
  }

  /**
   * Defers to the {@link AuthenticationProvider}.
   */
  public isInGlobalRoles(...roles: GlobalRole[]): boolean {
    return this._authenticationProvider.userInRoles(...roles);
  }

  public getGlobalRoles(): Promise<GlobalRole[]> {
    return this._authenticationProvider.getCurrentGlobalRoles().toPromise();
  }

  /**
   * Returns true if the current user is in one of the given Document Roles within the given document.
   *
   * @param document      {DocumentFragment?} Document to check. Defaults to selected document if none given.
   * @param documentRoles {DocumentRole[]}    Document Roles to check
   * @returns             {boolean}           True if user is in Document Role set given
   */
  public isInDocumentRole(document?: DocumentFragment, ...documentRoles: DocumentRole[]): boolean {
    document = document ? document : this._documentService.getSelected();
    let inRole: boolean = false;

    if (document) {
      const documentData: DocumentData = document.documentData;

      for (const documentRole of documentRoles) {
        if (inRole === true) {
          return true;
        }
        switch (documentRole) {
          case DocumentRole.OWNER:
            {
              inRole = this._userIn(documentData.owner);
            }
            break;

          case DocumentRole.LEAD_AUTHOR:
            {
              inRole = this._userIn(documentData.leadAuthor);
            }
            break;

          case DocumentRole.AUTHOR:
            {
              inRole = this._userIn(documentData.authors);
            }
            break;

          case DocumentRole.REVIEWER:
            {
              inRole = this._userIn(documentData.reviewers);
            }
            break;

          case DocumentRole.PEER_REVIEWER:
            {
              inRole = this._userIn(documentData.peerReviewers);
            }
            break;
        }
      }
    }
    return inRole;
  }

  /**
   * Returns currently logged in users role within the selected document.
   *
   * @param document {DocumentFragment} The document to check user is in. Defaults to the selected document
   * @returns        {DocumentRole}     The role the current user is assigned to on the document
   */
  public getUserRoleInDocument(document: DocumentFragment = this._documentService.getSelected()): DocumentRole {
    let role: DocumentRole = null;

    if (document) {
      const documentData: DocumentData = document.documentData;
      const documentRoles: string[] = Object.values(DocumentRole);

      for (const documentRole of documentRoles) {
        if (role !== null) {
          return role;
        }
        switch (documentRole) {
          case DocumentRole.OWNER:
            {
              role = this._userIn(documentData.owner) ? DocumentRole.OWNER : null;
            }
            break;

          case DocumentRole.LEAD_AUTHOR:
            {
              role = this._userIn(documentData.leadAuthor) ? DocumentRole.LEAD_AUTHOR : null;
            }
            break;

          case DocumentRole.AUTHOR:
            {
              role = this._userIn(documentData.authors) ? DocumentRole.AUTHOR : null;
            }
            break;

          case DocumentRole.REVIEWER:
            {
              role = this._userIn(documentData.reviewers) ? DocumentRole.REVIEWER : null;
            }
            break;

          case DocumentRole.PEER_REVIEWER:
            {
              role = this._userIn(documentData.peerReviewers) ? DocumentRole.PEER_REVIEWER : null;
            }
            break;
        }
      }
    }
    return role;
  }

  /**
   * Retrieves all users in the given document role.
   *
   * @param role    {DocumentRole}   Document role to query
   * @param message {message?}       Optional error message to display
   * @returns       {Promise<User[]} Promise resolving to an array of users
   */
  public getUsersByRole(role: DocumentRole, message?: string): Promise<User[]> {
    message = message || `Couldn\'t retrieve users in role: ${role}`;

    return this._http
      .get(`${RoleService.USERS_ENDPOINT}/?role=${role}`)
      .pipe(map((response: any) => response.map((user) => User.deserialise(user))))
      .toPromise()
      .catch((err: any) => {
        this._handleError(err, message, 'user-retrieval-error');
        throw err;
      });
  }

  /**
   * Converts argument into list and checks if the current user is in the list.
   *
   * @param ids {UUID|UUID[]} A UUID or an array of UUIDs
   * @returns   {boolean}     True if current user is in list
   */
  private _userIn(ids: UUID | UUID[]): boolean {
    const _userId: UUID = this._authenticationProvider.getCurrentUser().id;
    ids = this._toArray(ids);
    return ids.some((id: UUID) => id.equals(_userId));
  }
}
