import {Injectable} from '@angular/core';
import {UUID} from 'app/utils/uuid';
import {Observable, Subject} from 'rxjs';

export interface SearchEvent {
  docId: UUID;
  searchString: string;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private _searchSubject: Subject<SearchEvent> = new Subject();

  constructor() {}

  public searchDocument(s: string, docId: UUID): void {
    this._searchSubject.next({docId: docId, searchString: s});
  }

  public onSearchDocumentEvent(): Observable<SearchEvent> {
    return this._searchSubject;
  }
}
