import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {VersionSettingsComponent} from './version-settings.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatCheckboxModule, FormsModule],
  declarations: [VersionSettingsComponent],
  exports: [VersionSettingsComponent],
})
export class VersionSettingsModule {}
