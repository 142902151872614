<div *ngIf="CarsAction.CAN_VIEW_LIVE_DOCUMENT | carsEnabled: document?.id | async" class="buttons">
  <a mat-raised-button color="primary" [routerLink]="liveDocumentRoute" aria-label="Return to the live document"
    >Back to live document</a
  >
</div>

<div class="versions-container">
  <span class="title">{{ document.title }} - Versions</span>
  <div class="table-container mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear aria-label="Available versions">
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <!--Title column -->
      <ng-container matColumnDef="name">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="Order by version name"
          matTooltip="Order by version name"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
        >
          Version name
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.versionTag.name }} </mat-cell>
      </ng-container>

      <!--Version type column -->
      <ng-container matColumnDef="versionTagType">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Order by version type"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
        >
          Version type
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.versionTag.versionTagType | versionTagTypeDisplayName }}
        </mat-cell>
      </ng-container>

      <!--Version number column -->
      <ng-container matColumnDef="versionNumber">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Order by version number"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
        >
          Version number
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.versionTag.versionNumber }}
        </mat-cell>
      </ng-container>

      <!-- Versioned by column -->
      <ng-container matColumnDef="createdBy">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Order by versioned by"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
        >
          Versioned by
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <cars-user [user]="row.user"></cars-user>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Order by versioned at"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
        >
          Versioned at
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.versionTag.createdAt | date: dateFormat }}</mat-cell>
      </ng-container>

      <!-- Available to review column -->
      <ng-container matColumnDef="availableToReview">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Order by available to review"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
        >
          Available to reviewers
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            [disabled]="!canUpdateVersionTag"
            [(ngModel)]="row.versionTag.availableToReview"
            (ngModelChange)="availableForReviewChanges(row)"
            (click)="$event.stopPropagation()"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Available to comment column -->
      <ng-container matColumnDef="availableForCommenting">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Order by available to comment"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
        >
          Allow discussions and comments
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            [disabled]="!canUpdateVersionTag"
            [(ngModel)]="row.versionTag.availableForCommenting"
            (ngModelChange)="availableForCommentingChanges(row)"
            (click)="$event.stopPropagation()"
            aria-label="Available to comment"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- User discussions only column -->
      <ng-container matColumnDef="userDiscussionsOnly">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Order by disabled collaborative review features"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
        >
          Forbid reviewers from seeing and commenting on others' discussions
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            color="warn"
            [disabled]="!canUpdateVersionTag"
            [(ngModel)]="row.versionTag.userDiscussionsOnly"
            (ngModelChange)="userDiscussionsOnlyChanges(row)"
            (click)="$event.stopPropagation()"
            aria-label="Disable collaborative review features"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Offline column -->
      <ng-container matColumnDef="offline">
        <mat-header-cell *matHeaderCellDef> Download for offline review</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <cars-offline-version-status
            [version]="row.versionTag"
            (click)="$event.stopPropagation()"
          ></cars-offline-version-status>
        </mat-cell>
      </ng-container>

      <!-- revert document -->
      <ng-container matColumnDef="revertDocument">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Order by document version"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
        >
          Revert document to this version
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button
            mat-raised-button
            color="primary"
            aria-label="Revert document to version {row.versionTag.name}"
            enableDefaultFocus
            (click)="revertDocument($event, row.versionTag)"
          >
            Revert
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="loadingSpinner">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-spinner
            *ngIf="row.versionTag.versionId.equals(destination?.versionId)"
            aria-label="Opening version"
          ></mat-spinner>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        tabindex="0"
        (keyup.enter)="navigateToVersion(row)"
        (click)="navigateToVersion(row)"
        [attr.aria-label]="'Open version ' + row.versionTag.name"
        disableDefaultFocus
      ></mat-row>
    </mat-table>

    <div *ngIf="!loading && !dataSource.data.length" class="no-versions-container">No versions available</div>

    <mat-progress-bar *ngIf="loading" mode="indeterminate" aria-label="Loading versions"></mat-progress-bar>
  </div>
</div>
