import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ExtendedTimeAgoModule} from 'app/utils/pipes/extended-time-ago.module';
import {MapPipeModule} from 'app/utils/pipes/map-pipe.module';
import {UserModule} from 'app/widgets/user/user.module';
import {UserLocationsComponent} from './user-locations.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, MapPipeModule, ExtendedTimeAgoModule, UserModule],
  declarations: [UserLocationsComponent],
  exports: [UserLocationsComponent],
})
export class UserLocationsModule {}
