import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {RouterModule} from '@angular/router';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {SectionReferenceHovertipComponent} from './section-reference-hovertip.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, RouterModule, MatButtonModule, CarsPermissionPipesModule],
  declarations: [SectionReferenceHovertipComponent],
  exports: [SectionReferenceHovertipComponent],
})
export class SectionReferenceHovertipModule {}
