<button
  mat-icon-button
  class="close-button"
  (click)="close()"
  matTooltip="Close deletion manager"
  matTooltipPosition="before"
  [matTooltipShowDelay]="tooltipDelay"
  carsAnalytics="changelog-close-deletion-manager"
  aria-label="Close deletion manager"
>
  <mat-icon>clear</mat-icon>
</button>
<table>
  <tr>
    <td class="index-column">Document:</td>
    <td>
      {{ range.documentTitle }}
    </td>
  </tr>
  <tr>
    <td class="index-column">Excerpt:</td>
    <td class="excerpt">
      <div class="scrollable">
        {{ range.value }}
      </div>
    </td>
  </tr>
</table>

<div class="comment">
  <span>Comment:</span>
  <textarea id="deletionComment" [(ngModel)]="deletionComment" matInput rows="6" placeholder="Comment (optional)">
  </textarea>
</div>
<button
  mat-raised-button
  (click)="close()"
  carsAnalytics="changelog-cancel-mark-range-deleted"
  aria-label="Cancel marking as deleted"
>
  Cancel
</button>
<button
  mat-raised-button
  color="primary"
  (click)="save()"
  carsAnalytics="changelog-mark-range-deleted"
  aria-label="Mark range as deleted"
>
  Apply
</button>
