import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {DocumentRole} from 'app/documents/document-data';
import {DocumentFragment} from 'app/fragment/types';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionRequest, VersionTag} from 'app/interfaces';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentService} from 'app/services/document.service';
import {RoleService} from 'app/services/user/role.service';
import {UserService} from 'app/services/user/user.service';
import {User} from 'app/user/user';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {environment} from 'environments/environment';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {GlobalRole} from '../services/user/authentication-provider';

interface DocumentVersionTableEntry {
  versionTag: VersionTag;
  user: User;
}

@Component({
  selector: 'cars-document-versions',
  templateUrl: './document-versions.component.html',
  styleUrls: ['./document-versions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentVersionsComponent implements OnInit, AfterViewInit {
  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly dateFormat: string = 'MMM dd yyyy HH:mm';

  public readonly CarsAction: typeof CarsAction = CarsAction;

  public document: DocumentFragment;
  public loading: boolean;

  public canUpdateVersionTag: boolean;

  public liveDocumentRoute: string;
  public destination: VersionTag;

  public displayedColumns: string[];
  public dataSource: MatTableDataSource<DocumentVersionTableEntry> = new MatTableDataSource<DocumentVersionTableEntry>(
    []
  );
  @ViewChild(MatSort) public sort: MatSort;

  private _showOnlyAvailableToReview: boolean;
  private _isOffline: boolean = LocalConfigUtils.getConfig().offline;
  private _canRevert: boolean = false;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _documentService: DocumentService,
    private _versioningService: VersioningService,
    private _roleService: RoleService,
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _permissionService: PermissionsService
  ) {}

  public ngOnInit(): void {
    this.document = this._documentService.getSelected();

    if (this._isOffline) {
      this.displayedColumns = this._buildDisplayedColumns();
    } else {
      this._subscriptions.push(
        this._permissionService
          .can(CarsAction.CAN_REVERT_DOCUMENT, this.document.documentId)
          .subscribe((canRevert: boolean) => {
            this._canRevert = canRevert;
            this.displayedColumns = this._buildDisplayedColumns();
          })
      );
    }

    this._showOnlyAvailableToReview = this._roleService.isInDocumentRole(this.document, DocumentRole.REVIEWER);
    this.canUpdateVersionTag =
      this._roleService.isInDocumentRole(
        this.document,
        DocumentRole.AUTHOR,
        DocumentRole.LEAD_AUTHOR,
        DocumentRole.OWNER
      ) && !this._isOffline;

    this.liveDocumentRoute = '/documents/'.concat(this._route.snapshot.paramMap.get('document_id'));

    this._getDocumentVersions();
  }

  public ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = (data: DocumentVersionTableEntry, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'createdBy':
          const user: User = data.user;
          return user.name;
        default:
          return data.versionTag[sortHeaderId];
      }
    };
    this.dataSource.sort = this.sort;
  }

  private _buildDisplayedColumns(): string[] {
    const cols: string[] = [
      'name',
      'versionTagType',
      'versionNumber',
      'createdBy',
      'createdAt',
      'availableToReview',
      'availableForCommenting',
      'userDiscussionsOnly',
    ];

    const canUseOffline: boolean = this._roleService.isInGlobalRoles(GlobalRole.OFFLINE_MODE);

    if (!this._isOffline && canUseOffline) {
      cols.push('offline');
    }

    if (this._canRevert) {
      cols.push('revertDocument');
    }

    cols.push('loadingSpinner');

    return cols;
  }

  private _getDocumentVersions(): void {
    this.loading = true;
    this._versioningService
      .getVersionTagsForFragmentId(this.document.id, this._showOnlyAvailableToReview)
      .pipe(switchMap((versionTags: VersionTag[]) => this._buildTableEntries(versionTags)))
      .subscribe((entries: DocumentVersionTableEntry[]) => {
        this.dataSource.data = entries;
        this.loading = false;
      });
  }

  private _buildTableEntries(versionTags: VersionTag[]): Observable<DocumentVersionTableEntry[]> {
    return versionTags.length === 0
      ? of([])
      : combineLatest(
          versionTags.map((versionTag: VersionTag) =>
            this._userService.getUserFromId(versionTag.createdBy).pipe(
              map((user: User) => {
                return {
                  versionTag,
                  user: user ?? User.unknown(),
                };
              })
            )
          )
        );
  }

  /**
   * Navigates to document overview for the version selected.
   *
   * @param versionTag {VersionTag} Version id to fetch
   */
  public navigateToVersion(tableEntry: DocumentVersionTableEntry): void {
    this.destination = tableEntry.versionTag;
    this._router.navigate([`${tableEntry.versionTag.versionId.value}`], {relativeTo: this._route}).then((success) => {
      if (!success) {
        this.destination = null;
      }
    });
  }

  /**
   * Responds to changes to the document being available for review.
   * Setting availableToReview to false implies availableForCommenting is false.
   *
   * @param versionTag {VersionTag} Version tag to update
   */
  public availableForReviewChanges(tableEntry: DocumentVersionTableEntry): void {
    const versionTag: VersionTag = tableEntry.versionTag;
    if (!versionTag.availableToReview) {
      versionTag.availableForCommenting = false;
      versionTag.userDiscussionsOnly = false;
    }
    this._updateCollaborativeProperties(versionTag);
  }

  /**
   * Responds to changes to the document being available for commenting.
   * Setting availableForCommenting to true implies availableToReview is true.
   * Setting availableForCommenting to false implies userDiscussionsOnly is false.
   *
   * @param versionTag {VersionTag} Version tag to update
   */
  public availableForCommentingChanges(tableEntry: DocumentVersionTableEntry): void {
    const versionTag: VersionTag = tableEntry.versionTag;
    versionTag.availableForCommenting
      ? (versionTag.availableToReview = true)
      : (versionTag.userDiscussionsOnly = false);
    this._updateCollaborativeProperties(versionTag);
  }

  /**
   * Responds to changes to the document displaying only discussions raised by the user.
   * Setting userDiscussionsOnly to true implies availableToReview && availableForCommenting is true.
   *
   * @param versionTag {VersionTag} Version tag to update
   */
  public userDiscussionsOnlyChanges(tableEntry: DocumentVersionTableEntry): void {
    const versionTag: VersionTag = tableEntry.versionTag;
    if (versionTag.userDiscussionsOnly) {
      versionTag.availableToReview = true;
      versionTag.availableForCommenting = true;
    }
    this._updateCollaborativeProperties(versionTag);
  }

  /**
   * Updates versionTag.
   *
   * @param versionTag {VersionTag} VersionTag to update
   */
  private _updateCollaborativeProperties(versionTag: VersionTag): void {
    this._versioningService.updateCollaborativeProperties(
      versionTag.versionId,
      versionTag.availableForCommenting,
      versionTag.availableToReview,
      versionTag.userDiscussionsOnly
    );
  }

  /**
   * Revert a document to a previous version
   * @param event
   * @param versionId
   */
  public revertDocument(event: any, versionTag: VersionTag): void {
    event.stopPropagation();
    this._dialog
      .open(DialogComponent, {
        ariaLabel: `Are you sure you want to revert to version ${versionTag.name}`,
        data: {
          title: 'Revert version',
          message: 'This feature should be used with caution. Are you sure you want to revert to the selected version?',
          closeActions: [
            {
              title: 'Yes',
              tooltip: 'Revert to this version',
              response: true,
              color: 'warn',
            },
            {
              title: 'No',
              tooltip: `Cancel revert`,
              response: false,
            },
          ],
        },
      })
      .afterClosed()
      .subscribe((action: boolean) => {
        if (action === true) {
          const request: VersionRequest = {
            fragmentId: this.document.id.value,
            name: 'Auto-generated snapshot of live document prior to reversion back to ' + versionTag.versionNumber,
            availableToReview: false,
            availableForCommenting: false,
            userDiscussionsOnly: false,
            versionTagType: VersionTagType.REVERT_SNAPSHOT,
            shouldCreateTag: true,
          };
          this._versioningService.revertDocumentToVersion(request, versionTag.versionId);
        }
      });
  }
}
