import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

/**
 * Expects to be extended by a class with an @Component annotation.
 * See {@link UserHovertipComponent} and {@link ReferenceHovertipComponent}.
 */
@Component({
  template: '',
})
export abstract class AbstractHovertipComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public anchorElement: HTMLElement;
  @Input() public event: MouseEvent;
  @ViewChild('hoverTipRef') public hoverTipRef: ElementRef; // The HTML template will need to contain this child

  protected _componentRef: ComponentRef<AbstractHovertipComponent>;
  protected _timeout: number = 200;
  protected _subscriptions: Subscription[] = [];

  protected body: HTMLElement = document.getElementsByTagName('body')[0];

  protected readonly leftOffset: number = 0; // Will most likely need overriding
  protected readonly topOffset: number = 0; // Will most likely need overriding

  public destroy: Subject<void> = new Subject();

  protected dismissTimeout;
  protected hoveredOver: boolean = true;

  protected scrollListener: EventListener = (event: MouseEvent) => {
    this.setPosition();
  };

  protected leaveListener: EventListener = (event: MouseEvent) => {
    this.hoveredOver = false;
    this.checkMouseLocation(event);
  };

  protected enterListener: EventListener = (event: MouseEvent) => {
    this.hoveredOver = true;
    this.dismissTimeout = null;
  };

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {}

  /**
   * Sets event listeners and sets initial position of the hovertip.
   */
  public ngOnInit(): void {
    this.renderer.appendChild(this.body, this.elementRef.nativeElement);

    document.addEventListener('scroll', this.scrollListener, true);

    this.anchorElement.addEventListener('mouseleave', this.leaveListener);
    this.elementRef.nativeElement.addEventListener('mouseleave', this.leaveListener);
    this.anchorElement.addEventListener('mouseenter', this.enterListener);
    this.elementRef.nativeElement.addEventListener('mouseenter', this.enterListener);
  }

  /**
   * Sets the position of the hovertip after the components view has been initialised.
   */
  public ngAfterViewInit(): void {
    this.setPosition();
  }

  /**
   * Removes the event listeners on the elements.
   */
  public ngOnDestroy(): void {
    document.removeEventListener('scroll', this.scrollListener, true);
    this.anchorElement.removeEventListener('mouseleave', this.leaveListener);
    this.elementRef.nativeElement.removeEventListener('mouseleave', this.leaveListener);
    this.anchorElement.removeEventListener('mouseenter', this.enterListener);
    this.elementRef.nativeElement.removeEventListener('mouseenter', this.enterListener);

    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
    this.renderer.removeChild(this.body, this.elementRef.nativeElement);
  }

  /**
   * Setter for the component reference.
   * Subscribes to destroy events and mannually calls destroy.
   *
   * @param ref {ComponentRef<AbstractHovertip>} Reference to its component
   */
  public set componentRef(ref: ComponentRef<AbstractHovertipComponent>) {
    this._componentRef = ref;
    this._subscriptions.push(
      this._componentRef.instance.destroy.subscribe(() => {
        this._componentRef.destroy();
      })
    );
  }

  /**
   * Sets the position of the hovertip on init and scroll.
   */
  protected setPosition(): void {
    const anchorPosition: DOMRect = this.anchorElement.getBoundingClientRect();
    const above: boolean =
      this.event.clientY >
      window.innerHeight - this.anchorElement.offsetHeight - this.hoverTipRef.nativeElement.offsetHeight - 100;

    this.hoverTipRef.nativeElement.classList[above ? 'add' : 'remove']('above');
    const top: number = above
      ? anchorPosition.bottom -
        this.hoverTipRef.nativeElement.offsetHeight -
        this.anchorElement.offsetHeight -
        this.topOffset
      : anchorPosition.bottom + this.topOffset;

    this.hoverTipRef.nativeElement.style.left = anchorPosition.left + this.leftOffset + 'px';
    this.hoverTipRef.nativeElement.style.top = top + 'px';
  }

  /**
   * Starts the timer to destroy the component.
   *
   * @param event {MouseEvent} The mouse event
   */
  protected checkMouseLocation(event: MouseEvent) {
    if (!this.dismissTimeout) {
      this.dismissTimeout = setTimeout(() => {
        if (!this.hoveredOver) {
          this.destroy.next();
          this.destroy.complete();
          this.dismissTimeout = null;
        }
      }, this._timeout);
    }
  }
}
