<a
  mat-raised-button
  *ngIf="isClosable"
  color="primary"
  class="close-print-preview-button"
  [routerLink]="closeRoute"
  aria-label="Close print preview"
>
  <mat-icon class="mat-24">close</mat-icon>
  Close
</a>
<div *ngIf="currentView.showSectionPadHeader()" class="header-container">
  <span
    class="review-title"
    *ngIf="currentView.showReviewerInfo()"
    matTooltip="You are a reviewer for this document. You can raise and comment against discussions, but you cannot make any changes to the section or any background commentary"
    matTooltipPosition="below"
    matTooltipShowDelay="50"
  >
    Review Mode
  </span>
  <span *ngIf="currentView.versionTag" class="version-tag">Version: {{ currentView.versionTag?.name }}</span>
</div>
<ng-container *ngIf="section">
  <ng-container *ngIf="showSectionPad()">
    <cars-section-pad
      [section]="section"
      [disableCanvas]="disableCanvas"
      [readonly]="readonly"
      [currentView]="currentView"
      [toolbarButtonsExt]="toolbarButtonsExt"
      [documentToolbar]="documentToolbar"
      [padType]="padType"
    ></cars-section-pad>
  </ng-container>
  <ng-container *ngIf="!showSectionPad()">
    <cars-section-reference [section]="section" [documentToolbar]="documentToolbar"></cars-section-reference>
  </ng-container>
</ng-container>
