<div class="section-group" [class.active]="selected">
  <div class="section-details">
    <div
      *ngIf="!sectionGroup.deleted && (CarsAction.AUTHOR_DOCUMENT | carsEnabled: sectionGroup.documentId | async)"
      class="handle-container"
    >
      <em
        class="material-icons handle"
        [matTooltip]="
          !reorderingClauses
            ? 'Click and drag to reorder section group ' + (sectionGroup.id | fragmentIndex) + ' ' + sectionGroup.value
            : ''
        "
        matTooltipPosition="after"
        [matTooltipShowDelay]="tooltipDelay"
        (mousedown)="startDragging($event)"
        aria-disabled="true"
        >drag_handle
      </em>
    </div>

    <button
      mat-icon-button
      *ngIf="CarsAction.IS_CHANGELOG_AUX_VIEW_MODE | carsDisabled: sectionGroup.documentId | async"
      disabled="true"
    >
      <mat-icon class="expand-icon">expand_more</mat-icon>
    </button>

    <mat-form-field
      matTooltip="{{ sectionGroup.id | fragmentIndex }} {{ sectionGroup.value }}"
      [matTooltipDisabled]="!isTitleTrucated()"
    >
      <span *ngIf="!sectionGroup.deleted" id="section-index" matPrefix>
        {{ sectionGroup.id | fragmentIndex }}
      </span>
      <input matInput #title class="title" [(ngModel)]="sectionGroup.value" [disabled]="true" required />
    </mat-form-field>

    <cars-section-list-menu [sectionListItem]="sectionGroup" [currentView]="currentView"></cars-section-list-menu>
  </div>
</div>

<mat-list-item *ngFor="let child of sectionGroup.children" tabindex="0">
  <cars-section-list-item [section]="child" [currentView]="currentView" [type]="type"></cars-section-list-item>
</mat-list-item>
