import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {UserService} from 'app/services/user/user.service';
import {User} from 'app/user/user';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {UserHovertipComponent} from './user-hovertip/user-hovertip.component';

@Component({
  selector: 'cars-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public userId: UUID;
  @Input() public user: User;
  @Input() public hoverTip: boolean = true;
  @Input() public initials: boolean = false;
  @ViewChild('userWidgetRef') public userWidgetRef: ElementRef;

  @Output() public userEvent: EventEmitter<User> = new EventEmitter();

  private component: ComponentRef<UserHovertipComponent>;
  private hoverTimeout;
  private _subscriptions: Subscription[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) {}

  /**
   * Initialise this component and its user.
   */
  public ngOnInit(): void {
    this.assignUser();
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Respond to user or userId changes by updating user.
   *
   * @param changes {SimpleChanges}   The binding changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('user') || changes.hasOwnProperty('userId')) {
      this.user = changes['user'] ? changes['user'].currentValue : null;
      this.assignUser();
    }
  }

  /**
   * Assigns the user based on:
   *  - The user passed in via template
   *  - Looking up the user based on the userId passed in via template
   */
  private assignUser(): void {
    if (!this.user) {
      if (this.userId) {
        this.userId = typeof this.userId === 'string' ? UUID.orThrow(this.userId) : this.userId;
        this.user = null;
        this._subscriptions.push(
          this.userService.getUserFromId(this.userId).subscribe((user: User) => {
            this.user = user;
            this.cd.markForCheck();
            this.userEvent.emit(this.user);
          })
        );
      } else {
        this.user = User.unknown();
        this.cd.markForCheck();
        this.userEvent.emit(this.user);
      }
    } else {
      this.userEvent.emit(this.user);
    }
  }

  /**
   * Starts the process of showing the hover tip (if enabled).
   *
   * @param event The mouse event
   */
  @HostListener('mouseover', ['$event'])
  public mouseover(event: MouseEvent): void {
    if (!this.hoverTip || this.component) {
      return;
    }

    if (!this.hoverTimeout) {
      this.hoverTimeout = setTimeout(() => {
        this.hoverTimeout = null;
        this.createHover(event);
      }, 1000);
    }
  }

  @HostListener('mouseleave', ['$event'])
  public mouseleave(event: MouseEvent): void {
    if (this.hoverTimeout) {
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = null;
    }
  }

  private createHover(event: MouseEvent) {
    this.component = this.viewContainerRef.createComponent(UserHovertipComponent);
    this.component.instance.componentRef = this.component;
    this.component.instance.user = this.user;
    this.component.instance.event = event;
    this.component.instance.anchorElement = this.userWidgetRef.nativeElement;
    this._subscriptions.push(
      this.component.instance.destroy.subscribe(() => {
        this.component = null;
        this.cd.markForCheck();
      })
    );
    this.cd.markForCheck();
  }
}
