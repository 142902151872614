import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ClauseFragment, DocumentInformationFragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {UserService} from 'app/services/user/user.service';
import {DocInfoValueDisplayNameType, DocumentInformationItem} from 'app/suite-config/configuration.service';
import {BaseFormComponent} from '../base-form.component';
import {getEnumByName} from '../document-information-utils';

@Component({
  selector: 'cars-enum-multi-select-input',
  templateUrl: './enum-multi-select-input.component.html',
  styleUrls: ['./enum-multi-select-input.component.scss'],
})
export class EnumMultiSelectInputComponent extends BaseFormComponent {
  @Input() public set documentInformationItem(documentInformationItem: DocumentInformationItem) {
    this._clause = documentInformationItem.documentInformationFragment.parent as ClauseFragment;
    this.documentInformationType = documentInformationItem.documentInformationField.documentInformationType;
    this.titleText = documentInformationItem.documentInformationField.titleText;
    this.hintText = documentInformationItem.documentInformationField.hintText;
    this.valueDisplayName = documentInformationItem.documentInformationField.valueDisplayName;
    this.options = this.getOptions(documentInformationItem.documentInformationField.enumName);
  }

  public options: string[];

  public valueDisplayName: DocInfoValueDisplayNameType;

  constructor(
    protected fragmentService: FragmentService,
    protected lockService: LockService,
    protected userService: UserService,
    protected versioningService: VersioningService,
    protected cdr: ChangeDetectorRef
  ) {
    super(fragmentService, lockService, userService, versioningService, cdr);
  }

  /**
   * @Override
   *
   * Update the formgroup with fragment.values instead of value as in baseclass.
   * @returns {Promise<number>}   Promise resolving to the HTTP status code of the request
   */
  protected _updateFragment(): Promise<number> {
    this.docInfoFragment.values = this.formGroup.get(this.documentInformationType).value;
    return this.fragmentService.update(this.docInfoFragment).then((response: any) => {
      this._afterUpdateHook();
      return response;
    });
  }

  /**
   * @Override
   *
   * Instantiate the form group with fragment.values instead of value as in base class.
   */
  protected _initFormGroup(): void {
    this.formGroup.addControl(this.documentInformationType, new UntypedFormControl(this.docInfoFragment.values));
  }

  private getOptions(enumName: string): string[] {
    return Object.values(getEnumByName(enumName));
  }

  /**
   * @Override
   *
   * Patch with fragment.values instead of value as in baseclass.
   */
  protected _patchValue(fragment: DocumentInformationFragment, emitEvent: boolean = false): void {
    this.formGroup.patchValue({[this.documentInformationType]: fragment.values}, {emitEvent});
    this.cdr.markForCheck();
  }

  /**
   * @Override
   *
   * Overriden to stop the clause being unlocked when the value changes
   * as multiple enum values can be added/removed before the mat-select is closed.
   */
  protected _afterUpdateHook(): void {
    this.cdr.markForCheck();
  }

  /**
   * @Override
   *
   * Checks against the fragment.values instead of value.
   */
  protected _hasChangedLocally(): boolean {
    return this._arraysDifferent(
      this.docInfoFragment.values as any[],
      this.formGroup.get(this.documentInformationType).value
    );
  }

  public onSelectionOpenChanged(isOpened: boolean): void {
    if (!isOpened) {
      this.unlock();
    } else {
      this.lock();
    }
  }

  /**
   * Compares two arrays and returns true if they contain different elements, ignoring order.
   *
   * Designed for enum selects, assumes no duplicates.
   *
   * @param a1 the first array
   * @param a2 the second array
   */
  private _arraysDifferent(a1: any[], a2: any[]): boolean {
    return !(a1.length === a2.length && a1.every((item) => a2.includes(item)));
  }
}
