import {Injectable} from '@angular/core';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {from, Observable} from 'rxjs';

interface MathJax {
  typesetPromise: (elements?: any[]) => Promise<void>;
  asciimath2svgPromise: (str: string) => Promise<HTMLElement>;
  startup: {
    promise: Promise<void>;
  };
}

@Injectable({
  providedIn: 'root',
})
export class EquationService {
  private _mathJax: MathJax;

  constructor() {
    this._mathJax = window['MathJax'] as any;
  }

  /**
   * Renders the entire source as AsciiMath and inserts it into the given element.
   * The element's existing content is removed.
   *
   * @param element           {HTMLElement}       The element to render in
   * @param source            {string}            The AsciiMath source
   * @returns                 {Observable<any>}   An observable publishing when rendering has finished
   */
  public renderEquationFromSource(element: HTMLElement, source: string): Observable<void> {
    return from(
      this._mathJax.startup.promise
        .then(() =>
          this._mathJax.asciimath2svgPromise(source).then((el: HTMLElement) => {
            element.innerHTML = '';
            element.appendChild(el);
          })
        )
        .catch((err) => Logger.error('equation-error', 'Failed to typeset the equation from the source', err))
    );
  }

  /**
   * Renders all AsciiMath contained in the source within equation delimiters (i.e contained in backticks) and
   * inserts it into the given element.
   * The element's existing content is removed.
   *
   * @param element           {HTMLElement}       The element to render in
   * @param source            {string}            The string containing AsciiMath equations to render
   * @returns                 {Observable<any>}   An observable publishing when rendering has finished
   */
  public renderEquationsContainedInSource(element: HTMLElement, source: string): Observable<void> {
    element.innerHTML = this._htmlEntityEscape(source);
    return from(
      this._mathJax.startup.promise.then(() => {
        this._mathJax
          .typesetPromise([element])
          .catch((err) =>
            Logger.error('equation-error', 'Failed to typeset the equations contained in the source', err)
          );
      })
    );
  }

  private _htmlEntityEscape(input: string): string {
    // Leverage the inbuilt browser HTML entity escaping
    const escape = document.createElement('textarea');
    escape.textContent = input;
    return escape.innerHTML;
  }
}
