<span
  #referenceRef
  contenteditable="false"
  class="reference"
  [class.selected-reference]="selected"
  [class.red]="content.globalReferenceDeleted"
  [class.line-through]="content.globalReferenceDeleted && !currentView.isHistorical()"
  (click)="onFocus()"
>
  {{ referenceField }} [{{ content | fragmentIndex: defaultKey }}]
</span>
