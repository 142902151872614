export enum ContentType {
  TEXT_PLAIN = 'text/plain',
  TEXT_HTML = 'text/html',
}

/**
 * A class representing email content that will be sent to the web-service.
 *
 * @field subject {string}    Email subject
 * @field content {Content}   Email content
 */
export class EmailContent {
  /**
   * Static factory to create a plain text email.
   *
   * @param subject {string}         Email subject
   * @param body    {string}         Email body
   * @returns       {EmailContent}   Email content object
   */
  public static plainText(subject: string, body: string): EmailContent {
    const content: Content = new Content(ContentType.TEXT_PLAIN, body);
    return new EmailContent(subject, content);
  }

  /**
   * Static factory to create a html email.
   *
   * @param subject {string}         Email subject
   * @param body    {string}         Email body
   * @returns       {EmailContent}   Email content object
   */
  public static html(subject: string, body: string): EmailContent {
    const content: Content = new Content(ContentType.TEXT_HTML, body);
    return new EmailContent(subject, content);
  }

  protected constructor(public subject: string, public content: Content) {}
}

/**
 * Mirrors SendGrids' Content class.
 *
 * @field type  {ContentType}   The email Content-Type
 * @field value {string}        The body of the email
 */
class Content {
  constructor(public type: ContentType, public value: string) {}
}
