import {Pipe, PipeTransform} from '@angular/core';
import {Suite} from 'app/fragment/suite';

export const SUITE_TO_DISPLAY_NAME: Readonly<Record<Suite, string>> = {
  DMRB: 'DMRB',
  LEGACY_DMRB: 'Imported DMRB',
  MCHW: 'MCHW',
  LEGACY_MCHW: 'Imported MCHW',
  MOMHW: 'MMHW',
  UNRESTRICTED: 'Unrestricted',
};

@Pipe({
  name: 'suiteDisplayName',
})
export class SuiteToDisplayNamePipe implements PipeTransform {
  private static readonly UNKNOWN_SUITE: string = 'Unknown Suite';

  /**
   * Transforms the document suite enum into a friendly display name.
   * The {@link SUITE_TO_DISPLAY_NAME} const is exported for consumers wanting to use this within typescript files.
   *
   * @param suite {Suite}    The document suite
   * @returns     {string}   The display name
   */
  public transform(suite: Suite): string {
    return !!SUITE_TO_DISPLAY_NAME[suite] ? SUITE_TO_DISPLAY_NAME[suite] : SuiteToDisplayNamePipe.UNKNOWN_SUITE;
  }
}
