import {Injectable} from '@angular/core';
import {SpecifierInstructionType} from 'app/fragment/types/specifier-instruction-type';
import {Callback} from 'app/utils/typedefs';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable()
export class SpecifierInstructionModalService {
  private _selectedtypeSubject: BehaviorSubject<SpecifierInstructionType> = new BehaviorSubject(null);

  public updateSelectedType(type: SpecifierInstructionType): void {
    this._selectedtypeSubject.next(type);
  }

  public onSelectedTypeChange(callback: Callback<SpecifierInstructionType>): Subscription {
    return this._selectedtypeSubject.subscribe(callback);
  }
}
