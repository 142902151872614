import {Fragment, FragmentType} from '../../fragment/types';
import {UUID} from '../../utils/uuid';

export class DummyFragment extends Fragment {
  public isDummy: boolean = true;

  // For list items
  public indented: boolean = false;

  // For table cells
  public colSpan: number = 1;
  public rowSpan: number = 1;

  constructor(id: UUID) {
    super(id, FragmentType.DUMMY, [], null);
  }

  public split(): DummyFragment {
    return this;
  }

  public updateContainsList(): void {}
}
