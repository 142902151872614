export class Browser {
  public static isInternetExplorer(): boolean {
    return (
      navigator.appName === 'Microsoft Internet Explorer' ||
      (navigator.appName === 'Netscape' && navigator.appVersion.toLowerCase().indexOf('trident') > -1)
    );
  }

  public static isEdge(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('edge') >= 0;
  }

  public static isFirefox(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('firefox') >= 0;
  }
}
