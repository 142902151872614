import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {ReferenceHovertipComponent} from './reference-hovertip.component';

@NgModule({
  imports: [CommonModule, MatDialogModule],
  declarations: [ReferenceHovertipComponent],
  exports: [ReferenceHovertipComponent],
})
export class ReferenceHovertipModule {}
