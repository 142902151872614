import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Page} from 'app/admin/manage-references/manage-references.component';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {Caret} from 'app/fragment/caret';
import {
  DocumentFragment,
  DocumentReferenceFragment,
  Fragment,
  FragmentType,
  InlineReferenceFragment,
  InternalReferenceType,
  ReferenceType,
  SectionFragment,
  SectionType,
} from 'app/fragment/types';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {TargetDocumentType} from 'app/fragment/types/reference/target-document-type';
import {BaseService} from 'app/services/base.service';
import {DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {GlobalReference} from 'app/sidebar/references/global-reference';
import {SearchableGlobalReference} from 'app/sidebar/references/searchable-global-reference';
import {HttpStatus} from 'app/utils/http-status';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {saveAs} from 'file-saver';
import {Observable, Subject, Subscription} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {DocumentReferenceUtils} from './reference-utils/document-reference-utils';
import {ReferenceSortingUtils} from './reference-utils/reference-sorting-utils';
import {SearchableGlobalReferenceService} from './searchable-global-reference.service';

export interface ReferenceUsageLocation {
  count: number;
  documentId: UUID;
  sectionId: UUID;
  documentTitle: string;
  sectionTitle: string;
  documentOwner: UUID;
  referenceType: ReferenceType;
  yearOfIssue: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReferenceService extends BaseService {
  private static readonly REFERENCES_TOPIC: string = `/topic/references`;

  private _document: DocumentFragment;

  private _cachedSearchableGlobalReferences: Record<string, SearchableGlobalReference> = {};

  private _cachedInternalReferenceSorting: Record<string, number> = {};

  private _referencesChange: Subject<void> = new Subject();

  private _documentReferencesRecalculated: Subject<void> = new Subject();

  private _websocketSubscription: Subscription;

  constructor(
    private _documentService: DocumentService,
    private _fragmentService: FragmentService,
    private _searchableGlobalReferenceService: SearchableGlobalReferenceService,
    private _websocketService: WebSocketService,
    private _http: HttpClient,
    protected _snackBar: MatSnackBar
  ) {
    super(_snackBar);

    this._documentService.onSelection((doc: DocumentFragment) => {
      if (doc) {
        const oldDocument: DocumentFragment = this._document;
        this._document = doc;

        if (
          !this._document.equals(oldDocument) ||
          this._document.validFrom !== oldDocument.validFrom ||
          this._document.validTo !== oldDocument.validTo
        ) {
          // If the document has changed then clear the cache and add the entries for the new document:
          this._cachedSearchableGlobalReferences = {};
          this.fetchAndCacheAllSearchableGlobalReferencesForDocument(doc.id.value, doc.validTo);
          this._recalculateInternalReferenceOrdering();
        }
      }
    });

    this._websocketService.onConnection((connected: boolean) => {
      if (this._websocketSubscription) {
        this._websocketSubscription.unsubscribe();
      }
      if (!connected) {
        return;
      }
      this._websocketSubscription = this._websocketService.subscribe(ReferenceService.REFERENCES_TOPIC, (json: any) => {
        const globalReferenceId: UUID = GlobalReference.deserialise(json).id;
        const reference: SearchableGlobalReference = SearchableGlobalReference.deserialise(json);
        reference.globalReferenceId = globalReferenceId;
        if (this._cachedSearchableGlobalReferences[reference.globalReferenceId.value]) {
          this._cachedSearchableGlobalReferences[reference.globalReferenceId.value] = reference;
        }
        this._referencesChange.next();
      });
    });

    this._fragmentService.onCreate(
      (fragment: Fragment) => {
        if (fragment.is(FragmentType.DOCUMENT_REFERENCE)) {
          const referenceId: UUID = (fragment as DocumentReferenceFragment).globalReference;
          this.fetchAndCacheSearchableGlobalReference(referenceId).then((ref: SearchableGlobalReference) => {
            if (ref) {
              this._cachedSearchableGlobalReferences[ref.globalReferenceId.value] = ref;
              this._changeStatusOfReferenceSections();
              this._recalculateDocumentReferences();
              this._recalculateInternalReferenceOrdering();
              this._referencesChange.next();
            } else {
              Logger.error(
                'reference-error',
                `didn't have a global reference for document reference fragment ${fragment.id.value}`
              );
            }
          });
        } else {
          this._changeStatusOfReferenceSections();
          this._recalculateDocumentReferences();
          this._recalculateInternalReferenceOrdering();
          this._referencesChange.next();
        }
      },
      (fragment: Fragment) => fragment.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE, FragmentType.DOCUMENT_REFERENCE)
    );

    this._fragmentService.onDelete(
      (fragment: Fragment) => {
        this._changeStatusOfReferenceSections();
        this._recalculateDocumentReferences();
        this._recalculateInternalReferenceOrdering();
        this._referencesChange.next();
      },
      (fragment: Fragment) => fragment.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE, FragmentType.DOCUMENT_REFERENCE)
    );

    this._fragmentService.onUpdate(
      (fragment: Fragment) => {
        this._changeStatusOfReferenceSections();
        this._recalculateDocumentReferences();
        this._recalculateInternalReferenceOrdering();
        this._referencesChange.next();
      },
      (fragment: Fragment) => fragment.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE, FragmentType.DOCUMENT_REFERENCE)
    );
  }

  public onReferenceChange(): Observable<void> {
    return this._referencesChange.asObservable();
  }

  public onDocumentReferencesRecalculated(): Observable<void> {
    return this._documentReferencesRecalculated.asObservable();
  }

  /**
   * Get the document reference fragment which refers to the global reference, or null if
   * there are no references to that global reference in the current document.
   *
   * @param globalReferenceId the ID of the global reference.
   * @returns the DocumentReferenceFragment or null
   */
  public getDocumentReferenceFragment(globalReferenceId: UUID): DocumentReferenceFragment {
    return this._document.getDocumentReferences().find((fragment: DocumentReferenceFragment) => {
      return globalReferenceId.equals(fragment.globalReference);
    });
  }

  /**
   * Add the desired reference to the document.  This requires that the global reference
   * has already been created and is known about by the backend.
   *
   * @param globalReferenceId the GlobalReference to which we are referring.
   * @param selectedType the type of reference
   * @param release the release date or year of the standard we want to reference, or null for latest.
   */
  public addReference(
    globalReferenceId: UUID,
    selectedType: ReferenceType,
    caret: Caret,
    release?: string
  ): Promise<boolean> {
    if (!globalReferenceId || !globalReferenceId.value) {
      Logger.error(
        'reference-error',
        'cannot create reference to null or unpersisted GlobalReference: ' + JSON.stringify(globalReferenceId)
      );
      return Promise.resolve(false);
    } else if (!caret) {
      Logger.error('reference-error', 'cannot create reference: no input caret for inline reference.');
      return Promise.resolve(false);
    }

    const created: Fragment[] = [];
    const newSectionType =
      selectedType === ReferenceType.NORMATIVE ? SectionType.REFERENCE_NORM : SectionType.REFERENCE_INFORM;
    const section: SectionFragment = this._document.getReferenceSections().find((s: SectionFragment) => {
      return s.sectionType === newSectionType;
    });

    let documentReference: Fragment = section.children[0].children.find(
      (child: Fragment) =>
        child.is(FragmentType.DOCUMENT_REFERENCE) &&
        globalReferenceId.equals((child as DocumentReferenceFragment).globalReference)
    );
    if (!documentReference) {
      documentReference = new DocumentReferenceFragment(null, selectedType, globalReferenceId, release);
      section.children[0].children.push(documentReference);
      created.push(documentReference);
    }

    const inline: InlineReferenceFragment = new InlineReferenceFragment(null, documentReference.id);
    const split: Fragment = caret.fragment.split(caret.offset);
    inline.insertAfter(caret.fragment);
    split.insertAfter(inline);
    created.push(inline, split);

    return Promise.all([this._fragmentService.update(caret.fragment), this._fragmentService.create(created)])
      .then(() => true)
      .catch(() => false);
  }

  public createDocumentReference(reference: DocumentReferenceFragment): Promise<void> {
    const sectionType =
      reference.referenceType === ReferenceType.NORMATIVE ? SectionType.REFERENCE_NORM : SectionType.REFERENCE_INFORM;
    const section: SectionFragment = this._document.getReferenceSections().find((s: SectionFragment) => {
      return s.sectionType === sectionType;
    });
    section.children[0].children.push(reference);
    return this._fragmentService.create(reference).then(() => {});
  }

  /**
   * Add and return a new reference.  Will fail if a reference exists with the same title
   * and reference.
   *
   * Callers are expected to handle duplicate reference errors, but this class will
   * handle other failures.
   *
   * @param reference the reference to persist in the backend
   * @returns the created reference
   */
  public createGlobalReference(reference: GlobalReference): Promise<GlobalReference> {
    const body: any = reference.serialise();
    return this._http
      .post(`${environment.apiHost}/globalReferences`, body)
      .pipe(map((json: any) => GlobalReference.deserialise(json)))
      .toPromise()
      .catch((error) => {
        if (
          error &&
          error.error &&
          typeof error.error.exception === 'string' &&
          error.error.exception.endsWith('DuplicateReferenceException')
        ) {
          return Promise.reject('DuplicateReferenceException');
        } else {
          return Promise.reject(
            this._handleError(
              error,
              'You have attempted to create an invalid reference.  Please double-check the fields are correct.',
              'reference-error'
            )
          );
        }
      });
  }

  /**
   * fetch a global reference.
   *
   * @returns a promise which will resolve to either a global reference with given id
   * or a string error.
   */
  public fetchGlobalReference(id: string): Promise<GlobalReference> {
    return this._http
      .get(`${environment.apiHost}/globalReferences/${id}`)
      .pipe(map(GlobalReference.deserialise))
      .toPromise()
      .catch((error: any) => {
        this._handleError(error, 'Failed to fetch global reference.', 'reference-error');
        return null;
      });
  }

  public getDocumentReferenceWithSearchableGlobalReference(
    documentReferenceId: UUID
  ): Promise<DocumentReferenceFragment> {
    if (!documentReferenceId) {
      return Promise.resolve(null);
    }

    const documentReference: DocumentReferenceFragment = this._fragmentService.find(
      documentReferenceId
    ) as DocumentReferenceFragment;

    const documentReferencePromise: Promise<DocumentReferenceFragment> = documentReference
      ? Promise.resolve(documentReference)
      : this._fragmentService
          .fetchLatest(documentReferenceId, {
            depth: 0,
            validFrom: 0,
          })
          .then((fragment: Fragment) => fragment as DocumentReferenceFragment);

    return documentReferencePromise.then((documentReferenceFragment: DocumentReferenceFragment) => {
      return this.fetchAndCacheSearchableGlobalReference(documentReferenceFragment.globalReference).then(
        (searchabledGlobalReference: SearchableGlobalReference) => {
          documentReferenceFragment.searchableGlobalReference = searchabledGlobalReference;
          return documentReferenceFragment;
        }
      );
    });
  }

  /**
   * Get the searchable global reference of the given id. If it doesn't exist in the cache, get and cache
   * the searchable global reference before returning it.
   */
  public fetchAndCacheSearchableGlobalReference(id: UUID): Promise<SearchableGlobalReference> {
    if (!this._cachedSearchableGlobalReferences[id.value]) {
      return this._searchableGlobalReferenceService
        .fetchSearchableGlobalReference(id)
        .toPromise()
        .then((searchableGlobalReference: SearchableGlobalReference) => {
          this._cachedSearchableGlobalReferences[id.value] = searchableGlobalReference;
          this._recalculateDocumentReferences();
          return this._cachedSearchableGlobalReferences[id.value];
        })
        .catch((error: any) => {
          this._handleError(error, 'Failed to fetch searchable global reference.', 'reference-error');
          return Promise.reject(error);
        });
    }

    return Promise.resolve(this._cachedSearchableGlobalReferences[id.value]);
  }

  /**
   * Fetch and cache all the searchable global reference for the given document
   * that fulfil the given search terms.
   */
  public fetchAndCacheSearchableGlobalReferencesForDocument(
    searchTerm: string,
    documentId: string,
    page: number,
    size: number,
    validAt: number = null
  ): Observable<Page<SearchableGlobalReference>> {
    return this._searchableGlobalReferenceService
      .fetchSearchableGlobalReferencesForDocument(searchTerm, documentId, page, size, validAt)
      .pipe(
        tap((resultPage: Page<SearchableGlobalReference>) => {
          resultPage.resultsList.forEach(
            (gloRef) => (this._cachedSearchableGlobalReferences[gloRef.globalReferenceId.value] = gloRef)
          );
          this._recalculateDocumentReferences();
        })
      );
  }

  /**
   * Fetch all searchable global references for the given documentId, valid at the given time
   * and cache them.
   */
  public fetchAndCacheAllSearchableGlobalReferencesForDocument(
    documentId: string,
    validAt: number
  ): Promise<SearchableGlobalReference[]> {
    return this._searchableGlobalReferenceService
      .fetchAllSearchableGlobalReferencesForDocument(documentId, validAt)
      .pipe(
        tap((references: SearchableGlobalReference[]) => {
          references.forEach(
            (gloRef) => (this._cachedSearchableGlobalReferences[gloRef.globalReferenceId.value] = gloRef)
          );
          this._recalculateDocumentReferences();
        })
      )
      .toPromise();
  }

  /**
   * Sorts both reference sections, recalculates reference keys (i.e. Ref X.N),
   * and stores a map of each sections references.
   */
  private _recalculateDocumentReferences(): void {
    if (this._document) {
      this._document.getReferenceSections().forEach((section: SectionFragment) => {
        if (!section.children[0]) {
          return Logger.error('reference-error', `Reference section ${section.id.value} had no clause`);
        }

        DocumentReferenceUtils.getDocumentReferencesFromSection(section).forEach(
          (documentReference: DocumentReferenceFragment) => {
            documentReference.searchableGlobalReference =
              this._cachedSearchableGlobalReferences[documentReference.globalReference.value];
          }
        );
        this._documentReferencesRecalculated.next();
      });
    }
  }

  /**
   * Checks if each reference section actually has references and if so sets deleted to be false, else sets it to true.
   */
  private _changeStatusOfReferenceSections(): void {
    if (this._document) {
      this._document.getReferenceSections().forEach((section: SectionFragment) => {
        const newDeletedState: boolean =
          !section.children?.length ||
          !section.children[0].children.find((frag) => this._shouldDisplayInReferenceSection(frag));
        if (newDeletedState !== section.deleted) {
          section.deleted = newDeletedState;
          this._fragmentService.update(section);
        }
      });
    }
  }

  private _recalculateInternalReferenceOrdering(): void {
    if (this._document) {
      const internalReferences: InternalDocumentReferenceFragment[] = this._document
        .getReferenceSections()
        .map((section: SectionFragment) => {
          if (!section.children[0]) {
            Logger.error('reference-error', `Reference section ${section.id.value} had no clause`);
            return [];
          }
          return section.children[0].children
            .filter((frag) => frag.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE))
            .map((frag) => frag as InternalDocumentReferenceFragment);
        })
        .reduce((acc, val) => acc.concat(val));
      this._cachedInternalReferenceSorting = ReferenceSortingUtils.sortReferencesIntoMap(internalReferences);
    }
  }

  public getInternalReferenceSorting(internalRefId: UUID): number {
    return this._cachedInternalReferenceSorting[internalRefId.value] ?? 10000;
  }

  /**
   * Checks if reference fragment is either a document reference or a section reference pointing to a different document
   */
  private _shouldDisplayInReferenceSection(referenceFragment: Fragment): boolean {
    if (referenceFragment.is(FragmentType.DOCUMENT_REFERENCE)) {
      return true;
    }
    const internalDocRef: InternalDocumentReferenceFragment = referenceFragment as InternalDocumentReferenceFragment;
    return (
      (internalDocRef.internalReferenceType === InternalReferenceType.SECTION_REFERENCE ||
        internalDocRef.internalReferenceType === InternalReferenceType.CLAUSE_REFERENCE) &&
      internalDocRef.targetDocumentType === TargetDocumentType.DIFFERENT_DOCUMENT
    );
  }

  /**
   * Deletes the global reference with the given id.
   */
  public deleteGlobalReference(id: UUID, message?: string): Promise<any> {
    message = message || `sorry, could not delete the global reference.`;
    return this._http
      .patch(`${environment.apiHost}/globalReferences/${id.value}`, {
        id: id.value,
        deleted: true,
      })
      .toPromise()
      .catch((error: any) => {
        if (error.status === HttpStatus.FORBIDDEN) {
          message = error.error.message;
        }
        this._handleError(error, message);
        throw error;
      });
  }

  /**
   * Updates the global reference with the given id.
   *
   * @param json The json object with the global reference id, title, authors, publisher and reference.
   * @param message Given error message.
   */
  public updateGlobalReference(json: any, message?: string): Promise<any> {
    message = message || `Sorry, could not update the global reference.`;
    return this._http
      .patch(`${environment.apiHost}/globalReferences/${json.id}`, json)
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        if (error.status === HttpStatus.FORBIDDEN) {
          message = error.error.message;
        }
        this._handleError(error, message);
        throw error;
      });
  }

  public getUsageLocationsForGlobalReference(globalReferenceId: UUID): Promise<ReferenceUsageLocation[]> {
    return this._http
      .get(`${environment.apiHost}/references/globalReferences/${globalReferenceId.value}/locations`, {
        responseType: 'json',
      })
      .pipe(
        map((json: any) => {
          const array: any[] = this._toArray(json);
          return array.map((location: any) => ({
            count: location.count,
            documentId: UUID.orNull(location.documentId),
            sectionId: UUID.orNull(location.sectionId),
            documentTitle: location.documentTitle,
            sectionTitle: location.sectionTitle,
            documentOwner: UUID.orNull(location.documentOwner),
            referenceType: location.referenceType,
            yearOfIssue: location.yearOfIssue,
          }));
        })
      )
      .toPromise();
  }

  public downloadWithdrawnGlobalReferenceCsv(): Promise<boolean> {
    return this._http
      .get(`${environment.apiHost}/references/download/withdrawn`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          saveAs(
            response.body,
            'withdrawn_global_references_' + new Date().toLocaleDateString().split('/').join('_') + '.csv'
          );
          return true;
        }),
        catchError((err: any) => {
          this._handleError(err, 'Failed to download withdrawn global reference csv.', 'reference-error');
          return Promise.resolve(false);
        })
      )
      .toPromise();
  }

  public toggleDocumentReferenceType(globalId: UUID): void {
    let sectionToMoveTo: SectionFragment;
    let referenceToUpdate: DocumentReferenceFragment;
    this._document.getReferenceSections().forEach((section: SectionFragment) => {
      const documentReference: DocumentReferenceFragment = DocumentReferenceUtils.getDocumentReferencesFromSection(
        section
      ).find((_documentReference: DocumentReferenceFragment) => _documentReference.globalReference.equals(globalId));

      if (documentReference) {
        referenceToUpdate = documentReference;
      } else {
        sectionToMoveTo = section;
      }
    });

    if (referenceToUpdate && sectionToMoveTo) {
      referenceToUpdate.remove();
      referenceToUpdate.toggleReferenceType();
      sectionToMoveTo.children[0].children.push(referenceToUpdate);
      referenceToUpdate.sectionId = sectionToMoveTo.id;
      this._fragmentService.update(referenceToUpdate).then(() => {
        this._changeStatusOfReferenceSections();
        this._recalculateDocumentReferences();
        this._referencesChange.next();
      });
    }

    this._referencesChange.next();
  }
}
