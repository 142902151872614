import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {ChangelogLink} from 'app/changelog/changelog-link';
import {ChangelogRange} from 'app/changelog/changelog-range';
import {ChangelogService} from 'app/changelog/changelog.service';
import {LinkBuilder} from 'app/changelog/link-builder/link-builder';
import {LinkCreationWizardComponent} from 'app/changelog/link-creation-wizard/link-creation-wizard.component';
import {LinkManagerComponent} from 'app/changelog/link-manager/link-manager.component';
import {DocumentRole} from 'app/documents/document-data';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {CarsRange} from 'app/fragment/cars-range';
import {DocumentFragment, SectionFragment, SectionType} from 'app/fragment/types';
import {DocumentsSearchType} from 'app/search/document-selector/documents-search-types';
import {SearchableDocument} from 'app/search/search-documents.service';
import {CaretService} from 'app/services/caret.service';
import {Predicate} from 'app/utils/typedefs';
import {UUID} from 'app/utils/uuid';
import {CurrentView, ViewMode} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {PadType} from '../element-ref.service';
import {AltProperties} from '../services/alt-accessibility.service';
import {NavigationService, NavigationTypes, SectionNavigationEvent} from '../services/navigation.service';
import {LocalConfigUtils} from '../utils/local-config-utils';
import {LinkTableService} from './link-table/link-table.service';
import {RangeDeletionManagerComponent} from './range-deletion-manager/range-deletion-manager.component';

@Component({
  selector: 'cars-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit, OnDestroy {
  public readonly NavigationTypes: typeof NavigationTypes = NavigationTypes;

  public publishedDocument: DocumentFragment;

  public loading: boolean = false;

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public authoredView: CurrentView = new CurrentView(ViewMode.LIVE, DocumentRole.AUTHOR, null, true);
  public publishedView: CurrentView = new CurrentView(ViewMode.CHANGELOG_AUX, DocumentRole.AUTHOR, null, false);

  public readonly authoredPad: PadType = PadType.MAIN_EDITABLE;
  public readonly publishedPad: PadType = PadType.PUBLISHED_CHANGLOG;

  public readonly DocumentsSearchType: typeof DocumentsSearchType = DocumentsSearchType;

  public publishedSection: SectionFragment;
  public altProperties: Record<string, AltProperties> = {};
  public showingPublishedNavigation: boolean = false;
  public showingAuthoredNavigation: boolean = false;

  public linkTableRoute: string;

  public currentView: CurrentView;

  public linkCreationWizard: MatDialogRef<LinkCreationWizardComponent>;
  public linkManager: MatDialogRef<LinkManagerComponent>;

  public rangeDeletionManager: MatDialogRef<RangeDeletionManagerComponent>;

  private _subscriptions: Subscription[] = [];

  constructor(
    private changelogService: ChangelogService,
    private linkBuilder: LinkBuilder,
    private navigationService: NavigationService,
    private caretService: CaretService,
    private viewService: ViewService,
    private linkTableService: LinkTableService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.showingPublishedNavigation = this.navigationService.getState(NavigationTypes.CHANGELOG);
    this.showingAuthoredNavigation = this.navigationService.getState(NavigationTypes.DOCUMENT);

    this._subscriptions.push(
      this.caretService.onSelectionChange(
        (range: CarsRange) => {
          const changelogRange: ChangelogRange = new ChangelogRange(range[0], range[1]);

          this.changelogService.createRange(changelogRange).then((created: ChangelogRange) => {
            if (this.linkCreationWizard && this.linkBuilder.requiresAsSecondRange(created)) {
              this.linkBuilder.addRange(created);
            }
          });
        },
        (range: CarsRange) => {
          return !!range && range.isValidChangelogRange() && this.viewService.getCurrentView().isChangelogMarkup();
        }
      ),

      this.linkBuilder.onFirstRangeAdded((range: ChangelogRange) => this.onFirstRangeAdded()),

      this.linkBuilder.onSecondRangeAdded((range: ChangelogRange) => this.onSecondRangeAdded(range)),

      this.changelogService.onViewLinksRequest((range: ChangelogRange) => this.onViewLinksRequest(range)),

      this.changelogService.onDeletionEvent((range: ChangelogRange) => this.onRangeDeletion(range)),

      this.navigationService.onChange(NavigationTypes.CHANGELOG, (open: boolean) => {
        this.showingPublishedNavigation = open;
      }),

      this.navigationService.onChange(NavigationTypes.DOCUMENT, (open: boolean) => {
        this.showingAuthoredNavigation = open;
      }),

      this.viewService.onCurrentViewChange((currentView: CurrentView) => {
        this.currentView = currentView;
      }),

      this.navigationService.onNavigationStart(NavigationTypes.CHANGELOG, (e: SectionNavigationEvent) => {
        this.changePublishedSection(e.section);
      }),

      this.route.url.subscribe((segments: UrlSegment[]) => {
        this.linkTableService.setExitRoute(
          segments.reduce(
            (route: string[], segment: UrlSegment) => {
              route.push(segment.path);
              return route;
            },
            ['/']
          )
        );
      })
    );

    const authoredDocumentId: UUID = this.getAuthoredDocumentId();
    // We have been storing fake JSON-ised UUIDs rather than strings in localstorage so have to do this to actually get a UUID...
    const lastPublishedId: {_value: string} =
      LocalConfigUtils.getConfig().openedChangelogIdPairs[authoredDocumentId.value];

    if (lastPublishedId) {
      this.changePublishedDocument(UUID.orNull(lastPublishedId._value));
    }

    this.updateAltProperties();
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Event handler to process toggling the display of the navbar.
   *
   * @param navbar {string} The name of the navbar being toggled.
   */
  public onToggleNavBar(navbar: NavigationTypes): void {
    this.navigationService.toggleState(navbar);
  }

  /**
   * Change the section in the published document.
   *
   * @param newSection {SectionFragment} The section to show
   */
  private changePublishedSection(newSection: SectionFragment): Promise<SectionFragment> {
    if (newSection) {
      return this.changelogService
        .fetchPublishedSection(newSection.id)
        .then((s: SectionFragment) => (this.publishedSection = s));
    }
    return Promise.reject(null);
  }

  private getAuthoredDocumentId(): UUID {
    // Not sure if there's a better way to get the document Id, maybe straight from the service?
    return UUID.orThrow(this.route.snapshot.params['document_id']);
  }

  /**
   * Change the published document to display in the left hand pad.
   *
   * @param publishedDocument {DocumentFragment} The document that has been selected.
   */
  public changePublishedDocument(publishedDocument: SearchableDocument | UUID): void {
    const publishedDocumentId: UUID =
      publishedDocument instanceof UUID ? publishedDocument : publishedDocument.DOCUMENT_ID;

    if (!publishedDocumentId || !(publishedDocumentId instanceof UUID)) {
      this.onLoadFailure('Changelog: invalid selected document.');
      return;
    }

    this.loading = true;

    this.changelogService
      .fetchPublishedDocument(publishedDocumentId)
      .then((doc: DocumentFragment) => {
        // Set the current document
        this.changelogService.setPublishedDocument(doc);
        this.publishedDocument = doc;
        LocalConfigUtils.setOpenedChangelogIdPair(this.getAuthoredDocumentId(), publishedDocumentId);

        const section: SectionFragment = this.getFirstSection(doc);
        if (section) {
          return this.changePublishedSection(section);
        }
      })
      .then(() => this.onLoadSuccess())
      .catch((err) => this.onLoadFailure(err));
  }

  /**
   * Called on successful loading of the published document.
   */
  private onLoadSuccess(): void {
    this.loading = false;
    this.linkTableRoute = this.router.url + '/linktable';
  }

  /**
   * Called on failure to load the published document.
   */
  private onLoadFailure(message: string, err?: any): void {
    this.loading = false;
    Logger.error('changelog-error', message, err);
    this.snackBar.open(message, 'Dismiss', {duration: 5000});
  }

  /**
   * Load the first non-deleted, non-reference section.
   */
  private getFirstSection(document: DocumentFragment): SectionFragment {
    return document.getSections().find((section: SectionFragment) => {
      return (
        !section.deleted &&
        section.sectionType !== SectionType.REFERENCE_INFORM &&
        section.sectionType !== SectionType.REFERENCE_NORM &&
        section.sectionType !== SectionType.DOCUMENT_INFORMATION
      );
    });
  }

  private updateAltProperties(): void {
    this.altProperties['published-toolbar'] = new AltProperties('p', null, true);
    this.altProperties['toggle-menu'] = new AltProperties('p', 't', true);
    this.altProperties['authoring-mode'] = new AltProperties('p', 'e', this.currentView.isChangelogMarkup());
    this.altProperties['markup-mode'] = new AltProperties('p', 'r', this.currentView.isChangelog());
  }

  /**
   * If the currentview is set to CHANGELOG_MARKUP, then this toggles it to CHANGELOG, else it changes it to CHANGELOG_MARKUP
   */
  public toggleMarkup(): void {
    this.viewService.setCurrentView(
      this.currentView.isChangelogMarkup() ? ViewMode.CHANGELOG : ViewMode.CHANGELOG_MARKUP
    );
    this.updateAltProperties();
  }

  /**
   * When the delete range option is clicked, this opens up the link manager with a optional
   * deletion comment box.
   */
  private onRangeDeletion(range: ChangelogRange): void {
    if (this.linkManager) {
      return;
    }
    if (this.linkCreationWizard) {
      return;
    }

    if (!range.published) {
      this.snackBar.open('Cannot delete non-published highlight.', 'Dismiss', {
        duration: 5000,
      });
    }

    this.rangeDeletionManager = this.dialog.open(
      RangeDeletionManagerComponent,
      RangeDeletionManagerComponent.DIALOG_OPTIONS_FACTORY(range)
    );
    this.rangeDeletionManager
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => (this.rangeDeletionManager = null));
  }

  /**
   * When the first range is added during the link building process, show the link
   * creation wizard, unless either the wizard of the link manager are already open.
   */
  private onFirstRangeAdded(): void {
    if (!this.linkCreationWizard && !this.linkManager) {
      this.linkCreationWizard = this.dialog.open(
        LinkCreationWizardComponent,
        LinkCreationWizardComponent.DIALOG_OPTIONS
      );
      this.linkCreationWizard
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => (this.linkCreationWizard = null));
    }
  }

  /**
   * When the second range is added during the link building process, hide the link creation wizard
   * and show the link manager uness it is already open.
   *
   * If the two ranges are already linked, the operation is rejected.
   *
   * @param newRange {ChangelogRange} The range which has been added.
   */
  private onSecondRangeAdded(newRange: ChangelogRange): void {
    if (this.linkManager) {
      return;
    }
    if (this.linkCreationWizard) {
      this.linkCreationWizard.close();
    }

    const firstRange: ChangelogRange = newRange.published
      ? this.linkBuilder.authoredRange
      : this.linkBuilder.publishedRange;
    const isDuplicate: Predicate<ChangelogLink[]> = (links: ChangelogLink[]) => {
      return links.some((link: ChangelogLink) => {
        const id: UUID = firstRange.published ? link.authoredRangeId : link.publishedRangeId;
        return id.equals(newRange.id);
      });
    };

    this.changelogService.findLinksByRangeId(firstRange.id, firstRange.published).then((links: ChangelogLink[]) => {
      if (!isDuplicate(links)) {
        this.linkManager = this.dialog.open(
          LinkManagerComponent,
          LinkManagerComponent.DIALOG_OPTIONS_FACTORY(firstRange, newRange)
        );
        this.linkManager
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => (this.linkManager = null));
      } else {
        this.linkBuilder.reset();
        this.snackBar.open('This link already exists.', 'Dismiss', {
          duration: 5000,
        });
      }
    });
  }

  /**
   * Open the link manager unless it already open.
   *
   * @param range {ChangelogRange} The range whose links we want to view.
   */
  private onViewLinksRequest(range: ChangelogRange): void {
    if (this.linkManager) {
      return;
    }
    if (this.linkCreationWizard) {
      this.linkCreationWizard.close();
    }

    this.linkManager = this.dialog.open(LinkManagerComponent, LinkManagerComponent.DIALOG_OPTIONS_FACTORY(range, null));
    this.linkManager
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => (this.linkManager = null));
  }

  public onOpenLinkTable(): void {
    this.router.navigate(['/documents', this.publishedDocument.id.value, 'linktable'], {relativeTo: this.route});
  }
}
