import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

export enum RichTextType {
  LIST,
  TABLE,
  EQUATION,
  SUPERSCRIPT,
  SUBSCRIPT,
  MEMO,
  FIGURE,
  REVERT,
  SUGGESTION,
  SPELLING_SUGGESTION,
  TEXT,
  LIST_INCREASE_INDENT,
  LIST_DECREASE_INDENT,
  STANDARD_FORMAT_GROUP,
  SPECIFIER_INSTRUCTION,
  NATIONALLY_DETERMINED_REQUIREMENT,
}

@Injectable({
  providedIn: 'root',
})
export class RichTextService {
  // Odd TypeScript syntax here: template argument is an array with first entry a
  // RichTextType, and all other arguments of any type95
  private _subject: Subject<[RichTextType, any[]]> = new Subject();
  private _landscapeFragmentSubject: Subject<void> = new Subject<void>();

  constructor() {}

  /**
   * Publish to the rich text UI subject.  Following the type, multiple arguments may be
   * passed to subscribers, with are mapped to varargs in their subscription callback.
   *
   * @param type {RichTextType}   The rich text event type
   * @param args {any[]}          Variadic arguments
   */
  public next(type: RichTextType, ...args: any[]): void {
    // Bundle type and args into one array, since Subject only takes a single argument
    const bundle: any = [type].concat(args);
    this._subject.next(bundle);
  }

  /**
   * Subscribe to rich text events with a given callback.  The callback must have first
   * argument a RichTextType; following arguments are optional variadic parameters.
   *
   * @param callback {Function}       The callback function
   * @returns        {Subscription}   The subscription
   */
  public subscribe(callback: (type: RichTextType, ...args: any[]) => void): Subscription {
    // Unpack the type and args array and dispatch as distinct arguments to the callback
    const callbackWithBundledArgs = (bundle: any[]) => {
      return callback(bundle[0], ...bundle.slice(1));
    };

    return this._subject.subscribe(callbackWithBundledArgs);
  }

  public triggerLandscapeEvent(): void {
    this._landscapeFragmentSubject.next();
  }

  public onLandscapeEvent(callback: () => void): Subscription {
    return this._landscapeFragmentSubject.subscribe(callback);
  }
}
