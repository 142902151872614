import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Suite} from 'app/fragment/suite';
import {DocumentFragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {CarsAction} from 'app/permissions/types/permissions';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-document-overview-header',
  templateUrl: './document-overview-header.component.html',
  styleUrls: ['./document-overview-header.component.scss'],
})
export class DocumentOverviewHeaderComponent implements OnInit, OnDestroy {
  @Input() document: DocumentFragment;

  public readonly Suite: typeof Suite = Suite;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public latestVersionRoute: string;

  private _subscriptions: Subscription[] = [];

  constructor(private _versioningService: VersioningService, private _router: Router, private _route: ActivatedRoute) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._versioningService.getVersionTagsForFragmentId(this.document.id).subscribe((versionTags: VersionTag[]) => {
        if (versionTags.length > 0) {
          this.latestVersionRoute = `/documents/${this.document.id.value}/versions/${versionTags[0].versionId.value}`;
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }
}
