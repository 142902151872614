import {ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BlurOption} from 'app/blur-options';
import {Caret} from 'app/fragment/caret';
import {ClauseFragment, DocumentFragment, Fragment, FragmentType} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {WSRScheduleStandardFormatTypes} from 'app/fragment/types/standard-format-type';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {AltProperties} from 'app/services/alt-accessibility.service';
import {CaretService} from 'app/services/caret.service';
import {ClauseService} from 'app/services/clause.service';
import {FragmentService} from 'app/services/fragment.service';
import {RichTextService, RichTextType} from 'app/services/rich-text.service';
import {SpecifierInstructionService, SpecifierInstructionWrapper} from 'app/services/specifier-instruction.service';
import {ToolbarItem} from 'app/suite-config/configuration.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {
  SpecifierInstructionModalComponent,
  SpecifierInstructionModalOutputData,
} from './specifier-instruction-modal/specifier-instruction-modal.component';

@Component({
  selector: 'cars-specifier-instruction-creator',
  templateUrl: './specifier-instruction-creator.component.html',
  styleUrls: ['./specifier-instruction-creator.component.scss', '../toolbar-buttons.scss'],
})
export class SpecifierInstructionCreatorComponent implements OnInit, OnDestroy {
  @Input() public toolbarItem: ToolbarItem;
  @Input() public altProperties: AltProperties;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly BlurOption: typeof BlurOption = BlurOption;

  public enabled: boolean = false;
  private _lastSelectedClause: ClauseFragment;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _clauseService: ClauseService,
    private _fragmentService: FragmentService,
    private _specifierInstructionService: SpecifierInstructionService,
    private _richTextService: RichTextService,
    private _selectionOperationsService: SelectionOperationsService,
    private _caretService: CaretService,
    private _cdr: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._clauseService.onSelection((clause: ClauseFragment) => {
        this.enabled = this.toolbarItem.enabled(clause);
        this.altProperties.condition = this.enabled;
        this._lastSelectedClause = clause || this._lastSelectedClause;
        this._cdr.markForCheck();
      }),
      this._fragmentService.onUpdate((fragment: Fragment) => {
        this.enabled = this.toolbarItem.enabled(this._lastSelectedClause);
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Block event propagation when clicking on the RTE to prevent blurring.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  @HostListener('mousedown', ['$event'])
  public mouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  public openModal(): void {
    const caretOnCancel: Caret = this._caretService.getCaretPositionFromSelectedFragment();
    const isWithinScheduleSFR: boolean = WSRScheduleStandardFormatTypes.has(
      this._lastSelectedClause.parent.is(FragmentType.CLAUSE_GROUP)
        ? (this._lastSelectedClause.parent as ClauseGroupFragment).standardFormatType
        : null
    );

    // This line assumes that it is not possible for this openModal method to be called in a scenario where this._lastSelectedClause
    // does not have an ancestor of type Document with a suite property set
    const suite = (this._lastSelectedClause.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment).suite;

    const siFragments: SpecifierInstructionWrapper[] =
      this._specifierInstructionService.getAllSpecifierInstructionFragments(isWithinScheduleSFR, suite);

    if (siFragments.length === 1) {
      const specifierInstruction: ClauseFragment = this._specifierInstructionService.getSpecifierInstructionToCreate(
        siFragments[0].getSpecifierInstructionFragment().specifierInstructionType
      );
      this._richTextService.next(RichTextType.SPECIFIER_INSTRUCTION, specifierInstruction, this._lastSelectedClause);
    } else {
      const dialog = SpecifierInstructionModalComponent.open(this._dialog, siFragments, isWithinScheduleSFR);
      dialog
        .afterClosed()
        .subscribe((modalData: SpecifierInstructionModalOutputData) =>
          this._processModaldata(modalData, caretOnCancel)
        );
    }
  }

  private _processModaldata(modalData: SpecifierInstructionModalOutputData, caretOnCancel: Caret): void {
    if (!modalData.cancelled) {
      const specifierInstruction: ClauseFragment = this._specifierInstructionService.getSpecifierInstructionToCreate(
        modalData.type
      );
      this._richTextService.next(RichTextType.SPECIFIER_INSTRUCTION, specifierInstruction, this._lastSelectedClause);
    } else {
      this._selectionOperationsService.setSelected(
        caretOnCancel.fragment,
        caretOnCancel.offset,
        this._lastSelectedClause.component.padType
      );
    }
  }
}
