<mat-form-field class="form-field" [formGroup]="formGroup" [matTooltip]="!canLock ? (lockedText | async) : ''">
  <input
    matInput
    [formControlName]="documentInformationType"
    [id]="documentInformationType"
    [placeholder]="titleText"
    [name]="documentInformationType"
    (focus)="lock()"
    (blur)="onBlur()"
    autocomplete="off"
    [required]="pattern"
  />
  <mat-hint *ngIf="hintText">{{ hintText }}</mat-hint>
  <mat-error>{{ patternErrorText }}</mat-error>
</mat-form-field>
