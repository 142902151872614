<span class="title">
  All Documents
  <div>
    <button mat-button class="my-discussions-button" routerLink="/mydiscussions">My Discussions</button>
    <button mat-button class="my-documents-button" routerLink="/mydocuments">My Documents</button>
  </div>
</span>
<div class="documents-container mat-elevation-z2">
  <mat-tab-group [@.disabled]="true">
    <mat-tab>
      <ng-template mat-tab-label>
        <div>In Authoring</div>
      </ng-template>
      <ng-template matTabContent>
        <cars-documents-table></cars-documents-table>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="canViewPublished">
      <ng-template mat-tab-label>
        <div>Published</div>
      </ng-template>
      <ng-template matTabContent>
        <cars-documents-table publishedTable="true"></cars-documents-table>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<cars-privacy-footer></cars-privacy-footer>
