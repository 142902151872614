import {Component, OnInit} from '@angular/core';
import {StatisticsService, StatsField, StatsReport} from '../../services/statistics.service';

@Component({
  selector: 'cars-user-statistics',
  templateUrl: './user-statistics.component.html',
  styleUrls: ['./user-statistics.component.scss'],
})
export class UserStatisticsComponent implements OnInit {
  public documentChangeOptions: number[] = [7, 14, 21, 28];

  public numberOfDays: number = 7;

  public allStats: StatsField[] = [
    {
      key: StatsReport.DOCUMENT_STATISTICS,
      description:
        'A .csv containing one row per document. Each row contains a column for the ' +
        'document title, document id, whether the document is deleted and the JIRA Change ABCD id. This table ' +
        'includes deleted documents.',
      displayTitle: 'Document statistics',
      loading: false,
    },
    {
      key: StatsReport.DOUCMENT_USER_ROLES,
      description:
        'A .csv with a row per user per document. Each row has a column for ' +
        'the document title, document id, user username, user id and the role of that user in that document. ' +
        'The table includes multiple rows per document and per user, and includes deleted documents.',
      displayTitle: 'User roles per document',
      loading: false,
    },
    {
      key: StatsReport.USER_STATISTICS,
      description:
        'A .csv with a row per user in CARS. Each row contains the user username, user id and when the user was created.',
      displayTitle: 'User statistics',
      loading: false,
    },
    {
      key: StatsReport.DOCUMENT_CHANGES_STATISTICS,
      description:
        'A .csv with a row per document per user who edited the document within the given period of time. Each row contains ' +
        'the document title, the document id, the user username and the user id. This table includes deleted documents.',
      displayTitle: 'Document Modification Statistics',
      loading: false,
    },
    {
      key: StatsReport.BENEFIT_REALISATION_STATISTICS,
      description:
        'A .csv that contains benefits realisation metrics at a document level.' +
        '  These include page count, number of clauses per document, and document word count.',
      displayTitle: 'Benefits Realisation Statistics',
      loading: false,
    },
  ];

  public selectedDate: number = null;

  public availableDates: number[] = [];

  constructor(private statisticsService: StatisticsService) {}

  public ngOnInit(): void {
    this.statisticsService.getAvailableDatesForBenefitsRealisation().then((availableDates: number[]) => {
      this.availableDates = availableDates;
      if (this.availableDates.length) {
        this.selectedDate = this.availableDates[0];
      }
    });
  }

  public downloadStatistics(statistic: StatsReport): void {
    const selectedStat: StatsField = this.allStats.find((object: StatsField) => object.key === statistic);
    const validFrom: number = new Date().setDate(new Date().getDate() - this.numberOfDays);
    selectedStat.loading = true;

    const promise: Promise<void> =
      statistic === StatsReport.BENEFIT_REALISATION_STATISTICS
        ? this.statisticsService.downloadBenefitStatistics(this.selectedDate)
        : this.statisticsService.downloadStatistics(statistic, validFrom);

    promise
      .then((response: void) => (selectedStat.loading = false))
      .catch((error: any) => (selectedStat.loading = false));
  }

  public isButtonDisabled(report: StatsReport): boolean {
    if (report === StatsReport.BENEFIT_REALISATION_STATISTICS) {
      return !this.availableDates.length;
    }
    return false;
  }
}
