import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TableFragment} from 'app/fragment/table/table-fragment';
import {TableFragmentComponent} from 'app/fragment/table/table-fragment.component';
import {DocumentFragment, Fragment, FragmentType} from 'app/fragment/types';

@Component({
  selector: 'cars-table-updater',
  templateUrl: './table-updater.component.html',
  styleUrls: ['./table-updater.component.scss'],
})
export class TableUpdaterComponent implements OnInit, AfterViewInit {
  private document: DocumentFragment;

  public documentTables: TableFragment[] = [];
  public processed: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {document: DocumentFragment},
    private dialogRef: MatDialogRef<TableUpdaterComponent>
  ) {
    this.document = data.document;
  }

  ngOnInit() {
    this.document.iterateDown(null, null, (fragment: Fragment) => {
      if (fragment.type === FragmentType.TABLE) {
        this.documentTables.push(fragment as TableFragment);
      }
    });
  }

  ngAfterViewInit() {
    window.requestAnimationFrame(() => {
      this.processTables();
    });
  }

  public processTables(): void {
    this.processNext();
  }

  public processNext(): void {
    if (this.processed === this.documentTables.length) {
      this.dialogRef.close();
    } else {
      const table = this.documentTables[this.processed];
      (table.component as TableFragmentComponent).onCellBlur().then(() => {
        this.processed++;
        this.processNext();
      });
    }
  }

  public getClause(fragment: Fragment): Fragment {
    return fragment.findAncestorWithType(FragmentType.CLAUSE);
  }
}
