import {Injectable} from '@angular/core';
import {ChangelogService} from 'app/changelog/changelog.service';
import {SFPWorkflowStatus, SRPWorkflowStatus, WorkflowStatus} from 'app/documents/document-data';
import {PadType} from 'app/element-ref.service';
import {DocumentFragment} from 'app/fragment/types';
import {DocumentService} from 'app/services/document.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConditionASTNode} from '../types/condition-ast-node';
import {EqualityRelation, PermissionInput} from '../types/permissions';
import {FalseOnPadTypes} from './false-on-pad-types';
import {PermissionsHandler} from './permissions-handler';

@Injectable()
export class WorkflowStatusPermissionsHandler implements PermissionsHandler {
  constructor(private _documentService: DocumentService, private _changelogService: ChangelogService) {}

  public getInputType(): PermissionInput {
    return PermissionInput.WORKFLOW_STATUS;
  }

  @FalseOnPadTypes(PadType.NO_PAD)
  public handle(condition: ConditionASTNode, padType: PadType): Observable<boolean> {
    const workflowStatus: WorkflowStatus = this._validateAndGetWorkflowStatus(condition);
    const documentStream: Observable<DocumentFragment> = this._getDocumentStream(padType);

    switch (condition.relation) {
      case EqualityRelation.EQUALS:
        return documentStream.pipe(
          map((document: DocumentFragment) => document.documentData.workflowStatus === workflowStatus)
        );
      case EqualityRelation.NOT_EQUALS:
        return documentStream.pipe(
          map((document: DocumentFragment) => document.documentData.workflowStatus !== workflowStatus)
        );
      default:
        throw new Error(`Unsupported equality relation ${condition.relation}`);
    }
  }

  private _getDocumentStream(padType: PadType): Observable<DocumentFragment> {
    return padType === PadType.MAIN_EDITABLE
      ? this._documentService.getDocumentStream()
      : this._changelogService.getPublishedDocumentStream();
  }

  private _validateAndGetWorkflowStatus(condition: ConditionASTNode): WorkflowStatus {
    const workflowStatus = SRPWorkflowStatus[condition.value as string] ?? SFPWorkflowStatus[condition.value as string];
    if (workflowStatus !== undefined) {
      return workflowStatus;
    } else {
      throw new Error(`Unknown Workflow Status: ${condition.value}`);
    }
  }
}
