import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SectionBackgroundModule} from './section-background/section-background.module';
import {SectionCommentsModule} from './section-comments/section-comments.module';
import {SectionCompareModule} from './section-compare/section-compare.module';
import {SectionProxyModule} from './section-proxy/section-proxy.module';

@NgModule({
  imports: [CommonModule],
  exports: [SectionBackgroundModule, SectionCommentsModule, SectionCompareModule, SectionProxyModule],
})
export class SectionContentModule {}
