import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLineModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PdfDialogModule} from 'app/navigation/pdf-dialog/pdf-dialog.module';
import {PdfPreviewDialogModule} from 'app/navigation/pdf-preview-dialog/pdf-preview-dialog.module';
import {ExtendedTimeAgoModule} from 'app/utils/pipes/extended-time-ago.module';
import {NotificationComponent} from './notification.component';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatLineModule,
    MatProgressBarModule,
    ExtendedTimeAgoModule,
    PdfDialogModule,
    PdfPreviewDialogModule,
  ],
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
})
export class NotificationModule {}
