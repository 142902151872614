<div>
  <p>CARS needs to perform a data migration for the tables within this document before exporting, please stand by.</p>

  <p *ngIf="processed < documentTables.length">Processing {{ processed + 1 }} / {{ documentTables.length }}</p>

  <mat-progress-bar mode="determinate" [value]="(processed * 100) / documentTables.length"></mat-progress-bar>

  <div class="offscreen">
    <cars-clause
      *ngFor="let table of documentTables"
      [content]="getClause(table.parent)"
      [readOnly]="true"
      [showCurrentClause]="false"
      [showMarginIcons]="false"
    ></cars-clause>
  </div>
</div>
