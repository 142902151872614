import {CommonModule} from '@angular/common';
import {ErrorHandler, ModuleWithProviders, NgModule} from '@angular/core';
import {AnalyticsDirective} from 'app/error-handling/analytics.directive';
import {CarsErrorHandler} from './cars-error-handler';

@NgModule({
  imports: [CommonModule],
  declarations: [AnalyticsDirective],
  exports: [AnalyticsDirective],
})
export class CarsErrorHandlingModule {
  static forRoot(): ModuleWithProviders<CarsErrorHandlingModule> {
    return {
      ngModule: CarsErrorHandlingModule,
      providers: [{provide: ErrorHandler, useClass: CarsErrorHandler}],
    };
  }
}
