import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {ActivatedRoute} from '@angular/router';
import {DocumentRole} from 'app/documents/document-data';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {TreeDiffer} from 'app/fragment/tree-differ';
import {ClauseType, DocumentFragment, SectionFragment} from 'app/fragment/types';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {Breadcrumb, VersionTag} from 'app/interfaces';
import {SectionFetchParams, SectionService} from 'app/services/section.service';
import {RoleService} from 'app/services/user/role.service';
import {VersionTagTypeToDisplayNamePipe} from 'app/utils/pipes/version-tag-type-to-display-name.pipe';
import {UUID} from 'app/utils/uuid';
import {ViewMode} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {SectionCompareIndexerService} from './section-compare-indexer.service';

@Component({
  selector: 'cars-section-compare',
  templateUrl: './section-compare.component.html',
  styleUrls: ['./section-compare.component.scss'],
  providers: [
    {provide: FragmentIndexService, useExisting: SectionCompareIndexerService},
    SectionCompareIndexerService,
    VersionTagTypeToDisplayNamePipe,
  ],
})
export class SectionCompareComponent implements OnInit, OnDestroy {
  private static readonly _ERROR_MESSAGE_TEMPLATE: string = 'This section did not exist in version ';

  private static readonly _LIVE_OPTION: VersionTag = {
    name: 'Live',
    versionId: UUID.random(),
    fragmentId: null,
    availableToReview: false,
    availableForCommenting: false,
    userDiscussionsOnly: false,
    versionTagType: VersionTagType.NOT_KNOWN,
  };

  public sectionId: UUID;
  public section: SectionFragment;
  public previousSection: SectionFragment;
  public mergedSection: SectionFragment;

  public isAReviewer: boolean;
  public loading: boolean = false;
  public bothVersionsExist: boolean = true;
  public errorMessage: string = '';
  public closeRoute: string;

  public versionOptions: VersionTag[] = [];
  public versionOptionsRH: VersionTag[] = [];
  public currentOptionLH: VersionTag;
  public currentOptionRH: VersionTag;

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public readonly ClauseType: any = ClauseType;

  private _breadcrumbs: Breadcrumb[] = [];
  private _subscriptions: Subscription[] = [];
  private _differ: TreeDiffer = new TreeDiffer();

  constructor(
    private route: ActivatedRoute,
    private _roleService: RoleService,
    private _versioningService: VersioningService,
    private _sectionService: SectionService,
    private _viewService: ViewService,
    private _sectionCompareIndexer: SectionCompareIndexerService,
    private _versionTagTypeToDisplayNamePipe: VersionTagTypeToDisplayNamePipe
  ) {}

  public ngOnInit(): void {
    this.isAReviewer = this._roleService.isInDocumentRole(null, DocumentRole.REVIEWER);
    this._subscriptions.push(
      this.route.data.subscribe((data) => {
        this.section = data[0];
        this.sectionId = this.section.id;
        this._breadcrumbs = data.breadcrumbs;
        this._getVersions().then((taggedVersions: VersionTag[]) => {
          this.versionOptions = taggedVersions;
          this._setupCurrentOptions();
          this._changeSelectedVersions();
        });
      })
    );

    this.closeRoute = '/' + this._breadcrumbs[0].link;
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Set the currently selected options in the dropdown menus.  If there are already options
   * selected (i.e. the user is changing section), use those.  Otherwise, use the current version
   * in the LH dropdown and the the version immediately older than that version in the RH dropdown.
   */
  private _setupCurrentOptions(): void {
    if (this.currentOptionLH && this.currentOptionRH) {
      const currentLHIndex: number = this.versionOptions.findIndex((tag: VersionTag) => {
        return this.currentOptionLH && this.currentOptionLH.versionId.equals(tag.versionId);
      });
      const currentRHIndex: number = this.versionOptions.findIndex((tag: VersionTag) => {
        return this.currentOptionRH && this.currentOptionRH.versionId.equals(tag.versionId);
      });
      if (currentLHIndex !== -1 && currentRHIndex !== -1) {
        this.currentOptionLH = this.versionOptions[currentLHIndex];
        this.currentOptionRH = this.versionOptions[currentRHIndex];
        return;
      }
    }
    const currentVersionTag: VersionTag = this._viewService.getCurrentView().versionTag;
    let index = this.versionOptions.findIndex((tag: VersionTag) => {
      return currentVersionTag && currentVersionTag.versionId.equals(tag.versionId);
    });
    if (index === -1) {
      index = 0;
    }
    this.currentOptionLH = this.versionOptions[index];
    this.currentOptionRH = this.versionOptions[index + 1] || this.versionOptions[index];
  }

  /**
   * Retrieves all versions for the document. Displays only available to review versions if a reviewer.
   */
  private _getVersions(): Promise<VersionTag[]> {
    return this._versioningService
      .getVersionTagsForFragmentId(this.section.documentId, this.isAReviewer)
      .pipe(take(1))
      .toPromise()
      .then((taggedVersions: VersionTag[]) => {
        if (!this.isAReviewer) {
          taggedVersions.unshift(SectionCompareComponent._LIVE_OPTION);
        }

        return taggedVersions;
      });
  }

  /**
   * Change the version selected in the left-hand dropdown.
   *
   * @param event {MatSelectChange} The event thrown by the chosen display option
   */
  public changeVersionLH(event: MatSelectChange): void {
    this.currentOptionLH = event.value;
    this._changeSelectedVersions();
  }

  /**
   * Change the version selected in the right-hand dropdown.
   *
   * @param event {MatSelectChange} The event thrown by the chosen display option
   */
  public changeVersionRH(event: MatSelectChange): void {
    this.currentOptionRH = event.value;
    this._changeSelectedVersions();
  }

  public getOptionDisplayString(versionTag: VersionTag): string {
    if (versionTag === SectionCompareComponent._LIVE_OPTION) {
      return versionTag.name;
    } else {
      return `${versionTag.versionNumber}: ${this._versionTagTypeToDisplayNamePipe.transform(
        versionTag.versionTagType
      )} - ${versionTag.name}`;
    }
  }

  private _changeSelectedVersions(): void {
    this._setupVersionOptionsRH();
    const fetchParamsLH: SectionFetchParams = this._createFetchParams(this.currentOptionLH);
    const fetchParamsRH: SectionFetchParams = this._createFetchParams(this.currentOptionRH);
    this.loading = true;

    Promise.all([
      this._sectionService.load(this.sectionId, fetchParamsLH, false, null),
      this._sectionService.load(this.sectionId, fetchParamsRH, false, null),
    ])
      .then(([section, previousSection]: [SectionFragment, SectionFragment]) => {
        this.loading = false;
        this.section = section;
        this.previousSection = previousSection;

        if (!this.previousSection || !this.section) {
          this.bothVersionsExist = false;
          this._setupErrorMessage();
        } else {
          this._updateCurrentView();
          this.bothVersionsExist = true;
          Promise.all([
            this._sectionCompareIndexer.setPrevious(previousSection, this.currentOptionRH),
            this._sectionCompareIndexer.setCurrent(this.section, this.currentOptionLH),
          ]).then(([previousDocument, currentDocument]: [DocumentFragment, DocumentFragment]) => {
            this.mergedSection = this._differ.diffWithReferences(
              this.previousSection,
              this.section,
              previousDocument,
              currentDocument
            ) as SectionFragment;
          });
        }
      })
      .catch((e) => {
        this.loading = false;
        this.bothVersionsExist = false;
        this._setupErrorMessage();
      });
  }

  private _updateCurrentView(): void {
    this._viewService.setCurrentView(
      ViewMode.COMPARE,
      this.currentOptionLH === SectionCompareComponent._LIVE_OPTION ? null : this.currentOptionLH,
      this.currentOptionRH === SectionCompareComponent._LIVE_OPTION ? null : this.currentOptionRH
    );
  }

  private _setupErrorMessage(): void {
    if (!this.section) {
      this.errorMessage = SectionCompareComponent._ERROR_MESSAGE_TEMPLATE + this.currentOptionLH.name + '.';
    } else if (!this.previousSection) {
      this.errorMessage = SectionCompareComponent._ERROR_MESSAGE_TEMPLATE + this.currentOptionRH.name + '.';
    }
  }

  /**
   * Ensure that there are no versions in the right-hand drop down which are newer than
   * the currently selected left hand choice.
   */
  private _setupVersionOptionsRH(): void {
    this.versionOptionsRH = this.versionOptions.slice(this.versionOptions.indexOf(this.currentOptionLH));
    if (!this.versionOptionsRH.includes(this.currentOptionRH)) {
      this.currentOptionRH = this.versionOptionsRH[0];
    }
  }

  private _createFetchParams(currentOption: VersionTag): SectionFetchParams {
    return !currentOption || currentOption === SectionCompareComponent._LIVE_OPTION
      ? {}
      : {validAt: currentOption.createdAt, projection: 'FULL_TREE'};
  }
}
