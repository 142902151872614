import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FragmentModule} from 'app/fragment/fragment.module';
import {FragmentIndexModule} from 'app/fragment/indexing/fragment-index.module';
import {ErrorDialogComponent} from './error-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    FragmentModule,
    FragmentIndexModule,
  ],
  declarations: [ErrorDialogComponent],
  exports: [ErrorDialogComponent],
})
export class ErrorDialogModule {}
