import {Administration} from './administrations';

/**
 * A non exported enum to enable the creation of the DocumentChanges enum.
 */
enum ChangeTo {
  CORE_DOCUMENT = 'CORE_DOCUMENT',
}

/**
 * An enumeration of different areas in which a document could have been changed.
 */
export enum DocumentChanges {
  CORE_DOCUMENT = ChangeTo.CORE_DOCUMENT,
  ENGLAND = Administration.ENGLAND,
  NORTHERN_IRELAND = Administration.NORTHERN_IRELAND,
  SCOTLAND = Administration.SCOTLAND,
  WALES = Administration.WALES,
}
