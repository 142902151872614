import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {AltAccessibilityService, AltProperties} from 'app/services/alt-accessibility.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-alt-accessibility',
  templateUrl: 'alt-accessibility.component.html',
  styleUrls: ['alt-accessibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AltAccessibilityComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public altProperties: AltProperties;
  @Input() public richText: boolean;

  @Output() onSelect: EventEmitter<void> = new EventEmitter();
  @Output() updateAltProperties: EventEmitter<void> = new EventEmitter();

  public firstAlt: boolean = false;
  public secondAlt: boolean = false;

  public displayKey: string;

  public left: any;
  public top: any;

  private _subscriptions: Subscription[] = [];
  private _shouldDisplay: boolean = false;

  public get shouldDisplay(): boolean {
    return this._shouldDisplay;
  }

  constructor(
    private _altAccessibilityService: AltAccessibilityService,
    private _element: ElementRef,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._altAccessibilityService.onAltEvent().subscribe((value: boolean) => {
        this.updateAltProperties.emit();
        this.firstAlt = value;
        this.secondAlt = false;
        this._updateShouldDisplay();
        this.cd.markForCheck();
      }),
      this._altAccessibilityService.onKeyEvent().subscribe((keyEvent) => {
        if (!!this.altProperties.key) {
          if (keyEvent.selection) {
            if (keyEvent.key.toLowerCase() !== this.altProperties.initialKey) {
              this.secondAlt = false;
              this.firstAlt = false;
            }
          } else {
            if (this.secondAlt) {
              if (keyEvent.key.toLowerCase() === this.altProperties.key && this.altProperties.condition) {
                this.onSelect.emit();
                Logger.analytics('alt-accessibility');
                this._altAccessibilityService.triggerAltEvent(false);
              }
            } else if (
              keyEvent.key.toLowerCase() === this.altProperties.initialKey &&
              this.firstAlt &&
              this.altProperties.condition
            ) {
              this.secondAlt = true;
              this._altAccessibilityService.triggerKeyEvent({
                key: keyEvent.key,
                selection: true,
              });
              if (this.richText) {
                this._calculateLocation();
              }
            }
          }
        } else {
          if (keyEvent.key.toLowerCase() === this.altProperties.initialKey && this.firstAlt) {
            this.onSelect.emit();
            Logger.analytics('alt-accessibility');
          }
          if (keyEvent.selection) {
            this.firstAlt = false;
            this.secondAlt = false;
          }
        }
        this._updateShouldDisplay();
        this.cd.markForCheck();
      })
    );
    this._updateShouldDisplay();
    this.displayKey = !!this.altProperties.key ? this.altProperties.key : this.altProperties.initialKey.toUpperCase();
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public ngOnChanges(): void {
    this._updateShouldDisplay();
  }

  private _updateShouldDisplay(): void {
    if (!!this.altProperties.key) {
      this._shouldDisplay = this.secondAlt && this.altProperties.condition;
    } else {
      this._shouldDisplay = this.firstAlt && !this.secondAlt && this.altProperties.condition;
    }
  }

  /**
   * This calculates the fixed position used by the alt accessibility components in the rich text component.
   */
  private _calculateLocation(): void {
    const parent: HTMLElement = this._element.nativeElement.parentElement;
    if (!parent) {
      return;
    }

    const domRect: DOMRect = parent.getBoundingClientRect();

    if (!!this.altProperties.key) {
      this.left = domRect.left - 5;
      this.top = domRect.top - 26;
    } else {
      this.left = domRect.left + 15;
      this.top = domRect.top + 20;
    }
  }
}
