import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ClauseFragment} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {ClauseService} from 'app/services/clause.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {ValidationError, ValidationSeverity} from 'app/validation/validation-rule';
import {ValidationService} from 'app/validation/validation.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-validation-icon',
  templateUrl: './validation-icon.component.html',
  styleUrls: ['./validation-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationIconComponent implements OnInit, OnDestroy {
  @Input() public clause: ClauseFragment;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly ValidationSeverity: typeof ValidationSeverity = ValidationSeverity;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public validationErrors: ValidationError[] = [];
  public maxSeverity: ValidationSeverity;

  private _reordering: boolean;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _clauseService: ClauseService,
    private _sidebarService: SidebarService,
    private _validationService: ValidationService,
    private _reorderClausesService: ReorderClausesService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._validationService.onValidation(this.clause.id, (validationErrors: ValidationError[]) => {
        this.validationErrors = validationErrors;
        this.maxSeverity = ValidationService.getMaxSeverityOf(this.validationErrors);
        this._cdr.markForCheck();
      }),
      this._reorderClausesService.onReorderingEvent().subscribe((status: boolean) => (this._reordering = status))
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public openSidebar() {
    if (!this._reordering) {
      this._clauseService.setSelected(this.clause);
      this._sidebarService.setSidebarStatus(SidebarStatus.VALIDATION);
    }
  }
}
