import {CarsRange} from 'app/fragment/cars-range';
import {hasDescendantOfType, isClauseGroupOfType} from 'app/fragment/fragment-utils';
import {TableFragment} from 'app/fragment/table/table-fragment';
import {
  ClauseFragment,
  ClauseType,
  EquationFragment,
  Fragment,
  FragmentType,
  ListFragment,
  ListItemFragment,
  SectionFragment,
  SectionType,
} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {ClauseGroupType} from 'app/fragment/types/clause-group-type';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {SectionGroupType} from 'app/fragment/types/section-group-type';
import {FragmentService} from 'app/services/fragment.service';
import {CurrentView} from 'app/view/current-view';

export class EnabledToolbarFunctions {
  public static readonly functionNameToImplementation: Readonly<Record<string, (...args: any[]) => boolean>> = {
    isInsertNationallyDeterminedRequirementEnabled:
      EnabledToolbarFunctions.isInsertNationallyDeterminedRequirementEnabled,
    isInsertStandardFormatRequirementEnabled: EnabledToolbarFunctions.isInsertStandardFormatRequirementEnabled,
    isInlineEquationEnabled: EnabledToolbarFunctions.isInlineEquationEnabled,
    isListEnabled: EnabledToolbarFunctions.isListEnabled,
    isListIncreaseIndentEnabled: EnabledToolbarFunctions.isListIncreaseIndentEnabled,
    isListDecreaseIndentEnabled: EnabledToolbarFunctions.isListDecreaseIndentEnabled,
    isTextStylingEnabled: EnabledToolbarFunctions.isTextStylingEnabled,
    isTableEnabled: EnabledToolbarFunctions.isTableEnabled,
    isUndoEnabled: EnabledToolbarFunctions.isUndoEnabled,
    isRedoEnabled: EnabledToolbarFunctions.isRedoEnabled,
    fragmentIsNotInClauseGroupOrSpecifierInstruction:
      EnabledToolbarFunctions.fragmentIsNotInClauseGroupOrSpecifierInstruction,
    fragmentIsNotInStandardFormatGroupOrSpecifierInstruction:
      EnabledToolbarFunctions.fragmentIsNotInStandardFormatGroupOrSpecifierInstruction,
    fragmentIsNotInSpecifierInstruction: EnabledToolbarFunctions.fragmentIsNotInSpecifierInstruction,
    fragmentIsNotNull: EnabledToolbarFunctions.fragmentIsNotNull,
    fragmentIsNotNullAndNotIntroductorySection: EnabledToolbarFunctions.fragmentIsNotNullAndNotIntroductorySection,
    isSuggestChangesEnabled: EnabledToolbarFunctions.isSuggestChangesEnabled,
    isInsertImageEnabled: EnabledToolbarFunctions.isInsertImageEnabled,
  };

  private static isInsertNationallyDeterminedRequirementEnabled(fragment: Fragment): boolean {
    if (!EnabledToolbarFunctions.fragmentIsNotNullAndNotIntroductorySection(fragment)) {
      return false;
    }

    const sectionGroup: SectionGroupFragment = fragment.findAncestorWithType(
      FragmentType.SECTION_GROUP
    ) as SectionGroupFragment;
    const isInNationallyDeterminedSection: boolean =
      sectionGroup?.sectionGroupType === SectionGroupType.NATIONAL_DETERMINED_SECTION;

    return !isInNationallyDeterminedSection;
  }

  /**
   * We want to disable the standard format requirement button if:
   * - fragment is null
   * - we are in an introductory section
   * - we are in a standard format requirement in a nationally determined requirement
   * - we are in a specific instruction clause in a nationally determined requirement
   */
  private static isInsertStandardFormatRequirementEnabled(fragment: Fragment): boolean {
    if (!EnabledToolbarFunctions.fragmentIsNotNullAndNotIntroductorySection(fragment)) {
      return false;
    }

    const clauseGroupTypes: ClauseGroupType[] = fragment
      .findAllAncestorsWithType(FragmentType.CLAUSE_GROUP)
      .map((_fragment: Fragment) => (_fragment as ClauseGroupFragment).clauseGroupType);

    if (
      EnabledToolbarFunctions.arraysEqualPreserveOrder(clauseGroupTypes, [
        ClauseGroupType.STANDARD_FORMAT_REQUIREMENT,
        ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT,
      ])
    ) {
      return false;
    }

    const clause: Fragment = fragment.findAncestorWithType(FragmentType.CLAUSE);

    return (
      !clause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) ||
      !clauseGroupTypes.includes(ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)
    );
  }

  private static arraysEqualPreserveOrder<T>(array1: T[], array2: T[]): boolean {
    return array1.length === array2.length && array1.every((item: T, index: number) => item === array2[index]);
  }

  private static isInlineEquationEnabled(fragment: Fragment): boolean {
    if (
      (!EnabledToolbarFunctions.fragmentIsNotInStandardFormatGroupOrSpecifierInstruction(fragment) &&
        !EnabledToolbarFunctions.isTableInSFR(fragment)) ||
      EnabledToolbarFunctions.isInCaption(fragment)
    ) {
      return false;
    }

    const table: Fragment = fragment.findAncestorWithType(FragmentType.TABLE);
    const equation: EquationFragment = fragment.findAncestorWithType(FragmentType.EQUATION) as EquationFragment;

    if (table) {
      return !(equation && equation.inline);
    }

    return !equation;
  }

  private static isListEnabled(fragment: Fragment): boolean {
    return (
      EnabledToolbarFunctions.fragmentIsNotInSpecifierInstruction(fragment, FragmentType.LIST) &&
      !fragment.findAncestorWithType(FragmentType.LIST)
    );
  }

  private static isTextStylingEnabled(fragment: Fragment): boolean {
    if (!EnabledToolbarFunctions.fragmentIsNotNull(fragment) || EnabledToolbarFunctions.isInCaption(fragment)) {
      return false;
    }

    const table: Fragment = fragment.findAncestorWithType(FragmentType.TABLE);
    const equation: EquationFragment = fragment.findAncestorWithType(FragmentType.EQUATION) as EquationFragment;

    if (table) {
      return !(equation && equation.inline);
    }

    return !equation;
  }

  private static isListIncreaseIndentEnabled(fragment: Fragment): boolean {
    return !!fragment && EnabledToolbarFunctions._isListIndentEnabled(fragment, true);
  }

  private static isListDecreaseIndentEnabled(fragment: Fragment): boolean {
    return !!fragment && EnabledToolbarFunctions._isListIndentEnabled(fragment, false);
  }

  private static _isListIndentEnabled(fragment: Fragment, increaseIndent: boolean) {
    const list: ListFragment = fragment.findAncestorWithType(FragmentType.LIST) as ListFragment;
    const listItem: ListItemFragment = fragment.findAncestorWithType(FragmentType.LIST_ITEM) as ListItemFragment;

    return listItem && !list.children[0].id.equals(listItem.id) ? increaseIndent !== listItem.indented : false;
  }

  private static isTableEnabled(fragment: Fragment): boolean {
    return EnabledToolbarFunctions.fragmentIsNotInSpecifierInstruction(fragment, FragmentType.TABLE);
  }

  private static isUndoEnabled(fragmentService: FragmentService): boolean {
    return fragmentService.isRevertible(1);
  }

  private static isRedoEnabled(fragmentService: FragmentService): boolean {
    return fragmentService.isRevertible(-1);
  }

  private static fragmentIsNotInStandardFormatGroupOrSpecifierInstruction(fragment: Fragment): boolean {
    if (!EnabledToolbarFunctions.fragmentIsNotNull(fragment)) {
      return false;
    }

    const clause: ClauseFragment = fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const clauseGroup: ClauseGroupFragment = fragment.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    const isLoneFragment: boolean = !clauseGroup && !clause;
    const isInStandardFormatGroup: boolean =
      !!clauseGroup && isClauseGroupOfType(clauseGroup, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT);
    const isInSpecifierInstruction: boolean = !!clause && clause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION);

    return !isLoneFragment && !isInStandardFormatGroup && !isInSpecifierInstruction;
  }

  /**
   *
   * This method should be functionally identical to @fragmentIsNotInStandardFormatGroupOrSpecifierInstruction
   * but with any references to SFRs removed.
   *
   */
  private static fragmentIsNotInSpecifierInstruction(fragment: Fragment, fragmentType: FragmentType): boolean {
    if (!EnabledToolbarFunctions.fragmentIsNotNull(fragment)) {
      return false;
    }

    const clause: ClauseFragment = fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const clauseGroup: ClauseGroupFragment = fragment.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    const isLoneFragment: boolean = !clauseGroup && !clause;
    const isInSpecifierInstruction: boolean = !!clause && clause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION);
    const alreadyContainsFragment: boolean = !!clause && hasDescendantOfType(clause, 1, fragmentType);
    return !isLoneFragment && !isInSpecifierInstruction && !alreadyContainsFragment;
  }

  private static fragmentIsNotInClauseGroupOrSpecifierInstruction(fragment: Fragment): boolean {
    if (!EnabledToolbarFunctions.fragmentIsNotNull(fragment)) {
      return false;
    }

    const clause: ClauseFragment = fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const clauseGroup: ClauseGroupFragment = fragment.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    const isLoneFragment: boolean = !clauseGroup && !clause;
    const isInClauseGroup: boolean = !!clauseGroup;
    const isInSpecifierInstruction: boolean = !!clause && clause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION);

    return !isLoneFragment && !isInClauseGroup && !isInSpecifierInstruction;
  }

  /**
   * Checks that the fragment is not null and that it has a clause ancestor which is not unmodifiable.
   */
  private static fragmentIsNotNull(fragment: Fragment): boolean {
    if (!fragment) {
      return false;
    }

    const clause: ClauseFragment = fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;

    return !(clause && clause.isUnmodifiableClause);
  }

  private static fragmentIsNotNullAndNotIntroductorySection(fragment: Fragment): boolean {
    if (!EnabledToolbarFunctions.fragmentIsNotNull(fragment)) {
      return false;
    }

    const section: SectionFragment = fragment.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
    const sectionType: SectionType = section?.sectionType;

    return !!sectionType && sectionType !== SectionType.INTRODUCTORY;
  }

  private static isSuggestChangesEnabled(range: CarsRange, currentView: CurrentView): boolean {
    return !!range && range.isValidSuggestion() && !!currentView && currentView.userCanSuggestTextChange();
  }

  private static isInCaption(fragment: Fragment): boolean {
    return !!fragment?.parent?.isCaptioned() || !!fragment?.isCaptioned();
  }

  private static isInsertImageEnabled(fragment: Fragment): boolean {
    return (
      EnabledToolbarFunctions.fragmentIsNotInStandardFormatGroupOrSpecifierInstruction(fragment) ||
      EnabledToolbarFunctions.isTableInSFR(fragment)
    );
  }

  private static isTableInSFR(fragment: Fragment): boolean {
    const table: TableFragment = fragment?.findAncestorWithType(FragmentType.TABLE) as TableFragment;
    const clauseGroup: ClauseGroupFragment = table?.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    return !!table && !!clauseGroup && isClauseGroupOfType(clauseGroup, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT);
  }

  // Protected constructor to prevent external instantiation
  protected constructor() {}
}
