import {Logger} from 'app/error-handling/services/logger/logger.service';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {Callback} from 'app/utils/typedefs';
import {UUID} from 'app/utils/uuid';
import {Caret} from './caret';
import {EDITABLE_TEXT_FRAGMENT_TYPES, Fragment, FragmentType} from './types';

/**
 * An enumeration of the possible range types on the pad.
 */
export enum CarsRangeType {
  SELECTION,
  SUGGESTION,
  PENDING_SUGGESTION,
  SPELLING_ERROR,
  CHANGELOG_RANGE,
  DELETED_CHANGELOG_RANGE,
  SEARCH_RESULT,
  SELECTED_SEARCH_RESULT,
}

/**
 * A class representing a selection range within the pad.
 *
 * @field start {Caret}         Start caret
 * @field end   {Caret}         End caret
 * @field type  {CarsRangeType} The range type
 * @field section {Fragment} The section ancestor of the range
 */
export class CarsRange {
  /**
   * @returns {string} Value contained between the range.
   */
  public get value(): string {
    if (!this.start || !this.end || !this.start.fragment || !this.end.fragment) {
      return '';
    }
    const [startFragment, endFragment]: [Fragment, Fragment] = [this.start.fragment, this.end.fragment];

    if (startFragment.equals(endFragment)) {
      return startFragment.value.slice(this[0].offset, this[1].offset);
    } else {
      let value: string = startFragment.value.slice(this[0].offset, startFragment.length());
      let fragment: Fragment = startFragment.nextLeaf();

      while (fragment && !fragment.equals(endFragment)) {
        value += fragment.value;
        fragment = fragment.nextLeaf();
      }

      value += endFragment.value.slice(0, this[1].offset);
      return value;
    }
  }

  /**
   * Allows array-style referencing of the start caret, like range[0]
   */
  public get 0(): Caret {
    return this.start;
  }

  /**
   * Allows array-style referencing of the end caret, like range[1]
   */
  public get 1(): Caret {
    return this.end;
  }

  public start: Caret;
  public end: Caret;
  public type: CarsRangeType;

  public forceRedraw: boolean = false;

  public sectionId: UUID;
  public documentId: UUID;

  /**
   * Static helper to create a CarsRange from two carets for a given type.
   *
   * @param carets {Caret[]}       Carets to create range from
   * @param type   {CarsRangeType} The range type
   */
  public static fromArray(carets: Caret[], type: CarsRangeType = CarsRangeType.SELECTION): CarsRange {
    return !carets ? new CarsRange(null, null) : new CarsRange(carets[0], carets[1], type);
  }

  /**
   * Static helper to assert equality of two ranges. Wile dealing with the possibility that one or both may be null.
   *
   * @param carets {Caret[]}       Carets to create range from
   * @param type   {CarsRangeType} The range type
   */
  public static equals(r1: CarsRange, r2: CarsRange): boolean {
    if (!r1 || !r2) {
      return !r1 && !r2;
    }
    return r1.equals(r2);
  }

  constructor(start: Caret, end: Caret, type: CarsRangeType = CarsRangeType.SELECTION) {
    this.start = start;
    this.end = end;
    this.type = type;
    const fragment = start || end ? (start || end).fragment : null;
    if (fragment) {
      const section = fragment.findAncestorWithType(FragmentType.SECTION);
      const document = fragment.findAncestorWithType(FragmentType.DOCUMENT);
      if (section) {
        this.sectionId = section.id;
      }
      if (document) {
        this.documentId = document.id;
      }
    }
  }

  /**
   * Compares carets and range type for equality.
   *
   * @param other {CarsRange} Range to compare
   * @returns     {boolean}   True if these ranges are equal
   */
  public equals(other: CarsRange): boolean {
    if (other) {
      const startEquals: boolean = this.start ? this.start.equals(other.start) : !other.start;
      const endEquals: boolean = this.end ? this.end.equals(other.end) : !other.end;
      return startEquals && endEquals && this.type === other.type;
    }
    return false;
  }

  /**
   * @returns true if the caret is in the pad, otherwise false.
   */
  public isInPad(): boolean {
    return !!this.start || !!this.end;
  }

  /**
   * @returns {boolean} True if the selection has zero length, otherwise false.  If the selection
   *                    is not in the pad, returns false.
   */
  public isCollapsed(): boolean {
    return (
      (!!this.start && !!this.end && this.start.equals(this.end)) ||
      (!!this.start && !this.end) ||
      (!this.start && !!this.end)
    );
  }
  /**
   * Sets the end caret equal to the start.
   */
  public collapse(): void {
    if (this.isCollapsed()) {
      return;
    }
    this.end = this.start;
  }

  /**
   * @returns {boolean} True if the selection is within one clause, otherwise false.
   *                    If the selection is not in the pad, returns false.
   */
  public isBoundedByClause(): boolean {
    if (this.isCollapsed()) {
      return true;
    }
    if (!this.start || !this.end) {
      return false;
    }
    if (!this.start.fragment || !this.end.fragment) {
      return false;
    }
    const startClause: Fragment = this.start.fragment.findAncestorWithType(FragmentType.CLAUSE);
    const endClause: Fragment = this.end.fragment.findAncestorWithType(FragmentType.CLAUSE);
    return startClause && endClause && startClause.equals(endClause);
  }

  /**
   * @returns {boolean} True if text in the range is a valid suggestion.
   */
  public isValidSuggestion(): boolean {
    return (
      this.isBoundedByClause() &&
      !this.isCollapsed() &&
      this.start.fragment.parent.equals(this.end.fragment.parent) &&
      !this.start.fragment.parent.isCaptioned() &&
      !this.start.fragment.parent.children
        .slice(this.start.fragment.index(), this.end.fragment.index() + 1)
        .some((f: Fragment) => {
          return !f.is(...EDITABLE_TEXT_FRAGMENT_TYPES, FragmentType.ANCHOR);
        })
    );
  }

  /**
   * @returns {boolean} True if reference can be inserted in range.
   */
  public isValidReferenceInsertion(): boolean {
    if (!this.start) {
      return false;
    }
    const startFragment: Fragment = this.start.fragment;
    return (
      this.isCollapsed() &&
      startFragment.is(...EDITABLE_TEXT_FRAGMENT_TYPES) &&
      !this.start.fragment.parent.isCaptioned() &&
      !startFragment.parent.is(FragmentType.EQUATION)
    );
  }

  /**
   * @returns {boolean} True if the range can be made into a changelog highlight.  This does not
   * mean that it _is_ a changelog highlight.
   */
  public isValidChangelogRange(): boolean {
    return (
      !this.isCollapsed() &&
      !!this.start &&
      !!this.end &&
      this.start.fragment.component &&
      this.end.fragment.component &&
      !this.start.fragment.component['caption'] &&
      !this.end.fragment.component['caption']
    );
  }

  /**
   * @returns {string} Hashcode computed from the fragment IDs, offsets & type of range.
   */
  public hashcode(): string {
    let code: string = '';
    code += this.start && this.start.fragment ? this.start.fragment.id.value + this.start.offset : 'null';
    code += this.end && this.end.fragment ? this.end.fragment.id.value + this.end.offset : 'null';
    code += CarsRangeType[this.type];
    return code;
  }

  /**
   * Iterate over the fragment subtree of the common ancestor of the start and end fragments
   * of the range, bound by and including the start and end fragments, and run the callback
   * method on each.
   *
   * If start or start.fragment is null, do nothing.  If there is a start fragment but no end,
   * callback is only run on start.fragment.
   *
   * @param callback {Callback<Fragment>} The callback to run.
   */
  public forEachFragment(callback: Callback<Fragment>): void {
    if (!this.start || !this.start.fragment) {
      return;
    }
    if (!this.end || !this.end.fragment) {
      return callback(this.start.fragment);
    }
    if (this.start.fragment.equals(this.end.fragment)) {
      return callback(this.start.fragment);
    }

    const ancestor: Fragment = Fragment.commonAncestorOf(this.start.fragment, this.end.fragment);
    if (ancestor) {
      ancestor.iterateDown(this.start.fragment, this.end.fragment, callback);
    } else {
      Logger.error('range-error', 'Failed to find common ancestor for range ' + this.hashcode());
    }
  }

  /**
   * Iterate over the individual ClientRects of the leaf fragments which are
   * spanned by this range.  Unlike forEachFragment, this does not iterate over the entire
   * subtree spanned by the range.
   *
   * If either start or end carets, fragments, or components are null, does nothing.
   *
   * @param callback {Callback<DOMRect>} The callback to run.
   */
  public forEachClientRect(callback: Callback<DOMRect>): void {
    if (
      !this.start ||
      !this.end ||
      !this.start.fragment ||
      !this.end.fragment ||
      !this.start.fragment.component ||
      !this.end.fragment.component
    ) {
      return;
    }

    const startComponent: FragmentComponent = this.start.fragment.component;
    const endComponent: FragmentComponent = this.end.fragment.component;
    const startNode: Node = this._textNodesFrom(startComponent.element)[0];
    const endNode: Node = this._textNodesFrom(endComponent.element)[0];
    const range: Range = document.createRange();

    if (this.end.fragment.equals(this.start.fragment)) {
      // Handle special case where start fragment is the same as end fragment:
      this._safeSet(range, 'start', startNode, this.start.offset);
      this._safeSet(range, 'end', endNode, this.end.offset);
      this._iterateClientRectList(range.getClientRects(), callback);
      return;
    }

    // Handle special case for start:
    this._safeSet(range, 'start', startNode, this.start.offset);
    range.setEndAfter(startNode);
    this._iterateClientRectList(range.getClientRects(), callback);

    let fragment: Fragment = this.start.fragment.nextLeaf();
    while (fragment) {
      if (fragment.component && fragment.component.element) {
        if (fragment.equals(this.end.fragment)) {
          // Handle special case for end:
          this._safeSet(range, 'start', endNode, 0);
          this._safeSet(range, 'end', endNode, this.end.offset);
          this._iterateClientRectList(range.getClientRects(), callback);
          break;
        } else {
          this._iterateClientRectList(fragment.component.element.getClientRects(), callback);
        }
      }
      fragment = fragment.nextLeaf();
    }

    range.detach();
  }

  private _iterateClientRectList(list: DOMRectList, callback: Callback<DOMRect>): void {
    let i: number = 0;
    while (i < list.length) {
      callback(list[i]);
      i++;
    }
  }

  private _textNodesFrom(node: Node): Text[] {
    const result: Text[] = [];

    if (node instanceof Text) {
      result.push(node);
    } else if (node.childNodes.length > 0) {
      [].slice.apply(node.childNodes).forEach((child: Node) => {
        result.push(...this._textNodesFrom(child));
      });
    }

    return result;
  }

  private _safeSet(range: Range, end: 'end' | 'start', node: Node, offset: number): void {
    const length: number = node.nodeValue ? node.nodeValue.length : 0;
    if (end === 'end') {
      range.setEnd(node, Math.min(length, offset));
    } else {
      range.setStart(node, Math.max(0, Math.min(length, offset)));
    }
  }
}
