<div class="mchw-export-creator">
  <h1 matDialogTitle [innerHTML]="title"></h1>
  <div mat-dialog-content class="mchw-export-input-container">
    <mat-checkbox [(ngModel)]="selectAll" (click)="toggleSelectAll()" aria-label="Select all export options">
      Select All
    </mat-checkbox>

    <p>Standards for Highways Works + Instructions for Specifiers</p>
    <ul>
      <li>
        <mat-checkbox [(ngModel)]="exportShw" (click)="clearSelectAll()" aria-label="Include SHW"> SHW </mat-checkbox>
      </li>
      <li>
        <mat-checkbox [(ngModel)]="exportIfs" (click)="clearSelectAll()" aria-label="Include IfS"> IfS </mat-checkbox>
      </li>
    </ul>

    <p>Work Specific Requirements</p>
    <ul>
      <li>
        <mat-checkbox [(ngModel)]="exportOds" (click)="clearSelectAll()" aria-label="Include WSR Templates">
          WSR Templates
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox [(ngModel)]="exportOdsReview" (click)="clearSelectAll()" aria-label="Include WSR Review Template">
          WSR Review Template
        </mat-checkbox>
      </li>
    </ul>

    <p>Document Variations</p>
    <ul>
      <li>
        <mat-checkbox [(ngModel)]="exportCore" (click)="clearSelectAll()" aria-label="Include Core">
          Core
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox [(ngModel)]="exportEngland" (click)="clearSelectAll()" aria-label="Include England">
          England
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox
          [(ngModel)]="exportNorthernIreland"
          (click)="clearSelectAll()"
          aria-label="Include Northern Ireland"
        >
          Northern Ireland
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox [(ngModel)]="exportScotland" (click)="clearSelectAll()" aria-label="Include Scotland">
          Scotland
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox [(ngModel)]="exportWales" (click)="clearSelectAll()" aria-label="Include Wales">
          Wales
        </mat-checkbox>
      </li>
    </ul>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      mat-raised-button
      color="primary"
      (click)="prepareMchwExportRequest()"
      [disabled]="exportDisabled()"
      aria-label="Export Selection"
    >
      Export Selection
    </button>
    <button mat-button mat-raised-button color="basic" (click)="cancel()" aria-label="Cancel">Cancel</button>
  </div>
</div>
