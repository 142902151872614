<div>
  <h1 mat-dialog-title>{{ titleText }}</h1>
  <div mat-dialog-content class="associated-document-modal-container">
    <p class="guidance-text">
      Click here for guidance on using associated documents -
      <a href="https://help.futuredmrb.co.uk/using-associated-documents/" target="_blank">see here for guidance</a>
    </p>

    <form #associatedDocumentInformationForm="ngForm" style="position: relative">
      <div *ngIf="!remove">
        <mat-form-field class="modal-field-styling-dropdown">
          <mat-select
            placeholder="Associated document type"
            name="associatedDocumentType"
            [disabled]="edit"
            [(value)]="associatedDocumentType"
            aria-label="select associated document type"
          >
            <mat-option *ngFor="let type of associatedDocumentTypes" [value]="type">{{
              this.getDisplayStringForType(type)
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <br />

        <div class="national-variation-toggle-container">
          <mat-slide-toggle
            (change)="onToggleChange($event)"
            [checked]="hasNationalVariations"
            class="national-variation-toggle"
            color="primary"
          ></mat-slide-toggle>
          <p>{{ getHasNationalVariationText() }}</p>
        </div>

        <mat-form-field class="modal-field-styling-freetext">
          <input
            autocomplete="off"
            matInput
            placeholder="Title"
            [(ngModel)]="title"
            maxLength="100"
            name="title"
            required
            carsRequiredNonWhitespace
          />
          <mat-hint>Title must be < 100 characters</mat-hint>
          <mat-error>Please provide a valid title</mat-error>
        </mat-form-field>
      </div>

      <div class="remove-warning" *ngIf="remove">
        This action will remove "{{ title }}" ({{ reference }}) and cannot be undone
      </div>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button aria-label="Cancel" (click)="cancel()">Cancel</button>
    <button
      mat-raised-button
      [color]="remove ? 'warn' : 'primary'"
      aria-label="Confirm action"
      (click)="confirm(remove)"
      [disabled]="!remove && !title"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</div>
