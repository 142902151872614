import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Page} from 'app/admin/manage-references/manage-references.component';
import {Unit} from 'app/sidebar/units/unit';
import {environment} from 'environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UnitService extends BaseService {
  private static readonly UNITS_ENDPOINT: string = `${environment.apiHost}/units`;

  constructor(private _http: HttpClient, protected _snackBar: MatSnackBar) {
    super(_snackBar);
  }

  /**
   * Fetch the requested page of the requested size of units that match the given search parameters.
   */
  public search(searchTerm: string, page: number, size: number): Observable<Page<Unit>> {
    const params: {[param: string]: string} = {
      searchTerm: searchTerm,
      page: page.toString(),
      size: size.toString(),
      sort: 'title,asc',
    };
    return this._http
      .get(`${UnitService.UNITS_ENDPOINT}/search`, {
        params,
      })
      .pipe(
        map((json: any) => this._extractUnitsPage(json)),
        catchError((error) => {
          this._handleError(error, 'Failed to load units, please try again or contact support', 'unit-error');
          return throwError(error);
        })
      );
  }

  /**
   * Deserialise units.  Can handle the json+HAL format used by spring data REST endpoints
   * as well as normal json formats.
   *
   * @param json the json to deserialise.
   * @returns the deserialised Unit Page.
   */
  private _extractUnitsPage(json: any): Page<Unit> {
    const mapped: Unit[] = json['content'].map(Unit.deserialise);
    return {
      resultsList: mapped,
      total: json.totalElements,
    };
  }
}
