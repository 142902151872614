import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {UserModule} from 'app/widgets/user/user.module';
import {LockingOverlayComponent} from './locking-overlay.component';

@NgModule({
  imports: [CommonModule, UserModule, CarsPermissionPipesModule],
  declarations: [LockingOverlayComponent],
  exports: [LockingOverlayComponent],
})
export class LockingOverlayModule {}
