import {FragmentService} from 'app/services/fragment.service';
import {ChangelogRange} from '../changelog-range';
import {ClauseRow} from './clause-row';
import {LinkRow} from './link-row';
import {JsonLinkRow, JsonRangeRow} from './link-table.service';

export class RangeRow {
  public children: LinkRow[];
  public show: boolean;

  public static deserialise(json: JsonRangeRow, parent: ClauseRow, fragmentService: FragmentService): RangeRow {
    const rangeRow: RangeRow = new RangeRow(
      json.publishedRange ? ChangelogRange.deserialise(json.publishedRange, fragmentService, null) : null,
      parent
    );
    rangeRow.children = RangeRow.deserialiseLinkRows(json.children, rangeRow, fragmentService);
    return rangeRow;
  }

  private static deserialiseLinkRows(
    json: JsonLinkRow[],
    parent: RangeRow,
    fragmentService: FragmentService
  ): LinkRow[] {
    const linkRows: LinkRow[] = [];
    json.forEach((linkRow: JsonLinkRow) => {
      linkRows.push(LinkRow.deserialise(linkRow, parent, fragmentService));
    });
    return linkRows;
  }

  constructor(public publishedRange: ChangelogRange, public parentClauseRow: ClauseRow) {
    this.publishedRange = publishedRange;
    this.children = [];
    this.show = true;
    this.parentClauseRow = parentClauseRow;
  }

  public shownChildren(): LinkRow[] {
    return this.children.filter((l: LinkRow) => l.show);
  }

  public hasSamePublishedRange(rangeRow: RangeRow): boolean {
    return rangeRow.publishedRange.id.equals(this.publishedRange.id);
  }
}
