import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {ClauseGroupService} from 'app/services/clause-group.service';
import {ClauseService} from 'app/services/clause.service';
import {FragmentDeletionValidationService} from 'app/services/fragment-deletion-validation.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {RichTextType} from 'app/services/rich-text.service';
import {ScheduleTableService} from 'app/services/schedule-table.service';
import {STANDARD_FORMAT_TYPE_TO_DISPLAY_NAME} from 'app/utils/pipes/standard-format-type-to-display-name.pipe';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';
import {PadType} from '../../element-ref.service';
import {ActionRequest} from '../action-request';
import {Caret} from '../caret';
import {FragmentComponent} from '../core/fragment.component';
import {getFirstEditableDescendant} from '../fragment-utils';
import {Key} from '../key';
import {TableFragment} from '../table/table-fragment';
import {
  ClauseFragment,
  ClauseType,
  DocumentFragment,
  Fragment,
  FragmentType,
  ListFragment,
  SectionFragment,
  WeightInferenceFailure,
} from '../types';
import {ClauseGroupFragment} from '../types/clause-group-fragment';
import {ClauseGroupType} from '../types/clause-group-type';
import {InternalDocumentReferenceFragment} from '../types/reference/internal-document-reference-fragment';
import {FieldDefinitionSpecifierInstructionTypes} from '../types/specifier-instruction-type';
import {StandardFormatType} from '../types/standard-format-type';

@Component({
  selector: 'cars-clause-group-fragment',
  templateUrl: './clause-group-fragment.component.html',
  styleUrls: ['./clause-group-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseGroupFragmentComponent extends FragmentComponent implements OnInit, OnDestroy {
  @Input() public set content(value: ClauseGroupFragment) {
    super.content = value;
  }

  public get content(): ClauseGroupFragment {
    return super.content as ClauseGroupFragment;
  }

  @Input() public padType: PadType = PadType.MAIN_EDITABLE;

  public readonly PadType: typeof PadType = PadType;
  public readonly CarsAction: typeof CarsAction = CarsAction;
  public readonly ClauseGroupType: typeof ClauseGroupType = ClauseGroupType;

  public scheduleTableClause: ClauseFragment = ClauseFragment.empty(ClauseType.REQUIREMENT);

  public loadingPreview: boolean = false;

  public showErrorMessage: boolean = false;

  public groupTitle: string = '';

  public reordering: boolean = false;

  public isDeprecatedSFRType: boolean = false;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _reorderClausesService: ReorderClausesService,
    private _clauseGroupService: ClauseGroupService,
    private _fragmentService: FragmentService,
    private _clauseService: ClauseService,
    private _scheduleTableService: ScheduleTableService,
    private _lockService: LockService,
    private _viewService: ViewService,
    private _fragmentDeletionValidationService: FragmentDeletionValidationService,
    private _snackbar: MatSnackBar,
    protected _cdr: ChangeDetectorRef,
    protected _elementRef: ElementRef
  ) {
    super(_cdr, _elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit(); // IMPORTANT
    switch (this.content.clauseGroupType) {
      case ClauseGroupType.STANDARD_FORMAT_REQUIREMENT:
        this.groupTitle = STANDARD_FORMAT_TYPE_TO_DISPLAY_NAME[this.content.standardFormatType];
        this._setDeprecatedSFRStatus();
        this._subscriptions.push(
          this._clauseGroupService.onStandardFormatGroupTemplatesLoad().subscribe(() => this._setDeprecatedSFRStatus())
        );
        if (
          this.content.children.length > 1 &&
          this.content.standardFormatType === StandardFormatType.SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS_V2
        ) {
          this.generateScheduleTableFragments();
        }
        break;
      case ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT:
        this.groupTitle = 'Nationally Determined Requirement';
        break;
      default:
        this.groupTitle = null;
        break;
    }

    this._subscriptions.push(
      this._reorderClausesService.onSelectedClausesChange().subscribe(() => {
        this.reordering = this._reorderClausesService.isSelected(this.content);
        this.markForCheck();
      })
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy(); // IMPORTANT

    this._subscriptions.splice(0).forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    return key.equalsUnmodified(Key.ENTER) ? null : new ActionRequest();
  }

  public async onRichText(type: RichTextType, start: Caret, end: Caret, ...args: any[]): Promise<ActionRequest> {
    switch (this.content.clauseGroupType) {
      case ClauseGroupType.STANDARD_FORMAT_REQUIREMENT:
        return this._handleIfIsStandardFormatRequirement(type);
      case ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT:
        return await this._handleIfIsNationallyDeterminedRequirement(type, args);
      default:
        return new ActionRequest();
    }
  }

  public generateScheduleTableFragments(): void {
    this.loadingPreview = true;

    const validAt: number = this._viewService.getCurrentView()?.versionTag?.createdAt;

    this._scheduleTableService
      .getScheduleTableFragments(this.content, validAt)
      .then((clauseGroup: ClauseGroupFragment) => {
        const newClause: ClauseFragment = new ClauseFragment(null, ClauseType.REQUIREMENT, [], '');
        newClause.standardFormatType = StandardFormatType.SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS_V2;

        clauseGroup.children[0].children.forEach((child) => {
          if (this._isScheduleTableOrScheduleList(child)) {
            newClause.children.push(child);
          }
        });

        this.scheduleTableClause = newClause;

        this.loadingPreview = false;
      })
      .catch(() => {
        this.showErrorMessage = true;
        this.loadingPreview = false;
      });

    this.showErrorMessage = this.shouldShowErrorMessge();
  }

  private _isScheduleTableOrScheduleList(frag: Fragment): boolean {
    if (frag.is(FragmentType.TABLE)) {
      return (frag as TableFragment).isScheduleTable;
    } else if (frag.is(FragmentType.LIST)) {
      return (frag as ListFragment).isScheduleList;
    }
    return false;
  }

  private shouldShowErrorMessge(): boolean {
    for (const clause of this.content.children) {
      if (
        (clause as ClauseFragment).clauseType === ClauseType.SPECIFIER_INSTRUCTION &&
        !FieldDefinitionSpecifierInstructionTypes.has((clause as ClauseFragment).specifierInstructionType)
      ) {
        return true;
      }
    }

    return false;
  }

  /**
   * Handle the rich text event if this clause group is a standard format requirement.
   */
  private _handleIfIsStandardFormatRequirement(richTextType: RichTextType): ActionRequest {
    switch (richTextType) {
      case RichTextType.STANDARD_FORMAT_GROUP:
      case RichTextType.SPECIFIER_INSTRUCTION:
      case RichTextType.NATIONALLY_DETERMINED_REQUIREMENT:
        return null;
      default:
        return new ActionRequest();
    }
  }

  /**
   * Handle the rich text event if this clause group is a nationally determined requirement.
   */
  private async _handleIfIsNationallyDeterminedRequirement(
    richTextType: RichTextType,
    args: any[]
  ): Promise<ActionRequest> {
    switch (richTextType) {
      case RichTextType.STANDARD_FORMAT_GROUP:
        return await this._insertStandardformatRequirementInNDR(args[0], args[1]);
      default:
        return null;
    }
  }

  /**
   * Insert the standard format requirement in the correct place in this clause group if this
   * clause group is a nationally determined requirement.
   */
  private async _insertStandardformatRequirementInNDR(
    standardFormatType: StandardFormatType,
    selectedClause: ClauseFragment
  ): Promise<ActionRequest> {
    const action: ActionRequest = new ActionRequest();
    if (!this._isAllowedTarget(selectedClause) || !(await this._shouldDeleteTarget(selectedClause))) {
      return action;
    }

    const standardFormatGroup: ClauseGroupFragment =
      this._clauseGroupService.getStandardFormatGroupToCreate(standardFormatType);
    const normativeReferenceSection: SectionFragment = (
      this._content.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment
    )?.getNormReferenceSection();
    const internalDocumentReferences: InternalDocumentReferenceFragment[] =
      this._clauseGroupService.getInternalReferencesToCreate(standardFormatGroup, normativeReferenceSection);

    standardFormatGroup.administration = selectedClause.administration;
    standardFormatGroup.insertAfter(selectedClause);

    if (standardFormatGroup.tryInferWeight().failure === WeightInferenceFailure.WEIGHTS_TOO_CLOSE) {
      standardFormatGroup.remove();
      internalDocumentReferences.forEach((frag) => frag.remove());
      Logger.error('weight-error', 'weights too close');
      this._snackbar.open('Failed to create a clause group, please contact support to resolve the problem', 'Dismiss', {
        duration: 5000,
      });
      return action;
    }

    action.fragment = getFirstEditableDescendant(standardFormatGroup);

    this._fragmentService.deleteValidatedFragments(selectedClause);
    this._fragmentService.create([...internalDocumentReferences, standardFormatGroup]).then(() =>
      standardFormatGroup.getClauses().forEach((clause: ClauseFragment) => {
        if (!clause.equals(this._clauseService.getSelected())) {
          this._lockService.unlock(clause);
        }
      })
    );

    const clauseGroup: Fragment = this.content;
    this._clauseGroupService.createDeletePlaceholderClauses(clauseGroup);

    return action;
  }

  /**
   * We can only insert the standard format requirement when the selected clause is a default
   * requirement child of the nationally determined requirement.
   */
  private _isAllowedTarget(clause: ClauseFragment): boolean {
    return clause.parent.equals(this.content) && clause.clauseType === ClauseType.REQUIREMENT;
  }

  /**
   * Returns true if the target clause should be deleted when inserting an SFR into an NDR.
   *
   */
  private async _shouldDeleteTarget(target: Fragment): Promise<boolean> {
    return await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithSubtrees(target);
  }

  /**
   * Sets the isDeprecatedSFRType status based on the clause group service returned list of deprecated types. Note if
   * the content is not an SFR then does not call through to the service.
   */
  private _setDeprecatedSFRStatus(): void {
    this.isDeprecatedSFRType =
      this.content.clauseGroupType === ClauseGroupType.STANDARD_FORMAT_REQUIREMENT &&
      this._clauseGroupService.getDeprecatedStandardFormatTypes().includes(this.content.standardFormatType);
  }
}
