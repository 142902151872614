export enum SidebarStatus {
  CLOSED = '',
  BACKGROUND_COMMENTARY = 'Background commentary',
  DISCUSSIONS = 'Discussions',
  VALIDATION = 'Errors and warnings',
  HISTORY = 'History',
  REFERENCES = 'References',
  CLAUSE_REFERENCES = 'Clause references',
  REORDERING = 'Reordering',
  CLAUSE_LINKS = 'Verification and documentation links',
  SECTION_REFERENCES = 'Section/WSR references',
  UNIT_SELECTION = 'Unit selection',
  ALT_TEXT = 'Alternative text',
}
