import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ReferenceService} from 'app/services/references/reference.service';
import {GlobalReference} from 'app/sidebar/references/global-reference';
import {SearchableGlobalReference} from 'app/sidebar/references/searchable-global-reference';
import {SupersedingReference} from '../../../sidebar/references/superseding-reference';
import {CustomDateAdapter} from './superseded-reference-selector/superseded-reference-selector.component';

/** @title Datepicker emulating a Year and month picker */
@Component({
  selector: 'cars-withdrawal-dialog',
  templateUrl: 'withdrawal-dialog.component.html',
  styleUrls: ['withdrawal-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
  ],
})
export class WithdrawalDialogComponent implements OnInit {
  @ViewChild(MatDatepicker) datePicker;
  public date: UntypedFormControl;
  public searchableGlobalReference: SearchableGlobalReference;
  public supersedingReferences: SupersedingReference[] = [];
  public globalReference: GlobalReference;
  public wasWithdrawn: boolean;
  public formIsValid: boolean;
  public supersedingReferencesFormIsValid: boolean;

  public withdrawalErrorMessage: string = 'Withdrawal date required';

  constructor(
    public dialogRef: MatDialogRef<WithdrawalDialogComponent>,
    public referenceService: ReferenceService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      row: SearchableGlobalReference;
    }
  ) {}

  public ngOnInit(): void {
    this.supersedingReferencesFormIsValid = false;
    this.date = new UntypedFormControl(
      {
        value: this.data.row.withdrawn ? new Date(this.data.row.withdrawnOn) : null,
      },
      Validators.required
    );
    this.searchableGlobalReference = this.data.row;
    this.wasWithdrawn = this.data.row.withdrawn;
    this.loadGlobalReference();

    this.formIsValid = (this.date.valid && this.date.value.value !== null) || this.date.disabled;
  }

  private loadGlobalReference(): void {
    this.referenceService
      .fetchGlobalReference(this.searchableGlobalReference.globalReferenceId.value)
      .then((globalReference: GlobalReference) => {
        this.globalReference = globalReference;
        if (this.globalReference.withdrawnOn) {
          this.date.setValue(new Date(this.globalReference.withdrawnOn));
        }
      });
  }

  public close(save: boolean): void {
    const withdrawnOn = this.date.value instanceof Date ? this.date.value.getTime() : null;
    this.dialogRef.close({
      save: save,
      reference: this.searchableGlobalReference,
      supersedingReferences: this.supersedingReferences,
      wasWithdrawn: this.wasWithdrawn,
      withdrawnOn: withdrawnOn,
    });
  }

  public setSupersedingReferences(supersedingReferences: SupersedingReference[]): void {
    this.supersedingReferences = supersedingReferences;
  }

  public setSupersedingReferencesFormIsValid(supersedingReferenceSelectorIsValid: boolean): void {
    this.supersedingReferencesFormIsValid = supersedingReferenceSelectorIsValid;
  }
}
