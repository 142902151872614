import {UUID} from 'app/utils/uuid';
import {FragmentType} from '../../types';
import {AbstractInputFragment} from './abstract-input-fragment';

export class UnitInputFragment extends AbstractInputFragment {
  private static readonly START_DELIMITER: string = '<';
  private static readonly END_DELIMITER: string = '>';

  // This link is not currently used but is to enable future work of TAGG managing the list of units
  public unitId: UUID;
  public unitEquationSource: string;

  /**
   * Static factory to create empty UnitInputFragments.
   *
   * @returns {UnitInputFragment}   The new unit input fragment
   */
  public static empty(placeholder: string = 'units'): UnitInputFragment {
    return new UnitInputFragment(null, placeholder, null, null);
  }

  constructor(id: UUID, placeholderValue: string, unitId: UUID, unitEquationSource: string) {
    super(
      id,
      FragmentType.UNIT_INPUT,
      UnitInputFragment.START_DELIMITER,
      UnitInputFragment.END_DELIMITER,
      placeholderValue,
      []
    );

    this.unitId = unitId;
    this.unitEquationSource = unitEquationSource;
  }

  public getPreview(): string {
    return this.displayPlaceholder;
  }
}
