import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ResolvedIconModule} from 'app/dashboard/resolved-icon/resolved-icon.module';
import {VersionAggregateComponent} from './version-aggregate.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule, MatIconModule, MatCardModule, ResolvedIconModule],
  declarations: [VersionAggregateComponent],
  exports: [VersionAggregateComponent],
})
export class VersionAggregateModule {}
