import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ShowOnFocusDirective} from './show-on-focus.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ShowOnFocusDirective],
  exports: [ShowOnFocusDirective],
})
export class ShowOnFocusModule {}
