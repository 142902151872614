<table [class.inline]="content.inline" border-spacing="0" id="{{ id }}">
  <caption [class.inline]="content.inline" *ngIf="!content.inline">
    <div>
      <div class="caption-container">
        <label
          *ngIf="clause.getSection() && !clause.getSection().deleted && showIndex()"
          contenteditable="false"
          (click)="selectCaption(true)"
          >{{ content | fragmentIndex }}</label
        >
        <label *ngIf="showIndex() && clause.getSection()?.deleted" contenteditable="false" (click)="selectCaption(true)"
          >Equation
        </label>
        <cars-text-fragment
          *ngIf="!content?.diffedCaption?.length; else diffedCaption"
          [content]="content?.caption"
          [caption]="true"
        ></cars-text-fragment>
        <ng-template #diffedCaption>
          <cars-text-fragment
            *ngFor="let fragment of content?.diffedCaption"
            [content]="fragment"
            [caption]="true"
          ></cars-text-fragment>
        </ng-template>
      </div>

      <div
        *ngIf="showMarginIcons && padType === PadType.MAIN_EDITABLE"
        class="margin-icons-container"
        contenteditable="false"
      >
        <cars-discussions-icon [fragment]="content"></cars-discussions-icon>
      </div>
      <cars-captioned-hovertip
        class="hovertip"
        [fragmentType]="'equation'"
        [readOnly]="readOnly"
        [fragment]="content"
        [carsShowOnFocus]="content"
      >
      </cars-captioned-hovertip>
    </div>
  </caption>

  <tr>
    <td #source class="source">
      <cars-text-fragment [content]="content.source" [inline]="true" spellcheck="false"></cars-text-fragment>
    </td>
  </tr>

  <tr>
    <td
      #render
      class="render"
      (mousedown)="onSourceMousedown($event)"
      (click)="onSourceMousedown($event)"
      contenteditable="false"
      spellcheck="false"
    ></td>
  </tr>
</table>

<h4 *ngIf="invalidCharacter" class="red">
  This equation contains a non-keyboard character and needs to be remade, else it will not export properly.
</h4>
<cars-table-fragment *ngIf="!!getTable()" [content]="getTable()" [readOnly]="readOnly"></cars-table-fragment>
