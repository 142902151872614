import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Fragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {FragmentService} from 'app/services/fragment.service';
import {DocInfoValueDisplayNameType, DocumentInformationItem} from 'app/suite-config/configuration.service';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'cars-non-editable-field',
  templateUrl: './non-editable-field.component.html',
  styleUrls: ['./non-editable-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NonEditableFieldComponent implements OnInit, OnDestroy {
  @Input() public set documentInformationItem(documentInformationItem: DocumentInformationItem) {
    this.value = documentInformationItem.documentInformationFragment.value;
    this.titleText = documentInformationItem.documentInformationField.titleText;
    this.hintText = documentInformationItem.documentInformationField.hintText;
    this.valueDisplayName = documentInformationItem.documentInformationField.valueDisplayName;
    this._documentInformationFragment = documentInformationItem.documentInformationFragment;
  }

  @Input() public isHistoricalVersion: boolean;

  public value: string = '';
  public titleText: string = '';
  public hintText: string = '';

  public valueDisplayName: DocInfoValueDisplayNameType = null;

  private _documentInformationFragment: Fragment;

  protected _subscriptions: Subscription[] = [];

  constructor(
    protected fragmentService: FragmentService,
    protected versioningService: VersioningService,
    protected cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this.fragmentService.onUpdate(
        this._patchValue.bind(this),
        (f: Fragment) => this._onUpdatePredicate(f) && !this._documentInformationFragment.validTo
      ),
      this.versioningService
        .getFragmentVersionUpdateObsStream(this._documentInformationFragment.validTo)
        .pipe(filter(this._onUpdatePredicate.bind(this)))
        .subscribe(this._patchValue.bind(this))
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  private _onUpdatePredicate(fragment: Fragment): boolean {
    return !!fragment && fragment.id.equals(this._documentInformationFragment.id);
  }

  private _patchValue(fragment: Fragment): void {
    this.value = fragment.value;
    this.cdr.markForCheck();
  }
}
