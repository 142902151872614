import {Pipe, PipeTransform} from '@angular/core';
import {UUID} from 'app/utils/uuid';
import {Observable} from 'rxjs';
import {PermissionsService} from '../permissions.service';
import {CarsAction} from '../types/permissions';

@Pipe({
  name: 'carsEnabled',
})
export class CarsEnabledPipe implements PipeTransform {
  constructor(private _permissionsService: PermissionsService) {}

  /**
   * Returns boolean of whether the user can perform the action requested.
   *
   * @param action {CarsAction}            CARS action the user is looking to take
   * @returns      {Observable<boolean>}   An observable stream emitting whether the user can perform the action requested
   */
  public transform(action: CarsAction, documentId: UUID): Observable<boolean> {
    return this._permissionsService.can(action, documentId);
  }
}
