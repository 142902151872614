import {Component} from '@angular/core';
import {Dashboard, DashboardService} from 'app/services/dashboard.service';
import {DashboardComponent} from '../dashboard.component';

@Component({
  selector: 'cars-my-discussions',
  templateUrl: '../dashboard.component.html',
  styleUrls: ['../dashboard.component.scss'],
})
export class MyDiscussionsComponent extends DashboardComponent {
  public dashboardType: Dashboard = Dashboard.MY_DISCUSSIONS;
  public title: string = 'My Discussions';
  public pageInfo: string =
    'This dashboard provides an overview of the discussions and comments ' +
    'that you have raised.  It includes if the item has been resolved, what the latest comment was, ' +
    'and the count of all comments.  It can be used to help answer questions like <i>“Have all my ' +
    'discussions and comments been resolved?”</i> and <i>“What were the discussions I raised on the ' +
    'previous version?”</i>. To learn more about this dashboard, please watch the YouTube tutorial' +
    ' accessed via the FAQ page on the Help Pages.';

  constructor(protected _dashboardService: DashboardService) {
    super(_dashboardService);
  }
}
