<div
  class="section"
  [class.active]="selected"
  [class.clause-dragging]="draggingValidClauses"
  [class.grouped-section-indent]="section?.isInSectionGroup()"
  [attr.aria-label]="'Open section ' + section.title"
  (click)="selectSection()"
  tabindex="0"
  (keyup.enter)="selectSection()"
  (keyup.Space)="selectSection()"
  (mouseup)="moveClausesToSection()"
>
  <div class="section-details">
    <div
      class="handle-container"
      *ngIf="!section.deleted && (CarsAction.AUTHOR_DOCUMENT | carsEnabled: section.documentId | async)"
    >
      <mat-icon
        *ngIf="!section.isInSectionGroup()"
        class="handle"
        [matTooltip]="
          !reorderingClauses
            ? 'Click and drag to reorder section ' + (section.id | fragmentIndex) + ' ' + section.title
            : ''
        "
        matTooltipPosition="after"
        [matTooltipShowDelay]="tooltipDelay"
        (mousedown)="startDragging($event)"
        tabindex="0"
      >
        drag_handle
      </mat-icon>
    </div>

    <button
      mat-icon-button
      (click)="toggleHeadings($event)"
      *ngIf="CarsAction.IS_CHANGELOG_AUX_VIEW_MODE | carsDisabled: section.documentId | async"
      matTooltip="{{ expanded ? 'Hide' : 'Show' }} Headings for section {{ section.id | fragmentIndex }} {{
        section.title
      }}"
      matTooltipPosition="below"
      [matTooltipShowDelay]="tooltipDelay"
      disabled="{{ !headings || headings.length === 0 }}"
    >
      <mat-icon class="expand-icon">{{ expanded ? 'expand_more' : 'chevron_right' }}</mat-icon>
    </button>

    <mat-form-field
      matTooltip="{{ section.id | fragmentIndex }} {{ section.title }}"
      [matTooltipDisabled]="!isTitleTrucated()"
    >
      <span *ngIf="!section.deleted" id="section-index" matPrefix>
        {{ section.id | fragmentIndex }}
      </span>
      <input
        matInput
        #title
        class="title"
        [(ngModel)]="section.title"
        (blur)="saveSection()"
        (keydown.enter)="$event.stopPropagation(); selectSection(); saveSection()"
        [disabled]="!editing"
        (click)="$event.stopPropagation()"
        maxlength="255"
        required
        carsRequiredNonWhitespace
      />
    </mat-form-field>

    <cars-section-list-menu
      [sectionListItem]="section"
      [currentView]="currentView"
      (renamingSection)="renameSection($event)"
    ></cars-section-list-menu>
  </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="loading" aria-label="Loading section"></mat-progress-bar>

<div *ngIf="expanded" class="headings-list-container">
  <div
    *ngFor="let heading of headings"
    (mousedown)="selectHeading($event, heading)"
    (keyup.enter)="selectHeading($event, heading)"
    class="heading-list-item list-{{ heading.class }}"
    tabindex="0"
    [attr.aria-label]="'Navigate to ' + heading.text"
  >
    <div *ngIf="section?.isInSectionGroup()" class="grouped-section-indent"></div>
    <ng-container *ngIf="heading?.text?.length > 0; else emptyHeading">{{ heading?.text }}</ng-container>
    <ng-template #emptyHeading><em>[Empty Heading]</em></ng-template>
  </div>
</div>
