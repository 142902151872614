import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  CarsNotification,
  ExportNotification,
  NotificationStatus,
} from 'app/header/notification-area/notification/cars-notification';
import {MchwExportRequest, VersionTag} from 'app/interfaces';
import {PdfDialogComponent} from 'app/navigation/pdf-dialog/pdf-dialog.component';
import {PdfPreviewDialogComponent} from 'app/navigation/pdf-preview-dialog/pdf-preview-dialog.component';
import {BaseService} from 'app/services/base.service';
import {DocumentService} from 'app/services/document.service';
import {NotificationService} from 'app/services/notification.service';
import {UUID} from 'app/utils/uuid';
import {TableUpdaterComponent} from 'app/widgets/table-updater/table-updater.component';
import {environment} from 'environments/environment';
import * as FileSaver from 'file-saver';
import {DocumentFragment} from '../fragment/types';

export enum ExportType {
  WEB_PDF = 'WEB_PDF',
  WLT_PDF = 'WLT_PDF',
  HTML_NOT_FOR_PUBLICATION = 'HTML_NOT_FOR_PUBLICATION',
}

export interface ExportRequestParams {
  documentId: UUID;
  exportType: ExportType;
  forPreview: boolean;
  versionTag: VersionTag;
  exportResponse: ExportResponse;
}

interface ExportResponse {
  jobId: UUID;
  fileName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExportService extends BaseService {
  private static readonly RETRIEVE_ERROR_MESSAGE: string =
    'Failed to retrieve the file. Please retry or regenerate the file. Download links are temporary.';

  private openRetrieveDialog: MatDialogRef<PdfDialogComponent> = null;

  // Map from job ID to export request params
  private exportRequests: Record<string, ExportRequestParams> = {};

  constructor(
    private notificationService: NotificationService,
    private documentService: DocumentService,
    private http: HttpClient,
    private dialog: MatDialog,
    snackBar: MatSnackBar
  ) {
    super(snackBar);

    this.notificationService.onNotification().subscribe((notification: CarsNotification) => {
      if (notification instanceof ExportNotification) {
        if (notification.exception === 'uk.co.highwaysengland.cars.export.TableWidthExportException') {
          const params: ExportRequestParams = this.exportRequests[notification.notificationId.value];
          this.handleTableWidthException(params);
        }

        const exportNotification = notification as ExportNotification;
        this.exportRequests[notification.notificationId.value] = {
          documentId: exportNotification.data['documentId'],
          exportType: exportNotification.data['exportType'],
          forPreview: exportNotification.data['forPreview'],
          versionTag: null,
          exportResponse: {
            jobId: exportNotification.notificationId,
            fileName: exportNotification.data['fileName'],
          } as ExportResponse,
        } as ExportRequestParams;
      }
    });
  }

  private handleTableWidthException(params: ExportRequestParams): void {
    this.documentService
      .load(params.documentId, {
        validAt: params.versionTag?.createdAt,
        projection: 'FULL_TREE',
      })
      .then((fullDocument: DocumentFragment) => {
        return this.dialog
          .open(TableUpdaterComponent, {
            ariaLabel: 'Table Auto Updater Dialog',
            width: '680px',
            data: {document: fullDocument},
            disableClose: true,
          })
          .afterClosed()
          .subscribe(() => {
            // Try and export again.
            return this.generate(fullDocument, params.exportType, params.versionTag, params.forPreview);
          });
      });
  }

  /**
   * Generate an export of a document and trigger a notification.
   *
   * @param document    {DocumentFragment}  The document to export.
   * @param versionTag  {VersionTag}        The version of the document to export
   * @param forPreview  {boolean}           True if this is for in-app PDF preview.
   *
   * @returns a promise resolving to the job ID.
   */
  public generate(
    document: DocumentFragment,
    exportType: ExportType,
    versionTag?: VersionTag,
    forPreview: boolean = false,
    withNaas: boolean = false
  ): Promise<UUID> {
    let params = new HttpParams();
    params = params.set('exportType', exportType.toString());
    params = params.set('generateWithNaas', withNaas.toString());
    params = params.set('forPreview', forPreview.toString());
    params = versionTag ? params.set('versionId', versionTag.versionId.value) : params;

    return this.http
      .get(`${environment.apiHost}/documents/${document.id.value}/export/generate`, {
        responseType: 'json',
        params,
      })
      .toPromise()
      .then((response: any) => {
        const exportResponse: ExportResponse = {
          jobId: UUID.orNull(response.jobId),
          fileName: response.fileName,
        };
        const notification: ExportNotification = this.buildPendingNotification(exportResponse, exportType, forPreview);
        this.notificationService.triggerNotification(notification);
        this.exportRequests[exportResponse.jobId.value] = {
          documentId: document.id,
          exportType,
          versionTag,
          forPreview,
          exportResponse,
        };
        return exportResponse.jobId;
      });
  }

  /**
   * Generate the export of the combined outputs of an MCHW document and trigger a notification.
   *
   * @param document    {DocumentFragment}  The document to export.
   * @param versionTag  {VersionTag}        The version of the document to export
   * @param forPreview  {boolean}           True if this is for in-app PDF preview.
   *
   * @returns a promise resolving to the job ID.
   */
  public generateMchwZip(
    document: DocumentFragment,
    mchwExportRequest: MchwExportRequest,
    versionTag?: VersionTag
  ): Promise<UUID> {
    return this.http
      .post(`${environment.apiHost}/documents/export/generate-mchw-zip`, mchwExportRequest, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        responseType: 'json',
      })
      .toPromise()
      .then((response: any) => {
        const exportResponse: ExportResponse = {
          jobId: UUID.orNull(response.jobId),
          fileName: response.fileName,
        };
        const notification: ExportNotification = this.buildPendingNotification(
          exportResponse,
          mchwExportRequest.exportType,
          false
        );
        this.notificationService.triggerNotification(notification);
        this.exportRequests[exportResponse.jobId.value] = {
          documentId: document.id,
          exportType: mchwExportRequest.exportType,
          versionTag,
          forPreview: false,
          exportResponse,
        };
        return exportResponse.jobId;
      });
  }

  private buildPendingNotification(
    exportResponse: ExportResponse,
    exportType: ExportType,
    forPreview: boolean
  ): ExportNotification {
    const content: string = forPreview
      ? `${exportType} export in progress - Please wait while your ${exportType} preview is prepared`
      : `${exportType} export in progress - Please wait while your download is prepared`;
    return new ExportNotification(
      exportResponse.jobId,
      exportResponse.fileName,
      content,
      NotificationStatus.IN_PROGRESS
    );
  }

  /**
   * Retrieve a generated PDF and either download it or display it for preview.
   *
   * @param jobId {UUID}   The job ID of the generation job which produced the PDF.
   * @param title {string} The title of the pdf to use in notifications and as the filename.
   */
  public retrieve(jobId: UUID, exportType?: ExportType, title?: string): void {
    const params: ExportRequestParams = this.exportRequests[jobId.value];
    let promise;
    if (!params) {
      promise = this.retrieveAndDownload(jobId, exportType, title);
    } else {
      promise = params.forPreview
        ? this.retrieveAndPreview(jobId, params.exportType)
        : this.retrieveAndDownload(jobId, params.exportType, params.exportResponse.fileName);
    }
    this.openRetrieveDialog = this.dialog.open(PdfDialogComponent, {
      ariaLabel: 'Export to PDF dialog',
      width: '300px',
      data: {
        exportType: !params ? exportType : params.exportType,
      },
    });

    promise.then(() => this.openRetrieveDialog.close(null)).catch(() => this.openRetrieveDialog.close(null));
  }

  private retrieveAndDownload(jobId: UUID, exportType: ExportType, title: string): Promise<void> {
    let params = new HttpParams();
    params = params.set('jobId', jobId.value);
    params = params.set('exportType', exportType.toString());

    return this.http
      .get(`${environment.apiHost}/documents/export/retrieve`, {
        responseType: 'blob',
        headers: new HttpHeaders().set('Accept', 'application/pdf'),
        params,
      })
      .toPromise()
      .then((response: Blob) => FileSaver.saveAs(response, `${title}`))
      .catch((err) => {
        this._handleError(err, ExportService.RETRIEVE_ERROR_MESSAGE, 'export-error');
      });
  }

  private retrieveAndPreview(jobId: UUID, exportType: ExportType): Promise<void> {
    let params = new HttpParams();
    params = params.set('jobId', jobId.value);
    params = params.set('exportType', exportType.toString());

    return this.http
      .get(`${environment.apiHost}/documents/export/retrieve`, {
        responseType: 'arraybuffer',
        headers: new HttpHeaders().set('Accept', 'application/pdf'),
        params,
      })
      .toPromise()
      .then((response: ArrayBuffer) => {
        const byteArray: Uint8Array = new Uint8Array(response);
        this.dialog.open(PdfPreviewDialogComponent, {
          height: '800px',
          width: '1000px',
          data: byteArray,
          panelClass: 'custom-modalbox',
        });
      })
      .catch((err) => {
        this._handleError(err, ExportService.RETRIEVE_ERROR_MESSAGE, 'export-error');
      });
  }
}
