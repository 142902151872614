import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {VersionTagTypeToDisplayNameModule} from 'app/utils/pipes/version-tag-type-to-display-name.module';
import {VersionCreatorComponent} from './version-creator.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    MatButtonModule,
    CarsErrorHandlingModule,
    MatProgressBarModule,
    VersionTagTypeToDisplayNameModule,
  ],
  declarations: [VersionCreatorComponent],
  exports: [VersionCreatorComponent],
})
export class VersionCreatorModule {}
