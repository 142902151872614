<div class="alt-image-card">
  <div class="image-container">
    <cars-figure-fragment [content]="imageFragmentCopy" [readOnly]="true"></cars-figure-fragment>
  </div>
  <div class="alt-text-container">
    <mat-form-field
      matTooltip="Enter alternative text here."
      aria-label="Enter alternative text here."
      [class.illustrative-figure]="imageFragmentCopy.isIllustrativeFigure"
      [attr.contenteditable]="imageFragmentCopy.isIllustrativeFigure ? 'false' : 'true'"
    >
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        [ngModel]="textAreaText"
        [disabled]="CarsAction.AUTHOR_DOCUMENT | carsDisabled: documentId | async"
        (ngModelChange)="textAreaModelChanged.next($event)"
        placeholder="Please provide alternative text for this figure"
      ></textarea>
    </mat-form-field>
  </div>
</div>
