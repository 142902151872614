<div class="naa-checkbox" *ngIf="!isDocumentNaa()">
  <mat-icon>account_balance</mat-icon>
  <mat-checkbox
    name="has-national-application-annexes"
    [(ngModel)]="requiresNationalApplicationAnnexes"
    (change)="onNaaCheckboxChange()"
    [disabled]="!canToggleNaas || disabled"
    >Does this document require National Application Annexes?
  </mat-checkbox>
</div>

<ng-container *ngIf="requiresNationalApplicationAnnexes">
  <ul name="linked_naas_list">
    <li *ngFor="let nationalApplicationAnnex of fetchCoreDocumentNaaData() | keyvalue">
      <cars-document-link
        [document]="document"
        [title]="setNaaTitle(nationalApplicationAnnex.key | administrationToDisplayName)"
        [linkHint]="(nationalApplicationAnnex.key | administrationToDisplayName) + ' NAA '"
        [documentId]="nationalApplicationAnnex.value"
      ></cars-document-link>
    </li>
  </ul>

  <ng-container *ngIf="shouldShowGenerateNaasButton()">
    <div class="no-associated-naas">National Application Annexes do not currently exist for this document</div>
    <button
      name="create_naas_button"
      type="button"
      color="primary"
      mat-raised-button
      (click)="onCreateNaas()"
      aria-label="create-associated-naas"
      [disabled]="creatingNaas"
    >
      Create NAAs
    </button>
  </ng-container>
</ng-container>
