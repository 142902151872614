import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {DocInfoValueToDisplayNameModule} from 'app/utils/pipes/doc-info-value-to-display-name.module';
import {NonEditableFieldComponent} from './non-editable-field.component';

@NgModule({
  imports: [CommonModule, MatInputModule, DocInfoValueToDisplayNameModule],
  declarations: [NonEditableFieldComponent],
  exports: [NonEditableFieldComponent],
})
export class NonEditableFieldModule {}
