import {
  ComponentRef,
  Directive,
  DoCheck,
  Input,
  IterableChangeRecord,
  IterableChanges,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {InlineReferenceFragmentComponent} from 'app/fragment/inline-reference/inline-reference-fragment.component';
import {CurrentView} from 'app/view/current-view';
import {AnchorFragmentComponent} from '../anchor/anchor-fragment.component';
import {ClauseGroupFragmentComponent} from '../clause-group/clause-group-fragment.component';
import {InputFragmentComponent} from '../clause-group/no-op-clause/input-fragment/input-fragment.component';
import {NoOpClauseFragmentComponent} from '../clause-group/no-op-clause/no-op-clause-fragment.component';
import {ReadonlyFragmentComponent} from '../clause-group/no-op-clause/readonly/readonly-fragment.component';
import {ClauseComponent} from '../clause/clause.component';
import {FragmentComponent} from '../core/fragment.component';
import {EquationFragmentComponent} from '../equation/equation-fragment.component';
import {FigureFragmentComponent} from '../figure/figure-fragment.component';
import {InternalInlineReferenceFragmentComponent} from '../inline-section-reference/internal-inline-reference-fragment.component';
import {ListFragmentComponent} from '../list/list-fragment.component';
import {ClauseReferenceInputComponent} from '../reference-input/clause-reference-input/clause-reference-input.component';
import {SectionReferenceInputComponent} from '../reference-input/section-reference-input/section-reference-input.component';
import {TableFragmentComponent} from '../table/table-fragment.component';
import {MemoFragmentComponent} from '../text/memo-fragment.component';
import {SubscriptFragmentComponent} from '../text/subscript-fragment.component';
import {SuperscriptFragmentComponent} from '../text/superscript-fragment.component';
import {TextFragmentComponent} from '../text/text-fragment.component';
import {ClauseFragment, ClauseType, Fragment, FragmentType} from '../types';
import {ClauseGroupFragment} from '../types/clause-group-fragment';
import {ClauseGroupType} from '../types/clause-group-type';
import {ReferenceInputDisplayType, ReferenceInputFragment} from '../types/input/reference-input-fragment';
import {UnitInputFragmentComponent} from '../unit-input/unit-input-fragment.component';

/**
 * A dictionary responsible for converting FragmentType into a class deriving FragmentComponent.
 *
 * TODO: Consider extending this to allow lookup of the data object as well, so that logic
 *       isn't duplicated in both FragmentMapper and various methods in the contenteditable.
 */
export class FragmentLookup {
  private static _lookup: {[key: string]: any} = {
    [FragmentType.TEXT]: TextFragmentComponent,
    [FragmentType.SUBSCRIPT]: SubscriptFragmentComponent,
    [FragmentType.SUPERSCRIPT]: SuperscriptFragmentComponent,
    [FragmentType.MEMO]: MemoFragmentComponent,
    [FragmentType.LIST]: ListFragmentComponent,
    [FragmentType.LIST_ITEM]: void 0, // List items should only exist inside lists
    [FragmentType.EQUATION]: EquationFragmentComponent,
    [FragmentType.FIGURE]: FigureFragmentComponent,
    [FragmentType.TABLE_CELL]: void 0, // Table cells should only exist inside table rows
    [FragmentType.TABLE_ROW]: void 0, // Table rows should only exist inside tables
    [FragmentType.TABLE]: TableFragmentComponent,
    [FragmentType.CLAUSE]: void 0, // Clauses are handled in the from() method below
    [FragmentType.SECTION]: void 0, // Sections should only be created by the section-pad
    [FragmentType.SECTION_GROUP]: void 0, // Sections should only be created by the section-pad
    [FragmentType.DOCUMENT]: void 0, // Documents should only be created by the container
    [FragmentType.ROOT]: void 0, // ROOT is a unique sentinel tree node
    [FragmentType.INLINE_REFERENCE]: InlineReferenceFragmentComponent,
    [FragmentType.ANCHOR]: AnchorFragmentComponent,
    [FragmentType.DOCUMENT_INFORMATION]: void 0,
    [FragmentType.CLAUSE_GROUP]: ClauseGroupFragmentComponent,
    [FragmentType.READONLY]: ReadonlyFragmentComponent,
    [FragmentType.INPUT]: InputFragmentComponent,
    [FragmentType.UNIT_INPUT]: UnitInputFragmentComponent,
    [FragmentType.REFERENCE_INPUT]: void 0, // References are handled in the from() method below
    [FragmentType.INTERNAL_INLINE_REFERENCE]: InternalInlineReferenceFragmentComponent,
  };

  /**
   * Look up the fragment constructor from either the FragmentType string identifier, or its
   * number.  Throws if the FragmentType is not recognised, or if instances of that fragment
   * should not be created 'bare'.
   *
   * @param name {FragmentType|string}   The FragmentType or its string name
   * @returns    {FragmentComponent}     The corresponding component extending FragmentComponent
   * @throws                             If an uncreatable fragment type
   */
  public static from(fragment: Fragment): typeof FragmentComponent {
    if (fragment.is(FragmentType.CLAUSE)) {
      const clause: ClauseFragment = fragment as ClauseFragment;
      const clauseGroup: ClauseGroupFragment = fragment.findAncestorWithType(
        FragmentType.CLAUSE_GROUP
      ) as ClauseGroupFragment;
      if (
        clause.clauseType === ClauseType.SPECIFIER_INSTRUCTION ||
        (clauseGroup && clauseGroup.clauseGroupType === ClauseGroupType.STANDARD_FORMAT_REQUIREMENT) ||
        clause.isUnmodifiableClause
      ) {
        return NoOpClauseFragmentComponent as any;
      } else {
        return ClauseComponent as any;
      }
    } else if (fragment.is(FragmentType.REFERENCE_INPUT)) {
      const inputDisplayType: ReferenceInputDisplayType = (fragment as ReferenceInputFragment)
        .referenceInputDisplayType;
      switch (inputDisplayType) {
        case ReferenceInputDisplayType.CLAUSE_TEMPLATE:
          return ClauseReferenceInputComponent as any;
        case ReferenceInputDisplayType.SECTION_TEMPLATE:
          return SectionReferenceInputComponent as any;
        default:
          throw new Error(
            `Unable to find a component which implements Reference Inputes with display type ${inputDisplayType}.`
          );
      }
    }

    const fragmentType: FragmentType = fragment.type;

    const result: typeof FragmentComponent = FragmentLookup._lookup[fragmentType];

    if (!result) {
      const strName = FragmentType[fragmentType];
      throw new Error(`Unable to find a component which implements FragmentComponent with type ${strName}.`);
    }

    return result;
  }

  // Prevent creating instances of FragmentLookup
  private constructor() {}
}

@Directive({
  selector: `[carsFragmentHost]`,
})
export class FragmentHostDirective implements OnChanges, DoCheck {
  @Input('carsFragmentHost') private fragments: Fragment[];
  @Input() public readOnly: boolean;
  @Input() public currentView: CurrentView = null;
  @Input() public padType: PadType = null;

  private _differ: IterableDiffer<Fragment> = null;

  constructor(private _container: ViewContainerRef, private _differs: IterableDiffers) {}

  /**
   * Respond to Angular binding changes by updating the list of managed fragments.
   *
   * @param changes {SimpleChanges}   The Angular changes object
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('fragments')) {
      const value: Fragment[] = changes['fragments'].currentValue;
      // Don't react to changes until the value has been initialised
      if (!this._differ && value) {
        this._differ = this._differs.find(value).create((i: number, frag: Fragment) => frag.id.value);
      }
    }
    if (changes.hasOwnProperty('readOnly')) {
      this.fragments.forEach((f: Fragment) => {
        if (f.component) {
          f.component.readOnly = this.readOnly;
          f.markForCheck();
        }
      });
    }
    if (changes.hasOwnProperty('currentView')) {
      this.fragments.forEach((f: Fragment) => {
        if (f.component && f.component instanceof ClauseComponent) {
          (f.component as any).currentView = this.currentView;
          f.markForCheck();
        }
      });
    }
    if (changes.hasOwnProperty('padType')) {
      this.fragments.forEach((f: Fragment) => {
        if (
          f.component &&
          (f.component instanceof ClauseComponent ||
            f.component instanceof NoOpClauseFragmentComponent ||
            f.component instanceof ClauseGroupFragmentComponent)
        ) {
          (f.component as any).padType = this.padType;
          f.markForCheck();
        }
      });
    }
  }

  /**
   * Hook into Angular's change detection cycle to update fragments if necessary.
   */
  public ngDoCheck(): void {
    if (this._differ) {
      const changes = this._differ.diff(this.fragments);
      if (changes) {
        this._applyChanges(changes);
      }
    }
  }

  /**
   * Update the fragments managed by this directive.
   * See {@link https://github.com/angular/angular/blob/5.0.x/packages/common/src/directives/ng_for_of.ts#L156}.
   *
   * @param changes {IterableChanges<Fragment>}   The iterable changes
   *
   */
  private _applyChanges(changes: IterableChanges<Fragment>): void {
    changes.forEachOperation(
      (item: IterableChangeRecord<Fragment>, adjustedPreviousIndex: number, currentIndex: number) => {
        if (item.previousIndex === null) {
          const template: typeof FragmentComponent = FragmentLookup.from(item.item);
          const component: ComponentRef<any> = this._container.createComponent<FragmentComponent>(template, {
            index: currentIndex,
          });
          component.instance.content = item.item;
          component.instance.readOnly = this.readOnly;
          if (component.instance instanceof ClauseComponent) {
            component.instance.currentView = this.currentView;
          }
          if (
            component.instance instanceof ClauseComponent ||
            component.instance instanceof NoOpClauseFragmentComponent ||
            component.instance instanceof ClauseGroupFragmentComponent
          ) {
            component.instance.padType = this.padType;
          }
        } else if (currentIndex === null) {
          this._container.remove(adjustedPreviousIndex);
        } else {
          const view: ViewRef = this._container.get(adjustedPreviousIndex);
          this._container.move(view, currentIndex);
        }
      }
    );
  }
}
