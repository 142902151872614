import {Fragment} from './types';

/**
 * A class representing a caret position within an element managed by the cars-ce
 * contenteditable directive.
 *
 * @field fragment {Fragment}   The active leaf fragment
 * @field offset   {number}     The characteroffset within fragment
 */
export class Caret {
  /**
   * Creates a Caret pointing to before the first character of the given fragment.
   *
   * @returns {Caret}   The start caret
   */
  public static startOf(fragment: Fragment): Caret {
    fragment = fragment || null;

    return new Caret(fragment, 0);
  }

  /**
   * Creates a Caret pointing to after the last character of the given fragment.
   *
   * @returns {Caret}   The end caret
   */
  public static endOf(fragment: Fragment): Caret {
    fragment = fragment || null;
    const length: number = fragment ? fragment.length() : 0;

    return new Caret(fragment, length);
  }

  constructor(public fragment: Fragment = null, public offset: number = 0) {
    this.normalise();
  }

  /**
   * Returns true if two caret positions are equal.
   *
   * @param other {Caret}     The other caret
   * @returns     {boolean}   True if equal
   */
  public equals(other: Caret): boolean {
    return other && this.fragment.equals(other.fragment) && this.offset === other.offset;
  }

  public isAtEnd(): boolean {
    return this.fragment && this.fragment.length() === this.offset;
  }

  public isAtStart(): boolean {
    return this.offset === 0;
  }

  /**
   * Ensure that offset falls within [0, fragment.length()] by moving fragment left or right as
   * appropriate, and projecting to a leaf fragment.
   */
  public normalise(): void {
    if (this.fragment) {
      // If offset is negative, search for previous leaves until we find the one containing the caret
      while (this.offset < 0) {
        const prev: Fragment = this.fragment.previousLeaf();
        if (prev) {
          this.offset += prev.length();
          this.fragment = prev;
        } else {
          this.offset = Math.max(this.offset, 0);
        }
      }

      // If offset is positive, search for next leaves until we find the one containing the caret
      while (this.offset > this.fragment.length() && this.offset !== 1 && this.fragment.length() !== 0) {
        const next: Fragment = this.fragment.nextLeaf();
        if (next) {
          this.offset -= this.fragment.length();
          this.fragment = next;
        } else {
          this.offset = Math.min(this.offset, this.fragment.length());
        }
      }

      // Project down into the leaf fragment at the offset
      while (this.fragment.hasChildren()) {
        let childIndex: number = 0;
        while (
          childIndex < this.fragment.children.length &&
          this.offset > this.fragment.children[childIndex].length()
        ) {
          this.offset -= this.fragment.children[childIndex++].length();
        }

        // Check we've not run off the end of the array
        if (childIndex >= this.fragment.children.length) {
          childIndex = this.fragment.children.length - 1;
          this.offset = this.fragment.children[childIndex].length();
        }

        this.fragment = this.fragment.children[childIndex];
      }
    }
  }
}
