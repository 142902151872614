import {Injectable} from '@angular/core';
import {AuthenticationProvider, GlobalRole} from 'app/services/user/authentication-provider';
import {Observable, of} from 'rxjs';
import {ConditionASTNode} from '../types/condition-ast-node';
import {EqualityRelation, PermissionInput} from '../types/permissions';
import {PermissionsHandler} from './permissions-handler';

@Injectable()
export class GlobalRolePermissionsHandler implements PermissionsHandler {
  constructor(private authenticationProvider: AuthenticationProvider) {}

  public getInputType(): PermissionInput {
    return PermissionInput.GLOBAL_ROLE;
  }

  public handle(condition: ConditionASTNode): Observable<boolean> {
    const role = this.getRole(condition);
    switch (condition.relation) {
      case EqualityRelation.EQUALS:
        return of(this.authenticationProvider.userInRoles(role));
      case EqualityRelation.NOT_EQUALS:
        return of(!this.authenticationProvider.userInRoles(role));
      default:
        throw new Error(`Unsupported equality relation ${condition.relation}`);
    }
  }

  private getRole(condition: ConditionASTNode): GlobalRole {
    const role = GlobalRole[condition.value as string];
    if (!role) {
      throw new Error(`Unknown Global Role: ${condition.value}`);
    }
    return role;
  }
}
