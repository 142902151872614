import {MatSnackBar} from '@angular/material/snack-bar';
import {PadType} from 'app/element-ref.service';
import {Caret} from 'app/fragment/caret';
import {ClipboardFormat, FragmentParser} from 'app/fragment/core/parser/fragment-parser';
import {hasDescendantOfType, isClauseGroupOfType} from 'app/fragment/fragment-utils';
import {Suite} from 'app/fragment/suite';
import {TreeStructureValidator} from 'app/fragment/tree-structure-validator';
import {
  ClauseFragment,
  ClauseType,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  Fragment,
  FragmentType,
  SectionType,
} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {ClauseGroupType} from 'app/fragment/types/clause-group-type';
import {PadOperationsService} from 'app/pad-operations.service';
import {ClauseService} from '../clause.service';
import {DomService} from '../dom.service';
import {ClipboardJson} from './clipboard-json';
import {CopyPasteService} from './copy-paste.service';

/**
 * An abstract class representing a paste handler which checks if paste is allowed, can get the fragments to paste
 * and actually does the pasting. It delegates these operations to the deriving classes, which depends on the source
 * of the copied content.
 */
export abstract class PasteHandler {
  public static getHandler(
    copyPasteService: CopyPasteService,
    padOperationsService: PadOperationsService,
    domService: DomService,
    snackbar: MatSnackBar,
    event: Event
  ): PasteHandler {
    const sourceData: DataTransfer = event['clipboardData'] ? event['clipboardData'] : window['clipboardData']; // For IE

    const sourceMap: Record<ClipboardFormat, string> = {
      [ClipboardFormat.TEXT_HTML]: sourceData.getData(ClipboardFormat.TEXT_HTML),
      [ClipboardFormat.TEXT_PLAIN]: sourceData.getData(ClipboardFormat.TEXT_PLAIN),
    };

    const isPastingFromCars: boolean = ClipboardJson.getCurrent()?.compareHashes(sourceMap[ClipboardFormat.TEXT_PLAIN]);

    if (isPastingFromCars) {
      return new PasteFromCarsHandler(copyPasteService, padOperationsService, domService, snackbar, sourceMap);
    } else {
      return ExternalPasteHandler.getExternalHandler(
        copyPasteService,
        padOperationsService,
        domService,
        snackbar,
        sourceMap
      );
    }
  }

  public constructor(
    protected _copyPasteService: CopyPasteService,
    protected _padOperationsService: PadOperationsService,
    protected _domService: DomService,
    protected _snackbar: MatSnackBar,
    protected _sourceMap: Record<ClipboardFormat, string>
  ) {}

  public abstract canPaste(selection: Selection): boolean;

  public abstract getFragments(caret?: Caret, clause?: ClauseFragment, suite?: Suite): Promise<Fragment[]>;

  public abstract doPaste(caret: Caret, fragments: Fragment[], padType: PadType): void;

  /**
   * Returns true if the given fragment is within an input area (also returns true if it is an input fragment)
   */
  protected _isInInputArea(ancestor: Fragment): boolean {
    return !!ancestor.findAncestorWithType(FragmentType.INPUT);
  }

  /**
   * Returns true if the given fragment is wholly contained within a single nationally determined requirement clause,
   * and the clause is not a specifier instruction.
   *
   * Note we only check the immediate clause group ancestor, as pasting into a SFR contained within
   * an NDR should behave the same as pasting within an SFR, rather than an NDR.
   */
  protected _isInSingleNDRClause(ancestor: Fragment): boolean {
    const clause: Fragment = ancestor.findAncestorWithType(FragmentType.CLAUSE);
    const clauseGroup: ClauseGroupFragment = ancestor.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    return (
      !!clause &&
      !clause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) &&
      !!clauseGroup &&
      isClauseGroupOfType(clauseGroup, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)
    );
  }

  /**
   * Returns true if the given ancestor is in a specifier instruction or clause group or if a clause group
   * or specifier instruction is contained between the start and end fragments in the ancestor.
   */
  protected _isContainedInOrContainsNoOpClause(ancestor: Fragment, start: Caret, end: Caret): boolean {
    const clause: Fragment = ancestor.findAncestorWithType(FragmentType.CLAUSE);
    const clauseGroup: ClauseGroupFragment = ancestor.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    return (
      !!clauseGroup ||
      Boolean(clause?.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) ||
      this._containsClauseGroupOrSpecifierInstruction(ancestor, start.fragment, end.fragment)
    );
  }

  /**
   * Returns true if a clause group or specifier instruction clause exists between the start and end fragments in the ancestor
   */
  protected _containsClauseGroupOrSpecifierInstruction(ancestor: Fragment, start: Fragment, end: Fragment): boolean {
    return (
      ancestor.iterateDown(
        start,
        end,
        (fragment: Fragment) =>
          fragment.is(FragmentType.CLAUSE_GROUP) || fragment.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)
      ).length > 0
    );
  }
}

/**
 * A concrete implementation of PasteHandler which handles the case where we are copy/pasting from
 * within CARS rather than from an external source.
 */
class PasteFromCarsHandler extends PasteHandler {
  private readonly validClauseAncestorTypes: Readonly<FragmentType[]>;

  constructor(
    protected _copyPasteService: CopyPasteService,
    protected _padOperationsService: PadOperationsService,
    protected _domService: DomService,
    protected _snackbar: MatSnackBar,
    protected _sourceMap: Record<ClipboardFormat, string>
  ) {
    super(_copyPasteService, _padOperationsService, _domService, _snackbar, _sourceMap);
    this.validClauseAncestorTypes = TreeStructureValidator.getValidAncestorTypes(FragmentType.CLAUSE);
  }

  public canPaste(selection: Selection): boolean {
    const [start, end]: Caret[] = this._domService.getCaretsFromSelection(selection);

    if (!start.fragment || !end.fragment) {
      return false;
    }

    const ancestor: Fragment = Fragment.commonAncestorOf(start.fragment, end.fragment);
    return (
      ((this._isInInputArea(ancestor) || this._isInSingleNDRClause(ancestor)) && this._isClipboardPasteableText()) ||
      !this._isContainedInOrContainsNoOpClauseFromCars(ancestor, start, end)
    );
  }

  /**
   * Returns true if the given ancestor is in a specifier instruction or clause group or if a clause group
   * or specifier instruction is contained between the start and end fragments in the ancestor.
   */
  protected _isContainedInOrContainsNoOpClauseFromCars(commonAncestor: Fragment, start: Caret, end: Caret): boolean {
    const clause: Fragment = commonAncestor.findAncestorWithType(FragmentType.CLAUSE);
    const clauseGroup: ClauseGroupFragment = commonAncestor.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    const ignoreNoOpForTableAndListInClauseGroup: boolean =
      !!clauseGroup &&
      !this._pastingTableOrListIntoClauseGroup(clauseGroup, clause) &&
      !this._containedWithinTableOrList(commonAncestor);

    return (
      ignoreNoOpForTableAndListInClauseGroup ||
      Boolean(clause?.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) ||
      this._containsClauseGroupOrSpecifierInstruction(commonAncestor, start.fragment, end.fragment)
    );
  }

  /**
   * Check if fragment is contained within a Table or List as all pasting is allowed within these fragments
   * Always returns false if pasting a TableFragment as this cannot be pasted inside a list
   *
   * @param commonAncestor common ancestor of start and end fragment
   * @returns whether the given fragment is contained within a table or list
   */
  private _containedWithinTableOrList(commonAncestor: Fragment): boolean {
    const pastingFragments: Fragment[] = ClipboardJson.getCurrent().getFragments();
    const pastingTable: boolean = pastingFragments.length === 1 && pastingFragments[0].is(FragmentType.TABLE);

    return !pastingTable && !!commonAncestor.findAncestorWithType(FragmentType.LIST, FragmentType.TABLE);
  }

  /**
   * Allows pasting of up to 1 table and one list into an SFR at the clause level
   * @param clauseGroup parent clause group of fragment being pasted into
   * @param clauseAncestor clause ancestor of caret
   * @returns {boolean} If a table or list is being pasted into an sfr that does not already contain one
   */
  private _pastingTableOrListIntoClauseGroup(clauseGroup: ClauseGroupFragment, clauseAncestor: Fragment): boolean {
    if (!clauseGroup) {
      return false;
    }

    let tableOrList: FragmentType[] = [FragmentType.TABLE, FragmentType.LIST];
    const pastingFragments: Fragment[] = ClipboardJson.getCurrent().getFragments();

    if (!(pastingFragments.length === 1 && pastingFragments[0].is(...tableOrList))) {
      return false;
    }

    tableOrList = tableOrList.filter((fragmentType: FragmentType) => fragmentType === pastingFragments[0].type);

    return !hasDescendantOfType(clauseAncestor, 1, ...tableOrList);
  }

  /**
   * Returns true if the current clipboard contains only text fragments which can be pasted into a input region of a
   * standard format clause group or specifier instruction, or into an NDR clause
   */
  private _isClipboardPasteableText(): boolean {
    return ClipboardJson.getCurrent()
      .getFragments()
      .every((f: Fragment) => f.is(...EDITABLE_TEXT_FRAGMENT_TYPES));
  }

  public getFragments(caret: Caret, clause: ClauseFragment, suite: Suite): Promise<Fragment[]> {
    return this._copyPasteService.getFragmentsToPaste().then((fragments: Fragment[]) => {
      if (!fragments.length) {
        this._snackbar.open('No content in clipboard', 'Dismiss', {duration: 5000});
        this._copyPasteService.setCursorStyle(false);
        return Promise.reject(fragments);
      }

      fragments.forEach((fragment: Fragment) => this._fixClauseTypes(clause.getSection().sectionType, fragment, suite));

      return fragments;
    });
  }

  /**
   * Recursively update all clauses contained in the fragment to have the correct clause type for the new section.
   * Note that we don't want to change the clause types for clauses in clause groups.
   */
  private _fixClauseTypes(newSectionType: SectionType, fragment: Fragment, suite: Suite): void {
    if (fragment.is(FragmentType.CLAUSE_GROUP)) {
      return;
    } else if (fragment.is(...this.validClauseAncestorTypes)) {
      fragment.children.forEach((child: Fragment) => this._fixClauseTypes(newSectionType, child, suite));
    } else if (fragment.is(FragmentType.CLAUSE)) {
      const clause: ClauseFragment = fragment as ClauseFragment;
      clause.clauseType = ClauseService.getClauseTypeForNewSection(newSectionType, clause.clauseType, suite);
    }
  }

  public doPaste(caret: Caret, fragments: Fragment[], padType: PadType): void {
    caret = this._adjustCaretIfPastingTableOrListIntoClauseGroup(caret, fragments);
    this._padOperationsService.strictInsertFragments(caret, fragments, padType);
  }

  /**
   * If pasting a List or Table into an SFR, adjust the caret to the end of the current clause
   * @param caret caret position to paste into
   * @param fragments fragments being pasted
   * @return {Caret} New caret position
   */
  private _adjustCaretIfPastingTableOrListIntoClauseGroup(caret: Caret, fragments: Fragment[]): Caret {
    if (
      fragments.length !== 1 ||
      !fragments[0].is(FragmentType.TABLE, FragmentType.LIST) ||
      !!caret.fragment.findAncestorWithType(FragmentType.TABLE)
    ) {
      return caret;
    }

    const clauseGroup: ClauseGroupFragment = caret.fragment.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    if (!clauseGroup) {
      return caret;
    }

    const clause: ClauseFragment = caret.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;

    if (hasDescendantOfType(clause, 1, FragmentType.TABLE)) {
      return Caret.endOf(clause.children[clause.children.length - 2]);
    }
    return Caret.endOf(clause.children[clause.children.length - 1]);
  }
}

/**
 * An abstract implementation of PasteHandler which handles the case where we are copy/pasting from
 * somewhere other than CARS.
 */
abstract class ExternalPasteHandler extends PasteHandler {
  protected abstract readonly pasteFormat: ClipboardFormat;

  public static getExternalHandler(
    copyPasteService: CopyPasteService,
    padOperationsService: PadOperationsService,
    domService: DomService,
    snackbar: MatSnackBar,
    sourceMap: Record<ClipboardFormat, string>
  ): ExternalPasteHandler {
    const sourceAsElement: HTMLElement = document.createElement('DIV');
    sourceAsElement.innerHTML = sourceMap[ClipboardFormat.TEXT_HTML];

    const format: ClipboardFormat = this._getExternalFormat(sourceAsElement);

    switch (format) {
      case ClipboardFormat.TEXT_PLAIN:
        return new PastePlainTextHandler(copyPasteService, padOperationsService, domService, snackbar, sourceMap);
      case ClipboardFormat.TEXT_HTML:
        return new PasteHTMLTextHandler(
          copyPasteService,
          padOperationsService,
          domService,
          snackbar,
          sourceMap,
          sourceAsElement
        );
      default:
        throw new Error(`Cannot find an ExternalPasteHandler for '${format}'.`);
    }
  }

  private static _getExternalFormat(sourceAsElement: HTMLElement): ClipboardFormat {
    const metaTags = sourceAsElement.getElementsByTagName('META');
    const isFromHTML: boolean = Array.from(metaTags).every(
      (element: HTMLMetaElement) => element.content !== 'Word.Document'
    );

    return isFromHTML ? ClipboardFormat.TEXT_PLAIN : ClipboardFormat.TEXT_HTML;
  }

  public getFragments(caret: Caret, clause: ClauseFragment): Promise<Fragment[]> {
    const fragments: Fragment[] = FragmentParser.parseType(
      this.pasteFormat,
      clause.getSection().sectionType,
      this._sourceMap[ClipboardFormat.TEXT_HTML],
      this._sourceMap[ClipboardFormat.TEXT_PLAIN],
      caret
    );

    if (!fragments.length) {
      this._snackbar.open('No content in clipboard', 'Dismiss', {duration: 5000});
      return Promise.reject(fragments);
    }

    return Promise.resolve(fragments);
  }

  public doPaste(caret: Caret, fragments: Fragment[], padType: PadType): void {
    this._padOperationsService.insertFragments(caret, fragments, padType);
  }
}

/**
 * A concrete implementation of PasteHandler which handles the case where we are copy/pasting from a plain text
 * source such as a PDF or text file.
 */
class PastePlainTextHandler extends ExternalPasteHandler {
  protected readonly pasteFormat: ClipboardFormat = ClipboardFormat.TEXT_PLAIN;

  public canPaste(selection: Selection): boolean {
    const [start, end]: Caret[] = this._domService.getCaretsFromSelection(selection);

    if (!start.fragment || !end.fragment) {
      return false;
    }

    const ancestor: Fragment = Fragment.commonAncestorOf(start.fragment, end.fragment);

    return (
      this._isInInputArea(ancestor) ||
      this._isInSingleNDRClause(ancestor) ||
      !this._isContainedInOrContainsNoOpClause(ancestor, start, end)
    );
  }
}

/**
 * A concrete implementation of PasteHandler which handles the case where we are copy/pasting from a word document.
 */
class PasteHTMLTextHandler extends ExternalPasteHandler {
  private static readonly TABLE_TAG: string = 'TABLE';

  protected readonly pasteFormat: ClipboardFormat = ClipboardFormat.TEXT_HTML;

  constructor(
    protected _copyPasteService: CopyPasteService,
    protected _padOperationsService: PadOperationsService,
    protected _domService: DomService,
    protected _snackbar: MatSnackBar,
    protected _sourceMap: Record<ClipboardFormat, string>,
    private _sourceAsElement: HTMLElement
  ) {
    super(_copyPasteService, _padOperationsService, _domService, _snackbar, _sourceMap);
  }

  public canPaste(selection: Selection): boolean {
    const [start, end]: Caret[] = this._domService.getCaretsFromSelection(selection);

    if (!start.fragment || !end.fragment) {
      return false;
    }

    const ancestor: Fragment = Fragment.commonAncestorOf(start.fragment, end.fragment);

    return (
      ((this._isInInputArea(ancestor) || this._isInSingleNDRClause(ancestor)) && this._isOnlyTextNodes()) ||
      !this._isContainedInOrContainsNoOpClause(ancestor, start, end)
    );
  }

  private _isOnlyTextNodes(): boolean {
    for (let i = 0; i < this._sourceAsElement.childNodes.length; i++) {
      if (this._sourceAsElement.childNodes[i].nodeName === PasteHTMLTextHandler.TABLE_TAG) {
        return false;
      } else if (this._sourceAsElement.childNodes[i].nodeName === 'DIV') {
        for (let j = 0; j < this._sourceAsElement.childNodes[i].childNodes.length; j++) {
          if (this._sourceAsElement.childNodes[i].childNodes[j].nodeName === PasteHTMLTextHandler.TABLE_TAG) {
            return false;
          }
        }
      }
    }

    return true;
  }
}
