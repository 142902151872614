import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subject, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {BaseService} from './base.service';

export enum ReviewState {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export interface ReviewStatusProperties {
  statusText: string;
  buttonText: string;
  icon: string;
  iconColor: string;
}

export class UserReviewState {
  public static readonly NEXT_REVIEW_STATE: Readonly<Record<ReviewState, ReviewState>> = {
    [ReviewState.NOT_STARTED]: ReviewState.IN_PROGRESS,
    [ReviewState.IN_PROGRESS]: ReviewState.COMPLETE,
    [ReviewState.COMPLETE]: ReviewState.IN_PROGRESS,
  };

  public static readonly DISPLAY_PROPERTIES: Readonly<Record<ReviewState, ReviewStatusProperties>> = {
    [ReviewState.NOT_STARTED]: {
      statusText: 'Not Started',
      buttonText: 'START REVIEW',
      icon: 'cancel',
      iconColor: 'grey',
    },
    [ReviewState.IN_PROGRESS]: {
      statusText: 'In Progress',
      buttonText: 'FINISH REVIEW',
      icon: 'access_time',
      iconColor: 'orange',
    },
    [ReviewState.COMPLETE]: {
      statusText: 'Complete',
      buttonText: 'REOPEN REVIEW',
      icon: 'check_circle',
      iconColor: 'green',
    },
  };

  public id: UUID;
  public documentId: UUID;
  public versionId: UUID;
  public userId: UUID;
  public reviewState: ReviewState;
  public createdAt: number;
  public discussions: number;
  public resolvedDiscussions: number;
  public userName: string;

  public static deserialise(json: any): UserReviewState {
    if (!json) {
      return null;
    }

    const state: UserReviewState = new UserReviewState();

    state.id = UUID.orNull(json.id);
    state.documentId = UUID.orNull(json.documentId);
    state.versionId = UUID.orNull(json.versionId);
    state.userId = UUID.orNull(json.userId);
    state.reviewState = json.reviewState as ReviewState;
    state.createdAt = json.createdAt as number;
    state.discussions = json.discussions as number;
    state.resolvedDiscussions = json.resolvedDiscussions as number;
    state.userName = json.userName;

    return state;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ReviewStateService extends BaseService {
  private _updatedReviewStateSubject: Subject<UserReviewState> = new Subject();

  constructor(protected _snackbar: MatSnackBar, private _http: HttpClient) {
    super(_snackbar);
  }

  public onReviewStateSave(callback: (reviewState: UserReviewState) => void, versionId: UUID): Subscription {
    return this._updatedReviewStateSubject
      .pipe(filter((value: UserReviewState) => versionId && versionId.equals(value.versionId)))
      .subscribe(callback);
  }

  public getCurrentUserStateForAllVersions(documentId: UUID): Promise<Record<string, UserReviewState>> {
    return this._http
      .get<Record<string, any>>(
        `${environment.apiHost}/review/documents/${documentId.value}/versions/all/users/current`
      )
      .toPromise()
      .then((response: Record<string, any>) =>
        Object.keys(response).reduce((resultMap: Record<string, UserReviewState>, key: string) => {
          resultMap[key] = UserReviewState.deserialise(response[key]);
          return resultMap;
        }, {})
      )
      .catch((response: any) => {
        this._handleError(response, 'Failed to retrieve user states for document.', 'discussion-error');
        return Promise.reject(response);
      });
  }

  public getReviewerStatesForAllVersions(documentId: UUID): Promise<Record<string, UserReviewState[]>> {
    return this._http
      .get<Record<string, any[]>>(`${environment.apiHost}/review/documents/${documentId.value}/versions/all/users/all`)
      .toPromise()
      .then((response: Record<string, any[]>) =>
        Object.keys(response).reduce((resultMap: Record<string, UserReviewState[]>, key: string) => {
          const array: any[] = response[key];
          resultMap[key] = array.map(UserReviewState.deserialise);
          return resultMap;
        }, {})
      )
      .catch((response: any) => {
        this._handleError(response, 'Failed to retrieve reviewer states for document.', 'discussion-error');
        return Promise.reject(response);
      });
  }

  public getCurrentUserStateForVersion(documentId: UUID, versionId: UUID): Promise<UserReviewState> {
    return this._http
      .get<any>(`${environment.apiHost}/review/documents/${documentId.value}/versions/${versionId.value}/users/current`)
      .pipe(map((response: any) => UserReviewState.deserialise(response)))
      .toPromise()
      .catch((response: any) => {
        this._handleError(response, 'Failed to retrieve user state for document version.', 'discussion-error');
        return Promise.reject(response);
      });
  }

  public saveUserReviewState(
    documentId: UUID,
    versionId: UUID,
    userId: UUID,
    reviewState: ReviewState
  ): Promise<UserReviewState> {
    return this._http
      .post(
        `${environment.apiHost}/review/documents/${documentId.value}/versions/${versionId.value}/users/${userId.value}/save`,
        reviewState.toString()
      )
      .toPromise()
      .then((json) => {
        const saved: UserReviewState = UserReviewState.deserialise(json);
        this._updatedReviewStateSubject.next(saved);
        return saved;
      })
      .catch((response: any) => {
        this._handleError(response, 'Failed to save user review state.', 'review-state-error');
        return Promise.reject(response);
      });
  }
}
