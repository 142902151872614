import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {DocumentMetrics, SectionMetrics} from 'app/document-overview/document-information/metrics/document-metrics';
import {DocumentMetricsService} from 'app/document-overview/document-information/metrics/document-metrics.service';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {DocumentFragment, SectionFragment} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {
  ConfigurationService,
  DocumentMetricsConfigItem,
  DocumentMetricsConfigItemType,
} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';

@Component({
  selector: 'cars-document-metrics',
  templateUrl: './document-metrics.component.html',
  styleUrls: ['./document-metrics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentMetricsComponent implements OnInit, OnChanges {
  @Input() public document: DocumentFragment;

  public documentMetricsConfig: DocumentMetricsConfigItem[] = [];

  public loading: boolean = false;

  public metrics: DocumentMetrics = null;

  private _expanded: Partial<Record<DocumentMetricsConfigItemType, boolean>> = {};

  constructor(
    private _documentMetricsService: DocumentMetricsService,
    private _fragmentService: FragmentService,
    private _configurationService: ConfigurationService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._configurationService
      .getDocumentMetricsConfigurationForSuite(this.document.suite)
      .then((configItems: DocumentMetricsConfigItem[]) => {
        this.documentMetricsConfig = configItems;
        this.documentMetricsConfig.forEach((item) => {
          this._expanded[item.key] = false;
        });
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('document')) {
      if (
        !changes['document'].previousValue ||
        !changes['document'].previousValue.equals(changes['document'].currentValue)
      ) {
        this._displayMetrics(this._documentMetricsService.getLatest(this.document.id));
      }
    }
  }

  /**
   * Calculate document health metrics for this document.
   */
  public loadMetrics(): void {
    this._displayMetrics(this._documentMetricsService.calculate(this.document.id));
  }

  private _displayMetrics(promise: Promise<DocumentMetrics>): void {
    this.loading = true;
    this._cdr.markForCheck();
    promise
      .then(
        (metrics: DocumentMetrics) => {
          this.metrics = metrics;
          this.loading = false;
          this._cdr.markForCheck();
        },
        (e) => this._handleError(e)
      )
      .catch((e) => this._handleError(e));
  }

  /**
   * Get the link for navigating to a section.
   *
   * @param sectionId {UUID} The ID of the section to navigate to.
   */
  public getSectionUrl(sectionId: UUID): string {
    if (sectionId && this.document) {
      return `/documents/${this.document.id.value}/sections/${sectionId.value}`;
    } else {
      Logger.error('metrics-error', 'document or section ID not set');
      return '';
    }
  }

  /**
   * Get the title of a section.
   *
   * @param sectionId {UUID}   The ID of the section
   */
  public getSectionTitle(sectionId: UUID): string {
    const section: SectionFragment = this._fragmentService.find(sectionId) as SectionFragment;
    if (section) {
      return section.value;
    } else {
      Logger.error('metrics-error', 'Failed to load a section title in the metrics component');
      return 'Unknown section';
    }
  }

  /**
   * Returns true if the metric has been expanded so that the per-section breakdown
   * is visible.
   *
   * @param key {DocumentMetricsConfigItemType}  The type of the metric.
   */
  public isExpanded(key: DocumentMetricsConfigItemType): boolean {
    return this._expanded[key];
  }

  /**
   * Toggle whether the per-section breakdown of the metric is visible.
   *
   * @param key {MetricsKey} The key of the metric.
   */
  public toggleExpanded(key: DocumentMetricsConfigItemType): void {
    // This guard is to prevent clicking a row with 0 errors still toggling the expand icon
    if (this.metrics.getValueByConfigType(key) !== 0) {
      this._expanded[key] = !this._expanded[key];
      this._cdr.markForCheck();
    }
  }

  /**
   * Get the description of the metric.  If there is only 1 of that metric, return a description
   * suitable for describing a singular object.  Otherwise, return a plural description.
   *
   * @param key  {MetricsKey} The key of the metric.
   * @param sectionMetrics {SectionMetrics} If null, use the document-level metric to determine
   * plurality.  Otherwise, use the passed object to determine plurality.
   */
  public getDescription(item: DocumentMetricsConfigItem, sectionMetrics: SectionMetrics = null): string {
    const score: number = sectionMetrics
      ? sectionMetrics.getValueByConfigType(item.key)
      : this.metrics.getValueByConfigType(item.key);

    if (score === 1) {
      return item.singularDescription;
    } else {
      return item.pluralDescription;
    }
  }

  private _handleError(e: any): void {
    this.loading = false;
    this._cdr.markForCheck();
  }
}
