import {DocumentRole} from 'app/documents/document-data';
import {UUID} from 'app/utils/uuid';

export class DocumentDiscussionsAggregate {
  public documentId: UUID;
  public documentTitle: string;
  public documentCode: string;
  public role: DocumentRole;

  public static deserialise(json: any): DocumentDiscussionsAggregate {
    const aggregate: DocumentDiscussionsAggregate = new DocumentDiscussionsAggregate();
    aggregate.documentId = UUID.orNull(json.documentId);
    aggregate.documentTitle = json.documentTitle;
    aggregate.documentCode = json.documentCode;
    aggregate.role = json.role ? DocumentRole[json.role as string] : null;
    return aggregate;
  }
}
