import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Fragment, FragmentType} from 'app/fragment/types';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {CarsAction} from 'app/permissions/types/permissions';
import {FragmentService} from 'app/services/fragment.service';
import {NavigationTypes} from 'app/services/navigation.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-section-group-list-item',
  templateUrl: './section-group-list-item.component.html',
  styleUrls: ['./section-group-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGroupListItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public sectionGroup: SectionGroupFragment;
  @Input() public currentView: CurrentView = null;
  @Input() public type: NavigationTypes = NavigationTypes.DOCUMENT;

  @Output() public onDraggingEvent: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('title', {static: true}) private title: ElementRef;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public selected: boolean = false;
  public reorderingClauses: boolean;

  private _subscriptions: Subscription[] = [];
  private _useViewService: boolean = true;

  constructor(
    private _fragmentService: FragmentService,
    private _viewService: ViewService,
    private _reorderClausesService: ReorderClausesService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._fragmentService.onCreate(() => this._cdr.markForCheck(), this._fragmentServiceUpdatePredicate),
      this._fragmentService.onUpdate(() => this._cdr.markForCheck(), this._fragmentServiceUpdatePredicate),
      this._fragmentService.onDelete(() => this._cdr.markForCheck(), this._fragmentServiceUpdatePredicate),
      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        if (this._useViewService) {
          this.currentView = currentView;
          this._cdr.markForCheck();
        }
      }),
      this._reorderClausesService.onReorderingEvent().subscribe((reordering: boolean) => {
        this.reorderingClauses = reordering;
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Respond to Angular binding changes.
   *
   * @param changes {SimpleChanges}   The changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('currentView')) {
      this._useViewService = !changes.currentView.currentValue;
      this.currentView = this._useViewService ? this._viewService.getCurrentView() : changes.currentView.currentValue;
    }
  }

  /**
   * Triggered on mousedown of the drag handle / thumb.
   */
  public startDragging(event): void {
    event.preventDefault();
    this.onDraggingEvent.emit(true);
  }

  /**
   * Returns whether the title has been trucated within the display. Used to display tooltip if it is trucated.
   */
  public isTitleTrucated(): boolean {
    return this.title.nativeElement.scrollWidth > this.title.nativeElement.clientWidth;
  }

  /**
   * Predicate for whether the fragment event should trigger a mark for check of this component.
   * Returns true for SECTION_GROUPs and SECTIONs.
   */
  private _fragmentServiceUpdatePredicate(fragment: Fragment): boolean {
    return fragment?.is(FragmentType.SECTION_GROUP, FragmentType.SECTION);
  }
}
