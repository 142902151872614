import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {AdministrationToDisplayNameModule} from 'app/utils/pipes/administration-to-display-name.module';
import {DocumentLinkModule} from './document-link/document-link.module';
import {NaaDocumentLinkageComponent} from './naa-document-linkage.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    AdministrationToDisplayNameModule,
    MatCheckboxModule,
    MatButtonModule,
    DocumentLinkModule,
  ],
  declarations: [NaaDocumentLinkageComponent],
  exports: [NaaDocumentLinkageComponent],
})
export class NaaDocumentLinkageModule {}
