import {Div} from './divs';

/**
 * A class representating the pool of divs.
 */
export class DivPool {
  // If the total number of divs is greater than this, recycled divs will be destroyed.
  private static readonly SOFT_UPPER_BOUND: number = 20;

  private available: Div[] = [];
  private inUse: Set<Div> = new Set<Div>();

  /**
   * @param parent The parent element to attach divs to.
   * @param allocate How many divs to create initially.
   */
  constructor(private parent: HTMLElement, private allocate: number = 10) {
    for (let i = 0; i < this.allocate; i++) {
      this.available.push(this.create());
    }
  }

  /**
   * Take a div from the pool, or create one if none are available.
   * @returns the div.
   */
  public obtain(): Div {
    const div: Div = this.available.length > 0 ? this.available.shift() : this.create();
    this.inUse.add(div);
    return div;
  }

  /**
   * Total number of divs available and in use.
   */
  public size(): number {
    return this.available.length + this.inUse.size;
  }

  /**
   * Return a div or divs to the pool.  If there are more divs than
   * @param divs the divs to return.
   */
  public recycle(div: Div | Div[]): void {
    if (!div) {
      return;
    }

    const divs: Div[] = !(div instanceof Array) ? [div] : div;
    divs.forEach((_div: Div) => {
      if (!this.inUse.has(_div)) {
        throw new Error('Tried to recycle a div which was not in use');
      }
      _div.recycle();
      if (this.size() > DivPool.SOFT_UPPER_BOUND) {
        _div.remove();
      } else {
        this.available.push(_div);
      }
      this.inUse.delete(_div);
    });
  }

  private create(): Div {
    const div: Div = Div.empty();

    this.parent.appendChild(div.element);
    return div;
  }
}
