import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Suite} from 'app/fragment/suite';
import {CategoryOfChange} from 'app/fragment/types/category-of-change';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {Observable, Subject, Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {BaseService} from '../services/base.service';
import {WebSocketService} from '../services/websocket/websocket.service';
import {UUID} from '../utils/uuid';
import {EqualityRelation, QL, QlNode} from './query-node';

export interface PaginationAndSortData {
  pageIndex: number;
  pageSize: number;
  sort: string;
  direction: string;
}

export enum SearchableDocumentColumn {
  DOCUMENT_ID = 'DOCUMENT_ID',
  IS_DELETED = 'IS_DELETED',
  TITLE = 'TITLE',
  SUMMARY = 'SUMMARY',
  SHW_SUMMARY = 'SHW_SUMMARY',
  IFS_SUMMARY = 'IFS_SUMMARY',
  LEGACY_REFERENCE = 'LEGACY_REFERENCE',
  JIRA_DOCUMENT_ID = 'JIRA_DOCUMENT_ID',
  JIRA_EPIC_ID = 'JIRA_EPIC_ID',
  DEPRECATED_DMRB_REVISION = 'DEPRECATED_DMRB_REVISION',
  REVISION_RELEASE_NOTES = 'REVISION_RELEASE_NOTES',
  RELEASE_NOTES_IFS = 'RELEASE_NOTES_IFS',
  RELEASE_NOTES_SHW = 'RELEASE_NOTES_SHW',
  DISCIPLINE = 'DISCIPLINE',
  LIFE_CYCLE_STAGE = 'LIFE_CYCLE_STAGE',
  DOCUMENT_CODE = 'DOCUMENT_CODE',
  IFS_DOCUMENT_CODE = 'IFS_DOCUMENT_CODE',
  SHW_DOCUMENT_CODE = 'SHW_DOCUMENT_CODE',
  SHW_LEGACY_REFERENCE = 'SHW_LEGACY_REFERENCE',
  IFS_LEGACY_REFERENCE = 'IFS_LEGACY_REFERENCE',
  PREVIOUS_MCHW_VOLUME = 'PREVIOUS_MCHW_VOLUME',
  PREVIOUS_MCHW_SECTION = 'PREVIOUS_MCHW_SECTION',
  LEAD_AUTHOR_ID = 'LEAD_AUTHOR_ID',
  LEAD_AUTHOR_NAME = 'LEAD_AUTHOR_NAME',
  AUTHORS_IDS = 'AUTHORS_IDS',
  AUTHORS_NAMES = 'AUTHORS_NAMES',
  REVIEWERS_IDS = 'REVIEWERS_IDS',
  REVIEWERS_NAMES = 'REVIEWERS_NAMES',
  PEER_REVIEWERS_IDS = 'PEER_REVIEWERS_IDS',
  PEER_REVIEWERS_NAME = 'PEER_REVIEWERS_NAME',
  DOCUMENT_OWNER_ID = 'DOCUMENT_OWNER_ID',
  DOCUMENT_OWNER_NAME = 'DOCUMENT_OWNER_NAME',
  WORKFLOW_STATUS = 'WORKFLOW_STATUS',
  ADMINISTRATION = 'ADMINISTRATION',
  VERSION_ID = 'VERSION_ID',
  SUITE = 'SUITE',
  CATEGORY_OF_CHANGE = 'CATEGORY_OF_CHANGE',
  PUBLISHED_VERSION_NUMBER = 'PUBLISHED_VERSION_NUMBER',
  NEXT_PUBLICATION_VERSION_NUMBER = 'NEXT_PUBLICATION_VERSION_NUMBER',
  VERSION_TAG_TYPE = 'VERSION_TAG_TYPE',
  VERSION_CREATED_AT = 'VERSION_CREATED_AT',
}

export interface SearchableDocument {
  [SearchableDocumentColumn.DOCUMENT_ID]?: UUID;
  [SearchableDocumentColumn.IS_DELETED]?: boolean;
  [SearchableDocumentColumn.TITLE]?: string;
  [SearchableDocumentColumn.SUMMARY]?: string;
  [SearchableDocumentColumn.SHW_SUMMARY]?: string;
  [SearchableDocumentColumn.IFS_SUMMARY]?: string;
  [SearchableDocumentColumn.LEGACY_REFERENCE]?: string;
  [SearchableDocumentColumn.JIRA_DOCUMENT_ID]?: string;
  [SearchableDocumentColumn.JIRA_EPIC_ID]?: string;
  [SearchableDocumentColumn.DEPRECATED_DMRB_REVISION]?: string;
  [SearchableDocumentColumn.REVISION_RELEASE_NOTES]?: string;
  [SearchableDocumentColumn.RELEASE_NOTES_IFS]?: string;
  [SearchableDocumentColumn.RELEASE_NOTES_SHW]?: string;
  [SearchableDocumentColumn.DISCIPLINE]?: string;
  [SearchableDocumentColumn.LIFE_CYCLE_STAGE]?: string;
  [SearchableDocumentColumn.DOCUMENT_CODE]?: string;
  [SearchableDocumentColumn.IFS_DOCUMENT_CODE]?: string;
  [SearchableDocumentColumn.SHW_DOCUMENT_CODE]?: string;
  [SearchableDocumentColumn.SHW_LEGACY_REFERENCE]?: string;
  [SearchableDocumentColumn.IFS_LEGACY_REFERENCE]?: string;
  [SearchableDocumentColumn.PREVIOUS_MCHW_VOLUME]?: string;
  [SearchableDocumentColumn.PREVIOUS_MCHW_SECTION]?: string;
  [SearchableDocumentColumn.LEAD_AUTHOR_ID]?: UUID;
  [SearchableDocumentColumn.LEAD_AUTHOR_NAME]?: string;
  [SearchableDocumentColumn.AUTHORS_IDS]?: UUID[];
  [SearchableDocumentColumn.AUTHORS_NAMES]?: string;
  [SearchableDocumentColumn.REVIEWERS_IDS]?: UUID[];
  [SearchableDocumentColumn.REVIEWERS_NAMES]?: string;
  [SearchableDocumentColumn.PEER_REVIEWERS_IDS]?: UUID[];
  [SearchableDocumentColumn.PEER_REVIEWERS_NAME]?: string;
  [SearchableDocumentColumn.DOCUMENT_OWNER_ID]?: UUID;
  [SearchableDocumentColumn.DOCUMENT_OWNER_NAME]?: string;
  [SearchableDocumentColumn.WORKFLOW_STATUS]?: string;
  [SearchableDocumentColumn.ADMINISTRATION]?: string;
  [SearchableDocumentColumn.VERSION_ID]?: UUID;
  [SearchableDocumentColumn.SUITE]?: Suite;
  [SearchableDocumentColumn.CATEGORY_OF_CHANGE]?: CategoryOfChange;
  [SearchableDocumentColumn.PUBLISHED_VERSION_NUMBER]?: string;
  [SearchableDocumentColumn.NEXT_PUBLICATION_VERSION_NUMBER]?: string;
  [SearchableDocumentColumn.VERSION_TAG_TYPE]?: VersionTagType;
  [SearchableDocumentColumn.VERSION_CREATED_AT]?: number;
}

export interface SearchResult<T> {
  page: T[];
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class SearchDocumentsService extends BaseService {
  public static readonly SEARCH_ENDPOINT: string = `${environment.apiHost}/documents/search/query`;

  private searchChanged: Subject<void> = new Subject<void>();

  constructor(private httpClient: HttpClient, _snackbar: MatSnackBar, private websocketService: WebSocketService) {
    super(_snackbar);
    this.websocketService.onConnection(this._onWebsocketConnect.bind(this));
  }

  public globalReferenceCarsLink(filterTerm: string): Promise<SearchResult<SearchableDocument>> {
    const query: QlNode = this._generateDocumentSelectQueryNode(filterTerm, []);

    const columns: SearchableDocumentColumn[] = [
      SearchableDocumentColumn.DOCUMENT_ID,
      SearchableDocumentColumn.DOCUMENT_CODE,
      SearchableDocumentColumn.SHW_DOCUMENT_CODE,
      SearchableDocumentColumn.IFS_DOCUMENT_CODE,
      SearchableDocumentColumn.TITLE,
      SearchableDocumentColumn.DOCUMENT_OWNER_NAME,
    ];
    const paginationAndSortData: PaginationAndSortData = {
      pageIndex: 0,
      pageSize: 20,
      sort: SearchableDocumentColumn.TITLE,
      direction: 'ASC',
    };
    return this.search(query, columns, paginationAndSortData);
  }

  public sectionReferences(filterTerm: string): Promise<SearchResult<SearchableDocument>> {
    const query: QlNode = this._generateDocumentSelectQueryNode(filterTerm, [Suite.MCHW]);

    const columns: SearchableDocumentColumn[] = [
      SearchableDocumentColumn.DOCUMENT_ID,
      SearchableDocumentColumn.DOCUMENT_CODE,
      SearchableDocumentColumn.TITLE,
      SearchableDocumentColumn.DOCUMENT_OWNER_NAME,
    ];
    const paginationAndSortData: PaginationAndSortData = {
      pageIndex: 0,
      pageSize: 20,
      sort: SearchableDocumentColumn.TITLE,
      direction: 'ASC',
    };
    return this.search(query, columns, paginationAndSortData);
  }

  public clauseReferences(filterTerm: string): Promise<SearchResult<SearchableDocument>> {
    const query: QlNode = this._generateDocumentSelectQueryNode(filterTerm, [
      Suite.DMRB,
      Suite.MCHW,
      Suite.UNRESTRICTED,
      Suite.MOMHW,
    ]);

    return this._clauseReferencesSearch(query);
  }

  public clauseReferenceByDocumentId(documentId: UUID): Promise<SearchResult<SearchableDocument>> {
    const query: QlNode = QL.and(
      QL.condition(SearchableDocumentColumn.DOCUMENT_ID, EqualityRelation.EQUAL, documentId.value),
      QL.condition(SearchableDocumentColumn.VERSION_ID, EqualityRelation.EQUAL, null)
    );
    return this._clauseReferencesSearch(query);
  }

  private _clauseReferencesSearch(query: QlNode): Promise<SearchResult<SearchableDocument>> {
    const columns: SearchableDocumentColumn[] = [
      SearchableDocumentColumn.DOCUMENT_ID,
      SearchableDocumentColumn.DOCUMENT_CODE,
      SearchableDocumentColumn.SHW_DOCUMENT_CODE,
      SearchableDocumentColumn.TITLE,
      SearchableDocumentColumn.DOCUMENT_OWNER_NAME,
      SearchableDocumentColumn.SUITE,
    ];
    const paginationAndSortData: PaginationAndSortData = {
      pageIndex: 0,
      pageSize: 20,
      sort: SearchableDocumentColumn.TITLE,
      direction: 'ASC',
    };
    return this.search(query, columns, paginationAndSortData);
  }

  public changelog(filterTerm: string): Promise<SearchResult<SearchableDocument>> {
    const query: QlNode = this._generateDocumentSelectQueryNode(filterTerm, [Suite.LEGACY_DMRB, Suite.LEGACY_MCHW]);

    const columns: SearchableDocumentColumn[] = [SearchableDocumentColumn.DOCUMENT_ID, SearchableDocumentColumn.TITLE];
    const paginationAndSortData: PaginationAndSortData = {
      pageIndex: 0,
      pageSize: 10,
      sort: SearchableDocumentColumn.TITLE,
      direction: 'ASC',
    };
    return this.search(query, columns, paginationAndSortData);
  }

  /**
   * Helper method to generate the query node for use in a document selector search query.
   *
   * @param filterTerm The filter term to use
   * @param suites The suites to filter to, or an empty list to search all suites
   * @returns
   */
  private _generateDocumentSelectQueryNode(filterTerm: string, suites: Suite[]): QlNode {
    const suiteNodes = suites.map((suite: Suite) =>
      QL.condition(SearchableDocumentColumn.SUITE, EqualityRelation.EQUAL, suite)
    );
    const suiteCondition: QlNode =
      suiteNodes.length > 1 ? QL.or(...suiteNodes) : suiteNodes.length > 0 ? suiteNodes[0] : null;

    return QL.and(
      QL.condition(SearchableDocumentColumn.IS_DELETED, EqualityRelation.EQUAL, false),
      suiteCondition,
      QL.condition(SearchableDocumentColumn.VERSION_ID, EqualityRelation.EQUAL, null),
      this._constructFilterTermNode(filterTerm, false)
    );
  }

  public homepage(
    filterTerm: string,
    userId: UUID,
    showDeleted: boolean,
    published: boolean,
    paginationAndSortData: PaginationAndSortData
  ): Promise<SearchResult<SearchableDocument>> {
    const publishedQuery: QlNode = QL.or(
      QL.condition(SearchableDocumentColumn.SUITE, EqualityRelation.EQUAL, Suite.LEGACY_DMRB),
      QL.condition(SearchableDocumentColumn.SUITE, EqualityRelation.EQUAL, Suite.LEGACY_MCHW)
    );
    const query: QlNode = QL.and(
      showDeleted ? null : QL.condition(SearchableDocumentColumn.IS_DELETED, EqualityRelation.EQUAL, false),
      published ? publishedQuery : QL.not(publishedQuery),
      QL.condition(SearchableDocumentColumn.VERSION_ID, EqualityRelation.EQUAL, null),
      this._constructFilterTermNode(filterTerm, true),
      this._constructUserFilterNode(userId)
    );

    return this.search(query, null, paginationAndSortData);
  }

  public sections(
    suite: Suite,
    userId: UUID,
    filterTerm: string,
    documentId: UUID
  ): Promise<SearchResult<SearchableDocument>> {
    const query: QlNode = QL.and(
      QL.condition(SearchableDocumentColumn.IS_DELETED, EqualityRelation.EQUAL, false),
      QL.condition(SearchableDocumentColumn.VERSION_ID, EqualityRelation.EQUAL, null),
      QL.condition(SearchableDocumentColumn.SUITE, EqualityRelation.EQUAL, suite),
      QL.condition(SearchableDocumentColumn.DOCUMENT_ID, EqualityRelation.NOT_EQUAL, documentId.value),
      this._constructFilterTermNode(filterTerm, true),
      this._constructUserFilterNode(userId)
    );

    return this.search(query, null);
  }

  public search(
    query: QlNode,
    columns: string[],
    paginationAndSortData?: PaginationAndSortData
  ): Promise<SearchResult<SearchableDocument>> {
    if (!query) {
      return Promise.resolve({total: 0, page: []});
    }

    return this.httpClient
      .post(SearchDocumentsService.SEARCH_ENDPOINT, {query: query.emit(), columns, paginationAndSortData})
      .toPromise()
      .then((response: any) => {
        return this.deserialiseResults(response);
      })
      .catch((err: HttpErrorResponse) => {
        this._handleError(err, 'Sorry, could not make search query. Please try again.', 'search-error');
        return Promise.reject(err);
      });
  }

  private _constructFilterTermNode(filterTerm: string, includeDocumentOwner: boolean): QlNode {
    return filterTerm
      ? QL.or(
          QL.condition(SearchableDocumentColumn.TITLE, EqualityRelation.PARTIAL, filterTerm),
          QL.condition(SearchableDocumentColumn.LEGACY_REFERENCE, EqualityRelation.PARTIAL, filterTerm),
          QL.condition(SearchableDocumentColumn.DOCUMENT_CODE, EqualityRelation.PARTIAL, filterTerm),
          QL.condition(SearchableDocumentColumn.IFS_DOCUMENT_CODE, EqualityRelation.PARTIAL, filterTerm),
          QL.condition(SearchableDocumentColumn.SHW_DOCUMENT_CODE, EqualityRelation.PARTIAL, filterTerm),
          includeDocumentOwner
            ? QL.condition(SearchableDocumentColumn.DOCUMENT_OWNER_NAME, EqualityRelation.PARTIAL, filterTerm)
            : null
        )
      : null;
  }

  private _constructUserFilterNode(userId: UUID): QlNode {
    return userId
      ? QL.or(
          QL.condition(SearchableDocumentColumn.DOCUMENT_OWNER_ID, EqualityRelation.EQUAL, userId.value),
          QL.condition(SearchableDocumentColumn.LEAD_AUTHOR_ID, EqualityRelation.EQUAL, userId.value),
          QL.condition(SearchableDocumentColumn.AUTHORS_IDS, EqualityRelation.CONTAINS, userId.value),
          QL.condition(SearchableDocumentColumn.REVIEWERS_IDS, EqualityRelation.CONTAINS, userId.value),
          QL.condition(SearchableDocumentColumn.PEER_REVIEWERS_IDS, EqualityRelation.CONTAINS, userId.value)
        )
      : null;
  }

  private deserialiseResults(json: any): SearchResult<SearchableDocument> {
    return {total: json.total, page: json.page.map((entry) => this.deserialise(entry))};
  }

  private deserialise(json: any): SearchableDocument {
    const doc = Object.assign({}, json);
    doc.DOCUMENT_ID = UUID.orNull(doc.DOCUMENT_ID);
    doc.DOCUMENT_OWNER_ID = UUID.orNull(doc.DOCUMENT_OWNER_ID);
    doc.LEAD_AUTHOR_ID = UUID.orNull(doc.LEAD_AUTHOR_ID);
    doc.AUTHORS_IDS = doc.AUTHORS_IDS ? doc.AUTHORS_IDS.map((id) => UUID.orNull(id)) : void 0;
    doc.REVIEWERS_IDS = doc.REVIEWERS_IDS ? doc.REVIEWERS_IDS.map((id) => UUID.orNull(id)) : void 0;
    doc.PEER_REVIEWERS_IDS = doc.PEER_REVIEWERS_IDS ? doc.PEER_REVIEWERS_IDS.map((id) => UUID.orNull(id)) : void 0;
    doc.VERSION_ID = UUID.orNull(doc.VERSION_ID);
    return doc;
  }

  /**
   * Fires whenever the document search results are invalidated
   */
  public onSearchChanged(): Observable<void> {
    return this.searchChanged.asObservable();
  }

  private _onWebsocketConnect(connected: boolean): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());

    if (connected) {
      this._subscriptions.push(this.websocketService.subscribe('/topic/search', () => this.searchChanged.next()));
    }
  }
}
