/* eslint-disable @angular-eslint/directive-class-suffix */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {RichTextType} from 'app/services/rich-text.service';
import {Predicate} from '../../utils/typedefs';
import {ActionRequest} from '../action-request';
import {Caret} from '../caret';
import {FragmentState} from '../fragment-state';
import {Key} from '../key';
import {Fragment, FragmentType} from '../types';

@Directive()
export class FragmentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  // The property name with which FragmentComponents are attached to their DOM nodes
  private static readonly _DOM_TOKEN: string = 'carsFragmentData';
  protected _content: Fragment;

  @Input() public readOnly: boolean;
  protected _element: HTMLElement;

  /**
   * Given a DOM node, find the fragment component at or above it, optionally of a given
   * FragmentType.
   *
   * @param node {Node}                  The DOM node to search from
   * @param type {FragmentType}          An optional fragment type to match
   * @returns    {FragmentComponent[]}   The array of nested fragment components
   */
  public static fromNode(node: Node, type?: FragmentType): FragmentComponent {
    let fragment: FragmentComponent = node ? node[FragmentComponent._DOM_TOKEN] : null;
    while (node && node !== document && (!fragment || (fragment && type && fragment.content.type !== type))) {
      node = node.parentNode;
      fragment = node ? node[FragmentComponent._DOM_TOKEN] : null;
    }

    return fragment;
  }

  @Input() public set content(value: Fragment) {
    this._content = value;
  }

  public get content(): Fragment {
    return this._content;
  }

  constructor(protected _cd: ChangeDetectorRef, elementRef: ElementRef) {
    this._element = elementRef.nativeElement;
    this._element.classList.add('cars-fragment'); // For automated tests
  }

  /**
   * Initialise this FragmentComponent, by setting up references to ourself in the DOM and data tree.
   * Deriving classes can override this method, but _must_ call super.ngOnInit().
   */
  public ngOnInit(): void {
    this.content.component = this;
    this._element[FragmentComponent._DOM_TOKEN] = this;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Left for overriding
  }

  /**
   * Marks branch of tree for CD.
   */
  public markForCheck(): void {
    this._cd.markForCheck();
  }

  /**
   * Marks siblings for CD; applying the predicate to each if supplied.
   *
   * @param predicate {Predicate<Fragment>}   Optional predicate to apply to siblings
   */
  public markSiblingsForCheck(predicate: Predicate<Fragment> = null): void {
    predicate = typeof predicate === 'function' ? predicate : (f: Fragment) => true;
    if (this.content.parent && this.content.parent.children) {
      this.content.parent.children.filter((f: Fragment) => predicate(f)).forEach((f: Fragment) => f.markForCheck());
    }
  }

  /**
   * Marks children for CD; applying the predicate to each if supplied.
   *
   * @param predicate {Predicate<Fragment>}   Optional predicate to apply to children
   */
  public markChildrenForCheck(predicate: Predicate<Fragment> = null): void {
    predicate = typeof predicate === 'function' ? predicate : (f: Fragment) => true;
    if (this.content && this.content.children) {
      this.content.children.filter((f: Fragment) => predicate(f)).forEach((f: Fragment) => f.markForCheck());
    }
  }

  public ngAfterViewInit(): void {
    this.setStateClass();
  }

  /**
   * Tear down this FragmentComponent, by removing previously set references to ourself.  Deriving
   * classes can override this method, but _must_ call super.onOnDestroy().
   */
  public ngOnDestroy(): void {
    // Tear down should only happen if this.content is still the data node mandating this component's
    // existance.  This may not be the case, for example, if ngFor has reused the component after
    // applying binding changes; in this case, ngOnInit() is called on the new component state before
    // ngOnDestroy() is called on the old component state, resulting in the immediate removal of the
    // component reference on the shared reference to this.content.
    if (!this.content.component || this.content.component === this) {
      delete this._element[FragmentComponent._DOM_TOKEN];
      this.content.component = null;
    }
  }

  /**
   * Public getter for _element.
   *
   * @returns {HTMLElement}   This fragment's DOM node
   */
  public get element(): HTMLElement {
    return this._element;
  }

  /**
   * Convenience gublic getter for the parent fragment component.
   *
   * @returns {FragmentComponent}   This fragment's parent component
   */
  public get parent(): FragmentComponent {
    return this.content.parent?.component;
  }

  /**
   * Convenience getter for child fragment components.
   *
   * @returns {FragmentComponent[]}   The array of child fragment components
   */
  public get children(): FragmentComponent[] {
    return this.content.children.map((child: Fragment) => child.component);
  }

  /**
   * Respond to a focus event on this fragment.  Deriving classes can override this no-op.
   */
  public focus(): void {}

  /**
   * Respond to a blur event on this fragment.  Deriving classes can override this no-op.
   */
  public blur(): void {}

  /**
   * Respond to a keydown event on this fragment.  Deriving classes can override this no-op.
   *
   * @param key    {Key}                 The key that is down
   * @param target {FragmentComponent}   The leaf fragment that is focused
   * @param caret  {Caret}               The current caret position
   * @returns      {ActionRequest}       The action to take
   */
  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest | Promise<ActionRequest> {
    return null;
  }

  /**
   * Respond to a rich text UI event, returning true if the event should bubble past this fragment.
   * This no-op can be overridden by deriving components.
   *
   * @param type  {RichTextType}    The event type
   * @param start {Caret}           The start caret position
   * @param end   {Caret}           The end caret position
   * @param args  {any[]}           Any bundled arguments
   * @returns     {ActionRequest}   The requested action
   */
  public onRichText(
    type: RichTextType,
    start: Caret,
    end: Caret,
    ...args: any[]
  ): ActionRequest | Promise<ActionRequest> {
    return null;
  }

  /**
   * Update the display of the fragment depending on the diff state of its content.
   */
  public setStateClass(): void {
    this._element.classList.remove('version-created');
    this._element.classList.remove('version-removed');
    this._element.classList.remove('version-moved');
    switch (this.content.state) {
      case FragmentState.CREATED:
        this._element.classList.add('version-created');
        break;
      case FragmentState.DELETED:
        this._element.classList.add('version-removed');
        break;
      case FragmentState.MOVED:
        this._element.classList.add('version-moved');
        break;
    }
  }
}
