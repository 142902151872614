import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  ClauseFragment,
  DocumentFragment,
  DocumentInformationFragment,
  DocumentInformationType,
  SectionFragment,
} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentValidationService, ValidationFailure} from 'app/services/document-validation.service';
import {
  ConfigurationService,
  DocumentInformationField,
  DocumentInformationItem,
  FieldType,
} from 'app/suite-config/configuration.service';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {BaseFormComponent} from './base-form.component';

@Component({
  selector: 'cars-document-information',
  templateUrl: './document-information.component.html',
  styleUrls: ['./document-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentInformationComponent implements OnInit, OnChanges {
  @ViewChildren(BaseFormComponent) private _forms: QueryList<BaseFormComponent>;

  @Input() public set document(document: DocumentFragment) {
    this._document = document;
    this._configurationService.getDocumentInformationConfigurationForSuite(document.suite).then((fields) => {
      this.documentInformationItems = this._mapFieldsToItems(fields);
      this.formGroup = this._createFormGroup();
      this._cdr.markForCheck();
    });
  }

  public get document(): DocumentFragment {
    return this._document;
  }

  public readonly CarsAction: typeof CarsAction = CarsAction;
  public readonly FieldType: typeof FieldType = FieldType;

  public documentInformationItems: DocumentInformationItem[];

  private _document: DocumentFragment;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _documentValidationService: DocumentValidationService,
    private _configurationService: ConfigurationService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.formGroup = this._createFormGroup();
    this._initFormChanges();
    this._subscriptions.push(
      this._documentValidationService.onValidityCheck().subscribe((failures: ValidationFailure[]) => {
        this._markFormAsTouched();
        this._cdr.markForCheck();
      })
    );
  }

  private _initFormChanges(): void {
    this._subscriptions.push(
      this._documentValidationService.docInfoFormGroup
        .pipe(
          switchMap((formGroup: UntypedFormGroup) => {
            this._cdr.markForCheck();
            return formGroup.statusChanges;
          })
        )
        .subscribe()
    );
  }

  public ngOnChanges(): void {
    this.formGroup = this._createFormGroup();
  }

  private _markFormAsTouched(): void {
    if (this._forms) {
      this._forms.forEach((component: BaseFormComponent) => component.markAsTouched());
    }
  }

  private _mapFieldsToItems(fields: DocumentInformationField[]): DocumentInformationItem[] {
    const section: SectionFragment = this._document.getDocumentInformationSection();
    const documentInformationTypeToFragment: Partial<Record<DocumentInformationType, DocumentInformationFragment>> =
      section.children.reduce(
        (
          returnMap: Partial<Record<DocumentInformationType, DocumentInformationFragment>>,
          currentClause: ClauseFragment
        ) => {
          const docInfo: DocumentInformationFragment = currentClause.children[0] as DocumentInformationFragment;
          returnMap[docInfo.documentInformationType] = docInfo;
          return returnMap;
        },
        {}
      );

    return fields.map((field) => {
      const fragment: DocumentInformationFragment = field.documentInformationType
        ? documentInformationTypeToFragment[field.documentInformationType]
        : null;

      return {
        documentInformationField: field,
        documentInformationFragment: fragment,
      };
    });
  }

  /**
   * Public getter for the current form group from the subject.
   *
   * @returns {FormGroup}   Current form group object
   */
  public get formGroup(): UntypedFormGroup {
    return this._documentValidationService.docInfoFormGroup.value;
  }

  /**
   * Public setter for the form group object.
   *
   * @param formGroup {FormGroup}   Form group to set
   */
  public set formGroup(formGroup: UntypedFormGroup) {
    this._documentValidationService.docInfoFormGroup.next(formGroup);
  }

  private _createFormGroup(): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this._formBuilder.group({});
    if (this.documentInformationItems) {
      this.documentInformationItems.forEach((item) => {
        if (item.documentInformationField.documentInformationType) {
          formGroup.addControl(item.documentInformationField.documentInformationType, this._formBuilder.group({}));
        }
      });
    }

    return formGroup;
  }
}
