import {Injectable} from '@angular/core';
import {OfflineDocument, OfflineUser} from 'app/offline/offline-document';
import idb, {Cursor, DB, Transaction, UpgradeDB} from 'idb';

enum ConnectionMode {
  READ_ONLY = 'readonly',
  READ_WRITE = 'readwrite',
}

type Repositories = 'offline-documents' | 'offline-users';

/**
 * The Repository class wraps indexedDB.
 *
 * @tparam {T} Should be a data object, since persisting an
 * object in IndexedDB will clear its prototype.
 */
export abstract class Repository<T> {
  private static readonly DATABASE_NAME: string = 'cars-offline-data';

  private static readonly VERSION_NUMBER: number = 1;

  protected static readonly connect: Promise<DB> = Repository._getConnect();

  protected abstract readonly name: Repositories;

  private static _getConnect(): Promise<DB> {
    return Repository.isAvailable()
      ? idb.open(Repository.DATABASE_NAME, Repository.VERSION_NUMBER, (migration: UpgradeDB) => {
          switch (migration.oldVersion) {
            // Migration scripts can be added here in the future.
            case 0: {
              migration.createObjectStore('offline-documents');
              migration.createObjectStore('offline-users');
            }
          }
        })
      : Promise.reject(null);
  }

  public static isAvailable(): boolean {
    return !!window.indexedDB && typeof window.indexedDB.open === 'function';
  }

  constructor() {}

  public get(key: string): Promise<T> {
    if (!Repository.isAvailable()) {
      return Promise.reject(null);
    }
    return Repository.connect.then((db: DB) => {
      return db.transaction(this.name, ConnectionMode.READ_ONLY).objectStore(this.name).get(key);
    });
  }

  public count(): Promise<number> {
    if (!Repository.isAvailable()) {
      return Promise.reject(0);
    }
    return Repository.connect.then((db: DB) => {
      return db.transaction(this.name, ConnectionMode.READ_ONLY).objectStore(this.name).count();
    });
  }

  public getAll(): Promise<T[]> {
    if (!Repository.isAvailable()) {
      return Promise.reject([]);
    }
    return Repository.connect.then((db: DB) => {
      return db.transaction(this.name, ConnectionMode.READ_ONLY).objectStore(this.name).getAll();
    });
  }

  public set(key: string, val: T): Promise<void> {
    if (!Repository.isAvailable()) {
      return Promise.reject(null);
    }
    return Repository.connect.then((db: DB) => {
      const tx: Transaction = db.transaction(this.name, ConnectionMode.READ_WRITE);
      tx.objectStore(this.name).put(val, key);
      return tx.complete;
    });
  }

  public setAll(entries: Map<string, T>): Promise<void> {
    if (!Repository.isAvailable()) {
      return Promise.reject(null);
    }
    return Repository.connect.then((db: DB) => {
      const tx: Transaction = db.transaction(this.name, ConnectionMode.READ_WRITE);
      entries.forEach((val: T, key: string) => {
        tx.objectStore(this.name).put(val, key);
      });
      return tx.complete;
    });
  }

  public delete(key: string): Promise<void> {
    if (!Repository.isAvailable()) {
      return Promise.reject(null);
    }
    return Repository.connect.then((db: DB) => {
      const tx: Transaction = db.transaction(this.name, ConnectionMode.READ_WRITE);
      tx.objectStore(this.name).delete(key);
      return tx.complete;
    });
  }

  public clear(): Promise<void> {
    if (!Repository.isAvailable()) {
      return Promise.reject(null);
    }
    return Repository.connect.then((db: DB) => {
      const tx: Transaction = db.transaction(this.name, ConnectionMode.READ_WRITE);
      tx.objectStore(this.name).clear();
      return tx.complete;
    });
  }

  public search(field: string, value: string | boolean | number): Promise<T[]> {
    if (!Repository.isAvailable()) {
      return Promise.reject([]);
    }
    return Repository.connect.then((db: DB) => {
      const tx: Transaction = db.transaction(this.name, ConnectionMode.READ_ONLY);
      const results: T[] = [];
      tx.objectStore(this.name).iterateCursor((cursor: Cursor) => {
        if (!cursor) {
          return;
        }
        if (cursor.value[field] && cursor.value[field] === value) {
          results.push(cursor.value);
        }
        cursor.continue();
      });

      return tx.complete.then(() => results);
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class DocumentRepository extends Repository<OfflineDocument> {
  protected name: Repositories = 'offline-documents';
}

@Injectable({
  providedIn: 'root',
})
export class UserRepository extends Repository<OfflineUser> {
  protected name: Repositories = 'offline-users';
}
