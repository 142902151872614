import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {FragmentService} from 'app/services/fragment.service';
import {ClauseFragment} from '../../fragment/types';
import {LinkRow} from './link-row';
import {JsonClauseRow, JsonRangeRow} from './link-table.service';
import {RangeRow} from './range-row';

export class ClauseRow {
  public show: boolean;
  public children: RangeRow[];

  public static deserialise(json: JsonClauseRow, fragmentService: FragmentService): ClauseRow {
    if (json.clause && json.children && json.sectionTitle) {
      const clause: ClauseFragment = <ClauseFragment>FragmentMapper.deserialise(json.clause);
      const clauseRow: ClauseRow = new ClauseRow(clause, json.sectionTitle);
      clauseRow.children = ClauseRow.deserialiseRangeRows(json.children, clauseRow, fragmentService);
      return clauseRow;
    } else {
      return null;
    }
  }

  private static deserialiseRangeRows(
    json: JsonRangeRow[],
    clauseRow: ClauseRow,
    fragmentService: FragmentService
  ): RangeRow[] {
    const rangeRows: RangeRow[] = [];
    json.forEach((rangeRow: JsonRangeRow) => {
      rangeRows.push(RangeRow.deserialise(rangeRow, clauseRow, fragmentService));
    });
    return rangeRows;
  }

  constructor(public clause: ClauseFragment, public sectionTitle: string) {
    this.clause = clause;
    this.sectionTitle = sectionTitle;
    this.show = true;
    this.children = [];
  }

  public shownChildren(): RangeRow[] {
    return this.children.filter((r: RangeRow) => r.show);
  }

  public filter(docFilter: string, typeFilter: string): void {
    this.children.forEach((r: RangeRow) => {
      r.children.forEach((l: LinkRow) => {
        // First, filter the link by document destination
        l.show =
          docFilter === 'ALL_DOCUMENTS' ? true : l.authoredRange && l.authoredRange.documentId.value === docFilter;
        // If the link is still being shown, filter by it's type.
        if (l.show && typeFilter !== 'ALL') {
          if (typeFilter === 'DELETED') {
            l.show = r.publishedRange.deleted;
          } else {
            l.show = l.link && l.link.type && l.link.type.valueOf() === typeFilter;
          }
        }
      });
      r.show = r.shownChildren().length > 0;
    });
    this.show =
      this.shownChildren().length > 0 ||
      (this.children.length === 0 &&
        docFilter === 'ALL_DOCUMENTS' &&
        (typeFilter === 'ALL' || typeFilter === 'NO_CHANGES'));
  }
}
