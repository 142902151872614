import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Lock} from 'app/fragment/lock/lock';
import {ClauseFragment} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {LockService} from 'app/services/lock.service';
import {UserService} from 'app/services/user/user.service';
import {User} from 'app/user/user';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'cars-locking-overlay',
  templateUrl: './locking-overlay.component.html',
  styleUrls: ['./locking-overlay.component.scss'],
})
export class LockingOverlayComponent implements OnInit, OnDestroy {
  private static readonly DEFAULT_COLOR = 'inherit';

  public readonly CarsAction: typeof CarsAction = CarsAction;

  @Input() public clause: ClauseFragment;

  public locked: boolean;

  public lockingUser: User;
  public initialsColor: string = LockingOverlayComponent.DEFAULT_COLOR;
  public overlayColor: string = LockingOverlayComponent.DEFAULT_COLOR;

  private _subscriptions: Subscription[] = [];

  constructor(private _lockService: LockService, private _userService: UserService, private _cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._lockService.onChange(
        (lock: Lock) => this._updateOverlay(lock),
        (lock: Lock) => lock && lock.clauseId.equals(this.clause.id)
      )
    );
    this._updateOverlay(this._lockService.getLock(this.clause));
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  private _updateOverlay(lock: Lock): void {
    this.locked = !this._lockService.canLock(this.clause);

    if (lock && this.locked) {
      this._userService
        .getUserFromId(lock.userId)
        .pipe(take(1))
        .subscribe((user: User) => {
          this._setLockingUser(user);
        });
    } else {
      this._setLockingUser(null);
    }
  }

  private _setLockingUser(user: User): void {
    this.initialsColor = this._getColor(user, 1);
    this.overlayColor = this._getColor(user, 0.05);
    this.lockingUser = user;
    this._cdr.markForCheck();
  }

  private _getColor(user: User, alpha: number): string {
    return user ? UserService.getUserColour(user, alpha) : LockingOverlayComponent.DEFAULT_COLOR;
  }
}
