import {Injectable, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {Suite} from 'app/fragment/suite';
import {
  ClauseFragment,
  ClauseType,
  DocumentFragment,
  Fragment,
  FragmentType,
  SectionFragment,
  SectionType,
} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {ClauseGroupType} from 'app/fragment/types/clause-group-type';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {SectionGroupType} from 'app/fragment/types/section-group-type';
import {ConfigurationService} from 'app/suite-config/configuration.service';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ClauseService} from './clause.service';
import {FragmentService} from './fragment.service';
import {LockService} from './lock.service';
import {SectionService} from './section.service';

interface SectionTypeCollection {
  sectionTypes: SectionType[];
  sectionGroupTypes: SectionGroupType[];
}

@Injectable({
  providedIn: 'root',
})
export class ReorderClausesService implements OnDestroy {
  private _subscriptions: Subscription[] = [];
  private _section: SectionFragment = null;

  private _reorderClausesSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _selectedClausesSubject: BehaviorSubject<Fragment[]> = new BehaviorSubject([]);
  private _lastSelected: Fragment = null;

  private _draggingClausesSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _mouseOutsidePadSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly _disallowedClauseAncestors: Readonly<Partial<Record<ClauseType, SectionTypeCollection>>> = {
    [ClauseType.SPECIFIER_INSTRUCTION]: {
      sectionTypes: [SectionType.INTRODUCTORY],
      sectionGroupTypes: [],
    },
  };

  private readonly _disallowedClauseGroupAncestors: Readonly<Partial<Record<ClauseGroupType, SectionTypeCollection>>> =
    {
      [ClauseGroupType.STANDARD_FORMAT_REQUIREMENT]: {
        sectionTypes: [SectionType.INTRODUCTORY],
        sectionGroupTypes: [],
      },
      [ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT]: {
        sectionTypes: [SectionType.INTRODUCTORY],
        sectionGroupTypes: [SectionGroupType.NATIONAL_DETERMINED_SECTION],
      },
    };

  constructor(
    private _sectionService: SectionService,
    private _lockService: LockService,
    private _fragmentService: FragmentService,
    private _configurationService: ConfigurationService,
    private _snackbar: MatSnackBar
  ) {
    this._subscriptions.push(
      this._sectionService.onSelection((section: SectionFragment) => {
        if (!section || !section.equals(this._section)) {
          this._reorderClausesSubject.next(false);
        }
        this._section = section;
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Changes the reordering state, and clears the selected clauses and clause groups if beginning reordering.
   *
   * @param value True if starting reordering, false if exiting
   */
  public triggerReorderingEvent(value: boolean): void {
    this._clearSelection();
    this._reorderClausesSubject.next(value);
  }

  /**
   * Observable of the current reordering state
   */
  public onReorderingEvent(): Observable<boolean> {
    return this._reorderClausesSubject.asObservable();
  }

  public onSelectedClausesChange(): Observable<Fragment[]> {
    return this._selectedClausesSubject.asObservable();
  }

  /**
   * Adds relevant clauses and clause groups to the selected clause list, with key modifiers.
   */
  public selectFragment(fragment: Fragment, event: MouseEvent): void {
    if (event.shiftKey) {
      const fragmentIndex: number = this._section.childIndexOf(fragment);
      const lastSelectedIndex: number = this._lastSelected
        ? this._section.childIndexOf(this._lastSelected)
        : fragmentIndex;
      const min: number = Math.min(lastSelectedIndex, fragmentIndex);
      const max: number = Math.max(lastSelectedIndex, fragmentIndex);

      for (let i = min; i <= max; i++) {
        this._toggleSelection(this._section.children[i], true);
      }
    } else if (this._selectedClausesSubject.getValue().length === 1 && fragment.equals(this._lastSelected)) {
      this._clearSelection();
    } else {
      if (!event.ctrlKey) {
        this._clearSelection();
      }

      this._toggleSelection(fragment, false);
    }

    this._lastSelected = fragment;
  }

  /**
   * Clears the selected clause list.
   */
  private _clearSelection(): void {
    this._lastSelected = null;
    this._selectedClausesSubject.getValue().forEach((fragment: Fragment) => {
      if (fragment.is(FragmentType.CLAUSE)) {
        this._lockService.unlock(fragment as ClauseFragment);
      } else {
        this._lockService.unlockGroup(fragment as ClauseGroupFragment);
      }
    });
    this._selectedClausesSubject.next([]);
  }

  /**
   * Toggle whether the given clause or clause group is in the reorder selection, if the provided fragment is not a clause it will
   * be ignored.
   *
   * @param fragment The fragment to toggle
   * @param additive True if the selection is additive
   */
  private _toggleSelection(fragment: Fragment, additive: boolean): void {
    if (!fragment.is(FragmentType.CLAUSE, FragmentType.CLAUSE_GROUP)) {
      return;
    }

    const canLock: boolean = fragment.is(FragmentType.CLAUSE)
      ? this._lockService.canLock(fragment as ClauseFragment)
      : this._lockService.canLockGroup(fragment as ClauseGroupFragment);

    const selected: Fragment[] = this._selectedClausesSubject.getValue();

    if (!this.isSelected(fragment) && canLock) {
      selected.push(fragment);
      this._selectedClausesSubject.next(selected);
      this._sortSelection();

      if (fragment.is(FragmentType.CLAUSE)) {
        this._lockService.lock(fragment as ClauseFragment);
      } else {
        this._lockService.lockGroup(fragment as ClauseGroupFragment);
      }
    } else if (this.isSelected(fragment) && !additive) {
      selected.splice(
        selected.findIndex((c: Fragment) => c.equals(fragment)),
        1
      );
      this._selectedClausesSubject.next(selected);

      if (fragment.is(FragmentType.CLAUSE)) {
        this._lockService.unlock(fragment as ClauseFragment);
      } else {
        this._lockService.unlockGroup(fragment as ClauseGroupFragment);
      }
    }
  }

  /**
   * Sorts the selected clauses by weight
   */
  private _sortSelection(): void {
    this._selectedClausesSubject.next(
      this._selectedClausesSubject.getValue().sort((a: Fragment, b: Fragment) => a.weight - b.weight)
    );
  }

  /**
   * Returns the list of selected clauses and clause groups for reordering.
   */
  public getSelection(): Fragment[] {
    return this._selectedClausesSubject.getValue();
  }

  /**
   * Returns true if the given clause or clause group is in the reorder selection and reordering is in progress.
   *
   * @param fragment {Fragment}         The clause or clause group to check
   * @returns        {boolean}          True if in selection
   */
  public isSelected(fragment: Fragment): boolean {
    return this._reorderClausesSubject.value && this._selectedClausesSubject.getValue().indexOf(fragment) >= 0;
  }

  /**
   * Changes the dragging state.
   */
  public triggerDraggingEvent(value: boolean): void {
    this._draggingClausesSubject.next(value);
  }

  /**
   * Observable of the current dragging state.
   */
  public onDraggingEvent(): Observable<boolean> {
    return this._draggingClausesSubject.asObservable();
  }

  /**
   * Sets whether the user has dragged outside the pad.
   * This gets around an issue if the user drags outside the pad before the dragging delay is done.
   */
  public triggerMouseOutsidePadEvent(value: boolean): void {
    this._mouseOutsidePadSubject.next(value);
  }

  /**
   * Observable of tracking if the user has dragged outside the pad.
   */
  public onDraggedOutsidePadEvent(): Observable<boolean> {
    return combineLatest([
      this._draggingClausesSubject.asObservable(),
      this._mouseOutsidePadSubject.asObservable(),
    ]).pipe(map(([dragging, outsidePad]: [boolean, boolean]) => dragging && outsidePad));
  }

  /**
   * Moves the selected clauses and clause groups to be after the given fragment within the same section.
   *
   * @param targetDropFragment The fragment to insert the selection after
   */
  public moveClausesInSection(targetDropFragment: Fragment): void {
    const selected: Fragment[] = this._selectedClausesSubject.getValue();
    const originalPositions = this._section.children.slice(0);

    const dropInSamePlace: boolean = selected[0].equals(targetDropFragment);
    let index: number = selected[0].index() - 1;

    if (!targetDropFragment && this._section.children[0].weight === Fragment._WEIGHT_MIN) {
      Fragment.inferWeights([this._section.children[0]]);

      this._fragmentService.update(this._section.children[0]);
    }

    selected.forEach((fragment: Fragment) => {
      fragment.remove();
    });

    if (!dropInSamePlace) {
      index = targetDropFragment ? targetDropFragment.index() : -1;
    }

    try {
      selected.forEach((fragment: Fragment, i: number) => {
        this._section.children.splice(index + i + 1, 0, fragment);
      });
      Fragment.inferWeights(selected);

      this._fragmentService.update(selected);
    } catch (e) {
      const error: Error = e;

      // Move clauses back to original positions
      selected.forEach((fragment: Fragment) => {
        this._section.children.splice(fragment.index(), 1);
      });
      selected.forEach((fragment: Fragment) => {
        this._section.children.splice(originalPositions.indexOf(fragment), 0, fragment);
      });

      // Reset weights which may have been updated
      Fragment.inferWeights(selected);
      this._fragmentService.update(selected);

      this._snackbar.open(error.message, 'Dismiss');
    }
  }

  public isValidMoveToNewSection(section: SectionFragment): boolean {
    if (section.isSectionOfType(SectionType.REFERENCE_INFORM, SectionType.REFERENCE_NORM)) {
      return false;
    }

    const sectionType: SectionType = section.sectionType;
    const sectionGroupType: SectionGroupType = (
      section.findAncestorWithType(FragmentType.SECTION_GROUP) as SectionGroupFragment
    )?.sectionGroupType;

    return this._selectedClausesSubject.getValue().every((fragment: Fragment) => {
      if (!fragment.documentId.equals(section.documentId)) {
        return false;
      }

      let ancestorTypeCollection: SectionTypeCollection;

      if (fragment.is(FragmentType.CLAUSE)) {
        ancestorTypeCollection = this._disallowedClauseAncestors[(fragment as ClauseFragment).clauseType];
      } else if (fragment.is(FragmentType.CLAUSE_GROUP)) {
        ancestorTypeCollection =
          this._disallowedClauseGroupAncestors[(fragment as ClauseGroupFragment).clauseGroupType];
      }

      return (
        !ancestorTypeCollection ||
        !(
          ancestorTypeCollection.sectionTypes.includes(sectionType) ||
          ancestorTypeCollection.sectionGroupTypes.includes(sectionGroupType)
        )
      );
    });
  }

  /**
   * Puts the selected clauses and clause groups at the bottom of the target section and clears the selected clauses.
   * The section must first be loaded to ensure that the child clauses are present when working out the new weight
   * and the clauses get correctly inserted at the end.
   */
  public moveClausesToSection(section: SectionFragment): Promise<void> {
    return this._sectionService.load(section.id, {projection: 'FULL_TREE'}).then(() => {
      const toUpdate: Fragment[] = [];
      const selected: Fragment[] = this._selectedClausesSubject.getValue();

      if (!selected || selected.length === 0) {
        return;
      }

      // This guard is to stop clauses which have not been hooked up to their children
      // being moved between sections, as this would break the data integrity of the tree structure.
      // This shouldn't ever happen but seems to have at least once: see CF-573
      for (let i = 0; i < selected.length; i++) {
        const fragment: Fragment = selected[i];
        if (!fragment.children.length) {
          this._snackbar.open('Failed to perform operation, please contact support to resolve the problem', 'Dismiss');
          Logger.error('fragment-error', 'Tried to move clauses between section, but clause children were not loaded.');
          return;
        }
      }

      this._ensureSourceSectionNotEmpty(selected);

      const suite: Suite = (section.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment)?.suite;
      selected.forEach((fragment: Fragment) => {
        fragment.remove();
        if (fragment.is(FragmentType.CLAUSE)) {
          const clause: ClauseFragment = fragment as ClauseFragment;
          clause.clauseType = ClauseService.getClauseTypeForNewSection(section.sectionType, clause.clauseType, suite);
        }
        section.children.push(fragment);
        fragment.inferWeight(true);
        fragment.iterateDown(null, null, (f: Fragment) => {
          f.sectionId = section.id;
          toUpdate.push(f);
        });
      });

      this._fragmentService.update(toUpdate);

      this._clearSelection();
    });
  }

  /**
   * Creates a new clause in the source section if all clauses are being moved out. Ensures that the source section
   * isn't left in an uneditable state without a clause.
   */
  private _ensureSourceSectionNotEmpty(selected: Fragment[]): void {
    const sourceSection: SectionFragment = selected[0].findAncestorWithType(FragmentType.SECTION) as SectionFragment;

    if (sourceSection.children.length === selected.length) {
      this._configurationService.getDefaultClauseTypeForSectionId(sourceSection.id).then((clauseType: ClauseType) => {
        const newClause: ClauseFragment = ClauseFragment.empty(clauseType);
        sourceSection.children.push(newClause);

        this._fragmentService.create(newClause);
      });
    }
  }
}
