import {Serialisable} from 'app/utils/serialisable';
import {UUID} from 'app/utils/uuid';
import {ClauseLinkType} from './clause-link-type';
import {FragmentLinkType} from './fragment-link-type';

export class FragmentLink implements Serialisable {
  public static deserialise(json: any): FragmentLink {
    return new FragmentLink(
      UUID.orThrow(json.id),
      UUID.orThrow(json.linkId),
      UUID.orThrow(json.fragmentId),
      UUID.orThrow(json.targetFragmentId),
      FragmentLinkType[json.fragmentLinkType as string],
      ClauseLinkType[json.clauseLinkType as string],
      json.validFrom,
      json.validTo,
      UUID.orThrow(json.createdBy),
      json.lastModifiedAt,
      UUID.orThrow(json.lastModifiedBy)
    );
  }

  constructor(
    public id: UUID,
    public linkId: UUID,
    public fragmentId: UUID,
    public targetFragmentId: UUID,
    public fragmentLinkType: FragmentLinkType,
    public clauseLinkType: ClauseLinkType,
    public validFrom: number,
    public validTo: number,
    public createdBy: UUID,
    public lastModifiedAt: number,
    public lastModifiedBy: UUID
  ) {}

  public serialise(): any {
    return {
      id: this.id ? this.id.value : null,
      linkId: this.linkId ? this.linkId.value : null,
      fragmentId: this.fragmentId ? this.fragmentId.value : null,
      targetFragmentId: this.targetFragmentId ? this.targetFragmentId.value : null,
      fragmentLinkType: this.fragmentLinkType,
      clauseLinkType: this.clauseLinkType,
      validFrom: this.validFrom,
      validTo: this.validTo,
      createdBy: this.createdBy ? this.createdBy.value : null,
      lastModifiedAt: this.lastModifiedAt,
      lastModifiedBy: this.lastModifiedBy ? this.lastModifiedBy.value : null,
    };
  }
}
