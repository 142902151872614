<div class="header">
  <a routerLink="/" class="logo" id="logo" carsAnalytics="highways-england-logo">
    <img src="/assets/national-highways-cars.svg" alt="CARS Home" />
  </a>
  <cars-alt-accessibility
    class="logo"
    [altProperties]="{initialKey: 'h', key: 'd', condition: true}"
    (onSelect)="handleAltSelection('logo')"
  ></cars-alt-accessibility>
  <div class="title">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <a
        mat-button
        class="breadcrumb-button"
        color="{{ last ? 'primary' : '' }}"
        [routerLink]="breadcrumb.link"
        matTooltip="{{ breadcrumb.title }}"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
        [attr.aria-label]="breadcrumb.title + '.'"
        carsAnalytics="breadcrumb"
        >{{ breadcrumb.title }}</a
      >
      <mat-icon *ngIf="!last">chevron_right</mat-icon>
    </ng-container>
  </div>
  <div class="info">
    <div>
      <button
        mat-button
        [matMenuTriggerFor]="notificationMenu"
        #notificationMenuTrigger="matMenuTrigger"
        (menuOpened)="notificationArea.markAllAsSeen()"
        (menuClosed)="notificationArea.markAllAsSeen()"
        aria-label="notifications"
      >
        Notifications
        <mat-icon>notifications_none</mat-icon>
        <span *ngIf="unseenNotifications > 0" class="dot">{{ unseenNotifications }}</span>
      </button>
      <mat-progress-bar *ngIf="notificationArea.hasItemInProgress" mode="indeterminate"></mat-progress-bar>
    </div>
    <a
      href="https://help.futuredmrb.co.uk"
      target="_blank"
      mat-button
      aria-label="Open the CARS help pages"
      id="wiki-link"
      carsAnalytics="help-pages"
    >
      Help Pages
      <cars-alt-accessibility
        class="menu"
        [altProperties]="{initialKey: 'h', key: 'w', condition: true}"
        (onSelect)="handleAltSelection('wiki-link')"
      ></cars-alt-accessibility>
    </a>
    <button
      mat-button
      id="feedback"
      (click)="feedback()"
      *ngIf="!offline"
      carsAnalytics="contact-support"
      aria-label="Contact Support"
    >
      Contact Support
      <cars-alt-accessibility
        class="menu"
        [altProperties]="{initialKey: 'h', key: 'c', condition: !offline}"
        (onSelect)="handleAltSelection('feedback')"
      ></cars-alt-accessibility>
    </button>
    <button
      *ngIf="canUseOffline"
      mat-button
      [matMenuTriggerFor]="offlineMenu"
      #offlineMenuTrigger="matMenuTrigger"
      (focus)="openOfflineMenu()"
      [attr.aria-label]="offline ? 'Offline' : 'Online'"
    >
      {{ offline ? 'Off' : 'On' }}line<mat-icon>expand_more</mat-icon>
      <cars-alt-accessibility
        class="menu"
        [altProperties]="{
          initialKey: 'h',
          key: 'o',
          condition: canUseOffline
        }"
        (onSelect)="openOfflineMenu()"
      ></cars-alt-accessibility>
    </button>
    <button
      mat-button
      [matMenuTriggerFor]="userMenu"
      #userMenuTrigger="matMenuTrigger"
      *ngIf="!offline"
      (focus)="openUserMenu()"
      aria-label="User menu"
    >
      <cars-user [user]="user" [hoverTip]="false"></cars-user>
      <mat-icon>expand_more</mat-icon>
      <cars-alt-accessibility
        class="menu"
        [altProperties]="{initialKey: 'h', key: 'u', condition: !offline}"
        (onSelect)="openUserMenu()"
      ></cars-alt-accessibility>
    </button>
  </div>
  <cars-alt-accessibility
    class="initial"
    [altProperties]="{initialKey: 'h', key: null, condition: true}"
  ></cars-alt-accessibility>
</div>

<mat-menu #userMenu="matMenu">
  <a mat-menu-item [href]="accountUrl" carsAnalytics="account-management">Account management</a>
  <ng-container *ngIf="adminUser">
    <a mat-menu-item [href]="userManagementUrl">User management</a>
    <a mat-menu-item routerLink="/locations">User locations</a>
    <a mat-menu-item routerLink="/logs">User logs</a>
    <a mat-menu-item routerLink="/statistics">General Statistics</a>
    <a mat-menu-item routerLink="/references">Manage References</a>
  </ng-container>
  <button mat-menu-item (click)="$event.stopPropagation()" (keydown.enter)="onKeyboardEnter()">
    <span>Show accessibility focus </span>
    <mat-slide-toggle
      color="primary"
      (change)="onToggle($event)"
      aria-label="toggle accessibility focus'"
      [checked]="accessibilityToggleChecked"
    ></mat-slide-toggle>
  </button>
  <a mat-menu-item [href]="logoutUrl" (click)="forceLogout()" carsAnalytics="logout">Logout</a>
</mat-menu>

<mat-menu #offlineMenu="matMenu">
  <a
    mat-menu-item
    (click)="setOffline(!offline)"
    (keyup.enter)="setOffline(!offline)"
    carsAnalytics="{{ offline ? 'go-online' : 'go-offline' }}"
    [attr.aria-label]="'go' + offline ? 'Offline' : 'Online'"
    >Go {{ offline ? 'on' : 'off' }}line</a
  >
</mat-menu>

<mat-menu #notificationMenu="matMenu" [overlapTrigger]="false" class="notification-area">
  <cars-notification-area
    #notificationArea
    (numberUnseenNotifications)="onUpdateUnseen($event)"
  ></cars-notification-area>
</mat-menu>
