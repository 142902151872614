import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RootFragment} from 'app/fragment/types';
import {OfflineContext} from 'app/offline/offline-context';
import {OfflinePersistenceService} from 'app/offline/offline-persistence.service';
import {HttpStatus} from 'app/utils/http-status';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {from, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfflineInterceptor implements HttpInterceptor {
  private static readonly FRAGMENT_URL: string = `${environment.apiHost}/fragments`;
  private static readonly DISCUSSIONS_URL: string = `${environment.apiHost}/discussions`;
  private static readonly VERSION_TAG_URL: string = `${environment.apiHost}/versions`;
  private static readonly VERSIONS_OF_FRAGMENTS_URL: string = `${environment.apiHost}/versions/search`;
  private static readonly ASSETS_URL: string = `/assets/`;

  constructor(private offlinePersistenceService: OfflinePersistenceService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (LocalConfigUtils.getConfig().offline) {
      if (req.url.startsWith(OfflineInterceptor.FRAGMENT_URL)) {
        return this.handleFragmentRequest(req);
      } else if (req.url.startsWith(OfflineInterceptor.DISCUSSIONS_URL)) {
        return this.handleDiscussionRequest(req);
      } else if (req.url.startsWith(OfflineInterceptor.VERSIONS_OF_FRAGMENTS_URL)) {
        return this.handleVersionsOfFragmentsRequest(req);
      } else if (req.url.startsWith(OfflineInterceptor.VERSION_TAG_URL)) {
        return this.handleVersionTagRequest(req);
      } else if (req.url.startsWith(OfflineInterceptor.ASSETS_URL)) {
        return next.handle(req); // Allow through
      } else {
        return this.defaultResponse(req);
      }
    } else {
      return next.handle(req);
    }
  }

  private handleFragmentRequest(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    switch (req.method) {
      case 'GET': {
        const fragmentId: UUID = this.getUUIDsFromURL(req.url)[0];
        const versionId: UUID = OfflineContext.getVersionId();
        const depth: number = parseInt(req.params.get('depth'), 10);
        if (RootFragment.ID.equals(fragmentId)) {
          return from(
            this.offlinePersistenceService.fetchRoot().then((json: any) => new HttpResponse({body: json, status: 200}))
          );
        } else if (fragmentId && OfflineContext.getDocumentId()) {
          return from(
            this.offlinePersistenceService
              .fetchFragments(OfflineContext.getDocumentId(), versionId, fragmentId, depth)
              .then((json: any) => new HttpResponse({body: json, status: 200}))
          );
        } else {
          return this.errorResponse();
        }
      }
      default: {
        return this.errorResponse();
      }
    }
  }

  private handleVersionTagRequest(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    switch (req.method) {
      case 'GET': {
        const versionId: UUID = this.getUUIDsFromURL(req.url)[0];
        if (OfflineContext.getDocumentId() && versionId) {
          OfflineContext.setVersionId(versionId);
          return from(
            this.offlinePersistenceService
              .fetchVersionTag(OfflineContext.getDocumentId(), versionId)
              .then((json: any) => new HttpResponse({body: json, status: 200}))
          );
        } else {
          return this.errorResponse();
        }
      }
      default: {
        return this.errorResponse();
      }
    }
  }

  private handleVersionsOfFragmentsRequest(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    switch (req.method) {
      case 'GET': {
        if (OfflineContext.getDocumentId()) {
          return from(
            this.offlinePersistenceService.fetchVersionsOfDocument(OfflineContext.getDocumentId()).then(
              (json: any[]) =>
                new HttpResponse({
                  body: {_embedded: {versionTags: json}},
                  status: 200,
                })
            )
          );
        } else {
          return this.errorResponse();
        }
      }
      default: {
        return this.errorResponse();
      }
    }
  }

  private handleDiscussionRequest(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    switch (req.method) {
      case 'GET': {
        if (OfflineContext.getVersionId() && OfflineContext.getDocumentId()) {
          return from(
            this.offlinePersistenceService
              .fetchDiscussions(OfflineContext.getDocumentId(), OfflineContext.getVersionId())
              .then((json: any[]) => new HttpResponse({body: json, status: 200}))
          );
        } else {
          return this.errorResponse();
        }
      }
      case 'POST': {
        // TODO HED-1471: handle this
        return this.errorResponse();
      }
      default: {
        return this.errorResponse();
      }
    }
  }

  private getUUIDsFromURL(url: string): UUID[] {
    if (!url) {
      return [];
    }
    return url
      .split('/')
      .map((segment: string) => UUID.orNull(segment))
      .filter((u) => !!u);
  }

  private errorResponse(): Observable<HttpEvent<any>> {
    return of(new HttpResponse({status: HttpStatus.NOT_FOUND}));
  }

  private defaultResponse(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    switch (req.method) {
      case 'GET': {
        return of(new HttpResponse({status: HttpStatus.OK, body: []}));
      }
      case 'POST':
      case 'PUT':
      case 'PATCH':
      case 'DELETE':
      default: {
        return of(new HttpResponse({status: HttpStatus.OK}));
      }
    }
  }
}
