import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {Logger} from './services/logger/logger.service';

@Directive({
  selector: '[carsAnalytics]',
})
export class AnalyticsDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('carsAnalytics') key: string;

  constructor(private el: ElementRef) {}

  @HostListener('mousedown')
  onMouseDown() {
    if (this.el.nativeElement.nodeName !== 'INPUT') {
      Logger.analytics(this.key, 'mouse');
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      Logger.analytics(this.key, 'keyboard');
    }
  }
}
