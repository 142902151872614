import {suiteToIndexer} from 'app/fragment/indexing/fragment-index.service';
import {ClauseFragment, DocumentFragment, Fragment, FragmentType} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {UUID} from '../../utils/uuid';
import {ChangelogLink} from '../changelog-link';
import {ChangelogRange} from '../changelog-range';
import {JsonLinkRow} from './link-table.service';
import {RangeRow} from './range-row';

export interface RangeLocationInformation {
  documentId: UUID;
  sectionId: UUID;
  documentTitle: string;
  sectionTitle: string;
  startClauseIndex: string;
  endClauseIndex: string;
}

export class LinkRow {
  public id: UUID;
  public show: boolean;
  public linkComment: string;
  public locationInformation: RangeLocationInformation;

  public static deserialise(json: JsonLinkRow, parent: RangeRow, fragmentService: FragmentService): LinkRow {
    const range: ChangelogRange = json.authoredRange
      ? ChangelogRange.deserialise(json.authoredRange, fragmentService, null)
      : null;
    const sectionTitle: string = range ? fragmentService.find(range.sectionId).value : null;
    return new LinkRow(parent, ChangelogLink.deserialise(json.link), range, sectionTitle);
  }

  constructor(
    public parentRangeRow: RangeRow,
    public link: ChangelogLink,
    public authoredRange: ChangelogRange,
    public sectionTitle: string
  ) {
    this.parentRangeRow = parentRangeRow;
    this.authoredRange = authoredRange;
    this.link = link;
    this.id = UUID.random();
    this.show = true;

    if (this.isValidStartAndEndFragments(authoredRange)) {
      const startClause: ClauseFragment = authoredRange.start.fragment.findAncestorWithType(
        FragmentType.CLAUSE
      ) as ClauseFragment;
      const endClause: ClauseFragment = authoredRange.end.fragment.findAncestorWithType(
        FragmentType.CLAUSE
      ) as ClauseFragment;

      const document: DocumentFragment = authoredRange.start.fragment.findAncestorWithType(
        FragmentType.DOCUMENT
      ) as DocumentFragment;

      const lookup: Record<string, string> = suiteToIndexer[document.suite](document);

      this.locationInformation = {
        documentId: authoredRange.documentId,
        sectionId: authoredRange.sectionId,
        documentTitle: authoredRange.documentTitle,
        sectionTitle: sectionTitle,
        startClauseIndex: lookup[startClause.id.value],
        endClauseIndex: lookup[endClause.id.value],
      };
      this.linkComment = authoredRange ? link.comment : parentRangeRow.publishedRange.deletionComment;
    } else {
      this.locationInformation = {
        documentId: null,
        sectionId: null,
        documentTitle: 'n/a',
        sectionTitle: 'n/a',
        startClauseIndex: 'n/a',
        endClauseIndex: 'n/a',
      };
      this.linkComment = authoredRange
        ? `There was an error with this link (${link.comment}), a clause may have been moved out of its original section.`
        : parentRangeRow.publishedRange.deletionComment;
    }
  }

  public isFirstShownChild(): boolean {
    const shownSiblings: LinkRow[] = this.parentRangeRow.shownChildren();
    return this.show && shownSiblings.length > 0 && shownSiblings[0].id.equals(this.id);
  }

  private isValidStartAndEndFragments(authoredRange: ChangelogRange): boolean {
    if (authoredRange && authoredRange.start.fragment && authoredRange.end.fragment) {
      const commonAncestor: Fragment = Fragment.commonAncestorOf(
        authoredRange.start.fragment,
        authoredRange.end.fragment
      );
      if (commonAncestor && commonAncestor.type !== FragmentType.DOCUMENT) {
        return true;
      }
    }
  }
}
