import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FragmentModule} from 'app/fragment/fragment.module';
import {UserModule} from 'app/widgets/user/user.module';
import {VersionComponent} from './version.component';

@NgModule({
  imports: [CommonModule, UserModule, FragmentModule],
  declarations: [VersionComponent],
  exports: [VersionComponent],
})
export class VersionModule {}
