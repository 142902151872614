import {Component, Input} from '@angular/core';
import {Transition} from 'app/sidebar/history/history.component';

@Component({
  selector: 'cars-transition',
  templateUrl: './transition.component.html',
  styleUrls: ['./transition.component.scss'],
})
export class TransitionComponent {
  @Input() public transition: Transition;
  @Input() public dateFormat: string;

  constructor() {}
}
