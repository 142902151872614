export const environment = {
  production: true,
  apiHost: '/api',
  tooltipDelay: 500,
  debounceTime: 500,
  undoSize: 128,
  language: 'en_GB',
  spellCheckDebounce: 1000,
  validationInterval: 100,
  jiraUrl: 'https://highwaysengland.atlassian.net/browse/',
  supportEmail: 'IIG_CARS@caci.co.uk',
  authenticationHost: null,
  authenticationRealm: null,
  systemUserId: '1f524e3f-62db-4865-bcde-ef1cce07ba94',
};
