import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {DocumentOverviewSectionComponent} from './document-overview-section.component';
import {MchwExportSelectorModule} from './mchw-export-selector/mchw-export-selector.module';
import {NaaExportSelectorModule} from './naa-export-selector/naa-export-selector.module';
import {SectionCloneModule} from './section-clone-selector/section-clone.module';
import {VersionCreatorModule} from './version-creator/version-creator.module';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatTooltipModule,
    MatMenuModule,
    CarsErrorHandlingModule,
    VersionCreatorModule,
    NaaExportSelectorModule,
    AltAccessibilityModule,
    CarsPermissionPipesModule,
    MchwExportSelectorModule,
    SectionCloneModule,
  ],
  declarations: [DocumentOverviewSectionComponent],
  exports: [DocumentOverviewSectionComponent],
})
export class DocumentOverviewSectionModule {}
