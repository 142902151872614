import {CarsRange} from 'app/fragment/cars-range';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {Div} from './div/divs';

interface DifferenceRect {
  readonly top: number;
  readonly left: number;
  readonly bottom: number;
  readonly right: number;
}

export class DrawableRange {
  public isRendered: boolean = false;
  public renderedView: Div[] = [];
  private _startBoundingRect: DifferenceRect;
  private _endBoundingRect: DifferenceRect;

  constructor(public range: CarsRange, offsetClientRect: DOMRect) {
    this.updateBoundingRects(offsetClientRect);
  }

  /**
   * Update the stored versions of the bounding rects.  These are used to check for when the range
   * needs redrawing.
   */
  public updateBoundingRects(offsetClientRect: DOMRect): void {
    if (!offsetClientRect) {
      return;
    }
    const startComponent: FragmentComponent = this.range.start.fragment.component;
    const endComponent: FragmentComponent = this.range.end.fragment.component;
    this._startBoundingRect = startComponent
      ? this._subtract(startComponent.element.getBoundingClientRect(), offsetClientRect)
      : null;
    this._endBoundingRect = endComponent
      ? this._subtract(endComponent.element.getBoundingClientRect(), offsetClientRect)
      : null;
  }

  /**
   * Informs the drawing layer that this range should be re-drawn.
   *
   * If it has not
   * been drawn yet, returns true.  If the bounding rectangle of the start or end element
   * has changed since the last cycle, return true.  If either the start or end component
   * are null, return false.  All other cases return false.
   */
  public needsReRendering(offsetClientRect: DOMRect): boolean {
    if (!this.isRendered && !this.needsClearing()) {
      return true;
    }
    const startComponent: FragmentComponent = this.range.start.fragment.component;
    const endComponent: FragmentComponent = this.range.end.fragment.component;
    if (startComponent && endComponent) {
      const start: DifferenceRect = this._subtract(startComponent.element.getBoundingClientRect(), offsetClientRect);
      const end: DifferenceRect = this._subtract(endComponent.element.getBoundingClientRect(), offsetClientRect);
      return !this._rectsAreEqual(start, this._startBoundingRect) || !this._rectsAreEqual(end, this._endBoundingRect);
    } else {
      return false;
    }
  }

  /**
   * @returns true if the range should no longer be displayed, which equates to if
   * either its start or end fragment component is null.
   */
  public needsClearing(): boolean {
    const startComponent: FragmentComponent = this.range.start.fragment.component;
    const endComponent: FragmentComponent = this.range.end.fragment.component;
    return !startComponent || !endComponent;
  }

  private _rectsAreEqual(r1: DifferenceRect, r2: DifferenceRect): boolean {
    return (
      Math.abs(r1.top - r2.top) < 1 &&
      Math.abs(r1.left - r2.left) < 1 &&
      Math.abs(r1.bottom - r2.bottom) < 1 &&
      Math.abs(r1.right - r2.right) < 1
    );
  }

  /**
   * Subtracts r2 from r1.
   *
   * @param r1 {DOMRect} Left hand side of operation.
   * @param r2 {DOMRect} Right hand side of operation.
   *
   * @returns  {DOMRect} The result of the operation.
   */
  private _subtract(r1: DOMRect, r2: DOMRect): DifferenceRect {
    return {
      top: r1.top - r2.top,
      left: r1.left - r2.left,
      bottom: r1.bottom - r2.bottom,
      right: r1.right - r2.right,
    };
  }
}
