import {Serialisable} from 'app/utils/serialisable';
import {UUID} from 'app/utils/uuid';

export type ChangelogLinkType = 'TECHNICAL' | 'EDITORIAL' | 'TECHNICAL_AND_EDITORIAL';

export class ChangelogLink implements Serialisable {
  public static deserialise(json: any): ChangelogLink {
    return new ChangelogLink(
      UUID.orNull(json.id),
      UUID.orNull(json.publishedRangeId),
      UUID.orNull(json.authoredRangeId),
      json.comment,
      json.type
    );
  }

  constructor(
    public id: UUID = null,
    public publishedRangeId: UUID,
    public authoredRangeId: UUID,
    public comment: string,
    public type: ChangelogLinkType
  ) {}

  public serialise(): any {
    return {
      id: this.id ? this.id.value : null,
      publishedRangeId: this.publishedRangeId.value,
      authoredRangeId: this.authoredRangeId.value,
      comment: this.comment,
      type: this.type,
    };
  }
}
