import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {VersionDiscussionsAggregate} from 'app/dashboard/aggregates/version-discussions-aggregate';
import {DashboardService} from 'app/services/dashboard.service';
import {
  ReviewState,
  ReviewStateService,
  ReviewStatusProperties,
  UserReviewState,
} from 'app/services/review-state.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-version-aggregate',
  templateUrl: './version-aggregate.component.html',
  styleUrls: ['./version-aggregate.component.scss'],
})
export class VersionAggregateComponent implements OnInit, OnDestroy {
  @Input('versionDiscussionAggregate') set versionDiscussionAggregate(value: VersionDiscussionsAggregate) {
    this.versionAggregate = value;
    if (value) {
      this.discussionString = this._getResolvedString(value.resolvedDiscussions, value.discussions, false);
      this.commentString = value.isByUser()
        ? this._getResolvedString(value.resolvedDiscussionsCommentedOn, value.discussionsCommentedOn, true)
        : '';
      this._setVersionedToolTip(value);
      this._setReviewStateProperties(value);
    }
  }

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public isSelected: boolean = false;

  public versionAggregate: VersionDiscussionsAggregate;

  public reviewStateDisplayProperties: ReviewStatusProperties;

  public outstandingReviewString: string;
  public completeReviews: number;
  public totalReviews: number;

  public discussionString: string;
  public commentString: string;
  public versionTooltip: string;

  private _subscriptions: Subscription[] = [];

  constructor(private _dashboardService: DashboardService, private _reviewStateService: ReviewStateService) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._dashboardService.onVersionAggregateSelect((selectedAggregate: VersionDiscussionsAggregate) =>
        this._setSelected(selectedAggregate)
      )
    );

    if (this.versionAggregate.isByUser() && !this.versionAggregate.isLive()) {
      this._subscriptions.push(
        this._reviewStateService.onReviewStateSave((reviewState: UserReviewState) => {
          if (this.versionAggregate.isByUser()) {
            this.versionAggregate.userReviewState = reviewState;
            if (reviewState) {
              this.reviewStateDisplayProperties = UserReviewState.DISPLAY_PROPERTIES[reviewState.reviewState];
            }
          }
        }, this.versionAggregate.versionId)
      );
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  public select(event: Event): void {
    this._dashboardService.selectVersionAggregate(this.versionAggregate);
    this.isSelected = true;
    event.stopPropagation();
  }

  private _setSelected(selectedAggregate: VersionDiscussionsAggregate): void {
    if (!selectedAggregate || !selectedAggregate.documentId.equals(this.versionAggregate.documentId)) {
      this.isSelected = false;
      return;
    }

    this.isSelected = selectedAggregate.versionId
      ? selectedAggregate.versionId.equals(this.versionAggregate.versionId)
      : !this.versionAggregate.versionId;
  }

  private _getResolvedString(resolved: number, all: number, comment: boolean): string {
    if (all === 0) {
      return `No ${comment ? 'comments' : 'discussions'}`;
    } else if (all === resolved) {
      return 'All resolved';
    }
    return `${resolved}/${all} resolved`;
  }

  private _setVersionedToolTip(versionAggregate: VersionDiscussionsAggregate): void {
    this.versionTooltip =
      'Versioned by: ' +
      versionAggregate.versionCreatedBy +
      '\nCreated at: ' +
      new Date(versionAggregate.versionCreatedAt).toDateString();
  }

  private _setReviewStateProperties(versionAggregate: VersionDiscussionsAggregate): void {
    if (versionAggregate.isLive()) {
      return;
    }

    if (versionAggregate.isByUser()) {
      if (versionAggregate.userReviewState) {
        this.reviewStateDisplayProperties =
          UserReviewState.DISPLAY_PROPERTIES[versionAggregate.userReviewState.reviewState];
      }
    } else if (versionAggregate.isAll()) {
      this.totalReviews = versionAggregate.userReviewStates ? versionAggregate.userReviewStates.length : 0;
      if (this.totalReviews) {
        this.completeReviews = versionAggregate.userReviewStates.filter(
          (userReviewState: UserReviewState) => userReviewState.reviewState === ReviewState.COMPLETE
        ).length;
        this.outstandingReviewString = `${this.completeReviews}/${this.totalReviews} reviews completed`;
      } else {
        this.outstandingReviewString = 'No reviewers';
      }
    }
  }
}
