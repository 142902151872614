import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Fragment} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[carsShowOnFocus]',
})
export class ShowOnFocusDirective implements OnDestroy, OnInit {
  @Input('carsShowOnFocus') public content: Fragment;

  private _fragmentIsCaptioned: boolean = false;
  private _fragmentSelected: boolean = false;
  private _reordering: boolean = false;

  private readonly _subscriptions: Subscription[] = [];

  constructor(
    private _elementRef: ElementRef,
    private _reorderClausesService: ReorderClausesService,
    private _fragmentService: FragmentService
  ) {
    this._setVisibility();
  }

  public ngOnInit(): void {
    this._fragmentIsCaptioned = this.content.isCaptioned();

    this._subscriptions.push(
      this._fragmentService.onSelection((fragment: Fragment) => {
        this._handleFragment(fragment);
      }),
      this._fragmentService.onUpdate(() => {
        const fragment: Fragment = this._fragmentService.getSelected();
        this._handleFragment(fragment);
      }),
      this._reorderClausesService.onReorderingEvent().subscribe((reordering: boolean) => {
        this._reordering = reordering;
        if (reordering) {
          this._fragmentSelected = false;
        }
        this._setVisibility();
      })
    );
  }

  private _handleFragment(fragment: Fragment): void {
    const containingFragment: Fragment = fragment?.findAncestor((_fragment: Fragment) => {
      return (this._fragmentIsCaptioned && _fragment.isCaptioned()) || _fragment.equals(this.content);
    });
    this._fragmentSelected =
      containingFragment?.equals(this.content) || containingFragment?.parent?.equals(this.content);
    // right condition handles variable tables within equations
    this._setVisibility();
  }

  private _setVisibility(): void {
    if (this.content && this._fragmentSelected && !this._reordering) {
      this._elementRef.nativeElement.style.display = 'block';
    } else {
      this._elementRef.nativeElement.style.display = 'none';
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }
}
