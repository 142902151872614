import {UUID} from 'app/utils/uuid';

export class SupersedingReference {
  public static deserialise(json: any): SupersedingReference {
    const id: UUID = UUID.orNull(json.id);
    const oldReference: UUID = UUID.orNull(json.oldReference);
    const newReference: UUID = UUID.orNull(json.newReference);
    const supersededOn: number = json.supersededOn;

    return new SupersedingReference(id, oldReference, newReference, supersededOn);
  }

  public static empty(): SupersedingReference {
    return new SupersedingReference(UUID.random(), null, null);
  }

  constructor(public id: UUID, public oldReference: UUID, public newReference: UUID, public supersededOn?: number) {}

  public serialise(): any {
    const json: any = {
      id: this.id.value,
      oldReference: this.oldReference.value,
      newReference: this.newReference.value,
      supersededOn: this.supersededOn,
    };

    return json;
  }
}
