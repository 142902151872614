import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RangeMenuModule} from 'app/changelog/range-menu/range-menu.module';
import {SuggestionsHovertipModule} from 'app/spell-checker/suggestions-hovertip/suggestions-hovertip.module';
import {CanvasComponent} from './canvas.component';

@NgModule({
  imports: [CommonModule, SuggestionsHovertipModule, RangeMenuModule],
  declarations: [CanvasComponent],
  exports: [CanvasComponent],
})
export class CanvasModule {}
