import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {AbstractSectionResolver} from 'app/routing/resolvers/abstract-section-resolver';
import {DocumentService} from 'app/services/document.service';
import {SectionService} from 'app/services/section.service';
import {CurrentLocation, ViewService} from 'app/view/view.service';
import {ViewMode} from '../../view/current-view';

@Injectable({
  providedIn: 'root',
})
export class SectionResolverService extends AbstractSectionResolver {
  protected breadcrumb: string = null;
  protected viewMode: ViewMode = ViewMode.LIVE;
  protected location: CurrentLocation = CurrentLocation.PAD;

  constructor(
    protected documentService: DocumentService,
    protected sectionService: SectionService,
    protected versioningService: VersioningService,
    protected viewService: ViewService,
    protected snackBar: MatSnackBar,
    protected router: Router
  ) {
    super(documentService, sectionService, versioningService, viewService, snackBar, router);
  }

  protected updateCurrentView(previousView: ViewMode, versionTag?: VersionTag): ViewMode {
    return versionTag ? ViewMode.HISTORICAL : ViewMode.LIVE;
  }
}
