import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CacheManager} from 'app/fragment/cache-manager';
import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {FragmentCache} from 'app/fragment/diff/fragment-cache';
import {Suite} from 'app/fragment/suite';
import {CarsException} from 'app/interfaces';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {BehaviorSubject, Observable, Subscription, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {DocumentFragment, DocumentInformationType, Fragment, FragmentType} from '../fragment/types';
import {Callback} from '../utils/typedefs';
import {UUID} from '../utils/uuid';
import {BreadcrumbService} from './breadcrumb.service';
import {FragmentService} from './fragment.service';
import {Template} from './template.service';

export interface DocumentFetchParams {
  projection?: 'ROOT_ONLY' | 'ROOT_AND_CHILDREN' | 'FULL_TREE' | 'INITIAL_DOCUMENT_LOAD';
  validAt?: number;
  setLocation?: boolean;
}

export interface TemplateRequest {
  versionId: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends FragmentService<DocumentFragment> {
  readonly DEFAULT_ERROR_MESSAGE: string = 'Failed to export the document.  Please report this to the CARS team.';

  constructor(
    protected _websocketService: WebSocketService,
    protected _cacheManager: CacheManager,
    protected _http: HttpClient,
    protected _snackbar: MatSnackBar,
    private _breadcrumbService: BreadcrumbService
  ) {
    super(_websocketService, _cacheManager, _http, _snackbar);

    // TODO
    this._subscriptions.push(
      super.onUpdate(
        (info: Fragment) => {
          if (this.getSelected() && info.documentId.equals(this.getSelected().id)) {
            this._breadcrumbService.updateBreadcrumb(1, {
              title: info.value,
            });
          }
        },
        (f) =>
          f.is(FragmentType.DOCUMENT_INFORMATION) &&
          f['documentInformationType'] === DocumentInformationType.DOCUMENT_TITLE
      ),

      this._websocketService.onConnection((connected: boolean) => {
        if (connected && this.getSelected()) {
          this.setLocation(this.getSelected().id);
        }
      })
    );
  }

  public load(
    id: UUID,
    params: DocumentFetchParams,
    addToCache: boolean = true,
    errorSnackbar: string = 'Failed to load the document'
  ): Promise<DocumentFragment> {
    const idString: string = id != null ? id.value : null;
    let httpParams: HttpParams = new HttpParams();
    if (params.validAt !== undefined) {
      httpParams = httpParams.append('validAt', params.validAt.toString());
    }
    if (params.projection !== undefined) {
      httpParams = httpParams.append('projection', params.projection.toString());
    }
    if (params.setLocation !== undefined) {
      httpParams = httpParams.append('setLocation', params.setLocation.toString());
    }

    const errorHandler = (response: HttpErrorResponse): Promise<never> => {
      const error = response.error as CarsException;
      if (error && error.exception === 'uk.co.highwaysengland.cars.users.PermissionException') {
        errorSnackbar = error.message;
      }
      this._handleError(response, errorSnackbar, 'fragment-error');
      return Promise.reject(response);
    };

    const cache: FragmentCache = params.validAt
      ? this._cacheManager.getHistoricalCache(params.validAt)
      : this._cacheManager.getLiveCache();

    return this._http
      .get(`${environment.apiHost}/tree/document/${idString}`, {
        params: httpParams,
        observe: 'response',
      })
      .toPromise()
      .then((response: HttpResponse<any>) => {
        const document: DocumentFragment = FragmentMapper.deserialise(response.body) as DocumentFragment;

        const cachedDocument: DocumentFragment = (
          addToCache ? cache.insertTree(document) : document
        ) as DocumentFragment;

        return cachedDocument;
      }, errorHandler)
      .catch(errorHandler);
  }

  public setLocation(id: UUID): Promise<void> {
    return this._http
      .post(
        `${environment.apiHost}/location/document/${id ? id.value : null}`,
        {},
        {headers: this._httpHeaders, responseType: 'text'}
      )
      .toPromise()
      .then(() => {});
  }

  /**
   * Create a new document with the given parameters, returns a promise resolving to the document id of the newly created document
   */
  public createDocument(title: string, suite: Suite): Promise<UUID> {
    const params: {[param: string]: string} = {
      title: title,
      suite: suite,
    };
    const message: string =
      'Sorry, something went wrong and we were unable to create the document. Please try again in a moment.';

    return this._http
      .post<UUID>(`${environment.apiHost}/documents/create`, {}, {params})
      .pipe(
        map((response: any) => UUID.orThrow(response)),
        catchError((err) => {
          this._handleError(err, message, 'fragment-error');
          return throwError(null);
        })
      )
      .toPromise();
  }

  /**
   * Create a new document with the same content as the template, but using the title and administration given,
   *  returns a promise resolving to the document id of the newly created document.
   */
  public createDocumentFromTemplate(template: Template, title: string): Promise<UUID> {
    const body: TemplateRequest = {
      versionId: template.versionId,
      title: title,
    };

    const message: string =
      'Sorry, something went wrong and we were unable to create the document. Please try again in a moment.';

    return this._http
      .post<UUID>(`${environment.apiHost}/documents/create-from-template`, body)
      .pipe(
        map((response: any) => UUID.orThrow(response)),
        catchError((err) => {
          this._handleError(err, message, 'fragment-error');
          return throwError(null);
        })
      )
      .toPromise();
  }

  /**
   * @override
   */
  public update(document: DocumentFragment, message?: string): Promise<HttpResponse<any>> {
    message =
      message || 'Sorry, something went wrong and we were unable to update the document. Please try again in a moment.';
    return super.update(document, message);
  }

  /**
   * @override
   */
  public delete(document: DocumentFragment, message?: string): Promise<HttpResponse<any>> {
    document.deleted = true;

    message =
      message || 'Sorry, something went wrong and we were unable to delete the document. Please try again in a moment.';
    return super.delete(document, message);
  }

  /**
   * returns an observable stream of any selected or updated document fragment.
   */
  public getDocumentStream(): Observable<DocumentFragment> {
    const documentStream: BehaviorSubject<DocumentFragment> = new BehaviorSubject(null);

    this.onSelection((doc: DocumentFragment) => {
      documentStream.next(doc);
    });

    this.onUpdate((doc: DocumentFragment) => {
      if (documentStream.getValue()?.equals(doc)) {
        documentStream.next(doc);
      }
    });

    return documentStream.asObservable();
  }

  /**
   * @override
   */
  public onCreate(callback: Callback<DocumentFragment>): Subscription {
    return super.onCreate(callback, (f: Fragment) => f.is(FragmentType.DOCUMENT));
  }

  /**
   * @override
   */
  public onUpdate(callback: Callback<DocumentFragment>): Subscription {
    return super.onUpdate(callback, (f: Fragment) => f.is(FragmentType.DOCUMENT));
  }

  /**
   * @override
   */
  public onDelete(callback: Callback<DocumentFragment>): Subscription {
    return super.onDelete(callback, (f: Fragment) => f.is(FragmentType.DOCUMENT));
  }

  /**
   * @override
   */
  public onSelection(callback: Callback<DocumentFragment>): Subscription {
    return super.onSelection(callback, (f: Fragment) => f.is(FragmentType.DOCUMENT));
  }
}
