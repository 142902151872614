import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TdifOrientation, TdifTableRow} from '../tdif-types';

@Component({
  selector: 'cars-tdif-table',
  templateUrl: './tdif-table.component.html',
  styleUrls: ['./tdif-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifTableComponent {
  @Input() public index: string;
  @Input() public caption: string;
  @Input() public orientation: TdifOrientation;
  @Input() public hasHeaderRow: boolean;
  @Input() public columnWidths: number[];
  @Input() public rows: TdifTableRow[];

  constructor() {}
}
