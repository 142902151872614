import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MatAnchor} from '@angular/material/button';
import {CaptionedFragmentComponent} from 'app/fragment/core/captioned-fragment.component';
import {TableFragment} from 'app/fragment/table/table-fragment';
import {CaptionedFragment, ClauseFragment, Fragment, FragmentType} from 'app/fragment/types';
import {LockService} from 'app/services/lock.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {FragmentService} from './../../../services/fragment.service';

@Component({
  selector: 'cars-captioned-hovertip',
  templateUrl: './captioned-hovertip.component.html',
  styleUrls: ['./captioned-hovertip.component.scss'],
})
export class CaptionedHovertipComponent implements OnInit, OnDestroy {
  @Input() public readonly fragmentType: string;
  @Input() public readonly readOnly: boolean;

  @Input() public fragment: CaptionedFragment;

  @ViewChildren('equationHelp') public equationHelpLink: QueryList<MatAnchor>;

  private _clause: ClauseFragment;
  private _captionedFragmentComponent: CaptionedFragmentComponent;

  public altPropertiesCondition: boolean = false;
  public tooltipDelay: number = environment.tooltipDelay;
  public figureInline: boolean;
  public showLandscape: boolean;
  public showManualColumnWidthsOption: boolean;
  public showReplace: boolean;
  public showDelete: boolean;
  public showVariableTableToggle: boolean;
  public showOpenHelp: boolean;
  public hasVariableTable: boolean;
  public showHeaderRowToggle: boolean;
  public hasHeaderRow: boolean;

  /**
   * The URL link for help on using a given fragment
   */
  public helpUrl: string;

  public canLock: boolean;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _lockService: LockService,
    private _cd: ChangeDetectorRef,
    private _fragmentService: FragmentService
  ) {}

  /**
   * Initialises the component by finding parent clause and section.
   */
  public ngOnInit(): void {
    this._clause = this.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    this._captionedFragmentComponent = this.fragment.component as CaptionedFragmentComponent;

    this.figureInline = !!this.fragment.findAncestorWithType(FragmentType.TABLE_CELL);
    this.showLandscape = !this.figureInline && this.fragment.is(FragmentType.FIGURE, FragmentType.TABLE);
    this.showManualColumnWidthsOption =
      this.fragment.is(FragmentType.TABLE) && !(this.fragment as TableFragment).isScheduleTable;
    this.showReplace = this.fragment.is(FragmentType.FIGURE);
    this.showDelete = this.fragment.is(FragmentType.FIGURE, FragmentType.EQUATION, FragmentType.TABLE);
    this.showVariableTableToggle = this.fragment.is(FragmentType.EQUATION);
    this.hasVariableTable =
      this.fragment.is(FragmentType.EQUATION) &&
      this.fragment.children.some((child: Fragment) => child.is(FragmentType.TABLE));
    this.showHeaderRowToggle = this.fragment.is(FragmentType.TABLE);
    this.hasHeaderRow = this.fragment.is(FragmentType.TABLE) ? (this.fragment as TableFragment).hasHeaderRow : false;
    this.helpUrl = this._captionedFragmentComponent.helpUrl;

    this._subscriptions.push(
      this._lockService.onLockChange(this._clause, (canLock) => {
        this.canLock = canLock;
        this._cd.markForCheck();
      }),
      this._fragmentService.onSelection(
        (selectedFragment: Fragment) => {
          this.setIfAltPropertiesAreEnabled(selectedFragment);
        },
        (frag: Fragment) => !!frag
      )
    );
  }

  private setIfAltPropertiesAreEnabled(selectedFragment: Fragment): void {
    const commonAncestor: Fragment = selectedFragment?.findAncestor((fragment: Fragment) => fragment.isCaptioned());

    this.altPropertiesCondition =
      !!commonAncestor?.equals(this.fragment) || !!commonAncestor?.parent?.equals(this.fragment);
    // right condition handles variable tables within equations
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Calls onDelete() on the {@link CaptionedFragmentComponent}.
   */
  public delete(): void {
    this._captionedFragmentComponent.onDelete();
  }

  /**
   * Calls onLandscape() on the {@link CaptionedFragmentComponent}.
   */
  public landscape(): void {
    this._captionedFragmentComponent.onLandscape();
  }

  /**
   * Calls onReplace() on the {@link CaptionedFragmentComponent}.
   *
   * @param event {Event} The change event when inserting a file
   */
  public replace(event: Event): void {
    this._captionedFragmentComponent.onReplace(event);
  }

  /**
   * Calls onMove() on the {@link CaptionedFragmentComponent}.
   *
   * @param indexes {number} The number of indexes to move the captioned fragment. Pass negative values for moving backwards
   */
  public move(indexes: number): void {
    this._captionedFragmentComponent.onMove(indexes);
  }

  /**
   *  Calls onToggleVariableTable() on the {@link CaptionedFragmentComponent}
   */
  public toggleVariableTable(): void {
    this._captionedFragmentComponent.onToggleVariableTable();
    this.hasVariableTable = !this.hasVariableTable;
  }

  /**
   * Calls onToggleHeaderRow on the {@link CaptionedFragmentComponent}
   */
  public toggleHeaderRow(): void {
    this._captionedFragmentComponent.onToggleHeaderRow();
    this.hasHeaderRow = (this._captionedFragmentComponent.content as TableFragment).hasHeaderRow;
  }

  public copy(): void {
    this._captionedFragmentComponent.onCopy();
  }

  /**
   * Determines whether the column widths are auto or manually set.
   */
  public toggleManualWidths(): void {
    this._captionedFragmentComponent.onManualColumnWidths();
  }

  public openEquationHelpLink(): void {
    this.equationHelpLink.forEach((anchorElement: MatAnchor) => anchorElement._elementRef.nativeElement.click());
  }

  /**
   * Determines whether the captioned fragment can be moved the given number of indexes.
   * If unable to move within the clause, will move to the next/previous clause that is not a specifier instruction
   * or part of a clause group.
   *
   * @param indexes {number}  Number of indexes to move the fragment. Pass negative for moving backwards.
   * @returns       {boolean} true if fragment can be moved given number of indexes
   */
  public showMove(indexes: number): boolean {
    if (this.figureInline) {
      return false;
    }

    const clauseChildren: Fragment[] = this._clause.children;
    let newIndex: number = this.fragment.index() + indexes;

    const newParentClause: ClauseFragment = this._captionedFragmentComponent.findNextClauseToMoveTo(indexes);
    const canLockNewClause: boolean = newParentClause && this._lockService.canLock(newParentClause);

    // skip over anchor fragments
    while (!!clauseChildren[newIndex] && clauseChildren[newIndex].is(FragmentType.ANCHOR)) {
      newIndex += indexes / Math.abs(indexes);
    }

    return (
      (!!clauseChildren[newIndex] && clauseChildren[newIndex].isCaptioned()) ||
      (!!newParentClause && (!newParentClause.component || canLockNewClause))
    );
  }
}
