import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ResolvedIconModule} from 'app/dashboard/resolved-icon/resolved-icon.module';
import {ExtendedTimeAgoModule} from 'app/utils/pipes/extended-time-ago.module';
import {SectionTableComponent} from './section-table.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    ResolvedIconModule,
    ExtendedTimeAgoModule,
  ],
  declarations: [SectionTableComponent],
  exports: [SectionTableComponent],
})
export class SectionTableModule {}
