<ng-container *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <div class="loading-message">Loading discussions</div>
</ng-container>
<ng-container *ngIf="!loading">
  <div *ngIf="!discussions || discussions.length === 0" class="loading-message">No discussions.</div>
  <mat-table *ngIf="discussions && discussions.length > 0" [dataSource]="discussions" class="table">
    <ng-container matColumnDef="content">
      <mat-header-cell *matHeaderCellDef>Content</mat-header-cell>
      <mat-cell
        *matCellDef="let discussion"
        [class.italic]="!currentUserDiscussion(discussion) && dashboardType === 'MY_DISCUSSIONS'"
      >
        {{ discussion.fragmentIndex }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="discussion_text">
      <mat-header-cell *matHeaderCellDef>Discussion Text</mat-header-cell>
      <mat-cell
        *matCellDef="let discussion"
        [class.italic]="!currentUserDiscussion(discussion) && dashboardType === 'MY_DISCUSSIONS'"
      >
        <div class="wrap-text-container">
          <span
            class="multiline-text"
            matTooltip="{{ discussion.discussionText }}"
            [matTooltipShowDelay]="toolTipDelay"
            >{{ discussion.discussionText }}</span
          >
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="raised_by">
      <mat-header-cell *matHeaderCellDef>Raised by</mat-header-cell>
      <mat-cell
        *matCellDef="let discussion"
        [class.italic]="!currentUserDiscussion(discussion) && dashboardType === 'MY_DISCUSSIONS'"
      >
        <div
          class="time-ago"
          matTooltip="{{ discussion.raisedByName }} ({{ discussion.createdAt | timeAgo }})"
          [matTooltipShowDelay]="toolTipDelay"
        >
          {{ discussion.raisedByName }} <br />
          ({{ discussion.createdAt | timeAgo }})
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comments">
      <mat-header-cell *matHeaderCellDef>Comments</mat-header-cell>
      <mat-cell
        *matCellDef="let discussion"
        [class.italic]="!currentUserDiscussion(discussion) && dashboardType === 'MY_DISCUSSIONS'"
      >
        {{ discussion.numberOfComments }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="latest_comment">
      <mat-header-cell *matHeaderCellDef>Latest Comment</mat-header-cell>
      <mat-cell
        *matCellDef="let discussion"
        [class.italic]="!currentUserDiscussion(discussion) && dashboardType === 'MY_DISCUSSIONS'"
      >
        <div *ngIf="discussion.numberOfComments > 0" class="comment-wrapper">
          <div class="wrap-text-container">
            <div
              class="multiline-text-comments"
              matTooltip="{{ discussion.latestCommentContent }}"
              [matTooltipShowDelay]="toolTipDelay"
            >
              {{ discussion.latestCommentContent }}
            </div>
          </div>
          <span
            class="time-ago"
            matTooltip="{{ discussion.latestCommentCreatedAt | timeAgo }} by {{ discussion.lastCommentRaisedByName }}"
            [matTooltipShowDelay]="toolTipDelay"
            >({{ discussion.latestCommentCreatedAt | timeAgo }} by {{ discussion.lastCommentRaisedByName }})</span
          >
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="link">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let discussion">
        <a
          mat-stroked-button
          [href]="getSectionUrl(discussion)"
          target="_blank"
          [attr.aria-label]="'Go to section ' + discussion.sectionTitle"
          >Go to section</a
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="info_icon">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let discussion">
        <mat-icon
          matTooltip="This discussion was not raised by you, but you have commented on it."
          *ngIf="!currentUserDiscussion(discussion) && dashboardType === 'MY_DISCUSSIONS'"
          class="blue"
        >
          info
        </mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="all_resolved">
      <mat-header-cell *matHeaderCellDef class="all-resolved"></mat-header-cell>
      <mat-cell *matCellDef="let discussion" class="all-resolved">
        <cars-resolved-icon
          [total]="1"
          [resolved]="discussion.resolved ? 1 : 0"
          [type]="'DISCUSSION'"
        ></cars-resolved-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" [class.no-content]="!discussions || discussions.length === 0">
    </mat-header-row>
    <mat-row *matRowDef="let discussion; columns: displayedColumns"></mat-row>
  </mat-table>
</ng-container>
