import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {TableCellFragment} from 'app/fragment/table/table-fragment';
import {ClauseFragment, Fragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {Suggestion, VersionTag} from 'app/interfaces';
import {DiscussionsService} from 'app/services/discussions.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {UserService} from 'app/services/user/user.service';
import {DiscussionVersionHovertipComponent} from 'app/sidebar/discussions/discussion/discussion-hovertip/discussion-version-hovertip.component'; // eslint-disable-line
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {DocumentRole} from '../../../documents/document-data';
import {Caret} from '../../../fragment/caret';
import {CarsRange} from '../../../fragment/cars-range';
import {RoleService} from '../../../services/user/role.service';
import {CurrentView, ViewMode} from '../../../view/current-view';
import {Discussion} from '../discussions';
import {FragmentType} from './../../../fragment/types';

@Component({
  selector: 'cars-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscussionComponent implements OnInit, OnDestroy {
  @ViewChild('versionRef') public versionRef: ElementRef;

  @Input() public discussion: Discussion;
  @Input() public clause: ClauseFragment;
  @Input() public currentView: CurrentView;

  public submittingComment: boolean = false;
  public creatingComment: boolean = false;
  public resolving: boolean = false;
  public deletingDiscussion: boolean = false;
  public resolvingDiscussion: boolean = false;
  public newContent: string = '';

  public suggestContentHasChanged: [boolean, string] = [false, ''];

  public versionTag: VersionTag;
  private hovertipComponent: ComponentRef<DiscussionVersionHovertipComponent>;
  private hoverTimeout: any = null;

  public userId: UUID;
  public lockable: boolean = true;

  public dateFormat: string = 'MMM dd yyyy HH:mm';
  public tooltipDelay: number = environment.tooltipDelay;
  public discussionIndex: string;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _discussionsService: DiscussionsService,
    private _userService: UserService,
    private _lockService: LockService,
    private _fragmentService: FragmentService,
    private _roleService: RoleService,
    private _versioningService: VersioningService,
    private _dialog: MatDialog,
    private _viewContainerRef: ViewContainerRef,
    private _router: Router,
    private _fragmentIndexService: FragmentIndexService,
    private _cdr: ChangeDetectorRef
  ) {}

  /**
   * Initalises the component.
   */
  ngOnInit(): void {
    if (this.discussion.versionId) {
      this._versioningService
        .getVersionTagFromVersionId(this.discussion.versionId)
        .pipe(take(1))
        .toPromise()
        .then((versionTag) => {
          this.versionTag = versionTag;
        });
    }

    this.setDiscussionIndex();

    this.userId = this._userService.getUser().id;

    this._subscriptions.push(
      this._lockService.onLockChange(this.clause, this.onLockChange.bind(this)),
      this._fragmentService.onUpdate((f: Fragment) => {
        this.suggestContentHasChanged = this._checkSuggestionValue();
        this.setDiscussionIndex();
        this._cdr.markForCheck();
      }),
      this._fragmentService.onCreate((f: Fragment) => {
        this.suggestContentHasChanged = this._checkSuggestionValue();
        this.setDiscussionIndex();
        this._cdr.markForCheck();
      }),
      this._fragmentService.onDelete((f: Fragment) => {
        this.suggestContentHasChanged = this._checkSuggestionValue();
        this.setDiscussionIndex();
        this._cdr.markForCheck();
      })
    );

    this.suggestContentHasChanged = this._checkSuggestionValue();
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
    this.hoverTimeout = null;
  }

  private onLockChange(lockable: boolean): void {
    this.lockable = lockable;
  }

  private _checkSuggestionValue(): [boolean, string] {
    let hasChanged: boolean = false;
    let message: string = '';
    if (this.discussion.suggestion) {
      const s: Suggestion = this.discussion.suggestion;
      const current: string = s.currentValue;
      const start: Fragment = this._fragmentService.find(s.startFragmentId);
      const end: Fragment = this._fragmentService.find(s.endFragmentId);
      if (start && end) {
        const range: CarsRange = new CarsRange(new Caret(start, s.startOffset), new Caret(end, s.endOffset));
        hasChanged = range.value !== current;
        message = `The suggestion's current value has been changed from its original value`;
      } else if (!start && !end) {
        hasChanged = true;
        message = 'The suggestion has been deleted from the pad';
      }
    }
    return [hasChanged, message];
  }

  /**
   * Handles creation of comments. If accepting a change will defer to the {@link FragmentService}.
   *
   * @param acceptSuggestion {boolean} True if comment is accepting a change; defaults to false
   */
  public createComment(acceptSuggestion: boolean = false): void {
    if (this.submittingComment || (acceptSuggestion && !this.canAcceptSuggestedChanges())) {
      return;
    }

    this.submittingComment = true;
    this._discussionsService.createComment(this.newContent, this.discussion, this.resolving).then(
      () => {
        this.submittingComment = false;
        return this._resolveDiscussionPromise(acceptSuggestion).then(() => {
          this.cancelCreation();
          this._cdr.markForCheck();
        });
      },
      () => {
        this.submittingComment = false;
      }
    );
  }

  private _resolveDiscussionPromise(acceptSuggestion: boolean): Promise<void> {
    if (!this.resolving) {
      return Promise.resolve();
    }

    this.resolvingDiscussion = true;

    if (acceptSuggestion) {
      return this._discussionsService
        .acceptSuggestion(this.discussion)
        .then(() => this._discussionsService.resolveDiscussion(this.discussion).then());
    }

    return this._discussionsService.resolveDiscussion(this.discussion).then();
  }

  /**
   * Returns the index of the fragment and it's type where the discussion has been raised
   */
  private setDiscussionIndex(): void {
    const fragment: Fragment = this._fragmentService.find(this.discussion.fragmentId);
    const index: string = this._fragmentIndexService.getIndex(this.discussion.fragmentId);
    if (fragment) {
      switch (fragment.type) {
        case FragmentType.SECTION: {
          this.discussionIndex = 'Section ' + index;
          break;
        }
        case FragmentType.CLAUSE: {
          this.discussionIndex = 'Clause ' + index;
          break;
        }
        case FragmentType.TABLE_CELL: {
          this.discussionIndex = this.getTableCellIndex(fragment as TableCellFragment);
          break;
        }
        case FragmentType.TABLE: {
          this.discussionIndex = index || 'Table';
          break;
        }
        case FragmentType.FIGURE: {
          this.discussionIndex = index || 'Figure';
          break;
        }
        case FragmentType.EQUATION: {
          this.discussionIndex = index || 'Equation';
          break;
        }
        default: {
          this.discussionIndex = index;
          break;
        }
      }
    } else {
      this.discussionIndex = 'Unknown';
    }
  }

  /**
   * Returns the index of the table and cell where the discussion has been raised
   * unless the cell if part of a merged block where the index of the top left cell
   * in the merged block is returned.
   */
  private getTableCellIndex(tableCell: TableCellFragment): string {
    tableCell = tableCell.getRootTableCellForCellInMergedBlock();

    const colIndex = tableCell.index();
    const rowIndex = tableCell.parent.index();
    const tableIndex = this._fragmentIndexService.getIndex(tableCell.parent.parent);
    return `${tableIndex || 'Table'} Row ${rowIndex + 1} Col ${colIndex + 1}`;
  }

  /**
   * Cancels creation of discussion.
   */
  public cancelCreation(): void {
    this.newContent = '';
    this.creatingComment = false;
    this.resolving = false;
    this.submittingComment = false;
  }

  /**
   * Sets creatingComment flag to true.
   */
  public addComment(): void {
    this.creatingComment = true;
  }

  /**
   * Sets flags for when resolving.
   */
  public onResolve(): void {
    this.resolving = true;
    this.creatingComment = true;
  }

  /**
   * Deletes the discussion.
   */
  public deleteDiscussion(): void {
    const ref = this._dialog.open(DialogComponent, {
      ariaLabel: 'Delete discussion dialog',
    });
    ref.componentInstance.title = 'Deleting Discussion';
    ref.componentInstance.message = 'Are you sure you want to continue? This can not be undone.';
    ref.componentInstance.closeActions = [
      {title: 'Delete', tooltip: 'Delete discussion', response: 'delete'},
      {title: 'Cancel', tooltip: 'Cancel discussion deletion', response: null},
    ];
    this._subscriptions.push(
      ref.afterClosed().subscribe((action) => {
        this.deletingDiscussion = true;
        if (action) {
          this._discussionsService.deleteDiscussion(this.discussion);
        } else {
          this.deletingDiscussion = false;
        }
      })
    );
  }

  /**
   * @returns {boolean} Whether the current user can comment against the discussion
   */
  public commentable(): boolean {
    return this.discussion.isCommentable(this.currentView);
  }

  /**
   * @returns {boolean} Whether the current user can delete the discussion
   */
  public deletable(): boolean {
    return this.discussion.isDeleteable(this.userId, this.currentView);
  }

  /**
   * @returns {boolean} Whether the current user can resolve the discussion
   */
  public resolvable(): boolean {
    return this.discussion.isResolvable(this.userId, this.currentView);
  }

  /**
   * This starts the timer for showing the hovertip on the version button on the discussion.
   *
   * @param event   The mousevent.
   */
  public mouseEnterVersionName(event: MouseEvent): void {
    if (this.hovertipComponent) {
      return;
    }

    if (!this.hoverTimeout) {
      this.hoverTimeout = window.setTimeout(() => {
        this.hoverTimeout = null;
        this.createVersionNameHover(event);
      }, 500);
    }
  }

  /**
   * This closes the hovertip or cancels the hovertip timer when the mouse leaves the version button on the discussion.
   */
  public mouseLeaveVersionName(): void {
    if (this.hoverTimeout) {
      window.clearTimeout(this.hoverTimeout);
      this.hoverTimeout = null;
    }
  }

  /**
   * This creates the hovertip for the version button on the discussion.
   *
   * @param event   The mousevent.
   */
  public createVersionNameHover(event: MouseEvent) {
    this.hovertipComponent = this._viewContainerRef.createComponent(DiscussionVersionHovertipComponent);
    this.hovertipComponent.instance.versionName = this.versionTag.name;
    this.hovertipComponent.instance.event = event;
    this.hovertipComponent.instance.anchorElement = this.versionRef.nativeElement;
    this.hovertipComponent.instance.destroy.subscribe(() => {
      this.hovertipComponent.destroy();
      this.hovertipComponent = null;
    });
  }

  /**
   * This handles navigating to the correct version when the version button on the discussion is clicked.
   */
  public fetchVersion(): void {
    const section: Fragment = this._fragmentService.find(this.discussion.fragmentId)
      ? (this._fragmentService.find(this.discussion.fragmentId) as ClauseFragment).getSection()
      : null;
    if (section) {
      const path: Array<string> = [
        'documents',
        section.documentId.value,
        'versions',
        this.versionTag.versionId.value,
        'sections',
        section.id.value,
      ];
      switch (this.currentView.viewMode) {
        case ViewMode.COMMENTS: {
          path.push('comments');
          break;
        }
        case ViewMode.COMPARE: {
          path.push('compare');
          break;
        }
      }
      this._router.navigate(path);
    }
  }

  public canAcceptSuggestedChanges(): boolean {
    return !this._roleService.isInDocumentRole(null, DocumentRole.PEER_REVIEWER);
  }
}
