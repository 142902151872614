import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {AuthenticationProvider} from 'app/services/user/authentication-provider';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {AsyncSubject, Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CarsHttpInterceptor implements HttpInterceptor {
  private ready: AsyncSubject<boolean> = new AsyncSubject();

  private authProvider: AuthenticationProvider;
  private webSocketService: WebSocketService;

  constructor(private _injector: Injector) {
    // Apparent circular dependency
    setTimeout(() => {
      this.authProvider = this._injector.get(AuthenticationProvider);
      this.webSocketService = this._injector.get(WebSocketService);

      // Ensure webSocketService and (by proxy) authProvider are ready before processing any requests.
      this.webSocketService.onConnection((connected) => {
        if (connected) {
          this.ready.next(true);
          this.ready.complete();
        }
      });
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.ready.pipe(
      mergeMap(() => {
        if (this.requiresAuthentication(req)) {
          let modifiedRequest = req;
          if (this.isApiRequest(modifiedRequest)) {
            if (modifiedRequest.headers.has('Authorization')) {
              console.warn('INTERCEPT WITH EXISTING AUTH');
            }
            if (modifiedRequest.method === 'GET') {
              modifiedRequest = this.addCacheSettings(modifiedRequest);
            }
            if (modifiedRequest.method === 'PATCH') {
              modifiedRequest = this.springPatchMethod(modifiedRequest);
            }
            modifiedRequest = this.addWebsocketToken(modifiedRequest);
          }

          return this.authProvider.augmentRequest(modifiedRequest).pipe(
            mergeMap((authenticatedRequest) => {
              return next.handle(authenticatedRequest);
            })
          );
        }

        return next.handle(req);
      })
    );
  }

  private requiresAuthentication(req: HttpRequest<any>): boolean {
    return this.isApiRequest(req) || req.url.startsWith(this.authProvider.getBaseUrl());
  }

  private isApiRequest(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.apiHost);
  }

  private addCacheSettings(req: HttpRequest<any>): HttpRequest<any> {
    const headers = (req.headers ? req.headers : new HttpHeaders())
      .set('Pragma', 'no-cache')
      .set('Expires', '-1')
      .set('cache-control', 'no-cache, no-store');

    return req.clone({headers});
  }

  /**
   * This override is intended to work around HE's network blocking HTTP PATCH requests.
   * Spring handles POSTs with the query parameter _method=patch as PATCH requests.
   */
  private springPatchMethod(req: HttpRequest<any>): HttpRequest<any> {
    const method = 'POST';
    const params: HttpParams = (req.params ? req.params : new HttpParams()).append('_method', 'patch');

    return req.clone({method, params});
  }

  private addWebsocketToken(req: HttpRequest<any>): HttpRequest<any> {
    const sessionId = this.webSocketService.getSessionId();
    if (sessionId) {
      const headers = (req.headers ? req.headers : new HttpHeaders()).append('_cars_websocket_client_id', sessionId);
      return req.clone({headers});
    } else {
      Logger.error('websocket-error', 'Failed to get websocket session ID for req' + req.urlWithParams);
      return req;
    }
  }
}
