import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {SectionPadModule} from './section-pad/section-pad.module';
import {SectionProxyComponent} from './section-proxy.component';
import {SectionReferenceModule} from './section-reference/section-reference.module';

@NgModule({
  imports: [
    CommonModule,
    SectionPadModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    SectionReferenceModule,
    RouterModule,
  ],
  declarations: [SectionProxyComponent],
  exports: [SectionProxyComponent],
})
export class SectionProxyModule {}
