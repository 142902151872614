import {InjectionToken, NgModule, Provider} from '@angular/core';
import {AdministrationPermissionsHandler} from './administration-permissions-handler';
import {DocumentRolePermissionsHandler} from './document-role-permissions-handler';
import {GlobalRolePermissionsHandler} from './global-role-permissions-handler';
import {LocalConfigPermissionsHandler} from './local-config-permissions-handler';
import {PermissionsHandler} from './permissions-handler';
import {SectionTypePermissionsHandler} from './section-type-permissions-handler';
import {SuitePermissionsHandler} from './suite-permissions-handler';
import {UserDisabledPermissionsHandler} from './user-disabled-permissions-handler';
import {LatestVersionTagPermissionsHandler, VersionTagPermissionsHandler} from './version-tag-permissions-handler';
import {ViewModePermissionsHandler} from './view-mode-permissions-handler';
import {WorkflowStatusPermissionsHandler} from './workflow-status-permissions-handler';

export const HANDLERS_INJECTION_TOKEN: InjectionToken<PermissionsHandler[]> = new InjectionToken<PermissionsHandler[]>(
  'Permission Handlers'
);

const PROVIDERS: Provider[] = [
  DocumentRolePermissionsHandler,
  LocalConfigPermissionsHandler,
  SuitePermissionsHandler,
  ViewModePermissionsHandler,
  VersionTagPermissionsHandler,
  LatestVersionTagPermissionsHandler,
  GlobalRolePermissionsHandler,
  WorkflowStatusPermissionsHandler,
  UserDisabledPermissionsHandler,
  SectionTypePermissionsHandler,
  AdministrationPermissionsHandler,
].map((handler) => {
  return {
    provide: HANDLERS_INJECTION_TOKEN,
    useClass: handler,
    multi: true,
  };
});

/**
 * Module which provides each of the permissions handlers in a PermissionsHandler[],
 * injected into PermissionsService
 */
@NgModule({
  providers: PROVIDERS,
})
export class PermissionHandlersModule {}
