import {Logger} from 'app/error-handling/services/logger/logger.service';
import {HTMLNodeParser} from 'app/fragment/core/parser/html-node-parser';
import {Token, TokenType} from 'app/fragment/core/parser/token';
import {FragmentType, SectionType} from 'app/fragment/types';

/**
 * Represents a cell's location within the table.
 */
interface Location {
  row: number;
  col: number;
}

/**
 * Represents a range of merged cells.
 */
class Merge {
  cells: Location[];

  constructor(row: number, col: number, public rowSpan: number, public colSpan: number) {
    this.cells = [];
    for (let r: number = row; r < row + rowSpan; r++) {
      for (let c: number = col; c < col + colSpan; c++) {
        this.cells.push({row: r, col: c});
      }
    }
  }

  /**
   * Returns true if the merged range contains the cell.
   * @param cell {Location} The cell to check.
   */
  public contains(cell: Location): boolean {
    return this.cells.some((loc: Location) => {
      return loc.row === cell.row && loc.col === cell.col;
    });
  }
}

export class HTMLTableParser extends HTMLNodeParser {
  private static readonly BOLD_CLASS: string = 'Table-BoldText';

  // Unlike normal nodes, text cells are allowed newlines, so we override the default text clean regex.
  protected cleanText: RegExp = /[ \t\r\u200B\u00A0]+/g;

  // By nulling the section type, we ensure that clause-level fragments will not be parsed inside the
  // table cell.
  public readonly sectionType: SectionType = null;

  /**
   * Given a HTML table, parse it to tokens.  This has been designed to work
   * with the HTML tables which MS Word places on the clipboard when a table is
   * selected and copied from a MS Word document.
   *
   * @param table {Element} The HTML element of the table.
   * @returns     {Token[]} The tokens ready for lexing.
   */
  public parse(table: Element): Token[] {
    const tokens: Token[] = [new Token(FragmentType.TABLE, null)];
    const merges: Merge[] = [];
    const colsPerRow: number[] = [];
    if (!(table instanceof HTMLTableElement)) {
      return [];
    }

    const rows: HTMLTableRowElement[] = Array.from(table.rows);
    const current: Location = {row: 0, col: 0};
    rows.forEach((row: HTMLTableRowElement) => {
      tokens.push(new Token(FragmentType.TABLE_ROW, null));
      const cells: HTMLTableCellElement[] = Array.from(row.cells);
      let cell: HTMLTableCellElement = cells.shift();
      while (cell) {
        tokens.push(...this.handleMergedCell(current, merges));
        if (cell.rowSpan > 1 || cell.colSpan > 1) {
          // Cell is the top-left corner of a merged area: register the merge.
          merges.push(new Merge(current.row, current.col, cell.rowSpan, cell.colSpan));
        }
        const token: Token = new Token(FragmentType.TABLE_CELL, null);
        token.bold = this.isBold(cell);
        token.rowSpan = cell.rowSpan;
        token.colSpan = cell.colSpan;
        token.deleted = false;
        tokens.push(token);
        let content: Node = cell.firstChild;
        while (content) {
          if (content instanceof HTMLElement) {
            if (this.isList(content)) {
              tokens.push(...this.parseList(content));
            } else {
              tokens.push(this.parseTextContent(content, tokens));
            }
          }
          content = content.nextSibling;
        }
        current.col++;
        cell = cells.shift();
      }
      tokens.push(...this.handleMergedCell(current, merges));
      current.row++;
      colsPerRow.push(current.col);
      current.col = 0;
    });

    tokens.push(new Token(TokenType.TABLE_END, null));
    // Check for non-rectangular table:
    if (
      colsPerRow.length &&
      !colsPerRow.reduce((a: number, b: number) => {
        return a === b ? a : NaN;
      })
    ) {
      Logger.error('table-paste-error', 'Tried to paste a non-rectangular table.');
      return [];
    } else {
      return tokens;
    }
  }

  /**
   * Check if a cell is a bold cell or not.
   *
   * @param cell {Element} The cell to test.
   * @returns {boolean} True if the cell contains an element with the bold class.
   */
  private isBold(cell: Element): boolean {
    return Array.from(cell.children).some((el: Element) => el.classList.contains(HTMLTableParser.BOLD_CLASS));
  }

  /**
   * While the current cell is in a merged range, add a merged cell token and increment
   * the current cell counter.
   *
   * @param current {Location} The current cell coordinates.
   * @param merges  {Merge[]}  All merged cell ranges found so far.
   *
   * @returns {token[]} An array of all the tokens generated in order to move the counter
   * out of a merged range.
   */
  private handleMergedCell(current: Location, merges: Merge[]): Token[] {
    const tokens: Token[] = [];
    let merge: Merge;
    while ((merge = merges.find((m: Merge) => m.contains(current)))) {
      // Cell is in a merged area: add a dummy cell to pad the area out until
      // we are no longer in a merged area.
      current.col++;
      const mergedToken: Token = new Token(FragmentType.TABLE_CELL, null);
      mergedToken.deleted = true;
      mergedToken.rowSpan = merge.rowSpan;
      mergedToken.colSpan = merge.colSpan;
      tokens.push(mergedToken);
    }
    return tokens;
  }
}
