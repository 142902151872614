import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {AuthenticationProvider} from 'app/services/user/authentication-provider';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardService implements CanActivate {
  constructor(private authProvider: AuthenticationProvider) {}

  canActivate() {
    return this.authProvider.isAuthenticated().pipe(map(() => this.authProvider.isAdmin()));
  }
}
