import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ClauseFragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {UserService} from 'app/services/user/user.service';
import {DocInfoValueDisplayNameType, DocumentInformationItem} from 'app/suite-config/configuration.service';
import {BaseFormComponent} from '../base-form.component';
import {getEnumByName} from '../document-information-utils';

@Component({
  selector: 'cars-enum-select-input',
  templateUrl: './enum-select-input.component.html',
  styleUrls: ['./enum-select-input.component.scss'],
})
export class EnumSelectInputComponent extends BaseFormComponent implements OnInit {
  @Input() public set documentInformationItem(documentInformationItem: DocumentInformationItem) {
    this._clause = documentInformationItem.documentInformationFragment.parent as ClauseFragment;
    this.documentInformationType = documentInformationItem.documentInformationField.documentInformationType;
    this.titleText = documentInformationItem.documentInformationField.titleText;
    this.hintText = documentInformationItem.documentInformationField.hintText;
    this.valueDisplayName = documentInformationItem.documentInformationField.valueDisplayName;
    this.options = this.getOptions(documentInformationItem.documentInformationField.enumName);
  }

  public options: string[];

  public valueDisplayName: DocInfoValueDisplayNameType;

  constructor(
    protected fragmentService: FragmentService,
    protected lockService: LockService,
    protected userService: UserService,
    protected versioningService: VersioningService,
    protected cdr: ChangeDetectorRef
  ) {
    super(fragmentService, lockService, userService, versioningService, cdr);
  }

  /**
   * Gets the list of enum values for the given enumName string. The values are used as the display name and the values to store in the
   * database.
   */
  private getOptions(enumName: string): string[] {
    return Object.values(getEnumByName(enumName));
  }

  /**
   * @override
   *
   * Unlock the clause after the update for selecting an option has been sent
   */
  protected _afterUpdateHook(): void {
    this.unlock();
  }
}
