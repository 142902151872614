import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {ClauseFragment, Fragment, SectionFragment} from 'app/fragment/types';
import {ChangeSummaryChangeType} from './change-summary-change-type';
import {ChangeSummaryRowType} from './change-summary-row-type';

export type ChangeSummaryRow = SectionChangeSummaryRow | HeadingChangeSummaryRow | ClauseChangeSummaryRow;
export const ChangeSummaryRow = {
  deserialise(json: any): ChangeSummaryRow {
    switch (ChangeSummaryRowType[json.rowType as string]) {
      case ChangeSummaryRowType.SECTION:
        return SectionChangeSummaryRow.deserialise(json);
      case ChangeSummaryRowType.HEADING:
        return HeadingChangeSummaryRow.deserialise(json);
      case ChangeSummaryRowType.CLAUSE:
        return ClauseChangeSummaryRow.deserialise(json);
    }
  },
};

export abstract class AbstractChangeSummaryRow<T extends Fragment> {
  public abstract readonly rowType: ChangeSummaryRowType;

  public changeType: ChangeSummaryChangeType;

  public oldIndex: string;
  public newIndex: string;

  public oldFragment: T;
  public newFragment: T;

  public childRows: ChangeSummaryRow[];

  protected deserialise(json: any): void {
    this.changeType = ChangeSummaryChangeType[json.changeType as string];
    this.oldIndex = json.oldIndex;
    this.newIndex = json.newIndex;
    this.oldFragment = json.oldFragment ? (FragmentMapper.deserialise(json.oldFragment) as T) : null;
    this.newFragment = json.newFragment ? (FragmentMapper.deserialise(json.newFragment) as T) : null;
    this._forcePortraitModeOnFragment(this.newFragment);
    this._forcePortraitModeOnFragment(this.oldFragment);
    this.childRows = json.childRows ? json.childRows.map((r) => ChangeSummaryRow.deserialise(r)) : [];
  }

  private _forcePortraitModeOnFragment(fragment: Fragment) {
    if (!!fragment) {
      fragment.children.forEach((child: Fragment) => {
        if (child.isLandscape()) {
          child['landscape'] = false;
        }
      });
    }
  }
}

export class SectionChangeSummaryRow extends AbstractChangeSummaryRow<SectionFragment> {
  public readonly rowType: ChangeSummaryRowType.SECTION = ChangeSummaryRowType.SECTION;

  public static deserialise(json: any): SectionChangeSummaryRow {
    const result: SectionChangeSummaryRow = new SectionChangeSummaryRow();
    result.deserialise(json);
    return result;
  }
}

export abstract class AbstractClauseChangeSummaryRow extends AbstractChangeSummaryRow<ClauseFragment> {
  public oldChildIndexMap: Record<string, string>;
  public newChildIndexMap: Record<string, string>;

  protected deserialise(json: any): void {
    super.deserialise(json);
    this.oldChildIndexMap = json.oldChildIndexMap;
    this.newChildIndexMap = json.newChildIndexMap;
  }
}

export class HeadingChangeSummaryRow extends AbstractClauseChangeSummaryRow {
  public readonly rowType: ChangeSummaryRowType.HEADING = ChangeSummaryRowType.HEADING;

  public static deserialise(json: any): HeadingChangeSummaryRow {
    const result: HeadingChangeSummaryRow = new HeadingChangeSummaryRow();
    result.deserialise(json);
    return result;
  }
}

export class ClauseChangeSummaryRow extends AbstractClauseChangeSummaryRow {
  public readonly rowType: ChangeSummaryRowType.CLAUSE = ChangeSummaryRowType.CLAUSE;

  public static deserialise(json: any): ClauseChangeSummaryRow {
    const result: ClauseChangeSummaryRow = new ClauseChangeSummaryRow();
    result.deserialise(json);
    return result;
  }
}
