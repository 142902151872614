import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Caret} from 'app/fragment/caret';
import {TableCellFragment, TableFragment} from 'app/fragment/table/table-fragment';
import {Suggestion} from 'app/interfaces';
import {AnchorService} from 'app/services/anchor.service';
import {CanvasService} from 'app/services/canvas.service';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {CapacityOfComment, Comment, Discussion, DiscussionType} from 'app/sidebar/discussions/discussions';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {filter, first, map, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AnchorFragment, Fragment, FragmentType, SectionFragment} from '../fragment/types';
import {FragmentService} from '../services/fragment.service';
import {RichTextService, RichTextType} from '../services/rich-text.service';
import {Callback, Dictionary} from '../utils/typedefs';
import {UUID} from '../utils/uuid';
import {CurrentView} from '../view/current-view';
import {ViewService} from '../view/view.service';
import {BaseService} from './base.service';
import {UserService} from './user/user.service';

@Injectable({
  providedIn: 'root',
})
export class DiscussionsService extends BaseService {
  // A dictionary from clause ID to list of discussions for that clause
  private _discussions: Dictionary<Discussion[]> = {};
  private _inFlight: Dictionary<Promise<Discussion[]>> = {};
  private _changeSubject: BehaviorSubject<null> = new BehaviorSubject(null);
  private _websocketSubject: Subject<Discussion> = new Subject();
  private _mostRecentCapacityOfComment: BehaviorSubject<CapacityOfComment> = new BehaviorSubject(null);

  private _currentView: CurrentView;

  constructor(
    protected _snackbar: MatSnackBar,
    private _viewService: ViewService,
    private _fragmentService: FragmentService,
    private _richTextService: RichTextService,
    private _http: HttpClient,
    private _websocketService: WebSocketService,
    private _canvasService: CanvasService,
    private _anchorService: AnchorService,
    private _userService: UserService
  ) {
    super(_snackbar);

    this._viewService.onCurrentViewChange((currentView: CurrentView) => {
      this._currentView = currentView;
      this._discussions = {};
    });

    this._websocketService.onConnection(this._onWebsocketConnect.bind(this));

    this._fragmentService.onCreate((f: Fragment) => {
      if (f.is(FragmentType.ANCHOR) && !(f as AnchorFragment).hasBeenResolved) {
        const d: Discussion = this.getDiscussionForAnchor(f as AnchorFragment);
        if (d) {
          this._canvasService.addSuggestionHighlight(d);
        }
      }
    });

    this._websocketSubject
      .pipe(filter(this._viewService.appliesToDiscussion.bind(this._viewService)))
      .subscribe(this._spliceDiscussion.bind(this));

    // Adding setTimeout to ensure angular has finished intialising the service constructor as HTTP interceptor only gets fully
    // instantiated after that constructor.
    // Without the setTimeout angular ends up skipping the http request within the getCapacityOfCommentFromEndpoint() method
    // More info https://github.com/angular/angular/issues/25590
    setTimeout(() => {
      this._getCapacityOfCommentFromEndpoint();
    }, 0);
  }

  public getMostRecentCapacityOfComment(): CapacityOfComment {
    return this._mostRecentCapacityOfComment.value;
  }

  /**
   * Fetch capacity of comment from the latest discussion saved
   */
  private _getCapacityOfCommentFromEndpoint(): void {
    this._http
      .get(`${environment.apiHost}/discussions/most-recent-capacity-of-comment`)
      .toPromise()
      .then((capacityOfComment: CapacityOfComment) => {
        this._mostRecentCapacityOfComment.next(capacityOfComment);
      })
      .catch((response: any) => {
        this._handleError(response, 'Failed to retrieve most recent capacity of comment.', 'discussion-error');
      });
  }

  /**
   * Fetch all discussions relevant to a particular version of a document.
   *
   * @param version {UUID}                  The version tag ID.
   * @returns       {Promise<Discussion[]>} A promise resolving to the discussions.
   */
  public getVersionDiscussions(versionId: UUID): Promise<Discussion[]> {
    return this._http
      .get(`${environment.apiHost}/discussions/version/${versionId.value}`)
      .pipe(map((response: any) => response.map((j: any) => Discussion.deserialise(j))))
      .toPromise()
      .catch((response: any) => {
        this._handleError(
          response,
          'Failed to retrieve discussions for version.  Please retry or contact the CARS team.',
          'discussion-error'
        );
        return Promise.reject([]);
      });
  }

  public getDiscussionCounts(documentId: UUID, resolved: boolean): Promise<Record<string, number>> {
    return this._http
      .get<Record<string, number>>(`${environment.apiHost}/discussions/document/${documentId.value}/count`, {
        params: {resolved: '' + resolved},
      })
      .toPromise()
      .catch((response: any) => {
        this._handleError(response, 'Failed to retrieve discussion counts.', 'discussion-error');
        return Promise.reject(response);
      });
  }

  /**
   * Returns all cached discussions for a given fragment, optionally includes discussions against any
   * descendants. If discussions have never been fetched for the given fragment then returns an empty
   * array. If discussions against descendants are included then returns the list ordered by fragment
   * raised against, and usual (time created) ordering within these groups.
   *
   * @param fragment                         The fragment
   * @param includeDescendants {boolean}     Include descendants of fragment
   * @returns <Discussion[]>
   */
  public getCachedDiscussionsForFragment(fragment: Fragment, includeDescendants: boolean): Discussion[] {
    const allDiscussions: Discussion[] = [];

    if (includeDescendants) {
      fragment.iterateDown(null, null, (frag: Fragment) => {
        if (frag.is(FragmentType.TABLE)) {
          allDiscussions.push(...(this.getSortedDiscussionsForTable(frag as TableFragment) || []));
        } else if (
          !frag.is(FragmentType.TABLE_CELL) ||
          (fragment.findAncestorWithType(FragmentType.TABLE) && !fragment.is(FragmentType.TABLE))
        ) {
          allDiscussions.push(...(this._discussions[frag.id.value] || []));
        }
      });
    } else {
      allDiscussions.push(...(this._discussions[fragment.id.value] || []));
    }

    return allDiscussions;
  }

  /**
   * Returns true if the fragment is able to have comments raised against it.
   * This is true for a section, clause, captioned fragments and table cells within a clause.
   */
  private isFragmentCommentable(fragment: Fragment): boolean {
    return fragment.isCaptioned() || fragment.is(FragmentType.SECTION, FragmentType.CLAUSE, FragmentType.TABLE_CELL);
  }

  /**
   * Fetch all discussions for the given fragment.
   *
   * @param fragment {Fragment}              The fragment
   * @returns      {Promise<Discussion[]>}   A promise resolving to the discussions
   */
  public getDiscussions(fragment: Fragment): Promise<Discussion[]> {
    const section: SectionFragment = fragment?.findAncestorWithType(FragmentType.SECTION) as SectionFragment;

    if (!section) {
      return Promise.resolve([]);
    }

    const id: string = fragment.id.value;
    const sectionId: string = fragment.sectionId.value;

    // Order of preference is: cached response, in-flight request, HTTP.
    if (this._discussions[id]) {
      return Promise.resolve(this._discussions[id]);
    } else if (!this._inFlight[sectionId]) {
      this._inFlight[sectionId] = this._http
        .get(`${environment.apiHost}/discussions/section/${sectionId}`)
        .pipe(
          map((response: any) => response.map((j: any) => Discussion.deserialise(j))),
          map((discussions: Discussion[]) =>
            discussions.filter((d: Discussion) => this._viewService.appliesToDiscussion(d))
          )
        )
        .toPromise()
        .then((discussions: Discussion[]) => {
          const byCommentableFragment: Dictionary<Discussion[]> = {};
          for (const discussion of discussions) {
            const fragmentId: string = discussion.fragmentId.value;

            byCommentableFragment[fragmentId] = byCommentableFragment[fragmentId] || [];
            byCommentableFragment[fragmentId].push(discussion);
            this._canvasService.addSuggestionHighlight(discussion);
          }

          // Add all commentable fragments in the section to the discussions array.
          // Done so empty discussion fields are accounted for.
          // This means all discussions are retrieved once and race conditions don't happen.
          section.iterateDown(null, null, (commentableFrag: Fragment) => {
            if (this.isFragmentCommentable(commentableFrag)) {
              this._discussions[commentableFrag.id.value] = [];
            }
          });

          Object.keys(byCommentableFragment).forEach((fragmentId: string) => {
            if (!!UUID.orNull(fragmentId)) {
              this._discussions[fragmentId] = byCommentableFragment[fragmentId];
            }
          });

          this._changeSubject.next(null);
          delete this._inFlight[sectionId];

          return this._discussions[id];
        })
        .catch((response: any) => {
          this._handleError(response, 'Failed to retrieve discussions for fragment', 'discussion-error');

          // Remove incomplete and in-flight objects
          delete this._discussions[id];
          delete this._inFlight[sectionId];

          return Promise.reject(response);
        });
    }

    return this._inFlight[sectionId];
  }

  /**
   * Subscribe to discussion updates for a given fragment and (optionally) commentable descendants.
   *
   * @param fragment {Fragment}               The interested fragment
   * @param callback {Callback<Discussion[]>} The callback
   * @param includeDescendants                Optionally incude commentable descendants
   * @returns        {Subscription}           The subscription object
   */
  public onChange(fragment: Fragment, callback: Callback<Discussion[]>, includeDescendants: boolean): Subscription {
    const _callback: Callback<Discussion[]> = () =>
      callback(this.getCachedDiscussionsForFragment(fragment, includeDescendants));

    return this._makeSubscription(this._changeSubject, _callback);
  }

  /**
   * Create a discussion on a fragment, returning a promise resolving with the HTTP status code.
   *
   * @param text              {string}              The discussion text
   * @param fragment          {Fragment}            The fragment to raise against
   * @param type              {DiscussionType}      The type of discussion
   * @param capacityOfComment {CapacityOfComment}   The capacity in which the discussion was made
   * @param suggestion        {Suggestion}          Optional suggestion for discussion
   * @returns                 {Promise<number>}     The response promise
   */
  public createDiscussion(
    issueRaised: string,
    fragment: Fragment,
    type: DiscussionType,
    capacityOfComment: CapacityOfComment,
    suggestion?: Suggestion
  ): Promise<void> {
    const versionId: UUID = this._currentView.getCurrentVersionId();

    return Promise.resolve()
      .then(() => {
        if (suggestion) {
          return this._anchorService.createAnchors(suggestion).then((anchors: AnchorFragment[]) => {
            return {
              fragmentId: fragment.id.value,
              issueRaised,
              type,
              capacityOfComment,
              suggestion: true,
              versionId: versionId ? versionId.value : null,
              currentValue: suggestion.currentValue,
              suggestedValue: suggestion.suggestedValue,
              startFragmentId: anchors[0].id.value,
              endFragmentId: anchors[1].id.value,
              startOffset: 0,
              endOffset: 0,
            };
          });
        } else {
          return Promise.resolve({
            fragmentId: fragment.id.value,
            issueRaised,
            type,
            capacityOfComment,
            suggestion: false,
            versionId: versionId ? versionId.value : null,
          });
        }
      })
      .then((body: any) => {
        const observable: Observable<HttpResponse<any>> = this._http
          .post(`${environment.apiHost}/discussions/create`, body, {
            headers: this._httpHeaders,
            observe: 'response',
          })
          .pipe(
            tap((response: HttpResponse<any>) => {
              this._spliceDiscussion(Discussion.deserialise(response.body));
              this._canvasService.clearPendingSuggestionHighlight();
            })
          );

        return this._toPromise(
          observable,
          'Sorry, something went wrong and we were unable to create a discussion. ' + 'Please try again in a moment.',
          'discussion-error'
        ).then(() => {});
      });
  }

  /**
   * Returns the discussion that is a suggestion and has the given anchor fragment as either it's end fragment or start fragment.
   * Else, returns null.
   */
  public getDiscussionForAnchor(anchor: AnchorFragment): Discussion {
    for (const key of Object.keys(this._discussions)) {
      const discussions: Discussion[] = this._discussions[key];
      for (const d of discussions) {
        if (!d.resolved && d.suggestion) {
          const s: Suggestion = d.suggestion;
          if (s.startFragmentId.equals(anchor.id) || s.endFragmentId.equals(anchor.id)) {
            return d;
          }
        }
      }
    }
    return null;
  }

  private getSortedDiscussionsForTable(table: TableFragment): Discussion[] {
    const sortedDiscussions: Discussion[] = [...(this._discussions[table.id.value] || [])];

    for (let column = 0; column < table.children[0].children.length; column++) {
      for (let row = 0; row < table.children.length; row++) {
        sortedDiscussions.push(...this.getDiscussionsForMergedBlock(table, row, column));
      }
    }
    return sortedDiscussions;
  }

  private getDiscussionsForMergedBlock(table: TableFragment, blockStartRow, blockStartColumn): Discussion[] {
    const blockDiscussions: Discussion[] = [];
    const topLeftCell: TableCellFragment = table.at(blockStartRow, blockStartColumn);

    if (!topLeftCell.deleted && (topLeftCell.rowSpan > 1 || topLeftCell.colSpan > 1)) {
      for (let blockRow = blockStartRow; blockRow < blockStartRow + topLeftCell.rowSpan; blockRow++) {
        for (let blockColumn = blockStartColumn; blockColumn < blockStartColumn + topLeftCell.colSpan; blockColumn++) {
          blockDiscussions.push(...(this._discussions[table.at(blockRow, blockColumn).id.value] || []));
        }
      }
    } else if (!topLeftCell.deleted && (topLeftCell.rowSpan === 1 || topLeftCell.colSpan === 1)) {
      blockDiscussions.push(...(this._discussions[topLeftCell.id.value] || []));
    }

    return blockDiscussions;
  }

  /**
   * Delete a discussion, returning a promise resolving with the HTTP status code.
   *
   * @param discussion {Discussion}        The discussion to delete
   * @returns          {Promise<number>}   The response promise
   */
  public deleteDiscussion(discussion: Discussion): Promise<HttpResponse<any>> {
    const observable: Observable<HttpResponse<any>> = this._http
      .delete(`${environment.apiHost}/discussions/${discussion.id.value}`, {
        headers: this._httpHeaders,
        observe: 'response',
      })
      .pipe(
        tap(() => {
          const fragmentId: string = discussion.fragmentId.value;
          this._discussions[fragmentId].splice(this._discussions[fragmentId].indexOf(discussion), 1);
          this._canvasService.removeSuggestionHighlight(discussion);
          this._changeSubject.next(null);
        })
      );

    return this._toPromise(
      observable,
      'Sorry, something went wrong and we were unable to delete the discussion. ' + 'Please try again in a moment.',
      'discussion-error'
    );
  }

  /**
   * Create a comment on a clause, returning a promise resolving with the HTTP status code.
   *
   * @param content    {string}            The comment text
   * @param discussion {Discussion}        The discussion the comment is attached to
   * @param resolution {boolean}           True if the comment is the resolution to the discussion
   * @returns          {Promise<number>}   The response promise
   */
  public createComment(content: string, discussion: Discussion, resolution: boolean): Promise<HttpResponse<any>> {
    const versionId: UUID = this._currentView.getCurrentVersionId();
    const body: any = {
      content: content,
      discussionId: discussion.id.value,
      resolution: resolution,
      versionId: versionId ? versionId.value : null,
    };

    const post: Observable<HttpResponse<any>> = this._http
      .post(`${environment.apiHost}/comments`, body, {
        headers: this._httpHeaders,
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponse<any>) => {
          if (!(discussion.comments instanceof Array)) {
            discussion.comments = [];
          }
          discussion.comments.push(Comment.deserialise(response.body));
          this._changeSubject.next(null);
        })
      );

    return this._toPromise(
      post,
      'Sorry, something went wrong and we were unable to create a comment. ' + 'Please try again in a moment.',
      'discussion-error'
    );
  }

  /**
   * Resolve a discussion, returning a promise resolving with the HTTP status code.
   *
   * @returns          {Promise<number>}   The response promise
   * @param discussion {Discussion}        The discussion to resolve
   */
  public resolveDiscussion(discussion: Discussion): Promise<HttpResponse<any>> {
    const discussionId: string = discussion.id.value;

    const patch: Observable<HttpResponse<any>> = this._http
      .patch(`${environment.apiHost}/discussions/resolve/${discussionId}`, undefined, {
        headers: this._httpHeaders,
        observe: 'response',
      })
      .pipe(
        tap(() => {
          const updatedDiscussion: Discussion = this._discussions[discussion.fragmentId.value].find((d: Discussion) =>
            d.id.equals(discussion.id)
          );
          updatedDiscussion.resolved = true;
          this._canvasService.removeSuggestionHighlight(discussion);
          this._changeSubject.next(null);
        })
      );

    return this._toPromise(
      patch,
      'Sorry, something went wrong and we were unable to resolve the discussion. ' + 'Please try again in a moment.',
      'discussion-error'
    );
  }

  /**
   * Accepts the suggested change on the discussion.
   *
   * @param discussion {Discussion} Discussion to accept suggestion for
   */
  public acceptSuggestion(discussion: Discussion): Promise<void> {
    if (!discussion.suggestion) {
      return Promise.reject();
    }

    const startFragment: Fragment = this._fragmentService.find(discussion.suggestion.startFragmentId);
    const endFragment: Fragment = this._fragmentService.find(discussion.suggestion.endFragmentId);

    if (startFragment && endFragment) {
      const acceptedSuggestionSubject: Subject<void> = new Subject();
      const startIndex: number = discussion.suggestion.startOffset;
      const endIndex: number = discussion.suggestion.endOffset;

      this._richTextService.next(
        RichTextType.SUGGESTION,
        new Caret(startFragment, startIndex),
        new Caret(endFragment, endIndex),
        discussion.suggestion.suggestedValue,
        acceptedSuggestionSubject
      );

      // The subject will be triggered externally when the suggestion has been accepted
      return acceptedSuggestionSubject.pipe(first()).toPromise();
    } else if (startFragment || endFragment) {
      // Discussions suggestions are always relative to anchor fragments, no need to validate
      return this._fragmentService.delete(startFragment ? startFragment : endFragment).then();
    }

    return Promise.reject();
  }

  /**
   * Handles websocket connect and disconnect events.
   *
   * @param connected {boolean} True if a connect event
   */
  private _onWebsocketConnect(connected: boolean): void {
    this._subscriptions.splice(0).forEach((subscription: Subscription) => subscription.unsubscribe());

    if (connected) {
      this._subscriptions.push(
        this._websocketService.subscribe('/topics/discussions', (data: any) => {
          this._websocketSubject.next(Discussion.deserialise(data));
        })
      );
    }
  }

  /**
   * Helper function to splice a discussion into the cached array.
   *
   * @param discussion {Discussion}   The discussion to splice
   */
  private _spliceDiscussion(discussion: Discussion): void {
    const fragmentId: string = discussion.fragmentId.value;
    this._discussions[fragmentId] = this._discussions[fragmentId] || [];

    const discussions: Discussion[] = this._discussions[fragmentId];
    const existing: number = discussions.findIndex((d: Discussion) => d.id.equals(discussion.id));

    existing >= 0 ? discussions.splice(existing, 1, discussion) : discussions.push(discussion);

    this._canvasService.addSuggestionHighlight(discussion);
    this._changeSubject.next(null);
    if (discussion.createdBy.equals(this._userService.getUser().id)) {
      this._mostRecentCapacityOfComment.next(discussion.capacityOfComment);
    }
  }
}
