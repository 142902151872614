/**
 * A class used by the {@link SpellCheckerService}.
 * Check whether a fragments value should be matched based on RexExp rules.
 */
export class CharacterSetRegex {
  private static readonly ALPHANUMERIC_CHARACTERS_REGEX: RegExp = /\w+/;
  private static readonly ACCENTED_CHARACTERS_REGEX: RegExp = /[\u00C0-\u00FF]+/; // À - ÿ.
  private static readonly MATCH_REGEX: RegExp = new RegExp(
    CharacterSetRegex.ALPHANUMERIC_CHARACTERS_REGEX.source + '|' + CharacterSetRegex.ACCENTED_CHARACTERS_REGEX.source
  );

  /**
   * Analyses the character at the given index for the fragment's value.
   * Matches: apostrophe's that aren't preceded by an alphanumeric or accented character,
   * non-alphanumeric & non-accented characters.
   *
   * @param fragmentValue  {string}  Fragment value to analyse
   * @param index          {number}  Index to check
   * @returns              {boolean} True if word should extraced at the given index
   */
  public static shouldMatch(fragmentValue: string, index: number): boolean {
    let shouldMatch: boolean = false;
    const token: string = fragmentValue.charAt(index);

    if (token.match(/\'/g)) {
      shouldMatch = !CharacterSetRegex.MATCH_REGEX.test(fragmentValue.charAt(index - 1));
    } else if (!CharacterSetRegex.MATCH_REGEX.test(token)) {
      shouldMatch = true;
    }

    return shouldMatch;
  }

  // Protected constructor to prevent external instantiation
  protected constructor() {}
}
