import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {environment} from 'environments/environment';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleTableService extends BaseService {
  private readonly _scheduleTableGenerateFragmentsUrl: string = `${environment.apiHost}/schedule-table/generate-fragments`;

  constructor(protected _snackbar: MatSnackBar, private _http: HttpClient) {
    super(_snackbar);
  }

  public async getScheduleTableFragments(
    clauseGroup: ClauseGroupFragment,
    validAt: number
  ): Promise<ClauseGroupFragment> {
    const params: {[param: string]: string} = {
      fragmentId: clauseGroup.id.value,
    };

    if (validAt) {
      params['validAt'] = validAt.toString();
    }

    return this._http
      .get<ClauseGroupFragment>(this._scheduleTableGenerateFragmentsUrl, {params})
      .pipe(
        map((response: any) => FragmentMapper.deserialise(response) as ClauseGroupFragment),
        catchError((error: any) => {
          this._handleError(
            error,
            'Failed to generate schedule table. Please retry or contact support',
            'schedule-table-error'
          );
          return Promise.reject(error);
        })
      )
      .toPromise();
  }
}
