import {HttpClient, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {AuthenticationProvider, GlobalRole} from 'app/services/user/authentication-provider';
import {User} from 'app/user/user';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import Keycloak from 'keycloak-js';
import {AsyncSubject, BehaviorSubject, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KeycloakAuthenticationProvider extends AuthenticationProvider {
  private keycloakConfig: Keycloak.KeycloakConfig = {
    url: environment.authenticationHost,
    realm: environment.authenticationRealm,
    clientId: 'cars-webapp',
  };
  private keycloak: Keycloak;
  private authenticated = new AsyncSubject<boolean>();
  private ref: MatDialogRef<DialogComponent> = null;
  private users: {[id: string]: Observable<User>} = {};

  private _currentUser: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private dialog: MatDialog, private snackBar: MatSnackBar) {
    super();

    if (typeof Keycloak === 'function') {
      this.initialiseKeycloak();
    } else {
      Logger.error('auth-error', 'Failed to initialise keycloak adapter');
      const dialogRef = dialog.open(DialogComponent, {
        disableClose: true,
      });
      dialogRef.componentInstance.message =
        'CARS was unable to contact the authentication server.<br /><br />' +
        'Please try refreshing your browser or contact the CARS team at ' +
        environment.supportEmail +
        ' if the problem persists';
      dialogRef.componentInstance.closeActions = [
        {title: 'Refresh', tooltip: 'Refresh page', response: true, color: 'primary'},
      ];
      dialogRef.afterClosed().subscribe(() => {
        location.reload();
      });
    }
  }

  private initialiseKeycloak(): void {
    const keycloakSource: string | Keycloak.KeycloakConfig = !!this.keycloakConfig.url
      ? this.keycloakConfig
      : 'assets/keycloak.json';
    this.keycloak = new Keycloak(keycloakSource);
    const token: string = localStorage.getItem('kc-token');
    const refreshToken: string = localStorage.getItem('kc-refresh-token');
    this.keycloak
      .init({
        checkLoginIframe: false,
        token: token === 'undefined' ? undefined : token,
        refreshToken: refreshToken === 'undefined' ? undefined : refreshToken,
      })
      .then((authenticated) => {
        if (!this.keycloak.authenticated || !authenticated) {
          this.keycloak.login();
          return;
        }

        this.updateTokenStorage();

        const kcUser = this.keycloak.idTokenParsed;
        this._currentUser.next(AuthenticationProvider.userFromkeycloakUser(kcUser));

        this.addUserIdToDataLayer(this._currentUser.value.id);

        this.authenticated.next(authenticated);
        this.authenticated.complete();
      })
      .catch(() => {
        Logger.error('auth-error', 'Failed to check keycloak authentication');
        this.keycloak.login();
      });
  }

  /**
   * Add the user id to the dataLayer window object for use by analytics
   * @param userId The user's id as an UUID
   */
  private addUserIdToDataLayer(userId: UUID) {
    let userIdValue = 'unknown user';
    if (userId && userId.value) {
      userIdValue = userId.value;
    }
    if (window['dataLayer'] && window['dataLayer'] instanceof Array) {
      window['dataLayer'].push({userId: userIdValue});
    }
  }

  public isAuthenticated(): Observable<boolean> {
    return this.authenticated;
  }

  private updateTokenStorage(): void {
    if (this.keycloak.token) {
      localStorage.setItem('kc-token', this.keycloak.token);
    }
    if (this.keycloak.refreshToken) {
      localStorage.setItem('kc-refresh-token', this.keycloak.refreshToken);
    }
  }

  /*
   * Checks that the current authentication token is valid, and if not attempts to refresh it.
   * On Failure to acquire a valid token, will open an undismissable dialog to warn the user.
   */
  public augmentRequest(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    const response: AsyncSubject<HttpRequest<any>> = new AsyncSubject();
    this.isAuthenticated().subscribe((authenticated) => {
      this.updateTokenStorage();
      this.keycloak
        .updateToken(5)
        .then(() => {
          const headers = request.headers.set('Authorization', 'Bearer ' + this.keycloak.token);
          response.next(request.clone({headers}));
          response.complete();
        })
        .catch(() => {
          if (this.ref === null) {
            this.ref = this.dialog.open(DialogComponent, {
              ariaLabel: 'Session expired dialog',
              disableClose: true,
            });
            const component = this.ref.componentInstance;
            component.title = 'Authentication Expired';
            component.message = 'Your session has expired, please re-login to continue to use CARS';
            component.closeActions = [{title: 'Login', tooltip: 'Re-login to CARS', response: 'login'}];
            this.ref.afterClosed().subscribe((action) => {
              this.keycloak.login();
            });
          }
        });
    });

    return response.asObservable();
  }

  public getCurrentUserObs(): Observable<User> {
    return this._currentUser.asObservable();
  }

  public getCurrentUser(): User {
    return this._currentUser.value;
  }

  public getCurrentGlobalRoles(): Observable<GlobalRole[]> {
    return this.isAuthenticated().pipe(
      map((authenticated: boolean) => (authenticated ? this._extractGlobalRoles(this.keycloak.realmAccess.roles) : []))
    );
  }

  private _extractGlobalRoles(allRoles: string[] = []): GlobalRole[] {
    return allRoles.filter(this._isGlobalRole);
  }

  private _isGlobalRole(role: string): role is GlobalRole {
    return Object.keys(GlobalRole).some((globalRole: string) => GlobalRole[globalRole] === role);
  }

  public createAccountUrl(): string {
    return this.keycloak.createAccountUrl();
  }

  public createLogoutUrl(): string {
    return this.keycloak.createLogoutUrl();
  }

  public isAdmin(): boolean {
    return this.userInRoles(GlobalRole.ADMIN);
  }

  public userManagement(): string {
    if (this.isAdmin()) {
      return this.keycloak.authServerUrl + '/admin/' + this.keycloak.realm + '/console';
    }
  }

  /**
   * Determine if the current user has any of the specified roles.
   *
   * @param roles The roles to check.
   */
  public userInRoles(...roles: GlobalRole[]): boolean {
    return roles.findIndex((role) => this.keycloak.hasRealmRole(role)) > -1;
  }

  public getUser(id: UUID): Observable<User> {
    if (id === null) {
      return of(AuthenticationProvider.UNKNOWN_USER);
    }
    if (!this.users[id.value]) {
      const userSubject: ReplaySubject<User> = new ReplaySubject(1);
      this.users[id.value] = userSubject;

      this.http
        .get(`${this.keycloak.authServerUrl}/admin/realms/${this.keycloak.realm}/users/${id.value}`)
        .pipe(
          map((response: any) => User.deserialise(response)),
          catchError((error) => this.handleError(error, this.getUser, id))
        )
        .subscribe((user: User) => userSubject.next(user));
    }
    return this.users[id.value];
  }

  private handleError(error: any, retryMethod: (id: UUID) => any, id: UUID): Observable<User> {
    if (error) {
      if (error.status === 404) {
        return of(AuthenticationProvider.UNKNOWN_USER);
      } else {
        Logger.error('user-retrieval-error', `Could not retrieve user ${id.value}`, error);
        this.snackBar
          .open('User could not be retrieved', 'Retry', {
            duration: 5000,
          })
          .onAction()
          .subscribe(() => {
            retryMethod(id);
          });
      }
    }
    return throwError(error);
  }

  public offline(): boolean {
    return false;
  }

  public getBaseUrl(): string {
    return this.keycloak.authServerUrl;
  }
}
