import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {AdministrationToDisplayNameModule} from 'app/utils/pipes/administration-to-display-name.module';
import {AdministrationComponent} from './administration.component';

@NgModule({
  declarations: [AdministrationComponent],
  imports: [CommonModule, AdministrationToDisplayNameModule, MatFormFieldModule, MatInputModule],
  exports: [AdministrationComponent],
})
export class AdministrationModule {}
