<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<button
  mat-button
  *ngIf="!isCreatingSection"
  class="start-creation"
  color="primary"
  (click)="startCreatingSection()"
  matTooltip="Create a new section"
  [matTooltipShowDelay]="tooltipDelay"
  aria-label="Create a new section"
>
  <mat-icon>add</mat-icon> Create section
  <cars-alt-accessibility
    [altProperties]="{
      initialKey: 'q',
      key: 'q',
      condition: true
    }"
    (onSelect)="startCreatingSection()"
  ></cars-alt-accessibility>
</button>

<form (ngSubmit)="onSubmit()" #sectionCreate="ngForm" *ngIf="isCreatingSection">
  <mat-form-field>
    <input
      autocomplete="off"
      matInput
      #title
      placeholder="New section title"
      [(ngModel)]="newTitle"
      name="title"
      required
      carsRequiredNonWhitespace
      [disabled]="!selectedTypeConfig || loading"
    />
    <mat-error>Section title is required</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-select
      placeholder="Section type"
      [(ngModel)]="selectedTypeConfig"
      name="typeConfig"
      [disabled]="!selectedTypeConfig || loading"
      required
    >
      <ng-container *ngIf="documentId">
        <mat-option
          *ngFor="let typeConfig of documentId | clauseAndSectionOptions: 'SECTION' | async"
          [value]="typeConfig"
        >
          {{ typeConfig.displayName }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <button
    mat-raised-button
    (click)="stopCreatingSection()"
    type="button"
    carsAnalytics="cancel-section-creation"
    [disabled]="loading"
    aria-label="Cancel section creation"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!sectionCreate.form.valid || loading"
    carsAnalytics="create-section"
    enableDefaultFocus
  >
    Create section
  </button>
</form>
