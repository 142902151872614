import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ReviewStateService, ReviewStatusProperties, UserReviewState} from 'app/services/review-state.service';
import {environment} from 'environments/environment';

@Component({
  selector: 'cars-review-status-toggle',
  templateUrl: './review-status-toggle.component.html',
  styleUrls: ['./review-status-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewStatusToggleComponent {
  private static readonly LOADING_DISPLAY_PROPERTIES: ReviewStatusProperties = {
    statusText: 'Loading',
    buttonText: 'LOADING',
    icon: null,
    iconColor: null,
  };

  @Input() public set userReviewState(value: UserReviewState) {
    this._userReviewState = value;

    this.displayProperties = !!this._userReviewState
      ? UserReviewState.DISPLAY_PROPERTIES[this._userReviewState.reviewState]
      : ReviewStatusToggleComponent.LOADING_DISPLAY_PROPERTIES;
  }

  public get userReviewState(): UserReviewState {
    return this._userReviewState;
  }

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public readonly dateFormat: string = 'dd/MM/yyyy';

  public displayProperties: ReviewStatusProperties;

  private _userReviewState: UserReviewState;

  constructor(private _reviewStateService: ReviewStateService) {}

  public changeState(): void {
    if (!!this._userReviewState) {
      this._reviewStateService
        .saveUserReviewState(
          this._userReviewState.documentId,
          this._userReviewState.versionId,
          this._userReviewState.userId,
          UserReviewState.NEXT_REVIEW_STATE[this.userReviewState.reviewState]
        )
        .then((userReviewState: UserReviewState) => (this.userReviewState = userReviewState));
    }
  }
}
