import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Breadcrumb} from 'app/interfaces';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject([]);

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    // Needed for navigating direct to URLs without routing.
    this.subscribe(this.activatedRoute);

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.setBreadcrumbs([]);
      this.subscribe(this.activatedRoute);
    });
  }

  private subscribe(activatedRoute: ActivatedRoute) {
    activatedRoute.data.subscribe((data) => {
      const breadcrumbs: Breadcrumb[] =
        data != null && !data.ignoreBreadcrumbs && data.breadcrumbs != null ? (data.breadcrumbs as Breadcrumb[]) : [];
      this.setBreadcrumbs(this.breadcrumbs.getValue().concat(breadcrumbs));
      activatedRoute.children.forEach((child: ActivatedRoute) => {
        this.subscribe(child);
      });
    });
  }

  getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.breadcrumbs.asObservable();
  }

  setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    this.breadcrumbs.next(breadcrumbs);
  }

  updateBreadcrumb(index, breadcrumb: Breadcrumb) {
    const breadcrumbs: Breadcrumb[] = this.breadcrumbs.getValue();
    if (index < breadcrumbs.length) {
      breadcrumbs[index].title = breadcrumb.title !== void 0 ? breadcrumb.title : breadcrumbs[index].title;
      breadcrumbs[index].link = breadcrumb.link !== void 0 ? breadcrumb.link : breadcrumbs[index].link;
      this.breadcrumbs.next(breadcrumbs);
    }
  }
}
