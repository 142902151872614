import {Injectable} from '@angular/core';
import {FragmentCache} from 'app/fragment/diff/fragment-cache';
import {FragmentState} from 'app/fragment/fragment-state';
import {suiteToIndexer} from 'app/fragment/indexing/fragment-index.service';
import {Suite} from 'app/fragment/suite';
import {DocumentFragment, Fragment, SectionFragment} from 'app/fragment/types';
import {VersionTag} from 'app/interfaces';
import {SearchableGlobalReferenceService} from 'app/services/references/searchable-global-reference.service';
import {DocumentFetchParams, DocumentService} from '../../services/document.service';

@Injectable()
export class SectionCompareIndexerService {
  private _previousIdToIndex: Record<string, string> = {};
  private _currentIdToIndex: Record<string, string> = {};
  private _fragmentCache: Record<number, FragmentCache> = {};

  constructor(
    private _documentService: DocumentService,
    private _searchableGlobalReferenceService: SearchableGlobalReferenceService
  ) {}

  public getIndex(fragment: Fragment, defaultValue: string = ''): string {
    const id: string = fragment.id.value;
    const state: FragmentState = fragment.state;

    switch (state) {
      case FragmentState.DELETED:
        return this._previousIdToIndex[id] || defaultValue;
      case FragmentState.UNMODIFIED:
      case FragmentState.MOVED:
      case FragmentState.CREATED:
      default:
        return this._currentIdToIndex[id] || defaultValue;
    }
  }

  public setPrevious(section: SectionFragment, version: VersionTag): Promise<DocumentFragment> {
    return this._setIndex(section, false, version);
  }

  public setCurrent(section: SectionFragment, version: VersionTag): Promise<DocumentFragment> {
    return this._setIndex(section, true, version);
  }

  private async _setIndex(section: SectionFragment, current: boolean, version: VersionTag): Promise<DocumentFragment> {
    let cache: FragmentCache = version ? this._fragmentCache[version.createdAt] : null;

    if (!cache) {
      cache = new FragmentCache(1);
      const params: DocumentFetchParams =
        version?.createdAt !== null
          ? {projection: 'INITIAL_DOCUMENT_LOAD', validAt: version?.createdAt}
          : {projection: 'INITIAL_DOCUMENT_LOAD'};
      const d: DocumentFragment = await this._documentService.load(section.documentId, params, false);

      cache.insertTree(d);

      if (version?.createdAt || version?.createdAt === 0) {
        this._fragmentCache[version.createdAt] = cache;
      }
    }

    cache.insertTree(section);

    const document: DocumentFragment = await this._searchableGlobalReferenceService
      .populateDocumentReferenceSeachableGlobalReferences(
        cache.find(section.documentId).live as DocumentFragment,
        version?.createdAt
      )
      .toPromise();

    const suite: Suite = document.suite;
    const lookup: Record<string, string> = suiteToIndexer[suite](document);

    current ? (this._currentIdToIndex = lookup) : (this._previousIdToIndex = lookup);

    return document;
  }
}
