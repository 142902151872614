<mat-form-field class="form-field" [formGroup]="formGroup" [matTooltip]="!canLock ? (lockedText | async) : ''">
  <textarea
    matInput
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="20"
    [formControlName]="documentInformationType"
    [id]="documentInformationType"
    [placeholder]="titleText"
    [name]="documentInformationType"
    (focus)="lock()"
    (blur)="unlock()"
  ></textarea>
  <mat-hint *ngIf="hintText">{{ hintText }}</mat-hint>
</mat-form-field>
