import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {Page} from 'app/admin/manage-references/manage-references.component';
import {ClauseFragment, Fragment, FragmentType} from 'app/fragment/types';
import {UnitInputFragment} from 'app/fragment/types/input/unit-input-fragment';
import {FragmentService} from 'app/services/fragment.service';
import {UnitService} from 'app/services/unit.service';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {Unit} from './unit';

export interface UnitSearchParams {
  searchTerm: string;
  page: number;
  pageSize: number;
}

@Component({
  selector: 'cars-unit-selection',
  templateUrl: './unit-selection.component.html',
  styleUrls: ['./unit-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitSelectionComponent implements OnInit, OnChanges {
  @Input() public clause: ClauseFragment;

  public units: Unit[];
  public totalUnitsCount: number;

  public searchTerm: string = '';
  public loading: boolean = false;

  private _unitInput: UnitInputFragment;
  private _searchSubscription: Subscription;

  private readonly _defaultSearchParams: UnitSearchParams = {
    searchTerm: '',
    page: 0,
    pageSize: 10,
  };

  constructor(
    private _unitService: UnitService,
    private _fragmentService: FragmentService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.updateUnitsList(this._defaultSearchParams);
    this._setUnitInputFromClause();
  }

  public updateSearchTerm(newSearchTerm: string): void {
    this.searchTerm = newSearchTerm;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('clause')) {
      if (
        !changes.clause.previousValue ||
        !changes.clause.currentValue ||
        !changes.clause.previousValue.id.equals(changes.clause.currentValue.id)
      ) {
        this._setUnitInputFromClause();

        this._cdr.markForCheck();
      }
    }
  }

  /**
   * Helper method to extract the unitInput from the current clause if appropriate and store it in this._unitInput
   */
  private _setUnitInputFromClause(): void {
    this._unitInput = this.clause?.children.find((f: Fragment) => f.is(FragmentType.UNIT_INPUT)) as UnitInputFragment;
  }

  /**
   * Returns true if the clause contains a valid UnitInputFragment to set the value for.
   */
  public showUnitSelectionOptions(): boolean {
    return !!this._unitInput;
  }

  /**
   * Searches for units matching the given parameters and updates the internal state with these results.
   */
  public updateUnitsList(params: UnitSearchParams): void {
    this.loading = true;
    this._searchSubscription?.unsubscribe();
    this._searchSubscription = this._unitService
      .search(params.searchTerm, params.page, params.pageSize)
      .pipe(take(1))
      .subscribe((result: Page<Unit>) => {
        this.units = result.resultsList;
        this.totalUnitsCount = result.total;
        this.loading = false;
        this._cdr.markForCheck();
      });
  }

  /**
   * Selects a given unit in the unitInput for the current clause.
   */
  public selectUnit(unit: Unit): void {
    if (!!unit) {
      this.loading = true;
      this._unitInput.unitId = unit.id;
      this._unitInput.unitEquationSource = unit.equationSource;

      this._fragmentService.update(this._unitInput).then(() => {
        this.loading = false;
      });
    }
  }
}
