import {Pipe, PipeTransform} from '@angular/core';
import {ClauseLinkRequired} from 'app/fragment/fragment-link/clause-link-required';

export const CLAUSE_LINK_REQUIRED_TO_DISPLAY_NAME: Readonly<Record<ClauseLinkRequired, string>> = {
  YES: 'Yes',
  NO: 'No',
  NOT_SPECIFIED: 'Not Specified',
  NOT_FOR_CLAUSE_TYPE: 'No',
};

@Pipe({
  name: 'clauseLinkRequiredDisplayName',
})
export class ClauseLinkRequiredToDisplayNamePipe implements PipeTransform {
  private static readonly UNKNOWN_TYPE: string = 'Unknown Clause Link Required Type';

  /**
   * Transforms the clause link required enum into a friendly display name.
   * The {@link CLAUSE_LINK_REQUIRED_TO_DISPLAY_NAME} const is exported for consumers wanting to use this within typescript files.
   *
   * @param type  {ClauseLinkRequired}      The clause link required enum value
   * @returns     {string}                The display name
   */
  public transform(type: ClauseLinkRequired): string {
    return !!CLAUSE_LINK_REQUIRED_TO_DISPLAY_NAME[type]
      ? CLAUSE_LINK_REQUIRED_TO_DISPLAY_NAME[type]
      : ClauseLinkRequiredToDisplayNamePipe.UNKNOWN_TYPE;
  }
}
