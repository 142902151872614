import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {DocumentService} from 'app/services/document.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {DocumentFragment} from '../fragment/types';

@Component({
  selector: 'cars-document-overview',
  templateUrl: './document-overview.component.html',
  styleUrls: [
    './document-overview.component.scss',
    '../section-content/section-proxy/section-pad/section-pad.component.scss',
  ],
})
export class DocumentOverviewComponent implements OnDestroy, AfterViewInit {
  public tooltipDelay: number = environment.tooltipDelay;

  public document: DocumentFragment;

  private _subscriptions: Subscription[] = [];

  constructor(private _documentService: DocumentService, private _cdr: ChangeDetectorRef) {
    this._subscriptions.push(
      this._documentService.onSelection((document: DocumentFragment) => {
        this.document = document;
      })
    );
  }

  public ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }
}
