import {EDITABLE_TEXT_FRAGMENT_TYPES, EquationFragment, FigureFragment, Fragment, FragmentType} from './types';
import {ClauseGroupFragment} from './types/clause-group-fragment';
import {ClauseGroupType} from './types/clause-group-type';

/**
 * Depth First search through the fragment tree to find any fragment that matches the provided types within a max depth
 * @param fragment {Fragment} root fragment (ancestor of desired fragment)
 * @param depth {number} maximum depth to search through the tree
 * @param types {FragmentType[]} Fragment Types to look for
 * @returns {boolean} true if fragment has a descendant of the desired type within the specified depth
 */
export const hasDescendantOfType: (fragment: Fragment, depth: number, ...types: FragmentType[]) => boolean = (
  fragment: Fragment,
  depth: number,
  ...types: FragmentType[]
): boolean => {
  if (depth < 0) {
    return false;
  }

  if (fragment.is(...types)) {
    return true;
  }

  return fragment.children.some((child) => hasDescendantOfType(child, depth - 1, ...types));
};

/**
 * Helper function for recursively getting the first descendant of the given fragment
 * with FragmentType TEXT, SUPERSCRIPT, SUBSCRIPT or MEMO.
 * If we find a descendant of type EQUATION we return the source and if we find a descendant of type
 * FIGURE we return the caption.
 * Returns null if no text fragment descendant is found.
 *
 * @param fragment  {Fragment}  The fragment to search.
 * @returns         {Fragment}  The editable fragment if one exists, otherwise null.
 */
export const getFirstEditableDescendant: (fragment: Fragment) => Fragment = (fragment: Fragment): Fragment => {
  const editableFragment: Fragment = getFragmentIfEditable(fragment);

  if (!!editableFragment) {
    return editableFragment;
  }

  for (const child of fragment.children) {
    const result = getFirstEditableDescendant(child);

    if (result) {
      return result;
    }
  }

  return null;
};

/**
 * Helper function for recursively getting the final descendant of the given fragment
 * with FragmentType TEXT, SUPERSCRIPT, SUBSCRIPT or MEMO.
 * If we find a descendant of type EQUATION we return the source and if we find a descendant of type
 * FIGURE we return the caption.
 * Returns null if no text fragment descendant is found.
 *
 * @param fragment  {Fragment}  The fragment to search.
 * @returns         {Fragment}  The editable fragment if one exists, otherwise null.
 */
export const getFinalEditableDescendant: (fragment: Fragment) => Fragment = (fragment: Fragment): Fragment => {
  const editableFragment: Fragment = getFragmentIfEditable(fragment);

  if (!!editableFragment) {
    return editableFragment;
  }

  for (const child of fragment.children.slice().reverse()) {
    const result = getFinalEditableDescendant(child);

    if (result) {
      return result;
    }
  }

  return null;
};

/**
 * Returns the passed fragment if it has an editable area. If the fragment is a text fragment, it returns the fragment.
 * If the fragment is a figure, it returns the caption and if the fragment is an equation it returns the source text fragment.
 * If the fragment is not editable, it returns null.
 *
 * @param fragment  {Fragment}  The fragment to check
 * @returns         {Fragment}  Either null or an editable part of the fragment.
 */
export const getFragmentIfEditable: (fragment: Fragment) => Fragment = (fragment: Fragment): Fragment => {
  if (fragment.is(...EDITABLE_TEXT_FRAGMENT_TYPES)) {
    return fragment;
  } else if (fragment.is(FragmentType.FIGURE)) {
    return (fragment as FigureFragment).caption;
  } else if (fragment.is(FragmentType.EQUATION) && !(fragment as EquationFragment).inline) {
    return (fragment as EquationFragment).source;
  }

  return null;
};

export const isClauseGroupOfType: (fragment: Fragment, ...clauseGroupTypes: ClauseGroupType[]) => boolean = (
  fragment: Fragment,
  ...clauseGroupTypes: ClauseGroupType[]
): boolean => {
  return (
    fragment.is(FragmentType.CLAUSE_GROUP) &&
    (clauseGroupTypes.length === 0 || clauseGroupTypes.includes((fragment as ClauseGroupFragment).clauseGroupType))
  );
};
