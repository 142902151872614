import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {DocumentLinkComponent} from './document-link.component';

@NgModule({
  declarations: [DocumentLinkComponent],
  imports: [CommonModule, MatIconModule, FormsModule, MatFormFieldModule, MatInputModule],
  exports: [DocumentLinkComponent],
})
export class DocumentLinkModule {}
