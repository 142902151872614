import {Serialisable} from '../utils/serialisable';

/**
 * A class representing an RFC-4122 compliant UUID; see https://tools.ietf.org/html/rfc4122.
 */
export class UUID implements Serialisable {
  private static readonly _FORMAT: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  private static readonly _REGEXP: RegExp =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  private readonly _value;

  /**
   * Generate a pseudorandom UUID; see https://stackoverflow.com/a/2117523.
   *
   * @param {UUID}   The new UUID
   */
  public static random(): UUID {
    const value: string = UUID._FORMAT.replace(/[xy]/g, (char: string) => {
      /* eslint-disable no-bitwise */
      const r: number = self.crypto.getRandomValues(new Uint8Array(1))[0] & 0xf;
      const v: number = char === 'x' ? r : (r & 0x3) | 0x8;
      /* eslint-enable no-bitwise */
      return v.toString(16);
    });

    return new UUID(value);
  }

  /**
   * Construct a new UUID from the given value, returning null if the string is not a
   * valid UUID.
   *
   * @param value {string}   The value
   * @returns     {UUID}     The new UUID or null
   */
  public static orNull(value: string): UUID {
    return UUID._REGEXP.test(value) ? new UUID(value) : null;
  }

  /**
   * Construct a new UUID from the given value, throwing an error if the value string
   * is not a valid UUID.
   *
   * @param value {string}   The value
   * @returns     {UUID}     The new UUID or null
   * @throws      {Error}    If the UUID is invalid
   */
  public static orThrow(value: string): UUID {
    if (!UUID._REGEXP.test(value)) {
      throw new Error(`The string '${value}' is not a valid UUID!`);
    }

    return new UUID(value);
  }

  // Private constructor; use one of the factories above.
  private constructor(value: string) {
    this._value = value;
  }

  /**
   * Public getter for _value.
   *
   * @returns {string}   The string representation
   */
  public get value(): string {
    return this._value;
  }

  /**
   * Returns true if two UUIDs are equal.
   *
   * @param other {UUID}      The other UUID
   * @returns     {boolean}   True if equal
   */
  public equals(other: UUID): boolean {
    return !!other && this._value === other._value;
  }

  /**
   * @inheritdoc
   */
  public serialise(): string {
    return this._value;
  }
}
