import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {RequiredNonWhitespaceModule} from 'app/utils/directives/required-non-whitespace.module';
import {DebounceSearchModule} from '../debounce-search/debounce-search.module';
import {ReferenceListModule} from './reference-list/reference-list.module';
import {ReferencesComponent} from './references.component';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReferenceListModule,
    MatButtonModule,
    MatTooltipModule,
    FormsModule,
    RequiredNonWhitespaceModule,
    AltAccessibilityModule,
    CarsPermissionPipesModule,
    DebounceSearchModule,
    MatProgressSpinnerModule,
  ],
  declarations: [ReferencesComponent],
  exports: [ReferencesComponent],
})
export class ReferencesModule {}
