import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {BaseService} from 'app/services/base.service';
import {FragmentService} from 'app/services/fragment.service';
import {SectionService} from 'app/services/section.service';
import {environment} from 'environments/environment';
import {Observable, throwError} from 'rxjs';
import {DocumentFragment, Fragment, SectionFragment} from '../../fragment/types';
import {UUID} from '../../utils/uuid';
import {ClauseRow} from './clause-row';
import {LinkTableProgressService} from './link-table-progress-service';

export interface TreeCache {
  documents: Record<string, Promise<DocumentFragment>>;
  sections: Record<string, Promise<SectionFragment>>;
}

export interface JsonChangelogLink {
  publishedRangeId: string;
  authoredRangeId: string;
  comment: string;
  type: string;
}

export interface JsonRange {
  startFragmentId: string;
  endFragmentId: string;
  documentId: string;
  sectionId: string;
  startOffset: number;
  endOffset: number;
  deletionComment: string;
  documentTitle: string;
  value: string;
  deleted: boolean;
}

export interface JsonClause {
  children: any[];
  documentId: string;
  sectionId: string;
  parentId: string;
  id: string;
  clauseType: string;
  value: string;
  type: string;
}

export interface JsonLinkRow {
  authoredRange: JsonRange;
  link: JsonChangelogLink;
}

export interface JsonRangeRow {
  children: JsonLinkRow[];
  publishedRange: JsonRange;
}

export interface JsonClauseRow {
  children: JsonRangeRow[];
  clause: JsonClause;
  sectionTitle: string;
}

@Injectable({
  providedIn: 'root',
})
export class LinkTableService extends BaseService {
  private static readonly CHANGELOG_LINK_TABLE_URL: string = `${environment.apiHost}/changelogs/linktable`;

  private exitRoute: string[] = [];

  constructor(
    private fragmentService: FragmentService,
    private sectionService: SectionService,
    private http: HttpClient,
    public linkTableProgressService: LinkTableProgressService,
    protected snackbar: MatSnackBar
  ) {
    super(snackbar);
  }

  /**
   * Fetch the link table for this published doc using changelogcontroller endpoint CHANGELOG_LINK_TABLE_URL
   */
  public fetchChangelogLinkTable(publishedId: UUID): Promise<ClauseRow[]> {
    if (!publishedId) {
      return Promise.reject(null);
    }
    const cache: TreeCache = {documents: {}, sections: {}};
    this.linkTableProgressService.set(2);

    return this.http
      .get(`${LinkTableService.CHANGELOG_LINK_TABLE_URL}`, {
        params: {publishedId: publishedId.value},
      })
      .toPromise()
      .then((response: JsonClauseRow[]) => {
        this.linkTableProgressService.set(10);
        return this.deserialiseLinkTable(response, cache);
      })
      .catch((response) => {
        return this.handleError(response, 'Cannot load link table for this published document').toPromise();
      });
  }

  public setExitRoute(route: string[]): void {
    this.exitRoute = route;
  }

  public getExitRoute(): string[] {
    return this.exitRoute;
  }

  private deserialiseLinkTable(json: JsonClauseRow[], cache: TreeCache): Promise<ClauseRow[]> {
    const results: ClauseRow[] = [];
    return this.loadAllRequiredDocumentSectionsIntoCache(json, cache)
      .then(() => {
        const percentPerClauseRow: number = 10 / json.length;
        json.forEach((row: JsonClauseRow) => {
          this.linkTableProgressService.add(percentPerClauseRow);
          const clauseRow: ClauseRow = ClauseRow.deserialise(row, this.fragmentService);
          results.push(clauseRow);
        });
        return results;
      })
      .catch((response) => {
        return this.handleError(response, 'Cannot deserialise link table for this published document').toPromise();
      });
  }

  private loadAllRequiredDocumentSectionsIntoCache(
    json: JsonClauseRow[],
    cache: TreeCache
  ): Promise<SectionFragment[]> {
    const promises: Promise<SectionFragment>[] = [];
    const percentPerLinkRow: number = this.linkTableProgressService.getLinkRowPercent(json);
    json.forEach((clauseRow: JsonClauseRow) => {
      clauseRow.children.forEach((rangeRow: JsonRangeRow) => {
        rangeRow.children.forEach((linkRow: JsonLinkRow) => {
          if (linkRow.authoredRange && linkRow.authoredRange.documentId && linkRow.authoredRange.sectionId) {
            promises.push(
              this.getDocument(UUID.orNull(linkRow.authoredRange.documentId), cache)
                .then(() => {
                  return this.getSection(UUID.orNull(linkRow.authoredRange.sectionId), cache)
                    .then((section: SectionFragment) => {
                      this.linkTableProgressService.add(percentPerLinkRow);
                      return section;
                    })
                    .catch((response) => {
                      this.linkTableProgressService.add(percentPerLinkRow);
                      return this.handleError(response, 'Cannot load authored section').toPromise();
                    });
                })
                .catch((response) => {
                  this.linkTableProgressService.add(percentPerLinkRow);
                  return this.handleError(response, 'Cannot load authored document').toPromise();
                })
            );
          }
        });
      });
    });
    return Promise.all(promises);
  }

  private getDocument(documentId: UUID, cache: TreeCache): Promise<DocumentFragment> {
    if (documentId) {
      if (!cache.documents[documentId.value]) {
        cache.documents[documentId.value] = this.fragmentService
          .fetchLatest(documentId, {depth: 1})
          .then((fragment: Fragment) => fragment as DocumentFragment);
      }
      return cache.documents[documentId.value];
    }
  }

  private getSection(sectionId: UUID, cache: TreeCache): Promise<SectionFragment> {
    if (!cache.sections[sectionId.value]) {
      cache.sections[sectionId.value] = this.sectionService.load(sectionId, {
        projection: 'FULL_TREE',
      });
    }
    return cache.sections[sectionId.value];
  }

  private handleError(response: HttpErrorResponse | any, message: string = null): Observable<never> {
    this._handleError(response, message, 'changelog-error');
    Logger.error('changelog-error', response);
    return throwError(null);
  }
}
