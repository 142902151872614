import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {ClauseFragment, Fragment, FragmentType} from 'app/fragment/types';
import {Discussion} from 'app/sidebar/discussions/discussions';
import {BaseService} from './base.service';
import {DiscussionsService} from './discussions.service';

@Injectable({
  providedIn: 'root',
})
export class FragmentDeletionValidationService extends BaseService {
  constructor(
    private _discussionsService: DiscussionsService,
    private _dialog: MatDialog,
    protected _snackbar: MatSnackBar
  ) {
    super(_snackbar);
  }

  /**
   * Checks whether a deletion operation should be accepted, returns true if no fragments have background commentary or
   * discussions, or if the user accepts a modal to accept the deletion. Iterates into the fragments and checks all
   * descendants against the same criteria.
   *
   * @param fragments
   * @returns
   */
  public shouldDeleteFragmentsWithSubtrees(fragments: Fragment | Fragment[]): Promise<boolean> {
    fragments = fragments instanceof Array ? fragments : [fragments];

    const fragmentsToCheck: Fragment[] = [];
    fragments.forEach((frag: Fragment) =>
      frag.iterateDown(null, null, (f: Fragment) => {
        fragmentsToCheck.push(f);
      })
    );

    return this._shouldDeleteFragments(fragmentsToCheck);
  }

  /**
   * Checks whether a deletion operation should be accepted, returns true if no fragments have background commentary or
   * discussions, or if the user accepts a modal to accept the deletion. Only checks the list of fragments passed
   * (without descendants) against the criteria.
   *
   * @param fragments
   * @returns
   */
  public shouldDeleteFragmentsWithoutSubtrees(fragments: Fragment | Fragment[]): Promise<boolean> {
    fragments = fragments instanceof Array ? fragments : [fragments];

    return this._shouldDeleteFragments(fragments);
  }

  private async _shouldDeleteFragments(fragments: Fragment[]): Promise<boolean> {
    const clausesHaveBackground: boolean = fragments.some(
      (frag: Fragment) => frag.is(FragmentType.CLAUSE) && !!(frag as ClauseFragment).background?.length
    );

    if (clausesHaveBackground || (await this._getFragmentsWithDiscussions(fragments)).length > 0) {
      return this._openWarningModal();
    }

    return true;
  }

  private _getFragmentsWithDiscussions(fragmentsToCheck: Fragment[]): Promise<Fragment[]> {
    return Promise.all(
      fragmentsToCheck.map((frag: ClauseFragment) => this._discussionsService.getDiscussions(frag))
    ).then((discussions: Discussion[][]) => {
      return fragmentsToCheck.filter((clause: ClauseFragment, index: number) => discussions[index]?.length > 0);
    });
  }

  /**
   * Checks if the clause can safely be deleted, or if it has something that should be validated. Used where showing a
   * modal to the user would introduce weird flows (e.g. adding a SFR immediately showing a modal that data will be
   * lost).
   *
   * @param fragments The list of fragments to check, does not iterate down
   * @returns Whether any fragment has something that would usually prompt the modal
   */
  public fragmentHasBackgroundCommentaryOrDiscussions(fragments: Fragment[]): boolean {
    const clausesHaveBackground: boolean = fragments.some(
      (frag: Fragment) => frag.is(FragmentType.CLAUSE) && !!(frag as ClauseFragment).background?.length
    );

    const fragmentsHaveDiscussions: boolean = fragments.some(
      (frag) => this._discussionsService.getCachedDiscussionsForFragment(frag, false)?.length > 0
    );

    return clausesHaveBackground || fragmentsHaveDiscussions;
  }

  /**
   * Displays a warning modal to the user that some content has background commentary or discussions that will be deleted.
   *
   * @returns A promise resolving to whether the deletion has been accepted
   */
  private _openWarningModal(): Promise<boolean> {
    return this._dialog
      .open(DialogComponent, {
        ariaLabel: `Warning: Existing background commentary and/or discussions will be lost`,
        data: {
          title: `Warning: Existing background commentary and/or discussions will be lost`,
          // eslint-disable-next-line max-len
          message: `Deleting the selected content will result in existing background commentary and/or discussions being removed.  These cannot be recovered in the future.  Are you sure you want to delete the selected content?`,
          closeActions: [
            {title: 'Delete', tooltip: 'Delete', response: true},
            {title: 'Cancel', tooltip: 'Cancel deletion', response: false},
          ],
        },
      })
      .afterClosed()
      .toPromise();
  }
}
