import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DialogModule} from 'app/dialog/dialog/dialog.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {SuiteToDisplayNameModule} from 'app/utils/pipes/suite-to-display-name.module';
import {DocumentCreatorModule} from './document-creator/document-creator.module';
import {DocumentsTableComponent} from './documents-table.component';

@NgModule({
  imports: [
    CommonModule,
    DocumentCreatorModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    MatButtonModule,
    CarsErrorHandlingModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatPaginatorModule,
    FormsModule,
    DialogModule,
    SuiteToDisplayNameModule,
    CarsPermissionPipesModule,
  ],
  declarations: [DocumentsTableComponent],
  exports: [DocumentsTableComponent],
})
export class DocumentsTableModule {}
