<div class="content">
  <div class="info">
    <span class="author">
      <cars-user [userId]="version.lastModifiedBy"></cars-user>
    </span>
    <span class="datestamp">
      -
      {{ (this.version.lastModifiedAt | date: dateFormat) || '(unknown)' }}
    </span>
  </div>
  <cars-clause
    [content]="mergedFragment"
    [readOnly]="true"
    [showCurrentClause]="false"
    [showMarginIcons]="false"
  ></cars-clause>
</div>
