import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {SectionCommentsComponent} from 'app/section-content/section-comments/section-comments.component';

@Injectable({
  providedIn: 'root',
})
export class SectionCommentsGuardService implements CanDeactivate<SectionCommentsComponent> {
  /**
   * Displays a warning to the user if they have un-saved staged comments.
   *
   * @inheritdoc
   */
  public canDeactivate(component: SectionCommentsComponent): boolean {
    return component.canDeactivate() || component.submitting
      ? true
      : confirm(
          'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.'
        );
  }
}
