<div class="header">
  <h3>This page displays the 100 most recent error messages received by users.</h3>
  <button mat-button (click)="getLogs()" aria-label="refresh user logs">Refresh <mat-icon>refresh</mat-icon></button>
</div>

<ng-container *ngFor="let log of logs">
  <table class="log">
    <tr>
      <td class="label">state:</td>
      <td>{{ log.state }}</td>
    </tr>
    <tr>
      <td class="label">key:</td>
      <td>{{ log.key }}</td>
    </tr>
    <tr>
      <td class="label">git SHA:</td>
      <td>{{ log.gitHash }}</td>
    </tr>
    <tr>
      <td class="label">message:</td>
      <td>
        <pre>{{ log.message }}</pre>
      </td>
    </tr>
    <tr>
      <td class="label">when:</td>
      <td>{{ log.createdAt | timeAgo }}</td>
    </tr>
    <tr>
      <td class="label">user:</td>
      <td>
        <cars-user [userId]="log.createdBy"></cars-user>
      </td>
    </tr>
    <tr>
      <td class="label">location:</td>
      <td>{{ log.url }}</td>
    </tr>
  </table>
</ng-container>
