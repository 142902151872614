<div *ngIf="!selectedAggregate" class="loading-message">Please select a document in the left-hand navigation bar.</div>
<ng-container *ngIf="selectedAggregate">
  <ng-container *ngIf="loading; else selectedSectionInfo">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <div class="loading-message">Loading sections</div>
  </ng-container>
  <ng-template #selectedSectionInfo>
    <span>{{ selectedAggregate?.documentTitle }}</span>
    <cars-review-status-toggle
      *ngIf="selectedAggregate.isByUser() && !selectedAggregate.isLive() && selectedAggregate.userReviewState"
      [userReviewState]="selectedAggregate.userReviewState"
    ></cars-review-status-toggle>
    <cars-review-status-table
      *ngIf="selectedAggregate.isAll() && !selectedAggregate.isLive() && selectedAggregate.userReviewStates"
      [userReviewStates]="selectedAggregate.userReviewStates"
    ></cars-review-status-table>

    <div class="table-wrapper">
      <mat-table multiTemplateDataRows [dataSource]="sections">
        <ng-container matColumnDef="section">
          <mat-header-cell *matHeaderCellDef>Section</mat-header-cell>
          <mat-cell *matCellDef="let section">
            <div>{{ section.sectionIndex }} {{ section.sectionTitle }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number_of_discussions">
          <mat-header-cell *matHeaderCellDef>Number of Discussions</mat-header-cell>
          <mat-cell *matCellDef="let section">
            {{ getNumberOfDiscussions(section) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="all_resolved">
          <mat-header-cell *matHeaderCellDef>All Resolved?</mat-header-cell>
          <mat-cell *matCellDef="let section">
            <cars-resolved-icon
              [total]="getNumberOfDiscussions(section)"
              [resolved]="getNumberOfResolvedDiscussions(section)"
              [type]="'DISCUSSION'"
            ></cars-resolved-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let section" [attr.colspan]="displayedColumns.length">
            <div *ngIf="isSelected(section)" [@detailExpand]="isSelected(section) ? 'expanded' : 'collapsed'">
              <cars-section-table
                [section]="section"
                [versionId]="selectedAggregate.versionId"
                [dashboardType]="dashboardType"
              ></cars-section-table>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let section; columns: displayedColumns"
          [class.table-row-selected]="section.sectionId === selectedSection"
          (click)="selectRow(section)"
          tabindex="0"
          (keyup.enter)="selectRow(section)"
          [attr.aria-label]="
            isSelected(section) ? 'Collapse section ' + section.sectionTitle : 'Expand section ' + section.sectionTitle
          "
        ></mat-row>
        <mat-row
          *matRowDef="let result; columns: ['expandedDetail']"
          class="expandable-row"
          [class.expanded]="isSelected(result)"
        ></mat-row>
      </mat-table>
    </div>
  </ng-template>
</ng-container>
