import {UUID} from '../../utils/uuid';
import {DiffOperation, FragmentDiff} from './fragment-diff';

/**
 * A class representing a set of diffs for a single fragment.
 *
 * @param id {UUID}   The ID of the fragment
 */
export class FragmentDiffSet {
  public readonly id: UUID;
  private _parentId: UUID = null;
  private _diffs: FragmentDiff[] = [];
  private _versionRequest: FragmentDiff = null;

  constructor(id: UUID) {
    if (!id) {
      throw new Error(`Tried to instantiate a FragmentDiffSet with null ID!`);
    }

    this.id = id;
  }

  /**
   * Return the number of diffs of the given operations.
   *
   * @param ops {DiffOperation[]}   Optional operation types
   * @returns   {number}            The total diffs
   */
  public length(...ops: DiffOperation[]): number {
    if (ops && ops.length) {
      const operations = ops.reduce((opReducer, op) => {
        opReducer[op] = true;
        return opReducer;
      }, {});

      let counter = 0;
      for (const diff of this._diffs) {
        if (operations[diff.operation]) {
          counter += 1;
        }
      }
      return counter;
    } else {
      return this._diffs.length;
    }
  }

  /**
   * Push an array of diffs onto this diffset.  All diffs must have the same ID as
   * this diffset.
   *
   * @param diffs {FragmentDiff[]}   The diffs to push
   */
  public push(...diffs: FragmentDiff[]): void {
    diffs.forEach((diff: FragmentDiff) => {
      if (this.id.equals(diff.id)) {
        diff.operation === DiffOperation.VERSION ? (this._versionRequest = diff) : this._diffs.push(diff);
      }
    });
  }

  public parent(): UUID {
    return this._parentId;
  }

  /**
   * Simplify this diffset by removing and combining redundant diffs.
   */
  public collate(): FragmentDiff {
    let lastOperation: FragmentDiff = null;

    this._parentId = null;
    for (const diff of this._diffs.splice(0).reverse()) {
      if (diff.operation === DiffOperation.UPDATE && (diff.fields === null || Object.keys(diff.fields).length === 0)) {
        continue; // Throw out empty updates
      }

      if (lastOperation === null) {
        lastOperation = diff;
        if (lastOperation.fields && lastOperation.fields.parentId) {
          this._parentId = lastOperation.fields.parentId;
        }
        continue;
      }

      switch (lastOperation.operation) {
        case DiffOperation.CREATE:
          if (diff.operation === DiffOperation.DELETE || diff.operation === DiffOperation.UPDATE) {
            lastOperation = lastOperation.clone();
            lastOperation.operation = DiffOperation.UPDATE;
          }
          break;
        case DiffOperation.UPDATE:
          diff.assimilate(lastOperation);
          lastOperation = diff;
          break;
        case DiffOperation.DELETE:
          if (diff.operation === DiffOperation.CREATE) {
            lastOperation = null;
          }
          break;
      }

      if (this._parentId === null && diff.fields && diff.fields.parentId) {
        this._parentId = diff.fields.parentId;
      }
    }

    if (lastOperation) {
      this._diffs.push(lastOperation);
    }

    return lastOperation;
  }

  /**
   * Collate and extract all diffs in this diffset.  The diffset is empty following this operation.
   *
   * @returns { FragmentDiff[] } The diffs
   */
  public extract(): FragmentDiff[] {
    if (this._diffs.length === 0 && this._versionRequest) {
      return [this._versionRequest];
    } else {
      const diffs: FragmentDiff[] = [this.collate()];
      this._diffs.splice(0);
      if (this._versionRequest) {
        diffs.push(this._versionRequest);
      }
      return diffs;
    }
  }

  /**
   * Get the current pending update operation
   *
   * @returns {FragmentDiff} The last pending update operation or null
   */
  public getPendingUpdate(): FragmentDiff {
    const diff: FragmentDiff = this.collate();
    if (diff) {
      this._diffs.push(diff);
      return diff.operation === DiffOperation.UPDATE ? diff.clone() : null;
    }
    return null;
  }
}
