import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {CarsAction} from 'app/permissions/types/permissions';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-version-publication-options',
  templateUrl: './version-publication-options.component.html',
  styleUrls: ['./version-publication-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionPublicationOptionsComponent implements OnInit, OnDestroy {
  @Input() public documentId: UUID;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public dateFormControl: UntypedFormControl;

  public versionTag: VersionTag;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _versioningService: VersioningService,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.dateFormControl = new UntypedFormControl(
      {
        value: null,
      },
      Validators.required
    );

    this._subscriptions.push(
      this._versioningService.getCurrentViewVersionTagObsStream().subscribe((versionTag: VersionTag) => {
        this.versionTag = versionTag;

        this.dateFormControl.setValue(
          this.versionTag?.dateOfPublication ? new Date(this.versionTag?.dateOfPublication) : null,
          {emitEvent: false}
        );

        this._cdr.markForCheck();
      }),

      this.dateFormControl.valueChanges.subscribe((value: Date) => {
        this._versioningService.updateDateOfPublication(this.versionTag.versionId, value.getTime());
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  public publish(): void {
    this._dialog
      .open(DialogComponent, {
        ariaLabel: 'Mark version as published dialog',
        width: '50%',
        autoFocus: false,
        data: {
          title: `Confirmation required`,
          message: `Please confirm that you want to mark this version as ‘Published’.  This action is irreversible and
           a version cannot be unpublished.`,
          closeActions: [
            {title: 'Cancel', tooltip: 'Cancel marking version as published', response: null},
            {
              title: 'Publish',
              tooltip: 'Mark this version as published',
              response: VersionTagType.FOR_PUBLICATION_PUBLISHED,
              color: 'primary',
            },
          ],
        },
      })
      .afterClosed()
      .subscribe(this._transitionVersionTag.bind(this));
  }

  public changesRequired(): void {
    this._dialog
      .open(DialogComponent, {
        ariaLabel: 'Mark version as changes required dialog',
        width: '50%',
        autoFocus: false,
        data: {
          title: `Confirmation required`,
          message: `Please confirm that you want to mark this version as ‘Changes required’. You will need to create a
           new version of type ‘For approval/authorisation’ to publish this document once updates have been completed.`,
          closeActions: [
            {title: 'Cancel', tooltip: 'Cancel marking version as changes required', response: null},
            {
              title: 'Changes required',
              tooltip: 'Mark this version as changes required',
              response: VersionTagType.FOR_PUBLICATION_CHANGES_REQUIRED,
              color: 'primary',
            },
          ],
        },
      })
      .afterClosed()
      .subscribe(this._transitionVersionTag.bind(this));
  }

  private _transitionVersionTag(versionTagType: VersionTagType): void {
    if (versionTagType) {
      this._versioningService.transitionVersionTag(this.versionTag.versionId, versionTagType);
    }
  }
}
