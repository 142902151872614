import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DocInfoValueToDisplayNameModule} from 'app/utils/pipes/doc-info-value-to-display-name.module';
import {EnumSelectInputComponent} from './enum-select-input.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatTooltipModule,
    DocInfoValueToDisplayNameModule,
  ],
  declarations: [EnumSelectInputComponent],
  exports: [EnumSelectInputComponent],
})
export class EnumSelectInputModule {}
