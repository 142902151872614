import {Token, TokenType} from 'app/fragment/core/parser/token';
import {FragmentType, SectionType} from 'app/fragment/types';

interface TypeToValue {
  fragmentType: FragmentType | TokenType;
  value: string;
}

export class HTMLFragmentMapper {
  private static readonly ALL_SECTION_CLASS_MAPPINGS: Record<string, TypeToValue> = {};

  private static readonly INTRODUCTORY_CLASS_MAPPINGS: Record<string, TypeToValue> = {
    ['AppendixH1']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_1'},
    ['AppendixH2']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_1'},
    ['AppendixH3']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_1'},
    ['AppendixH4']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_1'},
    ['H2']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_1'},
    ['H3']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_2'},
    ['MsoNormal']: {fragmentType: FragmentType.CLAUSE, value: 'NORMAL'},
  };

  private static readonly NORMATIVE_CLASS_MAPPINGS: Record<string, TypeToValue> = {
    ['Req-Performanceshall']: {
      fragmentType: FragmentType.CLAUSE,
      value: 'REQUIREMENT',
    },
    ['Req-statutorymust']: {
      fragmentType: FragmentType.CLAUSE,
      value: 'REQUIREMENT',
    },
    ['Req-Methodshall']: {
      fragmentType: FragmentType.CLAUSE,
      value: 'REQUIREMENT',
    },
    ['Ad-Recommendationshould']: {
      fragmentType: FragmentType.CLAUSE,
      value: 'ADVICE',
    },
    ['Ad-Permissivemay']: {
      fragmentType: FragmentType.CLAUSE,
      value: 'ADVICE',
    },
    ['NOTE']: {fragmentType: FragmentType.CLAUSE, value: 'NOTE'},
    ['Memo']: {fragmentType: FragmentType.CLAUSE, value: 'NOTE'},
    ['H2']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_1'},
    ['H3']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_2'},
    ['AppendixH1']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_1'},
    ['AppendixH2']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_2'},
    ['AppendixH3']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_2'},
    ['AppendixH4']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_2'},
  };

  private static readonly APPENDIX_CLASS_MAPPINGS: Record<string, TypeToValue> = {
    ['AppendixH1']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_1'},
    ['AppendixH2']: {
      fragmentType: FragmentType.CLAUSE,
      value: 'HEADING_2',
    },
    ['AppendixH3']: {
      fragmentType: FragmentType.CLAUSE,
      value: 'HEADING_3',
    },
    ['AppendixH4']: {
      fragmentType: FragmentType.CLAUSE,
      value: 'HEADING_3',
    },
    ['H2']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_1'},
    ['H3']: {fragmentType: FragmentType.CLAUSE, value: 'HEADING_2'},
  };

  /**
   * Checks if any of the given keys can be mapped to a fragment type for a given section type.
   *
   * @param sectionType {SectionType} Section type for
   * @param keys        {string[]}    Keys to check
   * @returns           {boolean}     True if there is a fragment mapping
   */
  public static hasMapping(sectionType: SectionType, ...keys: string[]): boolean {
    return (
      (this.hasIntroductoryMapping(...keys) && sectionType === SectionType.INTRODUCTORY) ||
      (this.hasNormativeMapping(...keys) && sectionType === SectionType.NORMATIVE) ||
      (this.hasAppendixMapping(...keys) && sectionType === SectionType.APPENDIX) ||
      this.hasAllSectionMapping(...keys)
    );
  }

  public static hasAnyMapping(...keys: string[]): boolean {
    return (
      this.hasIntroductoryMapping(...keys) ||
      this.hasNormativeMapping(...keys) ||
      this.hasAppendixMapping(...keys) ||
      this.hasAllSectionMapping(...keys)
    );
  }

  public static hasAllSectionMapping(...keys: string[]): boolean {
    return keys.some((key: string) => !!HTMLFragmentMapper.ALL_SECTION_CLASS_MAPPINGS[key]);
  }

  public static hasIntroductoryMapping(...keys: string[]): boolean {
    return keys.some((key: string) => !!HTMLFragmentMapper.INTRODUCTORY_CLASS_MAPPINGS[key]);
  }

  public static hasNormativeMapping(...keys: string[]): boolean {
    return keys.some((key: string) => !!HTMLFragmentMapper.NORMATIVE_CLASS_MAPPINGS[key]);
  }

  public static hasAppendixMapping(...keys: string[]): boolean {
    return keys.some((key: string) => !!HTMLFragmentMapper.APPENDIX_CLASS_MAPPINGS[key]);
  }

  /**
   * Returns a token created using the relevant mapper for the given section.
   * Takes the first key to match in the given list.
   *
   * @param sectionType {SectionType} Section type
   * @param keys        {string[]}    Key values to check
   * @returns           {Token}       Token created
   */
  public static getMapping(sectionType: SectionType, ...keys: string[]): Token {
    let mapper: Record<string, TypeToValue>;
    switch (sectionType) {
      case SectionType.INTRODUCTORY:
        {
          mapper = HTMLFragmentMapper.INTRODUCTORY_CLASS_MAPPINGS;
        }
        break;
      case SectionType.NORMATIVE:
        {
          mapper = HTMLFragmentMapper.NORMATIVE_CLASS_MAPPINGS;
        }
        break;
      case SectionType.APPENDIX:
        {
          mapper = HTMLFragmentMapper.APPENDIX_CLASS_MAPPINGS;
        }
        break;
      default:
        {
          mapper = HTMLFragmentMapper.ALL_SECTION_CLASS_MAPPINGS;
        }
        break;
    }

    const key: string = keys.find((k: string) => !!mapper[k]);
    const fragmentToValue: TypeToValue = mapper[key];
    return fragmentToValue ? new Token(fragmentToValue.fragmentType, fragmentToValue.value) : null;
  }

  protected constructor() {}
}
