<div class="unit-selection-panel">
  <ng-container *ngIf="!showUnitSelectionOptions(); else selectUnits">
    <div class="placeholder">
      The selected content is not a units user input region. This functionality is only available for content of that
      type.
    </div>
  </ng-container>

  <ng-template #selectUnits>
    <div class="unit-selection-area">
      <cars-debounce-search
        placeholder="Filter units"
        hint="Search units"
        (onSearchChange)="updateSearchTerm($event)"
      ></cars-debounce-search>
      <cars-unit-list
        [unitsList]="units"
        [totalUnitsCount]="totalUnitsCount"
        [searchTerm]="searchTerm"
        (selection)="selectUnit($event)"
        (onSearch)="updateUnitsList($event)"
      ></cars-unit-list>
    </div>
  </ng-template>
</div>
