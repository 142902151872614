import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DocumentRole} from 'app/documents/document-data';
import {DocumentFragment} from 'app/fragment/types';
import {VersionTag} from 'app/interfaces';
import {CarsAction} from 'app/permissions/types/permissions';
import {DisabledUserService} from 'app/services/user/disabled-user.service';
import {RoleService} from 'app/services/user/role.service';
import {User} from 'app/user/user';
import {UUID} from 'app/utils/uuid';
import {CurrentView} from 'app/view/current-view';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {DocumentOverviewService} from '../document-overview.service';

export interface AutocompleteProperties {
  title: string;
  noUsersFoundMessage: string;
  mdIcon: string;
  canAssignUser: CarsAction;
}

export interface UserItem {
  user: User;
  inDocument: boolean;
}

type UserPoolMap = Partial<Record<DocumentRole, UserItem[]>>;

export const DOCUMENT_ROLE_AUTOCOMPLETE_PROPERTIES: Readonly<Partial<Record<DocumentRole, AutocompleteProperties>>> = {
  [DocumentRole.LEAD_AUTHOR]: {
    title: 'Lead author',
    mdIcon: 'create',
    noUsersFoundMessage: 'Your search does not match any user with permission to be a lead author.',
    canAssignUser: CarsAction.AUTHOR_DOCUMENT,
  },
  [DocumentRole.AUTHOR]: {
    title: 'Authors',
    noUsersFoundMessage: 'Your search does not match any user with permission to be an author.',
    mdIcon: 'create',
    canAssignUser: CarsAction.AUTHOR_DOCUMENT,
  },
  [DocumentRole.REVIEWER]: {
    title: 'Reviewers',
    noUsersFoundMessage: 'Your search does not match any user with permission to be a reviewer.',
    mdIcon: 'assignment',
    canAssignUser: CarsAction.ASSIGN_REVIEWERS,
  },
  [DocumentRole.PEER_REVIEWER]: {
    title: 'Peer reviewers',
    noUsersFoundMessage: 'Your search does not match any user with permission to be a peer reviewer.',
    mdIcon: 'assignment',
    canAssignUser: CarsAction.ASSIGN_REVIEWERS,
  },
};

@Component({
  selector: 'cars-role-assignments',
  templateUrl: './role-assignments.component.html',
  styleUrls: ['./role-assignments.component.scss'],
})
export class RoleAssignmentsComponent implements OnInit, OnDestroy {
  @Input() document: DocumentFragment;
  @Input() currentView: CurrentView;

  public readonly CarsAction: typeof CarsAction = CarsAction;

  public readonly assignableRoles: Readonly<DocumentRole[]> = [
    DocumentRole.LEAD_AUTHOR,
    DocumentRole.AUTHOR,
    DocumentRole.REVIEWER,
    DocumentRole.PEER_REVIEWER,
  ];

  public readonly tooltipDelay: number = environment.tooltipDelay;

  /**
   * A map from document role to the sorted list of all users, each containing a flag of whether they are assigned to
   * the role in the document. Note this ordering is reused by the children {@link UserListAutocompleteComponent} for
   * both the autocomplete list and the list of assigned users.
   */
  public userPools: UserPoolMap = {};

  public userAuthoringStatuses: Record<string, boolean> = {};

  private _subscriptions: Subscription[] = [];

  constructor(
    private _disabledUserService: DisabledUserService,
    private _roleService: RoleService,
    private _documentOverviewService: DocumentOverviewService
  ) {}

  public ngOnInit(): void {
    this.loadUserPool();

    this._subscriptions.push(
      this._disabledUserService.onUserStatuses().subscribe((statuses) => {
        this.userAuthoringStatuses = statuses;
      }),
      this._documentOverviewService.onAssignmentChange().subscribe(() => {
        this._updateUserPools();
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public getAutocompleteProperties(role: DocumentRole): AutocompleteProperties {
    return DOCUMENT_ROLE_AUTOCOMPLETE_PROPERTIES[role];
  }

  public show(role: DocumentRole): boolean {
    if (role === DocumentRole.REVIEWER) {
      const version: VersionTag = this.currentView ? this.currentView.versionTag : null;
      return version ? version.availableToReview : true;
    }

    return true;
  }

  public disableAll(): void {
    this._documentOverviewService.disableAllOnDocument(this.document);
  }

  private _updateUserPools(): void {
    const allAssignedUsers: UUID[] = this._getAllAssignedUsers();
    this.assignableRoles.forEach((role: DocumentRole) =>
      this._markAssignedUsers(allAssignedUsers, this.userPools[role])
    );
  }

  public loadUserPool(): void {
    const userPoolMap: UserPoolMap = {};
    const allAssignedUsers: UUID[] = this._getAllAssignedUsers();
    Promise.all(this.assignableRoles.map((role: DocumentRole) => this._roleService.getUsersByRole(role))).then(
      (users: User[][]) => {
        this.userPools = this.assignableRoles.reduce((returnMap: UserPoolMap, role: DocumentRole, index: number) => {
          const userItems: UserItem[] = users[index].map((user: User) => ({
            user,
            inDocument: false,
          }));
          returnMap[role] = userItems.sort(this._compareUserNames.bind(this));
          this._markAssignedUsers(allAssignedUsers, returnMap[role]);
          return returnMap;
        }, userPoolMap);
      }
    );
  }

  private _markAssignedUsers(allAssignedUsers: UUID[], possibleUsers: UserItem[] = []): void {
    const userItems: UserItem[] = possibleUsers ? possibleUsers : [];
    userItems.forEach(
      (userItem: UserItem) =>
        (userItem.inDocument = allAssignedUsers.findIndex((id: UUID) => id && id.equals(userItem.user.id)) > -1)
    );
  }

  private _getAllAssignedUsers(): UUID[] {
    return this.assignableRoles.reduce(
      (returnArray: UUID[], role: DocumentRole) => returnArray.concat(this._userIdsInRole(role)),
      []
    );
  }

  private _userIdsInRole(role: DocumentRole): UUID[] {
    return [].concat(this.document.documentData[DocumentOverviewService.ROLE_TO_DOCUMENT_DATA[role]]);
  }

  /**
   * Sorts the users alphabetically by their names.
   *
   * @param a {User} First user
   * @param b {User} Second user
   */
  private _compareUserNames(a: UserItem, b: UserItem): number {
    return User.compareNames(a.user, b.user);
  }
}
