<div class="box">
  <div *ngFor="let option of clauseGroupOptions" class="clause-group-option">
    <mat-checkbox [(ngModel)]="option.checked" [disabled]="loading">
      <div class="bold">
        {{ getClauseGroupType(option.clauseGroupId) | standardFormatTypeDisplayName }}
      </div>
      <cars-clause-group-link-preview [clauseGroupId]="option.clauseGroupId"> </cars-clause-group-link-preview>
    </mat-checkbox>
  </div>
  <div *ngIf="clauseGroupOptions.length === 0" class="no-valid-target-groups">
    There are no {{ clauseLinkType | clauseLinkTypeDisplayName | lowercase }} requirements within the current section.
  </div>
</div>

<div class="buttons">
  <button mat-button (click)="cancelChanges()" aria-label="Cancel selecting requirements to link" [disabled]="loading">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="saveSelection()"
    attr.aria-label="Save selection of requirements to link to clause {{ clause | fragmentIndex }}"
    [disabled]="loading"
    enableDefaultFocus
  >
    Save selection
  </button>
</div>
