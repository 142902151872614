import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SortDirection} from '@angular/material/sort';
import {Page} from 'app/admin/manage-references/manage-references.component';
import {DocumentFragment, DocumentReferenceFragment} from 'app/fragment/types';
import {SearchableGlobalReference} from 'app/sidebar/references/searchable-global-reference';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {DocumentReferenceUtils} from './reference-utils/document-reference-utils';

@Injectable({
  providedIn: 'root',
})
export class SearchableGlobalReferenceService extends BaseService {
  private static readonly REFERENCES_ENDPOINT: string = `${environment.apiHost}/references`;

  // Assumed maximum number of references used per document
  private static readonly REF_SEC_PAGE_SIZE: number = 1000;

  constructor(private _http: HttpClient, protected _snackBar: MatSnackBar) {
    super(_snackBar);
  }

  /**
   * Fetch and deserialise the searchable global reference with the given id.
   */
  public fetchSearchableGlobalReference(globalReferenceId: UUID): Observable<SearchableGlobalReference> {
    return this._http
      .get(
        `${SearchableGlobalReferenceService.REFERENCES_ENDPOINT}/searchableGlobalReference/${globalReferenceId.value}`
      )
      .pipe(
        map(SearchableGlobalReference.deserialise),
        catchError((error) => {
          this._handleError(error, 'Failed to load references, please try again or contact support');
          return throwError(error);
        })
      );
  }

  /**
   * Populate the searchableGlobalReference fields for document references of type DOCUMENT_REFERENCE in the
   * given document, valid at the given time.
   */
  public populateDocumentReferenceSeachableGlobalReferences(
    documentFragment: DocumentFragment,
    validAt: number
  ): Observable<DocumentFragment> {
    return this.fetchAllSearchableGlobalReferencesForDocument(documentFragment.id.value, validAt).pipe(
      map((searchableReferences: SearchableGlobalReference[]) => {
        const searchableReferenceMap: Record<string, SearchableGlobalReference> = searchableReferences.reduce(
          (returnMap: Record<string, SearchableGlobalReference>, current: SearchableGlobalReference) => {
            returnMap[current.globalReferenceId.value] = current;
            return returnMap;
          },
          {}
        );

        [
          ...DocumentReferenceUtils.getDocumentReferencesFromSection(documentFragment.getInformReferenceSection()),
          ...DocumentReferenceUtils.getDocumentReferencesFromSection(documentFragment.getNormReferenceSection()),
        ].forEach(
          (documentReference: DocumentReferenceFragment) =>
            (documentReference.searchableGlobalReference =
              searchableReferenceMap[documentReference.globalReference.value])
        );

        return documentFragment;
      })
    );
  }

  /**
   * Fetch all searchable global references for the given documentId, valid at the given time.
   */
  public fetchAllSearchableGlobalReferencesForDocument(
    documentId: string,
    validAt: number
  ): Observable<SearchableGlobalReference[]> {
    return this.fetchSearchableGlobalReferencesForDocument(
      '',
      documentId,
      0,
      SearchableGlobalReferenceService.REF_SEC_PAGE_SIZE,
      validAt
    ).pipe(map((page: Page<SearchableGlobalReference>) => page.resultsList));
  }

  /**
   * Fetch the requested page of the requested size of searchable global references that have been referenced from within
   * the given document and match the given search parameters.
   */
  public fetchSearchableGlobalReferencesForDocument(
    searchTerm: string,
    documentId: string,
    page: number,
    size: number,
    validAt: number = null
  ): Observable<Page<SearchableGlobalReference>> {
    const params: {[param: string]: string} = {
      searchTerm: searchTerm,
      documentId: documentId,
      validAt: validAt ? validAt.toString() : '',
      page: page.toString(),
      size: size.toString(),
      sort: 'title,asc',
    };
    return this._http
      .get(`${SearchableGlobalReferenceService.REFERENCES_ENDPOINT}/getAllSearchableReferencesIncludedInDocument`, {
        params,
      })
      .pipe(
        map(this._extractSearchableGlobalReferencePage),
        catchError((error) => {
          this._handleError(error, 'Failed to load references, please try again or contact support');
          return throwError(error);
        })
      );
  }

  /**
   * Fetch the requested page of the requested size of searchable global references that have not been referenced from within
   * the given document and match the given search parameters.
   */
  public fetchSearchableGlobalReferencesNotInDocument(
    searchTerm: string,
    documentId: string,
    page: number,
    size: number
  ): Observable<Page<SearchableGlobalReference>> {
    const params: {[param: string]: string} = {
      searchTerm: searchTerm,
      documentId: documentId,
      page: page.toString(),
      size: size.toString(),
      sort: 'title,asc',
    };
    return this._http.get(`${environment.apiHost}/references/getAllSearchableReferencesNotInDocument`, {params}).pipe(
      map(this._extractSearchableGlobalReferencePage),
      catchError((error) => {
        this._handleError(error, 'Failed to load references, please try again or contact support');
        return throwError(error);
      })
    );
  }

  /**
   * Fetch the searchable global references that match the given search paramters.
   */
  public fetchSearchableGlobalReferences(
    searchTerm: string,
    deleted: boolean,
    page: number,
    size: number,
    sort: boolean,
    sortColumn: string,
    sortDirection: SortDirection
  ): Observable<Page<SearchableGlobalReference>> {
    if (sortColumn === 'is_superseded') {
      sortColumn = 'search_superseding';
    }

    const params: {[param: string]: string} = {
      searchTerm: searchTerm,
      deleted: deleted.toString(),
      page: page.toString(),
      size: size.toString(),
      sort: sort ? sortColumn + ',' + sortDirection : '',
    };

    return this._http
      .get(`${SearchableGlobalReferenceService.REFERENCES_ENDPOINT}/searchableGlobalReferences`, {params})
      .pipe(
        map(this._extractSearchableGlobalReferencePage),
        catchError((error) => {
          this._handleError(error, 'Failed to fetch global references: error code ' + error.status, 'reference-error');
          return throwError(error);
        })
      );
  }

  /**
   * Deserialise global references.  Can handle the json+HAL format used by spring data REST endpoints
   * as well as normal json formats.
   *
   * @param json the json to deserialise.
   * @returns the deserialised GlobalReference array.
   */
  private _extractSearchableGlobalReferencePage(json: any): Page<SearchableGlobalReference> {
    const mapped: SearchableGlobalReference[] = json['content'].map(SearchableGlobalReference.deserialise);
    return {
      resultsList: mapped,
      total: json.totalElements,
    };
  }
}
