import {Fragment} from '../types';
import {DiffOperation} from './fragment-diff';

export class FragmentOperationSource {
  public static create(fragments: Fragment | Fragment[]): FragmentOperationSource[] {
    return this.constructWithOperation(fragments, DiffOperation.CREATE);
  }

  public static update(fragments: Fragment | Fragment[]): FragmentOperationSource[] {
    return this.constructWithOperation(fragments, DiffOperation.UPDATE);
  }

  public static delete(fragments: Fragment | Fragment[]): FragmentOperationSource[] {
    return this.constructWithOperation(fragments, DiffOperation.DELETE);
  }

  private static constructWithOperation(
    fragments: Fragment | Fragment[],
    operation: DiffOperation
  ): FragmentOperationSource[] {
    return FragmentOperationSource.toArray(fragments).map(
      (fragment: Fragment) => new FragmentOperationSource(fragment, operation)
    );
  }

  private static toArray(array: Fragment | Fragment[]): Fragment[] {
    array = array || [];
    array = array instanceof Array ? array : [array];

    return array.filter((fragment: Fragment) => !!fragment);
  }

  private constructor(public readonly fragment: Fragment, public readonly operation: DiffOperation) {}
}
