import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {ValidationIconComponent} from './validation-icon.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule, MatIconModule, CarsPermissionPipesModule],
  declarations: [ValidationIconComponent],
  exports: [ValidationIconComponent],
})
export class ValidationIconModule {}
