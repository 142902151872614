import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CarsNotification} from 'app/header/notification-area/notification/cars-notification';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {environment} from 'environments/environment';
import {BehaviorSubject, Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {catchError} from 'rxjs/internal/operators/catchError';
import {map} from 'rxjs/internal/operators/map';
import {UserService} from './user/user.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _notificationSubject: ReplaySubject<CarsNotification> = new ReplaySubject(10);

  private _notificationListSubject: BehaviorSubject<CarsNotification[]> = new BehaviorSubject([]);

  private _subscriptions: Subscription[] = [];

  constructor(
    private _websocketService: WebSocketService,
    private _http: HttpClient,
    private _userService: UserService
  ) {
    this._websocketService.onConnection(this._onWebsocketConnect.bind(this));
  }

  private _onWebsocketConnect(connected: boolean): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());

    if (connected) {
      this._getRecentNotifications();

      const sub: Subscription = this._websocketService.subscribe('/topic/notifications', (json: any) => {
        const notification: CarsNotification = CarsNotification.deserialise(json);
        if (notification) {
          this.triggerNotification(notification);
        }
      });

      this._subscriptions.push(sub);
    }
  }

  public triggerNotification(notification: CarsNotification): void {
    this._addToNotificationList(notification);
    this._notificationSubject.next(notification);
  }

  public onNotification(): Observable<CarsNotification> {
    return this._notificationSubject.asObservable();
  }

  public onNotificationList(): Observable<CarsNotification[]> {
    return this._notificationListSubject.asObservable();
  }

  public clearNotificationList(): void {
    this._notificationListSubject.next([]);
  }

  public markAllNotificationsAsSeen(): void {
    const notificationList: CarsNotification[] = this._notificationListSubject.getValue();
    notificationList.forEach((notification: CarsNotification) => (notification.seen = true));
    this._notificationListSubject.next(notificationList);
  }

  private _addToNotificationList(notification: CarsNotification): void {
    const notificationList: CarsNotification[] = this._notificationListSubject.getValue();
    const index: number = notificationList.findIndex(
      (n: CarsNotification) =>
        n.notificationId.equals(notification.notificationId) && !CarsNotification.CHILD_TYPES.includes(n.type)
    );

    if (index > -1) {
      if (CarsNotification.CHILD_TYPES.includes(notification.type)) {
        notificationList[index].childNotifications.push(notification);
      } else {
        notification.childNotifications = notificationList[index].childNotifications;
        notificationList.splice(index, 1);
      }
    }

    if (!CarsNotification.CHILD_TYPES.includes(notification.type)) {
      notificationList.unshift(notification);
    }

    this._notificationListSubject.next(notificationList);
  }

  /**
   * Fetches the 10 most recent notifications of the current user
   */
  private _getRecentNotifications(): void {
    this._http
      .get(`${environment.apiHost}/notifications/by-user-id/`)
      .pipe(
        map((notifications: any) => {
          if (!!notifications) {
            notifications.forEach((notification) => {
              this.triggerNotification(CarsNotification.deserialise(notification));
            });
            this.markAllNotificationsAsSeen();
          }
        }),
        catchError(() => {
          return of([]);
        })
      )
      .subscribe(() => {});
  }
}
