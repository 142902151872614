import {Pipe, PipeTransform} from '@angular/core';
import {AdministrationUtils} from 'app/documents/administration-utils';
import {Administration} from 'app/documents/administrations';

@Pipe({
  name: 'administrationToDisplayName',
})
export class AdministrationToDisplayNamePipe implements PipeTransform {
  /**
   * Transforms the administration enum into a friendly display name.
   *
   * @param administration  {Administration}  The administration
   * @returns               {string}          The administration name
   */
  public transform(administration: Administration): string {
    return AdministrationUtils.getDisplayName(administration);
  }
}
