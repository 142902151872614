<ng-content *ngTemplateOutlet="documentToolbar"></ng-content>

<div
  class="pad-container"
  [ngClass]="{
    'pad-max-height': currentView.isLive() || currentView.isExport()
  }"
>
  <div class="pad-padding pad-max-height">
    <div class="pad">
      <div class="content" *ngIf="section">
        <div class="title">
          <span class="index"> {{ section | fragmentIndex }}</span>
          <div class="title-text">
            {{ section.title }}
          </div>
        </div>
        <div class="centered">
          <span class="intro">
            {{ introduction }}
          </span>
          <ng-container *ngIf="loading">
            <mat-progress-bar
              class="loading-bar"
              mode="indeterminate"
              aria-label="Loading reference section"
            ></mat-progress-bar>
          </ng-container>
          <table>
            <tr>
              <th class="reference-column">Ref.</th>
              <th class="title-column">Document</th>
            </tr>
            <ng-container *ngFor="let reference of referenceList">
              <tr
                *ngIf="!loading"
                [class.red]="
                  reference.globalReferenceProperties?.deleted ||
                  reference.internalReferenceProperties?.deleted ||
                  reference.globalReferenceProperties?.withdrawn
                "
              >
                <td class="reference-column">
                  <mat-icon
                    *ngIf="getErrorMessageForReference(reference) as toolTipText"
                    [matTooltip]="toolTipText"
                    matTooltipPosition="left"
                    [matTooltipShowDelay]="tooltipDelay"
                  >
                    error_outline
                  </mat-icon>
                  {{ reference.id | fragmentIndex }}
                </td>
                <td class="title-column">
                  {{ reference.formattedTitle }}
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
