<td *ngIf="row.changeType === ChangeType.UNCHANGED; else changedRow" [attr.colspan]="showBackgroundCommentary ? 5 : 4">
  <div class="unchanged">
    <span *ngIf="!!row.newIndex">{{ row.newIndex }}</span>
    <cars-clause
      [content]="row.newFragment"
      [readOnly]="true"
      [showCurrentClause]="false"
      [showMarginIcons]="false"
      *ngIf="!!row.newFragment"
    ></cars-clause>
  </div>
</td>

<ng-template #changedRow>
  <td>{{ row.oldIndex }}</td>
  <td class="clause-cell" cars-clause-change-summary-clause-cell [row]="row" [isOldClause]="true"></td>
  <td>{{ row.newIndex }}</td>
  <td class="clause-cell" cars-clause-change-summary-clause-cell [row]="row" [isOldClause]="false"></td>
  <td *ngIf="showBackgroundCommentary">{{ row.changeType !== ChangeType.DELETED ? row.newFragment?.value : '' }}</td>
</ng-template>
