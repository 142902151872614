import {Injectable} from '@angular/core';
import {DocumentRole} from 'app/documents/document-data';
import {DocumentFragment, RootFragment} from 'app/fragment/types';
import {VersionTag} from 'app/interfaces';
import {
  OfflineDiscussion,
  OfflineDocument,
  OfflineFragment,
  OfflineUser,
  OfflineVersion,
  OfflineVersionBuilder,
  OfflineVersionTag,
} from 'app/offline/offline-document';
import {DocumentRepository, Repository, UserRepository} from 'app/offline/repository';
import {DiscussionsService} from 'app/services/discussions.service';
import {DocumentFetchParams, DocumentService} from 'app/services/document.service';
import {RoleService} from 'app/services/user/role.service';
import {Discussion} from 'app/sidebar/discussions/discussions';
import {User} from 'app/user/user';
import {UUID} from 'app/utils/uuid';

@Injectable({
  providedIn: 'root',
})
export class OfflinePersistenceService {
  private subtreeExtractor: SubtreeExtractor = new SubtreeExtractor();

  constructor(
    private documentService: DocumentService,
    private discussionsService: DiscussionsService,
    private roleService: RoleService,
    private documentRepository: DocumentRepository,
    private userRepository: UserRepository
  ) {}

  /**
   * Tries to detect if the required technologies for offline review are available in
   * the user's browser (IndexeDB and service workers).
   *
   * @returns {boolean} True if offline persistence is available, otherwise false.
   */
  public isAvailable(): boolean {
    return Repository.isAvailable() && !!navigator.serviceWorker;
  }

  /**
   * Download and persist a version of a document and all comments associated with
   * that version.
   *
   * @param version {VersionTag} The version of the document to download.
   */
  public downloadAndPersist(version: VersionTag): Promise<number> {
    if (!version || !this.isAvailable()) {
      return Promise.reject(null);
    }

    const syncTime: number = Date.now();
    const fragmentFetchParams: DocumentFetchParams = {
      validAt: version.createdAt,
      projection: 'FULL_TREE',
    };

    return Promise.all([
      this.documentService.load(
        version.fragmentId,
        fragmentFetchParams,
        false,
        'Failed to retrieve version for offline storage.  Please retry or contact the CARS team.'
      ),
      this.discussionsService.getVersionDiscussions(version.versionId),
      this.downloadAndPersistUsers(),
    ]).then(([document, discussions]: [DocumentFragment, Discussion[], void]) => {
      if (!document || !discussions) {
        return Promise.reject(null);
      }
      return this.persist(document, discussions, version, syncTime).then(() => syncTime);
    });
  }

  public downloadAndPersistUsers(): Promise<void> {
    return Promise.all([
      this.roleService.getUsersByRole(DocumentRole.AUTHOR),
      this.roleService.getUsersByRole(DocumentRole.LEAD_AUTHOR),
      this.roleService.getUsersByRole(DocumentRole.OWNER),
      this.roleService.getUsersByRole(DocumentRole.PEER_REVIEWER),
      this.roleService.getUsersByRole(DocumentRole.REVIEWER),
    ]).then((users: User[][]) => {
      const map: Map<string, OfflineUser> = new Map();
      users
        .reduce((prev: User[], curr: User[]) => prev.concat(curr))
        .forEach((user: User) => map.set(user.id.value, user.serialise()));
      return this.userRepository.setAll(map);
    });
  }

  /**
   * Save a version of a document for offline use.
   *
   * @param document    {DocumentFragment} The document to persist.
   * @param discussions {Discussion[]}     All discussions relevant to the document at that time.
   * @param version     {VersionTag}       The version information for that document.
   * @param syncTime    {number}           The time at which the document was synced.
   *
   * @returns           {Promise<void>}  When the operation is complete, returns.
   */
  public persist(
    document: DocumentFragment,
    discussions: Discussion[],
    version: VersionTag,
    syncTime: number
  ): Promise<void> {
    if (!document || !discussions || !version || !syncTime || !this.isAvailable()) {
      return Promise.reject(null);
    }

    const offlineDocument: OfflineVersion = new OfflineVersionBuilder(document, discussions, version, syncTime).build();
    return this.addVersion(document.id, version.versionId, offlineDocument);
  }

  /**
   * Check if the document version is stored offline.
   *
   * @param documentId {UUID} The ID of the document.
   * @param versionId  {UUID} The ID of the version.  If null, checks if any version of the document
   * is stored offline.
   *
   * @returns {Promise<boolean>} True if the version is stored, otherwise false.
   */
  public isPersisted(documentId: UUID, versionId?: UUID): Promise<boolean> {
    if (!versionId || !documentId || !this.isAvailable()) {
      return Promise.resolve(false);
    }
    return this.getVersion(documentId, versionId).then((json: OfflineVersion) => !!json);
  }

  /**
   * Get the time at which the document was pulled from the backend.
   *
   * @param documentId {UUID} The ID of the document.
   * @param versionId  {UUID} The ID of the version.
   *
   * @returns {Promise<boolean>} A promise resolvling to a timestamp, or null if the
   * version has not been persisted offline.
   */
  public getLastSyncTime(documentId: UUID, versionId: UUID): Promise<number> {
    if (!versionId || !documentId || !this.isAvailable()) {
      return Promise.resolve(null);
    }
    return this.getVersion(documentId, versionId).then((json: OfflineVersion) => (json ? json.syncTime : null));
  }

  /**
   * Acts as a mock backend, allowing intercepted HTTP requests to retrieve an array of Discussions as
   * normal.  While normally discussions are requested per section, this returns all per document
   * since there are no extra costs associated with that here, and the discussion service will cache
   * all the ones it receives.
   *
   * This will need to be wrapped in an HttpResponse by the interceptor.
   *
   * @param documentId {UUID} The ID of the persisted document which the user is interested in.
   * @param versionId  {UUID} The ID of the persisted version which the user is interested in.
   *
   * @returns {Promise<OfflineDiscussion[]>} The serialised discussions.
   */
  public fetchDiscussions(documentId: UUID, versionId: UUID): Promise<OfflineDiscussion[]> {
    if (!versionId || !documentId || !this.isAvailable()) {
      return Promise.reject(null);
    }
    return this.getVersion(documentId, versionId).then((json: OfflineVersion) => (json ? json.discussions : null));
  }

  /**
   * Acts as a mock backend, allowing intercepted HTTP requests to retrieve a VersionTag as
   * normal.
   *
   * This will need to be wrapped in an HttpResponse by the interceptor.
   *
   * @param documentId {UUID} The ID of the persisted document which the user is interested in.
   * @param versionId  {UUID} The ID of the persisted version which the user is interested in.
   *
   * @returns {Promise<OfflineVersionTag>} The serialised version tag.
   */
  public fetchVersionTag(documentId: UUID, versionId: UUID): Promise<OfflineVersionTag> {
    if (!versionId || !documentId || !this.isAvailable()) {
      return Promise.reject(null);
    }
    return this.getVersion(documentId, versionId).then((json: OfflineVersion) => (json ? json.version : null));
  }

  /**
   * Acts as a mock backend, allowing intercepted HTTP requests to retrieve all
   * version tags per document as normal.
   *
   * This will need to be wrapped in an HttpResponse by the interceptor.
   *
   * @param documentId {UUID} The ID of the document which the user is interested in.
   *
   * @returns {Promise<OfflineVersionTag[]>} The serialised version tags.
   */
  public fetchVersionsOfDocument(documentId: UUID): Promise<OfflineVersionTag[]> {
    if (!this.isAvailable() || !documentId) {
      return Promise.resolve([]);
    }
    return this.documentRepository.get(documentId.value).then((json: OfflineDocument) => {
      return Object.values(json).map((version: OfflineVersion) => version.version);
    });
  }

  /**
   * Acts as a mock backend, allowing intercepted HTTP requests to retrieve the first two
   * layers of the tree (a RootFragment and its immediate children).
   *
   * This will need to be wrapped in an HttpResponse by the interceptor.
   *
   * @returns         {Promise<Record<string, OfflineFragment[]>>} The bucketed subtree from the requested fragment down.
   */
  public fetchRoot(): Promise<Record<string, OfflineFragment[]>> {
    if (!this.isAvailable()) {
      return Promise.resolve(null);
    }
    return this.documentRepository.getAll().then((documents: OfflineDocument[]) => {
      const dict: Record<string, OfflineFragment[]> = {};
      const root: OfflineFragment = new RootFragment([]).serialise();
      root.validFrom = 0;
      root.validTo = null;
      dict[RootFragment.ID.value] = [root];
      documents.forEach((document: OfflineDocument) => {
        // Get any version of the document - version doesn't matter here.
        const version: OfflineVersion = Object.values(document)[0];
        const documentId: string = version.version.fragmentId;
        const documentFragment: OfflineFragment[] = version.fragments[documentId];
        documentFragment[0].validFrom = 0;
        documentFragment[0].validTo = null;
        dict[documentId] = documentFragment;
      });
      return dict;
    });
  }

  /**
   * Acts as a mock backend, allowing intercepted HTTP requests to retrieve a fragment tree as
   * normal.  Because the FragmentService expects fragments bucketed, rather than as a tree, the fragments
   * are stored in bucketed, serialised form.
   *
   * This will need to be wrapped in an HttpResponse by the interceptor.
   *
   * @param documentId {UUID}   The ID of the document which the fragment is in.
   * @param versionId  {UUID}   The ID of the persisted version which the user is interested in.
   * @param id         {UUID}   The ID of the fragment to fetch.
   * @param depth      {number} How many layers of the subtree to return.
   *
   * @returns         {Promise<Record<string, OfflineFragment[]>>} The bucketed subtree from the requested fragment down,
   * or null if that fragment has not been persisted.
   */
  public fetchFragments(
    documentId: UUID,
    versionId: UUID,
    id: UUID,
    depth: number
  ): Promise<Record<string, OfflineFragment[]>> {
    if (!documentId || !id || !this.isAvailable()) {
      return Promise.reject(null);
    }
    if (!depth && depth !== 0) {
      depth = Infinity;
    }
    return this.getVersion(documentId, versionId).then((version: OfflineVersion) => {
      return this.subtreeExtractor.extract(version.fragments, id, depth, !versionId);
    });
  }

  private getVersion(documentId: UUID, versionId: UUID): Promise<OfflineVersion> {
    return this.documentRepository.get(documentId.value).then((versions: OfflineDocument) => {
      // If there's no version ID in request, get any version of the document
      versionId = versionId ? versionId : UUID.orNull(Object.keys(versions)[0]);
      return versions ? versions[versionId.value] : null;
    });
  }

  private addVersion(documentId: UUID, versionId: UUID, offlineVersion: OfflineVersion): Promise<void> {
    return this.documentRepository.get(documentId.value).then((versions: OfflineDocument) => {
      const document: OfflineDocument = versions ? versions : {};
      document[versionId.value] = offlineVersion;
      return this.documentRepository.set(documentId.value, document);
    });
  }
}

class SubtreeExtractor {
  private byParentId: Record<string, OfflineFragment[]> = {};
  private inSubtree: Record<string, OfflineFragment[]> = {};
  private pretendCurrent: boolean;
  private maxDepth: number;

  /**
   * Extract a subtree from a flattened tree.
   *
   * @param fragments      {Record<string,OfflineFragment[]>} The flattened tree.
   * @param id             {UUID}    The root of the subtree.
   * @param depth          {number}  The depth to traverse the subtree.
   * @param pretendCurrent {boolean} Pretend that the tree is the current view (i.e. set the
   * validTo to null)
   *
   * @returns {Record<string, OfflineFragment[]>} The flat subtree.
   */
  public extract(
    fragments: Record<string, OfflineFragment[]>,
    id: UUID,
    depth: number,
    pretendCurrent: boolean
  ): Record<string, OfflineFragment[]> {
    this.reset();
    this.pretendCurrent = pretendCurrent;
    this.maxDepth = depth;
    const root: OfflineFragment = fragments[id.value][0];

    if (pretendCurrent) {
      root.validFrom = 0;
      root.validTo = null;
    }
    this.inSubtree[root.id] = [root];

    for (const item of Object.values(fragments)) {
      const fragment: OfflineFragment = item[0];
      if (!this.byParentId[fragment.parentId]) {
        this.byParentId[fragment.parentId] = [];
      }
      this.byParentId[fragment.parentId].push(fragment);
    }
    this.fetchSubtreeRecursively(root);
    return this.inSubtree;
  }

  private fetchSubtreeRecursively(node: OfflineFragment, currentDepth: number = 0): void {
    const children: OfflineFragment[] = this.byParentId[node.id];
    if (children && children.length && currentDepth < this.maxDepth) {
      children.forEach((child: OfflineFragment) => {
        if (this.pretendCurrent) {
          child.validFrom = 0;
          child.validTo = null;
        }
        this.inSubtree[child.id] = [child];
        this.fetchSubtreeRecursively(child, currentDepth + 1);
      });
    }
  }

  private reset(): void {
    this.byParentId = {};
    this.inSubtree = {};
  }
}
