import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ChangeSummaryChangeType} from '../types/change-summary-change-type';
import {ChangeSummaryRow} from '../types/change-summary-row';
import {ChangeSummaryRowType} from '../types/change-summary-row-type';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[cars-clause-change-summary-row]',
  templateUrl: './clause-change-summary-row.component.html',
  styleUrls: ['./clause-change-summary-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseChangeSummaryRowComponent {
  @Input() public row: ChangeSummaryRow;
  @Input() public showBackgroundCommentary: boolean;

  public readonly RowType: typeof ChangeSummaryRowType = ChangeSummaryRowType;
  public readonly ChangeType: typeof ChangeSummaryChangeType = ChangeSummaryChangeType;
}
