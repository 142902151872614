import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {SuggestionsHovertipComponent} from './suggestions-hovertip.component';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  declarations: [SuggestionsHovertipComponent],
  exports: [SuggestionsHovertipComponent],
})
export class SuggestionsHovertipModule {}
