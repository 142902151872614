<ng-container>
  <span>Review status: {{ displayProperties.statusText }}</span>

  <ng-container *ngIf="!!userReviewState; else spinner">
    <span *ngIf="userReviewState.reviewState !== 'NOT_STARTED'" class="created-at"
      >({{ userReviewState.createdAt | date: dateFormat }})</span
    >
    <mat-icon
      [style.color]="displayProperties.iconColor"
      [matTooltip]="displayProperties.statusText"
      matTooltipPosition="right"
      [matTooltipShowDelay]="tooltipDelay"
      >{{ displayProperties.icon }}</mat-icon
    >
  </ng-container>

  <ng-template #spinner>
    <mat-progress-spinner [diameter]="24" mode="indeterminate"></mat-progress-spinner>
  </ng-template>

  <button mat-raised-button (click)="changeState()">{{ displayProperties.buttonText }}</button>
</ng-container>
