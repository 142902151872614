import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MchwExportSelectorComponent} from './mchw-export-selector.component';

@NgModule({
  declarations: [MchwExportSelectorComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatCheckboxModule, FormsModule],
  exports: [MchwExportSelectorComponent],
})
export class MchwExportSelectorModule {}
