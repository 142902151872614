import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {Key} from 'app/fragment/key';
import {DocumentFragment} from 'app/fragment/types';
import {CurrentView} from 'app/view/current-view';
import {environment} from 'environments/environment';
import {AltProperties} from '../../../../services/alt-accessibility.service';
import {SearchService} from '../search.service';

@Component({
  selector: 'cars-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
  @ViewChild('searchBox') _el: ElementRef;
  @Input() public document: DocumentFragment;
  @Input() public currentView: CurrentView;

  public searchString: string = '';

  public readonly minSearchLength: number = 3;

  public tooltipDelay: number = environment.tooltipDelay;

  public altProperties: AltProperties;

  constructor(private _searchService: SearchService) {}

  ngOnInit() {
    this.updateAltProperties();
  }

  public searchDocument(): void {
    if (this.searchString.length >= this.minSearchLength) {
      this._searchService.searchDocument(this.searchString, this.document.id);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    const key: Key = Key.fromEvent(event);
    if (key.isSearchDocument() && !this.currentView.isChangelogAux()) {
      event.preventDefault();
      this.focusSearch();
    }
  }

  public updateAltProperties(): void {
    this.altProperties = new AltProperties('q', 'f', this.currentView && !this.currentView.isChangelogAux());
  }

  public focusSearch(): void {
    this._el.nativeElement.focus();
  }
}
