// Interface containing minimal required functionality of the Tree class, to avoid a
// circular dependency between Tree and TreeArray.
interface TreeBase {
  parent: TreeBase;
  equals: (other: TreeBase) => boolean;
  weight: number;
}

/**
 * A proxy class for built-in Arrays, to ensure nodes are inserted into the
 * tree with their parent references properly set.
 *
 * @tparam T                   Generic type extending Tree<any>
 * @field parent {Tree<any>}   The parent reference
 */
export class TreeArray<T extends TreeBase> extends Array<T> {
  protected _parent: TreeBase = null;

  public get parent(): TreeBase {
    return this._parent;
  }

  public set parent(value: TreeBase) {
    this._parent = value;
  }

  constructor() {
    super();

    // See https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
    //  #extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, TreeArray.prototype);
  }

  /**
   * Specialisation of Array::indexOf() to allow for comparison via equals() method.
   *
   * @param search {T}        The item to search for
   * @param start  {number}   The start index
   * @returns      {number}   The found index, or -1 if not found
   */
  public indexOf(search: T, start: number = 0): number {
    for (let i: number = start; i < this.length; ++i) {
      if (this[i].equals(search)) {
        return i;
      }
    }

    return -1;
  }

  /**
   * Specialisation of Array::push() to set parent references.
   *
   * @param args {T[]}      The new children
   * @returns    {number}   The new array length
   */
  public push(...args: T[]): number {
    args.forEach((newChild: T) => {
      newChild.parent = this.parent;
    });
    return super.push(...args);
  }

  /**
   * Specialisation of Array::splice() to set parent references on new children, and remove
   * parent references from outgoing elements.
   *
   * @param start  {number}         The start index
   * @param remove {number?}        The number of elements to remove
   * @param insert {T[]?}           The elements to insert
   * @returns      {TreeArray<T>}   The removed elements
   */
  public splice(start: number, remove: number = this.length, ...insert: T[]): TreeArray<T> {
    const result: TreeArray<T> = super.splice(start, remove, ...insert) as TreeArray<T>;
    result.forEach((oldChild: T) => {
      if (oldChild) {
        oldChild.parent = null;
        oldChild.weight = void 0;
      }
    });
    insert.forEach((newChild: T) => {
      newChild.parent = this.parent;
    });
    return result;
  }

  /**
   * Specialisation of Array::unshift() to set parent references.
   *
   * @param args {T[]}      The elements to front-insert
   * @returns    {number}   The new array length
   */
  public unshift(...args: T[]): number {
    args.forEach((newChild: T) => {
      newChild.parent = this.parent;
    });
    return super.unshift(...args);
  }

  /**
   * Specialisation of Array::slice() to set parent references.
   *
   * @param start {number?}        The start index
   * @param end   {number?}        The end index
   * @returns     {TreeArray<T>}   The sliced array
   */
  public slice(start?: number, end?: number): TreeArray<T> {
    const result: TreeArray<T> = super.slice(start, end) as TreeArray<T>;
    result.parent = this.parent;
    return result;
  }
}
