import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {FragmentIndexModule} from 'app/fragment/indexing/fragment-index.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {SectionListItemModule} from '../section-list-item/section-list-item.module';
import {SectionListMenuModule} from '../section-list-menu/section-list-menu.module';
import {SectionGroupListItemComponent} from './section-group-list-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    FormsModule,
    CarsErrorHandlingModule,
    FragmentIndexModule,
    SectionListItemModule,
    SectionListMenuModule,
    CarsPermissionPipesModule,
  ],
  declarations: [SectionGroupListItemComponent],
  exports: [SectionGroupListItemComponent],
})
export class SectionGroupListItemModule {}
