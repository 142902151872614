import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ClauseType} from 'app/fragment/types';
import {TdifNormalClause} from 'app/tdif/tdif-types';

@Component({
  selector: 'cars-tdif-normal-clause',
  templateUrl: './tdif-normal-clause.component.html',
  styleUrls: ['./tdif-normal-clause.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifNormalClauseComponent {
  @Input() public content: TdifNormalClause;

  constructor() {}

  public getClauseClass(clauseType: ClauseType): ClauseType {
    return ClauseType[clauseType];
  }
}
