import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {CurrentView, ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentOverviewResolverService implements Resolve<void> {
  constructor(private viewService: ViewService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    route.data = {ignoreBreadcrumbs: true};
    const currentView: CurrentView = this.viewService.getCurrentView();
    this.viewService.setLocation(CurrentLocation.DOCUMENT_OVERVIEW);
    if (!currentView.isLive() && !currentView.isHistorical() && !currentView.isChangelogMarkup()) {
      this.viewService.setCurrentView(
        currentView.isHistoricalDocument() ? ViewMode.HISTORICAL : ViewMode.LIVE,
        currentView.versionTag
      );
    }
  }
}
