import {PadType} from 'app/element-ref.service';
import {Observable} from 'rxjs';
import {PermissionsHandler} from '../handlers/permissions-handler';
import {PermissionASTNode} from './permission-ast-node';
import {ASTNodeType, EqualityRelation, PermissionInput} from './permissions';

/**
 * A class encapsulating a Condition ASTNode
 *
 * @field input             {PermissionInput}        The PermissionInput to be evaluated e.g. DOCUMENT_ROLE
 * @field relation          {EqualityRelation}       The EqualityRelation to be applied e.g. NOT_EQUALS
 * @field value             {string}                 The value for permissionInput to be evaluated against
 */
export class ConditionASTNode extends PermissionASTNode {
  constructor(
    public readonly input: PermissionInput,
    public readonly relation: EqualityRelation,
    public readonly value: string
  ) {
    super(ASTNodeType.CONDITION);
  }

  /**
   * Returns true if condition is satisfied
   *
   * @returns    {Observable<boolean>}    Observable emitting boolean representation of condition satisfied
   */
  public can(handlers: Map<PermissionInput, PermissionsHandler>, padType: PadType): Observable<boolean> {
    const handler = handlers.get(this.input);
    if (handler === undefined) {
      throw new Error(`No handler defined for ${this.input}`);
    } else {
      return handler.handle(this, padType);
    }
  }
}
