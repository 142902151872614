import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FreeTextInputComponent} from './free-text-input.component';
import {FreeTextMultilineInputComponent} from './free-text-multiline-input.component';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatTooltipModule, ReactiveFormsModule],
  declarations: [FreeTextInputComponent, FreeTextMultilineInputComponent],
  exports: [FreeTextInputComponent, FreeTextMultilineInputComponent],
})
export class FreeTextInputModule {}
