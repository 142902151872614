import {Fragment} from './types';

/**
 * A class representing a request from a fragment to its managing carsContentEditable
 * directive to update its content in some way.  This class is returned by a fragment's
 * onKeydown() handler.
 *
 * @field fragment   {Fragment}   An optional fragment to move the caret to
 * @field offset     {number}     A relative offset to move the caret by
 * @field remove     {number}     The number of characters to remove; negative values remove
 *                                  behind the caret and positive in front
 * @field add        {string}     A string to insert at the caret position
 */
export class ActionRequest {
  constructor(
    public fragment: Fragment = null,
    public offset: number = 0,
    public remove: number = 0,
    public add: string = null
  ) {}

  /**
   * Get the relative caret delta from this ActionRequest.
   *
   * @returns {number}   The relative delta
   */
  public toDelta(): number {
    let result: number = this.offset + Math.min(this.remove, 0);
    result += typeof this.add === 'string' ? this.add.length : 0;

    return result;
  }

  /**
   * Returns true if this ActionRequest has an effect on the fragment's content.
   *
   * @returns {boolean}   True if this request has effect
   */
  public hasEffect(): boolean {
    return (this.add && this.add.length > 0) || this.remove !== 0;
  }
}
