import {Injectable} from '@angular/core';
import {DocumentRole} from 'app/documents/document-data';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {DocumentFragment} from 'app/fragment/types';
import {DocumentService} from 'app/services/document.service';
import {DisabledUserService} from 'app/services/user/disabled-user.service';
import {UUID} from 'app/utils/uuid';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentOverviewService {
  public static readonly DEBOUNCE_TIME: number = 500; // in ms

  public static readonly ROLE_TO_DOCUMENT_DATA: Partial<Readonly<Record<DocumentRole, string>>> = {
    [DocumentRole.LEAD_AUTHOR]: 'leadAuthor',
    [DocumentRole.AUTHOR]: 'authors',
    [DocumentRole.REVIEWER]: 'reviewers',
    [DocumentRole.PEER_REVIEWER]: 'peerReviewers',
  };

  public saveTimeout = null;

  private _userAssignmentSubject: Subject<void> = new Subject();

  constructor(private _documentService: DocumentService, private _disabledUserService: DisabledUserService) {}

  public onAssignmentChange(): Observable<void> {
    return this._userAssignmentSubject;
  }

  public assignUserToDocument(document: DocumentFragment, userId: UUID, role: DocumentRole): void {
    let assigned: boolean = false;
    switch (role) {
      case DocumentRole.LEAD_AUTHOR:
        document.documentData[DocumentOverviewService.ROLE_TO_DOCUMENT_DATA[role]] = userId;
        assigned = true;
        break;
      case DocumentRole.AUTHOR:
      case DocumentRole.REVIEWER:
      case DocumentRole.PEER_REVIEWER:
        const usersInRole: UUID[] = document.documentData[DocumentOverviewService.ROLE_TO_DOCUMENT_DATA[role]];
        if (!usersInRole.find((id) => id.equals(userId))) {
          usersInRole.push(userId);
          assigned = true;
        }
        break;
      default:
        Logger.error('document-overview-error', 'Cannot assign user to: ' + role);
    }

    if (assigned) {
      this._userAssignmentSubject.next();
      this.save(document, () => {
        if (document.isInPublishedState() && this.roleCanEditDocument(role)) {
          this._disabledUserService.updateUserStatus(document.id, userId, true);
        } else {
          this._userAssignmentSubject.next();
        }
      });
    }
  }

  public removeUserFromDocument(document: DocumentFragment, userId: UUID, role: DocumentRole): void {
    let removed: boolean = false;
    switch (role) {
      case DocumentRole.LEAD_AUTHOR:
        document.documentData[DocumentOverviewService.ROLE_TO_DOCUMENT_DATA[role]] = null;
        removed = true;
        break;
      case DocumentRole.AUTHOR:
      case DocumentRole.REVIEWER:
      case DocumentRole.PEER_REVIEWER:
        const usersInRole: UUID[] = document.documentData[DocumentOverviewService.ROLE_TO_DOCUMENT_DATA[role]];
        const index: number = usersInRole.findIndex((id: UUID) => id.equals(userId));
        if (index > -1) {
          usersInRole.splice(index, 1);
          removed = true;
        }
        break;
      default:
        Logger.error('document-overview-error', 'Cannot unassign user from: ' + role);
    }

    if (removed) {
      this._userAssignmentSubject.next();
      this.save(document);
    }
  }

  public setUserAuthoringState(document: DocumentFragment, userId: UUID, disabled: boolean): void {
    this._disabledUserService.updateUserStatus(document.id, userId, disabled);
  }

  public disableAllOnDocument(document: DocumentFragment): void {
    this._disabledUserService.updateAllUserStatuses(document.id, true);
  }

  public roleCanEditDocument(role: DocumentRole): boolean {
    return role === DocumentRole.AUTHOR || role === DocumentRole.LEAD_AUTHOR || role === DocumentRole.OWNER;
  }

  public save(document: DocumentFragment, callback?: () => void): void {
    if (this.saveTimeout != null) {
      clearTimeout(this.saveTimeout);
      this.saveTimeout = null;
    }

    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = null;
      this._updateServer(document, callback);
    }, DocumentOverviewService.DEBOUNCE_TIME);
  }

  /**
   * Performs the server update and retry in case of failure
   */
  private _updateServer(document: DocumentFragment, callback?: () => void): void {
    this._documentService.update(document).then(() => {
      if (callback) {
        callback();
      }
    });
  }
}
