<div [class.landscape-pad]="content.landscape">
  <div *ngIf="content.landscape" class="hide-borders" contenteditable="false"></div>
  <div>
    <div class="figure title" [class.hide-underline]="section?.deleted || userLockingClause">
      <div>
        <label
          *ngIf="clause.getSection() && !clause.getSection().deleted && showIndex()"
          contenteditable="false"
          class="no-select"
          (click)="selectCaption(true)"
        >
          {{ content | fragmentIndex }}
        </label>
        <label
          *ngIf="clause.getSection()?.deleted && showIndex()"
          contenteditable="false"
          class="no-select"
          (click)="selectCaption(true)"
        >
          Figure
        </label>
        <cars-text-fragment
          *ngIf="!content?.diffedCaption?.length; else diffedCaption"
          [content]="content?.caption"
          [caption]="true"
        ></cars-text-fragment>
        <ng-template #diffedCaption>
          <cars-text-fragment
            *ngFor="let fragment of content?.diffedCaption"
            [content]="fragment"
            [caption]="true"
          ></cars-text-fragment>
        </ng-template>

        <div
          *ngIf="showMarginIcons && padType === PadType.MAIN_EDITABLE"
          class="margin-icons-container"
          contenteditable="false"
        >
          <cars-discussions-icon [fragment]="content"></cars-discussions-icon>
        </div>
      </div>

      <cars-captioned-hovertip
        *ngIf="!inline"
        class="hovertip"
        [fragmentType]="'figure'"
        [readOnly]="readOnly"
        [fragment]="content"
        [carsShowOnFocus]="content"
      >
      </cars-captioned-hovertip>
    </div>

    <div class="upload no-select" contenteditable="false" [class.inline]="inline">
      <cars-captioned-hovertip
        *ngIf="inline"
        class="hovertip-inline"
        [fragmentType]="'figure'"
        [readOnly]="readOnly"
        [fragment]="content"
        [carsShowOnFocus]="content"
      >
      </cars-captioned-hovertip>
      <div [ngSwitch]="content.virusScanState" class="virus-scan">
        <div *ngSwitchCase="VirusScanState.PENDING" class="virus-scan-state">
          <img src="assets\placeholder_view.png" alt="Placeholder image, virus scan pending" />
          <p>Virus scan pending...</p>
        </div>
        <div *ngSwitchCase="VirusScanState.SCAN_FAILED" class="virus-scan-state">
          <img src="assets\placeholder_view.png" alt="Placeholder image, failed to run virus scan" />
          <p>Failed to run virus scan, please try uploading the image again.</p>
        </div>
        <div *ngSwitchCase="VirusScanState.VIRUS_FOUND" class="virus-scan-state">
          <img src="assets\placeholder_view.png" alt="Placeholder image, virus scan detected virus" />
          <p>WARNING: VIRUS FOUND – Image cannot be uploaded</p>
          <p>Please replace with safe image.</p>
        </div>
        <div *ngSwitchCase="VirusScanState.OK">
          <ng-container *ngIf="content?.uploadId">
            <img
              *ngIf="imageUrl"
              [src]="imageUrl"
              [style.width]="displayWidth"
              alt="{{ content?.altText }}"
              (click)="selectCaption(false)"
            />
          </ng-container>
          <div *ngIf="!content?.uploadId" class="virus-scan-state">
            <img src="assets\placeholder_view.png" alt="Placeholder image, corrupted image file uploaded" />
            <p>Corrupted image file uploaded, please replace with a fixed image.</p>
          </div>
        </div>
      </div>
      <div *ngIf="showClickBelow()" class="click-below" (click)="insertTextFragment()"></div>
    </div>
    <div
      class="alt-text"
      *ngIf="!content?.diffedAltText?.length && (CarsAction.SHOW_ALT_TEXT | carsEnabled : content.documentId | async)"
    >
      <label contenteditable="false" class="no-select"> Alt text: </label>
      <cars-text-fragment [content]="changeSummaryAltTextDisplay()" [caption]="false"></cars-text-fragment>
    </div>
    <div class="alt-text" *ngIf="content?.diffedAltText?.length > 0">
      <label contenteditable="false" class="no-select"> Alt text: </label>
      <cars-text-fragment
        *ngFor="let fragment of content?.diffedAltText"
        [content]="fragment"
        [caption]="false"
      ></cars-text-fragment>
    </div>
  </div>
  <div
    *ngIf="userLockingClause && content.landscape"
    class="overlay locked"
    [style.background-color]="
      (CarsAction.AUTHOR_DOCUMENT | carsEnabled : content.documentId | async)
        ? getUserColour(userLockingClause, 0.05)
        : 'inherit'
    "
  ></div>
  <div *ngIf="section?.deleted && content.landscape" class="overlay deleted"></div>
</div>
