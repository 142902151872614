import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FragmentIndexModule} from 'app/fragment/indexing/fragment-index.module';
import {SectionReferenceComponent} from './section-reference.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, MatIconModule, MatTooltipModule, FragmentIndexModule],
  declarations: [SectionReferenceComponent],
  exports: [SectionReferenceComponent],
})
export class SectionReferenceModule {}
