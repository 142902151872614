import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DocumentInformationModule} from './document-information/document-information.module';
import {DocumentOverviewHeaderModule} from './document-overview-header/document-overview-header.module';
import {DocumentOverviewComponent} from './document-overview.component';
import {RoleAssignmentsModule} from './role-assignments/role-assignments.module';

@NgModule({
  imports: [CommonModule, DocumentOverviewHeaderModule, DocumentInformationModule, RoleAssignmentsModule],
  declarations: [DocumentOverviewComponent],
  exports: [DocumentOverviewComponent],
})
export class DocumentOverviewModule {}
