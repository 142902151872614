import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {FeedbackDialogComponent} from './feedback-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    CarsErrorHandlingModule,
  ],
  declarations: [FeedbackDialogComponent],
  exports: [FeedbackDialogComponent],
})
export class FeedbackDialogModule {}
