import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {BlurOption} from 'app/blur-options';
import {FragmentService} from 'app/services/fragment.service';
import {ToolbarItem} from 'app/suite-config/configuration.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AltProperties} from '../../services/alt-accessibility.service';
import {RichTextService, RichTextType} from '../../services/rich-text.service';

@Component({
  selector: 'cars-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss', '../toolbar-buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextComponent implements OnInit, OnDestroy {
  @Input() public toolbarItem: ToolbarItem;
  @Input() public altProperties: AltProperties;

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public enabled: boolean = false;
  public active: boolean = false;
  public subscription: Subscription = new Subscription();

  public readonly BlurOption: typeof BlurOption = BlurOption;

  constructor(
    private _richTextService: RichTextService,
    private _fragmentService: FragmentService,
    private _cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.toolbarItem.richTextType === RichTextType.REVERT
        ? this._fragmentService.onFlush(() => this._setActiveAndEnabled(this._fragmentService))
        : this._fragmentService.onSelection(this._setActiveAndEnabled.bind(this))
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Block event propagation when clicking on the RTE to prevent blurring.
   */
  @HostListener('mousedown')
  public mouseDown(): boolean {
    return false;
  }

  /**
   * Issue an event to the rich-text service.
   *
   * @param type {RichTextType}   The event type
   * @param args {any[]}          Any bundled arguments
   */
  public issueEvent(): void {
    this._richTextService.next(this.toolbarItem.richTextType, ...(this.toolbarItem.richTextArguments || []));
  }

  private _setActiveAndEnabled(...args: any[]): void {
    this.enabled = this.toolbarItem.enabled(...args);
    if (this.toolbarItem.active) {
      this.active = this.toolbarItem.active(...args);
    }
    this.altProperties.condition = this.enabled;
    this._cd.markForCheck();
  }
}
