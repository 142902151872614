<ng-container *ngIf="locked">
  <cars-user
    *ngIf="lockingUser && (CarsAction.IS_LIVE_VIEW_MODE | carsEnabled: clause.documentId | async)"
    [user]="lockingUser"
    initials="true"
    [style.color]="initialsColor"
  ></cars-user>
  <div
    contenteditable="false"
    [style.background-color]="
      (CarsAction.IS_LIVE_VIEW_MODE | carsEnabled: clause.documentId | async) ? overlayColor : 'inherit'
    "
  ></div>
</ng-container>
