import {Component, HostListener, Input, OnChanges, ViewChild} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';
import {PadType} from 'app/element-ref.service';
import {Caret} from 'app/fragment/caret';
import {getFinalEditableDescendant} from 'app/fragment/fragment-utils';
import {Suite} from 'app/fragment/suite';
import {TreeArray} from 'app/fragment/tree/tree-array';
import {
  ClauseFragment,
  ClauseType,
  DocumentFragment,
  Fragment,
  FragmentType,
  ListFragment,
  ListItemFragment,
  SectionFragment,
  TextFragment,
} from 'app/fragment/types';
import {InputFragment} from 'app/fragment/types/input/input-fragment';
import {ReadonlyFragment} from 'app/fragment/types/readonly-fragment';
import {SpecifierInstructionType} from 'app/fragment/types/specifier-instruction-type';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {CaretService} from 'app/services/caret.service';
import {FragmentDeletionValidationService} from 'app/services/fragment-deletion-validation.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {environment} from 'environments/environment';

@Component({
  selector: 'cars-list-hovertip',
  templateUrl: './list-hovertip.component.html',
  styleUrls: ['./list-hovertip.component.scss'],
})
export class ListHovertipComponent implements OnChanges {
  @Input() public fragment: ListFragment;
  @ViewChild('moveUpTooltip') moveUpTooltip: MatTooltip;
  @ViewChild('moveDownTooltip') moveDownTooltip: MatTooltip;

  public tooltipDelay: number = environment.tooltipDelay;
  public fragmentType: string = 'List';

  public showSplitToSis: boolean = false;
  public showSplitToItemClauses: boolean = false;

  private _clause: ClauseFragment;

  constructor(
    private _fragmentService: FragmentService,
    private _caretService: CaretService,
    private _selectionOperationsService: SelectionOperationsService,
    private _lockService: LockService,
    private _fragmentDeletionValidationService: FragmentDeletionValidationService
  ) {}

  public ngOnChanges(): void {
    if (this.fragment) {
      this._clause = this.fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
      const isMomhwSuite: boolean = (
        this._clause?.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment
      )?.isSuite(Suite.MOMHW);
      this.showSplitToSis = isMomhwSuite;
      this.showSplitToItemClauses = isMomhwSuite;
    }
  }

  /**
   * Block event propagation when clicking to prevent bluring of associated fragment.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  @HostListener('mousedown', ['$event'])
  public mouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  /**
   * Checks whether the clause or clause group can be locked by the current user. For clause groups this checks that
   * all child clauses can be locked.
   */
  public canLock(): boolean {
    return this._lockService.canLock(this._clause);
  }

  public splitToSis(): void {
    const fragmentsToCreate: Fragment[] = [];
    const fragmentsToUpdate: Fragment[] = [];
    let lastClause: Fragment = this._clause;

    this.fragment.children.forEach((listItem: ListItemFragment) => {
      const newClause: ClauseFragment = new ClauseFragment(
        null,
        ClauseType.SPECIFIER_INSTRUCTION,
        [
          new InputFragment(null, 'measurements', []),
          new ReadonlyFragment(null, ' ............ '),
          new InputFragment(null, 'units', []),
        ],
        '',
        null,
        null,
        SpecifierInstructionType.UNITS_OF_MEASUREMENT,
        null,
        null,
        false
      );
      const textContent: Fragment[] = listItem.children;
      fragmentsToUpdate.push(...textContent);
      const fragmentToSplit: Fragment = textContent.find((frag) => frag.value.includes('.....'));
      if (!fragmentToSplit) {
        newClause.children[0].children.push(...textContent);
        newClause.children[2].children.push(new TextFragment(null, ''));
      } else {
        const fragmentIndex: number = textContent.indexOf(fragmentToSplit);
        const splitIndex: number = fragmentToSplit.value.indexOf('.....');
        const newTextFragment: Fragment = fragmentToSplit.split(splitIndex);
        newTextFragment.value = newTextFragment.value.replace(/^(\.+)/, '').trim();
        fragmentToSplit.value = fragmentToSplit.value.trim();

        newClause.children[0].children.push(...textContent.slice(0, fragmentIndex + 1));
        newClause.children[2].children.push(newTextFragment, ...textContent.slice(fragmentIndex + 1));
      }
      newClause.insertAfter(lastClause);
      lastClause = newClause;
      newClause.inferWeight();
      fragmentsToCreate.push(newClause);

      // Remove list item children to prevent duplication when undoing
      listItem.children = <TreeArray<Fragment>>[];
    });
    this.fragment.remove();
    this._fragmentService.delete(this.fragment);
    this._fragmentService.create(fragmentsToCreate);
    this._fragmentService.update(fragmentsToUpdate);
  }

  public splitToItemClauses(): void {
    const fragmentsToCreate: Fragment[] = [];
    const fragmentsToUpdate: Fragment[] = [];
    let lastClause: Fragment = this._clause;

    this.fragment.children.forEach((listItem: ListItemFragment) => {
      fragmentsToUpdate.push(...listItem.children);
      const clause: ClauseFragment = new ClauseFragment(
        null,
        ClauseType.ITEM,
        listItem.children,
        '',
        null,
        null,
        null,
        null,
        null,
        false
      );
      clause.insertAfter(lastClause);
      lastClause = clause;
      clause.inferWeight();
      fragmentsToCreate.push(clause);

      // Remove list item children to prevent duplication when undoing
      listItem.children = <TreeArray<Fragment>>[];
    });
    this.fragment.remove();
    this._fragmentService.delete(this.fragment);
    this._fragmentService.create(fragmentsToCreate);
    this._fragmentService.update(fragmentsToUpdate);
  }

  /**
   * Check whether we can move the fragment up or down.
   */
  public canMove(up: boolean): boolean {
    const newClause: ClauseFragment = this._findNextClauseToMoveTo(up);

    return !!newClause && this.canLock() && this._lockService.canLock(newClause);
  }

  /**
   * Move the fragement up or down.
   */
  public move(up: boolean): void {
    const newClause: ClauseFragment = this._findNextClauseToMoveTo(up);

    if (!newClause || !this._lockService.canLock(newClause)) {
      return;
    }

    const firstCaptionedFragment: Fragment = newClause.children.find((fragment) => fragment.isCaptioned());

    this.fragment.remove();
    if (!!firstCaptionedFragment) {
      this.fragment.insertBefore(firstCaptionedFragment);
    } else {
      newClause.children.push(this.fragment);
    }

    this._fragmentService.update(this.fragment).then(() => this._lockService.unlock(this._clause));
    const caretPosition: Caret = this._caretService.getCaretPositionFromSelectedFragment();
    this._closeTooltips();

    this._selectionOperationsService.setSelected(caretPosition.fragment, caretPosition.offset, PadType.MAIN_EDITABLE);
  }

  /**
   * Finds the target clause to move the current list to, either above or below the current clause. This ignores any
   * clause group structure and will move into or out of these, it will only find clauses which can contain lists and
   * which can be locked.
   */
  private _findNextClauseToMoveTo(up: boolean): ClauseFragment {
    const section: SectionFragment = this.fragment.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
    const clauses: ClauseFragment[] = section.getClauses();

    const step: number = up ? -1 : 1;
    let index: number = clauses.indexOf(this._clause) + step;

    while (index >= 0 && index <= clauses.length - 1) {
      const clause: ClauseFragment = clauses[index];
      if (this._canAddListToClause(clause) && this._lockService.canLock(clause)) {
        return clause;
      }
      index += step;
    }

    return null;
  }

  /**
   * Checks if the target clause can have a new list added - this cannot be added to SIs, item or unmodifiable clauses,
   * or clauses that already contain a list as a child.
   */
  private _canAddListToClause(targetClause: ClauseFragment): boolean {
    return (
      !targetClause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION, ClauseType.ITEM) &&
      !targetClause.isUnmodifiableClause &&
      !targetClause.children.find((frag) => frag.is(FragmentType.LIST))
    );
  }

  /**
   * Stops the tooltips being stuck in the shown state when the fragment is moved
   *
   * @param event {MouseEvent}   The mousedown event
   */
  private _closeTooltips() {
    this.moveUpTooltip.hide();
    this.moveDownTooltip.hide();
  }

  /**
   * Deletes the fragment.
   */
  public async delete(): Promise<void> {
    if (!(await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithSubtrees(this.fragment))) {
      return;
    }

    this._setCaretPositionThenDelete();
  }

  private _setCaretPositionThenDelete(): void {
    this._selectFragmentForAfterDeletion();
    // Validated in the delete() method above
    this._fragmentService.deleteValidatedFragments(this.fragment);
  }

  /**
   * Ensures the carets are in a valid editable fragment after a deletion operation.
   * Selects the end of the last editable fragment of the previous sibling (if exists), deleting a list will always
   * have a previous editable fragment within the clause so only need to look behind the list.
   */
  private _selectFragmentForAfterDeletion(): void {
    let prevSibling: Fragment = this.fragment.previousSibling();
    let fragmentToSelect: Fragment = prevSibling ? getFinalEditableDescendant(prevSibling) : null;

    while (!!prevSibling && !fragmentToSelect) {
      prevSibling = prevSibling.previousSibling();
      fragmentToSelect = getFinalEditableDescendant(prevSibling);
    }

    const offsetToSelect: number = prevSibling ? fragmentToSelect.length() : 0;

    this._selectionOperationsService.setSelected(fragmentToSelect, offsetToSelect, PadType.MAIN_EDITABLE);
  }
}
