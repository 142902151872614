import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {
  AfterViewChecked,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {CarsRange} from 'app/fragment/cars-range';
import {ClauseComponent} from 'app/fragment/clause/clause.component';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {EquationFragmentComponent} from 'app/fragment/equation/equation-fragment.component';
import {TextFragmentComponent} from 'app/fragment/text/text-fragment.component';
import {CanvasService} from 'app/services/canvas.service';
import {CaretService} from 'app/services/caret.service';
import {RichTextService, RichTextType} from 'app/services/rich-text.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {Subject, Subscription} from 'rxjs';
import {BlurOption} from './blur-options';
import {ElementRefService, PadType} from './element-ref.service';
import {ActionRequest} from './fragment/action-request';
import {Caret} from './fragment/caret';
import {InputFragmentComponent} from './fragment/clause-group/no-op-clause/input-fragment/input-fragment.component';
import {FragmentMapper} from './fragment/core/fragment-mapper';
import {Key} from './fragment/key';
import {Lock} from './fragment/lock/lock';
import {Suite} from './fragment/suite';
import {ClauseFragment, DocumentFragment, FigureFragment, Fragment, FragmentType, TextFragment} from './fragment/types';
import {InputFragment} from './fragment/types/input/input-fragment';
import {PadOperationsService} from './pad-operations.service';
import {SelectionOperationsService} from './selection-operations.service';
import {AltAccessibilityService} from './services/alt-accessibility.service';
import {ClauseService} from './services/clause.service';
import {CopyPasteService} from './services/copy-paste/copy-paste.service';
import {PasteHandler} from './services/copy-paste/paste-handler';
import {DomService} from './services/dom.service';
import {FragmentService} from './services/fragment.service';
import {LockService} from './services/lock.service';
import {SpecialCaseHandler} from './special-case-handler';
import {Browser} from './utils/browser';
import {UUID} from './utils/uuid';
import {ViewService} from './view/view.service';

// TODO: Start considering moving some of the code out of this file and into utility classes.
// This should aid in testing and make the control flow of the text editor become more apparent.

type SelectionCallback = (
  fragment: Fragment,
  startCaret?: Caret,
  endCaret?: Caret
) => ActionRequest | Promise<ActionRequest>;

@Directive({
  selector: '[carsContentEditable]',
  exportAs: 'carsContentEditable',
})
export class ContentEditableDirective implements OnInit, AfterViewChecked, OnDestroy {
  @Input() padType: PadType;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @HostBinding('attr.contenteditable')
  @Input('carsContentEditable')
  public editable: boolean = true;

  private _subscriptions: Subscription[] = [];

  private _caret: Caret = new Caret(null, 0);

  private _altHeld: boolean = false;
  private _failed: boolean = false;

  constructor(
    private _fragmentService: FragmentService,
    private _clauseService: ClauseService,
    private _lockService: LockService,
    private _domService: DomService,
    private _caretService: CaretService,
    private _richTextService: RichTextService,
    private _altAccessibilityService: AltAccessibilityService,
    private _sidebarService: SidebarService,
    private _canvasService: CanvasService,
    private _selectionOperationsService: SelectionOperationsService,
    private _padOperationsService: PadOperationsService,
    private _copyPasteService: CopyPasteService,
    private _specialCaseHandler: SpecialCaseHandler,
    private _viewService: ViewService,
    private _elementRefService: ElementRefService,
    private _elementRef: ElementRef,
    private _snackbar: MatSnackBar
  ) {}

  /**
   * Initialise this directive by subscribing to various observables.
   */
  public ngOnInit(): void {
    this._subscriptions.push(
      this._richTextService.subscribe(this._handleRichText.bind(this)),

      // Blur the pad if the user's current clause is unlocked due to inactivity
      this._lockService.onChange((lock: Lock) => {
        const currentClause: Fragment = this._getClause();
        const lockedClause: Fragment = this._fragmentService.find(lock.clauseId);
        if (!lock.locking && currentClause && currentClause.equals(lockedClause)) {
          window.getSelection();
          this._elementRef.nativeElement.blur();
        }
      }),
      this._altAccessibilityService.onAltEvent().subscribe((value: boolean) => {
        this._altHeld = value;
      }),

      this._fragmentService.onFailure((error: HttpErrorResponse, failedIds: UUID[]) => {
        this.editable = false;
      })
    );
    this._elementRefService.setElementRef(this.padType, this._elementRef);
  }

  /**
   * Update the caret position, if necessary, after Angular has made template changes.  This
   * is necessary to ensure the caret doesn't jump back to the start of the containing text
   * node when it is removed and recreated by Angular bindings.
   */
  public ngAfterViewChecked(): void {
    this._caret = this._selectionOperationsService.updateCaretPosition(this._caret);
  }

  /**
   * Clean up this directive by removing any subscriptions.
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * Prevent drop events.
   *
   * @param event {DragEvent}   The drop event
   */
  @HostListener('drop', ['$event'])
  public ondrop(event: DragEvent): void {
    event.preventDefault();
  }

  /**
   * Respond to a blur event by unfocusing all fragments.  _getClause() in Firefox will return null, but chrome will return the clause the
   * caret was in.
   *
   * In certain cases we do not want a blur to be carried out. For example, if the blur is caused by clicking the clause-type dropdown or
   * the table menu, then we don't want to unfocus fragments as this will unlock them. In these cases the following attributes, from the
   * BlurOption enum, may be set on a component to prevent the blur:
   *
   * BlurOption.PREVENT_BLUR - prevent blur on this element;
   * BlurOption.PREVENT_IMMEDIATE_CHILD_BLUR - prevent blur on any child of this element;
   * BlurOption.INSERT_TABLE - prevent blur when inserting into a table; also updates the currently focused fragment.
   *
   * @param event {FocusEvent}   The blur event
   */
  @HostListener('blur', ['$event'])
  public onBlur(event: FocusEvent): void {
    const el: HTMLElement = event.relatedTarget as any;
    // The target element can be null if, say, the user clicks outside the browser window
    if (el !== null) {
      if (
        this._sidebarService.getStatus() !== SidebarStatus.CLOSED ||
        this._parentHasAttributeWithValue(el, 'blurOption', BlurOption.PREVENT_IMMEDIATE_CHILD_BLUR)
      ) {
        return;
      } else {
        switch (el.getAttribute('blurOption')) {
          case BlurOption.PREVENT_BLUR:
            return;
          case BlurOption.INSERT_TABLE:
            this._selectionOperationsService.setSelected(this._caret.fragment, this._caret.offset, this.padType);
            return;
        }
      }
    }

    if (this._caret.fragment && this._caret.fragment.component) {
      this._caret.fragment.component.blur();
      if (this._caret.fragment.component.parent) {
        this._caret.fragment.component.parent.blur();
      }
    }
    this._clauseService.requestVersion();

    this._selectionOperationsService.setSelected(null, 0, this.padType);
  }

  private _parentHasAttributeWithValue(el: HTMLElement, att: string, value: string): boolean {
    const parent: HTMLElement = el.parentElement;
    return parent === null ? false : parent.getAttribute(att) === value;
  }

  @HostListener('paste', ['$event'])
  public async onPaste(event: Event): Promise<void> {
    this.setInputIsPasting();

    if (!this.editable) {
      return;
    }

    event.preventDefault();

    const selection: Selection = window.getSelection();

    const handler: PasteHandler = PasteHandler.getHandler(
      this._copyPasteService,
      this._padOperationsService,
      this._domService,
      this._snackbar,
      event
    );
    const suite: Suite = (this._fragmentService.find(this._getClause().documentId) as DocumentFragment).suite;
    await handler.getFragments(this._caret, this._getClause(), suite).then((fragments: Fragment[]) => {
      if (handler.canPaste(selection) && !this.isPastingEquationIntoTableInClauseGroup(fragments)) {
        if (!selection.isCollapsed) {
          if (!this._deleteSelection(false)) {
            return;
          }
        }

        handler.doPaste(this._caret, fragments, this.padType);
        this._clauseService.requestVersion();
      } else {
        this._snackbar.open('Paste is not allowed for this type of content', 'Dismiss', {duration: 5000});
        this._copyPasteService.setCursorStyle(false);
      }
    });
  }

  private isPastingEquationIntoTableInClauseGroup(fragments: Fragment[]): boolean {
    return (
      fragments.length &&
      fragments[0].type === FragmentType.EQUATION &&
      !!this._caret.fragment.findAncestorWithType(FragmentType.TABLE) &&
      !!this._caret.fragment.findAncestorWithType(FragmentType.CLAUSE_GROUP)
    );
  }

  /**
   * Helper method to set isPasting property for input fragments.
   *
   */
  private setInputIsPasting(): void {
    const input: InputFragment = this._caret.fragment.findAncestorWithType(FragmentType.INPUT) as InputFragment;

    if (!!input) {
      (input.component as InputFragmentComponent).isPasting = true;
    }
  }

  /**
   * Cut a selection to the clipboard.
   *
   * @param event {Event}   The cut event
   */
  @HostListener('cut', ['$event'])
  public async onCut(event: Event): Promise<void> {
    if (!this.editable) {
      return;
    }

    event.preventDefault();

    if (!this._copyPasteService.canCopy(document.getSelection())) {
      this._snackbar.open('The selected content cannot be copied.', 'Dismiss', {duration: 5000});
      return;
    }

    document.execCommand('copy');
    await this._deleteSelection(true);
    this._clauseService.requestVersion();
  }

  @HostListener('copy', ['$event'])
  public onCopy(event: ClipboardEvent): void {
    const selection: Selection = document.getSelection();
    const plainText: string = selection.toString();
    const htmlText: string = this._domService.getHTMLFromSelection(selection);

    if (!this._copyPasteService.canCopy(selection)) {
      event.preventDefault();
      this._snackbar.open('The selected content cannot be copied.', 'Dismiss', {duration: 5000});
      return;
    }

    this._copyPasteService.copy(event, selection, plainText, htmlText);
  }

  /**
   * Respond to a keydown event by dispatching the event to an appropriate handler.
   * Listens to both keypress and keydown because Chrome will not see the keypress
   * events and Firefox only intermittently fires keydown events.
   *
   * @param event {KeyboardEvent}   The keydown event
   */
  @HostListener('keypress', ['$event'])
  @HostListener('keydown', ['$event'])
  public async onKeydown(event: KeyboardEvent): Promise<void> {
    const key: Key = Key.fromEvent(event);
    let prevent: boolean = true;

    if (key.isSelection()) {
      this._selectionOperationsService.selectAll(this._caret);
    } else if (key.isPageUp() || key.isPageDown()) {
      this._selectionOperationsService.onPageUpOrDown(this._caret, this.padType, key.isPageUp());
    } else if (key.meta || key.isShortcut() || key.isFunction() || key.isDev() || key.isModifier()) {
      prevent = false; // Nothing to do, the default action is correct.
    } else if (key.isDisabled()) {
      this._snackbar.open('This action is disabled to conform to MDD formatting requirements.', 'Dismiss', {
        duration: 3000,
      });
    } else if (key.isNavigation()) {
      prevent = false;
      if (event.type !== 'keypress') {
        this._onNavigation(key);
      }
    } else if (key.equals(new Key(['z', 'y'], false, true))) {
      const steps: number = key.value === 'z' || key.value === 'Z' ? 1 : -1;
      Logger.analytics(key.value === 'z' || key.value === 'Z' ? 'undo' : 'redo', 'keyboard');
      this._handleRevert(steps);
    } else if (!this._altHeld && !key.isSearchDocument()) {
      // This is to allow the creation of a table from the table creator using enter, without effecting the pad.
      if (!(key.equals(Key.ENTER) && document.getElementById('table-creator-menu'))) {
        // Prevent default behaviour before async method to avoid weird behaviour
        event.preventDefault();
        prevent = false;
        await this._handleKeydown(key);
      }
    }

    if (prevent) {
      event.preventDefault();
    }
  }

  /**
   * Respond to a mousedown event by setting the focused fragment.  The document prefix on
   * the listener is needed for Firefox to unlock clauses when they are clicked off, since
   * the blur listener cannot in Firefox.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  @HostListener('mousedown', ['$event'])
  public onMousedown(event: MouseEvent): void {
    event.stopPropagation();

    this._canvasService.togglemouseEvents(false);

    const target: Node = event.target as Node;
    const clause: ClauseFragment = this._getClause(target);

    if (this.editable && clause && this._lockService.canLock(clause)) {
      const component: EquationFragmentComponent = FragmentComponent.fromNode(target) as EquationFragmentComponent;
      if (component.content.is(FragmentType.EQUATION)) {
        this._selectionOperationsService.setSelected(component.content.source, 0, this.padType);
      } else {
        this._onNavigation();
      }
    } else if (!this._domService.hasAncestor(this._elementRef.nativeElement, target)) {
      this._clauseService.setSelected(null);
    }
  }

  /**
   * Respond to a mouseup event by setting the selected text.
   *
   * @param event {MouseEvent}   The mouseup event
   */
  @HostListener('document:mouseup', ['$event'])
  public onMouseup(event: MouseEvent): void {
    if (this._failed || this.padType === PadType.PUBLISHED_CHANGLOG) {
      return;
    }

    this._canvasService.togglemouseEvents(true);

    // Note that we don't want to update the caret postion if we're in the changelog markup view, as this could mean the
    // changelog range isn't created in the expected place
    if (
      this._domService.hasAncestor(this._elementRef.nativeElement, event.target as Node) &&
      !this._viewService.getCurrentView().isChangelogMarkup()
    ) {
      this._onNavigation();
    }

    this._caretService.setSelection(CarsRange.fromArray(this._domService.getCaretsFromSelection()));
  }

  @HostListener('keyup', ['$event'])
  public onKeyup(event: Event): void {
    this._caretService.setSelection(CarsRange.fromArray(this._domService.getCaretsFromSelection()));
  }

  /*
   * Respond to a UI event from the rich text toolbar by adding fragments as appropriate.
   *
   * @param type {RichTextType}   The type of rich text event
   * @param args {any[]}          The arguments bundled by the event issuer
   */
  private async _handleRichText(type: RichTextType, ...args: any[]): Promise<void> {
    if (!this.editable) {
      return;
    }
    if (
      this._caret &&
      this._caret.fragment &&
      this._caret.fragment.component &&
      this._caret.fragment.component.readOnly
    ) {
      return;
    }

    switch (type) {
      case RichTextType.REVERT:
        {
          this._handleRevert(args[0]);
        }
        break;

      case RichTextType.SUGGESTION:
        {
          const startCaret: Caret = args[0];
          const endCaret: Caret = args[1];
          const suggestedValue: string = args[2];
          const completedSubject: Subject<void> = args[3];
          await this._deleteSelection(true, [startCaret, endCaret], false);
          this._padOperationsService.insertFragments(
            this._caret,
            [new TextFragment(null, suggestedValue)],
            this.padType,
            false
          );
          this._clauseService.requestVersion();
          completedSubject.next();
        }
        break;

      case RichTextType.SPELLING_SUGGESTION:
        {
          const range: CarsRange = args[0];
          const suggestion: string = args[1];
          const fragment: Fragment = FragmentMapper.createTextualFrom(range[0].fragment.type, suggestion);
          await this._deleteSelection(true, [range[0], range[1]]);
          this._padOperationsService.insertFragments(this._caret, [fragment], this.padType);
        }
        break;

      case RichTextType.FIGURE:
        {
          // args[0] is the figure fragment
          // args[1] is the parent of the new figure, either a table cell or a clause
          // args[2] is the index of the figure to be replaced, null if a new figure is being added
          // args[3] is the image file
          this._handleFigure(args[0], args[1], args[2], args[3]);
        }
        break;

      case RichTextType.EQUATION:
      case RichTextType.LIST:
      case RichTextType.NATIONALLY_DETERMINED_REQUIREMENT:
      case RichTextType.TABLE:
      case RichTextType.STANDARD_FORMAT_GROUP:
      case RichTextType.SPECIFIER_INSTRUCTION:
        {
          const [start, end]: Caret[] = this._domService.getCaretsFromSelection();
          if (start) {
            let fragment: Fragment = start.fragment;
            let action: ActionRequest;
            while (!action && fragment && fragment.isAttached()) {
              action = await fragment.component.onRichText(type, start, end, ...args);
              fragment = fragment.parent;
            }
            this._caret.offset = 0; // place the caret at the start of the selected fragment
            this._caret = this._padOperationsService.handleActionRequest(this._caret, action, this.padType);
          }
        }
        break;
      case RichTextType.TEXT:
        {
          // Adds am empty text fragment after an image in a table
          const [start, end]: Caret[] = this._domService.getCaretsFromSelection();
          const fragment: Fragment = args[2];
          const action: ActionRequest = await fragment.component.onRichText(type, start, end, ...args);
          this._caret = this._padOperationsService.handleActionRequest(this._caret, action, this.padType);
        }
        break;

      case RichTextType.SUBSCRIPT:
      case RichTextType.SUPERSCRIPT:
      case RichTextType.MEMO:
        {
          const [start, end]: Caret[] = this._domService.getCaretsFromSelection();
          const isSameType: boolean = this._checkIfAllFragmentsInSelectionAreTheSameType(start, end, type);
          this._defaultRichTextHandler(start, end, (fragment: Fragment, _start: Caret, _end: Caret) => {
            return fragment.isAttached() ? fragment.component.onRichText(type, _start, _end, isSameType) : null;
          });
        }
        break;
      default:
        {
          const [start, end]: Caret[] = this._domService.getCaretsFromSelection();
          this._defaultRichTextHandler(start, end, (fragment: Fragment, _start: Caret, _end: Caret) => {
            return fragment.isAttached() ? fragment.component.onRichText(type, _start, _end) : null;
          });
        }
        break;
    }
  }

  /**
   * Handle a navigation key event by updating the caret position and locking/unlocking clauses.
   * If not editable then fragmentService.setSelected is called for components that require notifying of
   * the selectedFragment when read only such as the discussions sidebar in Review Mode.
   *
   * IMPORTANT: The requestAnimationFrame() here is necessary due to @HostListener()'s event handling:
   * the keyboard events are caught here before reaching the contenteditable.  Deferring execution
   * ensures that the caret has been drawn in its new position _before_ we try any updates.
   */
  private _onNavigation(key: Key = null): void {
    const oldComponent: FragmentComponent = FragmentComponent.fromNode(window.getSelection().anchorNode);

    if (!this.editable) {
      if (oldComponent) {
        this._fragmentService.setSelected(oldComponent.content);
      }
      return;
    }

    let specialCase: boolean = false;

    requestAnimationFrame(() => {
      const selection: Selection = window.getSelection();
      if (!selection.isCollapsed) {
        return;
      }
      const component: FragmentComponent = FragmentComponent.fromNode(selection.anchorNode);
      if (component) {
        let offset: number;
        if (Browser.isEdge() && !key) {
          const range: Range = selection.getRangeAt(0);
          const preCaretRange: Range = range.cloneRange();
          preCaretRange.selectNodeContents(selection.anchorNode);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          offset = preCaretRange.toString().length;
        } else {
          offset = this._domService.textOffsetToFragmentOffset(selection.anchorNode, selection.anchorOffset);
        }
        if (oldComponent) {
          specialCase = this._specialCaseHandler.handle(
            this._caret,
            key,
            oldComponent.content,
            component.content,
            this.padType
          );
        }
        if (!specialCase) {
          if (!component.content.equals(this._caret.fragment) || offset !== this._caret.offset) {
            component.markForCheck();
            this._selectionOperationsService.setSelected(component.content, offset, this.padType);
          } else {
            this._fragmentService.setSelected(component.content);
          }
        }
      }
    });
  }

  /**
   * Handle a keydown event by letting fragments up the tree handle the event, unless the key is delete/backspace over a selection
   * in which case do not allow handling by the section component as the _deleteSelection method already handles merging clauses.
   *
   * @param key {Key}   The pressed key
   */
  private async _handleKeydown(key: Key): Promise<void> {
    const selection: Selection = window.getSelection();

    if (this.editable && this._caret.fragment && selection.rangeCount > 0) {
      let action: ActionRequest = null;
      const shouldDelete: boolean = key.isOperation() && !selection.isCollapsed;
      if (shouldDelete) {
        await this._deleteSelection(true);
      }

      const leafFragment: Fragment = this._caret.fragment;
      let fragment: Fragment = leafFragment;
      while (!action && fragment && fragment.isAttached() && (!shouldDelete || !fragment.is(FragmentType.SECTION))) {
        action = await fragment.component.onKeydown(key, leafFragment.component, this._caret);
        fragment = fragment.parent;
      }

      if (action && shouldDelete) {
        action.remove = 0;
      }

      this._caret = this._padOperationsService.handleActionRequest(this._caret, action, this.padType);
    }
  }

  /**
   * Ask the FragmentService to undo or redo the last change set.
   *
   * @param key {Key}   The key that was pressed
   */
  private _handleRevert(steps: number): void {
    this._fragmentService
      .revert(steps)
      .then((response: HttpResponse<any>) => {
        // If the caret was in a fragment that was deleted by reverting, blur.
        const caret: Caret = this._caret;
        this._selectionOperationsService.setSelected(caret.fragment, caret.offset, this.padType);
      })
      .catch(() => {});
  }

  /**
   * Set the caret to the right position and insert image in correct place
   *
   * @param figureID        {FigureFragment}  The figure to be displayed
   * @param parentHandler   {Fragment}        The parent of the figure to be replaced
   * @param oldFigureIndex  {number}          The index of the figure to be replaced in its parent's child array
   */
  private async _handleFigure(
    figureId: FigureFragment,
    parentHandler: Fragment,
    oldFigureIndex: number,
    file: File
  ): Promise<void> {
    if (!parentHandler) {
      parentHandler = this._caret.fragment.parent;
      const currentTextComponent = this._caret.fragment.component as TextFragmentComponent;
      if (currentTextComponent.caption) {
        oldFigureIndex = this._caret.fragment.parent.index();
      }
    }

    let action: ActionRequest;
    while (!action && parentHandler && parentHandler.isAttached()) {
      if (parentHandler.is(FragmentType.LIST)) {
        oldFigureIndex = parentHandler.index();
      }
      action = await parentHandler.component.onRichText(
        RichTextType.FIGURE,
        this._caret,
        this._caret,
        figureId,
        oldFigureIndex,
        file
      );
      parentHandler = parentHandler.parent;
    }

    this._caret = this._padOperationsService.handleActionRequest(this._caret, action, this.padType);
  }

  /**
   * Convenience function to get the clause component above a given node.  If no node is given,
   * the current selection's anchorNode is used.
   *
   * @param? node {Node}             The node to search from
   * @returns     {ClauseFragment}   The clause component above node
   */
  private _getClause(node?: Node): ClauseFragment {
    node = node || (this._caret.fragment || <any>{}).element || window.getSelection().anchorNode;

    const component: ClauseComponent = FragmentComponent.fromNode(node, FragmentType.CLAUSE) as ClauseComponent;
    return component ? component.content : null;
  }

  /**
   * Iteratively apply a callback to each fragment within the current document selection, with
   * iteration strategy matching that described by Tree::iterate().  The callback must have
   * signature matching the typedef SelectionCallback.
   *
   * @param start     {Caret}               The start caret
   * @param end       {Caret}               The end caret
   * @param callback  {SelectionCallback}   The callback
   */

  private async _defaultRichTextHandler(start: Caret, end: Caret, callback: SelectionCallback): Promise<void> {
    const actions: ActionRequest[] = await start.fragment
      .root()
      .iterateUpAsync(start.fragment, end.fragment, async (fragment: Fragment) => {
        const action: ActionRequest = await callback(fragment, start, end);
        if (action) {
          action.fragment = action.fragment || fragment;
        }
        return action;
      });

    // Handle all the ActionRequests we got back; these are in left-to-right document order
    const lastAction: ActionRequest = actions[actions.length - 1];
    if (lastAction) {
      const caret = new Caret(lastAction.fragment, lastAction.offset);
      this._selectionOperationsService.setSelected(lastAction.fragment, lastAction.offset, this.padType);
      actions.forEach((action: ActionRequest) => {
        if (action.fragment && action.fragment.parent) {
          this._fragmentService.mergeChildren(action.fragment.parent, caret, true);
        }
      });
    }
  }

  /**
   * Delete the fragment subtree spanned by the browser selection.
   *
   * @param focus {boolean} focus the start of the deleted selection if true.
   */
  private async _deleteSelection(
    mergeClauses: boolean,
    [start, end]: Caret[] = this._domService.getCaretsFromSelection(),
    focus: boolean = true
  ): Promise<boolean> {
    const result: any[] = await this._padOperationsService.deleteSelection(
      mergeClauses,
      this.padType,
      [start, end],
      focus
    );
    this._caret = result[0] ? result[1] : this._caret;
    return result[0];
  }

  /**
   * This method checks that all fragments within the caret selection are of the same type. A few caveats:
   * - If the fragment is empty/has 0 length, we filter it out of the check
   * - If the type is 'MEMO', we filter out any fragments that aren't MEMO or TEXT as super/subscript fragments aren't affected by highlight.
   */
  private _checkIfAllFragmentsInSelectionAreTheSameType(start: Caret, end: Caret, type: RichTextType): boolean {
    const test1: Fragment = start.fragment.root();
    const test = test1.iterateUp(start.fragment, end.fragment, (fragment: Fragment) => {
      return fragment;
    });
    const fragments = test
      .filter((frag) => frag.length() > 0)
      .filter((frag) => type !== RichTextType.MEMO || frag.is(FragmentType.MEMO, FragmentType.TEXT));

    return fragments.find((fragment) => fragment.type !== fragments[0].type) === undefined;
  }
}
