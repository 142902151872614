import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Lock} from 'app/fragment/lock/lock';
import {ClauseFragment} from 'app/fragment/types';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {EquationService} from 'app/services/equation.service';
import {Browser} from 'app/utils/browser';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';
import {ClauseService} from '../../services/clause.service';
import {LockService} from '../../services/lock.service';

@Component({
  selector: 'cars-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private static readonly DEBOUNCE_MS: number = 500;

  @Input() public clause: ClauseFragment;
  @Output() public savingEmitter: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('render') private _renderRef: ElementRef;
  @ViewChild('source') private _sourceRef: ElementRef;

  public readOnly: boolean;

  public saved: boolean = false;
  public saving: boolean = false;
  public rendered: boolean;

  public readonly firefox: boolean = Browser.isFirefox();

  private backgroundChange: Subject<string> = new Subject();
  private _subscriptions: Subscription[] = [];

  public isBackgroundView: boolean = false;
  private _canAuthorDocument: boolean = false;

  constructor(
    private _clauseService: ClauseService,
    private _lockService: LockService,
    private _equationService: EquationService,
    private _permissionsService: PermissionsService
  ) {}

  /**
   * Initialise this component by setting up save debounces.
   */
  public ngOnInit(): void {
    this.backgroundChange
      .pipe(
        tap((...args) => {
          this.saved = false;
          this.savingEmitter.emit(true);
        }),
        debounceTime(BackgroundComponent.DEBOUNCE_MS)
      )
      .subscribe(() => {
        this.save();
      });

    this._subscriptions.push(
      this._clauseService.onUpdate(() => {
        if (!this.canEditBackgroundCommentary()) {
          this.renderCommentary();
        }
      }),
      this._lockService.onChange(
        () => {
          this.renderCommentary();
        },
        (lock: Lock) => this.clause && !this.clause.background && this.rendered && this.clause.id.equals(lock.clauseId)
      ),
      this._permissionsService
        .can(CarsAction.IS_BACKGROUND_VIEW_MODE, this.clause.documentId)
        .subscribe((isBackground: boolean) => (this.isBackgroundView = isBackground)),
      this._permissionsService
        .can(CarsAction.AUTHOR_DOCUMENT, this.clause.documentId)
        .subscribe((canAuthor: boolean) => (this._canAuthorDocument = canAuthor))
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * @inheritdoc
   * Called after Angular initialises the component. Renders the commentary.
   */
  public ngAfterViewInit(): void {
    setTimeout(this.renderCommentary.bind(this));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('clause')) {
      setTimeout(() => this.renderCommentary());
    }
  }

  /**
   * Returns whether the background commentary can be edited.
   */
  public canEditBackgroundCommentary(): boolean {
    return this._canAuthorDocument && this._lockService.canLock(this.clause) && !this.clause.isUnmodifiableClause;
  }

  /**
   * Getter for the chip status text.
   *
   * @returns {string}   The status
   */
  public get status(): string {
    return this.saving ? 'Saving...' : 'Saved';
  }

  /**
   * Respond to background text changes by broadcasting the change.
   *
   * @param value {string}   The new value
   */
  public onChange(value: string): void {
    this.backgroundChange.next(value);
  }

  /**
   * Save the current clause background.
   */
  public save(): void {
    this.saved = false;
    this.saving = true;
    this._clauseService.update(this.clause).then(() => {
      this.saved = true;
      this.saving = false;
      this.savingEmitter.emit(false);
    });
  }

  /**
   * Unlocks clause on blur for the background & commentary view.
   */
  public unlockClause(): void {
    this._lockService.unlock(this.clause, true);
  }

  /**
   * Renders background commentary through {@link EquationService}
   */
  public renderCommentary(): void {
    this.rendered = true;
    if (this.clause) {
      if (!this.clause.background) {
        this._renderRef.nativeElement.innerHTML = this.canEditBackgroundCommentary()
          ? 'Please enter some background commentary.'
          : 'No background commentary has been entered for this clause yet.';
      } else {
        this._equationService
          .renderEquationsContainedInSource(this._renderRef.nativeElement, this.clause.background)
          .subscribe();
      }
    }
  }

  /**
   * Focus source textarea and set rendered boolean to false.
   *
   * @param event {Event} Event from mousedown
   */
  public focusTextEditable(event: Event): void {
    if (!!event) {
      event.preventDefault();
    }
    if (this.canEditBackgroundCommentary()) {
      this.rendered = false;
      setTimeout(() => this._sourceRef.nativeElement.focus(), 0);
    }
  }
}
