<div>
  <span class="print-header">{{ getClauseChangeSummaryPrintHeader() }}</span>
</div>

<div class="header">
  <div class="dropdown-container">
    <span class="dropdown-label hide-On-Print-Dialog">Older version:</span>
    <mat-form-field class="hide-On-Print-Dialog">
      <mat-select
        placeholder="Select version"
        (selectionChange)="changeVersionLH($event)"
        disableRipple
        [(value)]="currentOptionLH"
        [attr.aria-label]="currentOptionLH && getOptionDisplayString(currentOptionLH)"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
        [matTooltip]="currentOptionLH && getOptionDisplayString(currentOptionLH)"
      >
        <ng-container *ngFor="let option of versionOptions">
          <mat-option [value]="option" [attr.aria-label]="option && getOptionDisplayString(option)">
            {{ getOptionDisplayString(option) }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="dropdown-container">
    <span class="dropdown-label hide-On-Print-Dialog">Newer version:</span>
    <mat-form-field class="hide-On-Print-Dialog">
      <mat-select
        placeholder="Select version"
        (selectionChange)="changeVersionRH($event)"
        disableRipple
        [(value)]="currentOptionRH"
        [attr.aria-label]="currentOptionRH && getOptionDisplayString(currentOptionRH)"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
        [matTooltip]="currentOptionRH && getOptionDisplayString(currentOptionRH)"
      >
        <ng-container *ngFor="let option of versionOptionsRH">
          <mat-option [value]="option" [attr.aria-label]="option && getOptionDisplayString(option)">
            {{ getOptionDisplayString(option) }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <button
    style="margin-left: 1%"
    mat-raised-button
    color="primary"
    (click)="generate()"
    matTooltip="Generate clause change summary for the selected versions"
    [matTooltipShowDelay]="tooltipDelay"
    aria-label="Generate clause change summary for the selected versions"
    [disabled]="loading || !canGenerate"
    enableDefaultFocus
  >
    <mat-icon>autorenew</mat-icon>
    Generate
  </button>
</div>

<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<div class="clause-summary-information">
  <ul class="legend-flex-display" aria-label="Colour Legend">
    <li class="legend-description" aria-label="Summary key/Legend">Key to mark-up used below:</li>
    <li class="version-created" aria-label="change legend created key">New text</li>
    <li class="version-removed" aria-label="change legend removed key">Removed text</li>
    <li class="version-moved" aria-label="change legend moved key">Re-ordered text</li>
    <li class="print-advice-label hide-On-Print-Dialog" aria-label="print advise label">{{ printerMessage }}</li>
  </ul>

  <a
    mat-raised-button
    color="primary"
    (click)="printRoute()"
    aria-label="Print change summary view"
    enableDefaultFocus
    [disabled]="loading || !canGenerate"
  >
    <mat-icon class="mat-24">print</mat-icon>
    Print
  </a>

  <a mat-raised-button color="primary" (click)="closeRoute()" aria-label="Close change summary view" enableDefaultFocus>
    <mat-icon class="mat-24">close</mat-icon>
    Close
  </a>

  <div class="hide-On-Print-Dialog" style="margin-right: 5px">
    <mat-slide-toggle color="primary" [(ngModel)]="showBackgroundCommentary">
      Show background commentary
    </mat-slide-toggle>
  </div>
</div>

<div class="body">
  <table>
    <tr>
      <th class="index-cell">Old index</th>
      <th class="clause-cell">Old clause</th>
      <th class="index-cell">New index</th>
      <th class="clause-cell">New clause</th>
      <th *ngIf="showBackgroundCommentary" class="background-cell">Background & commentary</th>
    </tr>
    <ng-container *ngFor="let row of changeSummaryRows" [ngSwitch]="row.rowType">
      <tr *ngSwitchCase="RowType.SECTION" cars-section-change-summary-row [row]="row"></tr>
      <tr
        *ngSwitchDefault
        cars-clause-change-summary-row
        [showBackgroundCommentary]="showBackgroundCommentary"
        [row]="row"
      ></tr>
    </ng-container>
  </table>
  <div class="generate-information-text hide-On-Print-Dialog" *ngIf="!hasClickedGenerate && !noVersions">
    <span>{{ notGeneratedMessage }}</span>
  </div>
  <div class="generate-information-text" *ngIf="hasClickedGenerate && !changes">
    <span>{{ noChangesMessage }}</span>
  </div>

  <div class="generate-information-text" *ngIf="noVersions">
    <span>{{ noVersionsMessage }}</span>
  </div>
</div>
