import {PadType} from 'app/element-ref.service';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PermissionsHandler} from '../handlers/permissions-handler';
import {PermissionASTNode} from './permission-ast-node';
import {ASTNodeType, OperationType, PermissionInput} from './permissions';

/**
 * A class encapsulating an Operation ASTNode
 *
 * @field operationType   {OperationType}         The type of operation represented by this ASTNode
 * @field left            {PermissionASTNode}     The left hand side ASTNode
 * @field right           {PermissionASTNode}     The right hand side ASTNode
 */
export class OperationASTNode extends PermissionASTNode {
  constructor(
    public readonly operationType: OperationType,
    public readonly left: PermissionASTNode,
    public readonly right: PermissionASTNode
  ) {
    super(ASTNodeType.OPERATION);
  }

  /**
   * Returns true if condition is satisfied
   *
   * @returns    {Observable<boolean>}    Observable emitting boolean representation of condition satisfied
   */
  public can(handlers: Map<PermissionInput, PermissionsHandler>, padType: PadType): Observable<boolean> {
    if (this.operationType === OperationType.NOT) {
      return this.left.can(handlers, padType).pipe(map((left): boolean => !left));
    } else {
      return combineLatest([this.left.can(handlers, padType), this.right.can(handlers, padType)]).pipe(
        map(([left, right]: [boolean, boolean]) => {
          let combinedBoolean: boolean;

          switch (this.operationType) {
            case OperationType.AND:
              combinedBoolean = left && right;
              break;
            case OperationType.OR:
              combinedBoolean = left || right;
              break;
          }

          return combinedBoolean;
        })
      );
    }
  }
}
