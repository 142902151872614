<button
  *ngIf="CarsAction.CAN_TOGGLE_USER | carsEnabled: document.id | async"
  mat-button
  (click)="disableAll()"
  matTooltip="Disable editing for all users on this document"
  matTooltipPosition="after"
  [matTooltipShowDelay]="tooltipDelay"
  aria-label="Disable editing for all users on this document"
>
  <mat-icon>indeterminate_check_box</mat-icon>Disable all users
</button>
<ng-container *ngFor="let role of assignableRoles">
  <mat-divider></mat-divider>
  <cars-user-list-autocomplete
    *ngIf="show(role)"
    [document]="document"
    [userAuthoringStatuses]="userAuthoringStatuses"
    [userPool]="userPools[role] || []"
    [properties]="getAutocompleteProperties(role)"
    [role]="role"
    (userListChanged)="loadUserPool()"
  >
  </cars-user-list-autocomplete>
</ng-container>
