import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FragmentIndexModule} from 'app/fragment/indexing/fragment-index.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {ClauseLinkTypeToDisplayNameModule} from 'app/utils/pipes/clause-link-type-to-display-name.module';
import {StandardFormatTypeToDisplayNameModule} from 'app/utils/pipes/standard-format-type-to-display-name.module';
import {ClauseGroupLinkPreviewModule} from '../clause-group-link-preview/clause-group-link-preview.module';
import {ClauseLinkSelectorComponent} from './clause-link-selector.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    StandardFormatTypeToDisplayNameModule,
    ClauseLinkTypeToDisplayNameModule,
    CarsPermissionPipesModule,
    ClauseGroupLinkPreviewModule,
    FragmentIndexModule,
  ],
  declarations: [ClauseLinkSelectorComponent],
  exports: [ClauseLinkSelectorComponent],
})
export class ClauseLinkSelectorModule {}
