import {UUID} from 'app/utils/uuid';
import {TreeArray} from '../tree/tree-array';
import {DocumentFragment, Fragment, FragmentType, SectionFragment} from '../types';
import {SectionGroupType} from './section-group-type';
import {StandardFormatType} from './standard-format-type';

export class SectionGroupFragment extends Fragment {
  public get parent(): DocumentFragment {
    return super.parent as DocumentFragment;
  }

  public set parent(value: DocumentFragment) {
    super.parent = value;
  }

  public children: TreeArray<SectionFragment>; // A section group may only contain sections

  public sectionGroupType: SectionGroupType;

  public deleted: boolean;

  /**
   * Create an empty SectionGroupFragment with the given type, and no children.
   *
   * @param sectionGroupType  {SectionGroupType}      The section group type
   * @param deletd            {boolean}               If the section group is deleted
   * @returns                 {SectionGroupFragment}  The new section group fragment
   */
  public static empty(
    sectionGroupType: SectionGroupType,
    title: string = '',
    deleted: boolean = false
  ): SectionGroupFragment {
    return new SectionGroupFragment(null, title, [], sectionGroupType, deleted);
  }

  constructor(
    id: UUID,
    title: string,
    children: SectionFragment[],
    sectionGroupType: SectionGroupType,
    deleted: boolean
  ) {
    super(id, FragmentType.SECTION_GROUP, children, title);

    this.sectionGroupType = sectionGroupType;
    this.deleted = deleted;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): SectionGroupFragment {
    return null;
  }

  /**
   * @override
   */
  public length(): number {
    return this.children.reduce((sum: number, child: Fragment) => (sum += child.length()), 0);
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();
    json.standardFormatType = StandardFormatType[json.standardFormatType];
    return json;
  }

  public getDocument(): DocumentFragment {
    return this.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment;
  }
}
