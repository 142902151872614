import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BaseService} from 'app/services/base.service';
import {HttpStatus} from 'app/utils/http-status';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NaaDocumentLinkageService extends BaseService implements OnDestroy {
  private static readonly NATIONAL_APPLICATION_ANNEX_ENDPOINT: string = `${environment.apiHost}/national-application-annex`;

  constructor(private _http: HttpClient, protected _snackBar: MatSnackBar) {
    super(_snackBar);
  }

  /**
   * Calls through to create a set of National Application Annex documents
   * and links them to the specified coreDocumentId
   *
   * @param coreDocumentId The coreDocument to link created annexes to
   * @returns The created annexes wrapped in LinkedDocuments object
   */
  public createNationApplicationAnnexesForCoreDocumentId(coreDocumentId: UUID): Promise<UUID> {
    if (!coreDocumentId) {
      throw new Error(`Tried to create NAAs for undefined coredocument Id`);
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.append('coreDocumentId', coreDocumentId.value);

    return this._http
      .post(`${NaaDocumentLinkageService.NATIONAL_APPLICATION_ANNEX_ENDPOINT}/create`, {}, {params: httpParams})
      .pipe(
        map((response: string) => UUID.orNull(response)),
        catchError((err: HttpErrorResponse) => {
          this._handleError(
            err,
            err.status === HttpStatus.CONFLICT ? 'NAA creation already in progress' : 'Failed to create NAAs'
          );

          return throwError(null);
        })
      )
      .toPromise();
  }
}
