import {Component, ElementRef, Input, Renderer2} from '@angular/core';
import {User} from 'app/user/user';
import {AbstractHovertipComponent} from 'app/widgets/abstract-hovertip.component';

@Component({
  selector: 'cars-user-hovertip',
  templateUrl: './user-hovertip.component.html',
  styleUrls: ['./user-hovertip.component.scss'],
})
export class UserHovertipComponent extends AbstractHovertipComponent {
  @Input() public user: User;

  protected readonly leftOffset: number = -135;
  protected readonly topOffset: number = 15;

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }
}
