import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DocumentFragment} from 'app/fragment/types';
import {UUID} from 'app/utils/uuid';
import {DocumentLinkService} from './document-link.service';

@Component({
  selector: 'cars-document-link',
  templateUrl: './document-link.component.html',
  styleUrls: ['./document-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentLinkComponent implements OnInit {
  @Input() document: DocumentFragment;
  @Input() title: string = '';
  @Input() linkHint: string = '';
  @Input() documentId: UUID;

  public routerLink: string = '';
  public userCanViewLink: boolean = false;

  constructor(private _documentLinkService: DocumentLinkService, private _cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.routerLink = `/documents/${this.documentId.value}`;
    this._canUserViewLink();
  }

  private _canUserViewLink(): void {
    this._documentLinkService.canUserViewDocumentLink(this.documentId).then((canView: boolean) => {
      this.userCanViewLink = canView;
      this._cdr.markForCheck();
    });
  }
}
