import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ClauseFragment, Fragment} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {Subscription} from 'rxjs';
import {ClauseStyle, ClauseStylingConfiguration, ConfigurationService} from './configuration.service';

@Directive({
  selector: '[carsClauseStyling]',
})
export class ClauseStylingDirective implements OnInit, OnDestroy {
  @Input('carsClauseStyling') public set clause(clause: ClauseFragment) {
    this._clause = clause;
    this._getAndApplyStyling();
  }

  private _clause: ClauseFragment;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _configurationService: ConfigurationService,
    private _fragmentService: FragmentService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._fragmentService.onUpdate(
        () => this._getAndApplyStyling(),
        (frag: Fragment) => frag && this._clause && frag.id.equals(this._clause.id)
      )
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  private _getAndApplyStyling(): void {
    if (this._clause && this._clause.sectionId) {
      this._configurationService
        .getClauseStylingForSectionIdAndClauseType(this._clause.sectionId, this._clause.clauseType)
        .then((stylingConfiguration: ClauseStylingConfiguration) => {
          this._clearStyles();

          if (stylingConfiguration) {
            stylingConfiguration.styling.forEach(this._applyStyle.bind(this));

            this._applyStylePxValue('fontSize', stylingConfiguration.fontSizePx);
            this._applyStylePxValue('paddingTop', stylingConfiguration.paddingTopPx);
          }
        });
    }
  }

  private _clearStyles(): void {
    this._removeStyle('fontWeight');
    this._removeStyle('fontStyle');
    this._removeStyle('textDecoration');
    this._removeStyle('fontSize');
    this._removeStyle('paddingTop');
  }

  private _applyStyle(style: ClauseStyle): void {
    switch (style) {
      case ClauseStyle.BOLD:
        this._setStyle('fontWeight', 'bold');
        break;
      case ClauseStyle.ITALICS:
        this._setStyle('fontStyle', 'italic');
        break;
      case ClauseStyle.UNDERLINE:
        this._setStyle('textDecoration', 'underline');
        break;
      default:
        break;
    }
  }

  private _applyStylePxValue(styleName: string, value: number): void {
    if (value) {
      this._setStyle(styleName, `${value}px`);
    }
  }

  private _setStyle(styleName: string, value: string): void {
    this._renderer.setStyle(this._elementRef.nativeElement, styleName, value);
  }

  private _removeStyle(styleName: string): void {
    this._renderer.removeStyle(this._elementRef.nativeElement, styleName);
  }
}
