import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {DocumentRole} from 'app/documents/document-data';
import {DocumentFragment} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {UserService} from 'app/services/user/user.service';
import {User} from 'app/user/user';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {DocumentOverviewService} from '../../../document-overview.service';
import {DOCUMENT_ROLE_AUTOCOMPLETE_PROPERTIES} from '../../role-assignments.component';

@Component({
  selector: 'cars-assigned-user',
  templateUrl: './assigned-user.component.html',
  styleUrls: ['./assigned-user.component.scss'],
})
export class AssignedUserComponent {
  @Input() public document: DocumentFragment;
  @Input() public userId: UUID;
  @Input() public set role(role: DocumentRole) {
    this.carsAction = DOCUMENT_ROLE_AUTOCOMPLETE_PROPERTIES[role].canAssignUser;
    this._role = role;
  }
  @Input() public carsAction: CarsAction;
  @Input() public set userHasDisabledStatus(userIsDisabled: boolean) {
    this.checked = userIsDisabled === false;
    this.tooltipMessage = this.checked ? 'Disable editing this document' : 'Enable editing this document';
  }
  @Input() public showRemove: boolean = true;
  @Input() public showToggle: boolean = true;

  public readonly CarsAction: typeof CarsAction = CarsAction;
  public readonly tooltipDelay: number = environment.tooltipDelay;
  public tooltipMessage: string = '';

  public checked: boolean = false;

  public userFound: boolean;

  public currentUserId: UUID;

  private _role: DocumentRole;

  constructor(
    private _documentOverviewService: DocumentOverviewService,
    private _userService: UserService,
    private _dialog: MatDialog
  ) {
    this.currentUserId = this._userService.getUser().id;
  }

  public unassignUser(): void {
    this._isAssignedUserCurrentUser() ? this._displayWarningDialog() : this._remove();
  }

  public toggle(event: MatSlideToggleChange): void {
    this._documentOverviewService.setUserAuthoringState(this.document, this.userId, !event.checked);
  }

  public handleUserEvent(user: User): void {
    this.userFound = user && !!user.id;
  }

  private _remove(): void {
    this._documentOverviewService.removeUserFromDocument(this.document, this.userId, this._role);
  }

  private _isAssignedUserCurrentUser(): boolean {
    return this.userId.equals(this.currentUserId);
  }

  private _displayWarningDialog(): void {
    this._dialog
      .open(DialogComponent, {
        ariaLabel: 'Unassign yourself from document',
        disableClose: false,
        data: {
          title: `Unassign yourself from ${this.document.title}?`,
          message:
            'Are you sure you wish to unassign yourself from this document?<br/>' +
            '<strong>You will lose access to the document, and this cannot be undone.</strong>',
          closeActions: [
            {title: 'Cancel', tooltip: `Cancel unassigning yourself from ${this.document.title}`, response: null},
            {title: 'Unassign myself', tooltip: `Unassign yourself from ${this.document.title}`, response: 'confirm'},
          ],
        },
      })
      .afterClosed()
      .subscribe((action) => {
        if (action === 'confirm') {
          this._remove();
        }
      });
  }
}
