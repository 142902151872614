import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClauseLinkRequired} from 'app/fragment/fragment-link/clause-link-required';
import {ClauseLinkType} from 'app/fragment/fragment-link/clause-link-type';
import {FragmentLink} from 'app/fragment/fragment-link/fragment-link';
import {Suite} from 'app/fragment/suite';
import {ClauseFragment, ClauseType, DocumentFragment, Fragment, FragmentType} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {BaseService} from './base.service';
import {DocumentService} from './document.service';
import {FragmentService} from './fragment.service';
import {WebSocketService} from './websocket/websocket.service';

interface FragmentLinkEvent {
  fragmentLink: any;
  operation: LinkEventOperation;
}

enum LinkEventOperation {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

@Injectable({
  providedIn: 'root',
})
export class ClauseLinkService extends BaseService implements OnDestroy {
  private static readonly CLAUSE_LINK_WEBSOCKET_TOPIC = '/topic/clauseLinks';

  private readonly _baseUrl: string = `${environment.apiHost}/clause-link`;

  private _websocketSubscriptions: Subscription[] = [];

  private _sectionClauseLinkMap: Map<string, Promise<FragmentLink[]>> = new Map();

  private _clauseLinkRequestsInProgress: string[] = [];

  /**
   * A map from clauseId to a subject of the list of ClauseLinks from that clause, only used when getting links for the
   * live document. Updates with websocket events.
   */
  private _clauseIdToClauseLinksReplaySubjects: Map<string, ReplaySubject<FragmentLink[]>> = new Map();

  private _fragmentServiceUpdateDeleteSubject: BehaviorSubject<Fragment> = new BehaviorSubject(null);

  constructor(
    private _websocketService: WebSocketService,
    private _documentService: DocumentService,
    private _fragmentService: FragmentService,
    protected _snackbar: MatSnackBar,
    private _http: HttpClient
  ) {
    super(_snackbar);

    this._subscriptions.push(
      this._websocketService.onConnection((connected: boolean) => {
        this._unsubscribeWebsocketSubscriptions();

        if (connected) {
          this._websocketSubscriptions.push(
            this._websocketService.subscribe(
              ClauseLinkService.CLAUSE_LINK_WEBSOCKET_TOPIC,
              (json: FragmentLinkEvent) => {
                const clauseLink: FragmentLink = FragmentLink.deserialise(json.fragmentLink);
                const operation: LinkEventOperation = LinkEventOperation[json.operation as string];
                this._handleWebsocketEvent(clauseLink, operation);
              }
            )
          );
        }
      }),
      // Websockets are only broadcast for the current document, this ensures we do not reuse old subjects when
      // changing documents.
      this._documentService.onSelection(() => this._clauseIdToClauseLinksReplaySubjects.clear()),
      this._documentService.onSelection(() => this._sectionClauseLinkMap.clear()),
      this._documentService.onSelection(() => (this._clauseLinkRequestsInProgress = [])),

      this._fragmentService.onUpdate(
        (frag: Fragment) => this._fragmentServiceUpdateDeleteSubject.next(frag),
        (frag: Fragment) => !!frag && frag.is(FragmentType.CLAUSE, FragmentType.CLAUSE_GROUP)
      ),
      this._fragmentService.onDelete(
        (frag: Fragment) => this._fragmentServiceUpdateDeleteSubject.next(frag),
        (frag: Fragment) => !!frag && frag.is(FragmentType.CLAUSE_GROUP)
      ),
      this._fragmentService.onCreate(
        // onCreate triggers when clause group deletions are undone
        (frag: Fragment) => this._fragmentServiceUpdateDeleteSubject.next(frag),
        (frag: Fragment) => !!frag && frag.is(FragmentType.CLAUSE_GROUP)
      )
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeWebsocketSubscriptions();
  }

  private _unsubscribeWebsocketSubscriptions(): void {
    this._websocketSubscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Handles a websocket event for a change to the given clauseLink, either creation or deletion based on the provided
   * value. If the clause links have not been fetched for the clauseId then does nothing.
   *
   * @param clauseLink The clause link to add/remove from the cache
   * @param creationEvent Whether the link was created if true, or deleted if false
   */
  private _handleWebsocketEvent(clauseLink: FragmentLink, operation: LinkEventOperation): void {
    const clauseLinksSubject: ReplaySubject<FragmentLink[]> = this._clauseIdToClauseLinksReplaySubjects.get(
      clauseLink.fragmentId.value
    );
    if (clauseLinksSubject) {
      clauseLinksSubject.pipe(take(1)).subscribe((clauseLinks: FragmentLink[]) => {
        switch (operation) {
          case LinkEventOperation.CREATE:
            clauseLinks.push(clauseLink);
            break;
          case LinkEventOperation.DELETE:
            const removedIndex: number = clauseLinks.findIndex((link) => link.id.equals(clauseLink.id));
            clauseLinks.splice(removedIndex, 1);
            break;
          default:
            throw new Error('Unexpect clause link websocket operation: ' + operation);
        }

        clauseLinksSubject.next(clauseLinks);
      });
    }
  }

  /**
   * Gets all clause links that originate from clauses in the given document id and are valid at the given time. If searching for
   * the live document will use the replay subject, otherwise makes a new request for versions. Note the returned list
   * is unordered.
   *
   * @param documentId The documentId to get links for.
   * @param validAt The time to fetch links at, or null if live.
   * @returns An observable of the valid links.
   */
  public getAllFromDocumentId(documentId: UUID): Promise<FragmentLink[]> {
    return this._http
      .get(`${this._baseUrl}/clauses/from-document-id/${documentId.value}`)
      .pipe(
        map((response: any[]) => response.map((json: any) => FragmentLink.deserialise(json))),
        catchError((error: any) => {
          this._handleError(error, 'Failed to get all fragment links for document', 'clause-link-error');
          return Promise.reject(error);
        })
      )
      .toPromise();
  }

  /**
   * Gets all clause links that originate from clauses in the given section id and are valid at the given time. If searching for
   * the live document will use the replay subject, otherwise makes a new request for versions. Note the returned list
   * is unordered.
   *
   * @param sectionId The sectionId to get links for.
   * @param validAt The time to fetch links at, or null if live.
   * @returns An observable of the valid links.
   */
  public getAllFromSectionId(sectionId: UUID, validAt: number): Promise<FragmentLink[]> {
    const params: {[param: string]: string} = !!validAt ? {validAt: validAt.toString()} : {};

    return this._http
      .get(`${this._baseUrl}/clauses/from-section-id/${sectionId.value}`, {params})
      .pipe(
        map((response: any[]) => response.map((json: any) => FragmentLink.deserialise(json))),
        catchError((error: any) => {
          this._handleError(error, 'Failed to get all fragment links for clause from section', 'clause-link-error');
          return Promise.reject(error);
        })
      )
      .toPromise();
  }

  /**
   * Gets all clause links that originate from the given clause id and are valid at the given time. If searching for
   * the live document will use the replay subject, otherwise makes a new request for versions. Note the returned list
   * is unordered.
   *
   * @param clause The clause to get links for.
   * @param validAt The time to fetch links at, or null if live.
   * @returns An observable of the valid links.
   */
  public getAllFromClause(clause: ClauseFragment, validAt: number): Observable<FragmentLink[]> {
    const sectionId: UUID = clause.sectionId;

    if (!sectionId) {
      return new Observable<FragmentLink[]>();
    }

    let clauseLinkSubject: ReplaySubject<FragmentLink[]>;

    if (!validAt) {
      clauseLinkSubject = this._clauseIdToClauseLinksReplaySubjects.get(clause.id.value);

      if (clauseLinkSubject) {
        return clauseLinkSubject.asObservable();
      }

      clauseLinkSubject = new ReplaySubject(1);
      this._clauseIdToClauseLinksReplaySubjects.set(clause.id.value, clauseLinkSubject);
    } else {
      clauseLinkSubject = new ReplaySubject(1);
    }

    const linksExist: boolean = this._sectionClauseLinkMap.has(sectionId.value);
    const requestInProgress: boolean = this._clauseLinkRequestsInProgress.includes(sectionId.value);

    if (!requestInProgress && !linksExist) {
      this._clauseLinkRequestsInProgress.push(sectionId.value);
      this._sectionClauseLinkMap.set(sectionId.value, this.getAllFromSectionId(sectionId, validAt));
      this._sectionClauseLinkMap
        .get(sectionId.value)
        .then(
          () =>
            (this._clauseLinkRequestsInProgress = this._clauseLinkRequestsInProgress.filter(
              (sectionIdInProgress: string) => sectionIdInProgress !== sectionId.value
            ))
        );
    }

    this._sectionClauseLinkMap.get(sectionId.value).then((fragmentLinks: FragmentLink[]) => {
      const clauseFragmentLinks: FragmentLink[] = fragmentLinks.filter(
        (link: FragmentLink) => link.fragmentId.value === clause.id.value
      );
      clauseLinkSubject.next(clauseFragmentLinks);
    });

    return clauseLinkSubject.asObservable();
  }

  /**
   * Calculates whether the given clause has invalid clause links or not
   * @param clause clause fragment to be checked
   * @param clauseLinks FragmentLinks associated with the given clause
   * @returns true if no invalid links
   */
  public calculateValidLinksForClause(clause: ClauseFragment, clauseLinks: FragmentLink[]): boolean {
    const document: DocumentFragment = clause?.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment;
    if (!document) {
      return false;
    } else if (document.suite !== Suite.MCHW) {
      return true;
    }

    if (clause.isClauseOfType(ClauseType.REQUIREMENT) && !clause.isUnmodifiableClause) {
      return (
        this._checkValidRequirementClauseLinksOfType(
          clauseLinks,
          ClauseLinkType.VERIFICATION,
          clause.verificationLinkRequired,
          clause.sectionId,
          null
        ) &&
        this._checkValidRequirementClauseLinksOfType(
          clauseLinks,
          ClauseLinkType.DOCUMENTATION,
          clause.documentationLinkRequired,
          clause.sectionId,
          null
        )
      );
    }

    // Check that headings do not have links. Note ClauseLinkRequired is not reset at this point and is simply
    // ignored for non REQUIREMENTS.
    return clauseLinks.length === 0;
  }

  /**
   * Returns a boolean stream of whether the current clause is in a valid link state. Updates on link websocket events
   * and on relevant clause or clause group fragment updates (relevant for target clause groups deletions and clauses
   * moved between sections). Returns true for any non-MCHW suite as the gutter icons do not hold logic for when to
   * show and simply use the result of this method.
   */
  public getHasValidLinksStream(clause: ClauseFragment, validAt: number): Observable<boolean> {
    return combineLatest([
      this.getAllFromClause(clause, validAt),
      this._fragmentServiceUpdateDeleteSubject.asObservable(),
    ]).pipe(
      map(([currentLinks]: [FragmentLink[], Fragment]) => {
        return this.calculateValidLinksForClause(clause, currentLinks);
      })
    );
  }

  /**
   * Check that clauseLinks exist for the correct type if set to YES and not if set to NO or NOT_FOR_CLAUSE_TYPE. If
   * they should exist then checks that the groups exist at the relevant time and are in the correct section.
   */
  private _checkValidRequirementClauseLinksOfType(
    clauseLinks: FragmentLink[],
    clauseLinkType: ClauseLinkType,
    clauseLinkRequired: ClauseLinkRequired,
    sectionId: UUID,
    validAt: number
  ): boolean {
    const clauseLinksOfType: FragmentLink[] = clauseLinks.filter((link) => link.clauseLinkType === clauseLinkType);

    switch (clauseLinkRequired) {
      case ClauseLinkRequired.NOT_SPECIFIED:
      case null: // Null value treated as a NOT_SPECIFIED for requirements
        return false;

      case ClauseLinkRequired.NO:
      case ClauseLinkRequired.NOT_FOR_CLAUSE_TYPE:
        return clauseLinksOfType.length === 0;

      case ClauseLinkRequired.YES:
        if (clauseLinksOfType.length > 0) {
          return clauseLinksOfType.every((link) => {
            const clauseGroup: ClauseGroupFragment = this._fragmentService.find(
              link.targetFragmentId,
              validAt
            ) as ClauseGroupFragment;

            return !!clauseGroup && clauseGroup.sectionId.equals(sectionId);
          });
        }
        return false;

      default:
        throw new Error('Unexpected ClauseLinkRequired of: ' + clauseLinkRequired);
    }
  }

  /**
   * Convenience method for creating multiple links. Creates a clause link from the given clauseId to each of the
   * targetClauseGroupIds with the provided clause link type.
   */
  public createAll(
    clauseId: UUID,
    targetClauseGroupIds: UUID[],
    clauseLinkType: ClauseLinkType
  ): Promise<FragmentLink[]> {
    return Promise.all(
      targetClauseGroupIds.map((targetClauseGroupId) => this.create(clauseId, targetClauseGroupId, clauseLinkType))
    );
  }

  /**
   * Creates a clause link from the given clauseId to the targetClauseGroupId with the provided clause link type.
   *
   * @param clauseId The source clauseId to create the link from.
   * @param targetClauseGroupId The target clauseId to create the link to.
   * @param clauseLinkType The type of link to create.
   * @returns A promise resolving to the created link.
   */
  public create(clauseId: UUID, targetClauseGroupId: UUID, clauseLinkType: ClauseLinkType): Promise<FragmentLink> {
    const params: {[param: string]: string} = {
      targetClauseGroupId: targetClauseGroupId.value,
      clauseLinkType: clauseLinkType,
    };
    return this._http
      .post(`${this._baseUrl}/clauses/${clauseId.value}`, {}, {params})
      .pipe(
        map((response: any) => FragmentLink.deserialise(response)),
        catchError((error: any) => {
          this._handleError(error, 'Failed to create clause link', 'clause-link-error');
          return Promise.reject(error);
        })
      )
      .toPromise();
  }

  /**
   * Convenience method for deleting multiple links. Marks each of the provided clause links as deleted, this sets the
   * validTo so the link is still visible on versions of the document.
   */
  public deleteAll(links: FragmentLink[]): Promise<void> {
    return Promise.all(links.map((link) => this.delete(link.linkId))).then(() => null);
  }

  /**
   * Marks a clause link as deleted, this sets the validTo so the link is still visible on versions of the document.
   *
   * @param linkId The linkId to mark as deleted.
   * @returns A promise resolving when the link has been deleted.
   */
  public delete(linkId: UUID): Promise<void> {
    return this._http
      .delete(`${this._baseUrl}/links/${linkId.value}`)
      .pipe(
        map(() => {}),
        catchError((error: any) => {
          this._handleError(error, 'Failed to delete clause link', 'clause-link-error');
          return Promise.reject(error);
        })
      )
      .toPromise();
  }
}
