import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {VERSION} from 'environments/version';

@Injectable({
  providedIn: 'root',
})
export class CarsVersionCheckerService {
  constructor(private http: HttpClient) {
    this.checkVersion();
  }

  private checkVersion(): void {
    this.http.get(`/expectedVersion`, {responseType: 'text'}).subscribe(
      (expectedSha) => {
        if (expectedSha && VERSION.sha !== expectedSha) {
          if (localStorage.getItem('forced-refresh') === 'true') {
            Logger.error(
              'webapp-version-error',
              'Outdated client (' + VERSION.sha + ') failed forced reload' + '(API supports ' + expectedSha + ')'
            );
          } else {
            localStorage.setItem('forced-refresh', 'true');
            window.location.reload();
          }
        } else {
          // Either correct version, or Deployment explicitly has no expected version
          localStorage.removeItem('forced-refresh');
        }
      },
      (error) => {
        Logger.error('webapp-version-error', 'Could not lookup expected version', error);
      }
    );
  }
}
