import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DashboardSidebarComponent} from './dashboard-sidebar.component';
import {VersionAggregatesListModule} from './version-aggregates-list/version-aggregates-list.module';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule,
    MatListModule,
    MatCardModule,
    MatTooltipModule,
    MatChipsModule,
    VersionAggregatesListModule,
    MatPaginatorModule,
    MatInputModule,
  ],
  declarations: [DashboardSidebarComponent],
  exports: [DashboardSidebarComponent],
})
export class DashboardSidebarModule {}
