<div class="table-container">
  <div class="cover" *ngIf="submitted"></div>
  <mat-progress-spinner *ngIf="submitted" mode="indeterminate" aria-label="Creating document"></mat-progress-spinner>
  <form (ngSubmit)="onSubmit()" #documentCreate="ngForm" class="create-form">
    <mat-form-field class="title-input">
      <input
        autocomplete="off"
        matInput
        #title
        #titleControl="ngModel"
        placeholder="New document title"
        [(ngModel)]="newTitle"
        name="title"
        carsRequiredNonWhitespace
        required
      />
      <mat-error *ngIf="titleControl.invalid"
        >Document title cannot
        {{ !!!newTitle || newTitle.length < 0 ? 'be empty' : 'only contain whitespace' }}</mat-error
      >
    </mat-form-field>

    <mat-form-field class="suite-select">
      <mat-select
        placeholder="Suite"
        [(ngModel)]="selectedSuite"
        name="suite"
        required
        (selectionChange)="onSuiteChange()"
      >
        <mat-option *ngFor="let suite of suites" [value]="suite">
          {{ suite | suiteDisplayName }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{ suiteErrorMessage }}
      </mat-error>
    </mat-form-field>

    <div [class.hide]="!selectedSuite">
      <div class="table-header">
        <mat-form-field floatLabel="never" class="search">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput #filter placeholder="Filter templates" />
          <mat-hint *ngIf="!templateDataSource.loading && !templateDataSource.filteredSize"
            >No templates match your search</mat-hint
          >
          <mat-hint *ngIf="templateDataSource.filteredSize">Search templates</mat-hint>
        </mat-form-field>
      </div>
      <mat-table #table [dataSource]="templateDataSource" matSort matSortDisableClear aria-label="Document templates">
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!--Suite Column -->
        <ng-container matColumnDef="suite">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            matTooltip="Order by suite"
            matTooltipPosition="below"
            [matTooltipShowDelay]="tooltipDelay"
          >
            Suite
          </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.suite | suiteDisplayName }} </mat-cell>
        </ng-container>

        <!--Title Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            matTooltip="Order by title"
            matTooltipPosition="below"
            [matTooltipShowDelay]="tooltipDelay"
          >
            Title
          </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
        </ng-container>

        <!-- Document Owner Column -->
        <ng-container matColumnDef="owner">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            matTooltip="Order by template creator"
            matTooltipPosition="below"
            [matTooltipShowDelay]="tooltipDelay"
            >Template owner</mat-header-cell
          >
          <mat-cell *matCellDef="let row">
            <cars-user [userId]="row.createdBy"></cars-user>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="rowClick(row)"
          (keyup.enter)="rowClick(row)"
          [class.active-row]="row?.versionId === selectedTemplate?.versionId"
          tabindex="0"
          [attr.aria-label]="'Use ' + row.name + ' as a template'"
          aria-role="button"
          carsAnalytics="select-template"
        ></mat-row>
      </mat-table>

      <mat-progress-bar
        *ngIf="templateDataSource.loading"
        mode="indeterminate"
        [color]="templateDataSource.color"
        aria-label="Loading templates"
      ></mat-progress-bar>
      <div class="no-template mat-form-field-wrapper mat-form-field-wrapper">
        <mat-checkbox matInput [(ngModel)]="noTemplate" name="noTemplate" (change)="selectedTemplate = null"
          >Don't use a template</mat-checkbox
        >
      </div>

      <mat-paginator
        [length]="templateDataSource.filteredSize"
        [pageIndex]="templateDataSource.pageOptions.pageIndex"
        [pageSize]="templateDataSource.pageOptions.pageSize"
        [pageSizeOptions]="templateDataSource.pageOptions.sizeOptions"
        (page)="templateDataSource.pageEvent($event)"
      >
      </mat-paginator>
    </div>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="
        !newTitle || !selectedSuite || !documentCreate.form.valid || (!noTemplate && !selectedTemplate) || submitted
      "
      carsAnalytics="create-document"
    >
      Create document
    </button>
    <button
      type="button"
      mat-raised-button
      (click)="canceled.emit()"
      [disabled]="submitted"
      aria-label="Cancel creating document"
      carsAnalytics="cancel-create-document"
    >
      Cancel
    </button>
  </form>
</div>
