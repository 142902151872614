import {AfterViewInit, Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'cars-placeholder-component',
  template: '',
  styles: [],
})
export class PlaceholderComponent implements AfterViewInit {
  @Output() onLoad: EventEmitter<void> = new EventEmitter();

  ngAfterViewInit() {
    this.onLoad.next();
  }
}
