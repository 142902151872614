<div
  *ngIf="showingChat"
  class="chat"
  [@slideInOut]
  [style.width.px]="width"
  [style.height.px]="height"
  [style.min-width.px]="minWidth"
  [style.max-width.px]="maxWidth"
  [style.min-height.px]="minHeight"
  [style.max-height.px]="maxHeight"
>
  <cars-alt-accessibility
    class="alt-close-chat"
    carsAutofocus
    [altProperties]="{initialKey: 'c', key: null, condition: true}"
    (onSelect)="handleAltEvent()"
  ></cars-alt-accessibility>
  <div class="heading-container">
    <div
      (mousedown)="onCornerClick($event)"
      class="chat-corner-resize"
      matTooltip="Resize chat window"
      matTooltipPosition="before"
      [matTooltipShowDelay]="tooltipDelay"
      role="img"
    >
      <mat-icon aria-label="Resize chat window">zoom_out_map</mat-icon>
    </div>
    <span class="title">Document chat</span>
    <button
      mat-icon-button
      (click)="hideChat()"
      aria-label="Minimise chat"
      matTooltip="Minimise chat"
      matTooltipPosition="before"
      [matTooltipShowDelay]="tooltipDelay"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <div class="messages" #list [scrollTop]="list.scrollHeight">
    <div class="info">
      This chat is viewable to the technical author, lead author, and all authors assigned to this document, as shown on
      the Document Information page.
    </div>

    <div *ngFor="let message of messages" class="message" [class.own-message]="message.ownMessage">
      <span class="author">
        <cars-user [userId]="message.author"></cars-user>
      </span>
      <p class="text">{{ message.text }}</p>
      <p class="date">{{ message.createdAt | date: dateFormat }}</p>
    </div>
  </div>
  <div class="create-message">
    <mat-form-field>
      <textarea
        id="message-input"
        #inputElement
        matInput
        [(ngModel)]="messageText"
        (keydown.enter)="onReturn()"
        carsAutofocus
        placeholder="Message"
        aria-label="Enter message"
      ></textarea>
      <mat-hint align="end">
        <mat-checkbox
          [(ngModel)]="sendOnReturn"
          (click)="saveSendOnReturn()"
          [attr.aria-label]="'Send message by pressing enter key (' + (sendOnReturn ? 'checked' : 'unchecked') + ')'"
          >Send message on return</mat-checkbox
        >
      </mat-hint>
      <button
        color="primary"
        mat-icon-button
        (click)="sendMessage()"
        [disabled]="messageText.length === 0"
        matTooltip="Send message"
        matTooltipPosition="before"
        [matTooltipShowDelay]="tooltipDelay"
        aria-label="Send message"
      >
        <mat-icon>send</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mat-spinner *ngIf="chatService.loading" aria-label="Sending message"></mat-spinner>
</div>
<div
  *ngIf="!showingChat && !showingPreview"
  class="show-chat-button"
  (click)="showChat()"
  tabindex="0"
  matTooltip="Open chat"
  matTooltipPosition="before"
  [matTooltipShowDelay]="tooltipDelay"
>
  <button mat-icon-button aria-label="Open chat" carsAnalytics="open-chat" tabindex="-1">
    <mat-icon>message</mat-icon>
  </button>
  <div *ngIf="unreadMessages > 0" class="unread-messages-counter">
    {{ unreadMessages }}
  </div>
</div>

<div
  *ngIf="!showingChat && showingPreview"
  class="preview-container"
  (click)="showChat()"
  matTooltip="Open chat"
  matTooltipPosition="before"
  [matTooltipShowDelay]="tooltipDelay"
>
  <div class="preview">
    <span class="author">
      <cars-user [userId]="previewMessage.author" [hoverTip]="false"></cars-user>
    </span>
    <button mat-icon-button (click)="dismissPreview()" aria-label="Close message preview">
      <mat-icon>close</mat-icon>
    </button>
    <p class="text">{{ previewMessage.text }}</p>
  </div>
</div>

<cars-alt-accessibility
  class="alt-open-chat"
  *ngIf="!showingChat"
  [altProperties]="{initialKey: 'c', key: null, condition: true}"
  (onSelect)="handleAltEvent()"
></cars-alt-accessibility>
