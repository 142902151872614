import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {DashboardSidebarModule} from './dashboard-sidebar/dashboard-sidebar.module';
import {DashboardTableModule} from './dashboard-table/dashboard-table.module';
import {MyDiscussionsComponent} from './my-dashboards/my-discussions.component';
import {MyDocumentsComponent} from './my-dashboards/my-documents.component';

@NgModule({
  imports: [CommonModule, MatIconModule, DashboardSidebarModule, DashboardTableModule],
  declarations: [MyDocumentsComponent, MyDiscussionsComponent],
  exports: [MyDocumentsComponent, MyDiscussionsComponent],
})
export class DashboardModule {}
