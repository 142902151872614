import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CarsDisabledPipe} from './cars-disabled.pipe';
import {CarsEnabledPipe} from './cars-enabled.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CarsEnabledPipe, CarsDisabledPipe],
  exports: [CarsEnabledPipe, CarsDisabledPipe],
})
export class CarsPermissionPipesModule {}
