<ng-container *ngIf="authenticated">
  <cars-header
    class="default-focus-style"
    (onAccessibilityToggle)="onAccessibilityToggle($event)"
    [accessibilityToggleChecked]="isToggleChecked"
  ></cars-header>

  <div class="main-body" [class.default-focus-style]="showDefaultFocusStyle">
    <router-outlet></router-outlet>
  </div>

  <div class="failed-connection-overlay" *ngIf="!connection">
    <div class="overlay-content">
      <mat-spinner aria-label="Waiting to connect"></mat-spinner>
    </div>
  </div>
  <cars-accessibility-manager></cars-accessibility-manager>
</ng-container>
