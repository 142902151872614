import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {Subject, Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {Lock} from '../fragment/lock/lock';
import {ClauseFragment, SectionFragment} from '../fragment/types';
import {Callback, Dictionary, Predicate} from '../utils/typedefs';
import {UUID} from '../utils/uuid';
import {BaseService} from './base.service';
import {FragmentService} from './fragment.service';
import {UserService} from './user/user.service';

/**
 * Clause lock operation ready to be sent to the server
 */
class ClauseLockOperation {
  /**
   * Subject used to resolve the clause send complete promise
   */
  public readonly subject: Subject<boolean> = new Subject<boolean>();

  /**
   * Promise used to inform the listener that the subject has completed
   */
  public readonly promise: Promise<boolean>;

  /**
   * Constructor
   * @param lock The type of operation to complete, where 'true' indicates a
   * lock operation and 'false indicates and unlock operation
   */
  constructor(public lock: boolean = false) {
    this.promise = this.subject.toPromise();
  }
}

/**
 * The ClauseLocks are used at a clause level to queue, manage and resolve
 * locking and unlocking operations to the server.
 */
class ClauseLocks {
  /**
   * Constructor
   * @param locked Indicates that the application expects the clause to be locked
   * @param serverLocked Indicates that the server expects the clause to be locked
   * @param pendingLocks Lock operations waiting to be sent
   * @param runningLocks Lock operations being sent to the server
   */
  constructor(
    public locked: boolean = false,
    public serverLocked: boolean = false,
    public pendingLocks: ClauseLockOperation[] = [],
    public runningLocks: ClauseLockOperation[] = []
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class LockService extends BaseService {
  private static readonly LOCK_TOPIC: string = `/topic/locks`;
  private static readonly UNLOCK_TOPIC: string = `/topic/unlocks`;
  private static readonly ENDPOINT: string = `${environment.apiHost}/clauses`;

  private _initialised: Subject<boolean> = new Subject();
  private _locks: {[id: string]: Lock} = {}; // A map from clause ID to its lock
  private _changeSubject: Subject<Lock> = new Subject(); // Fired on lock/unlock changes

  private _ownerId: UUID;

  /**
   * Lock operations waiting to be sent to the server
   */
  private clauseLocks: Dictionary<ClauseLocks> = {};

  constructor(
    protected _snackbar: MatSnackBar,
    private _websocketService: WebSocketService,
    private _http: HttpClient,
    private _fragmentService: FragmentService,
    userService: UserService
  ) {
    super(_snackbar);

    this._websocketService.onConnection((connected: boolean) => {
      this._subscriptions.splice(0).forEach((subscription: Subscription) => subscription.unsubscribe());

      if (connected) {
        this._initLocks();

        this._subscriptions.push(
          this._websocketService.subscribe(LockService.LOCK_TOPIC, (json: any) => this._dispatch(json, true)),
          this._websocketService.subscribe(LockService.UNLOCK_TOPIC, (json: any) => this._dispatch(json, false))
        );
      }

      this._ownerId = userService.getUser().id;
    });
  }

  /**
   * Return the lock for the given clause, or null if not locked.
   *
   * @param clause {ClauseFragment}   The clause to query
   * @returns      {Lock}             The corresponding lock
   */
  public getLock(clause: ClauseFragment): Lock {
    return clause ? this._locks[clause.id.value] || null : null;
  }

  /**
   * Request a lock for the given clause.
   *
   * @param clause    {ClauseFragment}    The clause to lock
   * @param broadcast {boolean}           True if should be broadcast
   * @returns         {Promise<number>}   A promise resolving to whether the lock was obtained
   */
  public lock(clause: ClauseFragment, broadcast: boolean = true): Promise<boolean> {
    return this._requestChange(clause, broadcast, true);
  }

  /**
   * Request a lock for all clauses within the given clause group. Returns false if any clauses cannot be locked by the user.
   * If any locks cannot be obtained all locks are unlocked. Returns true if a null fragment passed.
   *
   * @param clauseGroup   {ClauseGroupFragment}   The clause group to lock
   * @param broadcast     {boolean}               True if should be broadcast
   * @returns             {Promise<number>}       A promise resolving to whether the lock was obtained
   */
  public lockGroup(clauseGroup: ClauseGroupFragment, broadcast: boolean = true): Promise<boolean> {
    if (!clauseGroup) {
      return Promise.resolve(true);
    }
    if (this.canLockGroup(clauseGroup)) {
      return Promise.all(clauseGroup.getClauses().map((clause: ClauseFragment) => this.lock(clause, broadcast))).then(
        (res: boolean[]) => {
          if (res.every(Boolean)) {
            return Promise.resolve(true);
          } else {
            return this.unlockGroup(clauseGroup).then(() => Promise.resolve(false));
          }
        }
      );
    } else {
      return Promise.resolve(false);
    }
  }

  /**
   * Request that the given clause is unlocked.
   *
   * @param clause    {ClauseFragment}    The clause to unlock
   * @param broadcast {boolean}           True if should be broadcast
   * @returns         {Promise<number>}   A promise resolving to whether the unlock was obtained
   */
  public unlock(clause: ClauseFragment, broadcast: boolean = true): Promise<boolean> {
    return this._requestChange(clause, broadcast, false);
  }

  /**
   * Request that all clauses within the given clause group are unlocked. Returns true if a null fragment passed.
   *
   * @param clauseGroup   {ClauseGroupFragment}   The clause group to unlock
   * @param broadcast     {boolean}               True if should be broadcast
   * @returns             {Promise<number>}       A promise resolving to whether the unlock was obtained
   */
  public unlockGroup(clauseGroup: ClauseGroupFragment, broadcast: boolean = true): Promise<boolean> {
    if (!clauseGroup) {
      return Promise.resolve(true);
    }
    return Promise.all(clauseGroup.getClauses().map((clause: ClauseFragment) => this.unlock(clause, broadcast))).then(
      (res: boolean[]) => {
        return Promise.resolve(res.every(Boolean));
      }
    );
  }

  /**
   * Subscribe to clause lock and unlock events, with an optional predicate for filtering.
   *
   * @param callback  {Callback<Lock>}     The subscription callback
   * @param predicate {Predicate<Lock>?}   An optional filtering predicate
   * @returns         {Subscription}       The resulting subscription
   */
  public onChange(callback: Callback<Lock>, predicate?: Predicate<Lock>): Subscription {
    return this._makeSubscription(this._changeSubject, callback, predicate);
  }

  public onLockChange(clause: ClauseFragment, callback: Callback<boolean>): Subscription {
    callback(this.canLock(clause));
    return this.onChange(
      (lock: Lock) => {
        callback(this.canLock(clause));
      },
      (lock: Lock) => {
        return lock.clauseId.equals(clause.id);
      }
    );
  }

  /**
   * Returns true if the given clause can be locked by the current user.
   *
   * @param clause {ClauseFragment}   The clause to query
   * @returns      {boolean}          True if the lock is available
   */
  public canLock(clause: ClauseFragment): boolean {
    const lock: Lock = this.getLock(clause);
    return !lock || (lock.userId && lock.userId.equals(this._ownerId));
  }

  /**
   * Returns true if all clauses in the given clause groups can be locked by the current user.
   * Also returns true if a null fragment passed.
   *
   * @param clauseGroup {ClauseGroupFragment}   The clause group to query
   * @returns           {boolean}               True if a lock is available for each clause
   */
  public canLockGroup(clauseGroup: ClauseGroupFragment): boolean {
    return !clauseGroup || clauseGroup.getClauses().every(this.canLock.bind(this));
  }

  /**
   * Unlocks all the clauses locked by the current user in the sections in the given section group.
   */
  public unlockClausesInSectionGroup(sectionGroup: SectionGroupFragment): void {
    sectionGroup?.children.forEach((section: SectionFragment) => this.unlockClausesInSection(section));
  }

  /**
   * Unlocks all the clauses locked by the current user in the given section.
   */
  public unlockClausesInSection(section: SectionFragment): void {
    if (section) {
      section.getClauses().forEach((clause: ClauseFragment) => {
        const lock: Lock = this.getLock(clause);
        if (lock && lock.userId && lock.userId.equals(this._ownerId)) {
          this.unlock(clause);
        }
      });
    }
  }

  /**
   * Helper function to retrieve all locks from the webservice on initialisation.
   */
  private _initLocks(): void {
    this._http
      .get(`${LockService.ENDPOINT}/locks`, {headers: this._httpHeaders})
      .toPromise()
      .then((response: any) => {
        this._dispatch(response, true);
        this._initialised.next(true);
      })
      .catch((err) => {
        Logger.error('lock-error', 'Failed to load locks on initialisation', err);
        this._snackbar
          .open('Failed to retrieve the document collaboration state', 'Retry')
          .onAction()
          .subscribe(() => {
            this._initLocks();
          });
      });
  }

  /**
   * Helper function to dispatch websocket messages.
   *
   * @param json    {any}       The frame JSON
   * @param locking {boolean}   True if locking
   */
  private _dispatch(json: any, locking: boolean): void {
    const locks: Lock[] = json.map((j: any) => Lock.deserialise(j, locking));
    this._resolveChanges(...locks);
  }

  /**
   * Helper function to request a lock or unlock from the webservice.
   *
   * @param clause     {ClauseFragment}     The clause to act on
   * @param broadcast  {boolean}            True if should be broadcast
   * @param locking    {boolean}            True for a lock action
   * @returns          {Promise<boolean>}   A promise resolving to whether the action succeeded
   */
  private _requestChange(clause: ClauseFragment, broadcast: boolean, locking: boolean): Promise<boolean> {
    if (!clause) {
      return Promise.resolve(true);
    }
    const clauseId: UUID = clause.id;
    const lock: Lock = this._locks[clauseId.value];
    let promise: Promise<boolean>;

    if (!broadcast) {
      promise = Promise.resolve(true);
      this._resolveChanges(new Lock(clauseId, this._ownerId, locking));
    } else {
      if (!this.clauseLocks.hasOwnProperty(clauseId.value)) {
        if (lock) {
          this.clauseLocks[clauseId.value] = new ClauseLocks(lock.locking, lock.locking);
        } else {
          this.clauseLocks[clauseId.value] = new ClauseLocks(!locking, !locking);
        }
      }

      const clauseLocks: ClauseLocks = this.clauseLocks[clauseId.value];
      const clauseLockOperation: ClauseLockOperation = new ClauseLockOperation(locking);

      if (clauseLocks.pendingLocks.length === 0) {
        // No other operations running
        if (locking === clauseLocks.locked) {
          // No operations and already in the right state
          return Promise.resolve(true);
        }

        // Need to change state, add to and start the scheduler
        clauseLocks.pendingLocks.push(clauseLockOperation);
        this._scheduleLocks(clauseId);
      } else {
        // Push the locks to the running scheduler
        clauseLocks.pendingLocks.push(clauseLockOperation);
      }

      clauseLocks.locked = locking;
      promise = clauseLockOperation.promise;
    }

    return Promise.all([promise, this._initialised.toPromise()]).then((results: boolean[]) => results[0]);
  }

  /**
   * Helper function to schedule locks to the server. It will also look at the pending
   * locks to the server and will only send the most recent request to the server unless
   * the fragment is already in that state.
   *
   * @param clauseId The id of the clause to schedule
   */
  private _scheduleLocks(clauseId: UUID): void {
    const clauseLocks: ClauseLocks = this.clauseLocks[clauseId.value];
    if (clauseLocks.runningLocks.length > 0 || clauseLocks.pendingLocks.length === 0) {
      return; // Scheduler already running
    }

    const pendingLocks: ClauseLockOperation[] = clauseLocks.pendingLocks.splice(0);
    const locking = pendingLocks[pendingLocks.length - 1].lock;
    const action: string = locking ? 'lock' : 'unlock';

    const resolveOperations = (success: boolean) => {
      clauseLocks.runningLocks.splice(0).forEach((lockOperation) => {
        lockOperation.subject.next(success);
        lockOperation.subject.complete();
      });
    };

    const handleResponse = (response: any) => {
      const success: boolean = response.clauseId === clauseId.value && response.userId === this._ownerId.value;

      clauseLocks.serverLocked = locking;

      // Check if we need to resolve the locks
      if (clauseLocks.pendingLocks.length === 0) {
        this._resolveChanges(new Lock(clauseId, this._ownerId, locking));
      }

      // Resolve each of the promises
      resolveOperations(success);

      // If there is nothing else pending then delete, we do this after resolving incase
      // one of the subscribers adds a lock/unlock which it is processing the subscription
      if (clauseLocks.runningLocks.length === 0 && clauseLocks.pendingLocks.length === 0) {
        delete this.clauseLocks[clauseId.value];
      } else {
        this._scheduleLocks(clauseId);
      }
    };

    const handleError = (error: any) => {
      Logger.error('lock-error', 'Failed to send locks to server', error);
      clauseLocks.runningLocks.splice(0).forEach((lockOperation) => {
        lockOperation.subject.next(false);
      });
    };

    // Send the requests to the server
    clauseLocks.runningLocks.push(...pendingLocks);

    if (locking === clauseLocks.serverLocked) {
      this._resolveChanges(new Lock(clauseId, this._ownerId, locking));

      // Mark each of the requests complete
      resolveOperations(true);
    } else {
      this._http
        .post(`${LockService.ENDPOINT}/${clauseId.value}/${action}`, {}, {headers: this._httpHeaders})
        .toPromise()
        .then(handleResponse, handleError)
        .catch(handleError);
    }
  }

  /**
   * Helper function to apply locking changes received from the websocket.
   *
   * @param locks {Lock[]}      The received locks
   */
  private _resolveChanges(...locks: Lock[]): void {
    for (const lock of locks) {
      const id: string = lock.clauseId.value;
      if (lock.locking) {
        this._locks[id] = lock;
      } else {
        delete this._locks[id];
        lock.userId = null;
      }

      const clause: ClauseFragment = this._fragmentService.find(lock.clauseId) as ClauseFragment;
      if (clause && clause.parent) {
        this._changeSubject.next(lock);
      }
    }
  }
}
