<ng-container *ngIf="shouldDisplayCoreLink">
  <ng-container *ngIf="!isLinkActive">
    <mat-form-field>
      <input matInput disabled [value]="coreDocumentTitle" />
      <mat-hint> NAA currently unlinked from core document </mat-hint>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="isLinkActive">
    <cars-document-link
      name="parent_document_link"
      [document]="document"
      [title]="coreDocumentTitle"
      linkHint="Parent Document"
      [documentId]="coreDocumentId"
    ></cars-document-link>
  </ng-container>
</ng-container>
