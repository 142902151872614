import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {BlurOption} from 'app/blur-options';
import {PadType} from 'app/element-ref.service';
import {CarsRange} from 'app/fragment/cars-range';
import {ClauseFragment, ClauseType, Fragment, SectionFragment} from 'app/fragment/types';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {AltProperties} from 'app/services/alt-accessibility.service';
import {CaretService} from 'app/services/caret.service';
import {ClauseService} from 'app/services/clause.service';
import {SectionService} from 'app/services/section.service';
import {ToolbarItem} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'cars-clause-type-selector',
  templateUrl: './clause-type-selector.component.html',
  styleUrls: ['./clause-type-selector.component.scss', '../toolbar-buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseTypeSelectorComponent implements OnInit, OnDestroy {
  @Input() public toolbarItem: ToolbarItem;
  @Input() public altProperties: AltProperties;

  @ViewChild(MatSelect) mySelect: MatSelect;

  public enabled: boolean;
  public currentClause: ClauseFragment;
  public selectedClauseType: ClauseType;
  public readonly tooltipDelay = environment.tooltipDelay;
  public sectionId: UUID;
  public readonly BlurOption: typeof BlurOption = BlurOption;

  private _previousClause: ClauseFragment;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _sectionService: SectionService,
    private _clauseService: ClauseService,
    private _selectionsOperationsService: SelectionOperationsService,
    private _cd: ChangeDetectorRef,
    private _caretService: CaretService
  ) {}

  /**
   * Initialise this component by listening for section and clause changes.
   */
  public ngOnInit(): void {
    this._subscriptions.push(
      this._sectionService.onSelection((section: SectionFragment) => {
        this.sectionId = section ? section.id : null;
        this._cd.markForCheck();
      }),

      this._clauseService.onSelection((clause: ClauseFragment) => {
        this.enabled = this.toolbarItem.enabled(clause);
        this.currentClause = clause;
        this.selectedClauseType = clause ? clause.clauseType : null;
        this.altProperties.condition = this.enabled;
        this._cd.markForCheck();
      })
    );
  }

  /**
   * Destroy the component
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Block event propagation when clicking on the dropdown to prevent blurring.
   */
  @HostListener('mousedown', ['$event'])
  public mouseDown(_e): boolean {
    return this._updateClauseOnSelection();
  }

  @HostListener('keydown.enter', ['$event'])
  public keyDown(_e): boolean {
    return this._updateClauseOnSelection();
  }

  private _updateClauseOnSelection(): boolean {
    this._previousClause = this.currentClause;
    this._cd.markForCheck();
    return false;
  }

  /**
   * Respond to select change events by updating the current clause type.
   */
  public onChange(): void {
    const carsRange: CarsRange = this._caretService.getSelection();
    const newFragment: Fragment = carsRange.start.fragment;
    const offset: number = carsRange.start.offset;
    if (this._previousClause) {
      this._previousClause.clauseType = this.selectedClauseType;
      this._clauseService.update(this._previousClause);
    }

    this.mySelect.close();
    this._selectionsOperationsService.setSelected(newFragment, offset, PadType.MAIN_EDITABLE);
  }

  /**
   * Respond to ALT-select by opening the dropdown and bringing it into focus.
   */
  public handleAltSelect(): void {
    this._previousClause = this.currentClause;
    this.mySelect.open();
    this.mySelect.focus();
    this._cd.markForCheck();
  }
}
