import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {AdministrationToDisplayNameModule} from 'app/utils/pipes/administration-to-display-name.module';
import {DocumentOverviewHeaderComponent} from './document-overview-header.component';
import {InternalReferenceRefreshModule} from './internal-reference-refresh/internal-reference-refresh.module';
import {VersionInformationModule} from './version-information/version-information.module';
import {VersionPublicationOptionsModule} from './version-publication-options/version-publication-options.module';
import {VersionReviewStatusToggleModule} from './version-review-status-toggle/version-review-status-toggle.module';
import {VersionSettingsModule} from './version-settings/version-settings.module';

@NgModule({
  imports: [
    CommonModule,
    VersionInformationModule,
    VersionSettingsModule,
    InternalReferenceRefreshModule,
    MatIconModule,
    CarsPermissionPipesModule,
    AdministrationToDisplayNameModule,
    VersionPublicationOptionsModule,
    RouterModule,
    VersionReviewStatusToggleModule,
  ],
  declarations: [DocumentOverviewHeaderComponent],
  exports: [DocumentOverviewHeaderComponent],
})
export class DocumentOverviewHeaderModule {}
