<mat-form-field floatLabel="never">
  <mat-select
    [attr.blurOption]="BlurOption.PREVENT_BLUR"
    placeholder="Clause type"
    [matTooltip]="toolbarItem.tooltipText"
    matTooltipPosition="below"
    [matTooltipShowDelay]="tooltipDelay"
    [disabled]="!enabled"
    [(ngModel)]="selectedClauseType"
    (selectionChange)="onChange()"
  >
    <ng-container *ngIf="sectionId">
      <mat-option *ngFor="let type of sectionId | clauseAndSectionOptions: 'CLAUSE' | async" [value]="type.clauseType">
        {{ type.displayName }}
      </mat-option>
    </ng-container>
  </mat-select>

  <cars-alt-accessibility
    [altProperties]="altProperties"
    [richText]="true"
    (onSelect)="handleAltSelect()"
  ></cars-alt-accessibility>
</mat-form-field>
