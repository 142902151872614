import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RequiredNonWhitespaceDirective} from './required-non-whitespace.directive';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [RequiredNonWhitespaceDirective],
  exports: [RequiredNonWhitespaceDirective],
})
export class RequiredNonWhitespaceModule {}
