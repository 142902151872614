import {AdministrationUtils} from 'app/documents/administration-utils';
import {ReferenceIndexUtils} from 'app/services/references/reference-utils/reference-index-utils';
import {IndexUtils} from 'app/utils/index-utils';
import {
  ClauseFragment,
  ClauseType,
  DocumentFragment,
  EquationFragment,
  Fragment,
  FragmentType,
  InlineReferenceFragment,
  SectionFragment,
  SectionType,
} from '../types';
import {FragmentIndexer} from './fragment-index.service';

export const dmrbIndexer: FragmentIndexer = (document: DocumentFragment): Record<string, string> => {
  if (!document) {
    return {};
  }

  const applicationAnnexPrefix: string = !!document?.documentData?.administration
    ? AdministrationUtils.getInitials(document.documentData.administration) + '/'
    : '';

  const lookup: Record<string, string> = {};

  const documentReferenceIndexLookup: Record<string, string> = {};

  let normativeSectionCounter: number = 0;
  let requirementCounter: number = 0;
  let adviceCounter: number = 0;
  let noteCounter: number = 0;
  let tableCounter: number = 0;
  let figureCounter: number = 0;
  let equationCounter: number = 0;

  let appendxiSectionCounter: number = 0;
  let heading1Counter: number = 0;
  let heading2Counter: number = 0;
  let heading3Counter: number = 0;

  let currentSection: SectionFragment;
  let currentClause: ClauseFragment;

  const resetClauseCounters = (): void => {
    requirementCounter = adviceCounter = noteCounter = 0;
    tableCounter = figureCounter = equationCounter = 0;
    heading1Counter = heading2Counter = heading3Counter = 0;
  };

  const handleDocument = (_document: DocumentFragment) => {
    ReferenceIndexUtils.populateRecordWithReferenceIndexes(
      _document.getNormReferenceSection(),
      documentReferenceIndexLookup
    );
    ReferenceIndexUtils.populateRecordWithReferenceIndexes(
      _document.getInformReferenceSection(),
      documentReferenceIndexLookup
    );
  };

  const documentChildPredicate = (s: Fragment): boolean => {
    if (s.is(FragmentType.SECTION_GROUP)) {
      return false;
    }
    const section: SectionFragment = s as SectionFragment;
    return !(section.deleted || section.sectionType === SectionType.DOCUMENT_INFORMATION);
  };

  const getSectionLabel = (): string => {
    const sectionType: SectionType = currentSection.sectionType;

    if (
      sectionType === SectionType.NORMATIVE ||
      sectionType === SectionType.REFERENCE_NORM ||
      sectionType === SectionType.REFERENCE_INFORM
    ) {
      return `${applicationAnnexPrefix}${normativeSectionCounter}.`;
    } else if (sectionType === SectionType.APPENDIX) {
      const label: string = IndexUtils.generateAlphaIndex(appendxiSectionCounter, true);
      return `${applicationAnnexPrefix}${label}`;
    }
  };

  const getMultiCaptionedLabel = (counter: number): string => {
    return IndexUtils.generateAlphaIndex(counter, false);
  };

  const handleSectionFragment = (id: string, fragment: Fragment): void => {
    currentSection = fragment as SectionFragment;
    currentClause = null;

    switch (currentSection.sectionType) {
      case SectionType.NORMATIVE:
      case SectionType.REFERENCE_NORM:
      case SectionType.REFERENCE_INFORM: {
        normativeSectionCounter++;
        lookup[id] = getSectionLabel();

        resetClauseCounters();
        break;
      }
      case SectionType.APPENDIX: {
        appendxiSectionCounter++;
        lookup[id] = `Appendix ${getSectionLabel()}.`;

        resetClauseCounters();
        break;
      }
    }
  };

  const handleClauseFragment = (id: string, fragment: Fragment): void => {
    currentClause = fragment as ClauseFragment;
    const sectionType: SectionType = currentSection.sectionType;

    const handleNormativeSection = (): void => {
      switch (currentClause.clauseType) {
        case ClauseType.REQUIREMENT:
          {
            adviceCounter = noteCounter = tableCounter = figureCounter = equationCounter = 0;
            requirementCounter++;
            lookup[id] = `${getSectionLabel()}${requirementCounter}`;
          }
          break;

        case ClauseType.ADVICE:
          {
            noteCounter = tableCounter = figureCounter = equationCounter = 0;
            adviceCounter++;
            lookup[id] = `${getSectionLabel()}${requirementCounter}.${adviceCounter}`;
          }
          break;
        case ClauseType.NOTE:
          {
            tableCounter = figureCounter = equationCounter = 0;
            noteCounter++;
            const previousSibling: ClauseFragment = fragment.previousSibling() as ClauseFragment;
            const nextSibling: ClauseFragment = fragment.nextSibling() as ClauseFragment;

            lookup[id] =
              (previousSibling && previousSibling.clauseType === ClauseType.NOTE) ||
              (nextSibling && nextSibling.clauseType === ClauseType.NOTE)
                ? `NOTE ${noteCounter}`
                : 'NOTE';
          }
          break;
        default:
          noteCounter = tableCounter = figureCounter = equationCounter = 0;
      }
    };

    const handleAppendixSection = (): void => {
      switch (currentClause.clauseType) {
        case ClauseType.HEADING_1:
          {
            heading2Counter = heading3Counter = 0;
            heading1Counter++;
            lookup[id] = `${getSectionLabel()}${heading1Counter}`;
          }
          break;

        case ClauseType.HEADING_2:
          {
            heading3Counter = 0;
            heading2Counter++;
            lookup[id] = `${getSectionLabel()}${heading1Counter}.${heading2Counter}`;
          }
          break;

        case ClauseType.HEADING_3:
          {
            heading3Counter++;
            lookup[id] = `${getSectionLabel()}${heading1Counter}.${heading2Counter}.${heading3Counter}`;
          }
          break;
      }
    };

    if (sectionType === SectionType.NORMATIVE) {
      handleNormativeSection();
    } else if (sectionType === SectionType.APPENDIX) {
      handleAppendixSection();
    }
  };

  const handleCaptionedFragment = (id: string, fragment: Fragment, counter: number, prefix: string): void => {
    const clauseType: ClauseType = currentClause.clauseType;
    const sectionType: SectionType = currentSection.sectionType;

    let clauseSiblingCount: number;

    switch (fragment.type) {
      case FragmentType.TABLE:
        {
          clauseSiblingCount = currentClause.children.filter((f: Fragment) => {
            return f.is(FragmentType.TABLE);
          }).length;
        }
        break;
      case FragmentType.FIGURE:
        {
          clauseSiblingCount = currentClause.children.filter((f: Fragment) => {
            return f.is(FragmentType.FIGURE);
          }).length;
        }
        break;
      case FragmentType.EQUATION:
        {
          clauseSiblingCount = currentClause.children.filter((f: Fragment) => {
            return f.is(FragmentType.EQUATION) && !(f as EquationFragment).inline;
          }).length;
        }
        break;
    }

    if (sectionType === SectionType.NORMATIVE) {
      switch (clauseType) {
        case ClauseType.REQUIREMENT:
          {
            let label: string = `${prefix} ${getSectionLabel()}${requirementCounter}`;
            label += clauseSiblingCount === 1 ? '' : `${getMultiCaptionedLabel(counter)}`;
            lookup[id] = label;
          }
          break;
        case ClauseType.ADVICE:
          {
            let label: string = `${prefix} ${getSectionLabel()}${requirementCounter}.${adviceCounter}`;
            label += clauseSiblingCount === 1 ? '' : `${getMultiCaptionedLabel(counter)}`;
            lookup[id] = label;
          }
          break;
        case ClauseType.NOTE:
          {
            let requirementOrAdviceParent: boolean = null;
            let previousClause: ClauseFragment = currentClause.previousSibling() as ClauseFragment;

            while (requirementOrAdviceParent === null) {
              if (previousClause === null) {
                requirementOrAdviceParent = false;
              } else if (previousClause.clauseType === ClauseType.NOTE) {
                previousClause = previousClause.previousSibling() as ClauseFragment;
              } else if (
                previousClause.clauseType === ClauseType.REQUIREMENT ||
                previousClause.clauseType === ClauseType.ADVICE
              ) {
                requirementOrAdviceParent = true;
              } else {
                requirementOrAdviceParent = false;
              }
            }

            const nextClause: ClauseFragment = currentClause.nextSibling() as ClauseFragment;
            let label: string = requirementOrAdviceParent
              ? `${prefix} ${lookup[previousClause.id.value]}N`
              : `${prefix} 0.0.0N`;
            label += noteCounter > 1 || (nextClause && nextClause.clauseType === ClauseType.NOTE) ? noteCounter : '';

            label += clauseSiblingCount === 1 ? '' : `${getMultiCaptionedLabel(counter)}`;

            lookup[id] = label;
          }
          break;
      }
    } else if (sectionType === SectionType.APPENDIX) {
      lookup[id] = `${prefix} ${getSectionLabel()}.${counter}`;
    }
  };

  const handleReferenceFragment = (id: string, documentReferenceId: string) => {
    lookup[id] = documentReferenceIndexLookup[documentReferenceId];
  };

  const indexCallback = (fragment: Fragment): void => {
    const id: string = fragment.id.value;

    if (fragment.is(FragmentType.DOCUMENT)) {
      handleDocument(fragment as DocumentFragment);
      fragment.children.filter(documentChildPredicate).forEach(indexCallback);
    } else if (fragment.is(FragmentType.SECTION)) {
      handleSectionFragment(id, fragment);
      iterateChildren(fragment);
    } else if (fragment.is(FragmentType.CLAUSE)) {
      handleClauseFragment(id, fragment);
      iterateChildren(fragment);
    } else if (fragment.is(FragmentType.TABLE)) {
      if (!fragment.parent.is(FragmentType.EQUATION)) {
        tableCounter++;
        handleCaptionedFragment(id, fragment, tableCounter, 'Table');
      }
      iterateChildren(fragment);
    } else if (fragment.is(FragmentType.FIGURE) && !fragment.findAncestorWithType(FragmentType.TABLE_CELL)) {
      figureCounter++;
      handleCaptionedFragment(id, fragment, figureCounter, 'Figure');
    } else if (fragment.is(FragmentType.EQUATION) && !(fragment as EquationFragment).inline) {
      equationCounter++;
      handleCaptionedFragment(id, fragment, equationCounter, 'Equation');
      iterateChildren(fragment);
    } else if (fragment.is(FragmentType.INLINE_REFERENCE)) {
      handleReferenceFragment(id, (fragment as InlineReferenceFragment).documentReference.value);
    } else if (fragment.is(FragmentType.DOCUMENT_REFERENCE)) {
      handleReferenceFragment(id, id);
    } else {
      // iterate over all other fragment types to find reference fragments in lists, tables etc
      iterateChildren(fragment);
    }
  };

  const iterateChildren = (fragment: Fragment): void => {
    fragment.children.forEach(indexCallback);
  };

  indexCallback(document);

  return lookup;
};
