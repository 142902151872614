<div class="box">
  <div>Does this Constructor Requirement require {{ clauseLinkType | clauseLinkTypeDisplayName | lowercase }}?</div>

  <mat-form-field>
    <mat-select
      [(ngModel)]="linkRequired"
      (selectionChange)="onLinkRequiredChange()"
      disableRipple
      [disabled]="
        linkRequired === ClauseLinkRequired.NOT_FOR_CLAUSE_TYPE ||
        (CarsAction.AUTHOR_DOCUMENT | carsDisabled: clause?.documentId | async)
      "
    >
      <mat-option *ngFor="let option of clauseLinkRequiredOptions" [value]="option">
        {{ option | clauseLinkRequiredDisplayName }}
      </mat-option>
      <mat-option *ngIf="linkRequired === ClauseLinkRequired.NOT_FOR_CLAUSE_TYPE" [value]="linkRequired">
        {{ linkRequired | clauseLinkRequiredDisplayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="linkRequired === ClauseLinkRequired.YES">
    <div class="linked-title">Linked {{ clauseLinkType | clauseLinkTypeDisplayName | lowercase }} requirement(s)</div>

    <ng-container *ngIf="clauseLinks.length > 0 || brokenLinks.length > 0; else noLinkedClauses">
      <cars-clause-group-link-preview
        *ngFor="let clauseLink of clauseLinks"
        [clauseGroupId]="clauseLink.targetFragmentId"
      ></cars-clause-group-link-preview>

      <ng-container *ngIf="brokenLinks.length > 0">
        <div>
          One or more links exist to {{ clauseLinkType | clauseLinkTypeDisplayName | lowercase }} requirements that have
          been deleted or moved to another section, would you like to delete the broken links?
        </div>

        <button
          mat-raised-button
          color="warn"
          (click)="deleteBrokenLinks()"
          attr.aria-label="Delete broken links for clause {{ clause | fragmentIndex }}"
          *ngIf="CarsAction.AUTHOR_DOCUMENT | carsEnabled: clause?.documentId | async"
        >
          Delete broken links
        </button>
      </ng-container>
    </ng-container>
    <ng-template #noLinkedClauses>
      <div class="no-linked-requirements">No linked requirements</div>
    </ng-template>

    <ng-container *ngIf="CarsAction.AUTHOR_DOCUMENT | carsEnabled: clause?.documentId | async">
      <button
        mat-raised-button
        color="primary"
        (click)="selectRequirements()"
        attr.aria-label="Select requirements to link to clause {{ clause | fragmentIndex }}"
        enableDefaultFocus
      >
        Select requirement(s)
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="clearSelection()"
        attr.aria-label="Clear requirements linked to {{ clause | fragmentIndex }}"
        enableDefaultFocus
      >
        Clear selection
      </button>
    </ng-container>
  </div>
</div>
