import {Logger} from '../error-handling/services/logger/logger.service';
import {Serialisable} from '../utils/serialisable';
import {UUID} from '../utils/uuid';

export class ChangelogProperty implements Serialisable {
  public id: UUID;
  public publishedDocument: UUID;
  public efficiencySummary: string = '';

  public static deserialise(json: any): ChangelogProperty {
    const id: UUID = UUID.orNull(json.id);
    const publishedDocument: UUID = UUID.orNull(json.publishedDocument);
    const efficiencySummary: string = json.efficiencySummary;

    const newProperty: ChangelogProperty = new ChangelogProperty(publishedDocument);

    newProperty.id = id;
    newProperty.efficiencySummary = efficiencySummary;

    return newProperty;
  }

  public static empty(publishedDocument: UUID): ChangelogProperty {
    return new ChangelogProperty(publishedDocument);
  }

  constructor(publishedDocument: UUID) {
    this.publishedDocument = publishedDocument;
  }

  public serialise(): any {
    if (this.publishedDocument) {
      return {
        id: this.id ? this.id.value : null,
        publishedDocument: this.publishedDocument.value,
        efficiencySummary: this.efficiencySummary,
      };
    } else {
      Logger.error('changelog-error', 'Cannot serialise changelog property: published document id missing.');
      return {};
    }
  }
}
