import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {SpecifierInstructionListOptionsModule} from './specifier-instruction-list-options/specifier-instruction-list-options.module';
import {SpecifierInstructionModalComponent} from './specifier-instruction-modal.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, SpecifierInstructionListOptionsModule],
  declarations: [SpecifierInstructionModalComponent],
  exports: [SpecifierInstructionModalComponent],
})
export class SpecifierInstructionModalModule {}
