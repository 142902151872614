<span class="dropdown-label" *ngIf="!!searchLabelText()">{{ searchLabelText() }}</span>
<mat-form-field floatPlaceholder="never">
  <mat-icon class="drop-down-icon" matSuffix>arrow_drop_down</mat-icon>
  <input
    matInput
    placeholder="{{ searchPlaceholder }}"
    [matAutocomplete]="autoCompleteComponent"
    [(ngModel)]="filterTerm"
    (ngModelChange)="onNgModelChange($event)"
    (focus)="emptyFilter()"
    (blur)="resetFilter()"
    [disabled]="disabled"
  />
</mat-form-field>
<button
  mat-icon-button
  *ngIf="showClearButton"
  [disabled]="!filterTerm || disabled"
  (click)="clearSelectedDocument()"
  [matTooltipShowDelay]="tooltipDelay"
  matTooltipPosition="below"
  matTooltip="Clear selected document"
  attr.aria-label="Clear selected document"
>
  <mat-icon>clear</mat-icon>
</button>
<mat-autocomplete #autoCompleteComponent="matAutocomplete">
  <mat-option
    *ngFor="let document of documents"
    [value]="documentDisplayString(document)"
    (onSelectionChange)="onDropdownSelection($event, document)"
  >
    {{ documentDisplayString(document) }}
  </mat-option>
  <mat-option *ngIf="documents.length === 0" disabled="true">No documents found</mat-option>
</mat-autocomplete>
