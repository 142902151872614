import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {UserModule} from 'app/widgets/user/user.module';
import {AssignedUserComponent} from './assigned-user.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    MatSlideToggleModule,
    UserModule,
    CarsPermissionPipesModule,
  ],
  declarations: [AssignedUserComponent],
  exports: [AssignedUserComponent],
})
export class AssignedUserModule {}
