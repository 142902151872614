import {Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {BlurOption} from 'app/blur-options';
import {ResizeService} from 'app/services/resize.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {Subscription} from 'rxjs';
import {AltAccessibilityService} from '../../services/alt-accessibility.service';

@Component({
  selector: 'cars-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  private _subscriptions: Array<Subscription> = [];

  @ViewChild('overflow', {static: true}) public overflowElement: ElementRef;

  @ViewChild('toolbar', {static: true}) public toolbarElement: ElementRef;

  @ViewChild('more', {static: true}) public moreElement: MatButton;

  @Input() public preventClick: boolean = true;

  /**
   * Indicates that the show more toolbar is visible
   */
  public showMore: boolean = false;

  /**
   * ID of the animation request
   */
  private _animation: number = 0;

  /**
   * The width of the 'more' button, value is recalculate at runtime
   */
  private _moreButtonWidth: number = 40;

  /**
   * The position of the toolbar
   */
  public overflowToolbarRight: number = 0;

  /**
   * Max width of the overflow toolbar
   */
  public maxOverflowWidth: number = 200;

  /**
   * The blur option for this component
   */
  public readonly BlurOption: typeof BlurOption = BlurOption;

  /**
   * Lookup for elements and their last known width. This is useful as the
   * getBoundingClientRect does not always return dimensions if the element is
   * not currently visible on the UI (e.g. its parent has 'display: none')
   */
  private _elementWidths: Map<HTMLElement, number> = new Map<HTMLElement, number>();

  /**
   * Maintains a list of elements which have been split
   */
  private _splitChildren: Map<HTMLElement, HTMLElement[]> = new Map<HTMLElement, HTMLElement[]>();

  private _width: number = Number.MIN_VALUE;

  private _overflowing: boolean = false;

  /**
   * Used to determine wheather or not the elements are overflowing into the overflow toolbar
   */
  public get overflowing(): boolean {
    return this._overflowing;
  }

  /**
   * Returns the icon name to display in the 'more' button
   */
  public get expandDirection(): string {
    return this.showMore ? 'expand_less' : 'expand_more';
  }

  private static children(parent: HTMLElement): Array<HTMLElement> {
    return Array.from(parent.children) as Array<HTMLElement>;
  }

  constructor(
    private _resizeService: ResizeService,
    private _altAccessibilityService: AltAccessibilityService,
    private _zone: NgZone,
    private _sidebarService: SidebarService
  ) {}

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this._subscriptions.push(
      this._resizeService.subscribe(() => this.onWindowResized()),
      this._sidebarService.getSidebarStatus().subscribe((status: SidebarStatus) => this.onWindowResized()),
      this._altAccessibilityService.onAltEvent().subscribe((value) => {
        this.showMore = value;
      }),
      this._altAccessibilityService.onKeyEvent().subscribe((keyEvent) => {
        if (keyEvent.selection && keyEvent.key !== 'e') {
          this.showMore = false;
        }
      })
    );
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    if (this._animation) {
      cancelAnimationFrame(this._animation);
      this._animation = null;
    }

    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
    this._elementWidths.clear();
  }

  /**
   * @inheritdoc
   */
  @HostListener('mouseenter.no-cd')
  public onMouseEnter(): void {
    this.onWindowResized();
  }

  /**
   * Toggles the overflow toolbar
   */
  public onToggleShowOverflow(event: Event): void {
    this.showMore = !this.showMore; // BUG BUG: Need to stop loosing focus on the page
  }

  /**
   * Block event propagation when clicking on the RTE to prevent blurring.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  @HostListener('mousedown.no-cd', ['$event'])
  public mouseDown(event: MouseEvent): void {
    if (this.preventClick) {
      event.preventDefault();
    }
  }

  /**
   * Marks a child element as being splitable meaning that the elements can be managed by the toolbar
   * @param element The element to mark as splitable
   */
  public addSplitable(element: ElementRef): void {
    const nativeElement: HTMLElement = element.nativeElement;
    const children: HTMLElement[] = ToolbarComponent.children(nativeElement);
    const toolbar: HTMLElement = this.toolbarElement.nativeElement;
    let childElements: HTMLElement[] = null;

    // Maintain a list of elements which have been split out
    if (this._splitChildren.has(nativeElement)) {
      childElements = this._splitChildren.get(nativeElement);
    } else {
      childElements = [];
      this._splitChildren.set(nativeElement, childElements);
    }

    children.reduceRight((next: HTMLElement, child: HTMLElement) => {
      toolbar.insertBefore(child, next);
      childElements.unshift(child);
      return child;
    }, nativeElement);
  }

  /**
   * Event listener for window resizes
   */
  private onWindowResized(): void {
    if (!this._animation && this.hasChanged()) {
      this._zone.runOutsideAngular(() => {
        this._animation = requestAnimationFrame(() => {
          this._animation = 0;
          this.onUpdateToolbar();
        });
      });
    }
  }

  /**
   * Test if the toolbar has changed or not
   */
  private hasChanged(): boolean {
    if (!this.toolbarElement.nativeElement) {
      return false;
    }

    return this._width !== this.toolbarElement.nativeElement.clientWidth;
  }

  /**
   * Restores elements from the overflow into the toolbar.
   *
   * @return True if the overflow panel is emptied at the end of the operation
   */
  private restoreElementsFromOverflow(): boolean {
    let itemsMoved: boolean = false;
    const toolbarElement: HTMLElement = this.toolbarElement.nativeElement;
    const overflowElement: HTMLElement = this.overflowElement.nativeElement;

    const toolbarChildren: Array<HTMLElement> = ToolbarComponent.children(toolbarElement);
    const overflowChildren: Array<HTMLElement> = ToolbarComponent.children(overflowElement);
    const toolbarRect: DOMRect = toolbarElement.getBoundingClientRect();

    // Calculate the remaining space within the panel
    let remainingSpace: number = toolbarRect.width;
    if (toolbarChildren.length > 0) {
      remainingSpace = toolbarChildren.reduceRight(
        (remaining: number, element: HTMLElement) => remaining - Math.ceil(this._elementWidths.get(element)),
        remainingSpace
      );
    }

    // If we have only one item left see if this can fit
    if (overflowChildren.length === 1) {
      remainingSpace += this._moreButtonWidth;
    }

    // Restore elements back to the window from the overflow panel
    while (overflowChildren.length > 0 && remainingSpace > Math.ceil(this._elementWidths.get(overflowChildren[0]))) {
      const element: HTMLElement = overflowChildren.splice(0, 1)[0];

      remainingSpace -= Math.ceil(this._elementWidths.get(element));
      toolbarElement.appendChild(element);
      itemsMoved = true;

      // If we have only one item left see if this can fit
      if (overflowChildren.length === 1) {
        remainingSpace += this._moreButtonWidth;
      }
    }

    return itemsMoved && overflowChildren.length === 0;
  }

  private moveElementsToOverflow(): void {
    const toolbarElement: HTMLElement = this.toolbarElement.nativeElement;
    const overflowElement: HTMLElement = this.overflowElement.nativeElement;

    const toolbarRect: DOMRect = toolbarElement.getBoundingClientRect();
    const toolbarChildren: Array<HTMLElement> = ToolbarComponent.children(toolbarElement);
    const overflowChildren: Array<HTMLElement> = ToolbarComponent.children(overflowElement);

    // If the more button should disappear then recalcuate the width of the toolbar
    let toolbarRemaining = toolbarRect.width;

    const toolbarChildrenWidth = toolbarChildren.reduceRight(
      (remaining: number, element: HTMLElement) => remaining + Math.ceil(this._elementWidths.get(element)),
      0
    );

    if (toolbarChildrenWidth > toolbarRemaining) {
      toolbarRemaining -= this._moreButtonWidth;
    }

    // We now have the elements dimensions, so we'll start walking over the existing elements remove any that overflow
    toolbarChildren.forEach((element: HTMLElement) => {
      const elementWidth: number = Math.floor(this._elementWidths.get(element));
      if (elementWidth > toolbarRemaining) {
        element.remove();

        if (overflowChildren.length > 0) {
          overflowElement.insertBefore(element, overflowChildren[0]);
        } else {
          overflowElement.appendChild(element);
          this.showMore = false;
        }
      }
      toolbarRemaining -= elementWidth;
    });
  }

  /**
   * Manages moving elements between the toolbar and the overflow toolbar
   */
  private onUpdateToolbar(): void {
    const toolbarElement: HTMLElement = this.toolbarElement.nativeElement;
    const overflowElement: HTMLElement = this.overflowElement.nativeElement;
    const toolbarRect: DOMRect = toolbarElement.getBoundingClientRect();

    const toolbarChildren: Array<HTMLElement> = ToolbarComponent.children(toolbarElement);
    let valid: boolean = true;

    toolbarChildren.forEach((item: HTMLElement): void => {
      let rectWidth: number = item.getBoundingClientRect().width;

      if (rectWidth === null) {
        valid = false;
      } else {
        // Replaces elements with the minWidth property if set, else the current width
        rectWidth = this._getMinWidthOfElement(item, rectWidth);

        // Cache a copy of the elements dimensions
        this._elementWidths.set(item, rectWidth);
      }
    });

    if (!valid) {
      this.onWindowResized();
      return; // Require a redraw to recalculate dimensions
    }

    if (!this.restoreElementsFromOverflow()) {
      this.moveElementsToOverflow();
    }

    this.overflowToolbarRight = window.innerWidth - toolbarRect.right - this._moreButtonWidth + 4;
    this.maxOverflowWidth = toolbarRect.width - 8;
    this._width = this.toolbarElement.nativeElement.clientWidth;

    this._overflowing = Array.from(overflowElement.children).length > 0;
  }

  /**
   * Gets the minimum width of a toolbar item, this allows elements to be styled with flex and correctly appear or
   * disappear as the window width changes. This also means elements get removed from overflow as soon as it fits with
   * existing elements squashed to min-width.
   * NOTE any flex items should have the min-width property set.
   */
  private _getMinWidthOfElement(el: HTMLElement, rectWidth: number): number {
    const styles: CSSStyleDeclaration = window.getComputedStyle(el, null);

    const minWidthPx: number = this._parsePixelValueToNumber(styles.getPropertyValue('min-width'));
    if (minWidthPx > 0) {
      return minWidthPx;
    }

    const widthPx: number = this._parsePixelValueToNumber(styles.getPropertyValue('width'));
    if (widthPx > 0) {
      return widthPx;
    }

    return rectWidth;
  }

  private _parsePixelValueToNumber(stringValue: string): number {
    return stringValue.endsWith('px') ? Number(stringValue.replace('px', '')) : -1;
  }
}
