import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveEnd, Router, RouterEvent, RoutesRecognized} from '@angular/router';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {SectionFragment} from 'app/fragment/types';
import {SectionService} from 'app/services/section.service';
import {UUID} from 'app/utils/uuid';
import {Subject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {LocalConfigUtils} from '../utils/local-config-utils';
import {Callback} from '../utils/typedefs';

export enum NavigationTypes {
  CHANGELOG = 'CHANGELOG_NAVBAR',
  DOCUMENT = 'DOCUMENT_NAVBAR',
}

export interface SectionNavigationEvent {
  section: SectionFragment;
  type: NavigationTypes;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  /**
   * Map of navigation panel name to open/closed subject for that panel.
   */
  private _toolbars: Map<NavigationTypes, Subject<boolean>> = new Map();

  private _navigationStart: Subject<SectionNavigationEvent> = new Subject();

  private _navigationEnd: Subject<SectionNavigationEvent> = new Subject();

  constructor(private _router: Router, private _sectionService: SectionService) {
    this._router.events.subscribe((event: RouterEvent) => {
      if (event instanceof RoutesRecognized) {
        const section: SectionFragment = this.getSectionFromRouterEvent(event);
        if (section) {
          this._navigationStart.next({
            section,
            type: NavigationTypes.DOCUMENT,
          });
        }
      } else if (event instanceof ResolveEnd) {
        const section: SectionFragment = this.getSectionFromRouterEvent(event);
        if (section) {
          this._navigationEnd.next({section, type: NavigationTypes.DOCUMENT});
        }
      }
    });
  }

  private getSectionFromRouterEvent(event: RoutesRecognized | ResolveEnd): SectionFragment {
    let route: ActivatedRouteSnapshot = event.state.root;
    let sectionId: UUID = null;
    while (route && !sectionId) {
      sectionId = UUID.orNull(route.paramMap.get('section_id'));
      route = route.firstChild;
    }
    const section: SectionFragment = this._sectionService.find(sectionId);
    return section;
  }

  /**
   * Toggles the open/closed state of the toolbar with the given name.
   *
   * @param toolbar {NavigationTypes} The name of the navigation panel.
   */
  public toggleState(toolbar: NavigationTypes): void {
    this.setState(toolbar, !this.getState(toolbar));
  }

  /**
   * Sets the open/closed state of a navigation panel and updates listeners.
   *
   * @param toolbar {NavigationTypes}  The name of the navigation panel.
   * @param open    {boolean}          True if the toolbar is open, otherwise false
   */
  public setState(toolbar: NavigationTypes, open: boolean): void {
    switch (toolbar) {
      case NavigationTypes.DOCUMENT:
        LocalConfigUtils.setDocumentNavbarOpen(open);
        break;
      case NavigationTypes.CHANGELOG:
        LocalConfigUtils.setChangelogNavbarOpen(open);
        break;
      default:
        Logger.error('navigation-error', 'Unknown navigation type ' + toolbar);
    }

    if (this._toolbars.has(toolbar)) {
      const subject = this._toolbars.get(toolbar);
      subject.next(open);
    }
  }

  /**
   * Gets the state of a navigation panel.
   *
   * @param toolbar {NavigationTypes}  The name of the navigation panel.
   * @return        {boolean}          True if the toolbar is open, otherwise false
   */
  public getState(toolbar: NavigationTypes): boolean {
    switch (toolbar) {
      case NavigationTypes.DOCUMENT:
        return LocalConfigUtils.getDocumentNavbarOpen();
      case NavigationTypes.CHANGELOG:
        return LocalConfigUtils.getChangelogNavbarOpen();
      default:
        return false;
    }
  }

  /**
   * Subscribe to changes in the open/closed state of a toolbar.
   *
   * @param toolbar  {NavigationTypes}  The name of the navigation panel.
   * @param callback {Callback<boolean} The callback to run.
   */
  public onChange(toolbar: NavigationTypes, callback: Callback<boolean>): Subscription {
    let subject: Subject<boolean>;
    if (this._toolbars.has(toolbar)) {
      subject = this._toolbars.get(toolbar);
    } else {
      subject = new Subject<boolean>();
      this._toolbars.set(toolbar, subject);
    }

    return subject.subscribe(callback);
  }

  /**
   * Mark a published changelog section as having been navigated to.  If navigation has happened
   * via the router there is no need to do so, as it will be marked automatically.
   *
   * @param section {SectionFragment} The section which has been navigated to.
   */
  public navigateToPublishedChangelogSection(section: SectionFragment): void {
    this._navigationStart.next({section, type: NavigationTypes.CHANGELOG});
  }

  /**
   * Mark navigation to a published changelog section as complete.  This will happen automatically
   * if navigation was done via the router.
   *
   * @param section {SectionFragment} The section which has been navigated to.
   */
  public finishNavigationToPublishedChangelogSection(section: SectionFragment): void {
    this._navigationEnd.next({section, type: NavigationTypes.CHANGELOG});
  }

  /**
   * Subscribe to section navigation start events.
   *
   * @param callback  {Callback<SectionFragment>} The callback to run.
   * @param type      {NavigationTypes | 'ALL'}   If 'CHANGELOG_NAVBAR', only subscribe to section changes in the published
   * document in the changelog view.  Otherwise, if 'DOCUMENT_NAVBAR' only subscribe to section changes in the standard pad, including
   * the authored document in the changelog view.  If 'ALL', subscribe to all.
   */
  public onNavigationStart(type: NavigationTypes | 'ALL', callback: Callback<SectionNavigationEvent>): Subscription {
    return this._navigationStart
      .pipe(filter((event: SectionNavigationEvent) => type === 'ALL' || event.type === type))
      .subscribe(callback);
  }

  /**
   * Subscribe to section navigation end events.
   *
   * @param callback  {Callback<SectionFragment>} The callback to run.
   * @param type      {NavigationTypes | 'ALL'}   If 'CHANGELOG_NAVBAR', only subscribe to section changes in the published
   * document in the changelog view.  Otherwise, if 'DOCUMENT_NAVBAR' only subscribe to section changes in the standard pad, including
   * the authored document in the changelog view.  If 'ALL', subscribe to all.
   */
  public onNavigationEnd(type: NavigationTypes | 'ALL', callback: Callback<SectionNavigationEvent>): Subscription {
    return this._navigationEnd
      .pipe(filter((event: SectionNavigationEvent) => type === 'ALL' || event.type === type))
      .subscribe(callback);
  }
}
