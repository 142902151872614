<ng-container *ngIf="shouldDisplay">
  <mat-form-field>
    <input
      matInput
      disabled
      [value]="document.documentData.administration | administrationToDisplayName"
      placeholder="Administration"
    />
  </mat-form-field>
</ng-container>
