import {DocumentMetricsConfigItemType} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';

/**
 * An interface defining the list of metrics counts. Defining this separately to the BaseMetrics class ensures methods
 * can be added to the abstract base class without the functions appearing in `keyof Metrics`.
 */
interface Metrics {
  errors: number;
  warnings: number;
  noBackgroundsOnNormativeClause: number;
  noBackgroundsOnAppendixClause: number;
  noAlternativeText: number;
  noCaptions: number;
  unresolvedDiscussions: number;
  withdrawnRefWithoutYearOfIssue: number;
  invalidClauseLinks: number;
  internalSectionAndWsrReferencesToUpdate: number;
  internalSectionAndWsrReferencesTargetFragmentDeleted: number;
  internalClauseReferencesToUpdate: number;
  internalClauseReferencesTargetFragmentDeleted: number;
  wsrMapping: number;
}

/**
 * A map from metric config types to Metrics key names for mapping.
 */
const DOCUMENT_METRIC_CONFIG_ITEM_TYPE_TO_KEY_NAME: Record<DocumentMetricsConfigItemType, keyof Metrics> = {
  ERRORS: 'errors',
  WARNINGS: 'warnings',
  NO_BACKGROUNDS_ON_NORMATIVE_CLAUSE: 'noBackgroundsOnNormativeClause',
  NO_BACKGROUNDS_ON_APPENDIX_CLAUSE: 'noBackgroundsOnAppendixClause',
  NO_ALT_TEXT: 'noAlternativeText',
  NO_CAPTIONS: 'noCaptions',
  UNRESOLVED_DISCUSSIONS: 'unresolvedDiscussions',
  WITHDRAWN_REF_WITHOUT_YEAR_OF_ISSUE: 'withdrawnRefWithoutYearOfIssue',
  INVALID_CLAUSE_LINKS: 'invalidClauseLinks',
  INTERNAL_SECTION_AND_WSR_REFERENCES_TO_UPDATE: 'internalSectionAndWsrReferencesToUpdate',
  INTERNAL_SECTION_AND_WSR_REFERENCES_TARGET_FRAGMENT_DELETED: 'internalSectionAndWsrReferencesTargetFragmentDeleted',
  INTERNAL_CLAUSE_REFERENCES_TO_UPDATE: 'internalClauseReferencesToUpdate',
  INTERNAL_CLAUSE_REFERENCES_TARGET_FRAGMENT_DELETED: 'internalClauseReferencesTargetFragmentDeleted',
  WSR_MAPPING: 'wsrMapping',
};

/**
 * A base metrics class implementing the isHealthy() and getValueByConfigType().
 */
export abstract class BaseMetrics implements Metrics {
  constructor(
    public errors: number,
    public warnings: number,
    public noBackgroundsOnNormativeClause: number,
    public noBackgroundsOnAppendixClause: number,
    public noAlternativeText: number,
    public noCaptions: number,
    public unresolvedDiscussions: number,
    public withdrawnRefWithoutYearOfIssue: number,
    public invalidClauseLinks: number,
    public internalSectionAndWsrReferencesToUpdate: number,
    public internalSectionAndWsrReferencesTargetFragmentDeleted: number,
    public internalClauseReferencesToUpdate: number,
    public internalClauseReferencesTargetFragmentDeleted: number,
    public wsrMapping: number
  ) {}

  public isHealthy(): boolean {
    return Object.keys(DocumentMetricsConfigItemType).every(
      (metricType: DocumentMetricsConfigItemType) => this.getValueByConfigType(metricType) === 0
    );
  }

  public getValueByConfigType(type: DocumentMetricsConfigItemType): number {
    return this[DOCUMENT_METRIC_CONFIG_ITEM_TYPE_TO_KEY_NAME[type]];
  }

  public setValueByConfigType(type: DocumentMetricsConfigItemType, value: number): void {
    this[DOCUMENT_METRIC_CONFIG_ITEM_TYPE_TO_KEY_NAME[type]] = value;
  }
}

export class DocumentMetrics extends BaseMetrics {
  public static deserialise(json: any): DocumentMetrics {
    if (!json) {
      return null;
    }

    return new DocumentMetrics(
      UUID.orThrow(json.id),
      UUID.orThrow(json.documentId),
      json.createdAt,
      json.sectionMetrics.map((s) => SectionMetrics.deserialise(s)),
      json.errors,
      json.warnings,
      json.noBackgroundsOnNormativeClause,
      json.noBackgroundsOnAppendixClause,
      json.noAlternativeText,
      json.noCaptions,
      json.unresolvedDiscussions,
      json.withdrawnRefWithoutYearOfIssue,
      json.invalidClauseLinks,
      json.internalSectionAndWsrReferencesToUpdate,
      json.internalSectionAndWsrReferencesTargetFragmentDeleted,
      json.internalClauseReferencesToUpdate,
      json.internalClauseReferencesTargetFragmentDeleted,
      json.wsrMapping
    );
  }

  public static emptyMetrics(id: UUID, documentId: UUID, createdAt: number): DocumentMetrics {
    return new DocumentMetrics(id, documentId, createdAt, [], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
  }

  constructor(
    public id: UUID,
    public documentId: UUID,
    public createdAt: number,
    public sectionMetrics: SectionMetrics[],
    errors: number,
    warnings: number,
    noBackgroundsOnNormativeClause: number,
    noBackgroundsOnAppendixClause: number,
    noAlternativeText: number,
    noCaptions: number,
    unresolvedDiscussions: number,
    withdrawnRefWithoutYearOfIssue: number,
    invalidClauseLinks: number,
    internalSectionAndWsrReferencesToUpdate: number,
    internalSectionAndWsrReferencesTargetFragmentDeleted: number,
    internalClauseReferencesToUpdate: number,
    internalClauseReferencesTargetFragmentDeleted: number,
    wsrMapping: number
  ) {
    super(
      errors,
      warnings,
      noBackgroundsOnNormativeClause,
      noBackgroundsOnAppendixClause,
      noAlternativeText,
      noCaptions,
      unresolvedDiscussions,
      withdrawnRefWithoutYearOfIssue,
      invalidClauseLinks,
      internalSectionAndWsrReferencesToUpdate,
      internalSectionAndWsrReferencesTargetFragmentDeleted,
      internalClauseReferencesToUpdate,
      internalClauseReferencesTargetFragmentDeleted,
      wsrMapping
    );
  }

  public serialise(): any {
    const json: any = Object.assign({}, this);

    json.id = this.id ? this.id.value : null;
    json.documentId = this.documentId.value;
    json.sectionMetrics = this.sectionMetrics.map((s) => s.serialise());
    delete json.createdAt; // Set by backend

    return json;
  }
}

export class SectionMetrics extends BaseMetrics {
  public static deserialise(json: any): SectionMetrics {
    return new SectionMetrics(
      UUID.orThrow(json.sectionId),
      json.errors,
      json.warnings,
      json.noBackgroundsOnNormativeClause,
      json.noBackgroundsOnAppendixClause,
      json.noAlternativeText,
      json.noCaptions,
      json.unresolvedDiscussions,
      json.withdrawnRefWithoutYearOfIssue,
      json.invalidClauseLinks,
      json.internalSectionAndWsrReferencesToUpdate,
      json.internalSectionAndWsrReferencesTargetFragmentDeleted,
      json.internalClauseReferencesToUpdate,
      json.internalClauseReferencesTargetFragmentDeleted,
      json.wsrMapping
    );
  }

  public static emptyMetrics(sectionId: UUID): SectionMetrics {
    return new SectionMetrics(sectionId, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
  }

  constructor(
    public sectionId: UUID,
    errors: number,
    warnings: number,
    noBackgroundsOnNormativeClause: number,
    noBackgroundsOnAppendixClause: number,
    noAlternativeText: number,
    noCaptions: number,
    unresolvedDiscussions: number,
    withdrawnRefWithoutYearOfIssue: number,
    invalidClauseLinks: number,
    internalSectionAndWsrReferencesToUpdate: number,
    internalSectionAndWsrReferencesTargetFragmentDeleted: number,
    internalClauseReferencesToUpdate: number,
    internalClauseReferencesTargetFragmentDeleted: number,
    wsrMapping: number
  ) {
    super(
      errors,
      warnings,
      noBackgroundsOnNormativeClause,
      noBackgroundsOnAppendixClause,
      noAlternativeText,
      noCaptions,
      unresolvedDiscussions,
      withdrawnRefWithoutYearOfIssue,
      invalidClauseLinks,
      internalSectionAndWsrReferencesToUpdate,
      internalSectionAndWsrReferencesTargetFragmentDeleted,
      internalClauseReferencesToUpdate,
      internalClauseReferencesTargetFragmentDeleted,
      wsrMapping
    );
  }

  public serialise(): any {
    const json: any = Object.assign({}, this);
    json.sectionId = this.sectionId.value;

    return json;
  }
}
