import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FragmentModule} from 'app/fragment/fragment.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {AltImageComponent} from './alt-image.component';

@NgModule({
  declarations: [AltImageComponent],
  imports: [CommonModule, MatFormFieldModule, MatInputModule, FragmentModule, FormsModule, CarsPermissionPipesModule],
  exports: [AltImageComponent],
})
export class AltImageModule {}
