import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {TreeStructureValidator} from 'app/fragment/tree-structure-validator';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';
import {CarsRange} from '../../fragment/cars-range';
import {ClauseFragment, ClauseType, Fragment, FragmentType} from '../../fragment/types';
import {ExcerptService} from './excerpt.service';

@Component({
  selector: 'cars-excerpt',
  templateUrl: './excerpt.component.html',
  styleUrls: ['./excerpt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExcerptComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public excerptRange: CarsRange;

  @Input() public clauseToShow: ClauseFragment;

  public readonly PadType: typeof PadType = PadType;

  public currentView: CurrentView;

  public sectionChildren: Fragment[];

  private _subscriptions: Subscription[] = [];

  constructor(
    private _excerptService: ExcerptService,
    private _viewService: ViewService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        this.currentView = currentView;
        this._cdr.markForCheck();
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('excerptRange') || changes.hasOwnProperty('clauseToShow')) {
      this._updateSectionChildren();
      this._cdr.markForCheck();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  private _updateSectionChildren(): void {
    const fragments: Fragment[] = this._excerptService.getExcerpt(this.excerptRange);

    if (this._shouldCreateDummyClause(fragments)) {
      const clause: ClauseFragment = new ClauseFragment(null, ClauseType.NORMAL, fragments, '');
      clause.documentId = fragments[0]?.documentId;
      clause.sectionId = fragments[0]?.sectionId;
      this.sectionChildren = [clause];
    } else {
      this.sectionChildren = fragments.filter(
        (sectionChild: Fragment) => !this.clauseToShow || sectionChild.id.equals(this.clauseToShow.id)
      );
    }
  }

  private _shouldCreateDummyClause(children: Fragment[]): boolean {
    return children.every((child: Fragment) =>
      TreeStructureValidator.isValidRelationshipWithParentOfType(FragmentType.CLAUSE, child)
    );
  }
}
