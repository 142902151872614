import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {AutofocusModule} from 'app/utils/directives/autofocus.module';
import {UserModule} from 'app/widgets/user/user.module';
import {ChatComponent} from './chat.component';

@NgModule({
  imports: [
    CommonModule,
    AltAccessibilityModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    FormsModule,
    AutofocusModule,
    UserModule,
  ],
  declarations: [ChatComponent],
  exports: [ChatComponent],
})
export class ChatModule {}
