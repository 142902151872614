import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {Suite} from 'app/fragment/suite';
import {DocumentFragment} from 'app/fragment/types';
import {InternalReferenceService} from 'app/services/internal-reference.service';
import {environment} from 'environments/environment';

@Component({
  selector: 'cars-internal-reference-refresh',
  templateUrl: './internal-reference-refresh.component.html',
  styleUrls: ['./internal-reference-refresh.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalReferenceRefreshComponent {
  /**
   * The id of the document currently in view.
   */
  @Input() public document: DocumentFragment;

  public loading: boolean = false;

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public suites = Suite;

  constructor(
    private internalReferenceService: InternalReferenceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * Updates the internal document references for the currently selected document.
   */
  public refreshDocumentReferences(): void {
    this.loading = true;
    this.internalReferenceService.refreshDocumentReferences(this.document.id).then(() => {
      this.loading = false;
      this.changeDetectorRef.markForCheck();
    });
  }
}
