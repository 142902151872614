<div class="padded">
  <mat-form-field class="search-field">
    <mat-icon matPrefix>search</mat-icon>
    <input
      matInput
      #input
      placeholder="Superseding Reference"
      aria-label="Superseding Reference"
      [matAutocomplete]="auto"
      [formControl]="referenceDropdownCtrl"
      (click)="forcePanelOpen()"
  /></mat-form-field>
  <mat-autocomplete #autoComplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let reference of data"
      [value]="reference.globalReferenceId.value"
      [disabled]="supersedingReferenceLookup[reference.globalReferenceId.value]"
      [class.greyed]="reference.withdrawn"
      (onSelectionChange)="onSelect($event, reference)"
      ><mat-icon *ngIf="reference.withdrawn" matTooltip="This is a withdrawn reference.">error_outline</mat-icon>
      <span class="bold">{{ reference.reference }}</span>
      {{ reference.reference && reference.reference.length > 0 ? ':' : '' }}
      <small>{{ reference.title }}</small>
    </mat-option>
  </mat-autocomplete>

  <div [class.hidden]="dataSource.data.length === 0">Superseding references:</div>

  <mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="reference">
      <div>
        <mat-cell *matCellDef="let row">
          <mat-icon
            *ngIf="searchableGlobalReferenceLookup[row.newReference.value].withdrawn"
            matTooltip="This is a withdrawn reference."
            >error_outline</mat-icon
          >
          {{
            searchableGlobalReferenceLookup[row.newReference.value]
              ? searchableGlobalReferenceLookup[row.newReference.value].reference
              : 'unknown'
          }}
        </mat-cell>
      </div>
    </ng-container>

    <ng-container matColumnDef="title">
      <div>
        <mat-cell *matCellDef="let row">{{
          searchableGlobalReferenceLookup[row.newReference.value]
            ? searchableGlobalReferenceLookup[row.newReference.value].title
            : 'unknown'
        }}</mat-cell>
      </div>
    </ng-container>

    <ng-container matColumnDef="date">
      <div>
        <mat-cell *matCellDef="let row">
          <mat-form-field class="padded">
            <input
              matInput
              placeholder="Superseded date"
              [matDatepicker]="datePickers"
              [formControl]="dateControllers[row.newReference.value]"
              (focus)="datePickers.open()"
              (click)="datePickers.open()"
              readonly
              required
            />
            <mat-datepicker-toggle matSuffix [for]="datePickers"></mat-datepicker-toggle>
            <mat-datepicker #datePickers startView="multi-year"></mat-datepicker>
          </mat-form-field>
        </mat-cell>
      </div>
    </ng-container>

    <ng-container matColumnDef="delete"
      ><mat-cell *matCellDef="let row">
        <button
          mat-icon-button
          (click)="delete(row)"
          matTooltipPosition="before"
          aria-label="Delete superseding reference"
        >
          <mat-icon>delete</mat-icon>
        </button></mat-cell
      >
    </ng-container>

    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.greyed]="searchableGlobalReferenceLookup[row.newReference.value].withdrawn"
    ></mat-row>
  </mat-table>
</div>
