import {Component, OnDestroy} from '@angular/core';
import {Dashboard, DashboardService} from 'app/services/dashboard.service';

@Component({
  template: '',
})
export abstract class DashboardComponent implements OnDestroy {
  public abstract dashboardType: Dashboard;
  public abstract title: string;
  public abstract pageInfo: string;

  constructor(protected _dashboardService: DashboardService) {}

  public ngOnDestroy(): void {
    this._dashboardService.selectVersionAggregate(null);
  }
}
