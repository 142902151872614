import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {RequiredNonWhitespaceModule} from 'app/utils/directives/required-non-whitespace.module';
import {AssociatedDocumentInformationModalComponent} from './associated-document-information-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    RequiredNonWhitespaceModule,
    MatSlideToggleModule,
  ],
  declarations: [AssociatedDocumentInformationModalComponent],
  exports: [AssociatedDocumentInformationModalComponent],
})
export class AssociatedDocumentInformationModalModule {}
