import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UserHovertipModule} from './user-hovertip/user-hovertip.module';
import {UserComponent} from './user.component';

@NgModule({
  imports: [CommonModule, UserHovertipModule],
  declarations: [UserComponent],
  exports: [UserComponent],
})
export class UserModule {}
