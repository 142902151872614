import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ContentEditableDirective} from 'app/contenteditable.directive';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {ActionRequest} from 'app/fragment/action-request';
import {Caret} from 'app/fragment/caret';
import {Key} from 'app/fragment/key';
import {
  CaptionedFragment,
  ClauseFragment,
  ClauseType,
  DocumentFragment,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  Fragment,
  FragmentType,
  SectionFragment,
  WeightInferenceFailure,
} from 'app/fragment/types';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {ClauseGroupService} from 'app/services/clause-group.service';
import {FragmentDeletionValidationService} from 'app/services/fragment-deletion-validation.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {RichTextType} from 'app/services/rich-text.service';
import {ConfigurationService} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {PadType} from '../../element-ref.service';
import {SelectionOperationsService} from '../../selection-operations.service';
import {AltAccessibilityService} from '../../services/alt-accessibility.service';
import {ClauseService} from '../../services/clause.service';
import {FragmentService} from '../../services/fragment.service';
import {LockService} from '../../services/lock.service';
import {TabbableService} from '../../services/tabbable.service';
import {SpellCheckerService} from '../../spell-checker/spell-checker.service';
import {CurrentView} from '../../view/current-view';
import {FragmentComponent} from '../core/fragment.component';
import {getFinalEditableDescendant, getFirstEditableDescendant, isClauseGroupOfType} from '../fragment-utils';
import {ClauseGroupFragment} from '../types/clause-group-fragment';
import {ClauseGroupType} from '../types/clause-group-type';
import {InternalDocumentReferenceFragment} from '../types/reference/internal-document-reference-fragment';
import {StandardFormatType} from '../types/standard-format-type';

@Component({
  selector: 'cars-clause-list',
  templateUrl: './clause-list.component.html',
  styleUrls: ['./clause-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state('in', style({height: '0'})),
      transition('void => *', [style({height: '10px'}), animate(300)]),
      transition('* => void', [animate(300, style({height: '0'}))]),
    ]),
  ],
})
export class ClauseListComponent extends FragmentComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly DRAG_START_THRESHOLD: number = 200;
  private static readonly SCROLL_FREQUENCY: number = 15;
  private static readonly DEFAULT_SCROLL_SPEED: number = 1;
  private static readonly MAX_SCROLL_SPEED: number = 20;
  private static readonly COLLAPSE_THRESHOLD: number = 100;

  @Input() public set content(content: SectionFragment) {
    super.content = content;
    this._updateListForDisplay();
  }
  @Input() public readonly readOnly: boolean;
  @Input() public currentView: CurrentView;
  @Input() public padType: PadType = PadType.MAIN_EDITABLE;

  @ViewChild('ceDirective') public ceDirective: ContentEditableDirective;

  public readonly ClauseType: any = ClauseType;

  public readonly CarsAction: typeof CarsAction = CarsAction;

  public get content(): SectionFragment {
    return super.content as SectionFragment;
  }

  private _subscriptions: Subscription[] = [];

  private _spellingSubscription: Subscription;

  private _defaultClauseType: ClauseType = null;

  public reordering: boolean = false;
  public dragging: boolean = false;
  public collapseSelection: boolean = false;
  public _hovering: Fragment = null;
  private _draggedOutOfPad: boolean = false;

  public listForDisplay: Fragment[] = [];

  private _dragTimeout: any = null;
  private _scrollTimeout: any = null;

  private _canEditPad: boolean = false;
  private _isCommentable: boolean = false;

  constructor(
    protected _cd: ChangeDetectorRef,
    private _fragmentService: FragmentService,
    private _clauseService: ClauseService,
    private _spellCheckerService: SpellCheckerService,
    private _lockService: LockService,
    private _altAccessibilityService: AltAccessibilityService,
    private _selectionOperationsService: SelectionOperationsService,
    private _configurationService: ConfigurationService,
    private _reorderClausesService: ReorderClausesService,
    private _clauseGroupService: ClauseGroupService,
    private _permissionsService: PermissionsService,
    private _fragmentDeletionValidationService: FragmentDeletionValidationService,
    private _snackbar: MatSnackBar,
    private _tabbableService: TabbableService,
    elementRef: ElementRef
  ) {
    super(_cd, elementRef);
  }

  /**
   * Initialise this component by setting up subscriptions.
   */
  public ngOnInit(): void {
    super.ngOnInit(); // IMPORTANT!

    this._configurationService
      .getDefaultClauseTypeForSectionId(this.content.id)
      .then((clauseType: ClauseType) => (this._defaultClauseType = clauseType));

    this._subscriptions.push(
      this._reorderClausesService.onReorderingEvent().subscribe((status: boolean) => {
        if (!this.readOnly) {
          this.reordering = status;
        }
      }),
      this._reorderClausesService.onDraggingEvent().subscribe((status: boolean) => {
        if (!this.readOnly) {
          this.dragging = status;
        }
      }),
      this._reorderClausesService.onDraggedOutsidePadEvent().subscribe((status: boolean) => {
        if (!this.readOnly) {
          this._draggedOutOfPad = status;
          this._updateListForDisplay();
        }
      }),
      this._permissionsService
        .can(CarsAction.EDIT_PAD, this.content?.documentId)
        .subscribe((canEditPad: boolean) => (this._canEditPad = canEditPad)),
      this._permissionsService
        .can(CarsAction.IS_COMMENTABLE, this.content?.documentId)
        .subscribe((isCommentable: boolean) => (this._isCommentable = isCommentable))
    );
  }

  /**
   * Respond to changes in selected section by binding this component.
   *
   * @param changes {SimpleChanges}   The binding changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('content')) {
      this._scrollToTopIfChangelog(changes.content);

      (changes.content.previousValue || {}).component = null;
      (changes.content.currentValue || {}).component = this;
      if (this._spellingSubscription) {
        this._spellingSubscription.unsubscribe();
      }
      this._spellingSubscription = this._spellCheckerService.onReady((ready: boolean) => {
        if (ready) {
          this._spellCheckerService.validateSection(this.content);
        }
      });
    }
  }

  /**
   * Clean up after this component.
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());

    if (this._spellingSubscription) {
      this._spellingSubscription.unsubscribe();
    }

    super.ngOnDestroy(); // IMPORTANT!
  }

  /**
   * Due to how navigation in the changelog works, we can't just prevent the reuse of routes when navigating
   * from a section to another section in the changelog, else it refreshes the other pad.
   */
  private _scrollToTopIfChangelog(content: SimpleChange): void {
    if (this.currentView && this.currentView.isAnyChangelog()) {
      const previousId: UUID = content.previousValue ? content.previousValue.id : null;
      const currentId: UUID = content.currentValue ? content.currentValue.id : null;
      if (currentId && !currentId.equals(previousId)) {
        const pad: HTMLElement = this._getParentElementWithClass('pad-container');
        pad.scrollTop = 0;
      }
    }
  }

  public onRichText(type: RichTextType, start: Caret, end: Caret, ...args: any[]): ActionRequest {
    let action: ActionRequest = new ActionRequest();

    let selectedClause: ClauseFragment;

    switch (type) {
      case RichTextType.NATIONALLY_DETERMINED_REQUIREMENT:
        const nationallyDeterminedRequirement: ClauseGroupFragment =
          this._clauseGroupService.getNationallyDeterminedRequirementToCreate();
        selectedClause = this._clauseService.getSelected();
        this._insertClauseGroup(action, nationallyDeterminedRequirement, selectedClause);
        break;
      case RichTextType.STANDARD_FORMAT_GROUP:
        const standardFormatType: StandardFormatType = args[0];
        const standardFormatGroup: ClauseGroupFragment =
          this._clauseGroupService.getStandardFormatGroupToCreate(standardFormatType);
        selectedClause = args[1];
        this._insertClauseGroup(action, standardFormatGroup, selectedClause);
        break;
      case RichTextType.SPECIFIER_INSTRUCTION:
        const specifierInstruction: ClauseFragment = args[0];
        selectedClause = args[1];
        specifierInstruction.insertAfter(selectedClause);

        if (specifierInstruction.tryInferWeight().failure === WeightInferenceFailure.WEIGHTS_TOO_CLOSE) {
          specifierInstruction.remove();
          Logger.error('weight-error', 'weights too close');
          this._snackbar.open(
            'Failed to create specifier instruction, please contact support to resolve the problem',
            'Dismiss',
            {
              duration: 5000,
            }
          );
        } else {
          this._clauseService.create(specifierInstruction);
          action.fragment = getFirstEditableDescendant(specifierInstruction);
        }

        break;
      default:
        action = null;
    }

    return action;
  }

  private _insertClauseGroup(
    action: ActionRequest,
    clauseGroup: ClauseGroupFragment,
    selectedClause: ClauseFragment
  ): void {
    const ancestorClauseGroups: Fragment[] = selectedClause.findAllAncestorsWithType(FragmentType.CLAUSE_GROUP);
    const target: Fragment = ancestorClauseGroups.pop() || selectedClause;

    const normativeReferenceSection: SectionFragment = (
      this._content.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment
    )?.getNormReferenceSection();
    const internalDocumentReferences: InternalDocumentReferenceFragment[] =
      this._clauseGroupService.getInternalReferencesToCreate(clauseGroup, normativeReferenceSection);

    clauseGroup.insertAfter(target);

    if (clauseGroup.tryInferWeight().failure === WeightInferenceFailure.WEIGHTS_TOO_CLOSE) {
      clauseGroup.remove();
      internalDocumentReferences.forEach((frag) => frag.remove());
      Logger.error('weight-error', 'weights too close');
      this._snackbar.open('Failed to create a clause group, please contact support to resolve the problem', 'Dismiss', {
        duration: 5000,
      });
    } else {
      action.fragment = getFirstEditableDescendant(clauseGroup);

      if (
        target.is(FragmentType.CLAUSE) &&
        !target.length() &&
        !target.children.find((f) => f.is(FragmentType.LIST) || f.isCaptioned()) &&
        !this._fragmentDeletionValidationService.fragmentHasBackgroundCommentaryOrDiscussions([target])
      ) {
        // Only runs if the target clause would not remove any data, no need to validate.
        this._fragmentService.deleteValidatedFragments(target);
      }

      this._fragmentService.create([...internalDocumentReferences, clauseGroup]).then(() => {
        clauseGroup.getClauses().forEach((clause: ClauseFragment) => this._unlockClause(clause));
      });
    }
  }

  /**
   * @override
   */
  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest | Promise<ActionRequest> {
    const index: number = this.content.childIndexOf(target.content);

    if (key.equalsUnmodified(Key.ENTER)) {
      if (FragmentComponent.fromNode(target.element, FragmentType.LIST)) {
        return this._handleEnterFromList(index, target, caret);
      } else {
        return this._handleEnter(index, target, caret);
      }
    } else if (key.equalsUnmodified(Key.BACKSPACE)) {
      return this._handleBackspace(index, target);
    } else if (key.equalsUnmodified(Key.DELETE)) {
      return this._handleDelete(index, target);
    } else if (key.equalsUnmodified(Key.TAB)) {
      const elementToTabTo = this._tabbableService.getFocusableElement(document.body, this.element, key.shift !== true);
      if (elementToTabTo != null) {
        elementToTabTo.focus();
      }
      return null;
    } else {
      return null;
    }
  }

  /**
   * Handle an enter key event when the caret is contained within a list item.
   *
   * @param index  {number}              The index of the current list item
   * @param target {FragmentComponent}   The target fragment component
   * @returns      {ActionRequest}       The desired action
   */
  private _handleEnterFromList(index: number, target: FragmentComponent, caret: Caret): ActionRequest {
    const listItem: Fragment = target.content.findAncestorWithType(FragmentType.LIST_ITEM);
    const sectionChild: Fragment = this.content.children[index];

    if (sectionChild.is(FragmentType.CLAUSE) && !sectionChild.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) {
      const clause: ClauseFragment = sectionChild as ClauseFragment;

      const newClause: ClauseFragment = ClauseFragment.empty(this._defaultClauseType);
      newClause.insertAfter(clause);

      const toDelete: Fragment = listItem.parent.children.length <= 1 ? listItem.parent : listItem;
      // Either list or list item, no need to validate
      this._fragmentService.delete(toDelete);
      this._clauseService.create(newClause).then(() => {
        this._unlockClause(clause);
      });

      caret.offset = 0;
      return new ActionRequest(newClause);
    } else if (isClauseGroupOfType(sectionChild, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)) {
      const clauseGroup: ClauseGroupFragment = sectionChild as ClauseGroupFragment;
      const clause: ClauseFragment = target.content.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;

      const toDelete: Fragment = listItem.parent.children.length <= 1 ? listItem.parent : listItem;
      // Either list or list item, no need to validate
      this._fragmentService.delete(toDelete);

      if (clause.isLastChild()) {
        const newClause: ClauseFragment = ClauseFragment.empty(this._defaultClauseType);
        newClause.insertAfter(clauseGroup);

        this._clauseService.create(newClause).then(() => {
          this._unlockClause(clause);
        });

        caret.offset = 0;
        return new ActionRequest(newClause);
      }

      const nextClause: ClauseFragment = clause.nextSibling() as ClauseFragment;
      caret.offset = 0;
      return new ActionRequest(nextClause);
    }
  }

  /**
   * Handle an enter key event when the caret is contained within a clause.
   *
   * @param index  {number}              The index of the current list item
   * @param target {FragmentComponent}   The target fragment component
   * @param offset {number}              The caret offset
   * @returns      {ActionRequest}       The desired action
   */
  private _handleEnter(index: number, target: FragmentComponent, caret: Caret): ActionRequest {
    const targetClauseListEntry: Fragment = this.content.children[index];
    const newClause: ClauseFragment = this._getNewClauseAndInsertInPad(
      targetClauseListEntry,
      target.content,
      caret.offset
    );

    if (!newClause) {
      return new ActionRequest();
    }

    if (newClause.tryInferWeight().failure === WeightInferenceFailure.WEIGHTS_TOO_CLOSE) {
      newClause.remove();
      Logger.error('weight-error', 'weights too close');
      this._snackbar.open('Failed to create new paragraph, please contact support to resolve the problem', 'Dismiss', {
        duration: 5000,
      });
    } else {
      this._clauseService.create(newClause).then(() => {
        if (newClause.index() > index) {
          if (targetClauseListEntry.is(FragmentType.CLAUSE)) {
            this._unlockClause(targetClauseListEntry as ClauseFragment);
          } else {
            this._unlockClauseGroup(targetClauseListEntry as ClauseGroupFragment);
          }
        } else {
          this._unlockClause(newClause);
        }
      });

      if (
        target.content.parent.isCaptioned() &&
        (target.content.parent as CaptionedFragment).caption === target.content
      ) {
        // For captioned fragments their TextFragment is an attribute and not stored in the
        // FragmentService, so instead update the CaptionedFragment instead.
        this._fragmentService.update(target.content.parent);
      } else {
        this._fragmentService.update(target.content);
      }

      this._fragmentService.update(newClause.children);
    }

    caret.offset = 0;
    const selectedClause: Fragment = newClause.index() > index ? newClause : targetClauseListEntry;

    return new ActionRequest(getFirstEditableDescendant(selectedClause));
  }

  /**
   * Unlocks the given clause if it has not been reselected by the user. Avoids issues from running unlock after a
   * clause is created but not checking if the user has navigated back into the clause.
   */
  private _unlockClause(clause: ClauseFragment): void {
    if (!clause.equals(this._clauseService.getSelected())) {
      this._lockService.unlock(clause);
    }
  }

  /**
   * Unlocks the clauses in the given clause group if none of the clauses have been reselected by the user.
   * Avoids issues from running unlock after a clause is created but not checking if the user has navigated back into
   * the clause.
   */
  private _unlockClauseGroup(clauseGroup: ClauseGroupFragment): void {
    if (!clauseGroup.getClauses().find((clause: ClauseFragment) => clause.equals(this._clauseService.getSelected()))) {
      this._lockService.unlockGroup(clauseGroup);
    }
  }

  /**
   * Gets the new clause to be inserted into the clause list, inserts it in the correct position and then returns the new clause.
   */
  private _getNewClauseAndInsertInPad(
    targetClauseListEntry: Fragment,
    targetFragment: Fragment,
    offset: number
  ): ClauseFragment {
    let newClause: ClauseFragment;

    if (
      isClauseGroupOfType(targetClauseListEntry, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT) ||
      targetClauseListEntry.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)
    ) {
      if (this._isInInputAndAtOneEndOfClauseOrClauseGroup(targetFragment, offset, true)) {
        newClause = ClauseFragment.empty(this._defaultClauseType);
        newClause.insertBefore(targetClauseListEntry);
      } else if (this._isInInputAndAtOneEndOfClauseOrClauseGroup(targetFragment, offset, false)) {
        newClause = ClauseFragment.empty(this._defaultClauseType);
        newClause.insertAfter(targetClauseListEntry);
      } else {
        this._snackbar.open('This action is not allowed in this context', 'Dismiss', {
          duration: 5000,
        });
      }
    } else if (isClauseGroupOfType(targetClauseListEntry, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)) {
      if (this._isAtClauseGroupEdge(targetFragment, offset, true)) {
        newClause = ClauseFragment.empty(this._defaultClauseType);
        newClause.insertBefore(targetClauseListEntry);
      } else if (this._isAtClauseGroupEdge(targetFragment, offset, false)) {
        newClause = ClauseFragment.empty(this._defaultClauseType);
        newClause.insertAfter(targetClauseListEntry);
      } else {
        this._snackbar.open('This action is not allowed in this context', 'Dismiss', {
          duration: 5000,
        });
      }
    } else if (targetClauseListEntry.is(FragmentType.CLAUSE)) {
      const clause: ClauseFragment = targetClauseListEntry as ClauseFragment;
      const realOffset: number = clause.correctOffset(targetFragment, offset);

      if (realOffset === 0 && clause.length() > 0) {
        newClause = ClauseFragment.empty(this._defaultClauseType);
        newClause.insertBefore(clause);
      } else {
        newClause = clause.split(realOffset);
        newClause.insertAfter(clause);
      }
    }

    return newClause;
  }

  /**
   * Checks if the caret is at the start of the first or end of the last editable fragment in a clause, and that it is
   * the first or last clause in the clause group, respectively. Will also check if it is in an input at the end of a
   * clause group to catch specifier instructions in NDRs.
   *
   * @param checkAtStart  {boolean}   Whether to check if the caret is at the start of the group if true, or the end if false
   */
  private _isAtClauseGroupEdge(target: Fragment, offset: number, checkAtStart: boolean): boolean {
    const clause: ClauseFragment = target.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const isCorrectClause: boolean = this._isClauseAtEdgeOfClauseGroup(clause, checkAtStart);

    if (isCorrectClause) {
      if (this._isAtEdgeOfInputAtEdgeOfClause(target, offset, checkAtStart)) {
        return true;
      }

      const correctClauseOffset: number = clause.correctOffset(target, offset);

      return checkAtStart ? correctClauseOffset === 0 : correctClauseOffset === clause.length();
    }

    return false;
  }

  /**
   * Checks if the caret is at the start of the first input or end of the last input in a clause. Will also check if that clause is
   * not in a clause group. If it is, then it checks that it is the first or last clause in the clause group, respectively.
   *
   * @param checkAtStart  {boolean}   Whether to check if the caret is at the start of the group if true, or the end if false
   */
  private _isInInputAndAtOneEndOfClauseOrClauseGroup(target: Fragment, offset: number, checkAtStart: boolean): boolean {
    const isInCorrectInput: boolean = this._isAtEdgeOfInputAtEdgeOfClause(target, offset, checkAtStart);

    if (isInCorrectInput) {
      const clause: ClauseFragment = target.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
      const clauseGroup: Fragment = clause.findAncestorWithType(FragmentType.CLAUSE_GROUP);
      return !clauseGroup || this._isClauseAtEdgeOfClauseGroup(clause, checkAtStart);
    }

    return false;
  }

  /**
   * Checks if given clause is at the start or the end of it's ancestor clause groups.
   * Note that it does not check the position of the caret within the given clause, just the position
   * of the clause itself within the containing clause groups.
   */
  private _isClauseAtEdgeOfClauseGroup(clause: ClauseFragment, checkAtStart: boolean): boolean {
    const clauseGroups: Fragment[] = clause.findAllAncestorsWithType(FragmentType.CLAUSE_GROUP);

    return (
      clauseGroups.length &&
      [clause, ...clauseGroups.slice(0, -1)].every((fragment: Fragment) =>
        checkAtStart ? fragment.isFirstChild() : fragment.isLastChild()
      )
    );
  }

  /**
   * Checks if the caret is at the start of the first input or at the end of the last input in a clause.
   *
   * @param checkAtStart  {boolean}   Whether to check if the caret is at the start of the input if true, else the end if false
   */
  private _isAtEdgeOfInputAtEdgeOfClause(target: Fragment, offset: number, checkAtStart: boolean): boolean {
    const input: Fragment = target.findAncestorWithType(FragmentType.INPUT);

    if (!input) {
      return false;
    }

    const isInCorrectInput: boolean = checkAtStart
      ? target.isFirstChild() && offset === 0 && !this._findNearestInput(input, true)
      : target.isLastChild() && offset === target.length() && !this._findNearestInput(input, false);

    return isInCorrectInput;
  }

  /**
   * Find the nearest sibling of the given fragment in the given direction which is an input.
   *
   * @param fragment  {Fragment}  The fragment to search the siblings of
   * @param previous  {boolean}   True if searching previous siblings, else false
   */
  private _findNearestInput(fragment: Fragment, previous: boolean): Fragment {
    const getSibling: (f: Fragment) => Fragment = (f: Fragment) => (previous ? f.previousSibling() : f.nextSibling());

    let sibling: Fragment = getSibling(fragment);
    while (sibling && !sibling.is(FragmentType.INPUT)) {
      sibling = getSibling(sibling);
    }

    return sibling ? sibling : null;
  }

  /**
   * Handle a backspace key event by merging the clause into the previous clause.
   *
   * @param index  {number}              The index of the current list item
   * @param target {FragmentComponent}   The target fragment component
   * @returns      {ActionRequest}       The desired action
   */
  private async _handleBackspace(index: number, target: FragmentComponent): Promise<ActionRequest> {
    if (index === 0) {
      return null;
    }

    const previousEntry: Fragment = this.content.children[index - 1];

    if (previousEntry.is(FragmentType.CLAUSE_GROUP) || previousEntry.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) {
      const previousClause: ClauseFragment = previousEntry.is(FragmentType.CLAUSE)
        ? (previousEntry as ClauseFragment)
        : (previousEntry.children[previousEntry.children.length - 1] as ClauseFragment);
      const current: Fragment = this.content.children[index];

      if (
        current.length() === 0 &&
        this._lockService.canLock(previousClause) &&
        (await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithoutSubtrees(current))
      ) {
        // If current clause is empty, validate that we should delete it, otherwise do nothing.
        this._fragmentService.deleteValidatedFragments(current);
        const toSelect: Fragment = getFinalEditableDescendant(previousClause);
        return new ActionRequest(toSelect, toSelect ? toSelect.length() : 0);
      }
    } else if (previousEntry.is(FragmentType.CLAUSE)) {
      const prev: ClauseFragment = previousEntry as ClauseFragment;

      if (this._lockService.canLock(prev)) {
        if (prev.length() === 0) {
          // If previous clause is empty, validate that we should delete it, otherwise do nothing.
          if (await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithoutSubtrees(prev)) {
            this._fragmentService.deleteValidatedFragments(prev);
            return new ActionRequest(this.content.children[index - 1]);
          }
          return new ActionRequest();
        }
        // Calculate offset ignoring captioned child fragments.
        const offset: number = prev.length((child: Fragment) => !child.isCaptioned());
        const action: ActionRequest = new ActionRequest(prev, offset);
        await this.handleMerge(this.content.children[index] as ClauseFragment, prev);
        return action;
      } else {
        return new ActionRequest();
      }
    }

    return null;
  }

  /**
   * Handle a delete key event by merging the clause into the next clause.
   *
   * @param index  {number}              The index of the current list item
   * @param target {FragmentComponent}   The target fragment component
   * @returns      {ActionRequest}       The desired action
   */
  private async _handleDelete(index: number, target: FragmentComponent): Promise<ActionRequest> {
    if (index === this.content.children.length - 1) {
      return null;
    }

    const nextEntry: Fragment = this.content.children[index + 1];

    if (nextEntry.is(FragmentType.CLAUSE_GROUP) || nextEntry.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) {
      const nextClause: ClauseFragment = nextEntry.is(FragmentType.CLAUSE)
        ? (nextEntry as ClauseFragment)
        : (nextEntry.children[0] as ClauseFragment);
      const current: Fragment = this.content.children[index];

      if (
        current.length() === 0 &&
        this._lockService.canLock(nextClause) &&
        (await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithoutSubtrees(current))
      ) {
        // If current clause is empty, validate that we should delete it, otherwise do nothing.
        this._fragmentService.deleteValidatedFragments(current);
        const toSelect: Fragment = getFirstEditableDescendant(nextClause);
        return new ActionRequest(toSelect, 0);
      }
    } else if (nextEntry.is(FragmentType.CLAUSE)) {
      const next: ClauseFragment = this.content.children[index + 1] as ClauseFragment;
      const current: Fragment = this.content.children[index];
      if (this._lockService.canLock(next)) {
        if (current.length() === 0) {
          // If current clause is empty, validate that we should delete it, otherwise do nothing.
          if (await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithoutSubtrees(current)) {
            this._fragmentService.deleteValidatedFragments(current);
            return new ActionRequest(this.content.children[index]);
          }
          return new ActionRequest();
        }
        await this.handleMerge(next, current as ClauseFragment);
        return new ActionRequest();
      } else {
        return new ActionRequest();
      }
    }

    return null;
  }

  /**
   * Helper function to merge one clause's children into anothers. Captioned fragments are pushed to the end of the
   * target clause. The source clause is validated for deletion, unless the passed parameter indicates it has already
   * been validated.
   *
   * @param source {ClauseFragment}   The source clause
   * @param target {ClauseFragment}   The target clause
   * @param fragmentDeletionValidated {boolean} True if the deletion has already been validated
   */
  public async handleMerge(
    source: ClauseFragment,
    target: ClauseFragment,
    fragmentDeletionValidated: boolean = false
  ): Promise<void> {
    if (
      !fragmentDeletionValidated &&
      !(await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithoutSubtrees(source))
    ) {
      return;
    }

    const updated: Fragment[] = [];
    const deleted: Fragment[] = [];
    deleted.push(source);

    updated.push(...source.children.splice(0));
    target.children.push(...updated);

    // Temporarily remove captioned things so they can be pushed to the end
    const captioned: Fragment[] = target.children.filter((child: Fragment) => child.isCaptioned());
    captioned.forEach((child: Fragment) => child.remove());

    // Merge any text into the last list item, if it exists, and combine list items
    const list: Fragment = target.children.find((child: Fragment) => child.is(FragmentType.LIST));
    while (list && !list.isLastChild()) {
      list.markSiblingsForCheck();
      const sibling: Fragment = list.nextSibling();
      sibling.remove();

      if (sibling.is(FragmentType.LIST)) {
        const items: Fragment[] = sibling.children.splice(0);
        list.children.push(...items);
        deleted.push(sibling);
        updated.push(...items);
      } else {
        // We're temporarily removed tables, figures and display equations at this point
        const lastItem: Fragment = list.children[list.children.length - 1];
        lastItem.children.push(sibling);
        updated.push(sibling);
      }
    }

    // Add captioned stuff back to the end of target
    target.children.push(...captioned);
    updated.push(...captioned);

    this._fragmentService.update(updated);
    this._fragmentService.deleteValidatedFragments(deleted);
    this._fragmentService.mergeChildren(target);
  }

  /**
   * Manages the position of the cursor when a user clicks below the last clause in a section.
   * If the last clause has any non-text fragment children, or is locked, a new clause will be
   * created and focused.  Otherwise, the last clause will be focused.
   *
   * @param event {MouseEvent}   The click event
   */
  public contentEditableClick(event: MouseEvent) {
    const target: FragmentComponent = FragmentComponent.fromNode(event.target as Node);
    if (target && target.content.is(FragmentType.SECTION) && !this.reordering) {
      const lastClause: ClauseFragment = this.content.children[this.content.children.length - 1].is(FragmentType.CLAUSE)
        ? (this.content.children[this.content.children.length - 1] as ClauseFragment)
        : null;
      const lastChild: Fragment = lastClause ? lastClause.children[lastClause.children.length - 1] : null;

      if (
        lastChild &&
        lastChild.is(...EDITABLE_TEXT_FRAGMENT_TYPES) &&
        this._lockService.canLock(lastClause) &&
        this._isCommentable
      ) {
        this._selectionOperationsService.setSelected(lastClause, lastClause.length(), this.padType);
      } else if (this._canEditPad) {
        const newClause: ClauseFragment = ClauseFragment.empty(this._defaultClauseType);
        this.content.children.push(newClause);
        this._clauseService.create(newClause);
        this._selectionOperationsService.setSelected(newClause, 0, this.padType);
      }

      event.preventDefault();
    }
  }

  public handleAltEvent(): void {
    if (this.content.children[0]) {
      this._selectionOperationsService.setSelected(this.content.children[0], 0, this.padType);
      this._altAccessibilityService.triggerAltEvent(false);
    }
  }

  /**
   * Get the first element with class which is an ancestor of this component's element.
   * Otherwise, in views which contain multiple pads using document.getElementsByClassName
   * or similar would grab the wrong element.
   */
  private _getParentElementWithClass(elementClass: string): HTMLElement {
    let el: HTMLElement = this.element;
    while (el && !el.classList.contains(elementClass)) {
      el = el.parentElement;
    }
    return el;
  }

  /**
   * Respond to click events on a clause or clause group by selecting it for reordering if in progress.
   */
  public click(fragment: Fragment, event: MouseEvent): void {
    if (this.reordering) {
      this._reorderClausesService.selectFragment(fragment, event);
    }
  }

  /**
   * Returns true if the given fragment is selected for reordering.
   */
  public isSelected(fragment: Fragment): boolean {
    return !!fragment && this._reorderClausesService.isSelected(fragment);
  }

  /**
   * Gets the number of selected items for displaying the dragging indicator.
   */
  public getSelectionLength(): number {
    return this._reorderClausesService.getSelection().length;
  }

  /**
   * Respond to a mousedown while reordering by beggining dragging.
   *
   * @param fragment {Fragment}     The target fragment
   * @returns        {boolean}      Whether to cancel the event
   */
  public mouseDown(fragment: Fragment, event: MouseEvent): boolean {
    const canLockFragment: boolean = fragment.is(FragmentType.CLAUSE)
      ? this._lockService.canLock(fragment as ClauseFragment)
      : this._lockService.canLockGroup(fragment as ClauseGroupFragment);
    if (this.reordering && this.isSelected(fragment) && canLockFragment) {
      event.preventDefault();

      clearTimeout(this._dragTimeout);
      this._dragTimeout = setTimeout(() => {
        this.collapseSelection = this._shouldCollapseSelection();
        this._reorderClausesService.triggerDraggingEvent(true);
      }, ClauseListComponent.DRAG_START_THRESHOLD);

      return false;
    }
  }

  /**
   * @returns true iff the selection should be collapsed.
   */
  private _shouldCollapseSelection(): boolean {
    const getElementHeight = (fragment: Fragment): number => {
      if (!fragment.component) {
        return 0;
      }
      const element = fragment.component.element;
      return element.getBoundingClientRect().height;
    };

    const selection: Fragment[] = this._reorderClausesService.getSelection();
    const totalHeight: number = selection.reduce((total: number, fragment: Fragment) => {
      return total + getElementHeight(fragment);
    }, 0);

    return totalHeight > ClauseListComponent.COLLAPSE_THRESHOLD;
  }

  /**
   * Sets the current mouseovered fragment and update the display list. Does not set as hovering if it is part of the selection.
   */
  public setHovering(fragment: Fragment): void {
    if (!this.isSelected(fragment)) {
      this._hovering = fragment;
      this._updateListForDisplay();
    }
  }

  /**
   * Constructs the list of fragments that should be displayed, accounting for the dragged clauses and clause groups if dragging.
   */
  private _updateListForDisplay(): void {
    if (!this.dragging) {
      this.listForDisplay = this.content.children;
    } else {
      this.listForDisplay = [];
      const draggedFragmentsToShow: Fragment[] = this._draggedOutOfPad
        ? []
        : this.collapseSelection
        ? [this._reorderClausesService.getSelection()[0]]
        : this._reorderClausesService.getSelection();

      if (!this._hovering) {
        this.listForDisplay.push(...draggedFragmentsToShow);
      }

      for (let i = 0; i < this.content.children.length; i++) {
        if (!this.isSelected(this.content.children[i])) {
          this.listForDisplay.push(this.content.children[i]);

          if (this._hovering && this._hovering.equals(this.content.children[i])) {
            this.listForDisplay.push(...draggedFragmentsToShow);
          }
        }
      }
    }
    this._cd.markForCheck();
  }

  /**
   * Respond to mouseup events while reordering by performing the reorder.
   *
   * @param event {MouseEvent}   The mouseup event
   */
  public onDrop(event: MouseEvent): void {
    clearTimeout(this._scrollTimeout);
    clearTimeout(this._dragTimeout);

    if (this.dragging) {
      if (!this._draggedOutOfPad) {
        this._reorderClausesService.moveClausesInSection(this._hovering);
      }

      this._reorderClausesService.triggerDraggingEvent(false);
    }
  }

  /**
   * Scroll the clause list while dragging clauses for reorder.
   *
   * @param down  {boolean}  True if scrolling down
   * @param speed {number}   The scroll speed
   */
  public scroll(down: boolean, speed: number = ClauseListComponent.DEFAULT_SCROLL_SPEED): void {
    const pad = this._getParentElementWithClass('pad-container');
    pad.scrollTop = pad.scrollTop + (down ? speed : -speed);
    this._scrollTimeout = setTimeout(() => {
      this.scroll(down, speed < ClauseListComponent.MAX_SCROLL_SPEED ? speed + 1 : speed);
    }, ClauseListComponent.SCROLL_FREQUENCY);
  }

  /**
   * Stop the scroll timeout.
   */
  public stopScroll(): void {
    clearTimeout(this._scrollTimeout);
  }
}
