import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {ChangelogLink} from 'app/changelog/changelog-link';
import {ChangelogRange} from 'app/changelog/changelog-range';
import {ChangelogService} from 'app/changelog/changelog.service';
import {RangeMenuComponent} from 'app/changelog/range-menu/range-menu.component';
import {CarsRange, CarsRangeType} from 'app/fragment/cars-range';
import {SectionFragment} from 'app/fragment/types';
import {CanvasService} from 'app/services/canvas.service';
import {SuggestionsHovertipComponent} from 'app/spell-checker/suggestions-hovertip/suggestions-hovertip.component';
import {ColourUtils} from 'app/utils/colour-utils';
import {Subscription} from 'rxjs';
import {DivPool} from './div/div-pool';
import {Div} from './div/divs';
import {DrawableRange} from './drawable-range';

@Component({
  selector: 'cars-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit, OnDestroy, AfterViewInit {
  public static readonly FPS = 10;

  /**
   * This is the id of the DOM element used to determine the coordinate offsets
   * for drawing on the pad.  This is needed because bounding rect coordinates are
   * provided from the browser
   */
  @Input() public offsetElementId: string;
  @Input() public section: SectionFragment;

  @ViewChild('canvas', {static: true}) canvas: ElementRef;

  private ranges: Map<string, CarsRange> = new Map();
  private drawn: Map<string, DrawableRange> = new Map();
  private divPool: DivPool;
  private rendering: boolean = false;
  private subs: Subscription[] = [];
  private pad: HTMLElement;

  private changelogMenus: Map<string, ComponentRef<RangeMenuComponent>> = new Map();
  private spellingMenus: Map<string, ComponentRef<SuggestionsHovertipComponent>> = new Map();

  private renderingTypes: CarsRangeType[] = [];

  private renderLoop: number;

  public enabled: boolean = true;

  protected scrollListener: EventListener = (event: MouseEvent) => {
    this.rendering = true;
    const offsetClientRect: DOMRect = this._getOffsetElementBoundingRect();
    this.drawn.forEach((range: DrawableRange) => {
      const drawableRange: CarsRange = range.range;

      if (drawableRange instanceof ChangelogRange && !drawableRange.isDrawn) {
        range.isRendered = false;
        this._drawRange(range, offsetClientRect);
      }
    });
    this.rendering = false;
  };

  constructor(
    private _canvasService: CanvasService,
    private _containerRef: ViewContainerRef,
    private _zone: NgZone,
    private _changelogService: ChangelogService
  ) {}

  public ngOnInit(): void {
    this.subs.push(
      this._canvasService.getRanges().subscribe(this._setRanges.bind(this)),
      this._canvasService.onRenderingToggled((enabled: boolean) => {
        this.enabled = enabled;
        if (!this.enabled) {
          this.canvas.nativeElement.classList.add('disabled');
        } else {
          this.canvas.nativeElement.classList.remove('disabled');
          this.render();
        }
      }),
      this._canvasService.onRenderingTypesChanged((types: CarsRangeType[]) => {
        this.renderingTypes = types;
      }),
      this._canvasService.onMouseEventsToggled((enabled: boolean) => {
        if (!enabled) {
          this.canvas.nativeElement.classList.add('mouse-events-disabled');
        } else {
          this.canvas.nativeElement.classList.remove('mouse-events-disabled');
        }
      })
    );
    this._zone.runOutsideAngular(
      () => (this.renderLoop = window.setInterval(() => this.render(), 1000 / CanvasComponent.FPS))
    );
    this.pad = this._getParentElementWithClass(this.canvas.nativeElement, 'pad-container');
    document.addEventListener('scroll', this.scrollListener, true);
  }

  public ngOnDestroy(): void {
    document.removeEventListener('scroll', this.scrollListener, true);
    this.subs.splice(0).forEach((s: Subscription) => s.unsubscribe());
    this.ranges = new Map();
    this.drawn = new Map();
    window.clearInterval(this.renderLoop);
  }

  public ngAfterViewInit(): void {
    this.divPool = new DivPool(this.canvas.nativeElement);
  }

  /**
   * render the canvas.
   */
  public render(): void {
    if (this.enabled && !this.rendering && this.divPool && this.ranges.size > 0) {
      this.rendering = true;
      const offsetClientRect: DOMRect = this._getOffsetElementBoundingRect();
      this.drawn.forEach((range: DrawableRange) => this._drawRange(range, offsetClientRect));
      this.rendering = false;
    }
  }

  /**
   * Set the array of ranges to be drawn and call for a re-rendering.
   * @param ranges the ranges to draw
   */
  private _setRanges(ranges: Map<string, CarsRange>): void {
    if (!this.divPool) {
      return;
    }

    const offsetClientRect: DOMRect = this._getOffsetElementBoundingRect();
    this.ranges = ranges;

    this.drawn.forEach((drawable: DrawableRange) => {
      if (!this.ranges.has(drawable.range.hashcode()) || !this.renderingTypes.includes(drawable.range.type)) {
        this.divPool.recycle(drawable.renderedView);
        this.drawn.delete(drawable.range.hashcode());
      }
    });

    this.ranges.forEach((range: CarsRange) => {
      // Only draw ranges which are in the section this canvas is attached to,
      // and which are in the current set of rendered types.
      if (this.section.id.equals(range.sectionId) && this.renderingTypes.includes(range.type)) {
        if (!this.drawn.has(range.hashcode())) {
          this.drawn.set(range.hashcode(), new DrawableRange(range, offsetClientRect));
        } else if (range.forceRedraw || this.drawn.get(range.hashcode()).needsClearing()) {
          this.divPool.recycle(this.drawn.get(range.hashcode()).renderedView);
          range.forceRedraw = false;
          this.drawn.set(range.hashcode(), new DrawableRange(range, offsetClientRect));
        }
      }
    });

    this.render();
  }

  /**
   * Given a range object, draw it.
   *
   * @param drawableRange {DrawableRange} The range to draw
   */
  private _drawRange(drawableRange: DrawableRange, offsetClientRect: DOMRect): void {
    if (offsetClientRect && drawableRange.needsReRendering(offsetClientRect)) {
      const carsRange: CarsRange = drawableRange.range;

      // Clear out the current rendered view:
      this.divPool.recycle(drawableRange.renderedView);
      drawableRange.renderedView = [];

      // Don't try and draw invalid ranges: since the service still knows about them, though,
      // they will be drawn if they become valid again.
      if (
        carsRange.start.offset > carsRange.start.fragment.length() ||
        carsRange.end.offset > carsRange.end.fragment.length()
      ) {
        this.drawn.delete(drawableRange.range.hashcode());
        return;
      }

      // Draw the range:
      const padBoundingRect: DOMRect = this.pad.getBoundingClientRect();
      carsRange.forEachClientRect((rect: DOMRect) => {
        if (
          !(carsRange instanceof ChangelogRange) ||
          (rect.top > padBoundingRect.top && rect.bottom < padBoundingRect.bottom)
        ) {
          const div: Div = this.divPool.obtain();
          div.style(carsRange.type, rect, offsetClientRect);
          if (div.is(CarsRangeType.SPELLING_ERROR)) {
            this._createSuggestionsHovertip(div, carsRange);
          }
          drawableRange.renderedView.push(div);
          if (carsRange instanceof ChangelogRange) {
            carsRange.isDrawn = true;
          }
        } else {
          carsRange.isDrawn = false;
        }
      });

      if (carsRange instanceof ChangelogRange && carsRange.isDrawn) {
        this._changelogService.findLinksByRangeId(carsRange.id, carsRange.published).then((links: ChangelogLink[]) => {
          const linkCount: number = links.length;
          this._createChangelogMenu(drawableRange);
          if (linkCount > 0) {
            drawableRange.renderedView.forEach((div: Div) => {
              div.element.style.backgroundColor = ColourUtils.hslFromString(carsRange.id.value, 0.5);
            });
          } else if (carsRange.type !== CarsRangeType.DELETED_CHANGELOG_RANGE) {
            drawableRange.renderedView.forEach((div: Div) => {
              div.element.style.backgroundColor = 'lightgrey';
            });
          } else {
            drawableRange.renderedView.forEach((div: Div) => {
              div.element.style.backgroundColor = null;
            });
          }
        });
      }

      if (!(carsRange instanceof ChangelogRange && !carsRange.isDrawn)) {
        drawableRange.isRendered = true;
      }
    } else if (drawableRange.needsClearing()) {
      this.divPool.recycle(drawableRange.renderedView);
      drawableRange.renderedView = [];
      drawableRange.isRendered = false;
    }

    drawableRange.updateBoundingRects(offsetClientRect);
  }

  private _getOffsetElementBoundingRect(): DOMRect {
    const offsetElement: HTMLElement = document.getElementById(this.offsetElementId);
    return offsetElement ? offsetElement.getBoundingClientRect() : null;
  }

  /**
   * Adds event listeners to the div, creating the hovertip on mouseover.
   *
   * @param div   {Div}       Div to add event listeners for
   * @param range {CarsRange} Range to pass to the suggestions hovertip component
   */
  private _createSuggestionsHovertip(div: Div, range: CarsRange): void {
    this._zone.run(() => {
      let suggestionsComponent: ComponentRef<SuggestionsHovertipComponent>;
      div.addEventListener('mouseover', (event: MouseEvent) => {
        if (window.getSelection() && !window.getSelection().isCollapsed) {
          return;
        }
        suggestionsComponent = this._containerRef.createComponent(SuggestionsHovertipComponent);
        suggestionsComponent.instance.componentRef = suggestionsComponent;
        suggestionsComponent.instance.event = event;
        suggestionsComponent.instance.anchorElement = div.element;
        suggestionsComponent.instance.range = range;
        this.spellingMenus.forEach((cref: ComponentRef<SuggestionsHovertipComponent>) => cref.destroy());
        this.spellingMenus.clear();
        this.spellingMenus.set(range.hashcode(), suggestionsComponent);
      });
    });
  }

  /**
   * Adds event listeners to each div which makes up the rendered view.  Once the
   * menu component is created, no other menu components for any divs in the rendered
   * view can be created.
   *
   * @param div   {Div}       Div to add event listeners for
   * @param range {CarsRange} Range to pass to the suggestions hovertip component
   */
  private _createChangelogMenu(drawableRange: DrawableRange): void {
    this._zone.run(() => {
      let ref: ComponentRef<RangeMenuComponent>;
      const range: CarsRange = drawableRange.range;
      drawableRange.renderedView.forEach((div: Div) => {
        div.addEventListener('mouseover', (event: MouseEvent) => {
          if (this.changelogMenus.has(range.hashcode())) {
            return;
          }
          ref = this._containerRef.createComponent(RangeMenuComponent);
          ref.instance.componentRef = ref;
          ref.instance.event = event;
          ref.instance.anchorElement = div.element;
          ref.instance.drawnRange = drawableRange;
          ref.onDestroy(() => this.changelogMenus.delete(range.hashcode()));
          this.changelogMenus.forEach((cref: ComponentRef<RangeMenuComponent>) => cref.destroy());
          this.changelogMenus.set(range.hashcode(), ref);
        });
      });
    });
  }

  /**
   * Get the first element with class which is an ancestor of this component's element.
   * Otherwise, in views which contain multiple pads using document.getElementsByClassName
   * or similar would grab the wrong element.
   */
  private _getParentElementWithClass(element: HTMLElement, elementClass: string): HTMLElement {
    let el: HTMLElement = element;
    while (el && !el.classList.contains(elementClass)) {
      el = el.parentElement;
    }
    return el;
  }
}
