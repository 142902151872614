import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FragmentType} from 'app/fragment/types';
import {environment} from 'environments/environment';
import {UUID} from '../../utils/uuid';

interface WebsocketSession {
  createdAt: string;
  typeIdPairs: {FragmentType: UUID};
}

interface LocationUsers {
  fragmentId: UUID;
  fragmentType: FragmentType;
  users: UUID[];
}

@Component({
  selector: 'cars-user-locations',
  templateUrl: './user-locations.component.html',
  styleUrls: ['./user-locations.component.scss'],
})
export class UserLocationsComponent implements OnInit {
  private apiUrl = `${environment.apiHost}/users/locations`;

  public locationsPerUser: Map<string, [WebsocketSession]> = new Map();
  public usersPerLocation: Map<string, LocationUsers> = new Map();

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    this.loadLocations();
  }

  public loadLocations(): void {
    this.locationsPerUser.clear();
    this.usersPerLocation.clear();

    this.httpClient.get(this.apiUrl).subscribe((response: any[]) => {
      response.forEach((session) => {
        const userId: string = session.userId;
        const createdAt: string = session.createdAt;
        const typeIdPairs: {FragmentType: UUID} = session.interests;

        const sess: WebsocketSession = {createdAt, typeIdPairs};

        if (this.locationsPerUser.has(userId)) {
          this.locationsPerUser.get(userId).push(sess);
        } else {
          this.locationsPerUser.set(userId, [sess]);
        }

        Object.keys(typeIdPairs).forEach((type) => {
          const fragmentId: string = typeIdPairs[type];
          if (this.usersPerLocation.has(fragmentId)) {
            this.usersPerLocation.get(fragmentId).users.push(UUID.orThrow(userId));
          } else {
            this.usersPerLocation.set(fragmentId, {
              fragmentId: UUID.orThrow(fragmentId),
              fragmentType: FragmentType[type],
              users: [UUID.orThrow(userId)],
            });
          }
        });
      });
    });
  }

  public locationUsersToUrl(locationUsers: LocationUsers): string {
    return this.toFragmentUrl(locationUsers.fragmentType, locationUsers.fragmentId);
  }

  public typeIdPairToUrl(obj: {FragmentType: UUID}): string {
    const keys = Object.keys(obj);
    if (keys.length === 0) {
      return '/';
    }
    const type: FragmentType = FragmentType[keys[0]];
    const fragmentId: UUID = UUID.orThrow(obj[keys[0]]);
    return this.toFragmentUrl(type, fragmentId);
  }

  private toFragmentUrl(fragmentType: FragmentType, fragmentId: UUID): string {
    switch (fragmentType) {
      case FragmentType.DOCUMENT:
        return '/documents/' + fragmentId.value;
      default:
        return fragmentId.value;
    }
  }

  public mapTracker(index, item) {
    return index;
  }
}
