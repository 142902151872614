<div class="main-container">
  <div class="error-text">
    CARS has encounted an error and needs to refresh. The following clauses have unsaved changes in them. (Note that on
    copy, equations, figures and tables will not be copied).
    <p *ngIf="errorResponse">
      Error: <em class="error-message">{{ errorMessage }}</em>
    </p>
  </div>

  <table>
    <tr>
      <th class="index-column">Index</th>
      <th>Clauses</th>
    </tr>
    <ng-container *ngFor="let clause of clauses">
      <tr>
        <td>
          <span style="user-select: none">{{ clause | fragmentIndex }}</span>
        </td>
        <td>
          <cars-clause
            [content]="clause"
            [showMarginIcons]="false"
            [readOnly]="true"
            [showCurrentClause]="false"
          ></cars-clause>
        </td>
      </tr>
    </ng-container>
  </table>

  <div class="buttons-container">
    <button
      mat-raised-button
      matTooltip="Copy unsaved clause text (figures, equations and tables will not be copied) and refresh."
      [matTooltipShowDelay]="tooltipDelay"
      color="primary"
      (click)="copyAndRefresh()"
      aria-label="Copy unsaved clause text (figures, equations and tables will not be copied) and refresh."
    >
      <mat-icon>content_copy</mat-icon>
      Copy & refresh
    </button>
  </div>
</div>
