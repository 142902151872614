import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {InternalReferenceRefreshComponent} from './internal-reference-refresh.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatProgressBarModule, MatTooltipModule],
  declarations: [InternalReferenceRefreshComponent],
  exports: [InternalReferenceRefreshComponent],
})
export class InternalReferenceRefreshModule {}
