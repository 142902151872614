import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {ClauseFragment, FragmentType, InternalReferenceType} from 'app/fragment/types';
import {ReferenceInputFragment} from 'app/fragment/types/input/reference-input-fragment';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {TargetDocumentType} from 'app/fragment/types/reference/target-document-type';
import {CarsAction} from 'app/permissions/types/permissions';
import {ClauseService} from 'app/services/clause.service';
import {FragmentService} from 'app/services/fragment.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {UUID} from 'app/utils/uuid';
import {AbstractHovertipComponent} from 'app/widgets/abstract-hovertip.component';

@Component({
  selector: 'cars-section-reference-hovertip',
  templateUrl: './section-reference-hovertip.component.html',
  styleUrls: ['./section-reference-hovertip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionReferenceHovertipComponent extends AbstractHovertipComponent implements OnInit {
  @Input() public referenceInput: ReferenceInputFragment;
  @Input() public inlineReference: InternalInlineReferenceFragment;
  @Input() public internalReferenceType: InternalReferenceType;

  public readonly CarsAction: typeof CarsAction = CarsAction;
  protected readonly leftOffset: number = -15;
  protected readonly topOffset: number = 10;

  public documentId: UUID;
  public targetSectionUrl: string;

  private sidebarStatus: SidebarStatus;

  constructor(
    private _sidebarService: SidebarService,
    private _fragmentService: FragmentService,
    private _clauseService: ClauseService,
    protected elementRef: ElementRef,
    protected renderer: Renderer2
  ) {
    super(elementRef, renderer);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.documentId = this.inlineReference?.documentId || this.referenceInput?.documentId;
    this.sidebarStatus =
      this.internalReferenceType === InternalReferenceType.CLAUSE_REFERENCE
        ? SidebarStatus.CLAUSE_REFERENCES
        : SidebarStatus.SECTION_REFERENCES;
    if (this.internalReferenceType !== InternalReferenceType.CLAUSE_REFERENCE) {
      this._setSectionUrl();
    }
  }

  /**
   * Sets the url of the target section that should be navigated to.
   */
  private _setSectionUrl(): void {
    this.targetSectionUrl = null;

    if (!!this.inlineReference) {
      const internalDocRef = this._fragmentService.find(
        this.inlineReference.internalDocumentReferenceId
      ) as InternalDocumentReferenceFragment;

      if (internalDocRef) {
        this.targetSectionUrl = `/documents/${internalDocRef.targetDocumentId.value}${
          !!internalDocRef.targetVersionId ? `/versions/${internalDocRef.targetVersionId.value}` : ''
        }/sections/${internalDocRef.targetSectionId.value}`;
      } else {
        Logger.error('internal-reference-error', 'Internal document reference not found');
      }
    }
  }

  /**
   * Opens the sidebar to the Section Reference view. Selects the clause as clicking in the hovertip does not do this
   * automatically.
   */
  public openSidebar(): void {
    const refId: UUID = this.referenceInput
      ? this.referenceInput.id
      : this.inlineReference
      ? this.inlineReference.parentId
      : null;

    this._clauseService.setSelected(this._getClause());
    this._sidebarService.setSidebarStatus(this.sidebarStatus, false, refId);
  }

  /**
   * Returns true if the sidebar can be opened/switched to the section reference view for the current clause. Returns
   * false if the sidebar is already open in the section reference view with the current clause selected.
   */
  public canOpenSidebar(): boolean {
    return (
      this._sidebarService.getStatus() !== this.sidebarStatus ||
      !this._clauseService.getSelected()?.equals(this._getClause())
    );
  }

  private _getClause(): ClauseFragment {
    return (
      !!this.inlineReference
        ? this.inlineReference.findAncestorWithType(FragmentType.CLAUSE)
        : this.referenceInput.findAncestorWithType(FragmentType.CLAUSE)
    ) as ClauseFragment;
  }

  /**
   * Gets the string display name to show within the tooltip for the inline reference.
   */
  public getTargetSectionDisplayName(): string {
    const internalDocRef = this._fragmentService.find(
      this.inlineReference.internalDocumentReferenceId
    ) as InternalDocumentReferenceFragment;
    if (!!internalDocRef && internalDocRef.internalReferenceType === InternalReferenceType.WSR_REFERENCE) {
      const trimmedSectionIndex: string = internalDocRef.sectionIndex.endsWith('.')
        ? internalDocRef.sectionIndex.slice(0, -1)
        : internalDocRef.sectionIndex;
      return `${internalDocRef.documentCode}; Section ${trimmedSectionIndex}; ${internalDocRef.sectionTitle}
        (${internalDocRef.wsrCode}; ${internalDocRef.subject}; ${internalDocRef.topic})`;
    } else if (!!internalDocRef) {
      // eslint-disable-next-line max-len
      return `${internalDocRef.documentCode} ${internalDocRef.documentTitle} - ${internalDocRef.sectionIndex} ${internalDocRef.sectionTitle}`;
    } else {
      return 'Failed to load reference';
    }
  }

  /**
   * Gets the string value for the target used in the html, used to open a link to another document in a new tab, or a
   * link to the same document in the same tab.
   */
  public getRouterLinkTarget(): string {
    const internalDocRef = this._fragmentService.find(
      this.inlineReference.internalDocumentReferenceId
    ) as InternalDocumentReferenceFragment;

    return !!internalDocRef && internalDocRef.targetDocumentType === TargetDocumentType.DIFFERENT_DOCUMENT
      ? '_blank'
      : '';
  }
}
