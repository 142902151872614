import {
  DocumentReferenceFragment,
  Fragment,
  FragmentType,
  InternalReferenceType,
  SectionFragment,
  SectionType,
} from 'app/fragment/types';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {TargetDocumentType} from 'app/fragment/types/reference/target-document-type';
import {UUID} from 'app/utils/uuid';
import {referenceTitleCompareFunction} from './reference-compare-functions';

export const DEFAULT_REFERENCE_KEY: string = 'Ref ?.?';

interface SortableDocumentReference {
  title: string;
  referenceIds: UUID[];
}

export class ReferenceIndexUtils {
  private static readonly REFERENCE_LETTER_LOOKUP: Partial<Record<SectionType, string>> = {
    [SectionType.REFERENCE_NORM]: 'N',
    [SectionType.REFERENCE_INFORM]: 'I',
  };

  /**
   * Populates the given record with the refernec indexes for the references in the given section.
   * It does this by filtering/sorting the references in the given section, and then iterating
   * through and indexing each one as appropriate.
   */
  public static populateRecordWithReferenceIndexes(section: SectionFragment, map: Record<string, string>): void {
    ReferenceIndexUtils.getSortedSortableDocumentReferences(section).forEach(
      (value: SortableDocumentReference, index: number) => {
        value.referenceIds.forEach((id: UUID) => {
          map[id.value] = ReferenceIndexUtils.getReferenceIndex(value.title, index, section.sectionType);
        });
      }
    );
  }

  private static getReferenceIndex(title: string, index: number, sectionType: SectionType): string {
    return `Ref ${title ? index + 1 : '?'}.${ReferenceIndexUtils.REFERENCE_LETTER_LOOKUP[sectionType] || '?'}`;
  }

  /**
   * This takes a section, maps the reference fragments contained within it to SortableDocumentReference
   * objects, filters out any reference fragments that should not be indexed (and thus should not
   * effect the indexing of other reference in the section) and then sorts them alphabetically
   * according to their title.
   */
  private static getSortedSortableDocumentReferences(section: SectionFragment): SortableDocumentReference[] {
    const references: Fragment[] = section?.children?.length ? section.children[0].children : null;

    if (!references) {
      return [];
    }

    return Object.values(
      references.reduce((returnMap: Record<string, SortableDocumentReference>, current: Fragment) => {
        const targetId: string = ReferenceIndexUtils.getTargetId(current)?.value;

        if (!targetId) {
          return returnMap;
        }

        if (!returnMap[targetId]) {
          returnMap[targetId] = ReferenceIndexUtils.getSortableDocumentReference(current);
        } else {
          returnMap[targetId].referenceIds.push(current.id);
        }

        return returnMap;
      }, {})
    )
      .filter(Boolean)
      .sort(ReferenceIndexUtils.sortableDocumentReferenceCompareFunction);
  }

  private static getTargetId(fragment: Fragment): UUID {
    switch (fragment.type) {
      case FragmentType.DOCUMENT_REFERENCE:
        const documentReference: DocumentReferenceFragment = fragment as DocumentReferenceFragment;
        return documentReference.searchableGlobalReference?.carsDocumentId || documentReference.globalReference;
      case FragmentType.INTERNAL_DOCUMENT_REFERENCE:
        return (fragment as InternalDocumentReferenceFragment).targetDocumentId;
      default:
        return null;
    }
  }

  /**
   * Returns a SortableDocumentReference for the given reference fragment.
   * We return null for non reference fragments and internal document references as they should not
   * be indexed and thus should not be included when sorting the reference fragments.
   */
  private static getSortableDocumentReference(fragment: Fragment): SortableDocumentReference {
    if (fragment.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE)) {
      const internalDocumentReferenceFragment: InternalDocumentReferenceFragment =
        fragment as InternalDocumentReferenceFragment;

      return internalDocumentReferenceFragment.targetDocumentType !== TargetDocumentType.SAME_DOCUMENT &&
        internalDocumentReferenceFragment.internalReferenceType !== InternalReferenceType.WSR_REFERENCE
        ? {
            title: internalDocumentReferenceFragment.documentTitle,
            referenceIds: [internalDocumentReferenceFragment.id],
          }
        : null;
    } else if (fragment.is(FragmentType.DOCUMENT_REFERENCE)) {
      const documentReferenceFragment: DocumentReferenceFragment = fragment as DocumentReferenceFragment;

      return {
        title: documentReferenceFragment.searchableGlobalReference?.title || '',
        referenceIds: [documentReferenceFragment.id],
      };
    }

    return null;
  }

  private static sortableDocumentReferenceCompareFunction(
    a: SortableDocumentReference,
    b: SortableDocumentReference
  ): number {
    return referenceTitleCompareFunction(a.title, b.title);
  }

  // Private constructor to prevent instantiations
  private constructor() {}
}
