import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BlurOption} from 'app/blur-options';
import {CarsRange} from 'app/fragment/cars-range';
import {AltProperties} from 'app/services/alt-accessibility.service';
import {CaretService} from 'app/services/caret.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {ToolbarItem} from 'app/suite-config/configuration.service';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-suggest-changes',
  templateUrl: './suggest-changes.component.html',
  styleUrls: ['./suggest-changes.component.scss', '../toolbar-buttons.scss'],
})
export class SuggestChangesComponent implements OnInit, OnDestroy {
  @Input() public toolbarItem: ToolbarItem;
  @Input() public altProperties: AltProperties;

  public enabled: boolean;
  public status: SidebarStatus;
  public sidebarStatus = SidebarStatus;
  public readonly tooltipDelay = environment.tooltipDelay;
  public range: CarsRange;
  public currentView: CurrentView;
  public readonly BlurOption: typeof BlurOption = BlurOption;

  private _subscriptions: Subscription[] = [];

  constructor(
    private caretService: CaretService,
    private sidebarService: SidebarService,
    private viewService: ViewService
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this.sidebarService.getSidebarStatus().subscribe((status: SidebarStatus) => {
        this.status = status;
      }),
      this.caretService.onSelectionChange((range: CarsRange) => {
        this.range = range;
        this._updateEnabled();
      }),
      this.viewService.onCurrentViewChange((view: CurrentView) => {
        this.currentView = view;
        this._updateEnabled();
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public suggest(e: MouseEvent): void {
    if (!!e) {
      e.preventDefault();
    }
    this.sidebarService.setSidebarStatus(SidebarStatus.DISCUSSIONS, true);
  }

  private _updateEnabled(): void {
    this.enabled = this.toolbarItem.enabled(this.range, this.currentView);
    this.altProperties.condition = this.enabled;
  }
}
