import {Injectable} from '@angular/core';
import {ElementRefService, PadType} from './element-ref.service';
import {Caret} from './fragment/caret';
import {getFirstEditableDescendant} from './fragment/fragment-utils';
import {ClauseFragment, Fragment, FragmentType, SectionFragment} from './fragment/types';
import {ClauseService} from './services/clause.service';
import {DomService} from './services/dom.service';
import {FragmentService} from './services/fragment.service';

@Injectable({
  providedIn: 'root',
})
export class SelectionOperationsService {
  private _pendingCaret: Caret = new Caret(null, 0);

  constructor(
    private _clauseService: ClauseService,
    private _fragmentService: FragmentService,
    private _elementRefService: ElementRefService,
    private _domService: DomService
  ) {}

  /**
   * Set the currently focused fragment, blurring the previous one.  Also pass an offset
   * to set the cursor to a non-zero offset relative to the start of the fragment.
   *
   * @param newFragment {Fragment}   The fragment to focus
   * @param offset      {number}     An offset from the start of newFragment
   * @returns           {Fragment}   The previously focused fragment
   */
  public setSelected(newFragment: Fragment, offset: number, padType: PadType): void {
    if (!window.getSelection().isCollapsed) {
      window.getSelection().collapseToEnd();
    }

    if (newFragment) {
      this._elementRefService.getElementRef(padType).nativeElement.focus();
    }

    offset = newFragment ? Math.min(offset, newFragment.length()) : 0;
    this._pendingCaret = new Caret(newFragment, offset);

    // TODO: This is a temporary hack to enable caption updates, which are handled as fake
    // TextFragments by the frontend but are really just strings.  Hopefully this can be
    // made for systemic later, since there are a number of outstanding issues with captions.
    if (!newFragment || this._fragmentService.find(newFragment.id)) {
      this._fragmentService.setSelected(newFragment);
    } else if (newFragment.parent && this._fragmentService.find(newFragment.parent.id)) {
      this._fragmentService.setSelected(newFragment.parent);
    }

    const prevClause: ClauseFragment = this._clauseService.getSelected();
    const nextClause: ClauseFragment = newFragment
      ? (newFragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment)
      : null;
    if (!prevClause || !prevClause.equals(nextClause)) {
      this._clauseService.setSelected(nextClause);
    }

    // return this._caret.fragment;
  }

  /**
   * Update and return the caret position, if necessary.
   *
   * @param caret {Caret} The initial caret position
   * @returns     {Caret} The updated caret position
   */
  public updateCaretPosition(caret: Caret): Caret {
    if (this._pendingCaret && this._pendingCaret.fragment && this._pendingCaret.fragment.isAttached()) {
      this._updateCaret(caret, this._pendingCaret);

      // Apply the caret update
      caret = this._pendingCaret;
      this._pendingCaret = null;
    }
    return caret;
  }

  /**
   * Handle ctrl-a depending on location of the caret.
   *
   * @param caret {Caret} The current caret
   */
  public selectAll(caret: Caret): void {
    const fragment = caret.fragment;
    let startCaret: Caret = null;
    let endCaret: Caret = null;

    if (fragment) {
      if (fragment.findAncestorWithType(FragmentType.EQUATION)) {
        startCaret = Caret.startOf(fragment);
        endCaret = Caret.endOf(fragment);
      } else if (fragment.findAncestorWithType(FragmentType.TABLE_CELL)) {
        const tableCell: Fragment = fragment.findAncestorWithType(FragmentType.TABLE_CELL);
        startCaret = Caret.startOf(tableCell);
        endCaret = Caret.endOf(this._selectEndFragment(tableCell.children.slice(-1)[0]));
      } else if (fragment.findAncestorWithType(FragmentType.INPUT)) {
        const input: Fragment = fragment.findAncestorWithType(FragmentType.INPUT);
        startCaret = Caret.startOf(input.children[0]);
        endCaret = Caret.endOf(input.children.slice(-1)[0]);
      } else {
        const section: Fragment = fragment.findAncestorWithType(FragmentType.SECTION);
        startCaret = Caret.startOf(section.children[0]);
        endCaret = Caret.endOf(this._selectEndFragment(section.children.slice(-1)[0].children.slice(-1)[0]));
      }

      if (
        !!startCaret &&
        !!endCaret &&
        !(startCaret.fragment.equals(endCaret.fragment) && startCaret.fragment.length() === 0)
      ) {
        if (endCaret.fragment.length() === 0 && endCaret.offset === 0) {
          endCaret.offset = 1;
        }
        this._domService.setHighlighted(startCaret, endCaret);
      }
    }
  }

  /**
   * Handle PageUp and PageDown.
   *
   * On pageup, should select the first editable fragment in the first clause in the section which contains an editable fragment.
   * On pagedown, should select the first editable fragment in the last clause in the section which contains an editable fragment.
   *
   * @param caret   {Caret}   The current caret
   * @param padType {PadType} The type of pad
   * @param pageUp  {boolean} True if the PageUp button has been pressed
   */
  public onPageUpOrDown(caret: Caret, padType: PadType, pageUp: boolean): void {
    const fragment: Fragment = caret.fragment;
    if (fragment) {
      const section: SectionFragment = fragment.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
      const clauses: ClauseFragment[] = pageUp ? section.getClauses() : section.getClauses().reverse();

      let toSelect: Fragment;

      for (const clause of clauses) {
        toSelect = getFirstEditableDescendant(clause);

        if (toSelect) {
          break;
        }
      }

      this.setSelected(toSelect, 0, padType);
    }
  }

  /**
   * If the given fragment is captioned, this returns the last non captioned fragment in it's previous siblings.
   * Else it just returns the given fragment.
   *
   * @param fragment  The given fragment.
   */
  private _selectEndFragment(fragment: Fragment): Fragment {
    if (fragment.isCaptioned()) {
      if (fragment.previousSibling()) {
        return this._selectEndFragment(fragment.previousSibling());
      } else {
        return null;
      }
    } else {
      return fragment;
    }
  }

  /**
   * Update the caret position, and focus the fragment in which it is positioned.
   *
   * @param oldCaret  {Caret}       The current caret information
   * @param newCaret  {Caret}       The pending caret information
   * @param selection {Selection}   The current selection
   */
  private _updateCaret(oldCaret: Caret, newCaret: Caret, selection: Selection = window.getSelection()): void {
    const ancestor: Fragment = Fragment.commonAncestorOf(oldCaret.fragment, newCaret.fragment);
    // Blur the fragment branch from [oldCaret.fragment, ancestor)
    let blurFragment: Fragment = oldCaret.fragment;
    while (blurFragment && blurFragment !== ancestor && blurFragment.isAttached()) {
      blurFragment.component.blur();
      blurFragment = blurFragment.parent;
    }

    // Search for the text node within the fragment's shadow DOM with contains the cursor; this offset
    // differs from the caret offset due to zero-width spaces within some text nodes.
    const textNodes: Text[] = this._domService.textNodesFrom(newCaret.fragment.component.element);
    let targetIndex: number = 0;
    let renderOffset: number = newCaret.offset;

    const currentOffset: number = selection.anchorOffset;

    while (textNodes[targetIndex] && textNodes[targetIndex].textContent.length < renderOffset) {
      renderOffset -= textNodes[targetIndex++].textContent.length;
    }
    renderOffset = renderOffset <= 0 && newCaret.fragment.length() === 0 ? 1 : renderOffset;

    const selectionChangeRequired = selection.anchorNode !== textNodes[targetIndex] || currentOffset !== renderOffset;

    // Set the cursor to the correct offset
    if (selectionChangeRequired && selection.isCollapsed) {
      const range: Range = document.createRange();
      range.setStart(textNodes[targetIndex], renderOffset);
      range.setEnd(textNodes[targetIndex], renderOffset);
      range.collapse(true);

      selection.removeAllRanges();
      selection.addRange(range);
    }

    // This checks if the new cursor is off screen, and if so, it moves it back on screen.
    if (selection && selection.getRangeAt(0)) {
      const rect: DOMRect = selection.getRangeAt(0).getClientRects()[0];
      if (rect) {
        const pad: HTMLElement = this._getParentElementWithClass(newCaret.fragment.component.element, 'pad-container');
        if (rect.top + rect.height > window.innerHeight) {
          pad.scrollTop += Math.ceil(rect.top) + 2 * rect.height - window.innerHeight;
        } else {
          const padBoundingRect: DOMRect = pad.getBoundingClientRect();
          if (rect.top < padBoundingRect.top) {
            pad.scrollTop += Math.ceil(rect.top - padBoundingRect.top - rect.height);
          }
        }
      }
    }

    // Focus the fragment branch [newCaret.fragment, ancestor)
    let focusFragment: Fragment = newCaret.fragment;
    while (focusFragment && focusFragment !== ancestor && focusFragment.isAttached()) {
      focusFragment.component.focus();
      focusFragment = focusFragment.parent;
    }

    // Focus the parent component of fragments not directly in the tree, e.g. Equation sources.
    if (newCaret.fragment.parent && newCaret.fragment.parent.childIndexOf(newCaret.fragment) === -1) {
      newCaret.fragment.parent.component.focus();
    }

    // If we're selecting the same fragment, Angular may have replaced its DOM element between setting
    // _pendingCaret and arriving here; refocusing ensures it still has selection
    if (oldCaret.fragment === newCaret.fragment && newCaret.fragment.isAttached()) {
      newCaret.fragment.component.focus();
    }
  }

  /**
   * Get the first element with class which is an ancestor of this component's element.
   * Otherwise, in views which contain multiple pads using document.getElementsByClassName
   * or similar would grab the wrong element.
   */
  private _getParentElementWithClass(element: HTMLElement, elementClass: string): HTMLElement {
    let el: HTMLElement = element;
    while (el && !el.classList.contains(elementClass)) {
      el = el.parentElement;
    }
    return el;
  }
}
