import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ClauseService} from 'app/services/clause.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {Subscription} from 'rxjs';
import {ClauseFragment} from '../types';

@Directive({
  selector: '[carsCurrentClause]',
})
export class CurrentClauseDirective implements OnInit, OnDestroy {
  @Input('carsCurrentClause') public content: ClauseFragment;
  @Input() public showCurrentClause: boolean;

  private _selectedClause: ClauseFragment = null;
  private _isCaratInClause: boolean = false;
  private _isSidebarOpen: boolean = false;
  private _reordering: boolean = false;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _clauseService: ClauseService,
    private _sidebarService: SidebarService,
    private _reorderClausesService: ReorderClausesService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._clauseService.onSelection((clause: ClauseFragment) => {
        this._selectedClause = clause || this._selectedClause;
        this._isCaratInClause = !!clause;
        this._updateIsSelected();
      }),
      this._sidebarService.getSidebarStatus().subscribe((status: SidebarStatus) => {
        this._isSidebarOpen = status !== SidebarStatus.CLOSED;
        this._updateIsSelected();
      }),
      this._reorderClausesService.onReorderingEvent().subscribe((reordering: boolean) => {
        if (reordering) {
          this._selectedClause = null;
          this._isCaratInClause = false;
        }
        this._reordering = reordering;
        this._updateIsSelected();
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Sets the left border to be transparent if not selected, or `$he-blue` if selected.
   *
   * Note we cannot use css variables directly here: $he-blue = $pantone-287 = rgb(0, 83, 155)
   */
  private _updateIsSelected(): void {
    const colour: string = this.showCurrentClause && this._isCurrentClause() ? 'rgb(0, 83, 155)' : 'transparent';
    this._renderer.setStyle(this._elementRef.nativeElement, 'border-left', '2px solid ' + colour);
  }

  /**
   * The clause is selected if it equals the clause service selected clause and either the carat is in the clause
   * or the sidebar has been opened from this clause and it is not reordering.
   */
  private _isCurrentClause(): boolean {
    return (
      !!this.content &&
      this.content.equals(this._selectedClause) &&
      (this._isCaratInClause || this._isSidebarOpen) &&
      !this._reordering
    );
  }
}
