<div
  [ngClass]="{
    'discussion-container-bottom-border': !currentView.isComments()
  }"
>
  <div class="cover" *ngIf="submittingComment || deletingDiscussion || resolvingDiscussion"></div>
  <mat-progress-spinner
    *ngIf="submittingComment || deletingDiscussion || resolvingDiscussion"
    mode="indeterminate"
    aria-label="Saving discussion changes"
  ></mat-progress-spinner>
  <div class="discussion-container" [class.resolved]="discussion.resolved">
    <mat-icon>{{ discussion.resolved ? 'done' : 'comment' }}</mat-icon>

    <div class="content">
      <div>
        <span class="author">
          Raised by
          <cars-user [userId]="discussion.createdBy"></cars-user> </span
        ><br />
        <div class="italics">Raised against: {{ discussionIndex }}</div>
        <div class="italics">Raised on: {{ discussion.createdAt | date: dateFormat }}</div>
        <div *ngIf="!!discussion.capacityOfComment" class="italics">
          In the capacity of: {{ discussion.capacityOfComment | capacityOfCommentToDisplayName }}
        </div>
      </div>
      <div class="type-label">
        <em>{{ discussion.type }}</em>
      </div>
      <button
        #versionRef
        *ngIf="versionTag && versionTag.name"
        class="version-name"
        (mouseenter)="mouseEnterVersionName($event)"
        (mouseleave)="mouseLeaveVersionName()"
        (click)="fetchVersion()"
        [attr.aria-label]="'View version ' + versionTag.name"
      >
        <mat-icon class="version-icon">history</mat-icon>
        <span class="version-text">{{ versionTag.name }}</span>
      </button>
      <div>
        <span class="discussion-body">{{ discussion.issueRaised }}</span>
      </div>

      <div class="suggestion-container" *ngIf="discussion.suggestion">
        <div class="label">{{ suggestContentHasChanged[0] ? 'Original' : 'Current' }} value</div>
        <div class="field-container">
          {{ discussion.suggestion.currentValue }}
        </div>

        <div
          *ngIf="suggestContentHasChanged[0]"
          class="warning"
          [matTooltip]="suggestContentHasChanged[1]"
          matTooltipPosition="left"
          [matTooltipShowDelay]="tooltipDelay"
        >
          <mat-icon class="warning-icon"> error_outline </mat-icon>
          <div class="warning-text">
            <p>
              {{ suggestContentHasChanged[1] }}
            </p>
          </div>
        </div>

        <div class="label">Suggested value</div>
        <div class="field-container">
          {{ discussion.suggestion.suggestedValue }}
        </div>
      </div>
    </div>

    <div class="content" [class.comment]="!comment.resolution" *ngFor="let comment of discussion?.comments">
      <div>
        <span class="author">
          <span *ngIf="comment.resolution">Resolved by </span>
          <cars-user [userId]="comment.createdBy"></cars-user> </span
        ><br />
        <span class="datestamp"
          ><span *ngIf="comment.resolution">Resolved on: </span>{{ comment.createdAt | date: dateFormat }}</span
        >
      </div>
      <div class="discussion-body">{{ comment.content }}</div>
    </div>

    <ng-container *ngIf="!discussion.resolved && !currentView.isComments()">
      <div *ngIf="!creatingComment && !resolving" class="resolve-discussion-button-container">
        <div
          [matTooltip]="
            resolvable()
              ? 'Resolve discussion'
              : 'Discussions can only be resolved if this version of the document is available to comment on and they were raised by you or you are the technical author, lead author, or author'
          "
          matTooltipPosition="before"
          [matTooltipShowDelay]="tooltipDelay"
        >
          <button
            mat-button
            color="primary"
            (click)="onResolve()"
            [disabled]="!resolvable()"
            [attr.aria-label]="
              resolvable()
                ? 'Resolve discussion'
                : 'Discussions can only be resolved if this version of the document is available to comment on and they were raised by you or you are the technical author, lead author, or author'
            "
            carsAnalytics="begin-resolve-discussion-from-sidebar"
          >
            Resolve
          </button>
        </div>
        <div
          [matTooltip]="
            commentable()
              ? 'Comment on discussion'
              : 'Discussions can only be commented on if this version of the document is available to comment on'
          "
          matTooltipPosition="before"
          [matTooltipShowDelay]="tooltipDelay"
        >
          <button
            mat-button
            color="primary"
            (click)="addComment()"
            [disabled]="!commentable()"
            [attr.aria-label]="
              commentable()
                ? 'Comment on discussion'
                : 'Discussions can only be commented on if this version of the document is available to comment on'
            "
          >
            Comment
          </button>
        </div>
        <div
          [matTooltip]="
            deletable()
              ? 'Delete discussion'
              : 'Discussions can only be deleted if this version of the document is available to comment on and they were raised by you and have no comments against them'
          "
          matTooltipPosition="before"
          [matTooltipShowDelay]="tooltipDelay"
        >
          <button
            mat-button
            color="primary"
            (click)="deleteDiscussion()"
            *ngIf="deletable()"
            [attr.aria-label]="
              deletable()
                ? 'Delete discussion'
                : 'Discussions can only be deleted if this version of the document is available to comment on and they were raised by you and have no comments against them'
            "
            carsAnalytics="begin-delete-discussion"
          >
            Delete
          </button>
        </div>
      </div>

      <div *ngIf="creatingComment" class="discussion-input-container">
        <div>
          <mat-form-field>
            <textarea
              [(ngModel)]="newContent"
              rows="5"
              matInput
              autocomplete="off"
              #issue
              [placeholder]="resolving ? 'Resolution' : 'Comment'"
              name="comment"
              carsAutofocus
              [disabled]="submittingComment"
            >
            </textarea>
          </mat-form-field>
        </div>
        <div class="buttons">
          <button
            mat-raised-button
            *ngIf="!resolving"
            color="primary"
            (click)="createComment()"
            [disabled]="!(newContent.length > 0) || submittingComment"
            aria-label="Submit comment"
            carsAnalytics="begin-create-discussion-comment"
          >
            Submit
          </button>

          <ng-container *ngIf="resolving">
            <button
              mat-raised-button
              *ngIf="!discussion.suggestion"
              color="primary"
              (click)="createComment()"
              [disabled]="!(newContent.length > 0) || submittingComment"
              aria-label="Resolve discussion"
              carsAnalytics="resolve-discussion"
            >
              Resolve
            </button>

            <div
              class="button-wrapper"
              [matTooltip]="
                !lockable
                  ? 'Another user is currently working in this clause.'
                  : 'Accept the suggested replacement and resolve the discussion.'
              "
              matTooltipPosition="before"
              [matTooltipShowDelay]="tooltipDelay"
            >
              <button
                mat-raised-button
                *ngIf="discussion.suggestion && canAcceptSuggestedChanges()"
                color="primary"
                (click)="createComment(true)"
                [disabled]="!(newContent.length > 0) || submittingComment || !lockable"
                [attr.aria-label]="
                  !lockable
                    ? 'Another user is currently working in this clause.'
                    : 'Accept the suggested replacement and resolve the discussion.'
                "
                carsAnalytics="resolve-and-accept-changes"
              >
                Resolve and accept
              </button>
            </div>
            <button
              mat-raised-button
              *ngIf="discussion.suggestion"
              color="warn"
              (click)="createComment()"
              [disabled]="!(newContent.length > 0) || submittingComment"
              matTooltip="Reject the suggested replacement and resolve the discussion."
              matTooltipPosition="before"
              [matTooltipShowDelay]="tooltipDelay"
              aria-label="Reject the suggested replacement and resolve the discussion."
              carsAnalytics="resolve-and-reject-changes"
            >
              Resolve and reject
            </button>
          </ng-container>

          <button
            mat-raised-button
            (click)="cancelCreation()"
            [disabled]="submittingComment"
            class="comment-buttons"
            aria-label="Cancel comment creation"
          >
            Cancel
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
