import {ElementRef, Injectable} from '@angular/core';

export enum PadType {
  PUBLISHED_CHANGLOG = 'PUBLISHED_CHANGLOG',
  MAIN_EDITABLE = 'MAIN_EDITABLE',
  NO_PAD = 'NO_PAD', // used for permissions rather than for ElementRef
}

@Injectable({
  providedIn: 'root',
})
export class ElementRefService {
  private _elementList: Map<PadType, ElementRef> = new Map();

  constructor() {}

  public setElementRef(padType: PadType, element: ElementRef): void {
    if (padType === PadType.NO_PAD) {
      throw TypeError('Cannot set the element ref for given PadType');
    }

    this._elementList.set(padType, element);
  }

  public getElementRef(padType: PadType): ElementRef {
    if (padType === PadType.NO_PAD) {
      throw TypeError('Cannot get the element ref for given PadType');
    }

    return this._elementList.get(padType);
  }
}
