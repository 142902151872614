import {EquationFragment, Fragment, FragmentType, ListFragment, ListItemFragment} from 'app/fragment/types';

export class ActiveToolbarFunctions {
  public static readonly functionNameToImplementation: Readonly<Record<string, (...args: any[]) => boolean>> = {
    isInlineEquationActive: ActiveToolbarFunctions.isInlineEquationActive,
    isListActive: ActiveToolbarFunctions.isListActive,
    isSuperscriptActive: ActiveToolbarFunctions.isSuperscriptActive,
    isSubscriptActive: ActiveToolbarFunctions.isSubscriptActive,
    isMemoActive: ActiveToolbarFunctions.isMemoActive,
    isListIncreaseIndentActive: ActiveToolbarFunctions.isListIncreaseIndentActive,
    isListDecreaseIndentActive: ActiveToolbarFunctions.isListDecreaseIndentActive,
  };

  private static isInlineEquationActive(fragment: Fragment): boolean {
    if (!fragment) {
      return false;
    }
    const equation: EquationFragment = fragment.findAncestorWithType(FragmentType.EQUATION) as EquationFragment;
    return equation && equation.inline;
  }

  private static isListActive(fragment: Fragment): boolean {
    return fragment && !!fragment.findAncestorWithType(FragmentType.LIST);
  }

  private static isSuperscriptActive(fragment: Fragment): boolean {
    return fragment && fragment.is(FragmentType.SUPERSCRIPT);
  }

  private static isSubscriptActive(fragment: Fragment): boolean {
    return fragment && fragment.is(FragmentType.SUBSCRIPT);
  }

  private static isMemoActive(fragment: Fragment): boolean {
    return fragment && fragment.is(FragmentType.MEMO);
  }

  private static isListIncreaseIndentActive(fragment: Fragment): boolean {
    return fragment && ActiveToolbarFunctions._isListIndentActive(fragment, true);
  }

  private static isListDecreaseIndentActive(fragment: Fragment): boolean {
    return fragment && ActiveToolbarFunctions._isListIndentActive(fragment, false);
  }

  private static _isListIndentActive(fragment: Fragment, increaseIndent: boolean) {
    const list: ListFragment = fragment.findAncestorWithType(FragmentType.LIST) as ListFragment;
    const listItem: ListItemFragment = fragment.findAncestorWithType(FragmentType.LIST_ITEM) as ListItemFragment;
    return listItem && !list.children[0].id.equals(listItem.id) ? increaseIndent === listItem.indented : false;
  }

  // Protected constructor to prevent external instantiation
  protected constructor() {}
}
