import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ResolvedIconComponent} from './resolved-icon.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  declarations: [ResolvedIconComponent],
  exports: [ResolvedIconComponent],
})
export class ResolvedIconModule {}
