import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ValidationErrorModule} from './validation-error/validation-error.module';
import {ValidationErrorsComponent} from './validation-errors.component';

@NgModule({
  imports: [CommonModule, ValidationErrorModule],
  declarations: [ValidationErrorsComponent],
  exports: [ValidationErrorsComponent],
})
export class ValidationErrorsModule {}
