import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PrintComponent} from './print.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [PrintComponent],
  exports: [PrintComponent],
})
export class PrintModule {}
