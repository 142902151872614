import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MapPipe} from './map.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [MapPipe],
  exports: [MapPipe],
})
export class MapPipeModule {}
