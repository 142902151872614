import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {FragmentIndexModule} from '../fragment/indexing/fragment-index.module';
import {CarsPermissionPipesModule} from '../permissions/pipes/cars-permission-pipes.module';
import {WsrSubjectTopicComponent} from './wsr-subject-topic.component';

@NgModule({
  imports: [
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatTableModule,
    CommonModule,
    FragmentIndexModule,
    FormsModule,
    CarsPermissionPipesModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
  ],
  declarations: [WsrSubjectTopicComponent],
  exports: [WsrSubjectTopicComponent],
})
export class WsrSubjectTopicModule {}
