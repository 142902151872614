import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {BlurOption} from 'app/blur-options';
import {TableFragment} from 'app/fragment/table/table-fragment';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {ClauseGroupType} from 'app/fragment/types/clause-group-type';
import {FragmentService} from 'app/services/fragment.service';
import {ToolbarItem} from 'app/suite-config/configuration.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ClauseFragment, Fragment, FragmentType} from '../../fragment/types';
import {AltProperties} from '../../services/alt-accessibility.service';
import {ClauseService} from '../../services/clause.service';
import {RichTextService, RichTextType} from '../../services/rich-text.service';

@Component({
  selector: 'cars-table-creator',
  templateUrl: './table-creator.component.html',
  styleUrls: ['./table-creator.component.scss', '../toolbar-buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCreatorComponent implements AfterViewInit, OnDestroy {
  private static readonly _MAX_SIZE: number = 10;

  @Input() public toolbarItem: ToolbarItem;
  @Input() public altProperties: AltProperties;

  @ViewChild('createTableTrigger') public tableMenu: MatMenuTrigger;

  public tableCreatorOpen = false;
  public sizeArray: number[] = Array(TableCreatorComponent._MAX_SIZE);
  public tableCreated = false;
  public readonly tooltipDelay = environment.tooltipDelay;

  public enabled: boolean;

  public handler: Function;

  public maxRow: number = 0;
  public maxCol: number = 0;

  public readonly BlurOption: typeof BlurOption = BlurOption;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _clauseService: ClauseService,
    private _fragmentService: FragmentService,
    private _renderer: Renderer2,
    private _richTextService: RichTextService,
    private _cd: ChangeDetectorRef
  ) {}

  /**
   * Initialise this component.
   */
  public ngAfterViewInit(): void {
    this._subscriptions.push(
      this._clauseService.onSelection((clause: ClauseFragment) => {
        this._setToolbarEnabled(clause);
        if (!clause) {
          this.tableMenu.closeMenu();
        }
        this._cd.markForCheck();
      }),
      this._fragmentService.onSelection(
        (fragment: Fragment) => {
          this._setToolbarEnabled(fragment);
          this._cd.markForCheck();
        },
        (frag: Fragment) => this._isTableInSFR(frag)
      )
    );
  }

  /**
   * Destroy the component
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
    if (!!this.handler) {
      this.handler();
    }
  }

  /**
   * Block event propagation when clicking on the RTE to prevent blurring.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  @HostListener('mousedown', ['$event'])
  public mouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  /**
   * Respond to a click on the table creator by issuing a rich text UI event.
   *
   * @returns   {boolean}   False to prevent blurring
   */
  public onClick(): boolean {
    const table: TableFragment = TableFragment.withSize(this.maxRow + 1, this.maxCol + 1);

    this.tableMenu.closeMenu();
    this._richTextService.next(RichTextType.TABLE, table);
    this._cd.markForCheck();

    return false;
  }

  public initialiseKeydownListener(): void {
    this.handler = this._renderer.listen(document, 'keydown.no-cd', (event) => {
      event.preventDefault();
      switch (event.key) {
        case 'ArrowUp': {
          this.maxRow = Math.max(0, this.maxRow - 1);
          break;
        }
        case 'ArrowDown': {
          this.maxRow = Math.min(TableCreatorComponent._MAX_SIZE - 1, this.maxRow + 1);
          break;
        }
        case 'ArrowLeft': {
          this.maxCol = Math.max(0, this.maxCol - 1);
          break;
        }
        case 'ArrowRight': {
          this.maxCol = Math.min(TableCreatorComponent._MAX_SIZE - 1, this.maxCol + 1);
          break;
        }
        case 'Enter': {
          this.onClick();
          break;
        }
        case 'Escape': {
          this.tableMenu.closeMenu();
          break;
        }
      }
      this._cd.markForCheck();
    });
  }

  public handleAltSelect(): void {
    this.tableMenu.openMenu();
    this._cd.markForCheck();
  }

  public updateMaxSelect(row: number, col: number, event?: MouseEvent): void {
    if (!event || (event && !event.buttons)) {
      // If hover
      this.maxRow = row;
      this.maxCol = col;
    }
    this._cd.markForCheck();
  }

  public isHighlighted(row: number, col: number): boolean {
    return row <= this.maxRow && col <= this.maxCol;
  }

  public handleMenuClose(): void {
    this.handler();
    this.maxRow = 0;
    this.maxCol = 0;
    this._cd.markForCheck();
  }

  private _isTableInSFR(fragment: Fragment): boolean {
    const clauseGroup: ClauseGroupFragment = fragment.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;

    const isInTableOrIsClauseType = fragment.is(FragmentType.CLAUSE)
      ? true
      : !!fragment.findAncestorWithType(FragmentType.TABLE);

    return (
      fragment.is(FragmentType.CLAUSE, FragmentType.TABLE_CELL) &&
      isInTableOrIsClauseType &&
      !!clauseGroup &&
      clauseGroup.clauseGroupType === ClauseGroupType.STANDARD_FORMAT_REQUIREMENT
    );
  }

  private _setToolbarEnabled(frag: Fragment): void {
    this.enabled = this.toolbarItem.enabled(frag);
    this.altProperties.condition = this.enabled;
  }
}
