import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  DocumentFragment,
  DocumentInformationFragment,
  DocumentInformationType,
  Fragment,
  FragmentType,
} from 'app/fragment/types';
import {DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-core-document-linkage',
  templateUrl: './core-document-linkage.component.html',
  styleUrls: ['./core-document-linkage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreDocumentLinkageComponent implements OnInit, OnDestroy {
  @Input() document: DocumentFragment;
  public shouldDisplayCoreLink: boolean;
  public isLinkActive = false;
  public coreDocumentId: UUID;
  public coreDocumentTitle: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private _documentService: DocumentService,
    private _fragmentService: FragmentService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.shouldDisplayCoreLink = !!this.document.documentData.administration;
    this.coreDocumentId = this.document.documentData.coreDocument;
    if (this.shouldDisplayCoreLink) {
      this._fetchCoreDocumentData();
      this.subscription.add(
        this._fragmentService.onUpdate(() => {
          this._fetchCoreDocumentData();
        }, this._isDocumentTitle.bind(this))
      );
    }
  }

  private _fetchCoreDocumentData(): void {
    this._documentService.fetch(this.coreDocumentId, {depth: 3}).then((coreDocument) => {
      this.isLinkActive = coreDocument[0].documentData.naaRelationships.enabled;
      this.coreDocumentTitle = coreDocument[0].title;
      this._cdr.markForCheck();
    });
  }

  private _isDocumentTitle(fragment: Fragment): boolean {
    return (
      fragment.documentId.equals(this.document.id) &&
      fragment.is(FragmentType.DOCUMENT_INFORMATION) &&
      (fragment as DocumentInformationFragment).isInformationType(DocumentInformationType.DOCUMENT_TITLE)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
