import {ErrorCode, TriggerType} from 'app/error-handling/services/logger/logger.service';
import {Serialisable} from 'app/utils/serialisable';
import {UUID} from 'app/utils/uuid';
import {VERSION} from 'environments/version';

/**
 * An enumeration of valid log states.
 */
export enum LogState {
  ERROR = 'ERROR',
  ANALYTICS = 'ANALYTICS',
  UNCAUGHT = 'UNCAUGHT',
  NAVIGATION = 'NAVIGATION',
}

/**
 * A class representing a log that will be published to the web service.
 *
 * @field message   {string}   The message from the log
 * @field state     {LogState} The log state
 * @field hash      {number}   The hash of the log message
 * @field createdBy {UUID}     The UUID of user
 * @field createdAt {number}   The timestamp from when the log was created
 */
export class Log implements Serialisable {
  public gitHash: string;

  public static deserialise(json: any): Log {
    const state: LogState = json.state;
    const message: string = json.message ? json.message : '';
    const hash: number = json.hash ? json.hash : -1;
    const createdAt: number = json.createdAt ? json.createdAt : -1;
    const createdBy: UUID = UUID.orNull(json.createdBy);
    const key: string = json.key ? json.key : '';
    const gitHash: string = json.gitHash ? json.gitHash : '';
    const url: string = json.url ? json.url : '';
    const trigger: string = json.trigger;

    const log: Log = new Log(message, state, hash, createdAt, createdBy, key, url, trigger);
    log.gitHash = gitHash;
    log.url = url; // Override the constructor's behaviour - if we deserialise a falsy url, we should respect it.
    return log;
  }

  /**
   * Static helper to create an uncaught error.
   *
   * @param message   {string} The message.
   * @param hash      {number} The hash for the log.
   * @returns         {Log}    The log.
   */
  public static uncaught(message: string, hash: number): Log {
    return new Log(message, LogState.UNCAUGHT, hash, null, null, 'uncaught-error');
  }

  /**
   * Static helper to create an error log instance.
   *
   * @param key     {ErrorCode} The error code.
   * @param message {string}    The message.
   * @returns       {Log}       The log.
   */
  public static error(key: ErrorCode, message: string): Log {
    return new Log(message, LogState.ERROR, null, null, null, key);
  }

  /**
   * Static helper to create an analytics log instance.
   *
   * @param key   {string} The key for the event
   * @returns     {Log}    The log
   */
  public static analytics(key: string, trigger: TriggerType): Log {
    return new Log(null, LogState.ANALYTICS, null, null, null, key, null, trigger);
  }

  /**
   * Static helper to create a navigation log instance.
   *
   * @param url   {string} The url navigated to
   * @returns     {Log}    The log
   */
  public static navigation(url: string): Log {
    return new Log(null, LogState.NAVIGATION, null, null, null, 'navigation', url);
  }

  constructor(
    public message: string,
    public state: LogState,
    public hash: number,
    public createdAt?: number,
    public createdBy?: UUID,
    public key?: string,
    public url?: string,
    public trigger?: string
  ) {
    this.url = url || window.location.href;
    this.gitHash = VERSION.sha;
  }

  /**
   * @inheritdoc
   */
  public serialise(): any {
    return {
      message: this.message,
      state: this.state,
      hash: this.hash,
      key: this.key,
      gitHash: this.gitHash,
      url: this.url,
      trigger: this.trigger,
    };
  }
}
