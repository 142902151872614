import {Logger} from 'app/error-handling/services/logger/logger.service';

/**
 * A class representing a keyboard key state.  Value representations are taken from the standard
 * list at https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values.
 *
 * @field shift {boolean}   True if the shift key is pressed
 * @field ctrl  {boolean}   True if the ctrl key is pressed
 * @field alt   {boolean}   True if the alt key is pressed
 * @field meta  {boolean}   True if the meta key is pressed
 *
 * TODO: This class is starting to have a lot of helper functions.  Consider abstracting this
 *       functionality out into an easily configurable KeyMap class which contains key-by-key
 *       actions to perform.  This would also allow for configurable keymaps, e.g. to support
 *       different user-defined hotkeys or bindings for different browsers (what's our Mac
 *       support like?) or enabling/disabling certain keys when some conditions are met.
 */
export class Key {
  // Static instances for commonly used keys
  public static readonly SPACE: Key = new Key([' ', 'Spacebar']);
  public static readonly ENTER: Key = new Key('Enter');
  public static readonly TAB: Key = new Key('Tab');
  public static readonly BACKSPACE: Key = new Key('Backspace');
  public static readonly DELETE: Key = new Key(['Delete', 'Del']);
  public static readonly ESCAPE: Key = new Key(['Escape', 'Esc']);
  public static readonly LEFT: Key = new Key(['ArrowLeft', 'Left']);
  public static readonly RIGHT: Key = new Key(['ArrowRight', 'Right']);
  public static readonly UP: Key = new Key(['ArrowUp', 'Up']);
  public static readonly DOWN: Key = new Key(['ArrowDown', 'Down']);
  public static readonly HOME: Key = new Key('Home');
  public static readonly END: Key = new Key('End');

  // Name to printable character pairings.
  private static readonly PRINTABLE_MAP = new Map([
    ['Divide', '/'],
    ['Multiply', '*'],
    ['Add', '+'],
    ['Subtract', '-'],
    ['Spacebar', ' '],
  ]);

  protected _values: string[];
  public shift: boolean;
  public ctrl: boolean;
  public alt: boolean;
  public meta: boolean;

  /**
   * Create a Key instance from a KeyboardEvent.
   *
   * @param event {KeyboardEvent}   The event
   * @returns     {Key}             The created Key
   */
  public static fromEvent(event: KeyboardEvent): Key {
    if (!event.key) {
      Logger.error('input-error', 'keyboard event had null key field');
    }
    return new Key(event.key, event.shiftKey, event.ctrlKey, event.altKey, event.metaKey);
  }

  constructor(
    values: string | string[],
    shift: boolean = false,
    ctrl: boolean = false,
    alt: boolean = false,
    meta: boolean = false
  ) {
    if (!values) {
      values = [''];
    } else if (!(values instanceof Array)) {
      values = [values];
    }

    this._values = values;
    this.shift = shift;
    this.ctrl = ctrl;
    this.alt = alt;
    this.meta = meta;
  }

  /**
   * Getter for the first key value.
   *
   * @returns {string}   The key value
   */
  public get value(): string {
    return this._asPrintable(this._values[0]);
  }

  /**
   * Test for equality with another key, disregarding any modifier keys.
   *
   * @param rhs {Key}       The key to test against
   * @returns   {boolean}   True if equal
   */
  public equalsUnmodified(rhs: Key): boolean {
    const lower: string[] = this._values.map((value: string) => value.toLowerCase());
    const intersection: string[] = rhs._values
      .map((value: string) => value.toLowerCase())
      .filter((value: string) => lower.indexOf(value.toLowerCase()) >= 0);

    return intersection.length > 0;
  }

  /**
   * Test for equality with another key, including modifiers.
   *
   * @param rhs {Key}       The key to test against
   * @returns   {boolean}   True if equal
   */
  public equals(rhs: Key): boolean {
    return (
      this.equalsUnmodified(rhs) &&
      (typeof this.shift !== 'boolean' || this.shift === rhs.shift) &&
      (typeof this.ctrl !== 'boolean' || this.ctrl === rhs.ctrl) &&
      (typeof this.alt !== 'boolean' || this.alt === rhs.alt) &&
      (typeof this.meta !== 'boolean' || this.meta === rhs.meta)
    );
  }

  /**
   * Returns true if this key represents a navigation key, e.g. the arrow keys.
   *
   * @returns {boolean}   True if a navigation key
   */
  public isNavigation(): boolean {
    const navKey: Key = new Key([
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'End',
      'Home',
      'Down',
      'Left',
      'Right',
      'Up',
    ]); // IE special cases

    return this.equalsUnmodified(navKey);
  }

  public isPageUp(): boolean {
    return this.equalsUnmodified(new Key('PageUp'));
  }

  public isPageDown(): boolean {
    return this.equalsUnmodified(new Key('PageDown'));
  }

  /**
   * Returns true if this key is a modifier key, such as Ctrl, Shift or Alt.
   *
   * @returns {boolean}   True if a modifier
   */
  public isModifier(): boolean {
    const modifiers: Key = new Key([
      'Alt',
      'AltGraph',
      'CapsLock',
      'Control',
      'Fn',
      'FnLock',
      'Hyper',
      'Meta',
      'NumLock',
      'ScrollLock',
      'Shift',
      'Super',
      'Symbol',
      'SymbolLock',
    ]);

    return this.equalsUnmodified(modifiers);
  }

  /**
   * Returns true if the key either is printable, is a space, is backspace, or is delete.
   *
   * @returns {boolean}   True if an operation
   */
  public isOperation(): boolean {
    return (
      this.isPrintable() ||
      this.equalsUnmodified(Key.SPACE) ||
      this.equalsUnmodified(Key.BACKSPACE) ||
      this.equalsUnmodified(Key.DELETE)
    );
  }

  /**
   * Returns true if this key represents a printable character.  Note this only supports the printable
   * ISO8859-1 (Latin-1) character set; see http://cs.stanford.edu/people/miles/iso8859.html.
   *
   * @returns {boolean}   True if printable
   */
  public isPrintable(): boolean {
    const matches: string[] = this._values.map(this._asPrintable).filter((value: string) => {
      const code: number = this.value.charCodeAt(0);
      return (
        value.length === 1 && // Consists of exactly one glyph
        ((code >= 0x20 && code <= 0x7e) || // Printable ASCII characters
          (code >= 0xa1 && code <= 0xff))
      ); // Extra printable Latin-1 characters
    });

    return matches.length > 0;
  }

  /**
   * Returns true if this key represents a function key.
   *
   * @returns {boolean}   True if a function key
   */
  public isFunction(): boolean {
    const fnKey: Key = new Key([
      'F1',
      'F2',
      'F3',
      'F4',
      'F5',
      'F6',
      'F7',
      'F8',
      'F9',
      'F10',
      'F11',
      'F12',
      'F13',
      'F14',
      'F15',
      'F16',
      'F17',
      'F18',
      'F19',
      'F20',
      'Soft1',
      'Soft2',
      'Soft3',
      'Soft4',
    ]);

    return this.equalsUnmodified(fnKey);
  }

  /**
   * Returns true if this key is bound to a developer tool within browser dev tools.
   *
   * @returns {boolean}   True if a dev key
   */
  public isDev(): boolean {
    return (
      this.equals(new Key('r', false, true, false)) || this.equals(new Key('c', true, true, false)) // Refresh
    ); // Select DOM node
  }

  /**
   * Returns true if the key is a cut / copy / paste keyboard shortcut.
   *
   * @returns {boolean}   True if a shortcut
   */
  public isClipboard(): boolean {
    return this.equals(new Key(['v', 'c', 'x'], false, true, false));
  }

  /**
   * Returns true if this key is a browser shortcut key, such as 'new window'.
   *
   * @returns {boolean}   True if a shortcut
   */
  public isShortcut(): boolean {
    const ctrlKeys: Key = new Key(
      [
        'a',
        /* Select all    */ 'l',
        /* Focus address bar */ 'n' /* New window */,
        'o',
        /* Open file     */ 'p',
        /* Print page        */ 't' /* New tab    */,
        'w',
        /* Close tab     */ '-',
        /* Zoom out          */ '=' /* Zoom in    */,
        '0',
        /* Reset zoom    */ 'f' /* Find in page      */,
      ],
      false,
      true,
      false
    );
    const shiftCtrlKeys: Key = new Key(['n', /* New incognito */ 't' /* Reopen tab        */], true, true, false);

    return this.isClipboard() || this.equals(ctrlKeys) || this.equals(shiftCtrlKeys);
  }

  /**
   * Returns true if the key is a disabled native shortcut key.
   *
   * @returns {boolean}   True if disabled
   */
  public isDisabled(): boolean {
    const disabled: Key = new Key(['b', 'i', 'u'], false, true, false);

    return this.equals(disabled);
  }

  /**
   * Returns true is the key is ctrl-a.
   */
  public isSelection(): boolean {
    return this.equals(new Key('a', false, true));
  }

  public isSearchDocument(): boolean {
    return this.equals(new Key('f', true, true));
  }

  /**
   * Returns the printable form of the value given.
   *
   * @param value {string} The value to convert
   */
  private _asPrintable(value: string): string {
    return Key.PRINTABLE_MAP.has(value) ? Key.PRINTABLE_MAP.get(value) : value;
  }
}
