<div class="options-panel">
  <cars-alt-accessibility
    [altProperties]="{initialKey: 'r', key: 't', condition: true}"
    (onSelect)="focusTimeDropdown()"
  ></cars-alt-accessibility>
  <mat-form-field floatLabel="always">
    <mat-select
      id="history-time-dropdown"
      matTooltip="Choose how much history to view"
      matTooltipPosition="below"
      [matTooltipShowDelay]="tooltipDelay"
      [(ngModel)]="currentOption"
      (selectionChange)="refresh()"
      disableRipple
      placeholder="View history from: "
      aria-label="Choose how much history to view"
    >
      <mat-option *ngFor="let option of historyOptions" [value]="option.params">
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox [(ngModel)]="showDetails" (change)="refresh()">Show detailed changes</mat-checkbox>
</div>

<mat-progress-spinner
  *ngIf="loading; else history"
  mode="indeterminate"
  [diameter]="40"
  aria-label="Loading History"
></mat-progress-spinner>

<ng-template #history>
  <div *ngFor="let entry of historyEntries; let i = index">
    <cars-transition
      *ngIf="entry.transition"
      [transition]="entry.transition"
      [dateFormat]="dateFormat"
    ></cars-transition>
    <cars-version
      *ngIf="entry.version"
      [version]="entry.version"
      [previousVersion]="previousVersion(i)"
      [dateFormat]="dateFormat"
    ></cars-version>
  </div>
</ng-template>
