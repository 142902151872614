<div *ngIf="canUpdateVersionTag && versionTagToUpdate" class="version-settings-box">
  <p><mat-icon>info</mat-icon><span class="version-settings-title">Version settings</span></p>
  <div>
    <mat-checkbox
      [(ngModel)]="versionTagToUpdate.availableToReview"
      (ngModelChange)="changeAvailableToReview(versionTagToUpdate)"
    >
      Available to reviewers
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox
      [(ngModel)]="versionTagToUpdate.availableForCommenting"
      (ngModelChange)="changeAvailableForCommenting(versionTagToUpdate)"
    >
      Allow discussions and comments
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox
      color="warn"
      [(ngModel)]="versionTagToUpdate.userDiscussionsOnly"
      (ngModelChange)="changeUserDiscussionsOnly(versionTagToUpdate)"
    >
      Forbid reviewers from seeing and commenting on others' discussions
    </mat-checkbox>
  </div>
</div>
