import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {ActionRequest} from 'app/fragment/action-request';
import {Caret} from 'app/fragment/caret';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {getFragmentIfEditable} from 'app/fragment/fragment-utils';
import {Key} from 'app/fragment/key';
import {ClauseFragment, Fragment, FragmentType} from 'app/fragment/types';
import {ReadonlyFragment} from 'app/fragment/types/readonly-fragment';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {FragmentService} from 'app/services/fragment.service';
import {RichTextType} from 'app/services/rich-text.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-readonly-fragment',
  templateUrl: './readonly-fragment.component.html',
  styleUrls: ['./readonly-fragment.component.scss', '../../../core/fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyFragmentComponent extends FragmentComponent implements OnInit, OnDestroy {
  @Input() public set content(value: ReadonlyFragment) {
    super.content = value;
  }

  public get content(): ReadonlyFragment {
    return super.content as ReadonlyFragment;
  }

  private _subscriptions: Subscription[] = [];

  constructor(
    private _selectionOperationsService: SelectionOperationsService,
    private _fragmentService: FragmentService,
    protected _cdr: ChangeDetectorRef,
    protected elementRef: ElementRef
  ) {
    super(_cdr, elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit(); // IMPORTANT
    this._subscriptions.push(
      this._fragmentService.onSelection(
        (fragment: Fragment) => {
          if (!!fragment) {
            this._onSelect();
          }
        },
        (fragment: Fragment) => !!fragment && fragment.equals(this.content)
      )
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy(); // IMPORTANT
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * This finds the next editable leaf in the containing clause. If one doesn't exist, it finds the
   * previous editable leaf in the containing clause.
   * If an editable leaf is found in either direction, it selects the found fragment, else it does nothing.
   */
  private _onSelect(): void {
    const clause: ClauseFragment = this.content.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const padType: PadType = clause?.component?.padType;

    if (!clause || !padType) {
      return;
    }

    let previous: boolean = false;
    let leaf: Fragment = this._findNearestEditableLeafInClause(clause, previous);

    if (!leaf) {
      previous = true;
      leaf = this._findNearestEditableLeafInClause(clause, previous);
    }

    if (leaf) {
      this._selectionOperationsService.setSelected(leaf, previous ? leaf.length() : 0, padType);
    }
  }

  private _findNearestEditableLeafInClause(clause: ClauseFragment, previous: boolean): Fragment {
    let leaf: Fragment = previous ? this.content.previousLeaf() : this.content.nextLeaf();

    while (leaf) {
      if (!clause.equals(leaf.findAncestorWithType(FragmentType.CLAUSE))) {
        return null;
      }

      const editableFragment: Fragment = getFragmentIfEditable(leaf);

      if (!!editableFragment) {
        return editableFragment;
      } else {
        leaf = previous ? leaf.previousLeaf() : leaf.nextLeaf();
      }
    }

    return null;
  }

  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    return new ActionRequest();
  }

  public onRichText(type: RichTextType, start: Caret, end: Caret, ...args: any[]): ActionRequest {
    return new ActionRequest();
  }
}
