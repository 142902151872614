import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {InternalReferenceType} from 'app/fragment/types';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {TargetDocumentType} from 'app/fragment/types/reference/target-document-type';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root',
})
export class InternalReferenceService extends BaseService {
  /**
   * The endpoint for refreshing the internal references of a document.
   */
  private static readonly UPDATE_INTERNAL_REFERENCES_URL: string = environment.apiHost + '/internal-references/update/';

  /**
   * The endpoint for getting any reference updates
   */
  private static readonly INTERNAL_REFERENCE_GET_UPDATES_URL: string =
    environment.apiHost + '/internal-references/get-to-update/';

  /**
   * The endpoint for getting all the available internal document references for a document.
   */
  private static readonly INTERNAL_REFERENCE_GET_TO_CREATE_URL: string =
    environment.apiHost + '/internal-references/get-to-create/';

  constructor(private _http: HttpClient, protected _snackbar: MatSnackBar) {
    super(_snackbar);
  }

  /**
   * Calls the endpoint on the server to refresh the internal references for the given document.
   *
   * @param documentId The document id to refresh.
   */
  public refreshDocumentReferences(documentId: UUID): Promise<boolean> {
    const params: {[param: string]: string | string[]} = {
      internalReferenceTypes: Object.values(InternalReferenceType),
    };

    return this._http
      .patch(
        InternalReferenceService.UPDATE_INTERNAL_REFERENCES_URL + documentId.value,
        {},
        {
          params: params,
          observe: 'response',
        }
      )
      .pipe(
        map(() => {
          this._openSnackbar();
          return true;
        }),
        catchError((error: any) => {
          this._handleError(error, 'Failed to refresh section references for document', 'internal-reference-error');
          return Promise.resolve(false);
        })
      )
      .toPromise();
  }

  /**
   * Returns a Record of InternalDocumentReferenceFragments which are currently
   * out of date compared to the most recent published versions of their target sections
   * (or live version if no version is published), grouped by targetDocumentId.
   *
   * @param documentId the UUID of the document to retrieve internalDocumentReferences from.
   * @returns arrays of InternalDocumentReferences that need updating, grouped by targetDocumentId.
   */
  public getReferencesToUpdateGroupedByTargetDocument(
    documentId: UUID,
    internalReferenceTypes: InternalReferenceType[]
  ): Promise<Record<string, InternalDocumentReferenceFragment[]>> {
    return this._getReferencesToUpdate(documentId, internalReferenceTypes).then(
      (references: InternalDocumentReferenceFragment[]) => {
        const referenceMap: Record<string, InternalDocumentReferenceFragment[]> = {};
        references.forEach((ref: InternalDocumentReferenceFragment) => {
          const targetDocId: string = ref.targetDocumentId.value;
          if (!referenceMap[targetDocId]) {
            referenceMap[targetDocId] = [];
          }
          referenceMap[targetDocId].push(ref);
        });
        return referenceMap;
      }
    );
  }

  /**
   * Returns an array of InternalDocumentReferenceFragments, corresponding to each of the sections of the given
   * document that can be referenced internally.
   *
   * @param targetDocumentId the UUID of the document to retrieve internalDocumentReferences from.
   * @param currentDocumentId the UUID of the current document.
   * @param internalReferenceType the section reference type of the internalDocumentReferences retrieved.
   * @returns array of InternalDocumentReferences
   */
  public getReferencesToCreate(
    targetDocumentId: UUID,
    currentDocumentId: UUID,
    internalReferenceType: InternalReferenceType
  ): Promise<InternalDocumentReferenceFragment[]> {
    const referenceType: TargetDocumentType = targetDocumentId.equals(currentDocumentId)
      ? TargetDocumentType.SAME_DOCUMENT
      : TargetDocumentType.DIFFERENT_DOCUMENT;

    return this._http
      .get(InternalReferenceService.INTERNAL_REFERENCE_GET_TO_CREATE_URL + targetDocumentId.value, {
        params: {referenceType: referenceType, internalReferenceType: internalReferenceType},
      })
      .pipe(
        map((json: any[]) => json.map((j: any) => FragmentMapper.deserialise(j) as InternalDocumentReferenceFragment)),
        catchError((error: any) => {
          this._handleError(error, 'Failed to fetch references available to create', 'internal-reference-error');
          return Promise.reject(error);
        })
      )
      .toPromise();
  }

  private _getReferencesToUpdate(
    documentId: UUID,
    internalReferenceTypes: InternalReferenceType[]
  ): Promise<InternalDocumentReferenceFragment[]> {
    const params: {[param: string]: string | string[]} = {
      internalReferenceTypes: internalReferenceTypes,
    };

    return this._http
      .get(InternalReferenceService.INTERNAL_REFERENCE_GET_UPDATES_URL + documentId.value, {params: params})
      .pipe(
        map((json: any[]) => json.map((j: any) => FragmentMapper.deserialise(j) as InternalDocumentReferenceFragment)),
        catchError((error: any) => {
          this._handleError(error, 'Failed to fetch references to update', 'internal-reference-error');
          return Promise.reject(error);
        })
      )
      .toPromise();
  }

  /**
   * Opens a snackbar informing the user that their request to document's internal references has been succesful.
   */
  private _openSnackbar(): void {
    this._snackbar.open('Section references have been succesfully updated', 'Dismiss', {
      duration: 5000,
    });
  }
}
