import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DocumentFragment} from 'app/fragment/types';

@Component({
  selector: 'cars-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationComponent implements OnInit {
  @Input() document: DocumentFragment;
  public shouldDisplay: boolean;

  constructor() {}

  public ngOnInit(): void {
    this.shouldDisplay = !!this.document.documentData.administration;
  }
}
