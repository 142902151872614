import {Component, OnDestroy} from '@angular/core';
import {GlobalRole} from 'app/services/user/authentication-provider';
import {RoleService} from 'app/services/user/role.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnDestroy {
  private _subscriptions: Subscription[] = [];

  public canViewPublished: boolean = false;

  constructor(private roleService: RoleService) {
    this.canViewPublished = this.roleService.isInGlobalRoles(GlobalRole.VIEW_PUBLISHED);
  }

  ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }
}
