<ng-container *ngIf="row">
  <span *ngIf="!clause; else clauseBlock">{{ stateDisplayString }}</span>
  <ng-template #clauseBlock>
    <cars-clause
      [content]="clause"
      [readOnly]="true"
      [showCurrentClause]="false"
      [showMarginIcons]="false"
    ></cars-clause>
  </ng-template>
</ng-container>
