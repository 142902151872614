<div class="version-information-box">
  <p><mat-icon>info</mat-icon><span class="version-information-title">Version information</span></p>

  <div class="version-information">
    <span class="label">Name:</span>
    <span class="value">
      <strong>{{ versionTag?.name }}</strong>
    </span>
  </div>

  <div class="version-information">
    <span class="label">Type:</span>
    <span class="value">
      <strong>{{ versionTag?.versionTagType | versionTagTypeDisplayName }}</strong>
    </span>
  </div>

  <div class="version-information">
    <span class="label">Number:</span>
    <span class="value">
      <strong>{{ versionTag?.versionNumber }}</strong>
    </span>
  </div>

  <div class="version-information">
    <span class="label">Created:</span>
    <span class="value">
      <strong>{{ versionTag?.createdAt | date: dateFormat }}</strong>
    </span>
  </div>

  <div class="version-information" *ngIf="versionTag?.versionTagType === VersionTagType.FOR_PUBLICATION_PUBLISHED">
    <span class="label">Published:</span>
    <span class="value">
      <strong>{{ versionTag?.dateOfPublication | date: dateFormat }}</strong>
    </span>
  </div>

  <p *ngFor="let info of information" class="version-information">{{ info }}</p>
</div>
