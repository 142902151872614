import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {StandardFormatGroupExampleModule} from './standard-format-group-example/standard-format-group-example.module';
import {StandardFormatGroupListOptionsModule} from './standard-format-group-list-options/standard-format-group-list-options.module';
import {StandardFormatGroupModalComponent} from './standard-format-group-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    StandardFormatGroupListOptionsModule,
    StandardFormatGroupExampleModule,
  ],
  declarations: [StandardFormatGroupModalComponent],
  exports: [StandardFormatGroupModalComponent],
})
export class StandardFormatGroupModalModule {}
