import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {Administration} from 'app/documents/administrations';
import {
  DocumentFragment,
  DocumentInformationFragment,
  DocumentInformationType,
  Fragment,
  FragmentType,
} from 'app/fragment/types';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {NaaDocumentLinkageService} from './naa-document-linkage.service';

@Component({
  selector: 'cars-naa-document-linkage',
  templateUrl: './naa-document-linkage.component.html',
  styleUrls: ['./naa-document-linkage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NaaDocumentLinkageComponent implements OnInit, OnDestroy {
  @Input() document: DocumentFragment;
  @Input() disabled: boolean;

  public requiresNationalApplicationAnnexes: boolean = false;
  public doesDocumentHaveAssociatedAnnexes: boolean = false;
  public creatingNaas: boolean = false;
  public canToggleNaas: boolean = false;

  private _subscriptions: Subscription[] = [];
  private _areAssociatedAnnexesLinked: boolean = false;

  constructor(
    private _documentLinkageService: NaaDocumentLinkageService,
    private _permissionService: PermissionsService,
    private _documentService: DocumentService,
    private _fragmentService: FragmentService,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._setLinkageBooleans();
    this.requiresNationalApplicationAnnexes =
      this.doesDocumentHaveAssociatedAnnexes && this._areAssociatedAnnexesLinked;

    this._subscriptions.push(
      this._fragmentService.onUpdate(() => {
        this._cdr.markForCheck();
      }, this._isDocumentTitle.bind(this)),
      this._documentService.onUpdate(() => {
        this.creatingNaas = false;
        this._setLinkageBooleans();
        this._cdr.markForCheck();
      }),
      this._permissionService
        .can(CarsAction.CAN_TOGGLE_NAAS, this.document.documentId)
        .subscribe((canToggle: boolean) => {
          this.canToggleNaas = canToggle;
          this._cdr.markForCheck();
        })
    );
  }

  private _isDocumentTitle(fragment: Fragment): boolean {
    return (
      fragment.documentId.equals(this.document.id) &&
      fragment.is(FragmentType.DOCUMENT_INFORMATION) &&
      (fragment as DocumentInformationFragment).isInformationType(DocumentInformationType.DOCUMENT_TITLE)
    );
  }

  /**
   * Function to be called on event which mutate the contents of naaRelationship object,
   * this way we actively manage when calculations are performed instead of constantly re-checking in the DOM
   */
  private _setLinkageBooleans(): void {
    this.doesDocumentHaveAssociatedAnnexes = this.document.documentData.naaRelationships.linkedDocuments.size > 0;
    this._areAssociatedAnnexesLinked = this.document.documentData.naaRelationships?.enabled;
    this._cdr.markForCheck();
  }

  /**
   * Nice accessor method for referencing from DOM.
   * gets the associated Naas on a the input document fragment if it exists.
   *
   * @returns Linked documents if they exist
   */
  public fetchCoreDocumentNaaData(): Map<Administration, UUID> {
    if (!this.doesDocumentHaveAssociatedAnnexes) {
      return null;
    }

    return this.document.documentData.naaRelationships.linkedDocuments;
  }

  /**
   * Handles checkbox event indicating if a document does have associated NAAs
   */
  // TODO: Permissions
  public onNaaCheckboxChange(): void {
    const isLinked = this.requiresNationalApplicationAnnexes && this.doesDocumentHaveAssociatedAnnexes;
    this.document.documentData.naaRelationships.enabled = isLinked;
    if (this.doesDocumentHaveAssociatedAnnexes) {
      this._documentService.update(this.document);
    }
  }

  /**
   * If a document has an administration it is na NAA
   *
   * @returns if a document is core or an annex
   */
  public isDocumentNaa(): boolean {
    return !!this.document.documentData.administration;
  }

  /**
   * The Generate NAAs button should only be shown if there are no linkedDocuments and isEnabled property
   *
   * @returns if the Generate NAAs button should be shown
   */
  public shouldShowGenerateNaasButton(): boolean {
    return !this.isDocumentNaa() && !this.doesDocumentHaveAssociatedAnnexes && !this.disabled;
  }

  public setNaaTitle(administrationToDisplayName: string): string {
    return `${administrationToDisplayName} National Application Annex For ${this.document.title}`;
  }

  /**
   * Call off too service creating NAAs
   */
  private _createNaas(): void {
    this.creatingNaas = true;
    this._cdr.markForCheck();
    this._documentLinkageService
      .createNationApplicationAnnexesForCoreDocumentId(this.document.documentId)
      .catch(() => (this.creatingNaas = false));
  }

  /**
   * Handle click event from "create NAAs" button, make user confirm choice first,
   * then after a confirmation create NAAs or a cancelled, then deflate modal and do nothing.
   */
  public onCreateNaas(): void {
    this._dialog
      .open(DialogComponent, {
        data: {
          title: 'Create National Application Annexes (NAAs)',
          message: 'Are you sure you wish to create NAAs for this core document? You cannot undo this action.',
          closeActions: [
            {title: 'Cancel', response: null},
            {title: 'Create National Application Annexes', response: 'create', color: 'primary'},
          ],
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._createNaas();
        }
      });
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
