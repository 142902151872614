/**
 * An enum of all possible types for a SpecifierInstruction clause. Note that values marked as Deprecated will not be
 * returned as part of the specifier-instruction/specifier-instruction-creation-config config, as these have been
 * deleted/updated. Note these values cannot be removed from the enum otherwise we would not be able to view versions
 * of documents that contain any of these types.
 */
export enum SpecifierInstructionType {
  CASE_1A = 'CASE_1A', // Deprecated - updated to CASE_1A_V2
  CASE_1A_V2 = 'CASE_1A_V2',
  CASE_1B = 'CASE_1B', // Deprecated - updated to CASE_1B_V2
  CASE_1B_V2 = 'CASE_1B_V2',
  CASE_1C = 'CASE_1C',
  CASE_2 = 'CASE_2', // Deprecated - updated to CASE_2_V2
  CASE_2_V2 = 'CASE_2_V2',
  CASE_3A = 'CASE_3A', // Deprecated - updated to CASE_3A_V2
  CASE_3A_V2 = 'CASE_3A_V2',
  CASE_3B = 'CASE_3B', // Deprecated - updated to CASE_3B_V2
  CASE_3B_V2 = 'CASE_3B_V2',
  CASE_3C = 'CASE_3C',
  CASE_3D = 'CASE_3D',
  CASE_4A = 'CASE_4A', // Deprecated - updated to CASE_4A_V2
  CASE_4A_V2 = 'CASE_4A_V2',
  CASE_4B = 'CASE_4B', // Deprecated - updated to CASE_4B_V2
  CASE_4B_V2 = 'CASE_4B_V2',
  CASE_5 = 'CASE_5', // Deprecated
  CASE_5A = 'CASE_5A',
  CASE_5B = 'CASE_5B',
  CASE_6A = 'CASE_6A',
  CASE_6B = 'CASE_6B',
  CASE_6C = 'CASE_6C', // Deprecated - updated to CASE_6C_V2 (using UNIT_INPUT fragment)
  CASE_6C_V2 = 'CASE_6C_V2',
  CASE_6D = 'CASE_6D',
  CASE_6E = 'CASE_6E',
  CASE_6F = 'CASE_6F',
  CASE_6G = 'CASE_6G',
  CASE_6H = 'CASE_6H',
  CASE_6I = 'CASE_6I',
  CASE_6J = 'CASE_6J',
  CASE_6K = 'CASE_6K',
  CASE_6L = 'CASE_6L',
  CASE_6M = 'CASE_6M',
  CASE_6N = 'CASE_6N',
  CASE_6O = 'CASE_6O',
  CASE_6P = 'CASE_6P',
  UNITS_OF_MEASUREMENT = 'UNITS_OF_MEASUREMENT',
}

/**
 * A set of all non-deprecated field definition SI types. These have restricted placement and can only be used within
 * WSR schedule SFRs.
 */
export const FieldDefinitionSpecifierInstructionTypes: ReadonlySet<SpecifierInstructionType> = new Set([
  SpecifierInstructionType.CASE_6A,
  SpecifierInstructionType.CASE_6B,
  SpecifierInstructionType.CASE_6C_V2,
  SpecifierInstructionType.CASE_6D,
  SpecifierInstructionType.CASE_6E,
  SpecifierInstructionType.CASE_6F,
  SpecifierInstructionType.CASE_6G,
  SpecifierInstructionType.CASE_6H,
  SpecifierInstructionType.CASE_6I,
  SpecifierInstructionType.CASE_6J,
  SpecifierInstructionType.CASE_6K,
  SpecifierInstructionType.CASE_6L,
  SpecifierInstructionType.CASE_6M,
  SpecifierInstructionType.CASE_6N,
  SpecifierInstructionType.CASE_6O,
  SpecifierInstructionType.CASE_6P,
]);

/**
 * A list of non-deprecated SI types that are discouraged for being too generic ("Enter <free text> for <reason>")
 */
export const DiscouragedSpecifierInstructionTypes: ReadonlySet<SpecifierInstructionType> = new Set([
  SpecifierInstructionType.CASE_2_V2,
]);
