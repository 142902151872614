import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ClauseFragment, DocumentInformationFragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {UserService} from 'app/services/user/user.service';
import {DocumentInformationItem} from 'app/suite-config/configuration.service';
import {BaseFormComponent} from '../base-form.component';

@Component({
  selector: 'cars-free-text-input',
  templateUrl: './free-text-input.component.html',
  styleUrls: ['./free-text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeTextInputComponent extends BaseFormComponent implements OnInit, OnChanges {
  @Input() public set documentInformationItem(documentInformationItem: DocumentInformationItem) {
    this._clause = documentInformationItem.documentInformationFragment.parent as ClauseFragment;
    this.documentInformationType = documentInformationItem.documentInformationField.documentInformationType;
    this.titleText = documentInformationItem.documentInformationField.titleText;
    this.hintText = documentInformationItem.documentInformationField.hintText;
    this.pattern = documentInformationItem.documentInformationField.pattern
      ? new RegExp(documentInformationItem.documentInformationField.pattern)
      : null;
    this.patternErrorText = documentInformationItem.documentInformationField.patternErrorText;
  }

  constructor(
    protected fragmentService: FragmentService,
    protected lockService: LockService,
    protected userService: UserService,
    protected versioningService: VersioningService,
    protected cdr: ChangeDetectorRef
  ) {
    super(fragmentService, lockService, userService, versioningService, cdr);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    // Form errors get cleared upon initialsing.
    // Adding setTimeout to ensure on page load we are correctly validating free-text fields
    setTimeout(() => {
      if (this.value) {
        this._validate();
      }
    });
  }

  protected _patchValue(fragment: DocumentInformationFragment, emitEvent: boolean = false): void {
    super._patchValue(fragment, emitEvent);
    this._validate();
  }

  public onBlur(): void {
    super.unlock();
    this._validate();
  }

  private _validate(): void {
    if (!this.pattern) {
      return;
    }
    const fieldValue: string = String(this.formGroup.get(this.documentInformationType).value);

    if (this.pattern && !fieldValue.match(this.pattern)) {
      this.formGroup.get(this.documentInformationType).setErrors({
        invalidNumber: true,
      });
    } else {
      // reset errors
      this.formGroup.get(this.documentInformationType).setErrors(null);
    }

    // Adding markAsTouched to trigger errors to show even if not triggered by user input
    this.markAsTouched();
  }
}
