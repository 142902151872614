import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {ClauseFragment, ClauseType, Fragment, FragmentType, SectionFragment} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {FragmentService} from 'app/services/fragment.service';
import {NavigationService, NavigationTypes, SectionNavigationEvent} from 'app/services/navigation.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {CurrentView, ViewMode} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Lock} from '../../../../fragment/lock/lock';
import {ClauseService} from '../../../../services/clause.service';
import {LockService} from '../../../../services/lock.service';
import {SectionService} from '../../../../services/section.service';

interface Heading {
  clause: ClauseFragment;
  text: string;
  type: ClauseType;
  class: string;
}

@Component({
  selector: 'cars-section-list-item',
  templateUrl: './section-list-item.component.html',
  styleUrls: ['./section-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionListItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public section: SectionFragment;
  @Input() public currentView: CurrentView = null;
  @Input() public type: NavigationTypes = NavigationTypes.DOCUMENT;
  @Input() public set dragging(dragging: boolean) {
    if (!dragging) {
      // This is triggered by a mouseUp event, but needs to reset after the click event in this class
      setTimeout(() => {
        this._draggingSection = false;
      });
    }
  }

  @Output() public onDraggingEvent: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('title', {static: true}) private title: ElementRef;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public editing: boolean = false;
  public headings: Heading[] = [];
  public selected: boolean = false;
  public expanded: boolean = false;
  public loading: boolean = false;
  public reorderingClauses: boolean;
  public draggingValidClauses: boolean;

  private _ignoreDefaultExpandedStatus: boolean = false;
  private _subscriptions: Subscription[] = [];
  private _useViewService: boolean = true;
  private _draggingClauses: boolean;
  private _draggingSection: boolean;

  private _sectionTitle: string;

  constructor(
    private _snackBar: MatSnackBar,
    private _sectionService: SectionService,
    private _clauseService: ClauseService,
    private _fragmentService: FragmentService,
    private _lockService: LockService,
    private _viewService: ViewService,
    private _navigationService: NavigationService,
    private _reorderClausesService: ReorderClausesService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._lockService.onChange((lock: Lock) => {
        const clause: ClauseFragment = this._clauseService.find(lock.clauseId);
        const section: SectionFragment = clause?.getSection();
        if (!section || section.equals(this.section)) {
          this.getHeadings();
          this._cdr.markForCheck();
        }
      }),
      this._sectionService.onSelection((newlySelectedSection: SectionFragment) => {
        if (newlySelectedSection && newlySelectedSection.equals(this.section)) {
          this.getHeadings();
          this._setSelectedAndExpanded(true);
        } else if (!newlySelectedSection || this.type === NavigationTypes.DOCUMENT) {
          this._lockService.unlockClausesInSection(this.section);
          this._setSelectedAndExpanded(false);
        }
      }),
      this._fragmentService.onCreate(() => this._cdr.markForCheck(), this._fragmentServiceUpdatePredicate),
      this._fragmentService.onUpdate(() => this._cdr.markForCheck(), this._fragmentServiceUpdatePredicate),
      this._fragmentService.onDelete(() => this._cdr.markForCheck(), this._fragmentServiceUpdatePredicate),
      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        if (this._useViewService) {
          this.currentView = currentView;
          this._cdr.markForCheck();
        }
      }),
      this._navigationService.onNavigationStart('ALL', (e: SectionNavigationEvent) => {
        this.loading = this.section.equals(e.section);
        this._cdr.markForCheck();
      }),
      this._navigationService.onNavigationEnd('ALL', (e: SectionNavigationEvent) => {
        if (e.type === this.type) {
          this.loading = false;
          this.selected = this.section.equals(e.section);
        }
        this._cdr.markForCheck();
      }),
      this._reorderClausesService.onReorderingEvent().subscribe((reordering: boolean) => {
        this.reorderingClauses = reordering;
      }),
      this._reorderClausesService.onDraggingEvent().subscribe((dragging: boolean) => {
        this._draggingClauses = dragging;
        this.draggingValidClauses = dragging && this._reorderClausesService.isValidMoveToNewSection(this.section);
        this._cdr.markForCheck();
      })
    );

    this._sectionTitle = this.section.title;
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Respond to Angular binding changes.
   *
   * @param changes {SimpleChanges}   The changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.section) {
      this.getHeadings(null);
    }

    if (changes.hasOwnProperty('currentView')) {
      this._useViewService = !changes.currentView.currentValue;
      this.currentView = this._useViewService ? this._viewService.getCurrentView() : changes.currentView.currentValue;
    }
  }

  /**
   * Routes to the selected section
   *
   * @param section {SectionFragment} The selected section
   */
  public selectSection(): void {
    if (!this._draggingSection && !this.editing) {
      let segs: string[] = [];

      switch (this.currentView.viewMode) {
        case ViewMode.BACKGROUND:
          segs = ['sections', this.section.id.value, 'background'];
          break;

        case ViewMode.COMMENTS:
          segs = ['sections', this.section.id.value, 'comments'];
          break;

        case ViewMode.COMPARE:
          segs = ['sections', this.section.id.value, 'compare'];
          break;

        case ViewMode.CHANGELOG_MARKUP:
        case ViewMode.CHANGELOG:
          segs = ['/documents', this.section.documentId.value, 'sections', this.section.id.value, 'changelog'];
          break;

        case ViewMode.CHANGELOG_AUX:
          this._navigationService.navigateToPublishedChangelogSection(this.section);
          break;

        default:
          segs = ['sections', this.section.id.value];
          break;
      }

      this._router.navigate([...segs], {relativeTo: this._route});
    }
  }

  /**
   * Retrieve the children, and split the headings/subheadings into a separate array for display
   */
  public getHeadings(target?: ClauseFragment) {
    this.headings.length = 0;

    for (const clause of this.section.getClauses()) {
      if (
        clause.clauseType === ClauseType.HEADING_1 ||
        clause.clauseType === ClauseType.HEADING_2 ||
        clause.clauseType === ClauseType.HEADING_3
      ) {
        this.headings.push({
          clause: clause,
          text: clause.preview,
          type: clause.clauseType,
          class: ClauseType[clause.clauseType].toLowerCase().replace(/_/g, '-'),
        });
      }
    }
  }

  /**
   * Display or hide the section headings in the sidebar
   */
  public toggleHeadings(event: MouseEvent) {
    this.expanded = !this.expanded;
    this._ignoreDefaultExpandedStatus = this.expanded;

    event.stopPropagation();
    return false;
  }

  /**
   * Select a heading and scroll its clause into view.
   *
   * @param event   {MouseEvent}   The click event
   * @param heading {Heading}      The selected heading
   */
  public selectHeading(event: MouseEvent, heading: Heading) {
    event.stopPropagation();
    const clauseToSelect: ClauseFragment = heading.clause;

    if (!this.selected) {
      this.selectSection();
    }
    this._clauseService.setSelected(clauseToSelect);
    if (clauseToSelect.isAttached()) {
      clauseToSelect.component.element.scrollIntoView(true);
    }
  }

  public renameSection(renaming: boolean): void {
    if (renaming) {
      this.editing = true;
      this._cdr.markForCheck();
      setTimeout(() => {
        this.title.nativeElement.focus();
      });
    } else {
      this.editing = false;
      this._cdr.markForCheck();
    }
  }

  /**
   * Persist updates to the section managed by this component.
   */
  public saveSection(): void {
    if (this.section.title !== '' && !this.section.title.match(/^\s+$/g)) {
      this._sectionService
        .update(this.section)
        .then(() => {
          this.editing = false;
          this._cdr.markForCheck();
          this._sectionTitle = this.section.title;
        })
        .catch(() => {
          this._snackBar
            .open('Section could not be updated', 'Retry', {duration: 5000})
            .onAction()
            .subscribe(() => {
              this.saveSection();
            });
        });
    } else {
      const ref: MatDialogRef<DialogComponent> = this._dialog.open(DialogComponent);
      const dialog = ref.componentInstance;
      dialog.message = 'Section title cannot be empty';

      ref.afterClosed().subscribe(() => {
        this.section.title = this._sectionTitle;
        this.editing = false;
        this._cdr.markForCheck();
      });
    }
  }

  public moveClausesToSection(): void {
    if (!this._draggingClauses) {
      return;
    }

    if (this.draggingValidClauses) {
      this._reorderClausesService.moveClausesToSection(this.section).then(() => {
        this.selectSection();
      });
    } else {
      this._snackBar.open('Some of selected clauses cannot be moved to this section', 'Dismiss', {duration: 5000});
    }
  }

  /**
   * Triggered on mousedown of the drag handle / thumb.
   */
  public startDragging(event): void {
    event.preventDefault();
    this.onDraggingEvent.emit(true);
    this._draggingSection = true;
  }

  private _setSelectedAndExpanded(selected: boolean): void {
    this.selected = selected;
    this.expanded = this.selected || this._ignoreDefaultExpandedStatus;
    this._cdr.markForCheck();
  }

  /**
   * Returns whether the title has been trucated within the display. Used to display tooltip if it is trucated.
   */
  public isTitleTrucated(): boolean {
    return this.title.nativeElement.scrollWidth > this.title.nativeElement.clientWidth;
  }

  /**
   * Predicate for whether the fragment event should trigger a mark for check of this component.
   * Returns true for SECTION_GROUPs and SECTIONs.
   */
  private _fragmentServiceUpdatePredicate(fragment: Fragment): boolean {
    return fragment?.is(FragmentType.SECTION_GROUP, FragmentType.SECTION);
  }
}
