import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ChangelogLink} from 'app/changelog/changelog-link';
import {ChangelogRange} from 'app/changelog/changelog-range';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {AnchorFragment, DocumentFragment, Fragment, FragmentType, SectionFragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {AnchorSource} from 'app/interfaces';
import {AnchorService} from 'app/services/anchor.service';
import {BaseService} from 'app/services/base.service';
import {CanvasService} from 'app/services/canvas.service';
import {DocumentFetchParams, DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {NavigationService} from 'app/services/navigation.service';
import {SectionFetchParams, SectionService} from 'app/services/section.service';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {Callback} from 'app/utils/typedefs';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Observable, ReplaySubject, Subject, Subscription, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {ChangelogProperty} from './changelog-property';
import {FragmentIndex} from './link-manager/link-manager.component';

interface RangeEvent {
  operation: 'CREATE' | 'UPDATE' | 'DELETE';
  range: any;
}

interface LinkEvent {
  operation: 'CREATE' | 'UPDATE' | 'DELETE';
  link: any;
}

@Injectable({
  providedIn: 'root',
})
export class ChangelogService extends BaseService {
  private static readonly RANGE_URL: string = `${environment.apiHost}/ranges`;
  private static readonly LINK_URL: string = `${environment.apiHost}/changelogLinks`;
  private static readonly PROPERTY_URL: string = `${environment.apiHost}/changelogProperties`;

  private static readonly RANGE_TOPIC: string = '/topic/changelog/ranges';
  private static readonly LINK_TOPIC: string = '/topic/changelog/links';

  private static readonly FRAGMENT_INDEX_URL: string = `${environment.apiHost}/fragmentIndex`;

  /** Map keyed on published range ID to all the links attached to it (also keyed by ID). */
  private byPublishedRangeId: Record<string, Record<string, ChangelogLink>> = {};

  /** Map keyed on authored range ID to all the links attached to it (also keyed by ID). */
  private byAuthoredRangeId: Record<string, Record<string, ChangelogLink>> = {};

  /** Map keyed on range ID to the range object. */
  private byId: Record<string, ChangelogRange> = {};

  /** Subject for requests to view the links of a range */
  private viewRequestSubject: Subject<ChangelogRange> = new Subject();

  private rangeDeletionSubject: Subject<ChangelogRange> = new Subject();

  private websocketSubscriptions: Subscription[] = [];

  private connected: boolean = false;

  /** The parameters to use to fetch published sections of the published document */
  private readonly sectionFetchParams: SectionFetchParams = {
    projection: 'FULL_TREE',
  };

  private selectedPublishedDocumentSubject: Subject<DocumentFragment> = new ReplaySubject(1);

  constructor(
    private fragmentService: FragmentService,
    private documentService: DocumentService,
    private sectionService: SectionService,
    private websocketService: WebSocketService,
    private canvasService: CanvasService,
    private navigationService: NavigationService,
    private versioningService: VersioningService,
    private http: HttpClient,
    private anchorService: AnchorService,
    protected snackbar: MatSnackBar
  ) {
    super(snackbar);

    this.websocketService.onConnection((connected: boolean) => {
      this.connected = connected;
      this.websocketSubscriptions.splice(0).forEach((subscription: Subscription) => subscription.unsubscribe());

      if (!this.connected) {
        return;
      }
      this.websocketSubscriptions.push(
        this.websocketService.subscribe(ChangelogService.RANGE_TOPIC, (json: RangeEvent) => this.dispatchRange(json)),
        this.websocketService.subscribe(ChangelogService.LINK_TOPIC, (json: LinkEvent) => this.dispatchLink(json))
      );
    });
  }

  /**
   * Sets the current viewed published document, these changes can be subscribed to with getPublishedDocumentStream.
   */
  public setPublishedDocument(document: DocumentFragment): void {
    this.selectedPublishedDocumentSubject.next(document);
  }

  /**
   * Returns an Observable of the document selected within the left hand side changelog view.
   * Changes are triggered by calls to setPublishedDocument.
   */
  public getPublishedDocumentStream(): Observable<DocumentFragment> {
    return this.selectedPublishedDocumentSubject.asObservable();
  }

  /**
   * Fetch a published document with the given ID.  This will currently either fetch the latest
   * version of a document, or the live version if there are no versions.
   *
   * TODO: once publishing is in, this should get the published version instead of the latest version.
   *
   * @param id {UUID} The id of the document.
   */
  public fetchPublishedDocument(id: UUID): Promise<DocumentFragment> {
    if (!id) {
      return Promise.reject(null);
    }

    const fetchParams: DocumentFetchParams = {
      projection: 'INITIAL_DOCUMENT_LOAD',
    };

    return this.documentService
      .load(id, fetchParams)
      .then((doc: DocumentFragment) => doc)
      .catch((err) => Promise.reject(err));
  }

  public fetchFullPublishedDocumentTree(id: UUID): Promise<DocumentFragment> {
    if (!id) {
      return Promise.reject(null);
    }

    const fetchParams: DocumentFetchParams = {
      projection: 'FULL_TREE',
    };

    return this.documentService
      .load(id, fetchParams)
      .then((doc: DocumentFragment) => doc)
      .catch((err) => Promise.reject(err));
  }

  /**
   * Fetch a section of a published document with the given ID.  This will always fetch the section
   * from the same version as the last loaded published document.
   *
   * @param id {UUID} The id of the document.
   */
  public fetchPublishedSection(id: UUID): Promise<SectionFragment> {
    if (!id) {
      return Promise.reject(null);
    }

    return this.sectionService
      .load(id, this.sectionFetchParams)
      .then((section: SectionFragment) => {
        this.findRangeBySectionId(section.id);
        this.findLinksBySectionId(section.id, section.id);
        this.navigationService.finishNavigationToPublishedChangelogSection(section);
        return section;
      })
      .catch((err) => Promise.reject(err));
  }

  /**
   * Find and draw all ranges which are within the section specified.  This will never use
   * a cached version of the ranges.
   *
   * @param sectionId {UUID} The ID of the section.
   * @returns         {Promise<void>} A promise which resolves when the request is complete.
   */
  public findRangeBySectionId(sectionId: UUID): Promise<ChangelogRange[]> {
    return this.http
      .get(`${ChangelogService.RANGE_URL}/search/findBySectionId`, {
        params: {sectionId: sectionId.value},
      })
      .pipe(
        map((response) => {
          return this._deserialiseHALArray(response, 'ranges', (json: any) => {
            return this.dispatchRange({operation: 'CREATE', range: json});
          });
        }),
        catchError((response) => this.handleError(response, 'Cannot load highlights for this section'))
      )
      .toPromise();
  }

  /**
   * Find a range with the given ID.  If that range has been fetched via this or a different
   * request, it will use a cached version.
   *
   * @param id {UUID} The id of the document.
   */
  public findRangeById(id: UUID): Promise<ChangelogRange> {
    if (this.byId[id.value]) {
      return Promise.resolve(this.byId[id.value]);
    }
    return this.http
      .get(`${ChangelogService.RANGE_URL}/${id.value}`)
      .pipe(
        map((response) => this.dispatchRange({operation: 'CREATE', range: response})),
        catchError((response) => this.handleError(response, 'Cannot load highlight'))
      )
      .toPromise();
  }

  /**
   * Find and draw all ranges which are linked to the specified range.  This will never use
   * a cached version of the ranges.
   *
   * @param sectionId {UUID} The ID of the section.
   * @returns         {Promise<void>} A promise which resolves when the request is complete.
   */
  public findRangesLinkedToRange(rangeId: UUID): Promise<ChangelogRange[]> {
    return this.http
      .get(`${ChangelogService.RANGE_URL}/search/findAllRangesLinkedToRange`, {
        params: {rangeId: rangeId.value},
      })
      .pipe(
        map((response) => {
          return this._deserialiseHALArray(response, 'ranges', (json: any) => {
            return this.dispatchRange({operation: 'CREATE', range: json});
          });
        }),
        catchError((response) => this.handleError(response, 'Cannot load highlights linked to section'))
      )
      .toPromise();
  }

  public getFragmentIndex(fragmentId: UUID): Promise<FragmentIndex> {
    return this.http
      .get(`${ChangelogService.FRAGMENT_INDEX_URL}/${fragmentId.value}`)
      .pipe(
        map((response: any) => {
          const fragIndex: FragmentIndex = {
            index: response.index,
            value: response.value,
          };
          return fragIndex;
        }),
        catchError((response) => this.handleError(response, 'Unable to get fragment index'))
      )
      .toPromise();
  }

  /**
   * Find and draw all ranges which are either in or linked to a range in the specified section.
   * This will never use a cached version of the ranges.
   *
   * @param sectionId {UUID} The ID of the section.
   * @returns         {Promise<void>} A promise which resolves when the request is complete.
   */
  public findRangesLinkedToSection(sectionId: UUID): Promise<ChangelogRange[]> {
    return this.http
      .get(`${ChangelogService.RANGE_URL}/search/findAllRangesLinkedToSection`, {params: {sectionId: sectionId.value}})
      .pipe(
        map((response) => {
          return this._deserialiseHALArray(response, 'ranges', (json: any) => {
            return this.dispatchRange({operation: 'CREATE', range: json});
          });
        }),
        catchError((response) => this.handleError(response, 'Cannot load linked highlights'))
      )
      .toPromise();
  }

  /**
   * Delete a changelog range.
   *
   * @param range {ChangelogRange}         The range to delete.  Must have an ID.
   * @returns     {Promise<ChangelogRange>} A promise which will resolve once the change is persisted.
   */
  public deleteRange(range: ChangelogRange): Promise<ChangelogRange> {
    if (!range || !range.id) {
      Logger.error('changelog-error', 'Tried to delete invalid changelog range');
      return Promise.reject(void 0);
    }

    const deleteArray: Fragment[] = [];
    const parentArray: Fragment[] = [];

    this.addToDeleteAndParentArrays(range.start.fragment, deleteArray, parentArray);
    this.addToDeleteAndParentArrays(range.end.fragment, deleteArray, parentArray);

    return Promise.all([
      this.http
        .delete(`${ChangelogService.RANGE_URL}/${range.id.value}`)
        .pipe(
          tap((response: any) => this.dispatchRange({operation: 'DELETE', range})),
          catchError((response) =>
            this.handleError(response, 'This highlight cannot be removed, as it has been linked to other highlights.')
          )
        )
        .toPromise(),
      this.fragmentService.delete(deleteArray),
    ]).then(() => {
      parentArray.forEach((parent: Fragment) => this.fragmentService.mergeChildren(parent));
      return range;
    });
  }

  private addToDeleteAndParentArrays(fragment: Fragment, deleteArray: Fragment[], parentArray: Fragment[]): void {
    if (fragment?.is(FragmentType.ANCHOR)) {
      deleteArray.push(fragment);
      parentArray.push(fragment.parent);
    }
  }

  /**
   * Update and re-draw a changelog range.
   *
   * @param range {ChangelogRange}         The range to update.  Must have an ID.
   * @returns     {Promise<ChangelogRange>} A promise which will resolve once the change is persisted.
   */
  public updateRange(range: ChangelogRange): Promise<ChangelogRange> {
    if (!range || !range.id) {
      Logger.error('changelog-error', 'Tried to update invalid changelog range');
      return Promise.reject(void 0);
    }

    return this.http
      .patch(`${ChangelogService.RANGE_URL}/${range.id.value}`, range.serialise())
      .pipe(
        tap((response: any) => this.dispatchRange({operation: 'UPDATE', range})),
        catchError((response) => this.handleError(response, 'Failed to update range'))
      )
      .toPromise()
      .then(() => range);
  }

  /**
   * Create and draw a new changelog range.
   *
   * @param range {ChangelogRange}         The range to create.  Must _not_ have an ID.
   * @returns     {Promise<ChangelogRange>} A promise which will resolve once the range is persisted.
   */
  public createRange(range: ChangelogRange): Promise<ChangelogRange> {
    if (!range || range.id) {
      Logger.error('changelog-error', 'Tried to create invalid changelog range');
      return Promise.reject(void 0);
    }

    const anchorSource: AnchorSource = range.published
      ? null
      : {
          startFragmentId: range.start.fragment.id,
          endFragmentId: range.end.fragment.id,
          startOffset: range.start.offset,
          endOffset: range.end.offset,
        };

    return this.anchorService.createAnchors(anchorSource).then((anchors: AnchorFragment[]) => {
      if (anchors.length > 0) {
        range.start.fragment = anchors[0];
        range.end.fragment = anchors[1];
        range.start.offset = 0;
        range.end.offset = 0;
      }

      return this.http
        .post(ChangelogService.RANGE_URL, range.serialise(), {
          headers: this._httpHeaders,
        })
        .pipe(
          tap((response: any) => {
            range.id = UUID.orThrow(response.id);
            this.dispatchRange({operation: 'CREATE', range});
            window.getSelection().removeAllRanges();
          }),
          catchError((response) => this.handleError(response, 'Failed to persist selection'))
        )
        .toPromise()
        .then(() => range);
    });
  }

  /**
   * Handles all range events, whether from websocket, HTTP or user.
   *
   * @param event {RangeEvent} The event to handle.
   */
  private dispatchRange(event: RangeEvent): ChangelogRange {
    const range: ChangelogRange =
      event.range instanceof ChangelogRange
        ? event.range
        : ChangelogRange.deserialise(event.range, this.fragmentService, null);

    if (!range || !event.operation) {
      return null;
    }

    switch (event.operation) {
      case 'CREATE':
        {
          this.byId[range.id.value] = range;
          this.canvasService.addChangelogRange(range);
        }
        break;
      case 'UPDATE':
        {
          range.forceRedraw = true;
          this.byId[range.id.value] = range;
          this.canvasService.addChangelogRange(range);
        }
        break;
      case 'DELETE':
        {
          delete this.byId[range.id.value];
          this.canvasService.removeChangelogRange(range);
        }
        break;
    }

    return range;
  }

  /**
   * Delete a changelog link.
   *
   * @param link  {ChangelogLink}          The link to delete.  Must have an ID.
   * @returns     {Promise<ChangelogLink>} A promise which will resolve once the change is persisted.
   */
  public deleteLink(link: ChangelogLink): Promise<ChangelogLink> {
    if (!link || !link.id) {
      Logger.error('changelog-error', 'Tried to delete invalid changelog link');
      return Promise.reject(void 0);
    }

    return this.http
      .delete(`${ChangelogService.LINK_URL}/${link.id.value}`)
      .pipe(
        tap((response: any) => {
          this.dispatchLink({operation: 'DELETE', link});
          this.updateRangesFromLink(link);
        }),
        catchError((response) => this.handleError(response, 'Failed to delete link'))
      )
      .toPromise()
      .then(() => link);
  }

  /**
   * Update a changelog link.
   *
   * @param link  {ChangelogLink}          The link to update.  Must have an ID.
   * @returns     {Promise<ChangelogLink>} A promise which will resolve once the change is persisted.
   */
  public updateLink(link: ChangelogLink): Promise<ChangelogLink> {
    if (!link || !link.id) {
      Logger.error('changelog-error', 'Tried to update invalid changelog link');
      return Promise.reject(void 0);
    }

    return this.http
      .patch(`${ChangelogService.LINK_URL}/${link.id.value}`, link.serialise())
      .pipe(
        tap((response: any) => this.dispatchLink({operation: 'UPDATE', link})),
        catchError((response) => this.handleError(response, 'Failed to update link'))
      )
      .toPromise()
      .then(() => link);
  }

  /**
   * Create a new changelog link.
   *
   * @param link {ChangelogLink}          The link to create.  Must _not_ have an ID.
   * @returns    {Promise<ChangelogLink>} A promise which will resolve once the link is persisted.
   */
  public createLink(link: ChangelogLink): Promise<ChangelogLink> {
    if (!link || link.id) {
      Logger.error('changelog-error', 'Tried to create invalid changelog link');
      return Promise.reject(void 0);
    }
    return this.http
      .post(ChangelogService.LINK_URL, link.serialise(), {
        headers: this._httpHeaders,
      })
      .pipe(
        tap((response: any) => {
          link.id = UUID.orThrow(response.id);
          this.dispatchLink({operation: 'CREATE', link});
          this.updateRangesFromLink(link);
        }),
        catchError((response) => this.handleError(response, 'Failed to persist link'))
      )
      .toPromise()
      .then(() => link);
  }

  /**
   * Gets the ranges from a given link and re-draws them to the pad.
   *
   * @param link {ChangelogLink} The link to have it's ranges updated.
   */
  public updateRangesFromLink(link: ChangelogLink): void {
    Promise.all([this.findRangeById(link.authoredRangeId), this.findRangeById(link.publishedRangeId)])
      .then((ranges: ChangelogRange[]) => {
        for (const range of ranges) {
          this.dispatchRange({operation: 'UPDATE', range});
        }
      })
      .catch((response) => this.handleError(response, 'Failed to update ranges from link'));
  }

  /**
   * Find all links which relate to either of the sections specified.  If you are only
   * interested in links relating to one section, you can pass the same id in both arguments.
   *
   * This will never use cached versions of links.
   *
   * @param publishedSectionId {UUID} The ID of the published section.
   * @param authoredSectionId  {UUID} The ID of the authored section.
   * @returns         {Promise<void>} A promise which resolves when the request is complete.
   */
  public findLinksBySectionId(publishedSectionId: UUID, authoredSectionId: UUID): Promise<ChangelogLink[]> {
    return this.http
      .get(`${ChangelogService.LINK_URL}/search/findBySectionIdOrPublishedSectionId`, {
        params: {
          authoredSectionId: authoredSectionId.value,
          publishedSectionId: publishedSectionId.value,
        },
      })
      .pipe(
        map((response) => {
          return this._deserialiseHALArray(response, 'changelogLinks', (json: any) => {
            return this.dispatchLink({operation: 'CREATE', link: json});
          });
        }),
        catchError((response) => this.handleError(response, 'Cannot load links for these sections'))
      )
      .toPromise();
  }

  /**
   * Find all links which are linked to the specified range.
   *
   * This will use the cache if possible.
   *
   * @param id        {UUID}    The id of the range.
   * @param published {boolean} If the range is in a published document or an authored document.
   */
  public findLinksByRangeId(id: UUID, published: boolean): Promise<ChangelogLink[]> {
    const links: Record<string, ChangelogLink> = published
      ? this.byPublishedRangeId[id.value]
      : this.byAuthoredRangeId[id.value];
    if (links) {
      return Promise.resolve(Object.values(links));
    }

    const endpoint: string = published ? 'findByPublishedRangeId' : 'findByAuthoredRangeId';
    return this.http
      .get(`${ChangelogService.LINK_URL}/search/${endpoint}`, {
        params: {rangeId: id.value},
      })
      .pipe(
        map((response) => {
          return this._deserialiseHALArray(response, 'changelogLinks', (json: any) => {
            return this.dispatchLink({operation: 'CREATE', link: json});
          });
        }),
        catchError((response) => this.handleError(response, 'Cannot load links for this range'))
      )
      .toPromise();
  }

  /**
   * Get the changelog properties of the published document of the given id.
   *
   * @param publishedId {UUID}  The id of the published document.
   */
  public findChangelogPropertyForDocument(publishedId: UUID): Promise<ChangelogProperty> {
    return this.http
      .get(`${ChangelogService.PROPERTY_URL}/search/findByPublishedDocument`, {
        params: {publishedDocument: publishedId.value},
      })
      .pipe(
        map((response) => {
          return this._deserialiseHALArray(response, 'changelogProperties', (json: any) => {
            return ChangelogProperty.deserialise(json);
          })[0];
        }),
        catchError((response) => this.handleError(response, 'Cannot get changelog properties for this document'))
      )
      .toPromise();
  }

  /**
   * Update the given changelog property object.
   *
   * @param property {ChangelogProperty}  The property object to update.
   */
  public updateChangelogProperty(property: ChangelogProperty): Promise<ChangelogProperty> {
    if (!property || !property.id) {
      Logger.error('changelog-error', 'Tried to update invalue changelog property');
    }

    return this.http
      .patch(`${ChangelogService.PROPERTY_URL}/${property.id.value}`, property.serialise())
      .pipe(catchError((response) => this.handleError(response, 'Failed to update changelog properties')))
      .toPromise()
      .then(() => property);
  }

  /**
   * Create a new changelog property entry.
   *
   * @param property {ChangelogProperty}  The property object to create
   */
  public createChangelogProperty(property: ChangelogProperty): Promise<ChangelogProperty> {
    return this.http
      .post(ChangelogService.PROPERTY_URL, property.serialise(), {
        headers: this._httpHeaders,
      })
      .pipe(catchError((response) => this.handleError(response, 'Failed to create changelog properties')))
      .toPromise()
      .then((p) => ChangelogProperty.deserialise(p));
  }

  /**
   * Handles all link events, whether from websocket, HTTP or user.
   *
   * @param event {LinkEvent} The event to handle.
   */
  private dispatchLink(event: LinkEvent): ChangelogLink {
    const link: ChangelogLink =
      event.link instanceof ChangelogLink ? event.link : ChangelogLink.deserialise(event.link);
    if (!link || !event.operation) {
      return null;
    }

    switch (event.operation) {
      case 'CREATE':
      case 'UPDATE':
        {
          this.byAuthoredRangeId[link.authoredRangeId.value] = this.byAuthoredRangeId[link.authoredRangeId.value] || {};
          this.byAuthoredRangeId[link.authoredRangeId.value][link.id.value] = link;
          this.byPublishedRangeId[link.publishedRangeId.value] =
            this.byPublishedRangeId[link.publishedRangeId.value] || {};
          this.byPublishedRangeId[link.publishedRangeId.value][link.id.value] = link;
        }
        break;
      case 'DELETE':
        {
          this.byAuthoredRangeId[link.authoredRangeId.value] = this.byAuthoredRangeId[link.authoredRangeId.value] || {};
          delete this.byAuthoredRangeId[link.authoredRangeId.value][link.id.value];
          this.byPublishedRangeId[link.publishedRangeId.value] =
            this.byPublishedRangeId[link.publishedRangeId.value] || {};
          delete this.byPublishedRangeId[link.publishedRangeId.value][link.id.value];
        }
        break;
    }

    return link;
  }

  /**
   * Updates the given range's storedValue and stored documentTitle.
   *
   * @param range {ChangelogRange}  The range to update
   */
  public updateStoredValues(range: ChangelogRange, documentTitle?: string): void {
    const startFragment: Fragment = this.fragmentService.find(range.startFragmentId);
    const endFragment: Fragment = this.fragmentService.find(range.endFragmentId);
    if (!startFragment || !endFragment) {
      return;
    }
    range.start.fragment = startFragment;
    range.end.fragment = endFragment;
    range.storedValue = range.value;
    if (documentTitle) {
      range.documentTitle = documentTitle;
    }
    this.updateRange(range);
  }

  /**
   * Make a request to view existing links for the range.
   *
   * @param range {ChangelogRange} The range to view links for.
   */
  public viewExistingLinks(range: ChangelogRange): void {
    this.viewRequestSubject.next(range);
  }

  /**
   * Run the callback whenever a request is made to view existing links for a range.
   */
  public onViewLinksRequest(callback: Callback<ChangelogRange>): Subscription {
    return this.viewRequestSubject.subscribe(callback);
  }

  public onDeletionEvent(callback: Callback<ChangelogRange>): Subscription {
    return this.rangeDeletionSubject.subscribe(callback);
  }

  public triggerDeletionEvent(range: ChangelogRange): void {
    this.rangeDeletionSubject.next(range);
  }

  private handleError(
    response: HttpErrorResponse | any,
    message: string = null,
    persist: boolean = false
  ): Observable<never> {
    this._handleError(response, message, 'changelog-error');
    Logger.error('changelog-error', response);
    return throwError(null);
  }
}
