import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatButtonToggleChange, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {BlurOption} from 'app/blur-options';
import {CarsAction} from 'app/permissions/types/permissions';
import {CanvasService} from 'app/services/canvas.service';
import {ClauseService} from 'app/services/clause.service';
import {DocumentService} from 'app/services/document.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {ConfigurationService, SidebarItem} from 'app/suite-config/configuration.service';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {UUID} from 'app/utils/uuid';
import {CurrentView} from 'app/view/current-view';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {ClauseFragment, DocumentFragment} from '../fragment/types';
import {AltAccessibilityService, AltProperties} from '../services/alt-accessibility.service';
import {NavigationService, NavigationTypes} from '../services/navigation.service';
import {ResizeService} from '../services/resize.service';
import {SectionService} from '../services/section.service';
import {ViewService} from '../view/view.service';

@Component({
  selector: 'cars-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit, OnChanges, OnDestroy {
  public static readonly MIN_WIDTH = 250;
  public static readonly MAX_WIDTH = 500;

  @Input() public navigationName: NavigationTypes = NavigationTypes.DOCUMENT;
  @Input() public useViewService: boolean = true;
  @Input() public currentView: CurrentView = null;
  @Input() public disableNavigationSidebar: boolean = false;
  @Input() public documentFragment: DocumentFragment = null;

  @ViewChild('group', {static: true}) public group: MatButtonToggleGroup;
  @ViewChild('followCursor') public followCursor: ElementRef;

  public readonly SidebarStatus: typeof SidebarStatus = SidebarStatus;
  public readonly CarsAction: typeof CarsAction = CarsAction;
  public readonly tooltipDelay = environment.tooltipDelay;

  public documentId: UUID;
  public clauseFragment: ClauseFragment;
  public documentOverviewDisplayed = true;
  public discussionsUnresolved: number = 0;
  public sidebarStatus: SidebarStatus = SidebarStatus.CLOSED;
  public reorderingMode: boolean = false;
  public clauseDraggedOutOfPad: boolean = false;
  public numberOfDraggedClauses: number;
  public readonly BlurOption: typeof BlurOption = BlurOption;

  private dragListeners: EventListener[];

  // Current sizes
  public navigationOpen: boolean = true;
  public rightWidth: number = LocalConfigUtils.getConfig().sidebarWidth;
  private leftWidth: number = LocalConfigUtils.getConfig().navbarWidth;

  public isClauseReference: boolean = false;

  private _sideNavStyle: any;

  public defaultWidthTransition = 'width 0.4s ease-in-out';
  public widthTransition = this.defaultWidthTransition;

  public sidebarItems: SidebarItem[] = [];

  public altProperties: Record<string, AltProperties> = {};

  private draggedOutOfPadListener: EventListener = this.setDraggingCursorPosition.bind(this);

  private altAccessibilityService: AltAccessibilityService = new AltAccessibilityService(new ResizeService());

  // Transient
  private startWidth: number;
  private startX: number;
  private resizingRight: boolean;

  private _subscriptions: Subscription[] = [];

  public get sideNavStyle(): any {
    if (!this._sideNavStyle) {
      this._sideNavStyle = {
        width: this.leftWidth + 'px',
      };
    }
    return this._sideNavStyle;
  }

  constructor(
    private clauseService: ClauseService,
    private sectionService: SectionService,
    private sidebarService: SidebarService,
    private reorderClausesService: ReorderClausesService,
    private resizeservice: ResizeService,
    private viewService: ViewService,
    private navigationService: NavigationService,
    private canvasService: CanvasService,
    private configurationService: ConfigurationService,
    private documentService: DocumentService
  ) {}

  /**
   * Initialise this component and setup subscriptions.
   */
  public ngOnInit(): void {
    this.navigationOpen = this.navigationService.getState(this.navigationName);
    if (!!this.documentFragment) {
      this.documentId = this.documentFragment.id;
    }

    this._subscriptions.push(
      this.navigationService.onChange(this.navigationName, (open: boolean) => {
        this.navigationOpen = open;
        this.onSidebarsChanged();
      }),
      this.viewService.onCurrentViewChange((currentView: CurrentView) => {
        if (!this.useViewService) {
          return;
        }
        this.currentView = currentView;
      }),
      this.clauseService.onSelection((clause: ClauseFragment) => {
        if (clause) {
          this.clauseFragment = clause;
        }
      }),
      this.sidebarService.getSidebarStatus().subscribe((status: SidebarStatus) => {
        this.sidebarStatus = status;
        if (status === SidebarStatus.CLOSED) {
          this.group.value = null;
        }
      }),
      this.sidebarService.getIsClauseRefSidebar().subscribe((state: boolean) => {
        this.isClauseReference = state;
      }),
      this.reorderClausesService.onReorderingEvent().subscribe((status: boolean) => {
        if (status) {
          this.clauseFragment = null;
          this.openNavBar();
        }
        this.reorderingMode = status;
      }),
      this.reorderClausesService.onDraggingEvent().subscribe((dragging: boolean) => {
        if (dragging) {
          this.numberOfDraggedClauses = this.reorderClausesService.getSelection().length;
        }
      }),
      this.reorderClausesService.onDraggedOutsidePadEvent().subscribe((outOfPad: boolean) => {
        this.clauseDraggedOutOfPad = outOfPad;
        if (outOfPad) {
          document.addEventListener('mousemove', this.draggedOutOfPadListener);
        } else {
          document.removeEventListener('mousemove', this.draggedOutOfPadListener);
        }
      }),
      this.sectionService.onSelection(() => {
        this.clauseFragment = null;
      }),
      this._getSidebarItems()
    );

    this.updateAltProperties();

    if (this.currentView.userIsAReviewer() && this.currentView.isAvailableToCommentAgainst()) {
      this.sidebarService.setSidebarStatus(SidebarStatus.DISCUSSIONS);
    }
  }

  /**
   * Updates the stored documentId when changes are passed into this component. Only updates when the changes contain
   * a document fragment and this is not null, these conditions are only met when the published changelog document is
   * changed.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('documentFragment')) {
      if (!!this.documentFragment) {
        this.documentId = this.documentFragment.id;
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Gets the sidebar item configuration using the document service if a documentFragment is not passed to this
   * component. If a documentFragment is passed this component is showing the left changelog and this should not use
   * the document service onSelection method. The document is only used to update the sidebar config, and but the
   * sidebar is never shown for published documents in the changelog due to the SHOW_RIGHT_SIDEBAR permission. Returns
   * a new subscription for the left view to avoid errors in ngOnDestroy.
   */
  private _getSidebarItems(): Subscription {
    if (!this.documentFragment) {
      return this.documentService.onSelection((documentFragment: DocumentFragment) => {
        if (!!documentFragment) {
          this.documentId = documentFragment.id;

          this.configurationService
            .getSidebarItemsForSuite(documentFragment.suite)
            .then((sidebarItems: SidebarItem[]) => {
              this.sidebarItems = sidebarItems;
              this.updateAltProperties();
            });
        }
      });
    } else {
      return new Subscription();
    }
  }

  public onSidebarToggle() {
    this.setSidebarStatus({
      value:
        this.reorderingMode && this.sidebarStatus === SidebarStatus.CLOSED
          ? SidebarStatus.REORDERING
          : SidebarStatus.CLOSED,
    });
  }

  public setSidebarStatus(event: MatButtonToggleChange | any) {
    const sidebarStatus: SidebarStatus = event.value;
    this.sidebarService.setSidebarStatus(sidebarStatus);
    this.onSidebarsChanged();
    this.setIsClauseReference(sidebarStatus);
  }

  private onSidebarsChanged(): void {
    // Force the sidebars mat-tabs to behave
    this.resizeservice.fireResize();

    const transition = /(\d+.?\d*)s/.exec(this.widthTransition);
    if (transition.length >= 2) {
      // Wait for the transition to complete before updating again
      const delay = Number.parseFloat(transition[1]) * 1000;
      this.sendAnimationRequests(delay + 100);
    }
  }

  /**
   * Sends out window resize requests for a given duration, this enables window elements to
   * resize themselves as animations cause the window to update.
   * @param duration The duration of the animation
   * @param delay The number of ms between each request. Default is 10fps.
   */
  private sendAnimationRequests(duration: number, delay: number = 1000 / 10) {
    let timecount = 0;

    this.disableCanvasDrawing();
    const interval: any = setInterval(() => {
      this.resizeservice.fireResize();
      timecount += delay;
      if (duration <= timecount) {
        clearInterval(interval);
        this.enableCanvasDrawing();
      }
    }, Math.min(duration, delay));
  }

  /**
   * The mousemove callback when drag-resizing the sidebars.
   *
   * @param event {MouseEvent}   The mousemove event
   */
  private dragListener(event: MouseEvent): void {
    const delta = event.clientX - this.startX;
    const newWidth = this.resizingRight ? this.startWidth - delta : this.startWidth + delta;

    if (newWidth <= ContainerComponent.MAX_WIDTH && newWidth >= ContainerComponent.MIN_WIDTH) {
      if (this.resizingRight) {
        this.rightWidth = newWidth;
      } else {
        this.leftWidth = newWidth;
      }
      this._sideNavStyle = null;
    }

    this.resizeservice.fireResize();
  }

  /**
   * The mouseup handler to remove drag-resize events.
   *
   * @param event {MouseEvent}   The mouseup event
   */
  private stopListener(): void {
    this.resizingRight
      ? LocalConfigUtils.setSidebarWidth(this.rightWidth)
      : LocalConfigUtils.setNavbarWidth(this.leftWidth);

    document.removeEventListener('mousemove', this.dragListeners[0]);
    document.removeEventListener('mouseup', this.dragListeners[1]);

    document.documentElement.style.userSelect = '';
    document.documentElement.style['-moz-user-select'] = '';

    this.widthTransition = this.defaultWidthTransition;
  }

  /**
   * Respond to a mousedown event by starting sidebar drag resizing.
   *
   * @param event {MouseEvent}   The mousedown event
   * @param right {boolean}      True if the right sidebar
   */
  public mousedown(event: MouseEvent, right: boolean): boolean {
    this.resizingRight = right;
    this.startX = event.clientX;
    this.startWidth = right ? this.rightWidth : this.leftWidth;

    // Set userSelect none so we can't select the pad while dragging
    document.documentElement.style.userSelect = 'none';
    document.documentElement.style['-moz-user-select'] = '-moz-none';

    this.widthTransition = 'initial';

    // We need to store these off else the removeEventListeners above don't find the right
    // functions to remove.  Just passing them as arguments means that `this` in the event
    // callback stopListener() is window, so we're really calling removeEventListener()
    // with an undefined callback.  We also can't call bind directly as this creates new
    // functions.  JavaScript is really fun.
    this.dragListeners = [this.dragListener.bind(this), this.stopListener.bind(this)];

    document.addEventListener('mousemove', this.dragListeners[0]);
    document.addEventListener('mouseup', this.dragListeners[1]);

    return false;
  }

  /**
   * Toggle whether the navigation bar is open.
   */
  public toggleNavBar(): void {
    this.navigationService.toggleState(this.navigationName);
  }

  /**
   * This handles the selection of one of the sidebar options via the alt shortcuts.
   */
  public handleAltSelection(key: SidebarStatus): void {
    if (this.sidebarStatus !== key) {
      this.setSidebarStatus({value: key});
      this.sidebarStatus = key;
      setTimeout(() => document.getElementById('sidebar-button').focus());
    }
  }

  public setDraggingCursorPosition(event: MouseEvent) {
    this.followCursor.nativeElement.style.left = event.clientX.toString() + 'px';
    this.followCursor.nativeElement.style.top = (event.clientY - 50).toString() + 'px';
  }

  /**
   * This updates the properties that are passed to the alt acessiblity component.
   */
  public updateAltProperties(): void {
    this.sidebarItems.map((item: SidebarItem, index: number) => {
      this.altProperties[item.sidebarItemType] = this.altAccessibilityService.getAltProperties(
        item.icon,
        !this.sidebarOptionIsDisabled(item.sidebarItemType)
      );
    });

    this.altProperties['right-sidebar'] = this.altAccessibilityService.getAltProperties(
      'right_sidebar',
      !!this.clauseFragment
    );
  }

  public openNavBar(): void {
    this.navigationService.setState(this.navigationName, true);
  }

  public disableCanvasDrawing(): void {
    this.canvasService.toggleRendering(false);
  }

  public enableCanvasDrawing(): void {
    this.canvasService.toggleRendering(true);
  }

  public sidebarOptionIsDisabled(key: SidebarStatus): boolean {
    return !this.clauseFragment || this.sidebarStatus === key || this.sidebarStatus === SidebarStatus.REORDERING;
  }

  public setIsClauseReference(sidebarOption: SidebarStatus): void {
    this.isClauseReference = sidebarOption === SidebarStatus.CLAUSE_REFERENCES;
  }
}
