import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {NaaExportSelectorComponent} from './naa-export-selector.component';

@NgModule({
  declarations: [NaaExportSelectorComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  exports: [NaaExportSelectorComponent],
})
export class NaaExportSelectorModule {}
