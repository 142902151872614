<div class="changelog-container">
  <cars-navigation-sidebar-buttons [showNavigationToggle]="false"></cars-navigation-sidebar-buttons>
  <div class="data-container left-container">
    <div class="changelog-layout">
      <ng-template #toolbar>
        <cars-toolbar class="published" [preventClick]="false">
          <cars-alt-accessibility
            [altProperties]="altProperties['published-toolbar']"
            [richText]="true"
          ></cars-alt-accessibility>
          <button
            mat-button
            matTooltip="Toggle Sidebar"
            matTooltipPosition="below"
            [matTooltipShowDelay]="tooltipDelay"
            aria-label="Toggle sidebar"
            [disabled]="!publishedDocument"
            *ngIf="!showingPublishedNavigation"
            (click)="onToggleNavBar(NavigationTypes.CHANGELOG)"
          >
            <mat-icon>menu</mat-icon>
            <cars-alt-accessibility
              [altProperties]="altProperties['toggle-menu']"
              (onSelect)="onToggleNavBar(NavigationTypes.CHANGELOG)"
            ></cars-alt-accessibility>
          </button>
          <button
            mat-button
            class="button-markup-toggle markup-mode"
            [class.active]="currentView.isChangelogMarkup() && publishedDocument"
            [disabled]="currentView.isChangelogMarkup() || !publishedDocument"
            (click)="toggleMarkup()"
            aria-label="Changelog markup mode"
            [matTooltipShowDelay]="tooltipDelay"
            matTooltipPosition="below"
            matTooltip="Changelog markup mode"
          >
            <mat-icon>format_paint</mat-icon>
            <cars-alt-accessibility
              [altProperties]="altProperties['markup-mode']"
              (onSelect)="toggleMarkup()"
            ></cars-alt-accessibility>
          </button>
          <button
            mat-button
            class="button-markup-toggle authoring-mode"
            [class.active]="!currentView.isChangelogMarkup()"
            [disabled]="!currentView.isChangelogMarkup() || !publishedDocument"
            (click)="toggleMarkup()"
            aria-label="Split screen authoring mode"
            [matTooltipShowDelay]="tooltipDelay"
            matTooltipPosition="below"
            matTooltip="Split screen authoring mode"
          >
            <mat-icon>edit</mat-icon>
            <cars-alt-accessibility
              [altProperties]="altProperties['authoring-mode']"
              (onSelect)="toggleMarkup()"
            ></cars-alt-accessibility>
          </button>
          <button
            mat-button
            class="button-markup-toggle"
            (click)="onOpenLinkTable()"
            [disabled]="!publishedDocument"
            [matTooltipShowDelay]="tooltipDelay"
            matTooltipPosition="below"
            aria-label="See whole changelog"
            matTooltip="See whole changelog"
            carsAnalytics="changelog-see-link-table"
          >
            <mat-icon>grid_on</mat-icon>
          </button>
          <cars-document-selector
            [searchType]="DocumentsSearchType.CHANGELOG"
            [initialSelection]="publishedDocument"
            (onSelect)="changePublishedDocument($event)"
          ></cars-document-selector>
        </cars-toolbar>
      </ng-template>
      <div class="loading-document" *ngIf="loading">
        <mat-progress-spinner mode="indeterminate" aria-label="Loading document"></mat-progress-spinner>
      </div>
      <ng-container *ngIf="!publishedDocument">
        <ng-content *ngTemplateOutlet="toolbar"></ng-content>
        <div class="no-selection">Please pick a published document above to start creating a changelog.</div>
      </ng-container>
      <cars-container
        *ngIf="publishedDocument"
        [disableNavigationSidebar]="true"
        [currentView]="publishedView"
        [useViewService]="false"
        [documentFragment]="publishedDocument"
        [navigationName]="NavigationTypes.CHANGELOG"
      >
        <cars-section-proxy
          [alwaysShowCurrent]="false"
          [readonly]="true"
          [section]="publishedSection"
          [currentView]="publishedView"
          [documentToolbar]="publishedDocument ? toolbar : null"
          [padType]="publishedPad"
        ></cars-section-proxy>
      </cars-container>
    </div>
  </div>
  <div class="data-container right-container">
    <ng-template #markupToolbar>
      <cars-toolbar>
        <button
          mat-button
          matTooltip="Toggle Sidebar"
          matTooltipPosition="below"
          [matTooltipShowDelay]="tooltipDelay"
          aria-label="Toggle sidebar"
          *ngIf="!showingAuthoredNavigation"
          (click)="onToggleNavBar(NavigationTypes.DOCUMENT)"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </cars-toolbar>
    </ng-template>
    <cars-container [disableNavigationSidebar]="true" [currentView]="authoredView">
      <cars-section-proxy
        [currentView]="authoredView"
        [documentToolbar]="currentView.isChangelogMarkup() ? markupToolbar : null"
        [readonly]="currentView.isChangelogMarkup()"
        [padType]="authoredPad"
      ></cars-section-proxy>
    </cars-container>
  </div>
</div>
