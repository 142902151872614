import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BlurOption} from 'app/blur-options';
import {ClauseFragment, Fragment, FragmentType} from 'app/fragment/types';
import {AltProperties} from 'app/services/alt-accessibility.service';
import {ClauseService} from 'app/services/clause.service';
import {FragmentService} from 'app/services/fragment.service';
import {ImageService} from 'app/services/image.service';
import {ToolbarItem} from 'app/suite-config/configuration.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'cars-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss', '../toolbar-buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploadComponent implements OnInit, OnDestroy {
  @Input() public toolbarItem: ToolbarItem;
  @Input() public altProperties: AltProperties;

  public enabled: boolean;

  public currentClause: ClauseFragment;
  public clauseToUploadTo: ClauseFragment;
  public readonly tooltipDelay = environment.tooltipDelay;
  public readonly BlurOption: typeof BlurOption = BlurOption;

  private _subscriptions: Subscription[] = [];

  constructor(
    private clauseService: ClauseService,
    private fragmentService: FragmentService,
    private imageService: ImageService,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this.clauseService.onSelection((clause: ClauseFragment) => {
        this.enabled = this.toolbarItem.enabled(clause);
        this.altProperties.condition = this.enabled;
        this.currentClause = clause;
        this.cd.markForCheck();
      }),
      this.fragmentService.onSelection((fragment: Fragment) => {
        if (
          !fragment?.is(FragmentType.CLAUSE) &&
          fragment?.findAncestorWithType(FragmentType.TABLE) &&
          fragment?.findAncestorWithType(FragmentType.CLAUSE_GROUP)
        ) {
          this.enabled = this.toolbarItem.enabled(fragment);
          this.altProperties.condition = this.enabled;
          this.cd.markForCheck();
        }
      })
    );
  }

  /**
   * Destroy the component
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public handleUpload(event: Event): void {
    this.imageService.handleFigureCreation(event, this.clauseToUploadTo);
    this.cd.markForCheck();
  }

  /* Block blur. */
  mousedown(event: MouseEvent) {
    this.cd.markForCheck();
    event.preventDefault();
  }

  public handleAltSelect(): void {
    this.cd.markForCheck();
    this.clauseToUploadTo = this.currentClause;
    document.getElementById('image-upload-input').click();
  }

  public onClick(): void {
    this.clauseToUploadTo = this.currentClause;
  }

  public uploadImageInputClick(): void {
    document.getElementById('image-upload-input').click();
  }
}
