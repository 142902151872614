import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {OfflineVersionStatusComponent} from './offline-version-status.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatTooltipModule, MatButtonModule, MatProgressSpinnerModule],
  declarations: [OfflineVersionStatusComponent],
  exports: [OfflineVersionStatusComponent],
})
export class OfflineVersionStatusModule {}
