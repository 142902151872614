import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';
import {WebSocketService} from '../websocket/websocket.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root',
})
export class DisabledUserService extends BaseService {
  private static readonly DISABLED_USER_URL: string = `${environment.apiHost}/disabledUsers`;
  private static readonly DISABLED_USER_TOPIC: string = '/topic/disabledUsers';

  private _websocketSubscription: Subscription;

  private _userStatusSubject: BehaviorSubject<Record<string, boolean>> = new BehaviorSubject({});

  constructor(
    private _userService: UserService,
    private _webSocketService: WebSocketService,
    private _httpClient: HttpClient,
    protected _snackbar: MatSnackBar
  ) {
    super(_snackbar);

    this._webSocketService.onConnection(this._onWebsocketConnect.bind(this));
  }

  public onUserStatuses(): Observable<Record<string, boolean>> {
    return this._userStatusSubject;
  }

  public getAllUserStatuses(): Record<string, boolean> {
    return this._userStatusSubject.getValue();
  }

  /**
   * Populate the behaviour subject with the statuses for the document of the given id.
   */
  public populateStatusesForDocument(documentId: UUID): Promise<void> {
    return this._httpClient
      .get(`${DisabledUserService.DISABLED_USER_URL}/${documentId.value}`)
      .pipe(
        map((response: any) => this._userStatusSubject.next(this._extractMap(response))),
        catchError((response) => {
          this._handleError(response, 'Cannot check if users disabled in this document');
          return Promise.reject(null);
        })
      )
      .toPromise();
  }

  public getStatusForCurrentUser(documentId: UUID): Promise<boolean> {
    const currentId: UUID = this._userService.getUser().id;
    const currentStatuses: Record<string, boolean> = this.getAllUserStatuses();
    if (currentId.value in currentStatuses) {
      return Promise.resolve(currentStatuses[currentId.value]);
    }

    return this.getStatusForUsers(documentId, currentId).then(
      (entry: Record<string, boolean>) => entry[currentId.value]
    );
  }

  public getStatusForUsers(documentId: UUID, ...userIds: UUID[]): Promise<Record<string, boolean>> {
    const missingUsers: UUID[] = this._getUsersMissingFromSubject(userIds);

    return this._fetchStatusForUsers(documentId, ...missingUsers).then((userStatuses: Record<string, boolean>) => {
      const currentStatuses: Record<string, boolean> = this.getAllUserStatuses();
      Object.keys(userStatuses).forEach((key: string) => (currentStatuses[key] = userStatuses[key]));
      return userIds.reduce((returnMap: Record<string, boolean>, currentId: UUID) => {
        returnMap[currentId.value] = currentStatuses[currentId.value];
        return returnMap;
      }, {});
    });
  }

  private _fetchStatusForUsers(documentId: UUID, ...userIds: UUID[]): Promise<Record<string, boolean>> {
    if (userIds.length < 1) {
      return Promise.resolve({});
    }

    const params: {[params: string]: string | string[]} = {
      userIds: userIds.map((id: UUID) => id.value),
    };

    return this._httpClient
      .get(`${DisabledUserService.DISABLED_USER_URL}/${documentId.value}/users`, {params})
      .pipe(
        map((response: any) => this._extractMap(response)),
        catchError((response) => this.handleError(response, 'Cannot check if users disabled in this document'))
      )
      .toPromise();
  }

  public updateUserStatus(documentId: UUID, userId: UUID, disabled: boolean): Promise<Record<string, boolean>> {
    const params: {[params: string]: string} = {
      disabled: String(disabled),
    };
    return this._httpClient
      .patch(`${DisabledUserService.DISABLED_USER_URL}/${documentId.value}/users/${userId.value}`, {}, {params})
      .pipe(
        map((response: any) => {
          const status: Record<string, boolean> = this._extractMap(response);
          this._updateSubject(status);
          return status;
        }),
        catchError((response) => this.handleError(response, 'Cannot check if users disabled in this document'))
      )
      .toPromise();
  }

  public updateAllUserStatuses(documentId: UUID, disabled: boolean): Promise<Record<string, boolean>> {
    const params: {[params: string]: string} = {
      disabled: String(disabled),
    };
    return this._httpClient
      .patch(`${DisabledUserService.DISABLED_USER_URL}/${documentId.value}/users`, {}, {params})
      .pipe(
        map((response: any) => {
          const statuses: Record<string, boolean> = this._extractMap(response);
          this._updateSubject(statuses);
          return statuses;
        }),
        catchError((response) => this.handleError(response, 'Cannot check if users disabled in this document'))
      )
      .toPromise();
  }

  /**
   * Handles websocket connect and disconnect events.
   *
   * @param connected {boolean} True if a connect event
   */
  private _onWebsocketConnect(connected: boolean): void {
    if (this._websocketSubscription) {
      this._websocketSubscription.unsubscribe();
    }

    if (connected) {
      this._subscriptions.push(
        this._webSocketService.subscribe(DisabledUserService.DISABLED_USER_TOPIC, (data: any) => {
          const updatedStatuses: Record<string, boolean> = this._extractMap(data);
          this._updateSubject(updatedStatuses);
        })
      );
    }
  }

  private _updateSubject(updatedStatuses: Record<string, boolean>): void {
    const currentStatuses: Record<string, boolean> = this.getAllUserStatuses();
    Object.keys(updatedStatuses).forEach((key: string) => (currentStatuses[key] = updatedStatuses[key]));
    this._userStatusSubject.next(currentStatuses);
  }

  private _getUsersMissingFromSubject(userIds: UUID[]): UUID[] {
    const currentStatuses: Record<string, boolean> = this.getAllUserStatuses();
    return userIds.filter((id: UUID) => !(id.value in currentStatuses));
  }

  private _extractMap(response: any): Record<string, boolean> {
    const array: any[] = this._toArray(response);
    return array.reduce((returnMap: Record<string, boolean>, current: any) => {
      returnMap[current.userId] = current.disabled;
      return returnMap;
    }, {});
  }

  private handleError(response: any, message: string): Promise<Record<string, boolean>> {
    this._handleError(response, message);
    return Promise.reject(null);
  }
}
