import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {ChangeSummaryChangeType} from 'app/clause-change-summary/types/change-summary-change-type';
import {AbstractClauseChangeSummaryRow} from 'app/clause-change-summary/types/change-summary-row';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {ClauseFragment} from 'app/fragment/types';
import {ClauseChangeSummaryClauseCellIndexerService} from './clause-change-summary-clause-cell-indexer.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[cars-clause-change-summary-clause-cell]',
  templateUrl: './clause-change-summary-clause-cell.component.html',
  styleUrls: ['./clause-change-summary-clause-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: FragmentIndexService, useExisting: ClauseChangeSummaryClauseCellIndexerService},
    ClauseChangeSummaryClauseCellIndexerService,
  ],
})
export class ClauseChangeSummaryClauseCellComponent implements OnChanges {
  @Input() public row: AbstractClauseChangeSummaryRow;
  @Input() public isOldClause: boolean;

  public stateDisplayString: string;
  public clause: ClauseFragment;

  constructor(private _clauseChangeSummaryClauseCellIndexerService: ClauseChangeSummaryClauseCellIndexerService) {}

  public ngOnChanges(): void {
    if (this.isOldClause) {
      if (this.row.changeType !== ChangeSummaryChangeType.CREATED) {
        // Cell is for the old version's clause - show the old clause fragment
        this.clause = this.row.oldFragment;
        this._clauseChangeSummaryClauseCellIndexerService.setOldIndexMap(this.row.oldChildIndexMap);
      } else {
        // Indicate that clause is new with respect to the old document version
        this.clause = null;
        this.stateDisplayString = 'New';
      }
    } else {
      // Cell is for the new version's clause - show the new clause fragment
      this.clause = this.row.newFragment;
      this._clauseChangeSummaryClauseCellIndexerService.setNewIndexMap(this.row.newChildIndexMap);
      this._clauseChangeSummaryClauseCellIndexerService.setOldIndexMap(this.row.oldChildIndexMap);
    }
  }
}
