import {Logger} from 'app/error-handling/services/logger/logger.service';
import {Token, TokenType} from 'app/fragment/core/parser/token';
import {TableCellFragment, TableFragment, TableRowFragment} from 'app/fragment/table/table-fragment';
import {
  ClauseFragment,
  ClauseType,
  Fragment,
  FragmentType,
  ListFragment,
  ListItemFragment,
  MemoFragment,
  SubscriptFragment,
  SuperscriptFragment,
  TextFragment,
} from 'app/fragment/types';

export class Lexer {
  /**
   * Perform lexical analysis on an array of tokens, converting them into the AST of
   * corresponding fragments.  This is via a recursive descent strategy.
   *
   * @param tokens {Token[]}      The tokens to lex
   * @returns      {Fragment[]}   The fragment AST
   */
  public lex(tokens: Token[]): Fragment[] {
    const fragments: Fragment[] = [];

    for (let i: number = 0; i < tokens.length /* no increment */; ) {
      const token: Token = tokens[i];

      // Helper function for lexing fragment types with children.
      const lexChildren = (breakingTypes: (FragmentType | TokenType)[]): Fragment[] => {
        let end: number = i + 1;
        while (end < tokens.length && !breakingTypes.includes(tokens[end].type)) {
          ++end;
        }
        const children: Fragment[] = this.lex(tokens.slice(i + 1, end));
        i = end;
        return children;
      };

      // Helper function for lexing fragment types with no children (which extend TextFragment)
      const lexChildless = (constructor: typeof TextFragment): void => {
        if (token.value.length > 0) {
          fragments.push(new constructor(null, token.value));
        }
        ++i;
      };

      switch (token.type) {
        case FragmentType.TEXT:
          {
            lexChildless(TextFragment);
          }
          break;

        case FragmentType.SUBSCRIPT:
          {
            lexChildless(SubscriptFragment);
          }
          break;

        case FragmentType.SUPERSCRIPT:
          {
            lexChildless(SuperscriptFragment);
          }
          break;

        case FragmentType.MEMO:
          {
            lexChildless(MemoFragment);
          }
          break;

        case FragmentType.CLAUSE:
          {
            const clauseType: ClauseType = ClauseType[token.value.toUpperCase()];
            const children: Fragment[] = lexChildren([FragmentType.CLAUSE]);
            if (!children.length) {
              children.push(new TextFragment(null));
            }
            fragments.push(new ClauseFragment(null, clauseType, children, ''));
          }
          break;

        case FragmentType.LIST_ITEM:
          {
            // list of token types which force the list to end
            const breakingTypes: (FragmentType | TokenType)[] = [
              FragmentType.CLAUSE,
              FragmentType.TABLE,
              FragmentType.TABLE_ROW,
              FragmentType.TABLE_CELL,
              TokenType.TABLE_END,
              TokenType.CAPTION,
            ];
            // Just use the first item to tell if the list is ordered
            // eslint-disable-next-line  eqeqeq
            const ordered: boolean = tokens[i].value == 'true';
            const items: ListItemFragment[] = [];
            let endList: boolean = false;

            while (i < tokens.length && !endList) {
              let end: number = i + 1;
              while (
                end < tokens.length &&
                !breakingTypes.includes(tokens[end].type) &&
                tokens[end].type !== FragmentType.LIST_ITEM
              ) {
                ++end;
              }

              const children: Fragment[] = this.lex(tokens.slice(i + 1, end));
              if (!children.length) {
                children.push(new TextFragment(null));
              }
              items.push(new ListItemFragment(null, children, tokens[i].indented));
              i = end;

              if (tokens[end] && breakingTypes.includes(tokens[end].type)) {
                endList = true;
              }
            }

            fragments.push(new ListFragment(null, items, ordered));
          }
          break;

        case FragmentType.TABLE:
          {
            const caption: string =
              tokens[i - 1] && tokens[i - 1].type === TokenType.CAPTION ? tokens[i - 1].value : '';
            const children: TableRowFragment[] = lexChildren([
              FragmentType.CLAUSE,
              FragmentType.TABLE,
              TokenType.TABLE_END,
            ]) as TableRowFragment[];
            if (children.length) {
              // Don't allow empty tables.
              fragments.push(new TableFragment(null, children, caption));
            }
          }
          break;

        case FragmentType.TABLE_ROW:
          {
            const children: TableCellFragment[] = lexChildren([
              FragmentType.CLAUSE,
              FragmentType.TABLE,
              TokenType.TABLE_END,
              FragmentType.TABLE_ROW,
            ]) as TableCellFragment[];
            fragments.push(new TableRowFragment(null, children));
          }
          break;

        case FragmentType.TABLE_CELL:
          {
            const cell: TableCellFragment = new TableCellFragment(
              null,
              lexChildren([
                FragmentType.CLAUSE,
                FragmentType.TABLE,
                TokenType.TABLE_END,
                FragmentType.TABLE_ROW,
                FragmentType.TABLE_CELL,
              ])
            );
            cell.bold = token.bold;
            cell.rowSpan = token.rowSpan;
            cell.colSpan = token.colSpan;
            cell.deleted = token.deleted;
            if (!cell.children.length) {
              cell.children.push(new TextFragment(null));
            }
            fragments.push(cell);
          }
          break;

        case TokenType.TABLE_END: // Do nothing
        case TokenType.CAPTION:
          {
            // Do nothing, handled by the table.
            ++i;
          }
          break;

        default: {
          Logger.error('paste-error', `Unable to lex token with type ${FragmentType[token.type]}`);
          ++i;
        }
      }
    }
    return fragments;
  }
}
