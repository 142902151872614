import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Administration} from 'app/documents/administrations';
import {MchwDocumentType} from 'app/fragment/types';
import {MchwExportRequest, VersionTag} from 'app/interfaces';
import {ExportType} from 'app/services/export.service';

@Component({
  selector: 'cars-mchw-export-selector',
  templateUrl: './mchw-export-selector.component.html',
  styleUrls: ['./mchw-export-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MchwExportSelectorComponent implements OnInit {
  constructor(
    private _dialogRef: MatDialogRef<MchwExportSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {fragmentId: string; versionTag: VersionTag; exportType: ExportType}
  ) {
    if (data.exportType === ExportType.HTML_NOT_FOR_PUBLICATION) {
      this.title = 'HTML Export Options';
    } else {
      this.title = 'PDF Export Options';
    }
    this.fragmentId = data.fragmentId;
    this.exportType = data.exportType;
    this.versionTag = data.versionTag;
  }

  public title: string;

  public selectAll: boolean = false;

  public fragmentId: string;

  public exportType: ExportType;

  public versionTag: VersionTag;

  public administrations: Administration[];

  public exportCore: boolean = false;
  public exportNorthernIreland: boolean = false;
  public exportEngland: boolean = false;
  public exportScotland: boolean = false;
  public exportWales: boolean = false;

  public documentTypes: MchwDocumentType[];

  public exportIfs: boolean = false;
  public exportShw: boolean = false;
  public exportOds: boolean = false;
  public exportOdsReview: boolean = false;

  ngOnInit() {
    this._dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this._dialogRef.close(null);
      }
    });
  }

  public toggleSelectAll(): void {
    this.exportCore = !this.selectAll;
    this.exportNorthernIreland = !this.selectAll;
    this.exportEngland = !this.selectAll;
    this.exportScotland = !this.selectAll;
    this.exportWales = !this.selectAll;
    this.exportIfs = !this.selectAll;
    this.exportShw = !this.selectAll;
    this.exportOds = !this.selectAll;
    this.exportOdsReview = !this.selectAll;
  }

  public clearSelectAll(): void {
    this.selectAll = false;
  }

  public exportDisabled(): boolean {
    return (
      !this._onlyReviewTemplateSelected() &&
      (this._noDocumentTypesSelected() || this._noDocumentVariationsSelected() || this._onlyCoreAndOdsSelected())
    );
  }

  public prepareMchwExportRequest(): void {
    this.administrations = [];
    this.documentTypes = [];

    if (this.exportCore) {
      this.administrations.push(null);
    }

    if (this.exportEngland) {
      this.administrations.push(Administration.ENGLAND);
    }

    if (this.exportNorthernIreland) {
      this.administrations.push(Administration.NORTHERN_IRELAND);
    }

    if (this.exportScotland) {
      this.administrations.push(Administration.SCOTLAND);
    }

    if (this.exportWales) {
      this.administrations.push(Administration.WALES);
    }

    if (this.exportShw) {
      this.documentTypes.push(MchwDocumentType.SHW);
    }

    if (this.exportIfs) {
      this.documentTypes.push(MchwDocumentType.IFS);
    }

    const exportRequest: MchwExportRequest = {
      fragmentId: this.fragmentId,
      exportType: this.exportType,
      versionId: this.versionTag ? this.versionTag.versionId.value : null,
      administrations: this.administrations,
      documentTypes: this.documentTypes,
      exportOds: this.exportOds,
      exportOdsReview: this.exportOdsReview,
    };

    this._dialogRef.close(exportRequest);
  }

  public cancel(): void {
    this._dialogRef.close(null);
  }

  private _noDocumentVariationsSelected(): boolean {
    return (
      !this.exportCore &&
      !this.exportNorthernIreland &&
      !this.exportEngland &&
      !this.exportScotland &&
      !this.exportWales
    );
  }

  private _noDocumentTypesSelected(): boolean {
    return !this.exportIfs && !this.exportShw && !this.exportOds;
  }

  private _onlyCoreAndOdsSelected(): boolean {
    return (
      this.exportCore &&
      this.exportOds &&
      !this.exportNorthernIreland &&
      !this.exportEngland &&
      !this.exportScotland &&
      !this.exportWales &&
      !this.exportIfs &&
      !this.exportShw
    );
  }

  private _onlyReviewTemplateSelected(): boolean {
    return (
      this.exportOdsReview &&
      !this.exportOds &&
      !this.exportIfs &&
      !this.exportShw &&
      this._noDocumentVariationsSelected()
    );
  }
}
