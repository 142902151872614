/**
 * An enumeration of the available types for a VersionTag in CARS.
 */
export enum VersionTagType {
  DRAFT_FOR_PEER_REVIEW = 'DRAFT_FOR_PEER_REVIEW',
  DRAFT_FOR_CONSULTATION = 'DRAFT_FOR_CONSULTATION',
  FOR_PUBLICATION = 'FOR_PUBLICATION', // This is displayed as 'For approval/authorisation'
  FOR_PUBLICATION_CHANGES_REQUIRED = 'FOR_PUBLICATION_CHANGES_REQUIRED',
  FOR_PUBLICATION_PUBLISHED = 'FOR_PUBLICATION_PUBLISHED',
  NOT_KNOWN = 'NOT_KNOWN',
  TEMPLATE = 'TEMPLATE',
  REVERT_SNAPSHOT = 'REVERT_SNAPSHOT',
}
