import {Pipe, PipeTransform} from '@angular/core';
import {StandardFormatType} from 'app/fragment/types/standard-format-type';

export const STANDARD_FORMAT_TYPE_TO_DISPLAY_NAME: Readonly<Record<StandardFormatType, string>> = {
  SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS: 'DEPRECATED - Schedule in the works specific requirements',
  SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS_V2: 'Schedule in the works specific requirements',
  VERIFICATION_WITHIN_SHW: 'Verification within SHW',
  VERIFICATION_WITHIN_WSR: 'Verification within WSR',
  VERIFICATION_WITHIN_SHW_NO_REPLACEMENT:
    'DEPRECATED - Verification within SHW (no requirement for ([replacement for Clause 105]))',
  VERIFICATION_WITHIN_WSR_NO_REPLACEMENT:
    'DEPRECATED - Verification within WSR (no requirement for ([replacement for Clause 105]))',
  VERIFICATION_REQUIRES_ACCREDITED_TESTING_LABORATORY: 'Verification requires accredited testing laboratory',
  DOCUMENTATION: 'DEPRECATED - Documentation',
  DOCUMENTATION_GENERAL_REQUIREMENTS: 'Documentation (GC 101)',
  DOCUMENTATION_SPECIFIC_TIMESCALES: 'Documentation specific timescales',
  DOCUMENTATION_TECHNICAL_APPROVAL: 'DEPRECATED - Documentation (technical approval)',
  DOCUMENTATION_CONTINUOUS_RECORDS: 'DEPRECATED - Documentation (continuous records)',
  DOCUMENTATION_CONTINUOUS_RECORDS_V2: 'Documentation (continuous records)',
  DOCUMENTATION_WSR: 'DEPRECATED - Documentation (WSR)',
  DOCUMENTATION_V2: 'Documentation',
  DOCUMENTATION_WSR_V2: 'Documentation in WSR',
  CONSTRUCTOR_DESIGN: 'Constructor design',
  CONSTRUCTOR_DESIGN_INCLUDED_WORKS_SPECIFIC_REQUIREMENTS:
    'Constructor design (included in the works specific requirements)',
  CONSTRUCTOR_DESIGN_REQUIRING_TECHNICAL_APPROVAL: 'Constructor design (requiring technical approval)',
  HARMONISED_STANDARD_LIST: 'Harmonised Standard (list)',
  HARMONISED_STANDARD_TABLE: 'Harmonised Standard (table)',
  HARMONISED_STANDARD_LIST_INCLUDED_WORKS_SPECIFIC_REQUIREMENTS:
    'DEPRECATED - Harmonised Standard (list + included in the works specific requirements)',
  HARMONISED_STANDARD_TABLE_INCLUDED_WORKS_SPECIFIC_REQUIREMENTS:
    'DEPRECATED - Harmonised Standard (table + included in the works specific requirements)',
  PRODUCT_CERTIFICATION_SCHEMES_LIST: 'Products covered by Product Certification Schemes (list)',
  PRODUCT_CERTIFICATION_SCHEMES_TABLE: 'Products covered by Product Certification Schemes (table)',
  PRODUCT_ACCEPTANCE_SCHEMES: 'Product Acceptance Schemes',
  PRODUCTS_COVERED_BY_STANDARDS_SHW_LIST: 'Products covered by non-designated or non-harmonised standards (SHW list)',
  PRODUCTS_COVERED_BY_STANDARDS_SHW_TABLE: 'Products covered by non-designated or non-harmonised standards (SHW table)',
  PRODUCTS_COVERED_BY_STANDARDS_LIST_AND_WSR:
    'DEPRECATED - Products covered by non-designated or non-harmonised standards (list + WSR)',
  PRODUCTS_COVERED_BY_STANDARDS_TABLE_AND_WSR:
    'DEPRECATED - Products covered by non-designated or non-harmonised standards (table + WSR)',
  PRODUCTS_COVERED_BY_STANDARDS_WSR:
    'DEPRECATED - Products covered by non-designated or non-harmonised standards (WSR)',
  PRODUCTS_COVERED_BY_STANDARDS: 'Products covered by non-designated or non-harmonised standards',
  NATIONAL_HIGHWAY_SECTOR_SCHEMES: 'National Highway Sector Schemes',
  WEATHER_RESTRICTIONS_LIST: 'DEPRECATED - Weather restrictions (list)',
  WEATHER_RESTRICTIONS_TABLE: 'DEPRECATED - Weather restrictions (table)',
  GENERAL_REQUIREMENT_IN_SHW: 'Requirement in SHW',
  GENERAL_REQUIREMENT_OPTION_TO_CHANGE_REQUIREMENT_IN_WSR: 'SHW with option to change requirement in WSR',
  GENERAL_REQUIREMENT_IN_WSR: 'Requirement in WSR',
  PERFORMANCE_CHARACTERISTICS_IN_WSR: 'Performance characteristics in WSR',
  SECTION_REFERENCE_SINGLE: 'Section reference (single)',
  SECTION_REFERENCE_MULTIPLE: 'Section reference (multiple)',
};

@Pipe({
  name: 'standardFormatTypeDisplayName',
})
export class StandardFormatTypeToDisplayNamePipe implements PipeTransform {
  private static readonly UNKNOWN_TYPE: string = 'Unknown Standard Format Requirement Type';

  /**
   * Transforms the standard format type enum into a friendly display name.
   * The {@link STANDARD_FORMAT_TYPE_TO_DISPLAY_NAME} const is exported for consumers wanting to use this within typescript files.
   *
   * @param type  {StandardFormatType}    The standard format type
   * @returns     {string}                The display name
   */
  public transform(type: StandardFormatType): any {
    return !!STANDARD_FORMAT_TYPE_TO_DISPLAY_NAME[type]
      ? STANDARD_FORMAT_TYPE_TO_DISPLAY_NAME[type]
      : StandardFormatTypeToDisplayNamePipe.UNKNOWN_TYPE;
  }
}
