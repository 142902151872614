import {Component, Input, OnInit} from '@angular/core';
import {DiscussionSummary} from 'app/dashboard/aggregates/discussion-summary';
import {SectionDiscussionsAggregate} from 'app/dashboard/aggregates/section-discussions-aggregate';
import {Dashboard, DashboardService} from 'app/services/dashboard.service';
import {AuthenticationProvider} from 'app/services/user/authentication-provider';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';

@Component({
  selector: 'cars-section-table',
  templateUrl: './section-table.component.html',
  styleUrls: ['./section-table.component.scss'],
})
export class SectionTableComponent implements OnInit {
  @Input() section: SectionDiscussionsAggregate;
  @Input() versionId: UUID;
  @Input() dashboardType: Dashboard;
  public loading: boolean;
  public discussions: DiscussionSummary[] = [];
  public toolTipDelay: number = environment.tooltipDelay;
  public readonly displayedColumns: string[] = [
    'content',
    'discussion_text',
    'raised_by',
    'comments',
    'latest_comment',
    'link',
    'info_icon',
    'all_resolved',
  ];

  constructor(private dashboardService: DashboardService, private authProvider: AuthenticationProvider) {}

  ngOnInit() {
    if (this.section) {
      this._fetchData();
    }
  }

  public getSectionUrl(discussion: DiscussionSummary): string {
    let url: string = `/documents/${discussion.documentId.value}`;

    if (discussion.versionId) {
      url = `${url}/versions/${discussion.versionId.value}/sections/${discussion.sectionId.value}`;
    } else {
      url = `${url}/sections/${discussion.sectionId.value}`;
    }

    return url;
  }

  public currentUserDiscussion(discussion: DiscussionSummary): boolean {
    return this.authProvider.getCurrentUser().id.value === discussion.raisedBy.value;
  }

  private _fetchData(): void {
    this.loading = true;
    const discussionSummariesPromise: Promise<DiscussionSummary[]> = this.versionId
      ? this.dashboardService.getVersionDiscussionsSummariesForSection(
          this.section.documentId,
          this.versionId,
          this.section.sectionId,
          this.dashboardType
        )
      : this.dashboardService.getLiveDiscussionsSummariesForSection(
          this.section.documentId,
          this.section.sectionId,
          this.dashboardType
        );
    discussionSummariesPromise
      .then((response: DiscussionSummary[]) => {
        this.discussions = response;
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }
}
