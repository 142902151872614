import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DocumentRole} from 'app/documents/document-data';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';

type AccessMode = 'AUTHOR' | 'PEER_REVIEWER' | 'REVIEWER';

type VersionInformation =
  | 'ROLE'
  | 'AVAILABLE_TO_READ'
  | 'NOT_AVAILABLE_TO_READ'
  | 'AVAILABLE_TO_COMMENT'
  | 'NOT_AVAILABLE_TO_COMMENT'
  | 'COLLABORATION_ENABLED'
  | 'COLLABORATION_DISABLED'
  | 'CAN_VIEW_COMMENTS_IN_LIVE';

@Component({
  selector: 'cars-version-information',
  templateUrl: './version-information.component.html',
  styleUrls: ['./version-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionInformationComponent implements OnInit, OnDestroy {
  public static readonly READER_INFO: Record<AccessMode, Record<VersionInformation, string>> = {
    AUTHOR: {
      ROLE: 'You are assigned as an author.',
      AVAILABLE_TO_READ: 'This version is available to authors, reviewers and peer reviewers.',
      NOT_AVAILABLE_TO_READ: 'This version is only available to authors and peer reviewers - not reviewers.',
      AVAILABLE_TO_COMMENT: 'You can make comments on this version.',
      NOT_AVAILABLE_TO_COMMENT: 'You cannot comment on this version.',
      COLLABORATION_ENABLED:
        'Anyone with the permission to see this version can see and discuss your comments. ' +
        `You can also see other people's discussions.`,
      COLLABORATION_DISABLED: 'Reviewers cannot see your comments, but authors and peer reviewers can.',
      CAN_VIEW_COMMENTS_IN_LIVE: 'You can also view comments made in this version in the live document.',
    },
    PEER_REVIEWER: {
      ROLE: 'You are assigned as a peer reviewer.',
      AVAILABLE_TO_READ: 'This version is available to authors, reviewers and peer reviewers.',
      NOT_AVAILABLE_TO_READ: 'This version is only available to authors and peer reviewers - not reviewers.',
      AVAILABLE_TO_COMMENT: 'You can make comments on this version.',
      NOT_AVAILABLE_TO_COMMENT: 'You cannot comment on this version.',
      COLLABORATION_ENABLED:
        'Anyone with the permission to see this version can see and discuss your comments. ' +
        `You can also see other people's discussions.`,
      COLLABORATION_DISABLED: 'Reviewers cannot see your comments, but authors and peer reviewers can.',
      CAN_VIEW_COMMENTS_IN_LIVE: 'You can also view comments made in this version in the live document.',
    },
    REVIEWER: {
      ROLE: 'You are assigned as a reviewer.',
      AVAILABLE_TO_READ: 'This version is available to authors, reviewers and peer reviewers.',
      NOT_AVAILABLE_TO_READ: '', // Not possible
      AVAILABLE_TO_COMMENT: 'You can make comments on this version.',
      NOT_AVAILABLE_TO_COMMENT: 'You cannot comment on this version, as the authors have closed it for comments.',
      COLLABORATION_ENABLED:
        'Anyone with the permission to see this version can see and discuss your comments. ' +
        `You can also see other people's discussions.`,
      COLLABORATION_DISABLED: 'Other reviewers will not see your comments, but the authoring team will.',
      CAN_VIEW_COMMENTS_IN_LIVE: '', // not possible
    },
  };

  public readonly VersionTagType: typeof VersionTagType = VersionTagType;
  public readonly dateFormat: string = 'dd/MM/yy';

  public versionTag: VersionTag;

  public information: string[] = [];

  private _currentView: CurrentView;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _viewService: ViewService,
    private _versioningService: VersioningService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        this._currentView = currentView;
        this.information = this.getInformationToDisplay();
        this._cdr.markForCheck();
      }),

      this._versioningService.getCurrentViewVersionTagObsStream().subscribe((versionTag: VersionTag) => {
        this.versionTag = versionTag;
        this.information = this.getInformationToDisplay();
        this._cdr.markForCheck();
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  public getInformationToDisplay(): string[] {
    if (this._currentView && this.versionTag && this._currentView.userRole) {
      const mode: AccessMode = this.toAccessMode(this._currentView.userRole);
      const info: Record<VersionInformation, string> = VersionInformationComponent.READER_INFO[mode];
      const canRead: boolean = this.versionTag.availableToReview;
      const canComment: boolean = this.versionTag.availableForCommenting;
      const canCollaborate: boolean = !this.versionTag.userDiscussionsOnly;
      const toDisplay: string[] = [];

      toDisplay.push(info['ROLE']);
      toDisplay.push(canRead ? info['AVAILABLE_TO_READ'] : info['NOT_AVAILABLE_TO_READ']);
      toDisplay.push(canComment ? info['AVAILABLE_TO_COMMENT'] : info['NOT_AVAILABLE_TO_COMMENT']);
      if (canComment) {
        toDisplay.push(canCollaborate ? info['COLLABORATION_ENABLED'] : info['COLLABORATION_DISABLED']);
        if (mode !== 'REVIEWER') {
          toDisplay.push(info['CAN_VIEW_COMMENTS_IN_LIVE']);
        }
      }
      return toDisplay;
    } else {
      return [];
    }
  }

  private toAccessMode(role: DocumentRole): AccessMode {
    if (role === DocumentRole.AUTHOR || role === DocumentRole.LEAD_AUTHOR || role === DocumentRole.OWNER) {
      return 'AUTHOR';
    } else if (role === DocumentRole.PEER_REVIEWER) {
      return 'PEER_REVIEWER';
    } else if (role === DocumentRole.REVIEWER) {
      return 'REVIEWER';
    }
  }
}
