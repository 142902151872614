import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {DebounceSearchComponent} from './debounce-search.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatInputModule],
  declarations: [DebounceSearchComponent],
  exports: [DebounceSearchComponent],
})
export class DebounceSearchModule {}
