import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {BaseService} from 'app/services/base.service';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {ChangeSummaryRow, ClauseChangeSummaryRow, SectionChangeSummaryRow} from './types/change-summary-row';

@Injectable({
  providedIn: 'root',
})
export class ClauseChangeSummaryService extends BaseService implements OnDestroy {
  private static readonly GET_SUMMARY_ENDPOINT = `${environment.apiHost}/clause-change-summary/get-for-document`;
  private static readonly GET_CHANGED_CLAUSES_SUMMARY_ENDPOINT = `${environment.apiHost}/clause-change-summary/get-changed-clauses-for-document`;

  constructor(
    private _versioningService: VersioningService,
    private _http: HttpClient,
    protected _snackbar: MatSnackBar
  ) {
    super(_snackbar);
  }

  /**
   * Gets the clause change summary for the given document between the given version (or live if null) and the previous
   * published version. This is a snapshot from when the request was made and does not update if another user changes
   * the document.
   *
   * @param documentId  {UUID} The documentId to get the summary for.
   * @param versionId   {UUID} The version to fetch the summary for, or live if null.
   */
  public getSummary(documentId: UUID, oldVersionId: UUID, newVersionId: UUID): Observable<SectionChangeSummaryRow[]> {
    return this._getChangeSummaryRows(documentId, oldVersionId, newVersionId);
  }

  /**
   * Gets the changed clauses summary for the given document between the given version (or live if null) and the previous
   * published version. This is a snapshot from when the request was made and does not update if another user changes
   * the document.
   *
   * @param documentId  {UUID} The documentId to get the summary for.
   * @param versionId   {UUID} The version to fetch the summary for, or live if null.
   */
  public getChangedClausesSummary(documentId: UUID, versionId: UUID): Observable<ClauseChangeSummaryRow[]> {
    const params = {
      documentId: documentId.value,
    };
    if (!!versionId) {
      params['versionId'] = versionId.value;
    }
    return this._http.get<any[]>(ClauseChangeSummaryService.GET_CHANGED_CLAUSES_SUMMARY_ENDPOINT, {params}).pipe(
      map((json: any) => json.map((j) => ChangeSummaryRow.deserialise(j) as ClauseChangeSummaryRow)),
      catchError((response) => {
        this._handleError(response, 'Cannot load clause change summary for this document');
        return throwError(response);
      })
    );
  }

  private _getChangeSummaryRows(
    documentId: UUID,
    oldVersionId: UUID,
    newVersionId: UUID
  ): Observable<SectionChangeSummaryRow[]> {
    const params = {
      documentId: documentId.value,
      oldVersionId: oldVersionId.value,
    };

    if (!!newVersionId) {
      params['newVersionId'] = newVersionId.value;
    }
    return this._http.get<any[]>(ClauseChangeSummaryService.GET_SUMMARY_ENDPOINT, {params}).pipe(
      map((json: any) => json.map((j) => ChangeSummaryRow.deserialise(j) as SectionChangeSummaryRow)),
      catchError((response) => {
        this._handleError(response, 'Cannot load change summary for this document');
        return throwError(response);
      })
    );
  }

  public getPreviousPublishedVersionTag(documentId: UUID, versionId: UUID): Observable<VersionTag> {
    return this._versioningService.getVersionTagsForFragmentId(documentId).pipe(
      take(1),
      map((result: VersionTag[]) => {
        const cutoffDate: number = this._getCreatedDateFromVersionId(result, versionId);
        const previouslyPublished: VersionTag[] = result.filter(
          (tag: VersionTag) =>
            tag.versionTagType === VersionTagType.FOR_PUBLICATION_PUBLISHED &&
            tag.createdAt &&
            tag.createdAt < cutoffDate
        );
        return previouslyPublished?.length > 0 ? previouslyPublished[previouslyPublished.length - 1] : null;
      })
    );
  }

  private _getCreatedDateFromVersionId(versionTags: VersionTag[], versionId: UUID): number {
    const currentDate: number = new Date().valueOf();
    const version: VersionTag = versionTags.find((tag: VersionTag) => tag.versionId.equals(versionId));
    return version?.createdAt ?? currentDate;
  }
}
