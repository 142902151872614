import {Component, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';
import {PadType} from 'app/element-ref.service';
import {Caret} from 'app/fragment/caret';
import {ClauseGroupFragmentComponent} from 'app/fragment/clause-group/clause-group-fragment.component';
import {getFinalEditableDescendant, getFirstEditableDescendant, isClauseGroupOfType} from 'app/fragment/fragment-utils';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {ClauseFragment, ClauseType, Fragment, FragmentType} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {ClauseGroupType} from 'app/fragment/types/clause-group-type';
import {StandardFormatType} from 'app/fragment/types/standard-format-type';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {CaretService} from 'app/services/caret.service';
import {ClauseGroupService} from 'app/services/clause-group.service';
import {FragmentDeletionValidationService} from 'app/services/fragment-deletion-validation.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {ConfigurationService} from 'app/suite-config/configuration.service';
import {environment} from 'environments/environment';

@Component({
  selector: 'cars-move-delete-hovertip',
  templateUrl: './move-delete-hovertip.component.html',
  styleUrls: ['./move-delete-hovertip.component.scss'],
})
export class MoveDeleteHovertipComponent implements OnChanges {
  @Input() public fragment: ClauseFragment | ClauseGroupFragment;
  @Input() public padType: PadType;
  @Output() refreshFrag: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('moveUpTooltip') moveUpTooltip: MatTooltip;
  @ViewChild('moveDownTooltip') moveDownTooltip: MatTooltip;

  public tooltipDelay: number = environment.tooltipDelay;
  public fragmentType: string;

  private _isFragmentSpecifierInstruction: boolean;

  private readonly STANDARD_FORMAT_REQUIREMENT_NAME: string = 'Standard format requirement';
  private readonly NATIONALLY_DETERMINED_REQUIREMENT_NAME: string = 'Nationally determined requirement';
  private readonly SPECIFIER_INSTRUCTION_NAME: string = 'Specifier instruction';

  constructor(
    private _fragmentService: FragmentService,
    private _clauseGroupService: ClauseGroupService,
    private _caretService: CaretService,
    private _selectionOperationsService: SelectionOperationsService,
    private _configurationService: ConfigurationService,
    private _lockService: LockService,
    private _fragmentDeletionValidationService: FragmentDeletionValidationService,
    private _fragmentIndexService: FragmentIndexService
  ) {}

  public ngOnChanges(): void {
    if (this.fragment) {
      this._isFragmentSpecifierInstruction = this.fragment.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION);

      if (isClauseGroupOfType(this.fragment, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT)) {
        this.fragmentType = this.STANDARD_FORMAT_REQUIREMENT_NAME;
      } else if (isClauseGroupOfType(this.fragment, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)) {
        this.fragmentType = this.NATIONALLY_DETERMINED_REQUIREMENT_NAME;
      } else if (this._isFragmentSpecifierInstruction) {
        this.fragmentType = this.SPECIFIER_INSTRUCTION_NAME;
      }
    }
  }

  /**
   * Block event propagation when clicking to prevent bluring of associated fragment.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  @HostListener('mousedown', ['$event'])
  public mouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  public getMoveHovertip(up: boolean, canMove: boolean): string {
    return `${canMove ? 'Move' : 'Unable to move'} ${this.fragmentType} ${this._fragmentIndexService.getIndex(
      this.fragment
    )} ${up ? 'up' : 'down'}`;
  }

  /**
   * Check whether we can move the fragment up or down.
   */
  public canMove(up: boolean): boolean {
    const hasSiblingInDirection: boolean = up ? !!this.fragment.previousSibling() : !!this.fragment.nextSibling();
    const isInClauseGroup: boolean = !!this.fragment.parent.is(FragmentType.CLAUSE_GROUP);

    return (hasSiblingInDirection || isInClauseGroup) && !this._isSfrWithinNdr() && this.canLock();
  }

  /**
   * Check whether the fragment is a sfr that can have a schedule table.
   */
  public isRefreshAvailable(): boolean {
    return (
      this.fragment.is(FragmentType.CLAUSE_GROUP) &&
      (this.fragment as ClauseGroupFragment).clauseGroupType === ClauseGroupType.STANDARD_FORMAT_REQUIREMENT &&
      this.fragment.standardFormatType === StandardFormatType.SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS_V2
    );
  }

  /**
   * Check whether the fragment is a sfr that can have a schedule table.
   */
  public canRefresh(): boolean {
    if (!this.isRefreshAvailable()) {
      return false;
    }

    const fragmentComponent: ClauseGroupFragmentComponent = this.fragment.component as ClauseGroupFragmentComponent;

    return !fragmentComponent.loadingPreview;
  }

  /**
   * Checks whether the clause or clause group can be locked by the current user. For clause groups this checks that
   * all child clauses can be locked.
   */
  public canLock(): boolean {
    return this.fragment.is(FragmentType.CLAUSE)
      ? this._lockService.canLock(this.fragment as ClauseFragment)
      : this._lockService.canLockGroup(this.fragment as ClauseGroupFragment);
  }

  /**
   * Move the fragement up or down.
   */
  public move(up: boolean): void {
    if (this._isFragmentSpecifierInstruction) {
      this._moveSpecifierInstruction(up);
    } else {
      this._moveClause(up);
    }

    this._fragmentService.update(this.fragment);
    const caretPosition: Caret = this._caretService.getCaretPositionFromSelectedFragment();
    this._closeTooltips();

    // settimeout here is used to place the caret back in the selected fragment after mouse events have finished bubbling.
    // otherwise the caret is in the correct place but the mouse events cause it to not be visible.
    if (this._isFragmentSpecifierInstruction) {
      setTimeout(() => {
        this._selectionOperationsService.setSelected(caretPosition.fragment, caretPosition.offset, this.padType);
      }, 0);
    } else {
      this._selectionOperationsService.setSelected(caretPosition.fragment, caretPosition.offset, this.padType);
    }
  }

  public refresh(): void {
    this.refreshFrag.next();
  }

  private _moveSpecifierInstruction(up: boolean): void {
    const indices: number = up ? -1 : 1;
    const parent: Fragment = this.fragment.parent;
    const index: number = this.fragment.index();
    const newIndex: number = index + indices;
    const isSiblingGroup: boolean = parent.children[newIndex]?.is(FragmentType.CLAUSE_GROUP);
    const isOutOfParentBounds: boolean = !parent.children[newIndex];

    if (isSiblingGroup) {
      // move into clause group
      const sibling: Fragment = parent.children[newIndex];
      const newIndexInSibling: number = up ? sibling.children.length : 0;
      this.fragment.remove();
      sibling.children.splice(newIndexInSibling, 0, this.fragment);
    } else if (isOutOfParentBounds && parent.is(FragmentType.CLAUSE_GROUP)) {
      // move out of a clause group
      const grandparent: Fragment = parent.parent;
      const parentIndex: number = grandparent.children.indexOf(parent);
      const newIndexInGrandparent: number = up ? parentIndex : parentIndex + 1;
      this.fragment.remove();
      grandparent.children.splice(newIndexInGrandparent, 0, this.fragment);
    } else if (!isOutOfParentBounds) {
      // move up and down normally
      this.fragment.remove();
      parent.children.splice(newIndex, 0, this.fragment);
    }
  }

  private _isSfrWithinNdr(): boolean {
    return (
      isClauseGroupOfType(this.fragment, ClauseGroupType.STANDARD_FORMAT_REQUIREMENT) &&
      isClauseGroupOfType(this.fragment.parent, ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT)
    );
  }

  private _moveClause(up: boolean): void {
    const indices: number = up ? -1 : 1;
    const parent: Fragment = this.fragment.parent;
    const index: number = this.fragment.index();
    const newIndex: number = index + indices;

    if (!!parent.children[newIndex]) {
      this.fragment.remove();
      parent.children.splice(newIndex, 0, this.fragment);
    }
  }

  /**
   * Stops the tooltips being stuck in the shown state when the fragment is moved
   *
   * @param event {MouseEvent}   The mousedown event
   */
  private _closeTooltips() {
    this.moveUpTooltip.hide();
    this.moveDownTooltip.hide();
  }

  /**
   * Deletes the fragment, if this would leave an empty section then creates a new clause of the default type.
   */
  public async delete(): Promise<void> {
    if (!(await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithSubtrees(this.fragment))) {
      return;
    }

    const section: Fragment = this.fragment.findAncestorWithType(FragmentType.SECTION);
    const clauseGroup: Fragment = this.fragment.parent;

    if (section.children.length === 1 && this.fragment.parent.equals(section)) {
      this._configurationService.getDefaultClauseTypeForSectionId(section.id).then((clauseType: ClauseType) => {
        const newClause: ClauseFragment = ClauseFragment.empty(clauseType);

        section.children.push(newClause);
        this._fragmentService.create(newClause);
        this._setCaretPositionThenDelete(clauseGroup);
      });
    } else {
      this._setCaretPositionThenDelete(clauseGroup);
    }
  }

  private _setCaretPositionThenDelete(clauseGroup: Fragment): void {
    this._selectFragmentForAfterDeletion();
    this._createDefaultNdrClauseIfSfrWithinNdr();
    // Validated in the delete() method above
    this._fragmentService.deleteValidatedFragments(this.fragment);
    if (this._isSfrWithinNdr) {
      this._clauseGroupService.createDeletePlaceholderClauses(clauseGroup);
    }
  }

  private _createDefaultNdrClauseIfSfrWithinNdr(): void {
    if (this._isSfrWithinNdr()) {
      const newClause: ClauseFragment = this._clauseGroupService.getDefaultClauseForNDRWithAdministration(
        (this.fragment as ClauseGroupFragment).administration
      );
      newClause.insertBefore(this.fragment);
      this._fragmentService.create(newClause);
    }
  }

  /**
   * Ensures the carets are in a valid editable fragment after a deletion operation.
   * Selects the end of the last editable fragment of the previous sibling (if exists), else the
   * start of the first editable fragment of the next sibling.
   */
  private _selectFragmentForAfterDeletion(): void {
    let prevSibling: Fragment = this.fragment.previousSibling();
    let nextSibling: Fragment = this.fragment.nextSibling();
    let fragmentToSelect: Fragment = prevSibling ? getFinalEditableDescendant(prevSibling) : null;
    let offsetToSelect: number = 0;

    while (!!prevSibling && !fragmentToSelect) {
      prevSibling = prevSibling.previousSibling();
      fragmentToSelect = getFinalEditableDescendant(prevSibling);
    }
    if (!fragmentToSelect && !!nextSibling) {
      fragmentToSelect = getFirstEditableDescendant(nextSibling);
      while (!!nextSibling && !fragmentToSelect) {
        nextSibling = nextSibling.nextSibling();

        fragmentToSelect = getFirstEditableDescendant(nextSibling);
      }
    }
    if (prevSibling) {
      offsetToSelect = fragmentToSelect.length();
    }

    this._selectionOperationsService.setSelected(fragmentToSelect, offsetToSelect, PadType.MAIN_EDITABLE);
  }
}
