import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {DocumentFragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {Breadcrumb, VersionTag} from 'app/interfaces';
import {DocumentFetchParams, DocumentService} from 'app/services/document.service';
import {SectionService} from 'app/services/section.service';
import {UUID} from 'app/utils/uuid';
import {ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';
import {Observable} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentVersionResolverService implements Resolve<DocumentFragment> {
  constructor(
    private documentService: DocumentService,
    private sectionService: SectionService,
    private versioningService: VersioningService,
    private viewService: ViewService
  ) {}

  /**
   * Resolves the versioned document.
   *
   * @inheritdoc
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DocumentFragment> {
    const documentId: string = route.paramMap.get('document_id');
    const versionId: string = route.paramMap.get('version_id');

    return this.versioningService.getVersionTagFromVersionId(UUID.orNull(versionId)).pipe(
      take(1),
      mergeMap((versionTag: VersionTag) => {
        const fetchParams: DocumentFetchParams = {
          validAt: versionTag.createdAt,
          projection: 'INITIAL_DOCUMENT_LOAD',
        };
        const message: string = `Could not fetch document version ${versionTag.name}`;
        this.viewService.setCurrentView(ViewMode.HISTORICAL, versionTag);

        return this.documentService.load(versionTag.fragmentId, fetchParams, true, message).then((documentFragment) => {
          const breadcrumbs: Breadcrumb[] = [
            {title: 'Documents', link: '/documents'},
            {
              title: `${documentFragment.title}`,
              link: `/documents/${documentId}`,
            },
            {
              title: 'Versions',
              link: `/documents/${documentId}/versions`,
            },
            {
              title: `${versionTag.name}`,
              link: `/documents/${documentId}/versions/${versionTag.versionId.value}`,
            },
          ];
          route.data = {breadcrumbs};

          this.documentService.setSelected(documentFragment);
          this.sectionService.setSelected(null);
          this.sectionService.clearSectionUndoBuffer();
          this.viewService.setCurrentView(ViewMode.HISTORICAL, versionTag);
          this.viewService.setLocation(CurrentLocation.DOCUMENT_OVERVIEW);
          return documentFragment;
        });
      })
    );
  }
}
