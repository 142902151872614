import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {SectionService} from 'app/services/section.service';
import {ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class ChangeSummaryResolverService implements Resolve<void> {
  constructor(private _viewService: ViewService, private _sectionService: SectionService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    const currentView = this._viewService.getCurrentView();
    this._viewService.setLocation(CurrentLocation.CHANGE_SUMMARY);
    this._viewService.setCurrentView(ViewMode.CHANGE_SUMMARY, currentView?.versionTag);
    this._sectionService.setSelected(null);
  }
}
