import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {FragmentService} from 'app/services/fragment.service';
import {Subscription} from 'rxjs';
import {getFragmentIfEditable} from '../fragment-utils';
import {AnchorFragment, ClauseFragment, Fragment, FragmentType} from '../types';

@Component({
  selector: 'cars-anchor-fragment',
  template: '<span *ngIf="!content.hasBeenResolved" contenteditable="false">{{""}}</span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorFragmentComponent extends FragmentComponent implements OnInit, OnDestroy {
  public set content(value: AnchorFragment) {
    super.content = value;
  }

  public get content(): AnchorFragment {
    return super.content as AnchorFragment;
  }

  private _subscriptions: Subscription[] = [];

  constructor(
    protected _cd: ChangeDetectorRef,
    elementRef: ElementRef,
    private _selectionOperationsService: SelectionOperationsService,
    private _fragmentService: FragmentService
  ) {
    super(_cd, elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._subscriptions.push(
      this._fragmentService.onSelection(
        (fragment: Fragment) => {
          if (!!fragment) {
            this._onSelect();
          }
        },
        (fragment: Fragment) => !!fragment && fragment.equals(this.content)
      )
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * This finds the next editable leaf in the containing clause. If one doesn't exist, it finds the
   * previous editable leaf in the containing clause.
   * If an editable leaf is found in either direction, it selects the found fragment, else it does nothing.
   */
  private _onSelect(): void {
    const clause: ClauseFragment = this.content.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const padType: PadType = clause?.component.padType;

    if (!clause || !padType) {
      return;
    }

    let previous: boolean = false;
    let leaf: Fragment = this._findNearestEditableLeafInClause(clause, previous);

    if (!leaf) {
      previous = true;
      leaf = this._findNearestEditableLeafInClause(clause, previous);
    }

    if (leaf) {
      this._selectionOperationsService.setSelected(leaf, previous ? leaf.length() : 0, padType);
    }
  }

  private _findNearestEditableLeafInClause(clause: ClauseFragment, previous: boolean): Fragment {
    let leaf: Fragment = previous ? this.content.previousLeaf() : this.content.nextLeaf();

    while (leaf) {
      if (!clause.equals(leaf.findAncestorWithType(FragmentType.CLAUSE))) {
        return null;
      }

      const editableFragment: Fragment = getFragmentIfEditable(leaf);

      if (!!editableFragment) {
        return editableFragment;
      } else {
        leaf = previous ? leaf.previousLeaf() : leaf.nextLeaf();
      }
    }

    return null;
  }
}
