import {Component, HostListener} from '@angular/core';
import {MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {LinkBuilder} from 'app/changelog/link-builder/link-builder';
import {environment} from 'environments/environment';

@Component({
  selector: 'cars-link-creation-wizard',
  templateUrl: './link-creation-wizard.component.html',
  styleUrls: ['./link-creation-wizard.component.scss'],
})
export class LinkCreationWizardComponent {
  public static readonly DIALOG_OPTIONS: MatDialogConfig<any> = {
    ariaLabel: 'Create a change',
    width: '960px',
    hasBackdrop: false,
    position: {top: '64px'},
  };

  public tooltipDelay: number = environment.tooltipDelay;

  constructor(private linkBuilder: LinkBuilder, private dialogRef: MatDialogRef<LinkCreationWizardComponent>) {}

  public close(): void {
    this.linkBuilder.reset();
    this.dialogRef.close();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.linkBuilder.reset();
    }
  }
}
