<div #hoverTipRef class="hover-tip" [style.height]="hovertipHeight + 'px'">
  {{ title }}
  <div class="buttons">
    <ul class="suggestions" [ngClass]="['column-' + numberOfColumns]">
      <button
        *ngFor="let suggestion of suggestions"
        class="button"
        [disabled]="!canReplace()"
        mat-button
        color="primary"
        (click)="onSelect(suggestion)"
        [attr.aria-label]="'Replace with ' + suggestion"
      >
        {{ suggestion }}
      </button>
    </ul>
  </div>
</div>
