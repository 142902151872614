<form [formGroup]="formGroup">
  <ng-container *ngFor="let item of documentInformationItems" [ngSwitch]="item.documentInformationField.fieldType">
    <cars-free-text-input
      *ngSwitchCase="FieldType.FREE_TEXT"
      [documentInformationItem]="item"
      [formGroup]="formGroup.controls[item.documentInformationField.documentInformationType]"
      [disabled]="item.documentInformationField.carsAction | carsDisabled : document.id | async"
    ></cars-free-text-input>
    <cars-free-text-multiline-input
      *ngSwitchCase="FieldType.FREE_TEXT_MULTILINE"
      [documentInformationItem]="item"
      [formGroup]="formGroup.controls[item.documentInformationField.documentInformationType]"
      [disabled]="item.documentInformationField.carsAction | carsDisabled : document.id | async"
    ></cars-free-text-multiline-input>
    <cars-enum-select-input
      *ngSwitchCase="FieldType.ENUM"
      [documentInformationItem]="item"
      [formGroup]="formGroup.controls[item.documentInformationField.documentInformationType]"
      [disabled]="item.documentInformationField.carsAction | carsDisabled : document.id | async"
    ></cars-enum-select-input>
    <cars-enum-multi-select-input
      *ngSwitchCase="FieldType.MULTI_SELECT"
      [documentInformationItem]="item"
      [formGroup]="formGroup.controls[item.documentInformationField.documentInformationType]"
      [disabled]="item.documentInformationField.carsAction | carsDisabled : document.id | async"
    ></cars-enum-multi-select-input>
    <cars-non-editable-field
      *ngSwitchCase="FieldType.NON_EDITABLE"
      [documentInformationItem]="item"
      [isHistoricalVersion]="item.documentInformationField.carsAction | carsDisabled : document.id | async"
    ></cars-non-editable-field>
    <cars-logo-upload
      *ngSwitchCase="FieldType.IMAGE_UPLOAD"
      [documentInformationItem]="item"
      [canEdit]="item.documentInformationField.carsAction | carsEnabled : document.id | async"
    ></cars-logo-upload>
    <ng-container *ngSwitchCase="FieldType.CORE_DOCUMENT_LINKAGE">
      <cars-core-document-linkage [document]="document"></cars-core-document-linkage>
    </ng-container>
    <ng-container *ngSwitchCase="FieldType.ADMINISTRATION">
      <cars-administration [document]="document"></cars-administration>
    </ng-container>
    <ng-container *ngSwitchCase="FieldType.DOCUMENT_HEALTH">
      <cars-document-metrics
        *ngIf="item.documentInformationField.carsAction | carsEnabled : document.id | async"
        [document]="document"
      ></cars-document-metrics>
    </ng-container>
    <cars-associated-document-information
      *ngSwitchCase="FieldType.ASSOCIATED_DOCUMENT_INFORMATION"
      [documentInformationItem]="item"
      [formGroup]="formGroup.controls[item.documentInformationField.documentInformationType]"
      [document]="document"
      [isReadonly]="item.documentInformationField.carsAction | carsDisabled : document.id | async"
    >
    </cars-associated-document-information>
    <cars-jira-information *ngSwitchCase="FieldType.JIRA_WORKFLOW" [document]="document"></cars-jira-information>
    <cars-naa-document-linkage
      *ngSwitchCase="FieldType.DOCUMENT_LINKAGE"
      [document]="document"
      [disabled]="item.documentInformationField.carsAction | carsDisabled : document.id | async"
    ></cars-naa-document-linkage>
  </ng-container>
</form>
