import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {DocumentReferenceFragment, ReferenceType} from 'app/fragment/types';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentService} from 'app/services/document.service';
import {ReferenceService} from 'app/services/references/reference.service';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subject, Subscription} from 'rxjs';
import {ReferenceSearchParams} from '../references.component';
import {SearchableGlobalReference} from '../searchable-global-reference';

const TABLE_COLUMNS = ['all'];

interface InsertedReference {
  type: string;
  release: string;
}

@Component({
  selector: 'cars-reference-list',
  templateUrl: './reference-list.component.html',
  styleUrls: ['./reference-list.component.scss'],
})
export class ReferenceListComponent implements OnChanges, OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() public referenceList: SearchableGlobalReference[];
  @Input() public filter: string;
  @Input() public enableInsertButtons: boolean = false;
  @Input() public resultsLength: number;
  @Input() public referenceListType: string;
  @Input() public currentRefId: UUID;

  /**
   * Any row selected will be emitted via this EventEmitter
   */
  @Output() public selection: EventEmitter<UUID> = new EventEmitter();
  @Output() public onInsertReference: EventEmitter<InsertedReference> = new EventEmitter();
  @Output() public onSearch: Subject<any> = new Subject();

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public documentId: UUID;

  public dataSource: MatTableDataSource<SearchableGlobalReference>;
  public pageSize: number = 10;
  public pageSizeOptions: number[] = [10, 20, 50, 100];

  public yearOrEdition: string = '';
  public displayedColumns: string[] = TABLE_COLUMNS;

  private _subscriptions: Subscription[] = [];
  private _canAuthorDocument: boolean = false;

  constructor(
    private _referenceService: ReferenceService,
    private _documentService: DocumentService,
    private _permissionsService: PermissionsService
  ) {}

  public ngOnInit(): void {
    this.documentId = this._documentService.getSelected().documentId;
    this.dataSource = new MatTableDataSource<SearchableGlobalReference>([]);
    this.paginator.page.subscribe(() => {
      this.refreshTable();
    });
    this._subscriptions.push(
      this._permissionsService
        .can(CarsAction.AUTHOR_DOCUMENT, this.documentId)
        .subscribe((canAuthor: boolean) => (this._canAuthorDocument = canAuthor))
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.filter) {
      if (this.referenceList) {
        this.paginator.pageIndex = 0;
        this.refreshTable();
      }
    }
  }

  public refreshTable() {
    const pageIndex = this.paginator && this.paginator.pageIndex ? this.paginator.pageIndex : 0;
    const size = this.paginator && this.paginator.pageSize ? this.paginator.pageSize : 10;
    const params: ReferenceSearchParams = {
      searchTerm: this.filter ? this.filter : '',
      docId: this.documentId.value,
      page: pageIndex,
      size: size,
      listType: this.referenceListType,
    };
    this.onSearch.next(params);
  }

  public selectReference(ref: SearchableGlobalReference): void {
    if (this._canAuthorDocument) {
      this.currentRefId = ref.globalReferenceId;
      this.yearOrEdition = null;
      this.selection.emit(this.currentRefId);
    } else {
      this.currentRefId = null;
    }
  }

  /**
   * Returns the release date on the document reference of a global reference. If no release date exists, returns an empty string.
   *
   * @param ref {GlobalReference} The given global reference.
   */
  public getReleaseDate(ref: SearchableGlobalReference): string {
    const docRef: DocumentReferenceFragment = this._referenceService.getDocumentReferenceFragment(
      ref.globalReferenceId
    );
    return docRef && docRef.release ? ' ' + docRef.release : '';
  }

  public onInsertClicked(type: string, event: Event): void {
    event.stopPropagation(); // Stop bubbling up to selectReference
    const insertedReference: InsertedReference = {
      type: type,
      release: this.yearOrEdition,
    };
    this.onInsertReference.emit(insertedReference);
  }

  public toggleReferenceType(ref: SearchableGlobalReference, event: MouseEvent): void {
    event.stopPropagation(); // Stop bubbling up to selectReference
    this._referenceService.toggleDocumentReferenceType(ref.globalReferenceId);
  }

  public getToggleText(ref: SearchableGlobalReference): string {
    const docRef: DocumentReferenceFragment = this._referenceService.getDocumentReferenceFragment(
      ref.globalReferenceId
    );
    if (!docRef) {
      return '';
    }
    return docRef.referenceType === ReferenceType.INFORMATIVE ? 'Normative' : 'Informative';
  }
}
