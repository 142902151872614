import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'cars-tdif-inline-reference',
  templateUrl: './tdif-inline-reference.component.html',
  styleUrls: ['./tdif-inline-reference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifInlineReferenceComponent {
  @Input() value: string;

  constructor() {}
}
