import {Client, Frame, Message, StompHeaders, StompSubscription} from '@stomp/stompjs';

export class NoOpWebsocketClient extends Client {
  heartbeat: {
    incoming: number;
    outgoing: number;
  };

  reconnect_delay: number;

  ws: any;

  public debug = (...args: string[]) => {};

  public connect = (login: any, passcode: any, connectCallback: any, errorCallback?: any, host?: any) => {};

  public disconnect = (disconnectCallback: () => any, headers?: StompHeaders) => {};

  public send = (destination: string, headers?: StompHeaders, body?: string) => {};

  public subscribe(): StompSubscription {
    return null;
  }

  public onreceive = (message: Message) => {};
  public onreceipt = (frame: Frame) => {};
  public unsubscribe = (id: string, headers?: StompHeaders) => {};

  public begin = (transaction: string) => {
    return null;
  };
  public commit = (transaction: string) => {};
  public abort = (transaction: string) => {};

  public ack = (messageID: string, subscription: string, headers?: StompHeaders) => {};
  public nack = (messageID: string, subscription: string, headers?: StompHeaders) => {};
}
