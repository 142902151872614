import {AdministrationUtils} from 'app/documents/administration-utils';
import {Administration} from 'app/documents/administrations';
import {ReferenceIndexUtils} from 'app/services/references/reference-utils/reference-index-utils';
import {IndexUtils} from 'app/utils/index-utils';
import {
  ClauseType,
  DocumentFragment,
  EquationFragment,
  Fragment,
  FragmentType,
  InlineReferenceFragment,
  SectionFragment,
  SectionType,
} from '../types';
import {ClauseGroupFragment} from '../types/clause-group-fragment';
import {ClauseGroupType} from '../types/clause-group-type';
import {InternalDocumentReferenceFragment} from '../types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from '../types/reference/internal-inline-reference-fragment';
import {TargetDocumentType} from '../types/reference/target-document-type';
import {SectionGroupFragment} from '../types/section-group-fragment';
import {SectionGroupType} from '../types/section-group-type';
import {ClauseFragment} from './../types';
import {FragmentIndexer} from './fragment-index.service';

export const mchwIndexer: FragmentIndexer = (document: DocumentFragment): Record<string, string> => {
  if (!document) {
    return {};
  }

  const lookup: Record<string, string> = {};

  const documentReferenceIndexLookup: Record<string, string> = {};

  let normativeSectionCounter: number = 0;
  let requirementCounter: number = 0;
  let specifierInstructionCounter: number = 0;
  let tableCounter: number = 0;
  let figureCounter: number = 0;
  let equationCounter: number = 0;

  let currentSectionGroup: SectionGroupFragment;
  let currentSection: SectionFragment;
  let currentClauseGroup: ClauseGroupFragment;
  let currentClause: ClauseFragment;

  let currentAdministration: Administration;

  const resetClauseCounters = (): void => {
    requirementCounter = specifierInstructionCounter = 0;
    tableCounter = figureCounter = equationCounter = 0;
  };

  const handleDocument = (_document: DocumentFragment) => {
    ReferenceIndexUtils.populateRecordWithReferenceIndexes(
      _document.getNormReferenceSection(),
      documentReferenceIndexLookup
    );
    ReferenceIndexUtils.populateRecordWithReferenceIndexes(
      _document.getInformReferenceSection(),
      documentReferenceIndexLookup
    );
  };

  const documentChildPredicate = (s: Fragment): boolean => {
    if (s.is(FragmentType.SECTION_GROUP)) {
      return !(s as SectionGroupFragment).deleted;
    }
    const section: SectionFragment = s as SectionFragment;
    return !(section.deleted || section.sectionType === SectionType.DOCUMENT_INFORMATION);
  };

  const getAdministrationPrefix = (): string => {
    return currentAdministration ? AdministrationUtils.getInitials(currentAdministration) + '/' : '';
  };

  const getSectionLabel = (includeAdministration: boolean = true): string => {
    const sectionType: SectionType = currentSection.sectionType;

    const administrationPrefix: string = includeAdministration ? getAdministrationPrefix() : '';

    if (
      sectionType === SectionType.NORMATIVE ||
      sectionType === SectionType.REFERENCE_NORM ||
      sectionType === SectionType.REFERENCE_INFORM
    ) {
      return `${administrationPrefix}${normativeSectionCounter}.`;
    }
  };

  const getLowercaseAlphaLabel = (counter: number): string => {
    return IndexUtils.generateAlphaIndex(counter, false);
  };

  const handleSectionGroupFragment = (id: string, fragment: Fragment): void => {
    currentSectionGroup = fragment as SectionGroupFragment;
    currentSection = currentClauseGroup = currentClause = null;
    currentAdministration = null;

    normativeSectionCounter++;
    lookup[id] = `#${normativeSectionCounter}.`;
    resetClauseCounters();
  };

  const handleSectionFragment = (id: string, fragment: Fragment): void => {
    currentSection = fragment as SectionFragment;
    currentClauseGroup = currentClause = null;

    if (!currentSection.parent.equals(currentSectionGroup)) {
      currentSectionGroup = null;
    }

    currentAdministration = currentSection.administration;

    switch (currentSection.sectionType) {
      case SectionType.NORMATIVE:
      case SectionType.REFERENCE_NORM:
      case SectionType.REFERENCE_INFORM: {
        if (!currentSection.parent.equals(currentSectionGroup)) {
          normativeSectionCounter++;
        }
        lookup[id] = getSectionLabel();

        resetClauseCounters();
        break;
      }
    }
  };

  const handleClauseGroupFragment = (id: string, fragment: Fragment): void => {
    currentClauseGroup = fragment as ClauseGroupFragment;
    currentClause = null;

    currentAdministration = currentClauseGroup.administration || currentAdministration;

    if (currentClauseGroup.clauseGroupType === ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT) {
      const savedRequirementCount: number = requirementCounter;

      const shouldResetRequirementCounter: (f: Fragment) => boolean = (f: Fragment) => {
        return (
          f.is(FragmentType.CLAUSE_GROUP) ||
          (f.isClauseOfType(ClauseType.REQUIREMENT) && !(f as ClauseFragment).isUnmodifiableClause)
        );
      };

      lookup[id] = `#${getSectionLabel(false)}${requirementCounter + 1}`;
      fragment.children.forEach((child: Fragment) => {
        if (shouldResetRequirementCounter(child)) {
          requirementCounter = savedRequirementCount;
        }
        indexCallback(child);
      });
    } else {
      iterateChildren(fragment);
    }
  };

  const handleClauseFragment = (id: string, fragment: Fragment): void => {
    currentClause = fragment as ClauseFragment;
    const sectionType: SectionType = currentSection.sectionType;

    const shouldSetAdminstration = (clause: ClauseFragment): boolean => {
      return (
        currentSectionGroup?.sectionGroupType !== SectionGroupType.NATIONAL_DETERMINED_SECTION &&
        !clause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) &&
        !(currentClause.parent.equals(currentClauseGroup) && !!currentClauseGroup.administration)
      );
    };

    if (shouldSetAdminstration(currentClause)) {
      currentAdministration = currentClause.administration;
    }

    const isAdjacentToSpecifierInstruction = (clause: ClauseFragment): boolean => {
      const sectionClauses: ClauseFragment[] = currentSection.getClauses();
      const clauseIndex: number = sectionClauses.findIndex((c: ClauseFragment) => c.equals(clause));

      return (
        (clauseIndex > 0 && sectionClauses[clauseIndex - 1].isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) ||
        (clauseIndex < sectionClauses.length - 1 &&
          sectionClauses[clauseIndex + 1].isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION))
      );
    };

    const handleNormativeSection = (): void => {
      switch (currentClause.clauseType) {
        case ClauseType.REQUIREMENT:
          {
            requirementCounter++;
            specifierInstructionCounter = tableCounter = figureCounter = equationCounter = 0;
            lookup[id] = `${getSectionLabel()}${requirementCounter}`;
          }
          break;
        case ClauseType.SPECIFIER_INSTRUCTION:
          {
            tableCounter = figureCounter = equationCounter = 0;
            specifierInstructionCounter++;
            const baseIndex: string = `${getAdministrationPrefix()}SI.${getSectionLabel(false)}${requirementCounter}`;
            lookup[id] = isAdjacentToSpecifierInstruction(currentClause)
              ? `${baseIndex}${getLowercaseAlphaLabel(specifierInstructionCounter)}`
              : baseIndex;
          }
          break;
        default:
          specifierInstructionCounter = tableCounter = figureCounter = equationCounter = 0;
      }
    };

    if (sectionType === SectionType.NORMATIVE) {
      handleNormativeSection();
    }
  };

  const handleCaptionedFragment = (id: string, fragment: Fragment, counter: number, prefix: string): void => {
    const clauseType: ClauseType = currentClause.clauseType;
    const sectionType: SectionType = currentSection.sectionType;

    let clauseSiblingCount: number;

    switch (fragment.type) {
      case FragmentType.TABLE:
        {
          clauseSiblingCount = currentClause.children.filter((f: Fragment) => {
            return f.is(FragmentType.TABLE);
          }).length;
        }
        break;
      case FragmentType.FIGURE:
        {
          clauseSiblingCount = currentClause.children.filter((f: Fragment) => {
            return f.is(FragmentType.FIGURE);
          }).length;
        }
        break;
      case FragmentType.EQUATION:
        {
          clauseSiblingCount = currentClause.children.filter((f: Fragment) => {
            return f.is(FragmentType.EQUATION) && !(f as EquationFragment).inline;
          }).length;
        }
        break;
    }

    if (sectionType === SectionType.NORMATIVE) {
      switch (clauseType) {
        case ClauseType.REQUIREMENT:
          {
            let label: string = `${prefix} ${getSectionLabel()}${requirementCounter}`;
            label += clauseSiblingCount === 1 ? '' : `${getLowercaseAlphaLabel(counter)}`;
            lookup[id] = label;
          }
          break;
      }
    }
  };

  const handleReferenceFragment = (id: string, documentReferenceId: string) => {
    if (documentReferenceIndexLookup[documentReferenceId]) {
      lookup[id] = documentReferenceIndexLookup[documentReferenceId];
    }
  };

  const indexCallback = (fragment: Fragment): void => {
    const id: string = fragment.id.value;

    if (fragment.is(FragmentType.DOCUMENT)) {
      handleDocument(fragment as DocumentFragment);
      fragment.children.filter(documentChildPredicate).forEach(indexCallback);
    } else if (fragment.is(FragmentType.SECTION_GROUP)) {
      handleSectionGroupFragment(id, fragment);
      iterateChildren(fragment);
    } else if (fragment.is(FragmentType.SECTION)) {
      handleSectionFragment(id, fragment);
      iterateChildren(fragment);
    } else if (fragment.is(FragmentType.CLAUSE_GROUP)) {
      handleClauseGroupFragment(id, fragment);
    } else if (fragment.is(FragmentType.CLAUSE)) {
      handleClauseFragment(id, fragment);
      iterateChildren(fragment);
    } else if (fragment.is(FragmentType.TABLE)) {
      if (!fragment.parent.is(FragmentType.EQUATION)) {
        tableCounter++;
        handleCaptionedFragment(id, fragment, tableCounter, 'Table');
      }
      iterateChildren(fragment);
    } else if (fragment.is(FragmentType.FIGURE) && !fragment.findAncestorWithType(FragmentType.TABLE_CELL)) {
      figureCounter++;
      handleCaptionedFragment(id, fragment, figureCounter, 'Figure');
    } else if (fragment.is(FragmentType.EQUATION) && !(fragment as EquationFragment).inline) {
      equationCounter++;
      handleCaptionedFragment(id, fragment, equationCounter, 'Equation');
      iterateChildren(fragment);
    } else if (fragment.is(FragmentType.INLINE_REFERENCE)) {
      handleReferenceFragment(id, (fragment as InlineReferenceFragment).documentReference.value);
    } else if (fragment.is(FragmentType.INTERNAL_INLINE_REFERENCE)) {
      handleReferenceFragment(id, (fragment as InternalInlineReferenceFragment).internalDocumentReferenceId.value);
    } else if (fragment.is(FragmentType.DOCUMENT_REFERENCE)) {
      handleReferenceFragment(id, id);
    } else if (
      fragment.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE) &&
      (fragment as InternalDocumentReferenceFragment).targetDocumentType !== TargetDocumentType.SAME_DOCUMENT
    ) {
      handleReferenceFragment(id, id);
    } else {
      // iterate over all other fragment types to find reference fragments in lists, tables etc
      iterateChildren(fragment);
    }
  };

  const iterateChildren = (fragment: Fragment): void => {
    fragment.children.forEach(indexCallback);
  };

  indexCallback(document);

  return lookup;
};
