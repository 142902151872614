import {Injectable} from '@angular/core';
import {ChangelogService} from 'app/changelog/changelog.service';
import {PadType} from 'app/element-ref.service';
import {Suite} from 'app/fragment/suite';
import {DocumentFragment} from 'app/fragment/types';
import {DocumentService} from 'app/services/document.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConditionASTNode} from '../types/condition-ast-node';
import {EqualityRelation, PermissionInput} from '../types/permissions';
import {FalseOnPadTypes} from './false-on-pad-types';
import {PermissionsHandler} from './permissions-handler';

@Injectable()
export class SuitePermissionsHandler implements PermissionsHandler {
  constructor(private _documentService: DocumentService, private _changelogService: ChangelogService) {}

  public getInputType(): PermissionInput {
    return PermissionInput.SUITE;
  }

  @FalseOnPadTypes(PadType.NO_PAD)
  public handle(condition: ConditionASTNode, padType: PadType): Observable<boolean> {
    const suite: Suite = this._getSuite(condition);
    const documentStream: Observable<DocumentFragment> = this._getDocumentStream(padType);
    switch (condition.relation) {
      case EqualityRelation.EQUALS:
        return documentStream.pipe(map((document: DocumentFragment) => document.suite === suite));
      case EqualityRelation.NOT_EQUALS:
        return documentStream.pipe(map((document: DocumentFragment) => document.suite !== suite));
      default:
        throw new Error(`Unsupported equality relation ${condition.relation}`);
    }
  }

  private _getDocumentStream(padType: PadType): Observable<DocumentFragment> {
    return padType === PadType.MAIN_EDITABLE
      ? this._documentService.getDocumentStream()
      : this._changelogService.getPublishedDocumentStream();
  }

  private _getSuite(condition: ConditionASTNode): Suite {
    const suite = Suite[condition.value as string];
    if (suite !== undefined) {
      return suite;
    } else {
      throw new Error(`Unknown Suite: ${condition.value}`);
    }
  }
}
