import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FragmentIndexModule} from 'app/fragment/indexing/fragment-index.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {ClauseLinkRequiredToDisplayNameModule} from 'app/utils/pipes/clause-link-required-to-display-name.module';
import {ClauseLinkTypeToDisplayNameModule} from 'app/utils/pipes/clause-link-type-to-display-name.module';
import {ClauseGroupLinkPreviewModule} from '../clause-group-link-preview/clause-group-link-preview.module';
import {ClauseLinkRequiredSelectorComponent} from './clause-link-required-selector.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    FormsModule,
    MatOptionModule,
    MatButtonModule,
    ClauseLinkRequiredToDisplayNameModule,
    ClauseLinkTypeToDisplayNameModule,
    ClauseGroupLinkPreviewModule,
    CarsPermissionPipesModule,
    FragmentIndexModule,
  ],
  declarations: [ClauseLinkRequiredSelectorComponent],
  exports: [ClauseLinkRequiredSelectorComponent],
})
export class ClauseLinkRequiredSelectorModule {}
