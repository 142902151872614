import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {PdfPreviewDialogComponent} from './pdf-preview-dialog.component';

@NgModule({
  imports: [CommonModule, PdfViewerModule],
  declarations: [PdfPreviewDialogComponent],
  exports: [PdfPreviewDialogComponent],
})
export class PdfPreviewDialogModule {}
