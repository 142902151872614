<div class="error-container">
  <mat-icon *ngIf="error.severity === 2" svgIcon="error"></mat-icon>
  <mat-icon *ngIf="error.severity === 1" svgIcon="warning"></mat-icon>
  <mat-icon *ngIf="error.severity === 0" svgIcon="info"></mat-icon>
  <span class="title">{{ error.title }}</span>
  <div class="more">
    <button *ngIf="!expanded" (click)="expanded = !expanded" mat-button aria-label="Expand validation errors">
      Show more <mat-icon>expand_more</mat-icon>
    </button>
    <button *ngIf="expanded" (click)="expanded = !expanded" mat-button aria-label="Collapse validation errors">
      Show less <mat-icon>expand_less</mat-icon>
    </button>
  </div>
  <div *ngIf="expanded" class="message">
    <strong>{{ error.reference }}</strong>
    <p>{{ error.message }}</p>
  </div>
</div>
