import {Logger} from 'app/error-handling/services/logger/logger.service';
import {FragmentDiff} from 'app/fragment/diff/fragment-diff';
import {Dictionary} from '../../utils/typedefs';
import {UUID} from '../../utils/uuid';
import {FragmentMapper} from '../core/fragment-mapper';
import {TreeStructureValidator} from '../tree-structure-validator';
import {Fragment, RootFragment} from '../types';
import {CacheEntry} from './cache-entry';

// Convenience typedef
type Entry = CacheEntry<Fragment>;

/**
 * A class for caching and managing fragment references.
 */
export class FragmentCache {
  private _byOwnId: Dictionary<Entry> = {}; // A dictionary of ID to CacheEntry
  private _childIdsByParentId: Dictionary<Set<string>> = {}; // A dictionary of parent ID to childIds
  private _rootId: UUID = null; // The active tree root ID for fast lookup
  private _capacity: number; // Capacity of per-item history buffer

  constructor(capacity: number) {
    this._capacity = capacity;
    this.insert(new RootFragment([]));
  }

  /**
   * Find an entry from the cache with the given ID.  Returns null if the entry
   * cannot be found.
   *
   * @param id {UUID}    The UUID to search for
   * @returns  {Entry}   The cached entry
   */
  public find(id: UUID): Entry {
    const idString: string = id ? id.value : null;
    const entry: Entry = this._byOwnId[idString];

    return entry || null;
  }

  /**
   * Copy the live version of some fragments into their historical backlog.
   *
   * @param ids {UUID[]}   The IDs to commit
   */
  public commit(...ids: UUID[]): void {
    for (const id of ids) {
      const entry: Entry = this.find(id);
      if (entry) {
        const clone: Fragment = FragmentMapper.clone(entry.live);
        if (clone) {
          for (const child of entry.live.children) {
            // Make sure the clone knows its children, but that the children still
            // point to the live parent reference.
            clone.children.push(child);
            child.parent = entry.live;
          }
        }
        entry.history.push(clone);
      }
    }
  }

  /**
   * Clear the history of a set of IDs.  Does not affect the live object.  If given no
   * IDs, this clears history of all entries.
   *
   * @param ids {UUID[]}   The IDs to clear.
   */
  public reset(...ids: UUID[]): void {
    if (ids.length === 0) {
      ids = Object.keys(this._byOwnId).map((id: string) => UUID.orThrow(id));
    }

    for (const id of ids) {
      const entry: Entry = this._byOwnId[id.value];
      if (entry) {
        entry.history.reset();
      }
    }
  }

  public insertTree(root: Fragment): Fragment {
    root.iterateDown(null, null, (iterator: Fragment) => {
      const existing: Fragment = this.find(iterator.id) ? this.find(iterator.id).live : null;
      if (existing) {
        const diff: FragmentDiff = FragmentDiff.update(existing, iterator);
        diff.applyTo(existing, this);
      } else {
        this.insert(iterator);
        this.commit(iterator.id);
      }
    });
    return this.find(root.id).live;
  }

  /**
   * Insert a fragment into the cache.  If a corresponding entry already exists and is live, this
   * overwrites it; if it is not live, the inserted fragment becomes the live version and parent
   * references updated accordingly; otherwise a new cache entry is created for the fragment.  No
   * recursive insertion is applied; it is assumed that parents are already present in the cache.
   * Therefore the caller should insert a subtree via a depth-first traversal (e.g. using
   * Tree::iterateDown()).
   *
   * @param fragment {Fragment}   The fragment to insert
   * @returns        {Fragment}   The inserted live fragment
   */
  public insert(fragment: Fragment): Fragment {
    if (!fragment) {
      Logger.error('fragment-error', `Tried to create a null fragment; ignoring.`);
      return null;
    }
    if (!fragment.id) {
      Logger.error('fragment-error', `Tried to create a fragment with null ID; ignoring.`);
      return null;
    }

    const id: string = fragment.id.value;
    const parentId: string = fragment.parentId?.value;
    if (!this._byOwnId[id]) {
      this._byOwnId[id] = new CacheEntry(fragment, this._capacity);
    } else {
      // Remove the id from the parent id lookup if it has changed
      const prevParentId: string = this._byOwnId[id].live?.parentId?.value;
      if (prevParentId && prevParentId !== parentId) {
        this._childIdsByParentId[prevParentId]?.delete(id);
      }

      this._byOwnId[id].live = fragment;
    }

    if (parentId) {
      if (!this._childIdsByParentId[parentId]) {
        this._childIdsByParentId[parentId] = new Set();
      }

      this._childIdsByParentId[parentId].add(id);
    }

    const parentEntry: Entry = this.find(fragment.parentId);
    if (parentEntry && parentEntry.live) {
      const parent: Fragment = parentEntry.live;
      fragment.parent = parent;
      TreeStructureValidator.isValidInsertion(fragment);

      // Ensure the live object reference is registered with its parent correctly.
      const index: number = fragment.index();
      const existing: Fragment = parent.children[index];
      if (index < 0) {
        parent.children.push(fragment);
        parent.sortChildren();
      } else if (fragment.equals(existing) && fragment !== existing) {
        // fragment is the cache-created doppelgänger for existing, which the caller created.
        // We need to steal existings parent and children so as to steal its identity.
        parent.children.splice(index, 1, fragment);
        fragment.children.push(...existing.children.splice(0));
      }

      // Ensure all children have the live object reference as their parent.
      const childIds: Set<string> = this._childIdsByParentId[id] || new Set();
      const children: Fragment[] = [...childIds.values()]
        .map((childId: string) => this._byOwnId[childId].live)
        .filter((f: Fragment) => f && fragment.id.equals(f.parentId))
        .sort((first, second) => first.weight - second.weight);

      // If fragments are removed, and undone we do not want to reweight the children otherwise weights for future
      // undos will be incorrect and fragments such as tables will have their rows and columns placed out of order.
      if (!fragment.weight || children.filter((child) => !child.weight).length > 0) {
        children.forEach((child: Fragment) => child.remove());
        fragment.children.push(...children);
        children.forEach((child: Fragment) => child.inferWeight());
      } else {
        children.forEach((child: Fragment) => {
          if (!fragment.children.includes(child)) {
            fragment.children.push(child);
          }
        });
        fragment.sortChildren();
      }
    }

    return this.find(fragment.id).live;
  }

  /**
   * Remove a fragment from the cache, detaching it from the fragment tree and nulling
   * its live reference.  This does not affect its historical backlog.  If either the
   * entry doesn't exist or has no live version, this is a no-op.
   *
   * @param id {UUID}       The ID to remove
   * @returns  {Fragment}   The previously live fragment
   */
  public remove(id: UUID): Fragment {
    if (!id) {
      Logger.error('fragment-error', `Tried to remove a null ID; ignoring.`);
      return null;
    }

    const entry: Entry = this.find(id);
    const live: Fragment = entry ? entry.live : null;

    if (entry && entry.live) {
      if (entry.live.id.equals(this._rootId)) {
        this.setRoot(null);
      }

      entry.live.remove();
      entry.live = null;
    }

    return live;
  }

  /**
   * Get the root of the active subtree.
   *
   * @returns {Fragment}   The active root
   */
  public getRoot(): Fragment {
    const entry: Entry = this.find(this._rootId);
    return entry ? entry.live : null;
  }

  /**
   * Set the root of the active subtree.
   *
   * @param root {Fragment}   The new root node
   */
  public setRoot(root: Fragment): void {
    this._rootId = root.id;
  }

  /**
   * Clears the fragment cache.
   */
  public clear(): void {
    this._byOwnId = {};
    this._childIdsByParentId = {};
    this._rootId = null;
  }
}
