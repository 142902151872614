<div class="boundary-container top" contenteditable="false">
  <hr class="boundary top" />
</div>

<div class="group-heading" contenteditable="false" [class.deprecated-sfr]="isDeprecatedSFRType">
  <span class="index-container">
    <span class="index" [class.reordering]="reordering">
      <ng-container *ngIf="content?.clauseGroupType === ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT">
        {{ content | fragmentIndex }}
      </ng-container>
    </span>
  </span>

  <span class="group-title" [class.reordering]="reordering">{{ groupTitle }}</span>

  <span class="flex-grow"></span>

  <cars-move-delete-hovertip
    *ngIf="
      !readOnly &&
      padType === PadType.MAIN_EDITABLE &&
      (CarsAction.AUTHOR_DOCUMENT | carsEnabled: content.documentId | async)
    "
    [carsShowOnFocus]="content"
    [fragment]="content"
    [padType]="padType"
    (refreshFrag)="generateScheduleTableFragments()"
  ></cars-move-delete-hovertip>
</div>

<div class="clauses" [class.deprecated-sfr]="isDeprecatedSFRType">
  <ng-template [carsFragmentHost]="content.children" [readOnly]="readOnly" [padType]="padType"></ng-template>
</div>

<div
  *ngIf="content.standardFormatType === 'SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS_V2' && content.children.length > 1"
  class="schedule-clauses"
  contenteditable="false"
>
  <div class="boundary-container top-schedule" contenteditable="false">
    <hr class="boundary top-schedule" />
  </div>

  <div *ngIf="scheduleTableClause.children.length === 1 && !loadingPreview" class="message preview">
    Please click refresh button to generate preview.
  </div>

  <div *ngIf="loadingPreview" class="message loading">Loading the schedule preview.</div>

  <div *ngIf="showErrorMessage" class="message error">
    There are issues within the schedule definition above. Unable to generate the schedule preview.
  </div>

  <div *ngIf="scheduleTableClause.children.length > 1">
    <ng-template [carsFragmentHost]="[scheduleTableClause]" [readOnly]="true" [padType]="padType"></ng-template>
  </div>
</div>

<div class="boundary-container bottom" contenteditable="false">
  <hr class="boundary bottom" />
</div>
