import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatRadioModule} from '@angular/material/radio';
import {StandardFormatTypeToDisplayNameModule} from 'app/utils/pipes/standard-format-type-to-display-name.module';
import {StandardFormatGroupListOptionsComponent} from './standard-format-group-list-options.component';

@NgModule({
  imports: [CommonModule, MatRadioModule, StandardFormatTypeToDisplayNameModule],
  declarations: [StandardFormatGroupListOptionsComponent],
  exports: [StandardFormatGroupListOptionsComponent],
})
export class StandardFormatGroupListOptionsModule {}
