import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'cars-debounce-search',
  templateUrl: './debounce-search.component.html',
  styleUrls: ['./debounce-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebounceSearchComponent implements OnInit, OnDestroy {
  private static readonly DEBOUNCE_TIME: number = 300;

  @Input() placeholder: string = 'Search';

  @Input() hint: string = 'Search';

  @Output() onSearchChange: EventEmitter<string> = new EventEmitter();

  public searchForm: UntypedFormControl = new UntypedFormControl('');

  private _subscriptions: Subscription[] = [];

  constructor() {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this.searchForm.valueChanges
        .pipe(debounceTime(DebounceSearchComponent.DEBOUNCE_TIME), distinctUntilChanged())
        .subscribe((value: string) => {
          this.onSearchChange.emit(value);
        })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }
}
