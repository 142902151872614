import {ReferenceIndexUtils} from 'app/services/references/reference-utils/reference-index-utils';
import {
  DocumentFragment,
  Fragment,
  FragmentType,
  InlineReferenceFragment,
  SectionFragment,
  SectionType,
} from '../types';
import {FragmentIndexer} from './fragment-index.service';

export const legacyDmrbIndexer: FragmentIndexer = (document: DocumentFragment): Record<string, string> => {
  if (!document) {
    return {};
  }

  const lookup: Record<string, string> = {};

  const documentReferenceIndexLookup: Record<string, string> = {};

  const handleDocument = (_document: DocumentFragment) => {
    ReferenceIndexUtils.populateRecordWithReferenceIndexes(
      _document.getNormReferenceSection(),
      documentReferenceIndexLookup
    );
    ReferenceIndexUtils.populateRecordWithReferenceIndexes(
      _document.getInformReferenceSection(),
      documentReferenceIndexLookup
    );
  };

  const documentChildPredicate = (s: Fragment): boolean => {
    if (s.is(FragmentType.SECTION_GROUP)) {
      return false;
    }
    const section: SectionFragment = s as SectionFragment;
    return !(section.deleted || section.sectionType === SectionType.DOCUMENT_INFORMATION);
  };

  const handleReferenceFragment = (id: string, documentReferenceId: string) => {
    lookup[id] = documentReferenceIndexLookup[documentReferenceId];
  };

  const indexCallback = (fragment: Fragment): void => {
    const id: string = fragment.id.value;

    if (fragment.is(FragmentType.DOCUMENT)) {
      handleDocument(fragment as DocumentFragment);
      fragment.children.filter(documentChildPredicate).forEach(indexCallback);
    } else if (fragment.is(FragmentType.INLINE_REFERENCE)) {
      handleReferenceFragment(id, (fragment as InlineReferenceFragment).documentReference.value);
    } else if (fragment.is(FragmentType.DOCUMENT_REFERENCE)) {
      handleReferenceFragment(id, id);
    } else {
      // iterate over all other fragment types to find reference fragments in lists, tables etc
      iterateChildren(fragment);
    }
  };

  const iterateChildren = (fragment: Fragment): void => {
    fragment.children.forEach(indexCallback);
  };

  indexCallback(document);

  return lookup;
};
