import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'cars-tdif-figure',
  templateUrl: './tdif-figure.component.html',
  styleUrls: ['./tdif-figure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifFigureComponent implements OnInit {
  @Input() public index: string;
  @Input() public caption: string;
  @Input() public imageUrl: SafeUrl;

  constructor() {}

  ngOnInit(): void {}
}
