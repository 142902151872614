import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {RichTextType} from 'app/services/rich-text.service';
import {FragmentService} from '../../services/fragment.service';
import {ActionRequest} from '../action-request';
import {Caret} from '../caret';
import {FragmentComponent} from '../core/fragment.component';
import {getFinalEditableDescendant, getFirstEditableDescendant} from '../fragment-utils';
import {Key} from '../key';
import {TableCellFragment} from '../table/table-fragment';
import {
  Fragment,
  FragmentType,
  ListFragment,
  ListIndexingType,
  MemoFragment,
  SubscriptFragment,
  SuperscriptFragment,
  TextFragment,
} from '../types';

@Component({
  selector: 'cars-text-fragment',
  template: `<span [ngClass]="[inline ? 'inline' : '']" [class.schedule-list]="isInScheduleList()">{{
      content?.value
    }}</span>
    <span *ngIf="isUnitInScheduleTable()"><br /></span>`,
  styleUrls: ['../core/fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFragmentComponent extends FragmentComponent {
  @Input() public caption: boolean;
  @Input() public inline: boolean = false;

  @Input() public set content(value: Fragment) {
    super.content = value;
  }

  public get content(): Fragment {
    return super.content;
  }

  constructor(
    protected _fragmentService: FragmentService,
    protected _selectionOperationsService: SelectionOperationsService,
    protected _cd: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(_cd, elementRef);
  }

  /**
   * Respond to a keydown event on this fragment.  This simply sets caret deltas to allow
   * basic text editing, while other keys are allowed to bubble to parent fragments.
   *
   * @inheritdoc
   */
  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    if (key.isPrintable()) {
      return new ActionRequest(null, 0, 0, key.value);
    } else if (key.equalsUnmodified(Key.BACKSPACE)) {
      return this._handleBackspace(key, target, caret);
    } else if (key.equalsUnmodified(Key.DELETE)) {
      if (FragmentComponent.fromNode(target.element, FragmentType.EQUATION)) {
        // This is to prevent deleting at the end of an equation being wrongly handled by the text fragment.
        if (caret.offset === target.content.length()) {
          return new ActionRequest();
        }
      }
      return this._handleDelete(key, target, caret);
    } else {
      return null;
    }
  }

  /**
   * Handle rich-text events to allow superscript/subscript and memo creation.
   *
   * @inheritdoc
   */
  public onRichText(type: RichTextType, start: Caret, end: Caret, ...args: any[]): ActionRequest {
    switch (type) {
      case RichTextType.SUPERSCRIPT:
      case RichTextType.SUBSCRIPT:
      case RichTextType.MEMO: {
        const isSameType: boolean = args[0] === true;
        start = start.fragment.equals(this.content) ? start : Caret.startOf(this.content);
        end = end.fragment.equals(this.content) ? end : Caret.endOf(this.content);

        const created: Fragment[] = [];
        const middle: Fragment = this.content.split(start.offset);
        end.fragment = middle;
        end.offset -= this.content.length();

        if (end.offset < middle.length()) {
          const split: Fragment = middle.split(end.offset);
          split.insertAfter(this.content);
          created.push(split);
        }

        let sup: Fragment;

        if (
          type === RichTextType.MEMO &&
          (this.content.is(FragmentType.SUPERSCRIPT) || this.content.is(FragmentType.SUBSCRIPT))
        ) {
          sup = middle;
        } else if (type === RichTextType.SUPERSCRIPT && !this.content.is(FragmentType.SUPERSCRIPT)) {
          sup = new SuperscriptFragment(null, middle.value);
        } else if (type === RichTextType.SUBSCRIPT && !this.content.is(FragmentType.SUBSCRIPT)) {
          sup = new SubscriptFragment(null, middle.value);
        } else if (
          (type === RichTextType.MEMO && this.content.is(FragmentType.MEMO) && !isSameType) ||
          (type === RichTextType.MEMO && !this.content.is(FragmentType.MEMO))
        ) {
          sup = new MemoFragment(null, middle.value);
        } else {
          sup = new TextFragment(null, middle.value);
        }
        created.unshift(sup);
        sup.insertAfter(this.content);

        this._fragmentService.create(created);
        this._fragmentService.update(this.content);

        return new ActionRequest(sup, end.offset);
      }

      default: {
        return null;
      }
    }
  }

  public isInScheduleList(): boolean {
    const list: ListFragment = this.content.findAncestorWithType(FragmentType.LIST) as ListFragment;
    return !!list && list.primaryListIndexingType === ListIndexingType.LOWER_ALPHA;
  }

  public isUnitInScheduleTable(): boolean {
    const tableCell: TableCellFragment = this.content.findAncestorWithType(
      FragmentType.TABLE_CELL
    ) as TableCellFragment;
    const unitInput: Fragment = tableCell?.children.find((child) => child.is(FragmentType.UNIT_INPUT));
    const isParentTableCell: boolean = this.content.parent?.is(FragmentType.TABLE_CELL);

    return !!unitInput && /[\u200B]/g.test(this.content.value) && isParentTableCell;
  }

  /**
   * Handles backspace by sending action request to delete text from fragment.
   *
   * @param key    {Key}               The unmodifed key that was pressed
   * @param target {FragmentComponent} The highlighted fragment component
   * @param caret  {Caret}             The current caret state
   * @returns      {ActionRequest}     The desired action to take
   */
  private _handleBackspace(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    const offset: number = caret.offset;
    const parentOffset: number = this.content.parent.correctOffset(target.content, offset);

    if (offset > 0 || (offset === 0 && parentOffset > 0) || (this.caption && offset === 0)) {
      if (key.ctrl) {
        const words: string[] = this.content.value.substring(0, offset).split(/\b[ ]/);
        const remove: number = -Math.min(
          (words[words.length - 1].length || words[words.length - 2].length) + 1,
          offset
        );
        return new ActionRequest(null, 0, remove, null);
      } else if (offset === 0 && this.content.parent.is(FragmentType.TABLE_CELL)) {
        const isEmpty: boolean = !this.content.length();
        const isPreviousSiblingAnEquation: boolean =
          this.content.previousSibling() && this.content.previousSibling().is(FragmentType.EQUATION);
        const isPreviousSiblingAList: boolean = this.content.previousSibling()?.is(FragmentType.LIST);
        // Removing empty line after list in table cell
        if (isEmpty && isPreviousSiblingAList) {
          return this.removeEmptyLineNextToListInCell(false);
        }
        return isEmpty && !isPreviousSiblingAnEquation ? new ActionRequest() : null;
      } else {
        const remove: number = this.caption && !offset ? 0 : -1;
        return new ActionRequest(null, 0, remove, null);
      }
    } else if (offset === 0 && this.content.parent.is(FragmentType.TABLE_CELL)) {
      // Removing empty line before list in table cell
      if (!this.content.length() && this.content.nextSibling().is(FragmentType.LIST)) {
        return this.removeEmptyLineNextToListInCell(true);
      }
      return new ActionRequest();
    } else {
      return null;
    }
  }

  /**
   * Handles delete by sending action request to delete text from fragment.
   * Ignores lists, tables, figures & equations when calculating parents length.
   *
   * @param key    {Key}               The unmodifed key that was pressed
   * @param target {FragmentComponent} The highlighted fragment component
   * @param caret  {Caret}             The current caret state
   * @returns      {ActionRequest}     The desired action to take
   */
  private _handleDelete(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    const offset: number = caret.offset;
    const nextSibling: Fragment = this.content.nextSibling();
    const nextSiblingMergeable: boolean = nextSibling ? nextSibling.isMergeable() : false;
    const nextSiblingInline: boolean = nextSibling ? nextSibling.isInline() : false;
    const listFragment: Fragment = nextSibling
      ? nextSibling.is(FragmentType.LIST)
        ? this.content.nextSibling()
        : null
      : null;

    const contentLength: number = this.content.length();
    if (
      offset < contentLength ||
      (offset === contentLength && !!this.caption) ||
      (offset === contentLength && this.content.isMergeable() && nextSiblingMergeable)
    ) {
      if (key.ctrl) {
        const words: string[] = this.content.value.substring(offset).split(/[ ]\b/);
        const remove: number = Math.min((words[0].length || words[1].length) + 1, this.content.length() - offset);
        return new ActionRequest(null, 0, remove, null);
      } else {
        return new ActionRequest(null, 0, 1, null);
      }
    } else if (nextSiblingInline) {
      return new ActionRequest(null, 0, 1, null);
    } else if (listFragment) {
      // If deleting in an empty text fragment, then will keep the list and only remove the empty line
      // otherwise will merge the content children with the first list item children
      if (!this.content.length() && this.content.parent.is(FragmentType.TABLE_CELL)) {
        return this.removeEmptyLineNextToListInCell(true);
      } else {
        const moved: Fragment[] = listFragment.children[0].children.splice(0);

        this.parent.content.children.splice(this.content.index() + 1, 0, ...moved);
        this._fragmentService.update(moved);
        this._fragmentService.mergeChildren(this.content.parent);

        const list = listFragment.children.length > 1 ? listFragment.children[0] : listFragment;
        // Always list or list items, no need to validate
        this._fragmentService.delete([...list.children, list]);
      }

      return new ActionRequest();
    } else if (
      this.content.previousSibling()?.is(FragmentType.LIST) &&
      !this.content.length() &&
      this.content.parent.is(FragmentType.TABLE_CELL)
    ) {
      // Removing empty line after list in table cell
      return this.removeEmptyLineNextToListInCell(false);
    } else {
      return null;
    }
  }

  /**
   * Helper method to handle delete/backspace in empty text fragments before and after
   * a list in a Table cell and resetting the caret to the nearest editable fragment.
   *
   * @returns      {ActionRequest}     The desired action to take
   */
  private removeEmptyLineNextToListInCell(selectStart: boolean) {
    const editableFrag = selectStart
      ? getFirstEditableDescendant(this.content.nextSibling())
      : getFinalEditableDescendant(this.content.previousSibling());
    this._fragmentService.delete(this.content);
    const newOffset: number = selectStart ? 0 : editableFrag.length();
    this._selectionOperationsService.setSelected(editableFrag, newOffset, PadType.MAIN_EDITABLE);
    return new ActionRequest(editableFrag, newOffset);
  }
}
