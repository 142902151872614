import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {RangeMenuComponent} from './range-menu.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule, MatIconModule, MatButtonModule, CarsErrorHandlingModule],
  declarations: [RangeMenuComponent],
  exports: [RangeMenuComponent],
})
export class RangeMenuModule {}
