import {Inject, Injectable, NgZone} from '@angular/core';
import {EventManager, EVENT_MANAGER_PLUGINS} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CarsEventManager extends EventManager {
  constructor(@Inject(EVENT_MANAGER_PLUGINS) plugins: any[], private zone: NgZone) {
    super(plugins, zone);
  }

  /**
   * Overrides super.addEventListener to allow for option to run outside angular and therefore
   * not trigger a change detection cycle.
   */
  addEventListener(element: HTMLElement, eventName: string, handler: Function): Function {
    if (eventName.endsWith('no-cd')) {
      eventName = eventName.split('.')[0];
      return this.zone.runOutsideAngular(() => super.addEventListener(element, eventName, handler));
    }

    return super.addEventListener(element, eventName, handler);
  }

  /**
   * Overrides super.addGlobalEventListener to allow for option to run outside angular and therefore
   * not trigger a change detection cycle.
   */
  addGlobalEventListener(target: string, eventName: string, handler: Function): Function {
    if (eventName.endsWith('no-cd')) {
      eventName = eventName.split('.')[0];
      return this.zone.runOutsideAngular(() => super.addGlobalEventListener(target, eventName, handler));
    }

    return super.addGlobalEventListener(target, eventName, handler);
  }
}
