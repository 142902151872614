import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PrivacyFooterComponent} from './privacy-footer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PrivacyFooterComponent],
  exports: [PrivacyFooterComponent],
})
export class PrivacyFooterModule {}
