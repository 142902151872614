import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {RequiredNonWhitespaceModule} from 'app/utils/directives/required-non-whitespace.module';
import {AdministrationToDisplayNameModule} from 'app/utils/pipes/administration-to-display-name.module';
import {SuiteToDisplayNameModule} from 'app/utils/pipes/suite-to-display-name.module';
import {UserModule} from 'app/widgets/user/user.module';
import {DocumentCreatorComponent} from './document-creator.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatOptionModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    CarsErrorHandlingModule,
    MatProgressBarModule,
    MatPaginatorModule,
    FormsModule,
    MatIconModule,
    RequiredNonWhitespaceModule,
    MatButtonModule,
    UserModule,
    SuiteToDisplayNameModule,
    AdministrationToDisplayNameModule,
  ],
  declarations: [DocumentCreatorComponent],
  exports: [DocumentCreatorComponent],
})
export class DocumentCreatorModule {}
