<span
  #referenceRef
  contenteditable="false"
  [class.section-reference]="internalReferenceType !== InternalReferenceType.CLAUSE_REFERENCE"
  [class.selected-section-reference]="selected && internalReferenceType !== InternalReferenceType.CLAUSE_REFERENCE"
  [class.red]="targetFragmentDeleted"
  [class.line-through]="targetFragmentDeleted"
  (click)="onFocus()"
  >{{ sectionReferenceString
  }}<ng-container *ngIf="showDocumentReference"> [{{ content | fragmentIndex : defaultKey }}]</ng-container></span
>
