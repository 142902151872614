import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationBehaviorOptions, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {ViewMode} from '../view/current-view';
import {ViewService} from '../view/view.service';
import {PrintService} from './print.service';

@Component({
  selector: 'cars-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;

  constructor(private _router: Router, private _printService: PrintService, private _viewService: ViewService) {}

  ngOnInit() {
    // subscribe to the print service and only listen for the first print event to prevent multiple events triggered during
    // component configuration causing the print modal to reopen.
    this._subscription = this._printService
      .onPrintEvent()
      .pipe(take(1))
      .subscribe(() => {
        setTimeout(() => {
          window.print();
        });
      });
    window.onafterprint = () => {
      let navigateTo: string = this._router.url.split('/').slice(0, -2).join('/');
      const navOptions: NavigationBehaviorOptions = {state: {}};
      switch (this._viewService.getCurrentView().viewMode) {
        case ViewMode.BACKGROUND:
          navigateTo += '/background';
          break;
        case ViewMode.COMMENTS:
          navigateTo += '/comments';
          break;
        case ViewMode.CHANGE_SUMMARY:
          navigateTo += '/change-summary';
          navOptions.state['showBackgroundCommentary'] = history.state.showBackgroundCommentary;
          navOptions.state['currentOptionLH'] = history.state.currentOptionLH;
          navOptions.state['currentOptionRH'] = history.state.currentOptionRH;
          break;
        case ViewMode.WSR_SUBJECT_TOPIC:
          navigateTo += '/wsr-subject-topic';
          break;
      }
      this._router.navigateByUrl(navigateTo, navOptions);
    };
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
