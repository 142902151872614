import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UserHovertipComponent} from './user-hovertip.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UserHovertipComponent],
  exports: [UserHovertipComponent],
})
export class UserHovertipModule {}
