<span class="icons" *ngIf="!!fragment">
  <!-- Move up -->
  <button
    [disabled]="!canMove(true)"
    mat-icon-button
    [matTooltip]="getMoveHovertip(true, true)"
    matTooltipPosition="above"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="move(true)"
    [attr.aria-label]="getMoveHovertip(true, canMove(true))"
    [carsAnalytics]="'move-' + fragmentType"
    #moveUpTooltip="matTooltip"
  >
    <mat-icon>keyboard_arrow_up</mat-icon>
  </button>

  <!-- Move down -->
  <button
    [disabled]="!canMove(false)"
    mat-icon-button
    [matTooltip]="getMoveHovertip(false, true)"
    matTooltipPosition="above"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="move(false)"
    [attr.aria-label]="getMoveHovertip(false, canMove(false))"
    [carsAnalytics]="'move-' + fragmentType"
    #moveDownTooltip="matTooltip"
  >
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <!-- refresh -->
  <button
    *ngIf="isRefreshAvailable()"
    [disabled]="!canRefresh()"
    mat-icon-button
    matTooltip="Refresh Schedule Table Preview"
    matTooltipPosition="above"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="refresh()"
    aria-label="refresh Schedule Table"
    [carsAnalytics]="'refresh-' + fragmentType"
  >
    <mat-icon>refresh</mat-icon>
  </button>

  <!-- Delete -->
  <button
    [disabled]="!canLock()"
    mat-icon-button
    [matTooltip]="'Delete ' + (fragment | fragmentIndex)"
    matTooltipPosition="above"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="delete()"
    [carsAnalytics]="'delete-' + fragmentType"
  >
    <mat-icon>delete</mat-icon>
  </button>
</span>
