import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AssociatedDocumentInformationComponent} from './associated-document-information.component';

@NgModule({
  declarations: [AssociatedDocumentInformationComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  exports: [AssociatedDocumentInformationComponent],
})
export class AssociatedDocumentInformationModule {}
