import {Injectable} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {SectionFragment, SectionType} from 'app/fragment/types';
import {SectionService} from 'app/services/section.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConditionASTNode} from '../types/condition-ast-node';
import {EqualityRelation, PermissionInput} from '../types/permissions';
import {FalseOnPadTypes} from './false-on-pad-types';
import {PermissionsHandler} from './permissions-handler';

@Injectable()
export class SectionTypePermissionsHandler implements PermissionsHandler {
  constructor(private _sectionService: SectionService) {}

  public getInputType(): PermissionInput {
    return PermissionInput.SECTION_TYPE;
  }

  @FalseOnPadTypes(PadType.PUBLISHED_CHANGLOG, PadType.NO_PAD)
  public handle(condition: ConditionASTNode, padType: PadType): Observable<boolean> {
    const sectionType: SectionType = this._getSectionType(condition);
    const sectionStream: Observable<SectionFragment> = this._getSectionStream();
    switch (condition.relation) {
      case EqualityRelation.EQUALS:
        return sectionStream.pipe(map((section: SectionFragment) => section?.sectionType === sectionType));
      case EqualityRelation.NOT_EQUALS:
        return sectionStream.pipe(map((section: SectionFragment) => section?.sectionType !== sectionType));
      default:
        throw new Error(`Unsupported equality relation ${condition.relation}`);
    }
  }

  private _getSectionStream(): Observable<SectionFragment> {
    return this._sectionService.getSelectedSectionStream();
  }

  private _getSectionType(condition: ConditionASTNode): SectionType {
    const sectionType: SectionType = SectionType[condition.value as string];
    if (sectionType !== undefined) {
      return sectionType;
    } else {
      throw new Error(`Unknown Section Type: ${condition.value}`);
    }
  }
}
