import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {SupersededReferenceSelectorModule} from './superseded-reference-selector/superseded-reference-selector.module';
import {WithdrawalDialogComponent} from './withdrawal-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SupersededReferenceSelectorModule,
  ],
  declarations: [WithdrawalDialogComponent],
  exports: [WithdrawalDialogComponent],
})
export class WithdrawalDialogModule {}
