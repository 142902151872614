/* eslint-disable @angular-eslint/directive-class-suffix */
import {ChangeDetectorRef, Directive, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ClauseFragment, DocumentInformationFragment, DocumentInformationType, Fragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {UserService} from 'app/services/user/user.service';
import {User} from 'app/user/user';
import {Observable, of, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Directive()
export abstract class BaseFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public formGroup: UntypedFormGroup;

  protected _clause: ClauseFragment;
  protected _disabled: boolean;
  protected _canLock: boolean;
  protected _lockingUser: Observable<User>;
  protected _subscriptions: Subscription[] = [];

  public documentInformationType: DocumentInformationType;
  public titleText: string = '';
  public hintText?: string = '';
  public pattern?: RegExp;
  public patternErrorText?: string = '';

  constructor(
    protected fragmentService: FragmentService,
    protected lockService: LockService,
    protected userService: UserService,
    protected versioningService: VersioningService,
    protected cdr: ChangeDetectorRef
  ) {}

  /**
   * Initialises this component, setting up subscrpitions and adds the form control to the group.
   */
  public ngOnInit(): void {
    this._setup();
  }

  public ngOnChanges(): void {
    this._setup();
  }

  /**
   * Setup form control and initialise subscriptions.
   */
  protected _setup(): void {
    this._initFormGroup();
    this._initSubscriptions();
  }

  /**
   * Adds the form control to the group. We can override this method if we want to initialise other parts
   * of the component after the form group is initialised.
   */
  protected _initFormGroup(): void {
    this.formGroup.addControl(this.documentInformationType, new UntypedFormControl(this.docInfoFragment.value));
  }

  protected _onUpdatePredicate(fragment: Fragment): boolean {
    return !!fragment && fragment.id.equals(this._clause.children[0].id);
  }

  /**
   * Setup required subscriptions for this components document information fragment.
   */
  protected _initSubscriptions(): void {
    this.ngOnDestroy();
    this._subscriptions.push(
      this.fragmentService.onUpdate(
        (f: DocumentInformationFragment) => {
          this._patchValue(f);
        },
        (f: Fragment) => this._onUpdatePredicate(f) && !this._clause.validTo
      ),
      this.versioningService
        .getFragmentVersionUpdateObsStream(this._clause.validTo)
        .pipe(filter(this._onUpdatePredicate.bind(this)))
        .subscribe((f: DocumentInformationFragment) => this._patchValue(f)),
      this.lockService.onLockChange(this._clause, this._onLockUpdate.bind(this)),
      this.formGroup.valueChanges
        .pipe(filter(this._hasChangedLocally.bind(this)))
        .subscribe(this._updateFragment.bind(this))
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s && s.unsubscribe());
  }

  /**
   * This is used to check if the form has changed locally so that we should then update the fragment. This may be
   * overridden if detecting a local change is more complicated than checking if the current fragment is different
   * to the form.
   */
  protected _hasChangedLocally(): boolean {
    return String(this.docInfoFragment.value) !== String(this.formGroup.get(this.documentInformationType).value);
  }

  /**
   * Updates the fragment value. Returns a promise to allow overriding classes to wait for resolution of request.
   * This gets called only when the form is updated locally, after the form group is updated.
   *
   * @returns {Promise<number>}   Promise resolving to the HTTP status code of the request
   */
  protected _updateFragment(): Promise<number> {
    this.docInfoFragment.value = this.formGroup.get(this.documentInformationType).value;
    return this.fragmentService.update(this.docInfoFragment).then((response: any) => {
      this._afterUpdateHook();
      return response;
    });
  }

  /**
   * This method can be overridden to hook into fragment updates. This gets called after an update, sent locally to
   * the fragment service, has resolved.
   */
  protected _afterUpdateHook(): void {}

  /**
   * Sets the canLock value and udpates the locking user.
   *
   * @param canLock {boolean}   True if the user can lock the clause
   */
  protected _onLockUpdate(canLock: boolean): void {
    if (canLock !== this._canLock) {
      this.canLock = canLock;
      this._lockingUser = canLock
        ? null
        : this.userService.getUserFromId(this.lockService.getLock(this._clause).userId || null);
      this.cdr.markForCheck();
    }
  }

  /**
   * Public setter for the _disabled variable. Updates the form on setting.
   *
   * @param disabled {boolean}   True if form should be disabled
   */
  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
    this._updateLock();
  }

  /**
   * Sets the canLock value, determining whether the current user can lock the clause.
   *
   * @param canLock {boolean}   Can lock value
   */
  public set canLock(canLock: boolean) {
    this._canLock = canLock;
    this._updateLock();
  }

  /**
   * Updates the lock for the form group based on the disabled and canLock values.
   */
  protected _updateLock(): void {
    !this._canLock || this._disabled ? this.formGroup.disable() : this.formGroup.enable();
    this.cdr.markForCheck();
  }

  /**
   * Public getter for the canLock value.
   *
   * @returns {boolean}   True if user can lock the clause
   */
  public get canLock(): boolean {
    return this._canLock;
  }

  /**
   * Public getter for the document information fragment.
   *
   * @returns {DocumentInformationFragment}   The document information fragment
   */
  public get docInfoFragment(): DocumentInformationFragment {
    return this._clause && (this._clause.children[0] as DocumentInformationFragment);
  }

  /**
   * Public getter for the value of the document information fragment.
   *
   * @returns {string}   The value of the document information fragment
   */
  public get value(): string {
    return this.docInfoFragment.value;
  }

  /**
   * Public getter for the locked text.
   *
   * @returns {Observable<string>}   Observable object resolving to the locked text
   */
  public get lockedText(): Observable<string> {
    const locked: string = `Locked`;
    return this._lockingUser
      ? this._lockingUser.pipe(map((user: User) => `${locked} by ${user.firstName} ${user.lastName}`))
      : of(locked);
  }

  /**
   * Marks the control for the form group as touched. This can be used to manually mark a control as touched that otherwise
   * would not be, for example control that doesn't use and angular component as its display.
   *
   * @param onlySelf {boolean}   True if only this control should be marked; defaults to false
   */
  public markAsTouched(onlySelf: boolean = false): void {
    this.formGroup.get(this.documentInformationType).markAsTouched({onlySelf});
    this.cdr.markForCheck();
  }

  /**
   * Patches the value from the document information fragment onto the form control. This is called when a document
   * information fragment changes, whether that change originated locally or was received via websocket.
   *
   * @param fragment  {DocumentInformationFragment}    The updated fragment
   * @param emitEvent {boolean}                        Whether the form should broadcast this new value; defaults to false
   */
  protected _patchValue(fragment: DocumentInformationFragment, emitEvent: boolean = false): void {
    this.formGroup.patchValue({[this.documentInformationType]: fragment.value}, {emitEvent});
    this.cdr.markForCheck();
  }

  /**
   * Locks the clause fragment for this component.
   */
  public lock(): void {
    this.lockService.lock(this._clause);
  }

  /**
   * Un-locks the clause fragment for this component.
   */
  public unlock(): void {
    this.lockService.unlock(this._clause);
  }
}
