import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'map',
  pure: false,
})
export class MapPipe implements PipeTransform {
  transform(value: any, args?: any[]): object[] {
    const returnArray = [];

    value.forEach((entryVal, entryKey) => {
      returnArray.push({
        key: entryKey,
        val: entryVal,
      });
    });
    return returnArray;
  }
}
