import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {DateAdapter, MatOptionSelectionChange, NativeDateAdapter} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {MatInput} from '@angular/material/input';
import {MatTableDataSource} from '@angular/material/table';
import {SearchableGlobalReferenceService} from 'app/services/references/searchable-global-reference.service';
import {SupersedingReferenceService} from 'app/services/references/superseding-reference.service';
import {SearchableGlobalReference} from 'app/sidebar/references/searchable-global-reference';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {SupersedingReference} from '../../../../sidebar/references/superseding-reference';
import {Page} from '../../manage-references.component';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return date.getDate() + ' ' + super.getMonthNames('long')[date.getMonth()] + ' ' + date.getFullYear();
  }
}

@Component({
  selector: 'cars-superseded-reference-selector',
  templateUrl: 'superseded-reference-selector.component.html',
  styleUrls: ['superseded-reference-selector.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
  ],
})
export class SupersededReferenceSelectorComponent implements OnInit, OnDestroy {
  @ViewChildren(MatDatepicker) datePickers: QueryList<MatDatepicker<Date>>;
  @ViewChild(MatAutocompleteTrigger) public trigger: MatAutocompleteTrigger;
  @ViewChild(MatInput) input: MatInput;
  @ViewChild(MatAutocomplete) autoComplete: MatAutocomplete;
  @Input() oldReference: SearchableGlobalReference;
  @Output() onFormChangeIsValid: EventEmitter<boolean> = new EventEmitter();
  @Output() onSupersedingReferencesChanged: EventEmitter<SupersedingReference[]> = new EventEmitter();

  public referenceDropdownCtrl = new UntypedFormControl();
  public dateControllers: Record<string, UntypedFormControl> = {};
  public searchableGlobalReferenceLookup: Record<string, SearchableGlobalReference> = {};
  public supersedingReferenceLookup: Record<string, SupersedingReference> = {};
  public data: SearchableGlobalReference[] = [];
  public dataSource: MatTableDataSource<SupersedingReference>;

  public resultsLength: number = 0;
  public pageSize: number = 50;
  public pageIndex: number = 0;
  public searchTerm: string = '';
  public deleted: boolean = false;
  public displayedColumns: string[] = ['reference', 'title', 'date', 'delete'];

  private _subscriptions: Subscription[] = [];

  constructor(
    private supersedingReferenceService: SupersedingReferenceService,
    private searchableGlobalReferenceService: SearchableGlobalReferenceService,
    private cdr: ChangeDetectorRef
  ) {
    this.dataSource = new MatTableDataSource<SupersedingReference>([]);
  }

  ngOnInit(): void {
    this.addCurrentSupersedingReferencesToList();
    this.updateData();
    this.referenceDropdownCtrl.valueChanges.subscribe(() => {
      this.searchTerm = this.referenceDropdownCtrl.value;
      this.updateData();
    });

    this.emitIsValid();
    this.emitSupersedingReferences();
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /*
   * On select of superseding reference, add to list
   */
  public onSelect(evt: MatOptionSelectionChange, reference: SearchableGlobalReference) {
    if (evt.isUserInput) {
      this.referenceDropdownCtrl.patchValue('', {emitEvent: true});
      const supersedingReference = SupersedingReference.deserialise({
        id: UUID.random().value,
        oldReference: this.oldReference.globalReferenceId.value,
        newReference: reference.globalReferenceId.value,
      });
      this._addSupersedingReference(supersedingReference);
    }
  }

  /*
   * Update the valid event emitter to say whether form is valid
   */
  private emitIsValid(): void {
    let valid: boolean = true;
    Object.keys(this.dateControllers).forEach((key) => {
      valid = valid && this.dateControllers[key].valid && this.dateControllers[key].value != null;
    });
    this.onFormChangeIsValid.emit(valid);
  }

  /*
   * Update the superseding references event emitter
   */
  private emitSupersedingReferences(): void {
    this.onSupersedingReferencesChanged.emit(this.dataSource.data);
  }

  /*
   * Updates content of superseding reference search dropdown
   */
  private updateData(): void {
    this.searchableGlobalReferenceService
      .fetchSearchableGlobalReferences(
        this.searchTerm,
        this.deleted,
        this.pageIndex,
        this.pageSize,
        true,
        'withdrawn',
        'asc'
      )
      .subscribe((result: Page<SearchableGlobalReference>) => {
        this.data = result.resultsList;
        result.resultsList.forEach((searchableGlobalReference) => {
          this.searchableGlobalReferenceLookup[searchableGlobalReference.globalReferenceId.value] =
            searchableGlobalReference;
        });
        this.resultsLength = result.total;
        this.cdr.markForCheck();
        this.emitIsValid();
        this.emitSupersedingReferences();
      });
  }

  /**
   * Force the dropdown panel to open whenever the autocomplete input is clicked,
   * rather than only on the first click.
   */
  public forcePanelOpen(): void {
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  /*
   * Called on init to add all the current superseding references to the list.
   */
  private addCurrentSupersedingReferencesToList() {
    this.supersedingReferenceService
      .fetchSupersedingReferences(this.oldReference.globalReferenceId.value)
      .then((supersedingReferences: SupersedingReference[]) => {
        supersedingReferences.forEach((supersedingReference: SupersedingReference) => {
          this.searchableGlobalReferenceService
            .fetchSearchableGlobalReference(supersedingReference.newReference)
            .subscribe((searchableGlobalReference: SearchableGlobalReference) => {
              this.searchableGlobalReferenceLookup[supersedingReference.newReference.value] = searchableGlobalReference;
              this._addSupersedingReference(supersedingReference);
            });
        });
      });
  }

  private _addSupersedingReference(supersedingReference: SupersedingReference): void {
    this.supersedingReferenceLookup[supersedingReference.newReference.value] = supersedingReference;
    this._addDateController(supersedingReference);
    this.dataSource.data.push(supersedingReference);
    this.dataSource.data = [...this.dataSource.data];
    this.cdr.markForCheck();
    this.emitIsValid();
    this.emitSupersedingReferences();
  }

  private _addDateController(supersedingReference: SupersedingReference): void {
    this.dateControllers[supersedingReference.newReference.value] = new UntypedFormControl(Validators.required);
    this.dateControllers[supersedingReference.newReference.value].setValue(
      supersedingReference.supersededOn || supersedingReference.supersededOn === 0
        ? new Date(supersedingReference.supersededOn)
        : null
    );
    this._subscriptions.push(
      this.dateControllers[supersedingReference.newReference.value].valueChanges.subscribe((date: Date) => {
        if (date) {
          this.supersedingReferenceLookup[supersedingReference.newReference.value].supersededOn = date.getTime();
        }
        this.cdr.markForCheck();
        this.emitIsValid();
        this.emitSupersedingReferences();
      })
    );
  }

  /*
   * Delete a superseding reference from the table, not done on database until save & close is pressed.
   */
  public delete(row: SupersedingReference) {
    const index = this.dataSource.data.indexOf(row, 0);
    if (index > -1) {
      delete this.supersedingReferenceLookup[row.newReference.value];
      delete this.dateControllers[row.newReference.value];
      this.dataSource.data.splice(index, 1);
    }
    this.dataSource.data = [...this.dataSource.data];
    this.cdr.markForCheck();
    this.emitIsValid();
    this.emitSupersedingReferences();
  }
}
