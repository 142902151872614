import {Component, ElementRef, Input, Renderer2} from '@angular/core';
import {AbstractHovertipComponent} from 'app/widgets/abstract-hovertip.component';

@Component({
  selector: 'cars-reference-hovertip',
  templateUrl: './reference-hovertip.component.html',
  styleUrls: ['./reference-hovertip.component.scss'],
})
export class ReferenceHovertipComponent extends AbstractHovertipComponent {
  @Input() public title: string;

  protected readonly leftOffset: number = -15;
  protected readonly topOffset: number = 10;

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }
}
