import {Injectable} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {AuthenticationProvider} from 'app/services/user/authentication-provider';
import {DisabledUserService} from 'app/services/user/disabled-user.service';
import {User} from 'app/user/user';
import {combineLatest, Observable} from 'rxjs';
import {map, skipWhile} from 'rxjs/operators';
import {ConditionASTNode} from '../types/condition-ast-node';
import {EqualityRelation, PermissionInput} from '../types/permissions';
import {FalseOnPadTypes} from './false-on-pad-types';
import {PermissionsHandler} from './permissions-handler';

@Injectable()
export class UserDisabledPermissionsHandler implements PermissionsHandler {
  private readonly trueConditionValue: string = 'TRUE';
  private readonly falseConditionValue: string = 'FALSE';
  private readonly userDisabledProperties: string[] = [this.trueConditionValue, this.falseConditionValue];

  constructor(
    private disabledUserService: DisabledUserService,
    private authenticationProvider: AuthenticationProvider
  ) {}

  public getInputType(): PermissionInput {
    return PermissionInput.USER_DISABLED;
  }

  @FalseOnPadTypes(PadType.NO_PAD, PadType.PUBLISHED_CHANGLOG)
  public handle(condition: ConditionASTNode, padType: PadType): Observable<boolean> {
    if (condition.relation !== EqualityRelation.EQUALS) {
      throw new Error(`Unsupported equality relation: ${condition.relation}`);
    } else {
      const userShouldBeDisabled: boolean = this.validateAndGetBooleanConditionValue(condition);

      const userObsStream: Observable<User> = this.authenticationProvider
        .getCurrentUserObs()
        .pipe(skipWhile((user: User) => user === null));

      const disabledUserObsStream: Observable<Record<string, boolean>> = this.disabledUserService.onUserStatuses();

      return combineLatest([userObsStream, disabledUserObsStream]).pipe(
        map(([user, idToDisabled]: [User, Record<string, boolean>]) => {
          const isDisabled: boolean = !!idToDisabled[user.id.value] && idToDisabled[user.id.value];

          return userShouldBeDisabled === isDisabled;
        })
      );
    }
  }

  private validateAndGetBooleanConditionValue(condition: ConditionASTNode): boolean {
    if (!this.userDisabledProperties.includes(condition.value)) {
      throw new Error(`Unknown condition for USER_DISABLED: ${condition.value}`);
    }

    return condition.value === this.trueConditionValue;
  }
}
