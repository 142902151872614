import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {DocumentFragment, SectionFragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {Breadcrumb, VersionTag} from 'app/interfaces';
import {DocumentFetchParams, DocumentService} from 'app/services/document.service';
import {SectionFetchParams, SectionService} from 'app/services/section.service';
import {UUID} from 'app/utils/uuid';
import {ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';
import {Observable, of} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';

export abstract class AbstractSectionResolver implements Resolve<SectionFragment> {
  /**
   * The name of the last URL segment, e.g. 'background'.  If this is null, there will be
   * no last segment added, and no corresponding breadcrumb will be generated.
   */
  protected readonly breadcrumb: string = '';

  /** The name of the view, e.g. 'Background and Commentary' */
  protected readonly routeName: string = '';

  /** The viewmode to set on load. */
  protected readonly viewMode: ViewMode = ViewMode.LIVE;

  protected readonly location: CurrentLocation = CurrentLocation.UNKNOWN;

  /** Holds the viewMode from before navigation starts. */
  private previousViewMode: ViewMode;

  /**  Callback which will run once the section is loaded. */
  protected onLoad: (section: SectionFragment) => void = () => {};

  constructor(
    protected documentService: DocumentService,
    protected sectionService: SectionService,
    protected versioningService: VersioningService,
    protected viewService: ViewService,
    protected snackBar: MatSnackBar,
    protected router: Router
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SectionFragment> {
    const documentId: string = this.getRouteParam(route, 'document_id');
    const versionId: string = this.getRouteParam(route, 'version_id');

    this.previousViewMode = this.viewService.getCurrentView() ? this.viewService.getCurrentView().viewMode : null;

    return this.getVersionTag(versionId).pipe(
      take(1),
      mergeMap((versionTag: VersionTag) => {
        const fetchParams: DocumentFetchParams =
          versionTag !== null
            ? {
                projection: 'INITIAL_DOCUMENT_LOAD',
                validAt: versionTag.createdAt,
              }
            : {projection: 'INITIAL_DOCUMENT_LOAD'};

        const document: DocumentFragment = versionId ? null : this.findDocumentInCache(UUID.orNull(documentId));
        const documentPromise: Promise<DocumentFragment> = document
          ? Promise.resolve(document)
          : this.documentService.load(UUID.orNull(documentId), fetchParams);
        this.setCurrentView(this.previousViewMode, versionTag);
        return documentPromise.then((doc: DocumentFragment) => this.onDocumentLoad(doc, route, versionTag));
      })
    );
  }

  /**
   * Finds the document if already cached with children.
   *
   * @param id {UUID}             The ID of the document.
   * @returns  {DocumentFragment} The document.
   */
  private findDocumentInCache(id: UUID): DocumentFragment {
    const doc: DocumentFragment = this.documentService.find(id);
    return doc && doc.hasChildren() ? doc : null;
  }

  /**
   * Get a param from a route.  If not found, will check the route's parent, and so on
   * until either the param is found or there are no more parents.
   *
   * @param route {ActivatedRouteSnapshot} The route to check.
   * @param param {string}                 The param to search for.
   *
   * @returns     {string}                 The value of the param if found, or null if not.
   */
  private getRouteParam(route: ActivatedRouteSnapshot, param: string): string {
    let value: string = null;
    while (!value && route) {
      value = route.paramMap.get(param);
      if (!value) {
        route = route.parent;
      }
    }
    return value;
  }

  /**
   * Determines whether this is a child of a document-level route or not.
   *
   * @param route {ActivatedRouteSnapshot} The route.
   * @returns     {boolean}                True if this is not a child route.
   */
  private _isTopLevelRoute(route: ActivatedRouteSnapshot): boolean {
    return route.paramMap.has('document_id');
  }

  /**
   * Callback on document load.
   *
   * @param document   {DocumentFragment}       The loaded document.
   * @param route      {ActivatedRouteSnapshot} The route.
   * @param versionTag {VersionTag}             The version, or null if viewing the live version.
   *
   * @returns          {Promise<SectionFragment>} A promise which resolves to the section being loaded,
   * after this.onSectionLoad has been called.
   */
  protected onDocumentLoad(
    document: DocumentFragment,
    route: ActivatedRouteSnapshot,
    versionTag: VersionTag
  ): Promise<SectionFragment> {
    const sectionId: string = this.getRouteParam(route, 'section_id');
    const fetchParams: SectionFetchParams =
      versionTag !== null ? {validAt: versionTag.createdAt, projection: 'FULL_TREE'} : {projection: 'FULL_TREE'};
    return this.sectionService
      .load(UUID.orNull(sectionId), fetchParams)
      .then((section: SectionFragment) => this.onSectionLoad(document, section, route, versionTag))
      .catch((err: any) => {
        this.handleSectionNotFound(err, sectionId);
        return null;
      });
  }

  /**
   * Callback on section load.
   *
   * @param document   {DocumentFragment}       The loaded document.
   * @param section    {SectionFragment}        The loaded section.
   * @param route      {ActivatedRouteSnapshot} The route.
   * @param versionTag {VersionTag}             The version, or null if viewing the live version.
   *
   * @returns          {SectionFragment}        The loaded section.
   */
  protected onSectionLoad(
    document: DocumentFragment,
    section: SectionFragment,
    route: ActivatedRouteSnapshot,
    versionTag: VersionTag
  ): SectionFragment {
    this.documentService.setSelected(document);
    this.sectionService.setSelected(section);
    const breadcrumbs: Breadcrumb[] = this._generateBreadcrumbs(document, section, route, versionTag);
    route.data = {breadcrumbs: breadcrumbs};

    this.setCurrentView(this.previousViewMode, versionTag);
    this.onLoad(section);
    return section;
  }

  /**
   * Generates the breadcrumbs for the view, including version breadcrumbs if needed.
   *
   * @param document   {DocumentFragment} The document fragment
   * @param section    {SectionFragment}  The section fragment
   * @param versionTag {VersionTag}       The version tag
   * @returns          {Breadcrumb[]}     The generated breadcrumbs
   */
  private _generateBreadcrumbs(
    document: DocumentFragment,
    section: SectionFragment,
    route: ActivatedRouteSnapshot,
    versionTag: VersionTag
  ): Breadcrumb[] {
    const urlSegments: Map<string, string> = new Map();

    // If document title and section title overlay the path can become corrupt.
    // We add an invisible space to the section title when the document title
    // and section title are the same.
    const documentTitle = document.title;
    const sectionTitle = section.title === document.title ? section.title + ' ' : section.title;

    if (this._isTopLevelRoute(route)) {
      urlSegments.set('Documents', 'documents');
      urlSegments.set(documentTitle, document.id.value);
      if (versionTag) {
        urlSegments.set('Versions', 'versions');
        urlSegments.set(versionTag.name, versionTag.versionId.value);
      }
    }
    urlSegments.set(sectionTitle, `sections/${section.id.value}`);
    if (this.breadcrumb) {
      urlSegments.set(this.routeName, this.breadcrumb);
    }

    const breadcrumbs: Breadcrumb[] = [];
    let link: string = this._isTopLevelRoute(route) ? '' : route.parent.url.map((seg) => seg.path).join('/');
    urlSegments.forEach((urlSegment: string, title: string) => {
      link += '/' + urlSegment;
      breadcrumbs.push({title: title, link: link});
    });

    return breadcrumbs;
  }

  /**
   * Routes the users to the documents page if the given sectionId is not found.
   *
   * @param sectionId {string} ID of section not found.
   */
  protected handleSectionNotFound(err: any, sectionId: string): void {
    const message: string = `Failed to open ${this.routeName} view for section with id: ${sectionId}`;
    Logger.error('navigation-error', message, err);
    this.router.navigate(['/documents']).then(() => {
      this.snackBar.open(message, 'Dismiss', {duration: 3000});
    });
  }

  /**
   * Can be optionally overridden to allow fine-grained control over the version tag such as getting
   * the last version tag from the view service.
   *
   * @returns {Observable<VersionTag>}
   */
  protected getVersionTag(versionId: string): Observable<VersionTag> {
    return versionId !== null ? this.versioningService.getVersionTagFromVersionId(UUID.orNull(versionId)) : of(null);
  }

  /**
   * Can be optionally overridden to allow fine-grained control over what the
   * next viewmode will be given the previous viewmode and the version tag.
   *
   * @param previousView {ViewMode}   The previous view.  Might be null.
   * @param versionTag   {VersionTag} The tag of the version being loaded.  Might be null.
   *
   * @returns {ViewMode} The new view to use.  Should not be null.
   */
  protected updateCurrentView(previousView: ViewMode, versionTag?: VersionTag): ViewMode {
    return this.viewMode;
  }

  private setCurrentView(previousView: ViewMode, versionTag: VersionTag): void {
    const newViewMode: ViewMode = this.updateCurrentView(this.previousViewMode, versionTag);
    this.viewService.setCurrentView(newViewMode, versionTag);
    this.viewService.setLocation(this.location);
  }
}
