import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {DiscussionsIconComponent} from './discussions-icon.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatTooltipModule, CarsPermissionPipesModule],
  declarations: [DiscussionsIconComponent],
  exports: [DiscussionsIconComponent],
})
export class DiscussionsIconModule {}
