import {ChangeDetectionStrategy, Component} from '@angular/core';

interface FooterEntry {
  displayName: string;
  url: string;
  ariaLabel: string;
}

@Component({
  selector: 'cars-privacy-footer',
  templateUrl: './privacy-footer.component.html',
  styleUrls: ['./privacy-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyFooterComponent {
  public readonly listDivider: string = '|';

  public readonly entryList: FooterEntry[] = [
    {
      displayName: 'Privacy policy',
      url: 'https://help.futuredmrb.co.uk/privacy-policy/',
      ariaLabel: 'CARS Privacy Policy',
    },
    {
      displayName: 'Accessibility statement',
      url: 'https://help.futuredmrb.co.uk/accessibility-statement/',
      ariaLabel: 'CARS Accessibility Statement',
    },
    {
      displayName: 'Cookie policy',
      url: 'https://help.futuredmrb.co.uk/cookie-policy/',
      ariaLabel: 'CARS Cookie Policy',
    },
  ];

  constructor() {}
}
