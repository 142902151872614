<div class="toolbar">
  <div class="toolbar-container" #toolbar>
    <ng-content></ng-content>
  </div>
  <button
    mat-button
    [attr.blurOption]="BlurOption.PREVENT_BLUR"
    #more
    class="cars-toolbar-more"
    [ngStyle]="{display: overflowing ? 'block' : 'none'}"
    (click)="onToggleShowOverflow($event)"
    aria-label="Show more options"
  >
    <mat-icon>{{ expandDirection }}</mat-icon>
  </button>
</div>
<div
  class="toolbar overflow mat-elevation-z2"
  [ngStyle]="{
    right: overflowToolbarRight + 'px',
    'max-width': maxOverflowWidth + 'px'
  }"
>
  <div class="toolbar-container" #overflow [ngStyle]="{display: showMore ? 'block' : 'none'}"></div>
</div>
