import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {DialogModule} from 'app/dialog/dialog/dialog.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {UserModule} from 'app/widgets/user/user.module';
import {FeedbackDialogModule} from './feedback-dialog/feedback-dialog.module';
import {HeaderComponent} from './header.component';
import {NotificationAreaModule} from './notification-area/notification-area.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatTooltipModule,
    CarsErrorHandlingModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    NotificationAreaModule,
    MatDialogModule,
    FeedbackDialogModule,
    DialogModule,
    AltAccessibilityModule,
    UserModule,
    MatSlideToggleModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
