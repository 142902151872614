import {Pipe, PipeTransform} from '@angular/core';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';

export const VERSION_TAG_TYPE_TO_DISPLAY_NAME: Readonly<Record<VersionTagType, string>> = {
  DRAFT_FOR_PEER_REVIEW: 'Draft for peer review',
  DRAFT_FOR_CONSULTATION: 'Draft for consultation',
  FOR_PUBLICATION: 'For approval/authorisation',
  FOR_PUBLICATION_CHANGES_REQUIRED: 'For approval/authorisation - changes required',
  FOR_PUBLICATION_PUBLISHED: 'For approval/authorisation - published',
  NOT_KNOWN: 'Not known',
  TEMPLATE: 'Template',
  REVERT_SNAPSHOT: 'Revert snapshot',
};

@Pipe({
  name: 'versionTagTypeDisplayName',
})
export class VersionTagTypeToDisplayNamePipe implements PipeTransform {
  private static readonly UNKNOWN_TYPE: string = 'Unknown reason';

  /**
   * Transforms the version tag type enum into a friendly display name.
   * The {@link VERSION_TAG_TYPE_TO_DISPLAY_NAME} const is exported for consumers wanting to use this within typescript files.
   *
   * @param type  {VersionTagType}    The version tag type
   * @returns     {string}            The display name
   */
  public transform(type: VersionTagType): string {
    return !!VERSION_TAG_TYPE_TO_DISPLAY_NAME[type]
      ? VERSION_TAG_TYPE_TO_DISPLAY_NAME[type]
      : VersionTagTypeToDisplayNamePipe.UNKNOWN_TYPE;
  }
}
