import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatChipsModule} from '@angular/material/chips';
import {SavingChipComponent} from './saving-chip.component';

@NgModule({
  imports: [CommonModule, MatChipsModule],
  declarations: [SavingChipComponent],
  exports: [SavingChipComponent],
})
export class SavingChipModule {}
