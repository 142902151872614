import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Fragment} from 'app/fragment/types';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {FragmentService} from 'app/services/fragment.service';
import {DisplayReferenceUtils} from 'app/services/references/reference-utils/display-reference-utils';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-reference-input-children',
  templateUrl: './reference-input-children.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceInputChildrenComponent implements OnInit, OnDestroy {
  private static readonly COMMA: string = ', ';

  @Input() public readOnly: boolean;
  @Input() public content: InternalInlineReferenceFragment[];
  @Input() public contentId: UUID;

  @Input() public set coordinatingConjunction(value: 'and' | 'or') {
    this._coordinatingConjunction = ` ${value} `;
  }

  private _coordinatingConjunction: string;

  private _subscription: Subscription;

  constructor(private _fragmentService: FragmentService, private _cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this._subscription = this._fragmentService.onCreate(
      () => this._cdr.markForCheck(),
      (fragment: Fragment) => fragment.parentId?.equals(this.contentId)
    );
  }

  public ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  public showSeparator(index: number): boolean {
    return this.content && index < this.content.length - 1;
  }

  public getSeparator(index: number): string {
    return DisplayReferenceUtils.getSeparatorForReferenceInputChildren(
      this._coordinatingConjunction,
      index,
      this.content.length
    );
  }
}
