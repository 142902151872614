import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {DocumentRepository, UserRepository} from 'app/offline/repository';
import {KeycloakAuthenticationProvider} from 'app/services/user/keycloak-authentication-provider';
import {OfflineAuthenticationProvider} from 'app/services/user/offline-authentication-provider';
import {LocalConfigUtils} from 'app/utils/local-config-utils';

export class AuthenticationProviderFactory {
  public static factory = (
    httpClient: HttpClient,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    ur: UserRepository,
    dr: DocumentRepository
  ) => {
    if (LocalConfigUtils.getConfig().offline) {
      return new OfflineAuthenticationProvider(ur);
    } else {
      if (!window.navigator.onLine) {
        dr.count().then((count: number) => {
          const ref: MatDialogRef<DialogComponent> = dialog.open(DialogComponent);
          ref.disableClose = true;

          const component = ref.componentInstance;
          component.title = 'No internet connection detected';
          component.message =
            'CARS will not function without an internet connection, unless in offline review mode<br>' +
            'In offline review mode, you will only be able to review versions of documents' +
            'that you have previously downloaded for offline review.<br>' +
            'You currently have ' +
            count +
            ' documents available offline<br>' +
            '<br>' +
            'Would you like to enter offline review mode and reload CARS, or try to reload CARS in online mode?';
          component.closeActions = [
            {title: 'Enter Offline Review Mode', tooltip: 'Enter Offline Review Mode', response: true},
            {title: 'Reload Online', tooltip: 'Reload Online', response: false},
          ];

          ref.afterClosed().subscribe((response: boolean) => {
            if (response) {
              LocalConfigUtils.setOffline(true);
            }
            window.location.reload();
          });
        });
      }
      return new KeycloakAuthenticationProvider(httpClient, dialog, snackBar);
    }
  };
}
