import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Log} from 'app/error-handling/services/logger/log';
import {environment} from 'environments/environment';

@Component({
  selector: 'cars-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: ['./user-logs.component.scss'],
})
export class UserLogsComponent implements OnInit {
  private static readonly _API_URL = `${environment.apiHost}/logs/search/recent`;

  public logs: Log[] = [];

  constructor(private _httpClient: HttpClient) {}

  ngOnInit() {
    this.getLogs();
  }

  public getLogs(): void {
    this._httpClient.get(UserLogsComponent._API_URL).subscribe((res) => {
      const serialised: any[] = res['_embedded']['logs'];
      this.logs = serialised.map((json: any) => Log.deserialise(json));
    });
  }
}
