import {RingBuffer} from './ring-buffer';

/**
 * Value object for cache entries.
 *
 * @tparam        {T}               The entry type
 * @field live    {T}               The live object reference
 * @field history {RingBuffer<T>}   The historical states
 */
export class CacheEntry<T> {
  public live: T;
  public history: RingBuffer<T>;

  constructor(live: T, capacity: number) {
    this.live = live;
    this.history = new RingBuffer(capacity);
  }

  /**
   * Convenience getter for this entry's capacity.
   *
   * @returns {number}   The capacity
   */
  public get capacity(): number {
    return this.history.capacity;
  }
}
