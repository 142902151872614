<div *ngIf="document" class="container">
  <span>These fields are automatically populated from Jira, when available</span>
  <div class="mat-form-field-wrapper">
    <div class="formInput mat-form-field-infix owner-display">
      <mat-icon tabindex="-1" aria-hidden>account_box</mat-icon>
      <span>Technical Author:</span>
      <cars-assigned-user
        [document]="document"
        [userId]="document.documentData.owner"
        [userHasDisabledStatus]="ownerHasDisabledStatus"
        [showRemove]="false"
      ></cars-assigned-user>
    </div>
    <div class="hint mat-hint mat-form-field-subscript-wrapper"></div>
  </div>
  <mat-form-field class="formInput">
    <input
      matInput
      [value]="document.documentData.metadata['EPIC_ID']"
      placeholder="Jira Change ABCD ID"
      readonly
      disabled="true"
    />
    <a
      matSuffix
      mat-button
      target="_blank"
      *ngIf="document.documentData.metadata['EPIC_ID']"
      [href]="jiraUrl + document.documentData.metadata['EPIC_ID']"
    >
      <mat-icon tabindex="-1" aria-hidden>open_in_new</mat-icon>
      <span>Open in Jira</span>
    </a>
  </mat-form-field>
  <mat-form-field class="formInput">
    <input
      matInput
      [value]="document.documentData.workflowStatus"
      placeholder="Workflow Status"
      readonly
      disabled="true"
    />
  </mat-form-field>
  <mat-form-field class="formInput" *ngFor="let extra of jiraExtras">
    <input matInput [value]="extra[1]" [placeholder]="extra[0]" [name]="extra[0]" readonly disabled="true" />
  </mat-form-field>
</div>
