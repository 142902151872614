<div class="references-toplevel">
  <mat-tab-group
    [@.disabled]="true"
    [ngClass]="{invisible: addingReference}"
    [(selectedIndex)]="tabIndex"
    (selectedIndexChange)="tabIndexChanged($event)"
  >
    <mat-tab label="Document references">
      <div class="references-panel">
        <div class="references-main">
          <cars-debounce-search
            placeholder="Filter references"
            hint="Search document references"
            (onSearchChange)="updateFilterString($event)"
          ></cars-debounce-search>
          <div>
            <cars-reference-list
              [referenceListType]="'DOCUMENT_REFERENCES'"
              [currentRefId]="currentRefId"
              [referenceList]="documentReferences"
              [resultsLength]="documentReferencesResultLength"
              [filter]="filterString"
              (selection)="selectReferenceAlreadyInDocument($event)"
              (onSearch)="updateReferenceLists($event)"
            >
            </cars-reference-list>
          </div>
        </div>
        <div class="references-buttons">
          <button
            mat-raised-button
            color="primary"
            (click)="onOpenLibrary(true)"
            aria-label="Open reference library"
            enableDefaultFocus
          >
            <mat-icon>library_books</mat-icon>
            Reference Library
            <cars-alt-accessibility
              [altProperties]="{initialKey: 'r', key: 'r', condition: true}"
              (onSelect)="onOpenLibrary(true)"
            ></cars-alt-accessibility>
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Library">
      <div class="references-panel">
        <div class="references-main">
          <div class="button-area">
            <cars-debounce-search
              placeholder="Filter references"
              hint="Search global references"
              (onSearchChange)="updateFilterString($event)"
            ></cars-debounce-search>
          </div>
          <div>
            <cars-reference-list
              [referenceListType]="'ALL_REFERENCES'"
              [currentRefId]="currentRefId"
              [referenceList]="globalReferences"
              [resultsLength]="globalReferencesResultLength"
              [filter]="filterString"
              [enableInsertButtons]="true"
              (onInsertReference)="submitReferenceFromAll($event)"
              (onSearch)="updateReferenceLists($event)"
              (selection)="selectReferenceFromAll($event)"
            >
            </cars-reference-list>
          </div>
        </div>
        <div class="cover" *ngIf="submittingReference"></div>
        <mat-progress-spinner
          *ngIf="submittingReference"
          mode="indeterminate"
          aria-label="Saving reference"
        ></mat-progress-spinner>
        <div class="references-buttons" style="text-align: right">
          <button
            mat-raised-button
            color="secondary"
            (click)="onOpenLibrary(false)"
            class="navigation-buttons"
            matTooltip="Back to document"
            [matTooltipShowDelay]="tooltipDelay"
            aria-label="Return to document"
          >
            <mat-icon>chevron_left</mat-icon>
            Back to document references
          </button>
          <button
            mat-raised-button
            color="secondary"
            (click)="addingReference = true"
            class="navigation-buttons"
            matTooltip="Add new library entry"
            [matTooltipShowDelay]="tooltipDelay"
            aria-label="Add new library entry"
            [disabled]="CarsAction.AUTHOR_DOCUMENT | carsDisabled: documentId | async"
          >
            <mat-icon>add</mat-icon>
            New library entry
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div [ngClass]="{invisible: !addingReference}" class="references-panel">
    <div class="references-title">
      <span class="left">Add new reference</span>
      <button mat-button (click)="addingReference = false" class="right" aria-label="Cancel adding new reference">
        <mat-icon>close</mat-icon>
        Close
      </button>
    </div>
    <div class="references-main">
      <form (ngSubmit)="createReference()" #newReferenceForm="ngForm">
        <div>
          <mat-form-field class="form-field">
            <input
              matTooltip="Title of the publication. Do not include the reference."
              [matTooltipShowDelay]="tooltipDelay"
              autocomplete="off"
              matInput
              placeholder="Title"
              [(ngModel)]="newReference.title"
              name="title"
              required
              carsRequiredNonWhitespace
              aria-label="Title of the publication. Do not include the reference."
            />
            <mat-error>{{ titleErrorMessage }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matTooltip="Named authors of the publication. Not required for standards."
              [matTooltipShowDelay]="tooltipDelay"
              autocomplete="off"
              matInput
              placeholder="Author(s)"
              [(ngModel)]="newReference.author"
              name="author"
              aria-label="Named authors of the publication. Not required for standards."
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matTooltip="Corporate publisher. For adopted standards (e.g. BS EN) use BSI."
              [matTooltipShowDelay]="tooltipDelay"
              autocomplete="off"
              matInput
              placeholder="Publisher"
              [(ngModel)]="newReference.publisher"
              name="publisher"
              aria-label="Corporate publisher. For adopted standards (e.g. BS EN) use BSI."
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matTooltip="Identifier e.g. BS 9001, GD 01. Do not include the year of publication."
              [matTooltipShowDelay]="tooltipDelay"
              autocomplete="off"
              matInput
              placeholder="Identifier"
              [(ngModel)]="newReference.reference"
              maxLength="32"
              name="reference"
              aria-label="Identifier e.g. BS 9001, GD 01. Do not include the year of publication."
            />
            <mat-hint align="start">E.g. BS 9001, GD 01. Do not include the year of publication.</mat-hint>
            <mat-hint align="end">{{ newReference?.reference?.length || 0 }} / 32</mat-hint>
          </mat-form-field>
        </div>
        <div class="new-reference-buttons">
          <button
            class="new-reference-button"
            mat-stroked-button
            color="primary"
            type="submit"
            [disabled]="!allowReferenceSubmission()"
            (click)="setCreateType('normative')"
            aria-label="Add as normative reference"
          >
            Add as Normative
          </button>
          <button
            class="new-reference-button"
            mat-stroked-button
            color="primary"
            type="submit"
            [disabled]="!allowReferenceSubmission()"
            (click)="setCreateType('informative')"
            aria-label="Add as informative reference"
          >
            Add as Informative
          </button>
        </div>
        <div class="cover" *ngIf="addNewReferenceloadingSpinner"></div>
        <mat-progress-spinner
          *ngIf="addNewReferenceloadingSpinner"
          mode="indeterminate"
          aria-label="Saving reference"
        ></mat-progress-spinner>
        <div *ngIf="!this.inputCaret && this.newReferenceForm.form.valid" class="error">
          Please place your cursor on the document where you'd like the reference to appear.
        </div>
        <div *ngIf="referenceExists" class="error">
          A reference with this title and identifier already exists. Please check the Reference Library to find the
          existing reference.
        </div>
      </form>
    </div>
  </div>
</div>
