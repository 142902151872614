import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ChangelogService} from 'app/changelog/changelog.service';
import {SectionFragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {AbstractSectionResolver} from 'app/routing/resolvers/abstract-section-resolver';
import {DocumentService} from 'app/services/document.service';
import {SectionService} from 'app/services/section.service';
import {ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class ChangelogResolverService extends AbstractSectionResolver {
  protected breadcrumb: string = 'changelog';
  protected routeName: string = 'Changelog';
  protected viewMode: ViewMode = ViewMode.CHANGELOG_MARKUP;
  protected location: CurrentLocation = CurrentLocation.CHANGELOG;
  protected onLoad: (section: SectionFragment) => void = (section: SectionFragment) => {
    this.changelogService.findRangeBySectionId(section.id);
    this.changelogService.findLinksBySectionId(section.id, section.id);
  };

  constructor(
    protected documentService: DocumentService,
    protected sectionService: SectionService,
    protected versioningService: VersioningService,
    protected viewService: ViewService,
    protected snackBar: MatSnackBar,
    protected router: Router,
    protected changelogService: ChangelogService
  ) {
    super(documentService, sectionService, versioningService, viewService, snackBar, router);
  }

  /*
   * Overridden to allow changing sections and staying in markup mode.
   */
  protected updateCurrentView(previousView: ViewMode): ViewMode {
    if (previousView === ViewMode.CHANGELOG) {
      return ViewMode.CHANGELOG;
    } else {
      return ViewMode.CHANGELOG_MARKUP;
    }
  }
}
