import {Injectable, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResizeService implements OnDestroy {
  private _resizeSubject: Subject<void>;

  constructor() {
    this.onWindowResized = this.onWindowResized.bind(this);

    if (this._resizeSubject) {
      this._resizeSubject.complete();
    }

    this._resizeSubject = new Subject<void>();
    window.addEventListener('resize', this.onWindowResized, true);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('resize', this.onWindowResized, true);

    if (this._resizeSubject) {
      this._resizeSubject.complete();
      this._resizeSubject = null;
    }
  }

  private onWindowResized(): void {
    this._resizeSubject.next();
  }

  public fireResize(): void {
    const resizeEvent = document.createEvent('HTMLEvents');
    resizeEvent.initEvent('resize', true, true);
    window.dispatchEvent(resizeEvent);
  }

  public subscribe(next?: (value: any) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this._resizeSubject.subscribe(next, error, complete);
  }
}
