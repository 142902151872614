<table [class.inline]="!index" border-spacing="0">
  <caption [class.inline]="!index" *ngIf="index">
    <div class="caption-container">
      <label>{{ index }}</label>
    </div>
  </caption>
  <tr>
    <td><div #equationCell></div></td>
  </tr>
</table>
