import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StandardFormatTypeToDisplayNamePipe} from './standard-format-type-to-display-name.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [StandardFormatTypeToDisplayNamePipe],
  exports: [StandardFormatTypeToDisplayNamePipe],
})
export class StandardFormatTypeToDisplayNameModule {}
