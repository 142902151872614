import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from 'environments/environment';
import * as FileSaver from 'file-saver';
import {catchError, map} from 'rxjs/operators';
import {Logger} from '../error-handling/services/logger/logger.service';
import {BaseService} from './base.service';

export enum StatsReport {
  DOCUMENT_STATISTICS = 'DOCUMENT_STATISTICS',
  DOUCMENT_USER_ROLES = 'DOUCMENT_USER_ROLES',
  USER_STATISTICS = 'USER_STATISTICS',
  DOCUMENT_CHANGES_STATISTICS = 'DOCUMENT_CHANGES_STATISTICS',
  BENEFIT_REALISATION_STATISTICS = 'BENEFIT_REALISATION_STATISTICS',
}

export interface StatsField {
  key: StatsReport;
  description: string;
  displayTitle: string;
  loading: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BaseService {
  private static readonly DOWNLOAD_URL: string = `${environment.apiHost}/statistics/download`;

  private static STATISTICS_URLS: Record<StatsReport, string> = {
    [StatsReport.DOCUMENT_STATISTICS]: `${StatisticsService.DOWNLOAD_URL}/documentStatistics`,
    [StatsReport.DOUCMENT_USER_ROLES]: `${StatisticsService.DOWNLOAD_URL}/documentUserRoles`,
    [StatsReport.USER_STATISTICS]: `${StatisticsService.DOWNLOAD_URL}/userStatistics`,
    [StatsReport.DOCUMENT_CHANGES_STATISTICS]: `${StatisticsService.DOWNLOAD_URL}/documentChangesStatistics`,
    [StatsReport.BENEFIT_REALISATION_STATISTICS]: `${StatisticsService.DOWNLOAD_URL}/documentContentStatistics`,
  };

  constructor(private _httpClient: HttpClient, protected _snackbar: MatSnackBar) {
    super(_snackbar);
  }

  public getAvailableDatesForBenefitsRealisation(): Promise<number[]> {
    return this._httpClient
      .get<number[]>(`${environment.apiHost}/statistics/documentContentStatistics/availableDates`)
      .toPromise()
      .then((array) => array)
      .catch((err) => {
        this._handleError(err, 'Failed to fetch available benefits dates');
        return Promise.reject(err);
      });
  }

  public downloadStatistics(statistic: StatsReport, validFrom: number): Promise<void> {
    return this._httpClient
      .get(StatisticsService.STATISTICS_URLS[statistic], {
        responseType: 'blob',
        params: {validFrom: validFrom.toString()},
      })
      .toPromise()
      .then((response) => {
        const blob = new Blob([response], {type: 'text/csv'});
        FileSaver.saveAs(blob, `${statistic.toLowerCase()}.csv`);
      })
      .catch((error: any) => {
        Logger.error('untyped-error', 'failed to fetch ' + statistic, error);
        this._handleError(error, 'Failed to fetch ' + statistic);
      });
  }

  public downloadBenefitStatistics(validTo: number): Promise<void> {
    return this._httpClient
      .get(StatisticsService.STATISTICS_URLS[StatsReport.BENEFIT_REALISATION_STATISTICS], {
        responseType: 'blob',
        observe: 'response',
        params: {validTo: validTo.toString()},
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentDisposition: string = !!response.headers ? response.headers.get('Content-Disposition') : null;
          const fileName: string = !!contentDisposition
            ? contentDisposition.split('filename=')[1]
            : 'benefits_realisation_statistics.zip';
          FileSaver.saveAs(response.body, fileName);
        }),
        catchError((error: any) => {
          Logger.error('untyped-error', 'failed to fetch benefit realisation data', error);
          this._handleError(error, 'Failed to fetch benefit realisation data');
          return Promise.reject(false);
        })
      )
      .toPromise();
  }
}
