import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EquationComponent} from './equation.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EquationComponent],
  exports: [EquationComponent],
})
export class EquationModule {}
