import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {ClauseFragment, ClauseType, Fragment, FragmentType, SectionFragment, SectionType} from 'app/fragment/types';
import {Breadcrumb} from 'app/interfaces';
import {FragmentService} from 'app/services/fragment.service';
import {ImageService} from 'app/services/image.service';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';
import {PrintService} from '../../print/print.service';
import {CurrentView} from '../../view/current-view';

@Component({
  selector: 'cars-section-background',
  templateUrl: './section-background.component.html',
  styleUrls: ['./section-background.component.scss'],
})
export class SectionBackgroundComponent implements OnInit, OnDestroy {
  private section: SectionFragment;
  public clauses: ClauseFragment[] = [];
  public currentView: CurrentView;
  public loading: boolean = false;
  public saving: boolean = false;
  public closeRoute: string;

  public readonly ClauseType: any = ClauseType;

  private _breadcrumbs: Breadcrumb[] = [];
  private _subscriptions: Subscription[] = [];

  /**
   * Looks at the breadcrumb service and retrieves the breadcrumb prior to
   * the current breadcrumb which can be used to close the view.
   */
  get closeBreadcrumb(): Breadcrumb {
    if (!this._breadcrumbs || !this._breadcrumbs.length) {
      return null;
    }

    return this._breadcrumbs[0];
  }

  constructor(
    private _viewService: ViewService,
    private _printService: PrintService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _fragmentIndexService: FragmentIndexService,
    private _fragmentService: FragmentService,
    private _imageService: ImageService
  ) {}

  /**
   * Initialises the component fetching the section and breadcrumbs.
   */
  public ngOnInit(): void {
    this._viewService.onCurrentViewChange((currentView: CurrentView) => {
      this.currentView = currentView;
    });

    this._subscriptions.push(
      this._route.data.subscribe((data: Data) => {
        this.section = data[0];
        this._breadcrumbs = data.breadcrumbs;

        this.clauses = this.section.getClauses();
        this.clauses.forEach((clause: ClauseFragment) => {
          // Iterate over each clauses' children to check for landscape fragments.
          clause.children.forEach((f: Fragment) => {
            if (f.isLandscape()) {
              f['landscape'] = false;
            }
          });
        });
      }),

      this._fragmentService.onCreate(
        (f: Fragment) => {
          this.clauses = this.section.getClauses();
        },
        (f: Fragment) => this.fragmentPredicate(f)
      ),

      this._fragmentService.onUpdate(
        (f: Fragment) => {
          this.clauses = this.section.getClauses();
        },
        (f: Fragment) => this.fragmentPredicate(f)
      ),

      this._fragmentService.onDelete(
        (f: Fragment) => {
          this.clauses = this.section.getClauses();
        },
        (f: Fragment) => this.fragmentPredicate(f)
      )
    );

    this.closeRoute = '/' + this.closeBreadcrumb.link;

    setTimeout(() => {
      this._imageService.allImagesLoaded().then(() => this._printService.triggerPrintEvent());
    });
  }

  private fragmentPredicate(f: Fragment): boolean {
    return f.sectionId.equals(this.section.id) && f.is(FragmentType.CLAUSE);
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public setLoading(): void {
    this.loading = true;
  }

  /**
   * Calculates the display indexes for the different clause types.
   *
   * @param clause {ClauseFragment} The clause fragment who's index to calculate
   * @returns      {string}         The calculated clause display index
   */
  public clauseDisplayIndex(clause: ClauseFragment): string {
    switch (clause.clauseType) {
      case ClauseType.HEADING_1: {
        return this.section.sectionType === SectionType.APPENDIX
          ? this._fragmentIndexService.getIndex(clause.id)
          : 'Heading 1';
      }

      case ClauseType.HEADING_2: {
        return 'Heading 2';
      }

      case ClauseType.HEADING_3: {
        return 'Heading 3';
      }

      default:
        return this._fragmentIndexService.getIndex(clause.id);
    }
  }

  /**
   * @returns {boolean} true if this is a live view of the background and commentary
   */
  public isLiveView(): boolean {
    return !!this.currentView && this.currentView.versionTag === null;
  }

  /**
   * Getter for the chip status text.
   *
   * @returns {string}   The status
   */
  public get status(): string {
    return this.saving ? 'Saving...' : 'Saved';
  }

  public print(): void {
    this._router.navigateByUrl(this._router.url.split('/').slice(0, -1).join('/') + '/print' + '/background');
  }
}
