import {Dictionary} from 'app/utils/typedefs';
import {UUID} from '../../utils/uuid';
import {FragmentDiffSet} from './diff-set';
import {DiffOperation, FragmentDiff} from './fragment-diff';

/**
 * A class used to sort requests to the server with knowledge of parent
 * child diff sets.
 */
class DiffDependencyNode {
  public parent: DiffDependencyNode = null;
  public children: DiffDependencyNode[] = [];
  public diff: FragmentDiff;

  constructor(diff: FragmentDiff) {
    this.diff = diff;
  }

  getDiffs(): FragmentDiff[] {
    return [
      this.diff,
      ...this.children.reduce((children, child) => {
        children.push(...child.getDiffs());
        return children;
      }, []),
    ];
  }
}

/**
 * A class responsible for managing diffsets for many different fragments.
 */
export class FragmentDiffManager {
  private _sets: Dictionary<FragmentDiffSet> = {};

  /**
   * Atomic function to remove operations where a node and its parent may
   * have been created and the parent subsequently deleted without being
   * reassigned a new parent.
   */
  private static removeOrphanedOperations(
    diffs: FragmentDiff[],
    fosteringParents: Dictionary<boolean>
  ): FragmentDiff[] {
    const diffList: FragmentDiff[] = [...diffs];
    const fostering: Dictionary<boolean> = Object.assign({}, fosteringParents);

    let updated: boolean = false;
    do {
      updated = false;
      for (let i = diffList.length - 1; i >= 0; i--) {
        const diff = diffList[i];
        if (diff.fields && fostering[diff.fields.parentId]) {
          diffList.splice(i, 1);
          fostering[diff.id.value] = true;
          updated = true;
        }
      }
    } while (updated);

    return diffList;
  }

  /**
   * Atomic function used to sort FragmentDiffs whilst considering the heirachy
   * of the parent child relationships.
   */
  private static sortDiffOperations(diffs: FragmentDiff[]): FragmentDiff[] {
    const dependencyNodes: Dictionary<DiffDependencyNode> = diffs.reduce((nodes, diff) => {
      nodes[diff.id.value] = new DiffDependencyNode(diff);
      return nodes;
    }, {});

    for (const id of Object.keys(dependencyNodes)) {
      const node = dependencyNodes[id];

      if (node.diff.fields && node.diff.fields.parentId) {
        const parent = dependencyNodes[node.diff.fields.parentId];
        if (parent) {
          parent.children.push(node);
          node.parent = parent;
        }
      }
    }

    const updateTypes: Array<any> = Object.keys(dependencyNodes)
      .map((id) => dependencyNodes[id])
      .filter((node) => node.parent === null)
      .reduce((operations, diff) => {
        operations.push(...diff.getDiffs());
        return operations;
      }, [])
      .reduce((opTypes, diff) => {
        if (!opTypes.hasOwnProperty(diff.operation)) {
          opTypes[diff.operation] = [diff];
        } else {
          opTypes[diff.operation].push(diff);
        }
        return opTypes;
      }, {});

    return Object.keys(updateTypes)
      .sort()
      .reduce((operations, opType) => {
        operations.push(...updateTypes[opType]);
        return operations;
      }, []);
  }

  constructor() {}

  /**
   * Return the number of diffs of the given operations in all diffsets.
   *
   * @param id  {UUID?}             An optional ID to query for
   * @param ops {DiffOperation[]}   Optional operation types
   * @returns   {number}            The total diffs
   */
  public length(id?: UUID, ...ops: DiffOperation[]): number {
    if (id) {
      return this._sets[id.value] ? this._sets[id.value].length(...ops) : 0;
    } else {
      return Object.keys(this._sets).reduce(
        (count: number, key: string) => (count += this._sets[key].length(...ops)),
        0
      );
    }
  }

  /**
   * Push an array of diffs into their corresponding diffsets.
   *
   * @param diffs {FragmentDiff[]}   The diffs to push
   */
  public push(...diffs: FragmentDiff[]): void {
    for (const diff of diffs) {
      const id: string = diff.id.value;
      if (!this._sets.hasOwnProperty(id)) {
        this._sets[id] = new FragmentDiffSet(diff.id);
      }

      this._sets[id].push(diff);
    }
  }

  public getPendingUpdate(id: string): FragmentDiff {
    return this._sets[id] ? this._sets[id].getPendingUpdate() : null;
  }

  /**
   * Collate and extract diffs from all diffsets managed by this collator.  The return
   * array is ordered so that creates are first, updates are second, and deletes are
   * last.  This sorting is stable, so that the relative order of diffs with the same
   * type are not changed from the order they were added.  All diffsets are empty
   * following this operation.
   *
   * @returns {FragmentDiff[]}   The ordered diffs
   */
  public extract(): FragmentDiff[] {
    let diffs: FragmentDiff[] = [];
    const versionRequests: FragmentDiff[] = [];
    const fosteringParents: Dictionary<boolean> = {};

    Object.keys(this._sets).forEach((id: string) => {
      const diffsPerFragment: FragmentDiff[] = this._sets[id].extract();
      diffsPerFragment.forEach((diff: FragmentDiff) => {
        if (diff) {
          if (diff.operation === DiffOperation.VERSION) {
            versionRequests.push(diff);
          } else {
            diffs.push(diff);
          }
        } else {
          fosteringParents[id] = true;
        }
      });
      delete this._sets[id];
    });

    diffs = FragmentDiffManager.removeOrphanedOperations(diffs, fosteringParents);
    diffs = FragmentDiffManager.sortDiffOperations(diffs);

    diffs.unshift(...versionRequests);
    return diffs;
  }
}
