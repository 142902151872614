/**
 * An enum of all possible types for a StandardFormatRequirement group. Note that values marked as Deprecated should not
 * appear within the suite-configuration/standard-format-group-creation-config.json file, as these have been deleted/
 * updated. Note these values cannot be removed from the enum otherwise we would not be able to view versions of
 * documents that contain any of these types.
 */
export enum StandardFormatType {
  // Deprecated - updated to SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS_V2:
  SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS = 'SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS',
  SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS_V2 = 'SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS_V2',
  VERIFICATION_WITHIN_SHW = 'VERIFICATION_WITHIN_SHW',
  VERIFICATION_WITHIN_WSR = 'VERIFICATION_WITHIN_WSR',
  VERIFICATION_WITHIN_SHW_NO_REPLACEMENT = 'VERIFICATION_WITHIN_SHW_NO_REPLACEMENT', // Deprecated
  VERIFICATION_WITHIN_WSR_NO_REPLACEMENT = 'VERIFICATION_WITHIN_WSR_NO_REPLACEMENT', // Deprecated
  VERIFICATION_REQUIRES_ACCREDITED_TESTING_LABORATORY = 'VERIFICATION_REQUIRES_ACCREDITED_TESTING_LABORATORY',
  // Deprecated - updated to DOCUMENTATION_GENERAL_REQUIREMENTS, DOCUMENTATION_SPECIFIC_TIMESCALES:
  DOCUMENTATION = 'DOCUMENTATION',
  DOCUMENTATION_GENERAL_REQUIREMENTS = 'DOCUMENTATION_GENERAL_REQUIREMENTS',
  DOCUMENTATION_SPECIFIC_TIMESCALES = 'DOCUMENTATION_SPECIFIC_TIMESCALES',
  DOCUMENTATION_TECHNICAL_APPROVAL = 'DOCUMENTATION_TECHNICAL_APPROVAL', // Deprecated
  // Deprecated - updated to DOCUMENTATION_CONTINUOUS_RECORDS_V2:
  DOCUMENTATION_CONTINUOUS_RECORDS = 'DOCUMENTATION_CONTINUOUS_RECORDS',
  DOCUMENTATION_CONTINUOUS_RECORDS_V2 = 'DOCUMENTATION_CONTINUOUS_RECORDS_V2',
  DOCUMENTATION_WSR = 'DOCUMENTATION_WSR', // Deprecated - updated to DOCUMENTATION_V2
  DOCUMENTATION_V2 = 'DOCUMENTATION_V2',
  DOCUMENTATION_WSR_V2 = 'DOCUMENTATION_WSR_V2',
  CONSTRUCTOR_DESIGN = 'CONSTRUCTOR_DESIGN',
  CONSTRUCTOR_DESIGN_INCLUDED_WORKS_SPECIFIC_REQUIREMENTS = 'CONSTRUCTOR_DESIGN_INCLUDED_WORKS_SPECIFIC_REQUIREMENTS',
  CONSTRUCTOR_DESIGN_REQUIRING_TECHNICAL_APPROVAL = 'CONSTRUCTOR_DESIGN_REQUIRING_TECHNICAL_APPROVAL',
  HARMONISED_STANDARD_LIST = 'HARMONISED_STANDARD_LIST',
  HARMONISED_STANDARD_TABLE = 'HARMONISED_STANDARD_TABLE',
  // Deprecated:
  HARMONISED_STANDARD_LIST_INCLUDED_WORKS_SPECIFIC_REQUIREMENTS = 'HARMONISED_STANDARD_LIST_INCLUDED_WORKS_SPECIFIC_REQUIREMENTS',
  HARMONISED_STANDARD_TABLE_INCLUDED_WORKS_SPECIFIC_REQUIREMENTS = 'HARMONISED_STANDARD_TABLE_INCLUDED_WORKS_SPECIFIC_REQUIREMENTS',
  PRODUCT_CERTIFICATION_SCHEMES_LIST = 'PRODUCT_CERTIFICATION_SCHEMES_LIST',
  PRODUCT_CERTIFICATION_SCHEMES_TABLE = 'PRODUCT_CERTIFICATION_SCHEMES_TABLE',
  PRODUCT_ACCEPTANCE_SCHEMES = 'PRODUCT_ACCEPTANCE_SCHEMES',
  PRODUCTS_COVERED_BY_STANDARDS_SHW_LIST = 'PRODUCTS_COVERED_BY_STANDARDS_SHW_LIST',
  PRODUCTS_COVERED_BY_STANDARDS_SHW_TABLE = 'PRODUCTS_COVERED_BY_STANDARDS_SHW_TABLE',
  PRODUCTS_COVERED_BY_STANDARDS_LIST_AND_WSR = 'PRODUCTS_COVERED_BY_STANDARDS_LIST_AND_WSR', // Deprecated
  PRODUCTS_COVERED_BY_STANDARDS_TABLE_AND_WSR = 'PRODUCTS_COVERED_BY_STANDARDS_TABLE_AND_WSR', // Deprecated
  PRODUCTS_COVERED_BY_STANDARDS_WSR = 'PRODUCTS_COVERED_BY_STANDARDS_WSR', // Deprecated - updated to PRODUCTS_COVERED_BY_STANDARDS
  PRODUCTS_COVERED_BY_STANDARDS = 'PRODUCTS_COVERED_BY_STANDARDS',
  NATIONAL_HIGHWAY_SECTOR_SCHEMES = 'NATIONAL_HIGHWAY_SECTOR_SCHEMES',
  WEATHER_RESTRICTIONS_LIST = 'WEATHER_RESTRICTIONS_LIST', // Deprecated
  WEATHER_RESTRICTIONS_TABLE = 'WEATHER_RESTRICTIONS_TABLE', // Deprecated
  GENERAL_REQUIREMENT_IN_SHW = 'GENERAL_REQUIREMENT_IN_SHW',
  GENERAL_REQUIREMENT_OPTION_TO_CHANGE_REQUIREMENT_IN_WSR = 'GENERAL_REQUIREMENT_OPTION_TO_CHANGE_REQUIREMENT_IN_WSR',
  GENERAL_REQUIREMENT_IN_WSR = 'GENERAL_REQUIREMENT_IN_WSR',
  PERFORMANCE_CHARACTERISTICS_IN_WSR = 'PERFORMANCE_CHARACTERISTICS_IN_WSR',
  SECTION_REFERENCE_SINGLE = 'SECTION_REFERENCE_SINGLE',
  SECTION_REFERENCE_MULTIPLE = 'SECTION_REFERENCE_MULTIPLE',
}

/**
 * A set of all WSR schedule SFR types. This is used for restricting the placement of field definition SIs.
 */
export const WSRScheduleStandardFormatTypes: Set<StandardFormatType> = new Set([
  StandardFormatType.SCHEDULE_WORKS_SPECIFIC_REQUIREMENTS_V2,
]);
