import {Administration} from 'app/documents/administrations';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {DocumentReferenceUtils} from 'app/services/references/reference-utils/document-reference-utils';
import {SearchableGlobalReference} from 'app/sidebar/references/searchable-global-reference';
import {UUID} from 'app/utils/uuid';
import {DocumentData, SFPWorkflowStatus, SRPWorkflowStatus, WorkflowStatus} from '../documents/document-data';
import {Serialisable} from '../utils/serialisable';
import {Predicate} from '../utils/typedefs';
import {ClauseComponent} from './clause/clause.component';
import {UploadProperties} from './figure/upload-properties';
import {ClauseLinkRequired} from './fragment-link/clause-link-required';
import {FragmentState} from './fragment-state';
import {Suite} from './suite';
import {Tree} from './tree/tree';
import {TreeArray} from './tree/tree-array';
import {ClauseGroupFragment} from './types/clause-group-fragment';
import {AbstractInputFragment} from './types/input/abstract-input-fragment';
import {SpecifierInstructionType} from './types/specifier-instruction-type';
import {StandardFormatType} from './types/standard-format-type';

/**
 * An enumeration of all available fragment types.  This should be mirrored by
 * the webservice enum.
 */
export enum FragmentType {
  TEXT,
  SUPERSCRIPT,
  SUBSCRIPT,
  MEMO,
  LIST,
  LIST_ITEM,
  EQUATION,
  FIGURE,
  TABLE_CELL,
  TABLE_ROW,
  TABLE,
  CLAUSE,
  SECTION,
  DOCUMENT,
  ROOT,
  DOCUMENT_REFERENCE,
  INLINE_REFERENCE,
  ANCHOR,
  DUMMY,
  DOCUMENT_INFORMATION,
  ASSOCIATED_DOCUMENT_INFORMATION,
  CLAUSE_GROUP,
  READONLY,
  INPUT,
  SECTION_GROUP,
  REFERENCE_INPUT,
  UNIT_INPUT,
  INTERNAL_INLINE_REFERENCE,
  INTERNAL_DOCUMENT_REFERENCE,
}

/**
 * This defines any free text fragment types. This is used to check if fragments are mergable and is also
 * used to check if fragments are nicely splitable.
 */
export const EDITABLE_TEXT_FRAGMENT_TYPES: ReadonlyArray<FragmentType> = [
  FragmentType.TEXT,
  FragmentType.SUPERSCRIPT,
  FragmentType.SUBSCRIPT,
  FragmentType.MEMO,
];

export interface WeightInferenceAttempt {
  weight: number;
  failure?: WeightInferenceFailure;
}

export enum WeightInferenceFailure {
  WEIGHTS_TOO_CLOSE,
  NO_PARENT,
}

/**
 * An abstract base class from which all fragments should be derived.
 *
 * @field type           {FragmentType}        The type of this fragment
 * @field id             {UUID}                The fragment ID
 * @field lastModifiedBy {UUID}                The ID of the user which created this versioned fragment
 * @field lastModifiedAt {number}              The ms-epoch time at which this fragment was last modified
 * @field validFrom      {number}              The ms-epoch time from which this fragment is valid
 * @field validTo        {number}              The ms-epoch time until which this fragment is valid
 * @field weight         {number}              The sort criteria for sibling fragments
 * @field component      {FragmentComponent}   A reference to the Angular component, if it exists
 */
export abstract class Fragment extends Tree<Fragment> implements Serialisable {
  // A UTF-16 zero-width space used as a filler character.  Also create a copy of its RegExp to
  // avoid constantly new'ing RegExps in _value's setter.
  private static readonly _FILLER: string = '\u200B';

  // A UTF-16 line-feed character. Used in table cells.
  public static readonly _LINE_BREAK: string = '\u000A';

  // RegExp to match filler character or a line break character that has nothing immediately after it.
  private static readonly _FILLER_REGEXP: RegExp = new RegExp(/\u200B|\u000A$/, 'g');

  private static readonly _WEIGHT_EPSILON: number = Number.EPSILON * 2;
  // Max gap to create between frag weights - not max allowed weight:
  public static readonly _WEIGHT_MAX: number = 1000000;
  // Nothing should ever have its weight set to this, as it prevents
  // other fragments being placed before it:
  public static readonly _WEIGHT_MIN: number = 0;

  public readonly type: FragmentType;
  public readonly id: UUID;
  public parentId: UUID = null;
  public component: FragmentComponent = null;
  public lastModifiedBy: UUID = null;
  public lastModifiedAt: number = null;
  public validFrom: number = null;
  public validTo: number = null;
  public weight: number = void 0;
  public state: FragmentState = null;

  public sectionId: UUID = null;
  public documentId: UUID = null;
  protected _value: string;

  /**
   * Helper function to split a fragment by splitting its value at a given offset.
   *
   * @param fragment {Fragment}     The fragment to split
   * @param offset   {number}       The offset relative to fragment
   * @returns        {Fragment[]}   The string value
   */
  public static splitValue(fragment: any, offset: number): string {
    const end: string = fragment._value.substring(offset);
    fragment._value = fragment._value.substring(0, offset);

    return end;
  }

  /**
   * Helper function to split a fragment by splitting its children at a given offset.
   *
   * @param fragment      {Fragment}   The fragment to split
   * @param offset        {number}     The offset relative to fragment
   * @param skipCaptioned {boolean}    If true, does not move captioned fragments and does not null their weights.
   * @returns             {Fragment[]} The removed children
   */
  public static splitChildren(fragment: Fragment, offset: number, skipCaptioned: boolean = false): Fragment[] {
    let index: number = 0;
    while (offset > fragment.children[index].length()) {
      offset -= fragment.children[index++].length();
    }

    // If the caret is in these types, move to the next fragment:
    while (
      (fragment.children[index].is(FragmentType.EQUATION) && fragment.children[index]['inline']) ||
      fragment.children[index].is(FragmentType.INLINE_REFERENCE)
    ) {
      index++;
      offset = 0;
    }

    let end;
    if (skipCaptioned) {
      end = [];
      let i = index + 1;
      while (i < fragment.children.length) {
        const child: Fragment = fragment.children[i];
        if (!child.isCaptioned()) {
          child.remove();
          end.push(child);
        } else {
          i++;
        }
      }
    } else {
      end = fragment.children.splice(index + 1);
    }

    if (fragment.children[index] && offset < fragment.children[index].length()) {
      const splitFragment: Fragment = fragment.children[index].split(offset);
      if (splitFragment) {
        end.unshift(splitFragment);
      }
    }

    return end;
  }

  public static inferWeights(fragments: Fragment[]): void {
    const previous: Fragment = fragments[0].previousSibling();
    const next: Fragment = fragments[fragments.length - 1].nextSibling();

    let divisions: number = fragments.length;
    let start: number;
    let end: number;

    if (previous) {
      start = previous.weight;
      divisions += 1;
    } else {
      if (next) {
        start = next.weight / (divisions + 1);
      } else {
        start = Fragment._WEIGHT_MAX;
      }
    }

    if (next) {
      end = next.weight;
    } else {
      divisions -= 1;
      end = start + divisions * Fragment._WEIGHT_MAX;
    }

    const delta: number = (end - start) / divisions;
    let nextWeight = previous ? start + delta : start;

    fragments.forEach((fragment) => {
      if (nextWeight - (nextWeight - delta) < Fragment._WEIGHT_EPSILON) {
        Logger.error(
          'fragment-error',
          `Weights are too close, prevWeight=${nextWeight - delta},
            nextWeight=${nextWeight}, fragment=${JSON.stringify(fragment.serialise())}`
        );
        throw new Error('Failed to perform operation, please contact support to resolve the problem');
      }
      fragment.weight = nextWeight;
      nextWeight = nextWeight + delta;
    });
  }

  constructor(id: UUID, type: FragmentType, children: Fragment[], value: string = '') {
    super([]);

    this.type = type;
    this.id = id || UUID.random();
    this.value = value;
    this.children.push(...(children ? children : []));
    this.children.forEach((c: Fragment) => c.inferWeight());
  }

  /**
   * Getter for parent reference.
   *
   * @override
   */
  public get parent(): Fragment {
    return super.parent;
  }

  /**
   * Setter for parent reference which also keeps parentId up-to-date.
   *
   * @override
   */
  public set parent(fragment: Fragment) {
    super.parent = fragment;
    if (fragment) {
      this.parentId = fragment.id;
    }
  }

  /**
   * Split this fragment at a given offset. If this item cannot be split it will return 'null'.
   *
   * @param offset {number}     The offset relative to the start of this fragment
   * @returns      {Fragment}   The content past offset
   */
  public abstract split(offset: number): Fragment;

  /**
   * @override
   */
  public equals(other: Fragment): boolean {
    return super.equals(other) || (!!other && this.id.equals(other.id));
  }

  /**
   * @override
   */
  public remove(): void {
    super.remove();
    this.weight = void 0;
  }

  /**
   * Returns true if this fragment is valid at the given time.
   *
   * @param time {number}    The query time, default now
   * @returns    {boolean}   True if valid at time
   */
  public isValid(time: number = Date.now()): boolean {
    const vf: boolean = this.validFrom !== null && this.validFrom <= time;
    const vt: boolean = this.validTo === null || this.validTo > Math.max(time, this.validFrom);

    return vf && vt;
  }

  /**
   * Returns true if this Fragment has an Angular component contained within the DOM.
   *
   * @returns {boolean}   True if attached
   */
  public isAttached(): boolean {
    return !!this.component && this.component.content.equals(this);
  }

  /**
   * Marks this fragments Angular component for CD.
   */
  public markForCheck(): void {
    if (this.isAttached()) {
      this.component.markForCheck();
    }
  }

  /**
   * Marks this fragment sibling components for CD; applying the predicate to each if supplied.
   *
   * @param predicate {Predicate<Fragment>}   Optional predicate to apply to siblings
   */
  public markSiblingsForCheck(predicate: Predicate<Fragment> = null): void {
    if (this.isAttached()) {
      this.component.markSiblingsForCheck(predicate);
    }
  }

  /**
   * Marks this fragment children components for CD; applying the predicate to each if supplied.
   *
   * @param predicate {Predicate<Fragment>}   Optional predicate to apply to children
   */
  public markChildrenForCheck(predicate: Predicate<Fragment> = null): void {
    if (this.isAttached()) {
      this.component.markChildrenForCheck(predicate);
    }
  }

  /**
   * Serialise a Fragment to its JSON representation. This does not include serialising all children.
   * Derived classes can override this method.
   *
   * @returns {any}   The serialised JSON
   */
  public serialise(): any {
    const json: any = Object.assign({}, this);
    json.type = typeof json.type === 'number' ? FragmentType[json.type] : json.type;

    // The backend doesn't care about our underscore hackery
    json.value = json._value;
    delete json._value;

    // Remove the parent and component to avoid stringifying a cyclic JSON object
    delete json.lastModifiedBy;
    delete json.lastModifiedAt;
    delete json.validFrom;
    delete json.validTo;
    delete json._parent;
    delete json.children;
    delete json.component;
    delete json.state;

    // Serialise all other properties with a serialise() method
    Object.keys(json).forEach((key: string) => {
      if (json[key] && typeof json[key].serialise === 'function') {
        json[key] = json[key].serialise();
      }
    });

    return json;
  }

  /**
   * Returns true if this fragment has one of the passed types.  Also returns true if
   * passed no arguments, since an empty intersection is tautologically true.
   *
   * @param types {FragmentType[]}   The types
   * @returns     {boolean}          True if one of these types
   */
  public is(...types: FragmentType[]): boolean {
    return types.length === 0 || types.indexOf(this.type) >= 0;
  }

  /**
   * Returns true if this fragment has a value of positive length, once zero-width spaces
   * have been removed.
   *
   * @returns {boolean}   True if positive length
   */
  public hasValue(): boolean {
    return this._value.length > 0;
  }

  /**
   * Returns the first ancestor of this fragment with one of a given FragmentType, or
   * null if none can be found.
   *
   * @param types {FragmentType[]}   The type(s) to match
   * @returns     {Fragment}         The found ancestor
   */
  public findAncestorWithType(...types: FragmentType[]): Fragment {
    return this.findAncestor((fragment: Fragment) => types.indexOf(fragment.type) >= 0);
  }

  /**
   * Returns a list of ancestors of this fragment with the given FragmentType, or an empty list
   * if none can be found.
   *
   * The order of the list is the order in which they are encountered as we walk up the tree.
   */
  public findAllAncestorsWithType(type: FragmentType): Fragment[] {
    const resultList: Fragment[] = [];
    let result: Fragment = this.findAncestorWithType(type);
    while (result) {
      resultList.push(result);
      result = result.parent?.findAncestorWithType(type);
    }
    return resultList;
  }

  /**
   * Calculate the character length of this fragment.  This should coincide with the
   * total rendered characters of the corresponding Angular component.
   *
   * @param predicate {Predicate<Fragment>} An optional predicate to filter this fragments children
   * @returns         {number}              The character length
   */
  public length(predicate?: Predicate<Fragment>): number {
    if (typeof predicate !== 'function') {
      predicate = (f: Fragment) => true;
    }

    let result: number = typeof this._value === 'string' ? this._value.length : 0;
    result += this.children
      .filter((child: Fragment) => predicate(child))
      .reduce((sum: number, child: Fragment) => (sum += child.length()), 0);

    return result;
  }

  /**
   * Getter for _value which substitutes an zero-width space if value has zero length.
   * This ensures that empty fragments can always be selected, without having to mess
   * around inserting/removing filler spaces.
   */
  public get value(): string {
    return !!this._value ? this._value : Fragment._FILLER;
  }

  /**
   * Setter for _value which prevents setting a zero-width space.
   */
  public set value(value: string) {
    this._value = !!value ? value.replace(Fragment._FILLER_REGEXP, '') : '';
  }

  /**
   * Translate an offset relative to a descendant of this fragment into an offset
   * relative to the start of this fragment.
   *
   * @param descendant {FragmentComponent}   The descendant
   * @param offset     {number}              The offset relative to descendant
   * @returns          {number}              The offset relative to this
   */
  public correctOffset(descendant: Fragment, offset: number): number {
    let before: number = 0;

    while (descendant && descendant.parent && !descendant.equals(this)) {
      before += descendant.parent.children
        .slice(0, descendant.index())
        .reduce((sum: number, child: Fragment) => (sum += child.length()), 0);
      descendant = descendant.parent;
    }

    return before + offset;
  }

  /**
   * Sort this fragment's children by their weight.
   */
  public sortChildren(): void {
    this.children.sort((a: Fragment, b: Fragment) => a.weight - b.weight);
  }

  /**
   * Perform a mock weight inference.  If it is possible to infer a new weight for a fragment,
   * return the weight.
   *
   * If it is not possible to infer the weight, return an error code. in the WeightInferenceAttempt.failure
   * field.
   *
   * This does not modify any fragments or change any weights.
   *
   * @returns {WeightInferenceAttempt} The outcome of the weight inference attempt.
   */
  public tryInferWeight(): WeightInferenceAttempt {
    if (this.parent) {
      let index: number = this.index();
      if (index < Fragment._WEIGHT_MIN) {
        index = Fragment._WEIGHT_MIN;
      }

      const prevs: Fragment[] = this.parent.children
        .slice(0, index)
        .filter((f: Fragment) => typeof f.weight === 'number');

      const nexts: Fragment[] = this.parent.children
        .slice(index + 1)
        .filter((f: Fragment) => typeof f.weight === 'number');

      const prev: number = (prevs[prevs.length - 1] || {weight: Fragment._WEIGHT_MIN}).weight;
      const next: number = (nexts[0] || {weight: prev + Fragment._WEIGHT_MAX}).weight;
      const newWeight: number = this._weightEquation(prev, next);

      if (next - newWeight < Fragment._WEIGHT_EPSILON || newWeight - prev < Fragment._WEIGHT_EPSILON) {
        return {
          weight: null,
          failure: WeightInferenceFailure.WEIGHTS_TOO_CLOSE,
        };
      }

      return {weight: newWeight, failure: null};
    } else {
      return {weight: null, failure: WeightInferenceFailure.NO_PARENT};
    }
  }

  /**
   * Infer the weight of this fragment from those if its siblings.
   *
   * @param clear {boolean}   True if the existing weight should be cleared
   * @returns     {number}    The previous weight
   */
  public inferWeight(clear: boolean = false): number {
    const result: number = this.weight;
    if (clear) {
      this.weight = void 0;
    }

    if (this.parent && typeof this.weight !== 'number') {
      const newWeight: WeightInferenceAttempt = this.tryInferWeight();

      if (newWeight.failure === WeightInferenceFailure.WEIGHTS_TOO_CLOSE) {
        throw new Error('Failed to perform operation, please contact support to resolve the problem');
      }

      this.weight = newWeight.weight;
    }

    return result;
  }

  /**
   * Finds the 'best' weight between two other weights.
   *
   * @param prev The previous weight.
   * @param next The next weight.
   *
   * @returns The calculated weight.
   */
  private _weightEquation(prev: number, next: number): number {
    let power: number = Fragment._WEIGHT_MAX;
    const delta: number = next - prev;
    while (delta < power) {
      power = power / 10;
    }
    return (prev + prev + power) / 2;
  }

  /**
   * @returns {boolean} if this type of fragment can be merged with siblings.
   */
  public isMergeable(): boolean {
    return this.is(...EDITABLE_TEXT_FRAGMENT_TYPES);
  }

  /**
   * @returns {boolean} if this type of fragment has a visible caption.
   */
  public isCaptioned(): boolean {
    return this.is(FragmentType.FIGURE, FragmentType.TABLE)
      ? true
      : this.is(FragmentType.EQUATION)
      ? !(this as any).inline
      : false;
  }

  /**
   * @returns {boolean} true if this fragment is landscape.
   */
  public isLandscape(): boolean {
    return this.is(FragmentType.FIGURE, FragmentType.TABLE) && (this as any).landscape === true;
  }

  public isTableAndHasManualColumnWidths(): boolean {
    return this.is(FragmentType.TABLE) && !!(this as any).hasManualColumnWidths;
  }

  public isInline(): boolean {
    return (
      this.is(FragmentType.INLINE_REFERENCE) ||
      (this.is(FragmentType.EQUATION) && (this as any).inline) ||
      this.is(FragmentType.ANCHOR) ||
      (this.is(FragmentType.REFERENCE_INPUT) &&
        (this as any).internalReferenceType === InternalReferenceType.CLAUSE_REFERENCE)
    );
  }

  public isClauseOfType(...clauseTypes: ClauseType[]): boolean {
    return this.is(FragmentType.CLAUSE) && (clauseTypes.length === 0 || clauseTypes.includes((this as any).clauseType));
  }

  public isSectionOfType(...sectionTypes: SectionType[]): boolean {
    return (
      this.is(FragmentType.SECTION) && (sectionTypes.length === 0 || sectionTypes.includes((this as any).sectionType))
    );
  }

  /**
   * Does a comparision between two subtrees.  This fragment is taken as the root
   * of the first subtree. Two subtrees are considered equal if they contain all
   * the same fragments with all the same values and all the same last modifiying
   * users.
   *
   * @param other the root of the second subtree.
   * @returns true if they are equal, false if not.
   */
  public subtreeEquals(other: Fragment): boolean {
    if (!other) {
      return false;
    }

    const firstLookup: Map<string, Fragment> = new Map();
    const secondLookup: Map<string, Fragment> = new Map();

    this.iterateDown(null, null, (fragment: Fragment) => {
      firstLookup.set(fragment.id.value, fragment);
    });
    other.iterateDown(null, null, (fragment: Fragment) => {
      secondLookup.set(fragment.id.value, fragment);
    });

    if (secondLookup.size !== firstLookup.size) {
      return false;
    }

    for (const id of Array.from(firstLookup.keys())) {
      if (secondLookup.has(id)) {
        const firstFragment: Fragment = firstLookup.get(id);
        const secondFragment: Fragment = secondLookup.get(id);
        if (
          secondFragment.value !== firstFragment.value ||
          !secondFragment.lastModifiedBy.equals(firstFragment.lastModifiedBy)
        ) {
          return false;
        } else if (firstFragment.isCaptioned() && secondFragment.isCaptioned()) {
          const firstCaption: Fragment = (firstFragment as CaptionedFragment).caption;
          const secondCaption: Fragment = (secondFragment as CaptionedFragment).caption;
          if (firstCaption.value !== secondCaption.value) {
            return false;
          }
        } else if (firstFragment.type === FragmentType.EQUATION) {
          const firstSource: Fragment = (firstFragment as EquationFragment).source;
          const secondSource: Fragment = (secondFragment as EquationFragment).source;
          if (firstSource.value !== secondSource.value) {
            return false;
          }
        } else if (firstFragment.type === FragmentType.CLAUSE) {
          const firstClause: ClauseFragment = firstFragment as ClauseFragment;
          const secondClause: ClauseFragment = secondFragment as ClauseFragment;
          if (
            firstClause.clauseType !== secondClause.clauseType ||
            firstClause.background !== secondClause.background
          ) {
            return false;
          }
        }
      } else {
        return false;
      }
    }
    return true;
  }
}

/**
 * An abstract specialisation of Fragment containing a caption.
 *
 * @field caption {TextFragment}   The caption
 */
export abstract class CaptionedFragment extends Fragment {
  public caption: TextFragment;
  public diffedCaption: TextFragment[]; // Only used in Clause Change Summary and Section Compare

  constructor(id: UUID, type: FragmentType, children: Fragment[], value: string, caption: string) {
    super(id, type, children, value);

    this.caption = new TextFragment(null, caption);
    this.caption.parent = this;
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();
    json.caption = this.caption.value;
    delete json.diffedCaption; // Only used in Clause Change Summary and Section Compare

    return json;
  }

  /**
   * @override
   */
  public length(): number {
    return super.length() + this.caption.length();
  }
}

/**
 * A fragment representing a section of plain text.
 */
export class TextFragment extends Fragment {
  /**
   * Static factory to create empty TextFragments.
   *
   * @returns {TextFragment}   The new text fragment
   */
  public static empty(): TextFragment {
    return new TextFragment(null, '');
  }

  constructor(id: UUID, value: string = '', type: FragmentType = FragmentType.TEXT) {
    super(id, type, [], value);
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): TextFragment {
    return new TextFragment(null, Fragment.splitValue(this, offset));
  }
}

/**
 * A fragment representing a section of superscript text.
 */
export class SuperscriptFragment extends TextFragment {
  /**
   * Static factory to create empty SuperscriptFragments.
   *
   * @returns {TextFragment}   The new superscript fragment
   */
  public static empty(): SuperscriptFragment {
    return new SuperscriptFragment(null, '');
  }

  constructor(id: UUID, value: string = '') {
    super(id, value, FragmentType.SUPERSCRIPT);
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): SuperscriptFragment {
    return new SuperscriptFragment(null, Fragment.splitValue(this, offset));
  }
}

/**
 * A fragment representing a section of subscript text.
 */
export class SubscriptFragment extends TextFragment {
  /**
   * Static factory to create empty SubscriptFragments.
   *
   * @returns {TextFragment}   The new subscript fragment
   */
  public static empty(): SubscriptFragment {
    return new SubscriptFragment(null, '');
  }

  constructor(id: UUID, value: string = '') {
    super(id, value, FragmentType.SUBSCRIPT);
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): SubscriptFragment {
    return new SubscriptFragment(null, Fragment.splitValue(this, offset));
  }
}

/**
 * A fragment representing a section of memo text.
 */
export class MemoFragment extends TextFragment {
  /**
   * Static factory to create empty MemoFragments.
   *
   * @returns {TextFragment}   The new memo fragment
   */
  public static empty(): MemoFragment {
    return new MemoFragment(null, '');
  }

  constructor(id: UUID, value: string = '') {
    super(id, value, FragmentType.MEMO);
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): MemoFragment {
    return new MemoFragment(null, Fragment.splitValue(this, offset));
  }
}

/**
 * A fragment representing a list item, containing other fragments.
 */
export class ListItemFragment extends Fragment {
  public indented: boolean;

  /**
   * Create an empty list item fragment.
   *
   * @returns {ListItemFragment}   The new list item
   */
  public static empty(): ListItemFragment {
    const children: Fragment[] = [new TextFragment(null)];
    return new ListItemFragment(null, children);
  }

  constructor(id: UUID, children: Fragment[] = [], indented: boolean = false) {
    super(id, FragmentType.LIST_ITEM, children, null);
    this.indented = indented;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): ListItemFragment {
    return offset < this.length()
      ? new ListItemFragment(null, Fragment.splitChildren(this, offset))
      : ListItemFragment.empty();
  }
}

/**
 * A fragment representing a list, either ordered or unordered.
 *
 * @field ordered {boolean}   True if an ordered list
 */
export class ListFragment extends Fragment {
  public ordered: boolean;
  public listStartIndex: number;
  public primaryListIndexingType: ListIndexingType;
  public secondaryListIndexingType: ListIndexingType;
  public readonly isScheduleList: boolean;

  /**
   * Static factory to create lists with a given number of items.
   *
   * @param size     {number}    The number of items
   * @param? ordered {boolean}   True for ordered lists
   */
  public static withSize(size: number, ordered: boolean = true): ListFragment {
    const list: ListFragment = new ListFragment(null, [], ordered);

    for (let i: number = 0; i < size; ++i) {
      list.children.push(ListItemFragment.empty());
    }

    return list;
  }

  constructor(
    id: UUID,
    children: ListItemFragment[] = [],
    ordered: boolean = false,
    listStartIndex: number = 1,
    primaryListIndexingType: ListIndexingType = ListIndexingType.NUMERICAL,
    secondaryListIndexingType: ListIndexingType = ListIndexingType.LOWER_ALPHA,
    isScheduleList: boolean = false
  ) {
    super(id, FragmentType.LIST, children, null);
    this.ordered = ordered;
    this.listStartIndex = listStartIndex;
    this.primaryListIndexingType = primaryListIndexingType || ListIndexingType.NUMERICAL;
    this.secondaryListIndexingType = secondaryListIndexingType || ListIndexingType.LOWER_ALPHA;
    this.isScheduleList = isScheduleList;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): ListFragment {
    return new ListFragment(null, Fragment.splitChildren(this, offset) as ListItemFragment[], this.ordered);
  }
}

export enum ListIndexingType {
  NUMERICAL = 'NUMERICAL',
  LOWER_ALPHA = 'LOWER_ALPHA',
  NONE = 'NONE',
}

export enum VirusScanState {
  PENDING = 'PENDING',
  OK = 'OK',
  SCAN_FAILED = 'SCAN_FAILED',
  VIRUS_FOUND = 'VIRUS_FOUND',
}

/**
 * A fragment representing a figure with a caption.
 *
 * @field uploadId {UUID}   The upload primary key
 */
export class FigureFragment extends CaptionedFragment {
  public uploadId: UUID;
  public landscape: boolean;
  public altText: string;
  public virusScanState: VirusScanState;
  public uploadProperties: UploadProperties;
  public isIllustrativeFigure: boolean;
  public diffedAltText: TextFragment[];

  constructor(
    id: UUID,
    uploadId: UUID,
    caption: string = 'New Figure',
    landscape: boolean = false,
    altText: string = '',
    virusScanState: VirusScanState = VirusScanState.PENDING,
    uploadProperties: UploadProperties = null,
    isIllustrativeFigure: boolean = false
  ) {
    super(id, FragmentType.FIGURE, [], null, caption);
    this.uploadId = uploadId;
    this.landscape = landscape;
    this.altText = altText;
    this.virusScanState = virusScanState;
    this.uploadProperties = uploadProperties;
    this.isIllustrativeFigure = isIllustrativeFigure;
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();
    json.altText = this.altText;
    delete json.diffedAltText; // Only used in Clause Change Summary and Section Compare

    return json;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): FigureFragment {
    return null;
  }

  /**
   * Toggle the orientation
   */
  public toggleLandscape(): void {
    this.landscape = !this.landscape;
  }
}

/**
 * A fragment reprsenting an equation, either display or inline, with a caption.
 *
 * @field inline {boolean}        True if an inline equation
 * @field source {TextFragment}   The asciimath source fragment
 */
export class EquationFragment extends CaptionedFragment {
  public inline: boolean;
  public source: TextFragment;

  /**
   * Static factory for creating new equations.
   *
   * @param inline {boolean}            Whether the equation is inline
   * @returns      {EquationFragment}   The new equation
   */
  public static empty(inline: boolean): EquationFragment {
    return new EquationFragment(null, '', inline, inline ? '' : 'New Equation');
  }

  constructor(id: UUID, value: string, inline: boolean, caption: string) {
    super(id, FragmentType.EQUATION, [], null, caption);

    this.inline = inline;
    this.source = new TextFragment(null, value);
    this.source.parent = this;
  }

  /**
   * @override
   */
  public length(): number {
    return this.inline ? this.source.length() : super.length() + this.source.length();
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): EquationFragment {
    return new EquationFragment(null, Fragment.splitValue(this.source, offset), this.inline, this.caption.value);
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();
    json.source = json.source.value;

    return json;
  }
}

/**
 * An enumeration of all section reference types.
 */
export enum InternalReferenceType {
  SECTION_REFERENCE = 'SECTION_REFERENCE',
  WSR_REFERENCE = 'WSR_REFERENCE',
  CLAUSE_REFERENCE = 'CLAUSE_REFERENCE',
}

export enum ClauseReferenceTargetType {
  CLAUSE = 'CLAUSE',
}

export enum ReferenceType {
  NORMATIVE = 'NORMATIVE',
  INFORMATIVE = 'INFORMATIVE',
}

export class DocumentReferenceFragment extends Fragment {
  public referenceType: ReferenceType;
  public readonly globalReference: UUID;
  public searchableGlobalReference: SearchableGlobalReference;

  constructor(id: UUID, referenceType: ReferenceType, globalReference: UUID, release?: string) {
    super(id, FragmentType.DOCUMENT_REFERENCE, [], release);
    this.referenceType = referenceType;
    this.globalReference = globalReference;
    this.weight = 0;
  }

  public split(): DocumentReferenceFragment {
    return this;
  }

  public serialise(): any {
    const json: any = super.serialise();
    json.referenceType = ReferenceType[json.referenceType];

    delete json.searchableGlobalReference;

    return json;
  }

  /**
   * Getter so value can be referred to as 'release'.
   *
   * @returns {string}   The release date
   */
  public get release(): string {
    return this._value;
  }

  /**
   * Setter so value can be referred to as 'release'.
   *
   * @param release {string}   The new release date
   */
  public set release(release: string) {
    this._value = release;
  }

  public toggleReferenceType(): void {
    this.referenceType =
      this.referenceType === ReferenceType.INFORMATIVE ? ReferenceType.NORMATIVE : ReferenceType.INFORMATIVE;
  }
}

export class InlineReferenceFragment extends Fragment {
  public documentReference: UUID;
  public globalReferenceDeleted: boolean = false;
  public globalReferenceWithdrawnWithoutYearOfIssue: boolean = false;
  public globalReferenceWithdrawnWithYearOfIssue: boolean = false;

  public static empty(): InlineReferenceFragment {
    return new InlineReferenceFragment(null, null);
  }

  constructor(id: UUID, documentReference: UUID, public deleted: boolean = false) {
    super(id, FragmentType.INLINE_REFERENCE, [], null);
    this.documentReference = documentReference;
  }

  public split(): InlineReferenceFragment {
    return this;
  }

  public length(): number {
    return 1;
  }
}

/**
 * An enumeration of all clause types.  This should coincide with the service's
 * ClauseType enum.
 */
export enum ClauseType {
  HEADING_1 = 'HEADING_1',
  HEADING_2 = 'HEADING_2',
  HEADING_3 = 'HEADING_3',
  NOTE = 'NOTE',
  ADVICE = 'ADVICE',
  REQUIREMENT = 'REQUIREMENT',
  NORMAL = 'NORMAL',
  INFORMATION = 'INFORMATION',
  SPECIFIER_INSTRUCTION = 'SPECIFIER_INSTRUCTION',
  ITEM = 'ITEM',
}

/**
 * A fragment representing a clause.
 *
 * @field clauseType  {ClauseType}   The type of clause
 */
export class ClauseFragment extends Fragment implements Serialisable {
  public clauseType: ClauseType;

  public standardFormatType: StandardFormatType; // The standard format type if in a standard format group
  public administration: Administration; // The administration if in a national determined requirement
  public specifierInstructionType: SpecifierInstructionType; // The specifier instruction type if a Specifier Instruction clause

  public verificationLinkRequired: ClauseLinkRequired;
  public documentationLinkRequired: ClauseLinkRequired;
  public isUnmodifiableClause: boolean;

  public component: ClauseComponent; // Specialise the component type

  public preview: string; // Text only clause for previews

  /**
   * Static factory to create a clause of a given type with an empty TextFragment as
   * its only child.
   *
   * @param clauseType {ClauseType}       The type of clause
   * @returns          {ClauseFragment}   The new clause
   */
  public static empty(clauseType: ClauseType): ClauseFragment {
    const children: Fragment[] = [new TextFragment(null, '')];
    return new ClauseFragment(null, clauseType, children, '', null, null, null);
  }

  constructor(
    id: UUID,
    clauseType: ClauseType,
    children: Fragment[],
    background: string,
    standardFormatType: StandardFormatType = null,
    administration: Administration = null,
    specifierInstructionType: SpecifierInstructionType = null,
    verificationLinkRequired: ClauseLinkRequired = null,
    documentationLinkRequired: ClauseLinkRequired = null,
    isUnmodifiableClause: boolean = false
  ) {
    super(id, FragmentType.CLAUSE, children, background);

    this.clauseType = clauseType;
    this.standardFormatType = standardFormatType;
    this.administration = administration;
    this.specifierInstructionType = specifierInstructionType;
    this.verificationLinkRequired = verificationLinkRequired;
    this.documentationLinkRequired = documentationLinkRequired;
    this.isUnmodifiableClause = isUnmodifiableClause;
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();
    json.clauseType = ClauseType[json.clauseType];
    json.standardFormatType = StandardFormatType[json.standardFormatType];
    json.administration = Administration[json.administration];
    json.specifierInstructionType = SpecifierInstructionType[json.specifierInstructionType];
    json.verificationLinkRequired = ClauseLinkRequired[json.verificationLinkRequired];
    json.documentationLinkRequired = ClauseLinkRequired[json.documentationLinkRequired];
    // This is reconstructed on deserialisation
    delete json.preview;

    return json;
  }

  /**
   * @inheritdoc
   * If the parent section is of type (NORMATIVE) return a new Clause Fragment of type the clause
   * being split at the given offset. Else, the Clause Type will default to NORMAL.
   */
  public split(offset: number): ClauseFragment {
    const clauseType: ClauseType =
      this.getSection().sectionType === SectionType.NORMATIVE ? this.clauseType : ClauseType.NORMAL;

    if (offset < this.length() - this.captionLength() && offset > 0) {
      const children: Fragment[] = Fragment.splitChildren(this, offset, true);
      if (children.length === 0 || !children[0].is(FragmentType.TEXT)) {
        children.unshift(TextFragment.empty());
      }
      return new ClauseFragment(null, clauseType, children, this.background);
    } else {
      return ClauseFragment.empty(clauseType);
    }
  }

  /**
   * Sort this fragment's children by their weight, put captioned fragments and anchor fragments last, and lists just before.
   * @override
   */
  public sortChildren(): void {
    this.children.sort((a: Fragment, b: Fragment) => {
      const aTypeWeight: number = this._getTypeWeight(a, b);
      const bTypeWeight: number = this._getTypeWeight(b, a);
      return aTypeWeight - bTypeWeight || a.weight - b.weight;
    });
  }

  private _getTypeWeight(getTypeWeightFor: Fragment, other: Fragment): number {
    if (getTypeWeightFor.isCaptioned() || (getTypeWeightFor.is(FragmentType.ANCHOR) && other.isCaptioned())) {
      return 2;
    } else if (
      getTypeWeightFor.is(FragmentType.LIST) ||
      (getTypeWeightFor.is(FragmentType.ANCHOR) && other.is(FragmentType.LIST))
    ) {
      return 1;
    }

    return 0;
  }

  /**
   * @override
   */
  public length(predicate?: Predicate<Fragment>): number {
    if (typeof predicate !== 'function') {
      predicate = (f: Fragment) => true;
    }

    return this.children
      .filter((child: Fragment) => predicate(child))
      .reduce((sum: number, child: Fragment) => (sum += child.length()), 0);
  }

  /**
   * @override
   */
  public captionLength(predicate?: Predicate<Fragment>): number {
    if (typeof predicate !== 'function') {
      predicate = (f: Fragment) => true;
    }

    return this.children
      .filter((child: Fragment) => predicate(child) && child.isCaptioned())
      .reduce((sum: number, child: Fragment) => (sum += child.length()), 0);
  }

  /**
   * Getter so value can be referred to as 'background'.
   *
   * @returns {string}   The background
   */
  public get background(): string {
    return this._value;
  }

  /**
   * Setter so value can be referred to as 'background'.
   *
   * @param background {string}   The new background
   */
  public set background(background: string) {
    this._value = background;
  }

  public getSection(): SectionFragment {
    return this.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
  }

  /**
   * Find the correct index of that clause type,
   * as opposed to its index in the parents child array
   *
   * @param clause {ClauseFragment} The clause for which to find its index
   * @returns {number} The index
   */
  public indexOfType(clause: ClauseFragment): number {
    const sectionType: SectionType = this.getSection().sectionType;
    let index: number = 0;

    if (sectionType === SectionType.NORMATIVE) {
      if (
        clause &&
        (clause.clauseType === ClauseType.REQUIREMENT ||
          clause.clauseType === ClauseType.ADVICE ||
          clause.clauseType === ClauseType.NOTE)
      ) {
        for (let i = clause.index(); i >= 0; i--) {
          const sibling: Fragment = clause.parent.children[i];
          if (sibling && sibling.isClauseOfType(clause.clauseType)) {
            index++;
          } else if (
            clause.clauseType === ClauseType.ADVICE &&
            sibling &&
            sibling.isClauseOfType(ClauseType.REQUIREMENT)
          ) {
            break;
          } else if (clause.clauseType === ClauseType.NOTE) {
            break;
          }
        }
      }
    } else if (sectionType === SectionType.APPENDIX) {
      if (
        clause &&
        (clause.clauseType === ClauseType.HEADING_1 ||
          clause.clauseType === ClauseType.HEADING_2 ||
          clause.clauseType === ClauseType.HEADING_3)
      ) {
        for (let i = clause.index(); i >= 0; i--) {
          const sibling: Fragment = clause.parent.children[i];
          if (sibling && sibling.isClauseOfType(clause.clauseType)) {
            index++;
          } else if (clause.clauseType === ClauseType.HEADING_3) {
            break;
          } else if (
            clause.clauseType === ClauseType.HEADING_2 &&
            sibling &&
            sibling.isClauseOfType(ClauseType.HEADING_1)
          ) {
            break;
          }
        }
      }
    }

    return index;
  }

  /**
   * Compile all child text fragments of a heading into a single string
   */
  public calculateClausePreview(): void {
    const types: FragmentType[] = [...EDITABLE_TEXT_FRAGMENT_TYPES, FragmentType.READONLY];
    this.preview = this.children
      .reduce((currentValue: string, child: Fragment) => {
        if (child.is(FragmentType.INPUT, FragmentType.REFERENCE_INPUT, FragmentType.UNIT_INPUT)) {
          currentValue += (child as AbstractInputFragment).getPreview();
        } else if (child.is(...types)) {
          currentValue += child.value;
        }

        return currentValue;
      }, '')
      .replace(/[\u200B]/g, '');
  }
}

/**
 * An enumeration of all section types.
 */
export enum SectionType {
  DOCUMENT_INFORMATION = 'DOCUMENT_INFORMATION',
  NORMATIVE = 'NORMATIVE',
  INTRODUCTORY = 'INTRODUCTORY',
  APPENDIX = 'APPENDIX',
  REFERENCE_NORM = 'REFERENCE_NORM',
  REFERENCE_INFORM = 'REFERENCE_INFORM',
}

/**
 * A fragment representing a section.
 *
 * @field sectionType  {SectionType}   The section type
 * @field deleted      {boolean}       True if this section is deleted
 * @field subject      {string}        The subject of the WSR mapping of this section
 * @field topic        {string}        The topic of the WSR mapping of this section
 * @field wsrCode      {string}        The WSR document code for this section
 */
export class SectionFragment extends Fragment implements Serialisable {
  public sectionType: SectionType;
  public administration: Administration; // The administration if in a national determined section
  public deleted: boolean;
  public subject: string;
  public topic: string;
  public wsrCode: string;

  /**
   * Create an empty SectionFragment with the given type and title, and no children.
   *
   * @param sectionType {SectionType}       The section type
   * @param title       {string}            The section title (or document title if sectionType = DOCUMENT_INFORMATION)
   * @returns           {SectionFragment}   The new section
   */
  public static empty(sectionType: SectionType, title: string): SectionFragment {
    return new SectionFragment(null, title, sectionType, [], false, '', '', '', null);
  }

  constructor(
    id: UUID,
    title: string,
    sectionType: SectionType,
    children: Fragment[],
    deleted: boolean,
    subject: string = '',
    topic: string = '',
    wsrCode: string,
    administration: Administration = null
  ) {
    super(id, FragmentType.SECTION, children, title);

    this.sectionType = sectionType;
    this.administration = administration;
    this.deleted = deleted || false;
    this.subject = subject;
    this.topic = topic;
    this.wsrCode = wsrCode;
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();
    json.sectionType = SectionType[json.sectionType];
    json.administration = Administration[json.administration];

    return json;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): SectionFragment {
    return null;
  }

  /**
   * @override
   */
  public length(): number {
    return this.children.reduce((sum: number, child: Fragment) => (sum += child.length()), 0);
  }

  /**
   * Getter so value can be referred to as 'title'.
   *
   * @returns {string}   The title
   */
  public get title(): string {
    return this._value;
  }

  /**
   * Setter so value can be referred to as 'title'.
   *
   * @param title {string}   The new title
   */
  public set title(title: string) {
    this._value = title;
  }

  public getDocument(): DocumentFragment {
    return this.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment;
  }

  /**
   * Helper method for determining if the section is part of a section group.
   */
  public isInSectionGroup(): boolean {
    return this.parent && this.parent.is(FragmentType.SECTION_GROUP);
  }

  /**
   * Extract clause fragments that are direct children of this section
   * as well as nested clauses that are part of a clause group.
   */
  public getClauses(): ClauseFragment[] {
    return this.children.reduce((clauses: ClauseFragment[], child: Fragment) => {
      if (child.is(FragmentType.CLAUSE)) {
        clauses.push(child as ClauseFragment);
      } else if (child.is(FragmentType.CLAUSE_GROUP)) {
        clauses.push(...(child as ClauseGroupFragment).getClauses());
      }
      return clauses;
    }, []);
  }
}

/**
 * A fragment representing a document.
 *
 * @field suite        {Suite}                        The suite the document belongs to
 * @field documentData {DocumentData}                 The document metadata
 * @field deleted      {boolean}                      True if the document has been deleted
 */
export class DocumentFragment extends Fragment implements Serialisable {
  public get parent(): RootFragment {
    return super.parent as RootFragment;
  }

  public set parent(value: RootFragment) {
    super.parent = value;
  }

  public suite: Suite;

  public documentData: DocumentData;

  public deleted: boolean;

  private _publishedStates: WorkflowStatus[] = [
    // Published statuses for SFP documents
    SFPWorkflowStatus.AWAITING_DIVISIONAL_DIRECTOR_SIGNOFF,
    SFPWorkflowStatus.AWAITING_CHE_SIGNOFF,
    SFPWorkflowStatus.AWAITING_DEVOLVED_ADMIN_SIGNOFF,
    SFPWorkflowStatus.AWAITING_EU_NOTIFICATION,
    SFPWorkflowStatus.ADDRESSING_EU_COMMENTS,
    SFPWorkflowStatus.IN_EU_STANDSTILL,
    SFPWorkflowStatus.IN_QMR2_APPROVAL,
    SFPWorkflowStatus.FAILED_SIGNOFF,
    SFPWorkflowStatus.APPROVAL_COMPLETE,
    SFPWorkflowStatus.READY_FOR_PUBLISHING,
    SFPWorkflowStatus.IN_PUBLISHING_REVIEW,
    // Published statuses for SRP documents
    SRPWorkflowStatus.IN_APPROVAL_BY_TSC_CHAIR,
    SRPWorkflowStatus.CONTENT_SPECIALIST_REVIEW_FOR_APPROVAL,
    SRPWorkflowStatus.IN_APPROVAL_BY_DD,
    SRPWorkflowStatus.IN_APPROVAL_WITH_OO_HEADS_OF_STANDARDS,
    SRPWorkflowStatus.IN_PREPARATION_FOR_AUTHORISATION,
    SRPWorkflowStatus.IN_AUTHORISATION_WITH_HE_CHE,
    SRPWorkflowStatus.FAILED_AUTHORISATION,
    SRPWorkflowStatus.AWAITING_DEVOLVED_ADMINISTRATION_AUTHORISATION,
    SRPWorkflowStatus.IN_CONFIRMATION_OF_NOTIFICATION,
    SRPWorkflowStatus.AWAITING_EC_NOTIFICATION,
    SRPWorkflowStatus.IN_EC_STANDSTILL_3_MONTHS,
    SRPWorkflowStatus.EC_SUBMISSION_FAILURE_LEGAL_ACTIONS,
    SRPWorkflowStatus.IN_EC_STANDSTILL_FOR_DETAILED_OPINIONS_6_MONTHS_OVERALL,
    SRPWorkflowStatus.ADDRESSING_EC_COMMENTS,
    // Published statuses for both SFP and SRP documents
    SRPWorkflowStatus.IN_PUBLISHING,
    SRPWorkflowStatus.PUBLICATION_BLOCKED,
  ];

  /**
   * Static factory for creating empty documents.
   *
   * @param owner          {UUID}              The owning user ID
   * @param suite          {Suite}             The suite the document belongs to
   * @param administration {Administration}    The devolved administration (if an annex)
   * @returns              {DocumentFragment}  The new document
   */
  public static empty(owner: UUID, suite: Suite = Suite.DMRB, administration: Administration = null): DocumentFragment {
    const data: DocumentData = DocumentData.empty(owner, administration);
    return new DocumentFragment(null, [], suite, data, false);
  }

  constructor(id: UUID, children: Fragment[], suite: Suite, documentData: DocumentData, deleted: boolean = false) {
    super(id, FragmentType.DOCUMENT, children);
    this.suite = suite;
    this.documentData = documentData;
    this.deleted = deleted;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): DocumentFragment {
    return null;
  }

  /**
   * @override
   */
  public length(): number {
    return this.children.reduce((sum: number, child: Fragment) => (sum += child.length()), 0);
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();
    json.documentData.documentId = this.id.value;
    delete json._publishedStates;
    return json;
  }

  /**
   * Getter so title can be easily read from documents extracted using INITIAL_DOCUMENT_LOAD (or FULL_TREE)
   *
   * @returns {string}   The title
   */
  public get title(): string {
    return this.getInformation(DocumentInformationType.DOCUMENT_TITLE)?.value;
  }

  /**
   * Getter so document code can be easily read from documents extracted using INITIAL_DOCUMENT_LOAD (or FULL_TREE)
   *
   * @returns {string}   The document code
   */
  public get documentCode(): string {
    return this.getInformation(DocumentInformationType.DOCUMENT_CODE)?.value;
  }

  /**
   * Getter so document number can be easily read from documents extracted using INITIAL_DOCUMENT_LOAD (or FULL_TREE)
   *
   * @returns {string}   The document number
   */
  public get documentNumber(): string {
    return this.getInformation(DocumentInformationType.DOCUMENT_NUMBER)?.value;
  }

  /**
   * Helper function for extracting section fragments that are direct children of a document
   * as well as nested sections that are part of a section group.
   *
   * @returns       {SectionFragment[]}   The flattened list of section fragments
   */
  public getSections(): SectionFragment[] {
    const sections: SectionFragment[] = [];

    this.children.forEach((f: Fragment) => {
      if (f.is(FragmentType.SECTION)) {
        sections.push(f as SectionFragment);
      } else if (f.is(FragmentType.SECTION_GROUP)) {
        sections.push(...f.children.map((child: Fragment) => child as SectionFragment));
      }
    });

    return sections;
  }

  public getInformation(type: DocumentInformationType): DocumentInformationFragment {
    const informationSection: SectionFragment = this.getDocumentInformationSection();

    if (informationSection) {
      const res: Fragment[] = informationSection.iterateDown(null, null, (f: Fragment) => {
        if (f.is(FragmentType.DOCUMENT_INFORMATION) && (f as DocumentInformationFragment).isInformationType(type)) {
          return f;
        }
      });
      return res.length ? (res[0] as DocumentInformationFragment) : void 0;
    }
  }

  /**
   * Returns true if this document is one of the passed suites.  Also returns true if
   * passed no arguments, since an empty intersection is tautologically true.
   *
   * @param suites  {Suite[]}   The suites
   * @returns       {boolean}   True if one of these suites
   */
  public isSuite(...suites: Suite[]): boolean {
    return suites.length === 0 || suites.includes(this.suite);
  }

  /**
   * Helper method to check if the document is from a legacy suite, defined as LEGACY_DMRB or LEGACY_MCHW.
   */
  public isLegacySuite(): boolean {
    return this.isSuite(Suite.LEGACY_DMRB, Suite.LEGACY_MCHW);
  }

  public getReferenceSections(): SectionFragment[] {
    return this.children.filter((child: Fragment) => {
      return (
        child.is(FragmentType.SECTION) &&
        ((child as SectionFragment).sectionType === SectionType.REFERENCE_NORM ||
          (child as SectionFragment).sectionType === SectionType.REFERENCE_INFORM)
      );
    }) as SectionFragment[];
  }

  public getInformReferenceSection(): SectionFragment {
    return this.children.find((child: Fragment) => {
      return child.is(FragmentType.SECTION) && (child as SectionFragment).sectionType === SectionType.REFERENCE_INFORM;
    }) as SectionFragment;
  }

  public getNormReferenceSection(): SectionFragment {
    return this.children.find((child: Fragment) => {
      return child.is(FragmentType.SECTION) && (child as SectionFragment).sectionType === SectionType.REFERENCE_NORM;
    }) as SectionFragment;
  }

  public getDocumentInformationSection(): SectionFragment {
    return this.children.find((child: Fragment) => {
      return (
        child.is(FragmentType.SECTION) && (child as SectionFragment).sectionType === SectionType.DOCUMENT_INFORMATION
      );
    }) as SectionFragment;
  }

  /**
   * Gets all fragments in both document reference sections that are of type DOCUMENT_REFERENCE.
   * Note that it filters out INTERNAL_DOCUMENT_REFERENCE fragments.
   */
  public getDocumentReferences(): DocumentReferenceFragment[] {
    return this.getReferenceSections().reduce(
      (references: DocumentReferenceFragment[], section: SectionFragment) =>
        references.concat(DocumentReferenceUtils.getDocumentReferencesFromSection(section)),
      []
    );
  }

  public isInPublishedState(): boolean {
    return this._publishedStates.includes(this.documentData.workflowStatus);
  }

  public hasSections(): boolean {
    return this.getSections().some(
      (c) =>
        !c.isSectionOfType(SectionType.DOCUMENT_INFORMATION, SectionType.REFERENCE_INFORM, SectionType.REFERENCE_NORM)
    );
  }

  public firstStandardSection(): SectionFragment {
    return this.getSections().find(
      (c) =>
        !c.isSectionOfType(
          SectionType.DOCUMENT_INFORMATION,
          SectionType.REFERENCE_INFORM,
          SectionType.REFERENCE_NORM
        ) && !c.deleted
    );
  }
}

/**
 * A class representing the root node of the fragment tree.
 */
export class RootFragment extends Fragment {
  // The UUID of the unique tree root in the database; all DocumentFragments must have
  // this as their parent ID else the backend will reject them.
  public static readonly ID: UUID = UUID.orThrow('2e0afd80-88c8-11e7-bb31-be2e44b06b34');

  public get parent(): Fragment {
    return null; // This must be a root node
  }

  public set parent(val: Fragment) {
    Logger.error('fragment-error', 'Cannot set parent on root fragment');
  }

  public children: TreeArray<DocumentFragment>; // The root only contains documents

  constructor(children: DocumentFragment[]) {
    super(RootFragment.ID, FragmentType.ROOT, children, null);
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): RootFragment {
    return null;
  }
}

export class AnchorFragment extends Fragment {
  public otherAnchorId: UUID;

  constructor(id: UUID, public hasBeenResolved: boolean = false, public isFirstAnchor: boolean = false) {
    super(id, FragmentType.ANCHOR, [], null);
  }

  public split(): AnchorFragment {
    return this;
  }

  public length(): number {
    return 0;
  }
}

export enum DocumentInformationType {
  DOCUMENT_SUITE = 'DOCUMENT_SUITE',
  DOCUMENT_TITLE = 'DOCUMENT_TITLE',
  JIRA_DOCUMENT_ID = 'JIRA_DOCUMENT_ID',
  SUMMARY = 'SUMMARY',
  SHW_SUMMARY = 'SHW_SUMMARY',
  IFS_SUMMARY = 'IFS_SUMMARY',
  LEGACY_REFERENCE = 'LEGACY_REFERENCE',
  DEPRECATED_DMRB_REVISION = 'DEPRECATED_DMRB_REVISION',
  REVISION_RELEASE_NOTES = 'REVISION_RELEASE_NOTES',
  RELEASE_NOTES_IFS = 'RELEASE_NOTES_IFS',
  RELEASE_NOTES_SHW = 'RELEASE_NOTES_SHW',
  DOCUMENT_CHANGES = 'DOCUMENT_CHANGES',
  DISCIPLINE = 'DISCIPLINE',
  LIFECYCLE_STAGE = 'LIFECYCLE_STAGE',
  DOCUMENT_CODE = 'DOCUMENT_CODE',
  DOCUMENT_NUMBER = 'DOCUMENT_NUMBER',
  IFS_DOCUMENT_CODE = 'IFS_DOCUMENT_CODE',
  SHW_DOCUMENT_CODE = 'SHW_DOCUMENT_CODE',
  SHW_LEGACY_REFERENCE = 'SHW_LEGACY_REFERENCE',
  IFS_LEGACY_REFERENCE = 'IFS_LEGACY_REFERENCE',
  PREVIOUS_MCHW_VOLUME = 'PREVIOUS_MCHW_VOLUME',
  PREVIOUS_MCHW_SECTION = 'PREVIOUS_MCHW_SECTION',
  CATEGORY_OF_CHANGE = 'CATEGORY_OF_CHANGE',
  NEXT_PUBLICATION_VERSION_NUMBER = 'NEXT_PUBLICATION_VERSION_NUMBER',
  PUBLISHED_VERSION_NUMBER = 'PUBLISHED_VERSION_NUMBER',
  EXPORT_SUITE_DISPLAY_NAME = 'EXPORT_SUITE_DISPLAY_NAME',
  EXPORT_LOGO_IMAGE = 'EXPORT_LOGO_IMAGE',
  EXPORT_FRONT_PAGE_FOOTER = 'EXPORT_FRONT_PAGE_FOOTER',
  EXPORT_BACK_PAGE_FOOTER = 'EXPORT_BACK_PAGE_FOOTER',
  ASSOCIATED_DOCUMENT_INFORMATION = 'ASSOCIATED_DOCUMENT_INFORMATION',
}

type InformationFragmentValues = string[] | number[] | boolean[];

/**
 * A class representing a document Information Fragment used for displaying form field information captured in
 * the document Information section.
 *
 * @field documentInformationType {DocumentInformationType}   The type of document information
 */
export class DocumentInformationFragment extends Fragment {
  /**
   * Static helper to create a document information fragment of a given type.
   *
   * @param type {DocumentInformationType}      The type of document information type
   * @returns    {DocumentInformationFragment}   The Document Information Fragment object
   */
  public static empty(type: DocumentInformationType): DocumentInformationFragment {
    return new DocumentInformationFragment(null, '', type);
  }

  constructor(
    id: UUID,
    value: string = '',
    public documentInformationType: DocumentInformationType,
    public values: InformationFragmentValues = []
  ) {
    super(id, FragmentType.DOCUMENT_INFORMATION, [], value);
  }

  /**
   * @inheritDoc
   */
  public split(offset: number): DocumentInformationFragment {
    return null;
  }

  /**
   * @override
   */
  public get value(): string {
    return this._value;
  }

  /**
   * @override
   */
  public set value(value: string) {
    this._value = value;
  }

  /**
   * Returns true if this fragment has one of the passed types.  Also returns true if
   * passed no arguments.
   *
   * @param types {DepartureInformationType[]} The types
   * @returns     {boolean}                    True if one of these types
   */
  public isInformationType(...types: DocumentInformationType[]) {
    return types.length === 0 || types.indexOf(this.documentInformationType) >= 0;
  }
}

export enum MchwDocumentType {
  SHW = 'SHW',
  IFS = 'IFS',
}
