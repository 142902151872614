<div class="pdf-preview-dialog">
  <div class="sticky-warning">
    <strong>
      This is a preview for reviewing the presentation of the document at publication.<br />
      You are unable to download, print or select text in this view.
    </strong>
  </div>
  <pdf-viewer
    class="pdf-viewer"
    [src]="pdf"
    [original-size]="false"
    [render-text]="false"
    [show-all]="true"
    [zoom]="0.75"
  ></pdf-viewer>
</div>
