<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<div class="wsr-subject-topic-buttons">
  <p *ngIf="hasAnyChildBeenUpdated()" class="warning">
    Warning: Unsaved changes are present, press save to make sure changes are not lost.
  </p>
  <button
    *ngIf="CarsAction.CAN_EDIT_WSR | carsEnabled: document?.id | async"
    mat-raised-button
    color="primary"
    (click)="saveChanges()"
    aria-label="Save changes to wsr subject topic view"
    enableDefaultFocus
    type="submit"
    [disabled]="loading || !hasAnyChildBeenUpdated()"
  >
    <mat-icon class="mat-24">save</mat-icon>
    Save changes
  </button>

  <a
    mat-raised-button
    color="primary"
    (click)="printRoute()"
    aria-label="Print wsr subject topic view"
    enableDefaultFocus
    *ngIf="CarsAction.IS_MCHW_DOCUMENT | carsEnabled: document?.id | async"
  >
    <mat-icon class="mat-24">print</mat-icon>
    Print
  </a>

  <a
    mat-raised-button
    color="primary"
    (click)="closeRoute()"
    aria-label="Close wsr subject topic view"
    enableDefaultFocus
  >
    <mat-icon class="mat-24">close</mat-icon>
    Close
  </a>
</div>

<div class="body" *ngIf="CarsAction.IS_MCHW_DOCUMENT | carsEnabled: document?.id | async; else invalidSuiteMessage">
  <table>
    <tr>
      <th class="index-cell">Index</th>
      <th class="title-cell">Section title</th>
      <th class="subject-cell">Subject</th>
      <th class="topic-cell">Topic</th>
      <th class="wsr-topic-number-cell" *ngIf="CarsAction.CAN_EDIT_WSR | carsEnabled: document?.id | async">
        WSR topic number
      </th>
      <th class="wsr-code-cell">WSR document code</th>
      <th class="warning-icon-cell" *ngIf="CarsAction.CAN_EDIT_WSR | carsEnabled: document?.id | async"></th>
    </tr>
    <ng-container *ngFor="let child of documentChildren">
      <tr>
        <td>
          <div *ngFor="let section of getSectionsFromDocumentChild(child)">{{ section.id | fragmentIndex }}</div>
        </td>
        <td>
          <div class="truncated" *ngFor="let section of getSectionsFromDocumentChild(child)">{{ section.title }}</div>
        </td>
        <td>
          <ng-container *ngIf="isWsrMappingApplicable(child); else notApplicable">
            <input
              *ngIf="CarsAction.CAN_EDIT_WSR | carsEnabled: document?.id | async"
              type="text"
              id="Subject"
              placeholder="Subject"
              [(ngModel)]="subjects[child.id.value]"
              (blur)="validateChild(child)"
            />
            <span *ngIf="CarsAction.CAN_EDIT_WSR | carsDisabled: document?.id | async">
              {{ subjects[child.id.value] ? subjects[child.id.value] : 'No WSR mapping provided' }}
            </span>
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="isWsrMappingApplicable(child); else notApplicable">
            <input
              *ngIf="CarsAction.CAN_EDIT_WSR | carsEnabled: document?.id | async"
              type="text"
              id="Topic"
              placeholder="Topic"
              [(ngModel)]="topics[child.id.value]"
              (blur)="validateChild(child)"
            />
            <span *ngIf="CarsAction.CAN_EDIT_WSR | carsDisabled: document?.id | async">
              {{ topics[child.id.value] ? topics[child.id.value] : 'No WSR mapping provided' }}
            </span>
          </ng-container>
        </td>
        <td *ngIf="CarsAction.CAN_EDIT_WSR | carsEnabled: document?.id | async">
          <ng-container *ngIf="isWsrMappingApplicable(child); else notApplicable">
            <input
              type="number"
              id="Topic number"
              placeholder="Topic number"
              [(ngModel)]="wsrTopicNumbers[child.id.value]"
              maxLength="3"
              (blur)="handleWsrTopicNumberChange(child)"
            />
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="isWsrMappingApplicable(child); else notApplicable">
            {{ !!wsrDocumentCodes[child.id.value] ? wsrDocumentCodes[child.id.value] : 'No WSR mapping provided' }}
          </ng-container>
        </td>
        <td *ngIf="CarsAction.CAN_EDIT_WSR | carsEnabled: document?.id | async">
          <mat-icon
            *ngIf="!validWsrMapping[child.id.value]"
            svgIcon="error"
            [matTooltipShowDelay]="tooltipDelay"
            matTooltipPosition="left"
            matTooltip="WSR mapping values should either all be set or should be left blank, please update the values."
          ></mat-icon>
        </td>
      </tr>
      <ng-template #notApplicable>
        <div>Not applicable</div>
      </ng-template>
    </ng-container>
  </table>
</div>

<ng-template #invalidSuiteMessage>
  <div class="invalidSuite">WSR subject and topic mappings are not valid for this suite of document.</div>
</ng-template>
