import {ChangeDetectorRef, Directive, ElementRef, Input} from '@angular/core';
import {FragmentComponent} from '../core/fragment.component';
import {ListItemFragment} from '../types';

@Directive({
  selector: 'li[carsListItemFragment]',
})
export class ListItemFragmentDirective extends FragmentComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('carsListItemFragment') public set content(value: ListItemFragment) {
    super.content = value;
  }

  public get content(): ListItemFragment {
    return super.content as ListItemFragment;
  }

  constructor(protected _cd: ChangeDetectorRef, elementRef: ElementRef) {
    super(_cd, elementRef);
  }
}
