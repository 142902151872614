import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {Logger, LoggerService} from './services/logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class CarsErrorHandler implements ErrorHandler {
  private _loggerService: LoggerService;

  constructor(private _injector: Injector) {
    // Has to do be done to avoid circular dependencies as Angular loads the Error Module before anything else.
    setTimeout(() => (this._loggerService = this._injector.get(LoggerService)));
  }

  public handleError(error: Error): void {
    if (this._loggerService) {
      console.log('---- CARS ERROR HANDLER ----');
      Logger.uncaught(error);
    } else {
      setTimeout(() => {
        this.handleError(error);
      });
    }
  }
}
