import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {FragmentModule} from 'app/fragment/fragment.module';
import {ExcerptModule} from '../excerpt/excerpt.module';
import {LinkTableComponent} from './link-table.component';
import {PlaceholderComponent} from './placeholder.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatProgressBarModule,
    FragmentModule,
    ExcerptModule,
    MatPaginatorModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  declarations: [LinkTableComponent, PlaceholderComponent],
  exports: [LinkTableComponent, PlaceholderComponent],
})
export class LinkTableModule {}
