<div class="tab-container">
  <cars-alt-accessibility
    [altProperties]="{initialKey: 'r', key: 'w', condition: true}"
    (onSelect)="onCreate()"
  ></cars-alt-accessibility>
  <div class="cover" *ngIf="submittingDiscussion"></div>
  <mat-progress-spinner
    *ngIf="submittingDiscussion"
    mode="indeterminate"
    aria-label="Saving discussion"
  ></mat-progress-spinner>
  <div *ngIf="!clause" class="no-clause">Please select a clause to start a new discussion</div>

  <div class="content-selectors" *ngIf="clause">
    <mat-form-field>
      <mat-select
        placeholder="Manage discussions for"
        [(ngModel)]="selectedContent"
        (selectionChange)="onContentChange()"
        aria-label="Manage discussions for"
      >
        <mat-option *ngFor="let option of selectableContentOptions" [value]="option">
          {{ selectableContentIndexes[option.id.value] }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="subcontentAvailable()">
      <mat-select placeholder="Select table cell" [(ngModel)]="selectedSubcontent" aria-label="Select table cell">
        <mat-option *ngFor="let option of selectableSubcontentOptions" [value]="option">
          {{ selectableSubcontentIndexes[option.id.value] }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-tab-group *ngIf="clause" [@.disabled]="true" [(selectedIndex)]="tabIndex" [disablePagination]="true">
    <!--Unresolved Tab-->
    <mat-tab [label]="'Unresolved (' + unresolvedDiscussions + ')'">
      <div
        [matTooltip]="clause ? 'Start new discussion' : 'Please select a clause to start a new discussion'"
        [matTooltipShowDelay]="tooltipDelay"
      >
        <button
          mat-raised-button
          color="primary"
          class="full-width-button"
          (click)="onCreate()"
          *ngIf="canStartNewDiscussion()"
          [attr.aria-label]="clause ? 'Start new discussion' : 'Please select a clause to start a new discussion'"
          enableDefaultFocus
        >
          Start new discussion
        </button>
      </div>
      <div *ngIf="creatingDiscussion">
        <div class="discussion-input-container">
          <mat-form-field>
            <textarea
              [(ngModel)]="newDiscussion"
              rows="5"
              matInput
              autocomplete="off"
              #issue
              placeholder="Discussion"
              name="issue"
              carsAutofocus
              required
            >
            </textarea>
            <mat-error>{{ discussionErrorMessage }}</mat-error>
          </mat-form-field>
          <mat-button-toggle-group #typeGroup="matButtonToggleGroup" [value]="DiscussionType.GENERAL" required>
            <mat-button-toggle [value]="DiscussionType.GENERAL" aria-label="General">General</mat-button-toggle>
            <mat-button-toggle [value]="DiscussionType.EDITORIAL" aria-label="Editorial">Editorial</mat-button-toggle>
            <mat-button-toggle [value]="DiscussionType.TECHNICAL" aria-label="Technical">Technical</mat-button-toggle>
          </mat-button-toggle-group>

          <mat-form-field>
            <mat-select placeholder="In the capacity of:" [(ngModel)]="newCapacityOfComment" required>
              <mat-option
                *ngFor="let option of CapacityOfCommentOptions"
                [value]="option"
                [matTooltip]="option | capacityOfCommentToDisplayName"
                matTooltipPosition="left"
                [matTooltipShowDelay]="tooltipDelay"
              >
                {{ option | capacityOfCommentToDisplayName }}
              </mat-option>
            </mat-select>
            <mat-error>{{ capacityOfErrorMessage }}</mat-error>
          </mat-form-field>

          <div
            *ngIf="currentView.userCanSuggestTextChange()"
            matTooltip="Select a section of text to include a suggested replacement for it."
            matTooltipPosition="below"
            [matTooltipShowDelay]="tooltipDelay"
          >
            <mat-checkbox
              #suggestionToggle
              (click)="$event.preventDefault()"
              (mousedown)="checkboxToggle($event)"
              [checked]="suggesting"
              type="checkbox"
              [disabled]="!lastSelected && !suggesting"
            >
              Include suggestion
            </mat-checkbox>
          </div>
          <ng-container *ngIf="suggesting">
            <div class="label">Current value</div>
            <div class="field-container">
              {{ selectedText }}
            </div>
            <mat-form-field>
              <textarea
                [(ngModel)]="suggestedText"
                rows="5"
                matInput
                #issue
                placeholder="Suggested text"
                name="suggested"
                carsAutofocus
              >
              </textarea>
            </mat-form-field>
          </ng-container>
          <div>
            <button
              mat-raised-button
              color="primary"
              (click)="createDiscussion()"
              [disabled]="newDiscussion.length === 0 || !newCapacityOfComment"
              aria-label="Submit discussion"
              carsAnalytics="create-discussion"
            >
              Submit
            </button>
            <button mat-button (click)="cancelDiscussionCreation()" aria-label="Cancel creating discussion">
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div class="discussions-container" *ngIf="!creatingDiscussion">
        <ng-container *ngFor="let discussion of discussions; let i = index">
          <cars-discussion
            [currentView]="currentView"
            [clause]="clause"
            [discussion]="discussion"
            *ngIf="!discussion.resolved"
          ></cars-discussion>
        </ng-container>
        <div *ngIf="!unresolvedDiscussions" class="no-discussion">
          No{{ resolvedDiscussions ? ' unresolved' : '' }} discussions
          {{ resolvedDiscussions ? '' : 'yet' }}
        </div>
      </div>
    </mat-tab>

    <!--Resolved Tab-->
    <mat-tab [label]="'Resolved (' + resolvedDiscussions + ')'">
      <div class="discussions-container">
        <ng-container *ngFor="let discussion of discussions; let i = index">
          <cars-discussion
            [currentView]="currentView"
            [clause]="clause"
            [discussion]="discussion"
            *ngIf="discussion.resolved"
          ></cars-discussion>
        </ng-container>
        <div *ngIf="!resolvedDiscussions" class="no-discussion">No resolved discussions yet</div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
