<div class="statistic" *ngFor="let option of allStats">
  <div class="header">
    <span>{{ option.displayTitle }}</span>
    <mat-form-field *ngIf="option.key === 'DOCUMENT_CHANGES_STATISTICS'">
      <mat-select [(value)]="numberOfDays">
        <mat-option *ngFor="let number of documentChangeOptions" [value]="number">Last {{ number }} days</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="option.key === 'BENEFIT_REALISATION_STATISTICS'">
      <mat-select [(value)]="selectedDate" [disabled]="!availableDates.length">
        <mat-option *ngFor="let date of availableDates" [value]="date">{{ date | date }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      (click)="downloadStatistics(option.key)"
      [disabled]="isButtonDisabled(option.key)"
      [attr.aria-label]="'download csv for ' + option.displayTitle"
      enableDefaultFocus
    >
      Download .csv
      <mat-icon>file_download</mat-icon>
    </button>
  </div>
  <div class="body">
    <ng-container *ngIf="!option.loading">
      {{ option.description }}
    </ng-container>
    <ng-container *ngIf="option.loading">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <strong>Preparing download</strong>
    </ng-container>
  </div>
</div>
