import {Pipe, PipeTransform} from '@angular/core';
import {ClauseLinkType} from 'app/fragment/fragment-link/clause-link-type';

export const CLAUSE_LINK_TYPE_TO_DISPLAY_NAME: Readonly<Record<ClauseLinkType, string>> = {
  VERIFICATION: 'Verification',
  DOCUMENTATION: 'Documentation',
};

@Pipe({
  name: 'clauseLinkTypeDisplayName',
})
export class ClauseLinkTypeToDisplayNamePipe implements PipeTransform {
  private static readonly UNKNOWN_TYPE: string = 'Unknown Clause Link Type';

  /**
   * Transforms the clause link type enum into a friendly display name.
   * The {@link CLAUSE_LINK_TYPE_TO_DISPLAY_NAME} const is exported for consumers wanting to use this within typescript files.
   *
   * @param type  {ClauseLinkType}      The clause link type enum value
   * @returns     {string}              The display name
   */
  public transform(type: ClauseLinkType): string {
    return !!CLAUSE_LINK_TYPE_TO_DISPLAY_NAME[type]
      ? CLAUSE_LINK_TYPE_TO_DISPLAY_NAME[type]
      : ClauseLinkTypeToDisplayNamePipe.UNKNOWN_TYPE;
  }
}
