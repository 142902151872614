<button
  mat-button
  [attr.blurOption]="BlurOption.PREVENT_BLUR"
  [matTooltip]="enabled ? toolbarItem.tooltipText : toolbarItem.tooltipText + ' (select some text first)'"
  matTooltipPosition="below"
  [matTooltipShowDelay]="tooltipDelay"
  (mousedown)="suggest($event)"
  [disabled]="!enabled"
  [attr.aria-label]="toolbarItem.ariaLabel"
>
  <mat-icon *ngFor="let icon of toolbarItem.icons">{{ icon }}</mat-icon>
  <cars-alt-accessibility
    [altProperties]="altProperties"
    [richText]="true"
    (onSelect)="suggest(null)"
  ></cars-alt-accessibility>
</button>
