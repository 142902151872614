import {Logger} from 'app/error-handling/services/logger/logger.service';
import {isClauseGroupOfType} from 'app/fragment/fragment-utils';
import {Suite} from 'app/fragment/suite';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {ClauseGroupType} from 'app/fragment/types/clause-group-type';
import {ReferenceInputFragment} from 'app/fragment/types/input/reference-input-fragment';
import {UnitInputFragment} from 'app/fragment/types/input/unit-input-fragment';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {SectionGroupType} from 'app/fragment/types/section-group-type';
import {
  FieldDefinitionSpecifierInstructionTypes,
  SpecifierInstructionType,
} from 'app/fragment/types/specifier-instruction-type';
import {StandardFormatType, WSRScheduleStandardFormatTypes} from 'app/fragment/types/standard-format-type';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {
  ClauseFragment,
  ClauseType,
  DocumentFragment,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  EquationFragment,
  Fragment,
  FragmentType,
  InlineReferenceFragment,
  SectionFragment,
  SectionType,
} from '../fragment/types';
import {Serialisable} from '../utils/serialisable';
import {ParsedRequiredValuePattern} from './required-field-value/parsed-required-value-pattern';
import {RequiredValuePatternParser} from './required-field-value/required-value-pattern-parser';

/**
 * An enumeration of all validation rule modes.  Should be kept in sync with the
 * matching backend enum.
 */
export enum ValidationMode {
  TEXT, // Validate based on text content
  ELEMENT, // Validate based on child fragment types
  CLAUSE_TYPE_ORDER, // Validate based on clause types of current clause and previous siblings
  DELETED_REFERENCE, // Validate based on if the clause contains a deleted reference
  REQUIRED_FIELD, // Validate based on non-null fields for specific fragment types
  REQUIRED_FIELD_VALUE, // Validate based on field having specific non-null value
  REQUIRED_NUMERICAL_VALUE_SIZE, // Validate based on the field existing and having a numerical value >, <, >=, <=, =
  REQUIRED_NON_ZERO_LENGTH, // Validate based on aggregated length of child values being non-zero for specific fragment types
  WITHDRAWN_REFERENCE_WITHOUT_YEAR_OF_ISSUE, // Validate based on if the clause contains a withdrawn reference WITHOUT a year of issue
  WITHDRAWN_REFERENCE_WITH_YEAR_OF_ISSUE, // Validate based on if the clause contains a withdrawn reference WITH a year of issue
  DEPRECATED_STANDARD_FORMAT_TYPE, // Validate that the SFR type has not been deprecated
  DEPRECATED_SPECIFIER_INSTRUCTION_TYPE, // Validate that the SI type has not been deprecated
  INTERNAL_REFERENCE_TARGET_DELETED, // Validate based on if the target section of an internal section reference is deleted.
  REFERENCE_INPUT_HAS_REQUIRED_REFERENCE_COUNT, // Validate based on in the reference input contains the correct number of references.
  FIELD_DEFINITION_SI_NOT_IN_WSR_SCHEDULE_SFR, // Validate based on if the SI is a field definition SI not inside a WSR schedule SFR.
  NON_FIELD_DEFINITION_SI_IN_WSR_SCHEDULE_SFR, // Validate based on if the SI is not a field definition SI inside a WSR schedule SFR.
  UNIT_INPUT_WITHOUT_SELECTED_UNIT, // Validation based on if the unit input has a selected unit.
  EQUATION_TEXT, // Validate based on equation source not containing unescaped pattern.
}

/**
 * An enumeration of all validation rule severities.  Should be kept in sync with the
 * matching backend enum.  It must be kept in order from least severe to most severe.
 */
export enum ValidationSeverity {
  INFO, // Informational messages to improve content
  WARNING, // Warnings to suggest potential problems
  ERROR, // Errors that must be resolved
}

/**
 * An enumeration of all validation rule operations.  Should be kept in sync with the
 * matching backend enum.
 */
export enum ValidationOperation {
  ANY, // Logical OR
  ALL, // Logical AND
  NONE, // Logical NOR
  LTE_ONE, // Logical (NONE || XOR)
}

/**
 * Represents a condition under which the fragment will be validated: i.e.
 * only if the value of the field == value.
 */
export interface ValidationCondition {
  field: string;
  value: any;
}

/**
 * A class encapsulating a validation violation.
 *
 * @field rule      {ValidationSeverity}   The rule severity
 * @field title     {string}               The rule summary title
 * @field reference {string}               A supporting reference to the MDD
 * @field message   {string}               A diagnostic message
 */
export class ValidationError {
  constructor(
    public severity: ValidationSeverity,
    public title: string,
    public reference: string,
    public message: string
  ) {}
}

/**
 * A class representing a validation rule.  The rule applies only to the section and clause
 * types listed in the given fields, or all types if these arrays are empty.
 *
 * @field suiteTypes        {Suite[]}               The suite types the rule applies to
 * @field sectionGroupTypes {SectionGroupType[]}    The section group types the rule applies to (a null value means no group)
 * @field sectionTypes      {SectionType[]}         The section types the rule applies to
 * @field clauseGroupTypes   {ClauseGroupType[]}     The clause group types the rule applies to (a null value means no group)
 * @field clauseTypes       {ClauseType[]}          The clause types the rule applies to
 * @field mode              {ValidationMode}        The mode of rule
 * @field severity          {ValidationSeverity}    The severity of the rule
 * @field operation         {ValidationOperation}   The logical operation the rule applies
 * @field title             {string}                The rule title/summary
 * @field reference         {string}                A reference to the MDD supporting the rule
 * @field message           {string}                A more detailed message to display for the rule
 * @field patterns          {string[]}              An array of strings the rule matches against
 * @field conditions        {ValidationCondition[]} An array of strings the rule matches against
 * @field blockVersionCreation {Map<Suite, VersionTagType[]>} A map of suite to the version types creation that are blocked by this rule
 */
export class ValidationRule implements Serialisable {
  public readonly regexpPatterns: RegExp[];

  /**
   * Static helper to deserialise JSON rules received from the service into proper instances.
   *
   * @param json {any}              The JSON representation
   * @returns    {ValidationRule}   The deserialised rule
   */
  public static deserialise(json: any): ValidationRule {
    const mode: ValidationMode =
      typeof json.mode === 'string' ? ValidationMode[(json.mode || '').toUpperCase()] : json.mode;
    const severity: ValidationSeverity =
      typeof json.severity === 'string' ? ValidationSeverity[(json.severity || '').toUpperCase()] : json.severity;
    const operation: ValidationOperation =
      typeof json.operation === 'string' ? ValidationOperation[(json.operation || '').toUpperCase()] : json.operation;

    const suiteTypes: Suite[] = json.suiteTypes.map((st: string) => Suite[st.toUpperCase()]);
    const sectionGroupTypes: SectionGroupType[] = json.sectionGroupTypes.map((sgt: string) =>
      !!sgt ? SectionGroupType[sgt.toUpperCase()] : null
    );
    const sectionTypes: SectionType[] = json.sectionTypes.map((st: string) => SectionType[st.toUpperCase()]);
    const clauseGroupTypes: ClauseGroupType[] = json.clauseGroupTypes.map((cgt: string) =>
      !!cgt ? ClauseGroupType[cgt.toUpperCase()] : null
    );
    const clauseTypes: ClauseType[] = json.clauseTypes.map((ct: string) => ClauseType[ct.toUpperCase()]);

    const conditions: ValidationCondition[] = json.conditions.map((j) => {
      return {field: j.field, value: j.value};
    });

    const blockVersionCreation: Map<Suite, VersionTagType[]> = new Map<Suite, VersionTagType[]>();
    Object.entries(json.blockVersionCreation).forEach((pair) => {
      return blockVersionCreation.set(pair[0] as Suite, pair[1] as VersionTagType[]);
    });

    return new ValidationRule(
      suiteTypes,
      sectionGroupTypes,
      sectionTypes,
      clauseGroupTypes,
      clauseTypes,
      mode,
      severity,
      operation,
      json.title,
      json.reference,
      json.message,
      json.patterns,
      conditions,
      blockVersionCreation
    );
  }

  constructor(
    public readonly suiteTypes: Suite[],
    public readonly sectionGroupTypes: SectionGroupType[],
    public readonly sectionTypes: SectionType[],
    public readonly clauseGroupTypes: ClauseGroupType[],
    public readonly clauseTypes: ClauseType[],
    public readonly mode: ValidationMode,
    public severity: ValidationSeverity,
    public operation: ValidationOperation,
    public title: string,
    public reference: string,
    public message: string,
    public readonly patterns: string[],
    public conditions: ValidationCondition[] = [],
    public blockVersionCreation: Map<Suite, VersionTagType[]> = new Map()
  ) {
    this.regexpPatterns = patterns.map((str) => new RegExp(str));
  }

  /**
   * Serialise this rule to JSON for transmission to the service.
   *
   * @returns {any}   The JSON object
   */
  public serialise(): any {
    const json: any = Object.assign({}, this);

    json.mode = ValidationMode[json.mode];
    json.severity = ValidationSeverity[json.severity];
    json.operation = ValidationOperation[json.operation];

    return json;
  }

  /**
   * Returns true if this rule applies to a given fragment, based on the rule's section
   * and clause types.
   *
   * @param fragment {Fragment}   The fragment to query
   * @returns        {boolean}    True if applicable
   */
  public appliesTo(fragment: Fragment): boolean {
    const clause: ClauseFragment = fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    if (!clause) {
      return false;
    }

    const clauseGroup: ClauseGroupFragment = clause.findAncestorWithType(
      FragmentType.CLAUSE_GROUP
    ) as ClauseGroupFragment;
    const clauseGroupType: ClauseGroupType = !!clauseGroup ? clauseGroup.clauseGroupType : null;

    const section: SectionFragment = clause.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
    if (!section) {
      return false;
    }

    const sectionGroup: SectionGroupFragment = section.findAncestorWithType(
      FragmentType.SECTION_GROUP
    ) as SectionGroupFragment;
    const sectionGroupType: SectionGroupType = !!sectionGroup ? sectionGroup.sectionGroupType : null;

    const document: DocumentFragment = section.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment;
    if (!document) {
      return false;
    }

    const suiteMatches: boolean = this.suiteTypes.length === 0 || this.suiteTypes.includes(document.suite);
    const sectionMatches: boolean = this.sectionTypes.length === 0 || this.sectionTypes.includes(section.sectionType);
    const clauseGroupMatches: boolean =
      this.clauseGroupTypes.length === 0 || this.clauseGroupTypes.includes(clauseGroupType);
    const sectionGroupMatches: boolean =
      this.sectionGroupTypes.length === 0 || this.sectionGroupTypes.includes(sectionGroupType);
    const clauseMatches: boolean = this.clauseTypes.length === 0 || this.clauseTypes.includes(clause.clauseType);

    return suiteMatches && sectionMatches && clauseGroupMatches && sectionGroupMatches && clauseMatches;
  }

  /**
   * Apply this rule to a given fragment, returning a list of violations. Note that the deprecatedSFRTypes must be
   * passed in as it has dependencies on the ClauseGroupService which cannot be used in this class.
   *
   * @param fragment           {Fragment}                   The fragment to validate
   * @param deprecatedSFRTypes {StandardFormatType[]}       A list of deprecated SFR types to check against
   * @param deprecatedSITypes  {SpecifierInstructionType[]} A list of deprecated SI types to check against
   * @returns                  {ValidationError[]}          The violations
   */
  public validate(
    fragment: Fragment,
    deprecatedSFRTypes?: StandardFormatType[],
    deprecatedSITypes?: SpecifierInstructionType[]
  ): ValidationError[] {
    const results: ValidationError[] = [];

    switch (this.mode) {
      case ValidationMode.TEXT:
        {
          if (this.conditionsApply(fragment)) {
            const value: string = this._extractFragmentValues(fragment);
            const matches: string[] = [];

            for (let i: number = 0; i < this.patterns.length; ++i) {
              const pattern: RegExp = this._compilePattern(this.patterns[i], true);
              const result = value.match(pattern);
              if (result != null) {
                matches.push(...result);
              }
            }
            results.push(...this._resolveOperation(matches));
          }
        }
        break;

      case ValidationMode.ELEMENT:
        {
          if (this.conditionsApply(fragment)) {
            const fragTypes: string[] = this._extractFragmentTypes(fragment);
            const matches: string[] = [];

            for (let i: number = 0; i < this.patterns.length; ++i) {
              const fragType: string = this.patterns[i].toUpperCase();
              if (fragType && fragTypes.indexOf(fragType) >= 0) {
                matches.push(fragType);
              }
            }

            results.push(...this._resolveOperation(matches));
          }
        }
        break;

      case ValidationMode.CLAUSE_TYPE_ORDER:
        {
          if (this.conditionsApply(fragment)) {
            const currentClause: ClauseFragment = fragment as ClauseFragment;
            const valid: boolean = this._validatePreviousClauseOrder(currentClause);
            if (!valid) {
              results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
            }
          }
        }
        break;

      case ValidationMode.DELETED_REFERENCE:
        {
          if (this.conditionsApply(fragment)) {
            const currentClause: ClauseFragment = fragment as ClauseFragment;
            const inlineRefs: InlineReferenceFragment[] = this._extractInlineReferences(currentClause);
            if (inlineRefs.find((ref: InlineReferenceFragment) => ref.globalReferenceDeleted)) {
              results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
            }
          }
        }
        break;

      case ValidationMode.REQUIRED_FIELD:
        {
          const matches: string[] = [];

          this.patterns.forEach((pattern: string) => {
            if (this._isMissingRequiredField(fragment, pattern)) {
              matches.push(pattern);
            }
          });

          results.push(...this._resolveOperation(matches));
        }
        break;

      case ValidationMode.REQUIRED_FIELD_VALUE:
        {
          const matches: string[] = [];
          this.patterns.forEach((pattern: string) => {
            if (this._fieldDoesNotMatchValue(fragment, pattern)) {
              matches.push(pattern);
            }
          });

          results.push(...this._resolveOperation(matches));
        }
        break;

      case ValidationMode.REQUIRED_NUMERICAL_VALUE_SIZE:
        {
          const parser: RequiredValuePatternParser = new RequiredValuePatternParser();
          const matches: string[] = [];
          this.patterns.forEach((pattern: string) => {
            if (this._fieldHasFragmentWithValidNumericalValue(fragment, parser.parsePattern(pattern))) {
              matches.push(pattern);
            }
          });

          results.push(...this._resolveOperation(matches));
        }
        break;

      case ValidationMode.REQUIRED_NON_ZERO_LENGTH:
        {
          if (this._isMissingRequiredNonZeroLength(fragment)) {
            results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
          }
        }
        break;

      case ValidationMode.WITHDRAWN_REFERENCE_WITH_YEAR_OF_ISSUE:
        {
          if (this.conditionsApply(fragment)) {
            const currentClause: ClauseFragment = fragment as ClauseFragment;
            const inlineRefs: InlineReferenceFragment[] = this._extractInlineReferences(currentClause);
            if (
              inlineRefs.find((ref: InlineReferenceFragment) => ref.globalReferenceWithdrawnWithYearOfIssue === true)
            ) {
              results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
            }
          }
        }
        break;

      case ValidationMode.WITHDRAWN_REFERENCE_WITHOUT_YEAR_OF_ISSUE:
        {
          if (this.conditionsApply(fragment)) {
            const currentClause: ClauseFragment = fragment as ClauseFragment;
            const inlineRefs: InlineReferenceFragment[] = this._extractInlineReferences(currentClause);
            if (
              inlineRefs.find((ref: InlineReferenceFragment) => ref.globalReferenceWithdrawnWithoutYearOfIssue === true)
            ) {
              results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
            }
          }
        }
        break;

      case ValidationMode.DEPRECATED_STANDARD_FORMAT_TYPE:
        {
          const currentClause: ClauseFragment = fragment as ClauseFragment;
          if (!!currentClause.standardFormatType && deprecatedSFRTypes.includes(currentClause.standardFormatType)) {
            results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
          }
        }
        break;

      case ValidationMode.DEPRECATED_SPECIFIER_INSTRUCTION_TYPE:
        {
          const currentClause: ClauseFragment = fragment as ClauseFragment;
          if (
            currentClause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) &&
            deprecatedSITypes.includes(currentClause.specifierInstructionType)
          ) {
            results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
          }
        }
        break;

      case ValidationMode.INTERNAL_REFERENCE_TARGET_DELETED:
        {
          const currentClause: ClauseFragment = fragment as ClauseFragment;
          const inlineSectionRefs: InternalInlineReferenceFragment[] = this._extractFragmentsOfType(
            currentClause,
            FragmentType.INTERNAL_INLINE_REFERENCE
          ) as InternalInlineReferenceFragment[];

          if (!!inlineSectionRefs.length) {
            const document = currentClause.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment;
            const refSection = document.getNormReferenceSection();
            if (!refSection || !refSection.hasChildren() || !refSection.children[0].hasChildren()) {
              break;
            }
            const internalDocumentRefs = refSection.children[0].children
              .filter((internalDocumentRef: Fragment) =>
                internalDocumentRef.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE)
              )
              .filter(this.conditionsApply.bind(this)) as InternalDocumentReferenceFragment[];

            const anyTargetFragmentDeleted = inlineSectionRefs.some((inline: InternalInlineReferenceFragment) => {
              const docRef = internalDocumentRefs.find((ref: InternalDocumentReferenceFragment) =>
                ref.id.equals(inline.internalDocumentReferenceId)
              );
              return (docRef as InternalDocumentReferenceFragment)?.targetFragmentDeleted ?? false;
            });
            if (anyTargetFragmentDeleted) {
              results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
            }
          }
        }
        break;

      case ValidationMode.REFERENCE_INPUT_HAS_REQUIRED_REFERENCE_COUNT:
        {
          const referenceInputs: ReferenceInputFragment[] = this._extractFragmentsOfType(
            fragment,
            FragmentType.REFERENCE_INPUT
          ).filter(this.conditionsApply.bind(this)) as ReferenceInputFragment[];

          if (!referenceInputs.every((f: ReferenceInputFragment) => f.hasRequiredReferenceCount())) {
            results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
          }
        }
        break;

      case ValidationMode.FIELD_DEFINITION_SI_NOT_IN_WSR_SCHEDULE_SFR:
        {
          const currentClause: ClauseFragment = fragment as ClauseFragment;
          const currentClauseGroup: ClauseGroupFragment = fragment.findAncestorWithType(
            FragmentType.CLAUSE_GROUP
          ) as ClauseGroupFragment;

          if (
            FieldDefinitionSpecifierInstructionTypes.has(currentClause.specifierInstructionType) &&
            !WSRScheduleStandardFormatTypes.has(currentClauseGroup?.standardFormatType)
          ) {
            results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
          }
        }
        break;

      case ValidationMode.NON_FIELD_DEFINITION_SI_IN_WSR_SCHEDULE_SFR:
        {
          const currentClause: ClauseFragment = fragment as ClauseFragment;
          const currentClauseGroup: ClauseGroupFragment = fragment.findAncestorWithType(
            FragmentType.CLAUSE_GROUP
          ) as ClauseGroupFragment;

          if (
            currentClause.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) &&
            !FieldDefinitionSpecifierInstructionTypes.has(currentClause.specifierInstructionType) &&
            WSRScheduleStandardFormatTypes.has(currentClauseGroup?.standardFormatType)
          ) {
            results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
          }
        }
        break;

      case ValidationMode.UNIT_INPUT_WITHOUT_SELECTED_UNIT:
        {
          const unitInputs: UnitInputFragment[] = this._extractFragmentsOfType(
            fragment,
            FragmentType.UNIT_INPUT
          ).filter(this.conditionsApply.bind(this)) as UnitInputFragment[];
          if (!unitInputs.every((f: UnitInputFragment) => !!f.unitId && !!f.unitEquationSource)) {
            results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
          }
        }
        break;

      case ValidationMode.EQUATION_TEXT:
        {
          const equations: EquationFragment[] = this._extractFragmentsOfType(
            fragment,
            FragmentType.EQUATION
          ) as EquationFragment[];

          if (equations.some((equation) => this._checkEquationForPatternNotInEscapedText(equation))) {
            results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
          }
        }

        break;

      default:
        {
          Logger.error('validation-error', `Skipping rule with unknown ValidationMode '${this.mode}'.`);
        }
        break;
    }

    return results;
  }

  private _isMissingRequiredField(fragment: Fragment, pattern: string): boolean {
    const fieldPath: string[] = pattern.split('.');
    let isMissing: boolean = false;

    fragment.iterateDown(null, null, (f: Fragment) => {
      if (this.conditionsApply(f)) {
        let field: any = f;
        for (const pathSegment of fieldPath) {
          field = field[pathSegment];
          if (this._fieldIsFalsyOrEmpty(field)) {
            isMissing = true;
            return true;
          }
        }
      }
    });
    return isMissing;
  }

  /**
   * Checks if the given fragment or any of its descendants match the rule conditions and
   * do not have the required value at the field path specified.
   * The pattern defines the path and value using the format: fieldPath:value or field.path:value.
   * For example: caption.value:expectedValue
   */
  private _fieldDoesNotMatchValue(fragment: Fragment, pattern: string): boolean {
    const keyValue: string[] = pattern.split(':');

    // Retrieving the field path from the pattern.
    const fieldPath: string[] = keyValue[0].split('.');

    // Retrieving the value from the pattern.
    const value: string = keyValue[1];

    let hasNonMatchingValue: boolean = false;

    fragment.iterateDown(null, null, (f: Fragment) => {
      if (this.conditionsApply(f)) {
        let field: any = f;
        for (const pathSegment of fieldPath) {
          field = field[pathSegment];
          if (this._fieldIsFalsyOrEmpty(field)) {
            hasNonMatchingValue = true;
            return hasNonMatchingValue;
          }
        }
        if (field !== value) {
          hasNonMatchingValue = true;
        }
      }
    });
    return hasNonMatchingValue;
  }

  private _fieldHasFragmentWithValidNumericalValue(
    fragment: Fragment,
    parsedPatterns: ParsedRequiredValuePattern[]
  ): boolean {
    let hasFragmentWithValidValue: boolean = false;
    fragment.iterateDown(null, null, (f: Fragment) => {
      if (this.conditionsApply(f) && parsedPatterns.every((p: ParsedRequiredValuePattern) => p.fieldHasValidValue(f))) {
        hasFragmentWithValidValue = true;
        return hasFragmentWithValidValue;
      }
    });
    return hasFragmentWithValidValue;
  }

  private _isMissingRequiredNonZeroLength(fragment: Fragment): boolean {
    let isMissing: boolean = false;

    fragment.iterateDown(null, null, (f: Fragment) => {
      if (this.conditionsApply(f)) {
        if (f.length() === 0) {
          isMissing = true;
          return true;
        }
      }
    });

    return isMissing;
  }

  private _fieldIsFalsyOrEmpty(field: any): boolean {
    if (!field) {
      return true;
    } else if (typeof field === 'string') {
      return field.replace(/\s|\u200b/g, '').length === 0;
    } else {
      return false;
    }
  }

  private conditionsApply(fragment: Fragment): boolean {
    let applies: boolean = true;
    this.conditions.forEach((condition: ValidationCondition) => {
      let value = condition.value;
      if (condition.field === 'type') {
        value = FragmentType[value];
      }
      const field: any = fragment[condition.field];
      if (field !== value) {
        applies = false;
      }
    });
    return applies;
  }

  /**
   * This method checks equation text for the specified pattern, and excludes
   * anywhere we are escaped within either text(...) or quotes "..."
   */
  private _checkEquationForPatternNotInEscapedText(fragment: EquationFragment): boolean {
    const value: string = fragment.source.value;
    let result: string = '';
    const replaceText: string = '{ESCAPED_STRING}';

    let textStart: string = null;
    for (let i = 0; i < value.length; i++) {
      if (!!textStart) {
        if ((textStart === 'text(' && value[i] === ')') || (textStart === '"' && value[i] === '"')) {
          textStart = null;
          result += replaceText;
        }
      } else if (value[i] === '"') {
        textStart = '"';
      } else if (value.substring(i).replace(/ /g, '').startsWith('text(')) {
        textStart = 'text(';
      } else {
        result += value[i];
      }
    }

    for (let i = 0; i < this.patterns.length; i++) {
      const pattern: RegExp = this._compilePattern(this.patterns[i], false);
      if (result.match(pattern)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Helper function to recursively extract all inline references from a fragment and it's children.
   *
   * @param fragment  {Fragment}                  The fragment to extract from
   * @returns         {InlineReferenceFragment[]} The found inline referneces
   */
  private _extractInlineReferences(fragment: Fragment): InlineReferenceFragment[] {
    return this._extractFragmentsOfType(fragment, FragmentType.INLINE_REFERENCE) as InlineReferenceFragment[];
  }

  /**
   * Helper function to recursively extract all fragments of a given type from a fragment and it's children.
   *
   * @param fragment  {Fragment}   The fragment to extract from
   * @returns         {Fragment[]} The found fragments
   */
  private _extractFragmentsOfType(fragment: Fragment, type: FragmentType): Fragment[] {
    const results: Fragment[] = [];
    fragment.children.forEach((child: Fragment) => {
      if (child.is(type)) {
        results.push(child);
      } else {
        results.push(...this._extractFragmentsOfType(child, type));
      }
    });
    return results;
  }

  /**
   * Checks the order in which previous clauses appear and returns a boolean
   * depending on whether this order of ClauseTypes is permitted
   *
   * The order that previous clause types must follow should be submitted as an ordered list
   * in the 'patterns' attribute of rules. Not all types in the list (except the last) have to be present earlier
   * in the section for validity, but if they do, they should appear in the submitted order.
   *
   * @param clause   {ClauseFragment}   The clause to validate
   * @returns        {boolean}          True if valid
   */
  private _validatePreviousClauseOrder(clause: ClauseFragment): boolean {
    if (clause.index() === 0) {
      return false; // If the clause is first in a section by default the rule must have been violated
    } else {
      // Keep track of the index of the closest previous sibling of each clause type supplied in patterns
      const lastOccurences: number[] = [];
      let lastTypeMatch: boolean = false;
      for (let i: number = 0; i < this.patterns.length; ++i) {
        const clauseType: ClauseType = ClauseType[this.patterns[i].toUpperCase()];
        const clauseGroupType: ClauseGroupType = ClauseGroupType[this.patterns[i].toUpperCase()];
        for (let j: number = clause.index() - 1; j >= 0; --j) {
          const sibling: Fragment = clause.parent.children[j];
          if (sibling.isClauseOfType(clauseType) || isClauseGroupOfType(sibling, clauseGroupType)) {
            lastOccurences.push(j);
            if (i === this.patterns.length - 1) {
              lastTypeMatch = true; // The last type supplied in patterns must always be matched by a previous sibling
            }
            break;
          }
        }
      }
      if (!lastTypeMatch) {
        return false;
      } else {
        for (let i: number = 0; i < lastOccurences.length - 1; i++) {
          if (lastOccurences[i] > lastOccurences[i + 1]) {
            return false;
          }
        }
        return true;
      }
    }
  }

  /**
   * Helper function to recursively extract the text content from a fragment and its
   * children.  This happens in a depth-first order, which should match the left-to-
   * right reading order of the fragments.
   *
   * Adds special placeholders for inline objects to avoid empty space at beginning of clause rule. See CARS-832.
   *
   * @param fragment {Fragment}   The fragment to extract from
   * @returns        {string[]}   An array of values
   */
  private _extractFragmentValues(fragment: Fragment): string {
    const values: string[] = [];

    fragment.iterateDown(null, null, (frag: Fragment) => {
      if (frag.hasValue() && this._isValidTextBasedFragment(frag)) {
        values.push(frag.value);
      }

      if (frag.is(FragmentType.INLINE_REFERENCE, FragmentType.INTERNAL_INLINE_REFERENCE)) {
        values.push('REFERENCE');
      } else if (frag.is(FragmentType.EQUATION)) {
        values.push('EQUATION');
      }
    });

    return values.join(' ');
  }

  /**
   * Checks if the given fragment should have its value extracted, either if it is a text based fragment or a readonly
   * fragment.
   *
   * @param fragment {Fragment} The Fragment to check
   * @returns        {boolean}  True if this fragment is valid to extract
   */
  private _isValidTextBasedFragment(fragment: Fragment): boolean {
    const VALID_FRAGMENT_TYPES: FragmentType[] = [...EDITABLE_TEXT_FRAGMENT_TYPES, FragmentType.READONLY];
    return fragment.is(...VALID_FRAGMENT_TYPES);
  }

  /**
   * Helper function to recursively extract a list of FragmentTypes contained within a
   * given fragment, returned as an array of stringified names.  No attempt is made to
   * remove duplicates.
   *
   * @param fragment {Fragment}   The fragment to extract from
   * @returns        {string[]}   An array of fragment types
   */
  private _extractFragmentTypes(fragment: Fragment): string[] {
    const result: string[] = [FragmentType[fragment.type]];

    fragment.children.forEach((child: Fragment) => {
      result.push(...this._extractFragmentTypes(child));
    });

    return result;
  }

  /**
   * Resolve the result of applying this rule's logical operation to an array of matches.
   * Returns false if matches has zero length, or the operation is unrecognised.
   *
   * @param matches   {string[]}    The matches to operate on
   * @returns         {boolean}     The result of operation
   */
  private _resolveOperation(matches: string[]): ValidationError[] {
    const results: ValidationError[] = [];
    let failed: boolean = false;

    switch (this.operation) {
      // Logical OR: No matches is a violation
      case ValidationOperation.ANY:
        {
          failed = matches.length === 0;
        }
        break;

      // Logical AND: All the patterns must match
      case ValidationOperation.ALL:
        {
          failed = matches.length < this.patterns.length;
        }
        break;

      // Logical XOR: Exactly one of the patterns must match
      case ValidationOperation.LTE_ONE:
        {
          failed = matches.length > 1;
        }
        break;

      // Logical NOR: Exactly zero of the patterns may match
      case ValidationOperation.NONE:
        {
          failed = matches.length > 0;
        }
        break;

      default:
        {
          Logger.error(
            'validation-error',
            `Skipping resolution of rule with unknown ValidationOperation '${this.operation}'.`
          );
        }
        break;
    }

    if (failed) {
      results.push(new ValidationError(this.severity, this.title, this.reference, this.message));
    }

    return results;
  }

  /**
   * Compile a string pattern into a regexp, allowing for * and ? wildcard support.
   *
   * @param pattern {string}   The pattern to compile
   * @returns       {RegExp}   The resulting regexp
   */
  private _compilePattern(pattern: string, caseInsensitive: boolean): RegExp {
    // IMPORTANT: The 'g' flag is vital here!
    return caseInsensitive ? new RegExp(pattern, 'igm') : new RegExp(pattern, 'gm');
  }
}
