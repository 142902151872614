import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ClauseStylingDirective} from './clause-styling.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ClauseStylingDirective],
  exports: [ClauseStylingDirective],
})
export class ClauseStylingModule {}
