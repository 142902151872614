import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {Suite} from 'app/fragment/suite';
import {SpecifierInstructionType} from 'app/fragment/types/specifier-instruction-type';
import {StandardFormatType} from 'app/fragment/types/standard-format-type';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {ClauseGroupService} from 'app/services/clause-group.service';
import {FragmentService} from 'app/services/fragment.service';
import {SpecifierInstructionService} from 'app/services/specifier-instruction.service';
import {Callback, Dictionary} from 'app/utils/typedefs';
import {UUID} from 'app/utils/uuid';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {
  ClauseFragment,
  DocumentFragment,
  Fragment,
  FragmentType,
  SectionFragment,
  SectionType,
} from '../fragment/types';
import {ValidationError, ValidationMode, ValidationRule, ValidationSeverity} from './validation-rule';

@Injectable({
  providedIn: 'root',
})
export class ValidationService implements OnDestroy {
  private _rules: BehaviorSubject<ValidationRule[]> = new BehaviorSubject(null);
  private _fetched: boolean = false;
  private _timeout: any = null;
  private _requireValidation: Dictionary<ClauseFragment> = {};
  private _validationSubscriptions: Dictionary<Dictionary<Callback<ValidationError[]>>> = {};
  private _subscriptionCounter: number = 0;
  private _subscriptions: Subscription[] = [];

  /**
   * Get the maximum severity level of an array of errors.  Returns ValidationSeverity.INFO
   * if given an empty array.
   *
   * @param errors {ValidationError[]}    The errors
   * @returns      {ValidationSeverity}   The maximum severity
   */
  public static getMaxSeverityOf(errors: ValidationError[]): ValidationSeverity {
    const severities: ValidationSeverity[] = errors.map((error: ValidationError) => error.severity);

    // The ValidationSeverity enum is required to be in order
    return severities.length > 0 ? Math.max(...severities) : ValidationSeverity.INFO;
  }

  constructor(
    private http: HttpClient,
    private _fragmentService: FragmentService,
    private _clauseGroupService: ClauseGroupService,
    private _specifierInstructionService: SpecifierInstructionService
  ) {
    this.updateRules();

    this._subscriptions.push(
      this._fragmentService.onUpdate((fragment: Fragment) => this.onFragmentUpdated(fragment)),
      this._rules.subscribe(() => this.onRulesUpdated()),
      this._clauseGroupService.onStandardFormatGroupTemplatesLoad().subscribe(() => this.onRulesUpdated()),
      this._specifierInstructionService.onSpecifierInstructionTemplatesLoad().subscribe(() => this.onRulesUpdated())
    );
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy() {
    this._subscriptions.splice(0).forEach((sub) => sub.unsubscribe());
  }

  /**
   * Subscribe to changes on a specific clause fragment UUID
   * @param uuid The UUID of the Clause Fragment
   * @param callback Callback method when this subscription is fired
   * @returns Subscription
   */
  public onValidation(uuid: UUID, callback: Callback<ValidationError[]>): Subscription {
    const sub: Subscription = new Subscription();
    const id: number = ++this._subscriptionCounter;

    // Add the clause to the lookup
    if (!this._validationSubscriptions.hasOwnProperty(uuid.value)) {
      this._validationSubscriptions[uuid.value] = {};
    }

    this._validationSubscriptions[uuid.value][id] = callback;

    // Add the unsubscription
    sub.add(() => {
      if (this._validationSubscriptions.hasOwnProperty(uuid.value)) {
        if (this._validationSubscriptions[uuid.value].hasOwnProperty(id)) {
          delete this._validationSubscriptions[uuid.value][id];
        }

        if (Object.keys(this._validationSubscriptions[uuid.value]).length === 0) {
          delete this._validationSubscriptions[uuid.value];
        }
      }
    });

    // Update validations for this clause
    const clauseFragment: ClauseFragment = this._fragmentService.find(uuid) as ClauseFragment;
    if (clauseFragment) {
      this.onFragmentUpdated(clauseFragment);
    }

    return sub;
  }

  /**
   * Fetch the validation rules from the webservice, returning it as an observable
   * and emitting it to the _rules subject.
   *
   * @returns {Observable<ValidationRule[]>}   The result observable
   */
  public updateRules(): Observable<ValidationRule[]> {
    if (!this._fetched) {
      this._fetched = true;

      this.http
        .get(`${environment.apiHost}/validation/rules`)
        .pipe(
          map((response: any) => {
            return response.map((rule: any) => ValidationRule.deserialise(rule));
          })
        )
        .subscribe((rules: ValidationRule[]) => {
          this._rules.next(rules);
        });
    }

    return this._rules.asObservable().pipe(filter((rules: ValidationRule[]) => !!rules));
  }

  /**
   * Validate a clause, returning an array of violations.  Only rules which apply to the
   * clause are considered.  If a minimum severity threshold is given, only rules at or
   * above that severity are applied; this severity defaults to minimum.
   *
   * @param clause       {ClauseFragment}       The clause to validate
   * @param? minSeverity {ValidationSeverity}   An optional minimum severity
   * @returns            {ValidationError[]}    The violations
   */
  public validateClause(clause: ClauseFragment, minSeverity?: ValidationSeverity): ValidationError[] {
    // If not set, default to informational level validation
    minSeverity = minSeverity === void 0 ? ValidationSeverity.INFO : minSeverity;

    const rules: ValidationRule[] = (this._rules.getValue() || [])
      .filter((rule: ValidationRule) => rule.severity >= minSeverity)
      .filter((rule: ValidationRule) => rule.appliesTo(clause));

    return this._validate(rules, clause);
  }

  /**
   * Validate a section, returning an array of violations.  Only rules which apply to the
   * clauses are considered.  If a minimum severity threshold is given, only rules at or
   * above that severity are applied; this severity defaults to minimum.
   *
   * @param  section     {SectionFragment}      The section to validate
   * @param? minSeverity {ValidationSeverity}   An optional minimum severity
   * @returns            {ValidationError[]}    The violations
   */
  public validateSection(section: SectionFragment, minSeverity?: ValidationSeverity): ValidationError[] {
    const errors: ValidationError[] = [];
    section.getClauses().forEach((clause: ClauseFragment) => errors.push(...this.validateClause(clause, minSeverity)));
    return errors;
  }

  /**
   * Refreshes each of the clauses that are being tracked by the validation service, used if the rules update (e.g. due
   * to slow a network) or if the standard format group config was not loaded when the validation was initially run.
   */
  private onRulesUpdated(): void {
    Object.keys(this._validationSubscriptions).forEach((id: string) => {
      if (!this._requireValidation.hasOwnProperty(id)) {
        const clause: ClauseFragment = this._fragmentService.find(UUID.orNull(id)) as ClauseFragment;
        if (clause) {
          this._requireValidation[id] = clause;
        }
      }
    });
    this.onValidateClauseTimeout();
  }

  public reValidate(fragment: Fragment): void {
    this.onFragmentUpdated(fragment);
  }

  /**
   * When a fragment is updated with this look up the clause the fragment belongs to
   * and schedule the clause for a validation update.
   * @param fragment The fragment which was updated
   */
  private onFragmentUpdated(fragment: Fragment) {
    let clauseFragment: ClauseFragment;

    if (fragment.is(FragmentType.CLAUSE)) {
      clauseFragment = fragment as ClauseFragment;

      if (clauseFragment) {
        let nextSibling: Fragment = clauseFragment.nextSibling();
        while (nextSibling) {
          if (nextSibling.is(FragmentType.CLAUSE)) {
            if (!this._requireValidation.hasOwnProperty(nextSibling.id.value)) {
              this._requireValidation[nextSibling.id.value] = nextSibling as ClauseFragment;
            }
          }
          nextSibling = nextSibling.nextSibling();
        }
      }
    } else {
      clauseFragment = fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    }

    if (clauseFragment) {
      if (!this._requireValidation.hasOwnProperty(clauseFragment.id.value)) {
        this._requireValidation[clauseFragment.id.value] = clauseFragment;
      }

      if (!this._timeout) {
        this._timeout = setTimeout(() => this.onValidateClauseTimeout(), environment.validationInterval);
      }
    }
  }

  /**
   * Handler for the timeout started in the onFragmentUpdated to validate pending clauses
   */
  private onValidateClauseTimeout(): void {
    const clauses: ClauseFragment[] = Object.values(this._requireValidation);
    this._timeout = null;
    this._requireValidation = {};

    clauses.forEach((clause: ClauseFragment) => {
      if (!this._fragmentService.find(clause.id)) {
        return; // No longer exists
      }

      if (this._validationSubscriptions.hasOwnProperty(clause.id.value)) {
        const validationErrors: ValidationError[] = this.validateClause(clause);
        Object.values(this._validationSubscriptions[clause.id.value]).forEach((callback) => {
          callback(validationErrors);
        });
      }
    });
  }

  /**
   * Helper function to validate a fragment against a given set of rules.
   *
   * @param rules    {ValidationRule[]}     The validation rules
   * @param fragment {Fragment}             The fragment to validate
   * @returns        {ValidationError[]}   An array of  violations
   */
  private _validate(rules: ValidationRule[], fragment: Fragment): ValidationError[] {
    const results: Map<string, ValidationError> = new Map();

    // ValidationRule::validate() recurses into the fragment tree,
    // so no need to do it here
    for (const rule of rules) {
      switch (rule.mode) {
        case ValidationMode.DEPRECATED_STANDARD_FORMAT_TYPE:
          const deprecatedSFRTypes: StandardFormatType[] = this._clauseGroupService.getDeprecatedStandardFormatTypes();
          rule.validate(fragment, deprecatedSFRTypes).forEach((error: ValidationError) => {
            results.set(error.title, error);
          });
          break;
        case ValidationMode.DEPRECATED_SPECIFIER_INSTRUCTION_TYPE:
          const deprecatedSITypes: SpecifierInstructionType[] =
            this._specifierInstructionService.getDeprecatedSpecifierInstructionTypes();
          rule.validate(fragment, null, deprecatedSITypes).forEach((error: ValidationError) => {
            results.set(error.title, error);
          });
          break;
        default:
          rule.validate(fragment).forEach((error: ValidationError) => {
            results.set(error.title, error);
          });
      }
    }
    return Array.from(results.values());
  }

  /**
   * Filters validation rules for rules that have blocking errors corresponding to the selected version type
   * and the suite of the document. Then validates document over those rules.
   *
   * @param document       {DocumentFragment}     The document to validate
   * @param suite          {Suite}                The document suite
   * @param versionTagType {VersionTagType}       The version type selected
   * @returns              {ValidationError[]}    An array of  violations
   */
  public validateDocumentForVersionCreation(
    document: DocumentFragment,
    filteredRules: ValidationRule[]
  ): ValidationError[] {
    const errors: ValidationError[] = [];
    document
      .getSections()
      .filter(
        (section: SectionFragment) =>
          section.isSectionOfType(SectionType.REFERENCE_NORM, SectionType.REFERENCE_INFORM) ||
          (!section.deleted && !section.isSectionOfType(SectionType.DOCUMENT_INFORMATION))
      )
      .forEach((section) => {
        section.getClauses().forEach((clause) => {
          const rules: ValidationRule[] = filteredRules.filter((rule: ValidationRule) => rule.appliesTo(clause));
          errors.push(...this._validate(rules, clause));
        });
      });
    errors.reduce((distinct: ValidationError[], error: ValidationError) => {
      return distinct.includes(error) ? distinct : [...distinct, error];
    }, []);

    return errors;
  }

  /**
   * Checks if there are any rules for the specified version type and suite, and returns rules if there are any
   *
   * @param versionTagType {VersionTagType}       The version type selected
   * @returns              {boolean}              whether or not there are any rules to validate
   */
  public filterValidationRulesForVersionType(versionTagType: VersionTagType, suite: Suite): ValidationRule[] {
    const rulesForVersionType: ValidationRule[] = this._rules.getValue().filter((rule) => {
      return (
        rule.blockVersionCreation.size > 0 &&
        rule.blockVersionCreation.get(suite)?.find((versionTypes) => versionTypes.includes(versionTagType))
      );
    });

    return rulesForVersionType;
  }
}
