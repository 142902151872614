import {Injectable} from '@angular/core';
import {FragmentDeletionValidationService} from 'app/services/fragment-deletion-validation.service';
import {RichTextType} from 'app/services/rich-text.service';
import {ClauseFragment, ClauseType, Fragment, FragmentType, ListFragment} from '../types';

@Injectable({
  providedIn: 'root',
})
export class CaptionedFragmentTargetClauseService {
  constructor(private _fragmentDeletionValidationService: FragmentDeletionValidationService) {}

  /**
   * If we make a captioned fragment or a list and the current clause is empty, we want to delete the current clause and
   * place the created fragment in the clause above. If the clause above is a standard format requirement
   * or a specifier instruction, then we just want to place the new fragments in the current clause.
   *
   * @param richTextType  {RichTextType}  The rich text event type
   * @param listFragment  {ListFragment}  The list fragment contained in this clause if one exists, else null
   * @param args          {any[]}         Any arguments bundled with the rich text event
   */
  public determineTargetClause(
    clauseContent: ClauseFragment,
    richTextType: RichTextType,
    existingListFragmentInClauseContent: ListFragment,
    args: any[]
  ): ClauseFragment {
    let selectPreviousClause: boolean;

    switch (richTextType) {
      case RichTextType.TABLE:
      case RichTextType.FIGURE:
      case RichTextType.LIST:
        selectPreviousClause = !existingListFragmentInClauseContent;
        break;
      case RichTextType.EQUATION:
        selectPreviousClause = !existingListFragmentInClauseContent && !args[0];
        break;
      default:
        return clauseContent;
    }

    const previousSibling: Fragment = clauseContent.previousSibling();

    return !selectPreviousClause ||
      this._isClauseGroupOrSI(previousSibling) ||
      this._hasCaptionOrCommentaryOrDiscussions(clauseContent) ||
      clauseContent.length() > 0 ||
      clauseContent.parent.is(FragmentType.CLAUSE_GROUP)
      ? clauseContent
      : (previousSibling as ClauseFragment);
  }

  private _hasCaptionOrCommentaryOrDiscussions(clauseContent: ClauseFragment): boolean {
    return (
      this._fragmentDeletionValidationService.fragmentHasBackgroundCommentaryOrDiscussions([clauseContent]) ||
      !!clauseContent.children.find((fragment: Fragment) => fragment.isCaptioned())
    );
  }

  private _isClauseGroupOrSI(fragment: Fragment): boolean {
    return (
      !fragment || fragment.is(FragmentType.CLAUSE_GROUP) || fragment.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)
    );
  }

  /**
   * This adds the given list above any captioned fragments in the clause.
   *
   * @param newList       The new list to add.
   * @param targetClause  The clause to add the list to.
   */
  public addListToClause(newList: ListFragment, targetClause: ClauseFragment): void {
    const firstCaptionIndex: number = targetClause.children.findIndex((child: Fragment) => child.isCaptioned());
    if (firstCaptionIndex > 0) {
      targetClause.children.splice(firstCaptionIndex, 0, newList);
    } else {
      targetClause.children.push(newList);
    }
  }
}
