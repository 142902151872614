import {Injectable} from '@angular/core';
import {SectionType} from 'app/fragment/types';
import {SectionService} from 'app/services/section.service';
import {UUID} from 'app/utils/uuid';
import {BehaviorSubject, Observable} from 'rxjs';
import {SidebarStatus} from '../sidebar/sidebar-status';
import {ReorderClausesService} from './reorder-clauses.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private sidebarStatus: BehaviorSubject<SidebarStatus> = new BehaviorSubject<SidebarStatus>(SidebarStatus.CLOSED);
  private fromToolbar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private refInputId: BehaviorSubject<UUID> = new BehaviorSubject<UUID>(null);
  private isClauseRefSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private reorderClausesService: ReorderClausesService, private sectionService: SectionService) {
    this.reorderClausesService.onReorderingEvent().subscribe((reordering: boolean) => {
      this.setSidebarStatus(reordering ? SidebarStatus.REORDERING : SidebarStatus.CLOSED);
    });
    this.sectionService.onSelection((section) => {
      if (!section || section.isSectionOfType(SectionType.REFERENCE_INFORM, SectionType.REFERENCE_NORM)) {
        this.setSidebarStatus(SidebarStatus.CLOSED);
      }
    });
  }

  public setSidebarStatus(status: SidebarStatus, fromToolbar: boolean = false, refInputId: UUID = null): void {
    this.fromToolbar.next(fromToolbar);
    this.sidebarStatus.next(status);
    this.refInputId.next(refInputId);
    this.isClauseRefSidebar.next(status === SidebarStatus.CLAUSE_REFERENCES);
  }

  public getSidebarStatus(): Observable<SidebarStatus> {
    return this.sidebarStatus.asObservable();
  }

  public getStatus(): SidebarStatus {
    return this.sidebarStatus.value;
  }

  public getFromToolbar(): Observable<boolean> {
    return this.fromToolbar.asObservable();
  }

  public getRefInputId(): Observable<UUID> {
    return this.refInputId.asObservable();
  }

  public getIsClauseRefSidebar(): Observable<boolean> {
    return this.isClauseRefSidebar.asObservable();
  }
}
