import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-version-settings',
  templateUrl: './version-settings.component.html',
  styleUrls: ['./version-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionSettingsComponent implements OnInit, OnDestroy {
  public canUpdateVersionTag: boolean = false;

  public versionTagToUpdate: VersionTag;
  private _currentViewVersionTag: VersionTag;

  private _currentView: CurrentView;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _versioningService: VersioningService,
    private _viewService: ViewService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        this._currentView = currentView;
        this.canUpdateVersionTag = this._currentView.versionTag && this._currentView.userIsAnAuthor();
        this._cdr.markForCheck();
      }),

      this._versioningService.getCurrentViewVersionTagObsStream().subscribe((versionTag: VersionTag) => {
        this._currentViewVersionTag = versionTag;
        this.versionTagToUpdate = Object.assign({}, this._currentViewVersionTag);
        this._cdr.markForCheck();
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.filter((sub) => !!sub).forEach((sub) => sub.unsubscribe());
  }

  /**
   * Called when the availableToReview checkbox is ticked or unticked.  If set to false,
   * sets availableForCommenting and userDiscussionsOnly to false.
   */
  public changeAvailableToReview(versionTag: VersionTag): void {
    if (!versionTag.availableToReview) {
      versionTag.availableForCommenting = false;
      versionTag.userDiscussionsOnly = false;
    }
    this._updateCollaborativeProperties(versionTag);
  }

  /**
   * Called when the availableForCommenting checkbox is ticked or unticked.  If set to true,
   * sets availableToReview to true.  If set to false,  sets userDiscussionsOnly to false.
   */
  public changeAvailableForCommenting(versionTag: VersionTag): void {
    if (versionTag.availableForCommenting) {
      versionTag.availableToReview = true;
    } else {
      versionTag.userDiscussionsOnly = false;
    }
    this._updateCollaborativeProperties(versionTag);
  }

  /**
   * Called when the userDiscussionsOnly checkbox is ticked or unticked.  If set to true,
   * sets availableToReview and availableForCommenting to true too.
   */
  public changeUserDiscussionsOnly(versionTag: VersionTag): void {
    if (versionTag.userDiscussionsOnly) {
      versionTag.availableToReview = true;
      versionTag.availableForCommenting = true;
    }
    this._updateCollaborativeProperties(versionTag);
  }

  private _updateCollaborativeProperties(versionTag: VersionTag): Promise<void> {
    return this._versioningService
      .updateCollaborativeProperties(
        versionTag.versionId,
        versionTag.availableForCommenting,
        versionTag.availableToReview,
        versionTag.userDiscussionsOnly
      )
      .then(() => {
        // This will trigger an input change, so no need to manually update the state:
        this._viewService.setCurrentView(this._currentView.viewMode, versionTag);
      })
      .catch(() => {
        this.versionTagToUpdate = Object.assign({}, this._currentViewVersionTag);
      });
  }
}
