import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CacheManager} from 'app/fragment/cache-manager';
import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {FragmentCache} from 'app/fragment/diff/fragment-cache';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {SectionGroupType} from 'app/fragment/types/section-group-type';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {environment} from 'environments/environment';
import {Observable, Subscription, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CacheEntry} from '../fragment/diff/cache-entry';
import {ClauseFragment, Fragment, FragmentType, SectionFragment, SectionType} from '../fragment/types';
import {Callback, Dictionary} from '../utils/typedefs';
import {UUID} from '../utils/uuid';
import {BreadcrumbService} from './breadcrumb.service';
import {FragmentService} from './fragment.service';

export interface SectionFetchParams {
  projection?: 'ROOT_ONLY' | 'ROOT_AND_CHILDREN' | 'FULL_TREE';
  validAt?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SectionService extends FragmentService<SectionFragment> {
  private _perSectionUndo: Dictionary<CacheEntry<UUID[]>> = {}; // Dictionary from section ID to undo buffer

  constructor(
    protected _websocketService: WebSocketService,
    protected _cacheManager: CacheManager,
    protected _http: HttpClient,
    protected _snackbar: MatSnackBar,
    private _breadcrumbService: BreadcrumbService
  ) {
    super(_websocketService, _cacheManager, _http, _snackbar);

    this.onCreate((section: SectionFragment) => {
      this._updatePreview(section);
    });
    this.onDelete((section: SectionFragment) => {
      this._updatePreview(section);
    });
    this.onUpdate((section: SectionFragment) => {
      if (section.equals(this.getSelected())) {
        this._breadcrumbService.updateBreadcrumb(2, {title: section.title});
      }
      section.getDocument().getSections().forEach(this._updatePreview.bind(this));
    });
    this.onSelection((section: SectionFragment) => {
      if (section) {
        section.getClauses().forEach((child: Fragment) => {
          if (child.is(FragmentType.CLAUSE)) {
            (child as ClauseFragment).calculateClausePreview();
          }
        });
      }
    });

    super.onUpdate(
      (fragment: Fragment) => {
        const sectionGroup: SectionGroupFragment = fragment as SectionGroupFragment;
        sectionGroup.getDocument().getSections().forEach(this._updatePreview.bind(this));
      },
      (f) => f.is(FragmentType.SECTION_GROUP)
    );
  }

  public load(
    id: UUID,
    params: SectionFetchParams,
    addToCache: boolean = true,
    errorSnackbar: string = 'Failed to load the section'
  ): Promise<SectionFragment> {
    const idString: string = id != null ? id.value : null;
    let httpParams: HttpParams = new HttpParams();
    if (params.validAt !== undefined) {
      httpParams = httpParams.append('validAt', params.validAt.toString());
    }
    if (params.projection !== undefined) {
      httpParams = httpParams.append('projection', params.projection.toString());
    }

    const errorHandler = (response: HttpErrorResponse): Promise<never> => {
      this._handleError(response, errorSnackbar, 'fragment-error');
      return Promise.reject(response);
    };

    const cache: FragmentCache = params.validAt
      ? this._cacheManager.getHistoricalCache(params.validAt)
      : this._cacheManager.getLiveCache();

    // If the request is historical, check the relevant cache:
    if (params.validAt && addToCache) {
      const cached: Fragment = cache.find(id) ? cache.find(id).live : null;
      if (cached && (params.projection === 'ROOT_ONLY' || cached.hasChildren())) {
        return Promise.resolve(cached as SectionFragment);
      }
    }

    return this._http
      .get(`${environment.apiHost}/tree/section/${idString}`, {
        params: httpParams,
        observe: 'response',
      })
      .toPromise()
      .then((response: HttpResponse<any>) => {
        const section: SectionFragment = FragmentMapper.deserialise(response.body) as SectionFragment;

        return (addToCache ? cache.insertTree(section) : section) as SectionFragment;
      }, errorHandler)
      .catch(errorHandler);
  }

  /**
   * Helper function to update the preview for all clauses in a section.
   *
   * @param section {SectionFragment}   The section fragment
   */
  private _updatePreview(section: SectionFragment): void {
    for (const clause of section.getClauses()) {
      clause.calculateClausePreview();
      clause.markForCheck();
      clause.markChildrenForCheck((f: Fragment) => f.isCaptioned());
    }
  }

  /**
   * Clears the undo buffer for each section.
   * This should be called every time a user navigates between documents & versions.
   */
  public clearSectionUndoBuffer(): void {
    this._perSectionUndo = {};
  }

  /**
   * Create a new section with the given parameters, returns a promise resolving to the id of the newly created section.
   */
  public createSection(documentId: UUID, title: string, sectionType: SectionType): Promise<UUID> {
    const params: {[param: string]: string} = {
      documentId: documentId.value,
      title: title,
      sectionType: sectionType,
    };
    const message: string =
      'Sorry, something went wrong and we were unable to create a section. Please try again in a moment.';

    return this._http
      .post<UUID>(`${environment.apiHost}/sections/create-section-with-type`, {}, {params})
      .pipe(
        map((response: any) => UUID.orThrow(response)),
        catchError((err) => {
          this._handleError(err, message, 'fragment-error');
          return throwError(null);
        })
      )
      .toPromise();
  }

  /**
   * Create a new section group fragment with the given parameters.
   * Returns a promise resolving to the id of the first section child of the section group.
   *
   * @param documentId {UUID}                   The id of the document to create the section group in
   * @param title {string}                      The initial title of the section group children
   * @param sectionGroupType {SectionGroupType} The type of the section group
   */
  public createSectionGroup(documentId: UUID, title: string, sectionGroupType: SectionGroupType): Promise<UUID> {
    const params: {[param: string]: string} = {
      documentId: documentId.value,
      title: title,
      sectionGroupType: sectionGroupType,
    };
    const message: string =
      'Sorry, something went wrong and we were unable to create a section group. Please try again in a moment.';

    return this._http
      .post<UUID>(`${environment.apiHost}/sections/create-section-group-with-type`, {}, {params})
      .pipe(
        map((response: any) => UUID.orThrow(response)),
        catchError((err) => {
          this._handleError(err, message, 'fragment-error');
          return throwError(null);
        })
      )
      .toPromise();
  }

  /**
   * @override
   */
  public update(sections: SectionFragment | SectionFragment[], message?: string): Promise<HttpResponse<any>> {
    message =
      message || 'Sorry, something went wrong and we were unable to update the section. Please try again in a moment.';
    return super.update(sections, message);
  }

  /**
   * @override
   */
  public delete(sections: SectionFragment | SectionFragment[], message?: string): Promise<HttpResponse<any>> {
    sections = this._toArray(sections);
    sections.forEach((section: SectionFragment) => {
      section.deleted = true;
    });

    message =
      message || 'Sorry, something went wrong and we were unable to delete the section. Please try again in a moment.';
    return this.update(sections, message);
  }

  /**
   * Set the selected section, and swap out the FragmentService's undo buffer for that
   * section's buffer.
   *
   * @override
   */
  public setSelected(section: SectionFragment): void {
    const id: string = section ? section.id.value : null;
    if (!this._perSectionUndo[id]) {
      this._perSectionUndo[id] = new CacheEntry([], FragmentService.UNDO_BUFFER.capacity);
    }

    FragmentService.UNDO_BUFFER = this._perSectionUndo[id];
    super.setSelected(section);
  }

  /**
   * @override
   */
  public onCreate(callback: Callback<SectionFragment>): Subscription {
    return super.onCreate(callback, (f: Fragment) => f.is(FragmentType.SECTION));
  }

  /**
   * @override
   */
  public onUpdate(callback: Callback<SectionFragment>): Subscription {
    return super.onUpdate(callback, (f: Fragment) => f.is(FragmentType.SECTION));
  }

  /**
   * @override
   */
  public onDelete(callback: Callback<SectionFragment>): Subscription {
    return super.onDelete(callback, (f: Fragment) => f.is(FragmentType.SECTION));
  }

  /**
   * @override
   */
  public onSelection(callback: Callback<SectionFragment>): Subscription {
    return super.onSelection(callback, (f: Fragment) => f.is(FragmentType.SECTION));
  }

  /**
   * Returns an observable stream of the currently selected section.
   */
  public getSelectedSectionStream(): Observable<SectionFragment> {
    return this._selectSubject.asObservable();
  }
}
