import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {Suite} from 'app/fragment/suite';
import {ClauseFragment} from 'app/fragment/types';
import {SpecifierInstructionType} from 'app/fragment/types/specifier-instruction-type';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Observable, Subject} from 'rxjs';
import {BaseService} from './base.service';

export class SpecifierInstructionWrapper {
  private specifierInstruction: ClauseFragment;
  private guidanceText: string;
  private isWithinScheduleSFR: boolean;
  private orderingWeight: number;
  private suite: Suite;

  constructor(si: ClauseFragment, guidance: string, withinSchedule: boolean, weight: number, suite: Suite) {
    this.specifierInstruction = si;
    this.guidanceText = guidance;
    this.isWithinScheduleSFR = withinSchedule;
    this.orderingWeight = weight;
    this.suite = suite;
  }

  public getSpecifierInstructionFragment(): ClauseFragment {
    return this.specifierInstruction;
  }

  public getGuidanceText(): string {
    return this.guidanceText;
  }

  public getIsWithinScheduleSFR(): boolean {
    return this.isWithinScheduleSFR;
  }

  public getWeight(): number {
    return this.orderingWeight;
  }

  public getSuite(): Suite {
    return this.suite;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SpecifierInstructionService extends BaseService {
  private readonly _configEndpoint: string = `${environment.apiHost}/specifier-instruction/specifier-instruction-creation-config`;
  private readonly _siSectionsEndpoint: string = `${environment.apiHost}/specifier-instruction/sections-with-specifier-instruction`;

  // We store an object instead of a Fragment as we can't deserialise fragments with a null id, and
  // we don't want to set any default ids in the template fragments as these should get set on fragment creation.
  // Instead we store an object and then set the ids and deserialise when getting the template fragment from the record.
  private _cachedSpecifierInstructionTemplates: Record<SpecifierInstructionType, object>;

  // Subject to track when the SI templates have been loaded so that a single `true` event is emitted when loaded.
  private _specifierInstructionTemplateCacheLoaded: Subject<boolean> = new Subject();

  constructor(protected _snackbar: MatSnackBar, private _http: HttpClient) {
    super(_snackbar);
    this._loadSpecifierInstructionTemplates();
  }

  private _loadSpecifierInstructionTemplates(): void {
    this._http
      .get<Record<SpecifierInstructionType, object>>(this._configEndpoint)
      .toPromise()
      .then((responseMap: Record<SpecifierInstructionType, object>) => {
        this._cachedSpecifierInstructionTemplates = responseMap;
        this._specifierInstructionTemplateCacheLoaded.next(true);
      })
      .catch((error: any) => {
        this._handleError(
          error,
          'Failed to fetch the specifier instruction creation configuration',
          'configuration-error'
        );
        return Promise.reject(error);
      });
  }

  /**
   * Returns a subject that emits a single event when the templates are first loaded. Note that if templates are
   * already loaded when this method is called then no events will be emitted.
   */
  public onSpecifierInstructionTemplatesLoad(): Observable<boolean> {
    return this._specifierInstructionTemplateCacheLoaded.asObservable();
  }

  /**
   * Returns the list of non deprecated specifier instructions that can be created. These are sorted by their config
   * weights and contain guidance text in the background field.
   */
  public getAllSpecifierInstructionFragments(
    isWithinScheduleSFR: boolean,
    suite: Suite
  ): SpecifierInstructionWrapper[] {
    return Object.keys(SpecifierInstructionType)
      .map((type) => this._getSpecifierInstructionOfType(SpecifierInstructionType[type]))
      .filter((f) => !!f && f.getIsWithinScheduleSFR() === isWithinScheduleSFR && f.getSuite() === suite)
      .sort((a, b) => Number(a.getWeight()) - Number(b.getWeight()));
  }

  /**
   * Gets the list of deprecated SI types, defined as those that are not included within the cached template map.
   * Note that if the cache has not loaded yet then this will return an empty list - see onTemplatesLoad to get
   * notified when the load occurs.
   */
  public getDeprecatedSpecifierInstructionTypes(): SpecifierInstructionType[] {
    return Object.keys(SpecifierInstructionType)
      .map((type: SpecifierInstructionType) => type)
      .filter(
        (type) => !!this._cachedSpecifierInstructionTemplates && !this._cachedSpecifierInstructionTemplates[type]
      );
  }

  /**
   * Gets the specifier instruction fragment that should be created, clears out the weight and background value.
   */
  public getSpecifierInstructionToCreate(type: SpecifierInstructionType): ClauseFragment {
    const clause: SpecifierInstructionWrapper = this._getSpecifierInstructionOfType(type);

    if (!clause) {
      return null;
    }

    return clause.getSpecifierInstructionFragment();
  }

  /**
   * Returns a wrapper that contains a copy of the specifier instruction with the given type, as well as the guidance text,
   * ordering and whether the SI is a field definition. The latter three properties are only used within the modal component.
   */
  private _getSpecifierInstructionOfType(type: SpecifierInstructionType): SpecifierInstructionWrapper {
    const fragTemplate: object = this._cachedSpecifierInstructionTemplates[type];
    if (!fragTemplate) {
      return null;
    }
    const template: object = this._copyTemplateAndSetIds(null, fragTemplate['fragmentToCreate']);
    return new SpecifierInstructionWrapper(
      FragmentMapper.deserialise(template) as ClauseFragment,
      fragTemplate['guidanceText'],
      fragTemplate['isFieldDefinition'],
      fragTemplate['orderingWeight'],
      fragTemplate['suite']
    );
  }

  private _copyTemplateAndSetIds(parent: object, fragment: object): object {
    const fragmentCopy: object = Object.assign({}, fragment);
    fragmentCopy['id'] = UUID.random().value;
    if (parent) {
      fragmentCopy['parentId'] = parent['id'];
    }
    fragmentCopy['children'] = Array.from(fragmentCopy['children']).map((child: object) =>
      this._copyTemplateAndSetIds(fragmentCopy, child)
    );
    return fragmentCopy;
  }

  public getSectionsWithSIs(documentId: UUID): Promise<UUID[]> {
    const params = {
      documentId: documentId.value,
    };
    return this._http
      .get<any[]>(this._siSectionsEndpoint, {params})
      .toPromise()
      .then((response) => {
        return response.map((id) => UUID.orNull(id));
      });
  }
}
