import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ViewRef,
} from '@angular/core';
import {MatButtonToggleGroup} from '@angular/material/button-toggle';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CarsRange} from 'app/fragment/cars-range';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {TableCellFragment} from 'app/fragment/table/table-fragment';
import {Suggestion} from 'app/interfaces';
import {CanvasService} from 'app/services/canvas.service';
import {CaretService} from 'app/services/caret.service';
import {DiscussionsService} from 'app/services/discussions.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {
  ClauseFragment,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  Fragment,
  FragmentType,
  SectionFragment,
  TextFragment,
} from '../../fragment/types';
import {CurrentView} from '../../view/current-view';
import {ViewService} from '../../view/view.service';
import {CapacityOfComment, Discussion, DiscussionType} from './discussions';

@Component({
  selector: 'cars-discussions',
  templateUrl: './discussions.component.html',
  styleUrls: ['./discussions.component.scss'],
})
export class DiscussionsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public set selectedFragment(fragment: Fragment) {
    this.updateSelectedContent(fragment);
  }

  @ViewChild('typeGroup') public typeGroup: MatButtonToggleGroup;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly DiscussionType: typeof DiscussionType = DiscussionType;
  public readonly CapacityOfCommentOptions: CapacityOfComment[] = Object.values(CapacityOfComment);

  public clause: ClauseFragment = null;

  public currentView: CurrentView;

  public discussions: Discussion[] = [];

  public displayResolved: boolean = false;
  public displayUnresolved: boolean = true;

  public creatingDiscussion: boolean = false;
  public submittingDiscussion: boolean = false;

  public newDiscussion: string = '';
  public newCapacityOfComment: CapacityOfComment = null;
  private newSuggestion: Suggestion;
  public lastSelected: CarsRange;
  public selectedText: string;
  public suggesting: boolean = false;
  public suggestedText: string;

  public selectableContentOptions: Fragment[] = [];
  public selectableContentIndexes: Record<string, string> = {};

  public selectableSubcontentOptions: Fragment[] = [];
  public selectableSubcontentIndexes: Record<string, string> = {};

  public selectedContent: Fragment;
  public selectedSubcontent: Fragment;

  public discussionErrorMessage: string = 'Text input cannot be empty';

  public capacityOfErrorMessage: string = 'Capacity is required';

  public tabIndex: number = 0;

  private _subscriptions: Subscription[] = [];
  private _fragmentChangeSubscription: Subscription;

  constructor(
    private _fragmentIndexService: FragmentIndexService,
    private _discussionsService: DiscussionsService,
    private _viewService: ViewService,
    private _caretService: CaretService,
    private _sidebarService: SidebarService,
    private _canvasService: CanvasService,
    private _snackBar: MatSnackBar,
    private _cdr: ChangeDetectorRef
  ) {}

  /**
   * Initialises the component.
   */
  public ngOnInit(): void {
    this._subscriptions.push(
      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        this.currentView = currentView;
      }),
      this._sidebarService.getFromToolbar().subscribe((fromToolbar: boolean) => {
        if (fromToolbar) {
          // Need to delay the rendering of the discussion creation elements until angular has finished
          // building the discussions component, or the textarea elements behave unpredictably
          setTimeout(() => {
            this.cancelDiscussionCreation(false);
            this.onCreate();
            this.checkboxToggle();
          }, 200);
        }
      }),
      this._sidebarService.getSidebarStatus().subscribe((status: SidebarStatus) => {
        if (status === SidebarStatus.DISCUSSIONS) {
          this.tabIndex = 0;
        }
      }),
      this._caretService.onSelectionChange((lastSelected: CarsRange) => {
        this.lastSelected = lastSelected;
        this.getSelectedText();
        this._canvasService.setPendingSuggestionHighlight(lastSelected);
      }, this.onCaretSelectionChangePredicate.bind(this))
    );

    this.newCapacityOfComment = this._discussionsService.getMostRecentCapacityOfComment();
  }

  /**
   * Returns true if the new selection is valid to make a suggestion against and the user can make suggestions.
   */
  private onCaretSelectionChangePredicate(selected: CarsRange): boolean {
    return (
      !!selected &&
      selected.isInPad() &&
      selected.isValidSuggestion() &&
      this._sidebarService.getStatus() === SidebarStatus.DISCUSSIONS &&
      this.currentView &&
      this.currentView.userCanSuggestTextChange()
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
    if (this._fragmentChangeSubscription) {
      this._fragmentChangeSubscription.unsubscribe();
    }
    this._canvasService.clearPendingSuggestionHighlight();
  }

  /**
   * Respond to selected fragment changes.
   *
   * @param changes {SimpleChanges}   The changes object
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selectedFragment')) {
      this.cancelDiscussionCreation();
      if (this.selectedContent) {
        if (this._fragmentChangeSubscription) {
          this._fragmentChangeSubscription.unsubscribe();
        }

        this._fragmentChangeSubscription = this._discussionsService.onChange(
          this.selectedContent,
          (discussions: Discussion[]) => {
            this.discussions = discussions;
          },
          this.selectedContent.is(FragmentType.TABLE)
        );

        this._discussionsService.getDiscussions(this.selectedContent).then((discussions: Discussion[]) => {
          this.discussions = this._discussionsService.getCachedDiscussionsForFragment(
            this.selectedContent,
            this.selectedContent.is(FragmentType.TABLE)
          );
        });
      }
    }
    this._cdr.markForCheck();
  }

  get unresolvedDiscussions() {
    return this.discussions.reduce((n, discussion) => (discussion.resolved ? n : n + 1), 0);
  }

  get resolvedDiscussions() {
    return this.discussions.reduce((n, discussion) => (discussion.resolved ? n + 1 : n), 0);
  }

  /**
   * Action the 'start new discussion' button
   */
  public onCreate(): void {
    this.creatingDiscussion = true;
    this.newCapacityOfComment = this._discussionsService.getMostRecentCapacityOfComment();
  }

  /**
   * Create a new discussion for this clause.
   */
  public createDiscussion() {
    if (!this.submittingDiscussion) {
      this.submittingDiscussion = true;

      if (this.suggesting) {
        this._updateSuggestion();
      } else {
        this.newSuggestion = null;
      }

      this._discussionsService
        .createDiscussion(
          this.newDiscussion,
          this.selectedSubcontent || this.selectedContent,
          this.typeGroup.value,
          this.newCapacityOfComment,
          this.newSuggestion
        )
        .then(
          () => {
            this.submittingDiscussion = false;
            this.cancelDiscussionCreation();
          },
          () => {
            // Error management is handled in the createDiscussion, however we need
            // to re-enable the button here
            this.submittingDiscussion = false;
          }
        );
    }
  }

  /**
   * Cancel the discussion creation and close the dialog.
   */
  public cancelDiscussionCreation(clearLastSelected: boolean = true): void {
    this.creatingDiscussion = false;
    this.newDiscussion = '';
    this.newSuggestion = null;
    this.suggestedText = null;
    this.suggesting = false;
    this.selectedText = null;
    if (clearLastSelected) {
      this.lastSelected = null;
    }
  }

  /**
   * Determines whether the selected fragments can be reconstructed into a string.
   * Sets this as the selected text if true.
   *
   * @returns {boolean} If the selection can be reconstructed into a string
   */
  public getSelectedText(): boolean {
    const startFragment: Fragment = this.lastSelected[0].fragment;
    const endFragment: Fragment = this.lastSelected[1].fragment;

    if (!startFragment.parent.equals(endFragment.parent)) {
      this._snackBar.open('Suggestions can not be made across multiple items', 'Dismiss', {duration: 3000});
      return false;
    } else if (startFragment.component && startFragment.component.element.parentElement.localName === 'caption') {
      this._snackBar.open('Suggestions can not be to captions', 'Dismiss', {
        duration: 3000,
      });
      return false;
    } else {
      const startIndex: number = startFragment.index();
      const endIndex: number = endFragment.index();

      const selectedFragments: Fragment[] = startFragment.parent.children.slice(startIndex, endIndex + 1);

      const allowedFragmentTypes: FragmentType[] = [...EDITABLE_TEXT_FRAGMENT_TYPES, FragmentType.ANCHOR];
      const invalidMessage: string =
        selectedFragments.findIndex((fragment: Fragment) => !fragment.is(...allowedFragmentTypes)) >= 0
          ? 'Suggestions can only be made to clause text (not lists, tables, or equations)'
          : null;

      if (invalidMessage) {
        this._snackBar.open(invalidMessage, 'Dismiss', {duration: 3000});
        return false;
      } else {
        this.selectedText = this.reconstructSelectionAsString(selectedFragments);
        return true;
      }
    }
  }

  /**
   * Reconstructs a selection of fragments into a string.
   *
   * @param selectedFragments {Fragment[]} The selected fragments
   * @returns                 {string}     The reconstructed text
   */
  private reconstructSelectionAsString(selectedFragments: Fragment[]): string {
    let selectedText: string = '';
    if (selectedFragments.length === 1) {
      selectedText = this.lastSelected[0].fragment.value.slice(
        this.lastSelected[0].offset,
        this.lastSelected[1].offset
      );
    } else {
      const startFragmentIndex = this.clause.children.indexOf(this.lastSelected[0].fragment);
      const endFragmentIndex = this.clause.children.indexOf(this.lastSelected[1].fragment);

      const completeSelectedFragments: TextFragment[] = this.clause.children.slice(
        startFragmentIndex + 1,
        endFragmentIndex
      );

      const selectedTextOfFirstFragment = this.lastSelected[0].fragment.value.slice(this.lastSelected[0].offset);
      const selectedTextOfLastFragment = this.lastSelected[1].fragment.value.slice(0, this.lastSelected[1].offset);

      selectedText = selectedTextOfFirstFragment;
      completeSelectedFragments.forEach((fragment: TextFragment) => (selectedText += fragment.value));
      selectedText += selectedTextOfLastFragment;
    }
    return selectedText;
  }

  public checkboxToggle(e?: MouseEvent) {
    if (e) {
      e.preventDefault();
    }
    if (this.suggesting) {
      this.suggesting = false;
      this._canvasService.clearPendingSuggestionHighlight();
    } else if (this.lastSelected && this.getSelectedText()) {
      this._canvasService.setPendingSuggestionHighlight(this.lastSelected);
      this.newSuggestion = {
        currentValue: this.selectedText,
        suggestedValue: null,
        startFragmentId: this.lastSelected[0].fragment.id,
        endFragmentId: this.lastSelected[1].fragment.id,
        startOffset: this.lastSelected[0].offset,
        endOffset: this.lastSelected[1].offset,
      };
      this.suggesting = true;
      if (!(this._cdr as ViewRef).destroyed) {
        this._cdr.detectChanges();
      }
    }
  }

  /**
   * Add parent section, clause and child/sibling captioned fragments to
   * selectableContentOptions
   * @param fragments {Fragment} The selected fragment
   */
  private updateSelectedContent(fragment: Fragment): void {
    this.selectableContentOptions = [];
    this.selectableContentIndexes = {};
    this.selectedContent = this.selectedSubcontent = null;

    if (fragment) {
      if (fragment.is(FragmentType.SECTION)) {
        if (!this.clause) {
          this.clause = (fragment as SectionFragment).getClauses()[0];
        }
      } else {
        this.clause = fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
      }

      this.addToSelectableContentOptions(this.clause.getSection());

      this.addToSelectableContentOptions(this.clause);

      this.clause.children.forEach((childFragment) => {
        if (childFragment.isCaptioned()) {
          this.addToSelectableContentOptions(childFragment);
        }
      });

      const commentableAncestor: Fragment = fragment.findAncestor((frag: Fragment) =>
        this.selectableContentOptions.includes(frag)
      );

      if (commentableAncestor) {
        this.selectedContent = commentableAncestor;

        this.onContentChange();
        if (this.subcontentAvailable()) {
          // this assumes the only subcontent will be table cells or the table
          this.selectedSubcontent = fragment.findAncestorWithType(FragmentType.TABLE_CELL, FragmentType.TABLE);
        }
      }
    }
    this._cdr.markForCheck();
  }

  private addToSelectableContentOptions(fragment: Fragment): void {
    this.selectableContentOptions.push(fragment);
    const index: string = this._fragmentIndexService.getIndex(fragment);
    if (fragment) {
      switch (fragment.type) {
        case FragmentType.SECTION: {
          this.selectableContentIndexes[fragment.id.value] = 'Section ' + index;
          break;
        }
        case FragmentType.CLAUSE: {
          this.selectableContentIndexes[fragment.id.value] = 'Clause ' + index;
          break;
        }
        case FragmentType.TABLE: {
          this.selectableContentIndexes[fragment.id.value] = index || 'Table';
          break;
        }
        case FragmentType.FIGURE: {
          this.selectableContentIndexes[fragment.id.value] = index || 'Figure';
          break;
        }
        case FragmentType.EQUATION: {
          this.selectableContentIndexes[fragment.id.value] = index || 'Equation';
          break;
        }
        default: {
          this.selectableContentIndexes[fragment.id.value] = index;
          break;
        }
      }
    } else {
      this.selectableContentIndexes[fragment.id.value] = 'Unknown';
    }
  }

  /**
   * Respond to selected content change events and populate selectedSubcontentOptions.
   */
  public onContentChange(): void {
    this.selectableSubcontentOptions = [];
    this.selectableSubcontentIndexes = {};

    if (this.selectedContent.is(FragmentType.TABLE)) {
      this.selectableSubcontentOptions.push(this.selectedContent);
      this.selectableSubcontentIndexes[this.selectedContent.id.value] = 'Table';

      let rowIndex = 1;
      let cellIndex = 1;

      this.selectedContent.children.forEach((fragment) => {
        if (fragment.is(FragmentType.TABLE_ROW)) {
          fragment.children.forEach((cellFragment) => {
            if (!(cellFragment as TableCellFragment).deleted) {
              this.selectableSubcontentOptions.push(cellFragment);
              this.selectableSubcontentIndexes[cellFragment.id.value] = 'Row ' + rowIndex + ' Column ' + cellIndex;
            }
            cellIndex++;
          });
          rowIndex++;
          cellIndex = 1;
        }
      });
    }

    this.ngOnChanges({selectedFragment: new SimpleChange(this.selectedFragment, this.selectedFragment, false)});
  }

  public subcontentAvailable(): boolean {
    return this.selectableSubcontentOptions.length > 0;
  }

  /**
   * Updates all the suggestion fields to the correct values.
   */
  private _updateSuggestion(): void {
    this.newSuggestion = {
      currentValue: this.selectedText,
      suggestedValue: this.suggestedText,
      startFragmentId: this.lastSelected[0].fragment.id,
      endFragmentId: this.lastSelected[1].fragment.id,
      startOffset: this.lastSelected[0].offset,
      endOffset: this.lastSelected[1].offset,
    };
  }

  /**
   * Returns whether the user should be able to start a new discussion for the current clause
   */
  public canStartNewDiscussion(): boolean {
    return (
      !this.creatingDiscussion && this.currentView.isAvailableToCommentAgainst() && !this.clause.isUnmodifiableClause
    );
  }
}
