<div class="container">
  <span class="page-title">Changelog Table</span>
  <div class="options">
    <div class="document-options">
      <ng-container>
        <div>
          <span class="dropdown-label"> Published document: </span>
          <mat-form-field floatPlaceholder="never">
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <input
              matInput
              placeholder="Search"
              [matAutocomplete]="autoCompleteComponent"
              [(ngModel)]="publishedDocumentFilter"
              (ngModelChange)="onFilterNgModelChange($event)"
              [disabled]="loading || rendering"
              (focus)="emptyFilter()"
              (blur)="resetFilter()"
              aria-label="select published document"
            />
          </mat-form-field>
          <mat-autocomplete #autoCompleteComponent="matAutocomplete">
            <mat-option
              *ngFor="let document of publishedDocuments"
              [value]="document.TITLE"
              (onSelectionChange)="onDropdownSelection($event, document)"
            >
              {{ document.TITLE }}
            </mat-option>
            <mat-option *ngIf="publishedDocuments && publishedDocuments.length === 0" disabled="true"
              >No documents found</mat-option
            >
          </mat-autocomplete>
        </div>
        <div>
          <span class="dropdown-label"> Outcome document: </span>
          <mat-form-field>
            <mat-select
              [disabled]="loading || rendering"
              value="ALL_DOCUMENTS"
              (selectionChange)="setFilteredRowsByDestination($event)"
              aria-label="select outcome document"
            >
              <mat-option value="ALL_DOCUMENTS">All Documents</mat-option>
              <ng-container *ngFor="let doc of outcomeDocumentFilterOptions">
                <mat-option [value]="doc.id.value">{{ doc.title }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>

      <div class="filter-options">
        <mat-button-toggle-group [(value)]="selectedFilter" (change)="setFilteredRowsByOutcome($event.value)">
          <ng-container *ngFor="let option of displayOutcomeOptions">
            <mat-button-toggle
              [value]="option.key"
              [checked]="isSelected(option.key)"
              [aria-label]="option.aria"
              [matTooltipShowDelay]="tooltipDelay"
              matTooltipPosition="below"
              [matTooltip]="option.tooltip"
              [disabled]="loading || rendering"
              >{{ option.text }}</mat-button-toggle
            >
          </ng-container>
        </mat-button-toggle-group>

        <button
          class="exit"
          matTooltip="Close Changelog Table"
          mat-raised-button
          (click)="exit()"
          (keyup.enter)="exit()"
          [disabled]="loading || rendering"
          aria-label="Close Changelog Table"
        >
          <mat-icon class="mat-24">exit_to_app</mat-icon>
          Exit
        </button>
      </div>
      <div>
        <mat-checkbox [disabled]="loading || rendering" [(ngModel)]="showEfficiencySchedule"
          >Show efficiency schedule</mat-checkbox
        >
      </div>
    </div>

    <div *ngIf="showEfficiencySchedule && !loading && !!changelogProperty" class="efficiency-summary">
      <h4>Efficiency schedule</h4>
      <p>
        Please state the efficiency gained deleting text (please note that efficiency gained by changing existing
        clauses or introducing new clauses is covered in the background document). Examples include: reducing the number
        of departures in the future by deleting specific clauses; less resources needed for maintenance of the document
        as reduced content; etc.
      </p>
      <textarea
        [(ngModel)]="changelogProperty.efficiencySummary"
        (ngModelChange)="onEfficiencyChange($event)"
        [disabled]="loading || rendering"
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="3"
        placeholder="Efficiency schedule"
      ></textarea>
      <mat-chip-list [selectable]="false">
        <mat-chip color="primary" [selected]="savingEfficiency">{{
          savingEfficiency ? 'Saving...' : 'Saved'
        }}</mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <ng-container *ngIf="loading">
    <div class="loading-message">Loading changelog table, please wait a moment</div>
    <mat-progress-bar class="loading-bar" mode="determinate" [value]="progress"></mat-progress-bar>
  </ng-container>
  <ng-container *ngIf="rendering && !loading">
    <div class="loading-message">Rendering changelog table, please wait a moment</div>
    <mat-progress-bar class="loading-bar" mode="indeterminate"></mat-progress-bar>
  </ng-container>

  <table>
    <tr>
      <th width="150px">Published Location.</th>
      <th width="300px">Clause Content.</th>
      <th width="20%">Published Excerpt.</th>
      <th width="150px">Outcome Location.</th>
      <th width="300px">Outcome Excerpt.</th>
      <th width="20%">Comment.</th>
    </tr>
    <ng-container *ngIf="!loading">
      <ng-container *ngFor="let clauseRow of displayedClauseRows; let clauseRowNumber = index">
        <tr *ngFor="let linkRow of allShownLinkRowsForClauseRow.get(clauseRow.clause.id.value); let i = index">
          <td *ngIf="i === 0" [attr.rowspan]="allShownLinkRowsForClauseRow.get(clauseRow.clause.id.value).length">
            <div><span> Document: </span> {{ selectedPublishedDocument.title }}</div>
            <div><span> Section: </span> {{ clauseRow.sectionTitle }}</div>
          </td>
          <td *ngIf="i === 0" [attr.rowspan]="allShownLinkRowsForClauseRow.get(clauseRow.clause.id.value).length">
            <cars-clause
              [content]="clauseRow.clause"
              [showMarginIcons]="false"
              [readOnly]="true"
              [showCurrentClause]="false"
            ></cars-clause>
          </td>
          <td *ngIf="shouldShowRangeRowCell(linkRow)" [attr.rowSpan]="getRangeRowspan(linkRow)">
            <cars-excerpt
              [excerptRange]="linkRow.parentRangeRow.publishedRange"
              [clauseToShow]="linkRow.parentRangeRow.parentClauseRow.clause"
            ></cars-excerpt>
          </td>
          <td *ngIf="linkRow.authoredRange">
            <div>
              <span> Document: </span>
              {{ linkRow.locationInformation.documentTitle }}
            </div>
            <div>
              <span> Section: </span>
              <a
                *ngIf="linkRow.locationInformation.documentId && linkRow.locationInformation.sectionId"
                [routerLink]="getRouterLink(linkRow)"
                >{{ linkRow.locationInformation.sectionTitle }}</a
              >
            </div>
            <div>
              <span> Start Clause: </span>
              {{ linkRow.locationInformation.startClauseIndex }}
            </div>
            <div>
              <span> End Clause: </span>
              {{ linkRow.locationInformation.endClauseIndex }}
            </div>
          </td>
          <td [class.centred]="!linkRow.authoredRange" [attr.colspan]="linkRow.authoredRange ? 1 : 2">
            <span *ngIf="!linkRow.authoredRange"> Deleted. </span>
            <ng-container *ngIf="linkRow.authoredRange">
              <span> {{ formatLinkType(linkRow.link) }} Change: </span>
              <div>
                <cars-excerpt [excerptRange]="linkRow.authoredRange"></cars-excerpt>
              </div>
            </ng-container>
          </td>
          <td>
            {{ linkRow.link ? linkRow.linkComment : linkRow.parentRangeRow.publishedRange.deletionComment }}
          </td>
        </tr>
        <tr *ngIf="isEmptyClauseRow(clauseRow)">
          <td>
            <div><span> Document: </span> {{ selectedPublishedDocument.title }}</div>
            <div><span> Section: </span> {{ clauseRow.sectionTitle }}</div>
          </td>
          <td>
            <cars-clause
              [content]="clauseRow.clause"
              [showMarginIcons]="false"
              [readOnly]="true"
              [showCurrentClause]="false"
            ></cars-clause>
          </td>
          <td class="centred" colspan="4">There are no changes in this clause.</td>
        </tr>
        <tr class="hidden">
          <cars-placeholder-component (onLoad)="pageLoaded()"></cars-placeholder-component>
        </tr>
      </ng-container>
      <tr *ngIf="getShownClauseRows().length === 0">
        <td class="no-results-found" colspan="6">No changes found.</td>
      </tr>
    </ng-container>
  </table>

  <div class="paginator-and-warning-container">
    <div *ngIf="!loading" class="warning">
      <mat-icon class="warning-icon">warning</mat-icon> This changelog has been paginated to decrease loading time.
      Please use the controls below to see all document changes.
    </div>
    <div class="paginator-and-jump">
      <div class="page-select" [formGroup]="jumpFormGroup">
        <div class="label">Jump to page:</div>
        <mat-form-field>
          <input
            type="number"
            class="form-control"
            matInput
            name="value"
            placeholder="page"
            value="1"
            (change)="jumpToPage()"
            formControlName="jumpFormControl"
            aria-label="jump to page"
          />
        </mat-form-field>
      </div>
      <mat-paginator
        #paginator
        [length]="getShownClauseRows().length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="page($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
