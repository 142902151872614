<div
  *ngIf="unresolvedDiscussions && (CarsAction.SHOW_DISCUSSIONS_MARGIN_ICON | carsEnabled: fragment.documentId | async)"
  class="margin-icon"
  (click)="openSidebar()"
  [matTooltip]="
    unresolvedDiscussions > 1
      ? unresolvedDiscussions + ' unresolved discussions'
      : unresolvedDiscussions + ' unresolved discussion'
  "
  matTooltipPosition="before"
  [matTooltipShowDelay]="tooltipDelay"
  contenteditable="false"
>
  <mat-icon><span data-text="mode_comment"></span></mat-icon>
  <div class="discussion-count">
    <span [attr.data-text]="unresolvedDiscussions"></span>
  </div>
</div>
<div
  *ngIf="resolvedDiscussions && (CarsAction.IS_COMPARE_VIEW_MODE | carsEnabled: fragment.documentId | async)"
  class="margin-icon"
  (click)="openSidebar()"
  [matTooltip]="
    resolvedDiscussions > 1
      ? resolvedDiscussions + ' resolved discussions'
      : resolvedDiscussions + ' resolved discussion'
  "
  matTooltipPosition="before"
  [matTooltipShowDelay]="tooltipDelay"
  contenteditable="false"
>
  <mat-icon><span data-text="chat_bubble_outline"></span></mat-icon>
  <div class="discussion-count resolved-discussion-count">
    <span [attr.data-text]="resolvedDiscussions"></span>
  </div>
</div>
