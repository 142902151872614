import {Logger} from 'app/error-handling/services/logger/logger.service';
import {DocumentReferenceFragment, Fragment, FragmentType, SectionFragment} from 'app/fragment/types';

export class DocumentReferenceUtils {
  /**
   * Gets all fragments in the reference section that are of type DOCUMENT_REFERENCE.
   * Note that it filters out INTERNAL_DOCUMENT_REFERENCE fragments.
   */
  public static getDocumentReferencesFromSection(section: SectionFragment): DocumentReferenceFragment[] {
    if (!section.children?.length) {
      Logger.error('reference-error', `Reference section ${section.id.value} had no clause`);
      return [];
    }

    return [...section.children[0].children]
      .filter((fragment: Fragment) => fragment.is(FragmentType.DOCUMENT_REFERENCE))
      .map((fragment: Fragment) => fragment as DocumentReferenceFragment);
  }

  // Private constructor to prevent instantiations
  private constructor() {}
}
