import {FragmentType} from 'app/fragment/types';

/**
 * Extra types for tokens:
 * Needs to be a string-type enum to avoid clashing with FragmentType in switch statements etc.
 */
export enum TokenType {
  CAPTION = 'CAPTION',
  TABLE_END = 'TABLE_END',
}

/**
 * Represents parser tokens.
 *
 * @param type  {FragmentType}   The token type
 * @param value {string}         The token value
 */
export class Token {
  // For lists:
  public indented: boolean;

  // For table cells:
  public rowSpan: number;
  public colSpan: number;
  public deleted: boolean;
  public bold: boolean;

  constructor(public type: FragmentType | TokenType, public value: string) {}

  public clone(): Token {
    const t: Token = new Token(this.type, this.value);
    Object.keys(this).forEach((key: string) => {
      t[key] = this[key];
    });
    return t;
  }
}
