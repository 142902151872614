import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {DocumentFragment} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentService} from 'app/services/document.service';
import {ResizeService} from 'app/services/resize.service';
import {ConfigurationService, ToolbarItem, ToolbarItemType} from 'app/suite-config/configuration.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {AltAccessibilityService, AltProperties} from '../services/alt-accessibility.service';
import {NavigationService, NavigationTypes} from '../services/navigation.service';

@Component({
  selector: 'cars-editor-toolbar',
  templateUrl: './editor-toolbar.component.html',
  styleUrls: ['./editor-toolbar.component.scss'],
})
export class EditorToolbarComponent implements OnInit, OnDestroy {
  /**
   * Template with addition buttons to add
   */
  @Input() public toolbarButtonsExt: TemplateRef<any>;

  /**
   * The name of the navigation. Do not change after setting.
   */
  @Input() public navigationName: NavigationTypes = NavigationTypes.DOCUMENT;

  public document: DocumentFragment;

  public readonly ToolbarItemType: typeof ToolbarItemType = ToolbarItemType;

  public toolbarItems: ToolbarItem[];

  /**
   * Alt properties
   */
  public readonly altProperties: AltProperties = new AltProperties('e', null, true);
  public itemAltProperties: Record<string, AltProperties> = {};

  public readonly tooltipDelay: number = environment.tooltipDelay;

  /**
   * Indicates whether the navigation bar is open
   */
  public get isOpen() {
    return this._isOpen;
  }

  /**
   * Subscriptions to services
   */
  private _subscriptions: Subscription[] = [];

  /**
   * Indicates whether the navigation bar is open
   */
  private _isOpen: boolean = false;

  public readonly CarsAction: typeof CarsAction = CarsAction;

  private altAccessibilityService: AltAccessibilityService = new AltAccessibilityService(new ResizeService());

  constructor(
    private _navigationService: NavigationService,
    private _documentService: DocumentService,
    private _configurationService: ConfigurationService
  ) {}

  /**
   * @inheritDoc
   */
  ngOnInit() {
    this._isOpen = this._navigationService.getState(this.navigationName);

    this._subscriptions.push(
      this._navigationService.onChange(this.navigationName, (open: boolean) => {
        this._isOpen = open;
      }),

      this._documentService.onSelection((document: DocumentFragment) => {
        this.document = document;
        if (document) {
          this._configurationService.getToolbarItemsForSuite(document.suite).then((toolbarItems: ToolbarItem[]) => {
            this.toolbarItems = toolbarItems;
            this._createItemAltProperties();
          });
        }
      })
    );
  }

  /**
   * @inheritDoc
   */
  ngOnDestroy() {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Toggles the navigation bar
   */
  public onToggleNavBar(): void {
    this._navigationService.toggleState(this.navigationName);
  }

  private _createItemAltProperties(): void {
    this.itemAltProperties = {};
    this.toolbarItems.forEach((item: ToolbarItem, index: number) => {
      this.itemAltProperties[item.itemKey] = this.altAccessibilityService.getAltProperties(
        item.itemKey.replace(/-/g, '_'),
        true
      );
    });
  }
}
