import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {BlurOption} from 'app/blur-options';
import {PadType} from 'app/element-ref.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {DomService} from 'app/services/dom.service';
import {RichTextType} from 'app/services/rich-text.service';
import {FragmentService} from '../../services/fragment.service';
import {ActionRequest} from '../action-request';
import {Caret} from '../caret';
import {FragmentComponent} from '../core/fragment.component';
import {Key} from '../key';
import {Fragment, FragmentType, ListFragment, ListItemFragment, TextFragment} from '../types';

@Component({
  selector: 'cars-list-fragment',
  templateUrl: './list-fragment.component.html',
  styleUrls: ['../core/fragment.component.scss', './list-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFragmentComponent extends FragmentComponent implements OnInit {
  public set content(value: ListFragment) {
    super.content = value;
  }

  public get content(): ListFragment {
    return super.content as ListFragment;
  }

  public readonly BlurOption: typeof BlurOption = BlurOption;
  public readonly PadType: typeof PadType = PadType;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public showMenuButtons: boolean = false;

  constructor(
    protected _cd: ChangeDetectorRef,
    private _fragmentService: FragmentService,
    private _domService: DomService,
    elementRef: ElementRef
  ) {
    super(_cd, elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.showMenuButtons = this.content.parent.is(FragmentType.CLAUSE);
  }

  /**
   * @override
   */
  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    const index: number = this.content.childIndexOf(target.content);

    if (key.equalsUnmodified(Key.ENTER)) {
      return this._handleEnter(index, target, caret);
    } else if (key.equalsUnmodified(Key.BACKSPACE)) {
      return this._handleBackspace(index, target, caret);
    } else if (key.equalsUnmodified(Key.DELETE)) {
      return this._handleDelete(index, target, caret);
    } else if (key.equalsUnmodified(Key.TAB)) {
      return this._handleTab(key.shift);
    } else {
      return null;
    }
  }

  /**
   * Respond to a rich text UI event to allow changing a list type or the list item indent.
   *
   * @override
   */
  public onRichText(type: RichTextType, start: Caret, end: Caret, ...args: any[]): ActionRequest {
    switch (type) {
      case RichTextType.LIST: {
        this.content.ordered = args[0] === true;
        this._fragmentService.update(this.content);

        return new ActionRequest(start.fragment);
      }

      case RichTextType.LIST_INCREASE_INDENT: {
        this._handleTab(false, [start, end]);

        return new ActionRequest(end.fragment, end.fragment.length());
      }

      case RichTextType.LIST_DECREASE_INDENT: {
        this._handleTab(true, [start, end]);

        return new ActionRequest(end.fragment, end.fragment.length());
      }

      default: {
        return null;
      }
    }
  }

  /**
   * Handle an enter key event by splitting and creating a new list item.
   *
   * @param index  {number}              The target list item index
   * @param target {FragmentComponent}   The highlighted fragment component
   * @param caret  {Caret}               The current caret state
   * @returns      {ActionRequest}       The desired action
   */
  private _handleEnter(index: number, target: FragmentComponent, caret: Caret): ActionRequest {
    const currentItem: ListItemFragment = this.content.children[index] as ListItemFragment;

    if (
      currentItem.indented &&
      currentItem.length() === 0 &&
      ((currentItem.nextSibling() && !(currentItem.nextSibling() as ListItemFragment).indented) ||
        !currentItem.nextSibling())
    ) {
      currentItem.indented = false;
      this._fragmentService.update(currentItem);

      return new ActionRequest(currentItem);
    } else if (!(index === this.content.children.length - 1 && currentItem.length() === 0)) {
      const liOffset: number = currentItem.correctOffset(target.content, caret.offset);
      const newItem: Fragment = currentItem.split(liOffset);
      caret.offset = 0;

      if (currentItem.indented) {
        (newItem as ListItemFragment).indented = true;
      }

      if (newItem.hasChildren() && newItem.children[0].is(FragmentType.EQUATION, FragmentType.INLINE_REFERENCE)) {
        newItem.children.splice(0, 0, new TextFragment(null, ''));
      }

      newItem.insertAfter(currentItem);
      this._fragmentService.create(newItem);
      this._fragmentService.update(target.content);
      this._fragmentService.update(newItem.children);

      return new ActionRequest(newItem.children[0]);
    } else {
      return null;
    }
  }

  /**
   * Handle a backspace key event by merging the current list item into the previous.
   *
   * @param index  {number}              The target list item index
   * @param target {FragmentComponent}   The highlighted fragment component
   * @param caret  {Caret}               The current caret state
   * @returns      {ActionRequest}       The desired action
   */
  private _handleBackspace(index: number, target: FragmentComponent, caret: Caret): ActionRequest {
    const firstListItem: boolean = index === 0;

    if (this._isListItemFirstElementAndHasClauseGroupAncestor(target, firstListItem)) {
      return this._handleBackspaceForFirstListItemInClauseGroup(caret, target);
    }

    const action: ActionRequest = new ActionRequest();
    const moved: Fragment[] = this.content.children[index].children.splice(0);
    const deleted: Fragment[] = [];
    let fosterParent: Fragment;

    if (!firstListItem) {
      fosterParent = this.content.children[index - 1];
      action.fragment = fosterParent;
      caret.offset = action.fragment.length();
      fosterParent.children.push(...moved);
      deleted.push(this.content.children[index]);
    } else {
      fosterParent = this.content.parent;
      action.fragment = this.content.children[index].previousLeaf();
      caret.offset = action.fragment ? action.fragment.length() : 0;

      const parentIndex: number = this.content.index();
      const remove: number = this.content.children.length <= 1 ? 1 : 0;
      fosterParent.children.splice(parentIndex, remove, ...moved);
      remove === 1 ? deleted.push(this.content) : deleted.push(this.content.children[index]);
    }

    this._fragmentService.update(moved);
    // Always list or list items, no need to validate
    this._fragmentService.delete(deleted);
    this._fragmentService.mergeChildren(fosterParent);

    if (this.content.children[0] && (this.content.children[0] as ListItemFragment).indented) {
      (this.content.children[0] as ListItemFragment).indented = false;
      this._fragmentService.update(this.content.children[0]);
    }

    return action;
  }

  private _handleBackspaceForFirstListItemInClauseGroup(caret: Caret, target: FragmentComponent): ActionRequest {
    const parentListItem: ListItemFragment = caret.fragment.findAncestorWithType(
      FragmentType.LIST_ITEM
    ) as ListItemFragment;

    if (!this._contentIsEmpty(target)) {
      return new ActionRequest();
    }

    const listItemSibling: Fragment = parentListItem.nextSibling();

    if (!listItemSibling) {
      const parentList: ListFragment = target.content.findAncestorWithType(FragmentType.LIST) as ListFragment;
      this._fragmentService.delete(parentList);
      return new ActionRequest(target.content);
    }

    const targetListItem: ListItemFragment = target.content.findAncestorWithType(
      FragmentType.LIST_ITEM
    ) as ListItemFragment;

    this._fragmentService.delete(targetListItem);

    return new ActionRequest(listItemSibling);
  }

  private _contentIsEmpty(target: FragmentComponent): boolean {
    return target.content.length() === 0;
  }

  private _isListItemFirstElementAndHasClauseGroupAncestor(target: FragmentComponent, firstListItem: boolean): boolean {
    return firstListItem && target.content.parent && !!target.content.findAncestorWithType(FragmentType.CLAUSE_GROUP);
  }

  /**
   * Handle a delete key event by merging the current list item into the next.
   *
   * @param index  {number}              The target list item index
   * @param target {FragmentComponent}   The highlighted fragment component
   * @param caret  {Caret}               The current caret state
   * @returns      {ActionRequest}       The desired action
   */
  private _handleDelete(index: number, target: FragmentComponent, caret: Caret): ActionRequest {
    if (
      caret &&
      caret.fragment &&
      caret.fragment.nextSibling() &&
      caret.fragment.nextSibling().is(FragmentType.INLINE_REFERENCE, FragmentType.EQUATION)
    ) {
      if (caret.fragment.nextSibling().is(FragmentType.INLINE_REFERENCE)) {
        // Always inline reference, no need to validate
        this._fragmentService.delete(caret.fragment.nextSibling());
      }
      return new ActionRequest();
    } else if (index < this.content.children.length - 1) {
      const moved: Fragment[] = this.content.children[index + 1].children.splice(0);
      this.content.children[index].children.push(...moved);
      this._fragmentService.update(moved);
      // Always list item, no need to validate
      this._fragmentService.delete(this.content.children[index + 1]);
      this._fragmentService.mergeChildren(this.content.children[index]);

      return new ActionRequest();
    } else {
      return null;
    }
  }

  /**
   * Handle a tab key event up indenting or unindenting the selected list items.
   *
   * @param unIndent  {boolean} True if we should unindent rather than indent the list item.
   * @param carets    {Caret[]} Optional argument of the caret positions.
   */
  private _handleTab(unIndent: boolean, carets?: Caret[]): ActionRequest {
    const start: ListItemFragment =
      carets && carets[0]
        ? (carets[0].fragment.findAncestorWithType(FragmentType.LIST_ITEM) as ListItemFragment)
        : (this._domService
            .getCaretsFromSelection()[0]
            .fragment.findAncestorWithType(FragmentType.LIST_ITEM) as ListItemFragment);
    let end: ListItemFragment =
      carets && carets[1]
        ? (carets[1].fragment.findAncestorWithType(FragmentType.LIST_ITEM) as ListItemFragment)
        : (this._domService
            .getCaretsFromSelection()[1]
            .fragment.findAncestorWithType(FragmentType.LIST_ITEM) as ListItemFragment);

    if (start && end) {
      const updated: ListItemFragment[] = [];

      while (!end.equals(start)) {
        end.indented = !unIndent;
        updated.push(end);
        end = end.previousSibling() as ListItemFragment;
      }
      start.indented = start.isFirstChild() ? false : !unIndent;
      updated.push(start);

      this._fragmentService.update(updated);
    }

    return new ActionRequest();
  }

  /**
   * Returns true is the given indented item is the first in a block in indented items, else false.
   *
   * @param item  {ListItemFragment}  The target list item.
   */
  public isFirstIndentedItem(item: ListItemFragment): boolean {
    return !(item.previousSibling() && (item.previousSibling() as ListItemFragment).indented);
  }

  /**
   * Returns the given indented list item and all following consecutive indented list items.
   *
   * @param listItem  {ListItemFragment}  The target list item.
   */
  public iterateOverIndented(listItem: ListItemFragment): any {
    const returnList: ListItemFragment[] = [];
    let testItem: ListItemFragment = listItem;
    while (testItem && testItem.indented) {
      returnList.push(testItem);
      testItem = testItem.nextSibling() as ListItemFragment;
    }
    return returnList;
  }

  public startOffset(): number {
    return this.content.listStartIndex === null ? 0 : this.content.listStartIndex - 1;
  }
}
