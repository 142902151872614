import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {ActionRequest} from 'app/fragment/action-request';
import {Caret} from 'app/fragment/caret';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {Key} from 'app/fragment/key';
import {Fragment} from 'app/fragment/types';
import {ReferenceInputFragment} from 'app/fragment/types/input/reference-input-fragment';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {TargetDocumentType} from 'app/fragment/types/reference/target-document-type';
import {FragmentService} from 'app/services/fragment.service';
import {
  ClauseDisplayReferenceUtils,
  DisplayInternalReferenceFragment,
} from 'app/services/references/reference-utils/clause-display-reference-utils';
import {DisplayReferenceUtils} from 'app/services/references/reference-utils/display-reference-utils';
import {ReferenceService} from 'app/services/references/reference.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-clause-reference-input',
  templateUrl: './clause-reference-input.component.html',
  styleUrls: ['./clause-reference-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseReferenceInputComponent extends FragmentComponent implements OnInit, OnDestroy {
  @Input() public set content(value: ReferenceInputFragment) {
    super.content = value;
  }

  public get content(): ReferenceInputFragment {
    return super.content as ReferenceInputFragment;
  }

  public referenceSuffix: string = '';
  public showDocumentReference: boolean = false;
  public groupedClauses: DisplayInternalReferenceFragment<InternalInlineReferenceFragment>[];

  private _subscriptions: Subscription[] = [];

  constructor(
    protected _cdr: ChangeDetectorRef,
    private _fragmentService: FragmentService,
    private _referenceService: ReferenceService,
    _elementRef: ElementRef
  ) {
    super(_cdr, _elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._setReferenceProperties();
    this._subscriptions.push(
      this._referenceService.onDocumentReferencesRecalculated().subscribe(() => this._setReferenceProperties()),
      this._referenceService.onReferenceChange().subscribe(() => this._setReferenceProperties())
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  private _setReferenceProperties(): void {
    if (this.content.children.length === 0) {
      this.referenceSuffix = '';
      this.showDocumentReference = false;
      return;
    }

    this._groupReferences().then((res) => {
      this.groupedClauses = res;
      this._cdr.markForCheck();
    });

    this._getInternalDocumentReferenceFragment(this.content.children[0]).then((ref) => {
      if (ref.targetDocumentType === TargetDocumentType.SAME_DOCUMENT) {
        this.referenceSuffix = ' of this document';
        this.showDocumentReference = false;
        return;
      }
      this.referenceSuffix = ' of ' + (ref.documentCode || '[document code not set]');
      this.showDocumentReference = true;
    });
  }

  private async _groupReferences(): Promise<DisplayInternalReferenceFragment<InternalInlineReferenceFragment>[]> {
    const mainList: DisplayInternalReferenceFragment<InternalInlineReferenceFragment>[] = [];
    const currentGroup: DisplayInternalReferenceFragment<InternalInlineReferenceFragment> =
      ClauseDisplayReferenceUtils.emptyGroup();

    await Promise.all(
      this.content.children
        .sort((x: InternalInlineReferenceFragment, y: InternalInlineReferenceFragment) => {
          return (
            this._referenceService.getInternalReferenceSorting(x.internalDocumentReferenceId) -
            this._referenceService.getInternalReferenceSorting(y.internalDocumentReferenceId)
          );
        })
        .map(async (currentInlineReference: InternalInlineReferenceFragment, index) => {
          await this._getInternalDocumentReferenceFragment(currentInlineReference).then((currentDocumentReference) => {
            ClauseDisplayReferenceUtils.updateGroupWithReference(
              this.content,
              mainList,
              currentGroup,
              currentDocumentReference,
              currentInlineReference,
              index
            );
          });
        })
    );

    return mainList;
  }

  private _getInternalDocumentReferenceFragment(frag: Fragment): Promise<InternalDocumentReferenceFragment> {
    const internalInlineRef: InternalInlineReferenceFragment = frag as InternalInlineReferenceFragment;
    const docRef: InternalDocumentReferenceFragment = this._fragmentService.find(
      internalInlineRef.internalDocumentReferenceId
    ) as InternalDocumentReferenceFragment;
    if (!!docRef) {
      return Promise.resolve(docRef);
    }
    return this._fragmentService
      .fetchLatest(internalInlineRef.internalDocumentReferenceId, {
        depth: 0,
        validFrom: 0,
      })
      .then((fragment: Fragment) => fragment as InternalDocumentReferenceFragment);
  }

  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    this._cdr.markForCheck();
    if (key.equalsUnmodified(Key.BACKSPACE) || key.equalsUnmodified(Key.DELETE)) {
      const fragment: Fragment = this.content.previousSibling();
      const offset: number = fragment.length();
      this._fragmentService.delete(this.content);
      return new ActionRequest(fragment, offset);
    }
    return null;
  }

  public showSeparator(index: number): boolean {
    return this.content && index < this.groupedClauses.length - 1;
  }

  public getSeparator(index: number): string {
    return DisplayReferenceUtils.getSeparatorForGroupedReferences(index, this.groupedClauses.length);
  }
}
