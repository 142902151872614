<div #hoverTipRef class="hover-tip">
  <button
    mat-icon-button
    disableRipple="true"
    *ngIf="!inCreationWizard"
    matTooltip="Number of links"
    matTooltipPosition="left"
    [matTooltipShowDelay]="tooltipDelay"
    class="link-count"
    aria-label="Number of links"
  >
    <div>{{ linkCount }}</div>
  </button>
  <button
    mat-icon-button
    *ngIf="state === RangeMenuState.NORMAL && !inCreationWizard"
    matTooltip="Create link"
    matTooltipPosition="left"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="addLink()"
    class="range-menu-create"
    carsAnalytics="changelog-start-creating-link"
    aria-label="Create link"
  >
    <mat-icon>link</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="state === RangeMenuState.NORMAL && !inCreationWizard && linkCount"
    matTooltip="View links"
    matTooltipPosition="left"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="viewLinks()"
    class="range-menu-view"
    carsAnalytics="changelog-view-links"
    aria-label="View links"
  >
    <mat-icon>visibility</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="state === RangeMenuState.NORMAL && this.range.published && !inCreationWizard"
    matTooltip="Mark as deleted"
    matTooltipPosition="left"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="markAsDeleted()"
    class="range-menu-delete"
    carsAnalytics="changelog-start-marking-as-deleted"
    aria-label="Mark as deleted"
  >
    <mat-icon>delete</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="state === RangeMenuState.DELETED && !inCreationWizard"
    matTooltip="Restore highlight"
    matTooltipPosition="left"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="restore()"
    class="range-menu-restore"
    carsAnalytics="changelog-restore-range"
    aria-label="Restore highlight"
  >
    <mat-icon>restore_page</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="state === RangeMenuState.DELETED && !inCreationWizard"
    matTooltip="Edit deletion comment"
    matTooltipPosition="left"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="editDeletionComment()"
    carsAnalytics="changelog-edit-deletion-comment"
    aria-label="Edit deletion comment"
  >
    <mat-icon>insert_comment</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="state !== RangeMenuState.LINKING && !inCreationWizard"
    matTooltip="Remove highlight"
    matTooltipPosition="left"
    [matTooltipShowDelay]="tooltipDelay"
    (click)="removeRange()"
    class="range-menu-remove"
    carsAnalytics="changelog-remove-range"
    aria-label="Remove highlight"
  >
    <mat-icon>clear</mat-icon>
  </button>
</div>
