import {ComparisonOperator} from './comparison-operator';
import {ParsedRequiredValuePattern} from './parsed-required-value-pattern';

export class RequiredValuePatternParser {
  // Matches the required pattern format of ${FIELD_PATH}${OPERATOR}${NUMERICAL_VALUE} and
  // captures three groups: one for each part of the pattern (e.g path, comparator and numerical value)
  private static readonly PATTERN_REGEX: RegExp = /^([a-zA-Z\.]+)(>=|>|<=|<|=|!=)(\-?\d+(?:\.\d+)?)$/;

  private static readonly OPERATOR_MAP: Readonly<Record<string, ComparisonOperator>> = {
    '>=': ComparisonOperator.GREATER_THAN_OR_EQUAL_TO,
    '<=': ComparisonOperator.LESS_THAN_OR_EQUAL_TO,
    '>': ComparisonOperator.GREATER_THAN,
    '<': ComparisonOperator.LESS_THAN,
    '=': ComparisonOperator.EQUAL_TO,
    '!=': ComparisonOperator.NOT_EQUAL_TO,
  };

  constructor() {}

  /**
   * Valid patterns for required fields is a comma seperated list (TODO) of parsed required value patterns which are of the form of
   * "${FIELD_PATH}${OPERATOR}${NUMERICAL_VALUE}". For example, the following would return three valid parsed patterns:
   * "a.b<3,a.b>0,a.b.c=2".
   *
   * Any invalid patterns will be filtered out, and the valid ones will be returned.
   */
  public parsePattern(pattern: string): ParsedRequiredValuePattern[] {
    if (!pattern) {
      return [];
    }

    return pattern
      .split(',')
      .map((toParse: string) => {
        const match: RegExpExecArray = RequiredValuePatternParser.PATTERN_REGEX.exec(toParse);
        return match
          ? new ParsedRequiredValuePattern(
              RequiredValuePatternParser.OPERATOR_MAP[match[2]],
              +match[3],
              match[1].split('.')
            )
          : null;
      })
      .filter(Boolean);
  }
}
