<div
  *ngIf="validationErrors.length && (CarsAction.SHOW_VALIDATION_MARGIN_ICON | carsEnabled: clause.documentId | async)"
  (click)="openSidebar()"
  matTooltip="There are validation errors on this clause"
  matTooltipPosition="before"
  [matTooltipShowDelay]="tooltipDelay"
  [ngSwitch]="maxSeverity"
  contenteditable="false"
>
  <mat-icon *ngSwitchCase="ValidationSeverity.ERROR" svgIcon="error"></mat-icon>
  <mat-icon *ngSwitchCase="ValidationSeverity.WARNING" svgIcon="warning"></mat-icon>
  <mat-icon *ngSwitchCase="ValidationSeverity.INFO" svgIcon="info"></mat-icon>
</div>
