import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {ClauseAndSectionOptionsModule} from 'app/suite-config/clause-and-section-options.module';
import {RequiredNonWhitespaceModule} from 'app/utils/directives/required-non-whitespace.module';
import {SectionCreatorComponent} from './section-creator.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    CarsErrorHandlingModule,
    RequiredNonWhitespaceModule,
    ClauseAndSectionOptionsModule,
    MatProgressBarModule,
    AltAccessibilityModule,
    MatIconModule,
    MatTooltipModule,
  ],
  declarations: [SectionCreatorComponent],
  exports: [SectionCreatorComponent],
})
export class SectionCreatorModule {}
