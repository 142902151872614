import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {BlurOption} from 'app/blur-options';
import {PadType} from 'app/element-ref.service';
import {ActionRequest} from 'app/fragment/action-request';
import {Caret} from 'app/fragment/caret';
import {CaptionedFragmentTargetClauseService} from 'app/fragment/clause/captioned-fragment-target-clause.service';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {Key} from 'app/fragment/key';
import {Suite} from 'app/fragment/suite';
import {TableFragment} from 'app/fragment/table/table-fragment';
import {
  ClauseFragment,
  ClauseType,
  DocumentFragment,
  Fragment,
  FragmentType,
  ListFragment,
  ListItemFragment,
} from 'app/fragment/types';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {ClauseService} from 'app/services/clause.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {RichTextType} from 'app/services/rich-text.service';
import {SpecifierInstructionService} from 'app/services/specifier-instruction.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-no-op-clause-fragment',
  templateUrl: './no-op-clause-fragment.component.html',
  styleUrls: ['./no-op-clause-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoOpClauseFragmentComponent extends FragmentComponent implements OnInit, OnDestroy {
  @Input() public set content(value: ClauseFragment) {
    super.content = value;
  }

  public get content(): ClauseFragment {
    return super.content as ClauseFragment;
  }

  @Input() public showMarginIcons: boolean = true;
  @Input() public showCurrentClause: boolean = true;
  @Input() public padType: PadType;

  public readonly ClauseType: typeof ClauseType = ClauseType;
  public readonly PadType: typeof PadType = PadType;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public reordering: boolean = false;

  public isContentSpecifierInstruction: boolean = false;
  public isDeprecatedSIType: boolean = false;
  public isMomhw: boolean = false;

  public readonly BlurOption: typeof BlurOption = BlurOption;

  private _subscriptions: Subscription[] = [];

  private _canSelectClause: boolean = false;
  private _userCanLockPermission: boolean = false;

  constructor(
    protected _cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    private _reorderClausesService: ReorderClausesService,
    private _clauseService: ClauseService,
    private _lockService: LockService,
    private _permissionsService: PermissionsService,
    private _specifierInstructionService: SpecifierInstructionService,
    private _fragmentService: FragmentService,
    private _targetClauseService: CaptionedFragmentTargetClauseService
  ) {
    super(_cdr, elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit(); // IMPORTANT

    if (this.content?.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) {
      this.isContentSpecifierInstruction = true;
      this._setDeprecatedSIStatus();
      this._subscriptions.push(
        this._specifierInstructionService
          .onSpecifierInstructionTemplatesLoad()
          .subscribe(() => this._setDeprecatedSIStatus())
      );
    }

    this.isMomhw = (this.content.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment)?.isSuite(Suite.MOMHW);

    this._subscriptions.push(
      this._reorderClausesService.onReorderingEvent().subscribe((reordering: boolean) => {
        this.reordering = reordering;
        this.markForCheck();
      }),
      this._permissionsService
        .can(CarsAction.CAN_SELECT_CLAUSE, this.content?.documentId)
        .subscribe((canSelectClause: boolean) => (this._canSelectClause = canSelectClause)),
      this._permissionsService
        .can(CarsAction.LOCK_CLAUSE, this.content?.documentId)
        .subscribe((userCanLock: boolean) => (this._userCanLockPermission = userCanLock))
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy(); // IMPORTANT

    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * @override
   */
  public blur() {
    this._clauseService.requestVersion(this.content.id);
  }

  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    return key.equalsUnmodified(Key.ENTER) ? null : new ActionRequest();
  }

  public onRichText(type: RichTextType, start: Caret, end: Caret, ...args: any[]): ActionRequest {
    let action: ActionRequest = new ActionRequest();

    const listFragment: ListFragment = this.content.children.find(
      (child: Fragment) => child instanceof ListFragment
    ) as ListFragment;

    const targetClause: ClauseFragment = this._targetClauseService.determineTargetClause(
      this.content,
      type,
      listFragment,
      args
    );

    switch (type) {
      case RichTextType.LIST:
        {
          const ordered: boolean = args[0] === true;

          if (listFragment) {
            listFragment.ordered = ordered;
            this._fragmentService.update(listFragment);
            action.fragment = listFragment;
          } else {
            action = this._createActionForInsertingIntoClauseGroupWithNoExistingList(targetClause, ordered);
          }
        }
        break;
      case RichTextType.TABLE:
        {
          const existingTableFragment: TableFragment = this.content.children.find(
            (child: Fragment) => child instanceof TableFragment
          ) as TableFragment;

          if (existingTableFragment) {
            this._fragmentService.update(existingTableFragment);
            action.fragment = existingTableFragment;
          } else {
            const table: TableFragment = args[0];
            targetClause.children.push(table);
            this._fragmentService.create(table);
            action.fragment = table.at(0, 0);
          }
        }
        break;
      case RichTextType.STANDARD_FORMAT_GROUP:
      case RichTextType.SPECIFIER_INSTRUCTION:
      case RichTextType.NATIONALLY_DETERMINED_REQUIREMENT:
        return null;
      default:
        break;
    }
    return action;
  }

  private _createActionForInsertingIntoClauseGroupWithNoExistingList(
    targetClause: ClauseFragment,
    ordered: boolean
  ): ActionRequest {
    const actionRequest: ActionRequest = new ActionRequest();

    const listIndex: number = this._getIndexOfTargetClauseWhichIsListFragment(targetClause);
    if (listIndex > -1) {
      this._mergeNewAndExistingList(targetClause, listIndex, actionRequest);
    } else {
      this._createNewList(targetClause, ordered, actionRequest);
    }

    return actionRequest;
  }

  private _getIndexOfTargetClauseWhichIsListFragment(targetClause: ClauseFragment): number {
    return targetClause.children.findIndex((child: Fragment) => child.is(FragmentType.LIST));
  }

  private _mergeNewAndExistingList(
    targetClause: ClauseFragment,
    listIndex: number,
    actionRequest: ActionRequest
  ): void {
    const newItem: ListItemFragment = ListItemFragment.empty();
    targetClause.children[listIndex].children.push(newItem);
    this._fragmentService.create(newItem);
    actionRequest.fragment = newItem.children[0];
  }

  private _createNewList(targetClause: ClauseFragment, ordered: boolean, actionRequest: ActionRequest): void {
    const newList: ListFragment = ListFragment.withSize(1, ordered);
    this._targetClauseService.addListToClause(newList, targetClause);
    this._fragmentService.create(newList);
    actionRequest.fragment = newList.children[0].children[0];
  }

  /**
   * Respond to click events on the clause by selecting it.
   *
   * Will select the clause if the following hold:
   * - the clause list is not being reordered
   * - the clause should show the CurrentClauseDirective if it is selected
   * - the view mode is either live, historical or the change log right hand pad in editing mode
   * - either:
   *    - the user does not have the permissions to lock the clause (and so will not lock the clause on selection)
   *    - the user does have the permissions to lock the clause, the clause is not readonly and the clause can be locked
   */
  public click(): void {
    if (
      !this.reordering &&
      this.showCurrentClause &&
      this._canSelectClause &&
      (!this._userCanLockPermission || (!this.readOnly && this._lockService.canLock(this.content)))
    ) {
      this._clauseService.setSelected(this.content);
    }
  }

  /**
   * Sets the isDeprecatedSIType status based on the clause group service returned list of deprecated types. Note if
   * the content is not an SI then does not call through to the service.
   */
  private _setDeprecatedSIStatus(): void {
    this.isDeprecatedSIType =
      this.content.clauseType === ClauseType.SPECIFIER_INSTRUCTION &&
      this._specifierInstructionService
        .getDeprecatedSpecifierInstructionTypes()
        .includes(this.content.specifierInstructionType);
  }
}
