import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {ReorderClausesComponent} from './reorder-clauses.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    CarsErrorHandlingModule,
    MatIconModule,
    AltAccessibilityModule,
  ],
  declarations: [ReorderClausesComponent],
  exports: [ReorderClausesComponent],
})
export class ReorderClausesModule {}
