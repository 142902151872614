import {Pipe, PipeTransform} from '@angular/core';
import {UUID} from 'app/utils/uuid';
import {ClauseTypeConfiguration, ConfigurationService, SectionTypeConfiguration} from './configuration.service';

type ConfigurationType<T> = T extends 'SECTION'
  ? Promise<SectionTypeConfiguration[]>
  : T extends 'CLAUSE'
  ? Promise<ClauseTypeConfiguration[]>
  : never;

@Pipe({
  name: 'clauseAndSectionOptions',
})
export class ClauseAndSectionOptionsPipe implements PipeTransform {
  constructor(private configurationService: ConfigurationService) {}

  transform<T extends 'SECTION' | 'CLAUSE'>(id: UUID, type: T): ConfigurationType<T> {
    switch (type) {
      case 'SECTION':
        return this.configurationService.getSectionTypesForDocumentId(id) as ConfigurationType<T>;
      case 'CLAUSE':
        return this.configurationService.getClauseTypesForSectionId(id) as ConfigurationType<T>;
      default:
        throw new Error(`Cannot find child configuration for parent of type ${type}`);
    }
  }
}
