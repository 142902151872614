import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {EquationService} from 'app/services/equation.service';

@Component({
  selector: 'cars-equation',
  templateUrl: './equation.component.html',
  styleUrls: ['./equation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquationComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() public source: string;
  @Input() public inline: boolean;

  @ViewChild('render') private _renderRef: ElementRef;

  public invalidCharacter: boolean = false;

  private _requestAnimationFrameId: number = 0;
  private _prevSource: string;

  constructor(private _equationService: EquationService, private _cdr: ChangeDetectorRef) {}

  public ngOnChanges(): void {
    if (this.source !== this._prevSource) {
      this._prevSource = this.source;
      this._render(true);
    }
  }

  /**
   * Clean up subscriptions within component.
   */
  public ngOnDestroy(): void {
    if (this._requestAnimationFrameId) {
      cancelAnimationFrame(this._requestAnimationFrameId);
      this._requestAnimationFrameId = 0;
    }
  }

  /**
   * Render the equation once the view is ready.
   */
  public ngAfterViewInit(): void {
    this._render(false);
  }

  /**
   * Render the MathJax equation defined by the given source.
   *
   * @param fade {boolean}   True if the render should fade out and in
   */
  private _render(fade: boolean = true): void {
    if (this._renderRef) {
      const elem: HTMLElement = this._renderRef.nativeElement;
      if (fade) {
        elem.style.opacity = '0';
      }

      if (this._requestAnimationFrameId) {
        return;
      }

      this._requestAnimationFrameId = requestAnimationFrame(() => {
        this._requestAnimationFrameId = 0;
        const subscription = this._equationService.renderEquationFromSource(elem, this.source).subscribe(() => {
          elem.style.opacity = '1';
          this._cdr.markForCheck();
          subscription.unsubscribe();
        });
      });

      this.invalidCharacter = this.source.length > 0 && !!this.source.match(/[^ -~£]/);

      this._cdr.markForCheck();
    }
  }
}
