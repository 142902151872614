import {Suite} from 'app/fragment/suite';
import {ClauseType, SectionType} from 'app/fragment/types';
import {UUID} from 'app/utils/uuid';

export enum TdifVpos {
  SUPERSCRIPT = 'SUPERSCRIPT',
  SUBSCRIPT = 'SUBSCRIPT',
}

export class TdifText {
  public static readonly identifier: string = 'text';
  public value: string;
  public properties: TdifTextProperties;

  constructor(value: string, properties: TdifTextProperties) {
    this.value = value;
    this.properties = properties;
  }

  public getIdentifier() {
    return TdifText.identifier;
  }

  public static deserialise(json: any): TdifText {
    if (json == null) {
      return null;
    }

    return new TdifText(json.value, TdifTextProperties.deserialise(json.properties));
  }
}

export class TdifTextProperties {
  public static readonly identifier: string = 'properties';
  public vpos: TdifVpos;
  public memo: boolean;

  constructor(vpos: TdifVpos, memo: boolean) {
    this.vpos = vpos;
    this.memo = memo;
  }

  public static deserialise(json: any): TdifTextProperties {
    if (json == null) {
      return null;
    }

    return new TdifTextProperties(json.vpos, json.memo);
  }
}

export type TdifClause = TdifNormalClause | TdifHeadingClause;
export const TdifClause = {
  deserialiseArray(json: any[]): TdifClause[] {
    return json.map((clause) => TdifClause.deserialise(clause));
  },

  deserialise(json: any): TdifClause {
    if (json == null) {
      return null;
    }

    const key: string = Object.keys(json).length > 0 ? Object.keys(json)[0] : '';

    switch (key) {
      case TdifHeadingClause.identifier:
        return TdifHeadingClause.deserialise(json[key]);
      case TdifNormalClause.identifier:
        return TdifNormalClause.deserialise(json[key]);
    }
  },
};

export class TdifHeadingClause {
  public static readonly identifier: string = 'headingClause';
  public id: UUID;
  public index: string;
  public title: string;
  public clauseType: ClauseType;

  constructor(id: UUID, index: string, title: string, clauseType: ClauseType) {
    this.id = id;
    this.index = index;
    this.title = title;
    this.clauseType = clauseType;
  }

  public static deserialise(json: any): TdifHeadingClause {
    if (json == null) {
      return null;
    }

    return new TdifHeadingClause(UUID.orNull(json.id), json.index, json.title, ClauseType[json.type as string]);
  }

  public getIdentifier() {
    return TdifHeadingClause.identifier;
  }
}

export class TdifNormalClause {
  public static readonly identifier: string = 'clause';
  public id: UUID;
  public index: string;
  public content: TdifContent[];
  public clauseType: ClauseType;

  constructor(id: UUID, index: string, content: TdifContent[], clauseType: ClauseType) {
    this.id = id;
    this.index = index;
    this.content = content;
    this.clauseType = clauseType;
  }

  public static deserialise(json: any): TdifNormalClause {
    if (json == null) {
      return null;
    }

    return new TdifNormalClause(
      UUID.orNull(json.id),
      json.index,
      TdifContent.deserialiseArray(json.content),
      ClauseType[json.type as string]
    );
  }

  public getIdentifier() {
    return TdifNormalClause.identifier;
  }
}

export enum TdifOrientation {
  PORTRAIT,
  LANDSCAPE,
}

export enum TdifHorizontalAlignment {
  LEFT,
  CENTRE,
  RIGHT,
}

export enum TdifVerticalAlignment {
  TOP,
  MIDDLE,
  BOTTOM,
}

export class TdifTable {
  public static readonly identifier: string = 'table';
  public index: string;
  public caption: string;
  public orientation: TdifOrientation;
  public hasHeaderRow: boolean;
  public columnWidths: number[];
  public rows: TdifTableRow[];

  constructor(
    index: string,
    caption: string,
    orientation: TdifOrientation,
    hasHeaderRow: boolean,
    columnWidths: number[],
    rows: TdifTableRow[]
  ) {
    this.index = index;
    this.caption = caption;
    this.orientation = orientation;
    this.hasHeaderRow = hasHeaderRow;
    this.columnWidths = columnWidths;
    this.rows = rows;
  }

  public static deserialise(json: any): TdifTable {
    if (json == null) {
      return null;
    }

    return new TdifTable(
      json.index,
      json.caption,
      TdifOrientation[json.orientation as string],
      json.hasHeaderRow,
      json.columnWidths,
      TdifTableRow.deserialiseArray(json.rows)
    );
  }

  public getIdentifier() {
    return TdifTable.identifier;
  }
}

export class TdifTableRow {
  public cells: TdifTableCell[];

  constructor(cells: TdifTableCell[]) {
    this.cells = cells;
  }

  public static deserialise(json: any): TdifTableRow {
    if (json == null) {
      return null;
    }

    return new TdifTableRow(TdifTableCell.deserialiseArray(json.cells));
  }

  public static deserialiseArray(json: any): TdifTableRow[] {
    return json.map((row) => TdifTableRow.deserialise(row));
  }
}

export class TdifTableCell {
  public rowSpan: number;
  public colSpan: number;
  public hAlign: TdifHorizontalAlignment;
  public vAlign: TdifVerticalAlignment;
  public headerRowBorder: boolean;
  public bold: boolean;
  public content: TdifContent[];

  constructor(
    rowSpan: number,
    colSpan: number,
    hAlign: TdifHorizontalAlignment,
    vAlign: TdifVerticalAlignment,
    headerRowBorder: boolean,
    bold: boolean,
    content: TdifContent[]
  ) {
    this.rowSpan = rowSpan;
    this.colSpan = colSpan;
    this.hAlign = hAlign;
    this.vAlign = vAlign;
    this.headerRowBorder = headerRowBorder;
    this.bold = bold;
    this.content = content;
  }

  public static deserialise(json: any): TdifTableCell {
    if (json == null) {
      return null;
    }

    return new TdifTableCell(
      json.rowSpan,
      json.colSpan,
      TdifHorizontalAlignment[json.hAlign as string],
      TdifVerticalAlignment[json.vAlign as string],
      json.headerRowBorder,
      json.bold,
      TdifContent.deserialiseArray(json.content)
    );
  }

  public static deserialiseArray(json: any[]): TdifTableCell[] {
    return json.map((cell) => TdifTableCell.deserialise(cell));
  }
}

export class TdifSection {
  public static readonly identifier: string = 'section';
  public id: UUID;
  public sectionType: SectionType;
  public index: string;
  public title: string;
  public clauses: TdifClause[];

  constructor(id: UUID, sectionType: SectionType, index: string, title: string, clauses: TdifClause[]) {
    this.id = id;
    this.sectionType = sectionType;
    this.index = index;
    this.title = title;
    this.clauses = clauses;
  }

  public static deserialise(json: any): TdifSection {
    if (json == null) {
      return null;
    }

    return new TdifSection(
      UUID.orNull(json.id),
      SectionType[json.type as string],
      json.index,
      json.title,
      TdifClause.deserialiseArray(json.clauses)
    );
  }
  public static deserialiseArray(json: any): TdifSection[] {
    return json.map((section) => TdifSection.deserialise(section[TdifSection.identifier]));
  }
}

export class TdifReference {
  public reference: string;
  public title: string;
  public author: string;
  public publisher: string;
  public deleted: boolean;
  public withdrawn: boolean;
  public withdrawnOn: number;
  public release: string;

  constructor(
    reference: string,
    title: string,
    author: string,
    publisher: string,
    deleted: boolean,
    withdrawn: boolean,
    withdrawnOn: number,
    release: string
  ) {
    this.reference = reference;
    this.title = title;
    this.author = author;
    this.publisher = publisher;
    this.deleted = deleted;
    this.withdrawn = withdrawn;
    this.withdrawnOn = withdrawnOn;
    this.release = release;
  }

  public static deserialise(json: any): TdifReference {
    if (json == null) {
      return null;
    }

    return new TdifReference(
      json.reference,
      json.title,
      json.author,
      json.publisher,
      json.deleted,
      json.withdrawn,
      json.withdrawnOn,
      json.release
    );
  }

  public static deserialiseArray(json: any[]): TdifReference[] {
    json = typeof json === 'string' ? JSON.parse(json) : json;
    json = json instanceof Array ? json : [json];
    return json.map((reference) => TdifReference.deserialise(reference));
  }
}

export class TdifInlineReference {
  public static readonly identifier: string = 'reference';
  public value: string;

  constructor(value: string) {
    this.value = value;
  }

  public static deserialise(json: any): TdifInlineReference {
    if (json == null) {
      return null;
    }

    return new TdifInlineReference(json.value);
  }

  public getIdentifier() {
    return TdifInlineReference.identifier;
  }
}

export class TdifList {
  public static readonly identifier: string = 'list';
  public ordered: boolean;
  public items: TdifListItem[];

  constructor(ordered: boolean, items: TdifListItem[]) {
    this.ordered = ordered;
    this.items = items;
  }

  public static deserialise(json: any): TdifList {
    if (json == null) {
      return null;
    }

    return new TdifList(json.ordered, TdifListItem.deserialiseArray(json.items));
  }
  public getIdentifier() {
    return TdifList.identifier;
  }
}

export class TdifListItem {
  public indented: boolean;
  public content: TdifContent[];

  constructor(indented: boolean, content: TdifContent[]) {
    this.indented = indented;
    this.content = content;
  }

  public static deserialise(json: any): TdifListItem {
    if (json == null) {
      return null;
    }

    return new TdifListItem(json.indented, TdifContent.deserialiseArray(json.content));
  }

  public static deserialiseArray(json: any[]): TdifListItem[] {
    return json.map((listItem) => TdifListItem.deserialise(listItem));
  }
}

export class TdifFigure {
  public static readonly identifier: string = 'figure';
  public index: string;
  public caption: string;
  public altText: string;
  public imageUrl: string;

  constructor(index: string, caption: string, altText: string, imageUrl: string) {
    this.index = index;
    this.caption = caption;
    this.altText = altText;
    this.imageUrl = imageUrl;
  }

  public static deserialise(json: any): TdifFigure {
    if (json == null) {
      return null;
    }

    return new TdifFigure(json.index, json.caption, json.altText, json.imageUrl);
  }

  public getIdentifier() {
    return TdifFigure.identifier;
  }
}

export type TdifContent =
  | TdifEquation
  | TdifFigure
  | TdifList
  | TdifTable
  | TdifInlineReference
  | TdifText
  | TdifInput
  | TdifReferenceInput
  | TdifUnitInput;
export const TdifContent = {
  deserialiseArray(json: any[]): TdifContent[] {
    return json.map((content) => TdifContent.deserialise(content));
  },
  deserialise(json: any): TdifContent {
    if (json == null) {
      return null;
    }

    const key: string = Object.keys(json).length > 0 ? Object.keys(json)[0] : '';

    switch (key) {
      case TdifEquation.identifier:
        return TdifEquation.deserialise(json[key]);
      case TdifFigure.identifier:
        return TdifFigure.deserialise(json[key]);
      case TdifList.identifier:
        return TdifList.deserialise(json[key]);
      case TdifTable.identifier:
        return TdifTable.deserialise(json[key]);
      case TdifInlineReference.identifier:
        return TdifInlineReference.deserialise(json[key]);
      case TdifText.identifier:
        return TdifText.deserialise(json[key]);
      case TdifInput.identifier:
        return TdifInput.deserialise(json[key]);
      case TdifReferenceInput.identifier:
        return TdifReferenceInput.deserialise(json[key]);
      case TdifUnitInput.identifier:
        return TdifUnitInput.deserialise(json[key]);
    }
  },
};

export class TdifDocumentInformation {
  public static readonly identifier: string = 'documentInformation';
  public documentInformation: Map<string, string>;

  constructor(documentInformation: Map<string, string>) {
    this.documentInformation = documentInformation;
  }

  public static deserialise(json: any): TdifDocumentInformation {
    if (json == null) {
      return null;
    }

    json = json[TdifDocumentInformation.identifier];
    const documentInformationMap: Map<string, string> = new Map<string, string>();
    const keys: string[] = Object.keys(json);
    keys.forEach((key: string) => {
      documentInformationMap.set(key, json[key]);
    });
    return new TdifDocumentInformation(documentInformationMap);
  }
}

export class TdifEquation {
  public static readonly identifier: string = 'equation';
  public index: string;
  public text: string;
  public image: string;

  constructor(index: string, text: string, image: string) {
    this.index = index;
    this.text = text;
    this.image = image;
  }

  public static deserialise(json: any): TdifEquation {
    if (json == null) {
      return null;
    }

    return new TdifEquation(json.index, json.text, json.image);
  }

  public getIdentifier() {
    return TdifEquation.identifier;
  }
}

export class TdifDocument {
  public static readonly identifier: string = 'document';
  public id: UUID;
  public documentSuite: Suite;
  public sections: TdifSection[];
  public documentInformation: TdifDocumentInformation;
  public normativeReferences: TdifReference[];
  public informativeReferences: TdifReference[];

  constructor(
    id: UUID,
    documentSuite: Suite,
    sections: TdifSection[],
    documentInformation: TdifDocumentInformation,
    normativeReferences: TdifReference[],
    informativeReferences: TdifReference[]
  ) {
    this.id = id;
    this.documentSuite = documentSuite;
    this.sections = sections;
    this.documentInformation = documentInformation;
    this.normativeReferences = normativeReferences;
    this.informativeReferences = informativeReferences;
  }

  public static deserialise(json: any): TdifDocument {
    if (json == null) {
      return null;
    }

    return new TdifDocument(
      UUID.orNull(json.id),
      Suite[json.type as string],
      TdifSection.deserialiseArray(json.sections),
      TdifDocumentInformation.deserialise(json.documentInformation),
      TdifReference.deserialiseArray(json.normativeReferences),
      TdifReference.deserialiseArray(json.informativeReferences)
    );
  }
}

export class TdifInput {
  public static readonly identifier: string = 'input';
  public placeholderValue: string;
  public content: TdifContent[];

  constructor(placeholderValue: string, content: TdifContent[]) {
    this.placeholderValue = placeholderValue;
    this.content = content;
  }

  public getIdentifier() {
    return TdifInput.identifier;
  }

  public static deserialise(json: any): TdifInput {
    if (json == null) {
      return null;
    }

    return new TdifInput(json.placeholderValue, TdifContent.deserialiseArray(json.content));
  }
}

export class TdifReferenceInput {
  public static readonly identifier: string = 'referenceInput';
  public value: string;

  constructor(value: string) {
    this.value = value;
  }

  public getIdentifier() {
    return TdifReferenceInput.identifier;
  }

  public static deserialise(json: any): TdifReferenceInput {
    if (json == null) {
      return null;
    }

    return new TdifReferenceInput(json.value);
  }
}

export class TdifUnitInput {
  public static readonly identifier: string = 'unitInput';
  public text: string;
  public image: string;

  constructor(text: string, image: string) {
    this.text = text;
    this.image = image;
  }

  public getIdentifier() {
    return TdifUnitInput.identifier;
  }

  public static deserialise(json: any): TdifUnitInput {
    if (json == null) {
      return null;
    }

    return new TdifUnitInput(json.text, json.image);
  }
}
