import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Suite} from 'app/fragment/suite';
import {DocumentFragment, SectionFragment} from 'app/fragment/types';
import {Breadcrumb} from 'app/interfaces';
import {AltAccessibilityService, AltProperties} from 'app/services/alt-accessibility.service';
import {BreadcrumbService} from 'app/services/breadcrumb.service';
import {NavigationService, NavigationTypes} from 'app/services/navigation.service';
import {ResizeService} from 'app/services/resize.service';
import {GlobalRole} from 'app/services/user/authentication-provider';
import {RoleService} from 'app/services/user/role.service';
import {SUITE_TO_DISPLAY_NAME} from 'app/utils/pipes/suite-to-display-name.pipe';
import {CurrentView, ViewMode} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {DocumentService} from '../../services/document.service';

const CHANGELOG = 'Changelog';

@Component({
  selector: 'cars-navigation-sidebar-buttons',
  templateUrl: './navigation-sidebar-buttons.component.html',
  styleUrls: ['./navigation-sidebar-buttons.component.scss'],
})
export class NavigationSidebarButtonsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  private _breadcrumbs: Breadcrumb[] = [];
  private _changelogActive: boolean = false;
  private _document: DocumentFragment = null;

  @Input() public showNavigationToggle: boolean = true;
  public isNavigationOpen: boolean = true;
  public altProperties: Record<string, AltProperties> = {};
  public currentView: CurrentView;

  public tooltipDelay: number = environment.tooltipDelay;

  public canViewChangelog: boolean = false;

  private altAccessibilityService: AltAccessibilityService = new AltAccessibilityService(new ResizeService());

  /**
   * Indicates whether the change log is active
   */
  public get isChangeLogActive(): boolean {
    return this._changelogActive;
  }

  /**
   * Indicates whether authoring is active
   */
  public get isAuthoringActive(): boolean {
    return !this.isChangeLogActive;
  }

  /**
   * Indicates whether we can enter the change log
   */
  public get canEnterChangeLog(): boolean {
    return this.currentView.viewMode !== ViewMode.HISTORICAL && !!this._document && this._document.hasSections();
  }

  constructor(
    private _breadcrumbsService: BreadcrumbService,
    private _navigationService: NavigationService,
    private _roleService: RoleService,
    private router: Router,
    private viewService: ViewService,
    private _documentService: DocumentService,
    private snackBar: MatSnackBar
  ) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.isNavigationOpen = this._navigationService.getState(NavigationTypes.DOCUMENT);

    this._documentService.onSelection((document: DocumentFragment) => {
      this._document = document;
    });

    this._subscriptions.push(
      this._navigationService.onChange(NavigationTypes.DOCUMENT, (open: boolean) => {
        this.isNavigationOpen = open;
      }),
      this._breadcrumbsService.getBreadcrumbs().subscribe((breadcrumbs: Breadcrumb[]) => {
        this._breadcrumbs = breadcrumbs;
        this._changelogActive = this._breadcrumbs.find((crumb) => crumb.title === CHANGELOG) != null;
      }),
      this.viewService.onCurrentViewChange((currentView: CurrentView) => {
        this.currentView = currentView;
      })
    );

    this.canViewChangelog = this._roleService.isInGlobalRoles(GlobalRole.CHANGELOG);

    this.updateAltProperties();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Event handler to enter authoring mode
   */
  public onEnterAuthoring(): void {
    const breadcrumbs: Breadcrumb[] = this._breadcrumbs
      .filter((crumb: Breadcrumb) => crumb.title !== CHANGELOG)
      .reverse();
    if (breadcrumbs.length > 0) {
      this.router.navigate([breadcrumbs[0].link]);
    }
  }

  /**
   * Event handler to show the change log
   */
  public onEnterChangeLog(): void {
    if (this._document.isSuite(Suite.LEGACY_DMRB)) {
      this.snackBar.open(
        `This is a published document. To write the change log, please open` +
          ` your new ${
            SUITE_TO_DISPLAY_NAME[Suite.DMRB]
          } document and select change log. If your document is a complete withdrawal,` +
          ` you can do this from any new document.`,
        'Dismiss',
        {duration: 5000}
      );
    } else if (this._document.isSuite(Suite.LEGACY_MCHW)) {
      this.snackBar.open(
        `This is a published document. To write the change log, please open` +
          ` your new ${
            SUITE_TO_DISPLAY_NAME[Suite.MCHW]
          } document and select change log. If your document is a complete withdrawal,` +
          ` you can do this from any new document.`,
        'Dismiss',
        {duration: 5000}
      );
    } else {
      if (!this.isChangeLogActive) {
        const section: SectionFragment = this._document.firstStandardSection();
        if (this._breadcrumbs.length >= 3) {
          this.router.navigate([this._breadcrumbs[2].link, 'changelog']);
        } else if (section) {
          this.router.navigate([this._breadcrumbs[1].link, 'sections', section.id.value, 'changelog']);
        }
      }
    }
  }

  public onToggleNavBar(): void {
    this._navigationService.toggleState(NavigationTypes.DOCUMENT);
  }

  public updateAltProperties(): void {
    this.altProperties['initial-button'] = this.altAccessibilityService.getAltProperties('initial_button', true);
    this.altProperties['toggle-menu'] = this.altAccessibilityService.getAltProperties('toggle_menu', true);
    this.altProperties['authoring'] = this.altAccessibilityService.getAltProperties('authoring', true);
    this.altProperties['change-log'] = this.altAccessibilityService.getAltProperties('change_log', true);
  }
}
