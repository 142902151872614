import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {CacheManager} from 'app/fragment/cache-manager';
import {Caret} from 'app/fragment/caret';
import {DiffUtils} from 'app/fragment/diff/diff-utils';
import {FragmentOperationSource} from 'app/fragment/diff/fragment-operation-source';
import {TreeStructureValidator} from 'app/fragment/tree-structure-validator';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {VersionRequest} from 'app/interfaces';
import {WebSocketService} from 'app/services/websocket/websocket.service';
import {BehaviorSubject, Subject, Subscription, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {FragmentMapper} from '../fragment/core/fragment-mapper';
import {CacheEntry} from '../fragment/diff/cache-entry';
import {FragmentDiffManager} from '../fragment/diff/diff-manager';
import {FragmentCache} from '../fragment/diff/fragment-cache';
import {DiffOperation, FragmentDiff} from '../fragment/diff/fragment-diff';
import {
  AnchorFragment,
  ClauseFragment,
  EquationFragment,
  Fragment,
  FragmentType,
  InlineReferenceFragment,
  SectionFragment,
} from '../fragment/types';
import {HttpStatus} from '../utils/http-status';
import {Callback, Predicate} from '../utils/typedefs';
import {UUID} from '../utils/uuid';
import {BaseService} from './base.service';

// Convenience typedef
type Entry = CacheEntry<Fragment>;

// Query params the webservice GET request accepts
export interface FragmentFetchParams {
  depth?: number;
  validFrom?: number;
  validTo?: number;
}

export interface SavingEvent {
  saving?: boolean;
  pendingErrors?: boolean;
}

// Default template argument of Fragment means we can inject FragmentService as a
// type rather than having to inject FragmentService<Fragment>.
@Injectable({
  providedIn: 'root',
})
export class FragmentService<T extends Fragment = Fragment> extends BaseService {
  public static readonly ENDPOINT: string = `${environment.apiHost}/fragments`;
  public static readonly WS_TOPIC: string = '/topic/fragments';
  public static UNDO_BUFFER: CacheEntry<UUID[]> = new CacheEntry([], environment.undoSize);
  public static readonly RETRY_LIMIT: number = 10;

  // Unfortunately these need to be statics so they can be shared among all services
  // deriving FragmentService.
  protected static _createSubject: Subject<Fragment> = new Subject(); // Broadcasts on creates
  protected static _updateSubject: Subject<Fragment> = new Subject(); // Broadcasts on updates
  protected static _deleteSubject: Subject<Fragment> = new Subject(); // Broadcasts on deletes
  protected static _flushSubject: Subject<number> = new Subject(); // Broadcasts on flush
  protected static _failureSubject: Subject<[HttpErrorResponse, UUID[]]> = new Subject(); // Broadcasts on failed flush
  private static _subscriptions: Subscription[] = []; // Websocket subscriptions
  private static _pendingDiffs: FragmentDiffManager = new FragmentDiffManager(); // Diffs waiting to be sent
  private static _pendingDiffsSubjects: Subject<HttpResponse<any>>[] = [];
  private static _flushQueue: FragmentDiff[] = [];
  private static _flushingDiffs: FragmentDiff[] = null;
  private static _currentFlushSubject: Subject<HttpResponse<any>>;
  private static _nextFlushSubject: Subject<HttpResponse<any>>;

  private static _debounceTimer: any = null;
  private static _flushRetryCount: number = 0;

  public static saving: BehaviorSubject<SavingEvent> = new BehaviorSubject({
    saving: false,
  });
  private static elementsWithErrors: HTMLElement[] = [];

  private static readonly FRAGMENTS_THAT_CAN_CONTAIN_INLINE_REFERENCES: Readonly<FragmentType[]> =
    TreeStructureValidator.getValidAncestorTypes(FragmentType.INLINE_REFERENCE);

  protected _selectSubject: BehaviorSubject<T> = new BehaviorSubject(null); // Broadcasts on selected change

  constructor(
    protected _websocketService: WebSocketService,
    protected _cacheManager: CacheManager,
    protected _http: HttpClient,
    protected _snackbar: MatSnackBar
  ) {
    super(_snackbar);
    this._websocketService.onConnection(this._onWebsocketConnect.bind(this));
    this.onCreate(this._markCreatesForCheck.bind(this));
    this.onUpdate(this._markUpdatesForCheck.bind(this));
    this.onDelete(this._markDeletesForCheck.bind(this));
  }

  /**
   * Marks fragments that will need to be updated in the next change detection cycle.
   *
   * @param fragment {Fragment}   Created fragment
   */
  private _markCreatesForCheck(fragment: Fragment): void {
    if (fragment && fragment.parent) {
      fragment.parent.markForCheck();
      if (fragment.isCaptioned()) {
        fragment.parent.markChildrenForCheck((f: Fragment) => f.isCaptioned());
      }
    }
  }

  /**
   * Marks fragments that will need to be updated in the next change detection cycle.
   *
   * @param fragment {Fragment}   Updated fragment
   */
  private _markUpdatesForCheck(fragment: Fragment): void {
    fragment.markForCheck();
    if (fragment.isCaptioned()) {
      const section: SectionFragment = fragment.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
      if (section) {
        section
          .getClauses()
          .forEach((clause: ClauseFragment) => clause.markChildrenForCheck((f: Fragment) => f.isCaptioned()));
      }
    }
  }

  /**
   * Marks fragments that need to be updated in the next change detection cycle.
   *
   * @param fragment {Fragment}   Deleted fragment
   */
  private _markDeletesForCheck(fragment: Fragment): void {
    fragment.markForCheck();
    if (fragment.isCaptioned()) {
      const parent: Fragment = this.find(fragment.parentId);
      if (parent) {
        parent.markChildrenForCheck((f: Fragment) => f.isCaptioned());
      }
    }
  }

  /**
   * Retrieve a fragment from the cache, returning null if the fragment is unknown.
   *
   * @param id    {UUID}          The fragment's ID
   * @param cache {FragmentCache} The cache to check.
   * @returns     {T}             The fragment, or null
   */
  private _find(id: UUID, cache?: FragmentCache): T {
    const entry: Entry = (cache || this._cacheManager.getCurrentViewCache()).find(id);
    return entry ? (entry.live as T) : null;
  }

  /**
   * Retrieve a fragment from the cache, returning null if the fragment is unknown.
   *
   * @param id   {UUID}   The fragment's ID
   * @param time {number} The time at which the cache is valid, or null for current view time.
   * @returns    {T}      The fragment, or null
   */
  public find(id: UUID, time?: number): T {
    const cache: FragmentCache = time
      ? this._cacheManager.getHistoricalCache(time)
      : this._cacheManager.getCurrentViewCache();
    const entry: Entry = cache.find(id);
    return entry ? (entry.live as T) : null;
  }

  /**
   * Fetch the fragment subtrees whose root has the given ID from the webservice.
   * Versions of this subtree to the given depth are returned, sorted in increasing
   * time order.  This can be specified in params, along with a depth to which the
   * subtree should be fetched; this defaults to the full tree.
   *
   * @param id      {UUID}                  The ID of the root
   * @param params  {FragmentFetchParams}   The fetch params
   * @param message {string?}               An optional error message
   * @returns       {Promise<T[]>}          A promise resolving with the subtrees
   */
  public fetch(
    id: UUID,
    params: FragmentFetchParams = {},
    message?: string,
    errorHandler?: (response: HttpResponse<any>) => Promise<never>
  ): Promise<T[]> {
    const idString: string = id != null ? id.value : null;
    message = message || 'Failed to fetch data';

    // Deal with null params to keep webservice happy
    let httpParams: HttpParams = new HttpParams();
    if (params.validFrom !== undefined) {
      httpParams = httpParams.append('validFrom', params.validFrom.toString());
    }
    if (params.depth !== undefined) {
      httpParams = httpParams.append('depth', params.depth.toString());
    }
    if (params.validTo !== undefined) {
      httpParams = httpParams.append('validTo', params.validTo.toString());
    }

    errorHandler = errorHandler
      ? errorHandler
      : (response: HttpResponse<any>) => {
          this._handleError(response, message, 'fragment-error');
          return Promise.reject(response);
        };

    return this._http
      .get(`${FragmentService.ENDPOINT}/${idString}`, {
        params: httpParams,
        observe: 'response',
      })
      .toPromise()
      .then((response: HttpResponse<any>) => {
        const startTime: number = isNaN(params.validFrom) ? Date.now() : params.validFrom;
        const endTime: number = isNaN(params.validTo) ? Infinity : params.validTo;
        const res: T[] = FragmentMapper.deserialiseAndUnbucket(response.body, startTime, endTime) as T[];
        return res;
      }, errorHandler)
      .catch(errorHandler);
  }

  /**
   * Fetch the latest version of the subtree spanned by the fragment with ID id.  The fetch params are interpreted as
   * in fetch(). If a non-null fragment is returned, it is inserted into the relevant caches (live cache for a live
   * fragment, or any existing historical caches for a non-live fragment) for propagation to the app.
   *
   * @param id         {UUID}                  The ID of the root
   * @param params     {FragmentFetchParams}   The fetch params
   * @param message    {string?}               An optional error message
   * @returns          {Promise<T>}            A promise resolving with the latest subtree
   */
  public fetchLatest(id: UUID, params: FragmentFetchParams = {}, message?: string): Promise<T> {
    const errorHandler = (err) => {
      Logger.error('fragment-error', `failed to fetch ${id.value}`, err);
      return Promise.reject(err);
    };

    // If the request is historical, check the relevant cache:
    if (params.validTo && params.validFrom === params.validTo) {
      const cache: FragmentCache = this._cacheManager.getHistoricalCache(params.validTo);
      const cached: Fragment = cache.find(id) ? cache.find(id).live : null;
      if (cached && (params.depth === 0 || (cached.type !== FragmentType.DOCUMENT && cached.hasChildren()))) {
        return Promise.resolve(cached as T);
      }
    }

    return this.fetch(id, params, message)
      .then((trees: T[]) => {
        if (trees.length === 0) {
          return null;
        }

        const latest: T = trees[trees.length - 1];

        const cachesForLatest: FragmentCache[] = latest.validTo
          ? this._cacheManager.getHistoricalCachesForFragmentVersion(latest)
          : [this._cacheManager.getLiveCache()];
        cachesForLatest.forEach((c) => c.insertTree(latest) as T);

        return latest;
      })
      .then((data) => data, errorHandler)
      .catch(errorHandler);
  }

  /**
   * Combine any direct children contiguous text/superscript/subscript/memo fragments.
   *
   * @param fragment            {Fragment} The fragment whose children are being combined.
   * @param caret               {Caret}    Optionally, a caret whose fragment field may need updating, as it may
   * be referring to a fragment which will be deleted in this operation.
   * @param ignoreCaretFragment {boolean}  If true, do not affect the fragment which contains the caret.
   */
  public mergeChildren(fragment: Fragment, caret?: Caret, ignoreCaretFragment?: boolean): void {
    const updated: Fragment[] = [];
    const deleted: Fragment[] = [];

    if (fragment === null) {
      return;
    }

    for (let i: number = 0; i < fragment.children.length; i++) {
      if (fragment.children[i].isMergeable()) {
        const child = fragment.children[i];
        let sibling: Fragment = child.nextSibling();
        let childUpdated: boolean = false;
        while (sibling && (sibling.type === child.type || (!sibling.length() && sibling.isMergeable()))) {
          if (caret && caret.fragment.equals(sibling)) {
            if (ignoreCaretFragment) {
              break;
            }
            caret.fragment = child;
            caret.offset += child.length();
          }
          deleted.push(sibling);
          child.value += sibling.value;
          sibling = sibling.nextSibling();
          childUpdated = true;
          i++;
        }
        // Only add fragments to be updated if a change has been made
        if (childUpdated) {
          updated.push(child);
        }
      } else if (fragment.children[i].is(FragmentType.ANCHOR)) {
        const anchor: AnchorFragment = fragment.children[i] as AnchorFragment;
        if (anchor.hasBeenResolved) {
          deleted.push(anchor);
        } else if (this._isAnchorForEmptyRange(anchor)) {
          deleted.push(anchor, this.find(anchor.otherAnchorId));
        }
      } else if (
        fragment.children[i].is(FragmentType.EQUATION) &&
        (fragment.children[i] as EquationFragment).inline &&
        (fragment.children[i] as EquationFragment).source.length() < 1
      ) {
        deleted.push(fragment.children[i]);
      }
    }

    this.update(updated);
    // Always mergeable children which cannot have discussions, no need to validate
    this.delete(deleted);
  }

  /**
   * Checks if the given anchor fragment is the start or end fragment of an empty range, or a range that contains
   * only empty text fragments.
   *
   * @param anchor  {AnchorFragment}  The given anchor fragment to check
   * @returns       {boolean}         True if the anchor's range is empty
   */
  private _isAnchorForEmptyRange(anchor: AnchorFragment): boolean {
    const anchors: Fragment[] = anchor.isFirstAnchor
      ? [anchor, this.find(anchor.otherAnchorId) as Fragment]
      : [this.find(anchor.otherAnchorId) as Fragment, anchor];
    const ancestor: Fragment = Fragment.commonAncestorOf(anchors[0], anchors[1]);
    let returnvalue: boolean = true;
    if (ancestor) {
      ancestor.iterateDown(anchors[0], anchors[1], (f: Fragment) => {
        if (
          !f.is(FragmentType.CLAUSE, FragmentType.SECTION, FragmentType.ANCHOR) &&
          !(f.is(FragmentType.TEXT) && f.length() === 0)
        ) {
          returnvalue = false;
        }
      });
    }
    return returnvalue;
  }

  /**
   * Create, update or delete multipel fragments in one request.
   *
   * This does not yet support versioning as we haven't needed it yet, but there is no particular
   * reason not to update it to include versioning if need be.
   *
   * @param diffSources
   * @param message
   * @returns
   */
  public batchHandleFragmentOperations(
    diffSources: FragmentOperationSource[],
    message?: string
  ): Promise<HttpResponse<any>> {
    message =
      message ||
      `Sorry, something went wrong and we were unable to perform an update operation. Please try again in a moment.`;

    if (diffSources.length) {
      this._setSavingState(true);
    }

    const deletedFragments: Fragment[] = diffSources
      .filter((operationSource: FragmentOperationSource) => operationSource.operation === DiffOperation.DELETE)
      .map((operationSource: FragmentOperationSource) => operationSource.fragment);
    const diffs: FragmentDiff[] = [];

    try {
      for (const source of diffSources) {
        const fragment: Fragment = source.fragment;
        switch (source.operation) {
          case DiffOperation.CREATE:
            diffs.push(...this._handleCreateDiffOperation(fragment));
            break;
          case DiffOperation.UPDATE:
            diffs.push(...this._handleUpdateDiffOperation(fragment));
            break;
          case DiffOperation.DELETE:
            diffs.push(...this._handleDeleteDiffOperation(fragment, deletedFragments));
            break;
          default:
            throw new Error('Unsupported operation in batch fragment update');
        }
      }
    } catch (e) {
      const error: Error = e;
      return this._toPromise(throwError(error), error.message, 'fragment-error');
    }

    return this._queueDiffs(diffs, message, true);
  }

  /**
   * Create the diffs for when creating a fragment.
   */
  private _handleCreateDiffOperation(fragment: Fragment): FragmentDiff[] {
    const diffs: FragmentDiff[] = [];

    fragment.iterateDown(null, null, (iterator: Fragment) => {
      const sec: Fragment = iterator.findAncestorWithType(FragmentType.SECTION);
      iterator.sectionId = sec ? sec.id : null;
      const doc: Fragment = iterator.findAncestorWithType(FragmentType.DOCUMENT);
      iterator.documentId = doc ? doc.id : null;

      const cached: Fragment = this._find(iterator.id, this._cacheManager.getLiveCache());
      if (!cached) {
        diffs.push(FragmentDiff.create(iterator));
        // Pre-insert so callers can keep their object references.
        this._cacheManager.getLiveCache().insert(iterator);
      } else {
        diffs.push(FragmentDiff.update(cached, iterator));
      }
    });

    return diffs;
  }

  /**
   * Create the diffs for when updating a fragment.
   */
  private _handleUpdateDiffOperation(fragment: Fragment): FragmentDiff[] {
    const entry: Entry = this._cacheManager.getLiveCache().find(fragment.id);
    const diffs: FragmentDiff[] = [];

    if (entry && entry.live) {
      let peek: Fragment = entry.history.peek();
      // if there is a pending update, the peek may be out of date, and needs
      // to have any pending updates applied to it:
      const pendingDiff: FragmentDiff = FragmentService._pendingDiffs.getPendingUpdate(fragment.id.value);
      if (pendingDiff) {
        peek = FragmentMapper.clone(peek);
        DiffUtils.patch(pendingDiff.fields, peek);
      }
      diffs.push(FragmentDiff.update(peek, fragment));
    } else {
      // Pre-insert so callers can keep their object references.
      this._cacheManager.getLiveCache().insert(fragment);
      diffs.push(FragmentDiff.create(fragment));
    }

    return diffs;
  }

  /**
   * Create the diffs for when deleting a fragment.
   */
  private _handleDeleteDiffOperation(fragment: Fragment, deletedFragments: Fragment[]): FragmentDiff[] {
    const diffs: FragmentDiff[] = [];

    diffs.push(FragmentDiff.delete(fragment));
    // In order to keep the reference tables updated, we check if the deleted fragment contains any inline reference
    // fragments and, if so, we mark them as deleted.
    if (fragment.is(...FragmentService.FRAGMENTS_THAT_CAN_CONTAIN_INLINE_REFERENCES)) {
      fragment.iterateDown(null, null, (frag: Fragment) => {
        if (frag.is(FragmentType.INLINE_REFERENCE)) {
          (frag as InlineReferenceFragment).deleted = true;
          diffs.push(...this._handleUpdateDiffOperation(frag));
        }
        if (frag.is(FragmentType.INTERNAL_INLINE_REFERENCE)) {
          (frag as InternalInlineReferenceFragment).deleted = true;
          diffs.push(...this._handleUpdateDiffOperation(frag));
        }
      });
    } else if (fragment.is(FragmentType.ANCHOR)) {
      // If we delete one of a pair of anchor fragments, we also want to delete the other of the pair.
      if (
        fragment.is(FragmentType.ANCHOR) &&
        deletedFragments.findIndex((frag: Fragment) => frag.id.equals((frag as AnchorFragment).otherAnchorId)) === -1
      ) {
        const otherAnchor: Fragment = this.find((fragment as AnchorFragment).otherAnchorId);
        if (otherAnchor) {
          diffs.push(FragmentDiff.delete(otherAnchor));
        }
      }
    }

    return diffs;
  }

  /**
   * Create one or more fragments.  Delegates to FragmentService::update() if the
   * fragment is already present in the cache.
   *
   * @param fragments {Fragment|Fragment[]}   One or an array of fragments to create
   * @param message   {string?}               A message to display on failure
   * @returns         {Promise<number>}       Promise resolving to the HTTP status
   */
  public create(fragments: Fragment | Fragment[], message?: string): Promise<HttpResponse<any>> {
    return this.batchHandleFragmentOperations(
      FragmentOperationSource.create(fragments),
      message ||
        `Sorry, something went wrong and we were unable to perform a create operation. Please try again in a moment.`
    );
  }

  /**
   * Update one or more fragments.
   *
   * @param fragments {Fragment|Fragment[]}   One or an array of fragments to update
   * @param message   {string?}               A message to display on failure
   * @returns         {Promise<number>}       Promise resolving to the HTTP status
   */
  public update(fragments: Fragment | Fragment[], message?: string): Promise<HttpResponse<any>> {
    return this.batchHandleFragmentOperations(
      FragmentOperationSource.update(fragments),
      message ||
        `Sorry, something went wrong and we were unable to perform an update operation. Please try again in a moment.`
    );
  }

  /**
   * Fragments should already be validated by {@link FragmentDeletionValidationService.shouldDeleteFragmentsWithSubtrees}
   * or {@link FragmentDeletionValidationService.shouldDeleteFragmentsWithoutSubtrees}. This is a convenience method
   * that should be used whenever fragments to be deleted have been validated - note no validation is done within this
   * method, and this is just for developer ease.
   *
   * @param fragments {Fragment|Fragment[]}   One or an array of fragments to delete
   * @param message   {string?}               A message to display on failure
   * @returns         {Promise<number>}       Promise resolving to the HTTP status
   */
  public deleteValidatedFragments(fragments: Fragment | Fragment[], message?: string): Promise<HttpResponse<any>> {
    return this.delete(fragments, message);
  }

  /**
   * Delete one or more fragments. This method should only be called if the fragments should not be validated by the
   * {@link FragmentDeletionValidatorService}, otherwise use {@link deleteValidatedFragments}.
   *
   * @param fragments {Fragment|Fragment[]}   One or an array of fragments to delete
   * @param message   {string?}               A message to display on failure
   * @returns         {Promise<number>}       Promise resolving to the HTTP status
   */
  public delete(fragments: Fragment | Fragment[], message?: string): Promise<HttpResponse<any>> {
    return this.batchHandleFragmentOperations(
      FragmentOperationSource.delete(fragments),
      message ||
        `Sorry, something went wrong and we were unable to perform a delete operation. Please try again in a moment.`
    );
  }

  /**
   * Create a new version of a fragment's subtree.
   *
   * @param id   {UUID}     The fragment's ID
   * @param name {string}   The version name
   */
  public version(
    fragments: Fragment | Fragment[],
    versionRequest: VersionRequest,
    message?: string
  ): Promise<HttpResponse<any>> {
    fragments = this._toArray(fragments);
    message = message || `Sorry, something went wrong and we were unable to perform a version operation.`;
    if (fragments.length) {
      this._setSavingState(true);
    }

    const diffs: FragmentDiff[] = [];
    for (const fragment of fragments) {
      diffs.push(FragmentDiff.version(fragment, versionRequest));
    }

    return this._queueDiffs(diffs, message, true);
  }

  /**
   * Flush the pending diff buffer and send to the webservice.  On resolution, subscribes to
   * onFlush() will be notified with the HTTP response code.
   *
   * @param message {string?}         An optional error message
   * @returns       {Promise<number>} A promise resolving to the HTTP status
   */
  private flush(message?: string): Promise<HttpResponse<any>> {
    let flushQueue: FragmentDiff[];

    if (!FragmentService._currentFlushSubject) {
      // No flush currently in progress
      FragmentService._currentFlushSubject = FragmentService._nextFlushSubject;
      FragmentService._nextFlushSubject = null;

      const pendingDiffManager: FragmentDiffManager = new FragmentDiffManager();
      flushQueue = FragmentService._flushQueue.splice(0);
      flushQueue.forEach((diff: FragmentDiff) => pendingDiffManager.push(diff));
      FragmentService._flushingDiffs = pendingDiffManager.extract();

      if (!FragmentService._flushingDiffs.length) {
        const currentFlush = FragmentService._currentFlushSubject || new Subject<HttpResponse<any>>();
        FragmentService._currentFlushSubject = null;

        currentFlush.next(new HttpResponse<any>({body: '', status: HttpStatus.OK}));
        currentFlush.complete();
        return this._toPromise(currentFlush, message, 'fragment-error', true);
      }

      if (!FragmentService._currentFlushSubject) {
        FragmentService._currentFlushSubject = new Subject<HttpResponse<any>>();
      }
    } else {
      if (!FragmentService._nextFlushSubject) {
        FragmentService._nextFlushSubject = new Subject<HttpResponse<any>>();
      }
      return this._toPromise(FragmentService._nextFlushSubject, message, 'fragment-error', true);
    }

    const errorHandler = (response: HttpErrorResponse) => {
      // Create a new diff manager and put all failed diffs and pending diffs together
      const oldDiffs: FragmentDiff[] = this._toArray(FragmentService._flushingDiffs);
      const newDiffs: FragmentDiff[] = this._toArray(FragmentService._pendingDiffs.extract());
      const allDiffs: FragmentDiff[] = [...oldDiffs, ...newDiffs];

      if (!this._shouldRetry(response.status)) {
        this._broadcastFailure(response, allDiffs);
        return;
      }

      FragmentService._pendingDiffs = new FragmentDiffManager();
      FragmentService._pendingDiffs.push(...oldDiffs);
      FragmentService._pendingDiffs.push(...newDiffs);
      FragmentService._flushingDiffs = null;

      // Tell listeners of the error
      const flushSubject = FragmentService._currentFlushSubject;

      FragmentService._currentFlushSubject = null;
      FragmentService._flushingDiffs = null;

      flushSubject.error(response);
      FragmentService._flushSubject.next(response.status);
    };

    this._sendDiffs(FragmentService._flushingDiffs)
      .then((response: HttpResponse<any>) => {
        const flushSubject = FragmentService._currentFlushSubject;

        FragmentService._currentFlushSubject = null;
        FragmentService._flushingDiffs = null;

        flushSubject.next(response);
        flushSubject.complete();
        FragmentService._flushSubject.next(response.status);
        this._errorRecovered(message, true);

        if (FragmentService._nextFlushSubject) {
          this.flush(message);
        }
      }, errorHandler)
      .catch(errorHandler);

    return this._toPromise(FragmentService._currentFlushSubject, message, 'fragment-error', true);
  }

  /**
   * Returns true if the undo buffer can be reverted by a given number of steps.
   * Pass negative steps for rollforwards.
   *
   * @param steps {number}    The number of steps to query
   * @returns     {boolean}   True if revertible by steps
   */
  public isRevertible(steps: number): boolean {
    const buffer: CacheEntry<UUID[]> = FragmentService.UNDO_BUFFER;

    return (
      buffer.history.isRevertible(steps) || // Committed changes
      (steps === 0 && buffer.live && buffer.live.length > 0)
    ); // Pending changes
  }

  /**
   * Revert a number of diff packets sent to the server.  Pass negative steps to rollforward.
   *
   * TODO: currently does not properly detect when the changes to be reverted have not yet
   * been sent to the backend, sends diffs anyway.  This is harmless as these diffs always evaluate
   * to no-ops.
   *
   * @param steps   {number}            The number of steps to revert
   * @param message {string?}           An optional error message to display
   * @returns       {Promise<number>}   A promise resolving to the HTTP status code
   */
  public revert(steps: number, message?: string): Promise<HttpResponse<any>> {
    message =
      message || `Sorry, something went wrong and we were unable to perform the undo. Please try again in a moment.`;
    steps = Math.min(Math.max(steps, -1), 1); // Clamp the steps from -1 to 1

    const buffer: CacheEntry<UUID[]> = FragmentService.UNDO_BUFFER;
    const local: boolean = steps === 0; // never 0: see TODO above.
    const peek: number = steps > 0 ? steps - 1 : steps;
    const pendingDiffs: FragmentDiff[] = this._flushPendingDiffsToHistory(true); // Throw history away, as about to be reverted
    const ids: UUID[] = (local ? buffer.live : buffer.history.peek(peek)) || [];

    if (!local && pendingDiffs.length > 0) {
      FragmentService._flushQueue.push(...pendingDiffs);
    }

    // Check all relevant buffers are revertible.
    if (!this.isRevertible(steps)) {
      return Promise.reject(HttpStatus.BAD_REQUEST);
    }
    for (const id of ids) {
      const entry: Entry = this._cacheManager.getLiveCache().find(id);
      if (!entry || (!entry.history.isRevertible(steps) && !local)) {
        return Promise.reject(HttpStatus.CONFLICT);
      }
    }

    buffer.history.revert(steps);
    buffer.live = null;

    const diffs: FragmentDiff[] = [];
    for (const id of ids) {
      const entry: Entry = this._cacheManager.getLiveCache().find(id);
      const target: Fragment = entry.history.revert(steps);

      if (!target && entry.live) {
        diffs.push(FragmentDiff.delete(entry.live));
      } else if (target && entry.live) {
        diffs.push(FragmentDiff.update(entry.live, target));
      } else if (target && !entry.live) {
        diffs.push(FragmentDiff.create(target));
      } else if (!local) {
        throw new Error(
          `Something went wrong:  The fragment with ID ${id.value} had neither` +
            ` a live nor previous value, yet was not removed by diff collation.`
        );
      }
    }

    // Reverse the order of applying the diffs if undoing
    if (steps >= 0) {
      diffs.reverse();
    }

    if (diffs.length && !local) {
      this._setSavingState(true);
    }

    return local ? this._applyDiffs(diffs) : this._queueDiffs(diffs, message, false);
  }

  /**
   * Subscribe to diff flush events.  An optional predicate can be passed to filter
   * which HTTP status codes are published to the given callback.
   *
   * @param callback  {Callback<number>}     The callback
   * @param predicate {Predicate<number>?}   An optional filter predicate
   * @returns         {Subscription}         The subscription object
   */
  public onFlush(callback: Callback<number>, predicate?: Predicate<number>): Subscription {
    return this._makeSubscription(FragmentService._flushSubject, callback, predicate, true);
  }

  /**
   * Subscribe to fragment creation events.  An optional predicate can be passed to
   * filter which fragments are published to the given callback.
   *
   * @param callback  {Callback<T>}     The callback
   * @param predicate {Predicate<T>?}   An optional filter predicate
   * @returns         {Subscription}    The subscription object
   */
  public onCreate(callback: Callback<T>, predicate?: Predicate<T>): Subscription {
    return this._makeSubscription(FragmentService._createSubject, callback, predicate, false);
  }

  /**
   * Subscribe to fragment update events.  An optional predicate can be passed to
   * filter which fragments are published to the given callback.
   *
   * @param callback  {Callback<T>}     The callback
   * @param predicate {Predicate<T>?}   An optional filter predicate
   * @returns         {Subscription}    The subscription object
   */
  public onUpdate(callback: Callback<T>, predicate?: Predicate<T>): Subscription {
    return this._makeSubscription(FragmentService._updateSubject, callback, predicate, false);
  }

  /**
   * Subscribe to fragment deletion events.  An optional predicate can be passed to
   * filter which fragments are published to the given callback.
   *
   * @param callback  {Callback<T>}     The callback
   * @param predicate {Predicate<T>?}   An optional filter predicate
   * @returns         {Subscription}    The subscription object
   */
  public onDelete(callback: Callback<T>, predicate?: Predicate<T>): Subscription {
    return this._makeSubscription(FragmentService._deleteSubject, callback, predicate, false);
  }

  /**
   * Subscribe to failure events. This publishes an array of the ids of failed diffs, if any.
   * It publishes an empty array on success.
   *
   * @param callback  {Function}     The callback
   * @returns         {Subscription} The subscription object
   */
  public onFailure(callback: (err: HttpErrorResponse, uuids: UUID[]) => void): Subscription {
    const callbackWithBundlesArgs = (bundle: any[]) => {
      return callback(bundle[0], bundle.slice(1));
    };

    return this._makeSubscription(FragmentService._failureSubject, callbackWithBundlesArgs, null, false);
  }

  /**
   * Subscribe to fragment selection events.  An optional predicate can be passed to
   * filter which fragments are published to the given callback.
   *
   * @param callback  {Callback<T>}     The callback
   * @param predicate {Predicate<T>?}   An optional filter predicate
   * @returns         {Subscription}    The subscription object
   */
  public onSelection(callback: Callback<T>, predicate?: Predicate<T>): Subscription {
    return this._makeSubscription(this._selectSubject, callback, predicate, true);
  }

  /**
   * Subscribe to fragment saving events.
   *
   * @param callback  {Callback<boolean>}   The callback
   * @returns         {Subscription}        The subscription object
   */
  public onSaving(callback: Callback<SavingEvent>): Subscription {
    return this._makeSubscription(FragmentService.saving, callback);
  }

  /**
   * Set the currently selected fragment, then publish to the selection subject.
   *
   * @param fragment {T}   The fragment to select
   */
  public setSelected(fragment: T): void {
    let cached: T;
    if (fragment) {
      cached = this.find(fragment.id);
    }

    this._selectSubject.next(cached || fragment);
  }

  /**
   * Return the currently selected fragment.
   *
   * @returns {T}   The selected fragment
   */
  public getSelected(): T {
    return this._selectSubject.getValue();
  }

  /**
   * Broadcasts the HTTP error response and un-sent diffs to subscribers of _failureSubject.
   *
   * @param err   {HttpErrorResponse} The HTTP error
   * @param diffs {FragmentDiff[]}    The un-sent diffs
   */
  private _broadcastFailure(err: HttpErrorResponse, diffs: FragmentDiff[]): void {
    const uuids: UUID[] = diffs.map((diff: FragmentDiff) => diff.id);
    const bundle: any = [err as any].concat(uuids);
    FragmentService._failureSubject.next(bundle);
  }

  /**
   * Determines whether the failed network request should be re-sent based on the HTTP status code
   * and the number of retries.
   *
   * @param status {number}  HTTP status code
   * @returns      {boolean} True if the request should be re-sent
   */
  private _shouldRetry(status: number): boolean {
    let retry: boolean;
    switch (status) {
      case HttpStatus.FORBIDDEN:
        {
          retry = false;
        }
        break;
      default:
        retry = true;
    }
    return retry && FragmentService._flushRetryCount <= FragmentService.RETRY_LIMIT;
  }

  /**
   * Respond to websocket connect and disconnect events.
   *
   * @param connected {boolean}   True if a connect event
   */
  protected _onWebsocketConnect(connected: boolean): void {
    FragmentService._subscriptions.splice(0).forEach((subscription: Subscription) => subscription.unsubscribe());

    if (connected) {
      const root: Fragment = this._cacheManager.getLiveCache().getRoot();
      if (root) {
        this.fetchLatest(root.id, {depth: 0});
      }
      FragmentService._subscriptions.push(
        this._websocketService.subscribe(FragmentService.WS_TOPIC, (json: any) => {
          const diffs: FragmentDiff[] = json.map((j: any) => FragmentDiff.deserialise(j));
          const ids: UUID[] = diffs.map((diff: FragmentDiff) => diff.id);

          this._cacheManager.getLiveCache().reset(...ids);
          this._applyDiffs(diffs);
        })
      );
    }
  }

  /**
   * Resolve an array of FragmentDiff by dispatching to the FragmentCache and broadcasting
   * to the appropriate subject.  The diffs are applied in order.
   *
   * @param diffs {FragmentDiff[]}    The fragment diffs
   * @returns     {Promise<number>}   A promise resolving to HTTP 200
   */
  private _applyDiffs(diffs: FragmentDiff[]): Promise<HttpResponse<any>> {
    for (const diff of diffs) {
      switch (diff.operation) {
        case DiffOperation.CREATE:
          {
            let cached: Fragment = this._find(diff.id, this._cacheManager.getLiveCache());
            if (!cached) {
              diff.fields.id = diff.id.value;
              cached = this._cacheManager.getLiveCache().insert(FragmentMapper.deserialise(diff.fields));
            }
            FragmentService._createSubject.next(cached);
          }
          break;

        case DiffOperation.UPDATE:
          {
            const cached: Fragment = this._find(diff.id, this._cacheManager.getLiveCache());
            if (cached) {
              diff.applyTo(cached, this._cacheManager.getLiveCache());
              FragmentService._updateSubject.next(cached);
            }
          }
          break;

        case DiffOperation.DELETE:
          {
            if (this._find(diff.id, this._cacheManager.getLiveCache())) {
              const cached: Fragment = this._cacheManager.getLiveCache().remove(diff.id);
              FragmentService._deleteSubject.next(cached);
            }
          }
          break;

        case DiffOperation.VERSION:
          {
            // Do nothing.
          }
          break;

        default: {
          throw new TypeError(`Unimplemented DiffOperation '${diff.operation}'!`);
        }
      }
    }

    const response: HttpResponse<any> = new HttpResponse({
      body: null,
      headers: null,
      status: HttpStatus.OK,
    });

    // Return promise so this function can be chained into _queueDiffs if needed.
    return Promise.resolve(response);
  }

  /**
   * Goes through the cache for the fragment of the given id (that meets the given conditions) and runs the given callback for each entry.
   * It returns true if we can't find any fragments that meet the given conditions, and otherwise returns the given returnValue.
   */
  private _rewriteHistory(
    id: UUID,
    type: FragmentType,
    condition: boolean,
    callback: Function,
    returnValue: boolean
  ): boolean {
    const liveCache: FragmentCache = this._cacheManager.getLiveCache();
    if (this._find(id, liveCache) && this._find(id, liveCache).is(type) && condition) {
      if (this._cacheManager.getLiveCache().find(id)) {
        this._cacheManager
          .getLiveCache()
          .find(id)
          .history.forEach((f: Fragment) => callback(f));
      }
      return returnValue;
    }
    return true;
  }

  /**
   * Extracts and flushes the pending diffs to the history, returning the
   * extracted diff information.
   *
   * @param commit {boolean}        True if diffs should be commited to the CACHE
   * @return       {FragmentDiff[]} Pending diffs
   */
  private _flushPendingDiffsToHistory(commit: boolean): FragmentDiff[] {
    const pendingDiffs: FragmentDiff[] = FragmentService._pendingDiffs.extract();
    const buffer: CacheEntry<UUID[]> = FragmentService.UNDO_BUFFER;
    const liveCache: FragmentCache = this._cacheManager.getLiveCache();

    buffer.live = pendingDiffs
      .filter((diff: FragmentDiff) => diff.isRevertibleFor(this._find(diff.id, liveCache)))
      .filter((diff: FragmentDiff) => {
        return this._rewriteHistory(
          diff.id,
          FragmentType.CLAUSE,
          Object.keys(diff.fields).includes('value'),
          (f: Fragment) => {
            (f as ClauseFragment).background = diff.fields.value;
          },
          Object.keys(diff.fields).length !== 1
        );
      })
      .filter((diff: FragmentDiff) => diff.operation !== DiffOperation.VERSION)
      .filter((diff: FragmentDiff) => {
        return this._rewriteHistory(
          diff.id,
          FragmentType.ANCHOR,
          diff.fields.hasBeenResolved,
          (f: Fragment) => {
            (f as AnchorFragment).hasBeenResolved = true;
          },
          true
        );
      })
      .map((diff: FragmentDiff) => diff.id);

    if (commit && buffer.live && buffer.live.length > 0 && pendingDiffs.length > 0) {
      this._cacheManager.getLiveCache().commit(...buffer.live);
      buffer.history.push(buffer.live);
      buffer.live = null;
    }

    return pendingDiffs;
  }

  /**
   * Helper function to add diffs to the pending set and simplify where appropriate. If these
   * are the first diffs in the set, the interval is started.
   *
   * @param diffs   {FragmentDiff[]}    The diffs to add
   * @param message {string}            An error message to display on failure
   * @returns       {Promise<number>}   A promise resolving to the HTTP status code
   */
  private _queueDiffs(diffs: FragmentDiff[], message: string, commit: boolean): Promise<HttpResponse<any>> {
    FragmentService._pendingDiffs.push(...diffs);

    const flushToQueue = () => {
      const pendingDiffs = this._flushPendingDiffsToHistory(commit);

      // Apply the diff set to the history
      if (pendingDiffs.length > 0) {
        FragmentService._flushQueue.push(...pendingDiffs);
      }
    };

    this._applyDiffs(diffs);

    if (!commit) {
      flushToQueue();
    }

    if (FragmentService._debounceTimer === null) {
      FragmentService._debounceTimer = setTimeout(() => {
        // Reset the timer
        clearTimeout(FragmentService._debounceTimer);
        FragmentService._debounceTimer = null;

        flushToQueue();

        // Make a request to start flushing the queues
        const inFlightDiffSubjects = FragmentService._pendingDiffsSubjects.splice(
          0,
          FragmentService._pendingDiffsSubjects.length
        );

        this.flush(message).then(
          (response: HttpResponse<any>) => {
            FragmentService._flushRetryCount = 0;
            inFlightDiffSubjects.forEach((subject) => {
              subject.next(new HttpResponse<any>(response));
              subject.complete();
            });
            this._removePendingErrors();
          },
          (err) => {
            // Append the existing diffs to the start of the pending diffs so that they remain in order
            FragmentService._pendingDiffsSubjects = [...inFlightDiffSubjects, ...FragmentService._pendingDiffsSubjects];
            FragmentService._flushRetryCount += 1;
            this._queueDiffs([], message, commit);

            this._addPendingErrors(diffs);

            if (FragmentService._flushRetryCount > FragmentService.RETRY_LIMIT) {
              Logger.error(
                'retries-failed-error',
                `${FragmentService._flushRetryCount} Failed retries for diff packet`,
                err
              );
            }
          }
        );
      }, environment.debounceTime);
    }

    const completedSubject = new Subject<HttpResponse<any>>();
    FragmentService._pendingDiffsSubjects.push(completedSubject);

    return this._toPromise(completedSubject, message, 'fragment-error');
  }

  /**
   * Adds the pending-with-error (red text) class to the fragments that haven't
   * been saved to the back end correctly.
   *
   * @param diffs   {FragmentDiff[]}    The diffs to add red text to
   */
  private _addPendingErrors(diffs: FragmentDiff[]): void {
    for (const diff of diffs) {
      const fragment = this._find(diff.id) as Fragment;
      if (fragment && fragment.component && fragment.component.element) {
        const element = fragment.component.element;
        FragmentService.elementsWithErrors.push(element);
        element.classList.add('pending-with-error');
      }
    }
    this._setSavingState(true, true);
  }

  /**
   * Removes red text from all fragments that had it, when they have been saved to the
   * back end correctly.
   */
  private _removePendingErrors(): void {
    for (const element of FragmentService.elementsWithErrors) {
      element.classList.remove('pending-with-error');
    }
    FragmentService.elementsWithErrors = [];
    this._setSavingState(false);
  }

  private _setSavingState(saving: boolean, pendingErrors: boolean = false): void {
    FragmentService.saving.next({
      saving: saving,
      pendingErrors: pendingErrors,
    });
  }

  /**
   * Helper function to handle serialisation, sending and error handling of fragment diffs.
   *
   * @param diffs   {FragmentDiff[]}    The diffs to send
   * @returns       {Promise<number>}   A promise resolving with the HTTP status code
   */
  private _sendDiffs(diffs: FragmentDiff[]): Promise<HttpResponse<any>> {
    if (diffs.length > 0) {
      const json: any[] = diffs.map((diff: FragmentDiff) => diff.serialise());

      return this._http
        .patch(FragmentService.ENDPOINT, json, {
          headers: this._httpHeaders,
          observe: 'response',
        })
        .toPromise();
    } else {
      return Promise.resolve(new HttpResponse({status: 200}));
    }
  }
}
