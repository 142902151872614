import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FragmentModule} from 'app/fragment/fragment.module';
import {TableUpdaterComponent} from './table-updater.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, MatDialogModule, FragmentModule],
  declarations: [TableUpdaterComponent],
  exports: [TableUpdaterComponent],
})
export class TableUpdaterModule {}
