import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReviewStatusToggleModule} from 'app/dashboard/dashboard-table/review-status-toggle/review-status-toggle.module';
import {VersionReviewStatusToggleComponent} from './version-review-status-toggle.component';

@NgModule({
  imports: [CommonModule, ReviewStatusToggleModule],
  declarations: [VersionReviewStatusToggleComponent],
  exports: [VersionReviewStatusToggleComponent],
})
export class VersionReviewStatusToggleModule {}
