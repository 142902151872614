import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {DocumentFragment} from 'app/fragment/types';
import {JiraIssue} from 'app/interfaces';
import {DocumentService} from 'app/services/document.service';
import {JiraService} from 'app/services/jira.service';
import {DisabledUserService} from 'app/services/user/disabled-user.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-jira-information',
  templateUrl: './jira-information.component.html',
  styleUrls: ['./jira-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JiraInformationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() document: DocumentFragment;

  public jiraExtras: [string, string][] = [];
  public jiraUrl: string = environment.jiraUrl;

  public ownerHasDisabledStatus: boolean = true;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _jiraService: JiraService,
    private _documentService: DocumentService,
    private _disabledUserService: DisabledUserService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._documentService.onUpdate(this._updateJiraExtras.bind(this)),
      this._disabledUserService
        .onUserStatuses()
        .subscribe((statuses: Record<string, boolean>) => this._setOwnerDisabledStatus(statuses))
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('document')) {
      this._updateJiraExtras();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  private _updateJiraExtras(): void {
    const jiraDocumentId: string = this.document.documentData.metadata['EPIC_ID'];
    if (jiraDocumentId) {
      this._jiraService.getJiraDocument(jiraDocumentId).subscribe((jiraDocument: JiraIssue) => {
        this.jiraExtras = jiraDocument ? Array.from(jiraDocument.extras.entries()) : [];
        this._cdr.markForCheck();
      });
    } else {
      this.jiraExtras = [];
      this._cdr.markForCheck();
    }
  }

  private _setOwnerDisabledStatus(statuses: Record<string, boolean>): void {
    if (this.document && this.document.documentData.owner) {
      this.ownerHasDisabledStatus = statuses[this.document.documentData.owner.value];
    }
  }
}
