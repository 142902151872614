import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {FragmentModule} from 'app/fragment/fragment.module';
import {DiscussionModule} from 'app/sidebar/discussions/discussion/discussion.module';
import {UserModule} from 'app/widgets/user/user.module';
import {SectionCommentsComponent} from './section-comments.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    RouterModule,
    FormsModule,
    UserModule,
    FragmentModule,
    DiscussionModule,
  ],
  declarations: [SectionCommentsComponent],
  exports: [SectionCommentsComponent],
})
export class SectionCommentsModule {}
