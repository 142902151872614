<form #newReferenceForm="ngForm">
  <mat-form-field>
    <textarea
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      autocomplete="off"
      matInput
      placeholder="Title"
      [(ngModel)]="title"
      name="title"
      required
      carsRequiredNonWhitespace
    ></textarea>
    <mat-hint>Title of the publication. Do not include the reference.</mat-hint>
    <mat-error>{{ titleErrorMessage }}</mat-error>
  </mat-form-field>
  <mat-form-field>
    <textarea
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      autocomplete="off"
      matInput
      placeholder="Author(s)"
      [(ngModel)]="author"
      name="author"
    ></textarea>
    <mat-hint>Named authors of the publication. Not required for standards.</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <textarea
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      autocomplete="off"
      matInput
      placeholder="Publisher"
      [(ngModel)]="publisher"
      name="publisher"
    ></textarea>
    <mat-hint>Corporate publisher. For adopted standards (e.g. BS EN) use BSI.</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <input
      autocomplete="off"
      matInput
      placeholder="Reference"
      [(ngModel)]="reference"
      maxLength="32"
      name="reference"
    />
    <mat-hint>E.g. BS 9001, GD 01. Do not include the year of publication.</mat-hint>
    <mat-hint align="end">{{ reference?.length || 0 }} / 32</mat-hint>
  </mat-form-field>

  <cars-document-selector
    [searchType]="DocumentsSearchType.GLOBAL_REFERENCE"
    [showClearButton]="true"
    [initialSelection]="initialSelectedDocument"
    searchPlaceholder="CARS Link"
    (onSelect)="updateSelectedCarsDocument($event)"
    [disabled]="loadingDocument"
  ></cars-document-selector>

  <mat-progress-bar mode="indeterminate" *ngIf="loadingDocument"></mat-progress-bar>

  <button mat-raised-button aria-label="Discard and exit" (click)="close(false)">Discard & Exit</button>
  <button
    mat-raised-button
    color="primary"
    aira-label="Save and close"
    (click)="close(true)"
    [disabled]="titleIsEmpty()"
    class="save"
  >
    Save & Close
  </button>
</form>
