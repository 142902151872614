import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {CarsAction} from 'app/permissions/types/permissions';
import {FragmentService} from 'app/services/fragment.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {DocumentFragment, Fragment, FragmentType, SectionFragment, SectionType} from '../../../fragment/types';
import {NavigationService, NavigationTypes} from '../../../services/navigation.service';
import {SectionService} from '../../../services/section.service';
import {CurrentView, ViewMode} from '../../../view/current-view';

@Component({
  selector: 'cars-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state('in', style({height: '0'})),
      transition('void => *', [style({height: '10px'}), animate(300)]),
      transition('* => void', [animate(300, style({height: '0'}))]),
    ]),
  ],
})
export class SectionListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public document: DocumentFragment = null;
  @Input() public currentView: CurrentView = null;
  @Input() public deletedTab: boolean = false;
  @Input() public type: NavigationTypes = NavigationTypes.DOCUMENT;

  @Output() public tabChangeEvent: EventEmitter<number> = new EventEmitter();

  public readonly FragmentType: typeof FragmentType = FragmentType;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public sectionListForDisplay: Fragment[] = [];

  public draggingSection: Fragment = null;
  private _hoveringSection: Fragment = null;

  private _selectedSection: SectionFragment = null;

  private _liveTab: number = 0;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _navigationService: NavigationService,
    private _sectionService: SectionService,
    private _fragmentService: FragmentService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._sectionService.onSelection((section: SectionFragment) => {
        if (this.type === NavigationTypes.DOCUMENT) {
          this._selectedSection = section;
        }
      }),
      this._fragmentService.onCreate(this._updateListForDisplay.bind(this), this._fragmentServicePredicate),
      this._fragmentService.onUpdate((fragment: Fragment) => {
        if (fragment?.is(FragmentType.SECTION)) {
          this._onSectionUpdate(fragment as SectionFragment);
        }

        this._updateListForDisplay();
      }, this._fragmentServicePredicate),
      this._fragmentService.onDelete(this._updateListForDisplay.bind(this), this._fragmentServicePredicate)
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('document')) {
      this._updateListForDisplay();
    }
  }

  /**
   * Predicate for whether the fragment event should trigger an update to the section display list.
   */
  private _fragmentServicePredicate(fragment: Fragment): boolean {
    return fragment?.is(FragmentType.SECTION_GROUP, FragmentType.SECTION);
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Respond to a section update by routing away from deleted sections.
   *
   * @param section {SectionFragment}   The updated section
   */
  private _onSectionUpdate(section: SectionFragment): void {
    if (!section || !section.equals(this._selectedSection)) {
      return;
    }

    if (section.deleted) {
      const sections: SectionFragment[] = section.getDocument().getSections();
      let index: number = sections.indexOf(section);

      // First search forwards from section to find the first undeleted section;
      // if we don't find one, search backwards instead.
      while (index < sections.length && sections[index].deleted) {
        ++index;
      }
      if (index >= sections.length) {
        index = sections.indexOf(section);
        while (
          index >= 0 &&
          (sections[index].deleted || sections[index].isSectionOfType(SectionType.DOCUMENT_INFORMATION))
        ) {
          --index;
        }
      }

      if (sections[index]) {
        this._selectSection(sections[index]);
      } else {
        this._sectionService.setSelected(null);
        this._router.navigate(['.'], {relativeTo: this._route});
      }
    } else {
      this.tabChangeEvent.emit(this._liveTab);
    }
  }

  /**
   * Routes to the selected section
   *
   * @param section {SectionFragment} The selected section
   */
  private _selectSection(section: SectionFragment): void {
    let segs: string[] = [];

    switch (this.currentView.viewMode) {
      case ViewMode.BACKGROUND:
        segs = ['sections', section.id.value, 'background'];
        break;

      case ViewMode.COMMENTS:
        segs = ['sections', section.id.value, 'comments'];
        break;

      case ViewMode.COMPARE:
        segs = ['sections', section.id.value, 'compare'];
        break;

      case ViewMode.CHANGELOG_MARKUP:
      case ViewMode.CHANGELOG:
        segs = ['/documents', section.documentId.value, 'sections', section.id.value, 'changelog'];
        break;

      case ViewMode.CHANGELOG_AUX:
        this._navigationService.navigateToPublishedChangelogSection(section);
        break;

      default:
        segs = ['sections', section.id.value];
        break;
    }

    this._router.navigate([...segs], {relativeTo: this._route});
  }

  /**
   * This function is called from the section creator via template binding.
   *
   * @param section The newly created section
   */
  public created(sectionId: UUID) {
    this._sectionService.load(sectionId, {projection: 'FULL_TREE'}).then((section: SectionFragment) => {
      this.tabChangeEvent.emit(this._liveTab);
      // This is to navigate to the newly created section. If the section is created in the changelog view, the
      //  route that is being navigated to is different, else it just navigates to the normal section view.
      this._selectSection(section);
    });
  }

  /**
   * Emitted from the section when its thumb has a mousedown event.
   * @param section The section being dragged.
   */
  public dragEvent(section: Fragment) {
    window.addEventListener('mouseup', this._dragStopListener);

    document.documentElement.style.userSelect = 'none';
    document.documentElement.style['-moz-user-select'] = '-moz-none';

    this.draggingSection = section;
    this._hoveringSection = section;
  }

  /**
   * Perform the section reorder.
   */
  private _dragStopListener = () => {
    if (!this.draggingSection.equals(this._hoveringSection)) {
      this.draggingSection.remove();

      const targetIndex: number = this._hoveringSection ? this._hoveringSection.index() : 0;

      const sections: Fragment[] = this.document.children;
      sections.splice(targetIndex + 1, 0, this.draggingSection);

      this.draggingSection.inferWeight(true);
      this._fragmentService.update(this.draggingSection);
    }

    this._resetDrag();
  };

  /**
   * Cleanup reordering related state.
   */
  private _resetDrag(): void {
    window.removeEventListener('mouseup', this._dragStopListener);
    this.draggingSection = null;
    this._hoveringSection = null;
    document.documentElement.style.userSelect = '';
    document.documentElement.style['-moz-user-select'] = '';
  }

  public setHovering(section: Fragment): void {
    if (!!this.draggingSection && !this.draggingSection.equals(section)) {
      this._hoveringSection = section;
      this._updateListForDisplay();
    }
  }

  private _updateListForDisplay(): void {
    this.sectionListForDisplay = [];

    if (!!this.draggingSection && this._hoveringSection === null) {
      this.sectionListForDisplay.push(this.draggingSection);
    }

    this.document.children.forEach((child: Fragment) => {
      if (child.equals(this.draggingSection) && child.equals(this._hoveringSection)) {
        this.sectionListForDisplay.push(child);
      }

      if (this._shouldShowSection(child) && !child.equals(this.draggingSection)) {
        this.sectionListForDisplay.push(child);

        if (child.equals(this._hoveringSection)) {
          this.sectionListForDisplay.push(this.draggingSection);
        }
      }
    });

    this._cdr.markForCheck();
  }

  private _shouldShowSection(sectionFragment: Fragment): boolean {
    if (sectionFragment.is(FragmentType.SECTION)) {
      const section: SectionFragment = sectionFragment as SectionFragment;

      const showAsDeletedNonReferenceSection: boolean =
        this.deletedTab &&
        section.deleted &&
        !section.isSectionOfType(SectionType.REFERENCE_INFORM, SectionType.REFERENCE_NORM);
      const showAsLiveSection: boolean = !this.deletedTab && !section.deleted;

      return (
        (showAsDeletedNonReferenceSection || showAsLiveSection) &&
        !section.isSectionOfType(SectionType.DOCUMENT_INFORMATION)
      );
    } else if (sectionFragment.is(FragmentType.SECTION_GROUP)) {
      const sectionGroup: SectionGroupFragment = sectionFragment as SectionGroupFragment;

      const showAsDeletedSectionGroup: boolean = this.deletedTab && sectionGroup.deleted;
      const showAsLiveSectionGroup: boolean = !this.deletedTab && !sectionGroup.deleted;

      return showAsDeletedSectionGroup || showAsLiveSectionGroup;
    }

    return false;
  }
}
