import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SupersedingReference} from 'app/sidebar/references/superseding-reference';
import {HttpStatus} from 'app/utils/http-status';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class SupersedingReferenceService extends BaseService {
  private static readonly REFERENCES_ENDPOINT: string = `${environment.apiHost}/references`;

  constructor(private _http: HttpClient, protected _snackBar: MatSnackBar) {
    super(_snackBar);
  }

  /**
   * fetch the global reference's superseding references.
   *
   * @returns a promise which will resolve to either all superseding references of a
   * given global reference id or a string error.
   */
  public fetchSupersedingReferences(globalReferenceId: string): Promise<SupersedingReference[]> {
    const params: {[param: string]: string} = {
      oldReference: globalReferenceId,
    };

    return this._http
      .get(`${SupersedingReferenceService.REFERENCES_ENDPOINT}/getSupersedingReferences`, {params})
      .pipe(
        map((json: any) => json.map(SupersedingReference.deserialise)),
        catchError((error) => {
          this._handleError(error, 'Failed to fetch superseding references.', 'reference-error');
          return of([]);
        })
      )
      .toPromise();
  }

  /*
   * Withdraw a global reference with the given ID.
   */
  public withdrawAndSupersedeGlobalReference(
    id: UUID,
    time: number,
    supersedingReferences: SupersedingReference[],
    message?: string
  ): Promise<void> {
    message = message || `sorry, could not withdraw the global reference.`;
    return this._http
      .post(`${SupersedingReferenceService.REFERENCES_ENDPOINT}/withdrawAndSupersedeGlobalReference`, {
        id: id.value,
        withdrawnOn: time,
        supersedingReferences: supersedingReferences,
      })
      .pipe(
        map(() => {}),
        catchError((error) => {
          if (error.status === HttpStatus.FORBIDDEN) {
            message = error.error.message;
          }
          this._handleError(error, message);
          return throwError(error);
        })
      )
      .toPromise();
  }
}
