<h1 mat-dialog-title>CARS feedback</h1>
<mat-dialog-content>
  <form (ngSubmit)="sendFeedback()" #feedbackForm="ngForm">
    <mat-form-field>
      <input name="summary" [(ngModel)]="summary" matInput placeholder="Summary" required />
      <mat-error>Summary is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <textarea name="description" [(ngModel)]="description" matInput rows="5" placeholder="Description"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<div mat-dialog-actions>
  <button
    type="submit"
    mat-raised-button
    color="primary"
    (click)="sendFeedback()"
    [disabled]="!feedbackForm.valid"
    aria-label="Send feedback"
    carsAnalytics="submit-feedback"
  >
    Submit
  </button>
  <button mat-button (click)="close()" aria-label="Cancel sending feedback">Close</button>
</div>
<div *ngIf="loading" class="progress-bar-container">
  <mat-progress-bar mode="indeterminate" aria-label="Sending feeback"></mat-progress-bar>
  <div class="loading-note">Sending feedback, please wait a moment</div>
</div>
