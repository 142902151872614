import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {FragmentIndexModule} from 'app/fragment/indexing/fragment-index.module';
import {ClauseLinkTypeToDisplayNameModule} from 'app/utils/pipes/clause-link-type-to-display-name.module';
import {ClauseLinkRequiredSelectorModule} from './clause-link-required-selector/clause-link-required-selector.module';
import {ClauseLinkSelectorModule} from './clause-link-selector/clause-link-selector.module';
import {ClauseLinksComponent} from './clause-links.component';

@NgModule({
  imports: [
    CommonModule,
    ClauseLinkRequiredSelectorModule,
    ClauseLinkSelectorModule,
    ClauseLinkTypeToDisplayNameModule,
    MatButtonModule,
    FragmentIndexModule,
  ],
  declarations: [ClauseLinksComponent],
  exports: [ClauseLinksComponent],
})
export class ClauseLinksModule {}
