<mat-form-field>
  <input
    matInput
    [placeholder]="titleText"
    [value]="value | docInfoValueDisplayName: valueDisplayName"
    disabled
    [class.historical-version]="isHistoricalVersion"
  />
  <mat-hint *ngIf="hintText">{{ hintText }}</mat-hint>
</mat-form-field>
