import {Injectable} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {CurrentView, ViewMode} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConditionASTNode} from '../types/condition-ast-node';
import {EqualityRelation, PermissionInput} from '../types/permissions';
import {PermissionsHandler} from './permissions-handler';

@Injectable()
export class ViewModePermissionsHandler implements PermissionsHandler {
  constructor(private _viewService: ViewService) {}

  public getInputType(): PermissionInput {
    return PermissionInput.VIEW_MODE;
  }

  /**
   * Note that we don't need to return false on PadType.NO_PAD as we sometimes show fragments not in a pad
   * but with a viewmode on the CurrentView.
   */
  public handle(condition: ConditionASTNode, padType: PadType): Observable<boolean> {
    const viewMode: ViewMode = this._validateAndGetViewMode(condition);
    const viewModeStream: Observable<ViewMode> = this._getViewModeStream(padType);
    switch (condition.relation) {
      case EqualityRelation.EQUALS:
        return viewModeStream.pipe(map((_viewMode: ViewMode) => _viewMode === viewMode));
      case EqualityRelation.NOT_EQUALS:
        return viewModeStream.pipe(map((_viewMode: ViewMode) => _viewMode !== viewMode));
      default:
        throw new Error(`Unsupported equality relation ${condition.relation}`);
    }
  }

  private _getViewModeStream(padType: PadType): Observable<ViewMode> {
    return padType === PadType.PUBLISHED_CHANGLOG
      ? of(ViewMode.CHANGELOG_AUX)
      : this._viewService.getCurrentViewObsStream().pipe(map((currentView: CurrentView) => currentView.viewMode));
  }

  private _validateAndGetViewMode(condition: ConditionASTNode): ViewMode {
    const viewMode = ViewMode[condition.value as string];
    if (viewMode !== undefined) {
      return viewMode;
    } else {
      throw new Error(`Unknown View Mode: ${condition.value}`);
    }
  }
}
