import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {ClauseService} from 'app/services/clause.service';
import {DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {ClauseFragment, Fragment, FragmentType} from '../types';
import {UnitInputFragment} from '../types/input/unit-input-fragment';

@Component({
  selector: 'cars-unit-input-fragment',
  templateUrl: './unit-input-fragment.component.html',
  styleUrls: ['./unit-input-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitInputFragmentComponent extends FragmentComponent implements OnInit, OnDestroy {
  @Input() public set content(value: UnitInputFragment) {
    super.content = value;
  }

  public get content(): UnitInputFragment {
    return super.content as UnitInputFragment;
  }

  private _subscriptions: Subscription[] = [];
  private _canAuthorDocument: boolean = false;

  constructor(
    private _documentService: DocumentService,
    private _permissionsService: PermissionsService,
    private _sidebarService: SidebarService,
    private _fragmentService: FragmentService,
    private _clauseService: ClauseService,
    protected _cdr: ChangeDetectorRef,
    protected _elementRef: ElementRef
  ) {
    super(_cdr, _elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit(); // IMPORTANT
    const documentId: UUID = this._documentService.getSelected().documentId;
    this._subscriptions.push(
      this._permissionsService
        .can(CarsAction.AUTHOR_DOCUMENT, documentId)
        .subscribe((canAuthor: boolean) => (this._canAuthorDocument = canAuthor)),
      this._fragmentService.onUpdate(
        () => this._cdr.markForCheck(),
        (f: Fragment) => f?.equals(this.content)
      )
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy(); // IMPORTANT
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Opens the sidebar to the Unit Selection view and selects the clause if the user has permission to author the
   * document and the sidebar is not already open at the Unit Selection view.
   */
  public openSidebar(): void {
    if (
      this._canAuthorDocument &&
      this._sidebarService.getStatus() !== SidebarStatus.UNIT_SELECTION &&
      !this.isInScheduleList() &&
      !this.isInScheduleTable()
    ) {
      this._clauseService.setSelected(this.content.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment);
      this._sidebarService.setSidebarStatus(SidebarStatus.UNIT_SELECTION);
    }
  }

  /**
   * Returns true if the input is within a rendered schedule list item. Updates the styling within the html.
   */
  public isInScheduleList(): boolean {
    return !!this.content.findAncestorWithType(FragmentType.LIST);
  }

  /**
   * Returns true if the input is within a rendered schedule table. Updates the styling within the html.
   */
  public isInScheduleTable(): boolean {
    return !!this.content.findAncestorWithType(FragmentType.TABLE);
  }
}
