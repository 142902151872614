<div class="errors-container">
  <div class="no-errorwarnings" *ngIf="validationErrors.length === 0">No errors or warnings here.</div>
  <ng-container *ngFor="let error of errors; trackBy: trackValidation">
    <cars-validation-error [error]="error"></cars-validation-error>
  </ng-container>
  <ng-container *ngFor="let warning of warnings; trackBy: trackValidation">
    <cars-validation-error [error]="warning"></cars-validation-error>
  </ng-container>
  <ng-container *ngFor="let info of infos; trackBy: trackValidation">
    <cars-validation-error [error]="info"></cars-validation-error>
  </ng-container>
</div>
