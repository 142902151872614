import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {FragmentType, InternalReferenceType, SectionFragment, SectionType} from 'app/fragment/types';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {InternalReferenceService} from 'app/services/internal-reference.service';
import {
  SectionDisplayReference,
  SectionDisplayReferenceUtils,
} from 'app/services/references/reference-utils/section-display-reference-utils';
import {ReferenceService} from 'app/services/references/reference.service';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-section-reference',
  templateUrl: './section-reference.component.html',
  styleUrls: ['./section-reference.component.scss', '../section-pad/section-pad.component.scss'],
})
export class SectionReferenceComponent implements OnInit, OnDestroy, OnChanges {
  // When changing either NORMATIVE_INTRO_STRING/INFORMATIVE_INTRO_STRING make sure to also change the  value of
  // NORMATIVE_WORDS/INFORMATIVE_WORDS in the CountStatisticRepository in the cars service
  private static readonly NORMATIVE_INTRO_STRING: string =
    'The following documents, in whole or in part, ' +
    'are normative references for this document and are indispensable for its application. ' +
    'For dated references, only the edition cited applies. For undated references, ' +
    'the latest edition of the referenced document (including any amendments) applies.';
  private static readonly INFORMATIVE_INTRO_STRING: string =
    'The following documents are informative references for this document and provide supporting information.';

  @Input() public section: SectionFragment;
  @Input() public documentToolbar: TemplateRef<any>;

  public readonly FragmentType: typeof FragmentType = FragmentType;

  public readonly tooltipDelay = environment.tooltipDelay;

  public currentView: CurrentView;

  public introduction: string = '';

  public loading: boolean;

  public referenceList: SectionDisplayReference[] = [];

  private referencesNeedingUpdate: Record<string, InternalDocumentReferenceFragment[]> = {};

  private subs: Subscription[] = [];

  constructor(
    private viewService: ViewService,
    private referenceService: ReferenceService,
    private internalReferenceService: InternalReferenceService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.subs.push(
      this.viewService.onCurrentViewChange((currentView: CurrentView) => {
        this.currentView = currentView;
      }),
      this.referenceService.onDocumentReferencesRecalculated().subscribe(() => {
        this.setReferenceSectionData();
      })
    );

    this.internalReferenceService
      .getReferencesToUpdateGroupedByTargetDocument(this.section.documentId, [
        InternalReferenceType.SECTION_REFERENCE,
        InternalReferenceType.WSR_REFERENCE,
      ])
      .then((referencesToUpdate: Record<string, InternalDocumentReferenceFragment[]>) => {
        this.referencesNeedingUpdate = referencesToUpdate;
      });

    this.setReferenceSectionData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.section) {
      this.introduction =
        this.section && this.section.sectionType === SectionType.REFERENCE_NORM
          ? SectionReferenceComponent.NORMATIVE_INTRO_STRING
          : SectionReferenceComponent.INFORMATIVE_INTRO_STRING;
    }
  }

  public ngOnDestroy(): void {
    this.subs.splice(0).forEach((s: Subscription) => s.unsubscribe());
    this.referenceList = [];
  }

  /* Used in the component HTML. Generates the combined info/error message that should be shown to the user alongside
  the reference, or null if the reference is valid and up to date. Note that this can include
  both global and internal reference issues. */
  public getErrorMessageForReference(sectionReference: SectionDisplayReference): string {
    let errorString: string = '';
    if (this.referenceWithdrawn(sectionReference)) {
      errorString += 'This is a withdrawn reference. ';
    }
    if (this.referenceDeleted(sectionReference, FragmentType.DOCUMENT_REFERENCE)) {
      errorString +=
        'This reference has been deleted from the library by TAGG, please replace with the correct reference. ';
    }
    if (this.doesDocumentNeedUpdate(sectionReference)) {
      errorString +=
        'Updates have been made to sections within the referenced document that need to be reflected in section references. ' +
        'Please use the ‘Refresh section references’ functionality provided on the Document Information page. ';
    }
    if (this.referenceDeleted(sectionReference, FragmentType.INTERNAL_DOCUMENT_REFERENCE)) {
      errorString +=
        'One or more sections within the referenced document have been deleted and section references within this document need' +
        ' to be updated.  Please remove or update associated section references.';
    }

    return errorString?.length > 0 ? errorString : null;
  }

  /**
   * True if the provided reference has been withdrawn
   * and not deleted. Applicable only to DocumentReferenceFragments
   */
  private referenceWithdrawn(sectionReference: SectionDisplayReference): boolean {
    return (
      !!sectionReference.globalReferenceProperties &&
      !sectionReference.globalReferenceProperties?.deleted &&
      sectionReference.globalReferenceProperties?.withdrawn
    );
  }

  /**
   * True if the provided reference has been deleted.
   * Fragment type is necessary to display the correct tooltip in the HTML.
   */
  private referenceDeleted(sectionReference: SectionDisplayReference, fragmentType: FragmentType): boolean {
    if (fragmentType === FragmentType.DOCUMENT_REFERENCE) {
      return sectionReference.globalReferenceProperties?.deleted;
    } else if (fragmentType === FragmentType.INTERNAL_DOCUMENT_REFERENCE) {
      return sectionReference.internalReferenceProperties?.deleted;
    }
    return false;
  }

  /**
   * True if the provided reference is out of date compared to
   * the most recent published version of the target section (or live version, if none published).
   * Applicable only to InternalDocumentReferenceFragments.
   */
  private doesDocumentNeedUpdate(sectionReference: SectionDisplayReference): boolean {
    return (
      !!sectionReference.internalReferenceProperties && !!this.referencesNeedingUpdate[sectionReference.targetId?.value]
    );
  }

  private setReferenceSectionData(): void {
    if (this.section) {
      this.referenceList = SectionDisplayReferenceUtils.mapCollapseAndSortAllDocumentReferences(
        this.section.children[0]?.children
      );
      this.cdr.markForCheck();
    }
  }
}
