import {Injectable} from '@angular/core';
import {Caret} from 'app/fragment/caret';
import {getFirstEditableDescendant} from 'app/fragment/fragment-utils';
import {Key} from 'app/fragment/key';
import {TableCellFragment, TableFragment} from 'app/fragment/table/table-fragment';
import {
  AnchorFragment,
  CaptionedFragment,
  ClauseFragment,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  EquationFragment,
  Fragment,
  FragmentType,
  TextFragment,
} from 'app/fragment/types';
import {PadType} from './element-ref.service';
import {getFragmentIfEditable} from './fragment/fragment-utils';
import {TextFragmentComponent} from './fragment/text/text-fragment.component';
import {SelectionOperationsService} from './selection-operations.service';
import {LockService} from './services/lock.service';
import {Browser} from './utils/browser';

interface SpecialCase {
  key?: Key;
  condition: (oldFragment?: Fragment, fragment?: Fragment, next?: Fragment, previous?: Fragment) => boolean;
  handler: (oldFragment?: Fragment, fragment?: Fragment, next?: Fragment, previous?: Fragment) => void;
}

@Injectable({
  providedIn: 'root',
})
export class SpecialCaseHandler {
  private _caret: Caret = new Caret(null, 0);
  private _padType: PadType;

  private _rightArrowSpecialCases: SpecialCase[] = [
    // Tables
    {
      // Right arrow just before a table (Chrome)
      key: Key.RIGHT,
      condition: (old, current) =>
        current.is(FragmentType.TABLE) && old.findAncestorWithType(FragmentType.TABLE) !== current,
      handler: (old, current) => {
        const caption = (current as TableFragment).caption;
        caption.markForCheck();
        this._selectionOperationsService.setSelected(caption, 0, this._padType);
      },
    },
    {
      // Right arrow just before a table (Firefox)
      key: Key.RIGHT,
      condition: (old, current, next) =>
        next &&
        next.is(FragmentType.TABLE) &&
        this._caret.isAtEnd() &&
        !!current.findAncestorWithType(FragmentType.TABLE),
      handler: (old, current, next) => {
        const caption = (next as TableFragment).caption;
        caption.markForCheck();
        this._selectionOperationsService.setSelected(caption, 0, this._padType);
      },
    },
    // Inline Equations
    {
      // Right arrow at the end of an inline equation
      key: Key.RIGHT,
      condition: (old, current) =>
        current.findAncestorWithType(FragmentType.EQUATION) &&
        (current.findAncestorWithType(FragmentType.EQUATION) as EquationFragment).inline &&
        this._caret.isAtEnd(),
      handler: (old, current) =>
        this._selectionOperationsService.setSelected(
          current.findAncestorWithType(FragmentType.EQUATION).nextSibling(),
          0,
          this._padType
        ),
    },
    {
      // Right arrow inside an inline equation
      key: Key.RIGHT,
      condition: (old, current) => current.is(FragmentType.EQUATION) && (current as EquationFragment).inline,
      handler: (old, current) =>
        this._selectionOperationsService.setSelected((current as EquationFragment).source, 1, this._padType),
    },
    {
      // Right arrow just before an inline equation
      key: Key.RIGHT,
      condition: (old, current, next) =>
        next && next.is(FragmentType.EQUATION) && (next as EquationFragment).inline && this._caret.isAtEnd(),
      handler: (old, current, next) =>
        this._selectionOperationsService.setSelected((next as EquationFragment).source, 0, this._padType),
    },
    // Inline references
    {
      // Right arrow just before an inline reference
      key: Key.RIGHT,
      condition: (old, current, next) => next && next.is(FragmentType.INLINE_REFERENCE) && this._caret.isAtEnd(),
      handler: (old, current, next) => {
        next.markForCheck();
        this._selectionOperationsService.setSelected(next.nextSibling(), 0, this._padType);
      },
    },
    // Anchor fragments
    {
      // Right arrow before the first anchor fragment
      key: Key.RIGHT,
      condition: (old, current, next) =>
        next && next.is(FragmentType.ANCHOR) && this._caret.isAtEnd() && (next as AnchorFragment).isFirstAnchor,
      handler: (old, current, next) => {
        if (next.nextSibling()) {
          next.markForCheck();
          this._selectionOperationsService.setSelected(
            next.nextSibling(),
            next.nextSibling().length() > 0 ? 1 : 0,
            this._padType
          );
        }
      },
    },
    {
      // Right arrow before the second anchor fragment
      key: Key.RIGHT,
      condition: (old, current, next) =>
        next &&
        next.is(FragmentType.ANCHOR) &&
        this._caret.offset === this._caret.fragment.length() - 1 &&
        !(next as AnchorFragment).isFirstAnchor,
      handler: (old, current, next) => {
        if (next.nextSibling()) {
          next.markForCheck();
          this._selectionOperationsService.setSelected(next.nextSibling(), 0, this._padType);
        }
      },
    },
    // Equations
    {
      // Right arrow to navigate out of the variables and definition table
      key: Key.RIGHT,
      condition: (old, current) =>
        !!old.findAncestorWithType(FragmentType.TABLE_CELL) &&
        old.findAncestorWithType(FragmentType.TABLE_CELL).isLastChild() &&
        !!old.findAncestorWithType(FragmentType.EQUATION) &&
        current.is(FragmentType.TABLE),
      handler: (old, current) => {
        const newFragment: Fragment = !!current.findAncestorWithType(FragmentType.EQUATION).nextSibling()
          ? (current.findAncestorWithType(FragmentType.EQUATION).nextSibling() as CaptionedFragment).caption
          : current.findAncestorWithType(FragmentType.CLAUSE).nextSibling()
          ? current.findAncestorWithType(FragmentType.CLAUSE).nextSibling().children[0]
          : null;

        if (!!newFragment) {
          newFragment.markForCheck();
          this._selectionOperationsService.setSelected(newFragment, 0, this._padType);
        } else {
          this._selectNearestEditableLeafIfExists(old, false);
        }
      },
    },
    // Right arrow to navigate from equation source into the variables and definition table
    {
      key: Key.RIGHT,
      condition: (old, current) =>
        !!old.findAncestorWithType(FragmentType.EQUATION) &&
        old.parent.isCaptioned() &&
        !old.id.equals((old.parent as EquationFragment).caption.id) &&
        current.findAncestorWithType(FragmentType.EQUATION).hasChildren(),
      handler: (old, current) => {
        const newFragment: Fragment = getFirstEditableDescendant(
          current.findAncestorWithType(FragmentType.EQUATION).children[0]
        );
        if (this._caret.offset === old.value.length || (old.value.length === 1 && /[\u200B]/g.test(old.value))) {
          newFragment.markForCheck();
          this._selectionOperationsService.setSelected(newFragment, 0, this._padType);
        } else {
          old.markForCheck();
          this._selectionOperationsService.setSelected(old, this._caret.offset + 1, this._padType);
        }
      },
    },
    // Right arrow to navigate into equation caption
    {
      key: Key.RIGHT,
      condition: (old, current) => !!current.findAncestorWithType(FragmentType.EQUATION),
      handler: (old, current) => {
        if (
          !current.findAncestorWithType(FragmentType.EQUATION).equals(old.findAncestorWithType(FragmentType.EQUATION))
        ) {
          const caption: TextFragment = (current.findAncestorWithType(FragmentType.EQUATION) as EquationFragment)
            .caption;
          caption.markForCheck();
          this._selectionOperationsService.setSelected(caption, 0, this._padType);
        } else if (
          current.findAncestorWithType(FragmentType.EQUATION).equals(old.findAncestorWithType(FragmentType.EQUATION)) &&
          current.is(FragmentType.EQUATION)
        ) {
          const newFragment: Fragment = !!current.findAncestorWithType(FragmentType.EQUATION).nextSibling()
            ? (current.nextSibling() as CaptionedFragment).caption
            : current.findAncestorWithType(FragmentType.CLAUSE).nextSibling()
            ? current.findAncestorWithType(FragmentType.CLAUSE).nextSibling().children[0]
            : null;

          if (!!newFragment) {
            newFragment.markForCheck();
            this._selectionOperationsService.setSelected(newFragment, 0, this._padType);
          } else {
            this._selectNearestEditableLeafIfExists(old, false);
          }
        }
      },
    },
    // Inputs
    {
      // Right at end of input
      key: Key.RIGHT,
      condition: (old) => old.parent.is(FragmentType.INPUT) && old.isLastChild() && this._caret.isAtEnd(),
      handler: (old) => this._selectNearestEditableLeafIfExists(old, false),
    },
    // Right arrow to skip over a locked clause
    {
      key: Key.RIGHT,
      condition: (old, current) =>
        !current.findAncestorWithType(FragmentType.CLAUSE).equals(old.findAncestorWithType(FragmentType.CLAUSE)) &&
        !this._lockService.canLock(current.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment),
      handler: (old, current) => this._selectNextFragmentFromLockedClause(current, false),
    },
    // Make sure we otherwise end up in a text fragment
    {
      // If we end up in a fragment we can't place the caret in, select the next one that can be selected
      key: Key.RIGHT,
      condition: (old, current) => !current.is(...EDITABLE_TEXT_FRAGMENT_TYPES),
      handler: (old) => this._selectNearestEditableLeafIfExists(old, false),
    },
  ];

  private _leftArrowSpecialCases: SpecialCase[] = [
    // Tables
    {
      // Left arrow at the start of the first fragment of a table cell
      key: Key.LEFT,
      condition: (old) => old.parent.is(FragmentType.TABLE_CELL) && old.isFirstChild() && this._caret.isAtStart(),
      handler: (old) => {
        const cell = old.parent as TableCellFragment,
          prevCell = cell.getPreviousCell();
        if (prevCell) {
          prevCell.markForCheck();
          this._selectionOperationsService.setSelected(prevCell, prevCell.length(), this._padType);
        } else if (old.findAncestorWithType(FragmentType.EQUATION)) {
          const source = (old.findAncestorWithType(FragmentType.EQUATION) as EquationFragment).source;
          source.markForCheck();
          this._selectionOperationsService.setSelected(source, source.length(), this._padType);
        } else {
          const caption = cell.parent.parent.caption;
          caption.markForCheck();
          this._selectionOperationsService.setSelected(caption, caption.length(), this._padType);
        }
      },
    },
    {
      // Left arrow at the start of a table caption
      key: Key.LEFT,
      condition: (old) =>
        old.is(FragmentType.TEXT) && (old.component as TextFragmentComponent).caption && this._caret.offset <= 1,
      handler: (old) => {
        if (this._caret.offset === 1) {
          old.markForCheck();
          this._selectionOperationsService.setSelected(old, 0, this._padType);
        } else {
          const table = old.parent,
            previousSibling = this._getNearestEditableLeaf(table, true);

          previousSibling.markForCheck();
          this._selectionOperationsService.setSelected(previousSibling, previousSibling.length(), this._padType);
        }
      },
    },
    // Inline Equations
    {
      // Left arrow at the start of a fragment following an inline equation
      key: Key.LEFT,
      condition: (old, current, next, previous) =>
        previous &&
        previous.is(FragmentType.EQUATION) &&
        (previous as EquationFragment).inline &&
        this._caret.offset < 2,
      handler: (old, current, next, previous) => {
        if (this._caret.offset === 1) {
          this._selectionOperationsService.setSelected(old, 0, this._padType);
        } else {
          if (Browser.isEdge()) {
            (previous as EquationFragment).component.focus();
          }
          this._selectionOperationsService.setSelected(
            (previous as EquationFragment).source,
            (previous as EquationFragment).length(),
            this._padType
          );
        }
      },
    },
    // Anchor fragments
    {
      // Left arrow after the first anchor fragment
      key: Key.LEFT,
      condition: (old, current, next, previous) =>
        previous &&
        previous.is(FragmentType.ANCHOR) &&
        (previous as AnchorFragment).isFirstAnchor &&
        this._caret.offset === (this._caret.fragment.length() === 0 ? 0 : 1),
      handler: (old, current, next, previous) => {
        if (previous.previousSibling()) {
          previous.markForCheck();
          this._selectionOperationsService.setSelected(
            previous.previousSibling(),
            previous.previousSibling().length(),
            this._padType
          );
        }
      },
    },
    {
      // Left arrow after the second anchor fragment
      key: Key.LEFT,
      condition: (old, current, next, previous) =>
        previous &&
        previous.is(FragmentType.ANCHOR) &&
        !(previous as AnchorFragment).isFirstAnchor &&
        this._caret.isAtStart(),
      handler: (old, current, next, previous) => {
        if (previous.previousSibling()) {
          previous.markForCheck();
          this._selectionOperationsService.setSelected(
            previous.previousSibling(),
            previous.previousSibling().length() > 0 ? previous.previousSibling().length() - 1 : 0,
            this._padType
          );
        }
      },
    },
    // Equations
    {
      // Left arrow at the start of an equation
      key: Key.LEFT,
      condition: (old, current) =>
        Browser.isEdge() &&
        current.findAncestorWithType(FragmentType.EQUATION) &&
        (this._caret.offset === 1 || this._caret.isAtStart()),
      handler: (old, current) => {
        this._caret.offset === 1
          ? this._selectionOperationsService.setSelected(
              (current.findAncestorWithType(FragmentType.EQUATION) as EquationFragment).source,
              0,
              this._padType
            )
          : this._selectionOperationsService.setSelected(
              current.findAncestorWithType(FragmentType.EQUATION).previousSibling(),
              current.findAncestorWithType(FragmentType.EQUATION).previousSibling().length(),
              this._padType
            );
      },
    },
    {
      // Left arrow to enter equation caption
      key: Key.LEFT,
      condition: (old, current) =>
        !!current.findAncestorWithType(FragmentType.EQUATION) && old.equals((old.parent as EquationFragment).source),
      handler: (old, current) => {
        const caption = (old.parent as EquationFragment).caption;
        caption.markForCheck();
        this._selectionOperationsService.setSelected(caption, caption.length(), this._padType);
      },
    },
    // Inputs
    {
      // Left at start of input
      key: Key.LEFT,
      condition: (old) =>
        old.parent.is(FragmentType.INPUT) && old.isFirstChild() && (!old.parent.length() || this._caret.isAtStart()),
      handler: (old) => this._selectNearestEditableLeafIfExists(old, true),
    },
    // Left arrow to skip over a locked clause
    {
      key: Key.LEFT,
      condition: (old, current) =>
        !current.findAncestorWithType(FragmentType.CLAUSE).equals(old.findAncestorWithType(FragmentType.CLAUSE)) &&
        !this._lockService.canLock(current.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment),
      handler: (old, current) => {
        if (current.findAncestorWithType(FragmentType.CLAUSE).isFirstChild()) {
          this._selectNextFragmentFromLockedClause(current, false);
        } else {
          this._selectNextFragmentFromLockedClause(current, true);
        }
      },
    },
    // Make sure we otherwise end up in a text fragment
    {
      key: Key.LEFT,
      condition: (old, current) => !current.is(...EDITABLE_TEXT_FRAGMENT_TYPES),
      handler: (old) => this._selectNearestEditableLeafIfExists(old, true),
    },
  ];

  private _upArrowSpecialCases: SpecialCase[] = [
    // Tables
    {
      // Up arrow in the first line of a table cell
      key: Key.UP,
      condition: (old, current) => {
        const tableCell = old.findAncestorWithType(FragmentType.TABLE_CELL);
        return tableCell && tableCell !== current.findAncestorWithType(FragmentType.TABLE_CELL);
      },
      handler: (old) => {
        const cell = old.findAncestorWithType(FragmentType.TABLE_CELL) as TableCellFragment,
          aboveCell = cell.getCellAbove();
        if (aboveCell) {
          aboveCell.markForCheck();
          this._selectionOperationsService.setSelected(aboveCell, aboveCell.length(), this._padType);
        } else if (cell && cell.findAncestorWithType(FragmentType.EQUATION)) {
          const source: Fragment = (cell.findAncestorWithType(FragmentType.EQUATION) as EquationFragment).source;
          source.markForCheck();
          this._selectionOperationsService.setSelected(source, source.length(), this._padType);
        } else {
          const caption = cell.parent.parent.caption;
          caption.markForCheck();
          this._selectionOperationsService.setSelected(caption, 0, this._padType);
        }
      },
    },
    {
      // Up arrow in the first element in a list in a table cell when there are subsequent fragments in the cell (weirdly specific Firefox
      // issue)
      key: Key.UP,
      condition: (old, current) =>
        old.findAncestorWithType(FragmentType.LIST) &&
        old.findAncestorWithType(FragmentType.TABLE_CELL) &&
        old.findAncestorWithType(FragmentType.LIST_ITEM).isFirstChild() &&
        old !== current,
      handler: (old) => {
        const previousLeaf = old.previousLeaf();
        previousLeaf.markForCheck();
        this._selectionOperationsService.setSelected(previousLeaf, previousLeaf.length(), this._padType);
      },
    },
    // Figures
    {
      // Up arrow at the top of a figure caption
      key: Key.UP,
      condition: (old, current) =>
        old.is(FragmentType.TEXT) &&
        (old.component as TextFragmentComponent).caption &&
        current.is(FragmentType.FIGURE),
      handler: (old, current) => {
        const previousLeaf = current.previousLeaf();
        previousLeaf.markForCheck();
        this._selectionOperationsService.setSelected(previousLeaf, previousLeaf.length(), this._padType);
      },
    },
    // Clauses
    {
      // Up arrow for edge before an empty clause
      key: Key.UP,
      condition: (old) =>
        Browser.isEdge() &&
        !old.findAncestorWithType(FragmentType.TABLE_CELL) &&
        old.previousLeaf().is(FragmentType.TEXT) &&
        old.previousLeaf().length() === 0,
      handler: (old) => {
        const previousLeaf: Fragment = old.previousLeaf();
        previousLeaf.markForCheck();
        this._selectionOperationsService.setSelected(previousLeaf, 0, this._padType);
      },
    },
    // Equations
    {
      // Up arrow to enter equation caption
      key: Key.UP,
      condition: (old, current) => !!current.findAncestorWithType(FragmentType.EQUATION),
      handler: (old, current) => {
        const previousLeaf = old.equals((old.parent as EquationFragment).source)
          ? (old.parent as EquationFragment).caption
          : this._getNearestEditableLeaf(old, true);
        previousLeaf.markForCheck();
        this._selectionOperationsService.setSelected(previousLeaf, previousLeaf.length(), this._padType);
      },
    },

    // Inputs
    {
      // Up arrow in input in first clause in a section
      key: Key.UP,
      condition: (old) =>
        old.parent.is(FragmentType.INPUT) &&
        !this._getNearestEditableLeaf(old.findAncestorWithType(FragmentType.CLAUSE), true),
      handler: (old) => {
        if (old.isFirstChild() && this._caret.isAtStart()) {
          const previousLeaf: Fragment = this._getNearestEditableLeaf(old.parent, true);
          if (previousLeaf) {
            previousLeaf.markForCheck();
            this._selectionOperationsService.setSelected(previousLeaf, previousLeaf.length(), this._padType);
            return;
          }
        }
        const toSelect: Fragment = old.parent.children[0];
        toSelect.markForCheck();
        this._selectionOperationsService.setSelected(toSelect, 0, this._padType);
      },
    },
    // Up arrow to skip over a locked clause
    {
      key: Key.UP,
      condition: (old, current) =>
        !current.findAncestorWithType(FragmentType.CLAUSE).equals(old.findAncestorWithType(FragmentType.CLAUSE)) &&
        !this._lockService.canLock(current.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment),
      handler: (old, current) => {
        if (current.findAncestorWithType(FragmentType.CLAUSE).isFirstChild()) {
          this._selectNextFragmentFromLockedClause(current, false);
        } else {
          this._selectNextFragmentFromLockedClause(current, true);
        }
      },
    },
    // Make sure we otherwise end up in a text fragment
    {
      key: Key.UP,
      condition: (old, current) => !current.is(...EDITABLE_TEXT_FRAGMENT_TYPES),
      handler: (old) => this._selectNearestEditableLeafIfExists(old, true),
    },
  ];

  private _downArrowSpecialCases: SpecialCase[] = [
    // Tables
    {
      // Down arrow in the last line of a table cell
      key: Key.DOWN,
      condition: (old, current) => {
        const tableCell = old.findAncestorWithType(FragmentType.TABLE_CELL);
        return tableCell && tableCell !== current.findAncestorWithType(FragmentType.TABLE_CELL);
      },
      handler: (old, current) => {
        const cell = old.findAncestorWithType(FragmentType.TABLE_CELL) as TableCellFragment,
          belowCell = cell.getCellBelow();
        if (belowCell) {
          belowCell.markForCheck();
          this._selectionOperationsService.setSelected(belowCell.children[0], 0, this._padType);
        } else {
          const nextLeaf: Fragment = this._getNearestEditableLeaf(old.findAncestorWithType(FragmentType.TABLE), false);
          if (nextLeaf && nextLeaf.findAncestorWithType(FragmentType.EQUATION)) {
            const caption: Fragment = (nextLeaf.findAncestorWithType(FragmentType.EQUATION) as CaptionedFragment)
              .caption;
            caption.markForCheck();
            this._selectionOperationsService.setSelected(caption, 0, this._padType);
          } else if (nextLeaf) {
            nextLeaf.markForCheck();
            this._selectionOperationsService.setSelected(nextLeaf, 0, this._padType);
          } else {
            const toSelect: Fragment = cell.children[cell.children.length - 1];
            toSelect.markForCheck();
            this._selectionOperationsService.setSelected(toSelect, cell.length(), this._padType);
          }
        }
      },
    },
    {
      // Down arrow in the fragment before a table
      key: Key.DOWN,
      condition: (old, current) => {
        const currentTable = current.findAncestorWithType(FragmentType.TABLE);
        if (!currentTable) {
          return false;
        }
        const oldTable = old.findAncestorWithType(FragmentType.TABLE);
        return !oldTable || oldTable !== currentTable;
      },
      handler: (old, current) => {
        if (old?.equals((old.findAncestorWithType(FragmentType.EQUATION) as EquationFragment)?.source)) {
          const newFragment: Fragment = getFirstEditableDescendant(
            current.findAncestorWithType(FragmentType.EQUATION).children[0]
          );
          newFragment.markForCheck();
          this._selectionOperationsService.setSelected(newFragment, 0, this._padType);
        } else {
          const caption = (current.findAncestorWithType(FragmentType.TABLE) as TableFragment).caption;
          caption.markForCheck();
          this._selectionOperationsService.setSelected(caption, 0, this._padType);
        }
      },
    },
    {
      // Down arrow in a table caption (firefox)
      key: Key.DOWN,
      condition: (old) =>
        old.parent.is(FragmentType.TABLE) &&
        old.is(FragmentType.TEXT) &&
        (old.component as TextFragmentComponent).caption,
      handler: (old) => {
        const table = old.parent as TableFragment;
        table.markForCheck();
        this._selectionOperationsService.setSelected(table, 0, this._padType);
      },
    },
    {
      // Down arrow in the last element in a list in a table cell when there are subsequent fragments in the cell (weirdly specific
      // Firefox issue)
      key: Key.DOWN,
      condition: (old, current) =>
        old.findAncestorWithType(FragmentType.LIST) &&
        old.findAncestorWithType(FragmentType.TABLE_CELL) &&
        old.findAncestorWithType(FragmentType.LIST_ITEM).isLastChild() &&
        (this._caret.isAtEnd() || current.is(FragmentType.TABLE_CELL)),
      handler: (old) => {
        const nextLeaf = old.nextLeaf();
        nextLeaf.markForCheck();
        this._selectionOperationsService.setSelected(nextLeaf, 0, this._padType);
      },
    },
    // Clauses
    {
      // Down arrow for edge before an empty clause
      key: Key.DOWN,
      condition: (old) =>
        Browser.isEdge() &&
        !old.findAncestorWithType(FragmentType.TABLE_CELL) &&
        old.nextLeaf().is(FragmentType.TEXT) &&
        old.nextLeaf().length() === 0,
      handler: (old) => {
        const nextLeaf: Fragment = old.nextLeaf();
        nextLeaf.markForCheck();
        this._selectionOperationsService.setSelected(nextLeaf, 0, this._padType);
      },
    },
    // Equations
    {
      // Down arrow to navigate into equation caption
      key: Key.DOWN,
      condition: (old, current) => !!current.findAncestorWithType(FragmentType.EQUATION),
      handler: (old, current) => {
        if (
          !current.findAncestorWithType(FragmentType.EQUATION).equals(old.findAncestorWithType(FragmentType.EQUATION))
        ) {
          const caption: TextFragment = (current.findAncestorWithType(FragmentType.EQUATION) as EquationFragment)
            .caption;
          caption.markForCheck();
          this._selectionOperationsService.setSelected(caption, 0, this._padType);
        } else if (
          current.findAncestorWithType(FragmentType.EQUATION).equals(old.findAncestorWithType(FragmentType.EQUATION)) &&
          current.is(FragmentType.EQUATION)
        ) {
          const newFragment: Fragment = !!current.findAncestorWithType(FragmentType.EQUATION).nextSibling()
            ? (current.nextSibling() as CaptionedFragment).caption
            : current.findAncestorWithType(FragmentType.CLAUSE).nextSibling()
            ? current.findAncestorWithType(FragmentType.CLAUSE).nextSibling().children[0]
            : null;

          if (!!newFragment) {
            newFragment.markForCheck();
            this._selectionOperationsService.setSelected(newFragment, 0, this._padType);
          } else {
            this._selectNearestEditableLeafIfExists(old, false);
          }
        }
      },
    },

    // Inputs
    {
      // Down arrow in input in last clause in a section
      key: Key.DOWN,
      condition: (old) =>
        old.parent.is(FragmentType.INPUT) &&
        !this._getNearestEditableLeaf(old.findAncestorWithType(FragmentType.CLAUSE), false),
      handler: (old) => {
        if (old.isLastChild() && this._caret.isAtEnd()) {
          const next: Fragment = this._getNearestEditableLeaf(old.parent, false);
          if (next) {
            next.markForCheck();
            this._selectionOperationsService.setSelected(next, 0, this._padType);
            return;
          }
        }
        const toSelect: Fragment = old.parent.children[old.parent.children.length - 1];
        toSelect.markForCheck();
        this._selectionOperationsService.setSelected(toSelect, toSelect.length(), this._padType);
      },
    },
    // Down arrow to skip over a locked clause
    {
      key: Key.DOWN,
      condition: (old, current) =>
        !current.findAncestorWithType(FragmentType.CLAUSE).equals(old.findAncestorWithType(FragmentType.CLAUSE)) &&
        !this._lockService.canLock(current.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment),
      handler: (old, current) => this._selectNextFragmentFromLockedClause(current, false),
    },
    // Make sure we otherwise end up in a text fragment
    {
      key: Key.DOWN,
      condition: (old, current) => !current.is(...EDITABLE_TEXT_FRAGMENT_TYPES),
      handler: (old) => this._selectNearestEditableLeafIfExists(old, false),
    },
  ];

  private _homeSpecialCases: SpecialCase[] = [
    // Inputs
    {
      key: Key.HOME,
      condition: (old) => old.parent.is(FragmentType.INPUT),
      handler: (old) => {
        const firstInputChild: Fragment = old.parent.children[0];
        firstInputChild.markForCheck();
        this._selectionOperationsService.setSelected(firstInputChild, 0, this._padType);
      },
    },
  ];

  private _endSpecialCases: SpecialCase[] = [
    // Inputs
    {
      key: Key.END,
      condition: (old) => old.parent.is(FragmentType.INPUT),
      handler: (old) => {
        const lastInputChild: Fragment = old.parent.children[old.parent.children.length - 1];
        lastInputChild.markForCheck();
        this._selectionOperationsService.setSelected(lastInputChild, lastInputChild.length(), this._padType);
      },
    },
  ];

  private _specialCases: SpecialCase[] = [
    ...this._rightArrowSpecialCases,
    ...this._leftArrowSpecialCases,
    ...this._upArrowSpecialCases,
    ...this._downArrowSpecialCases,
    ...this._homeSpecialCases,
    ...this._endSpecialCases,
  ];

  constructor(private _selectionOperationsService: SelectionOperationsService, private _lockService: LockService) {}

  public handle(caret: Caret, key: Key, oldFragment: Fragment, fragment: Fragment, padType: PadType): boolean {
    const nextSibling: Fragment = oldFragment.nextSibling(),
      previousSibling: Fragment = oldFragment.previousSibling();
    this._caret = caret;
    this._padType = padType;

    return this._specialCases.some((specialCase) => {
      const keyMatches = specialCase.key ? key && key.equalsUnmodified(specialCase.key) : true;
      if (keyMatches && specialCase.condition(oldFragment, fragment, nextSibling, previousSibling)) {
        specialCase.handler(oldFragment, fragment, nextSibling, previousSibling);
        return true;
      }
    });
  }

  /**
   * Finds nearest editable unlocked leaf in the given direction. If one exists, select it, otherwise place the caret
   * back at the start/end of the original fragment. If we are selecting the previous leaf, place the caret at the end,
   * else place the caret at the beginning.
   *
   * @param old       {Fragment}  The old (previously selected) fragment
   * @param previous  {boolean}   True if we want to get the previous leaf, else get the next leaf.
   */
  private _selectNearestEditableLeafIfExists(old: Fragment, previous: boolean): void {
    const leaf: Fragment = this._getNearestEditableLeaf(old, previous);
    this._selectIfExists(old, leaf, previous);
  }

  /**
   * Finds the nearest editable leaf in the given direction whose ancestor clause is not locked. If one doesn't exist,
   * returns null.
   *
   * @param old       {Fragment}  The fragment to start from.
   * @param previous  {boolean}   True if we want to get the previous leaf, else get the next leaf.
   */
  private _getNearestEditableLeaf(fragment: Fragment, previous: boolean): Fragment {
    let leaf: Fragment = previous ? fragment.previousLeaf() : fragment.nextLeaf();

    while (leaf) {
      const editableFragment: Fragment = getFragmentIfEditable(leaf);
      const leafClause: ClauseFragment = leaf.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
      const isUnLocked: boolean = this._lockService.canLock(leafClause);

      if (!!editableFragment && isUnLocked) {
        return editableFragment;
      } else if (!isUnLocked) {
        leaf = previous ? leafClause.previousLeaf() : leafClause.nextLeaf();
      } else {
        leaf = previous ? leaf.previousLeaf() : leaf.nextLeaf();
      }
    }

    return null;
  }

  /**
   * Places the caret at the start/end on the given fragment if that fragment exists. If not, place the caret back at
   * the start/end of the previously selected fragment.
   *
   * @param old       {Fragment}  The previously selected fragment.
   * @param fragment  {Fragment}  The fragment to select.
   * @param previous  {boolean}   True if we are selecting the previous leaf, else get the next leaf.
   */
  private _selectIfExists(old: Fragment, fragment: Fragment, previous: boolean): void {
    if (fragment) {
      this._selectAndMarkForCheck(fragment, previous);
    } else {
      this._selectAndMarkForCheck(old, !previous);
    }
  }

  /**
   * Selects the next clause to move into when the target clause is locked. This will try to move to the next editable
   * region beyond the locked clause, and if no further unlocked editable fragments exist, then selects the last
   * editable region before the locked clause. Note this will not preserve caret position within a line as it moves to
   * the start/end of the nearest fragment.
   *
   * @param current  {Fragment} The (locked) fragment to move into.
   * @param previous {boolean}  True if we are selecting the previous leaf, else get the next leaf.
   */
  private _selectNextFragmentFromLockedClause(current: Fragment, previous: boolean): void {
    let next: Fragment = this._getNearestEditableLeaf(current, previous);
    if (next) {
      this._selectAndMarkForCheck(next, previous);
    } else {
      next = this._getNearestEditableLeaf(current, !previous);

      this._selectAndMarkForCheck(next, !previous);
    }
  }

  /**
   * Selects the given fragment at either the end, if selectEnd is true, else puts the caret at the start.
   *
   * @param fragment  The fragment to select
   * @param selectEnd True if we should put the caret at the end of the fragment, else puts the caret at the start.
   */
  private _selectAndMarkForCheck(fragment: Fragment, selectEnd: boolean): void {
    fragment.markForCheck();
    this._selectionOperationsService.setSelected(fragment, selectEnd ? fragment.length() : 0, this._padType);
  }
}
