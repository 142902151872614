import {Injectable} from '@angular/core';
import {Caret} from 'app/fragment/caret';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {TextFragmentComponent} from 'app/fragment/text/text-fragment.component';
import {EquationFragment, Fragment, FragmentType} from 'app/fragment/types';
import {Browser} from 'app/utils/browser';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  constructor(private _viewService: ViewService) {}

  /**
   * Retrieve all text nodes contained beneath a given parent node.
   *
   * @param node {Node}     The parent node
   * @returns    {Text[]}   All text nodes beneath node
   */
  public textNodesFrom(node: Node): Text[] {
    const result: Text[] = [];

    if (node instanceof Text) {
      result.push(node);
    } else if (node.childNodes.length > 0) {
      [].slice.apply(node.childNodes).forEach((child: Node) => {
        result.push(...this.textNodesFrom(child));
      });
    }

    return result;
  }

  /**
   * Disable the drag handles and inline table editor that Firefox creates on block-displayed
   * contenteditable elements.  This must be called once the element has been created in the DOM.
   */
  public disableNativeEditors(): void {
    document.execCommand('enableObjectResizing', false, 'false');
    document.execCommand('enableInlineTableEditing', false, 'false');
  }

  /**
   * Returns true if child is below ancestor in the DOM.
   *
   * @param ancestor {Node}      The ancestor node
   * @param child    {Node}      The child node
   * @returns        {boolean}   True if an ancestor
   */
  public hasAncestor(ancestor: Node, child: Node): boolean {
    while (child && child !== ancestor) {
      child = child.parentNode;
    }

    return child === ancestor;
  }

  /**
   * Get the caret positions from a browser selection.  Returns an array of two items, where the
   * 0th item is the start and the 1st item is the end.
   *
   * @param selection {Selection}   The browser selection object
   * @returns         {Caret[]}     The start and end caret
   */
  public getCaretsFromSelection(selection: Selection = window.getSelection()): Caret[] {
    let startCaret: Caret;
    let endCaret: Caret;
    if (selection.anchorNode) {
      if (selection.isCollapsed) {
        const startNode: Node = selection.anchorNode;
        if (FragmentComponent.fromNode(startNode)) {
          const startOffset: number = selection.anchorOffset;
          startCaret = new Caret(
            FragmentComponent.fromNode(startNode).content,
            this.textOffsetToFragmentOffset(startNode, startOffset)
          );
          endCaret = new Caret(startCaret.fragment, startCaret.offset);
        } else {
          startCaret = null;
          endCaret = null;
        }
      } else {
        // When selecting a range over multiple clauses we don't get valid start/end information by using
        // anchorNode/focusNode (firefox gives multiple ranges when selecting over multiple clauses).
        // We thus use getRangeAt() instead.
        const startRange: Range = selection.getRangeAt(0);
        if (FragmentComponent.fromNode(startRange.startContainer)) {
          startCaret = new Caret(FragmentComponent.fromNode(startRange.startContainer).content, startRange.startOffset);
        } else {
          startCaret = null;
        }

        const endRange: Range = selection.getRangeAt(selection.rangeCount - 1);
        if (FragmentComponent.fromNode(endRange.endContainer)) {
          let offset: number;
          if (Browser.isEdge()) {
            const lastRangeForEdge: Range = endRange.cloneRange();
            lastRangeForEdge.selectNodeContents(selection.anchorNode);
            lastRangeForEdge.setEnd(endRange.endContainer, endRange.endOffset);
            lastRangeForEdge.setStart(endRange.endContainer, 0);
            offset = lastRangeForEdge.toString().length;
          }
          endCaret = new Caret(
            FragmentComponent.fromNode(endRange.endContainer).content,
            offset ? offset : endRange.endOffset
          );
        } else {
          endCaret = null;
        }
      }

      // This is intended to make a selection a half-open range, so that if the end of the
      // selection is at the end of a fragment, it is pushed over to the start of the next
      // fragment.  This doesn't need to happen if the selection is zero-length.
      if (startCaret && endCaret && !startCaret.equals(endCaret) && endCaret.offset === 0) {
        endCaret.fragment = endCaret.fragment.previousLeaf();
        endCaret.offset = endCaret.fragment.length();
      }
    }

    if (startCaret && endCaret) {
      const [newstartCaret, newendCaret] = this.moveInvalidCarets(startCaret, endCaret);
      if (!startCaret.equals(newstartCaret) || !endCaret.equals(newendCaret)) {
        this.setHighlighted(newstartCaret, newendCaret);
        [startCaret, endCaret] = [newstartCaret, newendCaret];
      }
    }

    return [startCaret, endCaret];
  }

  /**
   * Moves the carets to a valid selection if the user has made a selection across fragments which is invalid.
   * @returns     {caret[]}   Returns a two item array with the start and end carets.
   */
  private moveInvalidCarets(startCaret: Caret, endCaret: Caret): Caret[] {
    const currentView: CurrentView = this._viewService.getCurrentView();

    if (!currentView.isChangelogMarkup()) {
      const startFragmentCellParent: Fragment = startCaret.fragment.findAncestorWithType(FragmentType.TABLE_CELL);
      const endFragmentCellParent: Fragment = endCaret.fragment.findAncestorWithType(FragmentType.TABLE_CELL);

      // Create type as text fragment components so we can query if they are captions
      const startTextComponent = startCaret.fragment.is(FragmentType.TEXT)
        ? (startCaret.fragment.component as TextFragmentComponent)
        : null;
      const endTextComponent = endCaret.fragment.is(FragmentType.TEXT)
        ? (endCaret.fragment.component as TextFragmentComponent)
        : null;

      if (startFragmentCellParent && endFragmentCellParent && !startFragmentCellParent.equals(endFragmentCellParent)) {
        endCaret = Caret.endOf(startFragmentCellParent.children[startFragmentCellParent.children.length - 1]);
      } else if (startFragmentCellParent && !endFragmentCellParent) {
        // This case catches wierdness with a table caption's left edge officially counting as a cell in the table
        if (
          endTextComponent &&
          endTextComponent.caption &&
          endCaret.fragment.parent.equals(startCaret.fragment.findAncestorWithType(FragmentType.TABLE))
        ) {
          startCaret = new Caret(endCaret.fragment, 0);
        } else {
          endCaret = Caret.endOf(startFragmentCellParent.children[startFragmentCellParent.children.length - 1]);
        }
      } else if (!startFragmentCellParent && endFragmentCellParent) {
        startCaret = new Caret(endFragmentCellParent.children[0], 0);
      } else if (!startCaret.fragment.equals(endCaret.fragment) && startTextComponent && startTextComponent.caption) {
        endCaret = new Caret(startCaret.fragment, startCaret.fragment.length());
      } else if (!startCaret.fragment.equals(endCaret.fragment) && endTextComponent && endTextComponent.caption) {
        // This case catches wierdness with a figure caption's left edge officially counting as the figure fragment itself.
        if (startCaret.fragment.is(FragmentType.TABLE, FragmentType.FIGURE, FragmentType.EQUATION)) {
          startCaret = new Caret(endCaret.fragment, 0);
        } else {
          let lastNonCaptionedFragment: Fragment;
          lastNonCaptionedFragment = endCaret.fragment.parent.previousSibling();
          while (!lastNonCaptionedFragment.is(FragmentType.TEXT)) {
            lastNonCaptionedFragment = lastNonCaptionedFragment.previousSibling();
          }

          endCaret = new Caret(lastNonCaptionedFragment, lastNonCaptionedFragment.length());
        }
      }

      if (this.isInEquationSource(startCaret) && !this.isInEquationSource(endCaret)) {
        endCaret = Caret.endOf(startCaret.fragment);
      } else if (!this.isInEquationSource(startCaret) && this.isInEquationSource(endCaret)) {
        startCaret = Caret.startOf(endCaret.fragment);
      }
    }

    return [startCaret, endCaret];
  }

  private isInEquationSource(caret: Caret): boolean {
    const fragment: Fragment = caret.fragment;
    return (
      !!fragment &&
      fragment.is(FragmentType.TEXT) &&
      fragment.parent.is(FragmentType.EQUATION) &&
      (fragment.parent as EquationFragment).source.equals(fragment)
    );
  }

  public setHighlighted(startCaret: Caret, endCaret: Caret): void {
    const startNode: Node = startCaret.fragment.component.element.firstChild.firstChild;
    const endNode: Node = endCaret.fragment.component.element.firstChild.firstChild;
    const range: Range = document.createRange();

    this._safeSet(range, 'start', startNode, startCaret.offset);
    this._safeSet(range, 'end', endNode, endCaret.offset);

    const selection: Selection = window.getSelection();
    if (selection.rangeCount > 0) {
      selection.removeAllRanges();
    }
    selection.addRange(range);
  }

  private _safeSet(range: Range, end: 'end' | 'start', node: Node, offset: number): void {
    const length: number = node.nodeValue ? node.nodeValue.length : 0;
    offset = Math.max(0, Math.min(length, offset));
    if (end === 'end') {
      range.setEnd(node, offset);
    } else {
      range.setStart(node, offset);
    }
  }

  /**
   * Convert an offset within a text node to an offset to the start of the fragment containing it.
   *
   * @param node   {Node}     The focused node
   * @param offset {number}   The character offset within node
   * @returns      {number}   The offset relative to the containing fragment
   */
  public textOffsetToFragmentOffset(node: Node, offset: number): number {
    const fragment: FragmentComponent = FragmentComponent.fromNode(node);
    const textNodes: Text[] = this.textNodesFrom(fragment.element);

    let index: number = 0;
    while (index < textNodes.length && offset > textNodes[index].textContent.length) {
      offset -= textNodes[index].textContent.length;
      ++index;
    }

    const before: number = textNodes
      .slice(0, index)
      .reduce((sum: number, text: Text) => (sum += text.textContent.length), 0);

    offset = Math.min(offset, fragment.content.length());
    return before + offset;
  }

  public getHTMLFromSelection(selection: Selection): string {
    const startRange: Range = selection.getRangeAt(0);
    const endRange: Range = selection.getRangeAt(selection.rangeCount - 1);

    const range: Range = document.createRange();
    range.setStart(startRange.startContainer, startRange.startOffset);
    range.setEnd(endRange.endContainer, endRange.endOffset);

    const div: HTMLDivElement = document.createElement('div');
    div.appendChild(range.cloneContents());

    // This is to remove any elements that we cannot see on the page
    document.body.appendChild(div);
    this._removeDisplayNone(div);
    div.remove();

    return div.innerHTML;
  }

  private _removeDisplayNone(node: Element): void {
    if (node.nodeType === Node.ELEMENT_NODE && window.getComputedStyle(node).display === 'none') {
      node.remove();
    } else if (node.hasChildNodes()) {
      for (let i = 0; i < node.childNodes.length; i++) {
        this._removeDisplayNone(node.childNodes[i] as Element);
      }
    }
  }
}
