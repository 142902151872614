<mat-progress-bar *ngIf="loading" mode="indeterminate" aria-label="Loading section comments"></mat-progress-bar>

<div class="options">
  <mat-button-toggle-group aria-label="Filter discussions" (change)="setDiscussionsToDisplay($event.value)">
    <ng-container *ngFor="let option of displayDiscussionOptions">
      <mat-button-toggle
        [value]="option.key"
        [checked]="isSelected(option.key)"
        [attr.aria-label]="'View ' + option.text + ' discussions'"
        >{{ option.text }}</mat-button-toggle
      >
    </ng-container>
  </mat-button-toggle-group>
  <mat-checkbox
    class="raised-by-me"
    *ngIf="!(currentView.userIsAReviewer() && currentView.userDiscussionsOnly())"
    matTooltipPosition="left"
    [matTooltip]="raisedByMe ? 'Show only discussions raised by you' : 'Show discussions by all users'"
    (change)="raisedByMe = !raisedByMe"
    [checked]="raisedByMe"
    >Raised by me
  </mat-checkbox>

  <mat-form-field>
    <mat-select
      placeholder="Filter comments by version:"
      [compareWith]="compare"
      (selectionChange)="changeSelectedVersion($event)"
    >
      <mat-option *ngIf="!isAReviewer" [value]="'live'">Version: Live</mat-option>
      <ng-container *ngFor="let option of versionOptions">
        <mat-option [value]="option">Version: {{ option.name }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <div class="button-wrapper">
    <button
      mat-raised-button
      color="primary"
      class="print"
      (click)="print()"
      aria-label="Print comments"
      enableDefaultFocus
    >
      <mat-icon class="mat-24">print</mat-icon>
      Print
    </button>
    <div
      [matTooltip]="
        !canSubmit()
          ? 'Another user is currently working a clause you are accepting a suggested change for.'
          : 'Save suggested change'
      "
    >
      <button
        class="submit"
        mat-raised-button
        color="primary"
        (click)="submit()"
        (keyup.enter)="submit()"
        [disabled]="!canSubmit() || submitting"
        [attr.aria-label]="
          !canSubmit()
            ? 'Another user is currently working a clause you are accepting a suggested change for.'
            : 'Save suggested change'
        "
      >
        <mat-icon class="mat-24">save</mat-icon>
        Submit
      </button>
    </div>
    <a
      class="exit"
      [routerLink]="closeRoute"
      matTooltip="Close"
      mat-raised-button
      (keyup.enter)="onClose()"
      aria-label="Close"
    >
      <mat-icon class="mat-24">exit_to_app</mat-icon>
      Exit
    </a>
  </div>
</div>

<div class="body">
  <table>
    <tr>
      <th width="100px">Index</th>
      <th>Content</th>
      <th width="33%">Discussions</th>
      <th width="33%">Comment</th>
    </tr>
    <ng-container *ngFor="let fragment of fragments">
      <ng-container *ngIf="!collapsed[fragment.id.value]">
        <tr>
          <ng-container *ngIf="showAll() || getFragmentDiscussions(fragment).length > 0">
            <!-- Clause index -->
            <td
              class="index-cell top-align"
              [attr.rowspan]="getFragmentDiscussions(fragment).length > 0 ? getFragmentDiscussions(fragment).length : 1"
            >
              <div class="clause-index">
                <span [class]="ClauseType[fragment.clauseType] | lowercase">{{ getFragmentIndex(fragment) }}</span>
              </div>
            </td>
            <!-- Section/Clause content -->
            <td
              [attr.rowspan]="getFragmentDiscussions(fragment).length > 0 ? getFragmentDiscussions(fragment).length : 1"
              class="top-align"
            >
              <div *ngIf="!isSection(fragment)" class="clause-padding-class">
                <cars-clause
                  [content]="fragment"
                  [showMarginIcons]="false"
                  [readOnly]="true"
                  [showCurrentClause]="false"
                ></cars-clause>
              </div>
              <div *ngIf="isSection(fragment)" class="section-padding-class">{{ fragment.value }}</div>
              <button
                mat-icon-button
                class="icon-clause"
                matTooltip="Minimise content"
                matTooltipPosition="right"
                [matTooltipShowDelay]="tooltipDelay"
                (click)="collapsed[fragment.id.value] = !collapsed[fragment.id.value]"
                aria-label="Minimise content"
              >
                <mat-icon>expand_less</mat-icon>
              </button>
            </td>
            <ng-container *ngFor="let discussion of getFragmentDiscussions(fragment); let i = index">
              <ng-container *ngIf="i === 0">
                <ng-container *ngIf="!collapsed[discussion.id.value]">
                  <!-- Discussion -->
                  <td class="top-align left-border" [class.discussion]="!discussion.resolved">
                    <cars-discussion
                      [currentView]="currentView"
                      [clause]="fragment"
                      [discussion]="discussion"
                    ></cars-discussion>
                    <button
                      mat-icon-button
                      class="icon-discussion"
                      matTooltip="Minimise discussion"
                      matTooltipPosition="right"
                      [matTooltipShowDelay]="tooltipDelay"
                      (click)="collapsed[discussion.id.value] = !collapsed[discussion.id.value]"
                      aria-label="Minimise discussion"
                    >
                      <mat-icon>expand_less</mat-icon>
                    </button>
                  </td>
                  <ng-container *ngIf="!discussion.resolved">
                    <!-- Comment -->
                    <td>
                      <div class="textarea-container" [class.disabled]="commentingDisabled(discussion)">
                        <mat-form-field [floatLabel]="'always'">
                          <textarea
                            matInput
                            [(ngModel)]="stagedCommentText[discussion.id.value].text"
                            [disabled]="commentingDisabled(discussion)"
                            [class.disabled]="commentingDisabled(discussion)"
                            [placeholder]="
                              commentingDisabled(discussion)
                                ? 'This version of the document has not been made available to comment on'
                                : 'Comment'
                            "
                          >
                          </textarea>
                        </mat-form-field>
                        <div *ngIf="stagedCommentText[discussion.id.value].text"></div>
                        <div class="button-container">
                          <ng-container *ngIf="!discussion.suggestion">
                            <mat-checkbox
                              [matTooltipShowDelay]="tooltipDelay"
                              [class.disabled]="commentingDisabled(discussion)"
                              matTooltipPosition="left"
                              [matTooltip]="
                                isResolvable(discussion)
                                  ? 'Only mark as resolved when there are no more outstanding actions'
                                  : cannotResolveDiscussionMessage
                              "
                              (change)="
                                stagedCommentText[discussion.id.value].resolve =
                                  !stagedCommentText[discussion.id.value].resolve
                              "
                              [checked]="
                                stagedCommentText[discussion.id.value].resolve &&
                                stagedCommentText[discussion.id.value].text
                              "
                              [disabled]="!(stagedCommentText[discussion.id.value].text && isResolvable(discussion))"
                              >Resolve
                            </mat-checkbox>
                          </ng-container>
                          <ng-container *ngIf="discussion.suggestion">
                            <mat-checkbox
                              [matTooltipShowDelay]="tooltipDelay"
                              [class.disabled]="commentingDisabled(discussion)"
                              matTooltipPosition="left"
                              [matTooltip]="
                                isResolvable(discussion)
                                  ? 'Accept the suggested replacement and resolve the discussion'
                                  : cannotResolveDiscussionMessage
                              "
                              (change)="
                                stagedCommentText[discussion.id.value].resolve =
                                  !stagedCommentText[discussion.id.value].resolve;
                                stagedCommentText[discussion.id.value].reject = false
                              "
                              [checked]="
                                stagedCommentText[discussion.id.value].resolve &&
                                stagedCommentText[discussion.id.value].text
                              "
                              [disabled]="!(stagedCommentText[discussion.id.value].text && isResolvable(discussion))"
                              >Resolve and accept
                            </mat-checkbox>
                            <mat-checkbox
                              [matTooltipShowDelay]="tooltipDelay"
                              color="warn"
                              [class.disabled]="commentingDisabled(discussion)"
                              matTooltipPosition="left"
                              [matTooltip]="
                                isResolvable(discussion)
                                  ? 'Reject the suggested replacement and resolve the discussion.'
                                  : cannotResolveDiscussionMessage
                              "
                              (change)="
                                stagedCommentText[discussion.id.value].reject =
                                  !stagedCommentText[discussion.id.value].reject;
                                stagedCommentText[discussion.id.value].resolve = false
                              "
                              [checked]="
                                stagedCommentText[discussion.id.value].reject &&
                                stagedCommentText[discussion.id.value].text
                              "
                              [disabled]="!(stagedCommentText[discussion.id.value].text && isResolvable(discussion))"
                              >Resolve and reject
                            </mat-checkbox>
                          </ng-container>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="discussion.resolved">
                    <td class="resolved-empty-text">This discussion has been resolved.</td>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="collapsed[discussion.id.value]">
                  <td colspan="2" height="30px" class="left-border">
                    <mat-icon class="resolved-icon" [class.resolved]="discussion.resolved">{{
                      discussion.resolved ? 'done' : 'comment'
                    }}</mat-icon>
                    <div class="collapsed-author">
                      Raised by
                      <cars-user [userId]="discussion.createdBy"></cars-user>
                      {{ discussion.createdAt | date : dateFormat }}
                      <span *ngIf="discussion.resolved" class="resolved-empty-text">(Resolved)</span>
                    </div>
                    <button
                      mat-icon-button
                      class="icon-discussion-collapsed"
                      matTooltip="Expand discussion"
                      matTooltipPosition="right"
                      [matTooltipShowDelay]="tooltipDelay"
                      (click)="collapsed[discussion.id.value] = !collapsed[discussion.id.value]"
                      aria-label="Expand discussion"
                    >
                      <mat-icon>expand_more</mat-icon>
                    </button>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!(getFragmentDiscussions(fragment).length > 0)">
              <td colspan="2" class="resolved-empty-text left-border">
                {{ hasDiscussions(fragment) ? 'You have raised' : 'There are' }}
                no discussions on this content.
              </td>
            </ng-container>
          </ng-container>
        </tr>
        <ng-container *ngFor="let discussion of getFragmentDiscussions(fragment); let i = index">
          <ng-container *ngIf="i > 0">
            <ng-container *ngIf="!collapsed[discussion.id.value]">
              <tr class="left-border">
                <!-- Discussion -->
                <td class="left-border top-align" [class.discussion]="!discussion.resolved">
                  <cars-discussion
                    [currentView]="currentView"
                    [clause]="fragment"
                    [discussion]="discussion"
                  ></cars-discussion>
                  <button
                    mat-icon-button
                    class="icon-discussion"
                    matTooltip="Minimise discussion"
                    matTooltipPosition="right"
                    [matTooltipShowDelay]="tooltipDelay"
                    (click)="collapsed[discussion.id.value] = !collapsed[discussion.id.value]"
                    aria-label="Minimise discussion"
                  >
                    <mat-icon>expand_less</mat-icon>
                  </button>
                </td>
                <ng-container *ngIf="!discussion.resolved">
                  <!-- Comment -->
                  <td>
                    <div
                      [ngClass]="{
                        disabled: commentingDisabled(discussion),
                        'textarea-container': true
                      }"
                    >
                      <mat-form-field [floatLabel]="'always'">
                        <textarea
                          matInput
                          [(ngModel)]="stagedCommentText[discussion.id.value].text"
                          [disabled]="commentingDisabled(discussion)"
                          [class.disabled]="commentingDisabled(discussion)"
                          [placeholder]="
                            commentingDisabled(discussion)
                              ? 'This version of the document has not been made available to comment on'
                              : 'Comment'
                          "
                        >
                        </textarea>
                      </mat-form-field>
                      <div *ngIf="discussion.textValue"></div>
                      <div class="button-container">
                        <ng-container *ngIf="!discussion.suggestion">
                          <mat-checkbox
                            [matTooltipShowDelay]="tooltipDelay"
                            [class.disabled]="commentingDisabled(discussion)"
                            matTooltipPosition="left"
                            [matTooltip]="
                              isResolvable(discussion)
                                ? 'Only mark as resolved when there are no more outstanding actions'
                                : cannotResolveDiscussionMessage
                            "
                            (change)="
                              stagedCommentText[discussion.id.value].resolve =
                                !stagedCommentText[discussion.id.value].resolve
                            "
                            [checked]="
                              stagedCommentText[discussion.id.value].resolve &&
                              stagedCommentText[discussion.id.value].text
                            "
                            [disabled]="!(stagedCommentText[discussion.id.value].text && isResolvable(discussion))"
                            >Resolve
                          </mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="discussion.suggestion">
                          <mat-checkbox
                            [matTooltipShowDelay]="tooltipDelay"
                            [class.disabled]="commentingDisabled(discussion)"
                            matTooltipPosition="left"
                            [matTooltip]="
                              isResolvable(discussion)
                                ? 'Accept the suggested replacement and resolve the discussion'
                                : cannotResolveDiscussionMessage
                            "
                            (change)="
                              stagedCommentText[discussion.id.value].resolve =
                                !stagedCommentText[discussion.id.value].resolve;
                              stagedCommentText[discussion.id.value].reject = false
                            "
                            [checked]="
                              stagedCommentText[discussion.id.value].resolve &&
                              stagedCommentText[discussion.id.value].text
                            "
                            [disabled]="!(stagedCommentText[discussion.id.value].text && isResolvable(discussion))"
                            >Resolve and accept
                          </mat-checkbox>
                          <mat-checkbox
                            [matTooltipShowDelay]="tooltipDelay"
                            color="warn"
                            [class.disabled]="commentingDisabled(discussion)"
                            matTooltipPosition="left"
                            [matTooltip]="
                              isResolvable(discussion)
                                ? 'Reject the suggested replacement and resolve the discussion'
                                : cannotResolveDiscussionMessage
                            "
                            (change)="
                              stagedCommentText[discussion.id.value].reject =
                                !stagedCommentText[discussion.id.value].reject;
                              stagedCommentText[discussion.id.value].resolve = false
                            "
                            [checked]="
                              stagedCommentText[discussion.id.value].reject &&
                              stagedCommentText[discussion.id.value].text
                            "
                            [disabled]="!(stagedCommentText[discussion.id.value].text && isResolvable(discussion))"
                            >Resolve and reject
                          </mat-checkbox>
                        </ng-container>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <ng-container *ngIf="discussion.resolved">
                  <td colspan="1" class="resolved-empty-text">This discussion has been resolved.</td>
                </ng-container>
              </tr>
            </ng-container>
            <ng-container *ngIf="collapsed[discussion.id.value]">
              <tr>
                <td colspan="2" height="30px" class="left-border">
                  <mat-icon
                    [ngClass]="{
                      'resolved-icon': true,
                      resolved: discussion.resolved
                    }"
                    >{{ discussion.resolved ? 'done' : 'comment' }}</mat-icon
                  >
                  <div class="collapsed-author">
                    Raised by
                    <cars-user [userId]="discussion.createdBy"></cars-user>
                    {{ discussion.createdAt | date : dateFormat }}
                    <span *ngIf="discussion.resolved" class="resolved-empty-text">(Resolved)</span>
                  </div>
                  <button
                    mat-icon-button
                    class="icon-discussion-collapsed"
                    matTooltip="Expand discussion"
                    matTooltipPosition="right"
                    [matTooltipShowDelay]="tooltipDelay"
                    (click)="collapsed[discussion.id.value] = !collapsed[discussion.id.value]"
                    aria-label="Expand discussion"
                  >
                    <mat-icon>expand_more</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="collapsed[fragment.id.value] && (showAll() || getFragmentDiscussions(fragment).length > 0)">
        <tr>
          <td class="index-cell" height="30px">
            <div class="clause-index">
              <span [class]="ClauseType[fragment.clauseType] | lowercase">{{ getFragmentIndex(fragment) }}</span>
            </div>
          </td>
          <td>
            <button
              mat-icon-button
              class="icon-clause"
              matTooltip="Expand content"
              matTooltipPosition="right"
              [matTooltipShowDelay]="tooltipDelay"
              (click)="collapsed[fragment.id.value] = !collapsed[fragment.id.value]"
              aria-label="Expand content"
            >
              <mat-icon>expand_more</mat-icon>
            </button>
          </td>
          <td
            class="left-border center-text"
            [class.resolved-empty-text]="!(getFragmentDiscussions(fragment).length > 0)"
            colspan="2"
          >
            {{ fragmentCollapsedString(fragment) }}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </table>
</div>
