<ng-content *ngTemplateOutlet="documentToolbar"></ng-content>

<cars-editor-toolbar
  *ngIf="currentView.showPadToolbar() && !documentToolbar"
  [toolbarButtonsExt]="toolbarButtonsExt"
></cars-editor-toolbar>
<div class="pad-container">
  <div class="pad-padding" [class.landscape]="hasLandscapeFragment">
    <div
      class="pad"
      [id]="padId"
      [class.reordering]="reordering"
      [class.landscape]="hasLandscapeFragment"
      (mouseenter)="handleMouseInPad(true)"
      (mouseleave)="handleMouseInPad(false)"
    >
      <cars-canvas *ngIf="!disableCanvas" [offsetElementId]="padId" [section]="section"></cars-canvas>
      <div class="cover-margin-icons" *ngIf="dragging"></div>
      <div class="hide-borders" *ngIf="hasLandscapeFragment"></div>
      <div class="content" *ngIf="section">
        <div class="title">
          <span
            *ngIf="!section.deleted"
            [ngClass]="section.sectionType !== SectionType.APPENDIX ? 'index' : 'appendix-index'"
          >
            {{ section | fragmentIndex }}
          </span>
          <div class="title-text">
            {{ section.title || '&nbsp;' }}
            <ng-container *ngIf="section.deleted"> (Deleted)</ng-container>
          </div>
          <div *ngIf="padType === PadType.MAIN_EDITABLE" class="margin-icons-container" contenteditable="false">
            <cars-discussions-icon [fragment]="section"></cars-discussions-icon>
          </div>
        </div>
        <cars-clause-list
          [content]="section"
          [currentView]="currentView"
          [readOnly]="readonly || section.deleted"
          [class.review-mode]="!currentView.isLive() && !currentView.isChangelog()"
          [padType]="padType"
        >
        </cars-clause-list>
      </div>
      <div *ngIf="!section || section.deleted" class="read-only">
        <div *ngIf="!section">Loading Section</div>
      </div>
    </div>
  </div>
</div>
