import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {ActionRequest} from 'app/fragment/action-request';
import {Caret} from 'app/fragment/caret';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {ReferenceHovertipComponent} from 'app/fragment/inline-reference/reference-hovertip/reference-hovertip.component';
import {Key} from 'app/fragment/key';
import {DocumentReferenceFragment, Fragment, InlineReferenceFragment} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {DEFAULT_REFERENCE_KEY} from 'app/services/references/reference-utils/reference-index-utils';
import {ReferenceService} from 'app/services/references/reference.service';
import {ValidationService} from 'app/validation/validation.service';
import {CurrentView} from 'app/view/current-view';
import {Subscription} from 'rxjs';
import {ViewService} from '../../view/view.service';

@Component({
  selector: 'cars-inline-reference-fragment',
  templateUrl: './inline-reference-fragment.component.html',
  styleUrls: ['./inline-reference-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineReferenceFragmentComponent extends FragmentComponent implements OnInit, OnDestroy {
  @ViewChild('referenceRef') public referenceRef: ElementRef;

  public readonly defaultKey: string = DEFAULT_REFERENCE_KEY;

  public currentView: CurrentView;

  public selected: boolean;
  public documentReference: DocumentReferenceFragment;
  public referenceField: string;

  private _referenceTitle: string;

  private _hovertipComponent: ComponentRef<ReferenceHovertipComponent>;
  private _hoverTimeout: number = null;

  private _subscriptions: Subscription[] = [];

  public set content(value: InlineReferenceFragment) {
    super.content = value;
  }

  public get content(): InlineReferenceFragment {
    return super.content as InlineReferenceFragment;
  }

  constructor(
    private _referenceService: ReferenceService,
    private _fragmentService: FragmentService,
    private _viewService: ViewService,
    private _validationService: ValidationService,
    private viewContainerRef: ViewContainerRef,
    private cd: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    super(cd, elementRef);
  }

  public async ngOnInit() {
    super.ngOnInit();

    await this._getDocumentReferenceFragment();

    this._subscriptions.push(
      this._viewService.onCurrentViewChange((currentView: CurrentView) => (this.currentView = currentView)),
      this._referenceService.onDocumentReferencesRecalculated().subscribe(() => this._updateModel())
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());

    if (this._hovertipComponent) {
      this._hovertipComponent.destroy();
      this._hovertipComponent = null;
    }
  }

  private _getDocumentReferenceFragment(): Promise<void> {
    return this._referenceService
      .getDocumentReferenceWithSearchableGlobalReference(this.content.documentReference)
      .then((_documentReference: DocumentReferenceFragment) => {
        if (_documentReference) {
          this.documentReference = _documentReference;
          this._updateModel();
        }
      });
  }

  private _updateModel(): void {
    if (this.documentReference) {
      this.referenceField =
        this.documentReference.searchableGlobalReference && this.documentReference.searchableGlobalReference.reference
          ? `${this.documentReference.searchableGlobalReference.reference} ${this.documentReference.release}`
          : '';

      this.content.globalReferenceDeleted = this.documentReference.searchableGlobalReference
        ? this.documentReference.searchableGlobalReference.deleted
        : false;

      if (
        this.documentReference.searchableGlobalReference &&
        this.documentReference.searchableGlobalReference.withdrawn
      ) {
        this.content.globalReferenceWithdrawnWithoutYearOfIssue = this.documentReference
          ? !this.documentReference.release
          : false;
        this.content.globalReferenceWithdrawnWithYearOfIssue = this.documentReference
          ? !!this.documentReference.release
          : false;
      }
    }
    this._validationService.reValidate(this.content);
    this.cd.markForCheck();
  }

  @HostListener('mouseover', ['$event'])
  public mouseover(event: MouseEvent): void {
    if (this._hovertipComponent) {
      return;
    }

    if (!this._hoverTimeout) {
      this._hoverTimeout = window.setTimeout(() => {
        this._hoverTimeout = null;
        this.createTitleHover(event);
        this.cd.markForCheck();
      }, 200);
    }
  }

  @HostListener('mouseleave', ['$event'])
  public mouseleave(event: MouseEvent): void {
    if (this._hoverTimeout) {
      window.clearTimeout(this._hoverTimeout);
      this._hoverTimeout = null;
      this.cd.markForCheck();
    }
  }

  public createTitleHover(event: MouseEvent): void {
    this._hovertipComponent = this.viewContainerRef.createComponent(ReferenceHovertipComponent);
    this._hovertipComponent.instance.componentRef = this._hovertipComponent;
    this._hovertipComponent.instance.title =
      this._getReferenceTitle() +
      (this.content.globalReferenceDeleted
        ? '<div><strong>This reference has been deleted from the library by TAGG, please replace with the correct reference.</strong><div>'
        : '');
    this._hovertipComponent.instance.event = event;
    this._hovertipComponent.instance.anchorElement = this.referenceRef.nativeElement;
    this._subscriptions.push(
      this._hovertipComponent.instance.destroy.subscribe(() => {
        this._hovertipComponent = null;
        this.cd.markForCheck();
      })
    );
  }

  private _getReferenceTitle(): string {
    if (!this._referenceTitle && this.documentReference) {
      this._referenceTitle = this.documentReference.searchableGlobalReference
        ? this.documentReference.searchableGlobalReference.format(this.documentReference.release)
        : null;
    }

    return this._referenceTitle ? this._referenceTitle : 'Loading, please wait...';
  }

  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    this.cd.markForCheck();
    if (key.equalsUnmodified(Key.BACKSPACE) || key.equalsUnmodified(Key.DELETE)) {
      const fragment: Fragment = this.content.previousSibling();
      const offset: number = fragment.length();
      // Always inline reference, no need to validate
      this._fragmentService.delete(this.content);
      return new ActionRequest(fragment, offset);
    }
    return null;
  }

  public onFocus(): void {
    this.selected = true;
    this.cd.markForCheck();
  }

  public blur(): void {
    Promise.resolve().then(() => {
      this.selected = false;
      this.cd.markForCheck();
    });
  }
}
