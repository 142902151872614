import {Component, Input} from '@angular/core';
import {ValidationError} from 'app/validation/validation-rule';

@Component({
  selector: 'cars-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
})
export class ValidationErrorComponent {
  @Input() error: ValidationError;

  public expanded = false;

  constructor() {}
}
