<div class="container">
  <div class="clause-references">
    <p class="placeholder">
      Please see the
      <a href="https://help.futuredmrb.co.uk/using-cars/clauses-referencing/">help pages</a>
      for guidance on how to use this feature.
    </p>
    <div>
      <cars-document-selector
        [searchType]="DocumentsSearchType.CLAUSE_REFERENCES"
        [searchPlaceholder]="searchPlaceholder"
        (onSelect)="handleSelectDocument($event)"
      ></cars-document-selector>

      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

      <mat-select
        placeholder="Select a section"
        [disabled]="!sections?.length"
        (selectionChange)="handleSelectSection($event.value)"
        [value]="selectedSection"
      >
        <ng-container *ngFor="let section of sections">
          <mat-option [value]="section" [attr.aria-label]="'section' + section.index + ' ' + section.title">
            {{ section.index }} {{ section.title }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-hint *ngIf="!loading && selectedDocument && !sections?.length">
        <mat-error>No normative sections available in the selected document</mat-error>
      </mat-hint>
    </div>

    <div>
      <ng-container *ngFor="let clause of clauses">
        <div class="clause">
          <mat-checkbox [checked]="isChecked(clause)" (change)="updateSelectedClauses(clause, $event)"></mat-checkbox>
          <cars-tdif-clause class="tdif-clause" [content]="clause"></cars-tdif-clause>
        </div>
      </ng-container>
      <mat-error *ngIf="!loading && selectedDocument && selectedSection && !clauses?.length">
        No clauses available in the selected section
      </mat-error>
    </div>
  </div>

  <div class="button-options">
    <p class="clause-reference-display-string">{{ clauseRefDisplayString }}</p>

    <button mat-raised-button color="basic" aria-label="Cancel" (click)="cancel()">Cancel</button>

    <button
      mat-raised-button
      color="primary"
      aria-label="Clear selection"
      (click)="clearSelection()"
      [disabled]="!changesMade()"
    >
      Clear Selection
    </button>

    <button
      mat-raised-button
      color="primary"
      aria-label="Save selection"
      (click)="saveSelection()"
      [disabled]="!changesMade()"
    >
      Save Selection
    </button>
  </div>
</div>
