import {Fragment, FragmentType} from 'app/fragment/types';
import {UUID} from 'app/utils/uuid';
import {InternalDocumentReferenceFragment} from './internal-document-reference-fragment';

export class InternalInlineReferenceFragment extends Fragment {
  public internalDocumentReferenceId: UUID;
  public deleted: boolean;
  public readonly: boolean;

  public diffedInternalDocumentReference: InternalDocumentReferenceFragment;

  public static empty(): InternalInlineReferenceFragment {
    return new InternalInlineReferenceFragment(null, null, null);
  }

  constructor(id: UUID, internalDocumentReferenceId: UUID, deleted: boolean = false, readonly: boolean = false) {
    super(id, FragmentType.INTERNAL_INLINE_REFERENCE, []);
    this.internalDocumentReferenceId = internalDocumentReferenceId;
    this.deleted = deleted;
    this.readonly = readonly;
    this.diffedInternalDocumentReference = null;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): InternalInlineReferenceFragment {
    return this;
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();
    json.internalDocumentReferenceId = this.internalDocumentReferenceId.value;
    delete json.diffedInternalDocumentReferenceId;

    return json;
  }
}
