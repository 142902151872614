<h3>Associated documents</h3>
<div class="guidance-text">
  Click here for guidance on using associated documents -
  <a href="https://help.futuredmrb.co.uk/using-associated-documents/" target="_blank">see here for guidance</a>
</div>
<div *ngIf="!hasChildren" class="mat-hint">No associated documents added</div>
<div *ngIf="hasChildren">
  <table class="associated-documents-table">
    <colgroup>
      <col class="reference" />
      <col class="title" />
      <col class="national-variations" />
      <col class="actions" />
    </colgroup>
    <tr style="text-align: left">
      <th>Reference</th>
      <th>Title</th>
      <th class="national-variations-header">National variations</th>
      <th class="actions"></th>
    </tr>
    <tr *ngFor="let associatedDocumentInformation of associatedDocumentInformationFragments">
      <td>{{ associatedDocumentInformation.reference }}</td>
      <td>{{ associatedDocumentInformation.title }}</td>
      <td class="national-variations-cell">{{ getHasNationalVariations(associatedDocumentInformation) }}</td>
      <td>
        <button
          [disabled]="!canUpdate()"
          [matTooltip]="!canUpdate() ? (lockedText | async) : 'Edit'"
          matTooltipPosition="before"
          [matTooltipShowDelay]="tooltipDelay"
          mat-icon-button
          [attr.aria-label]="'Edit ' + associatedDocumentInformation.reference"
          (click)="openModal(associatedDocumentInformation)"
        >
          <div>
            <mat-icon>edit</mat-icon>
          </div>
        </button>
        <button
          [disabled]="!canUpdate()"
          [matTooltip]="!canUpdate() ? (lockedText | async) : 'Remove'"
          matTooltipPosition="after"
          [matTooltipShowDelay]="tooltipDelay"
          mat-icon-button
          [attr.aria-label]="'Remove ' + associatedDocumentInformation.reference"
          (click)="openModal(associatedDocumentInformation, true)"
        >
          <div>
            <mat-icon>delete</mat-icon>
          </div>
        </button>
      </td>
    </tr>
  </table>
</div>
<div class="button-container">
  <button
    [matTooltip]="!canUpdate() ? (lockedText | async) : 'Add new associated document'"
    [disabled]="!canUpdate()"
    mat-flat-button
    color="primary"
    (click)="openModal()"
  >
    <mat-icon>add</mat-icon> Add an associated document
  </button>
</div>
<hr />
