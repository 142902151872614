<div
  *ngIf="content"
  class="clause"
  [class.momhw-si]="isMomhw"
  [carsClauseStyling]="content"
  [carsCurrentClause]="content"
  [showCurrentClause]="showCurrentClause"
  [attr.data-clausetype]="ClauseType[content?.clauseType] | lowercase"
  (click)="click()"
>
  <cars-locking-overlay
    *ngIf="!readOnly && padType === PadType.MAIN_EDITABLE"
    [clause]="content"
  ></cars-locking-overlay>

  <span
    class="index"
    [class.user-select-none]="!content.parent || content.parent.isFirstChild() || reordering"
    [class.deprecated-si]="isDeprecatedSIType"
    contenteditable="false"
  >
    <ng-container
      *ngIf="
        (!readOnly || (CarsAction.IS_CHANGELOG_MARKUP_VIEW_MODE | carsEnabled : content.documentId | async)) &&
        !content.getSection().deleted
      "
    >
      {{ content | fragmentIndex }}
    </ng-container>
  </span>

  <ng-container
    *ngIf="
      !readOnly &&
      padType === PadType.MAIN_EDITABLE &&
      (CarsAction.AUTHOR_DOCUMENT | carsEnabled : content.documentId | async) &&
      isContentSpecifierInstruction
    "
  >
    <button
      mat-icon-button
      class="open-specifier-instruction-options-button"
      [matMenuTriggerFor]="menu"
      contenteditable="false"
      [carsShowOnFocus]="content"
      [attr.blurOption]="BlurOption.PREVENT_BLUR"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu" class="move-delete-hovertip-menu" overlapTrigger="true" xPosition="before">
      <cars-move-delete-hovertip [fragment]="content" [padType]="padType"></cars-move-delete-hovertip>
    </mat-menu>
  </ng-container>

  <div class="content" [class.reordering]="reordering" [class.deprecated-si]="isDeprecatedSIType">
    <span class="deprecated-prefix" *ngIf="isDeprecatedSIType">DEPRECATED - </span>
    <ng-template
      [carsFragmentHost]="content.children"
      [readOnly]="readOnly || (CarsAction.EDIT_PAD | carsDisabled : content.documentId | async)"
    ></ng-template>
  </div>

  <div
    *ngIf="showMarginIcons && padType === PadType.MAIN_EDITABLE"
    class="margin-icons-container"
    contenteditable="false"
  >
    <cars-validation-icon [clause]="content"></cars-validation-icon>
    <cars-discussions-icon [fragment]="content"></cars-discussions-icon>
    <cars-clause-link-icon [clause]="content"></cars-clause-link-icon>
  </div>
</div>
