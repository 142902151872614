import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SectionFragment, SectionType} from 'app/fragment/types';
import {SectionService} from 'app/services/section.service';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';
import {PadType} from '../../element-ref.service';

@Component({
  selector: 'cars-section-proxy',
  templateUrl: './section-proxy.component.html',
  styleUrls: ['./section-proxy.component.scss'],
})
export class SectionProxyComponent implements OnInit, OnDestroy {
  /**
   * If false, enables control over the values of section and currentView,
   * rather than pulling the current values from the services.
   */
  @Input() public alwaysShowCurrent: boolean = true;
  @Input() public section: SectionFragment;
  @Input() public currentView: CurrentView;
  @Input() public disableCanvas: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public toolbarButtonsExt: TemplateRef<any>;
  @Input() public documentToolbar: TemplateRef<any>;
  @Input() public padType: PadType = PadType.MAIN_EDITABLE;

  public closeRoute: string;

  private _subs: Subscription[] = [];

  /**
   * Test whether the component requires a close button by looking whether of not it has any observers
   */
  get isClosable(): boolean {
    return this.currentView && this.currentView.isExport();
  }

  constructor(
    private sectionService: SectionService,
    private viewService: ViewService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this.viewService.onCurrentViewChange((currentView: CurrentView) => {
        if (this.alwaysShowCurrent) {
          this.currentView = currentView;
        }
      }),
      this.sectionService.onSelection((section) => {
        if (this.alwaysShowCurrent) {
          this.section = section;
        }
      })
    );
    this.closeRoute = '/documents/'
      .concat(this.route.snapshot.paramMap.get('document_id'))
      .concat('/sections')
      .concat(this.section ? '/' + this.section.id.value : '');
  }

  public ngOnDestroy(): void {
    this._subs.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Test whether to show the section-pad (true) or the section-reference (false).
   */
  public showSectionPad(): boolean {
    return !(
      this.section.sectionType === SectionType.REFERENCE_INFORM ||
      this.section.sectionType === SectionType.REFERENCE_NORM
    );
  }
}
