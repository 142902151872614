import {SearchableDocumentColumn} from './search-documents.service';

export enum EqualityRelation {
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL_TO = '<=',
  EQUAL = '=',
  NOT_EQUAL = '<>',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL_TO = '>=',
  PARTIAL = '~',
  CONTAINS = '@>',
}

enum DasqlOperation {
  AND = '&',
  OR = '|',
  NOT = '!',
}

export class QL {
  public static and(...children: QlNode[]): QlNode {
    return new MultiLeafOperationQlNode(DasqlOperation.AND, children);
  }

  public static or(...children: QlNode[]): QlNode {
    return new MultiLeafOperationQlNode(DasqlOperation.OR, children);
  }

  public static not(child: QlNode): QlNode {
    return new SingleLeafOperationQlNode(DasqlOperation.NOT, child);
  }

  public static condition(column: SearchableDocumentColumn, relation: EqualityRelation, arg: any): QlNode {
    return new ConditionQlNode(column, relation, String(arg));
  }
}

export interface QlNode {
  emit(): string;
}

class ConditionQlNode implements QlNode {
  private static readonly BACKSLASH_MATCHING_REGEX = /[\\]/g;
  private static readonly SINGLE_QUOTE_MATCHING_REGEX = /[']/g;

  private static readonly ESCAPED_BACKSLASH: string = `\\\\`;
  private static readonly ESCAPED_SINGLE_QUOTE: string = `\\'`;

  constructor(
    private readonly column: SearchableDocumentColumn,
    private readonly relation: EqualityRelation,
    private readonly arg: string
  ) {}

  public emit(): string {
    return `${this.column} ${this.relation} '${this.escapeSingleQuotes(this.arg)}'`;
  }

  protected escapeSingleQuotes(query: string): string {
    return query
      .replace(ConditionQlNode.BACKSLASH_MATCHING_REGEX, ConditionQlNode.ESCAPED_BACKSLASH)
      .replace(ConditionQlNode.SINGLE_QUOTE_MATCHING_REGEX, ConditionQlNode.ESCAPED_SINGLE_QUOTE);
  }
}

class MultiLeafOperationQlNode implements QlNode {
  constructor(private readonly operation: DasqlOperation, private readonly children: QlNode[]) {}

  public emit(): string {
    return `(${this.children
      .filter((child) => !!child)
      .map((child) => child.emit())
      .join(` ${this.operation} `)})`;
  }
}

class SingleLeafOperationQlNode implements QlNode {
  constructor(private readonly operation: DasqlOperation, private readonly child: QlNode) {}

  public emit(): string {
    return this.child ? `${this.operation}${this.child.emit()}` : '';
  }
}
