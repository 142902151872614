<div class="wrap">
  <button
    mat-icon-button
    class="close-button"
    (click)="close()"
    matTooltip="Close change manager"
    matTooltipPosition="before"
    [matTooltipShowDelay]="tooltipDelay"
    carsAnalytics="changelog-close-link-manager"
    aria-label="Close change manager"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <div class="table-wrapper">
    <mat-table [dataSource]="dataSource">
      <!-- Document column -->
      <ng-container matColumnDef="document">
        <mat-header-cell *matHeaderCellDef>{{ tableTitle }}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.documentAndSectionTitles }}</mat-cell>
      </ng-container>

      <!-- Excerpt column -->
      <ng-container matColumnDef="excerpt">
        <mat-header-cell *matHeaderCellDef>Excerpt</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.excerpt }}</mat-cell>
      </ng-container>

      <!-- Comment column -->
      <ng-container matColumnDef="change">
        <mat-header-cell *matHeaderCellDef>Change</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="change-column">
            <div *ngIf="!row.editing">
              <button
                mat-icon-button
                *ngIf="!editing"
                class="delete-button"
                (click)="delete(row)"
                matTooltip="Delete link"
                matTooltipPosition="before"
                [matTooltipShowDelay]="tooltipDelay"
                carsAnalytics="changelog-delete-link"
                aria-label="Delete link"
              >
                <mat-icon>delete</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="!editing"
                class="edit-button"
                (click)="edit(row)"
                matTooltip="Edit change"
                matTooltipPosition="before"
                carsAnalytics="changelog-start-editing-link"
                [matTooltipShowDelay]="tooltipDelay"
                aria-label="Edit change"
              >
                <mat-icon>mode_edit</mat-icon>
              </button>
              <p>
                Change Type:
                {{ formatLinkType(row.link.type) }}
              </p>
              <p>
                Comment:
                {{ row.link.comment }}
              </p>
            </div>

            <div class="editing" *ngIf="row.editing">
              <span>Type of change:</span>
              <mat-radio-group class="radio-buttons" [(ngModel)]="commentType">
                <mat-radio-button value="EDITORIAL" aria-label="Choose type of change, editorial"
                  >Editorial</mat-radio-button
                >
                <mat-radio-button value="TECHNICAL" aria-label="Choose type of change, technical"
                  >Technical</mat-radio-button
                >
                <mat-radio-button
                  value="TECHNICAL_AND_EDITORIAL"
                  aria-label="Choose type of change, editorial and technical"
                  >Technical and Editorial</mat-radio-button
                >
              </mat-radio-group>

              <textarea
                class="comment"
                [(ngModel)]="comment"
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="6"
                placeholder="Comment"
                name="comment"
                required
              >
              </textarea>

              <div>
                <button
                  mat-raised-button
                  color="primary"
                  (click)="apply(row)"
                  [disabled]="!comment || loading"
                  carsAnalytics="changelog-create-or-edit-link"
                  aria-label="Create change"
                  enableDefaultFocus
                >
                  Apply
                </button>
                <button
                  mat-button
                  (click)="cancel(row)"
                  [disabled]="loading"
                  carsAnalytics="changelog-cancel-create-or-edit-link"
                  aria-label="Cancel creating change"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <p *ngIf="!displayLinks.length && !loading" class="empty-message">
      There are no other selections linked to this selection.
    </p>
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate" aria-label="Loading changes"></mat-progress-bar>
</div>
