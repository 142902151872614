import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DocumentFragment} from 'app/fragment/types';
import {DocumentsSearchType} from 'app/search/document-selector/documents-search-types';
import {SearchableDocument} from 'app/search/search-documents.service';
import {DocumentService} from 'app/services/document.service';
import {SearchableGlobalReference} from 'app/sidebar/references/searchable-global-reference';
import {UUID} from 'app/utils/uuid';

export interface ReferenceDialogOutputData {
  save: boolean;
  title: string;
  author: string;
  publisher: string;
  reference: string;
  carsDocumentId: UUID;
}

@Component({
  selector: 'cars-reference-dialog',
  templateUrl: './reference-dialog.component.html',
  styleUrls: ['./reference-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceDialogComponent implements OnInit {
  public readonly DocumentsSearchType: typeof DocumentsSearchType = DocumentsSearchType;

  public title: string;
  public author: string;
  public publisher: string;
  public reference: string;
  public carsDocumentId: UUID;
  public initialSelectedDocument: DocumentFragment;
  public loadingDocument: boolean = false;

  public titleErrorMessage: string = 'Title is required';

  public static open(
    dialog: MatDialog,
    reference: SearchableGlobalReference
  ): MatDialogRef<ReferenceDialogComponent, ReferenceDialogOutputData> {
    return dialog.open(ReferenceDialogComponent, {
      ariaLabel: 'Edit existing reference dialog',
      width: '800px',
      disableClose: true,
      data: reference,
    });
  }

  constructor(
    private _dialogRef: MatDialogRef<ReferenceDialogComponent, ReferenceDialogOutputData>,
    @Inject(MAT_DIALOG_DATA) private _data: SearchableGlobalReference,
    private _documentService: DocumentService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (this._data) {
      this.title = this._data.title;
      this.author = this._data.author;
      this.publisher = this._data.publisher;
      this.reference = this._data.reference;
      this.carsDocumentId = this._data.carsDocumentId;
      if (this.carsDocumentId) {
        this.loadingDocument = true;
        this._documentService
          .load(
            this.carsDocumentId,
            {
              projection: 'INITIAL_DOCUMENT_LOAD',
            },
            false
          )
          .then((doc) => {
            this.initialSelectedDocument = doc;
            this.loadingDocument = false;
            this._cdr.markForCheck();
          });
      }
    }
  }

  public close(save: boolean): void {
    this._dialogRef.close({
      save: save,
      title: this.title,
      author: this.author,
      publisher: this.publisher,
      reference: this.reference,
      carsDocumentId: this.carsDocumentId,
    });
  }

  public titleIsEmpty(): boolean {
    return !this.title || !this.title.trim().length;
  }

  public updateSelectedCarsDocument(document: SearchableDocument): void {
    this.carsDocumentId = document ? document.DOCUMENT_ID : null;
  }
}
