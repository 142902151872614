import {Component, Inject, OnDestroy} from '@angular/core';
import {MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ChangelogLink, ChangelogLinkType} from 'app/changelog/changelog-link';
import {ChangelogRange} from 'app/changelog/changelog-range';
import {ChangelogService} from 'app/changelog/changelog.service';
import {LinkBuilder} from 'app/changelog/link-builder/link-builder';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {DocumentFragment, FragmentType} from 'app/fragment/types';
import {environment} from 'environments/environment';

// A table row in the <mat-table>
export interface LinkTableRow {
  link: ChangelogLink;
  documentAndSectionTitles: string;
  excerpt: string;
  editing: boolean;
  range: ChangelogRange;
}

export interface FragmentIndex {
  index: string;
  value: string;
}

@Component({
  selector: 'cars-link-manager',
  templateUrl: './link-manager.component.html',
  styleUrls: ['./link-manager.component.scss'],
})
export class LinkManagerComponent implements OnDestroy {
  public tooltipDelay: number = environment.tooltipDelay;

  public dataSource: MatTableDataSource<LinkTableRow>;
  public displayedColumns: string[] = ['document', 'excerpt', 'change'];
  public displayLinks: LinkTableRow[] = [];

  public loading: boolean = true;

  /** True if the comment on any link is being edited */
  public editing: boolean;

  /** The current value of the comment being edited */
  public comment: string;

  /** The type of the comment being edited */
  public commentType: ChangelogLinkType = 'EDITORIAL';

  /** The main range, whose links can be viewed and managed from this component. */
  public range: ChangelogRange;

  /** If the manager was opened as part of the link creation process, this is the range being linked to.
   *  Otherwise, will be null.  */
  public otherRange: ChangelogRange;

  /**
   * Determines the table header, which depends on the status of the document in the changelog
   */
  public tableTitle: string;

  public static DIALOG_OPTIONS_FACTORY(range: ChangelogRange, otherRange: ChangelogRange): MatDialogConfig<any> {
    return {
      ariaLabel: 'View change log comments',
      width: '960px',
      data: {
        range: range,
        otherRange: otherRange,
      },
    };
  }

  constructor(
    public dialogRef: MatDialogRef<LinkManagerComponent>,
    public linkBuilder: LinkBuilder,
    private changelogService: ChangelogService,
    @Inject(MAT_DIALOG_DATA)
    data: {range: ChangelogRange; otherRange: ChangelogRange}
  ) {
    this.range = data.range;
    this.otherRange = data.otherRange;
    this.editing = !!data.otherRange;

    Promise.all([
      this.changelogService.findLinksByRangeId(this.range.id, this.range.published),
      this.changelogService.findRangesLinkedToRange(this.range.id),
    ])
      .then(([links, ranges]: [ChangelogLink[], ChangelogRange[]]) => {
        this.initialiseDataTable(links, ranges);
      })
      .catch((err) => {
        this.loading = false;
        Logger.error('changelog-error', 'Unable to get changelog links', err);
      });
  }

  private initialiseDataTable(links: ChangelogLink[], ranges: ChangelogRange[]): void {
    const lookup: Record<string, ChangelogRange> = {};
    ranges.forEach((range: ChangelogRange) => (lookup[range.id.value] = range));
    this.tableTitle = this.range.published ? 'Outcome Document/Section' : 'Published Document/Section';
    if (this.otherRange) {
      this.mapToRow(this.otherRange, null).then((linkTableRow: LinkTableRow) => {
        this.displayLinks.unshift(linkTableRow);
        this.loading = false;
        this.dataSource = new MatTableDataSource(this.displayLinks);
      });
    }
    links.forEach((link: ChangelogLink) =>
      this.mapLinkToRow(link, lookup).then((linkTableRow: LinkTableRow) => {
        this.displayLinks.push(linkTableRow);
        this.loading = false;
        this.dataSource = new MatTableDataSource(this.displayLinks);
      })
    );
  }

  /**
   * Maps a Link to the form in which it is displayed in the table.  If the range at the
   * other end of the link has been fully deserialised (i.e. with fragments), the columns can
   * be generated properly.  Otherwise, the cached information stored in the range object
   * is used.
   *
   * @param link   {ChangelogLink}                           The link to map.
   * @param lookup {Record<string, ChangelogRange>} A map keyed on range ID to range.
   *
   * @returns {LinkTableRow} The table row.
   */
  private mapLinkToRow(link: ChangelogLink, lookup: Record<string, ChangelogRange>): Promise<LinkTableRow> {
    const otherRange: ChangelogRange = this.range.published
      ? lookup[link.authoredRangeId.value]
      : lookup[link.publishedRangeId.value];
    return this.mapToRow(otherRange, link);
  }

  private mapToRow(otherRange: ChangelogRange, link: ChangelogLink): Promise<LinkTableRow> {
    return this.changelogService.getFragmentIndex(otherRange.sectionId).then((fragmentIndex: FragmentIndex) => {
      const excerpt: string = otherRange.value || otherRange.storedValue;
      let documentTitle: string = otherRange.documentTitle;
      let documentCode: string = otherRange.documentCode;
      if (otherRange.start.fragment) {
        const doc: DocumentFragment = otherRange.start.fragment.findAncestorWithType(
          FragmentType.DOCUMENT
        ) as DocumentFragment;
        if (doc) {
          documentTitle = doc.title;
          documentCode = doc.documentCode;
        }
      }
      const documentString: string = `Document: ${!documentCode ? '' : documentCode + ' - '}${documentTitle}`;
      const sectionString: string = `Section: ${!fragmentIndex.index ? '' : fragmentIndex.index + ' '}${
        fragmentIndex.value
      }`;
      return {
        link: link,
        range: otherRange,
        documentAndSectionTitles: documentString + '\n' + sectionString,
        excerpt: excerpt,
        editing: link === null,
      };
    });
  }

  public ngOnDestroy(): void {
    this.linkBuilder.reset();
  }

  /**
   * Start editing a link.
   *
   * @param row {LinkTableRow} The row being edited.
   */
  public edit(row: LinkTableRow): void {
    this.editing = true;
    row.editing = true;
    if (row.link) {
      this.comment = row.link.comment;
      this.commentType = row.link.type;
    }
    this.linkBuilder.reset().addRange(this.range).addRange(row.range);
  }

  /**
   * Delete a link.
   *
   * @param row {LinkTableRow} The row being deleted.
   */
  public delete(row: LinkTableRow): void {
    this.loading = true;
    this.changelogService
      .deleteLink(row.link)
      .then(() => {
        const index = this.displayLinks.indexOf(row);
        if (index > -1) {
          this.displayLinks.splice(index, 1);
          this.dataSource.data = this.displayLinks;
        }
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }

  public close(): void {
    this.dialogRef.close();
  }

  /**
   * Save an edit to a link or finish creating it.
   *
   * @param row {LinkTableRow} The row to be edited/created.
   */
  public apply(row: LinkTableRow): void {
    this.loading = true;
    const link: ChangelogLink = this.linkBuilder.addComment(this.comment, this.commentType).build();
    if (row.link && row.link.id) {
      link.id = row.link.id;
    }
    const operation: Promise<ChangelogLink> =
      row.link && row.link.id ? this.changelogService.updateLink(link) : this.changelogService.createLink(link);

    operation
      .then((persisted: ChangelogLink) => {
        row.editing = false;
        row.link = persisted;
        this.editing = false;
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }

  /**
   * Cancel editing or creating a Link.
   *
   * @param row {LinkTableRow} The row being updated or which is under construction.
   */
  public cancel(row: LinkTableRow): void {
    if (!row.link) {
      // Remove the row for the link that was being created
      const i: number = this.displayLinks.indexOf(row);
      if (i > -1) {
        this.displayLinks.splice(i, 1);
        this.dataSource.data = this.displayLinks;
      }
    }
    this.linkBuilder.reset();
    row.editing = false;
    this.editing = false;
    this.comment = '';
    this.commentType = 'TECHNICAL';
  }

  /**
   * Get the correct string to display for the link type.
   *
   * @param type {ChangelogLinkType} The link type to get the string from
   *
   * @returns {string} the string to be displayed
   */
  public formatLinkType(type: ChangelogLinkType): string {
    switch (type) {
      case 'TECHNICAL':
        return 'Technical';
      case 'EDITORIAL':
        return 'Editorial';
      case 'TECHNICAL_AND_EDITORIAL':
        return 'Technical and Editorial';
      default:
        Logger.error('changelog-error', 'Unable to format changeling LinkType');
    }
  }
}
