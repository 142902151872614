<div class="container">
  <strong>Delete/Edit References</strong>
  <div class="download">
    <mat-progress-spinner *ngIf="downloadingCsv" [diameter]="24" mode="indeterminate"></mat-progress-spinner>
    <button
      mat-button
      [disabled]="downloadingCsv"
      (click)="downloadWithdrawReferenceCsv()"
      [matTooltipShowDelay]="tooltipDelay"
      matTooltipPosition="below"
      [matTooltip]="downloadingCsv ? 'Downloading csv, please wait' : 'Download csv'"
      [attr.aria-label]="downloadingCsv ? 'Downloading csv, please wait' : 'Download csv'"
    >
      Download Withdrawn References .csv <mat-icon>get_app</mat-icon>
    </button>
  </div>

  <form>
    <mat-form-field>
      <input
        matInput
        name="searchBar"
        placeholder="Search"
        [(ngModel)]="searchValue"
        (ngModelChange)="onSearchQueryChange($event)"
        aria-label="search references"
      />
    </mat-form-field>
  </form>

  <table
    mat-table
    #table
    matSort
    multiTemplateDataRows
    [dataSource]="dataSource"
    matSortDisableClear
    class="wide table"
  >
    <ng-container matColumnDef="withdrawn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.min-width]="columnWidths['withdrawn']">Withdrawn</th>
      <td mat-cell *matCellDef="let row">{{ row.withdrawn ? 'Yes' : 'No' }}</td>
    </ng-container>

    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.min-width]="columnWidths['reference']">Reference</th>
      <td mat-cell *matCellDef="let row">
        {{ row.reference }}
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.min-width]="columnWidths['title']">Title</th>
      <td mat-cell *matCellDef="let row">
        {{ row.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="is_superseded">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.min-width]="columnWidths['is_superseded']">
        Is Superseded?
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.supersededBy.length ? 'Yes' : 'No' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="search_superseding">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.min-width]="columnWidths['search_superseding']">
        Superseded By
      </th>
      <td mat-cell *matCellDef="let row">
        <ul>
          <li *ngFor="let supersedingDocument of row.supersededBy">
            {{ supersedingDocument.title }}
          </li>
        </ul>
      </td>
    </ng-container>

    <ng-container matColumnDef="cars_document_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.min-width]="columnWidths['cars_document_id']">
        Has CARS Link?
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.carsDocumentId ? 'Yes' : 'No' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.min-width]="columnWidths['created_at']">
        Created At
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.createdAtString ? row.createdAtString : 'Unknown Date' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="created_by">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
      <td mat-cell *matCellDef="let row">{{ row.createdBy }}</td>
    </ng-container>

    <ng-container matColumnDef="withdraw_button">
      <th mat-header-cell *matHeaderCellDef [style.min-width]="columnWidths['withdraw_button']"></th>
      <td mat-cell *matCellDef="let row">
        <button
          mat-button
          color="primary"
          [attr.aria-label]="'Withdraw global reference ' + row.reference"
          (click)="withdrawGlobalReference(row, $event)"
        >
          {{ row.withdrawn ? 'Edit withdrawal' : 'Withdraw reference' }}
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit_button">
      <div class="cell">
        <th mat-header-cell *matHeaderCellDef [style.min-width]="columnWidths['edit_button']"></th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-button
            color="primary"
            [attr.aria-label]="'Edit global reference ' + row.reference"
            (click)="editGlobalReference(row, $event)"
          >
            Edit
          </button>
        </td>
      </div>
    </ng-container>

    <ng-container matColumnDef="delete_button">
      <div class="cell">
        <th mat-header-cell *matHeaderCellDef [style.min-width]="columnWidths['delete_button']"></th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-button
            color="warn"
            [attr.aria-label]="'Delete global reference ' + row.getShortName()"
            (click)="deleteGlobalReference(row, $event)"
          >
            Delete
          </button>
        </td>
      </div>
    </ng-container>

    <ng-container matColumnDef="expandedDetail" class="wide">
      <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" class="blue-left wide">
        <div [@detailExpand]="isSelected(row) ? 'expanded' : 'collapsed'" class="wide">
          <mat-nav-list class="wide">
            <ng-container *ngIf="locationExpanded[row.globalReferenceId.value]">
              <div class="loading-locations" *ngIf="loadingLocations[row.globalReferenceId.value]">
                Loading live usage locations...
              </div>
              <ng-container *ngIf="!loadingLocations[row.globalReferenceId.value]">
                <span class="usages-header">Live usage locations:</span>
                <a
                  mat-list-item
                  (click)="loadingDocumentId = location.sectionId.value"
                  *ngFor="let location of referenceLocations[row.globalReferenceId.value]"
                  [routerLink]="getRouterLink(location.documentId, location.sectionId)"
                  class="block"
                >
                  <div class="flex wide">
                    <div class="number-usages"><span>No. usages: </span>{{ location.count }}</div>
                    <div class="document-title"><span>Document: </span>{{ location.documentTitle }}</div>
                    <div class="section-title"><span>Section: </span>{{ location.sectionTitle }}</div>
                    <div class="document-owner">
                      <span>Technical Author: </span>
                      <cars-user *ngIf="!!location.documentOwner" [userId]="location.documentOwner"></cars-user>
                      <ng-container *ngIf="!location.documentOwner"></ng-container>
                    </div>
                    <div class="year-of-issue"><span>Year of issue: </span>{{ location.yearOfIssue }}</div>
                    <div class="reference-type"><span>Type: </span>{{ location.referenceType | titlecase }}</div>
                  </div>
                  <div class="flex wide">
                    <mat-progress-bar
                      *ngIf="loadingDocumentId === location.sectionId.value"
                      mode="indeterminate"
                      aria-label="Loading document"
                    >
                    </mat-progress-bar>
                  </div>
                </a>
                <div
                  class="usages-none"
                  *ngIf="
                    !referenceLocations[row.globalReferenceId.value] ||
                    referenceLocations[row.globalReferenceId.value].length === 0
                  "
                >
                  Reference not currently used
                </div>
              </ng-container>
            </ng-container>
          </mat-nav-list>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let result; columns: displayedColumns"
      class="row"
      [class.selected]="isSelected(result)"
      [class.withdrawn]="result.withdrawn"
      (click)="toggleExpanded(result)"
    ></tr>
    <tr
      mat-row
      *matRowDef="let result; columns: ['expandedDetail']"
      class="detail-row wide"
      [class.border]="isSelected(result)"
    ></tr>
  </table>

  <div *ngIf="dataSource.data.length === 0 && loading" class="no-results">Loading</div>
  <div *ngIf="dataSource.data.length === 0 && !loading" class="no-results">No results</div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate" aria-label="Loading references"> </mat-progress-bar>
  <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>
