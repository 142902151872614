import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {VersionAggregateModule} from './version-aggregate/version-aggregate.module';
import {VersionAggregatesListComponent} from './version-aggregates-list.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, MatListModule, MatCardModule, VersionAggregateModule],
  declarations: [VersionAggregatesListComponent],
  exports: [VersionAggregatesListComponent],
})
export class VersionAggregatesListModule {}
