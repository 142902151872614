import {Injectable} from '@angular/core';
import {FragmentCache} from 'app/fragment/diff/fragment-cache';
import {environment} from 'environments/environment';
import {Fragment} from './types';

@Injectable({
  providedIn: 'root',
})
export class CacheManager {
  /** Map of timestamp to cache of fragments which existed at that time */
  private readonly _historicalCaches: Map<number, FragmentCache> = new Map();

  /** The cache for live fragments */
  private readonly _liveCache: FragmentCache = new FragmentCache(environment.undoSize);

  /** The timestamp of the current view */
  public time: number;

  constructor() {}

  /**
   * Get the cache relevant for the given time.  Guaranteed to be non-null if time is non-null.
   *
   * @param time {number}        The timestamp of the version.
   * @returns    {FragmentCache} The cache, or null if time was null.
   */
  public getHistoricalCache(time: number): FragmentCache {
    if (!time) {
      return null;
    }
    let cache: FragmentCache = this._historicalCaches.get(time);
    if (!cache) {
      cache = new FragmentCache(1); // Historical caches do not need an undo buffer.
      this._historicalCaches.set(time, cache);
    }
    return cache;
  }

  /**
   * Get all caches relevant for the duration of the given fragment. Returns an empty array if the relevant versions
   * have not been loaded into the cache. Returns null for a live fragment.
   */
  public getHistoricalCachesForFragmentVersion(fragment: Fragment): FragmentCache[] {
    if (!fragment.validTo) {
      return null;
    }

    const caches: FragmentCache[] = [];
    this._historicalCaches.forEach((cache, time) => {
      if (fragment.validFrom <= time && time < fragment.validTo) {
        caches.push(cache);
      }
    });
    return caches;
  }

  /**
   * Get the cache relevant to the current view.  This may be either the live
   * or a historical cache.
   *
   * @returns {FragmentCache} The cache.  Will never be null.
   */
  public getCurrentViewCache(): FragmentCache {
    const historicalCache: FragmentCache = this.getHistoricalCache(this.time);
    return historicalCache ? historicalCache : this._liveCache;
  }

  /**
   * Get the live cache.
   *
   * @returns {FragmentCache} The cache.  Will never be null.
   */
  public getLiveCache(): FragmentCache {
    return this._liveCache;
  }
}
