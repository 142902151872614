import {Injectable} from '@angular/core';
import {ChangelogRange} from 'app/changelog/changelog-range';
import {Caret} from 'app/fragment/caret';
import {CarsRange, CarsRangeType} from 'app/fragment/cars-range';
import {AnchorFragment, ClauseFragment, Fragment, FragmentType} from 'app/fragment/types';
import {Suggestion} from 'app/interfaces';
import {Discussion} from 'app/sidebar/discussions/discussions';
import {Callback} from 'app/utils/typedefs';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {FragmentService} from './fragment.service';

@Injectable({
  providedIn: 'root',
})
export class CanvasService {
  private static readonly RENDER_VIEWS: Record<string, CarsRangeType[]> = {
    NONE: [],
    DEFAULT: [
      CarsRangeType.PENDING_SUGGESTION,
      CarsRangeType.SUGGESTION,
      CarsRangeType.SELECTION,
      CarsRangeType.SPELLING_ERROR,
      CarsRangeType.SEARCH_RESULT,
      CarsRangeType.SELECTED_SEARCH_RESULT,
    ],
    CHANGELOG: [
      CarsRangeType.PENDING_SUGGESTION,
      CarsRangeType.SUGGESTION,
      CarsRangeType.SELECTION,
      CarsRangeType.CHANGELOG_RANGE,
      CarsRangeType.DELETED_CHANGELOG_RANGE,
      CarsRangeType.SPELLING_ERROR,
      CarsRangeType.SEARCH_RESULT,
      CarsRangeType.SELECTED_SEARCH_RESULT,
    ],
    CHANGELOG_MARKUP: [
      CarsRangeType.PENDING_SUGGESTION,
      CarsRangeType.SUGGESTION,
      CarsRangeType.SELECTION,
      CarsRangeType.CHANGELOG_RANGE,
      CarsRangeType.DELETED_CHANGELOG_RANGE,
    ],
  };

  private toDraw: BehaviorSubject<Map<string, CarsRange>> = new BehaviorSubject(new Map());

  private perClauseSpellingErrors: Map<string, CarsRange[]> = new Map<string, CarsRange[]>(); // Map of clause ID to spelling error ranges.

  private renderingEnabled: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private renderingTypes: BehaviorSubject<CarsRangeType[]> = new BehaviorSubject(CanvasService.RENDER_VIEWS['DEFAULT']);

  private mouseEventsEnabled: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private currentView: CurrentView;

  constructor(private _fragmentService: FragmentService, private _viewService: ViewService) {
    this._viewService.onCurrentViewChange((view: CurrentView) => {
      if (!view || (this.currentView && view.viewMode === this.currentView.viewMode)) {
        return;
      }

      this.currentView = view;

      if (view && view.isChangelog()) {
        this.setRenderingTypes(CanvasService.RENDER_VIEWS['CHANGELOG']);
      } else if (view && view.isChangelogMarkup()) {
        this.setRenderingTypes(CanvasService.RENDER_VIEWS['CHANGELOG_MARKUP']);
      } else {
        this.setRenderingTypes(CanvasService.RENDER_VIEWS['DEFAULT']);
      }

      this.toDraw.next(this.toDraw.value);
    });
  }

  public addChangelogRange(range: ChangelogRange): void {
    if (!range || !range.id || !range.start || !range.start.fragment || !range.end || !range.end.fragment) {
      console.error(
        'changelog-error',
        'Tried to draw a changelog range which could not be drawn: ' + (range && range.id ? range.id.value : 'null')
      );
      return;
    }
    this.addRange(range.hashcode(), range);
  }

  public removeChangelogRange(range: ChangelogRange): void {
    if (!range || !range.id) {
      return;
    }
    this.removeRange(range.hashcode());
  }

  /**
   * Tell the service to draw a highlighted caret or range.
   * @param caret the range to draw.
   */
  public drawCaret(caret: CarsRange): void {
    this.addRange(CarsRangeType[CarsRangeType.SELECTION], caret);
  }

  /**
   * Draws the errors in the given clause.
   *
   * @param clause  {ClauseFragment}  The given clause.
   * @param errors  {CarsRange[]}     The errors in the clause.
   */
  public drawClauseSpellingErrors(clause: ClauseFragment, errors: CarsRange[]): void {
    this._drawSpellingErrors(new Map().set(clause.id.value, errors));
  }

  /**
   * Draw the given section spelling errors onto the pad.
   *
   * @param errorsPerClause {Map<string, CarsRange[]>}  Map of clause id to errors in the clause.
   */
  public drawSectionSpellingErrors(errorsPerClause: Map<string, CarsRange[]>): void {
    this._drawSpellingErrors(errorsPerClause);
  }

  /**
   * Draws spelling errors onto the pad, removing any old spelling errors and re-drawing new ones.
   *
   * @param errorsPerClause {Map<string, CarsRange[]>}  Map of clause id to errors in the clause.
   */
  private _drawSpellingErrors(errorsPerClause: Map<string, CarsRange[]>): void {
    const errors: CarsRange[] = [];
    const rangesToRemove: CarsRange[] = [];

    errorsPerClause.forEach((clauseErrors: CarsRange[], id: string) => {
      rangesToRemove.push(...this._getDiffSet(this.perClauseSpellingErrors.get(id) || [], clauseErrors || []));
      this.perClauseSpellingErrors.set(id, clauseErrors);
      errors.push(...clauseErrors);
    });

    this.removeRanges(rangesToRemove);
    this.drawRanges(errors);
  }

  /**
   * Returns the ranges that are no longer in the newRanges array.
   *
   * @param oldRanges {CarsRange[]} The first range
   * @param newRanges {CarsRange[]} The second range
   * @returns         {CarsRange[]} The range diff set
   */
  private _getDiffSet(oldRanges: CarsRange[], newRanges: CarsRange[]): CarsRange[] {
    const diffSet: CarsRange[] = oldRanges.filter((range: CarsRange) => {
      const index: number = newRanges.findIndex((_r: CarsRange) => range.equals(_r));
      return index < 0;
    });

    return diffSet;
  }

  /**
   * Draws given ranges onto the pad.
   *
   * @param ranges {CarsRange[]} The ranges to draw
   */
  public drawRanges(ranges: CarsRange[]): void {
    let changed: boolean = false;
    ranges.forEach((range: CarsRange) => {
      if (range && range.hashcode()) {
        this.toDraw.value.set(range.hashcode(), range);
        changed = true;
      }
    });
    if (changed) {
      this.toDraw.next(this.toDraw.value);
    }
  }

  /**
   * Removes ranges on the pad.
   *
   * @param ranges {CarsRange[]} The ranges to remove
   */
  public removeRanges(ranges: CarsRange[]): void {
    let changed: boolean = false;
    ranges.forEach((range: CarsRange) => {
      if (range && range.hashcode()) {
        this.toDraw.value.delete(range.hashcode());
        changed = true;
      }
    });
    if (changed) {
      this.toDraw.next(this.toDraw.value);
    }
  }

  /**
   * Remove the highlighted caret.
   */
  public clearCaretHighlight(): void {
    this.removeRange(CarsRangeType[CarsRangeType.SELECTION]);
  }

  public clearAllSearchHighlights(): void {
    const toRemove: CarsRange[] = [];
    this.toDraw.value.forEach((range: CarsRange, key: string) => {
      if (range.type === CarsRangeType.SEARCH_RESULT) {
        toRemove.push(range);
      } else if (range.type === CarsRangeType.SELECTED_SEARCH_RESULT) {
        this.removeRange(key);
      }
    });
    this.removeRanges(toRemove);
  }

  public selectSearchRange(hash: string): string {
    // Change the old selected search range to a normal search range
    const oldRange: CarsRange = this.toDraw.value.get(CarsRangeType[CarsRangeType.SELECTED_SEARCH_RESULT]);
    if (oldRange) {
      this.removeRange(CarsRangeType[CarsRangeType.SELECTED_SEARCH_RESULT]);
      oldRange.type = CarsRangeType.SEARCH_RESULT;
      this.addRange(oldRange.hashcode(), oldRange);
    }
    // Change the given search range to selected search range
    const range: CarsRange = this.toDraw.value.get(hash);
    this.removeRange(hash);
    range.type = CarsRangeType.SELECTED_SEARCH_RESULT;
    this.addRange(CarsRangeType[CarsRangeType.SELECTED_SEARCH_RESULT], range);

    return oldRange ? oldRange.hashcode() : null;
  }

  /**
   * Draw a suggestion highlight.
   * @param discussion the discussion with attached suggestion to draw.
   */
  public addSuggestionHighlight(discussion: Discussion): void {
    if (!discussion || !discussion.suggestion || discussion.resolved) {
      return;
    }
    const suggestion: Suggestion = discussion.suggestion;
    const startFragment: Fragment = this._fragmentService.find(suggestion.startFragmentId);
    const endFragment: Fragment = this._fragmentService.find(suggestion.endFragmentId);

    if (startFragment && endFragment) {
      const range: CarsRange = new CarsRange(
        new Caret(startFragment, suggestion.startOffset),
        new Caret(endFragment, suggestion.endOffset),
        CarsRangeType.SUGGESTION
      );
      // handle caret normalisation:
      if (range.start.fragment.equals(startFragment) && range.end.fragment.equals(endFragment)) {
        this.addRange(range.hashcode(), range);
      }
    }
  }

  /**
   * Clear a suggestion highlight.
   * @param discussion the discussion with attached suggestion to clear.
   */
  public removeSuggestionHighlight(discussion: Discussion): void {
    if (!discussion || !discussion.suggestion) {
      return;
    }
    const suggestion: Suggestion = discussion.suggestion;
    const startFragment: Fragment = this._fragmentService.find(suggestion.startFragmentId);
    const endFragment: Fragment = this._fragmentService.find(suggestion.endFragmentId);

    if (startFragment && endFragment) {
      this.removeRange(
        new CarsRange(
          new Caret(startFragment, suggestion.startOffset),
          new Caret(endFragment, suggestion.endOffset),
          CarsRangeType.SUGGESTION
        ).hashcode()
      );
    }
    const toDelete: Fragment[] = [];
    if (startFragment && startFragment.is(FragmentType.ANCHOR)) {
      (startFragment as AnchorFragment).hasBeenResolved = true;
      toDelete.push(startFragment);
    }
    if (endFragment && startFragment.is(FragmentType.ANCHOR)) {
      (endFragment as AnchorFragment).hasBeenResolved = true;
      toDelete.push(endFragment);
    }
    this._fragmentService.update(toDelete).then(() => {
      // Always anchor fragments, no need to validate
      this._fragmentService.delete(toDelete);
    });
  }

  /**
   * Shows the last text range highlighted if the discussions sidebar is open.
   * @param range the range to draw.
   */
  public setPendingSuggestionHighlight(range: CarsRange): void {
    if (!range || !range[0] || !range[1] || range.isCollapsed()) {
      return;
    }
    const pending: CarsRange = new CarsRange(range[0], range[1], CarsRangeType.PENDING_SUGGESTION);
    this.addRange(CarsRangeType[CarsRangeType.PENDING_SUGGESTION], pending);
  }

  /**
   * Clear the last text range highlighted if the discussions sidebar is open.
   */
  public clearPendingSuggestionHighlight(): void {
    this.toDraw.value.delete(CarsRangeType[CarsRangeType.PENDING_SUGGESTION]);
    this.toDraw.next(this.toDraw.value);
  }

  public getRanges(): Observable<Map<string, CarsRange>> {
    return this.toDraw.asObservable();
  }

  private addRange(key: string, range: CarsRange) {
    if (!range) {
      return;
    }
    this.toDraw.value.set(key, range);
    this.toDraw.next(this.toDraw.value);
  }

  private removeRange(key: string): void {
    if (!key) {
      return;
    }
    this.toDraw.value.delete(key);
    this.toDraw.next(this.toDraw.value);
  }

  /**
   * Enable or disable rendering.
   *
   * @param enable {boolean} True to turn on rendering, false to turn off.
   */
  public toggleRendering(enable: boolean): void {
    this.renderingEnabled.next(enable);
  }

  public onRenderingToggled(callback: Callback<boolean>): Subscription {
    return this.renderingEnabled.subscribe(callback);
  }

  /**
   * Declare which types of range should be drawn.
   *
   * @param types {CarsRangeType[]} The ranges to draw.
   */
  public setRenderingTypes(types: CarsRangeType[]): void {
    this.renderingTypes.next(types);
  }

  public onRenderingTypesChanged(callback: Callback<CarsRangeType[]>): Subscription {
    return this.renderingTypes.subscribe(callback);
  }

  /**
   * Enable or disable mouse events for ranges.
   *
   * @param enable {boolean} True to turn on mouse events, false to turn off.
   */
  public togglemouseEvents(enable: boolean): void {
    this.mouseEventsEnabled.next(enable);
  }

  public onMouseEventsToggled(callback: Callback<boolean>): Subscription {
    return this.mouseEventsEnabled.subscribe(callback);
  }

  public getRange(hash: string): CarsRange {
    return this.toDraw.value.get(hash);
  }
}
