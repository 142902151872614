import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {AltImageModule} from './alt-image/alt-image.module';
import {AltTextComponent} from './alt-text.component';

@NgModule({
  declarations: [AltTextComponent],
  imports: [CommonModule, AltImageModule, MatCheckboxModule, CarsPermissionPipesModule],
  exports: [AltTextComponent],
})
export class AltTextModule {}
