import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {StringUtils} from 'app/utils/string-utils';
import {environment} from 'environments/environment';
import {Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {Log} from './log';

export type ErrorCode =
  | 'untyped-error' // default
  | 'auth-error'
  | 'changelog-error'
  | 'chat-error'
  | 'diff-error'
  | 'discussion-error'
  | 'document-overview-error'
  | 'export-error'
  | 'feedback-error'
  | 'fragment-error'
  | 'history-error'
  | 'image-error'
  | 'input-error'
  | 'jira-error'
  | 'lock-error'
  | 'metrics-error'
  | 'navigation-error'
  | 'offline-error'
  | 'paste-error'
  | 'range-error'
  | 'reference-error'
  | 'retries-failed-error'
  | 'search-error'
  | 'service-worker-error'
  | 'spelling-error'
  | 'table-layout-error'
  | 'table-paste-error'
  | 'tree-structure-error'
  | 'user-creation-error'
  | 'user-retrieval-error'
  | 'validation-error'
  | 'version-tag-error'
  | 'document-templates-error'
  | 'webapp-version-error'
  | 'websocket-error'
  | 'weight-error'
  | 'dashboards-error'
  | 'review-state-error'
  | 'configuration-error'
  | 'equation-error'
  | 'clause-link-error'
  | 'naa-creation-error'
  | 'internal-reference-error'
  | 'unit-error'
  | 'schedule-table-error'
  | 'tdif-error'
  | 'clone-error';

export type TriggerType = 'unknown' | 'mouse' | 'keyboard';

export class Logger {
  private static readonly _LOGGING_ENDPOINT: string = `${environment.apiHost}/logs`;

  private static _httpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private static _httpClient: HttpClient;

  private static _ready: Subject<void> = new Subject();

  /** uncaught errors keyed by hash to prevent spamming the backend */
  private static _uncaught: Map<number, Log> = new Map<number, Log>();

  private static _errors: Log[] = [];

  /**
   * Prints error to console and pushes error log to web service if not already seen.
   *
   * @param key     {ErrorCode} identifier for error type.
   * @param message {string} Message to display and attach to log
   * @param error   {Error | HttpErrorResponse | any} Optional error object.
   */
  public static error(key: ErrorCode, message: string, error?: Error | HttpErrorResponse | any) {
    if (!key) {
      return;
    }

    if (error instanceof Error) {
      message += ' ' + error.stack ? error.stack : error.message;
    } else if (error instanceof HttpErrorResponse) {
      message += ' ' + error.message + ': ' + (error.error ? error.error.message : '');
    } else if (error instanceof String) {
      message += ' ' + error;
    } else if (!!error) {
      message += ' ' + JSON.stringify(error);
    }

    const log: Log = Log.error(key, message);
    this._errors.push(log);
    this.publishLog(log);
    console.error(key, message);
  }

  /**
   * Prints error to console and pushes error log to web service if not already seen.
   * Should only be used by CarsErrorHandler - to log an error manually, use error().
   *
   * @param error   {Error} The uncaught error object.
   */
  public static uncaught(error: Error): void {
    if (!error) {
      return;
    }
    const message: string = error.stack ? error.stack : error.message;
    const hash: number = StringUtils.hash(error.message);

    if (!this._uncaught.get(hash)) {
      const log: Log = Log.uncaught(message, hash);
      this._uncaught.set(hash, log);
      this.publishLog(log);
    }
    console.error(error);
  }

  /**
   * Pushes an analytics log to the web service.
   *
   * @param key {string} Key of event to send to web service.
   */
  public static analytics(key: string, trigger?: TriggerType): void {
    if (key) {
      const log: Log = Log.analytics(key, trigger);
      this.publishLog(log);
    }
  }

  /**
   * Pushes a navigation log to the web service.
   *
   * @param url {string} URL navigated to.
   */
  public static navigation(url: string): void {
    if (url) {
      const log: Log = Log.navigation(url);
      this.publishLog(log);
    }
  }

  /**
   * Returns the logs from the in-memory Map of uncaught errors, and the array
   * of caught errors.
   *
   * @param hash {number} Optional hash for a given log
   * @returns    {Log[]}  Errors held in the service
   */
  public static getLogs(hash?: number): Log[] {
    const uncaught: Log[] = hash === void 0 ? Array.from(this._uncaught.values()) : [this._uncaught.get(hash)];

    return hash ? uncaught : uncaught.concat(this._errors);
  }

  /**
   * Publish logs to the web service;
   *
   * @param logs {Log} Log to publish to web service
   */
  private static publishLog(log: Log): void {
    const json: any = log.serialise();

    this._ready.pipe(take(1)).subscribe(() => {
      this._httpClient
        .post(this._LOGGING_ENDPOINT, json, {headers: this._httpHeaders})
        .toPromise()
        .catch((error: any) => console.warn(error));
    });

    if (this._httpClient) {
      this._ready.next();
    }
  }

  public static initialize(_httpClient: HttpClient) {
    Logger._httpClient = _httpClient;
    Logger._ready.next();
  }
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(_httpClient: HttpClient, private _router: Router) {
    Logger.initialize(_httpClient);
    this._router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        Logger.navigation(event.url);
      }
    });
  }
}
