import {UUID} from 'app/utils/uuid';
import {GlobalReference} from './global-reference';

export class SearchableGlobalReference {
  public static deserialise(json: any): SearchableGlobalReference {
    const globalReferenceId: UUID = UUID.orNull(json.globalReferenceId);
    const title: string = json.title;
    const reference: string = json.reference;
    const author: string = json.author;
    const publisher: string = json.publisher;
    const deleted: boolean = json.deleted;
    const withdrawn: boolean = json.withdrawn;
    const withdrawnOn: number = json.withdrawnOn;
    const searchSuperseding: string = json.searchSuperseding;
    const supersededBy: GlobalReference[] = json.supersededBy;
    const createdAtString: string = json.createdAtString;
    const createdAt: number = json.createdAt;
    const createdBy: string = json.createdBy;
    const carsDocumentId: UUID = UUID.orNull(json.carsDocumentId);

    return new SearchableGlobalReference(
      globalReferenceId,
      title,
      reference,
      author,
      publisher,
      deleted,
      withdrawn,
      withdrawnOn,
      searchSuperseding,
      supersededBy,
      createdAtString,
      createdAt,
      createdBy,
      carsDocumentId
    );
  }

  public static empty(): SearchableGlobalReference {
    return new SearchableGlobalReference(null, null);
  }

  constructor(
    public globalReferenceId: UUID,
    public title: string,
    public reference?: string,
    public author?: string,
    public publisher?: string,
    public deleted: boolean = false,
    public withdrawn: boolean = false,
    public withdrawnOn: number = null,
    public searchSuperseding?: string,
    public supersededBy: GlobalReference[] = [],
    public createdAtString?: string,
    public createdAt?: number,
    public createdBy?: string,
    public carsDocumentId: UUID = null
  ) {}

  public serialise(): any {
    const json: any = {
      globalReferenceId: this.globalReferenceId ? this.globalReferenceId.value : null,
      title: this.title,
      reference: this.reference,
      author: this.author,
      publisher: this.publisher,
      deleted: this.deleted,
      withdrawn: this.withdrawn,
      withdrawnOn: this.withdrawnOn,
      searchSuperseding: this.searchSuperseding,
      supersededBy: this.supersededBy,
      createdAtString: this.createdAtString,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      carsDocumentId: this.carsDocumentId ? this.carsDocumentId.value : null,
    };

    if (!json.globalReferenceId) {
      delete json.globalReferenceId;
    }

    return json;
  }

  public getSearchString(): string {
    return (
      this.title +
      this.reference +
      this.author +
      this.publisher +
      this.searchSuperseding +
      this.createdBy +
      this.createdAtString
    );
  }

  public getShortName(): string {
    return this.reference ? this.reference + ': ' + this.title : this.title;
  }

  public format(release?: string): string {
    return (
      `${this.publisher ? this.publisher + '. ' : ''}` +
      `${this.author ? this.author + '. ' : ''}` +
      `${this.reference ? this.reference + ', ' : ''}` +
      `'${this.title}'` +
      `${release ? ', ' + release : ''}`
    );
  }
}
