<p>Standard formats for specifier instructions</p>

<mat-radio-group>
  <mat-radio-button
    *ngFor="let clause of specifierInstructionFragments"
    [value]="clause.getSpecifierInstructionFragment().specifierInstructionType"
    (change)="selectOption(clause.getSpecifierInstructionFragment().specifierInstructionType)"
  >
    <span class="example">
      <div>SI.X.X</div>
      <span>{{ clause.getSpecifierInstructionFragment().preview }}</span>

      <span #source *ngIf="!!clause.getGuidanceText()" class="guidance-text"
        >Example: {{ clause.getGuidanceText() }}</span
      >

      <span *ngIf="isDiscouragedSI(clause.getSpecifierInstructionFragment())" class="guidance-text">
        Note: Usage of this very generic Specifier Instruction is discouraged. Please use one of the more specific
        Specifier Instructions available wherever possible.
      </span>
    </span>
  </mat-radio-button>
</mat-radio-group>
