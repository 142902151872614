import {animate, state, style, transition, trigger} from '@angular/animations';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, SortDirection} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {
  ReferenceDialogComponent,
  ReferenceDialogOutputData,
} from 'app/admin/manage-references/reference-dialog/reference-dialog.component';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {ReferenceService, ReferenceUsageLocation} from 'app/services/references/reference.service';
import {SearchableGlobalReferenceService} from 'app/services/references/searchable-global-reference.service';
import {SupersedingReferenceService} from 'app/services/references/superseding-reference.service';
import {SearchableGlobalReference} from 'app/sidebar/references/searchable-global-reference';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {SupersedingReference} from '../../sidebar/references/superseding-reference';
import {WithdrawalDialogComponent} from './withdrawal-dialog/withdrawal-dialog.component';

export interface Page<T> {
  resultsList: T[];
  total: number;
}

export interface SupersedingModalDataPackage {
  save: boolean;
  reference: SearchableGlobalReference;
  supersedingReferences: SupersedingReference[];
  wasWithdrawn: boolean;
  withdrawnOn: number;
}

@Component({
  selector: 'cars-manage-references',
  styleUrls: ['manage-references.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate(0)),
    ]),
  ],
  templateUrl: 'manage-references.component.html',
})
export class ManageReferencesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public loading: boolean = true;
  public loadingDocumentId: string;
  public searchValue: string = '';
  public searchSubject: Subject<string> = new Subject<string>();
  public dataSource: MatTableDataSource<SearchableGlobalReference>;
  public resultsLength: number = 0;
  public pageSize: number = 10;
  public pageSizeOptions: number[] = [10, 20, 50];
  public displayedColumns: string[] = [
    'withdrawn',
    'reference',
    'title',
    'is_superseded',
    'search_superseding',
    'cars_document_id',
    'created_at',
    'created_by',
    'withdraw_button',
    'edit_button',
    'delete_button',
  ];

  public columnWidths: Record<string, string> = {
    withdrawn: '30px',
    reference: '50px',
    title: '300px',
    is_superseded: '30px',
    search_superseding: '300px',
    cars_document_id: '30px',
    created_at: '80px',
    created_by: '50px',
    withdraw_button: '120px',
    edit_button: '50px',
    delete_button: '50px',
  };

  public referenceLocations: Record<string, ReferenceUsageLocation[]> = {};
  public locationExpanded: Record<string, boolean> = {};
  public loadingLocations: Record<string, boolean> = {};

  public downloadingCsv: boolean = false;

  public tooltipDelay: number = environment.tooltipDelay;

  constructor(
    private _referenceService: ReferenceService,
    private _supersedingReferenceService: SupersedingReferenceService,
    private _searchableGlobalReferenceService: SearchableGlobalReferenceService,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource<SearchableGlobalReference>([]);
    this.sort.sort({disableClear: false, id: 'title', start: 'asc'});
    this.refreshLocations();
    this.refreshTable();

    this.paginator.page.subscribe(() => {
      this.refreshTable();
    });

    this.searchSubject.pipe(debounceTime(200)).subscribe(() => {
      this.paginator.pageIndex = 0;
      this.refreshTable();
    });

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.refreshTable();
    });
  }

  ngOnDestroy(): void {
    this.paginator.page.unsubscribe();
    this.searchSubject.unsubscribe();
  }

  public onSearchQueryChange(query: string) {
    this.paginator.firstPage();
    this.searchSubject.next(query);
  }

  private refreshTable() {
    const pageIndex = this.paginator && this.paginator.pageIndex ? this.paginator.pageIndex : 0;
    const size = this.paginator && this.paginator.pageSize ? this.paginator.pageSize : 10;
    const sortColumn: string = this.sort.active;
    const sortDirection: SortDirection = this.sort.direction;
    this.refreshData(this.searchValue, false, pageIndex, size, !!sortColumn, sortColumn, sortDirection);
    this.refreshLocations();
  }

  private refreshData(
    searchTerm: string,
    deleted: boolean,
    pageIndex: number,
    size: number,
    sort: boolean,
    sortColumn: string = null,
    sortDirection: SortDirection = null
  ) {
    this.loading = true;
    this._searchableGlobalReferenceService
      .fetchSearchableGlobalReferences(searchTerm, deleted, pageIndex, size, sort, sortColumn, sortDirection)
      .subscribe((result: Page<SearchableGlobalReference>) => {
        this.dataSource.data = [...result.resultsList];
        this.resultsLength = result.total;
        this._cdr.markForCheck();
        this.loading = false;
      });
  }

  public isSelected(row: SearchableGlobalReference): boolean {
    return this.locationExpanded[row.globalReferenceId.value];
  }

  /**
   * Deleted the global reference.
   */
  public deleteGlobalReference(ref: SearchableGlobalReference, event: Event): void {
    event.stopPropagation();
    this._dialog
      .open(DialogComponent, {
        ariaLabel: 'Delete global reference dialog',
        data: {
          title: `Deleting Global Reference`,
          message: `Are you sure you want to delete this reference? <strong>This is permanent and can not be undone.</strong>`,
          subtext: `${ref.getShortName()}`,
          closeActions: [
            {title: 'Delete', tooltip: `Delete ${ref.getShortName()}`, response: 'delete'},
            {title: 'Cancel', tooltip: `Cancel deletion of ${ref.getShortName()}`, response: null},
          ],
        },
      })
      .afterClosed()
      .subscribe((action) => {
        if (action) {
          this._referenceService.deleteGlobalReference(ref.globalReferenceId).then(() => {
            this.refreshTable();
          });
        }
      });
  }

  /**
   * Opens the global reference editing dialog and on close updates the global reference.
   * @param ref The global reference to edit.
   */
  public editGlobalReference(ref: SearchableGlobalReference, event: Event): void {
    event.stopPropagation();
    const id: UUID = ref.globalReferenceId;
    const dialogRef = ReferenceDialogComponent.open(this._dialog, ref);
    dialogRef.afterClosed().subscribe((result: ReferenceDialogOutputData) => {
      if (result?.save) {
        this._referenceService
          .updateGlobalReference({
            id: id.value,
            title: result.title,
            author: result.author,
            publisher: result.publisher,
            reference: result.reference,
            carsDocumentId: result.carsDocumentId?.value || null,
          })
          .then(() => {
            this.refreshTable();
          });
      }
    });
  }

  public withdrawGlobalReference(row: SearchableGlobalReference, event: Event): void {
    event.stopPropagation();
    const dialogRef = this._dialog.open(WithdrawalDialogComponent, {
      ariaLabel: 'Edit withdrawal dialog',
      maxHeight: '650px',
      disableClose: true,
      autoFocus: false,
      data: {
        row: row,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.save) {
        this.askToSaveWithdrawalEdits(data);
      }
    });
  }

  /*
   * If the reference was previously unwithdrawn, ask if they are sure, then withdraw the reference.
   * Then pass through to add the superseding references.
   */
  private askToSaveWithdrawalEdits(data: SupersedingModalDataPackage): void {
    if (!data.wasWithdrawn) {
      this._dialog
        .open(DialogComponent, {
          ariaLabel: 'Withdraw global reference dialog',
          data: {
            title: 'Withdrawing Global Reference',
            message:
              '<p>Please review the live usage locations for this reference and contact the Technical Authors before ' +
              'withdrawing the reference.<p> Are you sure you want to withdraw this reference? ' +
              '<strong>This is permanent and can not be undone.</strong>',
            subtext: `${data.reference.getShortName()}`,
            closeActions: [
              {title: 'Withdraw', tooltip: `Withdraw ${data.reference.getShortName()}`, response: 'withdraw'},
              {title: 'Cancel', tooltip: `Cancel withdrawl of ${data.reference.getShortName()}`, response: null},
            ],
          },
        })
        .afterClosed()
        .subscribe((action) => {
          if (action) {
            this.saveWithdrawalEdits(data);
          }
        });
    } else {
      this.saveWithdrawalEdits(data);
    }
  }

  private saveWithdrawalEdits(data: SupersedingModalDataPackage): void {
    this._supersedingReferenceService
      .withdrawAndSupersedeGlobalReference(
        data.reference.globalReferenceId,
        data.withdrawnOn,
        data.supersedingReferences.map((supersedingReference: SupersedingReference) => supersedingReference.serialise())
      )
      .then(() => {
        this.refreshTable();
      });
  }

  /**
   * Closes all the expanded global reference location lists.
   */
  public refreshLocations(): void {
    this.setAllUnexpanded();
  }

  public downloadWithdrawReferenceCsv(): void {
    this.downloadingCsv = true;
    this._referenceService.downloadWithdrawnGlobalReferenceCsv().then(() => (this.downloadingCsv = false));
  }

  /**
   * Toggles if the location list of the given global reference is expanded or not.
   * If the list is being expanded, it calculates the useage locations for the global reference,
   * else it just closes the list.
   *
   * @param globalRef {GlobalReference} The given global reference
   */
  public toggleExpanded(row: SearchableGlobalReference): void {
    if (this.locationExpanded[row.globalReferenceId.value]) {
      this.setAllUnexpanded();
      this.loadingLocations[row.globalReferenceId.value] = false;
    } else {
      this.setAllUnexpanded();
      this.loadingLocations[row.globalReferenceId.value] = true;
      this.loading = true;
      this.locationExpanded[row.globalReferenceId.value] = true;
      this._getLocationsOfGlobalReference(row);
    }
  }

  private setAllUnexpanded() {
    this.dataSource.data.forEach(
      (g: SearchableGlobalReference) => (this.locationExpanded[g.globalReferenceId.value] = false)
    );
  }

  /**
   * Gets all the sections where a inline reference has been used that refers to the given global reference.
   *
   * @param globalRef {GlobalReference} The given global reference
   */
  private _getLocationsOfGlobalReference(globalRef: SearchableGlobalReference): void {
    this._referenceService
      .getUsageLocationsForGlobalReference(globalRef.globalReferenceId)
      .then((locations: ReferenceUsageLocation[]) => {
        this._sortLocations(locations);
        this.referenceLocations[globalRef.globalReferenceId.value] = locations;
        this.loadingLocations[globalRef.globalReferenceId.value] = false;
        this.loading = false;
      });
  }

  /**
   * sorts the given location list by document title and section title
   *
   * @param locations {InlineReferenceLocation[]} the location list to sort
   */
  private _sortLocations(locations: ReferenceUsageLocation[]): void {
    locations.sort((a: ReferenceUsageLocation, b: ReferenceUsageLocation) => {
      if (a.documentTitle.toLowerCase() < b.documentTitle.toLowerCase()) {
        return -1;
      } else if (a.documentTitle.toLowerCase() > b.documentTitle.toLowerCase()) {
        return 1;
      } else {
        if (a.sectionTitle.toLowerCase() < b.sectionTitle.toLowerCase()) {
          return -1;
        } else if (a.sectionTitle.toLowerCase() > b.sectionTitle.toLowerCase()) {
          return 1;
        }
        return 0;
      }
    });
  }

  public getRouterLink(documentId: UUID, sectionId: UUID): string {
    return '/documents/' + documentId.value + '/sections/' + sectionId.value;
  }
}
