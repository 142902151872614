<h1 mat-dialog-title>Insert standard format specifier instruction</h1>
<p class="guidance-text">
  Please note: you cannot switch to a different standard format once it has been inserted into the document. If this is
  required, you will need to delete the content and re-insert the specifier instruction using the appropriate standard
  format.
</p>

<p class="guidance-text bold">
  <ng-container *ngIf="isWithinScheduleSFR; else nonScheduleSFRGuidance">
    The specifier instruction options shown below have been filtered based on the type of clause currently selected. The
    selected clause type relates to schedules and hence only specifier instructions relating to the definition of fields
    within a schedule are available to be inserted.
  </ng-container>

  <ng-template #nonScheduleSFRGuidance>
    The specifier instruction options shown below have been filtered based on the type of clause currently selected. The
    selected clause type does <span class="underline">not</span> relate to schedules and hence specifier instructions
    relating to the definition of fields within a schedule are <span class="underline">not</span> available to be
    inserted.
  </ng-template>
</p>

<p class="guidance-text">
  Please see the help pages for guidance on
  <a href="https://help.futuredmrb.co.uk/using-specifier-instruction/" target="_blank">using specifier instructions</a>.
</p>

<div class="content">
  <cars-specifier-instruction-list-options
    [specifierInstructionFragments]="specifierInstructionFragments"
  ></cars-specifier-instruction-list-options>
</div>

<div mat-dialog-actions>
  <div class="flex-grow"></div>
  <button mat-button color="primary" (click)="cancel()" aria-label="Cancel creating specifier instruction">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!selectedType"
    (click)="confirm()"
    aria-label="Create specifier instruction"
  >
    Insert
  </button>
</div>
