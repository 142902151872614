import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TdifHeadingClauseComponent} from './clause/heading-clause/tdif-heading-clause.component';
import {TdifNormalClauseComponent} from './clause/normal-clause/tdif-normal-clause.component';
import {TdifClauseComponent} from './clause/tdif-clause.component';
import {TdifContentComponent} from './content/tdif-content.component';
import {TdifEquationComponent} from './equation/tdif-equation.component';
import {TdifFigureComponent} from './figure/tdif-figure.component';
import {TdifInlineReferenceComponent} from './inline-reference/tdif-inline-reference.component';
import {TdifInputComponent} from './input/tdif-input.component';
import {TdifUnitInputComponent} from './input/tdif-unit-input.component';
import {TdifListComponent} from './list/tdif-list.component';
import {TdifTableComponent} from './table/tdif-table.component';
import {TdifTextComponent} from './text/tdif-text.component';

const DECLARATIONS: any[] = [
  TdifClauseComponent,
  TdifNormalClauseComponent,
  TdifHeadingClauseComponent,
  TdifEquationComponent,
  TdifFigureComponent,
  TdifTextComponent,
  TdifTableComponent,
  TdifListComponent,
  TdifContentComponent,
  TdifInlineReferenceComponent,
  TdifInputComponent,
  TdifUnitInputComponent,
];

@NgModule({
  imports: [CommonModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class TdifModule {}
