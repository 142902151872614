<div class="pad-container">
  <div class="pad-padding">
    <div class="pad">
      <div class="content" *ngIf="document">
        <div class="document-form">
          <cars-document-overview-header [document]="document"></cars-document-overview-header>
          <cars-document-information [document]="document"></cars-document-information>
          <cars-role-assignments [document]="document"></cars-role-assignments>
        </div>
      </div>
    </div>
  </div>
</div>
