<div class="top-line">
  <span class="toggle">
    <button
      (click)="toggle()"
      mat-button
      matTooltip="Collapse section sidebar"
      matTooltipPosition="after"
      [matTooltipShowDelay]="tooltipDelay"
      id="toggle"
      class="menu-toggle"
      aria-label="Collapse section sidebar"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
  </span>
  <a
    *ngIf="currentView.isHistoricalDocument()"
    [attr.aria-label]="canViewLiveDocument ? 'Return to the live document' : 'Return to the document versions'"
    [routerLink]="canViewLiveDocument ? liveDocumentRoute : documentVersionsRoute"
    mat-button
    color="primary"
  >
    {{ canViewLiveDocument ? 'Back to live document' : 'Back to document versions' }}
  </a>
  <cars-alt-accessibility
    class="live"
    [altProperties]="altProperties['live']"
    (onSelect)="this.selectedTab = 0; focusElement('toggle')"
    (updateAltProperties)="updateAltProperties()"
  ></cars-alt-accessibility>
  <cars-alt-accessibility
    class="deleted"
    [altProperties]="altProperties['deleted']"
    (onSelect)="this.selectedTab = 1; focusElement('toggle')"
    (updateAltProperties)="updateAltProperties()"
  ></cars-alt-accessibility>
</div>
<cars-alt-accessibility
  class="initial-button"
  [altProperties]="altProperties['initial-button']"
  (updateAltProperties)="updateAltProperties()"
></cars-alt-accessibility>
<cars-search-box [document]="document" [currentView]="currentView"></cars-search-box>
<mat-tab-group *ngIf="!isSearching" [(selectedIndex)]="selectedTab" mat-stretch-tabs [@.disabled]="true">
  <mat-tab>
    <ng-template mat-tab-label>
      <div aria-label="Live sections">Contents</div>
    </ng-template>
    <cars-section-list
      [document]="document"
      [currentView]="currentView"
      [type]="type"
      (tabChangeEvent)="changeTab($event)"
    ></cars-section-list>
  </mat-tab>

  <mat-tab *ngIf="!currentView.isChangelogAux() && !currentView.isHistoricalDocument()">
    <ng-template mat-tab-label>
      <div aria-label="Deleted sections">Deleted</div>
    </ng-template>
    <cars-section-list
      [document]="document"
      [currentView]="currentView"
      [type]="type"
      [deletedTab]="true"
    ></cars-section-list>
  </mat-tab>
</mat-tab-group>
<cars-search-document
  [class.hidden]="!isSearching"
  [document]="document"
  [currentView]="currentView"
  (closeSearch)="isSearching = false"
></cars-search-document>
