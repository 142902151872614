import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {NotificationAreaComponent} from './notification-area.component';
import {NotificationModule} from './notification/notification.module';

@NgModule({
  imports: [CommonModule, MatButtonModule, NotificationModule],
  declarations: [NotificationAreaComponent],
  exports: [NotificationAreaComponent],
})
export class NotificationAreaModule {}
