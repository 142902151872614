import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TreeDiffer} from 'app/fragment/tree-differ';
import {ClauseFragment, ClauseType, Fragment, SectionFragment, TextFragment} from 'app/fragment/types';
import {UUID} from 'app/utils/uuid';
import {TreeArray} from '../../fragment/tree/tree-array';
import {ChangeSummaryChangeType} from '../types/change-summary-change-type';
import {ChangeSummaryRow} from '../types/change-summary-row';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[cars-section-change-summary-row]',
  templateUrl: './section-change-summary-row.component.html',
  styleUrls: ['./section-change-summary-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionChangeSummaryRowComponent implements OnInit {
  @Input() public row: ChangeSummaryRow;
  @Input() public showBackgroundCommentary: boolean;

  public readonly ChangeType: typeof ChangeSummaryChangeType = ChangeSummaryChangeType;

  public diffedSectionTitle: ClauseFragment;

  public wsrMap: Map<string, ClauseFragment[]> = new Map();
  public readonly OLD_IDENTIFIER: string = 'oldWsr';
  public readonly NEW_IDENTIFIER: string = 'newWsr';

  private sectionId: UUID;
  private textId: UUID;
  private clauseId: UUID;
  private subjectId: UUID[];
  private topicId: UUID[];
  private wsrCodeId: UUID[];

  /**
   * Generates the list of diffed fragments to display.
   */
  public ngOnInit(): void {
    if (this.row.changeType !== ChangeSummaryChangeType.UNCHANGED) {
      this.createIds();
      let oldMockChildren: ClauseFragment[];
      let newMockChildren: ClauseFragment[];

      if (this.row.changeType !== ChangeSummaryChangeType.CREATED) {
        oldMockChildren = this.createMockTitleClauseFragment(this.row.oldFragment, this.row.oldFragment.weight);
      } else {
        oldMockChildren = this.createMockTitleClauseFragment(null, this.row.newFragment.weight);
      }

      const oldWsrMockFragments: ClauseFragment[] = this.makeMockWsrFragments(this.row.oldFragment as SectionFragment);
      oldMockChildren = oldMockChildren.concat(this.makeMockWsrFragments(this.row.oldFragment as SectionFragment));

      if (this.row.changeType !== ChangeSummaryChangeType.DELETED) {
        const newWsrMockFragments: ClauseFragment[] = this.makeMockWsrFragments(
          this.row.newFragment as SectionFragment
        );
        newMockChildren = this.createMockTitleClauseFragment(this.row.newFragment, this.row.newFragment.weight).concat(
          newWsrMockFragments
        );
      }

      const oldMockSection: SectionFragment = this.createMockSection(oldMockChildren);
      const newMockSection: SectionFragment = this.createMockSection(newMockChildren);

      const diffedClauses: TreeArray<Fragment> = new TreeDiffer().diffWithoutReferences(
        oldMockSection,
        newMockSection
      ).children;
      this.diffedSectionTitle = diffedClauses.shift() as ClauseFragment;

      this.wsrMap.set(this.OLD_IDENTIFIER, oldWsrMockFragments);
      if (diffedClauses.some((frag: Fragment) => frag.children.length > 1)) {
        this.wsrMap.set(this.NEW_IDENTIFIER, diffedClauses as TreeArray<ClauseFragment>);
      }
    }
  }

  private createIds() {
    this.textId = UUID.random();
    this.clauseId = UUID.random();
    this.sectionId = UUID.random();
    this.subjectId = [UUID.random(), UUID.random()];
    this.topicId = [UUID.random(), UUID.random()];
    this.wsrCodeId = [UUID.random(), UUID.random()];
  }

  /**
   * Creates mock clause fragments to contain the WSR mapping values to apply to the differ
   */
  private makeMockWsrFragments(section: SectionFragment): ClauseFragment[] {
    return [
      this.createMockWsrClauseFragment(
        'WSR Subject: ' + (section != null && section.subject !== null ? section.subject : ''),
        this.subjectId
      ),
      this.createMockWsrClauseFragment(
        'WSR Topic: ' + (section != null && section.topic !== null ? section.topic : ''),
        this.topicId
      ),
      this.createMockWsrClauseFragment(
        'WSR Code: ' + (section != null && section.wsrCode !== null ? section.wsrCode : ''),
        this.wsrCodeId
      ),
    ];
  }

  private createMockWsrClauseFragment(value: string, ids: UUID[]): ClauseFragment {
    return new ClauseFragment(ids[0], ClauseType.NORMAL, [new TextFragment(ids[1], value)], '');
  }

  private createMockTitleClauseFragment(fragment: Fragment, weight: number): ClauseFragment[] {
    let title: TextFragment[] = [];
    if (!!fragment) {
      title = [new TextFragment(this.textId, fragment.value)];
    }
    const mockClause: ClauseFragment = new ClauseFragment(this.clauseId, ClauseType.HEADING_1, title, null);
    mockClause.weight = weight;
    mockClause.sectionId = this.sectionId;

    return [mockClause];
  }

  private createMockSection(children: ClauseFragment[]) {
    return new SectionFragment(this.sectionId, '', null, children, false, 'subject', 'topic', 'wsr code');
  }
}
