import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {SectionService} from 'app/services/section.service';
import {ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class WSRSubjectTopicResolverService implements Resolve<void> {
  constructor(private _viewService: ViewService, private _sectionService: SectionService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    const currentView = this._viewService.getCurrentView();
    this._viewService.setLocation(CurrentLocation.WSR_SUBJECT_TOPIC);
    this._viewService.setCurrentView(ViewMode.WSR_SUBJECT_TOPIC, currentView?.versionTag);
    this._sectionService.setSelected(null);
  }
}
