import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {ClauseTypeSelectorModule} from '../clause-type-selector/clause-type-selector.module';
import {RichTextComponent} from './rich-text.component';

@NgModule({
  imports: [
    CommonModule,
    AltAccessibilityModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    CarsErrorHandlingModule,
    ClauseTypeSelectorModule,
  ],
  declarations: [RichTextComponent],
  exports: [RichTextComponent],
})
export class RichTextModule {}
