import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {ChangelogService} from 'app/changelog/changelog.service';
import {DocumentFragment} from 'app/fragment/types';
import {BaseService} from 'app/services/base.service';
import {DocumentService} from 'app/services/document.service';
import {SectionService} from 'app/services/section.service';
import {UUID} from 'app/utils/uuid';
import {ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class LinkTableResolverService extends BaseService implements Resolve<DocumentFragment> {
  constructor(
    private _changelogService: ChangelogService,
    private _documentService: DocumentService,
    private _sectionService: SectionService,
    private _viewService: ViewService,
    private _router: Router,
    protected _snackbar: MatSnackBar
  ) {
    super(_snackbar);
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<DocumentFragment> {
    const documentId: string = route.paramMap.get('document_id');

    return this._changelogService
      .fetchPublishedDocument(UUID.orNull(documentId))
      .then((document: DocumentFragment) => {
        if (!document.isLegacySuite() || document.deleted) {
          document = null;
        }
        this._sectionService.clearSectionUndoBuffer();
        this._documentService.setSelected(document);
        this._changelogService.setPublishedDocument(document);
        this._sectionService.setSelected(null);
        this._viewService.setCurrentView(ViewMode.LINK_TABLE);
        this._viewService.setLocation(CurrentLocation.LINK_TABLE);
        return document;
      })
      .catch((err: any) => {
        const message: string = `Failed to open changelog table for published document with id: ${documentId}`;
        err = !!err ? err : {message};
        this._handleError(err, message, 'navigation-error');
        this._router.navigate(['/']);
        return null;
      });
  }
}
