import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {Suite} from 'app/fragment/suite';
import {ClauseFragment, DocumentInformationFragment, FigureFragment, Fragment, FragmentType} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-alt-text',
  templateUrl: './alt-text.component.html',
  styleUrls: ['./alt-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AltTextComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public clause: ClauseFragment;

  @ViewChild('illustrativeFigure') illustrativeFigure: MatCheckbox;

  public readonly CarsAction: typeof CarsAction = CarsAction;

  public imageFragments: FigureFragment[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private fragmentService: FragmentService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.fragmentService.onCreate(
        () => this.updateImageFragmentList(),
        (frag) => frag.is(FragmentType.FIGURE) && frag.findAncestorWithType(FragmentType.CLAUSE).equals(this.clause)
      ),
      this.fragmentService.onDelete(
        () => this.updateImageFragmentList(),
        (fragment: Fragment) =>
          fragment.is(FragmentType.FIGURE, FragmentType.TABLE) && fragment.parentId.equals(this.clause.id)
      )
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('clause')) {
      if (
        !changes.clause.previousValue ||
        !changes.clause.currentValue ||
        !changes.clause.previousValue.id.equals(changes.clause.currentValue.id)
      ) {
        this.updateImageFragmentList();
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Updates the list of image fragments to be rendered within the component.
   */
  private updateImageFragmentList(): void {
    this.imageFragments = [];
    this.clause?.iterateDown(null, null, (fragment: Fragment) => {
      if (fragment.is(FragmentType.FIGURE)) {
        this.imageFragments.push(fragment as FigureFragment);
      }
    });

    this.cdr.markForCheck();
  }

  public showIllustrativeFigureCheckbox(image: FigureFragment): boolean {
    const docInfoFrag = image.findAncestorWithType(FragmentType.DOCUMENT_INFORMATION) as DocumentInformationFragment;
    return this.documentService.getSelected().suite !== Suite.UNRESTRICTED || !docInfoFrag;
  }

  public setIllustrativeFigureProperties(image: FigureFragment, checked: boolean): void {
    if (checked) {
      if (!!image.altText && image.altText.length > 0) {
        this.dialog
          .open(DialogComponent, {
            ariaLabel: `Setting image as illustrative figure only will remove current alt-text data.`,
            data: {
              title: `Setting image as illustrative figure only will remove current alt-text data.`,
              message: 'Are you sure you want to continue? This can not be undone.',
              closeActions: [
                {
                  title: 'Continue',
                  tooltip: `Set image as a illustrative figure`,
                  response: true,
                  color: 'warn',
                },
                {
                  title: 'Cancel',
                  tooltip: `Cancel setting image as a illustrative figure`,
                  response: false,
                },
              ],
            },
          })
          .afterClosed()
          .subscribe((action: boolean) => {
            if (action === true) {
              image.altText = 'This figure is illustrative only.';
              image.isIllustrativeFigure = true;
              this.fragmentService.update(image);
            } else {
              this.illustrativeFigure.checked = false;
            }
          });
      } else {
        image.isIllustrativeFigure = true;
        image.altText = 'This figure is illustrative only.';
        this.fragmentService.update(image);
      }
    } else {
      image.isIllustrativeFigure = false;
      image.altText = '';
      this.fragmentService.update(image);
    }
  }
}
