import {Injectable} from '@angular/core';
import {Caret} from 'app/fragment/caret';
import {CarsRange} from 'app/fragment/cars-range';
import {Fragment} from 'app/fragment/types';
import {Callback, Predicate} from 'app/utils/typedefs';
import {BehaviorSubject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {FragmentService} from './fragment.service';

@Injectable({
  providedIn: 'root',
})
export class CaretService {
  private currentSelection: BehaviorSubject<CarsRange> = new BehaviorSubject(null);

  constructor(private _fragmentService: FragmentService) {}

  public getCaretPositionFromSelectedFragment(): Caret {
    const selectedFragment: Fragment = this._fragmentService.getSelected();
    const selection: CarsRange = this.getSelection();

    if (!selectedFragment) {
      return new Caret(null, 0);
    }

    const caretsToSearch: Caret[] = [selection.start, selection.end];
    const caretToSelect: Caret = caretsToSearch
      .filter((caret: Caret) => !!caret)
      .find((caret: Caret) => selectedFragment.equals(caret.fragment));
    return caretToSelect ?? new Caret(selectedFragment, 0);
  }

  public getSelection(): CarsRange {
    return this.currentSelection.getValue();
  }

  public setSelection(selection: CarsRange): void {
    this.currentSelection.next(selection);
  }

  public onSelectionChange(callback: Callback<CarsRange>, predicate?: Predicate<CarsRange>): Subscription {
    predicate = !predicate ? (r) => true : predicate;
    return this.currentSelection.pipe(distinctUntilChanged(CarsRange.equals), filter(predicate)).subscribe(callback);
  }
}
