import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {ClauseChangeSummaryRowModule} from './clause-change-summary-row/clause-change-summary-row.module';
import {ClauseChangeSummaryComponent} from './clause-change-summary.component';
import {SectionChangeSummaryRowModule} from './section-change-summary-row/section-change-summary-row.module';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    SectionChangeSummaryRowModule,
    ClauseChangeSummaryRowModule,
    MatSlideToggleModule,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
  ],
  declarations: [ClauseChangeSummaryComponent],
  exports: [ClauseChangeSummaryComponent],
})
export class ClauseChangeSummaryModule {}
