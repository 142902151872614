import {Component} from '@angular/core';
import {Dashboard, DashboardService} from 'app/services/dashboard.service';
import {DashboardComponent} from '../dashboard.component';

@Component({
  selector: 'cars-my-documents',
  templateUrl: '../dashboard.component.html',
  styleUrls: ['../dashboard.component.scss'],
})
export class MyDocumentsComponent extends DashboardComponent {
  public dashboardType: Dashboard = Dashboard.MY_DOCUMENTS;
  public title: string = 'My Documents';
  public pageInfo: string =
    'This dashboard provides an overview of all the discussions that have been left on your documents.  ' +
    'It includes documents where you are the Technical Author or Lead Author.  The summary table includes whether the item ' +
    'has been resolved, what the latest comment was, and the count of all comments.  It can be used to help answer ' +
    'questions like <i>“Which discussions are open, which have been responded to, and which are resolved?”</i> and <i>“What is an ' +
    'overview of the discussions that each reviewer has raised?”</i>. To learn more about this dashboard, ' +
    'please watch the YouTube tutorial accessed via the FAQ page on the Help Pages.';

  constructor(protected _dashboardService: DashboardService) {
    super(_dashboardService);
  }
}
