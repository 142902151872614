import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private _printSubject: Subject<void> = new Subject<void>();

  constructor() {}

  public triggerPrintEvent(): void {
    this._printSubject.next();
  }

  public onPrintEvent(): Observable<void> {
    return this._printSubject;
  }
}
