import {Administration} from './administrations';

export class AdministrationUtils {
  private static readonly ADMINISTRATION_TO_DISPLAY_NAME: Readonly<Record<Administration, string>> = {
    ENGLAND: 'England',
    NORTHERN_IRELAND: 'Northern Ireland',
    SCOTLAND: 'Scotland',
    WALES: 'Wales',
  };

  private static readonly ADMINISTRATION_TO_INITALS: Readonly<Record<Administration, string>> = {
    ENGLAND: 'E',
    NORTHERN_IRELAND: 'NI',
    SCOTLAND: 'S',
    WALES: 'W',
  };

  /**
   * Returns an array of all the available administrations. The list has to be ordered alphabetically to prevent political upset.
   */
  public static getAllAdministrations(): Administration[] {
    return Object.values(Administration).sort();
  }

  public static getDisplayName(administration: Administration): string {
    return AdministrationUtils.ADMINISTRATION_TO_DISPLAY_NAME[administration] ?? '';
  }

  public static getInitials(administration: Administration): string {
    return AdministrationUtils.ADMINISTRATION_TO_INITALS[administration] ?? '';
  }

  // Private constructor to prevent instantiations
  private constructor() {}
}
