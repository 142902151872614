import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {RouterModule} from '@angular/router';
import {DocumentsTableModule} from './documents-table/documents-table.module';
import {DocumentsComponent} from './documents.component';
import {PrivacyFooterModule} from './privacy-footer/privacy-footer.module';

@NgModule({
  imports: [CommonModule, MatButtonModule, RouterModule, MatTabsModule, DocumentsTableModule, PrivacyFooterModule],
  declarations: [DocumentsComponent],
  exports: [DocumentsComponent],
})
export class DocumentsModule {}
