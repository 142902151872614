<div *ngIf="!readOnly && canLock" class="icons" contenteditable="false">
  <!-- Open the equation help dialog -->
  <ng-container *ngIf="helpUrl">
    <a
      #equationHelp
      mat-icon-button
      [matTooltip]="'Open ' + fragmentType + ' help'"
      matTooltipPosition="above"
      [matTooltipShowDelay]="tooltipDelay"
      [href]="helpUrl"
      target="_blank"
    >
      <cars-alt-accessibility
        [altProperties]="{initialKey: 'z', key: 'o', condition: altPropertiesCondition}"
        (onSelect)="openEquationHelpLink()"
      ></cars-alt-accessibility>
      <mat-icon>help_outline</mat-icon>
    </a>
  </ng-container>

  <!-- Replace -->
  <ng-container *ngIf="showReplace">
    <input hidden type="file" [id]="fragment.id.value" (change)="replace($event)" accept="image/*" #replaceImage />
    <label [for]="fragment.id.value" [class.inline]="figureInline" (mousedown)="$event.preventDefault()">
      <a
        mat-icon-button
        [matTooltip]="'Replace ' + ((fragment | fragmentIndex) || fragmentType)"
        [matTooltipShowDelay]="tooltipDelay"
        matTooltipPosition="above"
      >
        <cars-alt-accessibility
          [altProperties]="{initialKey: 'z', key: 'r', condition: altPropertiesCondition}"
          (onSelect)="replaceImage.click()"
        ></cars-alt-accessibility>
        <mat-icon>find_replace</mat-icon>
      </a>
    </label>
  </ng-container>

  <!-- Add Variable Table -->
  <ng-container *ngIf="showVariableTableToggle">
    <button
      mat-icon-button
      [matTooltip]="
        (hasVariableTable ? 'Delete' : 'Create') +
        ' variables and definitions table for ' +
        ((fragment | fragmentIndex) || fragmentType)
      "
      matTooltipPosition="above"
      [matTooltipShowDelay]="tooltipDelay"
      (click)="toggleVariableTable()"
      [carsAnalytics]="(hasVariableTable ? 'delete' : 'create') + '-variable-table'"
    >
      <cars-alt-accessibility
        [altProperties]="{initialKey: 'z', key: 'v', condition: altPropertiesCondition}"
        (onSelect)="toggleVariableTable()"
      ></cars-alt-accessibility>
      <mat-icon *ngIf="!hasVariableTable" svgIcon="create_variable_table"></mat-icon>
      <mat-icon *ngIf="hasVariableTable" svgIcon="delete_variable_table"></mat-icon>
    </button>
  </ng-container>

  <!-- Toggle Header Row -->
  <ng-container *ngIf="showHeaderRowToggle">
    <button
      mat-icon-button
      [matTooltip]="
        (hasHeaderRow ? 'Delete' : 'Create') +
        ' repeating header row for ' +
        ((fragment | fragmentIndex) || fragmentType)
      "
      matTooltipPosition="above"
      [matTooltipShowDelay]="tooltipDelay"
      (click)="toggleHeaderRow()"
      [attr.aria-label]="(hasHeaderRow ? 'Delete' : 'Create') + ' header row.'"
      [carsAnalytics]="(hasHeaderRow ? 'delete' : 'create') + '-header-row.'"
    >
      <cars-alt-accessibility
        [altProperties]="{initialKey: 'z', key: 'h', condition: altPropertiesCondition}"
        (onSelect)="toggleHeaderRow()"
      ></cars-alt-accessibility>
      <mat-icon>{{ hasHeaderRow ? 'border_clear' : 'border_horizontal' }}</mat-icon>
    </button>
  </ng-container>

  <!-- Landscape -->
  <ng-container *ngIf="showLandscape">
    <button
      mat-icon-button
      [matTooltip]="'Toggle orientation for ' + ((fragment | fragmentIndex) || fragmentType)"
      matTooltipPosition="above"
      [matTooltipShowDelay]="tooltipDelay"
      (click)="landscape()"
      [carsAnalytics]="'toggle-' + fragmentType + '-landscape'"
    >
      <cars-alt-accessibility
        [altProperties]="{initialKey: 'z', key: 't', condition: altPropertiesCondition}"
        (onSelect)="landscape()"
      ></cars-alt-accessibility>
      <mat-icon *ngIf="!fragment.isLandscape()">crop_landscape</mat-icon>
      <mat-icon *ngIf="fragment.isLandscape()">crop_portrait</mat-icon>
    </button>
  </ng-container>

  <!-- Manually toggle column widths -->
  <ng-container *ngIf="showManualColumnWidthsOption">
    <button
      mat-icon-button
      [matTooltip]="
        (fragment.isTableAndHasManualColumnWidths()
          ? 'Use automatic column widths'
          : 'Use manually managed column widths') +
        ' for ' +
        ((fragment | fragmentIndex) || fragmentType)
      "
      matTooltipPosition="above"
      [matTooltipShowDelay]="tooltipDelay"
      (click)="toggleManualWidths()"
    >
      <cars-alt-accessibility
        [altProperties]="{initialKey: 'z', key: 'w', condition: altPropertiesCondition}"
        (onSelect)="toggleManualWidths()"
      ></cars-alt-accessibility>
      <mat-icon mat-primary [ngClass]="fragment.isTableAndHasManualColumnWidths() ? 'manual-widths' : 'auto-widths'"
        >view_column</mat-icon
      >
    </button>
  </ng-container>

  <!-- Copy the fragment to the clipboard -->
  <ng-container *ngIf="true">
    <button
      mat-icon-button
      [matTooltip]="'Copy ' + ((fragment | fragmentIndex) || fragmentType)"
      matTooltipPosition="above"
      [matTooltipShowDelay]="tooltipDelay"
      (click)="copy()"
      [carsAnalytics]="fragmentType + 'copy'"
    >
      <cars-alt-accessibility
        [altProperties]="{initialKey: 'z', key: 'c', condition: altPropertiesCondition}"
        (onSelect)="copy()"
      ></cars-alt-accessibility>
      <mat-icon>content_copy</mat-icon>
    </button>
  </ng-container>

  <!-- Move up -->
  <ng-container *ngIf="!figureInline">
    <button
      [disabled]="!showMove(-1)"
      mat-icon-button
      [matTooltip]="'Move ' + ((fragment | fragmentIndex) || fragmentType) + ' up'"
      matTooltipPosition="above"
      [matTooltipShowDelay]="tooltipDelay"
      (click)="move(-1)"
      [attr.aria-label]="
        showMove(-1)
          ? 'Move ' + ((fragment | fragmentIndex) || fragmentType) + ' up'
          : 'Unable to move ' + ((fragment | fragmentIndex) || fragmentType) + ' up'
      "
      [carsAnalytics]="'move-' + fragmentType"
    >
      <cars-alt-accessibility
        *ngIf="showMove(-1)"
        [altProperties]="{initialKey: 'z', key: 'u', condition: altPropertiesCondition}"
        (onSelect)="move(-1)"
      ></cars-alt-accessibility>

      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
  </ng-container>

  <!-- Move down -->
  <ng-container *ngIf="!figureInline">
    <button
      [disabled]="!showMove(1)"
      mat-icon-button
      [matTooltip]="'Move ' + ((fragment | fragmentIndex) || fragmentType) + ' down'"
      matTooltipPosition="above"
      [matTooltipShowDelay]="tooltipDelay"
      (click)="move(1)"
      [attr.aria-label]="
        showMove(1)
          ? 'Move ' + ((fragment | fragmentIndex) || fragmentType) + ' down'
          : 'Unable to move ' + ((fragment | fragmentIndex) || fragmentType) + ' down'
      "
      [carsAnalytics]="'move-' + fragmentType"
    >
      <cars-alt-accessibility
        *ngIf="showMove(1)"
        [altProperties]="{initialKey: 'z', key: 'l', condition: altPropertiesCondition}"
        (onSelect)="move(1)"
      ></cars-alt-accessibility>

      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </ng-container>

  <!-- Delete -->
  <ng-container *ngIf="showDelete">
    <button
      mat-icon-button
      [matTooltip]="'Delete ' + ((fragment | fragmentIndex) || fragmentType)"
      matTooltipPosition="above"
      [matTooltipShowDelay]="tooltipDelay"
      [class.inline]="figureInline"
      (click)="delete()"
      [carsAnalytics]="'delete-' + fragmentType"
    >
      <cars-alt-accessibility
        [altProperties]="{initialKey: 'z', key: 'd', condition: altPropertiesCondition}"
        (onSelect)="delete()"
      ></cars-alt-accessibility>
      <mat-icon>delete</mat-icon>
    </button>

    <cars-alt-accessibility
      class="initial"
      [altProperties]="{initialKey: 'z', key: null, condition: altPropertiesCondition}"
    ></cars-alt-accessibility>
  </ng-container>
</div>
