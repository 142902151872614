import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EditorToolbarModule} from 'app/editor-toolbar/editor-toolbar.module';
import {FragmentModule} from 'app/fragment/fragment.module';
import {FragmentIndexModule} from 'app/fragment/indexing/fragment-index.module';
import {DiscussionsIconModule} from './../../../fragment/clause/gutter-icons/discussions-icon/discussions-icon.module';
import {CanvasModule} from './canvas/canvas.module';
import {SectionPadComponent} from './section-pad.component';

@NgModule({
  imports: [
    CommonModule,
    CanvasModule,
    EditorToolbarModule,
    FragmentModule,
    FragmentIndexModule,
    DiscussionsIconModule,
  ],
  declarations: [SectionPadComponent],
  exports: [SectionPadComponent],
})
export class SectionPadModule {}
