import {
  ClauseReferenceTargetType,
  ClauseType,
  Fragment,
  FragmentType,
  InternalReferenceType,
  ReferenceType,
} from 'app/fragment/types';
import {UUID} from 'app/utils/uuid';
import {TargetDocumentType} from './target-document-type';

export class InternalDocumentReferenceFragment extends Fragment {
  public referenceType: ReferenceType;
  public targetDocumentType: TargetDocumentType;
  public internalReferenceType: InternalReferenceType;
  public clauseReferenceTargetType: ClauseReferenceTargetType;

  public targetDocumentId: UUID;
  public targetSectionId: UUID;
  public targetVersionId: UUID;
  public targetFragmentId: UUID;

  public documentCode: string;
  public documentTitle: string;

  public sectionTitle: string;
  public sectionIndex: string;
  public targetFragmentDeleted: boolean;
  public targetFragmentIndex: string;
  public targetFragmentValue: string;
  public targetClauseType: ClauseType;
  public targetFragmentOrderingWeight: number;

  public subject: string;
  public topic: string;
  public wsrCode: string;

  public static empty(
    referenceType: ReferenceType,
    targetDocumentType: TargetDocumentType,
    internalReferenceType: InternalReferenceType,
    clauseReferenceTargetType: ClauseReferenceTargetType
  ): InternalDocumentReferenceFragment {
    return new InternalDocumentReferenceFragment(
      null,
      referenceType,
      targetDocumentType,
      internalReferenceType,
      clauseReferenceTargetType,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  constructor(
    id: UUID,
    referenceType: ReferenceType,
    targetDocumentType: TargetDocumentType,
    internalReferenceType: InternalReferenceType,
    clauseReferenceTargetType: ClauseReferenceTargetType,
    targetDocumentId: UUID,
    targetSectionId: UUID,
    targetVersionId: UUID,
    targetFragmentId: UUID,
    documentCode: string,
    documentTitle: string,
    sectionTitle: string,
    sectionIndex: string,
    targetFragmentDeleted: boolean,
    targetFragmentIndex: string,
    targetFragmentValue: string,
    targetClauseType: ClauseType,
    subject: string,
    topic: string,
    wsrCode: string,
    targetFragmentOrderingWeight: number
  ) {
    super(id, FragmentType.INTERNAL_DOCUMENT_REFERENCE, []);
    this.referenceType = referenceType;
    this.targetDocumentType = targetDocumentType;
    this.internalReferenceType = internalReferenceType;
    this.clauseReferenceTargetType = clauseReferenceTargetType;
    this.targetDocumentId = targetDocumentId;
    this.targetSectionId = targetSectionId;
    this.targetVersionId = targetVersionId;
    this.targetFragmentId = targetFragmentId;
    this.documentCode = documentCode;
    this.documentTitle = documentTitle;
    this.sectionTitle = sectionTitle;
    this.sectionIndex = sectionIndex;
    this.targetFragmentDeleted = targetFragmentDeleted;
    this.targetFragmentIndex = targetFragmentIndex;
    this.targetFragmentValue = targetFragmentValue;
    this.targetClauseType = targetClauseType;
    this.subject = subject;
    this.topic = topic;
    this.wsrCode = wsrCode;
    this.targetFragmentOrderingWeight = targetFragmentOrderingWeight;
    this.weight = 0;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): InternalDocumentReferenceFragment {
    return null;
  }

  /**
   * Gets the formatted title for display in the Reference section.
   */
  public getFormattedTitle(): string {
    return `National Highways. ${this.documentCode ? `${this.documentCode}, ` : ``}'${this.documentTitle}'`;
  }
}
