<div [class.landscape-pad]="content.landscape">
  <div *ngIf="content.landscape" class="hide-borders" contenteditable="false"></div>
  <div #previewBox class="preview-box"></div>
  <table border-spacing="0" [class.deleted]="section?.deleted && content.landscape" id="{{ id }}">
    <caption #captionBox *ngIf="!variableTable" (mousedown)="blur()">
      <div>
        <div>
          <label
            *ngIf="section && !section.deleted && showIndex()"
            contenteditable="false"
            (click)="selectCaption(true)"
            >{{ content | fragmentIndex }}</label
          >
          <label *ngIf="showIndex() && section?.deleted" contenteditable="false" (click)="selectCaption(true)"
            >Table
          </label>
          <cars-text-fragment
            *ngIf="!content?.diffedCaption?.length; else diffedCaption"
            [content]="content?.caption"
            [caption]="true"
          ></cars-text-fragment>
          <ng-template #diffedCaption>
            <cars-text-fragment
              *ngFor="let fragment of content?.diffedCaption"
              [content]="fragment"
              [caption]="true"
            ></cars-text-fragment>
          </ng-template>
        </div>
        <cars-captioned-hovertip
          class="hovertip"
          [fragmentType]="'table'"
          [readOnly]="readOnly"
          [fragment]="content"
          [carsShowOnFocus]="content"
        >
        </cars-captioned-hovertip>
      </div>
    </caption>
    <thead>
      <tr
        class="cars-manual-column-width-row"
        *ngIf="(CarsAction.AUTHOR_DOCUMENT | carsEnabled : content.documentId | async) && showManualColumnWidthValues()"
        (click)="onOpenManualColumnWidthManagmentModalMouseup($event)"
        (mousedown)="$event.stopPropagation()"
        aria-label="Click to manage column % widths"
      >
        <th
          class="cars-manual-column-width-cell"
          *ngFor="let width of content.columnWidths; let i = index"
          contenteditable="true"
        >
          <span>{{ content.columnWidths.length > 0 ? formatColumnWidthToPercentage(i) : 0 }}%</span>
        </th>
      </tr>
    </thead>
    <tbody #tableBody>
      <tr *ngFor="let row of content.children; let i = index" [carsTableRowFragment]="row">
        <ng-container *ngFor="let cell of row.children; let j = index">
          <td
            *ngIf="!cell.deleted"
            [carsTableCellFragment]="cell"
            [rowSpan]="cell.rowSpan"
            [colSpan]="cell.colSpan"
            [height]="cell.height"
            (mousedown)="onCellMousedown(i, j, $event)"
            (mouseenter)="onCellEnter(i, j)"
            (click)="onCellMouseup()"
            (cellBlur)="onCellBlur()"
            [class.hover-button]="!menu.mergeMode && !dragSelecting"
            [class.selected]="menu.selection[i] && menu.selection[i][j]"
            [class.table-bold]="cell.bold"
            [class.normal-borders]="!variableTable"
            [class.dashed-borders]="showBorders"
            [class.header-border]="cell.headerRowBorder"
            [class.large-min-width]="cell.containsList"
            [class.small-min-width]="!cell.containsList"
            [style.visibility]="cell.isDummy ? 'hidden' : 'visible'"
            [style.textAlign]="TextAlignment[cell.alignment]?.toLowerCase().split('_')[1]"
            [style.verticalAlign]="TextAlignment[cell.alignment]?.toLowerCase().split('_')[0]"
            [style.width]="generateColumnWidth(i, j)"
          >
            <ng-template [carsFragmentHost]="cell.children" [readOnly]="readOnly"> </ng-template>
            <button
              *ngIf="!readOnly && !reordering"
              mat-icon-button
              [matMenuTriggerFor]="menuRef"
              class="menu-button"
              contenteditable="false"
              (click)="onMenuClick(i, j, $event)"
              (mousedown)="$event.stopPropagation()"
              (mouseup)="onMenuClick(i, j, $event)"
              [class.firefox]="firefox"
              [class.show-top-right]="isTopRightCell(i, j)"
              aria-label="Table menu"
            >
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </td>
        </ng-container>
      </tr>
      <tr class="column-width-calculator">
        <td contenteditable="false" *ngFor="let cell of columnWidthCalculator()" class="hidden"></td>
      </tr>
    </tbody>
  </table>

  <div
    *ngIf="userLockingClause && content.landscape"
    class="overlay locked"
    [style.background-color]="
      (CarsAction.IS_LIVE_VIEW_MODE | carsEnabled : content.documentId | async)
        ? getUserColour(userLockingClause, 0.05)
        : 'inherit'
    "
  ></div>
  <div *ngIf="section?.deleted && content.landscape" class="overlay deleted"></div>
  <div *ngIf="section?.deleted" class="overlay no-select"></div>

  <div
    *ngIf="showMarginIcons && padType === PadType.MAIN_EDITABLE"
    class="margin-icons-container"
    contenteditable="false"
  >
    <cars-discussions-icon [fragment]="content"></cars-discussions-icon>
  </div>
</div>

<mat-menu #menuRef="matMenu" contenteditable="false">
  <ng-template matMenuContent>
    <ng-container *ngIf="!menu.mergeMode">
      <button
        mat-menu-item
        (click)="insertRow($event, true)"
        (mouseenter)="stylePreviewBox(PreviewType.ROW_ABOVE)"
        (mouseleave)="stylePreviewBox(PreviewType.NONE)"
      >
        <mat-icon svgIcon="insert_above"></mat-icon>Insert row above
      </button>
      <button
        mat-menu-item
        (click)="insertRow($event)"
        (mouseenter)="stylePreviewBox(PreviewType.ROW_BELOW)"
        (mouseleave)="stylePreviewBox(PreviewType.NONE)"
      >
        <mat-icon svgIcon="insert_below"></mat-icon>Insert row below
      </button>
      <button
        mat-menu-item
        (click)="insertColumn($event, true)"
        (mouseenter)="stylePreviewBox(PreviewType.COL_BEFORE)"
        (mouseleave)="stylePreviewBox(PreviewType.NONE)"
      >
        <mat-icon svgIcon="insert_left"></mat-icon>Insert column before
      </button>
      <button
        mat-menu-item
        (click)="insertColumn($event)"
        (mouseenter)="stylePreviewBox(PreviewType.COL_AFTER)"
        (mouseleave)="stylePreviewBox(PreviewType.NONE)"
      >
        <mat-icon svgIcon="insert_right"></mat-icon>Insert column after
      </button>
      <button
        mat-menu-item
        *ngIf="content.numRows() > 1"
        (click)="deleteRow($event)"
        (mouseenter)="stylePreviewBox(PreviewType.DELETE_ROW)"
        (mouseleave)="stylePreviewBox(PreviewType.NONE)"
      >
        <mat-icon svgIcon="delete_row"></mat-icon>Delete row
      </button>
      <button
        mat-menu-item
        *ngIf="content.numColumns() > 1"
        (click)="deleteColumn($event)"
        (mouseenter)="stylePreviewBox(PreviewType.DELETE_COL)"
        (mouseleave)="stylePreviewBox(PreviewType.NONE)"
      >
        <mat-icon svgIcon="delete_column"></mat-icon>Delete column
      </button>
      <button
        mat-menu-item
        *ngIf="(content.children[menu.row]?.children)[menu.col]?.colSpan > 1"
        (click)="splitCell($event, SplitDirection.VERTICALLY)"
        (mouseenter)="stylePreviewBox(PreviewType.SPLIT_VERTICALLY)"
        (mouseleave)="stylePreviewBox(PreviewType.NONE)"
      >
        <mat-icon svgIcon="split_vertically"></mat-icon>Split vertically
      </button>
      <button
        mat-menu-item
        *ngIf="(content.children[menu.row]?.children)[menu.col]?.rowSpan > 1"
        (click)="splitCell($event, SplitDirection.HORIZONTALLY)"
        (mouseenter)="stylePreviewBox(PreviewType.SPLIT_HORIZONTALLY)"
        (mouseleave)="stylePreviewBox(PreviewType.NONE)"
      >
        <mat-icon svgIcon="split_horizontally"></mat-icon>Split horizontally
      </button>
      <button mat-menu-item (click)="toggleBold($event)" aria-label="Toggle heading">
        <mat-icon>text_fields</mat-icon>Toggle heading
      </button>
      <button mat-menu-item [matMenuTriggerFor]="alignmentMenu" aria-label="Open cell alignment menu">
        <mat-icon>format_align_center</mat-icon>Text Alignment
      </button>
    </ng-container>

    <ng-container *ngIf="menu.mergeMode">
      <button mat-menu-item (click)="mergeCells($event)" aria-label="Merge selected cells">
        <mat-icon svgIcon="merge_cells"></mat-icon>Merge cells
      </button>
      <button mat-menu-item (click)="toggleBold($event)"><mat-icon>text_fields</mat-icon>Toggle headings</button>
      <button mat-menu-item [matMenuTriggerFor]="alignmentMenu">
        <mat-icon>format_align_center</mat-icon>Text alignment
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #alignmentMenu="matMenu">
  <ng-template matMenuContent>
    <div style="text-align: center">
      <button
        mat-icon-button
        (click)="justify(TextAlignment.TOP_LEFT, $event)"
        aria-label="Align text in cell to the top left"
      >
        <mat-icon svgIcon="top_left"></mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="justify(TextAlignment.TOP_CENTER, $event)"
        aria-label="Align text in cell to the top center"
      >
        <mat-icon svgIcon="top_middle"></mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="justify(TextAlignment.TOP_RIGHT, $event)"
        aria-label="Align text in cell to the top right"
      >
        <mat-icon svgIcon="top_right"></mat-icon>
      </button>
    </div>
    <div style="text-align: center">
      <button
        mat-icon-button
        (click)="justify(TextAlignment.MIDDLE_LEFT, $event)"
        aria-label="Align text in cell to the middle left"
      >
        <mat-icon svgIcon="middle_left"></mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="justify(TextAlignment.MIDDLE_CENTER, $event)"
        aria-label="Align text in cell to the middle center"
      >
        <mat-icon svgIcon="middle_middle"></mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="justify(TextAlignment.MIDDLE_RIGHT, $event)"
        aria-label="Align text in cell to the middle right"
      >
        <mat-icon svgIcon="middle_right"></mat-icon>
      </button>
    </div>
    <div style="text-align: center">
      <button
        mat-icon-button
        (click)="justify(TextAlignment.BOTTOM_LEFT, $event)"
        aria-label="Align text in cell to the bottom left"
      >
        <mat-icon svgIcon="bottom_left"></mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="justify(TextAlignment.BOTTOM_CENTER, $event)"
        aria-label="Align text in cell to the bottom center"
      >
        <mat-icon svgIcon="bottom_middle"></mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="justify(TextAlignment.BOTTOM_RIGHT, $event)"
        aria-label="Align text in cell to the bottom right"
      >
        <mat-icon svgIcon="bottom_right"></mat-icon>
      </button>
    </div>
  </ng-template>
</mat-menu>
