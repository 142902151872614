import {HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {MatSnackBar, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {ErrorCode, Logger} from 'app/error-handling/services/logger/logger.service';
import {Observable, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Callback, Predicate} from '../utils/typedefs';

@Injectable()
export class BaseService implements OnDestroy {
  protected static readonly _SNACKBAR_DURATION: number = 5000;

  protected _httpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private _lastSnackbar: MatSnackBarRef<SimpleSnackBar> = null;
  private _lastMessage: string = null;
  private _lastPersisted: boolean = false;

  protected _subscriptions: Subscription[] = [];

  constructor(protected _snackbar: MatSnackBar) {}

  /**
   * Unsubscribes to subscriptions.
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Helper method to enable components to remove persistent messages after an error has recovered
   *
   * @param message {string}         The message, if any
   * @param persist {boolean}        True if the snackbar should stay open; default false
   */
  protected _errorRecovered(message: string = null, persist: boolean = false): void {
    if (this._lastSnackbar && this._lastMessage === message && this._lastPersisted === persist) {
      this._lastSnackbar.dismiss();
    }
  }

  /**
   * Helper method to handle HTTP errors and display a diagnostic snackbar.
   *
   * @param response {HttpErrorResponse|any} The response object
   * @param message  {string}                The message, if any
   * @param persist  {boolean}               True if the snackbar should stay open; default false
   * @returns        {string}                The error string
   */
  protected _handleError(
    response: HttpErrorResponse | any,
    message: string = null,
    key?: ErrorCode,
    persist: boolean = false
  ): string {
    if (message) {
      if (this._lastSnackbar && this._lastMessage === message) {
        // Keep the current message up, we don't need another one
      } else {
        const options: any = persist ? {} : {duration: BaseService._SNACKBAR_DURATION};
        this._lastSnackbar = this._snackbar.open(message, 'Dismiss', options);
        this._lastSnackbar.afterDismissed().subscribe(() => (this._lastSnackbar = null));
        this._lastPersisted = persist;
        this._lastMessage = message;
      }
    }

    key = key || 'untyped-error';
    Logger.error(key, message, response);

    const reason: string =
      response instanceof HttpErrorResponse && response.error ? response.error.message : response.message;
    const result: string = `${response.status} - ${response.statusText} ${reason}`;

    return result;
  }

  /**
   * Helper function to convert a cold HTTP Observable into a hot Promise returning the
   * status of the HTTP request, passing any errors that occur to _handleError().
   *
   * @param observable {Observable<T>}     The observable to convert
   * @param message    {string}            The error message, if required
   * @returns          {Promise<number>}   The response promise
   */
  protected _toPromise(
    observable: Observable<HttpResponse<any>>,
    message: string,
    key?: ErrorCode,
    persist: boolean = false
  ): Promise<HttpResponse<any>> {
    return observable.toPromise().catch((error: HttpErrorResponse) => {
      this._handleError(error, message, key, persist);
      throw error;
    });
  }

  /**
   * Helper function to create filtered subscriptions from the given observable stream.
   *
   * @param observable {Observable<C>}  The observable to subscribe to
   * @param callback   {Callback<D>}    The subscription callback
   * @param predicate  {Predicate<D>}   An optional event predicate
   * @param allowNulls {boolean}        True if nulls should stay in the stream
   * @returns          {Subscription}   The subscription object
   */
  protected _makeSubscription<C, D extends C>(
    observable: Observable<C>,
    callback: Callback<D>,
    predicate: Predicate<D> = null,
    allowNulls: boolean = true
  ): Subscription {
    if (typeof callback !== 'function') {
      callback = (f: C) => {};
    }
    if (typeof predicate !== 'function') {
      predicate = (f: D) => true;
    }

    const filtered: Observable<C> = allowNulls
      ? observable.pipe(filter((f: D) => f === null || predicate(f)))
      : observable.pipe(filter((f: D) => f !== null && predicate(f)));

    return filtered.subscribe(callback);
  }

  /**
   * Helper function to convert an object of type T|T[] to an array.
   *
   * @tparam      {T}       The object type
   * @param array {T|T[]}   Either an object or array of objects
   * @returns     {T[]}     The parameter converted to an array
   */
  protected _toArray<T>(array: T | T[]): T[] {
    array = array || [];
    array = array instanceof Array ? array : [array];

    return array.filter((t: T) => !!t);
  }

  /**
   * Helper function to convert the JSON produced from a HAL endpoint into an array of T.
   *
   * @param json         {any}           The raw response.
   * @param field        {string}        The name of the field to extract.
   * @param deserialiser {(t: any) => T} A function to deserialise the elements of the array
   * and perform any desired operations.
   *
   * @returns {T[]} The deserialised array.
   */
  protected _deserialiseHALArray<T>(json: any, field: string, deserialiser: (t: any) => T): T[] {
    const jsonArray: any[] = json['_embedded'][field];
    const results: T[] = [];
    jsonArray.forEach((instance: any) => {
      results.push(deserialiser(instance));
    });
    return results;
  }
}
