<div #hoverTipRef class="hover-tip">
  <ul>
    <li>Username:</li>
    <li>Name:</li>
    <li>Email:</li>
  </ul>
  <ul>
    <li>{{ user.userName }}</li>
    <li>{{ user.name }}</li>
    <li>{{ user.email || 'Unknown' }}</li>
  </ul>
</div>
