import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewRef} from '@angular/core';
import {FragmentIndexService} from 'app/fragment/indexing/fragment-index.service';
import {ClauseFragment, ClauseType, Fragment, FragmentType, SectionFragment, SectionType} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {ClauseService} from '../services/clause.service';
import {SectionService} from '../services/section.service';

@Component({
  selector: 'cars-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('content') public content: any;

  public readonly SidebarStatus: typeof SidebarStatus = SidebarStatus;
  public status: SidebarStatus;
  public refreshable: boolean = false;

  public selectedSection: SectionFragment;
  public clause: ClauseFragment;
  public selectedFragment: Fragment;
  public readonly tooltipDelay = environment.tooltipDelay;

  public readonly clauseType: typeof ClauseType = ClauseType;

  private _subscriptions: Subscription[] = [];

  private readonly SIDEBAR_TYPES_TO_HIDE_CLAUSE_PREVIEW: ReadonlyArray<SidebarStatus> = [
    SidebarStatus.CLOSED,
    SidebarStatus.DISCUSSIONS,
    SidebarStatus.REORDERING,
  ];

  constructor(
    private sectionService: SectionService,
    private clauseService: ClauseService,
    private fragmentService: FragmentService,
    private sidebarService: SidebarService,
    private cdr: ChangeDetectorRef,
    private fragmentIndexService: FragmentIndexService
  ) {}

  /**
   * ClauseService and fragmentService interdependent logic required due to fragmentService.onSelection not being called when
   * in Review Mode.  When the clause is updated selectedFragment is set and when the fragment is updated the clause is set to
   * the fragment ancestor clause.  Logic handles both onSelection callbacks being called in quick succession.
   */
  public ngOnInit(): void {
    this._subscriptions.push(
      this.sectionService.onSelection((section) => {
        this.selectedSection = section;
        this.clause = null;
      }),
      this.clauseService.onSelection((clause: ClauseFragment) => {
        this.clause = clause || this.clause;
        if (this.clause && !this.clause.equals(this.selectedFragment?.findAncestorWithType(FragmentType.CLAUSE))) {
          this.selectedFragment = this.clause;
        }
      }),
      this.fragmentService.onSelection((fragment: Fragment) => {
        if (this.clause?.id.equals(fragment?.findAncestorWithType(FragmentType.CLAUSE)?.id)) {
          this.selectedFragment = fragment || this.selectedFragment;
          this.clause =
            (this.selectedFragment?.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment) || this.clause;
        }
      }),
      this.sidebarService.getSidebarStatus().subscribe((status: SidebarStatus) => {
        this.status = status;
        setTimeout(() => {
          this.refreshable = !!this.content && typeof this.content['refresh'] === 'function';
        });
        if (!(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
        }
      })
    );
  }

  /**
   * Destroy the component
   */
  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public close(): void {
    this.sidebarService.setSidebarStatus(SidebarStatus.CLOSED);
  }

  public refresh(): void {
    if (this.refreshable) {
      this.content.refresh();
    }
  }

  public showClausePreviewInSidebar(): boolean {
    return !!this.clause && !this.SIDEBAR_TYPES_TO_HIDE_CLAUSE_PREVIEW.includes(this.status);
  }

  public isIndexed(): boolean {
    if (!this.selectedSection) {
      return false;
    } else if (this.selectedSection.sectionType === SectionType.NORMATIVE) {
      return (
        this.clause.clauseType === this.clauseType.REQUIREMENT ||
        this.clause.clauseType === this.clauseType.ADVICE ||
        this.clause.clauseType === this.clauseType.NOTE
      );
    } else if (this.selectedSection.sectionType === SectionType.APPENDIX) {
      return (
        this.clause.clauseType === this.clauseType.HEADING_1 ||
        this.clause.clauseType === this.clauseType.HEADING_2 ||
        this.clause.clauseType === this.clauseType.HEADING_3
      );
    } else if (this.selectedSection.sectionType === SectionType.DOCUMENT_INFORMATION) {
      return this.clause.clauseType === this.clauseType.INFORMATION;
    }
    return false;
  }
}
