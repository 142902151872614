<div #hoverTipRef class="hover-tip">
  <div *ngIf="!!inlineReference && !!targetSectionUrl">
    <div>Link to referenced section:</div>
    <a [routerLink]="targetSectionUrl" [target]="getRouterLinkTarget()" aria-label="Go to referenced section">
      {{ getTargetSectionDisplayName() }}
    </a>
  </div>

  <button
    *ngIf="!inlineReference?.readonly && (CarsAction.CAN_EDIT_INTERNAL_REFERENCE | carsEnabled: documentId | async)"
    mat-raised-button
    color="primary"
    (click)="openSidebar()"
    [disabled]="!canOpenSidebar()"
    aria-label="Open section reference sidebar to change references"
    enableDefaultFocus
  >
    Change reference
  </button>

  <div *ngIf="inlineReference?.readonly">
    <br />
    This section reference is set as part of the Standard Format Requirement definition and can not be changed.
  </div>

  <div
    *ngIf="
      !(!!inlineReference && !!targetSectionUrl) && (CarsAction.AUTHOR_DOCUMENT | carsDisabled: documentId | async)
    "
  >
    No references selected.
  </div>
</div>
