import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {ClauseLinkType} from 'app/fragment/fragment-link/clause-link-type';
import {FragmentLink} from 'app/fragment/fragment-link/fragment-link';
import {ClauseFragment, ClauseType, Fragment} from 'app/fragment/types';
import {VersionTag} from 'app/interfaces';
import {ClauseLinkService} from 'app/services/clause-link.service';
import {FragmentService} from 'app/services/fragment.service';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-clause-links',
  templateUrl: './clause-links.component.html',
  styleUrls: ['./clause-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseLinksComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public clause: ClauseFragment;

  public readonly ClauseLinkType: typeof ClauseLinkType = ClauseLinkType;
  public readonly ClauseType: typeof ClauseType = ClauseType;

  public readonly clauseLinkTypeOptions: ReadonlyArray<ClauseLinkType> = [
    ClauseLinkType.VERIFICATION,
    ClauseLinkType.DOCUMENTATION,
  ];

  public loading = true;

  public clauseLinks: FragmentLink[];

  public selectingLinksOfType: ClauseLinkType = null;

  private _subscriptions: Subscription[] = [];

  private _clauseLinksSubscription: Subscription;

  constructor(
    private _clauseLinkService: ClauseLinkService,
    private _viewService: ViewService,
    private _fragmentService: FragmentService,
    private _cdr: ChangeDetectorRef
  ) {}

  /**
   * Subscribe to fragment updates, used to refresh the sidebar when clause type changes.
   */
  public ngOnInit(): void {
    this._subscriptions.push(
      this._fragmentService.onUpdate(
        () => {
          this._cdr.markForCheck();
        },
        (frag: Fragment) => frag.id.equals(this.clause.id)
      )
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('clause')) {
      if (
        !changes.clause.previousValue ||
        !changes.clause.currentValue ||
        !changes.clause.previousValue.id.equals(changes.clause.currentValue.id)
      ) {
        this.selectingLinksOfType = null;
        this.clauseLinks = [];
        this._cdr.markForCheck();
      }

      if (this._clauseLinksSubscription) {
        this._clauseLinksSubscription.unsubscribe();
        this._clauseLinksSubscription = null;
      }

      if (this.clause) {
        this.loading = true;
        this.clauseLinks = [];

        const currentViewVersionTag: VersionTag = this._viewService.getCurrentView()?.versionTag;
        const currentVersionTagValidAt: number = !!currentViewVersionTag ? currentViewVersionTag.createdAt : null;

        this._clauseLinksSubscription = this._clauseLinkService
          .getAllFromClause(this.clause, currentVersionTagValidAt)
          .subscribe((clauseLinks: FragmentLink[]) => {
            this.clauseLinks = clauseLinks;

            this.loading = false;
            this._cdr.markForCheck();
          });
      } else {
        this.clauseLinks = [];
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
    this._clauseLinksSubscription?.unsubscribe();
  }

  /**
   * Sets the selection state to selecting either verification or documentation links, or stops selection if passed
   * null.
   */
  public setSelectingLinksState(clauseLinkType: ClauseLinkType): void {
    this.selectingLinksOfType = clauseLinkType;
    this._cdr.markForCheck();
  }

  /**
   * Clears all links for a clause that has been changed from a requirement to a heading/subheading.
   */
  public clearAllLinks(): void {
    this._clauseLinkService.deleteAll(this.clauseLinks);
    this.clause.verificationLinkRequired = null;
    this.clause.documentationLinkRequired = null;
    this._fragmentService.update(this.clause);
  }

  /**
   * Returns whether the Clause Links should be disabled/enabled for this particular clause
   */
  public isClauseLinksDisabled(): boolean {
    return this.clause.clauseType !== ClauseType.REQUIREMENT || this.clause.isUnmodifiableClause;
  }
}
