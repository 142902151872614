import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AccessibilityManagerComponent} from './accessibility-manager.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AccessibilityManagerComponent],
  exports: [AccessibilityManagerComponent],
})
export class AccessibilityManagerModule {}
