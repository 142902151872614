import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {DocumentFragment} from 'app/fragment/types';
import {Breadcrumb} from 'app/interfaces';
import {DocumentService} from 'app/services/document.service';
import {UUID} from 'app/utils/uuid';
import {CurrentLocation, ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentVersionsResolverService implements Resolve<DocumentFragment> {
  constructor(private documentService: DocumentService, private viewService: ViewService) {}

  /**
   * Resolves the versions for the given document ID.
   *
   * @inheritdoc
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<DocumentFragment> {
    const id: string = route.paramMap.get('document_id');

    return this.documentService
      .load(UUID.orNull(id), {projection: 'INITIAL_DOCUMENT_LOAD'})
      .then((document: DocumentFragment) => {
        const breadcrumbs: Breadcrumb[] = [
          {title: 'Documents', link: '/documents'},
          {title: document.title, link: `/documents/${document.id.value}`},
          {title: 'Versions'},
        ];
        route.data = {breadcrumbs: breadcrumbs};

        this.documentService.setSelected(document);
        this.viewService.setLocation(CurrentLocation.VERSIONS_TABLE);
        return document;
      });
  }
}
