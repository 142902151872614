import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {ReferenceInputFragment} from 'app/fragment/types/input/reference-input-fragment';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {ReferenceService} from 'app/services/references/reference.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-section-reference-input',
  templateUrl: './section-reference-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionReferenceInputComponent extends FragmentComponent implements OnInit, OnDestroy {
  @Input() public set content(value: ReferenceInputFragment) {
    super.content = value;
  }

  private _subscriptions: Subscription[] = [];

  public inlineReferences: InternalInlineReferenceFragment[] = [];

  public get content(): ReferenceInputFragment {
    return super.content as ReferenceInputFragment;
  }

  constructor(protected _cdr: ChangeDetectorRef, _elementRef: ElementRef, private _referenceService: ReferenceService) {
    super(_cdr, _elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._sortReferences();
    this._subscriptions.push(
      this._referenceService.onDocumentReferencesRecalculated().subscribe(() => {
        this._sortThenMarkForCheckReferences();
      }),
      this._referenceService.onReferenceChange().subscribe(() => {
        this._sortThenMarkForCheckReferences();
      })
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  private _sortThenMarkForCheckReferences() {
    this._sortReferences();
    this._cdr.markForCheck();
  }

  private _sortReferences(): void {
    this.inlineReferences = this.content.children
      .map((f) => f as InternalInlineReferenceFragment)
      .sort((x: InternalInlineReferenceFragment, y: InternalInlineReferenceFragment) => {
        return (
          this._referenceService.getInternalReferenceSorting(x.internalDocumentReferenceId) -
          this._referenceService.getInternalReferenceSorting(y.internalDocumentReferenceId)
        );
      });
  }
}
