import {Injectable} from '@angular/core';
import {FocusableElement, tabbable} from 'tabbable';

@Injectable({
  providedIn: 'root',
})
export class TabbableService {
  constructor() {}

  /**
   * Get the next or previous focusable element, from a given current element.
   * @param rootElement the root element to scan for focusables from
   * @param curElement the currently focussed element i.e. the element having focus or with a child that has focus
   * @param goToNext true if the next focusable element should be returned, false if the previous focusable element should be returned
   * @returns the next or previous focusable element
   */
  public getFocusableElement(rootElement: HTMLElement, curElement: HTMLElement, goToNext: boolean): FocusableElement {
    const tabbables: FocusableElement[] = this._getAppTabbables(rootElement);
    const curIndex: number = tabbables.findIndex((el) => curElement.contains(el));
    if (curIndex === -1) {
      return null;
    }
    const nextIndex: number = (curIndex + 1) % tabbables.length;
    const prevIndex: number = curIndex === 0 ? tabbables.length - 1 : curIndex - 1;
    const indexToTabTo: number = goToNext ? nextIndex : prevIndex;
    return tabbables[indexToTabTo];
  }

  /**
   * Get all the focusable/tabbable elements in the app, from a given root element.
   * @param rootElement the root element to search for focusables from.
   */
  private _getAppTabbables(rootElement): FocusableElement[] {
    return tabbable(rootElement);
  }
}
