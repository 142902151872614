import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {NavigationComponent} from './navigation.component';
import {SearchBoxModule} from './search/search-box/search-box.module';
import {SearchDocumentModule} from './search/search-document/search-document.module';
import {SectionListModule} from './section-list/section-list.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    AltAccessibilityModule,
    MatTabsModule,
    SectionListModule,
    SearchBoxModule,
    SearchDocumentModule,
  ],
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
})
export class NavigationModule {}
