import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FragmentModule} from 'app/fragment/fragment.module';
import {SectionChangeSummaryRowComponent} from './section-change-summary-row.component';

@NgModule({
  imports: [CommonModule, FragmentModule],
  declarations: [SectionChangeSummaryRowComponent],
  exports: [SectionChangeSummaryRowComponent],
})
export class SectionChangeSummaryRowModule {}
