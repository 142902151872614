import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {ReferenceDialogModule} from 'app/admin/manage-references/reference-dialog/reference-dialog.module';
import {DialogModule} from 'app/dialog/dialog/dialog.module';
import {UserModule} from 'app/widgets/user/user.module';
import {ManageReferencesComponent} from './manage-references.component';
import {WithdrawalDialogModule} from './withdrawal-dialog/withdrawal-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    UserModule,
    MatProgressBarModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    WithdrawalDialogModule,
    RouterModule,
    FormsModule,
    ReferenceDialogModule,
    DialogModule,
  ],
  declarations: [ManageReferencesComponent],
  exports: [ManageReferencesComponent],
})
export class ManageReferencesModule {}
