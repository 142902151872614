import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatOptionSelectionChange} from '@angular/material/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DocumentOverviewService} from 'app/document-overview/document-overview.service';
import {UserCreatorComponent} from 'app/document-overview/role-assignments/user-list-autocomplete/user-creator/user-creator.component';
import {DocumentRole} from 'app/documents/document-data';
import {DocumentFragment} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {FragmentService} from 'app/services/fragment.service';
import {GlobalRole} from 'app/services/user/authentication-provider';
import {UserService} from 'app/services/user/user.service';
import {User} from 'app/user/user';
import {Search} from 'app/utils/search';
import {CurrentView} from 'app/view/current-view';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {RoleService} from '../../../services/user/role.service';
import {UUID} from '../../../utils/uuid';
import {AutocompleteProperties, UserItem} from '../role-assignments.component';

@Component({
  selector: 'cars-user-list-autocomplete',
  templateUrl: './user-list-autocomplete.component.html',
  styleUrls: ['./user-list-autocomplete.component.scss'],
})
export class UserListAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  public readonly tooltipDelay: number = environment.tooltipDelay;

  @Input() public document: DocumentFragment;
  @Input() public currentView: CurrentView;
  @Input() public properties: AutocompleteProperties;
  @Input() public userAuthoringStatuses: Record<string, boolean>;
  @Input() public userPool: UserItem[] = [];
  @Input() public role: DocumentRole;
  @Output() public userListChanged: EventEmitter<void> = new EventEmitter();

  public readonly CarsAction: typeof CarsAction = CarsAction;

  public showUserStatusToggles: boolean = false;

  public assignedUsers: UUID[] = [];

  public get hintLabel(): string {
    return !this.assignedUsers.length && !this.noUsersFound()
      ? `No ${this.properties.title} selected. Click on the ${this.properties.title} box to add one.`
      : '';
  }

  public get filterResults(): UserItem[] {
    return this.noUsersFound() ? this._similarUsers : this._filteredUsers;
  }

  private _filteredUsers: UserItem[] = [];
  // List of users with usernames similar to the userFilter string
  private _similarUsers: UserItem[] = [];

  // A lookup of all users for use when sorting the list of assigned users.
  private _usersMap: Record<string, User> = {};

  public userFilter: string = '';

  public autoCompleteComponent: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger)
  public autoCompleteTrigger: MatAutocompleteTrigger;

  public isSingleUserAssignment: boolean = false;

  public additionalUsersMessage: string = '';

  private _dialogRef: MatDialogRef<UserCreatorComponent, User>;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _roleService: RoleService,
    private _userService: UserService,
    private _documentOverviewService: DocumentOverviewService,
    private _fragmentService: FragmentService,
    private _dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.isSingleUserAssignment = this.role === DocumentRole.LEAD_AUTHOR;
    this.showUserStatusToggles = [DocumentRole.AUTHOR, DocumentRole.LEAD_AUTHOR, DocumentRole.OWNER].includes(
      this.role
    );
    this.userPool.forEach((userItem: UserItem) => (this._usersMap[userItem.user.id.value] = userItem.user));
    this._calculateAssignedUsers();

    this._subscriptions.push(
      this._fragmentService.onUpdate(
        () => this._calculateAssignedUsers(),
        (frag) => frag.equals(this.document)
      )
    );
  }

  /**
   * Filters users as list is fetched asynchronously and updates the list of assigned users.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['userPool']) {
      this.filterUsers();
      this._calculateAssignedUsers();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Prevents click event to bubble on selection of user.
   * @param event {MouseEvent}  Prevent default click event to bubble
   */
  public preventDefaultEvent(event: MouseEvent): void {
    event.preventDefault();
  }

  /**
   * Calculates the list of assigned users to display.
   */
  private async _calculateAssignedUsers(): Promise<void> {
    const usersInRole: UUID[] = []
      .concat(this.document.documentData[DocumentOverviewService.ROLE_TO_DOCUMENT_DATA[this.role]])
      .filter(Boolean);

    // Deal with unknown users or users no longer in the assigned role
    for (const user of usersInRole) {
      if (!this._usersMap[user.value]) {
        await this._getUserFromService(user);
      }
    }

    this.assignedUsers = usersInRole
      .filter((userId: UUID) => !!this._usersMap[userId.value])
      .sort((a: UUID, b: UUID) => User.compareNames(this._usersMap[a.value], this._usersMap[b.value]));
  }

  /**
   * Gets the given user from the service and stores it into the _usersMap.
   */
  private async _getUserFromService(id: UUID): Promise<void> {
    const user: User = await this._userService.getUserFromId(id).pipe(take(1)).toPromise();
    this._usersMap[id.value] = user;
  }

  /**
   * @returns {boolean} True if the button to create a new should be displayed
   */
  public showCreateUser(): boolean {
    return (
      this._roleService.isInGlobalRoles(GlobalRole.USER_CREATOR) &&
      (this.userFilter.length > 0 || this.userPool.length === 0)
    );
  }

  /**
   * Creates the dialog for creating a user.
   */
  public createUser(): void {
    this._dialogRef = this._dialog.open(UserCreatorComponent, {
      width: '300px',
      ariaLabel: 'Create new user dialog',
      data: {input: this.userFilter},
    });
    this._dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((user: User) => {
        if (user) {
          this._documentOverviewService.assignUserToDocument(this.document, user.id, this.role);
          // Trigger reload of user pool in parent
          this.userListChanged.emit();
        }
        this._dialogRef = null;
      });
  }

  public assignUser(userId: UUID, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      this._documentOverviewService.assignUserToDocument(this.document, userId, this.role);
    }
  }

  /**
   * Filters the userRoleList by comparing the start of the name to the userFilter and sorts them alphabetically.
   */
  public filterUsers(): void {
    let filtered: UserItem[] = [];
    if (this.userPool) {
      filtered = this.userPool.filter((userItem: UserItem) => this._searchFilter(userItem.user));
      this._filteredUsers = filtered.slice(0, 20);
      this._setAdditonalUserMessage(filtered.length, this._filteredUsers.length);
      if (this.noUsersFound()) {
        filtered = this.userPool.filter((userItem: UserItem) => this._searchFilter(userItem.user, true)).slice(0, 20);
        this._similarUsers = filtered.slice(0, 20);
        this._setAdditonalUserMessage(filtered.length, this._similarUsers.length);
      }
    }
  }

  private _setAdditonalUserMessage(total: number, sliced: number): void {
    if (total > sliced) {
      this.additionalUsersMessage = `+ ${total - sliced} additional users`;
    } else {
      this.additionalUsersMessage = '';
    }
  }

  /**
   * Removes value from form input and resets filters & re-opens the MdAutocomplete panel.
   * @param mdOptionSelectionChange {MdOptionSelectionChange} Event oject emitted by MdOption when selected or deselected
   */
  public clearFilter(mdOptionSelectionChange: MatOptionSelectionChange): void {
    mdOptionSelectionChange.source.value = '';
    this.userFilter = '';
    setTimeout(() => this.autoCompleteTrigger.openPanel(), 10);
  }

  /**
   * @returns {boolean} True if the hint should be displayed
   */
  public noUsersFound(): boolean {
    return this._filteredUsers.length === 0 && this.userFilter.length > 0;
  }

  /**
   * Checks if the user matches the search.
   *
   * @param user {User}    User to filter
   * @returns    {boolean} True if user matches
   */
  private _searchFilter(user: User, getSimilar: boolean = false): boolean {
    const filter: string = this.userFilter.toLowerCase();

    return getSimilar
      ? Search.similar(filter, user.name, user.firstName, user.lastName, user.email)
      : Search.check(filter, user.name, user.email);
  }
}
