import {Serialisable} from 'app/utils/serialisable';
import {UUID} from 'app/utils/uuid';
import {Administration} from './administrations';

/**
 * Model used to store data about the potentially related Annexes to a core document. Along
 * with any other required metadata about the relaionship, if it exists.
 *
 * @field linksEnabled   {boolean} true if NAA links are enabled, false otherwise
 * @field linkedDocuments {Record<{Administration},{UUID}>} Map of linked document ids keyed by devolved administration
 */
export class LinkedDocuments implements Serialisable {
  /**
   * Deserialise an instance of LinkedDocuments from a JSON object.
   *
   * @param json {any}              The JSON
   * @returns    {LinkedDocuments}  The linked document data
   */
  public static deserialise(json: any): LinkedDocuments {
    if (json) {
      return new LinkedDocuments(json.enabled, LinkedDocuments.convertLinkedDocumentJsonToMap(json.linkedDocuments));
    }
  }

  /**
   * Accept JSON and build up correct "Map" object
   *
   * @param json raw JSON MAP (key -> value)
   * @returns Map<Administration, UUID>
   */
  private static convertLinkedDocumentJsonToMap(json: any): Map<Administration, UUID> {
    const map: Map<Administration, UUID> = new Map();
    if (json) {
      for (const [administration, documentId] of Object.entries(json)) {
        const convertedDocumentId = UUID.orNull(documentId as string);
        map.set(administration as Administration, convertedDocumentId);
      }
    }

    return map;
  }

  constructor(
    public enabled: boolean = null,
    public linkedDocuments: Map<Administration, UUID> = new Map<Administration, UUID>()
  ) {}

  /**
   *
   * Accept Map and convert to raw JSON object.
   *
   * @param map Map<Administration, UUID>
   * @returns raw json type
   */
  private convertLinkedDocumentsToJson(): any {
    const json = {};
    for (const [adminstration, documentId] of this.linkedDocuments.entries()) {
      json[adminstration.toString()] = documentId.value;
    }
    return json;
  }

  /**
   * serialise an instance of LinkedDocuments to a JSON Object
   * @returns linkedDocuments in raw string JSON format
   */
  public serialise(): any {
    return {
      enabled: this.enabled,
      linkedDocuments: !!this.linkedDocuments ? this.convertLinkedDocumentsToJson() : new Map<Administration, UUID>(),
    };
  }
}
