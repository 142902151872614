import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {AltImageModule} from 'app/sidebar/alt-text/alt-image/alt-image.module';
import {LogoUploadComponent} from './logo-upload.component';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatButtonModule, AltImageModule],
  declarations: [LogoUploadComponent],
  exports: [LogoUploadComponent],
})
export class LogoUploadModule {}
