import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {debounceTime, distinctUntilChanged, Subject, Subscription} from 'rxjs';

/**
 * Object used to transmit data upon open of the modal.
 */
export interface ManualTableColumnWidthModalInputData {
  title: string;
  widths: number[];
}

/**
 * Object used to transmit data upon closure of the modal
 */
export interface ManualTableColumnWidthModalOutputData {
  completed: boolean;
  widths: number[];
}

/**
 * Object used when the user is trying to set the width of a column
 */
export interface ColumnWidthSetInfo {
  index: number;
  value: string;
}

/**
 * Object used for the 'current' state of all columns, and whether they are using placeholder values or not.
 */
export interface ColumnWidthInfo {
  placeholder: boolean;
  value: number;
}

@Component({
  selector: 'cars-table-manual-width-modal',
  templateUrl: './manual-table-column-width-modal.component.html',
  styleUrls: ['./manual-table-column-width-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualTableColumnWidthModalComponent implements OnInit {
  public readonly title: string;
  public currentWidths: number[];
  public newWidths: ColumnWidthInfo[];
  public autoFillRemainingWidth: boolean = false;
  public totalWidth: number;

  public columnWidthChanged: Subject<ColumnWidthSetInfo> = new Subject<ColumnWidthSetInfo>();
  private _subscriptions: Subscription[] = [];

  public static open(
    dialog: MatDialog,
    currentWidths: number[],
    title: string
  ): MatDialogRef<ManualTableColumnWidthModalComponent, ManualTableColumnWidthModalOutputData> {
    return dialog.open(ManualTableColumnWidthModalComponent, {
      ariaLabel: 'Manually set column widths of selected table',
      autoFocus: false,
      width: '90vw',
      maxWidth: '1500px',
      maxHeight: '70vh',
      data: {
        widths: currentWidths,
        title: title,
      } as ManualTableColumnWidthModalInputData,
    });
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _dialogRef: MatDialogRef<ManualTableColumnWidthModalComponent, ManualTableColumnWidthModalOutputData>,
    @Inject(MAT_DIALOG_DATA) private _data: ManualTableColumnWidthModalInputData
  ) {
    this.title = _data.title;
    this.currentWidths = _data.widths;
    this.newWidths = this._convertToColumnWidthInfo(_data.widths);
  }

  public ngOnInit(): void {
    this._subscriptions.push(
      this.columnWidthChanged.pipe(debounceTime(200), distinctUntilChanged()).subscribe((debounced) => {
        this._updateColumnWidthFromInput(debounced);
      })
    );
    this.sumWidths(true);
  }

  /**
   * Deals with taking a user's input and updating the array of column widths to reflect their input.
   * If they remove the input fully, we set the value to '0' as to not break any parsing.
   * This method also deals with calling through to recalculate the placeholder values for column widths not specified by the user.
   */
  private _updateColumnWidthFromInput(infoToSet: ColumnWidthSetInfo) {
    const emptyValue: boolean = !infoToSet.value;
    if (emptyValue) {
      infoToSet.value = '0';
    }
    this._setWidth(infoToSet, emptyValue);
    this.newWidths.forEach((width, index) => this._recalculatePlaceholderTableWidths(width, index));
    this.sumWidths(true);
    this._cdr.markForCheck();
  }

  public onAutoFillChange(value: boolean) {
    this.autoFillRemainingWidth = value;
    this.newWidths.forEach((width, index) => this._recalculatePlaceholderTableWidths(width, index));
    this.sumWidths(true);
  }

  /**
   * Deals with recalculating the widths for placeholder columns i.e. unspecified by the user ONLY if the user
   * has requested the auto-calculation of remaining widths.
   * If the user has not activated auto-calculation, this method will return instantly, terminating any further process.
   */
  private _recalculatePlaceholderTableWidths(width: ColumnWidthInfo, index: number): void {
    if (!width.placeholder) {
      return;
    }
    const widthToSave = this.generateTableWidths(width, index).replace('%', '');
    this._setWidth({index, value: widthToSave}, true);
  }

  /**
   * Used to mark a width as inputted by user, and will set the width to that provided by the user.
   */
  private _setWidth({index, value}: ColumnWidthSetInfo, placeholder: boolean): void {
    this.newWidths[index].placeholder = placeholder;
    this.newWidths[index].value = parseFloat(value);
    this._cdr.markForCheck();
  }

  /**
   * Converts an array of numbers into an array of ColumnWidthInfo objects, default placeholder to true
   * See _convertToValue() for opposite
   */
  private _convertToColumnWidthInfo(widths: number[]): ColumnWidthInfo[] {
    return widths.map((width) => {
      return {placeholder: true, value: width * 100} as ColumnWidthInfo;
    });
  }

  /**
   * Formats a width by multiplying by a multiplier, then setting to a maximum of 2 d.p.
   * @param width to format
   * @param multiplier value to multiply by. Defaults to 100 (Decimal to Percentage)
   * @returns formatted width
   */
  public formatWidthToPercentage(width: number, multiplier: number = 100, fixDecimalPlaces: boolean = true): number {
    const calculatedWidth = width * multiplier;
    return fixDecimalPlaces ? parseFloat(calculatedWidth.toFixed(2)) : calculatedWidth;
  }

  /**
   * Used by the column width inputs to set the respective column's width to that inputted by a user.
   * @param value inputted by user
   * @param index of the column widthin the two 'width' variables of this class
   */
  public onColumnWidthInputChange(value: string, index: number): void {
    this.columnWidthChanged.next({value: value, index: index} as ColumnWidthSetInfo);
  }

  /**
   * Used to calculate the width of each column, based on the index of the column and metadata about the table itself.
   *
   * <p>If the user has enabled the Auto-fill, any specified columns will use their value, and any unspecified (placeholder)
   * columns will split the remaining % width. If the user has disabled the auto-fill, if the total % width sums to 100%, use
   * the width of the column, otherwise revert to the % width prior to any changes.
   * </p>
   */
  public generateTableWidths(width: ColumnWidthInfo, index: number): string {
    if (!!this.autoFillRemainingWidth) {
      if (width.placeholder) {
        return this.calculateRemainingWidth(index);
      }
      return `${width.value}%`;
    } else {
      const currentWidthSum = this.sumWidths();
      if (Math.floor(currentWidthSum) === 100) {
        return `${width.value}%`;
      } else {
        return `${this.formatWidthToPercentage(this.currentWidths[index], 100, true)}%`;
      }
    }
  }

  /**
   * Calculates the remaining width not allocated by the user, and splits evenly between the columns that have not had width allocated.
   * This method will also update the width of 'placeholder' columns with the result of the even split of width, but continues to store
   * them as placeholder widths.
   * Note: this will return a minimum value of 2% for auto-filled columns to prevent 0 or negative widths.
   * @param index index of current column being evaluated
   * @param currentwidth value of column width of current column
   * @returns correct width as a percentage
   */
  public calculateRemainingWidth(index: number): string {
    let thisWidth: number;

    const inputtedWidths = this.newWidths.filter((width) => !width.placeholder);
    if (!!inputtedWidths.length) {
      const usedWidth = inputtedWidths.reduce((sum, current) => sum + current.value, 0);
      const remainingWidth = 100 - usedWidth;
      thisWidth = Math.max(remainingWidth / (this.newWidths.length - inputtedWidths.length), 2);
    } else {
      thisWidth = this.formatWidthToPercentage(this.currentWidths[index], 100, false);
    }

    this._setWidth({index: index, value: thisWidth.toString()}, true);
    return `${thisWidth}%`;
  }

  /**
   * Calculates the total value of all widths combined. If autoFillRemainingWidth is enabled,
   * all 'placeholder' columns' widths are included in this summation. If not, the only columns
   * that are considered in the sum are those that have been allocated a width by the user.
   * @returns sum of all widths
   */
  public sumWidths(saveValue: boolean = false): number {
    let sum = 0;

    this.newWidths.forEach((width: ColumnWidthInfo) => {
      if (this.autoFillRemainingWidth || !width.placeholder) {
        sum += width.value;
      }
    });

    if (saveValue) {
      this.totalWidth = this.formatWidthToPercentage(sum, 1);
    }
    return this.formatWidthToPercentage(sum, 1);
  }

  /**
   * EventHandler for if the user cancels the update of the column widths.
   */
  public cancel(): void {
    this._dialogRef.close({completed: false, widths: this.currentWidths});
  }

  /**
   * Returns a boolean as to whether the user can save the column widths in their current state.
   */
  public canSaveWidths(): boolean {
    return (
      this.checkAllColumnsSpecifiedIfRequired() &&
      this.checkAllColumnsHaveAtLeastMinimumWidth() &&
      this.checkSumWidthsEquals100()
    );
  }

  /**
   * Checks that all columns have a % width that meets the minimum value required
   */
  public checkAllColumnsHaveAtLeastMinimumWidth(): boolean {
    return this.newWidths.every((width) => width.value >= 2);
  }

  /**
   * Checks if the total % width is 100%.
   */
  public checkSumWidthsEquals100(): boolean {
    return this.sumWidths() === 100;
  }

  /**
   * Checks if all columns have a specified value, or that the user has selected 'autoFillRemainingWidth'
   */
  public checkAllColumnsSpecifiedIfRequired(): boolean {
    return this.autoFillRemainingWidth || this.newWidths.every((width) => !width.placeholder);
  }
  /**
   * EventHandler for if the user clicks to save the column widths in their current state
   */
  public confirm(): void {
    this._dialogRef.close({
      completed: true,
      widths: this._formatWidthToDecimal(this._convertToValue(this.newWidths)),
    });
  }

  /**
   * Formats an array of percentage-based widths into an array of decimal-based widths.
   */
  private _formatWidthToDecimal(widths: number[]): number[] {
    return widths.map((width) => width / 100);
  }

  /**
   * Converts an array of ColumnWidthInfo objects into an array of numbers
   * See _convertToColumnWidthInfo() for opposite
   */
  private _convertToValue(widths: ColumnWidthInfo[]): number[] {
    return widths.map((width) => width.value);
  }
}
