import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {DocumentLinkModule} from '../naa-document-linkage/document-link/document-link.module';
import {CoreDocumentLinkageComponent} from './core-document-linkage.component';

@NgModule({
  declarations: [CoreDocumentLinkageComponent],
  imports: [CommonModule, DocumentLinkModule, FormsModule, MatFormFieldModule, MatInputModule],
  exports: [CoreDocumentLinkageComponent],
})
export class CoreDocumentLinkageModule {}
