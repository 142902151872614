<button
  mat-icon-button
  matTooltip="Close search results"
  matTooltipPosition="right"
  [matTooltipShowDelay]="tooltipDelay"
  (click)="closeView()"
  aria-label="Close search results"
>
  <mat-icon>close</mat-icon>
</button>
<div *ngIf="!searching" class="main">
  <ng-container *ngIf="numberOfResults > 0">
    <div class="total-message">
      {{ numberOfResultsText() }}
    </div>
    <ng-container *ngFor="let section of sectionKeys">
      <div
        class="section"
        matTooltip="Collapse section results"
        matTooltipPosition="right"
        [matTooltipShowDelay]="tooltipDelay"
        (click)="toggleSection(section)"
        (keyup.enter)="toggleSection(section)"
        tabindex="0"
      >
        <mat-icon [class.collapsed]="sectionProperties.get(section).collapsed">
          {{ sectionProperties.get(section).collapsed ? 'arrow_drop_up' : 'arrow_drop_down' }}
        </mat-icon>
        <strong>{{ sectionProperties.get(section).displayString }}</strong>
      </div>
      <ng-container *ngFor="let result of sectionProperties.get(section).searchResults">
        <div
          *ngIf="!sectionProperties.get(section).collapsed"
          matTooltip="Go to result location"
          matTooltipPosition="right"
          [matTooltipShowDelay]="tooltipDelay"
          class="fragment-result"
          [class.selected]="selectedResult === result"
          (click)="goToFragment(section, result)"
          (keyup.enter)="goToFragment(section, result)"
          tabindex="0"
        >
          {{ result.display[0] }}<mark>{{ result.display[1] }}</mark
          >{{ result.display[2] }}
        </div>
        <mat-progress-bar *ngIf="result.navigatingTo" mode="indeterminate"></mat-progress-bar>
      </ng-container>
      <mat-divider></mat-divider>
    </ng-container>
  </ng-container>
  <div *ngIf="numberOfResults === 0" class="total-message">No results found.</div>
</div>
<mat-progress-spinner *ngIf="searching" mode="indeterminate"></mat-progress-spinner>
