import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {WsrSubjectTopicComponent} from 'app/wsr-subject-topic/wsr-subject-topic.component';

@Injectable({
  providedIn: 'root',
})
export class WsrSubjectTopicGuardService implements CanDeactivate<WsrSubjectTopicComponent> {
  /**
   * Displays a warning to the user if they have un-saved staged comments.
   *
   * @inheritdoc
   */
  public canDeactivate(component: WsrSubjectTopicComponent): boolean {
    return component.canDeactivate()
      ? true
      : confirm(
          'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.'
        );
  }
}
