import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {DocumentSelectorModule} from 'app/search/document-selector/document-selector.module';
import {TdifModule} from 'app/tdif/tdif.module';
import {ClauseReferencesComponent} from './clause-references.component';

@NgModule({
  imports: [
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatCheckboxModule,
    CarsPermissionPipesModule,
    DocumentSelectorModule,
    CommonModule,
    FormsModule,
    TdifModule,
  ],
  declarations: [ClauseReferencesComponent],
  exports: [ClauseReferencesComponent],
})
export class ClauseReferencesModule {}
