import {Injectable} from '@angular/core';
import {DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {ReferenceService} from 'app/services/references/reference.service';
import {UUID} from 'app/utils/uuid';
import {Suite} from '../suite';
import {DocumentFragment, Fragment, FragmentType} from '../types';
import {dmrbIndexer} from './dmrb-indexer';
import {legacyDmrbIndexer} from './legacy-dmrb-indexer';
import {legacyMchwIndexer} from './legacy-mchw-indexer';
import {mchwIndexer} from './mchw-indexer';
import {momhwIndexer} from './momhw-indexer';
import {unrestrictedIndexer} from './unrestricted-indexer';

export const suiteToIndexer: Record<Suite, FragmentIndexer> = {
  [Suite.DMRB]: dmrbIndexer,
  [Suite.LEGACY_DMRB]: legacyDmrbIndexer,
  [Suite.MCHW]: mchwIndexer,
  [Suite.LEGACY_MCHW]: legacyMchwIndexer,
  [Suite.MOMHW]: momhwIndexer,
  [Suite.UNRESTRICTED]: unrestrictedIndexer,
};

/**
 * Function that all indexers should implement.
 * Generates an index lookup from fragment id to display index for a given document id.
 *
 * @param document {DocumentFragment}        The document fragment to generate the index lookup for
 * @returns        {Record<string, string}   The index lookup, from fragment id to display index
 */
export type FragmentIndexer = (document: DocumentFragment) => Record<string, string>;

@Injectable({
  providedIn: 'root',
})
export class FragmentIndexService {
  private _idToIndex: Record<string, string> = {};

  constructor(
    private _fragmentService: FragmentService,
    private _documentService: DocumentService,
    private _referenceService: ReferenceService
  ) {
    this._fragmentService.onCreate(this._generateLookup.bind(this), this._reindexPredicate.bind(this));
    this._fragmentService.onUpdate(this._generateLookup.bind(this), this._reindexPredicate.bind(this));
    this._fragmentService.onDelete(this._generateLookup.bind(this), this._reindexPredicate.bind(this));
    this._referenceService.onDocumentReferencesRecalculated().subscribe(this._generateLookup.bind(this));
    this._documentService.onSelection(this._generateLookup.bind(this));
  }

  /**
   * Re-generates the lookup from fragment id to it's display index.
   */
  private _generateLookup(): void {
    const documentFragment: DocumentFragment = this._documentService.getSelected();
    const suite: Suite = documentFragment.suite;

    this._idToIndex = suiteToIndexer[suite](documentFragment);
  }

  /**
   * For a given fragment or fragment id, return the display index.  If the display index is null, the default value is returned.
   *
   * @param arg {UUID|Fragment}   The fragment id or fragment data object
   * @param defaultValue {string} The default display index to display if no index is found. Defaults to the empty string.
   * @returns   {string}          The display index
   */
  public getIndex(arg: UUID | Fragment, defaultValue: string = ''): string {
    if (arg === null) {
      return defaultValue;
    } else {
      arg = arg instanceof Fragment ? arg.id : arg;
      return this._idToIndex[arg.value] || defaultValue;
    }
  }

  private _reindexPredicate(fragment: Fragment): boolean {
    return (
      fragment.is(
        FragmentType.SECTION_GROUP,
        FragmentType.SECTION,
        FragmentType.CLAUSE_GROUP,
        FragmentType.CLAUSE,
        FragmentType.INLINE_REFERENCE,
        FragmentType.INTERNAL_INLINE_REFERENCE
      ) || fragment.isCaptioned()
    );
  }
}
