import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InternalInlineReferenceFragmentComponent} from 'app/fragment/inline-section-reference/internal-inline-reference-fragment.component';
import {ClauseFragment, EDITABLE_TEXT_FRAGMENT_TYPES, Fragment, FragmentType} from 'app/fragment/types';
import {AbstractInputFragment} from 'app/fragment/types/input/abstract-input-fragment';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {TargetDocumentType} from 'app/fragment/types/reference/target-document-type';
import {StandardFormatType} from 'app/fragment/types/standard-format-type';
import {StandardFormatGroupConfig} from 'app/services/clause-group.service';
import {Subscription} from 'rxjs';
import {StandardFormatGroupModalService} from '../standard-format-group-modal.service';

@Component({
  selector: 'cars-standard-format-group-example',
  templateUrl: './standard-format-group-example.component.html',
  styleUrls: ['./standard-format-group-example.component.scss'],
})
export class StandardFormatGroupExampleComponent implements OnInit, OnDestroy {
  @Input() public standardFormatGroupConfig: StandardFormatGroupConfig[];

  public displayClauses: string[] = [];

  private _subscriptions: Subscription[] = [];

  constructor(private _modalService: StandardFormatGroupModalService) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._modalService.onSelectedTypeChange((type: StandardFormatType) => {
        this.displayClauses = this._getClausePreviewsForType(type);
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  public formatIndex(i: number): string {
    return i === 0 ? 'X.X' : 'X.X+' + i;
  }

  private _getClausePreviewsForType(type: StandardFormatType): string[] {
    if (!type) {
      return [];
    }

    const configForType: StandardFormatGroupConfig = this.standardFormatGroupConfig.find(
      (config) => config.fragmentToCreate.standardFormatType === type
    );

    const result: string[] = configForType.fragmentToCreate.children.reduce((arr: string[], clause: ClauseFragment) => {
      this.calculateClauseAndInternalInlineReferencePreview(clause, configForType.internalReferences);
      arr.push(clause.preview);
      return arr;
    }, []);

    return !!result ? result : [];
  }

  private calculateClauseAndInternalInlineReferencePreview(
    clause: ClauseFragment,
    internalReferences: InternalDocumentReferenceFragment[]
  ) {
    const types: FragmentType[] = [...EDITABLE_TEXT_FRAGMENT_TYPES, FragmentType.READONLY];

    clause.preview = clause.children
      .reduce((currentValue: string, child: Fragment) => {
        if (child.is(FragmentType.INPUT, FragmentType.REFERENCE_INPUT, FragmentType.UNIT_INPUT)) {
          currentValue += (child as AbstractInputFragment).getPreview();
        } else if (child.is(FragmentType.INTERNAL_INLINE_REFERENCE)) {
          const internalInlineReference = child as InternalInlineReferenceFragment;
          const internalReference: InternalDocumentReferenceFragment = internalReferences.find((internalRef) =>
            internalRef.id.equals(internalInlineReference.internalDocumentReferenceId)
          );

          currentValue += InternalInlineReferenceFragmentComponent.generateReferenceString(internalReference);

          if (internalReference.targetDocumentType !== TargetDocumentType.SAME_DOCUMENT) {
            currentValue += ' [Ref ?.N]';
          }
        } else if (child.is(...types)) {
          currentValue += child.value;
        }

        return currentValue;
      }, '')
      .replace(/[\u200B]/g, '');
  }
}
