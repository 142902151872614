<button
  mat-button
  [matTooltip]="toolbarItem.tooltipText"
  matTooltipPosition="below"
  [matTooltipShowDelay]="tooltipDelay"
  [matMenuTriggerFor]="createTableMenu"
  #createTableTrigger="matMenuTrigger"
  (menuClosed)="handleMenuClose()"
  (menuOpened)="initialiseKeydownListener()"
  [disabled]="!enabled"
  [attr.blurOption]="BlurOption.INSERT_TABLE"
  [attr.aria-label]="toolbarItem.ariaLabel"
>
  <mat-icon *ngFor="let icon of toolbarItem.icons">{{ icon }}</mat-icon>
  <cars-alt-accessibility
    [altProperties]="altProperties"
    [richText]="true"
    (onSelect)="handleAltSelect()"
  ></cars-alt-accessibility>
</button>

<mat-menu #createTableMenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <table class="table-creator" id="table-creator-menu">
      <tr *ngFor="let row of sizeArray; let i = index">
        <td
          *ngFor="let col of sizeArray; let j = index"
          (mouseover)="updateMaxSelect(i, j, $event)"
          (mousedown)="onClick()"
        >
          <div class="fake-cell" [ngClass]="{'creator-hover': isHighlighted(i, j)}"></div>
        </td>
      </tr>
    </table>
  </ng-template>
</mat-menu>
