import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {StandardFormatType} from 'app/fragment/types/standard-format-type';
import {StandardFormatGroupConfig} from 'app/services/clause-group.service';
import {Subscription} from 'rxjs';
import {StandardFormatGroupModalService} from './standard-format-group-modal.service';

export interface StandardFormatGroupModalOutputData {
  cancelled: boolean;
  type: StandardFormatType;
}

interface StandardFormatGroupModalInputData {
  standardFormatGroupConfig: StandardFormatGroupConfig[];
}

@Component({
  selector: 'cars-standard-format-group-modal',
  templateUrl: './standard-format-group-modal.component.html',
  styleUrls: ['./standard-format-group-modal.component.scss'],
  providers: [StandardFormatGroupModalService],
})
export class StandardFormatGroupModalComponent implements OnInit, OnDestroy {
  public selectedType: StandardFormatType = null;
  public standardFormatGroupConfig: StandardFormatGroupConfig[] = [];

  private _subscriptions: Subscription[] = [];

  public static open(
    dialog: MatDialog,
    standardFormatGroupConfig: StandardFormatGroupConfig[]
  ): MatDialogRef<StandardFormatGroupModalComponent, StandardFormatGroupModalOutputData> {
    return dialog.open(StandardFormatGroupModalComponent, {
      ariaLabel: 'Add standard format dialog',
      disableClose: true,
      autoFocus: false,
      width: '70vw',
      height: '70vh',
      data: {
        standardFormatGroupConfig: standardFormatGroupConfig,
      },
    });
  }

  constructor(
    private _dialog: MatDialogRef<StandardFormatGroupModalComponent, StandardFormatGroupModalOutputData>,
    private _modalService: StandardFormatGroupModalService,
    @Inject(MAT_DIALOG_DATA) private _data: StandardFormatGroupModalInputData
  ) {}

  public ngOnInit(): void {
    this.standardFormatGroupConfig = this._data.standardFormatGroupConfig;
    this._subscriptions.push(
      this._modalService.onSelectedTypeChange((type: StandardFormatType) => (this.selectedType = type))
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Block event propagation when clicking on the modal to prevent window/selection changes and ensure the caret
   * doesn't jump on close.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  @HostListener('document:mousedown', ['$event'])
  public mouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  public cancel(): void {
    this._dialog.close({cancelled: true, type: null});
  }

  public confirm(): void {
    this._dialog.close({cancelled: false, type: this.selectedType});
  }
}
