import {DOCUMENT} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {
  ClauseFragment,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  Fragment,
  FragmentType,
  ListItemFragment,
} from 'app/fragment/types';
import {FragmentService} from 'app/services/fragment.service';
import {HttpStatus} from 'app/utils/http-status';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';

@Component({
  selector: 'cars-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  private _dom: Document;
  private _failedIds: UUID[] = [];
  public clauses: ClauseFragment[] = [];
  public errorResponse: HttpErrorResponse;
  public tooltipDelay: number = environment.tooltipDelay;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    private _fragmentService: FragmentService,
    @Inject(DOCUMENT) dom: Document,
    @Inject(MAT_DIALOG_DATA)
    public data: {error: HttpErrorResponse; failedIds: UUID[]}
  ) {
    this._failedIds = data.failedIds || [];
    this.errorResponse = data.error;
    this._dom = dom;
  }

  public ngOnInit(): void {
    Logger.analytics('error-dialog-opened');
    this._getClauses();
  }

  /**
   * Fills this.clauses with copies of all the clauses that contain fragments that have not been properly saved.
   */
  private _getClauses(): void {
    this._failedIds.forEach((id: UUID) => {
      const fragment: Fragment = this._fragmentService.find(id);
      const newClause: ClauseFragment = fragment
        ? (fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment)
        : null;
      if (
        newClause &&
        !this.clauses.find((clause: ClauseFragment) => {
          return clause.equals(newClause);
        })
      ) {
        const clause: ClauseFragment = this._copyClauseTree(newClause)[0] as ClauseFragment;
        this.clauses.push(clause);
      }
    });
    this.clauses.sort((a: ClauseFragment, b: ClauseFragment) => {
      return this._fragmentService.find(a.id).index() - this._fragmentService.find(b.id).index();
    });
  }

  /**
   * Returns a copy of the given clause subtree.
   *
   * @param clause {ClauseFragment} The clause to be copied.
   */
  private _copyClauseTree(clause: ClauseFragment): Fragment[] {
    const json: any[] = [];
    clause.iterateDown(null, null, (fragment: Fragment) => {
      json.push(fragment.serialise());
    });
    return FragmentMapper.deserialiseAndUnflatten(json);
  }

  /**
   * Getter for the error message that should be displayed.
   *
   * @returns {string} Error message
   */
  public get errorMessage(): string {
    let error: string = 'An error has occured.';
    if (this.errorResponse) {
      switch (this.errorResponse.status) {
        case HttpStatus.FORBIDDEN:
          {
            error = 'The clause you have tried to edit is locked by another user.';
          }
          break;
        default: {
          error = this.errorResponse.error.message;
        }
      }
    }
    return error;
  }

  /**
   * Copies all of the clause text from the clauses in this.clauses.
   * It does not copy any captioned fragments (including inline equations and references), but does copy lists.
   */
  public copyAndRefresh(): void {
    let value: string = '';
    let textarea: HTMLTextAreaElement = null;

    value = this._getCopyableText();

    textarea = this._dom.createElement('textarea');
    textarea.style.height = '0px';
    textarea.style.left = '-100px';
    textarea.style.opacity = '0';
    textarea.style.position = 'fixed';
    textarea.style.top = '-100px';
    textarea.style.width = '0px';
    this._dom.body.appendChild(textarea);

    textarea.value = value;
    textarea.select();

    this._dom.execCommand('copy');

    if (textarea) {
      textarea.remove();
    }

    location.reload();
  }

  /**
   * Returns the copyabled text from all the clauses in this.clauses.
   * It ignores captioned fragments (including inline equations and references)
   */
  private _getCopyableText(): string {
    let copiedText: string = '';
    this.clauses.forEach((clause: ClauseFragment, index: number) => {
      copiedText += '1. ';
      clause.children.forEach((child: Fragment) => {
        if (child.is(...EDITABLE_TEXT_FRAGMENT_TYPES)) {
          copiedText += child.value;
        } else if (child.is(FragmentType.LIST)) {
          child.children.forEach((item: ListItemFragment) => {
            let itemString: string = '';
            item.children.forEach((t: Fragment) => {
              if (!t.is(FragmentType.EQUATION)) {
                itemString += t.value;
              }
            });
            if (itemString !== '') {
              copiedText += '\n* ' + itemString;
            }
          });
        }
      });
      copiedText += '\n';
    });

    return copiedText;
  }
}
