export class DisplayReferenceUtils {
  public static getSeparatorForGroupedReferences(index: number, numOfClauses): string {
    return this.getSeparator(', and ', index, numOfClauses);
  }

  public static getSeparatorForReferenceInputChildren(
    conjunction: string,
    index: number,
    numOfClauses: number
  ): string {
    if (numOfClauses > 2) {
      conjunction = ',' + conjunction;
    }
    return this.getSeparator(conjunction, index, numOfClauses);
  }

  private static getSeparator(conjunction: string, index: number, numOfClauses: number): string {
    return index === numOfClauses - 2 ? conjunction : ', ';
  }
}
