import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EmailContent} from 'app/email/email-content';
import {BaseService} from 'app/services/base.service';
import {User} from 'app/user/user';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';
import {Log} from '../error-handling/services/logger/log';
import {Logger} from '../error-handling/services/logger/logger.service';
import {UserService} from './user/user.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService extends BaseService {
  private static readonly EMAIL_ENDPOINT: string = `${environment.apiHost}/email`;

  constructor(protected _snackbar: MatSnackBar, private _http: HttpClient, private _userService: UserService) {
    super(_snackbar);
  }

  /**
   * Sends feedback to the web-service.
   *
   * @param summary     {string}          Summary text
   * @param description {string}          Description text
   * @returns           {Promise<number>} Promise resolving to the HTTP status code
   */
  public sendFeedback(summary: string, description: string, message?: string): Promise<void> {
    message = message || 'Sorry, could not send feedback';

    const body: string = this._parseFeedbackTemplate(description);
    const emailContent: EmailContent = EmailContent.html(summary, body);
    const obs: Observable<HttpResponse<any>> = this._http.post<any>(
      `${EmailService.EMAIL_ENDPOINT}/feedback`,
      emailContent
    );

    return this._toPromise(obs, message, 'feedback-error').then(() => {});
  }

  /**
   * Creates a stringified feedback template.
   *
   * @param description {string} Description of error
   * @returns           {string} Stringified template
   */
  private _parseFeedbackTemplate(description: string): string {
    const user: User = this._userService.getUser();
    const location: string = window.location ? window.location.href : '';
    const logs: string = Logger.getLogs()
      .map((log: Log) => log.message)
      .join(`<br/>`)
      .slice(-2000);
    return `
      <p>${description}</p>

      <p><strong>Reporter:</strong> ${user.name}</p>

      <p><strong>Email:</strong> ${user.email}</p>

      <p><strong>Location:</strong> ${location}</p>

      <p><strong>Logs:</strong> ${logs}</p>
    `;
  }
}
