import {Component, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {EmailService} from 'app/services/email.service';

@Component({
  selector: 'cars-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent {
  public summary: string = '';
  public description: string = '';
  public loading: boolean = false;

  @ViewChild('feedbackForm') public feedbackForm: NgForm;

  constructor(private _emailService: EmailService, private _dialog: MatDialogRef<FeedbackDialogComponent>) {}

  /**
   * Sends feedback to support.
   */
  public sendFeedback(): void {
    this.loading = true;
    this._emailService
      .sendFeedback(this.summary, this.description)
      .catch(() => {
        this.loading = false;
        this.close();
      })
      .then(() => {
        this.loading = false;
        this.close();
      });
  }

  /**
   * Closes the dialog.
   */
  public close(): void {
    this._dialog.close();
  }
}
