import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {DebounceSearchModule} from '../debounce-search/debounce-search.module';
import {UnitListModule} from './unit-list/unit-list.module';
import {UnitSelectionComponent} from './unit-selection.component';

@NgModule({
  imports: [
    CommonModule,
    UnitListModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    DebounceSearchModule,
  ],
  declarations: [UnitSelectionComponent],
  exports: [UnitSelectionComponent],
})
export class UnitSelectionModule {}
