import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef} from '@angular/core';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {FragmentService} from '../../services/fragment.service';
import {SuperscriptFragment} from '../types';
import {TextFragmentComponent} from './text-fragment.component';

@Component({
  selector: 'cars-superscript-fragment',
  template: `<sup>{{ content.value }}</sup>`,
  styleUrls: ['../core/fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperscriptFragmentComponent extends TextFragmentComponent {
  public set content(value: SuperscriptFragment) {
    super.content = value;
  }

  public get content(): SuperscriptFragment {
    return super.content;
  }

  constructor(
    protected _fragmentService: FragmentService,
    protected _selectionOperationsService: SelectionOperationsService,
    protected _cd: ChangeDetectorRef,
    _elementRef: ElementRef
  ) {
    super(_fragmentService, _selectionOperationsService, _cd, _elementRef);
  }
}
