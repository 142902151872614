import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClauseChangeSummaryService} from 'app/clause-change-summary/clause-change-summary.service';
import {ClauseChangeSummaryRow} from 'app/clause-change-summary/types/change-summary-row';
import {
  BaseMetrics,
  DocumentMetrics,
  SectionMetrics,
} from 'app/document-overview/document-information/metrics/document-metrics';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {FragmentLink} from 'app/fragment/fragment-link/fragment-link';
import {
  ClauseFragment,
  ClauseType,
  DocumentFragment,
  DocumentReferenceFragment,
  Fragment,
  FragmentType,
  InlineReferenceFragment,
  InternalReferenceType,
  SectionFragment,
  SectionType,
} from 'app/fragment/types';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {BaseService} from 'app/services/base.service';
import {ClauseLinkService} from 'app/services/clause-link.service';
import {DiscussionsService} from 'app/services/discussions.service';
import {DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {InternalReferenceService} from 'app/services/internal-reference.service';
import {ReferenceService} from 'app/services/references/reference.service';
import {SearchableGlobalReference} from 'app/sidebar/references/searchable-global-reference';
import {
  ConfigurationService,
  DocumentMetricsConfigItem,
  DocumentMetricsConfigItemType,
} from 'app/suite-config/configuration.service';
import {HttpStatus} from 'app/utils/http-status';
import {Predicate} from 'app/utils/typedefs';
import {UUID} from 'app/utils/uuid';
import {ValidationError, ValidationSeverity} from 'app/validation/validation-rule';
import {ValidationService} from 'app/validation/validation.service';
import {environment} from 'environments/environment';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentMetricsService extends BaseService {
  private static readonly METRICS_USING_VALIDATION_ERRORS: ReadonlyArray<DocumentMetricsConfigItemType> = [
    DocumentMetricsConfigItemType.ERRORS,
    DocumentMetricsConfigItemType.WARNINGS,
    DocumentMetricsConfigItemType.NO_ALT_TEXT,
    DocumentMetricsConfigItemType.NO_CAPTIONS,
    DocumentMetricsConfigItemType.INTERNAL_SECTION_AND_WSR_REFERENCES_TARGET_FRAGMENT_DELETED,
    DocumentMetricsConfigItemType.INTERNAL_CLAUSE_REFERENCES_TARGET_FRAGMENT_DELETED,
    DocumentMetricsConfigItemType.WSR_MAPPING,
  ];

  private static readonly METRICS_USING_GLOBAL_REFERENCES: ReadonlyArray<DocumentMetricsConfigItemType> = [
    DocumentMetricsConfigItemType.ERRORS,
    DocumentMetricsConfigItemType.WITHDRAWN_REF_WITHOUT_YEAR_OF_ISSUE,
  ];

  private static readonly METRICS_USING_CLAUSE_CHANGES: ReadonlyArray<DocumentMetricsConfigItemType> = [
    DocumentMetricsConfigItemType.NO_BACKGROUNDS_ON_NORMATIVE_CLAUSE,
    DocumentMetricsConfigItemType.NO_BACKGROUNDS_ON_APPENDIX_CLAUSE,
  ];

  /**
   * The title string for validation errors of images without alternative text
   */
  private static readonly NO_ALTERNATIVE_TEXT_VALIDATION_ERROR_TITLE: string =
    'Alternative text has not been provided for a figure';

  constructor(
    private _documentService: DocumentService,
    private _validationService: ValidationService,
    private _discussionService: DiscussionsService,
    private _fragmentService: FragmentService,
    private _referenceService: ReferenceService,
    private _configurationService: ConfigurationService,
    private _clauseLinkService: ClauseLinkService,
    private _internalReferenceService: InternalReferenceService,
    private _clauseChangeSummaryService: ClauseChangeSummaryService,
    private _http: HttpClient,
    protected _snackbar: MatSnackBar
  ) {
    super(_snackbar);
  }

  /**
   * Fetches the latest DocumentMetrics for the given documentId, returns null if no metrics have been generated
   * previously.
   */
  public getLatest(documentId: UUID): Promise<DocumentMetrics> {
    return this._http
      .get(`${environment.apiHost}/documentMetrics/search/findLatestByDocumentId`, {
        params: {documentId: documentId.value},
      })
      .pipe(
        map((json: any) => DocumentMetrics.deserialise(json)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatus.NOT_FOUND) {
            return of(null);
          } else {
            Logger.error('metrics-error', 'Failed to load latest metric', error);
            return throwError(error);
          }
        })
      )
      .toPromise();
  }

  /**
   * Calculates the document metrics for the given documentId, saves to the database and returns a promise resolving
   * to the object. Uses async for readability by reducing nested Promise::then blocks. ValidationErrors and
   * GlobalReferences are fetched initially as they are used by multiple calculation methods.
   * This method is only called for the live document.
   */
  public async calculate(documentId: UUID): Promise<DocumentMetrics> {
    const document: DocumentFragment = await this._documentService.load(documentId, {projection: 'FULL_TREE'}, true);
    const metricsConfig: DocumentMetricsConfigItem[] =
      await this._configurationService.getDocumentMetricsConfigurationForSuite(document.suite);

    const validationErrors: Record<string, ValidationError[]> = this._getValidationErrorsFromService(
      document,
      metricsConfig
    );

    const clauseChangeSummary: ClauseChangeSummaryRow[] = await this._getChangedClausesSummary(document, metricsConfig);

    const globalReferences: SearchableGlobalReference[] = await this._getGlobalReferences(document, metricsConfig);

    return this._mapConfigItemsToSectionMetrics(
      document,
      metricsConfig,
      validationErrors,
      globalReferences,
      clauseChangeSummary
    )
      .then((sectionMetricsMaps: Record<string, SectionMetrics>[]) => {
        return this._combineSectionMetricsAndGenerateDocumentMetrics(document, sectionMetricsMaps);
      })
      .catch((error: any) => {
        return this.handleError(error);
      });
  }

  /**
   * Calculates the list of validation errors for each section of the document, only if required by any config item.
   * Note this does not calculate the deleted and withdrawn reference errors as the validation service uses the inline
   * reference deleted/withrawn without year properties but these are not persisted and are simply calculated on the
   * fly in the InlineReferenceFragmentComponent.
   */
  private _getValidationErrorsFromService(
    document: DocumentFragment,
    metricsConfig: DocumentMetricsConfigItem[]
  ): Record<string, ValidationError[]> {
    if (!metricsConfig.some((item) => DocumentMetricsService.METRICS_USING_VALIDATION_ERRORS.includes(item.key))) {
      return {};
    }

    return this._getSectionsToCheck(document).reduce(
      (validationErrorMap: Record<string, ValidationError[]>, section: SectionFragment) => {
        const errors = this._validationService.validateSection(section, ValidationSeverity.WARNING);
        validationErrorMap[section.id.value] = errors;
        return validationErrorMap;
      },
      {}
    );
  }

  /**
   * Gets the clause change summary for the document.
   */
  private _getChangedClausesSummary(
    document: DocumentFragment,
    metricsConfig: DocumentMetricsConfigItem[]
  ): Promise<ClauseChangeSummaryRow[]> {
    if (!metricsConfig.some((item) => DocumentMetricsService.METRICS_USING_CLAUSE_CHANGES.includes(item.key))) {
      return Promise.resolve([]);
    }

    return this._clauseChangeSummaryService.getChangedClausesSummary(document.id, null).toPromise();
  }

  /**
   * Gets the list of all global references for a document, only if required to calculate the metric for any config
   * item.
   */
  private _getGlobalReferences(
    document: DocumentFragment,
    metricsConfig: DocumentMetricsConfigItem[]
  ): Promise<SearchableGlobalReference[]> {
    if (!metricsConfig.some((item) => DocumentMetricsService.METRICS_USING_GLOBAL_REFERENCES.includes(item.key))) {
      return Promise.resolve([]);
    }

    return this._referenceService.fetchAndCacheAllSearchableGlobalReferencesForDocument(document.id.value, null);
  }

  /**
   * Maps an array of document metrics config items to the corresponding maps of sectionId to SectionMetrics, and
   * returns a Promise of the array of these maps. Wraps the switch statement in a try catch as otherwise errors within
   * synchronous methods do not cause Promise.all to resolve/reject.
   */
  private _mapConfigItemsToSectionMetrics(
    document: DocumentFragment,
    metricsConfig: DocumentMetricsConfigItem[],
    validationErrors: Record<string, ValidationError[]>,
    globalReferences: SearchableGlobalReference[],
    clauseChangeSummary: ClauseChangeSummaryRow[]
  ): Promise<Record<string, SectionMetrics>[]> {
    return Promise.all(
      metricsConfig.map((item: DocumentMetricsConfigItem) => {
        try {
          switch (item.key) {
            case DocumentMetricsConfigItemType.ERRORS:
              return this._calculateErrors(document, validationErrors, globalReferences);
            case DocumentMetricsConfigItemType.WARNINGS:
              return this._calculateWarnings(validationErrors);
            case DocumentMetricsConfigItemType.NO_BACKGROUNDS_ON_NORMATIVE_CLAUSE:
              return this._calculateEmptyBackgroundsOnNormativeClause(document, clauseChangeSummary);
            case DocumentMetricsConfigItemType.NO_BACKGROUNDS_ON_APPENDIX_CLAUSE:
              return this._calculateEmptyBackgroundsOnAppendixClause(document, clauseChangeSummary);
            case DocumentMetricsConfigItemType.NO_ALT_TEXT:
              return this._calculateNoAlternativeText(validationErrors);
            case DocumentMetricsConfigItemType.NO_CAPTIONS:
              return this._calculateNoCaptions(validationErrors);
            case DocumentMetricsConfigItemType.UNRESOLVED_DISCUSSIONS:
              return this._calculateUnresolvedDiscussions(document);
            case DocumentMetricsConfigItemType.WITHDRAWN_REF_WITHOUT_YEAR_OF_ISSUE:
              return this._calculateWithdrawnWithoutYearOfIssue(document, globalReferences);
            case DocumentMetricsConfigItemType.INVALID_CLAUSE_LINKS:
              return this._calculateInvalidClauseLinks(document);
            case DocumentMetricsConfigItemType.INTERNAL_SECTION_AND_WSR_REFERENCES_TO_UPDATE:
              return this._calculateInternalReferencesToUpdate(document, [
                InternalReferenceType.SECTION_REFERENCE,
                InternalReferenceType.WSR_REFERENCE,
              ]);
            case DocumentMetricsConfigItemType.INTERNAL_SECTION_AND_WSR_REFERENCES_TARGET_FRAGMENT_DELETED:
              return this._calculateinternalReferencesTargetFragmentDeleted(
                validationErrors,
                DocumentMetricsConfigItemType.INTERNAL_SECTION_AND_WSR_REFERENCES_TARGET_FRAGMENT_DELETED
              );
            case DocumentMetricsConfigItemType.INTERNAL_CLAUSE_REFERENCES_TO_UPDATE:
              return this._calculateInternalReferencesToUpdate(document, [InternalReferenceType.CLAUSE_REFERENCE]);
            case DocumentMetricsConfigItemType.INTERNAL_CLAUSE_REFERENCES_TARGET_FRAGMENT_DELETED:
              return this._calculateinternalReferencesTargetFragmentDeleted(
                validationErrors,
                DocumentMetricsConfigItemType.INTERNAL_CLAUSE_REFERENCES_TARGET_FRAGMENT_DELETED
              );
            case DocumentMetricsConfigItemType.WSR_MAPPING:
              return this._calculateWsrMapping(document);
            default:
              throw new TypeError('Unexpected document metric key');
          }
        } catch (error) {
          return Promise.reject(error);
        }
      })
    );
  }

  /**
   * Combines the individual section metrics for each metric type into a single overall SectionMetrics array and uses
   * this to construct and save the DocumentMetrics.
   */
  private _combineSectionMetricsAndGenerateDocumentMetrics(
    document: DocumentFragment,
    sectionMetricsMaps: Record<string, SectionMetrics>[]
  ): DocumentMetrics {
    const combinedSectionMetricsMap: Record<string, SectionMetrics> = sectionMetricsMaps.reduce(
      (
        previousCombinedMetricsMap: Record<string, SectionMetrics>,
        calculatedSectionMetrics: Record<string, SectionMetrics>
      ) => {
        return this._combineSectionMetricsMaps(previousCombinedMetricsMap, calculatedSectionMetrics);
      },
      {}
    );

    const documentMetrics: DocumentMetrics = DocumentMetrics.emptyMetrics(null, document.id, Date.now());

    this._getSectionsToCheck(document).forEach((section: SectionFragment) => {
      const sectionMetrics: SectionMetrics = combinedSectionMetricsMap[section.id.value];
      if (!!sectionMetrics && !sectionMetrics.isHealthy()) {
        documentMetrics.sectionMetrics.push(sectionMetrics);

        this._mergeMetrics(documentMetrics, sectionMetrics);
      }
    });

    this._persistDocumentMetrics(documentMetrics);
    return documentMetrics;
  }

  /**
   * Combines the metric counts of two maps of sectionId to SectionMetrics.
   */
  private _combineSectionMetricsMaps(
    metrics1: Record<string, SectionMetrics>,
    metrics2: Record<string, SectionMetrics>
  ): Record<string, SectionMetrics> {
    return Object.keys(metrics2).reduce((previous, current) => {
      if (Object.keys(previous).includes(current)) {
        const existingMetrics: SectionMetrics = previous[current];

        this._mergeMetrics(existingMetrics, metrics2[current]);
      } else {
        previous[current] = metrics2[current];
      }

      return previous;
    }, metrics1);
  }

  /**
   * Merges the metrics values from one metrics into another metrics object. Edits the values on the first metric
   * object in place.
   *
   * @param metric1 The metrics object to merge into.
   * @param metric2 The metrics values to copy to the other object.
   */
  private _mergeMetrics(metric1: BaseMetrics, metric2: BaseMetrics): void {
    Object.keys(DocumentMetricsConfigItemType).forEach((metricType: DocumentMetricsConfigItemType) => {
      metric1.setValueByConfigType(
        metricType,
        metric1.getValueByConfigType(metricType) + metric2.getValueByConfigType(metricType)
      );
    });
  }

  /**
   * Stores the calculated metric to the database.
   */
  private _persistDocumentMetrics(metrics: DocumentMetrics): Promise<void> {
    return this._http
      .post(`${environment.apiHost}/documentMetrics`, metrics.serialise(), {
        headers: this._httpHeaders,
      })
      .pipe(
        map(() => {}),
        catchError((e) => {
          Logger.error('metrics-error', 'Failed to persist metrics', e);
          return throwError(null);
        })
      )
      .toPromise();
  }

  /**
   * Calculates the number of validation errors with severity ERROR, and includes deleted references. Does not include
   * withdrawn references without year of issue or without alternative text.
   */
  private _calculateErrors(
    document: DocumentFragment,
    validationErrors: Record<string, ValidationError[]>,
    globalReferences: SearchableGlobalReference[]
  ): Record<string, SectionMetrics> {
    const deletedReferences: Record<string, SectionMetrics> = this._calculateDeletedReferences(
      document,
      globalReferences
    );
    const validationErrorsMetrics: Record<string, SectionMetrics> = this._calculateValidationErrorCounts(
      validationErrors,
      DocumentMetricsConfigItemType.ERRORS,
      (error: ValidationError) =>
        error.severity === ValidationSeverity.ERROR &&
        error.title !== DocumentMetricsService.NO_ALTERNATIVE_TEXT_VALIDATION_ERROR_TITLE
    );

    return this._combineSectionMetricsMaps(validationErrorsMetrics, deletedReferences);
  }

  /**
   * Calculates the number of validation errors with severity WARNING.
   */
  private _calculateWarnings(validationErrors: Record<string, ValidationError[]>): Record<string, SectionMetrics> {
    return this._calculateValidationErrorCounts(
      validationErrors,
      DocumentMetricsConfigItemType.WARNINGS,
      (error: ValidationError) => error.severity === ValidationSeverity.WARNING
    );
  }

  /**
   * Calulates the number of images that don't have alternative text.
   *
   * @param validationErrors The validation errors.
   */
  private _calculateNoAlternativeText(
    validationErrors: Record<string, ValidationError[]>
  ): Record<string, SectionMetrics> {
    return this._calculateValidationErrorCounts(
      validationErrors,
      DocumentMetricsConfigItemType.NO_ALT_TEXT,
      (error: ValidationError) => error.title === DocumentMetricsService.NO_ALTERNATIVE_TEXT_VALIDATION_ERROR_TITLE
    );
  }

  /**
   * Calculates the number of captioned fragments without a caption, note this is a subset of the error count.
   */
  private _calculateNoCaptions(validationErrors: Record<string, ValidationError[]>): Record<string, SectionMetrics> {
    return this._calculateValidationErrorCounts(
      validationErrors,
      DocumentMetricsConfigItemType.NO_CAPTIONS,
      (error: ValidationError) =>
        error.title === 'Table needs a caption' ||
        error.title === 'Equation needs a caption' ||
        error.title === 'Figure needs a caption'
    );
  }

  private _calculateEmptyBackgroundsOnNormativeClause(
    document: DocumentFragment,
    clauseChangeSummaryRows: ClauseChangeSummaryRow[]
  ): Record<string, SectionMetrics> {
    return this._calculateEmptyBackgrounds(
      document,
      clauseChangeSummaryRows,
      DocumentMetricsConfigItemType.NO_BACKGROUNDS_ON_NORMATIVE_CLAUSE,
      [SectionType.NORMATIVE],
      [ClauseType.REQUIREMENT, ClauseType.ADVICE, ClauseType.NOTE]
    );
  }

  private _calculateEmptyBackgroundsOnAppendixClause(
    document: DocumentFragment,
    clauseChangeSummaryRows: ClauseChangeSummaryRow[]
  ): Record<string, SectionMetrics> {
    return this._calculateEmptyBackgrounds(
      document,
      clauseChangeSummaryRows,
      DocumentMetricsConfigItemType.NO_BACKGROUNDS_ON_APPENDIX_CLAUSE,
      [SectionType.APPENDIX],
      [ClauseType.NORMAL]
    );
  }

  /**
   * Calculates the number of clauses without any background commentary where the clause has been
   * updated or created since the last published version.
   */
  private _calculateEmptyBackgrounds(
    document: DocumentFragment,
    clauseChangeSummaryRows: ClauseChangeSummaryRow[],
    metricType: DocumentMetricsConfigItemType,
    sectionTypes: SectionType[],
    clauseTypes: ClauseType[]
  ): Record<string, SectionMetrics> {
    return this._getSectionsToCheck(document)
      .filter((section) => sectionTypes.includes(section.sectionType))
      .reduce((previousMetricsMap: Record<string, SectionMetrics>, section: SectionFragment) => {
        const sectionMetrics: SectionMetrics = SectionMetrics.emptyMetrics(section.id);

        sectionMetrics.setValueByConfigType(
          metricType,
          section
            .getClauses()
            .filter(
              (clause: ClauseFragment) =>
                clause.background.length === 0 &&
                clause.isClauseOfType(...clauseTypes) &&
                clauseChangeSummaryRows.some((row: ClauseChangeSummaryRow) => row.newFragment?.id.equals(clause.id)) &&
                !clause.isUnmodifiableClause
            ).length
        );

        previousMetricsMap[section.id.value] = sectionMetrics;

        return previousMetricsMap;
      }, {});
  }

  /**
   * Calculates the number of unresolved discussions.
   */
  private _calculateUnresolvedDiscussions(document: DocumentFragment): Promise<Record<string, SectionMetrics>> {
    return this._discussionService
      .getDiscussionCounts(document.id, false)
      .then((unresolvedDiscussions: Record<string, number>) => {
        return this._getSectionsToCheck(document).reduce(
          (previousMetricsMap: Record<string, SectionMetrics>, section: SectionFragment) => {
            const sectionMetrics: SectionMetrics = SectionMetrics.emptyMetrics(section.id);
            sectionMetrics.unresolvedDiscussions = unresolvedDiscussions[section.id.value] || 0;

            previousMetricsMap[section.id.value] = sectionMetrics;

            return previousMetricsMap;
          },
          {}
        );
      })
      .catch((e) => this.handleError(e));
  }

  /**
   * Calculates the number of deleted inline references.
   */
  private _calculateDeletedReferences(
    document: DocumentFragment,
    globalReferences: SearchableGlobalReference[]
  ): Record<string, SectionMetrics> {
    return this._calculateGlobalReferenceCounts(
      document,
      globalReferences,
      DocumentMetricsConfigItemType.ERRORS,
      (inlineRef: InlineReferenceFragment, docRef: DocumentReferenceFragment, globalRef: SearchableGlobalReference) =>
        globalRef.deleted
    );
  }

  /**
   * Calculates the number of withdrawn references without a year of issue.
   */
  private _calculateWithdrawnWithoutYearOfIssue(
    document: DocumentFragment,
    globalReferences: SearchableGlobalReference[]
  ): Record<string, SectionMetrics> {
    return this._calculateGlobalReferenceCounts(
      document,
      globalReferences,
      DocumentMetricsConfigItemType.WITHDRAWN_REF_WITHOUT_YEAR_OF_ISSUE,
      (inlineRef: InlineReferenceFragment, docRef: DocumentReferenceFragment, globalRef: SearchableGlobalReference) =>
        globalRef.withdrawn && !docRef.release
    );
  }

  /**
   * Calculates the number of clauses with an invalid clause link state.
   */
  private _calculateInvalidClauseLinks(document: DocumentFragment): Promise<Record<string, SectionMetrics>> {
    return this._clauseLinkService.getAllFromDocumentId(document.id).then((clauseLinksForDocument: FragmentLink[]) => {
      // Group Links by clause id
      const byClauseId: Map<string, FragmentLink[]> = clauseLinksForDocument.reduce((store, item) => {
        const key = item.fragmentId.value;
        if (!store.has(key)) {
          store.set(key, [item]);
        } else {
          store.get(key).push(item);
        }
        return store;
      }, new Map<string, FragmentLink[]>());

      return this._getSectionsToCheck(document)
        .map((section: SectionFragment) => {
          const metricsForSection = SectionMetrics.emptyMetrics(section.id);
          metricsForSection.invalidClauseLinks = section
            .getClauses()
            .filter(
              (clause) =>
                !clause.isUnmodifiableClause &&
                !this._clauseLinkService.calculateValidLinksForClause(clause, byClauseId.get(clause.id.value) ?? [])
            ).length;
          return metricsForSection;
        })
        .reduce((previousMetricsMap: Record<string, SectionMetrics>, currentSectionMetrics: SectionMetrics) => {
          previousMetricsMap[currentSectionMetrics.sectionId.value] = currentSectionMetrics;
          return previousMetricsMap;
        }, {});
    });
  }

  /**
   * Calculates the number of documents targetted by internal section references that need to be updated.
   */
  private _calculateInternalReferencesToUpdate(
    document: DocumentFragment,
    internalReferenceTypes: InternalReferenceType[]
  ): Promise<Record<string, SectionMetrics>> {
    return this._internalReferenceService
      .getReferencesToUpdateGroupedByTargetDocument(document.id, internalReferenceTypes)
      .then((referencesToUpdate: Record<string, InternalDocumentReferenceFragment[]>) => {
        const normativeReferenceSection = document.getNormReferenceSection();
        const normativeReferenceSectionMetrics: SectionMetrics = SectionMetrics.emptyMetrics(
          normativeReferenceSection.id
        );
        internalReferenceTypes.includes(InternalReferenceType.CLAUSE_REFERENCE)
          ? (normativeReferenceSectionMetrics.internalClauseReferencesToUpdate = Object.keys(referencesToUpdate).length)
          : (normativeReferenceSectionMetrics.internalSectionAndWsrReferencesToUpdate =
              Object.keys(referencesToUpdate).length);

        return {
          [normativeReferenceSection.id.value]: normativeReferenceSectionMetrics,
        };
      });
  }

  /**
   * Calculates the number of internal references that target deleted sections. Note, this is a subset of the error count.
   */
  private _calculateinternalReferencesTargetFragmentDeleted(
    validationErrors: Record<string, ValidationError[]>,
    documentMetricsType: DocumentMetricsConfigItemType
  ): Record<string, SectionMetrics> {
    return this._calculateValidationErrorCounts(
      validationErrors,
      documentMetricsType,
      documentMetricsType === DocumentMetricsConfigItemType.INTERNAL_SECTION_AND_WSR_REFERENCES_TARGET_FRAGMENT_DELETED
        ? (error: ValidationError) => error.title === 'Target section has been deleted'
        : (error: ValidationError) => error.title === 'Target clause has been deleted'
    );
  }

  /**
   * Calculates the number of sections which do not have the correct wsr mapping.
   */
  private _calculateWsrMapping(document: DocumentFragment): Record<string, SectionMetrics> {
    return this._getSectionsToCheck(document)
      .filter(
        (section: SectionFragment) => !section.isSectionOfType(SectionType.REFERENCE_NORM, SectionType.REFERENCE_INFORM)
      )
      .reduce((previousMetricsMap: Record<string, SectionMetrics>, section: SectionFragment) => {
        const sectionMetrics: SectionMetrics = SectionMetrics.emptyMetrics(section.id);
        previousMetricsMap[section.id.value] = sectionMetrics;
        sectionMetrics.wsrMapping = this._sectionHasIncorrectWSRMapping(section) ? 1 : 0;
        return previousMetricsMap;
      }, {});
  }

  /**
   * Determine if a section has incorrect wsr mapping
   */
  private _sectionHasIncorrectWSRMapping(section: SectionFragment): boolean {
    return !section.subject || !section.topic || !section.wsrCode;
  }

  /**
   * Combines the observables on completion and stores the count of false results (invalid links) into the
   * invalidClauseLinks property of the section metrics.
   */
  private _combineClauseValidObservables(
    sectionId: UUID,
    clauseValidObservables: Observable<boolean>[]
  ): Observable<SectionMetrics> {
    return forkJoin(clauseValidObservables).pipe(
      map((clausesValid) => {
        const sectionMetrics: SectionMetrics = SectionMetrics.emptyMetrics(sectionId);

        sectionMetrics.invalidClauseLinks = clausesValid.filter((valid: boolean) => !valid).length;

        return sectionMetrics;
      })
    );
  }

  /**
   * Calculates the number of validation errors that match the given predicate in each section, and returns a
   * constructed map of SectionMetrics with the count stored in the corresponding metricType field. Note that the
   * validationErrors map only contains valid sections as this is filtered in _getValidationErrorsFromService.
   */
  private _calculateValidationErrorCounts(
    validationErrors: Record<string, ValidationError[]>,
    metricType: DocumentMetricsConfigItemType,
    errorPredicate: Predicate<ValidationError>
  ): Record<string, SectionMetrics> {
    return Object.keys(validationErrors).reduce(
      (previousMetricsMap: Record<string, SectionMetrics>, sectionId: string) => {
        const sectionMetrics: SectionMetrics = SectionMetrics.emptyMetrics(UUID.orThrow(sectionId));

        sectionMetrics.setValueByConfigType(metricType, validationErrors[sectionId].filter(errorPredicate).length);

        previousMetricsMap[sectionId] = sectionMetrics;

        return previousMetricsMap;
      },
      {}
    );
  }

  /**
   * Calculates the number of inline references that match the given predicate in each section, and returns a
   * constructed map of SectionMetrics with the count stored in the corresponding metricType field.
   */
  private _calculateGlobalReferenceCounts(
    document: DocumentFragment,
    globalReferences: SearchableGlobalReference[],
    metricType: DocumentMetricsConfigItemType,
    referencePredicate: (InlineReferenceFragment, DocumentReferenceFragment, SearchableGlobalReference) => boolean
  ): Record<string, SectionMetrics> {
    return this._getSectionsToCheck(document).reduce(
      (previousMetricsMap: Record<string, SectionMetrics>, section: SectionFragment) => {
        const inlineRefs: InlineReferenceFragment[] = this._extractInlineReferences(section);

        const matchingRefs: InlineReferenceFragment[] = inlineRefs.filter((inlineRef: InlineReferenceFragment) => {
          if (inlineRef.component === null) {
            const documentReference: DocumentReferenceFragment = this._fragmentService.find(
              inlineRef.documentReference
            ) as DocumentReferenceFragment;
            if (documentReference === null) {
              return this.handleError(null);
            }

            const globalRef: SearchableGlobalReference = globalReferences.find((ref) =>
              ref.globalReferenceId.equals(documentReference.globalReference)
            );
            return referencePredicate(inlineRef, documentReference, globalRef);
          }
        });

        const sectionMetrics: SectionMetrics = SectionMetrics.emptyMetrics(section.id);
        sectionMetrics.setValueByConfigType(metricType, matchingRefs.length);

        previousMetricsMap[section.id.value] = sectionMetrics;

        return previousMetricsMap;
      },
      {}
    );
  }

  /**
   * Convenience method for getting the list of section fragments that should be checked for calculating metrics.
   * Excludes deleted non-refernce sections and information sections.
   */
  private _getSectionsToCheck(document: DocumentFragment): SectionFragment[] {
    return document
      .getSections()
      .filter(
        (section: SectionFragment) =>
          section.isSectionOfType(SectionType.REFERENCE_NORM, SectionType.REFERENCE_INFORM) ||
          (!section.deleted && !section.isSectionOfType(SectionType.DOCUMENT_INFORMATION))
      );
  }

  private handleError(e: any): Promise<never> {
    Logger.error('metrics-error', 'Failed to generate document metrics', e);
    return Promise.reject(e);
  }

  /**
   * Helper function to recursively extract all inline references from a fragment and it's children.
   *
   * @param fragment  {Fragment}                  The fragment to extract from
   * @returns         {InlineReferenceFragment[]} The found inline referneces
   */
  private _extractInlineReferences(fragment: Fragment): InlineReferenceFragment[] {
    return this._extractFragmentsOfType(fragment, FragmentType.INLINE_REFERENCE) as InlineReferenceFragment[];
  }

  /**
   * Helper function to recursively extract all fragments of a given type from a fragment and it's children.
   *
   * @param fragment  {Fragment}   The fragment to extract from
   * @returns         {Fragment[]} The found fragments
   */
  private _extractFragmentsOfType(fragment: Fragment, type: FragmentType): Fragment[] {
    const results: Fragment[] = [];
    fragment.children.forEach((child: Fragment) => {
      if (child.is(type)) {
        results.push(child);
      } else {
        results.push(...this._extractFragmentsOfType(child, type));
      }
    });
    return results;
  }
}
