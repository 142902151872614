<button
  mat-button
  [attr.blurOption]="BlurOption.PREVENT_BLUR"
  [matTooltip]="toolbarItem.tooltipText"
  matTooltipPosition="below"
  [matTooltipShowDelay]="tooltipDelay"
  (click)="issueEvent()"
  [class.active]="active"
  [disabled]="!enabled"
  [attr.data-toolbarItemKey]="toolbarItem.itemKey"
  [attr.aria-label]="toolbarItem.ariaLabel"
>
  <mat-icon *ngFor="let icon of toolbarItem.icons">{{ icon }}</mat-icon
  ><cars-alt-accessibility
    [altProperties]="altProperties"
    [richText]="true"
    (onSelect)="this.issueEvent()"
  ></cars-alt-accessibility>
</button>
