import {Component, Input} from '@angular/core';
import {environment} from 'environments/environment';

export enum ResolvedIconType {
  COMMENT = 'COMMENT',
  DISCUSSION = 'DISCUSSION',
  REVIEW = 'REVIEW',
}

@Component({
  selector: 'cars-resolved-icon',
  templateUrl: './resolved-icon.component.html',
  styleUrls: ['./resolved-icon.component.scss'],
})
export class ResolvedIconComponent {
  private static readonly GET_TOOLTIP_TEXT: Readonly<
    Record<ResolvedIconType, (total: number, resolved: number) => string>
  > = {
    [ResolvedIconType.COMMENT]: (total: number, resolved: number) => {
      const resolvedString: string = total === resolved ? '' : 'not';
      return total === 1
        ? `The comment has ${resolvedString} been resolved`
        : `All comments have ${resolvedString} been resolved`;
    },
    [ResolvedIconType.DISCUSSION]: (total: number, resolved: number) => {
      const resolvedString: string = total === resolved ? '' : 'not';
      return total === 1
        ? `The discussion has ${resolvedString} been resolved`
        : `All discussions have ${resolvedString} been resolved`;
    },
    [ResolvedIconType.REVIEW]: (total: number, resolved: number) => {
      const resolvedString: string = total === resolved ? '' : 'not';
      return total === 1
        ? `The review has ${resolvedString} been completed`
        : `All reviews have ${resolvedString} been completed`;
    },
  };

  @Input() total: number;
  @Input() resolved: number;
  @Input() type: ResolvedIconType;
  @Input() tooltipPosition: string = 'right';

  public toolTipDelay: number = environment.tooltipDelay;

  constructor() {}

  public allResolved(): boolean {
    return this.total === this.resolved;
  }

  public getIcon(): string {
    return this.allResolved() ? 'check_circle' : 'cancel';
  }

  public getToolTipText(): string {
    return ResolvedIconComponent.GET_TOOLTIP_TEXT[this.type](this.total, this.resolved);
  }
}
