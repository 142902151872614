import {Logger} from 'app/error-handling/services/logger/logger.service';
import {UUID} from 'app/utils/uuid';

/**
 * A collection of diff generation and patch application utilities for JS objects.
 */
export class DiffUtils {
  /**
   * Recursively diff two objects and their properties, returning an object containing
   * fields that have changed from before to after.
   *
   * @param before {any}   The state before
   * @param after  {any}   The state after
   * @returns      {any}   An object with changes
   */
  public static diff(before: any, after: any): any {
    if (!before || !after || typeof before !== 'object' || typeof after !== 'object') {
      return {};
    }

    if (before instanceof Array && after instanceof Array) {
      return after;
    } else {
      const diff: any = {};
      const keys: string[] = DiffUtils._keysFrom(before, after);

      for (const key of keys) {
        const b: any = DiffUtils._orNull(before[key]);
        const a: any = DiffUtils._orNull(after[key]);

        if (typeof b === 'object' && typeof a === 'object') {
          if (b !== a) {
            if (a instanceof Array) {
              const arrayDiff = DiffUtils._diffArray(b, a);
              if (arrayDiff) {
                diff[key] = arrayDiff;
              }
            } else {
              const change: any = DiffUtils.diff(b, a);
              if (Object.keys(change).length > 0) {
                diff[key] = change;
              }
            }
          }
        } else if ((a !== undefined || b !== undefined) && b !== a) {
          diff[key] = a;
        }
      }
      return diff;
    }
  }

  /**
   * Recursively patch one object onto another.
   *
   * @param patch  {any}   The patch to apply
   * @param target {any}   The target object
   */
  public static patch(patch: any, target: any): void {
    if (!patch || !target || typeof patch !== 'object' || typeof target !== 'object') {
      return;
    }

    const keys: string[] = DiffUtils._keysFrom(patch);

    for (const key of keys) {
      const p: any = DiffUtils._orNull(patch[key]);
      const t: any = DiffUtils._orNull(target[key]);

      if (t instanceof Array) {
        // TODO: Check the ordering of arrays; right now we just replace the old array
        // with the new one.
        target[key] = p;
      } else if (t instanceof Map) {
        // similar approach to arrays we just replace the old Map with a new one.
        target[key] = new Map(Object.entries(patch[key]));
      } else if (p && t && t instanceof UUID && p instanceof UUID) {
        target[key] = p; // Don't recurse into UUIDS
      } else if (p && t && typeof p === 'object' && typeof t === 'object') {
        DiffUtils.patch(p, t);
      } else if (t !== p) {
        target[key] = p;
      }
    }
  }

  /**
   * Helper function to get all object key names from an array of objects, and return
   * the deduplicated set.  The resulting keys are not sorted.
   *
   * @param objects {any[]}      The objects
   * @returns       {string[]}   The keys
   */
  private static _keysFrom(...objects: any[]): string[] {
    const allKeys: string[] = objects.reduce((keys: string[], object: any) => {
      const isObj: boolean = object && typeof object === 'object';
      keys.push(...(isObj ? Object.keys(object) : []));
      return keys;
    }, []);

    return allKeys.filter((key: string, index: number, keys: string[]) => keys.indexOf(key) === index);
  }

  /**
   * Helper function to return the value or null if undefined.
   *
   * @param value {any}   The value
   * @returns     {any}   The value or null
   */
  private static _orNull(value: any): any {
    return value !== undefined ? value : null;
  }

  private static _diffArray(before: any, after: any[]) {
    if (before == null) {
      return after;
    } else if (before instanceof Array) {
      if (after.length === before.length && after.every((v, i) => v === before[i])) {
        return null;
      } else {
        return after;
      }
    } else {
      Logger.error('diff-error', `Attempt to diff ${before} and ${after} as arrays`);
    }
  }

  // Private constructor to prevent instantiations
  private constructor() {}
}
