import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ChangelogLink} from 'app/changelog/changelog-link';
import {ChangelogRange} from 'app/changelog/changelog-range';
import {ChangelogService} from 'app/changelog/changelog.service';
import {LinkBuilder, LinkBuilderState} from 'app/changelog/link-builder/link-builder';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {Div} from 'app/section-content/section-proxy/section-pad/canvas/div/divs';
import {DrawableRange} from 'app/section-content/section-proxy/section-pad/canvas/drawable-range';
import {Callback} from 'app/utils/typedefs';
import {AbstractHovertipComponent} from 'app/widgets/abstract-hovertip.component';
import {environment} from 'environments/environment';

export enum RangeMenuState {
  NORMAL, // The standard state of the range.
  DELETED, // If the range has been marked as deleted
  LINKING, // Once the link button has been clicked
}

@Component({
  selector: 'cars-range-menu',
  templateUrl: './range-menu.component.html',
  styleUrls: ['./range-menu.component.scss'],
})
export class RangeMenuComponent extends AbstractHovertipComponent implements OnInit, OnDestroy {
  public readonly tooltipDelay: number = environment.tooltipDelay;

  /** Reference to enum for template usage */
  public readonly RangeMenuState: typeof RangeMenuState = RangeMenuState;

  public state: RangeMenuState = RangeMenuState.NORMAL;

  public drawnRange: DrawableRange;

  public range: ChangelogRange;

  protected topOffset: number = -20;

  protected _timeout: number = 500;

  /** True if the link creation wizard dialog is open */
  public inCreationWizard: boolean = false;

  public loading: boolean = false;

  public linkCount: number = 0;

  /** Darken the range highlight on hover */
  private divRestyleOnEnter: EventListener = (event: MouseEvent) => {
    if (this.state !== RangeMenuState.DELETED) {
      this.drawnRange.renderedView.forEach((d: Div) => {
        if (d.element) {
          d.element.classList.add('canvas-hovered-changelog-range');
        }
      });
    }
  };

  /** Remove the hover effect on the range highlight */
  private divRestyleOnLeave: EventListener = (event: MouseEvent) => {
    if (this.state !== RangeMenuState.DELETED) {
      this.drawnRange.renderedView.forEach((d: Div) => {
        if (d.element) {
          d.element.classList.remove('canvas-hovered-changelog-range');
        }
      });
    }
  };

  private addToLinkOnClick: EventListener = (event: MouseEvent) => {
    if (this.linkBuilder.requiresAsSecondRange(this.range) && this.state !== RangeMenuState.DELETED) {
      this.linkBuilder.addRange(this.range);
      Logger.analytics('changelog-add-second-link', 'mouse');
    }
  };

  constructor(
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    private changelogService: ChangelogService,
    private linkBuilder: LinkBuilder,
    private snackbar: MatSnackBar
  ) {
    super(elementRef, renderer);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!(this.drawnRange.range instanceof ChangelogRange)) {
      console.error('Cannot attach a changelog menu to non-changelog highlight.');
      return;
    }

    this.inCreationWizard = this.linkBuilder.state === LinkBuilderState.FIRST_RANGE;
    this.range = this.drawnRange.range;
    this.state = this.range.deleted ? RangeMenuState.DELETED : (this.state = RangeMenuState.NORMAL);
    this.elementRef.nativeElement.addEventListener('mouseenter', this.divRestyleOnEnter);
    this.elementRef.nativeElement.addEventListener('mouseleave', this.divRestyleOnLeave);
    this.drawnRange.renderedView.forEach((div: Div) => {
      div.addEventListener('mouseenter', this.enterListener);
      div.addEventListener('mouseleave', this.leaveListener);
      div.addEventListener('mouseenter', this.divRestyleOnEnter);
      div.addEventListener('mouseleave', this.divRestyleOnLeave);
      div.addEventListener('click', this.addToLinkOnClick);
    });
    this.changelogService.findLinksByRangeId(this.range.id, this.range.published).then((links: ChangelogLink[]) => {
      this.linkCount = links.length;
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.elementRef.nativeElement.removeEventListener('mouseenter', this.divRestyleOnEnter);
    this.elementRef.nativeElement.removeEventListener('mouseleave', this.divRestyleOnLeave);
  }

  /**
   * Remove this range.  If the range has any attached links, this will
   * fail and show a snackbar.
   */
  public removeRange(): void {
    this.doIfNoLinks(
      () =>
        this.changelogService.deleteRange(this.range).then(() => {
          this.destroy.next();
          this.destroy.complete();
          this.loading = false;
        }),
      'This highlight cannot be removed, as it has been linked to other highlights.'
    );
  }

  /**
   * Mark this range as deleted.  If the range has any attached links, this will
   * fail and show a snackbar.
   */
  public markAsDeleted(): void {
    this.changeState(RangeMenuState.DELETED);
  }

  /**
   * Restore a range which has been marked as deleted.
   */
  public restore(): void {
    this.changeState(RangeMenuState.NORMAL);
  }

  /**
   * View all links attached to the range.
   */
  public viewLinks(): void {
    this.changelogService.viewExistingLinks(this.range);
  }

  /**
   * Open the deletion manager to view/edit the comment.
   */
  public editDeletionComment(): void {
    this.changelogService.triggerDeletionEvent(this.range);
  }

  /**
   * Called to enter linking mode.
   */
  public addLink(): void {
    this.changeState(RangeMenuState.LINKING);
  }

  /**
   * Overrides super.setPosition() since it is not positioned relative to the element which was
   * hovered, but relative to the fragment.
   */
  protected setPosition(): void {
    const anchorPosition = this.anchorElement.getBoundingClientRect();

    this.hoverTipRef.nativeElement.style.left = anchorPosition.right + this.leftOffset + 'px';
    this.hoverTipRef.nativeElement.style.top = anchorPosition.top + this.topOffset + 'px';
  }

  /**
   * Manages the state of the menu.
   *
   * @param newState {RangeMenuState} The state being transitioned to.
   */
  private changeState(newState: RangeMenuState): void {
    switch (newState) {
      case RangeMenuState.NORMAL:
        {
          this.range.deleted = false;
          this.changelogService.updateRange(this.range);
          this.state = RangeMenuState.NORMAL;
        }
        break;
      case RangeMenuState.LINKING:
        {
          this.linkBuilder.addRange(this.range);
          this.state = RangeMenuState.LINKING;
          this.destroy.next();
          this.destroy.complete();
        }
        break;
      case RangeMenuState.DELETED:
        {
          this.doIfNoLinks(() => {
            this.changelogService.triggerDeletionEvent(this.range);
            this.state = RangeMenuState.DELETED;
            this.destroy.next();
            this.destroy.complete();
            this.loading = false;
          }, 'This highlight cannot be marked as deleted, as it has been linked to other highlights.');
        }
        break;
    }
  }

  /**
   * Run the callback if the range is not linked to anything.
   *
   * @param callback {Callback<void>} The callback to run once the attached links have been fetched.
   * @param onFailureMessage {string} The snackbar message to display on failure.
   */
  private doIfNoLinks(callback: Callback<void>, onFailureMessage: string): void {
    this.loading = true;
    this.changelogService.findLinksByRangeId(this.range.id, this.range.published).then((links: ChangelogLink[]) => {
      if (!links.length) {
        callback(null);
      } else {
        this.snackbar.open(onFailureMessage, 'Dismiss', {duration: 5000});
        this.loading = false;
      }
    });
  }
}
