import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {AssociatedDocumentInformationFragment} from 'app/fragment/types/associated-document-information-fragment';
import {AssociatedDocumentType} from 'app/fragment/types/associated-document-information-type';

export interface AssociatedDocumentInformationModalOutputData {
  cancelled: boolean;
  title: string;
  associatedDocumentType: AssociatedDocumentType;
  remove: boolean;
  hasNationalVariations: boolean;
}

interface AssociatedDocumentInformationModalInputData {
  existingFragment: AssociatedDocumentInformationFragment;
  remove: boolean;
}

@Component({
  selector: 'cars-associated-document-information-modal',
  templateUrl: './associated-document-information-modal.component.html',
  styleUrls: ['./associated-document-information-modal.component.scss'],
})
export class AssociatedDocumentInformationModalComponent implements OnInit {
  @ViewChild('associatedDocumentInformationForm', {static: true}) public associatedDocumentInformationForm: NgForm;

  public title: string = '';
  public titleErrorMessage: string = '';
  public associatedDocumentType: AssociatedDocumentType = AssociatedDocumentType.CONSTRUCTOR_PROFORMA;
  public reference: string = '';

  public titleText: string = 'Add an associated document';
  public confirmButtonText: string = 'Add this associated document';
  public edit: boolean = false;
  public remove: boolean;
  public associatedDocumentTypes: AssociatedDocumentType[] = [AssociatedDocumentType.CONSTRUCTOR_PROFORMA];
  public hasNationalVariations: boolean;

  public static open(
    dialog: MatDialog,
    existingFragment?: AssociatedDocumentInformationFragment,
    remove?: boolean
  ): MatDialogRef<AssociatedDocumentInformationModalComponent, AssociatedDocumentInformationModalOutputData> {
    return dialog.open(AssociatedDocumentInformationModalComponent, {
      ariaLabel: 'Add an associated document',
      data: {
        existingFragment,
        remove,
      },
    });
  }

  constructor(
    private _dialogRef: MatDialogRef<
      AssociatedDocumentInformationModalComponent,
      AssociatedDocumentInformationModalOutputData
    >,
    @Inject(MAT_DIALOG_DATA) private _data: AssociatedDocumentInformationModalInputData
  ) {}

  public ngOnInit(): void {
    if (!!this._data.remove) {
      this.remove = true;
      this.titleText = 'Remove an associated document';
      this.confirmButtonText = 'Remove associated document';
      this.title = this._data.existingFragment.title;
      this.reference = this._data.existingFragment.reference;
      return;
    }

    this.remove = false;
    if (!!this._data.existingFragment) {
      this.edit = true;
      this.titleText = 'Edit an associated document';
      this.confirmButtonText = 'Save changes to this associated document ';
      this.title = this._data.existingFragment.title;
      this.associatedDocumentType = this._data.existingFragment.associatedDocumentType;
      this.hasNationalVariations = this._data.existingFragment.hasNationalVariations;
    }
  }

  public cancel(): void {
    this._dialogRef.close({
      cancelled: true,
      title: null,
      associatedDocumentType: null,
      remove: false,
      hasNationalVariations: false,
    });
  }

  public confirm(remove: boolean): void {
    this._dialogRef.close({
      cancelled: false,
      title: this.title,
      associatedDocumentType: this.associatedDocumentType,
      remove: !!remove,
      hasNationalVariations: this.hasNationalVariations === undefined ? false : this.hasNationalVariations,
    });
  }

  public onToggleChange(event: MatSlideToggleChange): void {
    this.hasNationalVariations = event.checked;
  }

  public getHasNationalVariationText(): string {
    return !!this.hasNationalVariations ? 'Has national variations' : 'Does not have national variations';
  }

  public getDisplayStringForType(type: AssociatedDocumentType): string {
    switch (type) {
      case AssociatedDocumentType.CONSTRUCTOR_PROFORMA: {
        return 'Constructor proforma';
      }
      default: {
        return '';
      }
    }
  }
}
