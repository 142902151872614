import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';

export enum ValidationFailure {
  DOCUMENT_INFORMATION = 'DOCUMENT_INFORMATION',
}

@Injectable({
  providedIn: 'root',
})
export class DocumentValidationService {
  private readonly onValidityCheckSubject: BehaviorSubject<ValidationFailure[]> = new BehaviorSubject([]);

  public docInfoFormGroup: BehaviorSubject<UntypedFormGroup> = new BehaviorSubject(null); // Document information form group

  constructor() {}

  public checkValidity(): boolean {
    const validationFailures: ValidationFailure[] = this.validate();
    this.onValidityCheckSubject.next(validationFailures);
    return validationFailures.length < 1;
  }

  public onValidityCheck(): Observable<ValidationFailure[]> {
    return this.onValidityCheckSubject.asObservable();
  }

  private validate(): ValidationFailure[] {
    const failures: Record<ValidationFailure, boolean> = {
      [ValidationFailure.DOCUMENT_INFORMATION]: !this.docInfoFormGroup.value || !this.docInfoFormGroup.value.valid,
    };

    return Object.keys(failures).filter((key: ValidationFailure): key is ValidationFailure => failures[key]);
  }
}
