/* eslint-disable */
// tslint is unable to recognize 'parent' from inherited class when used in the html

import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PadType} from 'app/element-ref.service';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {CopyPasteService} from 'app/services/copy-paste/copy-paste.service';
import {EquationService} from 'app/services/equation.service';
import {FragmentDeletionValidationService} from 'app/services/fragment-deletion-validation.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {UserService} from 'app/services/user/user.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DomService} from '../../services/dom.service';
import {UUID} from '../../utils/uuid';
import {ActionRequest} from '../action-request';
import {Caret} from '../caret';
import {ClauseComponent} from '../clause/clause.component';
import {CaptionedFragmentComponent} from '../core/captioned-fragment.component';
import {FragmentComponent} from '../core/fragment.component';
import {Key} from '../key';
import {TableFragment} from '../table/table-fragment';
import {EquationFragment, Fragment, TextFragment} from '../types';

@Component({
  selector: 'cars-equation-fragment',
  templateUrl: './equation-fragment.component.html',
  styleUrls: ['../core/fragment.component.scss', './equation-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true,
})
export class EquationFragmentComponent
  extends CaptionedFragmentComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  /**
   * URL to the equations help page
   */
  private static readonly HELP_URL: string = 'https://help.futuredmrb.co.uk/using-cars/equations/';

  @ViewChild('source') private _sourceRef: ElementRef;
  @ViewChild('render') private _renderRef: ElementRef;

  public readonly PadType: typeof PadType = PadType;
  public readonly id: string = `cars-equation-fragment-${UUID.random()}`;

  public showMarginIcons: boolean = false;
  public padType: PadType = PadType.MAIN_EDITABLE;

  public focused: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public invalidCharacter: boolean = false;

  private _requestAnimationFrameId: number = 0;
  private _rendered: boolean = false;
  private _subscription: Subscription = new Subscription();
  private _prevSource: string;

  public set content(value: EquationFragment) {
    super.content = value;
  }

  public get content(): EquationFragment {
    return super.content as EquationFragment;
  }

  constructor(
    protected _fragmentService: FragmentService,
    protected _cd: ChangeDetectorRef,
    private _equationService: EquationService,
    private _domService: DomService,
    protected _copyPasteService: CopyPasteService,
    protected _userService: UserService,
    protected _selectionOperationService: SelectionOperationsService,
    private _fragmentDeletionValidationService: FragmentDeletionValidationService,
    public _dialog: MatDialog,
    _lockService: LockService,
    elementRef: ElementRef
  ) {
    super(_cd, _fragmentService, _lockService, _copyPasteService, _userService, _selectionOperationService, elementRef);
    this.helpUrl = EquationFragmentComponent.HELP_URL;
  }

  /**
   * Initialise this component.
   */
  public ngOnInit(): void {
    super.ngOnInit(); // IMPORTANT

    this._prevSource = this.content.source.value;

    this._subscription.add(
      this._fragmentService.onUpdate(
        (fragment: Fragment) => {
          this._prevSource = this.content.source.value;
          this._render();
        },
        (f: Fragment) => f.equals(this.content) && this._prevSource !== this.content.source.value
      )
    );

    const clauseComponent: ClauseComponent = this.clause?.component as ClauseComponent;
    if (!!clauseComponent) {
      this.padType = clauseComponent.padType;
      this.showMarginIcons = clauseComponent.showMarginIcons;
    }
  }

  /**
   * @override
   */
  public markForCheck(): void {
    super.markForCheck();
    if (this.content.source) {
      this.content.source.markForCheck();
    }
  }

  /**
   * Clean up subscriptions within component.
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }

    if (this._requestAnimationFrameId) {
      cancelAnimationFrame(this._requestAnimationFrameId);
      this._requestAnimationFrameId = 0;
    }

    this.focused.complete();
  }

  /**
   * Render the equation once the view is ready.
   */
  public ngAfterViewInit(): void {
    this._sourceRef.nativeElement.style.display = 'none';
    this._render(false);
    super.ngAfterViewInit();
  }

  /**
   * Disable native controls (drag/resize handles). This is done here rather than in ngAfterViewInit because the table element hasn't
   * actually rendered at that point (it exists in Angular's virtual DOM, but won't show up using a document.getElement method).
   */
  public ngAfterViewChecked(): void {
    if (document.getElementById(this.id) && !this._rendered) {
      this._domService.disableNativeEditors();
      this._rendered = true;
    }
  }

  /**
   * Respond to a focus event on this fragment.
   */
  public focus(): void {
    if (!this.readOnly && this.clause.isAttached() && !this.userLockingClause) {
      this._sourceRef.nativeElement.style.display = this.content.inline ? 'inline' : 'block';
      this.focused.next(true);
    }
  }

  /**
   * Respond to a blur event on this fragment.
   */
  public blur(): void {
    if (this.content.source.length() < 1 && this.content.inline) {
      // Always inline equation which can't have discussions, no need to validate
      this._fragmentService.delete(this.content);
    } else {
      this._sourceRef.nativeElement.style.display = 'none';
      this._render();
    }
    this.focused.next(false);
  }

  public getFocusObservable(): Observable<boolean> {
    return this.focused.asObservable();
  }

  /**
   * Catch mousedown events on the rendered equation and show the source.
   *
   * @param event {MouseEvent}   The mousedown event
   */
  public onSourceMousedown(event: MouseEvent): void {
    event.preventDefault();
    this._fragmentService.setSelected(this.content);
    this.focus();
  }

  /**
   * Respond to a keydown event received at the cars-ce directive.
   *
   * @inheritdoc
   */
  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    if ((key.equalsUnmodified(Key.BACKSPACE) || key.equalsUnmodified(Key.DELETE)) && this.content.source.length() < 1) {
      let fragment: Fragment = null;
      let offset: number = 0;
      if (this.content.inline) {
        fragment = this.content.previousSibling();
        offset = fragment.length();
        // Always inline equation which can't have discussions, no need to validate
        this._fragmentService.delete(this.content);
      }
      return new ActionRequest(fragment, offset);
    } else if (key.equalsUnmodified(Key.BACKSPACE) || key.equalsUnmodified(Key.DELETE)) {
      // This is to prevent backspacing in the equation fragment being handled by the clause-list.
      return new ActionRequest(this.content.source, 0, -1);
    } else if (key.equalsUnmodified(Key.ENTER)) {
      const next: Fragment = this.content.nextLeaf();
      if (!!next) {
        this.blur();
      }
      return next ? new ActionRequest(next, -caret.offset) : new ActionRequest();
    } else {
      const action: ActionRequest = super.onKeydown(key, target, caret) as ActionRequest;
      if (action && action.hasEffect()) {
        this._render();
      }

      return action;
    }
  }

  /**
   * Render the MathJax equation owned by this fragment.
   *
   * @param fade {boolean}   True if the render should fade out and in
   */
  private _render(fade: boolean = true): void {
    const elem: HTMLElement = this._renderRef.nativeElement;
    if (fade) {
      elem.style.opacity = '0';
    }

    if (this._requestAnimationFrameId) {
      return;
    }

    this._requestAnimationFrameId = requestAnimationFrame(() => {
      this._requestAnimationFrameId = 0;
      const subscription = this._equationService
        .renderEquationFromSource(elem, this.content.source.value)
        .subscribe(() => {
          elem.style.opacity = '1';
          this.markForCheck();
          subscription.unsubscribe();
        });
    });

    this.invalidCharacter = this.content.source.length() > 0 && !!this.content.source.value.match(/[^ -~£]/);

    this.markForCheck();
  }

  /**
   * @inheritdoc
   */
  public async onDelete(): Promise<void> {
    if (await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithSubtrees(this.content)) {
      this._fragmentService.deleteValidatedFragments(this.content);
    }
  }

  /**
   * Determines whether the delete button should be visible.
   */
  public showButton(): boolean {
    return !this.readOnly && this.clause.component && !this.userLockingClause;
  }

  /**
   * Creates a variable table if the equation does not already have one, else deletes the variable table.
   */
  public onToggleVariableTable(): void {
    if (!!this.getTable()) {
      // Sub content of the equation, cannot raise discussions, no need to validate
      this._fragmentService.delete(this.getTable());
    } else {
      this.content.children.push(TableFragment.withSize(2, 2));
      this._setupVariableTable();
      this._fragmentService.create(this.getTable());
    }
  }

  /**
   * Returns the variable table if one exists, else return null.
   */
  public getTable(): TableFragment {
    return this.content.children.find((child: Fragment) => {
      return child instanceof TableFragment;
    }) as TableFragment;
  }

  /**
   * Sets up default values for the variable table.
   */
  public _setupVariableTable(): void {
    this.getTable().children[0].children[0].children.push(new TextFragment(null, 'where:'));
    this.getTable().children[1].children[0].children.push(new TextFragment(null, '[variable]'));
    this.getTable().children[1].children[1].children.push(new TextFragment(null, '[definition]'));
  }
}
