import {Serialisable} from '../../utils/serialisable';
import {UUID} from '../../utils/uuid';

/**
 * A class encapsulating a locked clause.
 *
 * @field clauseId {UUID}   The ID of the clause that's locked
 * @field userId   {UUID}   The ID of the owning user
 */
export class Lock implements Serialisable {
  /**
   * Deserialise a lock object from JSON.
   *
   * @param json {any}    The JSON to deserialise
   * @returns    {Lock}   The resulting Lock object
   */
  public static deserialise(json: any, locking: boolean): Lock {
    return new Lock(UUID.orThrow(json.clauseId), UUID.orNull(json.userId), locking);
  }

  constructor(public clauseId: UUID, public userId: UUID, public locking: boolean) {
    this.clauseId = clauseId;
    this.userId = userId;
    this.locking = locking;
  }

  /**
   * @inheritdoc
   */
  public serialise(): any {
    return {
      clauseId: this.clauseId ? this.clauseId.serialise() : null,
      userId: this.userId ? this.userId.serialise() : null,
    };
  }
}
