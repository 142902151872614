import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

/* carsRequiredNoWhitespace*/
/**
 * Validator to ensure that required fields do not just contain spaces.
 * <input name="name" carsRequiredNonWhitespace required />
 */
@Directive({
  selector: '[carsRequiredNonWhitespace]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequiredNonWhitespaceDirective,
      multi: true,
    },
  ],
})
export class RequiredNonWhitespaceDirective implements Validator {
  validate(control: AbstractControl): {[key: string]: any} {
    if (!control) {
      return {carsRequiredNonWhitespace: null};
    }

    if (control.value) {
      if (control.value.constructor === Number) {
        return null; // Type is a number so cannot contain spaces
      } else if (control.value.constructor === String) {
        const value = control.value.trim();
        return value.length ? null : {carsRequiredNonWhitespace: control.value};
      }
      return null; // Not a supported type
    }

    return {carsRequiredNonWhitespace: control.value};
  }
}
