import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {ResizeService} from './resize.service';

export enum CarsAccessibilityItem {
  UNDO = 'undo',
  REDO = 'redo',
  CHANGE_CLAUSE_TYPE = 'change_clause_type',
  STANDARD_FORMAT_REQUIREMENT = 'standard_format_requirement',
  SPECIFIER_INSTRUCTION = 'specifier_instruction',
  INSERT_NATIONALLY_DETERMINED_REQUIREMENT = 'insert_nationally_determined_requirement',
  INSERT_LIST = 'insert_list',
  UNINDENT_LIST = 'unindent_list',
  INDENT_LIST = 'indent_list',
  INSERT_INLINE_EQUATION = 'insert_inline_equation',
  INSERT_DISPLAY_EQUATION = 'insert_display_equation',
  TOGGLE_SUPERSCRIPT = 'toggle_superscript',
  TOGGLE_SUBSCRIPT = 'toggle_subscript',
  HIGHLIGHT_TEXT = 'highlight_text',
  INSERT_TABLE = 'insert_table',
  UPLOAD_IMAGE = 'upload_image',
  SUGGEST_CHANGE = 'suggest_change',
  REORDER_CLAUSE = 'reorder_clause',

  // Right sidebar
  RIGHT_SIDEBAR = 'right_sidebar',
  SUBJECT = 'subject',
  QUESTION_ANSWER = 'question_answer',
  ERROR = 'error',
  DESCRIPTION = 'description',
  UNITS_ICON = 'units_icon',
  BOOK = 'book',
  SUMMARIZE = 'summarize',
  HISTORY = 'history',
  LINK = 'link',
  IMAGE = 'image',

  // Left sidebar
  INITIAL_BUTTON = 'initial_button',
  AUTHORING = 'authoring',
  CHANGE_LOG = 'change_log',
  TOGGLE_MENU = 'toggle_menu',
}

const carsAccessibilityKeys: Record<CarsAccessibilityItem, AltProperties> = {
  undo: {initialKey: 'e', key: 'u', condition: true},
  redo: {initialKey: 'e', key: 'r', condition: true},
  change_clause_type: {initialKey: 'e', key: 'c', condition: true},
  standard_format_requirement: {initialKey: 'e', key: 'f', condition: true},
  specifier_instruction: {initialKey: 'e', key: 'p', condition: true},
  insert_nationally_determined_requirement: {initialKey: 'e', key: 'q', condition: true},
  insert_list: {initialKey: 'e', key: 'n', condition: true},
  unindent_list: {initialKey: 'e', key: 'd', condition: true},
  indent_list: {initialKey: 'e', key: 'i', condition: true},
  insert_inline_equation: {initialKey: 'e', key: 'e', condition: true},
  insert_display_equation: {initialKey: 'e', key: 'l', condition: true},
  toggle_superscript: {initialKey: 'e', key: 's', condition: true},
  toggle_subscript: {initialKey: 'e', key: 'b', condition: true},
  highlight_text: {initialKey: 'e', key: 'm', condition: true},
  insert_table: {initialKey: 'e', key: 't', condition: true},
  upload_image: {initialKey: 'e', key: 'a', condition: true},
  suggest_change: {initialKey: 'e', key: 'h', condition: true},
  reorder_clause: {initialKey: 'e', key: 'o', condition: true},

  // Right navigation side
  right_sidebar: {initialKey: 's', key: null, condition: true},
  subject: {initialKey: 's', key: 'b', condition: true},
  question_answer: {initialKey: 's', key: 'd', condition: true},
  error: {initialKey: 's', key: 'v', condition: true},
  description: {initialKey: 's', key: 's', condition: true},
  units_icon: {initialKey: 's', key: 'u', condition: true},
  book: {initialKey: 's', key: 'r', condition: true},
  summarize: {initialKey: 's', key: 'c', condition: true},
  history: {initialKey: 's', key: 'h', condition: true},
  link: {initialKey: 's', key: 'l', condition: true},
  image: {initialKey: 's', key: 'a', condition: true},

  // Left navigation sidebar
  initial_button: {initialKey: 'n', key: null, condition: true},
  authoring: {initialKey: 'n', key: 'a', condition: true},
  change_log: {initialKey: 'n', key: 'c', condition: true},
  toggle_menu: {initialKey: 'n', key: 't', condition: true},
};

interface AltKeyEvent {
  selection: boolean;
  key: string;
}

export class AltProperties {
  constructor(public readonly initialKey: string, public readonly key: string, public condition: boolean) {}
}

@Injectable({
  providedIn: 'root',
})
export class AltAccessibilityService implements OnDestroy {
  private altSubject: Subject<boolean> = new Subject();
  private selectSubject: Subject<AltKeyEvent> = new Subject();
  private _subscription: Subscription;

  constructor(private _resizeService: ResizeService) {
    this._subscription = this._resizeService.subscribe(() => this.triggerAltEvent(false));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._subscription = null;
  }

  public triggerAltEvent(value: boolean): void {
    this.altSubject.next(value);
  }

  /**
   * This subscribes the the main alt events, i.e when the ale overlay opens or closes.
   */
  public onAltEvent(): Observable<boolean> {
    return this.altSubject;
  }

  public triggerKeyEvent(keyEvent: AltKeyEvent): void {
    this.selectSubject.next(keyEvent);
  }

  /**
   * This subscribes to the key press events that are triggered then the alt overlay is open.
   */
  public onKeyEvent(): Observable<AltKeyEvent> {
    return this.selectSubject;
  }

  // Returns an altProperty based on a given enum value from the CarsAccessibilityItem
  public getAltProperties(enumValue: string, condition: boolean = true): AltProperties {
    const verifiedItem = this.getCarsAccessibilityItemByKeyValue(enumValue);
    if (verifiedItem != null) {
      const changeTypeOfItem: any = enumValue;

      const altProperties = carsAccessibilityKeys[changeTypeOfItem];
      altProperties.condition = condition;
      return altProperties;
    }
    return null;
  }

  // This method is used to lookup an enum key based on the enum value.
  // The enumValue paramter will be holding a potential enum value and a check will
  // is performed to see which enum Key it belongs too.
  private getCarsAccessibilityItemByKeyValue(enumValue: string): string {
    let enumKey: string = null;
    Object.keys(CarsAccessibilityItem).forEach((key) => {
      const enumValueIsString: string = CarsAccessibilityItem[key];
      if (enumValueIsString.toLowerCase() === enumValue.toLowerCase()) {
        enumKey = key;
      }
    });
    return enumKey;
  }
}
