import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FragmentModule} from 'app/fragment/fragment.module';
import {ClauseChangeSummaryClauseCellComponent} from './clause-change-summary-clause-cell.component';

@NgModule({
  imports: [CommonModule, FragmentModule],
  declarations: [ClauseChangeSummaryClauseCellComponent],
  exports: [ClauseChangeSummaryClauseCellComponent],
})
export class ClauseChangeSummaryClauseCellModule {}
