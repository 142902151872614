import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatRadioModule} from '@angular/material/radio';
import {SpecifierInstructionListOptionsComponent} from './specifier-instruction-list-options.component';

@NgModule({
  imports: [CommonModule, MatRadioModule],
  declarations: [SpecifierInstructionListOptionsComponent],
  exports: [SpecifierInstructionListOptionsComponent],
})
export class SpecifierInstructionListOptionsModule {}
