<div class="readOnlyGuidance" *ngIf="CarsAction.AUTHOR_DOCUMENT | carsDisabled: document?.id | async">
  <span>
    This document is read only as it has been submitted for approval. See document information for further guidance.
  </span>
</div>
<cars-toolbar *ngIf="CarsAction.AUTHOR_DOCUMENT | carsEnabled: document?.id | async">
  <button
    *ngIf="(CarsAction.ENABLE_NAVIGATION_TOGGLE | carsEnabled: document?.id | async) && !isOpen"
    mat-button
    matTooltip="Expand Sidebar"
    matTooltipPosition="below"
    [matTooltipShowDelay]="tooltipDelay"
    aria-label="Expand Sidebar"
    (click)="onToggleNavBar()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <cars-alt-accessibility [richText]="true" [altProperties]="altProperties"></cars-alt-accessibility>
  <ng-content *ngTemplateOutlet="toolbarButtonsExt"></ng-content>
  <ng-container *ngFor="let item of toolbarItems" [ngSwitch]="item.toolbarItemType">
    <cars-rich-text
      *ngSwitchCase="ToolbarItemType.RICH_TEXT"
      [toolbarItem]="item"
      [altProperties]="itemAltProperties[item.itemKey]"
    ></cars-rich-text>
    <cars-clause-type-selector
      *ngSwitchCase="ToolbarItemType.CLAUSE_TYPE_SELECTOR"
      [toolbarItem]="item"
      [altProperties]="itemAltProperties[item.itemKey]"
    ></cars-clause-type-selector>
    <cars-table-creator
      *ngSwitchCase="ToolbarItemType.CREATE_TABLE"
      [toolbarItem]="item"
      [altProperties]="itemAltProperties[item.itemKey]"
    ></cars-table-creator>
    <cars-image-upload
      *ngSwitchCase="ToolbarItemType.UPLOAD_IMAGE"
      [toolbarItem]="item"
      [altProperties]="itemAltProperties[item.itemKey]"
    ></cars-image-upload>
    <cars-suggest-changes
      *ngSwitchCase="ToolbarItemType.SUGGESTION"
      [toolbarItem]="item"
      [altProperties]="itemAltProperties[item.itemKey]"
    ></cars-suggest-changes>
    <cars-reorder-clauses
      *ngSwitchCase="ToolbarItemType.REORDER_CLAUSES"
      [toolbarItem]="item"
      [altProperties]="itemAltProperties[item.itemKey]"
    ></cars-reorder-clauses>
    <cars-standard-format-group-creator
      *ngSwitchCase="ToolbarItemType.STANDARD_FORMAT_REQUIREMENT"
      [toolbarItem]="item"
      [altProperties]="itemAltProperties[item.itemKey]"
    ></cars-standard-format-group-creator>
    <cars-specifier-instruction-creator
      *ngSwitchCase="ToolbarItemType.SPECIFIER_INSTRUCTION"
      [toolbarItem]="item"
      [altProperties]="itemAltProperties[item.itemKey]"
    ></cars-specifier-instruction-creator>
  </ng-container>
</cars-toolbar>
