import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {FragmentState} from 'app/fragment/fragment-state';
import {TableCellFragment, TableFragment} from 'app/fragment/table/table-fragment';
import {
  CaptionedFragment,
  ClauseFragment,
  EDITABLE_TEXT_FRAGMENT_TYPES,
  EquationFragment,
  FigureFragment,
  Fragment,
  FragmentType,
  TextFragment,
} from 'app/fragment/types';
import {UnitInputFragment} from 'app/fragment/types/input/unit-input-fragment';
import {Callback} from 'app/utils/typedefs';
import {UUID} from 'app/utils/uuid';
import * as JsDiff from 'diff';
import {DocumentFragment, DocumentReferenceFragment, InlineReferenceFragment} from './types';
import {ReferenceInputFragment} from './types/input/reference-input-fragment';
import {InternalDocumentReferenceFragment} from './types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from './types/reference/internal-inline-reference-fragment';

export class TreeDiffer {
  private static FRAGMENT_TYPES_TO_IGNORE: ReadonlyArray<FragmentType> = [FragmentType.ANCHOR];

  private previousLookup: Map<string, Fragment>;
  private currentLookup: Map<string, Fragment>;
  private tableIds: Set<string> = new Set();

  private previousReferenceLookup: Map<string, Fragment>;
  private currentReferenceLookup: Map<string, Fragment>;

  /**
   * Diffs two versions of the same subtree but does not check for changes in inline reference ReferenceType.
   *
   * WARNING: this method modifies the subtrees passed to it.  Do not use it on fragments
   * which are in the cache.
   */
  public diffWithoutReferences(previousFragment: Fragment, nextFragment: Fragment): Fragment {
    // reset the document lookups to their initial states
    this.previousReferenceLookup = null;
    this.currentReferenceLookup = null;
    return this._diff(previousFragment, nextFragment);
  }

  /**
   * Diffs two versions of the same subtree and also diffs inline references to check if they have changed their
   * ReferenceType.
   *
   * WARNING: this method modifies the subtrees passed to it.  Do not use it on fragments
   * which are in the cache.
   */
  public diffWithReferences(
    previousFragment: Fragment,
    nextFragment: Fragment,
    previousDocument: DocumentFragment,
    currentDocument: DocumentFragment
  ): Fragment {
    this.previousReferenceLookup = this._createReferenceLookup(previousDocument);
    this.currentReferenceLookup = this._createReferenceLookup(currentDocument);

    return this._diff(previousFragment, nextFragment);
  }

  /**
   * Diff two versions of the same subtree.
   *
   * WARNING: this method modifies the subtrees passed to it.  Do not use it on fragments
   * which are in the cache.
   *
   * @param previousFragment {Fragment} The earlier version.
   * @param nextFragment     {Fragment} The later version.
   * @returns                {Fragment} A modified subtree, with fragments decorated with
   * diff information.
   */
  private _diff(previousFragment: Fragment, nextFragment: Fragment): Fragment {
    // If this is the earliest version, display as normal.  Also, if
    // the two trees have different roots.
    if (!previousFragment || !previousFragment.equals(nextFragment)) {
      return nextFragment;
    }

    // Initialise lookup data structures.
    this.previousLookup = this._createLookup(previousFragment, (f: Fragment) => {
      if (f.is(FragmentType.TABLE)) {
        this.tableIds.add(f.id.value);
      }
    });
    this.currentLookup = this._createLookup(nextFragment, (f: Fragment) => {
      if (f.is(FragmentType.TABLE)) {
        this.tableIds.add(f.id.value);
      }
    });

    this.tableIds.forEach((id: string) => {
      const table: TableFragment = this.currentLookup.get(id) as TableFragment;
      const prevTable: TableFragment = this.previousLookup.get(id) as TableFragment;
      if (!this._canDisplayTableDiff(prevTable, table)) {
        table.iterateDown(null, null, (f: Fragment) => {
          if (!f.is(FragmentType.TABLE)) {
            this.currentLookup.delete(f.id.value);
          }
        });
        prevTable.iterateDown(null, null, (f: Fragment) => {
          if (!f.is(FragmentType.TABLE)) {
            this.previousLookup.delete(f.id.value);
          }
        });
        table.value = '_CHANGED';
      }
    });

    const mergedFragment = nextFragment;
    this.previousLookup.delete(nextFragment.id.value); // Don't diff the root

    this.previousLookup.forEach((prevFrag: Fragment, id: string) => {
      if (this.currentLookup.has(id)) {
        const currentFragment: Fragment = this.currentLookup.get(id);
        if (prevFrag instanceof ReferenceInputFragment) {
          this._diffReferenceInputFragments(prevFrag, currentFragment as ReferenceInputFragment);
          return;
        }

        this._diffFragmentCaptions(prevFrag, currentFragment);
        this._diffFigureAltText(prevFrag, currentFragment);
        if (this._isMergedOrSplit(prevFrag, currentFragment)) {
          currentFragment.state = FragmentState.CREATED;
        } else if (this._hasClauseTypeChanged(prevFrag, currentFragment)) {
          currentFragment.state = FragmentState.MOVED;
        } else if (this._isSame(prevFrag, currentFragment)) {
          currentFragment.state = this._hasMoved(prevFrag, currentFragment)
            ? FragmentState.MOVED
            : FragmentState.UNMODIFIED;
        } else if (this._isSuitableForStringDiff(currentFragment)) {
          const parent = currentFragment.parent;
          const startIndex = parent.childIndexOf(currentFragment);
          currentFragment.remove();
          JsDiff.diffWords(prevFrag.value, currentFragment.value).map((changeObject, index) => {
            const newFrag = FragmentMapper.clone(currentFragment);
            newFrag.state = changeObject.added
              ? FragmentState.CREATED
              : changeObject.removed
              ? FragmentState.DELETED
              : FragmentState.UNMODIFIED;
            newFrag.value = changeObject.value;
            this.currentLookup.get(parent.id.value).children.splice(startIndex + index, 0, newFrag);
          });
        } else {
          const parent = currentFragment.parent;
          const startIndex = parent.childIndexOf(currentFragment);
          currentFragment.state = FragmentState.CREATED;
          prevFrag.state = FragmentState.DELETED;
          if (currentFragment.isCaptioned()) {
            // If the fragment is being treated as a delete/create, we need to ignore the old/new caption merged diff
            (currentFragment as CaptionedFragment).diffedCaption = null;
          }
          this.currentLookup.get(parent.id.value).children.splice(startIndex, 0, prevFrag);
        }
      } else {
        this._handleSettingDeletedOnPreviousFragment(prevFrag);
      }
    });

    this.currentLookup.forEach((frag: Fragment, id: string) => {
      if (!this.previousLookup.has(id)) {
        frag.state = FragmentState.CREATED;
      }
    });

    mergedFragment.state = null;
    this._reset();
    return mergedFragment;
  }

  /**
   * Mark the provided previous fragment as deleted and insert it above the current fragment
   *
   */
  private _handleSettingDeletedOnPreviousFragment(previousFragment: Fragment): void {
    previousFragment.state = FragmentState.DELETED;

    if (this.currentLookup.has(previousFragment.parent.id.value)) {
      if (this.currentLookup.get(previousFragment.parent.id.value).state !== FragmentState.DELETED) {
        this.currentLookup
          .get(previousFragment.parent.id.value)
          .children.splice(previousFragment.parent.childIndexOf(previousFragment), 0, previousFragment);
        this.currentLookup.set(previousFragment.id.value, previousFragment);
      }
    }
  }

  /**
   *
   * check a difference between the number of inline sec refs, references that are in only the prev or current version, references within both prev and current and with the reference being updated
   * set the prev reference input fragment state toFragmentState.DELETED and the current fragment state to FragmentState.CREATED
   * call this in the TreeDiffer::_diff method for reference input fragment only.
   *
   */
  private _diffReferenceInputFragments(
    previousReferenceInputFragment: ReferenceInputFragment,
    currentReferenceInputFragment: ReferenceInputFragment
  ) {
    let changeDetected: boolean = false;

    if (previousReferenceInputFragment.children.length === currentReferenceInputFragment.children.length) {
      // If both reference input fragments have the same number of children, compare their children directly

      currentReferenceInputFragment.children.forEach(
        (currentInternalInlineReference: InternalInlineReferenceFragment, index: number) => {
          const previousInternalInlineReference = previousReferenceInputFragment.children[
            index
          ] as InternalInlineReferenceFragment;
          const previousInternalDocumentReferenceFragment: InternalDocumentReferenceFragment =
            this.previousReferenceLookup.get(
              previousInternalInlineReference.internalDocumentReferenceId.value
            ) as InternalDocumentReferenceFragment;
          const currentInternalDocumentReferenceFragment: InternalDocumentReferenceFragment =
            this.currentReferenceLookup.get(
              currentInternalInlineReference.internalDocumentReferenceId.value
            ) as InternalDocumentReferenceFragment;

          // either overwrite changeDetected with itself if it's true, OR check if the documentRefs are different.
          changeDetected =
            changeDetected ||
            this._areInternalDocumentReferenceFragmentsDifferent(
              previousInternalDocumentReferenceFragment,
              currentInternalDocumentReferenceFragment
            );
        }
      );
    } else {
      // If the number of children between reference input fragments does not match
      changeDetected = true;
    }

    // Even if the number of children don't match, we need to set the diffed doc ref so that it's displayed correctly
    this._injectDiffedInternalDocRefFragmentOnInternalInlineRefs(
      previousReferenceInputFragment,
      this.previousReferenceLookup
    );
    this._injectDiffedInternalDocRefFragmentOnInternalInlineRefs(
      currentReferenceInputFragment,
      this.currentReferenceLookup
    );

    if (changeDetected) {
      currentReferenceInputFragment.state = FragmentState.CREATED;
      this._handleSettingDeletedOnPreviousFragment(previousReferenceInputFragment);
    }
  }

  private _areInternalDocumentReferenceFragmentsDifferent(
    previousInternalDocumentReferenceFragment: InternalDocumentReferenceFragment,
    currentInternalDocumentReferenceFragment: InternalDocumentReferenceFragment
  ): boolean {
    const targetSectionIdMatches: boolean =
      currentInternalDocumentReferenceFragment.targetSectionId.value ===
      previousInternalDocumentReferenceFragment.targetSectionId.value;

    const documentCodeMatches: boolean =
      currentInternalDocumentReferenceFragment.documentCode === previousInternalDocumentReferenceFragment.documentCode;

    const sectionIndexMatches: boolean =
      currentInternalDocumentReferenceFragment.sectionIndex === previousInternalDocumentReferenceFragment.sectionIndex;

    const sectionTitleMatches: boolean =
      currentInternalDocumentReferenceFragment.sectionTitle === previousInternalDocumentReferenceFragment.sectionTitle;

    const wsrCodeMatches: boolean =
      currentInternalDocumentReferenceFragment.wsrCode === previousInternalDocumentReferenceFragment.wsrCode;

    const targetFragmentIndexMatches: boolean =
      currentInternalDocumentReferenceFragment.targetFragmentIndex ===
      previousInternalDocumentReferenceFragment.targetFragmentIndex;

    const targetFragmentValueMatches: boolean =
      currentInternalDocumentReferenceFragment.targetFragmentValue ===
      previousInternalDocumentReferenceFragment.targetFragmentValue;

    const targetClauseTypeMatches: boolean =
      currentInternalDocumentReferenceFragment.targetClauseType ===
      previousInternalDocumentReferenceFragment.targetClauseType;

    if (
      !targetSectionIdMatches ||
      !documentCodeMatches ||
      !sectionIndexMatches ||
      !sectionTitleMatches ||
      !wsrCodeMatches ||
      !targetFragmentIndexMatches ||
      !targetFragmentValueMatches ||
      !targetClauseTypeMatches
    ) {
      return true;
    }
    return false;
  }

  /**
   * Inject the InternalDocumentReference for that version into the corresponding InternalInlineReferences for display on change summary screens
   * @param refInputFrag Parent fragment containing the InternalInlineReferences that need their diffed Document Reference.
   */
  private _injectDiffedInternalDocRefFragmentOnInternalInlineRefs(
    refInputFrag: ReferenceInputFragment,
    referenceLookup: Map<string, Fragment>
  ): void {
    refInputFrag.children.forEach((internalInlineRef: InternalInlineReferenceFragment) => {
      const previousInternalDocumentReferenceFragment: InternalDocumentReferenceFragment = referenceLookup.get(
        internalInlineRef.internalDocumentReferenceId.value
      ) as InternalDocumentReferenceFragment;
      // Setting this as a requirement for _handleSettingDeletedOnPreviousFragment to see the change in text
      internalInlineRef.diffedInternalDocumentReference = previousInternalDocumentReferenceFragment;
    });
  }

  /**
   * Reset the differ to initial state.
   */
  private _reset(): void {
    this.previousLookup = null;
    this.currentLookup = null;
    this.previousReferenceLookup = null;
    this.currentReferenceLookup = null;
    this.tableIds = new Set();
  }

  /**
   *  Helper for diff() to diff Captioned Fragment caption text.
   */
  private _diffFragmentCaptions(prevFrag: Fragment, frag: Fragment): void {
    if (prevFrag.isCaptioned()) {
      // Get current version of this captioned fragment
      const currCaptionedFragment: CaptionedFragment = frag as CaptionedFragment;
      const prevCaptionedFragment: CaptionedFragment = prevFrag as CaptionedFragment;
      // Compare the old and new caption text - we convert the resulting changeObjects to
      // diffedCaption array of (change-)state annotated TextFragments.
      currCaptionedFragment.diffedCaption = JsDiff.diffWords(
        prevCaptionedFragment.caption.value,
        currCaptionedFragment.caption.value
      ).map((changeObject) => {
        const newFrag = FragmentMapper.clone(currCaptionedFragment.caption);
        newFrag.parent = currCaptionedFragment.caption.parent;
        newFrag.state = changeObject.added
          ? FragmentState.CREATED
          : changeObject.removed
          ? FragmentState.DELETED
          : FragmentState.UNMODIFIED;
        newFrag.value = changeObject.value;
        return newFrag;
      });
    }
  }

  /**
   *  Helper for diff() to diff figure alt text.
   */
  private _diffFigureAltText(prevFrag: Fragment, frag: Fragment): void {
    if (prevFrag.is(FragmentType.FIGURE)) {
      // Get current version of this figure fragment
      const currFigureFragment: FigureFragment = frag as FigureFragment;
      const prevFigureFragment: FigureFragment = prevFrag as FigureFragment;
      // Compare the old and new alt text - we convert the resulting changeObjects to
      // diffedAlttext array of (change-)state annotated TextFragments.
      const prevAlt = prevFigureFragment.altText ?? '';
      const currAlt = currFigureFragment.altText ?? '';
      currFigureFragment.diffedAltText = JsDiff.diffWords(prevAlt, currAlt).map((changeObject) => {
        const newFrag = new TextFragment(UUID.random(), currFigureFragment.altText);
        newFrag.parent = currFigureFragment;
        newFrag.state = changeObject.added
          ? FragmentState.CREATED
          : changeObject.removed
          ? FragmentState.DELETED
          : FragmentState.UNMODIFIED;
        newFrag.value = changeObject.value;
        return newFrag;
      });
    }
  }

  /**
   * Checks if a fragment has been reordered within its parent. Classed as a reorder if either the weight has changed
   * or the parent fragment has changed.
   *
   * Note that this can lead to some false positives where a clause is reordered then reordered back into the same
   * position or the clauses around it are deleted such that it appears in the same position, but this is consistent
   * with the logic in the clause change summary and the history component.
   */
  private _hasMoved(prevFrag: Fragment, frag: Fragment): boolean {
    return frag.weight !== prevFrag.weight || !frag.parent.equals(prevFrag.parent);
  }

  private _hasClauseTypeChanged(prevFrag: Fragment, frag: Fragment): boolean {
    return (
      frag.is(FragmentType.CLAUSE) && (prevFrag as ClauseFragment).clauseType !== (frag as ClauseFragment).clauseType
    );
  }

  /**
   * Checks if two versions of a fragment are the same. If comparing clause fragments, always return true as reordering
   * is handled elsewhere and background commentary changes should not be treated as a change.
   */
  private _isSame(prevFrag: Fragment, frag: Fragment): boolean {
    if (frag.is(FragmentType.EQUATION)) {
      const prevEq: EquationFragment = prevFrag as EquationFragment;
      const eq: EquationFragment = frag as EquationFragment;
      return prevEq.source.value === eq.source.value;
    } else if (frag.is(FragmentType.CLAUSE)) {
      return true;
    } else if (frag.is(FragmentType.INLINE_REFERENCE)) {
      if (!this.previousReferenceLookup && !this.currentReferenceLookup) {
        return true;
      }

      const prevInlineRef: InlineReferenceFragment = prevFrag as InlineReferenceFragment;
      const currentInlineRef: InlineReferenceFragment = frag as InlineReferenceFragment;

      const prevDocumentReference: DocumentReferenceFragment = this.previousReferenceLookup.get(
        prevInlineRef.documentReference.value
      ) as DocumentReferenceFragment;
      const currDocumentReference: DocumentReferenceFragment = this.currentReferenceLookup.get(
        currentInlineRef.documentReference.value
      ) as DocumentReferenceFragment;

      return prevDocumentReference?.referenceType === currDocumentReference?.referenceType;
    } else if (frag.is(FragmentType.UNIT_INPUT)) {
      const prevUnitInput: UnitInputFragment = prevFrag as UnitInputFragment;
      const unitInput: UnitInputFragment = frag as UnitInputFragment;

      return prevUnitInput.unitEquationSource === unitInput.unitEquationSource;
    } else {
      return frag.value === prevFrag.value;
    }
  }

  private _isSuitableForStringDiff(fragment: Fragment): boolean {
    return fragment.is(...EDITABLE_TEXT_FRAGMENT_TYPES, FragmentType.READONLY);
  }

  /**
   * Creates a lookup from fragmentId to fragment for descendants of the given root. Removes anchor fragments and
   * attempts to merge any siblings together if they are mergeable and not already merged. Note that fragments are
   * removed from the tree to stop them showing up as unchanged fragments (only fragments in the lookup are diffed, but
   * all children of the new clause are displayed) and for ease of doing fragment::previousSibling.
   */
  private _createLookup(root: Fragment, callback: Callback<Fragment> = () => {}): Map<string, Fragment> {
    const lookup: Map<string, Fragment> = new Map();
    if (!root) {
      return lookup;
    }

    root.iterateDown(null, null, (fragment: Fragment) => {
      if (fragment) {
        if (TreeDiffer.FRAGMENT_TYPES_TO_IGNORE.includes(fragment.type)) {
          fragment.remove();
        } else {
          const previousSibling: Fragment = fragment.previousSibling();

          if (fragment.isMergeable() && !!previousSibling && previousSibling.type === fragment.type) {
            previousSibling.value = previousSibling.value + fragment.value;
            fragment.remove();
          } else {
            lookup.set(fragment.id.value, fragment);
          }
        }

        callback(fragment);
      }
    });

    return lookup;
  }

  private _createReferenceLookup(root: DocumentFragment): Map<string, Fragment> {
    const lookup: Map<string, Fragment> = new Map();
    if (!root) {
      return lookup;
    }
    root.getReferenceSections().forEach((section) =>
      section.iterateDown(null, null, (fragment: Fragment) => {
        if (fragment?.is(FragmentType.DOCUMENT_REFERENCE, FragmentType.INTERNAL_DOCUMENT_REFERENCE)) {
          lookup.set(
            fragment.id.value,
            fragment instanceof DocumentReferenceFragment
              ? (fragment as DocumentReferenceFragment)
              : (fragment as InternalDocumentReferenceFragment)
          );
        }
      })
    );
    return lookup;
  }

  private _isMergedOrSplit(prevFrag: Fragment, frag: Fragment): boolean {
    if (frag.is(FragmentType.TABLE_CELL)) {
      const prevCell: TableCellFragment = prevFrag as TableCellFragment;
      const cell: TableCellFragment = frag as TableCellFragment;
      return (prevCell.rowSpan !== cell.rowSpan || prevCell.colSpan !== cell.colSpan) && !cell.deleted;
    } else {
      return false;
    }
  }

  /**
   * Calculates whether a table diff can be neatly displayed or not.
   *
   * @param prevTable {TableFragment} The previous version of the table.
   * @param table     {TableFragment} The current version of the table.
   * @returns         {boolean}       False if there have been row additions
   * and col deletions, or vise versa. Also returns false if there have been
   * deletions across a merged area.
   */
  private _canDisplayTableDiff(prevTable: TableFragment, table: TableFragment): boolean {
    if (!prevTable || !table) {
      return true;
    }
    const prevNumRows: number = prevTable.children.length;
    const numRows: number = table.children.length;
    const prevNumCols: number = prevTable.children[0].children.length;
    const numCols: number = table.children[0].children.length;

    // if there have been row additions and col deletions, or row deletions and col additions:
    if (numRows > prevNumRows && numCols < prevNumCols) {
      return false;
    }
    if (numRows < prevNumRows && numCols > prevNumCols) {
      return false;
    }
    if (numRows >= prevNumRows && numCols >= prevNumCols) {
      return true;
    } // No row/col deletions, table is fine.

    // if there is a row which has been deleted and contains a cell with a rowspan > 1
    // or there is a col which has been deleted and contains a cell with a colspan > 1
    const rowsAndCells: Map<string, Fragment> = this._createTableLookup(table);

    return (
      prevTable.iterateDown(null, null, (fragment: Fragment) => {
        // Found deleted row:
        if (fragment.is(FragmentType.TABLE_ROW) && !rowsAndCells.has(fragment.id.value)) {
          return fragment.children.some((cell: TableCellFragment) => cell.rowSpan > 1);
          // Found deleted col:
        } else if (
          fragment.is(FragmentType.TABLE_CELL) &&
          !rowsAndCells.has(fragment.id.value) &&
          rowsAndCells.has(fragment.parent.id.value)
        ) {
          return (fragment as TableCellFragment).colSpan > 1;
        }
      }).length === 0
    );
  }

  /**
   * Creates a lookup from id to fragment of all table rows and table cell descendants of the given fragment.
   */
  private _createTableLookup(root: Fragment): Map<string, Fragment> {
    const lookup: Map<string, Fragment> = new Map();
    if (!root) {
      return lookup;
    }

    root.iterateDown(null, null, (fragment: Fragment) => {
      if (fragment && fragment.is(FragmentType.TABLE_CELL, FragmentType.TABLE_ROW)) {
        lookup.set(fragment.id.value, fragment);
      }
    });

    return lookup;
  }
}
