import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {PadType} from 'app/element-ref.service';
import {Caret} from 'app/fragment/caret';
import {FragmentComponent} from 'app/fragment/core/fragment.component';
import {Fragment, FragmentType} from 'app/fragment/types';
import {InputFragment} from 'app/fragment/types/input/input-fragment';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {DomService} from 'app/services/dom.service';
import {FragmentService} from 'app/services/fragment.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-input-fragment',
  templateUrl: './input-fragment.component.html',
  styleUrls: ['./input-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFragmentComponent extends FragmentComponent implements OnInit, OnDestroy {
  @Input() public set content(value: InputFragment) {
    super.content = value;
  }

  public get content(): InputFragment {
    return super.content as InputFragment;
  }

  public focused: boolean = false;

  public isPasting: boolean = false;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _fragmentService: FragmentService,
    private _selectionOperationsService: SelectionOperationsService,
    protected _domService: DomService,
    protected _cdr: ChangeDetectorRef,
    protected _elementRef: ElementRef
  ) {
    super(_cdr, _elementRef);
  }

  public ngOnInit(): void {
    super.ngOnInit(); // IMPORTANT
    this._subscriptions.push(
      this._fragmentService.onSelection((fragment: Fragment) => {
        this.focused = fragment && (fragment.equals(this.content) || fragment.parent.equals(this.content));
        this.markForCheck();
      })
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy(); // IMPORTANT
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * @override
   */
  public blur(): void {
    if (this.readOnly) {
      return;
    }

    if (!this.isPasting) {
      this.focused = false;
      this._trim(true);
      this._trim(false);
      this._fragmentService.mergeChildren(this.content);
    } else {
      const selection: Selection = window.getSelection();
      const start: Caret = this._domService.getCaretsFromSelection(selection)[0];
      this._selectionOperationsService.setSelected(start.fragment, start.offset, PadType.MAIN_EDITABLE);
      this.isPasting = false;
    }
  }

  /**
   * Returns true if the input is within a rendered schedule list item. Updates the styling within the html.
   */
  public isInScheduleList(): boolean {
    return !!this.content.findAncestorWithType(FragmentType.LIST);
  }

  /**
   * Trims whitespace from one end of the input fragment.
   *
   * @param trimLeft {boolean} If true, trim whitespace from the left, else trim from the right.
   */
  private _trim(trimLeft: boolean): void {
    const step: number = trimLeft ? 1 : -1;
    const finalIndex: number = trimLeft ? this.content.children.length : -1;
    let previousChild: Fragment;

    for (let i = trimLeft ? 0 : this.content.children.length - 1; i !== finalIndex; i += step) {
      const child: Fragment = this.content.children[i];

      if (!previousChild || !previousChild.length()) {
        const childValue: string = child.value;
        child.value = trimLeft ? childValue.trimLeft() : childValue.trimRight();
        if (child.value !== childValue) {
          this._fragmentService.update(child);
        }
        previousChild = child;
      } else {
        break;
      }
    }
  }
}
