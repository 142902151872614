import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ResolvedIconModule} from 'app/dashboard/resolved-icon/resolved-icon.module';
import {ReviewStatusTableComponent} from './review-status-table.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ResolvedIconModule,
  ],
  declarations: [ReviewStatusTableComponent],
  exports: [ReviewStatusTableComponent],
})
export class ReviewStatusTableModule {}
