<div class="header">
  <div class="dropdown-container">
    <span class="dropdown-label">Newer version:</span>
    <mat-form-field>
      <mat-select
        (selectionChange)="changeVersionLH($event)"
        disableRipple
        [(value)]="currentOptionLH"
        [attr.aria-label]="currentOptionLH && getOptionDisplayString(currentOptionLH)"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
        [matTooltip]="currentOptionLH && getOptionDisplayString(currentOptionLH)"
      >
        <ng-container *ngFor="let option of versionOptions">
          <mat-option [value]="option" [attr.aria-label]="option && getOptionDisplayString(option)">
            {{ getOptionDisplayString(option) }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="dropdown-container">
    <span class="dropdown-label">Older version:</span>
    <mat-form-field>
      <mat-select
        (selectionChange)="changeVersionRH($event)"
        disableRipple
        [(value)]="currentOptionRH"
        [attr.aria-label]="currentOptionRH && getOptionDisplayString(currentOptionRH)"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
        [matTooltip]="currentOptionRH && getOptionDisplayString(currentOptionRH)"
      >
        <ng-container *ngFor="let option of versionOptionsRH">
          <mat-option [value]="option" [attr.aria-label]="option && getOptionDisplayString(option)">
            {{ getOptionDisplayString(option) }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <a mat-raised-button color="primary" [routerLink]="closeRoute" aria-label="Close section comparison">
    <mat-icon class="mat-24">close</mat-icon>Close
  </a>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" aria-label="Loading section comparison"></mat-progress-bar>
</div>
<div class="pad-wrapper">
  <cars-section-proxy
    [section]="mergedSection"
    [class.hidden]="!bothVersionsExist"
    [disableCanvas]="true"
  ></cars-section-proxy>
  <div *ngIf="!bothVersionsExist" class="does-not-exist-message">
    {{ this.errorMessage }}
  </div>
</div>
