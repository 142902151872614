import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FragmentModule} from 'app/fragment/fragment.module';
import {ExcerptComponent} from './excerpt.component';

@NgModule({
  imports: [CommonModule, FragmentModule],
  declarations: [ExcerptComponent],
  exports: [ExcerptComponent],
})
export class ExcerptModule {}
