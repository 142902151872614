import {CurrentView} from 'app/view/current-view';
import {Suggestion} from '../../interfaces';
import {UUID} from '../../utils/uuid';

/**
 * An enumeration of all the discussion types.
 */
export enum DiscussionType {
  GENERAL = 'GENERAL',
  EDITORIAL = 'EDITORIAL',
  TECHNICAL = 'TECHNICAL',
}

/**
 * An enumeration of all the capacities in which a discussion can be made.
 */
export enum CapacityOfComment {
  TAGG_CONTENT_SPECIALIST = 'TAGG_CONTENT_SPECIALIST',
  TECHNICAL_CONSULTEES = 'TECHNICAL_CONSULTEES',
  COMMERCIAL = 'COMMERCIAL',
  MAJOR_PROJECTS_CIP = 'MAJOR_PROJECTS_CIP',
  MAJOR_PROJECTS_RIP = 'MAJOR_PROJECTS_RIP',
  MAJOR_PROJECTS_SMP = 'MAJOR_PROJECTS_SMP',
  MAJOR_PROJECTS_OTHERS = 'MAJOR_PROJECTS_OTHERS',
  OPERATIONS = 'OPERATIONS',
  PROCUREMENT = 'PROCUREMENT',
  CUSTOMER_SATISFACTION = 'CUSTOMER_SATISFACTION',
  HEALTH_AND_SAFETY = 'HEALTH_AND_SAFETY',
  EQUALITY_DIVERSITY_AND_INCLUSION = 'EQUALITY_DIVERSITY_AND_INCLUSION',
  CARBON_MANAGEMENT_SUSTAINABLE_DEVELOPMENT_AND_GOOD_DESIGN = 'CARBON_MANAGEMENT_SUSTAINABLE_DEVELOPMENT_AND_GOOD_DESIGN',
  INTELLIGENT_TRANSPORT_SYSTEMS = 'INTELLIGENT_TRANSPORT_SYSTEMS',
}

/**
 * A class representing a discussion within CARS.
 *
 * @field id                {UUID}              The UUID of the discussion
 * @field fragmentId        {UUID}              The UUID of the fragment
 * @field versionId         {UUID}              The UUID of the version the discussion was raised against
 * @field type              {DiscussionType}    The discussion type
 * @field capacityOfComment {CapacityOfComment} The capacity in which the discussion is made
 * @field issueRaised       {string}            The issue raised
 * @field comments          {Comment[]}         The comments attached to the discussion
 * @field resolved          {boolean}           If the discussion is resolved
 * @field suggestion        {Suggestion}        The suggested text
 * @field createdBy         {UUID}              The UUID for who created the discussion
 * @field createdAt         {number}            The timestamp for when the discussion was created
 */
export class Discussion {
  public id: UUID;
  public fragmentId: UUID;
  public versionId: UUID;
  public type: DiscussionType;
  public capacityOfComment: CapacityOfComment;
  public issueRaised: string;
  public comments: Comment[];
  public resolved: boolean;
  public suggestion: Suggestion;
  public createdBy: UUID;
  public createdAt: number;

  /**
   * Deserialise an instance of a discussion from a JSON object.
   *
   * @param json {any}        The JSON object from the web service
   * @returns    {Discussion} The deserialised discussion
   */
  public static deserialise(json: any): Discussion {
    const id: UUID = UUID.orThrow(json.id);
    const fragmentId: UUID = UUID.orThrow(json.fragmentId);
    const versionId: UUID = UUID.orNull(json.versionId);
    const type: DiscussionType = DiscussionType[json.type as string] || DiscussionType.GENERAL;
    const capacityOfComment: CapacityOfComment = CapacityOfComment[json.capacityOfComment as string];
    const issueRaised: string = json.issueRaised;
    const comments: Comment[] = json.comments ? Comment.deserialiseArray(json.comments) : [];
    const resolved: boolean = json.resolved || false;
    const suggestion: Suggestion = Discussion.deserialiseSuggestion(json);
    const createdBy: UUID = UUID.orThrow(json.createdBy);
    const createdAt: number = json.createdAt;

    return new Discussion(
      id,
      fragmentId,
      versionId,
      type,
      capacityOfComment,
      issueRaised,
      comments,
      resolved,
      suggestion,
      createdBy,
      createdAt
    );
  }

  /**
   * Deserialise the suggestion from the JSON object.
   *
   * @param json {any} The JSON object
   */
  public static deserialiseSuggestion(json: any): Suggestion {
    return json.suggestion
      ? {
          currentValue: json.currentValue,
          suggestedValue: json.suggestedValue,
          startFragmentId: UUID.orNull(json.startFragmentId),
          endFragmentId: UUID.orNull(json.endFragmentId),
          startOffset: json.startOffset,
          endOffset: json.endOffset,
        }
      : null;
  }

  constructor(
    id: UUID,
    fragmentId: UUID,
    versionId: UUID,
    type: DiscussionType,
    capacityOfComment: CapacityOfComment,
    issueRaised: string,
    comments: Comment[],
    resolved: boolean,
    suggestion: Suggestion,
    createdBy: UUID,
    createdAt: number
  ) {
    this.id = id;
    this.fragmentId = fragmentId;
    this.versionId = versionId;
    this.type = type;
    this.capacityOfComment = capacityOfComment;
    this.issueRaised = issueRaised;
    this.comments = comments || [];
    this.resolved = resolved;
    this.suggestion = suggestion;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
  }

  /**
   * Serialise a Discussion to a JSON object.
   *
   * @returns {any} The serialised form.
   */
  public serialise(): any {
    const body: any = {
      id: this.id.value,
      fragmentId: this.fragmentId.value,
      versionId: this.versionId ? this.versionId.value : null,
      type: this.type,
      capacityOfComment: this.capacityOfComment,
      issueRaised: this.issueRaised,
      comments: this.comments.map((c: Comment) => c.serialise()),
      suggestion: this.suggestion,
      createdBy: this.createdBy.value,
      createdAt: this.createdAt,
    };

    if (this.suggestion) {
      body.currentValue = this.suggestion.currentValue;
      body.suggestedValue = this.suggestion.suggestedValue;
      body.startFragmentId = this.suggestion.startFragmentId.value;
      body.endFragmentId = this.suggestion.endFragmentId.value;
      body.startOffset = this.suggestion.startOffset;
      body.endOffset = this.suggestion.endOffset;
    }

    return body;
  }

  /**
   * Determines whether the given user can comment against the discussion.
   *
   * @param currentView {CurrentView} The current view
   * @returns           {boolean}     If the user can comment against the discussion
   */
  public isCommentable(currentView: CurrentView): boolean {
    return currentView.isAvailableToCommentAgainst();
  }

  /**
   * Determines whether the discussion can be deleted by the given user.
   *
   * @param userId      {UUID}        The current logged in user
   * @param currentView {CurrentView} The current view
   * @returns           {boolean}     If the user can delete the discussion
   */
  public isDeleteable(userId: UUID, currentView: CurrentView): boolean {
    return this.wasRaisedBy(userId) && !this.hasComments() && currentView.isAvailableToCommentAgainst();
  }

  /**
   * Determines whether the given user can resolve the discussion.
   *
   * @param userId      {UUID}        The current logged in user
   * @param currentView {CurrentView} The current view
   * @returns           {boolean}     If the user can resolve the discussion
   */
  public isResolvable(userId: UUID, currentView: CurrentView): boolean {
    return (currentView.userIsAnAuthor() || this.wasRaisedBy(userId)) && currentView.isAvailableToCommentAgainst();
  }

  /**
   * @returns {boolean} true if this discussion has comments
   */
  public hasComments(): boolean {
    return !!this.comments && this.comments.length > 0;
  }

  /**
   * Determines whether the discussion was raised by the given user.
   *
   * @param id {UUID}    User id|ids to check against
   * @returns  {boolean} If the discussion was raised by the given user|users
   */
  public wasRaisedBy(id: UUID | UUID[]): boolean {
    const ids: UUID[] = !(id instanceof Array) ? [id] : id;
    return ids.findIndex((_id: UUID) => _id.equals(this.createdBy)) >= 0;
  }

  /**
   * Determines whether the discussion was raised against the given version.
   *
   * @param versionId {UUID}    Version UUID to compare
   * @returns         {boolean} If the discussion was raised against this version
   */
  public wasRaisedAgainst(versionId: UUID): boolean {
    return !!versionId && !!this.versionId ? this.versionId.equals(versionId) : false;
  }
}

/**
 * A class representing a comment within CARS.
 *
 * @field id           {UUID}    The UUID of the Comment
 * @field discussionId {UUID}    The UUID of the discussion
 * @field versionId    {UUID}    The UUID of the version the comment was raised against
 * @field content      {string}  The content
 * @field resolution   {boolean} If this comment resolves the discussion
 * @field createdBy    {UUID}    The UUID for who created the comment
 * @field createdAt    {number}  The timestamp for when the comment was created
 */
export class Comment {
  public id: UUID;
  public discussionId: UUID;
  public versionId: UUID;
  public content: string;
  public resolution: boolean;
  public createdBy: UUID;
  public createdAt: number;

  /**
   * Helper function to deserialise an array of comments.
   *
   * @param json {any}       The JSON to deserialise
   * @returns    {Comment[]} The deserialised comments
   */
  public static deserialiseArray(json: any): Comment[] {
    json = typeof json === 'string' ? JSON.parse(json) : json;
    json = json instanceof Array ? json : [json];

    return json.map((j: any) => Comment.deserialise(j));
  }

  /**
   * Deserialise an instance of a comment from a JSON object.
   *
   * @param json {any}     The JSON object to deserialise
   * @returns    {Comment} The deserialised comment
   */
  public static deserialise(json: any): Comment {
    const id: UUID = UUID.orThrow(json.id);
    const discussionId: UUID = UUID.orThrow(json.discussionId);
    const versionId: UUID = UUID.orNull(json.versionId);
    const content: string = json.content;
    const resolution: boolean = json.resolution;
    const createdBy: UUID = UUID.orThrow(json.createdBy);
    const createdAt: number = json.createdAt;

    return new Comment(id, discussionId, versionId, content, resolution, createdBy, createdAt);
  }

  constructor(
    id: UUID,
    discussionId: UUID,
    versionId: UUID,
    content: string,
    resolution: boolean,
    createdBy: UUID,
    createdAt: number
  ) {
    this.id = id;
    this.discussionId = discussionId;
    this.versionId = versionId;
    this.content = content;
    this.resolution = resolution;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
  }

  /**
   * Serialise a Comment to a json objext.
   *
   * @returns {any} The serialised object.
   */
  public serialise(): any {
    return {
      id: this.id.value,
      discussionId: this.discussionId.value,
      versionId: this.versionId ? this.versionId.value : null,
      content: this.content,
      resolution: this.resolution,
      createdBy: this.createdBy.value,
      createdAt: this.createdAt,
    };
  }
}
