import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {CarsRange} from 'app/fragment/cars-range';
import {ClauseFragment, FragmentType} from 'app/fragment/types';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {CanvasService} from 'app/services/canvas.service';
import {LockService} from 'app/services/lock.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {RichTextService, RichTextType} from 'app/services/rich-text.service';
import {SpellCheckerService} from 'app/spell-checker/spell-checker.service';
import {AbstractHovertipComponent} from 'app/widgets/abstract-hovertip.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-suggestions-hovertip',
  templateUrl: './suggestions-hovertip.component.html',
  styleUrls: ['./suggestions-hovertip.component.scss'],
})
export class SuggestionsHovertipComponent extends AbstractHovertipComponent implements OnInit, OnDestroy {
  private static readonly HOVERTIP_BASE_HEIGHT: number = 30;
  private static readonly ROW_HEIGHT: number = 18;

  private static readonly TWO_COLUMN_THRESHOLD: number = 4;
  private static readonly THREE_COLUMN_THRESHOLD: number = 7;

  @Input() public range: CarsRange;

  private _clause: ClauseFragment;

  public suggestions: string[];

  protected readonly leftOffset: number = -5;
  protected readonly topOffset: number = 10;

  private _isReordering: boolean = false;
  private _canEditPad: boolean = false;

  constructor(
    private _canvasService: CanvasService,
    private _richTextService: RichTextService,
    private _spellCheckerService: SpellCheckerService,
    private _lockService: LockService,
    private _reorderClausesService: ReorderClausesService,
    private _permissionsService: PermissionsService,
    protected _elementRef: ElementRef,
    protected _renderer: Renderer2
  ) {
    super(_elementRef, _renderer);
  }

  /**
   * Calls super ngOnInit() and finds parent clause.
   */
  public ngOnInit(): void {
    super.ngOnInit();
    this._clause = this.range[0].fragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    this.suggestions = this._spellCheckerService.getSpellingSuggestions(this.range);

    this._subscriptions.push(
      this._reorderClausesService.onReorderingEvent().subscribe((isReordering: boolean) => {
        this._isReordering = isReordering;
      }),
      this._permissionsService
        .can(CarsAction.EDIT_PAD, this._clause.documentId)
        .subscribe((canEditPad: boolean) => (this._canEditPad = canEditPad))
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Calculates the fixed height of the hovertip based on number of suggestions
   * And number of columns.
   *
   * @returns {number} Hovertip fixed height
   */
  public get hovertipHeight(): number {
    const baseHeight: number = SuggestionsHovertipComponent.HOVERTIP_BASE_HEIGHT;
    const rowHeight: number =
      SuggestionsHovertipComponent.ROW_HEIGHT * Math.ceil(this.suggestions.length / this.numberOfColumns);

    return baseHeight + rowHeight;
  }

  /**
   * @returns {number} Number of columns to split suggestions into
   */
  public get numberOfColumns(): number {
    return this._suggestionsLength(SuggestionsHovertipComponent.TWO_COLUMN_THRESHOLD, false)
      ? 1
      : this._suggestionsLength(SuggestionsHovertipComponent.THREE_COLUMN_THRESHOLD, false)
      ? 2
      : 3;
  }

  /**
   * Checks if the total amount of suggestions is greater/less than the given value.
   *
   * @param value       {number}  Value to check
   * @param greaterThan {boolean} True if should check suggestions greater than
   * @returns           {boolean} True if suggestions length is greater/less than given number
   */
  private _suggestionsLength(value: number, greaterThan: boolean = true): boolean {
    return greaterThan ? this.suggestions.length > value : this.suggestions.length < value;
  }

  /**
   * Getter for the title.
   *
   * @returns {string} Returns string for display
   */
  public get title(): string {
    return this._suggestionsLength(0) ? 'Suggestions:' : 'No suggestions';
  }

  /**
   * @returns {boolean} True if user can replace text with a suggestion.
   */
  public canReplace(): boolean {
    return this._canEditPad && this._lockService.canLock(this._clause) && !this._isReordering;
  }

  /**
   * Replaces the text in the range with the given suggestion.
   *
   * @param suggestion {string} Word to replace in the range
   */
  public onSelect(suggestion: string): void {
    this._canvasService.removeRanges([this.range]);
    this._richTextService.next(RichTextType.SPELLING_SUGGESTION, this.range, suggestion);
    this.destroy.next();
  }

  /**
   * Prevent clause blurring.
   * @param event Mousedown event.
   */
  @HostListener('mousedown.no-cd', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    event.preventDefault();
  }
}
