import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {ReviewStateService, UserReviewState} from 'app/services/review-state.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-version-review-status-toggle',
  templateUrl: './version-review-status-toggle.component.html',
  styleUrls: ['./version-review-status-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionReviewStatusToggleComponent implements OnInit, OnDestroy {
  public userReviewState: UserReviewState;

  private _subscriptions: Subscription[] = [];
  private _reviewStateSubscription: Subscription = null;

  constructor(
    private _versioningService: VersioningService,
    private _reviewStateService: ReviewStateService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._versioningService.getCurrentViewVersionTagObsStream().subscribe((versionTag: VersionTag) => {
        this._getReviewStateForVersion(versionTag);
        this._subscribeToReviewStateUpdates(versionTag.versionId);
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Gets the review status for the current user in the given document
   */
  private _getReviewStateForVersion(versionTag: VersionTag): void {
    this._reviewStateService
      .getCurrentUserStateForVersion(versionTag.fragmentId, versionTag.versionId)
      .then((state: UserReviewState) => {
        this.userReviewState = state;
        this._cdr.markForCheck();
      });
  }

  /**
   * Subscribes to any change of userReviewState for the given versionId, and updates the current reviewState if it is
   * from the same user.
   */
  private _subscribeToReviewStateUpdates(versionId: UUID): void {
    if (!!this._reviewStateSubscription) {
      this._reviewStateSubscription.unsubscribe();
    }

    this._reviewStateSubscription = this._reviewStateService.onReviewStateSave((reviewState: UserReviewState) => {
      if (reviewState.userId.equals(this.userReviewState?.userId)) {
        this.userReviewState = reviewState;
        this._cdr.markForCheck();
      }
    }, versionId);
  }
}
