import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdministrationUtils} from 'app/documents/administration-utils';
import {Administration} from 'app/documents/administrations';
import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {ClauseFragment, ClauseType, Fragment, FragmentType, SectionFragment, TextFragment} from 'app/fragment/types';
import {ClauseGroupFragment} from 'app/fragment/types/clause-group-fragment';
import {ClauseGroupType} from 'app/fragment/types/clause-group-type';
import {ReadonlyFragment} from 'app/fragment/types/readonly-fragment';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {TargetDocumentType} from 'app/fragment/types/reference/target-document-type';
import {StandardFormatType} from 'app/fragment/types/standard-format-type';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Observable, Subject} from 'rxjs';
import {BaseService} from './base.service';
import {DocumentService} from './document.service';
import {FragmentService} from './fragment.service';

interface StandardFormatGroupJsonConfig {
  fragmentToCreate: object;
  internalReferences: object[];
}

export interface StandardFormatGroupConfig {
  fragmentToCreate: ClauseGroupFragment;
  internalReferences: InternalDocumentReferenceFragment[];
}

@Injectable({
  providedIn: 'root',
})
export class ClauseGroupService extends BaseService {
  private readonly _baseUrl: string = `${environment.apiHost}/clause-group`;

  // We store an object instead of a Fragment as we can't deserialise fragments with a null id, and
  // we don't want to set any default ids in the template fragments as these should get set on fragment creation.
  // Instead we store an object and then set the ids and deserialise when getting the template fragment from the record.
  private _cachedStandardFormatGroupTemplates: Partial<Record<StandardFormatType, StandardFormatGroupJsonConfig>>;

  // Subject to track when the SFR templates have been loaded so that a single `true` event is emitted when loaded.
  private _standardFormatTemplateCacheLoaded: Subject<boolean> = new Subject();

  constructor(
    protected _snackbar: MatSnackBar,
    private _http: HttpClient,
    private _fragmentService: FragmentService,
    private _documentService: DocumentService
  ) {
    super(_snackbar);
    this._loadStandardFormatGroupTemplates();
  }

  private _loadStandardFormatGroupTemplates(): void {
    this._http
      .get<Record<StandardFormatType, StandardFormatGroupJsonConfig>>(
        `${this._baseUrl}/standard-format-group-creation-config`
      )
      .toPromise()
      .then((responseMap: Record<StandardFormatType, StandardFormatGroupJsonConfig>) => {
        this._cachedStandardFormatGroupTemplates = responseMap;

        this._standardFormatTemplateCacheLoaded.next(true);
      })
      .catch((error: any) => {
        this._handleError(
          error,
          'Failed to fetch the standard format group creation configuration',
          'configuration-error'
        );
        return Promise.reject(error);
      });
  }

  /**
   * Returns the ClauseGroupFragment corresponding to a new Nationally Determined Requirement to be inserted into the
   * pad.
   */
  public getNationallyDeterminedRequirementToCreate(): ClauseGroupFragment {
    const clauseGroup: ClauseGroupFragment = new ClauseGroupFragment(
      null,
      [],
      ClauseGroupType.NATIONAL_DETERMINED_REQUIREMENT,
      null
    );

    AdministrationUtils.getAllAdministrations().forEach((administration: Administration) =>
      clauseGroup.children.push(this.getDefaultClauseForNDRWithAdministration(administration))
    );

    return clauseGroup;
  }

  /**
   * Returns a requirement clause populated with the default text with the given administration set.
   * This is used for the initial default clauses that populate a nationally determined requirement
   * clause group.
   */
  public getDefaultClauseForNDRWithAdministration(administration: Administration): ClauseFragment {
    return new ClauseFragment(
      null,
      ClauseType.REQUIREMENT,
      [new TextFragment(null, ClauseGroupFragment.DEFAULT_NATIONALLY_DETERMINED_REQUIREMENT_TEXT)],
      '',
      null,
      administration,
      null,
      null,
      null,
      false
    );
  }

  /**
   * Returns a subject that emits a single event when the templates are first loaded. Note that if templates are
   * already loaded when this method is called then no events will be emitted.
   */
  public onStandardFormatGroupTemplatesLoad(): Observable<boolean> {
    return this._standardFormatTemplateCacheLoaded.asObservable();
  }

  /**
   * Returns the list of non deprecated standard format groups that can be created.
   */
  public getAllStandardFormatGroupFragments(): StandardFormatGroupConfig[] {
    return Object.keys(StandardFormatType)
      .map((type: StandardFormatType) => {
        return {
          fragmentToCreate: this.getStandardFormatGroupToCreate(type),
          internalReferences: this.getInternalReferences(type),
        };
      })
      .filter((f) => !!f && !!f.fragmentToCreate);
  }

  /**
   * Gets the list of deprecated SFR types, defined as those that are not included within the cached template map.
   * Note that if the cache has not loaded yet then this will return an empty list - see onTemplatesLoad to get
   * notified when the load occurs.
   */
  public getDeprecatedStandardFormatTypes(): StandardFormatType[] {
    return Object.keys(StandardFormatType)
      .map((type: StandardFormatType) => type)
      .filter((type) => !!this._cachedStandardFormatGroupTemplates && !this._cachedStandardFormatGroupTemplates[type]);
  }

  /**
   * Returns the ClauseGroupFragment that should be created to insert an SFR of the given type into the pad.
   */
  public getStandardFormatGroupToCreate(type: StandardFormatType): ClauseGroupFragment {
    const fragTemplate: object = this._cachedStandardFormatGroupTemplates[type]?.fragmentToCreate;
    if (!fragTemplate) {
      return null;
    }

    const template: object = this._copyTemplateAndSetIds(null, fragTemplate);
    return FragmentMapper.deserialise(template) as ClauseGroupFragment;
  }

  public getInternalReferencesToCreate(
    standardFormatGroup: ClauseGroupFragment,
    normativeReferenceSection: SectionFragment
  ): InternalDocumentReferenceFragment[] {
    const internalReferences: InternalDocumentReferenceFragment[] = this.getInternalReferences(
      standardFormatGroup.standardFormatType
    );
    const newInternalReferences: InternalDocumentReferenceFragment[] = [];

    internalReferences?.forEach((internalReference) => {
      const inlineReferences: InternalInlineReferenceFragment[] = [];
      standardFormatGroup.iterateDown(null, null, (frag: Fragment) => {
        if (
          frag.is(FragmentType.INTERNAL_INLINE_REFERENCE) &&
          (frag as InternalInlineReferenceFragment).internalDocumentReferenceId.equals(internalReference.id)
        ) {
          inlineReferences.push(frag as InternalInlineReferenceFragment);
        }
      });

      const existingReference: Fragment = normativeReferenceSection?.children[0].children.find(
        (docRef) =>
          docRef.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE) &&
          (docRef as InternalDocumentReferenceFragment).targetSectionId.equals(internalReference.targetSectionId) &&
          (docRef as InternalDocumentReferenceFragment).internalReferenceType ===
            internalReference.internalReferenceType
      );

      if (existingReference) {
        // update inline reference to use existing
        inlineReferences.forEach((frag) => (frag.internalDocumentReferenceId = existingReference.id));
      } else {
        // randomise id and add to return list
        const referenceToCreate: InternalDocumentReferenceFragment = FragmentMapper.deserialise(
          this._copyTemplateAndSetIds(null, internalReference)
        ) as InternalDocumentReferenceFragment;

        normativeReferenceSection?.children[0].children.push(referenceToCreate);
        referenceToCreate.inferWeight();

        inlineReferences.forEach((frag) => (frag.internalDocumentReferenceId = referenceToCreate.id));
        newInternalReferences.push(referenceToCreate);
      }
    });

    return newInternalReferences;
  }

  private getInternalReferences(type: StandardFormatType): InternalDocumentReferenceFragment[] {
    const fragTemplate: object[] = this._cachedStandardFormatGroupTemplates[type]?.internalReferences;
    if (!fragTemplate) {
      return null;
    }

    const currentDocumentId: UUID = this._documentService.getSelected().documentId;

    return fragTemplate.map((template) => {
      const ref = FragmentMapper.deserialise(template) as InternalDocumentReferenceFragment;
      ref.targetDocumentType = ref.targetDocumentId.equals(currentDocumentId)
        ? TargetDocumentType.SAME_DOCUMENT
        : TargetDocumentType.DIFFERENT_DOCUMENT;
      return ref;
    });
  }

  private _copyTemplateAndSetIds(parent: object, fragment: object): object {
    const fragmentCopy: object = Object.assign({}, fragment);
    fragmentCopy['id'] = UUID.random().value;
    if (parent) {
      fragmentCopy['parentId'] = parent['id'];
    }

    if (fragmentCopy['targetDocumentId']) {
      fragmentCopy['targetDocumentId'] = fragmentCopy['targetDocumentId'].value;
    }

    if (fragmentCopy['targetSectionId']) {
      fragmentCopy['targetSectionId'] = fragmentCopy['targetSectionId'].value;
    }

    fragmentCopy['children'] = Array.from(fragmentCopy['children']).map((child: object) =>
      this._copyTemplateAndSetIds(fragmentCopy, child)
    );
    return fragmentCopy;
  }

  public createDeletePlaceholderClauses(clauseGroup: Fragment) {
    const administrationCount: Record<Administration, number> = {
      ENGLAND: 0,
      NORTHERN_IRELAND: 0,
      SCOTLAND: 0,
      WALES: 0,
    };
    const administrationCountTotals: Record<Administration, number> = {
      ENGLAND: 0,
      NORTHERN_IRELAND: 0,
      SCOTLAND: 0,
      WALES: 0,
    };
    const lastClauseInAdministration: Record<Administration, Fragment> = {
      ENGLAND: null,
      NORTHERN_IRELAND: null,
      SCOTLAND: null,
      WALES: null,
    };

    let currentAdministration: Administration;

    clauseGroup.children.forEach((element) => {
      currentAdministration = (element as ClauseFragment | ClauseGroupFragment).administration || currentAdministration;

      if (element.is(FragmentType.CLAUSE) && !element.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) {
        administrationCountTotals[currentAdministration] += 1;
        const clause: ClauseFragment = element as ClauseFragment;
        if (!clause.isUnmodifiableClause) {
          administrationCount[currentAdministration] += 1;
        }
      } else if (element.is(FragmentType.CLAUSE_GROUP)) {
        for (let i = 0; i < element.children.length; i++) {
          if (!element.children[i].isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION)) {
            administrationCountTotals[currentAdministration] += 1;
            administrationCount[currentAdministration] += 1;
          }
        }
      }
      lastClauseInAdministration[currentAdministration] = element;
    });
    const maxAdministrationCount: number = Math.max(...Object.values(administrationCount));

    const listClauseToCreate: Fragment[] = [];
    const listClauseToDelete: Fragment[] = [];

    AdministrationUtils.getAllAdministrations().forEach((administration) => {
      for (let i = 0; i < maxAdministrationCount - administrationCountTotals[administration]; i++) {
        const newClause: ClauseFragment = new ClauseFragment(
          null,
          ClauseType.REQUIREMENT,
          [new ReadonlyFragment(null, ClauseGroupFragment.DEFAULT_NATIONALLY_DETERMINED_REQUIREMENT_TEXT)],
          '',
          null,
          administration,
          null,
          null,
          null,
          true
        );
        newClause.insertAfter(lastClauseInAdministration[administration]);
        listClauseToCreate.push(newClause);
      }

      for (let i = 0; i < administrationCountTotals[administration] - maxAdministrationCount; i++) {
        let clauseToDelete: Fragment = lastClauseInAdministration[administration];
        while (!clauseToDelete.isClauseOfType(ClauseType.REQUIREMENT)) {
          clauseToDelete = clauseToDelete.previousSibling();
        }
        listClauseToDelete.push(clauseToDelete);
        lastClauseInAdministration[administration] = clauseToDelete.previousSibling();
        clauseToDelete.remove();
      }
    });
    this._fragmentService.create(listClauseToCreate);
    this._fragmentService.delete(listClauseToDelete);
  }
}
