import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AltAccessibilityComponent} from './alt-accessibility.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AltAccessibilityComponent],
  exports: [AltAccessibilityComponent],
})
export class AltAccessibilityModule {}
