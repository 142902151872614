/**
 * An enumeration of the suites available in CARS.
 */
export enum Suite {
  DMRB = 'DMRB',
  LEGACY_DMRB = 'LEGACY_DMRB',
  MCHW = 'MCHW',
  LEGACY_MCHW = 'LEGACY_MCHW',
  MOMHW = 'MOMHW',
  UNRESTRICTED = 'UNRESTRICTED',
}
