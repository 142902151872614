/**
 * Compare function for reference titles. If one of the titles does not exist, order it to the end of the list.
 * If both exist, then sort their lower case values using localeCompare.
 */
export const referenceTitleCompareFunction: (aTitle: string, bTitle: string) => number = (
  aTitle: string,
  bTitle: string
) => {
  if (!aTitle) {
    return 1;
  } else if (!bTitle) {
    return -1;
  } else {
    return aTitle.toLowerCase().localeCompare(bTitle.toLowerCase());
  }
};
