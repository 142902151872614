import {loadModule} from 'hunspell-asm';

// Create a wrapper object for loadModule
const HunspellModuleWrapper = {
  loadModule: async (initOptions) => {
    return loadModule(initOptions);
  },
};

export {HunspellModuleWrapper};
