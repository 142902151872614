import {CarsRangeType} from 'app/fragment/cars-range';
import {Callback, Dictionary} from 'app/utils/typedefs';

/**
 * A class representing a Div used by the {@link CanvasComponent}.
 *
 * @field type    {CarsRangeType} The type of this div
 * @field element {HTMLElement}   The div element
 */
export class Div {
  private static readonly CSS_CLASS_NAMES: Map<CarsRangeType, string> = new Map([
    [CarsRangeType.SELECTION, 'canvas-selection'],
    [CarsRangeType.SUGGESTION, 'canvas-suggestion'],
    [CarsRangeType.PENDING_SUGGESTION, 'canvas-pending-suggestion'],
    [CarsRangeType.SPELLING_ERROR, 'canvas-spelling-error'],
    [CarsRangeType.CHANGELOG_RANGE, 'canvas-changelog-range'],
    [CarsRangeType.DELETED_CHANGELOG_RANGE, 'canvas-deleted-changelog-range'],
    [CarsRangeType.SEARCH_RESULT, 'canvas-search-result'],
    [CarsRangeType.SELECTED_SEARCH_RESULT, 'canvas-selected-search-result'],
  ]);

  public type: CarsRangeType;
  public element: HTMLElement;

  private _eventListeners: Dictionary<Callback<Event>[]> = {};

  /**
   * Static factory to create empty instances of a div. Nulling type.
   *
   * @returns {Div} Div to return
   */
  public static empty(): Div {
    return new Div();
  }

  constructor(type: CarsRangeType = null) {
    this.type = type;
    this.create();
  }

  /**
   * Creates the div element if it does not exist already.
   */
  public create(): void {
    if (this.element) {
      return;
    }

    this.element = document.createElement('div');
    this.element.style.position = 'absolute';
    this.element.style.backgroundColor = null;
  }

  /**
   * Removes element from the DOM.
   */
  public remove(): void {
    this.element.remove();
    this.element = null;
  }

  /**
   * Applies styling.
   *
   * @param type             {CarsRangeType} Type of div to style
   * @param rect             {DOMRect}    The client bounding rect
   * @param offsetClientRect {DOMRect}    The offset element to determine where to draw the co-ordinates
   */
  public style(type: CarsRangeType, clientRect: DOMRect, offsetClientRect: DOMRect): void {
    this.type = type;

    const offsetX: number = offsetClientRect.left + 1;
    const offsetY: number = offsetClientRect.top + 1;

    const isSpellingError: boolean = this.is(CarsRangeType.SPELLING_ERROR);
    const top: number = isSpellingError ? clientRect.bottom - offsetY - 6 : clientRect.top - offsetY;
    const height: number = isSpellingError ? 8 : clientRect.height;

    this.element.style.top = top + 'px';
    this.element.style.left = clientRect.left - offsetX + 'px';
    this.element.style.width = Math.max(clientRect.width, 2) + 'px';
    this.element.style.height = height + 'px';
    this.element.classList.add(Div.CSS_CLASS_NAMES.get(this.type));
  }

  /**
   * Attaches an event listener to this element with the given callback.
   *
   * @param event    {string}          Name of the event to subscribe to
   * @param callback {CallBack<Event>} The callback to the event
   */
  public addEventListener(event: string, callback: Callback<Event>): void {
    const callbacks: Callback<Event>[] = this._eventListeners[event] || [];
    callbacks.push(callback);
    this._eventListeners[event] = callbacks;

    this.element.addEventListener(event, callback);
  }

  /**
   * Removes styling on div, removes event listeners and nulls the type.
   */
  public recycle(): void {
    this.element.style.top = '0px';
    this.element.style.left = '0px';
    this.element.style.width = '0px';
    this.element.style.height = '0px';
    this.element.style.backgroundColor = null;
    this.element.classList.remove(Div.CSS_CLASS_NAMES.get(this.type));

    this._removeEventListeners();
    this.type = null;
  }

  /**
   * Removes event listeners on the div element.
   */
  private _removeEventListeners(): void {
    Object.keys(this._eventListeners).forEach((event: string) => {
      const callbacks: Callback<Event>[] = this._eventListeners[event];
      callbacks.forEach((callback: Callback<Event>) => {
        this.element.removeEventListener(event, callback);
      });
    });
    this._eventListeners = {};
  }

  /**
   * Returns true if this div has one of the passed types.  Also returns true if
   * passed no arguments, since an empty intersection is tautologically true.
   *
   * @param types {CarsRangeType[]}  The range types
   * @returns     {boolean}          True if one of these types
   */
  public is(...types: CarsRangeType[]): boolean {
    return types.length === 0 || types.indexOf(this.type) >= 0;
  }
}
