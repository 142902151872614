<button
  *ngIf="showCreateUser()"
  aria-label="Create new user"
  mat-button
  matTooltip="Create new user"
  (click)="createUser()"
  class="create-user"
>
  <mat-icon>add</mat-icon>
</button>
<div class="user-list-autocomplete">
  <div *ngIf="isSingleUserAssignment && assignedUsers.length > 0" class="user-information-title">
    <mat-icon [innerHtml]="properties.mdIcon"></mat-icon>
    {{ properties.title }}
  </div>
  <mat-form-field *ngIf="!(isSingleUserAssignment && assignedUsers.length > 0)" [hintLabel]="hintLabel">
    <input
      matInput
      [placeholder]="properties.title"
      [matAutocomplete]="autoCompleteComponent"
      name="users"
      [disabled]="properties.canAssignUser | carsDisabled: document.id | async"
      [(ngModel)]="userFilter"
      (ngModelChange)="filterUsers()"
    />
    <span matPrefix><mat-icon [innerHtml]="properties.mdIcon"></mat-icon>&nbsp;</span>
    <mat-hint *ngIf="noUsersFound()" [ngStyle]="{color: 'red'}" [align]="!assignedUsers.length ? 'end' : 'start'">{{
      properties.noUsersFoundMessage
    }}</mat-hint>
  </mat-form-field>
  <mat-autocomplete #autoCompleteComponent="matAutocomplete">
    <div *ngIf="noUsersFound()" class="no-user-found-message">
      {{ properties.noUsersFoundMessage }}
      Are you sure you didn't mean any of the users below?
    </div>
    <mat-option
      *ngFor="let userItem of filterResults"
      [disabled]="userItem.inDocument"
      [value]="userItem.user.name"
      matTooltip="User already assigned to a role in this document"
      matTooltipPosition="left"
      [matTooltipDisabled]="!userItem.inDocument"
      [matTooltipShowDelay]="tooltipDelay"
      (onSelectionChange)="assignUser(userItem.user.id, $event); clearFilter($event)"
      (click)="preventDefaultEvent($event)"
    >
      {{ userItem?.user.name }} ({{ userItem?.user.email }})
    </mat-option>
    <div *ngIf="additionalUsersMessage" class="additional-users-message">{{ additionalUsersMessage }}</div>
  </mat-autocomplete>
  <mat-list class="mat-list">
    <mat-list-item *ngFor="let userId of assignedUsers">
      <cars-assigned-user
        [document]="document"
        [userId]="userId"
        [role]="role"
        [showToggle]="showUserStatusToggles"
        [userHasDisabledStatus]="userAuthoringStatuses[userId.value]"
      ></cars-assigned-user>
    </mat-list-item>
  </mat-list>
</div>
