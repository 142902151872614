export enum DisciplineDMRB {
  GENERAL_PRINCIPLES = 'General Principles & Scheme Governance',
  SUSTAINABILITY_AND_ENVIRONMENT = 'Sustainability & Environment',
  ROAD_LAYOUT = 'Road Layout',
  PAVEMENT = 'Pavement',
  HIGHWAY_STRUCTURES_AND_BRIDGES = 'Highway Structures & Bridges',
  GEOTECHNICS = 'Geotechnics',
  DRAINAGE = 'Drainage',
  CONTROL_COMMUNICATIONS_TECHNOLOGY = 'Control & Communications Technology',
  ROAD_LIGHTING = 'Road Lighting',
}
