import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {HistoryComponent} from './history.component';
import {TransitionModule} from './transition/transition.module';
import {VersionModule} from './version/version.module';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    FormsModule,
    MatOptionModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    TransitionModule,
    VersionModule,
    AltAccessibilityModule,
  ],
  declarations: [HistoryComponent],
  exports: [HistoryComponent],
})
export class HistoryModule {}
