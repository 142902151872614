export class IndexUtils {
  public static generateAlphaIndex(index: number, uppercase: boolean): string {
    const charCodeStart: string = uppercase ? 'A' : 'a';
    let label: string = '';
    while (--index >= 0) {
      label = String.fromCharCode(charCodeStart.charCodeAt(0) + (index % 26)) + label;
      index /= 26;
    }
    return label;
  }
}
