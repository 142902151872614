<div class="publication-options-box">
  <p><mat-icon>info</mat-icon><span class="publication-options-title">Version publication</span></p>

  <ng-container *ngIf="CarsAction.SHOW_VERSION_PUBLICATION_OPTIONS_IN_OVERVIEW | carsEnabled: documentId | async">
    <p class="version-information">
      Since this version has a type of ‘For approval/authorisation’, the live document has been locked for editing until
      this version is either marked as ‘Published’ or ‘Changes required’.
    </p>

    <p class="version-information">
      Please note: the ‘Published’ button is only available when a ‘Date of publication’ is provided.
    </p>

    <mat-form-field>
      <input
        matInput
        readonly
        required
        [matDatepicker]="picker"
        (click)="picker.open()"
        placeholder="Date of publication"
        [formControl]="dateFormControl"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button
      mat-raised-button
      (click)="publish()"
      matTooltip="Mark version as published"
      [matTooltipShowDelay]="tooltipDelay"
      matTooltipPosition="below"
      [disabled]="dateFormControl.invalid"
      aria-label="Mark version as published"
    >
      Publish
    </button>

    <button
      mat-raised-button
      (click)="changesRequired()"
      matTooltip="Mark version as changes required"
      [matTooltipShowDelay]="tooltipDelay"
      matTooltipPosition="below"
      aria-label="Mark version as changes required"
    >
      Changes required
    </button>
  </ng-container>

  <ng-container *ngIf="CarsAction.SHOW_VERSION_PUBLICATION_OPTIONS_IN_OVERVIEW | carsDisabled: documentId | async">
    <p class="version-information">
      The live document is read only as this version has been marked as ‘For approval/authorisation’. TAGG are able to
      complete the publication or mark if more changes required, both of which will unlock the document for editing.
    </p>
  </ng-container>
</div>
