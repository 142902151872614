import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {DocumentFragment} from 'app/fragment/types';
import {Breadcrumb} from 'app/interfaces';
import {DocumentService} from 'app/services/document.service';
import {SectionService} from 'app/services/section.service';
import {DisabledUserService} from 'app/services/user/disabled-user.service';
import {UserService} from 'app/services/user/user.service';
import {Browser} from 'app/utils/browser';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {UUID} from 'app/utils/uuid';
import {ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentResolverService implements Resolve<DocumentFragment> {
  constructor(
    private documentService: DocumentService,
    private sectionService: SectionService,
    private viewService: ViewService,
    private userService: UserService,
    private disabledUserService: DisabledUserService,
    private router: Router
  ) {}

  /**
   * Resolve the live document route.
   *
   * @inheritdoc
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<DocumentFragment> {
    const id: string = route.paramMap.get('document_id');

    if (LocalConfigUtils.getConfig().offline) {
      this.router.navigateByUrl(`/documents/${id}/versions`);
      return null;
    }

    this.viewService.setCurrentView(ViewMode.LIVE);

    return this.documentService
      .load(UUID.orNull(id), {
        projection: 'INITIAL_DOCUMENT_LOAD',
        setLocation: true,
      })
      .then((document: DocumentFragment) => {
        if (this.userIsAReviewer(document) && !this.userService.isAdmin()) {
          this.router.navigateByUrl(`/documents/${id}/versions`);
          return null;
        }
        const breadcrumbs: Breadcrumb[] = [
          {title: 'Documents', link: '/documents'},
          {title: document.title, link: `/documents/${document.id.value}`},
        ];
        route.data = {breadcrumbs: breadcrumbs};

        this._ensureSupportedForAuthoring();

        this.documentService.setSelected(document);
        this.viewService.setCurrentView(ViewMode.LIVE);
        this.sectionService.setSelected(null);
        this.sectionService.clearSectionUndoBuffer();
        this.viewService.setLocation(CurrentLocation.DOCUMENT_OVERVIEW);
        return this.disabledUserService.populateStatusesForDocument(document.id).then(() => {
          return document;
        });
      });
  }

  private userIsAReviewer(document: DocumentFragment): boolean {
    const userUUID: UUID = this.userService.getUser().id;
    return document.documentData.reviewers.findIndex((uuid: UUID) => userUUID.equals(uuid)) > -1;
  }

  /**
   * Ensure that the user is using a browser supported for authoring.
   */
  private _ensureSupportedForAuthoring(): void {
    const unsupported = Browser.isInternetExplorer();

    if (unsupported) {
      alert(
        'Your browser has been detected as Microsoft Edge or Internet Explorer, which are unsupported for authoring.\n' +
          'Please use Firefox ESR or Chrome instead.'
      );
    }
  }
}
