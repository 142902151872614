<h1 mat-dialog-title>Add standard format requirement</h1>
<p class="guidance-text">
  Please note: You cannot change the type of standard format requirement from within the document once it has been
  inserted. If this is required, you will need to delete and re-insert the standard format requirement using the
  appropriate type.
</p>

<div class="content">
  <cars-standard-format-group-list-options
    [standardFormatGroupConfig]="standardFormatGroupConfig"
  ></cars-standard-format-group-list-options>
  <cars-standard-format-group-example
    [standardFormatGroupConfig]="standardFormatGroupConfig"
  ></cars-standard-format-group-example>
</div>

<div mat-dialog-actions>
  <div class="flex-grow"></div>
  <button mat-button color="primary" (click)="cancel()" aria-label="Cancel creating standard format requirement">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!selectedType"
    (click)="confirm()"
    aria-label="Create standard format requirement"
  >
    Insert
  </button>
</div>
