import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {SectionReferenceHovertipComponent} from '../../inline-section-reference/section-reference-hovertip/section-reference-hovertip.component';
import {ReferenceInputFragment} from '../../types/input/reference-input-fragment';
@Component({
  selector: 'cars-reference-input-wrapper',
  templateUrl: './reference-input-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceInputWrapperComponent implements OnDestroy {
  @Input() public content: ReferenceInputFragment;
  @Input() public showHovertip: boolean = false;

  private _hovertipComponent: ComponentRef<SectionReferenceHovertipComponent>;
  private _hoverTimeout: number = null;

  private _subscriptions: Subscription[] = [];

  constructor(
    protected _cdr: ChangeDetectorRef,
    private _viewContainerRef: ViewContainerRef,
    private _elementRef: ElementRef
  ) {}

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  @HostListener('mouseover', ['$event'])
  public mouseover(event: MouseEvent): void {
    if (this._hovertipComponent || !this.showHovertip) {
      return;
    }

    if (!this._hoverTimeout) {
      this._hoverTimeout = window.setTimeout(() => {
        this._hoverTimeout = null;
        this._createHovertip(event);
        this._cdr.markForCheck();
      }, 200);
    }
  }

  @HostListener('mouseleave', ['$event'])
  public mouseleave(event: MouseEvent): void {
    if (this._hoverTimeout) {
      window.clearTimeout(this._hoverTimeout);
      this._hoverTimeout = null;
      this._cdr.markForCheck();
    }
  }

  private _createHovertip(event: MouseEvent): void {
    this._hovertipComponent = this._viewContainerRef.createComponent(SectionReferenceHovertipComponent);
    this._hovertipComponent.instance.componentRef = this._hovertipComponent;
    this._hovertipComponent.instance.referenceInput = this.content;
    this._hovertipComponent.instance.event = event;
    this._hovertipComponent.instance.anchorElement = this._elementRef.nativeElement;
    this._hovertipComponent.instance.internalReferenceType = this.content.internalReferenceType;
    this._subscriptions.push(
      this._hovertipComponent.instance.destroy.subscribe(() => {
        this._hovertipComponent = null;
        this._cdr.markForCheck();
      })
    );
  }
}
