import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy} from '@angular/router';

// As you can't import the DefaultrouteReuseStrategy, we have to implement it ourselves.
// The default behaviour was taken from https://github.com/angular/angular/issues/16184
export class DefaultRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}

@Injectable()
export class CustomRouteReuseStrategy extends DefaultRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    // This prevents the reuse of routes if navigating from a section to a section.
    // It dramatically increases the performance of navigating between large sections in firefox ESR 52.4.
    if (this._doNotReuseRoute(future.routeConfig, current.routeConfig)) {
      return false;
    }
    return super.shouldReuseRoute(future, current);
  }

  private _doNotReuseRoute(futureConfig: Route, currentConfig: Route): boolean {
    return (
      futureConfig &&
      currentConfig &&
      this._bothPathsEndIn(futureConfig.path, currentConfig.path, 'sections/:section_id')
    );
  }

  private _bothPathsEndIn(futurePath: string, currentPath: string, end: string): boolean {
    return futurePath && currentPath && futurePath.endsWith(end) && currentPath.endsWith(end);
  }
}
