import {PadType} from 'app/element-ref.service';
import {Observable, of} from 'rxjs';
import {ConditionASTNode} from '../types/condition-ast-node';
import {PermissionsHandler} from './permissions-handler';

/**
 * A decorator factory for the handle method of the permissions handlers which returns of(false) if the passed
 * in pad type is one of the given pad types.
 *
 * @param padTypes  {PadType[]} The pad types to return false for
 */
export const FalseOnPadTypes = (...padTypes: PadType[]) => {
  return <T extends PermissionsHandler>(
    target: T,
    propertyKey: 'handle',
    descriptor: TypedPropertyDescriptor<(condition: ConditionASTNode, padType: PadType) => Observable<boolean>>
  ) => {
    const handleMethod = descriptor.value;

    descriptor.value = function (condition: ConditionASTNode, padType: PadType) {
      if (padTypes.includes(padType)) {
        return of(false);
      }

      return handleMethod.apply(this, [condition, padType]) as Observable<boolean>;
    };

    return descriptor;
  };
};
