<div
  *ngIf="!clauseLinksValid && (CarsAction.SHOW_CLAUSE_LINK_MARGIN_ICON | carsEnabled: clause.documentId | async)"
  (click)="openSidebar()"
  matTooltip="There are invalid verification and/or documentation links for this clause"
  matTooltipPosition="before"
  [matTooltipShowDelay]="tooltipDelay"
  contenteditable="false"
>
  <mat-icon>link_off</mat-icon>
</div>
