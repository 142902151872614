<input
  hidden
  type="file"
  id="image-upload-input"
  (change)="handleUpload($event)"
  (click)="onClick()"
  accept="image/*"
/>
<button
  mat-button
  [matTooltip]="toolbarItem.tooltipText"
  matTooltipPosition="below"
  [matTooltipShowDelay]="tooltipDelay"
  [disabled]="!enabled"
  [attr.blurOption]="BlurOption.PREVENT_BLUR"
  [attr.aria-label]="toolbarItem.ariaLabel"
  (click)="uploadImageInputClick()"
>
  <mat-icon *ngFor="let icon of toolbarItem.icons">{{ icon }}</mat-icon>
  <cars-alt-accessibility
    [altProperties]="altProperties"
    [richText]="true"
    (onSelect)="handleAltSelect()"
  ></cars-alt-accessibility>
</button>
