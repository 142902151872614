import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ClauseFragment} from 'app/fragment/types';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {UserService} from 'app/services/user/user.service';
import {DocumentInformationItem} from 'app/suite-config/configuration.service';
import {BaseFormComponent} from '../base-form.component';

@Component({
  selector: 'cars-free-text-multiline-input',
  templateUrl: './free-text-multiline-input.component.html',
  styleUrls: ['./free-text-multiline-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeTextMultilineInputComponent extends BaseFormComponent implements OnInit {
  @Input() public set documentInformationItem(documentInformationItem: DocumentInformationItem) {
    this._clause = documentInformationItem.documentInformationFragment.parent as ClauseFragment;
    this.documentInformationType = documentInformationItem.documentInformationField.documentInformationType;
    this.titleText = documentInformationItem.documentInformationField.titleText;
    this.hintText = documentInformationItem.documentInformationField.hintText;
  }

  constructor(
    protected fragmentService: FragmentService,
    protected lockService: LockService,
    protected userService: UserService,
    protected versioningService: VersioningService,
    protected cdr: ChangeDetectorRef
  ) {
    super(fragmentService, lockService, userService, versioningService, cdr);
  }
}
