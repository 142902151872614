<div class="section-references-panel">
  <ng-container *ngIf="!showSectionReferenceSelection(); else selectSectionReferences">
    <div class="placeholder">
      The selected clause does not contain either a section or WSR document reference. This functionality is only
      available for clauses that require a section or WSR document reference to be defined.
    </div>
  </ng-container>

  <ng-template #selectSectionReferences>
    <div class="section-references-selection-area">
      <ng-container *ngIf="containsWsrReference(); else sectionReferencesGuidance">
        <p class="placeholder">
          Use this functionality to select the Works Specific Requirements (WSR) document reference within the selected
          clause.
        </p>
      </ng-container>
      <ng-template #sectionReferencesGuidance>
        <p class="placeholder">
          References between sections of the same document, or another document within the same suite, can be added
          using one of Standard Format Requirements provided.
        </p>
      </ng-template>

      <p class="placeholder">
        See the <a href="https://help.futuredmrb.co.uk/references/" target="_blank">help pages</a> for guidance on this
        functionality.
      </p>

      <mat-checkbox
        *ngIf="containsWsrReference()"
        class="checkbox"
        [(ngModel)]="currentSectionStaged"
        [disabled]="
          (stagedSectionReferences.length > 0 && !currentSectionStaged) ||
          (CarsAction.AUTHOR_DOCUMENT | carsDisabled: clause?.documentId | async)
        "
        (click)="selectWsrOfCurrentSection()"
        [attr.aria-label]="'Select this sections WSR mappings'"
        >Define WSR within the current section</mat-checkbox
      >
      <cars-document-selector
        [searchType]="DocumentsSearchType.SECTION_REFERENCES"
        [searchPlaceholder]="searchPlaceholder"
        (onSelect)="getAvailableInternalReferencesFromSelectedDocument($event)"
        [disabled]="stagedSectionReferences.length > 0 && currentSectionStaged"
      ></cars-document-selector>
      <mat-progress-bar *ngIf="loading" mode="indeterminate" aria-label="Loading"> </mat-progress-bar>

      <div *ngIf="!selectedDocumentId" class="placeholder">Please select a document.</div>

      <p *ngIf="selectedDocumentId" class="placeholder">
        The Technical Author for this document is {{ selectedDocumentTechnicalAuthor }}, please contact them for
        Reviewer access if you would like to read the section referenced.
      </p>
      <ng-container *ngIf="selectedDocumentId && selectedDocumentInternalReferences">
        <div class="placeholder" *ngIf="!selectedDocumentInternalReferences.length">
          This document has no sections that can be referenced. Please select a different document.
        </div>

        <mat-table #table [dataSource]="selectedDocumentInternalReferences">
          <ng-container matColumnDef="section">
            <mat-cell *matCellDef="let section">
              <div class="row-section-details">
                <div class="row-section-info">
                  <span *ngIf="!containsWsrReference()">{{ section.sectionIndex }} {{ section.sectionTitle }}</span>
                  <span *ngIf="containsWsrReference()">
                    {{ section.sectionIndex }} {{ section.sectionTitle }}<br />
                    {{ section.wsrCode }}
                  </span>
                </div>
              </div>
              <div class="row-action-button">
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="stageReferenceForCreation(section)"
                  (keyup.enter)="stageReferenceForCreation(section)"
                  matTooltip="Select section reference"
                  [matTooltipShowDelay]="tooltipDelay"
                  aria-label="Select section reference"
                  [disabled]="
                    !referenceCanBeStaged(section) ||
                    (CarsAction.AUTHOR_DOCUMENT | carsDisabled: clause?.documentId | async)
                  "
                >
                  Select
                </button>
              </div>
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </ng-container>
    </div>

    <div class="section-references-staging-area">
      <div class="staging-area-header">Selected References ({{ stagedSectionReferences.length }}):</div>
      <div class="placeholder no-staged-references" *ngIf="!stagedSectionReferences.length">No selected references</div>
      <div class="staged-references-table" *ngIf="!!stagedSectionReferences.length">
        <mat-table #table [dataSource]="stagedSectionReferences">
          <ng-container matColumnDef="section">
            <mat-cell *matCellDef="let section">
              <div
                class="row-section-details"
                [ngClass]="{warning: section.targetFragmentDeleted}"
                matTooltip="This section has been deleted from its parent document, and can no longer be referenced"
                [matTooltipShowDelay]="tooltipDelay"
                [matTooltipDisabled]="!section.targetFragmentDeleted"
              >
                <div class="row-document-info">
                  <span *ngIf="!containsWsrReference() && section.documentCode">{{ section.documentCode }} - </span>
                  <span *ngIf="!containsWsrReference()">{{ section.documentTitle }}</span>
                </div>
                <div class="row-section-info">
                  <span *ngIf="!containsWsrReference()">{{ section.sectionIndex }} {{ section.sectionTitle }}</span>
                  <span *ngIf="containsWsrReference()">
                    {{ section.documentCode }} - {{ section.documentTitle }}<br />
                    {{ section.sectionIndex }} {{ section.sectionTitle }}<br />
                    {{ section.wsrCode }}</span
                  >
                </div>
              </div>
              <div class="row-action-button">
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="unstageReferenceFromCreation(section)"
                  (keyup.enter)="unstageReferenceFromCreation(section)"
                  matTooltip="Deselect section reference"
                  [matTooltipShowDelay]="tooltipDelay"
                  aria-label="Deselect section reference"
                  [disabled]="CarsAction.AUTHOR_DOCUMENT | carsDisabled: clause?.documentId | async"
                >
                  Deselect
                </button>
              </div>
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>

      <div class="action-buttons">
        <button
          mat-button
          (click)="resetStagedChanges()"
          aria-label="Cancel selecting sections to reference"
          [disabled]="
            loading || !hasStagedChanges() || (CarsAction.AUTHOR_DOCUMENT | carsDisabled: clause?.documentId | async)
          "
        >
          Cancel
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="saveChanges()"
          aria-label="Save selection of sections to reference"
          [disabled]="
            loading || !hasStagedChanges() || (CarsAction.AUTHOR_DOCUMENT | carsDisabled: clause?.documentId | async)
          "
          enableDefaultFocus
        >
          Save selection
        </button>
      </div>
    </div>
  </ng-template>
</div>
