import {HttpResponse} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogComponent} from 'app/dialog/dialog/dialog.component';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {Caret} from 'app/fragment/caret';
import {CarsRange} from 'app/fragment/cars-range';
import {Suite} from 'app/fragment/suite';
import {
  ClauseFragment,
  ClauseReferenceTargetType,
  ClauseType,
  DocumentFragment,
  Fragment,
  FragmentType,
  InternalReferenceType,
  MemoFragment,
  ReferenceType,
  SectionType,
  TextFragment,
} from 'app/fragment/types';
import {ReferenceInputDisplayType, ReferenceInputFragment} from 'app/fragment/types/input/reference-input-fragment';
import {RequiredReferenceCount} from 'app/fragment/types/input/required-reference-count';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {TargetDocumentType} from 'app/fragment/types/reference/target-document-type';
import {CarsAction} from 'app/permissions/types/permissions';
import {DocumentSelectorComponent} from 'app/search/document-selector/document-selector.component';
import {DocumentsSearchType} from 'app/search/document-selector/documents-search-types';
import {
  SearchableDocument,
  SearchableDocumentColumn,
  SearchDocumentsService,
  SearchResult,
} from 'app/search/search-documents.service';
import {CanvasService} from 'app/services/canvas.service';
import {CaretService} from 'app/services/caret.service';
import {DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {
  ClauseDisplayReferenceUtils,
  DisplayInternalReferenceFragment,
} from 'app/services/references/reference-utils/clause-display-reference-utils';
import {ReferenceSortingUtils} from 'app/services/references/reference-utils/reference-sorting-utils';
import {SidebarService} from 'app/services/sidebar.service';
import {TdifService} from 'app/services/tdif.service';
import {TdifClause, TdifDocument, TdifHeadingClause, TdifSection} from 'app/tdif/tdif-types';
import {UUID} from 'app/utils/uuid';
import {Observable, Subscription} from 'rxjs';
import {SidebarStatus} from '../sidebar-status';

export interface ReferenceSearchParams {
  searchTerm: string;
  docId: string;
  page: number;
  size: number;
  listType: string;
}

enum ClauseReferenceDisplayType {
  GROUPING,
  REFERENCE,
}

interface SectionProperties {
  sectionIndex: string;
  clauseIdToIndexMap: Record<string, string>;
}

@Component({
  selector: 'cars-clause-references',
  templateUrl: './clause-references.component.html',
  styleUrls: ['./clause-references.component.scss'],
})
export class ClauseReferencesComponent implements OnInit, OnDestroy, OnChanges {
  private static readonly CONFIRM_CLEARING_SELECTION_MESSAGE: string = 'Confirm clearing existing clause selection';
  private static readonly CANCEL_CLEARING_SELECTION_MESSAGE: string = 'Cancel clearing existing clause selection';
  private static readonly CONTINUE_CLEARING_SELECTION_MESSAGE: string = 'Continue clearing existing clause selection';
  private static readonly HEADING_CLAUSE_TYPES: Readonly<ClauseType[]> = [
    ClauseType.HEADING_1,
    ClauseType.HEADING_2,
    ClauseType.HEADING_3,
  ];

  private static readonly SECTION_ORDERING_WEIGHT: number = 10000000;
  private static readonly REQUIREMENT_ORDERING_WEIGHT: number = 1000;
  private static readonly CLAUSE_ORDERING_WEIGHT: number = 1;

  public readonly DocumentsSearchType: typeof DocumentsSearchType = DocumentsSearchType;
  public readonly CarsAction: typeof CarsAction = CarsAction;
  public readonly searchPlaceholder: string = 'Select a document';
  @ViewChild(DocumentSelectorComponent) documentSelector: DocumentSelectorComponent;
  @Input() public clause: ClauseFragment;
  public referenceId: UUID;

  public documentId: UUID = null;
  public loading: boolean = false;
  public sections: TdifSection[] = [];
  public clauses: TdifClause[] = [];
  public clauseRefDisplayString = '';
  // The location at which new inline clause reference will be inserted:
  public inputCaret: Caret;
  public selectedDocument: SearchableDocument;
  public selectedSection: TdifSection;

  private _selectedDocumentId: UUID;
  private _internalReferencesStagedForCreation: Record<string, InternalDocumentReferenceFragment> = {};
  private _clauseIdsStagedForDeletion: UUID[] = [];
  private _existingSelectedDocument: DocumentFragment;
  private _cachedSectionPropertiesForDocument: Record<string, SectionProperties> = {};

  private _fragmentOrderingWeights: Record<string, number> = {};

  private _referenceInput: ReferenceInputFragment;
  private _existingInlineReferences: InternalInlineReferenceFragment[] = [];
  private _existingDocumentReferences: InternalDocumentReferenceFragment[] = [];
  private _subs: Subscription[] = [];

  constructor(
    private _caretService: CaretService,
    private _canvasService: CanvasService,
    private _documentService: DocumentService,
    private _searchDocumentsService: SearchDocumentsService,
    private _fragmentService: FragmentService,
    private _tdifService: TdifService,
    private _sidebarService: SidebarService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.documentId = this._documentService.getSelected().documentId;
    this._referenceInput = this._createReferenceInput();

    this._subs.push(
      this._caretService.onSelectionChange(
        (caret: CarsRange) => {
          this.inputCaret = caret.start;
          this._canvasService.drawCaret(caret);
          this._resetAllFields();
        },
        (caret: CarsRange) => caret && caret.isValidReferenceInsertion()
      ),
      this._sidebarService.getRefInputId().subscribe((id: UUID) => {
        this.referenceId = id;
        const refInput: ReferenceInputFragment = this.clause?.children.find(
          (frag: Fragment) =>
            frag.is(FragmentType.REFERENCE_INPUT) &&
            frag.id.equals(id) &&
            (frag as ReferenceInputFragment).internalReferenceType === InternalReferenceType.CLAUSE_REFERENCE
        ) as ReferenceInputFragment;
        this._referenceInput = refInput ? refInput : this._createReferenceInput();

        if (!!refInput) {
          this._refreshExistingReferencesList();
          this._loadSelectedReferenceDocumentById();
        }
        this._cdr.markForCheck();
      }),
      this._fragmentService.onCreate(
        () => {
          this._internalReferencesStagedForCreation = {};
          this._refreshExistingReferencesList();
        },
        (frag: Fragment) =>
          frag.parentId.equals(this._referenceInput?.id) ||
          this._existingInlineReferences.some((inline: InternalInlineReferenceFragment) =>
            inline.internalDocumentReferenceId.equals(frag.id)
          )
      ),
      this._fragmentService.onDelete(
        () => {
          this._clauseIdsStagedForDeletion = [];
          this._refreshExistingReferencesList();
        },
        (frag: Fragment) => frag.parentId.equals(this._referenceInput?.id)
      )
    );
    this._cdr.markForCheck();
  }

  private _loadSelectedReferenceDocumentById(): void {
    if (this._existingDocumentReferences.length === 0) {
      this._onLoadFailure(
        'Clause references: invalid existing reference. Please contact support, an existing reference group was found with no children'
      );
      return;
    }
    this._selectedDocumentId = this._existingDocumentReferences[0]?.targetDocumentId;

    this._searchDocumentsService
      .clauseReferenceByDocumentId(this._selectedDocumentId)
      .then((res: SearchResult<SearchableDocument>) => {
        if (res.page.length === 0) {
          return;
        }
        this.documentSelector.setSelection = res.page[0];
        this.handleSelectDocument(res.page[0]).then(() => {
          const targetSectionId = this._existingDocumentReferences[0]?.targetSectionId;
          const targetTdifSection = this.sections.find((section) => section.id.equals(targetSectionId));
          if (!!targetTdifSection) {
            this.handleSelectSection(targetTdifSection);
          }
        });
      })
      .catch((err) => Logger.error('internal-reference-error', 'Failed to fetch specific document', err));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.hasOwnProperty('clause')) {
      return;
    }

    if (
      changes.clause.previousValue &&
      changes.clause.currentValue &&
      changes.clause.previousValue.id.equals(changes.clause.currentValue.id)
    ) {
      return;
    }
    this._resetAllFields();
  }

  private _resetAllFields() {
    this._existingDocumentReferences = [];
    this._internalReferencesStagedForCreation = {};
    this._clauseIdsStagedForDeletion = [];
    this.referenceId = null;
    this._referenceInput = this._createReferenceInput();

    this.clauseRefDisplayString = '';
    this._cdr.markForCheck();
  }

  // Helper method to grab the internalDocumentReferences corresponding to each internalInlineReference.
  // If a InternalDocumentReference isn't found, a dummy InternalDocumentReference is saved in its place
  // with the corresponding InternalDocumentReferenceId with an error message.
  private _refreshExistingReferencesList(): void {
    if (this._referenceInput) {
      this._existingInlineReferences = this._referenceInput.children.map(
        (frag: Fragment) => frag as InternalInlineReferenceFragment
      );

      this._existingDocumentReferences = this._existingInlineReferences.map(
        (inline: InternalInlineReferenceFragment): InternalDocumentReferenceFragment => {
          const internalDocumentReference: InternalDocumentReferenceFragment = this._fragmentService.find(
            inline.internalDocumentReferenceId
          ) as InternalDocumentReferenceFragment;

          return !!internalDocumentReference
            ? internalDocumentReference
            : new InternalDocumentReferenceFragment(
                inline.internalDocumentReferenceId,
                null,
                null,
                null,
                null,
                UUID.random(), // Add random targetDocumentId to avoid null pointer issues elsewhere.
                UUID.random(), // Add random targetSectionId to avoid null pointer issues elsewhere.
                null,
                null,
                null,
                'Failed to load reference',
                null,
                null,
                true, // Mark as deleted in sidebar to show issue.
                null,
                null,
                ClauseType.NORMAL,
                null,
                null,
                null,
                ReferenceSortingUtils.ORDERING_WEIGHT_NOT_SET
              );
        }
      );
    }
    this._getReferenceString();
  }

  public ngOnDestroy(): void {
    this._subs.forEach((sub: Subscription) => sub.unsubscribe());
    this._canvasService.clearCaretHighlight();
  }

  public async handleSelectDocument(selectedDocument: SearchableDocument): Promise<void> {
    if (!selectedDocument) {
      return;
    }

    if (!selectedDocument[SearchableDocumentColumn.DOCUMENT_ID]) {
      this._onLoadFailure('Clause references: invalid selected document.');
      return;
    }

    if (
      (!!this.referenceId || this._referenceInput.children.length > 0) &&
      !!this._selectedDocumentId &&
      !selectedDocument[SearchableDocumentColumn.DOCUMENT_ID].equals(this._selectedDocumentId)
    ) {
      this._onLoadFailure(
        'Clause references: invalid selected document. To reference a new document, please delete the existing clause references and reselect a document.'
      );
      return;
    }

    if (this.changesMade()) {
      const message: string =
        'Changing document will clear existing clause selection. Are you sure you want to continue?';
      this._openDialog(message).subscribe((action: boolean) => {
        if (action) {
          this._internalReferencesStagedForCreation = {};
          this._clauseIdsStagedForDeletion = [];
          this._cachedSectionPropertiesForDocument = {};
          this._updateSectionProperties(null);
          this.clauseRefDisplayString = '';
          this.getAvailableSectionsFromSelectedDocument(selectedDocument);
          this._cdr.markForCheck();
        } else {
          this.documentSelector.setSelection = this.selectedDocument;
        }
      });
    } else {
      this.selectedSection = null;
      this._cachedSectionPropertiesForDocument = {};
      this._updateSectionProperties(null);
      await this.getAvailableSectionsFromSelectedDocument(selectedDocument);
    }
  }

  public async getAvailableSectionsFromSelectedDocument(selectedDocument: SearchableDocument): Promise<void> {
    this.loading = true;
    this.selectedDocument = selectedDocument;
    this._selectedDocumentId = selectedDocument.DOCUMENT_ID;
    await this._tdifService
      .generateTdifForDocument(this._selectedDocumentId)
      .toPromise()
      .then((tdifDoc: TdifDocument) => {
        this.sections = tdifDoc.sections.filter(
          (section: TdifSection) => section.sectionType === SectionType.NORMATIVE
        );

        this._populateClauseOrderingWeightMap(tdifDoc);

        this.loading = false;
        this._cdr.markForCheck();
      })
      .catch((err) => this._onLoadFailure('Clause references: invalid selected document.', err));
  }

  /**
   * Populates the ordering weights for all the returned sections and clauses within the TDIF. Note this has separate
   * requirement and other clause weights to minimise issues when new references are created and some existing ones are
   * out of date.
   */
  private _populateClauseOrderingWeightMap(tdifDoc: TdifDocument): void {
    this._fragmentOrderingWeights = {};
    tdifDoc.sections.forEach((section: TdifSection, sectionIndex: number) => {
      let requirementCount: number = 0;
      let clauseCountSinceRequirement: number = 0;

      section.clauses.forEach((clause: TdifClause) => {
        if (clause.clauseType === ClauseType.REQUIREMENT) {
          requirementCount++;
          clauseCountSinceRequirement = 0;
        } else {
          clauseCountSinceRequirement++;
        }

        this._fragmentOrderingWeights[clause.id.value] =
          (sectionIndex + 1) * ClauseReferencesComponent.SECTION_ORDERING_WEIGHT +
          requirementCount * ClauseReferencesComponent.REQUIREMENT_ORDERING_WEIGHT +
          clauseCountSinceRequirement * ClauseReferencesComponent.CLAUSE_ORDERING_WEIGHT;
      });
    });
  }

  public handleSelectSection(section: TdifSection): void {
    this.selectedSection = section;
    this._updateSectionProperties(section);
  }

  private _updateSectionProperties(section: TdifSection): void {
    if (!section) {
      this.clauses = [];
      return;
    }

    this.clauses = section.clauses.filter(
      (clause: TdifClause) => !!clause?.index || !!(clause as TdifHeadingClause).title
    );

    if (this._cachedSectionPropertiesForDocument[section.id.value]) {
      return;
    }

    const clauseIdToIndexMapForSection: Record<string, string> = this.clauses.reduce(
      (
        current: {
          previousRequirementOrAdviceClause: TdifClause;
          headingCounter: number;
          indexes: Record<string, string>;
          previousClauseType: ClauseType;
        },
        clause: TdifClause
      ) => {
        switch (clause.clauseType) {
          case ClauseType.REQUIREMENT:
          case ClauseType.ADVICE:
            current.indexes[clause.id.value] = clause.index;
            current.previousRequirementOrAdviceClause = clause;
            current.headingCounter = 0;
            current.previousClauseType = clause.clauseType;
            break;
          case ClauseType.HEADING_1:
          case ClauseType.HEADING_2:
          case ClauseType.HEADING_3:
            current.headingCounter = current.headingCounter + 1;
            current.indexes[clause.id.value] = this._buildHeadingIndexWithPreviousRequirement(
              current.previousRequirementOrAdviceClause,
              section,
              clause,
              current.headingCounter
            );
            current.previousClauseType = clause.clauseType;
            break;
          case ClauseType.SPECIFIER_INSTRUCTION:
            if (this.selectedDocument[SearchableDocumentColumn.SUITE] === Suite.MCHW) {
              current.indexes[clause.id.value] = clause.index;
              current.previousRequirementOrAdviceClause = null;
            } else {
              if (ClauseReferencesComponent.HEADING_CLAUSE_TYPES.includes(current.previousClauseType)) {
                current.previousRequirementOrAdviceClause = null;
              }
              current.indexes[clause.id.value] = this._buildIndexWithPreviousRequirement(
                clause,
                current.previousRequirementOrAdviceClause
              );
            }
            current.previousClauseType = clause.clauseType;
            break;
          case ClauseType.NOTE:
          case ClauseType.ITEM:
            if (ClauseReferencesComponent.HEADING_CLAUSE_TYPES.includes(current.previousClauseType)) {
              current.previousRequirementOrAdviceClause = null;
            }
            current.indexes[clause.id.value] = this._buildIndexWithPreviousRequirement(
              clause,
              current.previousRequirementOrAdviceClause
            );
            current.previousClauseType = clause.clauseType;
            break;
        }

        return current;
      },
      {previousRequirementOrAdviceClause: null, headingCounter: 0, indexes: {}, previousClauseType: null}
    ).indexes;

    this._cachedSectionPropertiesForDocument[section.id.value] = {
      sectionIndex: section.index,
      clauseIdToIndexMap: clauseIdToIndexMapForSection,
    };
  }

  private _buildHeadingIndexWithPreviousRequirement(
    previousRequirement: TdifClause,
    section: TdifSection,
    clause: TdifClause,
    headingCounter: number
  ): string {
    if (previousRequirement) {
      return this._constructHeadingIndex(previousRequirement, section, headingCounter);
    }

    const clausePosition: number = section.clauses.findIndex((clauseFrag) => clauseFrag.id.equals(clause.id));
    const clonedSectionClausesBeforeTarget: TdifClause[] = section.clauses.slice(0, clausePosition).reverse();
    const prevRequirement: TdifClause = clonedSectionClausesBeforeTarget.find(
      (secClause) => secClause.clauseType === ClauseType.REQUIREMENT || secClause.clauseType === ClauseType.ADVICE
    );

    return this._constructHeadingIndex(prevRequirement, section, headingCounter);
  }

  private _constructHeadingIndex(
    previousRequirement: TdifClause,
    section: TdifSection,
    headingCounter: number
  ): string {
    return !!previousRequirement
      ? `${previousRequirement.index} H${headingCounter}`
      : `${section.index}0 H${headingCounter}`;
  }

  private _buildIndexWithPreviousRequirement(clause: TdifClause, previousRequirement: TdifClause): string {
    return previousRequirement ? `${previousRequirement.index} ${clause.index}` : clause.index;
  }

  public updateSelectedClauses(clause: TdifClause, event: MatCheckboxChange): void {
    const isExistingRef: boolean = !!this._existingDocumentReferences.find(
      (referenceFragment: InternalDocumentReferenceFragment) => referenceFragment.targetFragmentId.equals(clause.id)
    );
    if (event.checked) {
      if (!this._internalReferencesStagedForCreation[clause.id.value] && !isExistingRef) {
        this._internalReferencesStagedForCreation[clause.id.value] =
          this._buildInternalDocumentReferenceFragmentFromClause(this.selectedSection.id, clause);
      } else if (isExistingRef && this._clauseIdsStagedForDeletion.includes(clause.id)) {
        this._clauseIdsStagedForDeletion.splice(this._clauseIdsStagedForDeletion.indexOf(clause.id), 1);
      }
    } else {
      if (isExistingRef && !this._internalReferencesStagedForCreation[clause.id.value]) {
        this._clauseIdsStagedForDeletion.push(clause.id);
      } else if (this._internalReferencesStagedForCreation[clause.id.value]) {
        delete this._internalReferencesStagedForCreation[clause.id.value];
      }
    }
    this._getReferenceString();
  }

  public isChecked(clause: TdifClause): boolean {
    return (
      !!this._internalReferencesStagedForCreation[clause.id.value] ||
      !!this._existingDocumentReferences.find((ref: InternalDocumentReferenceFragment) =>
        ref.targetFragmentId.equals(clause.id)
      )
    );
  }

  private _getReferenceString(): void {
    let internalDocumentReferences: InternalDocumentReferenceFragment[] = this._existingDocumentReferences
      .filter((internalDocumentReference: InternalDocumentReferenceFragment) => {
        return !this._clauseIdsStagedForDeletion.find((clauseId: UUID) =>
          clauseId.equals(internalDocumentReference.targetFragmentId)
        );
      })
      .concat(Object.values(this._internalReferencesStagedForCreation));

    internalDocumentReferences = ReferenceSortingUtils.getSortedReferences(internalDocumentReferences);

    const groupedReferences =
      ClauseDisplayReferenceUtils.groupDocumentReferencesByClauseType(internalDocumentReferences);

    const documentCode: string = this._getDocumentCode();
    const docIdToCompare: UUID = !!this.selectedDocument
      ? this.selectedDocument[SearchableDocumentColumn.DOCUMENT_ID]
      : this._existingDocumentReferences[0]?.targetDocumentId;
    this.clauseRefDisplayString = this._constructReferenceString(groupedReferences, docIdToCompare, documentCode);
  }

  private _getDocumentCode(): string {
    let documentCode: string = !!this.selectedDocument
      ? this.selectedDocument[SearchableDocumentColumn.SUITE] === Suite.MCHW
        ? this.selectedDocument[SearchableDocumentColumn.SHW_DOCUMENT_CODE]
        : this.selectedDocument[SearchableDocumentColumn.DOCUMENT_CODE]
      : this._existingDocumentReferences[0]?.documentCode;

    if (!documentCode) {
      documentCode = '[document code not set]';
    }
    return documentCode;
  }

  private _constructReferenceString(
    references: DisplayInternalReferenceFragment<InternalDocumentReferenceFragment>[],
    documentIdToCompare: UUID,
    documentCode: string
  ): string {
    if (references.length) {
      const categorisedClauseStringArray: string[] = references.map(
        (currentGroup: DisplayInternalReferenceFragment<InternalDocumentReferenceFragment>) => {
          const referenceStringsArray: string[] = this._mapDisplayFragmentToReferenceValuesArray(currentGroup);

          const referenceString: string = this._reduceReferenceValueArrayToString(
            ClauseReferenceDisplayType.REFERENCE,
            referenceStringsArray
          );

          return currentGroup.referencePrefix + referenceString;
        }
      );
      const clauseReferenceString = this._reduceReferenceValueArrayToString(
        ClauseReferenceDisplayType.GROUPING,
        categorisedClauseStringArray
      );

      return `${clauseReferenceString} of ${
        documentIdToCompare.equals(this.documentId) ? 'this document' : documentCode
      }`;
    }
    return '';
  }

  /**
   * This maps from a Complex Fragment object to a simple string array containing the display values of references
   * @param currentGroup contains the list of categorised references as well as their prefix
   * @returns an array containing the display value for each reference
   */
  private _mapDisplayFragmentToReferenceValuesArray(
    currentGroup: DisplayInternalReferenceFragment<InternalDocumentReferenceFragment>
  ): string[] {
    return currentGroup.children.map((internalDocumentReference: InternalDocumentReferenceFragment) => {
      const trimmedSectionIndex: string = internalDocumentReference.sectionIndex?.replace(/\.$/, '');
      return this._generateReferenceDisplayValue(internalDocumentReference, trimmedSectionIndex);
    });
  }

  /**
   * This will generate the display value for a reference based on its clause type & fragment value/index data
   * @param internalDocumentReference the fragment containing the data to generate the display value
   * @param trimmedSectionIndex the index value used for 'heading' clause types
   * @returns the reference's display value
   */
  private _generateReferenceDisplayValue(
    internalDocumentReference: InternalDocumentReferenceFragment,
    trimmedSectionIndex: string
  ): string {
    return ClauseReferencesComponent.HEADING_CLAUSE_TYPES.includes(internalDocumentReference.targetClauseType)
      ? `"${internalDocumentReference.targetFragmentValue}" in Section ${trimmedSectionIndex}`
      : internalDocumentReference.targetFragmentIndex;
  }

  /**
   * This joins an array into a single string, dynamically selecting the delimiter for each element
   * @param originalArray the array of strings to join together
   * @returns the final string with all joined array elements
   */
  private _reduceReferenceValueArrayToString(
    clauseReferenceDisplayType: ClauseReferenceDisplayType,
    originalArray: string[]
  ): string {
    return originalArray.reduce((returnString: string, currentReference: string, idx: number) => {
      return this._constructDelimeter(clauseReferenceDisplayType, returnString, originalArray, currentReference, idx);
    }, '');
  }

  private _constructDelimeter(
    clauseReferenceDisplayType: ClauseReferenceDisplayType,
    returnString: string,
    references: string[],
    currentReference: string,
    index: number
  ): string {
    let andDelimeter: string = ' and ';

    if (clauseReferenceDisplayType === ClauseReferenceDisplayType.GROUPING || index >= 1) {
      andDelimeter = `,${andDelimeter}`;
    }
    switch (index) {
      case references.length - 1:
        return `${returnString}${currentReference}`;
      case references.length - 2:
        return `${returnString}${currentReference}${andDelimeter}`;
      default:
        return `${returnString}${currentReference}, `;
    }
  }

  public cancel(): void {
    const message: string = 'Cancelling will clear existing clause selection. Are you sure you want to continue?';

    if (this.changesMade()) {
      this._openDialog(message).subscribe((action: boolean) => {
        if (action) {
          this._internalReferencesStagedForCreation = {};
          this._clauseIdsStagedForDeletion = [];
          this.selectedDocument = null;
          this._selectedDocumentId = null;
          this.sections = [];
          this._cachedSectionPropertiesForDocument = {};
          this._updateSectionProperties(null);
          this.clauseRefDisplayString = '';
          this._sidebarService.setSidebarStatus(SidebarStatus.CLOSED);
          this._cdr.markForCheck();
        }
      });
    } else {
      this._sidebarService.setSidebarStatus(SidebarStatus.CLOSED);
    }
  }

  public clearSelection(): void {
    const message: string = 'Are you sure you want to clear the existing selection?';

    if (this.changesMade()) {
      this._openDialog(message).subscribe((action: boolean) => {
        if (action) {
          this._internalReferencesStagedForCreation = {};
          this._clauseIdsStagedForDeletion = [];
          this._updateSectionProperties(null);
          this.sections = [];
          this.clauseRefDisplayString = '';
          !!this._existingSelectedDocument
            ? (this.documentSelector.initialSelection = this._existingSelectedDocument)
            : this.documentSelector.emptyFilter();
          this._cdr.markForCheck();
        }
      });
    }
  }

  private _openDialog(message: string): Observable<any> {
    return this._dialog
      .open(DialogComponent, {
        ariaLabel: ClauseReferencesComponent.CONFIRM_CLEARING_SELECTION_MESSAGE,
        data: {
          title: ClauseReferencesComponent.CONFIRM_CLEARING_SELECTION_MESSAGE,
          message: message,
          closeActions: [
            {title: 'Cancel', tooltip: ClauseReferencesComponent.CANCEL_CLEARING_SELECTION_MESSAGE, response: false},
            {
              title: 'Continue',
              tooltip: ClauseReferencesComponent.CONTINUE_CLEARING_SELECTION_MESSAGE,
              color: 'primary',
              response: true,
            },
          ],
        },
      })
      .afterClosed();
  }

  public saveSelection(): void {
    this.loading = true;

    Promise.all([this._createNewReferences(), this._deleteReferences()])
      .then(() => {
        this.loading = false;
        this._cdr.markForCheck();
      })
      .catch(() => {
        this.loading = false;
        this._cdr.markForCheck();
      });
  }

  /**
   * creates a internalDocRef for any selected clause that doesn't already have a corresponding reference,
   * adding new refs to the normative section
   * and creating a internalInlineRef for each new docRef.
   */
  private async _createNewReferences(): Promise<HttpResponse<any>> {
    const newDocumentReferences: InternalDocumentReferenceFragment[] = Object.values(
      this._internalReferencesStagedForCreation
    );

    if (!newDocumentReferences.length) {
      return Promise.resolve(null);
    }

    const document: DocumentFragment = this.clause?.findAncestorWithType(FragmentType.DOCUMENT) as DocumentFragment;
    const normativeReferenceClause: ClauseFragment = document.getNormReferenceSection().children[0] as ClauseFragment;

    const documentReferencesToCreate: InternalDocumentReferenceFragment[] = [];
    const inlineReferencesToCreate: InternalInlineReferenceFragment[] = [];

    // For each document reference, if a reference of the same type and target already exists, we want to create the new
    // inline reference pointed at the existing document reference. Otherwise, we create the new document reference and then
    // create an inline reference pointed at the that newly created one.
    for (const newDocumentReference of newDocumentReferences) {
      const existingDocumentReference: InternalDocumentReferenceFragment = normativeReferenceClause.children.find(
        (documentReference: Fragment) => {
          if (documentReference.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE)) {
            const internalDocumentReference: InternalDocumentReferenceFragment =
              documentReference as InternalDocumentReferenceFragment;
            return (
              newDocumentReference.targetFragmentId.equals(internalDocumentReference.targetFragmentId) &&
              newDocumentReference.internalReferenceType === internalDocumentReference.internalReferenceType
            );
          }

          return false;
        }
      ) as InternalDocumentReferenceFragment;

      if (existingDocumentReference) {
        inlineReferencesToCreate.push(this._createNewInternalInlineReference(existingDocumentReference));
      } else {
        this._addNewInternalDocumentReferenceToClause(normativeReferenceClause, newDocumentReference);
        documentReferencesToCreate.push(newDocumentReference);
        inlineReferencesToCreate.push(this._createNewInternalInlineReference(newDocumentReference));
      }
    }

    return !!this.referenceId
      ? this._handleInsertWhereReferenceInputExists(documentReferencesToCreate, inlineReferencesToCreate)
      : this._handleInsertWithNewReferenceInput(documentReferencesToCreate, inlineReferencesToCreate);
  }

  private _addNewInternalDocumentReferenceToClause(
    normativeReferenceClause: ClauseFragment,
    newDocumentReference: InternalDocumentReferenceFragment
  ): void {
    newDocumentReference.documentId = normativeReferenceClause.documentId;
    newDocumentReference.sectionId = normativeReferenceClause.sectionId;
    newDocumentReference.parentId = normativeReferenceClause.id;
    normativeReferenceClause.children.push(newDocumentReference);
    newDocumentReference.inferWeight();
  }

  private _createNewInternalInlineReference(newDocumentReference: InternalDocumentReferenceFragment) {
    const inlineRef: InternalInlineReferenceFragment = new InternalInlineReferenceFragment(
      null,
      newDocumentReference.id,
      false
    );
    inlineRef.documentId = this._referenceInput.documentId;
    inlineRef.sectionId = this._referenceInput.sectionId;
    inlineRef.parentId = this._referenceInput.id;
    this._referenceInput.children.push(inlineRef);
    inlineRef.inferWeight();
    return inlineRef;
  }

  private _buildInternalDocumentReferenceFragmentFromClause(
    sectionId: UUID,
    clause: TdifClause
  ): InternalDocumentReferenceFragment {
    const sectionProperties: SectionProperties = this._cachedSectionPropertiesForDocument[sectionId.value];
    return new InternalDocumentReferenceFragment(
      UUID.random(),
      ReferenceType.NORMATIVE,
      this._selectedDocumentId.equals(this.documentId)
        ? TargetDocumentType.SAME_DOCUMENT
        : TargetDocumentType.DIFFERENT_DOCUMENT,
      InternalReferenceType.CLAUSE_REFERENCE,
      ClauseReferenceTargetType.CLAUSE,
      this._selectedDocumentId,
      sectionId,
      null,
      clause.id,
      this.selectedDocument[SearchableDocumentColumn.SUITE] === Suite.MCHW
        ? this.selectedDocument[SearchableDocumentColumn.SHW_DOCUMENT_CODE]
        : this.selectedDocument[SearchableDocumentColumn.DOCUMENT_CODE],
      this.selectedDocument[SearchableDocumentColumn.TITLE],
      null,
      sectionProperties.sectionIndex,
      false,
      sectionProperties.clauseIdToIndexMap[clause.id.value],
      ClauseReferencesComponent.HEADING_CLAUSE_TYPES.includes(clause.clauseType)
        ? (clause as TdifHeadingClause).title
        : null,
      clause.clauseType,
      null,
      null,
      null,
      this._fragmentOrderingWeights[clause.id.value] || ReferenceSortingUtils.ORDERING_WEIGHT_NOT_SET
    );
  }

  /**
   * Splitting existing text or memo fragement to insert referenceInput where caret is positioned
   * is the care is in a superscript or subscript the refInput will be rendered after the fragment
   */
  private async _handleInsertWithNewReferenceInput(
    documentReferencesToCreate: Fragment[],
    inlineReferencesToCreate: Fragment[]
  ): Promise<HttpResponse<any>> {
    if (this.inputCaret?.fragment.is(FragmentType.TEXT, FragmentType.MEMO)) {
      const index: number = this.clause.children.indexOf(this.inputCaret?.fragment);
      const fragToReplace: Fragment = this.clause.children[index];

      let newFrag;

      const newFragString: string =
        fragToReplace.value.substring(this.inputCaret.offset).length === 1 &&
        /[\u200B]/g.test(fragToReplace.value.substring(this.inputCaret.offset)[0])
          ? ' '
          : fragToReplace.value.substring(this.inputCaret.offset);
      fragToReplace.value = fragToReplace.value.substring(0, this.inputCaret.offset);
      switch (fragToReplace.type) {
        case FragmentType.TEXT:
          {
            newFrag = new TextFragment(UUID.random(), newFragString, FragmentType.TEXT);
          }
          break;
        case FragmentType.MEMO:
          {
            newFrag = new MemoFragment(UUID.random(), newFragString);
          }
          break;
      }
      newFrag.documentId = this.clause.documentId;
      newFrag.sectionId = this.clause.sectionId;
      newFrag.parentId = this.clause.id;
      this._referenceInput.insertAfter(this.clause.children[index]);
      newFrag.insertAfter(this.clause.children[index + 1]);
      this._referenceInput.inferWeight();
      newFrag.inferWeight();

      const fragsToCreate: Fragment[] = [
        ...documentReferencesToCreate,
        ...[this._referenceInput, newFrag],
        ...inlineReferencesToCreate,
      ];
      await this._fragmentService.create(fragsToCreate).then(() => {
        this.referenceId = this._referenceInput.id;
      });
      return this._fragmentService.update(fragToReplace);
    } else if (this.inputCaret.fragment.is(FragmentType.SUBSCRIPT, FragmentType.SUPERSCRIPT)) {
      const index: number = this.clause.children.indexOf(this.inputCaret?.fragment);
      this._referenceInput.insertAfter(this.clause.children[index]);
      this._referenceInput.inferWeight();
      const fragsToCreate: Fragment[] = [
        ...documentReferencesToCreate,
        ...[this._referenceInput],
        ...inlineReferencesToCreate,
      ];
      await this._fragmentService.create(fragsToCreate).then(() => {
        this.referenceId = this._referenceInput.id;
      });
      return Promise.resolve(null);
    }
  }

  private async _handleInsertWhereReferenceInputExists(documentReferencesToCreate, inlineReferencesToCreate) {
    const fragsToCreate: Fragment[] = [...documentReferencesToCreate, ...inlineReferencesToCreate];
    return this._fragmentService.create(fragsToCreate);
  }

  /**
   * Deletes the inline references that previously existed but have been unstaged. Only deletes the inline references
   * as internal document references will be handled by a service fragment event handler.
   */
  private async _deleteReferences(): Promise<HttpResponse<any>> {
    const deletedDocumentReferences: InternalDocumentReferenceFragment[] = this._existingDocumentReferences.filter(
      (ref: InternalDocumentReferenceFragment) =>
        this._clauseIdsStagedForDeletion.some((clauseId: UUID) => clauseId.equals(ref.targetFragmentId))
    );
    const deletedInlineReferences: InternalInlineReferenceFragment[] = deletedDocumentReferences.map(
      (internalDocRef: InternalDocumentReferenceFragment) =>
        this._existingInlineReferences.find((inlineRef: InternalInlineReferenceFragment) =>
          inlineRef.internalDocumentReferenceId.equals(internalDocRef.id)
        )
    );

    if (deletedInlineReferences.length === this._referenceInput.children.length) {
      const index: number = this.clause.children.indexOf(this._referenceInput);
      if (this.clause.children[index - 1].type === this.clause.children[index + 1].type) {
        return this._handleDeleteInlineRefsAndRefInput(deletedInlineReferences, index);
      } else {
        return this._handleDeleteOnlyInlineRefs(deletedInlineReferences);
      }
    } else if (deletedInlineReferences.length > 0) {
      return this._fragmentService.delete(deletedInlineReferences);
    } else {
      return Promise.resolve(null);
    }
  }

  private _handleDeleteInlineRefsAndRefInput(deletedInlineReferences: Fragment[], index: number): Promise<any> {
    const fragBeforeRefInput: Fragment = this.clause.children[index - 1];
    const fragAfterRefInput: Fragment = this.clause.children[index + 1];
    const joinedValue: string = fragBeforeRefInput.value + fragAfterRefInput.value;
    let joinedFrag;
    if (fragBeforeRefInput.is(FragmentType.TEXT)) {
      joinedFrag = new TextFragment(UUID.random(), joinedValue, FragmentType.TEXT);
    } else if (fragBeforeRefInput.is(FragmentType.MEMO)) {
      joinedFrag = new MemoFragment(UUID.random(), joinedValue);
    }
    joinedFrag.documentId = this.clause.documentId;
    joinedFrag.sectionId = this.clause.sectionId;
    joinedFrag.parentId = this.clause.id;
    joinedFrag.insertBefore(this.clause.children[index - 1]);
    joinedFrag.inferWeight();
    this._fragmentService.create(joinedFrag);
    this._fragmentService
      .delete([...deletedInlineReferences, ...[fragBeforeRefInput, this._referenceInput, fragAfterRefInput]])
      .then(() => {
        this.referenceId = null;
        this._referenceInput = this._createReferenceInput();
        this.inputCaret = new Caret(this.clause.children[index - 1], this.clause.children[index - 1].value.length);
      });
    return Promise.resolve(null);
  }

  private async _handleDeleteOnlyInlineRefs(deletedInlineReferences: Fragment[]): Promise<HttpResponse<any>> {
    return this._fragmentService.delete(deletedInlineReferences);
  }

  private _createReferenceInput(): ReferenceInputFragment {
    const refInput: ReferenceInputFragment = new ReferenceInputFragment(
      UUID.random(),
      'clause reference',
      RequiredReferenceCount.ONE_OR_MORE,
      InternalReferenceType.CLAUSE_REFERENCE,
      [],
      ReferenceInputDisplayType.CLAUSE_TEMPLATE
    );
    refInput.documentId = this.clause.documentId;
    refInput.sectionId = this.clause.sectionId;
    refInput.parentId = this.clause.id;
    return refInput;
  }

  private _onLoadFailure(message: string, err?: any): void {
    this.loading = false;
    Logger.error('internal-reference-error', message, err);
    this._snackBar.open(message, 'Dismiss');
  }

  public changesMade(): boolean {
    return (
      Object.keys(this._internalReferencesStagedForCreation).length > 0 || this._clauseIdsStagedForDeletion.length > 0
    );
  }
}
