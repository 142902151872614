import {Logger} from 'app/error-handling/services/logger/logger.service';
import {VersionRequest} from 'app/interfaces';
import {Administration} from '../../documents/administrations';
import {Serialisable} from '../../utils/serialisable';
import {UUID} from '../../utils/uuid';
import {TreeStructureValidator} from '../tree-structure-validator';
import {ClauseType, DocumentFragment, Fragment, FragmentType, SectionType} from '../types';
import {CacheEntry} from './cache-entry';
import {DiffUtils} from './diff-utils';
import {FragmentCache} from './fragment-cache';

/**
 * An enumeration of all fragment diff operations.
 */
export enum DiffOperation {
  CREATE = 0x1,
  UPDATE = 0x2,
  DELETE = 0x4,
  VERSION = 0x8,
}

/**
 * A class representing a fragment diff for communication with the webservice.
 *
 * @field id        {UUID}            The fragment ID
 * @field operation {DiffOperation}   The operation to perform
 * @field fields    {any}             An object containing fields to update
 */
export class FragmentDiff implements Serialisable {
  public id: UUID;
  public operation: DiffOperation;
  public fields: any;
  public versionRequest: VersionRequest;

  /**
   * Deserialise a diff received as JSON from the webservice.
   *
   * @param json {any}            The diff JSON
   * @returns    {FragmentDiff}   The diff
   */
  public static deserialise(json: any): FragmentDiff {
    const operation: DiffOperation = typeof json.op === 'number' ? json.op : DiffOperation[json.op.toUpperCase()];

    return new FragmentDiff(UUID.orNull(json.id), operation, json.fields);
  }

  /**
   * Create a FragmentDiff whose fields contain a copy of the given fragment.
   *
   * @param fragment  {Fragment}        The fragment to use
   * @param operation {DiffOperation}   The diff operation
   * @returns         {FragmentDiff}    The resulting diff
   */
  public static create(fragment: Fragment): FragmentDiff {
    fragment.inferWeight();

    const fields: any = fragment.serialise();
    delete fields.id;

    return new FragmentDiff(fragment.id, DiffOperation.CREATE, fields);
  }

  /**
   * Create a FragmentDiff which updates only the necessary fields from a previous state.
   *
   * @param before {Fragment}       The state of the fragment before
   * @param after  {Fragment}       The state of the fragment now
   * @returns      {FragmentDiff}   The resulting diff
   */
  public static update(before: Fragment, after: Fragment): FragmentDiff {
    if (before && (!after || !after.equals(before))) {
      throw new Error('The passed before and after state do not have matching IDs');
    }

    after.inferWeight();

    const then: any = before ? before.serialise() : {};
    const now: any = after ? after.serialise() : {};
    const fields: any = DiffUtils.diff(then, now);

    // Fragment types where operations are non-revertible cannot be diffed
    // as their undo buffer is not populated.
    const diff: FragmentDiff = new FragmentDiff(after.id, DiffOperation.UPDATE, fields);
    if (!diff.isRevertibleFor(after)) {
      diff.fields = now;
      // Documents must have all their documentData currently.
      if (after.is(FragmentType.DOCUMENT)) {
        fields.documentData = (after as DocumentFragment).documentData.serialise();
        fields.documentData.documentId = after.id.value;
      }
    }
    delete fields.id;

    // CARS-1070: If the parent has changed, always re-weight, even if the before and after weights are the same.
    // Otherwise local reverts will null out local weights and shuffle fragments
    if (diff.fields['parentId']) {
      diff.fields['weight'] = after.weight;
    }
    return diff;
  }

  /**
   * Create a FragmentDiff which deletes the given fragment.
   *
   * @param fragment {Fragment}       The fragment to delete
   * @returns        {FragmentDiff}   The resulting diff
   */
  public static delete(fragment: Fragment): FragmentDiff {
    return new FragmentDiff(fragment.id, DiffOperation.DELETE, {});
  }

  /**
   * Creates a FragmentDiff which versions the given fragment.
   *
   * @param fragment {Fragment}     The fragment to version
   * @returns        {FragmentDiff} The resulting diff
   */
  public static version(fragment: Fragment, versionRequest: VersionRequest): FragmentDiff {
    return new FragmentDiff(fragment.id, DiffOperation.VERSION, {}, versionRequest);
  }

  constructor(id: UUID, operation: DiffOperation, fields: any, versionRequest: VersionRequest = null) {
    this.id = id;
    this.operation = operation;
    this.fields = fields;
    this.versionRequest = versionRequest;
  }

  /**
   * Clone a fragment
   *
   * @returns {FragmentDiff}   The cloned fragment diff
   */
  public clone(): FragmentDiff {
    return new FragmentDiff(this.id, this.operation, Object.assign({}, this.fields, this.versionRequest));
  }

  /**
   * Serialise this diff for transmission to the webservice.
   *
   * @returns {any}   The serialised JSON
   */
  public serialise(): any {
    const json: any = {
      id: this.id.serialise(),
      op: DiffOperation[this.operation],
      fields: this.fields,
    };
    if (this.versionRequest) {
      json.versionRequest = this.versionRequest;
    }
    return json;
  }

  /**
   * Returns true if this diff does something.  This always returns true for
   * creates and deletes.
   *
   * @returns {boolean}   True if has effect
   */
  public hasEffect(): boolean {
    return !(this.operation === DiffOperation.UPDATE && Object.keys(this.fields).length < 1);
  }

  /**
   * Returns true if this diff should be considered revertible for the given FragmemntType.
   *
   * @param type {Fragment}  The fragment to consider
   * @returns    {boolean}   True if revertible
   */
  public isRevertibleFor(fragment: Fragment): boolean {
    /* eslint-disable no-bitwise */
    const mask: any = {
      [FragmentType.SECTION]: DiffOperation.CREATE | DiffOperation.UPDATE | DiffOperation.DELETE,
      [FragmentType.SECTION_GROUP]: DiffOperation.CREATE | DiffOperation.UPDATE | DiffOperation.DELETE,
      [FragmentType.DOCUMENT]: DiffOperation.CREATE | DiffOperation.UPDATE | DiffOperation.DELETE,
      [FragmentType.ROOT]: DiffOperation.CREATE | DiffOperation.UPDATE | DiffOperation.DELETE,
      [FragmentType.DOCUMENT_REFERENCE]: DiffOperation.CREATE | DiffOperation.UPDATE | DiffOperation.DELETE,
      [FragmentType.INTERNAL_DOCUMENT_REFERENCE]: DiffOperation.CREATE | DiffOperation.UPDATE | DiffOperation.DELETE,
    };

    return !fragment || !(mask[fragment.type] & this.operation);
    /* eslint-enable no-bitwise */
  }

  /**
   * Apply this diff to the given fragment.
   *
   * @param target {Fragment}         The fragment to apply to
   * @param cache  {FragmentCache?}   The cache in which target is stored
   * @returns      {Fragment}         The target after patching
   */
  public applyTo(target: Fragment, cache?: FragmentCache): Fragment {
    if (!target) {
      return null;
    }

    if (!this.id.equals(target.id)) {
      Logger.error(
        'fragment-error',
        `Attempted to apply a diff with ID ${this.id.value} to target with ID ${target.id.value}; ignoring.`
      );
      return target;
    }

    const fields: any = this._coerce(this.fields);

    // If we changed parent or weight, we need to update parent and sort its children
    if (cache && (fields.hasOwnProperty('parentId') || fields.hasOwnProperty('weight'))) {
      const parentEntry: CacheEntry<Fragment> = cache.find(fields.parentId); // UUIDs have been coerced
      if (parentEntry && parentEntry.live && !parentEntry.live.equals(target.parent)) {
        if (target.parent) {
          target.parent.children.splice(target.index(), 1);
        }
        parentEntry.live.children.push(target);
      }

      if (typeof fields.weight === 'number') {
        target.weight = fields.weight;
      }

      if (target.parent) {
        target.parent.sortChildren();
        TreeStructureValidator.isValidInsertion(target);
      }
    }

    // Delete these so the patch doesn't overwrite any adjustments we made above
    delete fields.parentId;
    delete fields.parent;
    delete fields.weight;
    delete fields.children;

    DiffUtils.patch(fields, target);
    return target;
  }

  /**
   * Combine this diff with a set of other diffs.  The operation of this diff is unchanged.
   *
   * @param diffs {FragmentDiff[]}   The diffs to merge with
   */
  public assimilate(...diffs: FragmentDiff[]): void {
    for (const diff of diffs) {
      if (diff !== this) {
        Object.assign(this.fields, diff.fields);
        diff.fields = {};
      }
    }
  }

  /**
   * Helper function to convert enum types and captions to patchable types.  The returned
   * object is a copy, so as not to upset the real object.
   *
   * @returns {any}   The coerced fields
   */
  private _coerce(fields: any): any {
    if (!fields) {
      return fields;
    }
    if (typeof fields === 'string') {
      const asUuid: UUID = UUID.orNull(fields);
      return asUuid ? asUuid : fields;
    }
    if (typeof fields !== 'object') {
      return fields;
    }

    const copy: any = Object.assign({}, fields); // Make a copy

    // Coerce UUID types
    const keys: string[] = Object.keys(copy);
    for (const key of keys) {
      const c: any = copy[key];
      if (typeof c === 'string') {
        const asUuid: UUID = UUID.orNull(c);
        if (asUuid) {
          copy[key] = asUuid;
        }
      } else if (c instanceof Array) {
        copy[key] = c.map((member) => this._coerce(member));
      } else if (typeof c === 'object') {
        copy[key] = this._coerce(c);
      }
    }

    // Coerce enum types
    if (typeof copy.type === 'string') {
      copy.type = FragmentType[copy.type];
    }
    if (typeof copy.clauseType === 'string') {
      copy.clauseType = ClauseType[copy.clauseType];
    }
    if (typeof copy.sectionType === 'string') {
      copy.sectionType = SectionType[copy.sectionType];
    }
    if (typeof copy.administration === 'string') {
      copy.administration = Administration[copy.administration];
    }

    // TODO: Temporary hacks to support captions and equation source:
    if (typeof copy.caption === 'string') {
      copy.caption = {value: copy.caption};
    }
    if (typeof copy.source === 'string') {
      copy.source = {value: copy.source};
    }

    return copy;
  }
}
