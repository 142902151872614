import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AltAccessibilityService} from 'app/services/alt-accessibility.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-accessibility-manager',
  template: '',
})
export class AccessibilityManagerComponent implements OnInit, OnDestroy {
  private static readonly KEY_ALT: string = 'Alt';

  private _altAccessibilityTimeout = null;
  private _altHeld: boolean = false;

  private _subscriptions: Subscription[] = [];

  constructor(private altAccessibilityService: AltAccessibilityService, private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this.altAccessibilityService.onAltEvent().subscribe((value: boolean) => {
        if (!value) {
          this._altHeld = false;
          window.clearTimeout(this._altAccessibilityTimeout);
          this._altAccessibilityTimeout = null;
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (this._altHeld) {
      event.preventDefault();
      if (['Escape', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        this.altAccessibilityService.triggerAltEvent(false);
        this.cd.detectChanges();
      } else if (event.key !== 'Alt') {
        this.altAccessibilityService.triggerKeyEvent({
          key: event.key,
          selection: false,
        });
        this.cd.detectChanges();
      }
    } else {
      if (event.key === AccessibilityManagerComponent.KEY_ALT) {
        event.preventDefault();
        if (!this._altHeld) {
          this._altAccessibilityTimeout = window.setTimeout(() => {
            if (!this._altHeld) {
              this.altAccessibilityService.triggerAltEvent(true);
              this._altHeld = true;
              this.cd.detectChanges();
            }
          }, 500);
        }
      }
    }
  }

  @HostListener('document:keyup.no-cd', ['$event'])
  handleKeyup(event: KeyboardEvent): void {
    if (event.key === AccessibilityManagerComponent.KEY_ALT && !this._altHeld) {
      this.altAccessibilityService.triggerAltEvent(false);
      this.cd.detectChanges();
    }
  }

  @HostListener('document:mousedown.no-cd', ['$event'])
  handleMousedown(event: Event): void {
    if (this._altHeld) {
      this.altAccessibilityService.triggerAltEvent(false);
      this.cd.detectChanges();
    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.altAccessibilityService.triggerAltEvent(false);
  }
}
