import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {VersioningService} from 'app/fragment/versioning/versioning.service';
import {VersionTag} from 'app/interfaces';
import {AbstractSectionResolver} from 'app/routing/resolvers/abstract-section-resolver';
import {DocumentService} from 'app/services/document.service';
import {SectionService} from 'app/services/section.service';
import {ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SectionCommentsResolverService extends AbstractSectionResolver {
  protected breadcrumb: string = 'comments';
  protected routeName: string = 'Discussions and comments';
  protected viewMode: ViewMode = ViewMode.COMMENTS;
  protected location: CurrentLocation = CurrentLocation.COMMENTS;

  constructor(
    protected documentService: DocumentService,
    protected sectionService: SectionService,
    protected versioningService: VersioningService,
    protected viewService: ViewService,
    protected snackBar: MatSnackBar,
    protected router: Router
  ) {
    super(documentService, sectionService, versioningService, viewService, snackBar, router);
  }

  /**
   * Override the version tag so that the current version set on the view service is used.
   *
   * @returns {Observable<VersionTag>}
   */
  protected getVersionTag(versionId: string): Observable<VersionTag> {
    return this.viewService.getCurrentView() ? of(this.viewService.getCurrentView().versionTag) : of(null);
  }
}
