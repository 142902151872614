import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatMenu} from '@angular/material/menu';
import {ActivatedRoute} from '@angular/router';
import {DocumentRole} from 'app/documents/document-data';
import {DocumentFragment, SectionFragment} from 'app/fragment/types';
import {NavigationTypes} from 'app/services/navigation.service';
import {RoleService} from 'app/services/user/role.service';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AltProperties} from '../../services/alt-accessibility.service';
import {DocumentService} from '../../services/document.service';
import {CurrentView} from '../../view/current-view';
import {SearchEvent, SearchService} from './search/search.service';

@Component({
  selector: 'cars-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavigationComponent implements OnInit, OnDestroy, OnChanges {
  @Output() public toggleOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('documentMenu') public documentMenu: MatMenu;

  @Input() public document: DocumentFragment = null;
  @Input() public useDocumentService: boolean = true;
  @Input() public currentView: CurrentView = null;
  @Input() public type: NavigationTypes = NavigationTypes.DOCUMENT;

  public useViewService: boolean = true;
  public showCreator: boolean = false;
  public creating: boolean = false;
  public tooltipDelay: number = environment.tooltipDelay;
  public selectedTab: number = 0;
  public pendingSections: SectionFragment[] = [];

  public altProperties: Record<string, AltProperties> = {};

  public documentVersionsRoute: string;
  public liveDocumentRoute: string;

  public handler: Function;

  private subscriptions: Subscription[] = [];

  public isOffline: boolean = LocalConfigUtils.getConfig().offline;

  public isSearching: boolean = false;

  private _userIsAPeerReviewer: boolean = false;

  /**
   * Determines whether the user can view the live document.
   */
  public get canViewLiveDocument(): boolean {
    return this._userIsAPeerReviewer || (!!this.currentView && this.currentView.userIsAnAuthor());
  }

  constructor(
    private documentService: DocumentService,
    private roleService: RoleService,
    private viewService: ViewService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    const rootDocumentPath: string = '/documents/' + this.route.snapshot.params['document_id'];
    this.documentVersionsRoute = rootDocumentPath + '/versions';
    this.liveDocumentRoute = rootDocumentPath;

    this.subscriptions.push(
      this.searchService.onSearchDocumentEvent().subscribe((s: SearchEvent) => {
        this.isSearching = this.document && this.document.id === s.docId;
      }),
      this.documentService.onSelection((document: DocumentFragment) => {
        if (this.useDocumentService) {
          this.document = document;
        }
      }),
      this.viewService.onCurrentViewChange((currentView: CurrentView) => {
        if (this.useViewService) {
          this.currentView = currentView;
        }
      })
    );

    this.updateAltProperties();
    this.updateState();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('currentView')) {
      this.useViewService = !changes.currentView.currentValue;
      this.currentView = this.useViewService ? this.viewService.getCurrentView() : changes.currentView.currentValue;
    }
  }

  /**
   * Clean up after this component.
   */
  public ngOnDestroy(): void {
    this.subscriptions.splice(0).forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Close the navigation sidenav.
   */
  public toggle(): void {
    this.creating = false;
    this.toggleOpen.emit();
  }

  public updateAltProperties(): void {
    this.altProperties['live'] = new AltProperties('q', 'w', this.selectedTab === 1);
    this.altProperties['deleted'] = new AltProperties('q', 'w', this.selectedTab === 0);
    this.altProperties['initial-button'] = new AltProperties('q', null, true);
  }

  public focusElement(id: string) {
    document.getElementById(id).focus();
  }

  public changeTab(tab: number): void {
    this.selectedTab = tab;
  }

  public updateState(): void {
    this._userIsAPeerReviewer = this.roleService.isInDocumentRole(null, DocumentRole.PEER_REVIEWER);
  }
}
