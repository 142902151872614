<div [ngSwitch]="state">
  <div *ngSwitchCase="LoadingState.NOT_DOWNLOADED">
    <button
      mat-icon-button
      (click)="download()"
      matTooltip="Download version for offline review"
      matTooltipPosition="after"
      [matTooltipShowDelay]="tooltipDelay"
      aria-label="Download version for offline review"
    >
      <mat-icon>cloud_download</mat-icon>
    </button>
  </div>
  <div *ngSwitchCase="LoadingState.DOWNLOADING">
    <div class="spinner-container">
      <mat-spinner aria-label="Downloading document"></mat-spinner>
    </div>
  </div>
  <div *ngSwitchCase="LoadingState.DOWNLOADED">
    <button
      mat-icon-button
      (click)="download()"
      matTooltip="Update version for offline review"
      matTooltipPosition="after"
      [matTooltipShowDelay]="tooltipDelay"
      aria-label="Update version for offline review"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
    <span>{{ this.lastSyncTime | date: dateFormat }}</span>
  </div>
  <div *ngSwitchCase="LoadingState.NOT_AVAILABLE">
    <mat-icon
      matTooltip="Your browser does not support the technologies required for offline review"
      matTooltipPosition="after"
      [matTooltipShowDelay]="tooltipDelay"
      aria-label="Your browser does not support the technologies required for offline review"
      >cancel</mat-icon
    >
  </div>
</div>
