import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SearchDocumentComponent} from './search-document.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatIconModule,
    MatProgressBarModule,
    MatDividerModule,
    MatProgressSpinnerModule,
  ],
  declarations: [SearchDocumentComponent],
  exports: [SearchDocumentComponent],
})
export class SearchDocumentModule {}
