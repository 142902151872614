import {ChangeDetectorRef, Directive, ElementRef, Input} from '@angular/core';
import {ActionRequest} from '../action-request';
import {Caret} from '../caret';
import {FragmentComponent} from '../core/fragment.component';
import {Key} from '../key';
import {TableRowFragment} from './table-fragment';

@Directive({
  selector: '[carsTableRowFragment]',
})
export class TableRowFragmentDirective extends FragmentComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('carsTableRowFragment') public set content(value: TableRowFragment) {
    super.content = value;
  }

  public get content(): TableRowFragment {
    return super.content as TableRowFragment;
  }

  constructor(protected _cd: ChangeDetectorRef, elementRef: ElementRef) {
    super(_cd, elementRef);
  }

  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    let action: ActionRequest = new ActionRequest();
    const index: number = this.content.childIndexOf(target.content);

    if (key.equalsUnmodified(Key.TAB)) {
      if (key.shift && !this.content.children[index].isFirstChild()) {
        action.fragment = this.content.children[index].previousLeaf();
        action.offset = Math.max(action.fragment.length(), Math.min(caret.offset, 0));
      } else if (!key.shift && !this.content.children[index].isLastChild()) {
        action.fragment = this.content.children[index].nextLeaf();
        action.offset = Math.max(action.fragment.length(), Math.min(caret.offset, 0));
      } else {
        // We're either the first or last column of the row, so let the enclosing table
        // handle moving to the correct cell
        action = null;
      }
    } else {
      action = null;
    }

    return action;
  }
}
