import {Fragment, FragmentType} from 'app/fragment/types';
import {UUID} from 'app/utils/uuid';

export abstract class AbstractInputFragment extends Fragment {
  public readonly displayPlaceholder: string;
  public readonly scheduleListPlaceholderValue: string;

  constructor(
    id: UUID,
    fragmentType: FragmentType,
    private readonly startDelimiter: string,
    private readonly endDelimiter: string,
    private readonly placeholderValue: string,
    children: Fragment[]
  ) {
    super(id, fragmentType, children);

    this.startDelimiter = this.startDelimiter || '';
    this.endDelimiter = this.endDelimiter || '';

    this.placeholderValue = this.placeholderValue || '';
    this.displayPlaceholder = this._getDisplayPlaceholder();
  }

  private _getDisplayPlaceholder(): string {
    return `${this.startDelimiter}${this.placeholderValue}${this.endDelimiter}`;
  }

  /**
   * Construct and return the string to display for the clause preview.
   */
  public abstract getPreview(): string;

  /**
   * @inheritdoc
   */
  public split(offset: number): this {
    return null;
  }

  /**
   * @override
   */
  public serialise(): any {
    const json: any = super.serialise();

    delete json.displayPlaceholder;

    delete json.startDelimiter;
    delete json.endDelimiter;

    return json;
  }
}
