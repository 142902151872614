import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Dashboard, DashboardService} from 'app/services/dashboard.service';
import {UUID} from 'app/utils/uuid';
import {Subscription} from 'rxjs';
import {SectionDiscussionsAggregate} from '../aggregates/section-discussions-aggregate';
import {VersionDiscussionsAggregate} from '../aggregates/version-discussions-aggregate';

@Component({
  selector: 'cars-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate(0)),
    ]),
  ],
})
export class DashboardTableComponent implements OnInit, OnDestroy {
  @Input() dashboardType: Dashboard;

  public readonly displayedColumns: string[] = ['section', 'number_of_discussions', 'all_resolved'];

  public selectedAggregate: VersionDiscussionsAggregate;

  public loading: boolean;
  public sections: SectionDiscussionsAggregate[] = [];
  public selectedSection: UUID;

  private _subscriptions: Subscription[] = [];

  constructor(private _dashboardService: DashboardService) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._dashboardService.onVersionAggregateSelect((selection: VersionDiscussionsAggregate) =>
        this.fetchData(selection)
      )
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  public fetchData(selection: VersionDiscussionsAggregate): void {
    this.selectedAggregate = selection;

    if (!selection) {
      return;
    }

    this.loading = true;

    const promise: Promise<SectionDiscussionsAggregate[]> = selection.versionId
      ? this._dashboardService.getVersionSectionDiscussionsAggregates(
          selection.documentId,
          selection.versionId,
          this.dashboardType
        )
      : this._dashboardService.getLiveSectionDiscussionsAggregates(selection.documentId, this.dashboardType);

    promise
      .then((response: SectionDiscussionsAggregate[]) => {
        this.sections = response;
        this.loading = false;
      })
      .catch((error: any) => (this.loading = false));
  }

  public getNumberOfDiscussions(section: SectionDiscussionsAggregate): number {
    return section.isByUser() ? section.discussions + section.discussionsCommentedOn : section.discussions;
  }

  public getNumberOfResolvedDiscussions(section: SectionDiscussionsAggregate): number {
    return section.isByUser()
      ? section.resolvedDiscussions + section.resolvedDiscussionsCommentedOn
      : section.resolvedDiscussions;
  }

  public selectRow(section: SectionDiscussionsAggregate) {
    if (this.isSelected(section)) {
      this.selectedSection = null;
    } else {
      this.selectedSection = section.sectionId;
    }
  }

  public isSelected(section: SectionDiscussionsAggregate): boolean {
    return this.selectedSection === section.sectionId;
  }
}
