import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ManageReferencesModule} from './manage-references/manage-references.module';
import {UserLocationsModule} from './user-locations/user-locations.module';
import {UserLogsModule} from './user-logs/user-logs.module';
import {UserStatisticsModule} from './user-statistics/user-statistics.module';

@NgModule({
  imports: [CommonModule],
  exports: [UserStatisticsModule, UserLogsModule, UserLocationsModule, ManageReferencesModule],
})
export class AdminModule {}
