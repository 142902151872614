import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {Fragment, FragmentType, SectionFragment, SectionType} from 'app/fragment/types';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {Breadcrumb} from 'app/interfaces';
import {PermissionsService} from 'app/permissions/permissions.service';
import {CarsAction} from 'app/permissions/types/permissions';
import {BreadcrumbService} from 'app/services/breadcrumb.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {BehaviorSubject, Subscription} from 'rxjs';

export interface SectionListMenuEntry {
  condition: () => boolean;
  onClick: () => void;
  routerLink?: BehaviorSubject<string>;
  icon: string;
  name: string;
  ariaLabel: () => string;
  carsAnalytics: string;
}

@Component({
  selector: 'cars-section-list-menu',
  templateUrl: './section-list-menu.component.html',
  styleUrls: ['./section-list-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionListMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public sectionListItem: SectionFragment | SectionGroupFragment;
  @Input() public currentView: CurrentView;

  @Output() public renamingSection: EventEmitter<boolean> = new EventEmitter();

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly tooltip: string = 'Open section menu';

  private _printPreviewRoute: BehaviorSubject<string> = new BehaviorSubject('');
  private _commentsRoute: BehaviorSubject<string> = new BehaviorSubject('');
  private _backgroundRoute: BehaviorSubject<string> = new BehaviorSubject('');
  private _compareRoute: BehaviorSubject<string> = new BehaviorSubject('');

  private readonly _menuEntryMap: Readonly<Partial<Record<FragmentType, SectionListMenuEntry[]>>> = {
    [FragmentType.SECTION]: [
      {
        condition: () => this._canAuthorDocument && this._isSectionNotReferenceOrInformationWithDeletedValue(false),
        onClick: this._renameSection.bind(this),
        icon: 'mode_edit',
        name: 'Rename',
        ariaLabel: () => `Rename section ${this._getTitle()}`,
        carsAnalytics: 'rename-section',
      },
      {
        condition: () =>
          this._canAuthorDocument &&
          this._isSectionNotReferenceOrInformationWithDeletedValue(false) &&
          !this.sectionListItem.parent.is(FragmentType.SECTION_GROUP),
        onClick: () => this._setItemDeleted(true),
        icon: 'delete',
        name: 'Delete',
        ariaLabel: () => `Delete section ${this._getTitle()}`,
        carsAnalytics: 'delete-section',
      },
      {
        condition: () =>
          this._canAuthorDocument &&
          this._isSectionNotReferenceOrInformationWithDeletedValue(true) &&
          !this.sectionListItem.parent.is(FragmentType.SECTION_GROUP),
        onClick: () => this._setItemDeleted(false),
        icon: 'restore_page',
        name: 'Restore',
        ariaLabel: () => `Restore section ${this._getTitle()}`,
        carsAnalytics: 'restore-section',
      },
      {
        condition: () =>
          !this.currentView?.isHistoricalDocument() && !(this.sectionListItem as SectionFragment).deleted,
        onClick: this._unlockClausesInListItem.bind(this),
        routerLink: this._printPreviewRoute,
        icon: 'print',
        name: 'Print preview',
        ariaLabel: () => `Print preview for section ${this._getTitle()}`,
        carsAnalytics: 'print-preview',
      },
      {
        condition: () => !this._isCommentsView && this._isSectionNotReferenceOrInformationWithDeletedValue(false),
        onClick: this._unlockClausesInListItem.bind(this),
        routerLink: this._commentsRoute,
        icon: 'question_answer',
        name: 'Discussions & comments',
        ariaLabel: () => `Discussions and comments for section ${this._getTitle()}`,
        carsAnalytics: 'comments-from-navbar',
      },
      {
        condition: () => !this._isBackgroundView && this._isSectionNotReferenceOrInformationWithDeletedValue(false),
        onClick: this._unlockClausesInListItem.bind(this),
        routerLink: this._backgroundRoute,
        icon: 'subject',
        name: 'Background & commentary',
        ariaLabel: () => `Background and commentary for section ${this._getTitle()}`,
        carsAnalytics: 'background-from-navbar',
      },
      {
        condition: () => !this._isCompareView && this._isSectionNotReferenceOrInformationWithDeletedValue(false),
        onClick: this._unlockClausesInListItem.bind(this),
        routerLink: this._compareRoute,
        icon: 'compare',
        name: 'Compare to version',
        ariaLabel: () => `Compare section ${this._getTitle()} to a previous version`,
        carsAnalytics: 'compare-to-version',
      },
    ],
    [FragmentType.SECTION_GROUP]: [
      {
        condition: () => this._canAuthorDocument && !(this.sectionListItem as SectionGroupFragment).deleted,
        onClick: () => this._setItemDeleted(true),
        icon: 'delete',
        name: 'Delete',
        ariaLabel: () => `Delete section group ${this._getTitle()}`,
        carsAnalytics: 'delete-section-group',
      },
      {
        condition: () => this._canAuthorDocument && (this.sectionListItem as SectionGroupFragment).deleted,
        onClick: () => this._setItemDeleted(false),
        icon: 'restore_page',
        name: 'Restore',
        ariaLabel: () => `Restore section group ${this._getTitle()}`,
        carsAnalytics: 'restore-section-group',
      },
    ],
  };

  public menuEntries: SectionListMenuEntry[] = [];

  public reorderingClauses: boolean = false;

  private _subscriptions: Subscription[] = [];
  private _useViewService: boolean = true;

  private _canAuthorDocument: boolean = false;
  private _isCommentsView: boolean = false;
  private _isBackgroundView: boolean = false;
  private _isCompareView: boolean = false;

  constructor(
    private _fragmentService: FragmentService,
    private _lockService: LockService,
    private _viewService: ViewService,
    private _breadcrumbService: BreadcrumbService,
    private _reorderClausesService: ReorderClausesService,
    private _permissionsService: PermissionsService,
    private _route: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._calculateMenuEntries();
    this._subscriptions.push(
      this._breadcrumbService.getBreadcrumbs().subscribe(this._updateRoutes.bind(this)),
      this._fragmentService.onCreate(() => this._calculateMenuEntries(), this._fragmentServicePredicate),
      this._fragmentService.onUpdate(() => this._calculateMenuEntries(), this._fragmentServicePredicate),
      this._fragmentService.onDelete(() => this._calculateMenuEntries(), this._fragmentServicePredicate),
      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        if (this._useViewService) {
          this.currentView = currentView;
          this._calculateMenuEntries();
        }
      }),
      this._reorderClausesService.onReorderingEvent().subscribe((reordering: boolean) => {
        this.reorderingClauses = reordering;
        this._cdr.markForCheck();
      }),
      this._permissionsService
        .can(CarsAction.AUTHOR_DOCUMENT, this.sectionListItem.documentId)
        .subscribe((canAuthor: boolean) => {
          this._canAuthorDocument = canAuthor;
          this._calculateMenuEntries();
        }),
      this._permissionsService
        .can(CarsAction.IS_COMMENTS_VIEW_MODE, this.sectionListItem.documentId)
        .subscribe((isCommentsView: boolean) => {
          this._isCommentsView = isCommentsView;
          this._calculateMenuEntries();
        }),
      this._permissionsService
        .can(CarsAction.IS_BACKGROUND_VIEW_MODE, this.sectionListItem.documentId)
        .subscribe((isBackgroundView: boolean) => {
          this._isBackgroundView = isBackgroundView;
          this._calculateMenuEntries();
        }),
      this._permissionsService
        .can(CarsAction.IS_COMPARE_VIEW_MODE, this.sectionListItem.documentId)
        .subscribe((isCompareView: boolean) => {
          this._isCompareView = isCompareView;
          this._calculateMenuEntries();
        })
    );
  }

  /**
   * Predicate for whether the fragment event should trigger a mark for check of this component.
   * Returns true for SECTION_GROUPs and SECTIONs.
   */
  private _fragmentServicePredicate(fragment: Fragment): boolean {
    return fragment?.is(FragmentType.SECTION_GROUP, FragmentType.SECTION);
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Respond to Angular binding changes.
   *
   * @param changes {SimpleChanges}   The changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('currentView')) {
      this._useViewService = !changes.currentView.currentValue;
      this.currentView = this._useViewService ? this._viewService.getCurrentView() : changes.currentView.currentValue;
      this._calculateMenuEntries();
    }
  }

  private _getTitle(): string {
    return this.sectionListItem?.value || '';
  }

  /**
   * Calculates the list of menu items to display for the current sectionListItem.
   */
  private _calculateMenuEntries(): void {
    const entries: SectionListMenuEntry[] = this._menuEntryMap[this.sectionListItem?.type] ?? [];
    this.menuEntries = entries.filter((entry: SectionListMenuEntry) => entry.condition());
    this._cdr.markForCheck();
  }

  private _isSectionNotReferenceOrInformationWithDeletedValue(deleted: boolean): boolean {
    return (
      this.sectionListItem.deleted === deleted &&
      !(this.sectionListItem as SectionFragment).isSectionOfType(
        SectionType.DOCUMENT_INFORMATION,
        SectionType.REFERENCE_INFORM,
        SectionType.REFERENCE_NORM
      )
    );
  }

  /**
   * Emits an event to trigger opening the rename textbox for this section.
   */
  private _renameSection(): void {
    this.renamingSection.emit(true);
  }

  private _setItemDeleted(deleted: boolean): void {
    const toUpdate: Fragment[] = [this.sectionListItem];
    this.sectionListItem.deleted = deleted;

    if (this.sectionListItem.is(FragmentType.SECTION_GROUP)) {
      (this.sectionListItem as SectionGroupFragment).children.forEach((section: SectionFragment) => {
        section.deleted = deleted;
        toUpdate.push(section);
      });
    }

    this._fragmentService.update(toUpdate).catch(() =>
      this._snackbar
        .open(`Section could not be ${deleted ? 'deleted' : 'restored'}`, 'Retry', {duration: 5000})
        .onAction()
        .subscribe(() => this._setItemDeleted(deleted))
    );

    this._unlockClausesInListItem();
  }

  private _unlockClausesInListItem(): void {
    switch (this.sectionListItem.type) {
      case FragmentType.SECTION:
        this._lockService.unlockClausesInSection(this.sectionListItem as SectionFragment);
        break;
      case FragmentType.SECTION_GROUP:
        this._lockService.unlockClausesInSectionGroup(this.sectionListItem as SectionGroupFragment);
        break;
    }
  }

  private _updateRoutes(breadcrumbs: Breadcrumb[]): void {
    if (!this.currentView || !this.sectionListItem?.is(FragmentType.SECTION)) {
      return;
    }

    if (this.currentView.isChangelog() || this.currentView.isChangelogMarkup()) {
      if (breadcrumbs.length > 1) {
        const prefix: string[] = [breadcrumbs[1].link, 'sections', this.sectionListItem.id.value];
        this._printPreviewRoute.next([...prefix, 'export'].join('/'));
        this._commentsRoute.next([...prefix, 'comments'].join('/'));
        this._backgroundRoute.next([...prefix, 'background'].join('/'));
        this._compareRoute.next([...prefix, 'compare'].join('/'));
      }
    } else {
      this._printPreviewRoute.next(this._constructSectionRoute('export'));
      this._commentsRoute.next(this._constructSectionRoute('comments'));
      this._backgroundRoute.next(this._constructSectionRoute('background'));
      this._compareRoute.next(this._constructSectionRoute('compare'));
    }
  }

  private _constructSectionRoute(end: string): string {
    return `/${this._route.snapshot.url.map((seg) => seg.path).join('/')}/sections/${
      this.sectionListItem.id.value
    }/${end}`;
  }
}
