import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Client, Frame, IFrame} from '@stomp/stompjs';
import {BaseService} from 'app/services/base.service';
import {AuthenticationProvider} from 'app/services/user/authentication-provider';
import {NoOpWebsocketClient} from 'app/services/websocket/no-op-websocket-client';
import {UUID} from 'app/utils/uuid';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import * as SockJS from 'sockjs-client';
import {environment} from '../../../environments/environment';
import {Callback} from '../../utils/typedefs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService extends BaseService {
  private _client: Client;
  private _subject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _topicMap: Map<string, Subject<any>> = new Map();
  private _sessionId: string;

  private _fallbackTransports: string[] = ['xhr-polling'];
  private _hasConnected: boolean = false;

  constructor(snackbar: MatSnackBar, private authProvider: AuthenticationProvider) {
    super(snackbar);
  }

  /**
   * Open the websocket connection.
   */
  public connect(fromReconnect?: boolean): void {
    if (this.authProvider.offline()) {
      this._client = new NoOpWebsocketClient();
      this._subject.next(true);
      return;
    }

    const sockJsOptions = {
      // We provide a custom sessionId factory so that we can keep track of the session id.
      sessionId: (): string => {
        this._sessionId = UUID.random().value.substring(0, 8);
        return this._sessionId;
      },
    };

    if (fromReconnect && !this._hasConnected) {
      console.warn('Failed to connect via WebSockets, falling back to HTTP polling');
      sockJsOptions['transports'] = this._fallbackTransports;
    }

    if (fromReconnect) {
      this._client.forceDisconnect();
      this._client.deactivate();
    }

    this._client = new Client();
    this._client.webSocketFactory = function () {
      return new SockJS(`${environment.apiHost}/websocket`, [], sockJsOptions);
    };

    this._topicMap.clear();

    const userId: UUID = this.authProvider.getCurrentUser().id;
    this._client.connectHeaders = {'user-id': userId.value, host: environment.apiHost};

    this._client.onConnect = (result: IFrame): void => {
      this._hasConnected = true;
      this._subject.next(true);
    };

    this._client.onStompError = this._handleConnectionError.bind(this);
    this._client.logRawCommunication = true;
    this._client.onWebSocketError = function (evt: Event): void {
      console.error(evt);
    };
    this._client.onWebSocketClose = this._handleConnectionError.bind(this);

    this._client.activate();
  }

  /**
   * Subscribe to connection state changes.
   *
   * @param callback {boolean => void}   The subscription callback
   * @returns        {Subscription}      The resulting subscription
   */
  public onConnection(callback: Callback<boolean>): Subscription {
    return this._makeSubscription(this._subject, callback);
  }

  /**
   * Subscribe to a websocket topic.
   *
   * @param url
   * @param callback {any => void}    The callback
   * @returns        {Subscription}   The subscription object
   */
  public subscribe(url: string, callback: (data: any) => void): Subscription {
    let obs: Subject<any>;
    if (this._topicMap.has(url)) {
      obs = this._topicMap.get(url);
    } else {
      obs = new Subject<any>();
      this._client.subscribe(url, (frame: Frame) => {
        const json: any = typeof frame.body === 'string' ? JSON.parse(frame.body) : frame.body;
        obs.next(json);
      });
      this._topicMap.set(url, obs);
    }

    return obs.subscribe(callback);
  }

  /**
   * Close the websocket connection.
   */
  public disconnect(): void {
    if (this._client) {
      console.log('Disconnected');
      this._client.deactivate();
    }
  }

  /**
   * Get the ID of the websocket connection.
   *
   * @returns {string}   The client ID
   */
  public getSessionId(): string {
    return this._sessionId;
  }

  /**
   * Handle websocket connection errors by alerting the user and attempting to reconnect.
   *
   * @param error {string}   The error
   */
  private _handleConnectionError(error: string): void {
    if (!document.hidden) {
      this._handleError(error, 'Network connection failed: CARS is attempting to reconnect.', 'websocket-error');
    }

    this._subject.next(false);

    console.error('STOMP: Attempting to reconnect in 1 second');

    setTimeout(() => {
      this.connect(true);
    }, 1000);
  }
}
