import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {DocumentSelectorModule} from 'app/search/document-selector/document-selector.module';
import {SectionReferencesComponent} from './section-references.component';

@NgModule({
  imports: [
    CommonModule,
    DocumentSelectorModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatProgressBarModule,
    CarsPermissionPipesModule,
    FormsModule,
  ],
  declarations: [SectionReferencesComponent],
  exports: [SectionReferencesComponent],
})
export class SectionReferencesModule {}
