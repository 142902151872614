<div class="version-creator">
  <div class="version-input-container">
    <h2 matDialogTitle>Create {{ template ? 'template' : 'version' }}</h2>
    <div mat-dialog-content>
      <ng-container *ngIf="!template">
        <p class="warning" *ngIf="versionInProgress">
          A version of this document is already being created. Please try again later.
        </p>
        <p>
          Create a version of the document based on its current state. Please note that once created, versions cannot be
          renamed.
        </p>

        <mat-form-field>
          <mat-select placeholder="Version type" [disabled]="fieldDisabled()" required>
            <mat-option
              *ngFor="let versionType of availableVersionTypeList"
              [value]="versionType"
              (onSelectionChange)="onVersionTypeSelect($event, versionType)"
            >
              {{ versionType | versionTagTypeDisplayName }}
            </mat-option>
          </mat-select>
          <mat-error>Version type is required</mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="template">
        <p>Create a template from the current document.</p>
        <p>Please note that once created, templates cannot be renamed.</p>
      </ng-container>

      <mat-form-field>
        <textarea
          [(ngModel)]="name"
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="20"
          [placeholder]="template ? 'Template name' : 'Version name'"
          name="version"
          [disabled]="fieldDisabled()"
          required
        >
        </textarea>
        <mat-error>{{ template ? 'Template' : 'Version' }} name is required</mat-error>
      </mat-form-field>
    </div>

    <ng-container *ngIf="!template">
      <mat-checkbox
        [(ngModel)]="availableToReview"
        (ngModelChange)="changeAvailableToReview()"
        [disabled]="fieldDisabled()"
      >
        Available to reviewers
      </mat-checkbox>

      <mat-checkbox
        [(ngModel)]="availableForCommenting"
        (ngModelChange)="changeAvailableForCommenting()"
        [disabled]="fieldDisabled()"
      >
        Allow discussions and comments
      </mat-checkbox>

      <mat-checkbox
        color="warn"
        [(ngModel)]="userDiscussionsOnly"
        (ngModelChange)="changeUserDiscussionsOnly()"
        [disabled]="fieldDisabled()"
      >
        Forbid reviewers from seeing and commenting on others' discussions
      </mat-checkbox>
    </ng-container>

    <ng-container *ngIf="!isValidPublication">
      <div class="warning">
        Versions for approval/authorisation must have the following fields set:
        <ul>
          <li *ngFor="let field of documentInformationFieldsToValidate">{{ field.titleText }}</li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="publishingUnlinkedNaa">
      <div class="warning">NAA versions for approval/authorisation must be linked to a core document</div>
    </ng-container>

    <ng-container *ngIf="showWsrMappingErrorMessage">
      <div class="warning">
        All MCHW sections, irrespective of whether they contain Specifier Instructions or not, have WSR templates
        created. A valid subject, topic and reference number are required in the WSR Mapping table for all sections.
        This is managed by the National Highways TSG Team; please contact them to provide missing information and
        unblock version creation.
      </div>
    </ng-container>

    <ng-container *ngIf="validationLoading">
      <div class="validation-loading">Checking document for validation errors, please wait.</div>
    </ng-container>

    <ng-container *ngIf="hasBlockingValidationErrors">
      <div class="warning">
        There are validation errors in this document, review the document health check to resolve these.
      </div>
    </ng-container>

    <div mat-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        (click)="create()"
        [disabled]="creationDisabled()"
        aria-label="Create version"
        carsAnalytics="{{ template ? 'create-template' : 'create-version' }}"
        enableDefaultFocus
      >
        Create
      </button>

      <button
        mat-button
        mat-dialog-close
        [attr.aria-label]="creating ? 'Continue version creation in background' : 'Cancel version creation'"
      >
        {{ creating ? 'Continue in background' : 'Cancel' }}
      </button>
    </div>

    <div *ngIf="creating || loading || validationLoading" class="progress-bar-container">
      <mat-progress-bar
        mode="indeterminate"
        [attr.aria-label]="
          validationLoading
            ? 'Validation Loading'
            : creating
            ? 'Creating ' + template
              ? 'template'
              : 'version'
            : 'Loading'
        "
      ></mat-progress-bar>

      <div *ngIf="creating" class="creating-note">
        Creating {{ template ? 'template' : 'version' }}, please wait a moment
      </div>
    </div>
  </div>
</div>
