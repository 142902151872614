import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {NavigationSidebarButtonsModule} from 'app/navigation/navigation-sidebar-buttons/navigation-sidebar-buttons.module';
import {NavigationModule} from 'app/navigation/navigation/navigation.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {SidebarModule} from 'app/sidebar/sidebar.module';
import {ChatModule} from './chat/chat.module';
import {ContainerComponent} from './container.component';
import {SavingChipModule} from './saving-chip/saving-chip.module';

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    CarsErrorHandlingModule,
    NavigationModule,
    NavigationSidebarButtonsModule,
    SavingChipModule,
    ChatModule,
    SidebarModule,
    RouterModule,
    AltAccessibilityModule,
    CarsPermissionPipesModule,
  ],
  declarations: [ContainerComponent],
  exports: [ContainerComponent],
})
export class ContainerModule {}
