import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {DocumentFragment} from 'app/fragment/types';
import {DocumentService} from 'app/services/document.service';
import {SectionService} from 'app/services/section.service';
import {ConfigurationService, SectionTypeConfiguration} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-section-creator',
  templateUrl: './section-creator.component.html',
  styleUrls: ['./section-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionCreatorComponent implements OnInit, OnDestroy {
  @ViewChild('title') titleInput: ElementRef;

  @Output() created: EventEmitter<UUID> = new EventEmitter();

  public readonly tooltipDelay: number = environment.tooltipDelay;

  public newTitle: string;

  public selectedTypeConfig: SectionTypeConfiguration;
  private _defaultSectionType: SectionTypeConfiguration;

  public documentId: UUID;

  public loading: boolean = false;

  public isCreatingSection: boolean = false;
  private _escapeKeyListenerHandler: Function;

  private _subscription: Subscription;

  constructor(
    private _documentService: DocumentService,
    private _configurationService: ConfigurationService,
    private _sectionService: SectionService,
    private _cdr: ChangeDetectorRef,
    private _renderer: Renderer2
  ) {}

  /**
   * Initialise this component.
   */
  public ngOnInit(): void {
    this._subscription = this._documentService.onSelection((document: DocumentFragment) => {
      if (document) {
        this._configurationService
          .getSectionTypesForDocumentId(document.id)
          .then((configList: SectionTypeConfiguration[]) => {
            this._defaultSectionType = configList.length ? configList[0] : null;
            this.documentId = document.id;
            this._cdr.markForCheck();
          });
      } else {
        this._defaultSectionType = null;
        this.documentId = null;
        this._cdr.markForCheck();
      }
    });
  }

  /**
   * Unsubscribes from subscription.
   */
  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.stopCreatingSection();
  }

  public onSubmit(): void {
    this.loading = true;
    this.stopCreatingSection();

    if (this.selectedTypeConfig && this.selectedTypeConfig.sectionType) {
      this._sectionService
        .createSection(this.documentId, this.newTitle, this.selectedTypeConfig.sectionType)
        .then((sectionId: UUID) => {
          this.loading = false;
          this.created.emit(sectionId);
        })
        .catch((err: any) => (this.loading = false));
    } else if (this.selectedTypeConfig && this.selectedTypeConfig.sectionGroupType) {
      this._sectionService
        .createSectionGroup(this.documentId, this.newTitle, this.selectedTypeConfig.sectionGroupType)
        .then((sectionId: UUID) => {
          this.loading = false;
          this.created.emit(sectionId);
        })
        .catch((err: any) => (this.loading = false));
    }
  }

  public startCreatingSection(): void {
    this.isCreatingSection = true;
    this.selectedTypeConfig = this._defaultSectionType;
    this.newTitle = '';
    this.initialiseKeydownListener();
    setTimeout(() => this.titleInput.nativeElement.focus(), 0);
  }

  public stopCreatingSection(): void {
    this.isCreatingSection = false;
    this._cdr.markForCheck();
    if (this._escapeKeyListenerHandler) {
      this._escapeKeyListenerHandler();
      this._escapeKeyListenerHandler = null;
    }
  }

  public initialiseKeydownListener(): void {
    this._escapeKeyListenerHandler = this._renderer.listen(document, 'keydown', (event) => {
      switch (event.key) {
        case 'Escape': {
          event.preventDefault();
          this.stopCreatingSection();
          break;
        }
      }
    });
  }
}
