import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {VersionTagTypeToDisplayNameModule} from 'app/utils/pipes/version-tag-type-to-display-name.module';
import {UserModule} from 'app/widgets/user/user.module';
import {DocumentVersionsComponent} from './document-versions.component';
import {OfflineVersionStatusModule} from './offline-version-status/offline-version-status.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    RouterModule,
    MatCheckboxModule,
    FormsModule,
    MatProgressBarModule,
    OfflineVersionStatusModule,
    MatProgressSpinnerModule,
    UserModule,
    VersionTagTypeToDisplayNameModule,
    CarsPermissionPipesModule,
  ],
  declarations: [DocumentVersionsComponent],
  exports: [DocumentVersionsComponent],
})
export class DocumentVersionsModule {}
