import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {ContainerModule} from 'app/container/container.module';
import {ToolbarModule} from 'app/editor-toolbar/toolbar/toolbar.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {NavigationSidebarButtonsModule} from 'app/navigation/navigation-sidebar-buttons/navigation-sidebar-buttons.module';
import {SectionProxyModule} from 'app/section-content/section-proxy/section-proxy.module';
import {DocumentSelectorModule} from '../search/document-selector/document-selector.module';
import {ChangelogComponent} from './changelog.component';
import {ExcerptModule} from './excerpt/excerpt.module';
import {LinkCreationWizardModule} from './link-creation-wizard/link-creation-wizard.module';
import {LinkManagerModule} from './link-manager/link-manager.module';
import {LinkTableModule} from './link-table/link-table.module';
import {RangeDeletionManagerModule} from './range-deletion-manager/range-deletion-manager.module';
import {RangeMenuModule} from './range-menu/range-menu.module';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
    AltAccessibilityModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CarsErrorHandlingModule,
    DocumentSelectorModule,
    ExcerptModule,
    LinkCreationWizardModule,
    LinkManagerModule,
    LinkTableModule,
    RangeDeletionManagerModule,
    RangeMenuModule,
    SectionProxyModule,
    ContainerModule,
    NavigationSidebarButtonsModule,
  ],
  declarations: [ChangelogComponent],
  exports: [ChangelogComponent],
})
export class ChangelogModule {}
