import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ChatMessage} from '../../interfaces';
import {AltAccessibilityService} from '../../services/alt-accessibility.service';
import {ChatService} from '../../services/chat.service';

@Component({
  selector: 'cars-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [style({transform: 'translateX(100%)'}), animate(300)]),
      transition('* => void', [animate(300, style({transform: 'translateX(100%)'}))]),
    ]),
  ],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('inputElement')
  public inputElement: ElementRef;

  // Resizing variables:
  private px = 0;
  private py = 0;
  public maxHeight: number = document.body.clientHeight;
  public minHeight = 520;
  public maxWidth: number = document.body.clientWidth;
  public minWidth = 250;

  public width = Math.min(this.maxWidth, LocalConfigUtils.getConfig().chatWindowSize.width);
  public height = Math.min(this.maxHeight, LocalConfigUtils.getConfig().chatWindowSize.height);

  public readonly tooltipDelay = environment.tooltipDelay;
  public messages: ChatMessage[] = [];
  public messageText = '';
  public readonly dateFormat = 'MMM dd yyyy HH:mm';
  public showingChat = false;
  public unreadMessages = 0;
  public previewMessage: ChatMessage;
  public sendOnReturn = LocalConfigUtils.getConfig().sendOnReturn;
  public showingPreview: boolean = false;
  public timer;

  public handler: Function;

  private _subscriptions: Subscription[] = [];
  private mousemoveListener: EventListener = this.onCornerMove.bind(this);
  private mouseupListener: EventListener = this.onCornerRelease.bind(this);

  constructor(
    public chatService: ChatService,
    private _altAccessibilityService: AltAccessibilityService,
    private _renderer: Renderer2,
    public snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this.chatService.onNewMessage(this.handleNewMessage.bind(this)),
      this.chatService.getMessages().subscribe((messages: ChatMessage[]) => {
        this.messages = messages;
        this._subscriptions.push(
          this.chatService.getLastReadChatMessageIndex().subscribe((lastReadPosition: number) => {
            this.unreadMessages = this.messages.length - lastReadPosition;
          })
        );
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
    if (!!this.handler) {
      this.handler();
    }
  }

  public showChat(): void {
    this.showingChat = true;
    this.showingPreview = false;
    this.markLastMessageRead();
    if (!this.handler) {
      this.initialiseKeydownListener();
    }
  }

  public hideChat(): void {
    this.showingChat = false;
    if (!!this.handler) {
      this.handler();
      this.handler = null;
    }
  }

  public sendMessage() {
    if (this.messageText.length > 0) {
      this.chatService.sendMessage(this.messageText);
      this.messageText = '';
    }
  }

  public dismissPreview() {
    this.showingPreview = false;
    this.previewMessage = null;
  }

  private handleNewMessage(message: ChatMessage) {
    this.messages.push(message);
    if (!this.showingChat) {
      this.unreadMessages++;
      this.showPreview(message);
    } else {
      this.markLastMessageRead();
    }
  }

  private showPreview(message: ChatMessage) {
    if (this.showingPreview) {
      clearTimeout(this.timer);
    }
    this.previewMessage = message;
    this.showingPreview = true;
    this.timer = setTimeout(this.dismissPreview.bind(this), 4000);
  }

  /**
   * Tell server that the latest message has been read
   */
  private markLastMessageRead() {
    this.chatService.updateChatPosition(this.messages.length);
    this.unreadMessages = 0;
  }

  /**
   * Handles the mousedown on the corner being clicked.
   * @param event The mousedown event.
   */
  public onCornerClick(event: MouseEvent) {
    // Only allows left mouse drag
    if (event.buttons !== 1) {
      return;
    }

    document.addEventListener('mousemove', this.mousemoveListener);
    document.addEventListener('mouseup', this.mouseupListener);

    // Save the mouse coordinates
    this.px = event.clientX;
    this.py = event.clientY;
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * If mouse is moving and draggingcorner is true, change the height and width
   * of the component.
   */
  public onCornerMove(event: MouseEvent) {
    // Get the diff between current mouse coords and old coords:
    const offsetX = event.clientX - this.px;
    const offsetY = event.clientY - this.py;

    this.width -= offsetX;
    this.height -= offsetY;

    this.px = event.clientX;
    this.py = event.clientY;
  }

  /**
   * Handles the resize corner being released by the cursor.
   * @param {MouseEvent} event The mouseup event.
   */
  public onCornerRelease(event: MouseEvent) {
    document.removeEventListener('mousemove', this.mousemoveListener);
    document.removeEventListener('mouseup', this.mouseupListener);

    // These checks stop Angular setting the height and width to lower/higher than the
    // CSS-defined min/max-height or width:
    if (this.width < this.minWidth) {
      this.width = this.minWidth;
    }
    if (this.height < this.minHeight) {
      this.height = this.minHeight;
    }
    if (this.width > this.maxWidth) {
      this.width = this.maxWidth;
    }
    if (this.height > this.maxHeight) {
      this.height = this.maxHeight;
    }

    LocalConfigUtils.setChatWindowSize(this.width, this.height);
  }

  /**
   * Updates the local config setting for saveOnReturn.
   */
  public saveSendOnReturn(): void {
    LocalConfigUtils.setSendOnReturn(!this.sendOnReturn);
  }

  /**
   * Called on enter.keydown within the input box.
   * @return {boolean} Returns false after sending to avoid a newline appearing.
   */
  public onReturn(): boolean {
    if (this.sendOnReturn) {
      this.sendMessage();
      return false;
    }
  }

  /**
   * Sets the handler function to listen for the Escape keydown event.
   */
  public initialiseKeydownListener(): void {
    this.handler = this._renderer.listen(document, 'keydown', (event) => {
      switch (event.key) {
        case 'Escape': {
          event.preventDefault();
          this.hideChat();
          break;
        }
      }
    });
  }

  /**
   * Handles an alt key event depending on the state of the chat window.
   */
  public handleAltEvent(): void {
    if (!this.showingChat) {
      this.showChat();
    } else {
      document.getElementById('message-input').focus();
    }
    this._altAccessibilityService.triggerAltEvent(false);
  }
}
