import {ClauseType} from 'app/fragment/types';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {UUID} from 'app/utils/uuid';

export interface DisplayInternalReferenceFragment<T> {
  id: UUID;
  referenceType: ClauseType;
  referencePrefix: string;
  children: T[];
}

export enum ReferencePrefix {
  HEADING = 'heading',
  SUB_HEADING = 'sub-heading',
  CLAUSE = 'clause',
}

export class ClauseDisplayReferenceUtils {
  public static emptyGroup<T>(): DisplayInternalReferenceFragment<T> {
    return {
      id: null,
      referenceType: null,
      referencePrefix: '',
      children: [] as T[],
    };
  }

  public static groupDocumentReferencesByClauseType(
    listToIterate: InternalDocumentReferenceFragment[]
  ): DisplayInternalReferenceFragment<InternalDocumentReferenceFragment>[] {
    const mainList: DisplayInternalReferenceFragment<InternalDocumentReferenceFragment>[] = [];
    const currentGroup: DisplayInternalReferenceFragment<InternalDocumentReferenceFragment> = this.emptyGroup();

    listToIterate.forEach((item, index) => {
      this.updateGroupWithReference({id: item.id, children: listToIterate}, mainList, currentGroup, item, item, index);
    });
    return mainList;
  }

  public static updateGroupWithReference<T>(
    {id: contentId, children: contentChildren},
    mainList: DisplayInternalReferenceFragment<T>[],
    currentGroup: DisplayInternalReferenceFragment<T>,
    currentDocumentReference: InternalDocumentReferenceFragment,
    referenceToPush: T,
    index: number
  ) {
    if (currentGroup.children.length > 0 && this._clauseTypeMatchesGroup(currentGroup, currentDocumentReference)) {
      currentGroup.referencePrefix = this._clausePrefixMapping(currentGroup);
      mainList.push({...currentGroup});

      this._clearDown(currentGroup);
    }

    currentGroup.children.push(referenceToPush);

    if (currentGroup.id === null) {
      currentGroup.id = contentId;
    }

    if (currentGroup.referenceType === null) {
      currentGroup.referenceType = currentDocumentReference.targetClauseType;
    }

    if (index === contentChildren.length - 1) {
      currentGroup.referencePrefix = this._clausePrefixMapping(currentGroup);
      mainList.push({...currentGroup});
    }
  }

  private static _clearDown<T>(currentGroup: DisplayInternalReferenceFragment<T>) {
    currentGroup.id = null;
    currentGroup.referenceType = null;
    currentGroup.referencePrefix = '';
    currentGroup.children = [] as T[];
  }

  private static _clauseTypeMatchesGroup<T>(
    currentGroup: DisplayInternalReferenceFragment<T>,
    currentDocumentReference: InternalDocumentReferenceFragment
  ): boolean {
    return (
      this._convertClauseType(currentGroup.referenceType) !==
      this._convertClauseType(currentDocumentReference.targetClauseType)
    );
  }

  private static _clausePrefixMapping({referenceType, children}): string {
    const refType = this._convertClauseType(referenceType);
    if (children.length > 1) {
      return `${refType}s `;
    }
    return `${refType} `;
  }

  private static _convertClauseType(type: ClauseType) {
    switch (type) {
      case ClauseType.HEADING_1:
        return ReferencePrefix.HEADING;
      case ClauseType.HEADING_2:
      case ClauseType.HEADING_3:
        return ReferencePrefix.SUB_HEADING;
      default:
        return ReferencePrefix.CLAUSE;
    }
  }
}
