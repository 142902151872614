<cars-document-creator
  *ngIf="creating"
  class="mat-elevation-z2"
  (created)="documentCreated($event)"
  (canceled)="creating = false"
></cars-document-creator>

<!-- Can't ngIf this due to: https://github.com/angular/material2/issues/6980-->
<div [class.hidden]="creating" class="table-container">
  <div class="table-header">
    <mat-form-field [floatLabel]="'never'" class="search">
      <mat-icon matPrefix>search</mat-icon><input matInput placeholder="Filter documents" />
      <mat-hint align="start">
        <mat-checkbox
          *ngIf="!publishedTable"
          [(ngModel)]="checkboxSettings.userHasRoleOnly"
          (ngModelChange)="optionChange()"
          >Show only documents I'm assigned to</mat-checkbox
        >
        <mat-checkbox
          *ngIf="canDeleteDocuments()"
          [(ngModel)]="checkboxSettings.excludeDeleted"
          (ngModelChange)="optionChange()"
          >Exclude deleted documents</mat-checkbox
        >
      </mat-hint>
    </mat-form-field>

    <span class="buttons">
      <div
        class="disabled-button-container"
        [matTooltip]="
          (CarsAction.CREATE_DOCUMENT | carsEnabled: null | async)
            ? 'Create a new document'
            : 'You do not have the correct permissions to create a new document'
        "
        [matTooltipShowDelay]="tooltipDelay"
        *ngIf="!publishedTable"
      >
        <button
          [disabled]="CarsAction.CREATE_DOCUMENT | carsDisabled: null | async"
          (click)="creating = !creating"
          color="primary"
          mat-raised-button
          enableDefaultFocus
        >
          <mat-icon>add</mat-icon> Create document
        </button>
      </div>
    </span>
  </div>
  <div *ngIf="!loading && !total" class="table-overlay">No documents match your search</div>
  <mat-table
    #table
    [dataSource]="dataSource"
    matSort
    matSortDisableClear
    matSortActive="title"
    matSortDirection="asc"
    aria-label="Available documents"
    (matSortChange)="onSortChange($event)"
  >
    <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->

    <!--Suite Column -->
    <ng-container matColumnDef="SUITE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        matTooltip="Order by suite"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
      >
        Suite
      </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.SUITE | suiteDisplayName }} </mat-cell>
    </ng-container>

    <!--Reference Column -->
    <ng-container matColumnDef="LEGACY_REFERENCE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        matTooltip="Order by Reference"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
      >
        Legacy Reference
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.LEGACY_REFERENCE }}
      </mat-cell>
    </ng-container>

    <!--Document Code Column -->
    <ng-container matColumnDef="DOCUMENT_CODE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        matTooltip="Order by Document Code"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
      >
        Code
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.DOCUMENT_CODE }}
      </mat-cell>
    </ng-container>

    <!--Title Column -->
    <ng-container matColumnDef="TITLE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        matTooltip="Order by title"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
      >
        Title
      </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.TITLE }} </mat-cell>
    </ng-container>

    <!-- Technical Author Column -->
    <ng-container matColumnDef="DOCUMENT_OWNER_NAME">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        matTooltip="Order by technical author"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
        >Technical Author</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.DOCUMENT_OWNER_NAME ? row.DOCUMENT_OWNER_NAME : 'None' }}</mat-cell>
    </ng-container>

    <!-- Workflow Status Column -->
    <ng-container matColumnDef="WORKFLOW_STATUS">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        matTooltip="Order by status"
        matTooltipPosition="below"
        [matTooltipShowDelay]="tooltipDelay"
        >Workflow status</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.WORKFLOW_STATUS }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="DOCUMENT_CONTROLS">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" class="flex-row">
        <mat-chip-list [tabIndex]="-1">
          <ng-container *ngFor="let role of roleData">
            <mat-chip disabled="true" *ngIf="inRole(row[role.key])">
              <mat-icon>{{ role.icon }}</mat-icon>
              <div>{{ role.display }}</div>
            </mat-chip>
          </ng-container>
        </mat-chip-list>
        <div class="icons">
          <div *ngIf="row !== destination">
            <button
              *ngIf="!row.IS_DELETED && canDeleteDocuments()"
              mat-icon-button
              (click)="deleteDocument(row)"
              (keyup.enter)="preventEventPropagation($event)"
              [matTooltip]="'Delete document ' + row.TITLE"
              matTooltipPosition="below"
              [matTooltipShowDelay]="tooltipDelay"
              [attr.aria-label]="'Delete document ' + row.TITLE"
              carsAnalytics="delete-document"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <mat-spinner *ngIf="row === destination" [attr.aria-label]="'Opening document ' + row.TITLE"></mat-spinner>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="rowClick(row)"
      (keyup.enter)="rowClick(row)"
      [ngClass]="row.IS_DELETED ? 'disabled' : ''"
      [matTooltip]="row.IS_DELETED ? 'This document has been deleted.' : 'Open'"
      [matTooltipShowDelay]="tooltipDelay"
      tabindex="0"
      [attr.aria-label]="
        (row.IS_DELETED ? 'This document has been deleted.' : 'Open ') +
        row.TITLE +
        '.' +
        (row.SUITE === Suite.LEGACY_DMRB || row.SUITE === Suite.LEGACY_MCHW ? ' This document was imported.' : '')
      "
      carsAnalytics="select-document"
      disableDefaultFocus
    >
    </mat-row>
  </mat-table>

  <mat-progress-bar
    *ngIf="loading"
    mode="indeterminate"
    [color]="color"
    aria-label="Loading documents"
  ></mat-progress-bar>

  <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="paginationOptions"></mat-paginator>
</div>
