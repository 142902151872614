import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {TdifDocument, TdifSection} from 'app/tdif/tdif-types';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TdifService {
  public static readonly SECTION_URL: string = environment.apiHost + '/tdif/section/';
  public static readonly DOCUMENT_URL: string = environment.apiHost + '/tdif/document/';

  constructor(private http: HttpClient) {}

  public generateTdifForSection(sectionId: UUID): Observable<TdifSection> {
    return this.http.get(`${TdifService.SECTION_URL}${sectionId.value}`).pipe(
      map((section: any) => TdifSection.deserialise(section.section)),
      catchError((e) => {
        Logger.error('tdif-error', 'Failed to GET TDIF section from API.', e);
        return of(null);
      })
    );
  }

  public generateTdifForDocument(documentId: UUID): Observable<TdifDocument> {
    return this.http.get(`${TdifService.DOCUMENT_URL}${documentId.value}`).pipe(
      map(
        (tdif: any) => {
          return TdifDocument.deserialise(tdif.document);
        },
        catchError((e) => {
          Logger.error('tdif-error', 'Failed to GET TDIF document from API.', e);
          return of(null);
        })
      )
    );
  }
}
