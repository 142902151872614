<mat-form-field>
  <cars-alt-accessibility
    [altProperties]="altProperties"
    (onSelect)="focusSearch()"
    (updateAltProperties)="updateAltProperties()"
  ></cars-alt-accessibility>
  <mat-icon matPrefix>search</mat-icon>
  <input
    matInput
    [(ngModel)]="searchString"
    placeholder="Search document"
    (keyup.enter)="searchDocument()"
    [minlength]="minSearchLength"
    carsAnalytics="search-document"
    #search="ngModel"
    #searchBox
  />
  <mat-error *ngIf="search.invalid"> Search must be at least {{ minSearchLength }} characters long. </mat-error>
</mat-form-field>
<button
  mat-raised-button
  color="primary"
  (click)="searchDocument()"
  matTooltip="Search the entire document"
  matTooltipPosition="right"
  [matTooltipShowDelay]="tooltipDelay"
  aria-label="Search the entire document"
  carsAnalytics="search-document"
  enableDefaultFocus
>
  Search
</button>
