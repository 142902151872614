import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TdifContent} from '../tdif-types';

@Component({
  selector: 'cars-tdif-input',
  templateUrl: './tdif-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdifInputComponent {
  @Input() content: TdifContent[];
  @Input() placeholderValue: string;

  constructor() {}
}
