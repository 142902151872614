import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ClauseFragment, FragmentType, ListFragment, ListIndexingType} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {ClauseLinkService} from 'app/services/clause-link.service';
import {ClauseService} from 'app/services/clause.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {SidebarService} from 'app/services/sidebar.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-clause-link-icon',
  templateUrl: './clause-link-icon.component.html',
  styleUrls: ['./clause-link-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClauseLinkIconComponent implements OnInit, OnDestroy {
  @Input() public clause: ClauseFragment;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public clauseLinksValid: boolean = true;
  private _reordering: boolean;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _clauseService: ClauseService,
    private _sidebarService: SidebarService,
    private _clauseLinkService: ClauseLinkService,
    private _reorderClausesService: ReorderClausesService,
    private _viewService: ViewService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    let validAt: number = this._viewService.getCurrentView()?.versionTag?.createdAt;
    validAt = !!validAt ? validAt : null;
    this._subscriptions.push(
      this._clauseLinkService.getHasValidLinksStream(this.clause, validAt).subscribe((valid: boolean) => {
        this.clauseLinksValid = valid || this.checkIfClauseIsScheduleClause();
        this._cdr.markForCheck();
      }),
      this._reorderClausesService.onReorderingEvent().subscribe((status: boolean) => (this._reordering = status))
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public openSidebar() {
    if (!this._reordering) {
      this._clauseService.setSelected(this.clause);
      this._sidebarService.setSidebarStatus(SidebarStatus.CLAUSE_LINKS);
    }
  }

  /**
   * Helper method to check if clause is 'fake' schedule clause to prevent the link validation icon from being applied
   */
  private checkIfClauseIsScheduleClause(): boolean {
    const clauseScheduleFrags = this.clause.children.find(
      (child) =>
        child.is(FragmentType.LIST) && (child as ListFragment).primaryListIndexingType === ListIndexingType.LOWER_ALPHA
    );
    return clauseScheduleFrags !== undefined;
  }
}
