<h3>This information is based on websocket session information.</h3>

<button mat-button (click)="loadLocations()" aria-label="refresh user locations">
  Refresh <mat-icon>refresh</mat-icon>
</button>

<table>
  <tr>
    <th>User ({{ locationsPerUser.size }})</th>
    <th>Locations</th>
  </tr>
  <tr *ngFor="let location of locationsPerUser | map; trackBy: mapTracker">
    <td>
      <cars-user [userId]="location.key"></cars-user>
    </td>
    <td>
      <div *ngFor="let session of location.val">
        <a [href]="typeIdPairToUrl(session.typeIdPairs)">{{ typeIdPairToUrl(session.typeIdPairs) }}</a>
        (Session created {{ session.createdAt | timeAgo }})
      </div>
    </td>
  </tr>
</table>

<table>
  <tr>
    <th>Location ({{ usersPerLocation.size }})</th>
    <th>Users</th>
  </tr>
  <tr *ngFor="let location of usersPerLocation | map; trackBy: mapTracker">
    <td>
      <a [href]="locationUsersToUrl(location.val)">{{ locationUsersToUrl(location.val) }}</a>
    </td>
    <td>
      <div *ngFor="let user of location.val.users">
        <cars-user [userId]="user.value"></cars-user>
      </div>
    </td>
  </tr>
</table>
