<ng-container *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <div class="loading-message">Loading versions</div>
</ng-container>
<mat-list *ngIf="!loading">
  <mat-card *ngIf="versionDiscussionsAggregates.length === 0" class="version-card"> No versions available </mat-card>
  <ng-container *ngFor="let vda of versionDiscussionsAggregates">
    <cars-version-aggregate [versionDiscussionAggregate]="vda"></cars-version-aggregate>
  </ng-container>
</mat-list>
