import {Component, Inject} from '@angular/core';
import {MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from 'environments/environment';
import {ChangelogRange} from '../changelog-range';
import {ChangelogService} from '../changelog.service';

@Component({
  selector: 'cars-range-deletion-manager',
  templateUrl: './range-deletion-manager.component.html',
  styleUrls: ['./range-deletion-manager.component.scss'],
})
export class RangeDeletionManagerComponent {
  public tooltipDelay: number = environment.tooltipDelay;

  /**
   * The range to be deleted.
   */
  public range: ChangelogRange;

  /**
   * Optional comment about why the range was deleted.
   */
  public deletionComment: string;

  public static DIALOG_OPTIONS_FACTORY(range: ChangelogRange): MatDialogConfig<any> {
    return {
      ariaLabel: 'Mark changelog range as deleted',
      width: '720px',
      data: {
        range: range,
      },
    };
  }

  constructor(
    public dialogRef: MatDialogRef<RangeDeletionManagerComponent>,
    private _changelogService: ChangelogService,
    @Inject(MAT_DIALOG_DATA) data: {range: ChangelogRange}
  ) {
    this.range = data.range;
    this.deletionComment = this.range.deletionComment;
  }

  /**
   * Save the changes to the deletion comment and mark the range as deleted.
   */
  public save(): void {
    this.range.deleted = true;
    this.range.deletionComment = this.deletionComment;
    this._changelogService.updateRange(this.range).then(() => {
      this.dialogRef.close();
    });
  }

  /**
   * Close the dialog without saving.
   */
  public close(): void {
    this.dialogRef.close();
  }
}
